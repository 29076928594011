import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { User } from '@app/profile/model/user.model';
import { UsersService } from '@app/profile/services/users.service';
import { take } from 'rxjs/operators';

declare var Skype: any;

/**
 * Сервис интеграции со SFB
 *
 * @export
 * @class SkypeService
 */
@Injectable({
  providedIn: 'root'
})
export class SkypeService {

  constructor(private usersService: UsersService) { }

  public initialized: Subject<boolean> = new BehaviorSubject(false);

  private application: any;

  initialize() {
    try {
      return;
      this.usersService.getDomainName().pipe(take(1)).subscribe(domainName => {
        const that = this;
        Skype.initialize({
          apiKey: 'a42fcebd-5b43-4b89-a065-74450fb91255'
        }, function (api) {
          that.application = api.application();

          if (!domainName) {
            return;
          }

          console.log(`SkypeService: SDK Instantiated for domain name=${domainName}`);

          that.application.signInManager.signIn({
            domain: domainName
          }).then(function (value) {
            that.initialized.next(true);
            try {
              console.log('SkypeService: Signed in as ' + that.application.personsAndGroupsManager.mePerson.displayName());
            } catch (e) {
              console.error(`SkypeService: Error on get user name: ${e}`);
            }
          }, function (error) {
            console.error('SkypeService: Failed to sign in.');
          });
        }, function (err) {
          console.error(`SkypeService: Error loading Skype Web SDK: ${err}`);
        });
      });
    } catch (e) {
      console.error(`SkypeService: Error loading Skype Web SDK: ${e}`);
    }
  }

  subscribeToUser(user: User): void {
    this.initialized.subscribe(res => {
      if (!res || !user || user.skypeStatus && user.skypeStatus.getValue() != null) {
        return;
      }

      if (!user.skypeStatus) {
        user.skypeStatus = new BehaviorSubject(null);
      }

      this.usersService.currentUser.subscribe(currentsUser => {
        if (user.id !== currentsUser.id) {
          let searchString: string;

          if (user.email) {
            searchString = user.email;
          } else {
            searchString = user.accountName.substr(user.accountName.indexOf('\\') + 1);
          }

          try {
            const query = this.application.personsAndGroupsManager.createPersonSearchQuery();
            query.text(searchString);
            query.limit(1);
            query.getMore().then(function (results) {
              results.forEach(function (result) {
                const person = result.result;
                person.status.get().then(function (presence) {
                  console.log(searchString + ' presence: ' + presence);
                  user.skypeStatus.next(presence);
                });
                person.status.subscribe(presence => {
                  console.log(searchString + ' presence.subscribe: ' + presence);
                  user.skypeStatus.next(presence);
                });
                // person.status.changed((status) => {
                //   if (status) {
                //     console.log(searchString + ' presence.changed: ' + status);
                //     user.skype = status;
                //   }
                // });
              });
            }).then(null, function (error) {
              console.log(`error: ${error}`);
            });
          } catch (e) {
            console.error(`Error loading Skype Web SDK: ${e}`);
          }
        } else {
          user.skypeStatus.next(this.application.personsAndGroupsManager.mePerson.status());
        }
      });
    });
  }
}
