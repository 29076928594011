<div class="form cos__body">
  <div class="form__line _far" *ngFor="let item of settings; let i = index">
    <div class="draggable-line _nowrap _transparent">
      <div class="draggable-line__content">
        <div class="input-main _simple _static form__subtitle _full">
          <input placeholder="Название настройки {{i + 1}}" [(ngModel)]="item.key" type="text" maxlength="255"
            class="input-main__field" (focus)="onFocus($event, item)" (blur)="onBlur($event, item)"
            (keyup)="onBlur($event, item)" />
          <div class="form__note" *ngIf="item.keyFocused">{{item.key?.length || 0}} из 255</div>
        </div>
        <button type="button" class="btn btn-remove" (click)="onRemove(item)"></button>
      </div>
    </div>
    <div class="input-main _simple _static form__input">
      <textarea autosize [(ngModel)]="item.value" placeholder="Значение настройки {{i + 1}}"
        rows="1" class="input-main__field" (focus)="item.valueFocused=true" (blur)="onBlur($event, item)"
        (keyup)="onBlur($event, item)"></textarea>
      <div class="form__note" *ngIf="item.valueFocused">{{item.value?.length || 0}} из 255</div>
    </div>
  </div>
  <div class="form__line _far">
    <div type="button" class="btn btn-add" (click)="addSetting()">Добавить настройку</div>
  </div>
  <div class="form__actions">
    <button type="button" class="btn btn-simple" [attr.disabled]="hasAnyChanges()?null:''"
      (click)="onCancel()">Отмена</button>
    <button type="button" class="btn btn-primary" [attr.disabled]="hasAnyChanges()?null:''"
      (click)="submit()">Сохранить</button>
  </div>
</div>
