import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { BaseService } from '@app/core/services/base.service';
import { INewAlbumModel, IUpdateAlbumModel } from '@app/gallery/model/new-album-model';
import { IAlbum, IAlbumVoteResults } from '@app/gallery/model/album-model';
import {
  IMediaFile,
  IMediaFileRequestModel,
  IMoveMediaFileModel,
  ISetMediaFileAsCoverModel,
} from '@app/gallery/model/media-file';
import { Observable } from 'rxjs';
import { HelperService } from '@app/core/services/helper.service';
import { MediaFileUploadV2Service } from '@app/shared/services/media-file-upload-v2.service';

/**
 * API сервис галереи
 *
 * @export
 * @class GalleryApiService
 * @extends {BaseService}
 */
@Injectable()
export class GalleryApiService extends BaseService {

  protected serviceUrl = `${environment.siteUrl}/api/gallery`;
  constructor(
    protected mediaFileUploadSevice: MediaFileUploadV2Service,
    protected http: HttpClient,
    public helper: HelperService) { super(http, helper); }

  /**
  * Получение альбомов профиля
  *
  * @param number profileId ИД профиля
  * @param number offset сдвиг
  * @param number limit лимит
  * @memberof GalleryApiService
  */
  getAlbums(profileId: number, offset: number, limit: number) {
    return this.get<IAlbum[]>(`profile/${profileId}/albums?offset=${offset}&limit=${limit}`);
  }

  /**
   * Поиск альбомов
   *
   * @param {string} searchText текст поиска
   * @param {number} offset сдвиг
   * @param {number} limit лимит
   * @returns
   * @memberof GalleryApiService
   */
  searchAlbums(searchText: string, profileId: number, offset: number, limit: number): Observable<IAlbum[]> {
    // tslint:disable-next-line:max-line-length
    return this.get(`searchAlbums?searchText=${encodeURIComponent(searchText)}&profileId=${profileId ? profileId : 0}&offset=${offset}&limit=${limit}`);
  }

  /**
   * Получение текущего альбома
   *
   * @param {number} id ИД альбома
   * @memberof GalleryApiService
   */
  getAlbum(id: number) {
    return this.get<IAlbum>(`album/${id}`);
  }

  /**
   * Добавление нового альбома
   *
   * @param {INewAlbumModel} data
   * @memberof GalleryApiService
   */
  addAlbum(data: INewAlbumModel) {
    return this.post<IAlbum>(`album`, data);
  }

  /**
   * Редактирование альбома
   *
   * @param {IUpdateAlbumModel} data
   * @memberof GalleryApiService
   */
  editAlbum(albumId: number, data: IUpdateAlbumModel) {
    return this.put<IAlbum>(`album/${albumId}`, data);
  }

  /**
   * Удаление альбома
   *
   * @param {number} id ИД альбома
   * @memberof GalleryApiService
   */
  deleteAlbum(id: number) {
    return this.delete<IAlbum>(`album/${id}`);
  }

  /**
   * Включить голосование
   *
   * @param {number} id ИД альбома
   * @param {Date} date Дата окончания
   * @returns
   * @memberof GalleryApiService
   */
  albumVoting(albumId: number, dateEnd: Date, enable: boolean) {
    return this.post<IAlbum>(`albumVoting`, { albumId: albumId, dateEnd: dateEnd, enable: enable });
  }

  /**
   * Получение количества медиа-файлов альбома
   *
   * @param {number} profileId
   * @returns {Observable<number>}
   * @memberof GalleryApiService
   */
  getAlbumMediaFilesCount(albumId: number): Observable<number> {
    return this.get<number>(`album/${albumId}/mediaFilesCount`);
  }

  getMediaFiles(albumId: number, offset: number, limit: number) {
    return this.get<IMediaFile[]>(`album/${albumId}/media?offset=${offset}&limit=${limit}`);
  }

  getProfileMediaFiles(profileId: number, offset: number, limit: number) {
    return this.get<IMediaFile[]>(`profile/${profileId}/media?offset=${offset}&limit=${limit}`);
  }

  getLastMediaFiles(userId: number, offset: number, limit: number) {
    return this.get<IMediaFile[]>(`user/${userId}/lastMedia?offset=${offset}&limit=${limit}`);
  }

  getLastMediaFilesBlock(userId: number, profileId: number, date: Date, offset: number, limit: number) {
    return this.get<IMediaFile[]>(`GetLastMediaFilesBlock?userId=${userId}&profileId=${profileId}&date=${date}&offset=${offset}&limit=${limit}`);
  }

  /**
   * Поиск по медиа-файлам
   *
   * @param {string} searchText текст поиска
   * @param {number} offset сдвиг
   * @param {number} limit лимит
   * @returns
   * @memberof GalleryApiService
   */
  searchMediaFiles(searchText: string, profileId: number, offset: number, limit: number) {
    // tslint:disable-next-line:max-line-length
    return this.get<IMediaFile[]>(
      `searchMediaFiles?searchText=${encodeURIComponent(
        searchText,
      )}&profileId=${profileId}&offset=${offset}&limit=${limit}`,
    );
  }

  getMediaFile(id: number): Observable<IMediaFile> {
    return this.get(`mediaFile/${id}`);
  }

  getMediaFileBlob(url: string): Observable<Blob> {
    return this.get(url, null, null, 'blob');
  }

  addMediaFile() {
    throw new Error('Method not implemented.');
  }

  /**
   * Обновление медиа-файла
   *
   * @param {IMediaFileRequestModel} data
   * @returns {Observable<IMediaFile>}
   * @memberof GalleryApiService
   */
  editMediaFile(data: IMediaFileRequestModel): Observable<IMediaFile> {
    return this.put(`mediaFile`, data);
  }

  deleteMediaFile(id: number): Observable<boolean> {
    return this.delete(`mediaFile/${id}`);
  }

  moveMediaFile(data: IMoveMediaFileModel): Observable<IMediaFile> {
    return this.post(`moveMediaFile`, data);
  }

  setMediaFileAsCover(id: number): Observable<ISetMediaFileAsCoverModel> {
    return this.put(`mediaFile/${id}/setAsCover`, {});
  }

  /**
   * Получение количества медиа-файлов профиля
   *
   * @param {number} profileId
   * @returns {Observable<number>}
   * @memberof GalleryApiService
   */
  getProfileMediaFilesCount(profileId: number): Observable<number> {
    return this.get<number>(`profile/${profileId}/mediaFilesCount`);
  }

  /**
   * Получение результатов голосования
   *
   * @param {number} albumId ИД
   * @param {number} offset сдвиг
   * @param {number} limit предел
   * @returns
   * @memberof GalleryApiService
   */
  getAlbumVoteResults(albumId: number, offset: number, limit: number): Observable<IAlbumVoteResults> {
    return this.get(`album/${albumId}/voteResults?offset=${offset}&limit=${limit}`);
  }

  /**
   * Загрузка медиафайла
   *
   * @param number albumId ИД альбома
   * @param FormData formData данные формы с медиафайлом
   */
  uploadMediaFile(albumId: number, formData: FormData): Observable<IMediaFile[]> {
    return super.post(`album/${albumId}/uploadMediaFile`, formData);
    // return this.mediaFileUploadSevice.uploadMediaFile(albumId,)
  }

  uploadMediaFilePreview(mediaFile: number, ownerId: number, formData: FormData): Observable<IMediaFile> {
    return super.post(`mediaFile/${mediaFile}/uploadMediaPreview?ownerId=${ownerId}`, formData);
  }

  uploadVideoPreview(mediaFile: number, formData: FormData): Observable<IMediaFile> {
    return super.post(`mediaFile/${mediaFile}/uploadVideoPreview`, formData);
  }

  uploadFeedAttachedFiles(formData, ownerId: number, ownerType: number): Observable<IMediaFile[]> {
    return super.post(`uploadPostMediaAttachment/${ownerId}`, formData);
  }

  uploadCommentAttachedFiles(formData, ownerId: number): Observable<IMediaFile[]> {
    return super.post(`uploadCommentMediaAttachment/${ownerId}`, formData);
  }

  uploadFruitFace(formData: FormData): Observable<IMediaFile> {
    return super.post(
      `${(<any>window).signalRServer}/_layouts/15/Intry/handlers/FruitFaceUploadHandler.ashx`,
      formData,
    );
  }
}
