import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { HelperService } from '@app/core/services/helper.service';
import { BaseResizableComponent } from '@app/core/components/base-resizable.component';
import { Router, Params } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PageLinkModalComponent } from '../../dialogs/page-link-modal/page-link-modal.component';
import { IPage } from '@app/pages/model/page';

/**
 * Меню создания страницы или ссылки
 *
 * @export
 * @class PagesMenuComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-pages-menu',
  templateUrl: './pages-menu.component.html',
  styleUrls: ['./pages-menu.component.scss']
})
export class PagesMenuComponent extends BaseResizableComponent implements OnDestroy {

  @Input() showAddButton: boolean;
  @Input() bigPlus: boolean;
  @Input() parentId: number;
  @Input() order: number;
  @Input() groupId: number;

  @Output() added: EventEmitter<IPage> = new EventEmitter();

  private createLinkDialog: MatDialogRef<PageLinkModalComponent>;

  constructor(
    protected router: Router,
    private dialog: MatDialog,
    public helper: HelperService
  ) { super(helper); }

  navigateToNewForm() {
    let queryParams: Params = null;

    if (this.parentId) {
      queryParams = { parentId: this.parentId };
    }

    if (this.groupId) {
      this.router.navigate([`/group/${this.groupId}/pages/new`], { queryParams: queryParams });
    } else {
      this.router.navigate([`/admin/pages/new`], { queryParams: queryParams });
    }
  }

  openCreateLink() {
    this.createLinkDialog = this.dialog.open(PageLinkModalComponent, {
      data: {
        isNew: true,
        link: null,
        text: `Добавление ссылки`,
        ownerId: this.groupId,
        parentId: this.parentId,
        onOk: this.onLinkCreated.bind(this)
      }
    });
  }

  private onLinkCreated(res: IPage) {
    if (res) {
      console.log(`created`);
      this.added.emit(res);
    }
    this.createLinkDialog.close();
  }

  isBigPlus() {
    return this.bigPlus && !this.isMobileView();
  }
}
