import { Injectable, EventEmitter } from '@angular/core';
import { MainHub } from '@app/signalr/main.hub';
import { FeedHub } from '@app/signalr/feed.hub';
import { CommentHub } from '@app/signalr/comment.hub';
import { LikeHub } from '@app/signalr/like.hub';
import { GroupHub } from '@app/signalr/group.hub';
import { GalleryHub } from '@app/signalr/gallery.hub';
import * as signalR from '@microsoft/signalr';
import { HelperService } from '@app/core/services/helper.service';
import { IntryAuthService } from '@app/auth/shared/services/intry-auth.service';

/**
 * Базовый сервис SignalR обёртки над @microsoft/signalr
 *
 * @export
 * @class SignalRService
 */
@Injectable()
export class SignalRService {

    // Declare the variables
    protected connection: any;
    protected startOptions: any;
    protected logging = false;

    // create the Event Emitter
    public connectionEstablished: EventEmitter<Boolean>;
    public connectionExists: Boolean;

    private services = [
        this.mainHub,
        this.feedHub,
        this.commentHub,
        this.likeHub,
        this.groupHub,
        this.galleryHub
    ];

    /**
     * URL-адрес хаба. Переопределяется в наследниках.
     *
     * @protected
     * @type string
     * @memberof PortalBaseService
     */
    protected hubUrl = this.helper.getSiteUrl() + '/hub/main';
    protected hubConnection: signalR.HubConnection;

    constructor(
        public mainHub: MainHub,
        public feedHub: FeedHub,
        public commentHub: CommentHub,
        public likeHub: LikeHub,
        public groupHub: GroupHub,
        public galleryHub: GalleryHub,
        protected auth: IntryAuthService,
        protected helper: HelperService
    ) {

        // Constructor initialization
        this.connectionEstablished = new EventEmitter<Boolean>();
        this.connectionExists = false;

        this.init();
    }

    protected init() {
        this.startConnection();
    }

    /**
     * Подключиться в хабу
     *
     * @private
     * @memberof HubBaseService
     */
    startConnection() {
        const builder = new signalR.HubConnectionBuilder();

        // if (!this.helper.environment.production) {
        //     builder.configureLogging(signalR.LogLevel.Trace);
        // } else {
        //     builder.configureLogging(signalR.LogLevel.Information);
        // }

        this.hubConnection = builder.withUrl(this.hubUrl, {
            accessTokenFactory: () => this.auth.getToken(),
            // transport: signalR.HttpTransportType.WebSockets
        }).withAutomaticReconnect().build();

        // для дебага отложим дисконнект по таймауту
        if (!this.helper.environment.production) {
            this.hubConnection.keepAliveIntervalInMilliseconds = 150000;
        }

        this.hubConnection.start().then(res => {
            console.log('Now connected, connection ID= ' + this.hubConnection.connectionId);
            this.registerOnServerEvents();
        }, error => {
            console.log('Could not connect ' + error);
        });

        // реконнект
        this.hubConnection.onclose(() => setTimeout(() => {
            // this.hubConnection.start();
        }, 5000));

        // // реконнект
        // this.hubConnection.onreconnecting(error => {
        //     console.log('Reconnecting ' + error);
        // });

        // // реконнект
        // this.hubConnection.onreconnected(connectionId => {
        //     console.log('Now reconnected, connection ID= ' + connectionId);
        // });
    }

    private registerOnServerEvents() {
        this.services.forEach(service => {
            // init proxy and register on server events
            service.registerOnServerEvents(this.hubConnection);
        });
    }

    /**
     * Отключиться от хаба
     *
     * @memberof HubBaseService
     */
    disconnect() {
        this.hubConnection.stop();
    }
}
