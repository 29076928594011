<ng-container *ngIf="isEdit; then edit; else display"></ng-container>
<ng-template #edit>
  <div class="post__files">
    <div class="post__file" *ngFor="let attachment of attachments">
      <ng-container *ngIf="attachment.attachment?.toUpload">
        <span class="link-file" [ngClass]="filesService.getFileIcon(attachment.attachment)">
          <span class="link-file__text">{{attachment.attachment.title}}</span>
          <span class="link-file__subtext"
            *ngIf="attachment?.attachment?.size">{{attachment?.attachment?.size | fileSize}}</span>
        </span>
      </ng-container>
      <ng-container *ngIf="!attachment.attachment?.toUpload">
        <a [attr.href]="filesService.webApiGetFileUrl(attachment.attachment, attachment.attachment?.guid)"
          target="_blank" class="link-file" [ngClass]="filesService.getFileIcon(attachment.attachment)">
          <span class="link-file__text">{{attachment.attachment.title}}</span>
          <span class="link-file__subtext"
            *ngIf="attachment?.attachment?.size">{{attachment?.attachment?.size | fileSize}}</span>
        </a>
      </ng-container>
      <a href="javascript:;" (click)="remove(attachment)"><span class="icon _remove"></span></a>
    </div>
  </div>
  <div *ngIf="filesUploading">
    <mat-spinner [diameter]="32"></mat-spinner>
  </div>
</ng-template>
<ng-template #display>
  <div class="post__files">
    <div class="post__file" *ngFor="let attachment of attachments">
      <a attr.id="att_{{attachment.attachment.guid}}"
        [attr.href]="filesService.webApiGetFileUrl(attachment.attachment, attachment.attachment?.guid)" target="_blank"
        class="link-file" [ngClass]="filesService.getFileIcon(attachment.attachment)">
        <span class="link-file__text">{{attachment.attachment.title}}</span>
        <span class="link-file__subtext"
          *ngIf="attachment?.attachment?.size!==''">{{getAttachmentSize(attachment) | async | fileSize}}</span>
      </a>
      <app-attachment-menu [attachment]="attachment" itemLink="att_{{attachment.attachment.guid}}">
      </app-attachment-menu>
    </div>
  </div>
</ng-template>