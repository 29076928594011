import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CalendarWidgetComponent } from './components/calendar-widget/calendar-widget.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { CalendarTabsComponent } from './components/calendar-tabs/calendar-tabs.component';
import { CalendarBaseComponent } from './components/calendar-base/calendar-base.component';
import { CalendarWidgetTabsComponent } from './components/calendar-widget-tabs/calendar-widget-tabs.component';
import { CalendarApiService } from './services/calendar-api.service';
import { CalendarService } from './services/calendar.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule,
  ],
  declarations: [
    CalendarComponent,
    CalendarWidgetComponent,
    CalendarTabsComponent,
    CalendarBaseComponent,
    CalendarWidgetTabsComponent
  ],
  exports: [
    CalendarComponent,
    CalendarWidgetComponent
  ],
  providers: [
    DatePipe,
    CalendarApiService,
    CalendarService
  ]
})
export class CalendarModule { }
