export interface ITag {
    id?: number;
    name?: string;
    type?: TagType;
    // ui
    fragment?: string;
    routeLink?: string;
}

export enum TagType {
    unknown = 0,
    post = 1,
    page = 2
}
