import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserAwardsComponent } from './components/user-awards/user-awards.component';
import { NewAwardComponent } from './components/forms/new-award/new-award.component';
import { UserAwardsListComponent } from './components/user-awards-list/user-awards-list.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { UserAwardsMainComponent } from './components/user-awards-main/user-awards-main.component';
import { SharedModule } from '@app/shared/shared.module';
import { CoreModule } from '@app/core/core.module';
import { AwardsService } from './services/awards.service';
import { AwardsApiService } from './services/awards-api.service';
import { AwardsMenuComponent } from './components/menu/awards-menu/awards-menu.component';
import { AwardMenuComponent } from './components/menu/award-menu/award-menu.component';
import { EditAwardComponent } from './components/forms/edit-award/edit-award.component';

// const routes: Routes = [
//   {
//     path: 'profile/:id', component: ProfileViewComponent,
//     children: [
//       {
//         path: 'awards', component: ProfileViewComponent, children: [
//           {
//             path: '', component: UserAwardsMainComponent,
//             children: [
//               { path: '', component: UserAwardsListComponent },
//               { path: 'new', component: NewAwardComponent },
//             ]
//           },
//         ]
//       }
//     ]
//   }
// ];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CoreModule,
    SharedModule,
    RouterModule
  ],
  declarations: [
    UserAwardsComponent,
    NewAwardComponent,
    UserAwardsListComponent,
    UserAwardsMainComponent,
    AwardsMenuComponent,
    AwardMenuComponent,
    EditAwardComponent],
  exports: [UserAwardsComponent],
  providers: [
    AwardsService,
    AwardsApiService
  ]
})
export class AwardsModule { }
