import {Component, OnInit, OnDestroy} from '@angular/core';
import {HelperService} from '@app/core/services/helper.service';
import {IdeasService} from '@app/ideas/services/ideas.service';
import {GroupsService} from '@app/groups/services/groups.service';
import {takeUntil} from 'rxjs/operators';
import {BreadcrumbsService} from '@app/shared/services/breadcrumbs.service';
import {GroupInfo} from '@app/groups/model/group-info.model';
import {TabbedListComponent} from '@app/shared/components/tabs/tabbed-list.component';
import {Router, ActivatedRoute} from '@angular/router';
import {IIdeaStatus, IIdea, IdeasListType} from '@app/ideas/model/idea';
import {Tab} from '@app/shared/components/tabs/tab.model';
import {GroupType} from '@app/groups/model/group-type.model';
import { UsersService } from '@app/profile/services/users.service';

/**
 * Идеи группы
 *
 * @export
 * @class GroupIdeaListComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-group-idea-list',
  templateUrl: './group-idea-list.component.html',
  styleUrls: ['./group-idea-list.component.scss'],
})
export class GroupIdeaListComponent extends TabbedListComponent<any> implements OnInit, OnDestroy {
  /**
   * Группа
   *
   * @type {GroupInfo}
   * @memberof GroupIdeaListComponent
   */
  group: GroupInfo;

  /**
   * Отобразить кнопку "+"
   *
   * @type {boolean}
   * @memberof GroupIdeaListComponent
   */
  showAddButton: boolean;

  /**
   * Ссылка для перехода на форму создания
   *
   * @type {string}
   * @memberof GroupIdeaListComponent
   */
  newLink: string;

  /**
   * Блок фильтров отображается/не отображается
   *
   * @type {boolean}
   * @memberof GroupIdeaListComponent
   */
  filtersVisible: boolean;

  /**
   * Текущий статус, выбранный в фильтрах
   *
   * @type {IIdeaStatus}
   * @memberof GroupIdeaListComponent
   */
  currentStatus: IIdeaStatus;

  // TODO: костыль, убрать после того как будут процедуры подсчёта количества идей
  limit = 50;

  constructor(
    public usersService: UsersService,
    public groupsService: GroupsService,
    protected breadcrumbsService: BreadcrumbsService,
    protected ideasService: IdeasService,
    protected router: Router,
    protected route: ActivatedRoute,
    public helper: HelperService,
  ) {
    super(router, route, helper);
  }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(
        group => {
          if (group) {
            this.group = group;

            const link = `/group/${group.id}/ideas`;

            this.breadcrumbsService.breadcrumbs.next([{title: 'Идеи', routerUrl: link}]);

            // show "+" when has rights
            this.showAddButton = this.group.type === GroupType.Business || this.groupsService.isParticipant(this.group, currentUser);

            this.tabs = {
              title: 'Идеи',
              routerLink: link,
              hideCounters: false,
              items: [
                {
                  id: 1,
                  title: 'Идеи',
                  name: 'ideas',
                  fragment: '',
                  items: [],
                  offset: 0,
                  itemsCount: 0,
                  method: this.getGroupIdeas.bind(this),
                  default: true,
                  emptyText: 'В группе нет идей',
                },
              ],
            };

            if (this.groupsService.isAdmin(this.group, currentUser)) {
              this.tabs.items.push({
                id: 2,
                title: 'Новые',
                name: 'new',
                fragment: 'new',
                items: [],
                offset: 0,
                itemsCount: 0,
                method: this.getGroupNewIdeas.bind(this),
                emptyText: 'В группе нет новых идей',
                asBadge: true,
              });
            } else {
              this.tabs.items.push({
                id: 2,
                title: 'Опубликованные',
                name: 'published',
                fragment: 'published',
                items: [],
                offset: 0,
                itemsCount: 0,
                method: this.getGroupPublishedIdeas.bind(this),
                emptyText: 'В группе нет опубликованных идей',
              });
            }

            this.newLink = link + '/new';

            this.route.fragment.pipe(takeUntil(this.destroyed$)).subscribe(val => {
              this.mobHeight = window.innerHeight;
              this.setNewLimit();

              // get hash
              if (val != null) {
                const keys = val.split('&');
                const hash = {};
                keys.forEach(key => {
                  // tslint:disable-next-line:no-shadowed-variable
                  const val = key.split('=');
                  hash[val[0]] = val[1];
                });

                this.currentTab = null;

                this.tabs.items.forEach(tab => {
                  if (tab.fragment && keys.find(k => k === tab.fragment)) {
                    this.currentTab = tab;
                  }
                });

                if (!this.currentTab) {
                  this.currentTab = this.tabs.items[0];
                }
              } else {
                this.currentTab = this.tabs.items[0];
              }

              this.tabs.items.forEach(tab => {
                tab.offset = 0;
                tab.method(tab, this);
              });
            });

            this.ideasService.ideaDeleted$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
              this.cleanTab(this.currentTab);
              this.currentTab.method(this.currentTab, this);
            });

            this.ideasService.ideaChanged$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
              this.cleanTab(this.currentTab);
              this.currentTab.method(this.currentTab, this);
            });
          } else {
            this.group = null;
          }
          this.loaded = true;
        },
        error => {
          this.loaded = true;
        },
      );
    });
  }

  /**
   * Отображать фильтры только для вкладки идеи
   *
   * @returns {boolean}
   * @memberof GroupIdeaListComponent
   */
  isShowFilters(): boolean {
    return this.currentTab && this.currentTab.name === 'ideas';
  }

  /**
   * Включить/выключить фильтры
   *
   * @memberof GroupIdeaListComponent
   */
  toggleFilters() {
    this.filtersVisible = !this.filtersVisible;
  }

  /**
   * Действие при изменении фильтра "Статус"
   *
   * @param {IIdeaStatus} status новый статус
   * @memberof GroupIdeaListComponent
   */
  onStatusChanged(status: IIdeaStatus) {
    this.currentStatus = status;
    this.cleanTab(this.currentTab);
    this.currentTab.method(this.currentTab, this);
  }

  /**
   * Получить все идеи группы
   *
   * @private
   * @param {Tab<IIdea>} tab
   * @memberof GroupIdeaListComponent
   */
  private getGroupIdeas(tab: Tab<IIdea>) {
    const statusId = this.currentStatus ? this.currentStatus.id : 0;

    this.ideasService
      .getGroupIdeas(this.group.id, statusId, IdeasListType.AllOrMy, tab.offset, this.limit)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        res => {
          if (res) {
            res.forEach(item => {
              if (!tab.items.find(idea => idea.id === item.id)) {
                tab.items.push(item);
              }
            });
            tab.offset = tab.items.length;
          }
          tab.itemsCount = tab.items.length;
          this.loaded = tab.loaded = true;
        },
        error => {
          this.loaded = tab.loaded = true;
        },
      );
  }

  /**
   * Получить все обпуликованные идеи группы
   *
   * @private
   * @param {Tab<IIdea>} tab
   * @memberof GroupIdeaListComponent
   */
  private getGroupPublishedIdeas(tab: Tab<IIdea>) {
    this.getAdditionalTabIdeas(tab, 0, IdeasListType.NewOrOther);
  }

  /**
   * Получить все новые идеи группы
   *
   * @private
   * @param {Tab<IIdea>} tab
   * @memberof GroupIdeaListComponent
   */
  private getGroupNewIdeas(tab: Tab<IIdea>) {
    const statusId = this.currentStatus ? this.currentStatus.id : 0;
    this.getAdditionalTabIdeas(tab, statusId, IdeasListType.NewOrOther);
  }

  /**
   * Получить данные для дополнительных вкладок
   *
   * @private
   * @param {Tab<IIdea>} tab
   * @param {number} statusId Ид статуса
   * @param {IdeasListType} listType Тип списка
   * @memberof GroupIdeaListComponent
   */
  private getAdditionalTabIdeas(tab: Tab<IIdea>, statusId: number, listType: IdeasListType) {
    this.ideasService
      .getGroupIdeas(this.group.id, statusId, listType, tab.offset, this.limit)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        res => {
          if (res) {
            res.forEach(item => {
              if (!tab.items.find(idea => idea.id === item.id)) {
                tab.items.push(item);
              }
            });
            tab.offset = tab.items.length;
          }
          this.loaded = tab.loaded = true;
          tab.itemsCount = tab.items.length;
        },
        error => {
          this.loaded = tab.loaded = true;
        },
      );
  }
}
