import { Component, Input } from '@angular/core';
import { User } from '@app/profile/model/user.model';
import { BaseResizableComponent } from '@app/core/components/base-resizable.component';
import { UsersService } from '@app/profile/services/users.service';
import { HelperService } from '@app/core/services/helper.service';

@Component({
  selector: 'app-user-absence',
  templateUrl: './user-absence.component.html',
  styleUrls: ['./user-absence.component.scss']
})
export class UserAbsenceComponent extends BaseResizableComponent {

  @Input() user: User;
  @Input() isLightMode: boolean;

  constructor(
    public usersService: UsersService,
    public helper: HelperService) { super(helper); }
}
