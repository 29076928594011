import { Component, OnInit, OnDestroy } from '@angular/core';
import { SurveysService } from '@app/surveys/services/surveys.service';
import { take, takeUntil } from 'rxjs/operators';
import { AlertsService } from '@app/shared/services/alerts.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SurveyNewComponent } from './survey-new.component';
import { GroupsService } from '@app/groups/services/groups.service';
import { HelperService } from '@app/core/services/helper.service';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { MatDialog } from '@angular/material/dialog';
import { UsersService } from '@app/profile/services/users.service';

/**
 * Компонент создания нового опроса в группе
 *
 * @export
 * @class SurveyNewComponent
 * @implements OnInit, OnDestroy
 */
@Component({
  selector: 'app-group-survey-new',
  templateUrl: './survey-new.component.html',
  styleUrls: ['./survey-new.component.scss']
})
export class GroupSurveyNewComponent extends SurveyNewComponent implements OnInit, OnDestroy {

  showScope = false;

  constructor(
    public groupsService: GroupsService,
    public usersService: UsersService,
    public surveysService: SurveysService,
    protected alertsService: AlertsService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected breadcrumbsService: BreadcrumbsService,
    protected dialog: MatDialog,
    public helper: HelperService
  ) {
    super(usersService, surveysService, alertsService, route, router, breadcrumbsService, dialog, helper);
    this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
      if (group) {
        this.survey.ownerId = group.id;
        const surveysRoute = `/group/${group.id}/surveys`;

        this.route.fragment.pipe(take(1)).subscribe(fragment => {
          // для определения типа опроса - "тест"
          this.isTest = fragment && fragment.indexOf('test') !== -1;

          if (this.isTest) {
            this.placeholderSuffix = 'теста';
            this.placeholderPrefix = 'Тест';
          }

          this.breadcrumbsService.breadcrumbs.next([
            { title: 'Опросы', routerUrl: surveysRoute },
            { title: this.isTest ? 'Новый тест' : 'Новый опрос', routerUrl: surveysRoute + '/new' }
          ]);

          this.loaded = true;
        });
      }
    });
  }
}
