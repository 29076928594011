import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, Inject } from '@angular/core';
import { AddUsersBaseComponent } from '@app/shared/components/add-users-base/add-users-base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '@app/profile/services/users.service';
import { User } from '@app/profile/model/user.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelperService } from '@app/core/services/helper.service';

@Component({
  selector: 'app-add-users',
  templateUrl: './add-users.component.html'
})
export class AddUsersComponent extends AddUsersBaseComponent implements OnInit, OnDestroy {
  @Input() placeholder: string;

  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  @Output() onOk: EventEmitter<User[]> = new EventEmitter();

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public usersService: UsersService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public helper: HelperService
  ) { super(route, router, usersService, helper); }

  ngOnInit() {
    super.ngOnInit();
    this.placeholder = this.data.placeholder;
    if (this.data.onOk) {
      this.onOk = this.data.onOk;
    }
    if (this.data.onCancel) {
      this.onCancel = this.data.onCancel;
    }
  }
}
