import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchBoxComponent } from '@app/search/components/search-box/search-box.component';
import { SearchResultsComponent } from '@app/search/components/search-results/search-results.component';
import { SearchService } from './services/search.service';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FilesModule } from '@app/files/files.module';
import { GroupsModule } from '@app/groups/groups.module';
import { FeedModule } from '@app/feed/feed.module';
import { GalleryModule } from '@app/gallery/gallery.module';
import { PagesModule } from '@app/pages/pages.module';
import { SearchSharedModule } from './shared/search-shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    FilesModule,
    FeedModule,
    GroupsModule,
    GalleryModule,
    PagesModule,
    SearchSharedModule,
    SharedModule
  ],
  declarations: [
    SearchBoxComponent,
    SearchResultsComponent
  ],
  exports: [
    SearchBoxComponent,
    SearchResultsComponent,
    SearchSharedModule
  ],
  providers: [
    SearchService
  ]
})
export class SearchModule { }
