import {
  Component,
  OnInit,
  Type,
  OnDestroy,
  Input,
  ViewChild,
  HostListener,
  ComponentFactoryResolver,
  ViewContainerRef
} from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { User, UserType } from '@app/profile/model/user.model';
import { trigger, state, animate, transition, style } from '@angular/animations';
import { GalleryWidgetComponent } from '@app/gallery/components/gallery-widget/gallery-widget.component';
import { HelperService } from '@app/core/services/helper.service';
import { UsersService } from '@app/profile/services/users.service';
import { FilesWidgetSmallComponent } from '@app/files/components/files-widget-small/files-widget-small.component';
import { GroupsWidgetSmallComponent } from '@app/groups/components/groups-widget-small/groups-widget-small.component';
import { UserVacancyWidgetComponent } from '@app/profile/components/vacancy/user-vacancy-widget/user-vacancy-widget.component';
import { NewEmployeesWidgetComponent } from '@app/employees/components/new-employees-widget/new-employees-widget.component';
// tslint:disable-next-line:max-line-length
import { UserSurveysWidgetSmallComponent } from '@app/profile/components/surveys/user-surveys-widget-small/user-surveys-widget-small.component';
import { ColleaguesWidgetSmallComponent } from '@app/subscribe/components/colleagues-widget-small/colleagues-widget-small.component';
import { FilesService } from '@app/files/services/files.service';
import { IntryAuthService } from '@app/auth/shared/services/intry-auth.service';

@Component({
  selector: 'app-main-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.scss'],
  animations: [
    trigger('visibilityChanged', [
      state('true', style({ opacity: 1, display: 'inherit' })),
      state('false', style({ opacity: 0, display: 'none' })),
      transition('* => *', animate('.5s'))
    ])
  ]
})
export class MainViewComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() goTopVisible = false;

  currentUser: User;

  /**
   * Контейнер для динамического добавления больших виджетов на страницу
   */
  @ViewChild('bigDynamicContainer', { read: ViewContainerRef, static: true }) bigDynamicContainer: ViewContainerRef;
  /**
   * Контейнер для динамического добавления маленьких виджетов на страницу
   */
  @ViewChild('smallDynamicContainer', { read: ViewContainerRef, static: true }) smallDynamicContainer: ViewContainerRef;

  constructor(
    // private authService: IntryAuthService,
    private usersService: UsersService,
    private filesService: FilesService,
    protected componentFactoryResolver: ComponentFactoryResolver,
    public helper: HelperService) {
    super(helper);
    // this.authService.authCallbackIfRequired();
  }

  ngOnInit() {

    this.usersService.currentUser.subscribe(currentUser => {

      this.currentUser = currentUser;
      this.usersService.user.next(currentUser);

      // очищаем текущий список виджетов
      // this.bigDynamicContainer.viewContainerRef.clear();
      // this.smallDynamicContainer.viewContainerRef.clear();

      // добавление виджетов
      this.addComponent(NewEmployeesWidgetComponent, this.bigDynamicContainer);

      // виджет вакансий отображаем только у текущего пользователя
      this.addComponent(UserVacancyWidgetComponent, this.bigDynamicContainer);
      this.addComponent(GalleryWidgetComponent, this.bigDynamicContainer);

      if (this.currentUser.userType !== UserType.local) {
        this.filesService.showUserFiles$.subscribe(showFiles => {
          if (showFiles) {
            this.addComponent(FilesWidgetSmallComponent, this.smallDynamicContainer);
          }
        });
      }
      this.addComponent(ColleaguesWidgetSmallComponent, this.smallDynamicContainer);

      // виджет опросов отображаем только у текущего пользователя
      this.addComponent(UserSurveysWidgetSmallComponent, this.smallDynamicContainer);
      this.addComponent(GroupsWidgetSmallComponent, this.smallDynamicContainer);

      this.error = '';
      this.loaded = true;
    }, error => {
      this.error = error;
      this.loaded = true;
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.goTopVisible = window.pageYOffset > window.innerHeight / 2;
  }

  scrollTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  private addComponent(type: Type<any>, container: ViewContainerRef) {
    container.createComponent(this.componentFactoryResolver.resolveComponentFactory(type));
  }
}
