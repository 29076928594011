import { Component } from '@angular/core';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { UsersService } from '@app/profile/services/users.service';
import { User } from '@app/profile/model/user.model';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss']
})
export class AdminPageComponent {

  currentUser: User;

  constructor(
    protected breadcrumbsService: BreadcrumbsService,
    public usersService: UsersService
  ) {
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;
    });
    this.breadcrumbsService.breadcrumbs.next(null);
  }

  isIdeasAdmin() {
    return this.currentUser && (this.currentUser.isAdmin || this.currentUser.isIdeasAdmin);
  }

  isVacancyAdmin() {
    return this.currentUser && (this.currentUser.isAdmin || this.currentUser.isVacancyAdmin);
  }
}
