import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { HelperService } from '@app/core/services/helper.service';
import { BaseComponent } from '@app/core/components/base.component';
import { UsersService } from '@app/profile/services/users.service';
import { CalendarService } from '@app/calendar/services/calendar.service';
import { User } from '@app/profile/model/user.model';
import { take, finalize, takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

export interface IUserBirthdaySlide {
  users: User[];
}

@Component({
  selector: 'app-birthdays-widget',
  templateUrl: './birthdays-widget.component.html',
  styleUrls: ['./birthdays-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BirthdaysWidgetComponent extends BaseComponent implements OnInit, OnDestroy {

  currentUser: User;
  users: User[];
  slides: Array<User[]> = [];

  constructor(
    public usersService: UsersService,
    public calendarService: CalendarService,
    private datePipe: DatePipe,
    protected cdr: ChangeDetectorRef,
    public helper: HelperService) {
    super(helper);
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;
    });
  }

  ngOnInit() {
    const dateStart = new Date();
    this.calendarService.GetMainBirthdays(dateStart.toDateString(), 0, 10)
      .pipe(
        take(1),
        finalize(() => {
          this.loaded = true;
          this.cdr.detectChanges();
        }),
        takeUntil(this.destroyed$))
      .subscribe(res => {
        if (res && res.length) {
          this.users = res;
          this.slides = this.users.reduce((rows, key, index) => (index % 2 === 0 ? rows.push([key])
            : rows[rows.length - 1].push(key)) && rows, []);
        } else {
          this.users = [];
          this.slides = [];
        }
      });
  }

  getDateText(user: User) {
    if (!user.birthdayText) {
      try {
        const birthday = new Date(user.birthday);
        const today = new Date();
        birthday.setFullYear(today.getFullYear());
        if (today.toDateString() === birthday.toDateString()) {
          user.birthdayText = 'Сегодня, ';
        } else {
          user.birthdayText = '';
        }

        user.birthdayText += this.datePipe.transform(birthday, 'd MMMM', 'ru');
      } catch (e) {
        console.log(`error on getDateText=`, e);
      }
    }
    return user.birthdayText;
  }

  congratulate(user: User) {
    this.usersService.navigateProfile(user.id, null, 'congratulate', true);
  }
}
