import { Injectable } from '@angular/core';
import { IntryAutoLoginGuard } from './intry-auto-login.guard';

@Injectable({ providedIn: 'root' })
/**
 * @deprecated
 * @see IntryAuthGuard
 */
export class IntryAuthGuard extends IntryAutoLoginGuard {

}
