import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageNewComponent } from '../page-new/page-new.component';
import { PagesService } from '@app/pages/services/pages.service';
import { AlertsService } from '@app/shared/services/alerts.service';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil, switchMap, take } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { IUpdatePage, IPage } from '@app/pages/model/page';
import { UsersService } from '@app/profile/services/users.service';
import { User } from '@app/profile/model/user.model';

/**
 * Форма редактирования страницы
 *
 * @export
 * @class PageEditComponent
 * @extends {PageNewComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-page-edit',
  templateUrl: './page-edit.component.html',
  styleUrls: ['./page-edit.component.scss']
})
export class PageEditComponent extends PageNewComponent implements OnInit, OnDestroy {

  page: IUpdatePage = {
    id: null,
    title: '',
    body: ''
  };

  originPage: IPage;

  rawHtml = false;
  currentUser: User;

  constructor(
    public pagesService: PagesService,
    protected alertsService: AlertsService,
    protected breadcrumbsService: BreadcrumbsService,
    protected usersService: UsersService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected dialog: MatDialog,
    public helper: HelperService
  ) {
    super(pagesService, alertsService, breadcrumbsService, route, router, dialog, helper);
  }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;

      if (!currentUser.isAdmin) {
        return this.router.navigate(['/403'], { skipLocationChange: true });
      }

      this.route.params
        .pipe(takeUntil(this.destroyed$), switchMap((params: Params) => {
          this.page = null;
          const pageId = +params['pageId'];
          return this.pagesService.getById(pageId);
        }))
        .subscribe(page => {
          this.originPage = page;
          if (page) {
            this.page = {
              id: page.id,
              body: page.body,
              title: page.title
            };
            this.setBreadcrumbs();
          } else {
            this.router.navigate(['/404'], { skipLocationChange: true });
          }

          this.error = '';
          this.loaded = true;
        }, error => {
          this.error = error;
          this.loaded = true;
        });
    });
  }

  /**
   * Отправить данные формы на сервер
   *
   * @private
   * @memberof SurveyNewComponent
   */
  protected send() {
    if (this.isValid()) {

      this.sending = true;
      this.page.tags = this.tagsList.tags.map(s => s.id);
      this.pagesService.updatePage(this.page.id, this.page).pipe(take(1)).subscribe(res => {
        this.sending = false;
        if (this.page.published) {
          this.alertsService.riseSuccess(`Страница '${this.page.title}' была успешно опубликована`);
          this.router.navigate([`../../${this.page.id}`], { relativeTo: this.route });
        } else {
          this.router.navigate([`../edit`], { relativeTo: this.route, fragment: 'edited' });
          this.alertsService.riseSuccess(`Страница '${this.page.title}' была успешно сохранена`);
        }
      }, error => {
        this.sending = false;
        this.alertsService.riseError(`Произошла ошибка при создании страницы '${this.page.title}'`);
      });
    }
  }

  protected setBreadcrumbs() { }

  protected selectRawHtml(e: Event) {
    this.rawHtml = !this.rawHtml;
  }

}
