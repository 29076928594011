import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LikeComponent } from '@app/likes/components/like/like.component';
import { LikesService } from '@app/likes/services/likes.service';
import { SharedModule } from '@app/shared/shared.module';
import { LikesListComponent } from './components/likes-list/likes-list.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule
  ],
  declarations: [
    LikeComponent,
    LikesListComponent
  ],
  exports: [
    LikeComponent
  ],
  providers: [
    LikesService
  ]
})
export class LikesModule { }
