<div class="form cos__body" *ngIf="!loaded">
  <span class="preloader _square _co" *ngFor="let r of [0,1,2,3,4,5]">
    <span class="preloader__side"></span>
    <span class="preloader__line"></span>
  </span>
</div>

<div class="form cos__body" *ngIf="loaded">
    <div cdkDropList (cdkDropListDropped)="drop($event)">
      <div cdkDrag *ngFor="let gamificationType of gamificationTypes; let i=index">
        <div class="form__line draggable-line">
          <div class="draggable-line__content">
            <button type="button" class="btn btn-drag draggable-line__icon"></button>
            <label class="checkbox _bigger">
                <input type="checkbox" name="search-dir" class="checkbox__input" [checked]="!gamificationType.deleted"
                    (change)="toggle($event, gamificationType)">
                <span class="checkbox__icon"></span>
                <span class="checkbox__text">{{gamificationType.title}}</span>
            </label>
          </div>
          <div class="form__line _secondary">
            <label class="form__line-label">Балл за действие</label>
            <div class="input-main _inline _smaller">
                <input placeholder="от 1 балла" [(ngModel)]="gamificationType.value" type="number" min="1" class="input-main__field" (keyup)="onBlur($event, gamificationType)" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form__actions">
      <button type="button" class="btn btn-simple" (click)="cancel()">Отмена</button>
      <button type="button" [attr.disabled]="isValid()?null:''" class="btn btn-primary"
        (click)="submit()">Сохранить</button>
    </div>
  </div>
