import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { IAlbum } from '@app/gallery/model/album-model';
import { User } from '@app/profile/model/user.model';
import { Subject } from 'rxjs';
import { UsersService } from '@app/profile/services/users.service';
import { GalleryService } from '@app/gallery/services/gallery.service';
import { HelperService } from '@app/core/services/helper.service';
import { takeUntil, take } from 'rxjs/operators';
import { ProfileType } from '@app/core/model/profile-type.enum';

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss']
})
export class UserHeaderComponent extends BaseComponent implements OnInit, OnDestroy {
  album: IAlbum;
  user: User;

  constructor(
    protected usersService: UsersService,
    protected galleryService: GalleryService,
    public helper: HelperService
  ) { super(helper); }

  ngOnInit() {
    this.usersService.user.pipe(takeUntil(this.destroyed$)).subscribe(user => {
      this.user = user;

      this.galleryService.currentOwner.next({ ownerType: ProfileType.User, owner: this.user});

      if (user) {
        this.galleryService.currentAlbum.pipe(take(1), takeUntil(this.destroyed$)).subscribe(album => {
          if (album) {
            this.album = album;
          } else {
            this.album = null;
          }
        });

        this.loaded = true;
      }
    });
  }
}
