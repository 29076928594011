import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateSpLike'
})
export class DateSpLikePipe implements PipeTransform {

  transform(value: any, local?: boolean): string {
    if (!value) {
      return null;
    }

    let date = moment(value);

    if (!date.isValid()) {
      return null;
    }

    // для правильного отображения дат utc
    if (local) {
      date = date.utc(true).local();
    }

    if (date > moment().add(-5, 'm')) {
      return 'Только что';
    }

    if (moment(date).startOf('day').isSame(moment().startOf('day'))) {
      return date.format('Сегодня в HH:mm');
    }

    if (moment(date).startOf('day').isSame(moment().add(-1, 'days').startOf('day'))) {
      return date.format('Вчера в HH:mm');
    }

    return date.format('DD.MM.YY HH:mm');
  }
}
