import { Component, OnInit, Inject, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IMediaFile } from '@app/gallery/model/media-file';
import { IAlbum } from '@app/gallery/model/album-model';
import { IMediaFileSelect } from '../media-list/media-list.component';
import { GroupsService } from '@app/groups/services/groups.service';
import { GalleryService } from '@app/gallery/services/gallery.service';
import { UsersService } from '@app/profile/services/users.service';
import { ProfileType } from '@app/core/model/profile-type.enum';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';

export enum GalleryTreeType {
  selectMediaFiles = 1,
  selectAlbum = 2
}

export enum GalleryTreeMode {
  myGallery = 1,
  groupGallery = 2
}

export interface IGalleryTreeData {
  headerText?: string;
  okText?: string;
  cancelText?: string;
  error?: string;
  onOk?: any;
  doNotHideOnOk?: boolean;
  doNotSelectMediaFile?: boolean;
  type: GalleryTreeType;
  mode: GalleryTreeMode;
}

/**
 * Компонент дерева галереи
 *
 * @export
 * @class GalleryTreeComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-gallery-tree',
  templateUrl: './gallery-tree.component.html',
  styleUrls: ['./gallery-tree.component.scss']
})
export class GalleryTreeComponent extends BaseComponent implements OnDestroy {

  /**
   * Выбранные медиафайлы
   *
   * @type {IMediaFile[]}
   * @memberof GalleryTreeComponent
   */
  selectedMediaFiles: IMediaFile[] = [];

  /**
   * Текущий выбранный альбом
   *
   * @type {IAlbum}
   * @memberof GalleryTreeComponent
   */
  currentAlbum: IAlbum;

  galleryTreeMode: GalleryTreeMode;

  text = '';
  foundAlbums: IAlbum[];

  constructor(
    public groupsService: GroupsService,
    public usersService: UsersService,
    public galleryService: GalleryService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: IGalleryTreeData,
    public helper: HelperService,
  ) {
    super(helper);

    // устанавливаем режим отображения
    if (data && data.mode) {
      this.galleryTreeMode = data.mode;
    }

    this.init();
  }

  private init() {
    switch (this.galleryTreeMode) {
      case GalleryTreeMode.groupGallery:
        this.groupGallery(true);
        break;
      case GalleryTreeMode.myGallery:
      default:
        this.myGallery(true);
        break;
    }
  }

  /**
   * Действие при выборе альбома
   *
   * @param {IAlbum} album
   * @memberof GalleryTreeComponent
   */
  onAlbumSelect(album: IAlbum) {
    if (album) {
      this.currentAlbum = album;
    }
  }

  /**
   * Действие при выборе медиа-файла
   *
   * @param {IMediaFileSelect} e
   * @memberof GalleryTreeComponent
   */
  onMediaFileSelect(e: IMediaFileSelect) {
    if (e && e.mediaFile) {
      if (e.selected) {
        if (!this.selectedMediaFiles.find(mediaFile => mediaFile.id === e.mediaFile.id)) {
          this.selectedMediaFiles.push(e.mediaFile);
        }
      } else {
        this.selectedMediaFiles = this.selectedMediaFiles.filter(mediaFile => mediaFile.id !== e.mediaFile.id);
      }
    }
  }

  /**
   * Операция на нажатие ОК
   *
   * @memberof GalleryTreeComponent
   */
  ok() {
    let result: IMediaFile[] | IAlbum;

    switch (this.data.type) {
      case GalleryTreeType.selectAlbum:
        result = this.currentAlbum;
        break;
      case GalleryTreeType.selectMediaFiles:
        result = this.selectedMediaFiles;
        break;
    }

    if (this.data.onOk instanceof EventEmitter) {
      this.data.onOk.emit(result);
    } else {
      this.data.onOk(result);
    }
  }

  disabledOk() {
    switch (this.data.type) {
      case GalleryTreeType.selectAlbum:
        return this.currentAlbum ? null : '';
      case GalleryTreeType.selectMediaFiles:
        return this.selectedMediaFiles && this.selectedMediaFiles.length ? null : '';
    }
    return '';
  }

  /**
   * Отобразить мою галерею
   *
   * @returns
   * @memberof GalleryTreeComponent
   */
  myGallery(force: boolean = false) {
    if (this.galleryTreeMode === GalleryTreeMode.myGallery && !force) {
      return;
    }
    this.galleryTreeMode = GalleryTreeMode.myGallery;
    this.currentAlbum = null;
    this.usersService.currentUser.subscribe(currentUser => {
      this.galleryService.currentOwner.next({ ownerType: ProfileType.User, owner: currentUser });
    });
  }

  /**
   * Отобразить галерею группы
   *
   * @returns
   * @memberof GalleryTreeComponent
   */
  groupGallery(force: boolean = false) {
    if (this.galleryTreeMode === GalleryTreeMode.groupGallery && !force) {
      return;
    }
    this.galleryTreeMode = GalleryTreeMode.groupGallery;
    this.currentAlbum = null;
    const group = this.groupsService.selectedGroup.getValue();
    if (group && group.id) {
      this.galleryService.currentOwner.next({ ownerType: ProfileType.Group, owner: group });
    }
  }

  /**
   * При выполнении поиска по альбомам
   *
   * @param {*} e
   * @returns
   * @memberof AttachmentsTreeComponent
   */
  onSearch(e) {
    // ESC
    if (e.keyCode === 27) {
      this.text = '';
      e.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    // ENTER
    if (e.keyCode === 13) {
      if (!this.text) {
        this.init();
      } else if (this.text.length > 2) {
        const currentOwner = this.galleryService.currentOwner.getValue();
        if (currentOwner && currentOwner.owner) {
          this.galleryService.searchAlbums(this.text, currentOwner.owner.id, 0, 4).pipe(takeUntil(this.destroyed$))
            .subscribe(res => {
              if (res) {
                this.foundAlbums = res;
              } else {
                this.foundAlbums = [];
              }
            });
        }
      }
    }
  }

  /**
   * При отмене
   *
   * @memberof GalleryTreeComponent
   */
  cancel() {
    console.log(`on cancel`);
  }
}
