import { Component, OnInit } from '@angular/core';
import { FeedbackService } from '@app/feedback/services/feedback.service';
import { take, finalize, takeUntil } from 'rxjs/operators';
import { IFeedbackCategory } from '@app/feedback/model/feedback';
import { AlertsService } from '@app/shared/services/alerts.service';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { Router } from '@angular/router';

/**
 * Компонент обратной связи
 *
 * @export
 * @class FeedbackComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent extends BaseComponent implements OnInit {
  /**
   * Все категории вопросов
   *
   * @type {IFeedbackCategory[]}
   * @memberof FeedbackComponent
   */
  categories: IFeedbackCategory[];

  /**
   * Текущая выбранная категория
   *
   * @type {IFeedbackCategory}
   * @memberof FeedbackComponent
   */
  category: IFeedbackCategory;

  /**
   * Вопрос
   *
   * @type {string}
   * @memberof FeedbackComponent
   */
  feedback: string;

  constructor(
    private feedbackService: FeedbackService,
    private alertsService: AlertsService,
    public helper: HelperService,
    private router: Router,
  ) {
    super(helper);
  }

  ngOnInit() {
    this.feedbackService
      .getCategories()
      .pipe(
        finalize(() => (this.loaded = true)),
        takeUntil(this.destroyed$),
      )
      .subscribe(categories => {
        this.categories = categories;
      });
  }

  /**
   * Отправить данные формы
   *
   * @memberof FeedbackComponent
   */
  submit() {
    if (this.isValid()) {
      this.loading = true;
      this.feedbackService
        .send({ categoryId: this.category.id, feedback: this.feedback })
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          res => {
            if (res) {
              this.alertsService.riseSuccess('Ваш вопрос был успешно отправлен.');
              this.router.navigate(['']);
            } else {
              this.alertsService.riseError(
                'Произошла ошибка при отправке формы. Обратитесь к администратору.',
              );
            }
            this.loading = false;
          },
          error => {
            this.alertsService.riseError(
              'Произошла ошибка при отправке формы. Обратитесь к администратору.',
            );
            this.loading = false;
          },
        );
    } else {
      this.alertsService.riseError(
        'Произошла ошибка при отправке формы. Не заполнены обязательные поля',
      );
    }
  }

  /**
   * Установить выбранную категорию
   *
   * @param {IFeedbackCategory} category
   * @memberof FeedbackComponent
   */
  setCategory(category: IFeedbackCategory) {
    this.category = category;
  }

  /**
   * Форма валидна для отправки?
   *
   * @returns {boolean}
   * @memberof FeedbackComponent
   */
  isValid(): boolean {
    return this.feedback && this.category ? true : false;
  }
}
