<div class="post" [ngClass]="{'_edit': isEdit}">
  <ng-container *ngIf="isEdit; then edit else display"></ng-container>
  <ng-template #edit>
    <div class="new-comment__main">
      <app-user-avatar *ngIf="comment.author" [user]="comment.author" [linkClass]="'avatar _30'" [imageClass]="'avatar__image'"></app-user-avatar>
      <app-froala-inline-editor
          class="post__editor"
          [focusOnInit]="false"
          [(editorModel)]="text"
          [placeholder]="'Написать комментарий'"
          [(text)]="text"
          [(mentions)]="mentionsEdit"
      ></app-froala-inline-editor>
    </div>
    <app-attachments-list #attachmentsList [attachments]="attachments" [isEdit]="true" (onFilesUploaded)="onFilesUploaded($event)"
      (onRemove)="onRemoveAttachment($event)" [ownerId]="ownerId" [elementType]="elementType"></app-attachments-list>
    <app-media-attachments-list #mediaAttachmentsList [mediaAttachments]="mediaAttachments" [isEdit]="true" (onRemove)="onRemoveMediaAttachment($event)"
      (onFilesUploaded)="onMediaFilesUploaded($event)" [ownerId]="ownerId" [ownerType]="ownerType" [elementType]="elementType"></app-media-attachments-list>
    <div class="post__actions" [ngClass]="{'loading _blue': isBusy}">
      <app-attachments-menu (choose)="attachmentsList.onChooseFile($event)" (upload)="attachmentsList.onUploadFile($event)"></app-attachments-menu>
      <app-media-attachments-menu (choose)="mediaAttachmentsList.onChooseFile($event)" (upload)="mediaAttachmentsList.onUploadFile($event)"></app-media-attachments-menu>
      <div class="post__submit">
        <button type="button" class="btn btn-simple" (click)="cancel()">Отмена</button>
        <button type="button" class="btn btn-primary" (click)="submit()">Опубликовать</button>
      </div>
    </div>
  </ng-template>
  <ng-template #display>
    <div class="post__author">
      <app-comment-menu [comment]="comment" [config]="config" (onRemove)="remove()" (onEdit)="onEdit()" [elementAuthorId]="elementAuthorId"></app-comment-menu>
      <a routerLink="/profile/{{comment.author.id}}" class="link-profile">
        <app-user-avatar [user]="comment.author" [linkClass]="'avatar _30'" [imageClass]="'avatar__image'" [asSpan]="true"></app-user-avatar>
        <span class="link-profile__text" [appUserTooltip]="comment.author?.id">{{comment.author.fullName}}</span>
        <span class="link-profile__subtext">{{comment.comment.created | dateSpLike:true }}</span>
      </a>
    </div>
    <app-message [showBody]="showBody" [text]="comment.comment.body" [mentions]="mentions" [highlightText]="highlightText"></app-message>
    <app-attachments-list [attachments]="comment?.attachments" [isEdit]="false" [elementType]="elementType"></app-attachments-list>
    <app-media-attachments-list [mediaAttachments]="comment.mediaAttachments" [isEdit]="false" [elementType]="elementType"></app-media-attachments-list>
    <div class="post__actions">
      <app-like [count]="comment.likes.count" [canLike]="comment.likes.canLike" [elementId]="comment.comment.id"
        [elementType]="elementType"></app-like>
      <a href="javascript:;" class="link" (click)="reply()">Ответить</a>
    </div>
  </ng-template>
</div>
