import { Component, OnInit, Input, Output, EventEmitter, ViewChild, forwardRef } from '@angular/core';
import { User } from '@app/profile/model/user.model';
import { AttachmentViewModel } from '@app/attachments/file-attachments/model/attachment-view-model.model';
import { CommentsService } from '@app/comments/services/comments.service';
import { CommentViewModel } from '@app/comments/model/comment-view-model.model';
import { LikesService } from '@app/likes/services/likes.service';
import { ElementType } from '@app/core/model/element-type.enum';
import { AttachmentsListComponent } from '@app/attachments/file-attachments/components/attachments-list/attachments-list.component';
import { ShareableObject } from '@app/files/model/shareable-object.model';
import { ICommentReply } from '@app/comments/components/comments-list/comments-list.component';
import { BaseComponent } from '@app/core/components/base.component';
import { IMediaFile } from '@app/gallery/model/media-file';
import { MediaAttachmentViewModel } from '@app/attachments/media-attachments/model/media-attachment-view-model';
// tslint:disable-next-line:max-line-length
import { MediaAttachmentsListComponent } from '@app/attachments/media-attachments/components/media-attachments-list/media-attachments-list.component';
import { take } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { AlertsService } from '@app/shared/services/alerts.service';
import { ICommentMenu } from '@app/comments/model/comment.model';
import { IDatafileModel } from '@app/files/model/data-file-model';

/**
 * Компоннент отображения и редактирвоания комментария
 *
 * @export
 * @class CommentComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html'
})
export class CommentComponent extends BaseComponent implements OnInit {

  /**
   * Автор элемента, к которому был создан комментарий.
   * Необходим для того, чтобы можно было определить права на удаление комментария.
   *
   * @type {number}
   * @memberof CommentComponent
   */
  @Input() elementAuthorId: number;

  @Input() config: ICommentMenu;

  /**
   * Комментарий для отображения/редактирования
   *
   * @type {CommentViewModel}
   * @memberof CommentComponent
   */
  @Input() comment: CommentViewModel;

  /**
   * Выделенный текст поиска
   *
   * @type {string}
   * @memberof CommentComponent
   */
  @Input() highlightText: string;

  @Input() showBody: boolean;

  /**
   * Действие при нажатии кнопки "Ответить"
   *
   * @type {EventEmitter<ICommentReply>}
   * @memberof CommentComponent
   */
  @Output() onReply: EventEmitter<ICommentReply> = new EventEmitter();

  /**
   * Действие при нажатии кнопки "Удалить"
   */
  @Output() onRemove: EventEmitter<CommentViewModel> = new EventEmitter();

  /**
   * Компонент списка вложений
   *
   * @private
   * @type {AttachmentsListComponent}
   * @memberof CommentComponent
   */
  @ViewChild(forwardRef(() => AttachmentsListComponent)) private attachmentsList: AttachmentsListComponent;

  /**
   * Компонент списка медиа вложений
   *
   * @protected
   * @type {MediaAttachmentsListComponent}
   * @memberof CommentComponent
   */
  @ViewChild(forwardRef(() => MediaAttachmentsListComponent)) protected mediaAttachmentsList: MediaAttachmentsListComponent;

  isEdit = false;
  attachments: AttachmentViewModel[] = [];
  mediaAttachments: MediaAttachmentViewModel[] = [];
  text: string;
  posting = false;
  uploadFiles = false;
  uploadMediaFiles = false;

  elementType: ElementType = ElementType.Comment;

  ownerId: number;

  mentions: User[] = [];
  mentionsEdit: User[] = [];

  constructor(
    private commentsService: CommentsService,
    private likesService: LikesService,
    protected alertsService: AlertsService,
    public helper: HelperService) {
    super(helper);
  }

  ngOnInit() {
    this.ownerId = this.comment.comment.userID;
    this.mentions = (this.comment.mentions || []).map(item => item.user);
  }

  /**
   * При нажатии "Редактировать" в меню
   *
   * @memberof CommentComponent
   */
  onEdit() {
    // тело комментария
    this.text = this.comment.comment.body;

    // вложения комментария
    this.attachments = [];
    if (this.comment.attachments) {
      this.comment.attachments.forEach(o => this.attachments.push(o));
    }

    // медиа вложения
    this.mediaAttachments = [];
    this.comment.mediaAttachments.forEach(o => this.mediaAttachments.push(o));

    // упоминания комментария
    this.mentionsEdit = [];
    if (this.mentions) {
      this.mentions.forEach(o => this.mentionsEdit.push(o));
    }
    this.isEdit = true;
  }

  /**
   * Действие при удалении комментария
   *
   * @memberof CommentComponent
   */
  remove() {
    if (!this.posting) {
      this.commentsService.deleteComment(this.comment.comment.id)
        .subscribe(res => {
          if (res) {
            this.onRemove.emit(this.comment);
            this.alertsService.riseSuccess('Комментарий был успешно удалён');
          } else {
            this.alertsService.riseError('Произошла ошибка при удалении комментария');
          }
        }, error => {
          this.alertsService.riseError('Произошла ошибка при удалении комментария');
        });
    }
  }

  /**
   * Ответить на комментарий
   *
   * @memberof CommentComponent
   */
  reply() {
    this.onReply.emit({
      user: this.comment.author,
      parentId: this.comment.comment.id
    });
  }

  /**
   * Отмена внесения изменений
   *
   * @memberof CommentComponent
   */
  cancel() {
    this.isEdit = false;
    this.clear();
  }

  /**
   * On submit button click need to save attachments to server and send data async
   *
   * @memberof CommentComponent
   */
  submit() {
    // тело комментария
    this.comment.comment.body = this.text;
    // вложения комментария
    this.comment.attachments = [];
    this.attachments.forEach(o => this.comment.attachments.push(o));

    // медиа вложения комментария
    this.comment.mediaAttachments = [];
    this.mediaAttachments.forEach(o => this.comment.mediaAttachments.push(o));

    // упоминания комментария
    this.mentions = [];
    this.mentionsEdit.forEach(o => this.mentions.push(o));

    if (this.text && !this.posting
      || (this.attachments && this.attachments.length)
      || (this.attachmentsList && this.attachmentsList.attachmentsToUpload && this.attachmentsList.attachmentsToUpload.length)
      || (this.mediaAttachments && this.mediaAttachments.length)
      || (this.mediaAttachmentsList && this.mediaAttachmentsList.attachmentsToUpload
        && this.mediaAttachmentsList.attachmentsToUpload.length)) {

      this.posting = true;

      if (this.attachmentsList
        && this.attachmentsList.attachmentsToUpload
        && this.attachmentsList.attachmentsToUpload.length) {

        this.uploadFiles = true;
        // upload attached files at first
        this.attachmentsList.uploadAttachedFiles(ElementType.Comment);
      }
      if (this.mediaAttachmentsList
        && this.mediaAttachmentsList.attachmentsToUpload
        && this.mediaAttachmentsList.attachmentsToUpload.length) {

        this.uploadMediaFiles = true;
        // upload attached media files at first
        this.mediaAttachmentsList.uploadAttachedFiles(ElementType.Comment);
      }

      if (!this.uploadFiles && !this.uploadMediaFiles) {
        // send to server
        this.send();
      }
    } else {
      // todo: error empty text
    }
  }

  /**
   * Отправить данные на сервер
   *
   * @private
   * @memberof CommentComponent
   */
  private send() {
    // attachments
    const attachmentsIds = this.attachments
      ? this.attachments.map((att) => att.attachment.guid)
      : null;
    // media attachments
    const mediaAttachmentsIds = this.mediaAttachments
      ? this.mediaAttachments.map((att) => att.mediaAttachment.id)
      : null;
    // mentions
    const mentionsIds = this.mentions
      ? this.mentions.map(item => item.id)
      : null;
    this.commentsService.editComment(this.comment.comment.id, this.text, mentionsIds, attachmentsIds, mediaAttachmentsIds)
      .pipe(take(1))
      .subscribe(res => {
        this.posting = false;
        this.isEdit = false;
        this.clear();
        this.alertsService.riseSuccess('Комментарий изменен.');
      }, error => {
        this.posting = false;
        this.isEdit = false;
        this.clear();
        this.alertsService.riseError('Произошла ошибка при изменении комментария. Попробуйте позже.');
      });
  }

  // TODO: вытащить лайк в отдельный компонент, чтобы не дублировать в комментариях и постах
  like() {
    if (this.comment.likes.canLike) {
      this.likesService.like(this.comment.comment.id, ElementType.Comment)
        .pipe(take(1))
        .subscribe(res => {
          this.comment.likes.count = res.count;
          this.comment.likes.canLike = res.canLike;
        });
    } else {
      this.likesService.unlike(this.comment.comment.id, ElementType.Comment)
        .pipe(take(1))
        .subscribe(res => {
          this.comment.likes.count = res.count;
          this.comment.likes.canLike = res.canLike;
        });
    }
  }

  /**
   * Действие при удалении вложения
   *
   * @param {AttachmentViewModel} attachment
   * @memberof CommentComponent
   */
  onRemoveAttachment(attachment: AttachmentViewModel) {
    this.attachments = this.attachments.filter(att =>
      attachment.attachment.guid && att.attachment.guid !== attachment.attachment.guid
      || !attachment.attachment.guid && att.attachment.title !== attachment.attachment.title);
  }

  /**
   * Действие при завершении загрузки вложений
   *
   * @param IDatafileModel[] event
   * @memberof CommentComponent
   */
   onFilesUploaded(event: IDatafileModel[]) {
    // add uploaded attachments
    this.attachments = this.attachments.filter(att => att.attachment.guid);
    if (event) {
      event.forEach(file => {
        this.attachments.push({
          attachment:
          {
            title: file.name,
            modified: file.created,
            ownerID: file.authorId,
            url: `/api/documentFile/${file.guid}`,
            guid: file.guid
          },
          owner: null // file.author
        });
      });
    }
    this.uploadFiles = false;

    // send to server
    if (!this.uploadFiles && !this.uploadMediaFiles) {
      this.send();
    }
  }

  /**
   * Действие при завершении загрузки медиа вложений
   *
   * @param {IMediaFile[]} event
   * @memberof CommentComponent
   */
  onMediaFilesUploaded(event: IMediaFile[]) {
    // add uploaded attachments
    this.mediaAttachments = this.mediaAttachments.filter(att => att.mediaAttachment.id);
    if (event) {
      event.forEach(mediaFile => {
        this.mediaAttachments.push({
          mediaAttachment:
          {
            id: mediaFile.id,
            title: mediaFile.name,
            url: mediaFile.url,
            previewUrl: mediaFile.previewUrl,
            guid: mediaFile.guid,
            type: mediaFile.type,
          }
        });
      });
    }
    this.uploadMediaFiles = false;

    // send to server
    if (!this.uploadFiles && !this.uploadMediaFiles) {
      this.send();
    }
  }

  /**
   * Очистить блок редактирования комментария
   *
   * @private
   * @memberof CommentComponent
   */
  private clear() {
    this.text = '';
    this.attachments = [];
  }
}
