import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { UserLink } from '@app/subscribe/model/user-link';
import { HttpClient } from '@angular/common/http';
import { SubscribeApiService } from '@app/subscribe/services/subscribe-api.service';
import { HelperService } from '@app/core/services/helper.service';
import { take } from 'rxjs/operators';
import { AlertsService } from '@app/shared/services/alerts.service';
import { User } from '@app/profile/model/user.model';

/**
 * Сервис "Коллег"
 *
 * @export
 * @class SubscribeService
 * @extends {SubscribeApiService}
 */
@Injectable()
export class SubscribeService extends SubscribeApiService {
    public static subscribeErrorMessage = `Произошла ошибка при попытке подписаться на пользователя`;
    public static unsubscribeErrorMessage = `Произошла ошибка при попытке отписаться от пользователя`;

    public currentUserColleaguesCount: Subject<number> = new BehaviorSubject<number>(0);
    public subscribedOnUser$: Subject<number> = new Subject();
    public unsubscribedFromUser$: Subject<number> = new Subject();

    constructor(
        private alertsService: AlertsService,
        protected http: HttpClient,
        public helper: HelperService) { super(http, helper); }

    /**
     * Подписан ли текущий пользователь на пользователя в link.user
     *
     * @param {UserLink} link
     * @returns {boolean}
     * @memberof SubscribeService
     */
    isSubscribed(link: UserLink): boolean {
        if (link.canSubscribe) {
            if (link.unsubscribed === false) {
                return true;
            }
            return false;
        } else {
            if (link.unsubscribed === true) {
                return false;
            }
            return true;
        }
    }

    /**
     * Подписаться на пользователя или отписаться от него
     *
     */
    changeSubscribe(link: UserLink) {
        if (this.isSubscribed(link)) {
            return this.unsubscribe(link.user.id)
                .pipe(take(1))
                .subscribe(res => {
                    if (res) {
                        link.canSubscribe = true;
                        link.unsubscribed = true;
                        this.unsubscribedFromUser$.next(link.user.id);
                        this.alertsService.riseSuccess(`Вы успешно отписались от пользователя`);
                    } else {
                        this.alertsService.riseError(SubscribeService.unsubscribeErrorMessage);
                    }
                }, () => {
                    this.alertsService.riseError(SubscribeService.unsubscribeErrorMessage);
                });
        } else {
            return this.subscribe(link.user.id)
                .pipe(take(1))
                .subscribe(res => {
                    if (res) {
                        link.canSubscribe = false;
                        link.unsubscribed = false;
                        this.subscribedOnUser$.next(link.user.id);
                        this.alertsService.riseSuccess(`Вы успешно подписались на пользователя`);
                    } else {
                        this.alertsService.riseError(SubscribeService.subscribeErrorMessage);
                    }
                }, () => {
                    this.alertsService.riseError(SubscribeService.subscribeErrorMessage);
                });
        }
    }

    changeSubscribeUser(user: User) {
        if (!user.canSubscribe) {
            this.unsubscribe(user.id)
                .pipe(take(1))
                .subscribe(res => {
                    user.canSubscribe = true;
                    this.alertsService.riseSuccess(`Вы успешно отписались от пользователя`);
                }, err => {
                    this.alertsService.riseError(SubscribeService.subscribeErrorMessage);
                });
        } else {
            this.subscribe(user.id)
                .pipe(take(1))
                .subscribe(res => {
                    user.canSubscribe = false;
                    this.alertsService.riseSuccess(`Вы успешно подписались на пользователя`);
                }, err => {
                    this.alertsService.riseError(SubscribeService.subscribeErrorMessage);
                });
        }
    }
}
