import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

  transform(text: string, search: string, fromStart: boolean = false): any {
    if (search && text) {

      if (text) {
        text = text.replace(/<ddd\/>/g, '...');
      }

      let pattern = search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
      pattern = pattern.split(' ').filter((t) => {
        return t.length > 0;
      }).join('|');

      const regex = new RegExp(pattern, 'gi');
      const regexNumber = new RegExp('\\d+(-\\d)*?', 'gm');

      if (fromStart) {
        let match;
        const indexes = [];
        // save start & end indexes for all matches
        while (match = regex.exec(text)) {
          if (match.index > 0 && text[match.index - 1] !== '"' && text[match.index - 1] !== '\'') {
            indexes.push({ start: match.index, end: regex.lastIndex });
          }
        }
        if (indexes) {
          let newText = '';
          let lastIndex = 0;
          indexes.forEach(index => {
            const tmp = text.substr(index.start, index.end - index.start);

            if (lastIndex) {
              // дописываем текст, который был до текущей ссылки
              newText += text.substr(lastIndex, index.start - lastIndex);
            } else {
              newText = text.substr(0, index.start);
            }

            if (index.start === 0 || text[index.start - 1] === ' ') {
              newText += `<span class="highlighted">${tmp}</span>`;
            } else {
              newText += tmp;
            }

            // сохраняем последний символ совпадения
            lastIndex = index.end;
          });

          if (lastIndex < text.length) {
            newText += text.substr(lastIndex);
          }
          text = newText;
        }
      } else {
        text = text.replace(regex, (match) => {
          return `<span class="highlighted">${match}</span>`;
        });
      }

      return text.replace(regexNumber, (match) => {
        if (search.indexOf(match) !== -1) {
          return `<span class="highlighted">${match}</span>`;
        } else {
          return match;
        }
      });
    } else {
      return text;
    }
  }
}
