import { Component } from '@angular/core';
import { IntryAuthService } from '../shared/services/intry-auth.service';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent {
  constructor(
    public auth: IntryAuthService
  ) {
  }
}
