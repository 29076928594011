import { Component } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { Helper } from '@app/core/helpers/helper';
import { Router } from '@angular/router';

/**
 * Компонент для 404 ошибки
 *
 * @export
 * @class PageNotFoundComponent
 * @extends {BaseComponent}
 */
@Component({
    templateUrl: 'page-not-found.component.html'
})

export class PageNotFoundComponent extends BaseComponent {
    constructor(
        public helper: HelperService,
        public router: Router) { super(helper); }

    /**
     * При нажатии на "вернуться назад"
     *
     * @memberof PageNotFoundComponent
     */
    goBack() {
        if (Helper.routes && Helper.routes.length && Helper.routes.length > 1) {
            this.router.navigate([Helper.routes[Helper.routes.length - 2]]);
        } else {
            this.router.navigate(['/']);
        }
    }
}
