import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HelperService} from '@app/core/services/helper.service';
import {FeedApiService} from './feed-api.service';
import {BehaviorSubject} from 'rxjs';
import {IIntryProfile} from '@app/shared/model/intry-profile';

/**
 * Сервис ленты
 *
 * @export
 * @class FeedService
 * @extends {BaseService}
 */
@Injectable()
export class FeedService extends FeedApiService {
  public selectedProfile: BehaviorSubject<IIntryProfile> =
    new BehaviorSubject<IIntryProfile>(null);

  constructor(protected http: HttpClient, public helper: HelperService) {
    super(http, helper);
  }
}
