import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileType } from '@app/core/model/profile-type.enum';
import { HelperService } from '@app/core/services/helper.service';
import { FeedEventViewModel } from '@app/feed/model/feed-event-view-model.model';
import { PostProfileSelectorType } from '@app/feed/model/post-profile-selector-type';
import { FeedService } from '@app/feed/services/feed.service';
import { GroupsService } from '@app/groups/services/groups.service';
import { UsersService } from '@app/profile/services/users.service';
import { GroupPostViewComponent } from '../group-post-view/group-post-view.component';

@Component({
  selector: 'app-group-post-share',
  templateUrl: './group-post-share.component.html',
  styleUrls: ['./group-post-share.component.scss']
})
export class GroupPostShareComponent extends GroupPostViewComponent implements OnInit, OnDestroy {

  selectorType: PostProfileSelectorType = PostProfileSelectorType.all;

  hashFrom: string;

  constructor(
    protected usersService: UsersService,
    protected groupsService: GroupsService,
    protected feedService: FeedService,
    protected route: ActivatedRoute,
    protected router: Router,
    public helper: HelperService
  ) { super(usersService, groupsService, feedService, route, router, helper); }

  ngOnInit(): void {
    super.ngOnInit();
    this.hashFrom = new URLSearchParams(this.route.snapshot.fragment).get('from');
  }

  redirectToTarget(post: FeedEventViewModel) {
    if (post) {
      if (post.event.ownerType === ProfileType.User) {
        this.router.navigate(['profile', post.event.ownerId]);
      } else {
        this.router.navigate(['group', post.event.ownerId]);
      }
    }
  }

  cancel() {
    if (this.hashFrom) {
      this.router.navigate([this.hashFrom]);
    } else {
      this.router.navigate(['group', this.group.id]);
    }
  }
}
