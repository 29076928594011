<div class="card" *ngIf="!loaded">
  <span class="preloader _avatar">
    <span class="preloader__side"></span>
    <span class="preloader__line"></span>
  </span>
</div>
<ng-container *ngFor="let r of [0,1]">
  <div class="card" *ngIf="!loaded">
    <div class="post">
      <div class="post__author">
        <span class="preloader _avatar">
          <span class="preloader__side"></span>
          <span class="preloader__line"></span>
        </span>
      </div>
      <div class="post__text">
        <span class="preloader _cascade" *ngFor="let r of [0,1,2]">
          <span class="preloader__line"></span>
        </span>
      </div>
      <div class="post__comments">
        <div class="card">
          <div class="post">
            <div class="post__author">
              <span class="preloader">
                <span class="preloader__side"></span>
                <span class="preloader__line"></span>
              </span>
            </div>
            <div class="post__text">
              <span class="preloader _cascade" *ngFor="let r of [0,1,2]">
                <span class="preloader__line"></span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="post card">
      <span class="preloader _avatar">
        <span class="preloader__side"></span>
        <span class="preloader__line"></span>
      </span>
    </div>
  </div>
</ng-container>