<div class="cos">

  <!-- Header -->
  <app-group-small-header></app-group-small-header>
  <!-- Header END-->

  <!-- header preloader -->
  <app-tab-preloader *ngIf="!loaded"></app-tab-preloader>
  <!-- header preloader END -->

  <!-- Tabs -->
  <div class="cos__header" *ngIf="loaded">
    <app-tabs [tabs]="tabs"></app-tabs>
    <a [routerLink]="newLink" *ngIf="showAddButton" class="btn btn-plus pull-right">+</a>
  </div>
  <!-- Tabs END-->

</div>

<app-group-forbidden *ngIf="!isVisibleMainBlock()" [type]="groupsService.GroupForbiddenType.cos"></app-group-forbidden>

<ng-container *ngIf="isVisibleMainBlock()">

  <ng-container *ngIf="isVisibleLists()">
    <app-group-album-list></app-group-album-list>
    <app-group-media-list [title]="'Все медиафайлы'" [limit]="10" [all]="true"></app-group-media-list>
    <div class="co" *ngIf="currentTab?.loading">
      <mat-spinner [diameter]="56"></mat-spinner>
    </div>
  </ng-container>

  <!-- Empty tab -->
  <div class="cos"
    *ngIf="!currentTab?.loading && loaded && currentTab?.loaded && (!currentTab?.items || !currentTab?.items.length)">
    <div class="cos__body">
      <div class="card">
        <div class="group-empty">
          <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" class="group-empty__img">
          <div class="group-empty__text">{{currentTab?.emptyText}}</div>
        </div>
      </div>
    </div>
  </div>
  <!-- Empty tab END-->
</ng-container>

<!-- header preloader -->
<app-tabbed-list-preloader *ngIf="!currentTab?.loaded"></app-tabbed-list-preloader>
<!--  header preloader END -->
