import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntryAuthGuard } from './shared/guards/intry-auth.guard';
import { IntryAuthService } from './shared/services/intry-auth.service';
import { AuthModule, OidcConfigService } from 'angular-auth-oidc-client';
import { intryAuthConfigFactory } from './factories/intry-auth-config.factory';
import { INTRY_AUTH_CONFIG, IntryAuthConfig } from './shared/models/intry-auth.config';
import { IntryAnonymousGuard } from './shared/guards/intry-anonymous.guard';
import { IntryAutoLoginGuard } from './shared/guards/intry-auto-login.guard';
import { IntryAuthenticatedGuard } from './shared/guards/intry-authenticated-guard.service';
import { IntryAuthRedirectService } from './shared/services/intry-auth-redirect.service';
import { IntrySecurityStorageService } from './shared/services/intry-security-storage.service';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { IntryAuthRoutingModule } from './auth-routing.module';
import { IntryStorageModule } from '@app/intry-storage/intry-storage.module';

@NgModule({
  imports: [
    CommonModule,
    IntryAuthRoutingModule,
    IntryStorageModule,
  ],
  declarations: [
    AuthCallbackComponent
  ],
  providers: [
    OidcConfigService,
    IntryAuthService,
    IntryAuthenticatedGuard,
    IntryAuthRedirectService,
    IntryAnonymousGuard,
    IntryAutoLoginGuard,
    IntrySecurityStorageService,
    IntryAuthGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: intryAuthConfigFactory,
      deps: [OidcConfigService, INTRY_AUTH_CONFIG],
      multi: true,
    },
  ],
})
export class IntryAuthModule {

  static forRoot(config: IntryAuthConfig): ModuleWithProviders<IntryAuthModule> {
    return {
      ngModule: IntryAuthModule,
      providers: [
        AuthModule.forRoot({
          storage: IntrySecurityStorageService,
        }).providers,
        {
          provide: INTRY_AUTH_CONFIG,
          useValue: config,
        },
      ]
    };
  }

  static forChild(): ModuleWithProviders<IntryAuthModule> {
    return {
      ngModule: IntryAuthModule,
      providers: []
    };
  }

  constructor(authRedirectService: IntryAuthRedirectService) {
    authRedirectService.subscribe();
  }
}
