import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil, switchMap } from 'rxjs/operators';
import { IThank } from '@app/thanks/model/thank';
import { BaseComponent } from '@app/core/components/base.component';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subject } from 'rxjs';
import { ThanksService } from '@app/thanks/services/thanks.service';
import { UsersService } from '@app/profile/services/users.service';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { AlertsService } from '@app/shared/services/alerts.service';
import { HelperService } from '@app/core/services/helper.service';
import { User } from '@app/profile/model/user.model';
import { MatDialog } from '@angular/material/dialog';
import { NewThankComponent } from '../new-thank/new-thank.component';

enum ThankTargetType {
  iThanked = 1,
  iWasThanked = 2,
  colleagueThanked = 3,
  colleagueWasThanked = 4
}

@Component({
  selector: 'app-thank-view',
  templateUrl: './thank-view.component.html',
  styleUrls: ['./thank-view.component.scss']
})
export class ThankViewComponent extends BaseComponent implements OnInit, OnDestroy {

  thank: IThank;
  thankTitle: string;

  currentUser: User;
  user: User;
  userForView: User;

  thankTargetType: ThankTargetType;
  ThankTargetType = ThankTargetType;

  constructor(
    public thanksService: ThanksService,
    public usersService: UsersService,
    protected breadcrumbsService: BreadcrumbsService,
    private alertsService: AlertsService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    public helper: HelperService) {
    super(helper);

  }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;

      this.route.params
        .pipe(takeUntil(this.destroyed$), switchMap((params: Params) => {
          this.thank = null;
          const thankId = +params['thankId'];
          if (thankId) {
            return this.thanksService.getById(thankId);
          } else {
            this.router.navigate(['/404'], { skipLocationChange: true });
          }
        }))
        .subscribe(thank => {
          this.thank = thank;

          if (!this.thank) {
            this.router.navigate(['/404'], { skipLocationChange: true });
          }

          this.usersService.user
            .pipe(takeUntil(this.destroyed$)).subscribe(user => {
              this.user = user;

              if (this.user) {

                this.getThankType(this.thank, currentUser, user);

                const thanksRoute = `/profile/${this.user.id}/thanks`;

                let routeText = 'Спасибо от сотрудника';

                if (this.thankTargetType === ThankTargetType.iThanked
                  || this.thankTargetType === ThankTargetType.colleagueThanked) {
                  routeText = 'Спасибо отправлено';
                  this.userForView = this.thank.user;
                } else {
                  this.userForView = this.thank.author;
                }

                this.breadcrumbsService.breadcrumbs.next([
                  { title: 'Спасибо', routerUrl: thanksRoute },
                  { title: routeText, routerUrl: thanksRoute + '/' + this.thank.id }
                ]);
              }
            });

          this.error = '';
          this.loaded = true;
        }, error => {
          this.error = error;
          this.loaded = true;
        });
    });
  }

  private getThankType(thank: IThank, currentUser: User, user: User) {
    if (currentUser.id === user.id) {
      // это мой профиль
      if (user.id === thank.authorId) {
        // поблагодарил я
        this.thankTargetType = ThankTargetType.iThanked;
        this.thankTitle = 'Вы сказали спасибо';
      } else {
        // поблагодарили меня
        this.thankTargetType = ThankTargetType.iWasThanked;
        this.thankTitle = 'Вам сказали спасибо';
      }
    } else {
      // это чужой профиль
      if (user.id === thank.authorId) {
        // поблагодарил коллега
        this.thankTargetType = ThankTargetType.colleagueThanked;
        this.thankTitle = 'Сотрудник сказал спасибо';
      } else {
        // поблагодарили коллегу
        this.thankTargetType = ThankTargetType.colleagueWasThanked;
        this.thankTitle = 'Сотруднику сказали спасибо';
      }
    }
  }

  thankUser(user: User) {
    const newThankDialog = this.dialog.open(NewThankComponent, {
      data: {
        usersToAdd: [user],
        onOk: () => {
          newThankDialog.close();
        }
      }
    });
  }
}
