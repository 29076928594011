<div class="cos__body" *ngIf="loaded && survey">
    <div class="survey">
        <div class="survey__section _first">
            <div class="survey__title input-main">
                <textarea rows="1" placeholder="Введите название {{placeholderSuffix}}*" [(ngModel)]="survey.title" class="input-main__field" maxlength="255" (focus)="survey.titleFocused=true" (blur)="survey.titleFocused=false" (keyup)="isAlreadyExists=false;"></textarea>
                <div class="form__note" *ngIf="survey.titleFocused">{{survey.title?.length || 0}} из 255</div>
                <div class="error-message" *ngIf="isAlreadyExists">{{placeholderPrefix}} с таким названием уже существует
                </div>
            </div>
            <div class="survey__description input-main">
                <textarea autosize rows="3" placeholder="Введите описание {{placeholderSuffix}}" [(ngModel)]="survey.description" class="input-main__field" maxlength="4000" (focus)="survey.descriptionFocused=true" (blur)="survey.descriptionFocused=false"></textarea>
                <div class="form__note" *ngIf="survey.descriptionFocused">{{survey.description?.length}} из 4000</div>
            </div>
            <div class="survey__group" *ngIf="showScope">
                <app-profile-selector [required]="true" [placeholder]="'Выберите группу для публикации'" (profileSelected)="onProfileSelected($event)" [selectedProfileId]="survey.ownerId">
                </app-profile-selector>
            </div>
        </div>
        <div class="survey__questions" cdkDropList (cdkDropListDropped)="drop($event, survey.questions)">
            <div class="survey__question survey__section" cdkDrag *ngFor="let question of survey.questions; let i=index">
                <ng-container *ngTemplateOutlet="question.isGroup ? questionGroupTemplate : questionBaseTemplate; context: {$implicit: {i:i, questions:survey.questions, question: question}}">
                </ng-container>
            </div>
        </div>
        <div class="survey__section">
            <div>
                <button type="button" class="btn btn-white" (click)="addQuestion()">
                    <span class="icon _add"></span> Добавить вопрос</button>
                <button type="button" class="btn btn-white" (click)="addQuestionGroup()">
                    <span class="icon _add"></span> Добавить группу</button>
            </div>
            <div class="post__calendar">
                Срок окончания
                <input maxlength="10" placeholder="дд.мм.гггг" matInput (keydown)="onCalendarKeydown($event)" [min]="minDate" [matDatepicker]="dp" [(ngModel)]="survey.dateEnd">
                <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp></mat-datepicker>
            </div>
            <div class="post__actions" [ngClass]="{'loading _blue': sending}">
                <div class="post__submit">
                    <button type="button" class="btn btn-simple" (click)="cancel()">Отмена</button>
                    <!-- черновик -->
                    <button type="button" class="btn btn-white" [attr.disabled]="isValid()?null:''" *ngIf="allowEditSurvey()" (click)="draft()">{{draftButtonText}}</button>
                    <button type="button" class="btn btn-primary" [attr.disabled]="isValid()?null:''" *ngIf="allowEditSurvey()" (click)="submit()">Опубликовать</button>
                    <!-- опубликованный -->
                    <button type="button" class="btn btn-primary" [attr.disabled]="isValid()?null:''" *ngIf="!allowEditSurvey()" (click)="submit()">Сохранить</button>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #questionGroupTemplate let-data>
    <div class="survey__question-title input-main">
        <span class="survey__question-number">{{data.i+1}}.</span>
        <input type="text" placeholder="ВВЕДИТЕ НАЗВАНИЕ ГРУППЫ*" [(ngModel)]="data.question.title"
            class="input-main__field _upper" required maxlength="255" (focus)="data.question.focused=true"
            (blur)="data.question.focused=false">
        <div class="form__note" *ngIf="data.question.focused">{{data.question.title?.length || 0}} из 255</div>
    </div>
    <div class="survey__description input-main">
        <input type="text" placeholder="Введите описание группы" [(ngModel)]="data.question.description"
            class="input-main__field" maxlength="255"
            (focus)="data.question.focusedDescription=true" (blur)="data.question.focusedDescription=false">
        <div class="form__note" *ngIf="data.question.focusedDescription">{{data.question.description?.length || 0}} из 255
        </div>
    </div>
    <div class="survey__questions" cdkDropList (cdkDragMoved)="moved($event)" (cdkDropListDropped)="drop($event, data.question.questions)">
        <div class="survey__question survey__section" cdkDrag *ngFor="let childQuestion of data.question.questions; let childIndex=index">
            <ng-container *ngTemplateOutlet="questionBaseTemplate; context: {$implicit: {parentIndex:data.i, i:childIndex, questions:data.question.questions, question: childQuestion}}">
            </ng-container>
        </div>
    </div>
    <div class="survey__section _last">
        <div>
            <button type="button" class="btn btn-add" (click)="addChildQuestion(data.i)">Добавить вопрос в
                группу</button>
            <button type="button" class="btn btn-destroy" *ngIf="allowEditQuestion(data.question)" (click)="removeQuestion(data.i)">Удалить группу</button>
            <button type="button" class="btn btn-copy" (click)="copyQuestionGroup(data.i)">Копировать группу</button>
        </div>
    </div>
</ng-template>

<ng-template #questionBaseTemplate let-data>
    <div class="survey__question-title input-main">
        <span class="survey__question-number"><span *ngIf="data.parentIndex>=0">{{data.parentIndex+1}}.</span>{{data.i+1}}.</span>
        <input type="text" placeholder="Введите название вопроса*" [(ngModel)]="data.question.title"
            class="input-main__field" required (mousedown)="$event.stopPropagation()"
            maxlength="255" (focus)="data.question.focused=true" (blur)="data.question.focused=false">
        <div class="form__note" *ngIf="data.question.focused">{{data.question.title?.length || 0}} из 255</div>
    </div>
    <div class="survey__description input-main">
        <textarea autosize rows="3" type="text" placeholder="Введите описание вопроса" [(ngModel)]="data.question.description"
            class="input-main__field" maxlength="255" (mousedown)="$event.stopPropagation()"
                  (focus)="data.question.focusedDescription=true" (blur)="data.question.focusedDescription=false"></textarea>
        <div class="form__note" *ngIf="data.question.focusedDescription">{{data.question.description?.length || 0}} из 255
        </div>
    </div>
    <ng-container [ngSwitch]="data.question.type">
        <ng-container *ngSwitchCase="SurveyQuestionType.single">
            <ng-container *ngTemplateOutlet="simpleQuestionTemplate; context: {$implicit: {index:data.i, question: data.question, questions: data.questions, options: data.question.options}}">
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="SurveyQuestionType.multiple">
            <ng-container *ngTemplateOutlet="multipleQuestionTemplate; context: {$implicit: {index:data.i, question: data.question, questions: data.questions, options: data.question.options}}">
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="SurveyQuestionType.text">
            <ng-container *ngTemplateOutlet="textQuestionTemplate; context: {$implicit: {index:data.i, question: data.question, questions: data.questions, options: data.question.options}}">
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="SurveyQuestionType.range">
            <ng-container *ngTemplateOutlet="rangeQuestionTemplate; context: {$implicit: {index:data.i, question: data.question, questions: data.questions, options: data.question.options}}">
            </ng-container>
        </ng-container>
    </ng-container>
    <div class="survey__footer">
        <div class="dropdown _slim" [ngClass]="{_disabled: !allowEditQuestion(data.question)}">
            <ng-container *ngIf="allowEditQuestion(data.question)">
                <div class="" mat-button [matMenuTriggerFor]="menu">{{getQuestionType(data.question.type)}}</div>
                <mat-menu #menu="matMenu">
                    <div class="icon-menu" #menuContainer>
                        <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" (click)="setQuestionType(type, data.question)" *ngFor="let type of SurveyQuestionType.values()">
                            <span class="icon-menu__icon">
                                <img *ngIf="getQuestionType(type) === getQuestionType(data.question.type)"
                                    src="{{assetsPrefix}}/assets/images/icons/menu/check.svg" class="icon-menu__img">
                            </span>
                            <span>{{getQuestionType(type)}}</span>
                        </a>
                    </div>
                </mat-menu>
            </ng-container>
            <ng-container *ngIf="!allowEditQuestion(data.question)">
                <div class="text-secondary">{{getQuestionType(data.question.type)}}</div>
            </ng-container>
        </div>
        <label class="checkbox _hover">
            <input type="checkbox" [checked]="data.question.required"
                (change)="data.question.required = !data.question.required" class="checkbox__input" >
            <span class="checkbox__icon"></span>
            <span class="checkbox__text">Обязательный вопрос</span>
        </label>
        <button type="button" class="btn btn-delete" *ngIf="allowEditQuestion(data.question)" (click)="removeQuestion(data.i, data.questions)">Удалить</button>
    </div>
</ng-template>

<!-- Вариант вопроса с единственным выбором -->
<ng-template #simpleQuestionTemplate let-data>
    <div class="survey__case" *ngFor="let option of data.options; let i=index;">
        <label class="checkbox _oneline">
            <input type="radio" name="options_{{data.index}}" value="options_{{data.index}}" class="checkbox__input"
                [attr.disabled]="isTest?null:''" [checked]="option.isCorrect" (change)="radioSelected(data.options, option)">
            <span class="checkbox__icon"></span>
            <ng-container *ngIf="option.type===SurveyQuestionOptionType.normal">
                <input type="text" placeholder="Вариант {{i+1}}" [(ngModel)]="option.title" class="input-main__field" (mousedown)="$event.stopPropagation()" maxlength="255">
            </ng-container>
            <ng-container *ngIf="option.type===SurveyQuestionOptionType.other">
                <span class="survey__custom">Другое</span>
                <input type="text" placeholder="Поле ввода ответа" disabled class="input-main__field" (mousedown)="$event.stopPropagation()" maxlength="255">
            </ng-container>
            <button type="button" class="btn btn-remove" *ngIf="allowEditQuestion(data.question)"
                (click)="removeOption(i, data, data.questions)"></button>
        </label>
    </div>
    <div class="survey__note" *ngIf="isTest">Установите один правильный ответ.</div>
    <button type="button" class="btn btn-add" *ngIf="allowEditQuestion(data.question)" (click)="addOption(data.options)">Добавить вариант</button>
    <button *ngIf="allowEditQuestion(data.question) && !hasOtherOption(data.options) && !isTest" type="button" class="btn btn-add" (click)="addOtherOption(data.options)">Добавить вариант "Другое"</button>
</ng-template>
<!-- Вариант вопроса с единственным выбором END -->

<!-- Вариант вопроса с множественным выбором -->
<ng-template #multipleQuestionTemplate let-data>
    <div class="survey__case" *ngFor="let option of data.options; let i=index;">
        <label class="checkbox _oneline">
            <input type="checkbox" class="checkbox__input" [(checked)]="option.isCorrect"
                [attr.disabled]="isTest?null:''" (change)="checkboxSelected(option)">
            <span class=" checkbox__icon"></span>
            <ng-container *ngIf="option.type===SurveyQuestionOptionType.normal">
                <input type="text" placeholder="Вариант {{i+1}}" [(ngModel)]="option.title" class="input-main__field" (mousedown)="$event.stopPropagation()" maxlength="255">
            </ng-container>
            <ng-container *ngIf="option.type===SurveyQuestionOptionType.other">
                <span class="survey__custom">Другое</span>
                <input type="text" placeholder="Поле ввода ответа" disabled class="input-main__field" (mousedown)="$event.stopPropagation()" maxlength="255">
            </ng-container>
            <button type="button" class="btn btn-remove" *ngIf="allowEditQuestion(data.question)"
                (click)="removeOption(i, data, data.questions)"></button>
        </label>
    </div>
    <div class="survey__note" *ngIf="isTest">Установите несколько правильных ответов.</div>
    <button type="button" class="btn btn-add" *ngIf="allowEditQuestion(data.question)" (click)="addOption(data.options)">Добавить вариант</button>
    <button type="button" class="btn btn-add" *ngIf="allowEditQuestion(data.question) && !isTest" (click)="addOtherOption(data.options)">Добавить вариант "Другое"</button>
</ng-template>
<!-- Вариант вопроса с множественным выбором END -->

<!-- Текстовый вариант вопроса -->
<ng-template #textQuestionTemplate let-data>
    <div class="input-main _huge">
        <input type="text" placeholder="Введите свой ответ" disabled class="input-main__field">
    </div>
</ng-template>
<!-- Текстовый вариант вопроса END -->

<!-- Вариант вопроса с оценкой -->
<ng-template #rangeQuestionTemplate let-data>
    <div class="survey__case" *ngFor="let option of data.options; let i=index;">
        <span class="input-main _tiny _inline">
            <input class="input-main__field" type="number" [(ngModel)]="data.question.meta.min" (mousedown)="$event.stopPropagation()">
        </span> &nbsp;-&nbsp;
        <span class="input-main _tiny _inline">
            <input class="input-main__field" type="number" [(ngModel)]="data.question.meta.max" (mousedown)="$event.stopPropagation()">
        </span>
    </div>
</ng-template>
<!-- Вариант вопроса с оценкой END -->
