import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  Input,
  ViewChild,
  Type,
  ComponentFactoryResolver,
} from '@angular/core';
import {GroupsService} from '@app/groups/services/groups.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {BaseComponent} from '@app/core/components/base.component';
import {UsersService} from '@app/profile/services/users.service';
import {GroupInfo} from '@app/groups/model/group-info.model';
import {GroupType} from '@app/groups/model/group-type.model';
import {trigger, state, style, animate, transition} from '@angular/animations';
import {switchMap, takeUntil} from 'rxjs/operators';
import {HelperService} from '@app/core/services/helper.service';
import {IModuleInfo} from '@app/groups/model/module';
import {DynamicContainerDirective} from '@app/shared/directives/dynamic-container.directive';
import {PagesWidgetComponent} from '@app/pages/components/pages-widget/pages-widget.component';
import {GroupFilesWidgetComponent} from '@app/files/components/files-widget/group-files-widget.component';
import {GroupGalleryWidgetComponent} from '../../gallery/group-gallery-widget/group-gallery-widget.component';
import {GroupSurveysWidgetComponent} from '../../surveys/group-surveys-widget/group-surveys-widget.component';
import {GroupVacancyWidgetComponent} from '../../vacancy/group-vacancy-widget/group-vacancy-widget.component';
import {GroupIdeasWidgetComponent} from '../../ideas/group-ideas-widget/group-ideas-widget.component';
import {GroupUserLinkObjectType} from '../group-settings/group-settings.component';
import { UserType } from '@app/profile/model/user.model';
import { FilesService } from '@app/files/services/files.service';

/**
 * Компонент отображения главной страницы группы
 *
 * @export
 * @class GroupViewComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-group-vew',
  templateUrl: 'group-view.component.html',
  animations: [
    trigger('visibilityChanged', [
      state('true', style({opacity: 1, display: 'inherit'})),
      state('false', style({opacity: 0, display: 'none'})),
      transition('* => *', animate('.5s')),
    ]),
  ],
})
export class GroupViewComponent extends BaseComponent implements OnInit, OnDestroy {
  group: GroupInfo;
  showGroup: boolean;
  groupModules: IModuleInfo[] = [];
  UserType = UserType;

  @Input() goTopVisible = false;

  /**
   * Контейнер для динамического добавления компонентов на страницу
   *
   * @type {DynamicContainerDirective}
   * @memberof ProfileViewComponent
   */
  @ViewChild(DynamicContainerDirective, {static: true}) dynamicContainer: DynamicContainerDirective;

  constructor(
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected route: ActivatedRoute,
    protected router: Router,
    protected usersService: UsersService,
    private filesService: FilesService,
    public groupsService: GroupsService,
    public helper: HelperService,
  ) {
    super(helper);
  }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      // get group from id parameter
      this.route.params
        .pipe(
          takeUntil(this.destroyed$),
          switchMap((params: Params) => {
            this.groupsService.currentGroup.next(null);
            const groupId = +params['id'];
            if (groupId) {
              return this.groupsService.getGroup(groupId);
            } else {
              this.router.navigate(['/404'], {skipLocationChange: true});
            }
          }),
        )
        .subscribe(
          group => {
            if (!group || (!this.groupsService.canReadGroup(group, currentUser) && group.type === GroupType.Private)) {
              this.router.navigate(['/404'], {skipLocationChange: true});
            }

            this.group = group;

            if (this.group) {
              // если бизнес-группа не 1 подтипа (GroupUserLinkObjectType.allUsers), то её могут смотреть
              // только суперадмины либо админы и подписчики
              if (group.type === GroupType.Business && !(currentUser.isAdmin || currentUser.isGroupsAdmin)) {
                this.groupsService.getGroupUsersSettings(this.group.id).subscribe(res => {
                  if (
                    res &&
                    res.linkObjectType !== GroupUserLinkObjectType.allUsers &&
                    !(group.isAdmin || group.isMember || group.isSubscriber)
                  ) {
                    this.router.navigate(['/404'], {skipLocationChange: true});
                  } else {
                    this.group.isCanView = true;
                  }
                  this.operateGroupLoading(group);
                });
              } else {
                // остальные случаи уже были обработаны заранее через !group и !this.groupsService.canReadGroup(group)
                this.group.isCanView = true;
                this.operateGroupLoading(group);
              }
            }
          },
          error => {
            this.loaded = true;
            this.error = error;
          },
        );
    });

    this.groupsService.settingsChanged$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.getGroupModules();
    });
  }

  private operateGroupLoading(group: GroupInfo) {
    if (group) {
      this.group.showCard = false;
      this.getGroupModules();
    }

    this.loaded = true;
    this.groupsService.currentGroup.next(group);
  }

  private isShowModule(name: string) {
    return this.groupModules.find(s => s.title === name);
  }

  private getGroupModules() {
    this.groupsService
      .getGroupModules(this.group.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(groupModules => {
        this.groupModules = groupModules;
        // очищаем текущий список виджетов
        const viewContainerRef = this.dynamicContainer.viewContainerRef;
        viewContainerRef.clear();

        this.checkWidgets();
      });
  }

  private checkWidgets() {
    if (this.isShowModule('Страницы')) {
      this.addComponent(PagesWidgetComponent);
    }
    if (this.isShowModule('Файлы') && this.filesService.showUserFiles$.getValue()) {
      this.addComponent(GroupFilesWidgetComponent);
    }
    if (this.isShowModule('Галерея')) {
      this.addComponent(GroupGalleryWidgetComponent);
    }
    if (this.isShowModule('Опросы')) {
      this.addComponent(GroupSurveysWidgetComponent);
    }
    if (this.isShowModule('Вакансии')) {
      this.addComponent(GroupVacancyWidgetComponent);
    }
    if (this.isShowModule('Идеи')) {
      this.addComponent(GroupIdeasWidgetComponent);
    }
  }

  private addComponent(type: Type<any>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(type);
    const viewContainerRef = this.dynamicContainer.viewContainerRef;
    viewContainerRef.createComponent(componentFactory);
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.goTopVisible = window.pageYOffset > window.innerHeight / 2;
  }

  scrollTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.groupsService.currentGroup.next(null);
  }
}
