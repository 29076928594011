import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { GalleryService } from '@app/gallery/services/gallery.service';
import { GalleryApiService } from '@app/gallery/services/gallery-api.service';
import { GalleryWidgetComponent } from '@app/gallery/components/gallery-widget/gallery-widget.component';
import { SharedModule } from '@app/shared/shared.module';
import { AlbumListComponent } from '@app/gallery/components/lists/album-list/album-list.component';
import { MediaLastListComponent } from '@app/gallery/components/lists/media-last-list/media-last-list.component';
import { RouterModule } from '@angular/router';
import { AlbumViewComponent } from '@app/gallery/components/forms/album-view/album-view.component';
import { AlbumNewComponent } from '@app/gallery/components/forms/album-new/album-new.component';
import { GalleryComponent } from '@app/gallery/components/gallery-main/gallery.component';
import { GalleryViewComponent } from '@app/gallery/components/forms/gallery-view/gallery-view.component';
import { FormsModule } from '@angular/forms';
import { MediaListComponent } from '@app/gallery/components/lists/media-list/media-list.component';
import { AlbumMenuComponent } from '@app/gallery/components/menu/album-menu/album-menu.component';
import { MediaItemMenuComponent } from '@app/gallery/components/menu/media-item-menu/media-item-menu.component';
import { LikesModule } from '@app/likes/likes.module';
import { CommentsModule } from '@app/comments/comments.module';
import { MediaFileViewComponent } from './components/forms/media-file-view/media-file-view.component';
import { MediaFileEditComponent } from './components/forms/media-file-edit/media-file-edit.component';
import { GalleryTreeComponent } from './components/lists/gallery-tree/gallery-tree.component';
import { AlbumVotingComponent } from './components/forms/album-voting/album-voting.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    LikesModule,
    CommentsModule,
    RouterModule,
    BrowserModule
  ],
  declarations: [
    GalleryViewComponent,
    GalleryWidgetComponent,
    AlbumListComponent,
    MediaLastListComponent,
    MediaListComponent,
    AlbumNewComponent,
    AlbumViewComponent,
    GalleryComponent,
    AlbumMenuComponent,
    MediaItemMenuComponent,
    MediaFileViewComponent,
    MediaFileEditComponent,
    GalleryTreeComponent,
    AlbumVotingComponent
  ],
  exports: [
    GalleryViewComponent,
    GalleryWidgetComponent,
    AlbumListComponent,
    MediaLastListComponent,
    MediaListComponent,
    AlbumNewComponent,
    AlbumViewComponent,
    GalleryComponent,
    MediaFileViewComponent,
    AlbumMenuComponent,
    MediaItemMenuComponent,
    GalleryTreeComponent
  ],
  providers: [GalleryService, GalleryApiService]
})
export class GalleryModule { }
