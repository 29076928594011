<div class="container _full-page _moved _with-sidebar">
  <div class="sidebar _menu">
    <div class="co-group" *ngIf="currentUser?.isAdmin">
      <div class="header-text _dark _medium co-group__header"><span class="header-text__text">Настройки портала</span></div>

      <a [routerLink]="['pages']" class="co _link link co__link" routerLinkActive="_active">Главное меню</a>
      <a [routerLink]="['gamification-types']" class="co _link link co__link" routerLinkActive="_active">Геймификация</a>
      <a [routerLink]="['settings']" class="co _link link co__link" routerLinkActive="_active">Общие настройки</a>
      <a href="/Lists/FeedbackCategories" target="_blank" class="co _link link co__link">Задать вопрос</a>
      <!-- <a href="/Shared%20Documents" target="_blank" class="co _link link co__link">Шаблон заявления на отпуск</a> -->
    </div>

    <div class="co-group" *ngIf="isVacancyAdmin()">
      <div class="header-text _dark _medium co-group__header"><span class="header-text__text">Вакансии</span></div>

      <a [routerLink]="['vacancy-categories']" class="co _link link co__link" routerLinkActive="_active">Настройка категорий вакансий и интеграции с hh.ru</a>
    </div>

    <div class="co-group" *ngIf="currentUser?.isAdmin || currentUser?.isBannerAdmin">
      <div class="header-text _dark _medium co-group__header"><span class="header-text__text">Баннер</span></div>

      <a [routerLink]="['banner-settings']" class="co _link link co__link" routerLinkActive="_active">Управление слайдами</a>
      <a [routerLink]="['banner-types']" class="co _link link co__link" routerLinkActive="_active">Настройка типов слайдов</a>
    </div>

    <div class="co-group" *ngIf="isIdeasAdmin()">
      <div class="header-text _dark _medium co-group__header"><span class="header-text__text">Идеи</span></div>

      <a [routerLink]="['idea-statuses']" class="co _link link co__link" routerLinkActive="_active">Статусы идей</a>
    </div>

  </div>

  <div class="content _one _admin">
    <app-alerts></app-alerts>
    <div class="cos">
      <div class="user-header">
        <a routerLink="/admin" class="link-profile hint">
          <span title="" class="avatar _30">
            <span class="avatar__image-wrap">
              <img src="{{assetsPrefix}}/assets/images/icons/settings-white.svg" alt="" class="avatar__image">
            </span>
          </span>
          <span class="link-profile__text">Администрирование</span>
        </a>
        <app-breadcrumbs></app-breadcrumbs>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
