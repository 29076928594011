<div class="grid _transparent">
  <div class="grid__content">
    <div class="grid__item" *ngFor="let idea of ideas; trackBy:trackByFn">
      <!-- _ok если published -->
      <div class="idea" [ngClass]="{'_ok': idea.published}">
        <div class="idea__header">
          <app-idea-menu [item]="idea" [config]="getIdeaConfig(idea)"></app-idea-menu>
          <div class="link-profile">
            <app-user-avatar [user]="idea.author" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'">
            </app-user-avatar>
            <span class="link-profile__text">
              <a routerLink="/profile/{{idea?.author?.id}}" class="link"
                (click)="usersService.navigateProfile(idea.author?.id)"
                [appUserTooltip]="idea.author?.id">{{idea.author?.fullName}}</a>
            </span>
            <span class="link-profile__subtext">{{idea.created | dateSpLike:true }}</span>
          </div>
        </div>
        <div class="idea__content">
          <a class="idea__title" routerLink="/group/{{idea?.ownerId}}/ideas/{{idea.id}}">{{idea.title}}</a>
          <div class="idea__status text-secondary">{{ideasService.getStatusText(idea)}}</div>
          <!-- <div class="idea__status">Реализована</div> -->
          <div class="idea__footer">
            <span class="btn btn-post _like" style="cursor: default" [ngClass]="{'_pressed': !idea.canLike}">{{idea.likesCount}}</span>
            <span class="btn btn-post _comment" [ngClass]="{'transparent-text': idea.commentsCount === 0}" style="cursor: default">{{idea.commentsCount}}</span>
            <span class="text-secondary" *ngIf="idea.published">Опубликована</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>