import { Component, Input, EventEmitter, Output, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { IMediaFile, MediaFileType } from '@app/gallery/model/media-file';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { GalleryService } from '@app/gallery/services/gallery.service';
import { AlertsService } from '@app/shared/services/alerts.service';
import { IMediaFileMenuConfig } from '@app/gallery/model/media-file-menu-config';
import { MediaFileEditComponent } from '@app/gallery/components/forms/media-file-edit/media-file-edit.component';
import { ProfileType } from '@app/core/model/profile-type.enum';
import { UsersService } from '@app/profile/services/users.service';
import { User } from '@app/profile/model/user.model';
import { HelperService } from '@app/core/services/helper.service';
import { GalleryTreeComponent, GalleryTreeType } from '../../lists/gallery-tree/gallery-tree.component';
import { take, takeUntil, map } from 'rxjs/operators';
import { IAlbum } from '@app/gallery/model/album-model';
import { Subject } from 'rxjs';
import { Helper } from '@app/core/helpers/helper';
import { FilesService } from '@app/files/services/files.service';

@Component({
  selector: 'app-media-item-menu',
  templateUrl: './media-item-menu.component.html',
  styleUrls: ['./media-item-menu.component.scss']
})
export class MediaItemMenuComponent extends BaseComponent implements OnDestroy {
  @Input() item: IMediaFile;
  @Input() config: IMediaFileMenuConfig;

  @Output() deleted: EventEmitter<any> = new EventEmitter();
  @ViewChild('itemLinkHref', { static: true }) itemLinkHref: ElementRef;
  @ViewChild('itemDownloadHref', { static: true }) itemDownloadHref: ElementRef;

  @Input() selectedItems: Array<IMediaFile>;
  @ViewChild(MatMenuTrigger, { static: true }) trigger: MatMenuTrigger;

  deletingDialog: MatDialogRef<ModalConfirmationComponent>;
  editDialog: MatDialogRef<MediaFileEditComponent>;

  currentUser: User;

  constructor(
    protected galleryService: GalleryService,
    protected usersService: UsersService,
    protected dialog: MatDialog,
    protected alertsService: AlertsService,
    protected filesService: FilesService,
    public helper: HelperService
  ) {
    super(helper);

    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;
    });
  }

  /**
   * Всплывающее окно подтверждения удаления медиа-файла
   *
   * @memberof MediaItemMenuComponent
   */
  showDeleteItem() {
    this.selectedItems = this.selectedItems && this.selectedItems.length ? this.selectedItems : [this.item];
    let text;
    if (this.selectedItems && this.selectedItems.length > 1) {
      text = 'медиа-файлы?';
    } else {
      text = 'медиа-файл?';
    }
    this.deletingDialog = this.dialog.open(ModalConfirmationComponent, {
      data: {
        text: `Вы действительно хотите удалить ${text}`,
        onOk: this.deleteItem.bind(this),
        doNotHideOnOk: true
      }
    });
  }

  /**
   * Удаление медиа-файла
   *
   * @private
   * @memberof MediaItemMenuComponent
   */
  private deleteItem() {
    this.selectedItems.forEach(item => {
      this.galleryService.deleteMediaFile(item.id).subscribe(res => {
        if (this.deleted) {
          this.deleted.emit(item.id);
        }
        // отправить событие в сервис
        this.galleryService.mediaFileDeleted.next(item.id);
        this.deletingDialog.close();
        this.alertsService.riseSuccess(`Медиа-файл был успешно удалён`);
      }, error => {
        const errorText = 'Произошла ошибка при удалении медиа-файла.';
        console.log(`${errorText}: '${error}'`);
        this.alertsService.riseError(errorText);
      });
    });
  }

  /**
   * Отобразить окно изменения медиа-файла
   *
   * @memberof MediaItemMenuComponent
   */
  showEditMediaFile() {
    this.editDialog = this.dialog.open(MediaFileEditComponent, {
      data: {
        text: `Изменить медиа-файл ${this.item.title}`,
        item: this.item,
        onOk: this.editItem.bind(this)
      }
    });
  }

  /**
   * Отобразить меню перемещения медиа-файла
   *
   * @memberof MediaItemMenuComponent
   */
  showMoveMediaFile() {
    this.selectedItems = this.selectedItems && this.selectedItems.length ? this.selectedItems : [this.item];
    this.dialog.open(GalleryTreeComponent, {
      minWidth: window.innerWidth - 100,
      data: {
        onOk: this.onMove.bind(this),
        cancelText: 'Отмена',
        okText: 'Переместить сюда',
        headerText: 'Выберите альбом',
        type: GalleryTreeType.selectAlbum,
        doNotSelectMediaFile: true
      }
    });
  }

  isCanSetMediaFileAsCover(): boolean {
    return this.item
      && this.item.type === MediaFileType.image
      && !this.item.isAlbumPreview
      && this.config
      && this.config.allowEdit;
  }

  setMediaFileAsCover() {
    this.galleryService.setMediaFileAsCover(this.item.id).pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        if (res) {
          this.item.isAlbumPreview = true;
          this.alertsService.riseSuccess(`Фотография успешно сделана обложкой альбома`);
        } else {
          this.alertsService.riseSuccess(`Произошла ошибка при установке фотографии обложкой альбома`);
        }
      }, error => {
        this.alertsService.riseSuccess(`Произошла ошибка при установке фотографии обложкой альбома`);
      });
  }

  /**
   * Получение ссылки на медиа-файл (всплывающее окно)
   *
   * @returns {string}
   * @memberof AlbumMenuComponent
   */
  getNavigateUrl(): string {
    if (!this.item) {
      return '';
    }

    if (this.item.ownerType === ProfileType.Group) {
      if (this.item.group && this.item.album) {
        return `/group/${this.item.group.id}/gallery/${this.item.album.id}#m=${this.item.id}`;
      } else {
        return ``;
      }
    } else {
      if (!this.currentUser) {
        return '';
      }
      return `/profile/${this.item.ownerId}/gallery/${this.item.album.id}#m=${this.item.id}`;
    }
  }

  getItemLinkUrl(): string {
    if (!this.item) {
      return '';
    }

    return this.getNavigateUrl();
  }

  show(e) {
    this.trigger.openMenu();
    e.preventDefault();
    e.stopImmediatePropagation();
    e.cancelBubble = true;
    return false;
  }

  /**
   * Копирование ссылки на медиа-файл в буфер обмена
   *
   * @memberof AlbumMenuComponent
   */
  copyToClipboard() {
    this.galleryService.copyToClipboard(this.itemLinkHref.nativeElement.href, 'медиа-файл');
  }

  download() {
    const fileUrl = decodeURIComponent(this.item.url).trim();
    const serverUrl = (<any>window).signalRServer;
    const mediaIndex = fileUrl.indexOf('/Media');
    const webUrl = fileUrl.substr(0, mediaIndex);

    const url = Helper.concatUrls(
      Helper.concatUrls(serverUrl, webUrl),
      `/_layouts/download.aspx?SourceUrl=${Helper.concatUrls(serverUrl, fileUrl)}`);

    this.itemDownloadHref.nativeElement.href = url;
    this.itemDownloadHref.nativeElement.click();
  }

  private editItem() {
    console.log('edited');
  }

  /**
   * Переместить медиа-файл в другой альбом
   *
   * @private
   * @memberof MediaItemMenuComponent
   */
  private onMove(event: IAlbum) {
    this.selectedItems.forEach(item => {
      this.galleryService.moveMediaFile({ mediaFileId: item.id, newAlbumId: event.id })
        .pipe(take(1))
        .subscribe(res => {
          this.alertsService.riseSuccess(`Медиа-файл ${item.title} был успешно перемещён`);
          this.galleryService.mediaFileMoved$.next({
            id: item.id,
            toAlbumId: event.id,
            fromAlbumId: item.album ? item.album.id : null
          });
        }, error => {
          const errorText = 'Произошла ошибка при перемещении медиа-файла.';
          console.log(`${errorText}: '${error}'`);
          this.alertsService.riseError(errorText);
        });
    });
  }
}
