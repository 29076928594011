<div class="card" [ngClass]="{'_full-content': !isShowEmpty()}" *ngIf="loaded">
  <div class="card__title">
    <a *ngIf="showAddButton" [routerLink]="newLink" class="btn btn-plus pull-right"></a>
    <a (click)="navigate()" [routerLink]="link" [fragment]="fragment" class="header-text">
      <span class="header-text__text">Галерея</span>
    </a>
  </div>
  <div class="card__content" *ngIf="!isShowEmpty()">
    <ngx-slick-carousel class="carousel _widget" #slickModal="slick-carousel" [config]="slideConfig">
      <div ngxSlickItem *ngFor="let mediaFile of mediaFiles" class="slide">
        <div (click)="openMediaFile($event, mediaFile)" class="carousel__slide"
          [ngClass]="{'_video': mediaFile.type===mediaFileType.video}"
          [style.background-image]="galleryService.getMediaFilePreview(mediaFile)">
<!--            <video *ngIf="mediaFile.type===mediaFileType.video"-->
<!--                 class="carousel__video-preview"-->
<!--                 width="640" height="480" preload="metadata">-->
<!--              <source [src]="galleryService.getMediaFileUrl(mediaFile)" type="video/mp4">-->
<!--            </video>-->
        </div>
      </div>
    </ngx-slick-carousel>
  </div>
  <app-empty-widget-content *ngIf="isShowEmpty()" [text]="'Здесь будут фото и видео'"></app-empty-widget-content>
</div>
<div class="card" *ngIf="!loaded">
  <div class="card__title">
    <span class="preloader">
      <span class="preloader__line"></span>
    </span>
  </div>
  <div class="card__content">
    <span class="preloader _cascade">
      <span class="preloader__line"></span>
    </span>
    <span class="preloader _cascade">
      <span class="preloader__line"></span>
    </span>
    <span class="preloader _cascade">
      <span class="preloader__line"></span>
    </span>
    <span class="preloader _cascade">
      <span class="preloader__line"></span>
    </span>
  </div>
</div>
