import {
    Component,
    OnInit, Input,
    OnChanges,
    SimpleChanges,
    OnDestroy,
    ViewChild,
    ElementRef,
    forwardRef,
    NgZone,
    HostListener,
    ChangeDetectorRef,
    AfterViewInit
} from '@angular/core';
import { Location } from '@angular/common';
import { GroupFormType } from '@app/groups/model/group-form-type.model';
import { GroupInfo } from '@app/groups/model/group-info.model';
import { GroupType } from '@app/groups/model/group-type.model';
import { GroupsService } from '@app/groups/services/groups.service';
import { Router } from '@angular/router';
import { UsersService } from '@app/profile/services/users.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { BehaviorSubject, Subject } from 'rxjs';
import { AlertsService } from '@app/shared/services/alerts.service';
import { User } from '@app/profile/model/user.model';
import { SignalRService } from '@app/signalr/signalR.service';
import { IGroupUserResult } from '@app/signalr/group.hub';
import { GroupUserRole } from '@app/groups/model/group-user-role.model';
import { BaseResizableComponent } from '@app/core/components/base-resizable.component';
import { IGroupUserLink } from '@app/groups/model/group-request.model';
import { GroupMenuType, GroupMenuComponent } from '../group-menu/group-menu.component';
import { takeUntil, take } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { GroupUserStateChangedType } from '@app/groups/model/group-user-state-changed';
import { GroupRequestState } from '@app/groups/model/group-request-state.model';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-group-card',
    templateUrl: 'group-card.component.html'
})

export class GroupCardComponent extends BaseResizableComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() formType: GroupFormType;

    group: GroupInfo;
    @Input() user: User;

    groupFormType = GroupFormType;
    menuType = GroupMenuType;
    groupType = GroupType;

    private oldName: string;
    private showEditButtons: boolean;

    showGroupTypeSelect = false;
    creating = false;
    isValid = false;

    showSubscribe: boolean;
    showJoin: boolean;

    groupNameLimit = 70;

    errors = {
        title: ''
    };

    groupTypes: Array<GroupType>;

    /**
     * Название группы.
     * Автофокус на форме создания группы.
     *
     * @memberof GroupCardComponent
     */
    @ViewChild('groupName') set groupName(ref: ElementRef) {
        if (!!ref && this.formType === GroupFormType.new) {
            ref.nativeElement.focus();
        }
    }
    @ViewChild(forwardRef(() => GroupMenuComponent)) private groupMenu: GroupMenuComponent;

    countersLoaded = false;
    showCounters: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    membersCount: number;
    lastMember: IGroupUserLink;
    groupRequestsCount: number;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private location: Location,
        private router: Router,
        private sanitizer: DomSanitizer,
        private usersService: UsersService,
        private alertsService: AlertsService,
        private signalRService: SignalRService,
        protected ngZone: NgZone,
        protected dialog: MatDialog,
        private groupsService: GroupsService,
        public helper: HelperService) {
        super(helper);
        this.groupsService.currentGroupShowCounters$.next(false);
    }

    ngOnInit() {
        if (this.formType === GroupFormType.new) {
            this.group = new GroupInfo();
            this.group.type = GroupType.Open;
            this.loaded = true;
        }

        this.usersService.currentUser.subscribe(currentUser => {
            this.user = currentUser;
            this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
                if (this.formType !== GroupFormType.new) {
                    this.group = group;
                    if (this.group) {
                        this.loaded = true;
                        this.changeDetector.detectChanges();
                        if (this.groupMenu) {
                            this.groupMenu.initItems();
                        }
                        this.oldName = this.group.title;
                        this.showJoin = this.showJoinButton();
                        this.showSubscribe = this.showSubscribeButton();
                        this.onResize(null);

                        this.groupsService.userMemberStateChanged$.pipe(takeUntil(this.destroyed$))
                            .subscribe(res => {
                                if (res && res.groupId === this.group.id) {
                                    this.showJoin = this.showJoinButton();
                                    if (res.state === GroupUserStateChangedType.joined) {
                                        if (this.group.type === GroupType.Closed) {
                                            this.group.memberState = GroupRequestState.Active;
                                        } else {
                                            this.group.isMember = true;
                                        }
                                    } else {
                                        if (this.group.type === GroupType.Closed) {
                                            this.group.memberState = GroupRequestState.Declined;
                                        } else {
                                            this.group.isMember = false;
                                        }
                                    }
                                }
                            });

                        this.groupsService.userSubscriberStateChanged$.pipe(takeUntil(this.destroyed$))
                            .subscribe(res => {
                                if (res && res.groupId === this.group.id) {
                                    this.showSubscribe = this.showSubscribeButton();
                                    if (res.state === GroupUserStateChangedType.joined) {
                                        if (this.group.type === GroupType.Closed) {
                                            this.group.subscriberState = GroupRequestState.Active;
                                        } else {
                                            this.group.isSubscriber = true;
                                        }
                                    } else {
                                        if (this.group.type === GroupType.Closed) {
                                            this.group.subscriberState = GroupRequestState.Declined;
                                        } else {
                                            this.group.isSubscriber = false;
                                        }
                                    }
                                }
                            });
                    }
                }
            });
        });

        this.signalRService.groupHub.onSubscribeForCurrent.subscribe((res: IGroupUserResult) => {
            this.operateJoinSubscribe(res);
        });

        this.signalRService.groupHub.onJoinForCurrent.subscribe((res: IGroupUserResult) => {
            this.operateJoinSubscribe(res);
        });

        this.signalRService.groupHub.onUnsubscribeForCurrent.subscribe((res: IGroupUserResult) => {
            this.operateJoinSubscribe(res);
        });

        this.signalRService.groupHub.onLeaveForCurrent.subscribe((res: IGroupUserResult) => {
            this.operateJoinSubscribe(res);
        });
    }

    private operateJoinSubscribe(res: IGroupUserResult) {
        if (res && this.group.id === res.group.id) {
            this.ngZone.run(() => {
                this.group = res.group;
                if (this.groupMenu) {
                    this.groupMenu.clear();
                }
                this.showSubscribe = this.showSubscribeButton();
                this.showJoin = this.showJoinButton();
            });
        }
    }

    toggleGroupTypeSelect() {
        this.showGroupTypeSelect = !this.showGroupTypeSelect;
    }

    closeGroupTypeSelect() {
        this.showGroupTypeSelect = false;
    }

    selectGroupType(groupType: GroupType) {
        if (this.formType === GroupFormType.new) {
            this.group.type = groupType;
        } else if (this.group.type !== groupType) {
            this.groupsService.changeGroupType(this.group.id, groupType).subscribe(res => {
                if (res) {
                    this.group.type = groupType;
                    this.alertsService.riseSuccess('Тип группы был успешно изменён');
                } else {
                    this.alertsService.riseSuccess('Произошла ошибка при смене типа группы');
                }
            }, error => {
                this.errors.title = 'Произошла ошибка при смене типа группы';
            });
        }
    }

    private create() {
        this.errors.title = '';

        if (!this.creating) {
            if (this.group && this.group.title) {
                this.creating = true;
                this.groupsService.createGroup(this.group.title, this.group.description, this.group.type)
                    .subscribe(res => {
                        if (res) {
                            this.groupsService.navigateGroup(res.id);
                        } else {
                            console.log('group was not created: ' + this.group.title);
                        }
                        this.creating = false;
                    }, error => {
                        console.log(`group with name='${this.group.title}'`);
                        if (error.error && error.error.Message) {
                            this.errors.title = error.error.Message;
                            // focus and select text
                            this.groupName.nativeElement.select();
                        } else {
                            // tslint:disable-next-line:max-line-length
                            this.errors.title = 'Произошла ошибка при создании группы. Попробуйте ещё раз. Если ошибка повторится, то обратитесь к администратору.';
                        }
                        this.creating = false;
                    });

            } else {
                console.log('cannot create group without title: ' + this.group.title);
            }
        }
    }

    private cancel() {
        if (!this.creating) {
            this.usersService.user.pipe(takeUntil(this.destroyed$)).subscribe(user => {
                if (user) {
                    this.router.navigate([`profile/${user.id}/groups`]);
                } else {
                    this.location.back();
                }
            });
        }
    }

    remove() {
        if (this.formType !== GroupFormType.new) {
            this.groupsService.deleteGroup(this.group.id).subscribe(res => {
                if (res) {
                    // todo: а что делать то? Куда переходить?
                    this.usersService.currentUser.subscribe(currentUser => {
                        this.router.navigate(['/profile/' + currentUser.id + '/groups']);
                    });
                } else {
                    this.error = 'Произошла ошибка во время удаления группы';
                }
            }, error => {
                this.error = error;
            });
        }
    }

    // watch 'enter' click
    onKey(e) {
        this.isValid = e.target.value && /\S/.test(e.target.value) ? true : false;

        if (e.keyCode === 13) {
            if (this.formType === this.groupFormType.disp) {
                e.preventDefault();
                this.editTitle();
            } else {
                this.create();
            }
        } else {
            this.errors.title = null;
        }
    }

    editTitle() {
        if (this.formType === this.groupFormType.disp) {
            if (this.group.title && this.oldName !== this.group.title) {
                this.groupsService.editGroup(this.group).subscribe(res => {
                    if (res) {
                        // clear
                        this.oldName = this.group.title;
                        this.showEditButtons = false;
                        this.errors.title = null;
                        // create alert
                        this.alertsService.riseSuccess('Группа была успешно переименована');
                        // remove focus
                        this.groupName.nativeElement.blur();
                    } else {
                        this.alertsService.riseError('Произошла ошибка при попытке переименовать группу');
                    }
                }, error => {
                    if (error.error && error.error.Message) {
                        this.errors.title = error.error.Message;
                        // focus and select text
                        this.groupName.nativeElement.select();
                    }
                });
            } else if (!this.group.title) {
                console.log(`new group title is empty`);
            } else {
                console.log(`new group title is the same as old`);
            }
        }
    }

    imageError(e) {
        e.target.parentElement.style = 'background-image: ;';
        e.target.style.display = 'none';
        console.log('image not loaded for group ' + this.group.title);
    }

    imageLoaded(e) {
        e.target.parentElement.style = 'background-image: url(' + this.group.pictureUrl + ');';
        e.target.style.display = 'none';
    }

    imageChangeEvent(fileInput) {
        const files = fileInput.target.files;
        if (files && files[0]) {
            const formData: FormData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append('file', files[i], files[i].name);
            }
            this.groupsService.uploadGroupPhoto(formData, this.group.id).subscribe(res => {
                if (res) {
                    if (res.url) {
                        // new picture
                        this.group.pictureUrl = res.url;
                    } else {
                        this.group.pictureUrl = '';
                    }
                    this.group.smallPicture = undefined;
                } else {
                    console.log(res.error);
                }
            }, error => {
                console.log(error);
            });
        }
    }

    removeImage() {
        this.dialog.open(ModalConfirmationComponent, {
            data: {
                text: `Вы действительно хотите удалить фото группы?`,
                okText: 'Да',
                onOk: () => { this.removeGroupPhoto(); }
            }
        });
    }

    private removeGroupPhoto() {
        this.groupsService.removeGroupPhoto(this.group.id).subscribe(res => {
            if (res.url === '') {
                this.group.pictureUrl = res.url;
            } else if (res.error) {
                console.log(res.error);
            }
        }, error => {
            console.log(error);
        });
    }

    join() {
        this.groupsService.join(this.group.id).pipe(takeUntil(this.destroyed$)).subscribe(res => {
            if (res) {
                let text: string;
                if (this.group.type === GroupType.Closed && !this.groupsService.isAdmin(this.group, this.user)) {
                    text = 'Запрос на вступление в группу отправлен';
                    this.group.memberState = GroupRequestState.Active;
                } else {
                    text = 'Вы успешно вступили в группу';
                    this.group.isMember = true;
                }
                this.showJoin = this.showJoinButton();
                this.groupsService.currentGroup.next(this.group);
                this.groupsService.userMemberStateChanged$.next({
                    userId: this.user.id,
                    groupId: this.group.id,
                    state: GroupUserStateChangedType.joined
                });
                this.alertsService.riseSuccess(text);
            } else {
                this.alertsService.riseError('Произошла ошибка при попытке вступить в группу');
            }
        }, error => {
            this.alertsService.riseError('Произошла ошибка при попытке вступить в группу');
        });
    }

    subscribe() {
        this.groupsService.subscribe(this.group.id).pipe(take(1)).subscribe(res => {
            if (res) {
                let text: string;
                if (this.group.type === GroupType.Closed && !this.groupsService.isAdmin(this.group, this.user)) {
                    text = 'Запрос на подписку на группу отправлен';
                    this.group.subscriberState = GroupRequestState.Active;
                } else {
                    text = 'Вы успешно подписались на группу';
                    this.group.isSubscriber = true;

                }
                this.showSubscribe = this.showSubscribeButton();
                this.groupsService.currentGroup.next(this.group);
                this.groupsService.userSubscriberStateChanged$.next({
                    userId: this.user.id,
                    groupId: this.group.id,
                    state: GroupUserStateChangedType.joined
                });
                this.alertsService.riseSuccess(text);
            } else {
                this.alertsService.riseError('Произошла ошибка при попытке подписаться на группу');
            }
        }, error => {
            this.alertsService.riseError('Произошла ошибка при попытке подписаться на группу');
        });
    }

    ngAfterViewInit(): void {
        this.changeDetector.detectChanges();
    }

    // временно
    getGroupType(groupType: number) {
        switch (groupType) {
            case 0:
                return 'Открытая группа';
            case 1:
                return 'Закрытая группа';
            case 2:
                return 'Бизнес-группа';
            case 3:
                return 'Приватная группа';
        }
    }

    getGroupTypes() {
        if (this.groupTypes === undefined) {
            this.usersService.currentUser.subscribe(currentUser => {
                this.groupTypes = [GroupType.Open, GroupType.Closed, GroupType.Private];
                // only admin can create business group
                if ((currentUser.isAdmin || currentUser.isBusinessGroupsAdmin) && this.formType === GroupFormType.new) {
                    this.groupTypes.push(GroupType.Business);
                }
            });
        }
        return this.groupTypes;
    }

    // временно
    getGroupDescription(groupType: number) {
        switch (groupType) {
            case 0:
                return 'Для всех желающих';
            case 1:
                return 'Вступление по запросу';
            case 2:
                return 'Обязательна для всех сотрудников';
            case 3:
                return 'Вступление по приглашению';
        }
    }

    getPictureUrl(group: GroupInfo): SafeStyle {
        if (group.pictureUrl) {
            const style = `background-image: url('${this.groupsService.getGroupSmallPicture(group)}')`;
            return this.sanitizer.bypassSecurityTrustStyle(style);
        }
        return '';
    }

    getGroupNameLengthText() {
        return this.group ? `${this.group.title.length} из ${this.groupNameLimit}` : '';
    }

    /**
     * Отображать меню группы
     *
     * @returns
     * @memberof GroupCardComponent
     */
    showGroupMenu() {
        return (!this.showSubscribe || !this.showJoin)
            && this.formType !== this.groupFormType.new/*
            && this.group && this.groupMenu && !this.groupMenu.hideMenu*/;
    }

    isShowGroupTypes(): boolean {
        return this.formType === this.groupFormType.new
            || this.formType === this.groupFormType.disp
            && this.groupsService.isAdmin(this.group, this.user)
            && this.group && this.group.type !== this.groupType.Business;
    }

    private showJoinButton() {
        return this.formType !== GroupFormType.new
            && (!this.groupsService.isMember(this.group) && this.groupsService.canRequest(this.group, GroupUserRole.Member))
            && !this.groupsService.isAdmin(this.group, this.user)
            && this.group.type !== GroupType.Business;
    }

    private showSubscribeButton() {
        return this.formType !== GroupFormType.new
            && (!this.groupsService.isSubscriber(this.group) && this.groupsService.canRequest(this.group, GroupUserRole.Subscriber))
            && this.group.type !== GroupType.Business;
    }

    private getMembersCount() {
        if (this.group != null && this.group.id) {
            this.groupsService.getUsers(this.group.id, 0, 1).subscribe(res => {
                if (res && res.count) {
                    // all members count (minus one)
                    this.membersCount = res.count > 1 ? res.count - 1 : 0;
                    // last member picture
                    if (res.items && res.items.length) {
                        this.lastMember = res.items[0];
                        // only for closed groups
                        if (this.group.type === GroupType.Closed) {
                            // get group requests for current group
                            this.groupsService.getUserGroupRequestsCount(this.user.id, this.group.id).subscribe(requestsCount => {
                                this.groupRequestsCount = requestsCount;
                            });
                        }
                    }
                }
            });
        }
    }

    private getCounters() {
        if (this.isNotDesktop()) {
            // show counters
            const visible = this.loaded && this.formType !== GroupFormType.new;
            this.groupsService.currentGroupShowCounters$.next(visible);
            if (!this.countersLoaded) {
                this.countersLoaded = true;
                // request counters from api
                this.getMembersCount();
            }
        } else {
            // hide counters
            this.groupsService.currentGroupShowCounters$.next(false);
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.onBaseResize();
        this.getCounters();
    }
}
