import { Injectable } from '@angular/core';
import { FilesListItem } from '../model/files-list-item.model';
import { FilesService } from './files.service';
import { MatDialog } from '@angular/material/dialog';
import { IFileMediaViewData } from '../model/file-media-view-data';
import { FileMediaViewComponent } from '../components/file-media-view/file-media-view.component';

@Injectable({
  providedIn: 'root'
})
export class FilesDialogService {

  constructor(
    public dialog: MatDialog,
    private filesService: FilesService
  ) { }

  openFile(e: Event, item: FilesListItem, items: FilesListItem[]): boolean {
    const isMedia = this.filesService.isImage(item) || this.filesService.isVideo(item);

    if (isMedia) {
      e.preventDefault();
      e.stopPropagation();
      const data: IFileMediaViewData = {
        item: item,
        items: items.filter(file => this.filesService.isVideo(file) || this.filesService.isImage(file))
      };
      this.dialog.open(FileMediaViewComponent, {
        minWidth: window.innerWidth - 100,
        data: data
      });
    } else {
      return true;
    }
  }
}
