import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Helper } from '@app/core/helpers/helper';
import { HelperService } from '@app/core/services/helper.service';
import { IGift } from '../model/gift';
import { GiftApiService } from './gift-api.service';

@Injectable({
  providedIn: 'root'
})
export class GiftService extends GiftApiService {

  defaultIcon: string = this.helper.environment.assetsPrefix + '/assets/images/Avatar_80.png';
  styleCache = new Map();
  iconsCache = new Map();

  constructor(
    private sanitizer: DomSanitizer,
    protected http: HttpClient,
    public helper: HelperService
  ) { super(http, helper); }

  getIconUrl(iconUrl: string): string {
    if (iconUrl) {
      return Helper.concatUrls(this.helper.getSiteHostUrl(), iconUrl);
    }
    return this.defaultIcon;
  }

  getPictureUrl(item: IGift): SafeStyle {
    let style;

    if (this.styleCache.get(item)) {
      return this.styleCache.get(item);
    }

    if (item.pictureUrl) {
      style = `background-image: url('${this.getIconUrl(item.pictureUrl)}')`;
      style = this.sanitizer.bypassSecurityTrustStyle(style);
    }

    this.styleCache.set(item, style);

    return style || '';
  }

  getValueText(item: IGift) {
    if (item) {
      if (!item.valueText) {
        item.valueText = Helper.getNoun(item.value, 'балл', 'балла', 'баллов');
      }
      return item.valueText;
    }
    return '';
  }
}
