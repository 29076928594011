import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { FeedService } from '@app/feed/services/feed.service';
import { take, takeUntil, finalize } from 'rxjs/operators';
import { INewEmployee } from '@app/employees/model/new-employee';
import { UsersService } from '@app/profile/services/users.service';
import { SubscribeService } from '@app/subscribe/services/subscribe.service';

@Component({
  selector: 'app-new-employees-widget',
  templateUrl: './new-employees-widget.component.html',
  styleUrls: ['./new-employees-widget.component.scss']
})
export class NewEmployeesWidgetComponent extends BaseComponent implements OnInit, OnDestroy {

  items: INewEmployee[] = [];

  constructor(
    public subscribeService: SubscribeService,
    public usersService: UsersService,
    protected feedService: FeedService,
    public helper: HelperService) {
    super(helper);
  }

  ngOnInit() {
    this.feedService.getNewEmployees(0, 10)
      .pipe(take(1), finalize(() => this.loaded = true), takeUntil(this.destroyed$))
      .subscribe(res => {
        if (res) {
          res.forEach(feedEvent => {
            feedEvent.mentions.forEach(mention => {
              if (mention.user && !this.items.find(s => s.user.id === mention.user.id)) {
                const newEmployee: INewEmployee = {
                  user: mention.user,
                  postId: feedEvent.event.id,
                  groupId: feedEvent.event.ownerId
                };
                this.items.push(newEmployee);
              }
            });
          });
        }
      });
  }
}
