import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { UsersService } from '@app/profile/services/users.service';

@Component({
  selector: 'app-user-redirect',
  template: ''
})
export class UserRedirectComponent extends BaseComponent implements OnInit {

  constructor(
    private usersService: UsersService,
    private router: Router,
    private route: ActivatedRoute,
    public helper: HelperService
  ) { super(helper); }

  ngOnInit() {
    this.route.data.subscribe(data => {
      if (data && data.subUrl) {
        this.usersService.currentUser.subscribe(user => {
          if (user) {
            this.router.navigate(['profile', user.id, 'surveys']);
          }
        });
      }
    });
  }
}
