<div class="cos__header" *ngIf="loaded">
    <app-tabs [tabs]="tabs"></app-tabs>
    <a *ngIf="isAdmin && currentTab.id==2" class="btn btn-plus pull-right" [routerLink]="'new'">+</a>
</div>
  
  <!-- header preloader -->
  <app-tab-preloader *ngIf="!loaded"></app-tab-preloader>
  <!--  header preloader END -->
  
  <div class="cos__body" *ngIf="loaded && currentTab && currentTab.loaded">
    <ng-container *ngIf="currentTab.id==1">
        <app-gift-tiles-view [gifts]="currentTab?.items"></app-gift-tiles-view>
    </ng-container>  
    <ng-container *ngIf="currentTab.id==2">
        <app-gift-list-view [gifts]="currentTab?.items"></app-gift-list-view>
    </ng-container>
    <div class="co" *ngIf="currentTab?.loading">
      <mat-spinner [diameter]="56"></mat-spinner>
    </div>
  </div>
  <!-- Empty tab -->
  <div class="card" *ngIf="!currentTab?.loading && loaded && currentTab?.loaded && (!currentTab?.items || !currentTab?.items.length)">
    <div class="group-empty">
      <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" class="group-empty__img">
      <div class="group-empty__text">{{currentTab?.emptyText}}</div>
    </div>
  </div>
  <!-- Empty tab END-->
  <!-- header preloader -->
  <app-tabbed-list-preloader *ngIf="!currentTab?.loaded"></app-tabbed-list-preloader>
  <!--  header preloader END -->
  