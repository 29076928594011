<div class="cos__body" *ngIf="loaded">
  <div class="survey">
    <div class="survey__section _first">
      <div class="survey__title input-main">
        <input type="text" placeholder="Введите название награды *" [(ngModel)]="award.title" class="input-main__field"
          maxlength="255">
      </div>
      <div class="survey__description input-main">
        <textarea autosize rows="1" placeholder="Введите период награждения *" [(ngModel)]="award.period"
          class="input-main__field" maxlength="255">
          </textarea>
      </div>
      <div>
        <div>Выберите иконку для награды:</div>
        <span *ngFor="let awardIcon of icons">
          <span class="awards-image-wrap _style-one" [ngClass]="{'_active': awardIcon?.id === award.iconId}"
            (click)="choseIcon(awardIcon)">
            <img class="awards-image" [attr.src]="awardsService.getIconUrl(awardIcon?.iconUrl)">
            <button class="btn btn-remove" type="button" (click)="removeIcon(awardIcon)"></button>
          </span>
        </span>
        <div>
          <a class="btn btn-add" href="javascript:;" (click)="iconUpload.click()">Добавить иконку</a>
          <input type="file" (change)="fileChangeEvent($event)" #iconUpload multiple accept=".svg"
            style="display: none" />
        </div>
        <div class="survey__note">Требования к иконке: формат svg, размер 60*60 пикс, используются только белые контуры без заливки, графический элемент выровнен по центру.
        </div>
      </div>
      <div class="post__actions" [ngClass]="{'loading _blue': sending}">
        <div class="post__submit">
          <button type="button" class="btn btn-secondary" (click)="cancel()">Отмена</button>
          <button type="button" class="btn btn-primary" (click)="submit()"
            [attr.disabled]="isValid()?null:''">{{buttonSaveText}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
