<div class="menu">
  <button class="btn icon _menu menu__icon" type="button" (click)="toggleMenu($event)"></button>
  <!-- добавь класс "_visible" к menu__list, чтобы увидеть раскрытое меню-->
  <div class="menu__list" [ngClass]="{_visible:visible}" appClickOutside (clickOutside)="clickOutside()">
    <div class="menu__actions">
      <a href="/_layouts/15/settings.aspx" class="link"><span class="icon _settings"></span><span>Настройки</span></a>
      <a href="/_layouts/15/singout.aspx" class="link"><span class="icon _exit"></span><span>Выйти</span></a>
    </div>
    <div class="menu-list__header">
      <button class="btn icon _close menu-list__close" type="button" (click)="toggleMenu($event)"></button>
      <img src="{{assetsPrefix}}/assets/images/logo-big.svg?rev=6" class="menu-list__logo">
      <div class="pull-right menu-list__profile">
        <app-user-avatar (navigated)="clickOutside()" [noTooltip]="true" [user]="currentUser" [linkClass]="'avatar _30'"
          [imageClass]="'avatar__image'"></app-user-avatar>
      </div>
    </div>
    <app-left-menu-items [cssClass]="'menu-list__item'" (navigated)="visible=false"></app-left-menu-items>

    <app-menu-pages-list [showMenu]="visible"></app-menu-pages-list>

    <div class="menu-list">
      <a [routerLink]="['/admin/pages']" class="menu-list__item" routerLinkActive="_active"
        [routerLinkActiveOptions]="{ exact: true }" (click)="navigate()" *ngIf="currentUser?.isAdmin">
        <div class="header-text _low">
          <span class="header-text__text">Администрирование</span>
        </div>
      </a>
      <a href="#" class="menu-list__item" routerLink="/feedback" routerLinkActive="_active"
        [routerLinkActiveOptions]="{ exact: true }" (click)="navigate()">
        <div class="header-text _low">
          <span class="header-text__text">Задать вопрос</span>&nbsp;
        </div>
      </a>
    </div>

  </div>
</div>
