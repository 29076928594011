<div class="container _full-page _moved">

    <div class="card">
        <div class="no-data">
            <h1 class="no-data__title">503</h1><img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt=""
                class="no-data__img">
            <div class="no-data__text">
                <span *ngIf="showDefault">Произошла внутренняя ошибка системы.<br></span> 
                <span *ngIf="errorText">{{errorText}}<br></span>
                <span *ngIf="showDefault"> Вернуться <a routerLink="/" (click)="goBack()">назад</a>.</span>
            </div>
        </div>
    </div>
</div>