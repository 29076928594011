import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRelationsComponent } from './components/user-relations/user-relations.component';
import { UserRelationsApiService } from './services/user-relations-api.service';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule,
  ],
  declarations: [
    UserRelationsComponent,
  ],
  exports: [
    UserRelationsComponent,
  ],
  providers: [
    UserRelationsApiService,
  ]
})
export class UserRelationsModule { }
