import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { IGift, INewGiftModel, IUpdateGiftModel } from '@app/gifts/model/gift';
import { GiftService } from '@app/gifts/services/gift.service';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import { AlertsService } from '@app/shared/services/alerts.service';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { take, finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-gift-new',
  templateUrl: './gift-new.component.html',
  styleUrls: ['./gift-new.component.scss']
})
export class GiftNewComponent extends BaseComponent implements OnInit {

  gift: INewGiftModel | IUpdateGiftModel = {
    title: '',
    order: null,
    description: '',
    pictureUrl: null,
    value: 0
  };

  sending: boolean;

  protected pageRoute = `/profile/gifts`;

  constructor(
    public giftService: GiftService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected dialog: MatDialog,
    protected breadcrumbsService: BreadcrumbsService,
    protected alertsService: AlertsService,
    public helper: HelperService
  ) { super(helper); }

  ngOnInit(): void {
    this.breadcrumbsService.breadcrumbs.next([
      { title: 'Магазин подарков', routerUrl: this.pageRoute },
      { title: 'Подарок', routerUrl: this.pageRoute + `/new` }
    ]);
    this.loaded = true;
  }

  imageChangeEvent(fileInput, item: IGift) {
    const files = fileInput.target.files;
    if (files && files[0]) {
      const formData: FormData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i], files[i].name);
      }
      this.giftService.uploadPhoto(formData).subscribe(res => {
        if (res) {
          if (res) {
            // new picture
            item.pictureUrl = res;
          } else {
            item.pictureUrl = '';
          }
        }
      }, error => {
        console.log(error);
      });
    }
  }

  removeImage(item: IGift) {
    item.pictureUrl = '';
  }

  submit() {
    if (this.isValid()) {
      this.sending = true;
      this.giftService.add(this.gift)
        .pipe(finalize(() => this.sending = false), takeUntil(this.destroyed$))
        .subscribe(res => {
          if (res) {
            this.alertsService.riseSuccess('Подарок был успешно сохранён.');
            this.router.navigate(['../..'], { relativeTo: this.route, fragment: 'admin' });
          } else {
            this.alertsService.riseError('Произошла ошибка при отправке формы. Обратитесь к администратору.');
          }
        }, error => {
          this.alertsService.riseError('Произошла ошибка при отправке формы. Обратитесь к администратору.');
          console.log(error);
        });
    } else {
      this.alertsService.riseError('Произошла ошибка при отправке формы. Не заполнены обязательные поля');
    }
  }

  /**
   * Отмена сохранения баннера
   */
  cancel() {
    if (this.gift && this.gift.title && this.gift.value) { // TODO: сделать норм проверку
      this.dialog.open(ModalConfirmationComponent, {
        data: {
          text: `Вы действительно хотите отменить сохранение?`,
          onOk: this.goBackToProfile.bind(this),
          okText: 'Да'
        }
      });
    } else {
      this.goBackToProfile();
    }
  }

  private goBackToProfile() {
    this.router.navigate(['../..'], { relativeTo: this.route });
  }

  isValid(): boolean {
    return this.gift && !!this.gift.title && !!this.gift.value;
  }
}
