import { Injectable } from '@angular/core';
import { VacancyApiService } from './vacancy-api.service';
import { HelperService } from '@app/core/services/helper.service';
import { HttpClient } from '@angular/common/http';
import { IVacancy, VacancyStatus, IVacancyCategory, IVacancyResponse, ResponseStatus } from '../model/vacancy';
import { environment } from '@env/environment';
import * as moment from 'moment';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Helper } from '@app/core/helpers/helper';

/**
 * Сервис вакансий (Открытые возможности)
 *
 * @export
 * @class VacancyService
 * @extends {VacancyApiService}
 */
@Injectable({
  providedIn: 'root'
})
export class VacancyService extends VacancyApiService {

  public newResponse: BehaviorSubject<IVacancyResponse> = new BehaviorSubject(null);

  constructor(
    protected http: HttpClient,
    public helper: HelperService) { super(http, helper); }

  getStatusImageUrl(vacancy: IVacancy): string {
    if (vacancy) {
      switch (vacancy.status) {
        case VacancyStatus.published:
          return environment.assetsPrefix + '/assets/images/vacancy/vacancy_blue.svg';
        case VacancyStatus.draft:
          return environment.assetsPrefix + '/assets/images/vacancy/vacancy_stroke.svg';
        case VacancyStatus.archive:
          return environment.assetsPrefix + '/assets/images/vacancy/vacancy_grey.svg';
      }
    }
    return '';
  }

  getStatusText(vacancy: IVacancy): string {
    let result = '';
    const isPublished = vacancy.status === VacancyStatus.published;
    const isArchive = vacancy.status === VacancyStatus.archive;

    if ((isPublished || isArchive) && vacancy.publishDate) {
      result += `От ${moment(vacancy.publishDate).format('DD.MM.YY')}, `;
    }
    switch (vacancy.status) {
      case VacancyStatus.published:
        result += 'опубликована';
        break;
      case VacancyStatus.draft:
        result += 'черновик';
        break;
      case VacancyStatus.archive:
        result += 'архивная';
        break;
    }
    return result;
  }

  getResponseStatusText(response: IVacancyResponse) {
    switch (response.status) {
      case ResponseStatus.new:
        return 'новый';
      case ResponseStatus.inProcess:
        return 'в работе';
      case ResponseStatus.prospective:
        return 'перспективный';
      case ResponseStatus.declined:
        return 'отклонен';
    }
  }

  getResponseStatusCss(response: IVacancyResponse) {
    switch (response.status) {
      case ResponseStatus.new:
        return '_new';
      case ResponseStatus.inProcess:
        return '_progress';
      case ResponseStatus.prospective:
        return '_perspective';
      case ResponseStatus.declined:
        return '_rejected';
    }
  }

  /**
   * Текст количества откликов в вакансии
   *
   * @param {IVacancy} vacancy Вакансия
   * @returns
   * @memberof VacancyService
   */
  getResponsesCountText(vacancy: IVacancy) {
    const count = vacancy && vacancy.responsesCount ? vacancy.responsesCount : 0;
    return `${count} ${Helper.getNoun(count, 'отклик', 'отклика', 'откликов')}`;
  }
}
