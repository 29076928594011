import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-result',
  templateUrl: './modal-result.component.html'
})
export class ModalResultComponent implements OnInit {
  @Input() text = 'Вы действительно хотите удалить?';
  @Input() okText = 'Закрыть';
  @Output() onOk: EventEmitter<any> = new EventEmitter();
  @Input() showOk: boolean;

  constructor(public dialogRef: MatDialogRef<ModalResultComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (this.data) {
      if (this.data.okText) {
        this.okText = this.data.okText;
      }
      if (this.data.text) {
        this.text = this.data.text;
      }
      if (this.data.showOk) {
        this.showOk = this.data.showOk;
      }
    }
  }

  ok() {
    if (this.onOk) {
      this.onOk.emit();
    }
  }
}
