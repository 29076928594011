<!-- container with hyperlink -->
<a #container [attr.data-initials]="userInitials" [routerLink]="routerLink" class="avatar"
  *ngIf="(user || avatarImage!=undefined) && !asSpan && !asDiv" [ngClass]="linkClass" [appUserTooltip]="user?.id"
  [noTooltip]="noTooltip" (click)="navigate($event)">
  <ng-container *ngTemplateOutlet="avatar"></ng-container>
</a>

<!-- no link span container -->
<span #container [attr.data-initials]="userInitials" class="avatar" *ngIf="(user || avatarImage!=undefined) && asSpan"
  [ngClass]="linkClass" [appUserTooltip]="user?.id" [noTooltip]="noTooltip">
  <ng-container *ngTemplateOutlet="avatar"></ng-container>
</span>

<!-- no link div container -->
<div #container [attr.data-initials]="userInitials" *ngIf="(user || avatarImage!=undefined) && asDiv"
  [ngClass]="linkClass" [appUserTooltip]="user?.id" [noTooltip]="noTooltip">
  <ng-container *ngTemplateOutlet="avatar"></ng-container>
</div>

<ng-template #avatar>
  <span *ngIf="!noStatus && showStatus" class="avatar__status"
    [ngClass]="{'_online': isOnline(user), '_offline': isBusy(user), '_away': isAway(user)}"></span>
  <ng-container *ngIf="noWrapper; then imageNoWrapper; else imageWithWrapper"></ng-container>
</ng-template>

<ng-template #imageNoWrapper>
  <img #imgUser *ngIf="(user?.imageUrl || avatarImage!=undefined) && showPicture" style='display:none'
    [attr.src]="user?.image | async" class="{{imageDefaultClass}}" [ngClass]="imageClass" (error)="onImgError($event)"
    (load)="onImgLoad($event.target)" />
</ng-template>
<ng-template #imageWithWrapper>
  <span class="avatar__image-wrap">
    <ng-container *ngTemplateOutlet="imageNoWrapper"></ng-container>
  </span>
</ng-template>