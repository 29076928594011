import { AbstractSecurityStorage } from 'angular-auth-oidc-client';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@app/intry-storage/services/local-storage.service';

@Injectable()
export class IntrySecurityStorageService implements AbstractSecurityStorage {

  constructor(private storage: LocalStorageService) {
  }

  public read(key: string): any {
    return this.storage.read(key, null);
  }

  public remove(key: string): void {
    this.storage.remove(key);
  }

  public write(key: string, value: any): void {
    this.storage.write(key, value);
  }

}
