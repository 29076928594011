import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { Alert, AlertType } from '@app/shared/components/alerts/alert';

/**
 * Сервис алёртов
 *
 * @export
 * @class AlertsService
 */
@Injectable()
export class AlertsService {
  public alert: Subject<Alert> = new BehaviorSubject<Alert>(null);

  readonly DELAY_HIDE: number = 5000;

  constructor() { }

  /**
   * Создать новый алёрт об успехе
   *
   * @param {string} title
   * @memberof AlertsService
   */
  public riseSuccess(title: string, delayHide: number = null): void {
    this.alert.next({
      text: title,
      type: AlertType.Success,
      delayHide: delayHide ? delayHide : this.DELAY_HIDE
    });
  }

  /**
   * Создать новый алёрт о неудача
   *
   * @param {string} title
   * @memberof AlertsService
   */
  public riseError(title: string, delayHide: number = null): void {
    this.alert.next({
      text: title,
      type: AlertType.Error,
      delayHide: delayHide ? delayHide : this.DELAY_HIDE
    });
  }
}
