import {Component} from '@angular/core';
import {Tabs, Tab} from '@app/shared/components/tabs/tab.model';
import {Router, ActivatedRoute} from '@angular/router';
import {ScrollableListComponent} from '@app/shared/components/scrollable-list/scrollable-list.component';
import {HelperService} from '@app/core/services/helper.service';

declare var $: any;

/**
 * Компонент с вкладками
 */
@Component({
  selector: 'app-tabbed-list',
  templateUrl: 'tabbed-list.component.html',
})
export class TabbedListComponent<T> extends ScrollableListComponent {
  /**
   * Все вкладки
   */
  tabs: Tabs<T>;
  /**
   * Текущая вкладка
   */
  currentTab: Tab<T>;

  protected margin = 20;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    public helper: HelperService,
  ) {
    super(helper);
  }

  /**
   * Сбросить данные табика
   */
  protected cleanTab(tab: Tab<T>) {
    tab.items = [];
    tab.offset = 0;
    tab.loaded = false;
    tab.allLoaded = false;
  }

  /**
   * Действие при прокрутке страницы.
   * Загружаем остальные элементы, учитывая offset и limit
   */
  protected onScroll() {
    const number = window.innerHeight + window.pageYOffset + this.margin;
    if (
      this.currentTab &&
      this.currentTab.items &&
      number > this.maxItemHeight * this.currentTab.items.length
    ) {
      if (!this.currentTab.loading) {
        if (
          this.currentTab.itemsCount > this.currentTab.items.length &&
          !this.currentTab.allLoaded
        ) {
          this.currentTab.method(this.currentTab, this);
        }
      }
    }
  }
}
