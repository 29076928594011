import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '@app/core/services/helper.service';
import { IdeasApiService } from './ideas-api.service';
import { environment } from '@env/environment';
import { IIdea } from '../model/idea';
import { Subject } from 'rxjs';
import { IIdeaMenuConfig } from '../components/idea-menu/idea-menu.component';
import { GroupInfo } from '@app/groups/model/group-info.model';
import { User } from '@app/profile/model/user.model';

/**
 * Сервис идей
 *
 * @export
 * @class IdeasService
 * @extends {IdeasApiService}
 */
@Injectable({
  providedIn: 'root'
})
export class IdeasService extends IdeasApiService {

  ideaAdded$: Subject<void> = new Subject();
  ideaChanged$: Subject<IIdea> = new Subject();
  ideaDeleted$: Subject<number> = new Subject();

  menuItemAdminConfig: IIdeaMenuConfig = {
    allowDelete: true,
    allowEdit: true,
    allowPublish: true
  };

  menuItemAdminNoEditConfig: IIdeaMenuConfig = {
    allowDelete: true,
    allowEdit: false,
    allowPublish: true
  };

  menuItemAuthorConfig: IIdeaMenuConfig = {
    allowDelete: true,
    allowEdit: true,
    allowPublish: false
  };

  constructor(
    protected http: HttpClient,
    public helper: HelperService) { super(http, helper); }

  getStatusImageUrl(idea: IIdea): string {
    if (idea) {
      return environment.assetsPrefix + '/assets/images/ideas/idea-list.svg';
    }
    return '';
  }

  getStatusText(idea: IIdea) {
    if (idea) {
      if (idea.status) {
        return idea.status.title;
      }
    } else {
      return '';
    }
  }

  getIdeaConfig(idea: IIdea, isAdmin: boolean, currentUser: User): IIdeaMenuConfig {
    if (isAdmin) {
      if (idea && idea.author && currentUser && idea.author.id === currentUser.id) {
        return this.menuItemAdminConfig;
      } else {
        return this.menuItemAdminNoEditConfig;
      }
    } else if (idea && idea.author && currentUser && idea.author.id === currentUser.id) {
      return this.menuItemAuthorConfig;
    }
    return null;
  }
}
