<ng-container *ngIf="isVisible()">
  <div class="btn btn-dots tooltip" [ngClass]="{'btn-dots-white': whiteDots}" mat-button [matMenuTriggerFor]="menu"></div>
  <mat-menu #menu="matMenu">
    <div class="icon-menu" #menuContainer>
      <ng-container *ngIf="selectedItems?.length>1; then multipleItemsMenu; else singleItemMenu"></ng-container>
      <ng-template #singleItemMenu>
        <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="config?.allowResults"
          (click)="resultsClick()">
          <span class="icon-menu__icon">
            <img src="{{assetsPrefix}}/assets/images/icons/eye-progress.svg" alt="" class="icon-menu__img">
          </span>
          <span>Посмотреть результаты</span>
        </a>
        <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="config?.allowEdit"
          (click)="editClick()">
          <span class="icon-menu__icon">
            <img src="{{assetsPrefix}}/assets/images/icons/menu/edit.svg" alt="" class="icon-menu__img">
          </span>
          <span>Редактировать</span>
        </a>
        <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="config?.allowResults" (click)="openCopyItem()">
          <span class="icon-menu__icon">
            <img src="{{assetsPrefix}}/assets/images/icons/menu/copy.svg" alt="" class="icon-menu__img">
          </span>
          <span>Копировать</span>
        </a>
        <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="config?.allowDelete"
          (click)="deleteClick()">
          <span class="icon-menu__icon">
            <img src="{{assetsPrefix}}/assets/images/icons/menu/remove.svg" alt="" class="icon-menu__img">
          </span>
          <span>Удалить</span>
        </a>
      </ng-template>
      <ng-template #multipleItemsMenu>
        <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="config?.allowDelete"
          (click)="deleteClick()">
          <span class="icon-menu__icon">
            <img src="{{assetsPrefix}}/assets/images/icons/menu/remove.svg" alt="" class="icon-menu__img">
          </span>
          <span>Удалить</span>
        </a>
      </ng-template>
    </div>
  </mat-menu>
</ng-container>
