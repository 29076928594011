import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { SessionStorageService } from '@app/intry-storage/services/session-storage.service';
import { EventTypes, PublicEventsService } from 'angular-auth-oidc-client';
import { filter } from 'rxjs/operators';

@Injectable()
export class IntryAuthRedirectService {

  private static STORAGE_KEY = 'IntryAuthRedirectRoute';
  private static QUERY_PARAMS = 'IntryAuthRedirectQueryParams';

  constructor(
    private storage: SessionStorageService,
    private router: Router,
    private events: PublicEventsService,
  ) {}

  public subscribe(): void {
    this.events
      .registerForEvents()
      .pipe(
        filter(notification => notification.type === EventTypes.NewAuthorizationResult),
      )
      .subscribe(() => this.redirectIfNeeded());
  }

  public redirectIfNeeded(): Promise<boolean> {
    const route: string = this.storage.read(IntryAuthRedirectService.STORAGE_KEY);

    if (route) {
      // tslint:disable-next-line:no-console
      console.debug(`[IntryAuthRedirectService.redirectIfNeeded] Redirect to "${route}"`);
      this.reset();
      return this.router.navigateByUrl(route, {replaceUrl: true}).then(result => {
        if (!result) {
          return this.router.navigate(['/'], {replaceUrl: true});
        }

        return true;
      });
    }

    return Promise.resolve(false);
  }

  public setAuthRedirectRoute(url: string, queryParams: Params = null): void {
    // tslint:disable-next-line:no-console
    console.debug(
      `[IntryAuthRedirectService.setAuthRedirectRoute] The new redirect url is "${url}"`,
    );

    this.storage.write(IntryAuthRedirectService.STORAGE_KEY, url);
    this.storage.write(IntryAuthRedirectService.QUERY_PARAMS, queryParams);
  }

  public reset(): void {
    this.storage.remove(IntryAuthRedirectService.STORAGE_KEY);
    this.storage.remove(IntryAuthRedirectService.QUERY_PARAMS);
  }
}
