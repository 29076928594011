import { Component, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  AttachmentsTreeComponent,
  AttachmentsDialogMode
} from '@app/attachments/file-attachments/components/attachments-tree/attachments-tree.component';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { FilesService } from '@app/files/services/files.service';
import { GroupsService } from '@app/groups/services/groups.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Меню добавления вложений
 *
 * @export
 * @class AttachmentsMenuComponent
 * @extends {BaseComponent}
 */
@Component({
  selector: 'app-attachments-menu',
  templateUrl: './attachments-menu.component.html'
})
export class AttachmentsMenuComponent extends BaseComponent implements OnDestroy {

  @Input() multiple = true;
  @Input() noGroupFiles: boolean;

  @ViewChild('fileUpload', { static: true }) fileUpload: ElementRef;

  /**
   * При выборе из существующих
   *
   * @type {EventEmitter<any>}
   * @memberof AttachmentsMenuComponent
   */
  @Output() choose: EventEmitter<any> = new EventEmitter();

  /**
   * При загрузке с компьютера
   *
   * @type {EventEmitter<any>}
   * @memberof AttachmentsMenuComponent
   */
  @Output() upload: EventEmitter<any> = new EventEmitter();

  isVisible: boolean;

  attachmentsTreeDialog: MatDialogRef<AttachmentsTreeComponent>;

  attachmentsTreeType = AttachmentsDialogMode;

  constructor(
    public groupsService: GroupsService,
    public filesService: FilesService,
    public helper: HelperService,
    protected dialog: MatDialog) { super(helper); }

  hide() {
    this.isVisible = false;
  }

  toggleVisibility() {
    this.isVisible = !this.isVisible;
  }

  showAttachmentsTree(mode: AttachmentsDialogMode) {
    this.attachmentsTreeDialog = this.dialog.open(AttachmentsTreeComponent, {
      minWidth: '50vw',
      maxWidth: '90vw',
      minHeight: '50vh',
      maxHeight: '90vh',
      data: {
        skipCurrent: false,
        foldersOnly: false,
        onOk: this.onChoose.bind(this),
        okText: 'Добавить',
        headerText: 'Выберите файл',
        allowCreateFolder: false,
        mode: mode,
        noGroupFiles: this.noGroupFiles
      }
    });
  }

  onChoose(event) {
    this.choose.emit(event);
    this.attachmentsTreeDialog.close();
  }

  onUpload(event) {
    this.upload.emit(event);
    // hide menu
    this.hide();
  }

  /**
   * Отобразить элемент меню выбора в группе
   *
   * @returns {Observable<boolean>}
   * @memberof MediaAttachmentsMenuComponent
   */
  showGroupMenu(): Observable<boolean> {
    if (this.noGroupFiles) {
      return of(false);
    }
    return this.groupsService.selectedGroup.pipe(map(res => res ? true : false));
  }

  showFilesMenu(): Observable<boolean> {
    return this.filesService.showUserFiles$.pipe(map(res => res ? true : false));
  }

  ngOnDestroy(): void {
    if (this.fileUpload) {
      this.fileUpload.nativeElement.value = '';
    }
    super.ngOnDestroy();
  }
}
