import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '@app/profile/model/user.model';
import { AddUsersBaseComponent } from '@app/shared/components/add-users-base/add-users-base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '@app/profile/services/users.service';
import { HelperService } from '@app/core/services/helper.service';
import { ThanksService } from '@app/thanks/services/thanks.service';
import { take } from 'rxjs/operators';
import { AlertsService } from '@app/shared/services/alerts.service';

/**
 * Отправить благодарность
 *
 * @export
 * @class NewThankComponent
 * @extends {AddUsersBaseComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-new-thank',
  templateUrl: './new-thank.component.html',
  styleUrls: ['./new-thank.component.scss']
})
export class NewThankComponent extends AddUsersBaseComponent implements OnInit, OnDestroy {

  /**
   * Текст благодарности
   */
  thankText: string;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public thanksService: ThanksService,
    public usersService: UsersService,
    public alertsService: AlertsService,
    public dialogRef: MatDialogRef<NewThankComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public helper: HelperService
  ) { super(route, router, usersService, helper); }

  ngOnInit() {
    super.ngOnInit();
    if (this.data.onOk) {
      this.onOk = this.data.onOk;
    }
    if (this.data.onCancel) {
      this.onCancel = this.data.onCancel;
    }
    if (this.data.usersToAdd) {
      this.usersToAdd = this.data.usersToAdd;
    }
  }

  addUsers() {
    if (this.isFormValid()) {
      this.thanksService.thank(this.usersToAdd[0].id, this.thankText)
        .pipe(take(1))
        .subscribe((res) => {
          if (res) {
            this.alertsService.riseSuccess('«Спасибо» отправлено');
          } else {
            this.alertsService.riseError(this.thanksService.ERROR_ON_THANK);
          }
          super.addUsers();
        }, () => {
          this.alertsService.riseError(this.thanksService.ERROR_ON_THANK);
        });
    }
  }

  addUser(user: User) {
    if (!this.usersToAdd.find(u => u.id === user.id)) {
      this.usersToAdd = [];
      this.usersToAdd.push(user);
      this.usersFound = [];
      this.searchText = '';
    } else {
      this.removeUser(user);
    }
    this.isFormValid();
  }

  isFormValid(): boolean {
    this.isValid = !!(this.usersToAdd && this.usersToAdd.length && this.thankText);
    return this.isValid;
  }
}
