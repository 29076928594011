import { Component, OnDestroy } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { Router } from '@angular/router';
import { HelperService } from '@app/core/services/helper.service';
import { IVacancy, VacancyStatus } from '@app/vacancy/model/vacancy';

/**
 * Базовый виджет вакансий
 *
 * @export
 * @class VacancyWidgetComponent
 * @extends {BaseComponent}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-vacancy-widget',
  template: ''
})
export class VacancyWidgetComponent extends BaseComponent implements OnDestroy {

  /**
   * Массив вакансий
   */
  vacancies: IVacancy[];

  /**
   * Ссылка перехода в раздел опросов
   */
  link = '/vacancy';

  /**
   * Отображать виджет (только для пользователей у которых есть права)
   */
  showWidget: boolean;

  /**
   * Отображать ссылку создания нового опроса
   */
  showAddButton: boolean;

  /**
   * Ссылка создания нового опроса
   */
  addButtonLink: string;

  VacancyStatus = VacancyStatus;

  constructor(
    protected router: Router,
    public helper: HelperService
  ) { super(helper); }

  navigate() {
    this.router.navigate([this.link]);
  }

  onDeleted(id: number) {
    this.vacancies = this.vacancies.filter(s => s.id !== id);
  }

  /**
   * Отображать заглушку?
   */
  isShowEmpty(): boolean {
    return this.showWidget && this.loaded && (!this.vacancies || !this.vacancies.length);
  }
}
