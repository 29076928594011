import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { IntryAuthService } from '../services/intry-auth.service';
import { IntryGuard } from './intry.guard';

@Injectable({ providedIn: 'root' })
export class IntryAuthenticatedGuard extends IntryGuard implements CanActivate {

  constructor(auth: IntryAuthService, router: Router) {
    super(auth, router);
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.check((isAuthorized: boolean) => {
      if (!isAuthorized) {
        this.forbidden();
        return false;
      }

      if (this.auth.isExternalUser() && !this.auth.isExternalUsersAllowed()) {
        this.forbidden();
        return false;
      }

      return true;
    });
  }

}
