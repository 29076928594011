<div class="cos__body">
  <div class="list-item" *ngFor="let vacancy of vacancies; trackBy:trackBy">
    <img [src]="vacancyService.getStatusImageUrl(vacancy)" class="list-item__status">
    <div class="list-item__desc">
      <a class="list-item__title" routerLink="/group/{{vacancy?.ownerId}}/vacancy/{{vacancy?.id}}">{{vacancy.title}}</a>
      <div class="list-item__subtitle" *ngIf="vacancy.location">{{vacancy.location}}</div>
      <div class="list-item__subtitle" *ngIf="vacancy.category">{{vacancy.category?.title}}</div>
      <div class="list-item__subtitle">{{vacancyService.getStatusText(vacancy)}}</div>
      <div class="list-item__subtitle" *ngIf="vacancy.isResponded">Вы откликнулись</div>
      <a *ngIf="!hideGroupsTitle" class="list-item__dir" routerLink="/group/{{vacancy?.ownerId}}/vacancy">{{vacancy.group?.title}}</a>
    </div>
    <app-vacancy-menu [item]="vacancy" [config]="getVacancyConfig(vacancy)" [vacancyLocation]="2" (deleted)="onDeleted($event)"></app-vacancy-menu>
  </div>
</div>
