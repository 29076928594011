import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  NgZone,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from '@app/core/components/base.component';
import { User } from '@app/profile/model/user.model';
import { UsersService } from '@app/profile/services/users.service';
import { SignalRService } from '@app/signalr/signalR.service';
import { Helper } from '@app/core/helpers/helper';
import { HelperService } from '@app/core/services/helper.service';
import { SkypeService } from '@app/skype/services/skype.service';

declare var $: any;

/**
 * Компонент отображения аватара пользователя
 *
 * @export
 * @class UserAvatarComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 * @implements {OnChanges}
 * @implements {AfterViewInit}
 */
@Component({
  selector: 'app-user-avatar',
  templateUrl: 'user-avatar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent extends BaseComponent implements OnInit, OnChanges {
  // необходимо указать либо юзера либо картинку
  @Input() user: User;
  @Input() avatarImage: string;

  /**
   * CSS-класс для блока гиперссылки
   *
   * @type {string}
   * @memberof UserAvatarComponent
   */
  @Input() linkClass: string;

  /**
   * CSS-класс для блока картинки
   *
   * @type {string}
   * @memberof UserAvatarComponent
   */
  @Input() imageClass: string;

  /**
   * Не выполнять переход на пользователя
   *
   * @type {boolean}
   * @memberof UserAvatarComponent
   */
  @Input() noRedirect: boolean;

  /**
   * Не отображать всплывашку информации о пользователе
   *
   * @type {boolean}
   * @memberof UserAvatarComponent
   */
  @Input() noTooltip: boolean;

  /**
   * Не отображать статус присутствия
   *
   * @type {boolean}
   * @memberof UserAvatarComponent
   */
  @Input() noStatus: boolean;

  /**
   * Отрисовывать в span контейнере
   *
   * @type {boolean}
   * @memberof UserAvatarComponent
   */
  @Input() asSpan: boolean;

  /**
   * Отрисовывать в div контейнере
   *
   * @type {boolean}
   * @memberof UserAvatarComponent
   */
  @Input() asDiv: boolean;

  /**
   * Без блока-обёртки картинки
   *
   * @type {boolean}
   * @memberof UserAvatarComponent
   */
  @Input() noWrapper: boolean;

  /**
   * Не пересчитывать отображение картинки
   *
   * @type {boolean}
   * @memberof UserAvatarComponent
   */
  @Input() noRecalculate: boolean;

  /**
   * CSS-класс блока картинки (img) по-умолчанию
   *
   * @memberof UserAvatarComponent
   */
  @Input() imageDefaultClass = 'avatar__image';

  /**
   * Отображать всплывающее окно?
   *
   * @type {boolean}
   * @memberof UserAvatarComponent
   */
  @Input() tooltipVisible: boolean;

  /**
   * Ссылка для перехода по картинке
   *
   * @type {string}
   * @memberof UserAvatarComponent
   */
  @Input() routerLink: string;

  @ViewChild('imgUser') imgUser: ElementRef;
  @ViewChild('container') container: ElementRef;

  @Input() name: string;

  @Input() inNewTab: boolean;

  @Output() navigated: EventEmitter<void> = new EventEmitter();

  showPicture = true;
  showStatus = false;
  elm_edges: any;

  userInitials: string;

  constructor(
    private router: Router,
    private usersService: UsersService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private signalRService: SignalRService,
    public helper: HelperService,
    public skypeService: SkypeService,
  ) {
    super(helper);
  }

  ngOnInit() {
    this.signalRService.mainHub.onUserPictureChanged.subscribe(res => {
      this.ngZone.run(() => {
        if (res && this.user && res.userId === this.user.id) {
          this.setUserPhoto(res.url);
        }
      });
    });

    this.usersService.userPhotoChanged$.subscribe(res => {
      if (res && this.user && res.userId === this.user.id) {
        this.setUserPhoto(res.url);
      }
    });

    this.usersService.userPhotoDeleted$.subscribe(res => {
      if (res && this.user && res.userId === this.user.id) {
        this.clearUserPhoto();
      }
    });

    this.skypeService.initialized.subscribe(res => {
      this.showStatus = res;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['user']) {
      if (this.user) {
        if (!this.noRedirect) {
          this.routerLink = `/profile/${this.user.id}`;
        }
        this.setUserPhoto(null);
        this.skypeService.subscribeToUser(this.user);
      }
      this.showPicture = true;
    }

    if (changes['avatarImage']) {
      if (this.avatarImage || this.avatarImage === null) {
        this.showPicture = true;
        this.userInitials = this.getInitials();
        // async update picture
        this.user = new User();
        this.user.image.next(this.avatarImage);
      } else if (!this.avatarImage) {
        this.userInitials = this.getInitials();
      }
    }
  }

  private setUserPhoto(newPicture: string) {
    if (this.user.image === undefined) {
      this.user.image = new BehaviorSubject<string>(undefined);
    }

    // get new pictures
    const pic = this.getImageUrl(newPicture);

    // show picture if needed
    this.showPicture = pic ? true : false;

    if (!this.showPicture) {
      this.setColor();
      this.userInitials = this.getInitials();
    }

    // async update picture
    this.user.image.next(pic);
    this.user.pictureUrl = pic;
    this.avatarImage = pic;

    this.cdr.detectChanges();
  }

  private clearUserPhoto() {
    if (this.user.image === undefined) {
      this.user.image = new BehaviorSubject<string>(undefined);
    }

    this.setColor();
    this.userInitials = this.getInitials();

    this.user.imageUrl = this.user.pictureUrl = this.avatarImage = '';
    this.showPicture = false;

    // async update picture
    this.user.image.next('');

    this.cdr.detectChanges();
  }

  /**
   * Действие при ошибке загрузки картинки.
   * Устанавливаем цвет заглушки и инициалы.
   *
   * @param {*} e
   * @memberof UserAvatarComponent
   */
  onImgError(e) {
    this.showPicture = false;
    this.setColor();
    this.userInitials = this.getInitials();
    this.loaded = true;
  }

  /**
   * Установить цвет заглушки
   *
   * @private
   * @memberof UserAvatarComponent
   */
  private setColor() {
    if (this.container) {
      this.container.nativeElement.style.backgroundColor = this.getColor(this.user);
    }
  }

  onImgLoad(target) {
    const elem = $(target);
    if (!this.noRecalculate) {
      const height = elem.height();
      elem.height('inherit');
      elem.width('inherit');
      // todo: сделать это нормально
      if (target.naturalWidth > target.naturalHeight) {
        elem.height('100%');
        elem.width('auto');
        elem.css('max-height', '100%');
        elem.css('max-width', 'none');
      } else {
        elem.width('100%');
        elem.height('auto');
        elem.css('max-width', '100%');
        elem.css('max-height', 'none');
      }
    }
    elem.css('display', 'inherit');
    // elem.css('visibility', 'hidden');
    // elem.parent().css('background-image', `url(${elem.prop('src')})`);
    this.loaded = true;
  }

  navigate(e: Event) {
    if (this.routerLink) {
      if (this.inNewTab) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        window.open(
          Helper.concatUrls(this.helper.environment.startPage, this.routerLink),
        );
        return false;
      } else {
        this.router.navigate([this.routerLink]);
        this.navigated.emit();
      }
    }
  }
  /**
   * Получить/обработать url картинки
   *
   * @private
   * @param string newPicture
   * @returns
   * @memberof UserAvatarComponent
   */
  private getImageUrl(newPicture: string) {
    if (this.user) {
      const imageUrl = (<any>this.user).imageUrl;

      if (imageUrl !== '' && newPicture === '') {
        return (<any>this.user).imageUrl = '';
      }

      if (imageUrl !== undefined
        && imageUrl !== null
        && (newPicture === null || imageUrl.indexOf(newPicture) !== -1)) {
        return imageUrl;
      }
      const win = <any>window;

      if (newPicture === '') {
        return (<any>this.user).imageUrl = '';
      } else if (newPicture !== null && newPicture !== undefined) {
        const prefixUrl = this.user.pictureUrl.indexOf('/api/documentFile') !== -1
          ? this.helper.getSiteUrl()
          : Helper.concatUrls(win.Intry.mySiteHost, 'User photos/Profile pictures');

        return (<any>this.user).imageUrl = Helper.concatUrls(prefixUrl, newPicture);
      }

      if (this.user.pictureUrl) {
        const prefixUrl = this.user.pictureUrl.indexOf('/api/documentFile') !== -1
          ? this.helper.getSiteUrl()
          : Helper.concatUrls(win.Intry.mySiteHost, 'User photos/Profile pictures');

        return (<any>this.user).imageUrl = Helper.concatUrls(prefixUrl, this.user.pictureUrl);
      }
      // return this.user.pictureUrl;
      // return `/_layouts/15/userphoto.aspx?default=none&size=L&username=${this.user.accountName}`;
    } else {
      return '';
    }
  }

  /**
   * Определить и получить цвет-заглушку
   *
   * @private
   * @param * user
   * @returns
   * @memberof UserAvatarComponent
   */
  private getColor(user: any) {
    if (user && user.id) {
      if (!user.color) {
        user.color = Helper.getColorById(user.id);
      }
    }
    return user.color;
  }

  /**
   * Получение инициалов пользователя
   *
   * @returns
   * @memberof UserAvatarComponent
   */
  getInitials() {
    if (this.user) {
      return this.usersService.getInitials(this.user);
    } else if (this.name) {
      return this.name
        .split(' ')
        .map(function (s) {
          return s.charAt(0);
        })
        .join('')
        .substr(0, 3);
    }
  }

  isOnline(user: User) {
    return Helper.isOnline(user);
  }

  isBusy(user: User) {
    return Helper.isBusy(user);
  }

  isAway(user: User) {
    return Helper.isAway(user);
  }
}
