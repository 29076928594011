import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { PagesService } from '@app/pages/services/pages.service';
import { IPage, INewLink, IUpdateLink } from '@app/pages/model/page';
import { AlertsService } from '@app/shared/services/alerts.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';

export interface IPageLinkModalComponentData {
  onOk: EventEmitter<IPage> | Function;
  isNew: boolean;
  link: IPage;

  /**
   * Заголовок модального окна
   *
   * @type {string}
   * @memberof IPageLinkModalComponentData
   */
  text: string;
  parentId: number;
  ownerId: number;
}

@Component({
  selector: 'app-page-link-modal',
  templateUrl: './page-link-modal.component.html',
  styleUrls: ['./page-link-modal.component.scss']
})
export class PageLinkModalComponent {

  title: string;
  url: string;
  inNewTab: boolean;

  /**
   * Текст ошибки
   *
   * @type {string}
   * @memberof PageLinkModalComponent
   */
  error: string;

  /**
   * Статус загрузки
   *
   * @type {boolean}
   * @memberof PageLinkModalComponent
   */
  loading: boolean;

  constructor(
    private pagesService: PagesService,
    protected alertsService: AlertsService,
    protected dialog: MatDialog,
    protected cancelDialog: MatDialogRef<ModalConfirmationComponent>,
    public dialogRef: MatDialogRef<PageLinkModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IPageLinkModalComponentData
  ) {
    if (!this.data.isNew && this.data.link) {
      this.title = this.data.link.title;
      this.url = this.data.link.url ? this.data.link.url : null;
      this.inNewTab = this.data.link.inNewTab;
    }
  }

  /**
   * Отправить данные формы на сервер
   *
   * @memberof PageLinkModalComponent
   */
  save() {

    if (this.data.isNew) {
      const body: INewLink = {
        title: this.title,
        url: this.url ? this.url : null,
        inNewTab: this.inNewTab,
        parentId: this.data.parentId ? this.data.parentId : null,
        ownerId: this.data.ownerId ? this.data.ownerId : null
      };

      this.pagesService.createLink(body).pipe(take(1)).subscribe(res => {
        if (res) {
          this.alertsService.riseSuccess(`Ссылка была успешно создана`);
          this.emitOk(res);
        } else {
          this.alertsService.riseSuccess(`Произошла ошибка создания ссылки`);
        }

      }, error => {
        console.log(error);
        this.alertsService.riseSuccess(`Произошла ошибка создания ссылки`);
      });
    } else {
      const body: IUpdateLink = {
        id: this.data.link.id,
        title: this.title,
        url: this.url ? this.url : null,
        inNewTab: this.inNewTab
      };
      this.pagesService.updateLink(body.id, body).pipe(take(1)).subscribe(res => {
        if (res) {
          this.alertsService.riseSuccess(`Ссылка была успешно обновлена`);
          this.data.link.title = body.title;
          this.data.link.url = body.url ? body.url : null;
          this.data.link.inNewTab = body.inNewTab;
          this.emitOk(body);
        } else {
          this.alertsService.riseSuccess(`Произошла ошибка обновления ссылки`);
        }
      }, error => {
        console.log(error);
        this.alertsService.riseSuccess(`Произошла ошибка обновления ссылки`);
      });
    }
  }

  showConfirmDialog() {
    if (!this.title && !this.url) {
      return this.dialogRef.close();
    }

    this.cancelDialog = this.dialog.open(ModalConfirmationComponent, {
      data: {
        text: `Вы действительно хотите отменить добавление ссылки?`,
        onOk: () => this.dialogRef.close(),
        okText: 'Да',
      }
    });
  }

  /**
   * Валидна ли форма?
   *
   * @returns {boolean}
   * @memberof PageLinkModalComponent
   */
  isValid(): boolean {
    return this.title ? true : false;
  }

  private emitOk(res: any) {
    if (this.data.onOk) {
      if (this.data.onOk instanceof EventEmitter) {
        this.data.onOk.emit(res);
      } else {
        this.data.onOk(res);
      }
    }
  }
}
