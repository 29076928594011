import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from '@app/shared/components/404/page-not-found.component';
import { AddUsersComponent } from '@app/shared/components/add-users/add-users.component';
import { AddTagsComponent, AddTagsComponentMode } from '@app/shared/components/add-tags/add-tags.component';
import { AlertsComponent } from '@app/shared/components/alerts/alerts.component';
import { BackLinkComponent } from '@app/shared/components/back-link/back-link.component';
import { EditorComponent } from '@app/shared/components/editor/editor.component';
import { GobackComponent } from '@app/shared/components/goback/goback.component';
import { MessageComponent } from '@app/shared/components/message/message.component';
import { ModalResultComponent } from '@app/shared/components/modals/modal-result/modal-result.component';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import { TabPreloaderComponent } from '@app/shared/components/preloaders/tab-preloader/tab-preloader.component';
import { TabbedListComponent } from '@app/shared/components/tabs/tabbed-list.component';
import { ScrollableListComponent } from '@app/shared/components/scrollable-list/scrollable-list.component';
import { TabbedListPreloaderComponent } from '@app/shared/components/preloaders/tabbed-list-preloader/tabbed-list-preloader.component';
import { TabsComponent } from '@app/shared/components/tabs/tabs.component';
import { UserAvatarComponent } from '@app/shared/components/user-avatar/user-avatar.component';
import { VideoPlayerComponent } from '@app/shared/components/video-player/video-player.component';
import { ClickOutsideDirective } from '@app/shared/directives/click-outside.directive';
import { FileIconDirective } from '@app/shared/directives/file-icon.directive';
import { InfiniteScrollDirective } from '@app/shared/directives/infinite-scroll.directive';
import { IslastDirective } from '@app/shared/directives/islast.directive';
import { TextareaAutosizeDirective } from '@app/shared/directives/textarea-autosize.directive';
import { DateSpLikePipe } from '@app/shared/pipes/date-sp-like.pipe';
import { FileSizePipe } from '@app/shared/pipes/file-size.pipe';
import { HighlightPipe } from '@app/shared/pipes/highlight.pipe';
import { AlertsService } from '@app/shared/services/alerts.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RouterModule } from '@angular/router';
import { QuillModule } from 'ngx-quill-wrapper';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '@env/environment';
import { SafeHtmlPipe } from '@app/shared/pipes/safe-html.pipe';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { DynamicContainerDirective } from './directives/dynamic-container.directive';
import { CoreModule } from '@app/core/core.module';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
import { ProfileSelectorComponent } from './components/profile-selector/profile-selector.component';
import { SafePipe } from './pipes/safe.pipe';
import { UserHeaderComponent } from './components/user-header/user-header.component';
import { SortableListDirective } from './directives/sortable/sortable-list.directive';
import { SortableDirective } from './directives/sortable/sortable.directive';
import { DraggableDirective } from './directives/draggable/draggable.directive';
import { DraggableHelperDirective } from './directives/draggable/draggable-helper.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { DroppableDirective } from './directives/droppable/droppable.directive';
import { DropZoneDirective } from './directives/droppable/drop-zone.directive';
import { UserTooltipDirective } from './directives/user-tooltip.directive';
import { TreeModule } from '@circlon/angular-tree-component';
import { EmptyWidgetContentComponent } from './components/empty-widget-content/empty-widget-content.component';
import { EditorService } from './services/editor.service';
import { CookieService } from 'ngx-cookie-service';
import { UserSimpleCardComponent } from './components/user-simple-card/user-simple-card.component';
import { AutosizeModule } from 'ngx-autosize';
import { WidgetBaseComponent } from './components/widget-base.component';
import { MissionComponent } from './components/mission/mission.component';
import { ContainerComponent } from './components/container/container.component';
import { ForbiddenComponent } from './components/403/forbidden.component';
import { FileUploadService } from './services/file-upload.service';
import { UserAbsenceComponent } from './components/user-absence/user-absence.component';
import { SettingsService } from './services/settings.service';
import { ProfileSelectorModalComponent } from './components/profile-selector-modal/profile-selector-modal.component';
import { UserRedirectComponent } from './components/user-redirect-component/user-redirect-component.component';
import { UserAvatarViewComponent } from './components/user-avatar-view/user-avatar-view.component';
import { InternalServerErrorComponent } from './components/503/internal-server-error.component';
import { ImageCropperModule } from 'ngx-image-cropper';
// import { NgxTinymceModule } from 'ngx-tinymce';
// import { EditorModule } from '@tinymce/tinymce-angular';
import { FroalaEditorComponent } from './components/froala-editor/froala-editor.component';
import { FroalaInlineEditorComponent } from './components/froala-editor/inline/inline.component';
// Froala editor Workaround for AoT compiler
// import 'froala-editor/js/froala_editor.pkgd.min.js';
// Froala plugins
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/char_counter.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/entities.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/plugins/help.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/image_manager.min.js';
import 'froala-editor/js/plugins/line_breaker.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/print.min.js';
import 'froala-editor/js/plugins/quick_insert.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';
// import 'froala-editor/js/plugins/code_beautifier.min.js'; этот плагин кривой, приводит к ошибке при просмотре кода
// import 'froala-editor/js/plugins.pkgd.min.js'; // а этот как раз содержит все плагины
import 'froala-editor/js/languages/ru.js';
// import * as $ from 'jquery';
// window['$'] = $;
// window['jQuery'] = $;
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { ImageCropComponent } from './components/image-crop/image-crop.component';
import { FileUploadV2Service } from './services/file-upload-v2.service';
// import { WidgetBaseComponent } from './components/widget-base.component';

/**
 * Догрузка файла с локализацией
 */
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, `${environment.assetsPrefix}/assets/i18n/`, '.js');
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatDialogModule,
    MatCheckboxModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatTooltipModule,
    DragDropModule,
    OverlayModule,
    ImageCropperModule,
    CoreModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    TreeModule,
    SlickCarouselModule,
    AutosizeModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
  ],
  declarations: [
    // directives
    ClickOutsideDirective,
    FileIconDirective,
    InfiniteScrollDirective,
    IslastDirective,
    TextareaAutosizeDirective,
    DynamicContainerDirective,
    SortableListDirective,
    SortableDirective,
    DraggableDirective,
    DraggableHelperDirective,
    // pipes
    DateSpLikePipe,
    FileSizePipe,
    SafeHtmlPipe,
    HighlightPipe,
    SafePipe,
    // components
    ProfileSelectorComponent,
    PageNotFoundComponent,
    ForbiddenComponent,
    AddUsersComponent,
    AddTagsComponent,
    AlertsComponent,
    BackLinkComponent,
    EditorComponent,
    GobackComponent,
    MessageComponent,
    ModalResultComponent,
    ModalConfirmationComponent,
    TabPreloaderComponent,
    TabbedListPreloaderComponent,
    ScrollableListComponent,
    TabbedListComponent,
    TabsComponent,
    UserAvatarComponent,
    VideoPlayerComponent,
    BreadcrumbsComponent,
    UserHeaderComponent,
    DroppableDirective,
    DropZoneDirective,
    UserTooltipDirective,
    EmptyWidgetContentComponent,
    UserSimpleCardComponent,
    FroalaEditorComponent,
    FroalaInlineEditorComponent,
    WidgetBaseComponent,
    MissionComponent,
    ContainerComponent,
    UserAbsenceComponent,
    ProfileSelectorModalComponent,
    ProfileSelectorModalComponent,
    UserRedirectComponent,
    UserAvatarViewComponent,
    InternalServerErrorComponent,
    ImageCropComponent,
    AddTagsComponent,
  ],
  exports: [
    // modules
    MatDialogModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    SlickCarouselModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTooltipModule,
    DragDropModule,
    TreeModule,
    AutosizeModule,
    ImageCropperModule,
    // directives
    ClickOutsideDirective,
    FileIconDirective,
    InfiniteScrollDirective,
    IslastDirective,
    TextareaAutosizeDirective,
    DynamicContainerDirective,
    SortableListDirective,
    SortableDirective,
    DraggableDirective,
    DraggableHelperDirective,
    UserTooltipDirective,
    // pipes
    DateSpLikePipe,
    FileSizePipe,
    HighlightPipe,
    SafePipe,
    // components
    PageNotFoundComponent,
    InternalServerErrorComponent,
    AddUsersComponent,
    AddTagsComponent,
    AlertsComponent,
    BackLinkComponent,
    EditorComponent,
    GobackComponent,
    MessageComponent,
    ModalResultComponent,
    ModalConfirmationComponent,
    TabPreloaderComponent,
    TabbedListPreloaderComponent,
    ScrollableListComponent,
    TabbedListComponent,
    TabsComponent,
    UserAvatarComponent,
    VideoPlayerComponent,
    BreadcrumbsComponent,
    ProfileSelectorComponent,
    UserHeaderComponent,
    EmptyWidgetContentComponent,
    UserSimpleCardComponent,
    FroalaEditorComponent,
    FroalaInlineEditorComponent,
    WidgetBaseComponent,
    MissionComponent,
    ContainerComponent,
    UserAbsenceComponent,
    ProfileSelectorModalComponent,
    UserRedirectComponent,
    ImageCropComponent,
    AddTagsComponent,
  ],
  providers: [
    // services
    AlertsService,
    EditorService,
    CookieService,
    FileUploadService,
    SettingsService,
    FileUploadV2Service,
    // pipes
    DateSpLikePipe,
    FileSizePipe,
    HighlightPipe
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        FroalaEditorModule.forRoot().providers,
        FroalaViewModule.forRoot().providers,
      ]
    };
  }

  static forChild(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: []
    };
  }
}
