import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { environment } from '@env/environment';
import { HelperService } from '@app/core/services/helper.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  IVacancy,
  INewVacancy,
  IUpdateVacancy,
  INewVacancyResponse,
  IVacancyResponse,
  IVacancyCategory,
  ResponseStatus,
  VacancyStatus,
  IVacancyCategoryChanges,
  IHhVacancy,
  IHhVacancyResponse,
  ISyncVacanciesModel
} from '../model/vacancy';
import { IChangeOrders } from '@app/shared/model/item-order';

/**
 * API - сервис вакансий (Открытые возможности)
 *
 * @export
 * @class VacancyApiService
 * @extends {BaseService}
 */
@Injectable({
  providedIn: 'root'
})
export class VacancyApiService extends BaseService {
  protected serviceUrl = `${environment.siteUrl}/api/vacancy`;

  constructor(
    protected http: HttpClient,
    public helper: HelperService) { super(http, helper); }

  /**
   * Вакансии созданные в группе, в которой пользователь админ
   *
   * @param {number} statusId ИД статуса
   * @param {number} categoryId ИД категории
   * @param {number} offset сдвиг
   * @param {number} limit количество возвращаемых значений
   * @returns {Observable<IVacancy[]>}
   * @memberof VacancyApiService
   */
  getUserVacancies(userId: number, statusId: number, categoryId: number, offset: number, limit: number): Observable<IVacancy[]> {
    return super.get(`user/${userId}/vacancies?statusId=${statusId}&categoryId=${categoryId}&offset=${offset}&limit=${limit}`);
  }

  /**
   * Вакансии в группах, на которые пользователь подписан
   *
   * @param {number} offset сдвиг
   * @param {number} limit количество возвращаемых значений
   * @returns {Observable<IVacancy[]>}
   * @memberof VacancyApiService
   */
  getVacanciesForUser(offset: number, limit: number): Observable<IVacancy[]> {
    return super.get(`vacanciesForUser?offset=${offset}&limit=${limit}`);
  }

  /**
   * Вакансии для виджета
   *
   * @param {number} profileId ИД профиля (пользователь или виджет)
   * @param {number} offset сдвиг
   * @param {number} limit количество возвращаемых значений
   * @returns {Observable<IVacancy[]>}
   * @memberof VacancyApiService
   */
  getProfileVacancies(profileId: number, offset: number, limit: number): Observable<IVacancy[]> {
    return super.get(`profile/${profileId}/vacancies?offset=${offset}&limit=${limit}`);
  }

  /**
   * Вакансии, созданные в группе
   *
   * @param {number} groupId ИД группы
   * @param {number} offset сдвиг
   * @param {number} limit количество возвращаемых значений
   * @returns {Observable<IVacancy[]>}
   * @memberof VacancyApiService
   */
  getGroupVacancies(groupId: number, statusId: number, categoryId: number, offset: number, limit: number): Observable<IVacancy[]> {
    return super.get(`group/${groupId}/vacancies?statusId=${statusId}&categoryId=${categoryId}&offset=${offset}&limit=${limit}`);
  }

  /**
   * Получить вакансию по идентификатору
   *
   * @param {number} id ИД опроса
   * @returns {Observable<IVacancy>}
   * @memberof VacancyApiService
   */
  getById(id: number): Observable<IVacancy> {
    return super.get(`${id}`);
  }

  /**
   * Создать вакансию
   *
   * @param {INewSurvey} vacancy новая вакансия
   * @returns
   * @memberof VacancyApiService
   */
  create(vacancy: INewVacancy): Observable<IVacancy> {
    return super.post(``, vacancy);
  }

  /**
   * Обновить вакансию
   *
   * @param {IUpdateSurvey} survey
   * @returns
   * @memberof VacancyApiService
   */
  update(id: number, vacancy: INewVacancy): Observable<boolean> {
    return super.put(`${id}`, vacancy);
  }

  /**
   * Удалить вакансию
   *
   * @param {number} id
   * @returns
   * @memberof VacancyApiService
   */
  remove(id: number): Observable<boolean> {
    return super.delete(`${id}}`);
  }

  updateVacancyStatus(vacancyId: number, status: VacancyStatus): Observable<boolean> {
    return super.put(`${vacancyId}/updateStatus`, { status: status });
  }

  /**
  * Откликнуться на вакансию
  *
  * @param {ITakeSurvey} survey
  * @returns
  * @memberof VacancyApiService
  */
  response(response: INewVacancyResponse): Observable<IVacancyResponse> {
    return super.post(`response`, response);
  }

  getResponses(vacancyId: number, offset: number, limit: number): Observable<IVacancyResponse[]> {
    return super.get(`${vacancyId}/responses?offset=${offset}&count=${limit}`);
  }

  getResponse(responseId: number): Observable<IVacancyResponse> {
    return super.get(`response/${responseId}`);
  }

  updateResponseStatus(responseId: number, status: ResponseStatus): Observable<boolean> {
    return super.put(`response/${responseId}/updateStatus`, { status: status });
  }

  getVacancyCategories(offset: number, limit: number): Observable<IVacancyCategory[]> {
    return super.get(`vacancyCategories?offset=${offset}&count=${limit}`);
  }

  addVacancyCategory(title: string): Observable<IVacancyCategory> {
    return super.post(`vacancyCategory`, title);
  }

  editVacancyCategory(id: number, title: string): Observable<IVacancyCategory> {
    return super.put(`vacancyCategory/${id}`, title);
  }

  editVacancyCategories(categories: IVacancyCategoryChanges): Observable<boolean> {
    return super.put(`vacancyCategories`, categories);
  }

  removeVacancyCategory(id: number): Observable<boolean> {
    return super.delete(`vacancyCategory/${id}`);
  }

  changeVacancyCategoryOrder(settings: IChangeOrders): Observable<boolean> {
    return super.put(`changeVacancyCategoryOrder`, settings);
  }

  /**
   * Получить все вакансии, которые были загружены из hh.ru.
   * Т.е. у которых указан hhId в столбце не равный null
   *
   * @param {number} ownerId
   * @returns {Observable<IHhVacancyResponse>}
   * @memberof VacancyApiService
   */
  getHhVacanciesIds(ownerId: number): Observable<number[]> {
    return super.get(`hhVacanciesIds/${ownerId}`);
  }

  getFromHhList(companyId: number, page: number, per_page: number): Observable<IHhVacancyResponse> {
    return <any>this.http.get(`https://api.hh.ru/vacancies?employer_id=${companyId}&page=${page}&per_page=${per_page}`);
  }

  getFromHhItem(vacancyId: number): Observable<IHhVacancy[]> {
    return <any>this.http.get(`https://api.hh.ru/vacancies/${vacancyId}?host=hh.ru`);
  }

  syncVacanciesFromHh(hhSync: ISyncVacanciesModel): Observable<boolean> {
    return super.post(`syncVacanciesFromHh`, hhSync);
  }
}
