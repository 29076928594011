<div class="like-wrap" (mouseleave)="mouseLeave=true; hideTooltip();">
    <button class="btn btn-post _like" [ngClass]="{'_dislike': !canLike, '_like-white': white}" type="button"
        (click)="toggle()">&nbsp;</button>
    <span class="text-like" [ngClass]="{'transparent-text': count === 0}" (click)="showPopup()"
        (mouseover)="showTooltip()">{{count}}</span>

    <div class="like-wrap__popup" *ngIf="mouseOver && users?.length">
        <div class="like-wrap__cos">
            <div class="co _one-line" *ngFor="let u of users">
                <a href="#" class="co__avatar" routerLink="/profile/{{u?.id}}" (click)="usersService.navigateProfile(u?.id)">
                    <app-user-avatar [user]="u" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'"
                        [noRedirect]="true" [asSpan]="true"></app-user-avatar>
                </a>
            </div>
        </div>
        <div class="like-wrap__count text-secondary" *ngIf="count > 6" (click)="showPopup()">
            Eщё {{count - 6}}
        </div>
        <div class="mouseover-help"></div>
    </div>
</div>