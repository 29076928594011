import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, of, BehaviorSubject, throwError } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { IntryAuthService } from '../services/intry-auth.service';

@Injectable({ providedIn: 'root' })
export class IntryAuthInterceptor implements HttpInterceptor {

  private refreshTokenInProgress = false;

  /**
   * Переменная для отслеживания процесса обновления токена
   *
   * @private
   * @type {BehaviorSubject<boolean>}
   * @memberof IntryAuthInterceptor
   */
  private tokenRefreshed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  constructor(private auth: IntryAuthService) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addAuthenticationToken(request))
      .pipe(
        catchError(error => {
          // отслеживаем только 401 ошибку, остальные пробрасываем дальше
          if (error.status !== 401) {
            // tslint:disable-next-line:no-console
            console.debug('[IntryAuthInterceptor] Not an 401 error');
            return throwError(error);
          }

          if (this.auth.isTokenExpired()) {
            // tslint:disable-next-line:no-console
            console.debug('[IntryAuthInterceptor] Token wax expired');
            return throwError(error);
          }

          if (this.refreshTokenInProgress) {
            // tslint:disable-next-line:no-console
            console.debug('[IntryAuthInterceptor] Refreshing of token is in progress');
            // если токен был обновлён - отправляем запрос снова
            return this.tokenRefreshed.pipe(
              filter(res => res !== null),
              take(1),
              switchMap(() => {
                // tslint:disable-next-line:no-console
                console.debug('[IntryAuthInterceptor] Send original http request cause other refresh request complete');
                return next.handle(this.addAuthenticationToken(request));
              })
            );
          }

          // tslint:disable-next-line:no-console
          console.debug('[IntryAuthInterceptor] Force Refresh Token');

          // токен начали обновлять
          this.refreshTokenInProgress = true;
          // отслеживаем обновление токена
          this.tokenRefreshed.next(null);

          // выполняем обновление сессии
          return this.auth.forceRefreshSession().pipe(
            take(1),
            switchMap((token: any) => {
              // tslint:disable-next-line:no-console
              console.debug('[IntryAuthInterceptor] New token has been received');
              // отмечаем, что токен больше не обновляем
              this.refreshTokenInProgress = false;
              // указываем, что токен обновился
              this.tokenRefreshed.next(true);
              // выполняем исходный запрос
              // tslint:disable-next-line:no-console
              console.debug('[IntryAuthInterceptor] Send original http request');
              return next.handle(this.addAuthenticationToken(request));
            }),
            catchError(err => {
              // tslint:disable-next-line:no-console
              console.debug('[IntryAuthInterceptor] Error during force refresh token');

              // отмечаем, что токен больше не обновляем
              this.refreshTokenInProgress = false;
              // пробрасываем ошибку выше по стеку
              throw err;
            }));

        })
      );
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    const accessToken = this.auth.getToken();

    if (!accessToken) {
      return request;
    }

    return request.clone({
      setHeaders: {
        Authorization: this.auth.getAuthorizationHeaderValue()
      }
    });
  }

}
