import { Injectable, EventEmitter } from '@angular/core';
import { IMediaFile, ISetMediaFileAsCoverModel } from '@app/gallery/model/media-file';
import { IAlbumVoting } from '@app/gallery/model/album-model';
import { HubConnection } from '@microsoft/signalr';

@Injectable()
export class GalleryHub {

    public onMediaFileMoved: EventEmitter<IMediaFile> = new EventEmitter<IMediaFile>();
    public onSetMediaFileAsCover: EventEmitter<ISetMediaFileAsCoverModel> = new EventEmitter<ISetMediaFileAsCoverModel>();
    public onAlbumVoting: EventEmitter<IAlbumVoting> = new EventEmitter<IAlbumVoting>();

    registerOnServerEvents(hubConnection: HubConnection): void {
        hubConnection.on('onMediaFileMoved', (data: IMediaFile) => {
            this.onMediaFileMoved.emit(data);
        });
        hubConnection.on('onSetMediaFileAsCover', (data: ISetMediaFileAsCoverModel) => {
            this.onSetMediaFileAsCover.emit(data);
        });
        hubConnection.on('onAlbumVoting', (data: IAlbumVoting) => {
            this.onAlbumVoting.emit(data);
        });
    }
}
