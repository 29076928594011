import { Component, OnInit } from '@angular/core';
import { SurveysService } from '@app/surveys/services/surveys.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { takeUntil, switchMap, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  ISurvey,
  SurveyQuestionType,
  SurveyQuestionOptionType,
  ISurveyQuestionOption,
  ISurveyQuestion,
  ISurveyOptionDetails,
  SurveyType,
  ISurveyResultUser,
  ISurveyResult
} from '@app/surveys/model/survey';
import { HelperService } from '@app/core/services/helper.service';
import { Helper } from '@app/core/helpers/helper';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ScrollableListComponent } from '@app/shared/components/scrollable-list/scrollable-list.component';
import { UsersService } from '@app/profile/services/users.service';
import { User } from '@app/profile/model/user.model';

enum SurveyResultsMode {
  survey = 1,
  option = 2
}

const MAX_BAR_WIDTH = 250;

/**
 * Компонент результатов прохождения опроса
 *
 * @export
 * @class SurveyResultsComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-survey-results',
  templateUrl: './survey-results.component.html',
  styleUrls: ['./survey-results.component.scss']
})
export class SurveyResultsComponent extends ScrollableListComponent implements OnInit {

  /**
   * Опрос
   */
  survey: ISurvey;

  surveyUsers: ISurveyResultUser[] = [];

  optionDetails: ISurveyOptionDetails;

  mode: SurveyResultsMode;

  SurveyType = SurveyType;
  SurveyQuestionType = SurveyQuestionType;
  SurveyQuestionOptionType = SurveyQuestionOptionType;
  SurveyResultsMode = SurveyResultsMode;

  limit = 20;
  maxItemHeight = 120;
  allLoaded = false;

  currentUser: User;

  constructor(
    public usersService: UsersService,
    protected surveysService: SurveysService,
    protected breadcrumbsService: BreadcrumbsService,
    private route: ActivatedRoute,
    private router: Router,
    public helper: HelperService,
    protected sanitizer: DomSanitizer,
  ) { super(helper); }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;

      this.route.params
        .pipe(takeUntil(this.destroyed$), switchMap((params: Params) => {
          this.survey = null;
          const surveyId = +params['surveyId'];
          return this.surveysService.results(surveyId, this.offset, this.limit);
        }))
        .subscribe(survey => {
          this.survey = survey;

          // только админ может смотреть результаты
          if (!this.currentUser.isAdmin && !this.survey.userAdmin) {
            this.router.navigate(['403'], { skipLocationChange: true });
            return;
          }

          this.mode = SurveyResultsMode.survey;

          if (this.survey) {
            const surveysRoute = `/group/${this.survey.ownerId}/surveys`;
            this.breadcrumbsService.breadcrumbs.next([
              { title: 'Опросы', routerUrl: surveysRoute },
              { title: survey.type === SurveyType.test ? 'Тест' : 'Опрос', routerUrl: `${surveysRoute}/${this.survey.id}` },
              { title: 'Результаты', routerUrl: `${surveysRoute}/${this.survey.id}/results` },
            ]);

            this.operateSurveyUsers(survey);
          }

          this.error = '';
          this.loaded = true;
        }, error => {
          this.error = error;
          this.loaded = true;
        });
    });
  }

  private operateSurveyUsers(survey: ISurveyResult) {
    if (survey.users) {
      survey.users.forEach(surveyUser => {
        if (!this.surveyUsers.find(s => s.user.id === surveyUser.user.id)) {
          this.surveyUsers.push(surveyUser);
        }
      });

      if (survey.users.length < this.limit) {
        this.allLoaded = false;
      }
    }
  }

  trackBy(index, item: ISurvey) {
    if (item) {
      return item.id;
    } else {
      return index;
    }
  }

  getSurveyAnswersText(): string {
    if (this.survey) {
      const surveyTitle = this.survey.type === SurveyType.test ? 'тесте' : 'опросе';

      return `В ${surveyTitle} ${Helper.getNoun(this.survey.answersCount, 'участвовал', 'участвовали', 'участвовали')}
       ${this.survey.answersCount} ${Helper.getNoun(this.survey.answersCount, 'человек', 'человека', 'человек')}`;
    }
    return '';
  }

  getSurveyAnswersCount(): number {
    if (this.survey) {
      return this.survey.answersCount;
    }
    return;
  }

  getSurveyTestAnswersText(surveyUser: ISurveyResultUser): string {
    if (surveyUser) {
      return Helper.getNoun(surveyUser.percent, 'балл', 'балла', 'баллов');
    }
    return '';
  }

  getOptionPercent(option: ISurveyQuestionOption, question: ISurveyQuestion): number {
    if (this.survey && option && question && question.answersCount) {
      return Math.round(option.answersCount / question.answersCount * 100);
    }
    return 0;
  }

  getBarWidth(option: ISurveyQuestionOption, question: ISurveyQuestion): SafeStyle {
    const percent = this.getOptionPercent(option, question);

    const width = (MAX_BAR_WIDTH / 100) * percent;

    const style = `width: ${width}px`;

    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  getOptionDetails(optionId: number, offset: number = 0, limit: number = 50) {

    this.mode = SurveyResultsMode.option;
    this.loaded = false;

    this.surveysService.getOptionDetails(optionId, offset, limit).pipe(take(1)).subscribe(res => {
      if (res) {
        this.optionDetails = res;
      }
      this.loaded = true;
    }, error => {
      this.loaded = true;
      console.log(error);
    });
  }

  protected onScroll() {
    if (this.survey && this.survey.type === SurveyType.test) {
      const number = window.innerHeight + window.pageYOffset + 20;
      if (this.surveyUsers && number > this.maxItemHeight * this.surveyUsers.length) {
        if (!this.loading) {
          if (!this.allLoaded) {
            this.offset += this.limit;
            this.surveysService.results(this.survey.id, this.offset, this.limit).subscribe(survey => {
              if (survey) {
                this.operateSurveyUsers(survey);
              }
            });
          }
        }
      }
    }
  }
}
