import { Component } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';

/**
 * Меню виджета наград
 *
 * @export
 * @class AwardsMenuComponent
 */
@Component({
  selector: 'app-awards-menu',
  templateUrl: './awards-menu.component.html',
  styleUrls: ['./awards-menu.component.scss']
})
export class AwardsMenuComponent extends BaseComponent {
  constructor(
    public helper: HelperService,
  ) { super(helper); }
}
