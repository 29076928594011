<div class="pull-right btn btn-dots tooltip" mat-button [matMenuTriggerFor]="menu"></div>
<mat-menu #menu="matMenu">
  <div class="icon-menu" #menuContainer>
    <ng-container *ngIf="selectedItems?.length>1; then multipleItemsMenu; else singleItemMenu"></ng-container>
    <ng-template #singleItemMenu>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="config?.allowEdit"
        (click)="editClick()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/edit.svg" alt="" class="icon-menu__img">
        </span>
        <span>Редактировать</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="config?.allowDelete"
        (click)="deleteClick()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/remove.svg" alt="" class="icon-menu__img">
        </span>
        <span>Удалить</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="showExternalLinks" (click)="navigateToNewForm()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/choose-file.svg" alt="" class="icon-menu__img">
        </span>
        <span>Добавить страницу</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="showExternalLinks" (click)="openCreateLink()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/link.svg" alt="" class="icon-menu__img">
        </span>
        <span>Добавить ссылку</span>
      </a>
    </ng-template>
    <ng-template #multipleItemsMenu>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="config?.allowDelete"
        (click)="deleteClick()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/remove.svg" alt="" class="icon-menu__img">
        </span>
        <span>Удалить</span>
      </a>
    </ng-template>
  </div>
</mat-menu>