import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { IMediaFile } from '@app/gallery/model/media-file';
import { GalleryService } from '@app/gallery/services/gallery.service';
import { AlertsService } from '@app/shared/services/alerts.service';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';

declare var $: any;

@Component({
  selector: 'app-image-crop',
  templateUrl: './image-crop.component.html',
})
export class ImageCropComponent extends BaseComponent {
  mediaFileItem: IMediaFile;
  croppedImage: any = '';
  errorMessage: string;
  imageIsLoaded: boolean;
  imageChanged: boolean;
  tempUrl: any;
  croppedUrl: any;

  constructor(
    private readonly sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<ImageCropComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected alertsService: AlertsService,
    public galleryService: GalleryService,
    public helper: HelperService
  ) {
    super(helper);
    this.mediaFileItem = data.item;
  }

  checkMediaFile(url: string) {
    if (!this.mediaFileItem.tempUrl) {
      this.galleryService
        .getMediaFileBlob(
          url
        )
        .subscribe((res) => {
          const reader = new FileReader();

          reader.onloadend = () => {
            this.mediaFileItem.tempUrl = reader.result as string;
          };

          reader.onerror = () => {
            console.log('reader error');
          };

          reader.readAsDataURL(res);
        });
    }
  }

  onImgLoad(target) {
    const elem = $(target);
    this.checkMediaFile(target.src);
  }

  imageLoaded(image: LoadedImage) {
    this.imageIsLoaded = true;
  }

  /**
   * Меняет миниатюры фотографии и проверяет их ширину
   *
   * @memberof PhotoEditorComponent
   */
  imageCropped(event: ImageCroppedEvent) {
    if (this.imageIsLoaded) {
      const width = event.width - 1;

      if (width < 200 && width > 0) {
        this.errorMessage = 'Выбранная область слишком маленькая';
        this.imageChanged = false;
      } else {
        this.errorMessage = undefined;
        this.imageChanged = true;
        this.croppedUrl = event.base64;
      }
    }

    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.base64);
  }

  isValid(): boolean {
    return this.imageChanged;
  }

  cancel() {
    this.dialogRef.close();
  }

  submit() {
    this.mediaFileItem.croppedUrl = this.croppedUrl;
    this.data.onCropped(this.mediaFileItem);
    this.dialogRef.close();
  }
}
