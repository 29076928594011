import { OidcConfigService, OpenIdConfiguration, AuthWellKnownEndpoints } from 'angular-auth-oidc-client';
import { IntryAuthConfig } from '../shared/models/intry-auth.config';

export const createAuthWellKnownEndpoints = (config: IntryAuthConfig): AuthWellKnownEndpoints => ({
  issuer: config.authority,
  jwksUri: config.authority + '/protocol/openid-connect/certs',
  authorizationEndpoint: config.authority + '/protocol/openid-connect/auth',
  tokenEndpoint: config.authority + '/protocol/openid-connect/token',
  userinfoEndpoint: config.authority + '/protocol/openid-connect/userinfo',
  endSessionEndpoint: config.authority + '/protocol/openid-connect/logout',
  checkSessionIframe: config.authority + '/connect/checksession',
  revocationEndpoint: config.authority + '/protocol/openid-connect/revoke',
  introspectionEndpoint: config.authority + '/protocol/openid-connect/token/introspect',
});

export const createOpenIdConfiguration = (config: IntryAuthConfig): OpenIdConfiguration => {
  const frontUrl = config.frontUrl ? config.frontUrl : (window.location.protocol + '//' + window.location.host);

  const openIdConfiguration: OpenIdConfiguration = {
    stsServer: config.authority,
    redirectUrl: frontUrl + '/auth-callback',
    silentRenewUrl: frontUrl + '/silent-renew.html',
    postLogoutRedirectUri: frontUrl + '/401',
    responseType: 'id_token token',
    silentRenew: true,
    postLoginRoute: '/',
    forbiddenRoute: '/403',
    unauthorizedRoute: '/401',
    triggerAuthorizationResultEvent: true,
    maxIdTokenIatOffsetAllowedInSeconds: 1200,
    renewUserInfoAfterTokenRenew: true,
  };

  const shouldSkip = [
    'stsServer',
    'authWellknownEndpoint',
    'triggerAuthorizationResultEvent',
  ];

  for (const key of Object.keys(config)) {
    if (shouldSkip.includes(key)) {
      continue;
    }

    openIdConfiguration[key] = config[key];
  }

  return openIdConfiguration;
};

export const intryAuthConfigFactory = (
  oidcConfigService: OidcConfigService,
  config: IntryAuthConfig
) => () => oidcConfigService.withConfig(
  createOpenIdConfiguration(config),
  createAuthWellKnownEndpoints(config)
);
