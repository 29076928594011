<div class="relative">
  <div class="progressbar" *ngIf="creating">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div class="card _no-padding" *ngIf="group && loaded">
    <div class="group">
      <div class="group__header" [attr.style]="getPictureUrl(group)">
        <app-goback [target]="group" [cssClass]="'btn group__back'" [text]="'Назад'" [prefix]="'group/'"
          style="position: absolute; top:0px; left:0px"></app-goback>
        <div class="group__photo">
          <div class="profile-photo _group" *ngIf="groupsService.isAdmin(group)">
            <img src="{{assetsPrefix}}/assets/images/icons/photo.svg" class="profile-photo__img">
            <span class="profile-photo__text">{{group?.pictureUrl ? 'Заменить фото':'Добавить фото'}}</span>
            <div class="profile-photo__edit" (click)="groupImage.click()">
              <input #groupImage style="display: none" type="file" class="profile-photo__file" accept="image/*"
                (change)="imageChangeEvent($event)">
            </div>
          </div>
          <div class="profile-photo _group"
            *ngIf="formType!=groupFormType.new && groupsService.isAdmin(group) && group?.pictureUrl"
            (click)="removeImage()">
            <img src="{{assetsPrefix}}/assets/images/icons/photo_remove.svg" class="profile-photo__img">
            <span class="profile-photo__text">Удалить фото</span>
          </div>
        </div>
        <textarea rows="1" [attr.maxlength]="groupNameLimit" #groupName
          placeholder="Введите название группы" [ngClass]="{'_error':errors.title}" [(ngModel)]="group.title"
          (keyup)="onKey($event)" (focus)="showEditButtons=true" class="main-input group__title"
          [attr.disabled]="groupsService.isAdmin(group) || formType==groupFormType.new ?null:''"></textarea>
        <button type="button" class="btn btn-simple icon _close new-file__clear"
          *ngIf="formType==groupFormType.disp && showEditButtons && group.title!=oldName"
          (click)="group.title=oldName"></button>
        <button type="button" class="btn btn-check new-file__add"
          *ngIf="formType==groupFormType.disp && showEditButtons && group.title!=oldName"
          [attr.disabled]="isValid?null:''" (click)="editTitle()"></button>
        <div class="group__counter"
          *ngIf="group.title && !errors.title && (formType==groupFormType.disp && showEditButtons && group.title!=oldName || formType==groupFormType.new)">
          {{getGroupNameLengthText()}}</div>
        <div class="main-error" *ngIf="errors.title">{{errors.title}}</div>
        <div class="btn align-left select main-input group__type tooltip" [ngClass]="{'_active': showGroupTypeSelect}"
          (click)="toggleGroupTypeSelect()" appClickOutside (clickOutside)="closeGroupTypeSelect()">
          {{getGroupType(group.type)}}
          <!--{{'GroupType_' + group.type | translate}} -->
          <span class="group__type-disclaimer"
            *ngIf="formType==groupFormType.new && group.type == groupType.Business">&mdash; После создания группы вы не
            сможете изменить тип</span>
          <input type="hidden">
          <div class="tooltip__body" *ngIf="isShowGroupTypes()">
            <div class="icon-menu">
              <button type="button" class="icon-menu__item btn" *ngFor="let groupType of getGroupTypes()"
                (click)="selectGroupType(groupType)">
                <span class="icon-menu__icon">
                  <img src="{{assetsPrefix}}/assets/images/icons/check.svg" alt="" class="icon-menu__img"
                    *ngIf="group.type == groupType">
                </span>
                <span>
                  <!--{{'GroupType_' + groupType | translate }} -->
                  {{getGroupType(groupType)}}
                  <span class="icon-menu__secondary light-text">{{getGroupDescription(groupType)}}
                    <!--{{'GroupType_Description_' + groupType | translate  }}-->
                  </span>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div class="group__controls">
          <button type="button" class="btn btn-secondary btn-icon" *ngIf="showJoin" (click)="join($event)">
            <img src="{{assetsPrefix}}/assets/images/icons/menu/add.svg">
            <span>Вступить</span>
          </button>
          <button type="button" class="btn btn-secondary btn-icon" *ngIf="showSubscribe" (click)="subscribe($event)">
            <img src="{{assetsPrefix}}/assets/images/icons/menu/rss.svg">
            <span>Подписаться</span>
          </button>
          <span class="btn" *ngIf="showGroupMenu()" [ngStyle]="{'display': groupMenu?.hideMenu?'none':'inline-block'}">
            <app-group-menu #groupMenu class="btn btn-secondary btn-icon _outside _by-content tooltip" [user]="user"
              [group]="group" [type]="menuType.Group" [hideJoin]="!showJoin"
              [hideSubscribe]="!showSubscribe" [hideMenu]="false"></app-group-menu>
          </span>
          <button [ngClass]="{_inactive:creating}" type="button" class="btn btn-secondary group__cancel"
            *ngIf="formType==groupFormType.new" (click)="cancel()">Отменить</button>
          <!-- _inactive для заблокированности-->
          <button [ngClass]="{_inactive:!isValid || creating}" type="button" class="btn btn-primary group__create"
            *ngIf="formType==groupFormType.new" (click)="create()">Создать</button>
        </div>
      </div>
    </div>
  </div>
  <div class="card _no-padding" *ngIf="!loaded">
    <div class="group">
      <div class="group__header">
        <span class="preloader _cascade">
          <span class="preloader__line"></span>
        </span>
        <span class="preloader _cascade">
          <span class="preloader__line"></span>
        </span>
        <span class="preloader _cascade">
          <span class="preloader__line"></span>
        </span>
      </div>
      <div class="group__tabs">
        <span class="preloader _avatar _inline">
          <span class="preloader__lines">
            <span class="preloader__line"></span>
            <span class="preloader__line"></span>
            <span class="preloader__line"></span>
          </span>
        </span>
      </div>
    </div>
  </div>
</div>
<app-onboarding page="group"></app-onboarding>
