<ng-container *ngIf="currentUser">
    <app-header></app-header>
    <app-left-menu></app-left-menu>
    <span id="user_tooltip" class="hint__body" style="display: none; position:fixed; z-index: 10000;">
        <span id="user_tooltip-name" class="hint__title"></span>
        <span class="hint__text">
            <span id="user_tooltip-jobTitle"></span>
            <span id="user_tooltip-department"></span>
            <span id="user_tooltip-workPhone"></span>
            <span id="user_tooltip-mobilePhone"></span>
        </span>
    </span>
    <app-alerts></app-alerts>
    <router-outlet></router-outlet>
</ng-container>
