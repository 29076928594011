import { Component, OnInit, Input, OnDestroy, Inject } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { BaseComponent } from '@app/core/components/base.component';
import { FilesService } from '@app/files/services/files.service';
import { FileVersion } from '@app/files/model/file-version.model';
import { FilesListItem } from '@app/files/model/files-list-item.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { takeUntil, take } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { AlertsService } from '@app/shared/services/alerts.service';
import { Helper } from '@app/core/helpers/helper';
import { environment } from '@env/environment';

/**
 * Компонент отображения истории изменений файла
 *
 * @export
 * @class FileHistoryComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-file-history',
  templateUrl: './file-history.component.html'
})
export class FileHistoryComponent extends BaseComponent implements OnInit, OnDestroy {
  /**
   * Файл, для которого необходимо получить историю
   *
   * @type {FilesListItem}
   * @memberof FileHistoryComponent
   */
  @Input() item: FilesListItem;
  /**
   * URL-адрес сайта, на котором располагается файл
   *
   * @type {Subject<string>}
   * @memberof FileHistoryComponent
   */
  @Input() webUrl: Subject<string> = new BehaviorSubject<string>(null);

  /**
   * Версии файла
   *
   * @type {FileVersion[]}
   * @memberof FileHistoryComponent
   */
  items: FileVersion[] = [];

  /**
   * Сортировать по возрастанию?
   *
   * @type {boolean}
   * @memberof FileHistoryComponent
   */
  sortAsc: boolean;

  /**
   * Сортировть по полю
   *
   * @type {string}
   * @memberof FileHistoryComponent
   */
  sortBy: string;

  constructor(
    public dialogRef: MatDialogRef<FileHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public filesService: FilesService,
    public alertsService: AlertsService,
    public helper: HelperService) { super(helper); }

  /**
   * Метод OnInit жизненого цикла компонента
   *
   * @memberof FileHistoryComponent
   */
  ngOnInit() {
    if (this.data.item) {
      this.item = this.data.item;
    }
    if (this.data.webUrl) {
      this.webUrl = this.data.webUrl;
    }

    this.webUrl
      .pipe(takeUntil(this.destroyed$))
      .subscribe(url => {
        if (url && this.item) {
          this.filesService.webApiGetHistory(url, this.item.url)
            .pipe(take(1), takeUntil(this.destroyed$))
            .subscribe(res => {
              this.items = res;
              this.items.forEach(item => {
                item.AbsoluteUrl = Helper.concatUrls(url, item.Url);
              });
              this.addCurrentAsLastVersion();
              this.loaded = true;
            }, error => {
              this.error = error;
              this.loaded = true;
            });
        } else {
          console.log(`webUrl=${url}`);
        }
      });
  }

  /**
   * Сортировать столбец
   *
   * @param {string} sortBy
   * @param {boolean} sortAsc
   * @memberof FileHistoryComponent
   */
  sortColumn(sortBy: string, sortAsc: boolean) {
    this.sortAsc = sortAsc;
    this.sortBy = sortBy;
    this.sortItems(this);
  }

  /**
   * Восстановление версии файла
   *
   * @param {FileVersion} fileVersion
   * @memberof FileHistoryComponent
   */
  restore(fileVersion: FileVersion) {
    this.webUrl
      .pipe(take(1))
      .subscribe(url => {
        if (url && this.item) {
          this.filesService.webApiFileVersionRestoreByLabel(url, this.item.url, fileVersion.VersionLabel)
            .pipe(take(1), takeUntil(this.destroyed$))
            .subscribe(() => {
              // tslint:disable-next-line:max-line-length
              this.alertsService.riseSuccess(`Версия ${fileVersion.VersionLabel} файла "${this.item.name}" была успешно восстановлена`, 5000);
              this.operateFile(url);
            }, error => {
              const errorText = `Ошибка при восстановлении версии ${fileVersion.VersionLabel} файла "${this.item.name}"`;
              this.alertsService.riseError(errorText);
              console.log(`${errorText}=${error}`);
            });
        }
      });
  }

  /**
   * Удаление версии файла
   *
   * @param {FileVersion} fileVersion
   * @memberof FileHistoryComponent
   */
  delete(fileVersion: FileVersion) {
    this.webUrl
      .pipe(take(1))
      .subscribe(url => {
        if (url && this.item) {
          this.filesService.webApiFileVersionDeleteByLabel(url, this.item.url, fileVersion.VersionLabel)
            .pipe(take(1), takeUntil(this.destroyed$))
            .subscribe(() => {
              this.alertsService.riseSuccess(`Версия ${fileVersion.VersionLabel} файла "${this.item.name}" была успешно удалена`, 5000);
              this.operateFile(url);
            }, error => {
              const errorText = `Ошибка при удалении версии ${fileVersion.VersionLabel} файла "${this.item.name}"`;
              this.alertsService.riseError(errorText);
              console.log(`${errorText}=${error}`);
            });
        }
      });
  }

  /**
   * Получаем заново информацию по файлу при восстановлении или удалении версий
   *
   * @param {string} url
   * @memberof FileHistoryComponent
   */
  operateFile(url: string) {
    this.filesService.webApiGetFile(
      Helper.concatUrls(this.helper.getSiteHostUrl(), url), this.item.url, 'Versions,ModifiedBy,Versions/CreatedBy')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(resFile => {
        if (resFile) {
          this.item.version = resFile.version;
          this.item.size = resFile.size;
          this.items = resFile.versions;
          this.addCurrentAsLastVersion();
        }
      }, error => {
        const errorText = `Ошибка при обновлении файла = ${this.item.name}`;
        this.alertsService.riseError(errorText);
        console.log(`${errorText}=${error}`);
      });
  }

  /**
   * Текущую версию поставить последней
   *
   * @memberof FileHistoryComponent
   */
  private addCurrentAsLastVersion() {
    this.items.forEach(item => {
      item.Created = new Date(item.Created);
    });

    this.items.unshift({
      VersionLabel: this.item.version,
      CreatedBy: {
        Title: this.item.editor[0].title,
        Email: this.item.editor[0].email,
        Id: this.item.editor[0].id,
      },
      IsCurrentVersion: true,
      Size: this.item.size,
      Created: this.item.modified,
      Url: this.item.url,
      AbsoluteUrl: Helper.concatUrls(environment.siteUrl, this.item.url)
    });
    this.sortColumn('version', false);
  }

  /**
   * Сортировать версии файла
   *
   * @param {FileHistoryComponent} obj
   * @memberof FileHistoryComponent
   */
  private sortItems(obj: FileHistoryComponent) {
    if (obj.sortBy) {
      switch (obj.sortBy) {
        case 'created':
          obj.sortByCreated(obj.items, obj.sortAsc);
          break;
        case 'version':
          obj.sortByVersion(obj.items, obj.sortAsc);
          break;
        case 'createdBy':
          obj.sortByUser(obj.items, obj.sortAsc);
          break;
        case 'size':
          obj.sortBySize(obj.items, obj.sortAsc);
          break;
        default:
          obj.sortByVersion(obj.items, obj.sortAsc);
          break;
      }
    }
  }

  /**
   * Сортировать по дате создания
   *
   * @param {*} items
   * @param {boolean} sortAsc
   * @memberof FileHistoryComponent
   */
  private sortByCreated(items: any, sortAsc: boolean) {
    this.sortAsc = sortAsc;
    items.sort((a, b) => {
      if (a.Created < b.Created) {
        return sortAsc ? -1 : 1;
      }
      if (a.Created > b.Created) {
        return sortAsc ? 1 : -1;
      }
      return 0;
    });
  }

  /**
   * Сортировать по весии
   *
   * @param {*} items
   * @param {boolean} sortAsc
   * @memberof FileHistoryComponent
   */
  private sortByVersion(items: any, sortAsc: boolean) {
    this.sortAsc = sortAsc;
    items.sort((a, b) => {
      if (+a.VersionLabel < +b.VersionLabel) {
        return sortAsc ? -1 : 1;
      }
      if (+a.VersionLabel > +b.VersionLabel) {
        return sortAsc ? 1 : -1;
      }
      return 0;
    });
  }

  /**
   *Сортировать по пользователю
   *
   * @param {*} items
   * @param {boolean} sortAsc
   * @memberof FileHistoryComponent
   */
  private sortByUser(items: any, sortAsc: boolean) {
    this.sortAsc = sortAsc;
    items.sort((a, b) => {
      if (a.CreatedBy.Title < b.CreatedBy.Title) {
        return sortAsc ? -1 : 1;
      }
      if (a.CreatedBy.Title > b.CreatedBy.Title) {
        return sortAsc ? 1 : -1;
      }
      return 0;
    });
  }

  /**
   * Сортировать по размеру
   *
   * @param {*} items
   * @param {boolean} sortAsc
   * @memberof FileHistoryComponent
   */
  private sortBySize(items: any, sortAsc: boolean) {
    this.sortAsc = sortAsc;
    items.sort((a, b) => {
      if (a.Size < b.Size) {
        return sortAsc ? -1 : 1;
      }
      if (a.Size > b.Size) {
        return sortAsc ? 1 : -1;
      }
      return 0;
    });
  }
}
