import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { BannersService } from '@app/banners/services/banners.service';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { HelperService } from '@app/core/services/helper.service';
import { INewBannerSlideTypeModel } from '@app/banners/model/banner';
import { IBannerFile } from '@app/banners/model/banner-file';
import { take, finalize, shareReplay, takeUntil } from 'rxjs/operators';
import { AlertsService } from '@app/shared/services/alerts.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import { FilesService } from '@app/files/services/files.service';

@Component({
  selector: 'app-banner-type-new',
  templateUrl: './banner-type-new.component.html',
  styleUrls: ['./banner-type-new.component.scss']
})
export class BannerTypeNewComponent extends BaseComponent implements OnInit, OnDestroy {

  bannerSlideType: INewBannerSlideTypeModel = {
    title: '',
    color: '',
    iconUrl: ''
  };

  /**
   * Существующие на данный момент иконки
   *
   * @type {IAwardIcon[]}
   * @memberof NewAwardComponent
   */
  icons: IBannerFile[];

  iconUploading: boolean;
  sending: boolean;

  buttonSaveText = 'Сохранить';

  private iconsToUpload: any[];

  constructor(
    public bannersService: BannersService,
    protected filesService: FilesService,
    protected alertsService: AlertsService,
    protected breadcrumbsService: BreadcrumbsService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected dialog: MatDialog,
    public helper: HelperService
  ) { super(helper); }

  ngOnInit() {
    // получение всех иконок
    this.getIcons();

    this.breadcrumbsService.breadcrumbs.next([
      { title: 'Настройка баннера', routerUrl: `/admin/banner-settings` },
      { title: 'Типы слайдов баннера', routerUrl: `/admin/banner-types` },
      { title: 'Новый тип слайда', routerUrl: `/admin/banner-types/new` }
    ]);

    this.loaded = true;
  }

  /**
   * Отправка формы
   */
  submit() {
    if (this.isValid()) {
      this.sending = true;
      this.bannersService.bannerSlideTypeApiService.add(this.bannerSlideType)
        .pipe(take(1), finalize(() => this.sending = false))
        .subscribe(res => {
          if (res) {
            this.alertsService.riseSuccess('Тип слайда баннера успешно добавлен.');
            this.router.navigate(['..'], { relativeTo: this.route });
          } else {
            this.alertsService.riseError('Произошла ошибка при отправке формы. Обратитесь к администратору.');
          }
        }, error => {
          this.alertsService.riseError('Произошла ошибка при отправке формы. Обратитесь к администратору.');
          console.log(error);
        });
    } else {
      this.alertsService.riseError('Произошла ошибка при отправке формы. Не заполнены обязательные поля');
    }
  }

  /**
   * Отмена создания типа слайда баннера
   */
  cancel() {
    if (this.bannerSlideType
      && (this.bannerSlideType.title
        || this.bannerSlideType.color
        || this.bannerSlideType.iconUrl)
    ) {
      this.dialog.open(ModalConfirmationComponent, {
        data: {
          text: `Вы действительно хотите отменить добавление?`,
          onOk: this.goBack.bind(this),
          okText: 'Да'
        }
      });
    } else {
      this.goBack();
    }
  }

  /**
   * Форма валидна для отправки?
   * @returns {boolean}
   */
  isValid(): boolean {
    return this.bannerSlideType
      && !!this.bannerSlideType.title
      && !!this.bannerSlideType.color;
  }

  /**
   * Отмена создания типа слайда баннера
   */
  private goBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  /**
   * Получение всех иконок
   *
   * @memberof NewAwardComponent
   */
  getIcons() {
    this.bannersService.bannerSlideTypeApiService.getIcons().pipe(take(1), shareReplay(1, 10000)).subscribe(icons => {
      this.icons = icons;
    });
  }

  fileChangeEvent(fileInput) {
    const files = fileInput.target.files;
    this.operateUploadingFiles(files);
  }

  /**
   * Выбрать иконку
   *
   * @param {IBannerFile} icon
   * @memberof NewAwardComponent
   */
  choseIcon(icon: IBannerFile) {
    this.bannerSlideType.iconUrl = icon.url;
  }

  /**
   * Удаление иконки
   *
   * @param {IBannerFile} icon Иконка, которую необходимо удалить
   */
  removeIcon(icon: IBannerFile) {
    this.filesService.webApiDeleteItem((<any>window).signalRServer, <any>icon)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        this.icons = this.icons.filter(s => s.url !== icon.url);
        this.alertsService.riseSuccess('Иконка была успешно удалена.');
      }, error => {
        this.alertsService.riseError('Произошла ошибка при удалении иконки.');
      });
  }

  /**
   * Добавление новой иконки
   *
   * @memberof NewAwardComponent
   */
  private operateUploadingFiles(files) {
    if (files && files.length) {
      this.iconsToUpload = [];
      for (let i = 0; i < files.length; i++) {
        this.iconsToUpload.push(files[i]);
      }
    }

    if (this.iconsToUpload && this.iconsToUpload.length) {
      // upload file
      this.iconUploading = true;
      this.iconsToUpload.forEach(file => {
        this.uploadFile(file);
      });
    }
  }

  private uploadFile(file: any) {
    file.uploading = true;
    const formData: FormData = new FormData();
    formData.append('file', file);
    this.bannersService.bannerSlideTypeApiService.upload(formData).subscribe(res => {
      if (res) {
        // уведомление об успешно добавленном файле
        this.alertsService.riseSuccess(`Иконка '${file.name}' была успешно добавлена`);
        if (this.icons && !this.icons.find(s => s.url === res.url)) {
          this.icons.push(res);
        }
      }
      this.iconUploadingFinished(file);
    }, () => {
      // уведомление об ошибке добавления файла
      this.alertsService.riseError(`Ошибка во время добавления иконки '${file.name}'.`);
      this.iconUploadingFinished(file);
    });
  }

  private iconUploadingFinished(file: any) {
    file.uploading = false;
    if (!this.iconsToUpload.filter(f => f.uploading === true).length) {
      this.iconUploading = false;
    }
  }
}
