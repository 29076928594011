<div class="card">
    <span class="header-text _modal">
        <span class="header-text__text">{{data.headerText}}</span>&nbsp;<span class="header-text__count"></span>
    </span>
    <div class="input-main _big">
        <a href="javascript:;" class="link-file" [ngClass]="data.icon"></a>
        <input type="text" [attr.placeholder]="data?.placeholder" [(ngModel)]="folderName" class="input-main__field">
        <button *ngIf="folderName" type="button" class="btn icon _remove input-main__clear" (click)="folderName=''"></button>
    </div>
    <div class="error-message" *ngIf="error">{{error}}</div>
</div>
<div class="confirm _right">
    <div class="confirm__actions" mat-dialog-actions [ngClass]="{'loading _blue':loading}">
        <button type="button" mat-button mat-dialog-close class="btn btn-simple">Отмена</button>
        <button type="button" mat-button class="btn btn-primary" (click)="ok()" [attr.disabled]="folderName?null:''">Создать</button>
    </div>
</div>
