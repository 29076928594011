import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentsService } from '@app/comments/services/comments.service';
import { CommentComponent } from '@app/comments/components/comment/comment.component';
import { CommentNewComponent } from '@app/comments/components/comment-new/comment-new.component';
import { CommentMenuComponent } from '@app/comments/components/comment-menu/comment-menu.component';
import { CommentsListComponent } from '@app/comments/components/comments-list/comments-list.component';
import { AttachmentsModule } from '@app/attachments/file-attachments/attachments.module';
import { SharedModule } from '@app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { LikesModule } from '@app/likes/likes.module';
import { MatMenuModule } from '@angular/material/menu';
import { MediaAttachmentsModule } from '@app/attachments/media-attachments/media-attachments.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    AttachmentsModule,
    MediaAttachmentsModule,
    LikesModule,
    MatMenuModule
  ],
  declarations: [
    CommentComponent,
    CommentNewComponent,
    CommentMenuComponent,
    CommentsListComponent
  ],
  exports: [
    CommentComponent,
    CommentNewComponent,
    CommentMenuComponent,
    CommentsListComponent
  ],
  providers: [
    CommentsService
  ]
})
export class CommentsModule { }
