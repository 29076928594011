<!-- New post -->
<app-post-new [profileType]="1" [group]="group" *ngIf="loaded && canAdd()" [placeholder]="'Что нового в группе?'"
  (onAdd)="onFeedNewPost($event)"></app-post-new>
<!-- New post END-->
<!-- Empty group -->
<div class="card" *ngIf="canRead && loaded && (!posts || !posts.length)">
  <div class="group-empty">
    <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" class="group-empty__img">
    <div class="group-empty__text">Добавьте первую публикацию, поделитесь новостями</div>
  </div>
</div>
<!-- Empty group END-->
<!-- if not participant -->
<div class="card" *ngIf="!canRead && loaded">
  <app-group-forbidden></app-group-forbidden>
</div>
<!-- if not participant -->
<!-- List of posts -->
<ng-container *ngIf="canRead && loaded">
  <app-group-post *ngFor="let post of posts; trackBy:trackBy" [post]="post" [feedType]="feedType.GroupFeed"
    (remove)="onRemove($event)"></app-group-post>
</ng-container>
<!-- List of Posts END -->
<div class="feed-spinner" *ngIf="loaded && loading">
  <mat-spinner [diameter]="56"></mat-spinner>
</div>
<!-- preloaders -->
<app-feed-preloader [loaded]="loaded" [loading]="loading"></app-feed-preloader>
<!-- preloaders END -->
