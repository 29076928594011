import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { Helper } from '@app/core/helpers/helper';
import { AlertsService } from '@app/shared/services/alerts.service';
import { FeedEventViewModel } from '@app/feed/model/feed-event-view-model.model';
import { ProfileType } from '@app/core/model/profile-type.enum';
import { environment } from '@env/environment';
import { EventType } from '@app/feed/model/feed-event.model';

/**
 * Меню поста
 */
@Component({
  selector: 'app-feed-event-menu',
  templateUrl: './feed-event-menu.component.html'
})
export class FeedEventMenuComponent extends BaseComponent implements OnChanges {
  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() share: EventEmitter<any> = new EventEmitter();
  @Output() remove: EventEmitter<any> = new EventEmitter();
  @Output() cancelPin: EventEmitter<any> = new EventEmitter();
  @Input() allowEdit: boolean;
  @Input() allowDelete: boolean;
  @Input() allowShare: boolean;
  @Input() whiteDots: boolean;
  @Input() post: FeedEventViewModel;
  @ViewChild('postLinkHref', { static: true }) postLinkHref: ElementRef;

  postLink: string;

  constructor(
    private dialog: MatDialog,
    public helper: HelperService,
    private alertsService: AlertsService) { super(helper); }

  showRemoveConfirmation() {
    this.dialog.open(ModalConfirmationComponent, {
      data: {
        text: 'Вы действительно хотите удалить событие?',
        onOk: this.remove
      }
    });
  }

  /**
   * Отменить закрепление
   */
  cancelPinClick() {
    this.cancelPin.emit();
  }

  /**
   * Копировать ссылку на элемент
   */
  copyToClipboard() {
    if (this.post && this.post.event) {
      if (Helper.copyToClipboard(this.postLinkHref.nativeElement.href)) {
        this.alertsService.riseSuccess('Ссылка на пост была успешно скопирована в буфер обмена');
      } else {
        this.alertsService.alert.error('Ссылка на пост не была скопирована в буфер обмена');
      }
    }
  }

  editClick() {
    this.edit.emit();
  }

  shareClick() {
    this.share.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['post']) {
      if (this.post && this.post.event) {
        if (this.post.event.eventType === EventType.BusinessGroupNewsPost
          || this.post.event.eventType === EventType.GroupNewsPost) {
          this.setGroupLink();
        } else {
          switch (this.post.event.ownerType) {
            case ProfileType.User:
              this.postLink = `/profile/${this.post.event.ownerId}/post/${this.post.event.id}`;
              break;
            case ProfileType.Group:
              this.setGroupLink();
              break;
          }
        }
      }
    }
  }

  private setGroupLink() {
    this.postLink = `/group/${this.post.event.ownerId}/post/${this.post.event.id}`;
  }
}
