import {
  Component,
  EventEmitter,
  OnInit,
  Input,
  Output,
  ChangeDetectorRef,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FroalaEditorOptions, EditorOptions } from './froala-editor-options';
import { User } from '@app/profile/model/user.model';
import { from, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';
import { KeyCodes } from '@app/shared/key-codes';
import { UsersService } from '@app/profile/services/users.service';
import { SubscribeService } from '@app/subscribe/services/subscribe.service';
import Tribute from 'tributejs';
import { HelperService } from '@app/core/services/helper.service';
import { IntryAuthService } from '@app/auth/shared/services/intry-auth.service';
import {Browser} from '@app/shared/browser';

/**
 * Компонент wysiwyg редактора Froala
 *
 * 1. https://www.froala.com/wysiwyg-editor/
 * 2. https://github.com/froala/angular-froala-wysiwyg
 */
@Component({
  selector: 'app-froala-editor',
  templateUrl: './froala-editor.component.html',
  styleUrls: ['./froala-editor.component.scss'],
})
export class FroalaEditorComponent implements OnInit, OnDestroy, OnChanges {
  @Input() editorModel: string;
  @Input() editorOptions: EditorOptions;
  @Input() itemId: string;
  @Input() readonly: boolean;
  @Input() content: string;
  @Input() mentions: User[] = [];
  @Input() placeholder: string;
  @Input() focusOnInit: boolean;
  @Input() rawHtml: boolean;
  @Input() showToolbar = false;
  @Input() simple = false;
  @Input() user: User;

  @Output() changed = new EventEmitter<string>();
  @Output() focused: EventEmitter<any> = new EventEmitter();
  @Output() textChange = new EventEmitter();
  @Output() submited = new EventEmitter();

  @Input()
  get text() {
    return this.textValue;
  }

  set text(value) {
    this.textValue = value;
    this.textChange.emit(this.textValue);
  }
  protected textValue: string;
  protected mentionSubscription: Subscription;
  protected tribute: InstanceType<typeof Tribute>;
  protected initControls: any;
  public isFroalaVisible = false;
  private replyActivated: boolean;

  colleagues: User[];

  constructor(
    protected subscribeService: SubscribeService,
    protected cdr: ChangeDetectorRef,
    protected usersService: UsersService,
    protected auth: IntryAuthService,
    public helper: HelperService,
  ) { }

  ngOnInit(): void {
    this.tribute = new Tribute({
      values: this.searchUsers(),
      lookup: (person, mentionText) => {
        return Object.values(person || {})
          .join('')
          .toLowerCase();
      },
      selectTemplate: ({ original }: { original: User }) => {
        return `<span contenteditable="false"><a href="/profile/${original.id}" target="_blank">${original.fullName}</a></span>`;
      },
      noMatchTemplate: () => {
        return `<div class="co _one-line">Пользователь не найден</div>`;
      },
      menuItemTemplate: ({ original }: { original: User }) => {
        return this.getAvatarHtml(original);
      },
    });
    this.isFroalaVisible = true;
    this.addMandatoryOptions();
  }

  private getAvatarHtml(user: User): string {
    return `
      <div class="co _one-line">
        <div class="co__avatar">
            <a title="" class="avatar _40" data-initials="${this.usersService.getInitials(user)}"
               href="/profile/${user.id}" onclick="navigateProfile(event,${user.id})">
              <span class="avatar__image-wrap" style="background-image: url('${this.usersService.getPictureUrl(user)}')"></span>
            </a>
        </div>
        <div class="co__info">
            <a href="javascript:;" class="link co__name" href="/profile/${user.id}"
              onclick="navigateProfile(event,${user.id})">${user.fullName}</a>
            ${user.jobTitle ? `<div class="co__occ">${user.jobTitle}</div>` : ''}
        </div>
      </div>
    `;
  }

  ngOnDestroy(): void {
    this.mentionSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const editor = this.initControls?.getEditor();

    if (changes['simple']) {
      if (editor) {
        this.isFroalaVisible = false;
        this.initControls.destroy();
        const that = this;
        setTimeout(() => {
          that.initialize(that.initControls);
          that.isFroalaVisible = true;
        }, 100);
      }
    }
  }

  searchUsers() {
    const subject$ = new Subject();

    this.mentionSubscription = from(this.usersService.currentUser)
      .pipe(
        switchMap(currentUser => {
          return subject$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            switchMap(({ text, callback }) => {
              if (text) {
                return this.usersService.searchUsers(text, 0, 0, 3).pipe(
                  take(1),
                  map(o => o.items.map(item => item.user)),
                  map(items => ({ items, callback })),
                );
              } else {
                return this.subscribeService.searchOnUser(text, currentUser.id, 0, 3).pipe(
                  take(1),
                  map(o => o.items.map(item => item.user)),
                  map(items => ({ items, callback })),
                );
              }
            }),
          );
        }),
      )
      .subscribe(({ items, callback }) => {
        callback(items);
      });

    return (text, callback) => {
      subject$.next({
        text,
        callback,
      });
    };
  }

  public initialize(initControls) {
    this.addMandatoryOptions();

    this.initControls = initControls;
    this.initControls.initialize();
  }

  public clear() {
    this.text = '';
    this.mentions = [];
    const editor = this.initControls.getEditor();
    editor.html.set('');
    editor.toolbar.hide();
  }

  focus() {
    const editor = this.initControls.getEditor();
    editor?.events?.focus();
  }

  /**
   * Добавляет обязательные опции
   * 1. Поднятие события при изменении контента в редакторе
   */
  protected addMandatoryOptions(): void {
    this.editorOptions = { ...FroalaEditorOptions };

    this.editorOptions.placeholderText = this.placeholder;

    if (this.simple) {
      this.editorOptions.toolbarButtons = [
        'bold',
        'italic',
        'underline',
        '|',
        'formatOL',
        'formatUL',
        '|',
        'insertImage',
        'insertLink',
        'insertVideo',
        '|',
        'undo',
        'redo',
        'clearFormatting',
      ];
    }

    const that = this;

    this.editorOptions.events = {
      initialized: function () {
        const editor = this;
        that.tribute.attach(editor.el);
        editor.events.on(
          'keydown',
          function (e) {
            if (e.which === KeyCodes.ENTER && that.tribute.isActive) {
              return false;
            }
          },
          true,
        );

        if (that.focusOnInit) {
          editor.events.focus();
        } else if (!that.showToolbar) {
          editor.toolbar.hide();
        }
      },
      focus: function (e) {
        const editor = that.initControls.getEditor();
        editor?.toolbar?.show();
        that.focused.emit();
      },
      contentChanged: function () {
        const html = this.html.get();
        that.text = html;
        that.changed.emit(html);
      },
      'image.error': this.onError.bind(this),
      'video.error': this.onError.bind(this),
      'file.error': this.onError.bind(this),
    };

    this.editorOptions.videoUploadURL =
      this.editorOptions.imageUploadURL =
      this.editorOptions.fileUploadURL = this.helper.getSiteUrl() + '/api/documentFile/uploadFileToBody';

    // this.editorOptions.videoUploadParams =
    //   this.editorOptions.imageUploadParams =
    //   this.editorOptions.fileUploadParams = { id: this.itemId };

    this.editorOptions.requestHeaders = { Authorization: this.auth.getAuthorizationHeaderValue() };
  }

  protected onError(e, editor, error, response) {
    // Bad link.
    if (+error.code === 1) {
      console.log(`Bad link`);
    } else if (+error.code === 2) {
      // No link in upload response.
      console.log(`No link in upload response`);
    } else if (+error.code === 3) {
      // Error during video upload.
      console.log(`Error during video upload`);
    } else if (+error.code === 4) {
      // Parsing response failed.
      console.log(`Parsing response failed`);
    } else if (+error.code === 5) {
      // Video too text-large.
      console.log(`File too text-large`);
    } else if (+error.code === 6) {
      // Invalid video type.
      console.log(`Invalid file type`);
    } else if (+error.code === 7) {
      // Video can be uploaded only to same domain in IE 8 and IE 9.
      console.log(`File can be uploaded only to same domain in IE 8 and IE 9`);
    }
    // Response contains the original server response to the request if available.
  }

  appendMention(user: User) {
    const editor = this.initControls?.getEditor();

    editor.html.set(
        `<span contenteditable="false"><a href="/profile/${user.id}" target="_blank">${user.fullName}</a> </span>`
    );

    if (!this.mentions.find(u => u.id === user.id)) {
      this.mentions.push(user);
    }
  }

  activate(user: User) {
    const editor = this.initControls?.getEditor();

    if (!Browser.MsieEdge) {
      const opt: ScrollIntoViewOptions = {
        behavior: 'smooth',
        block: 'center'
      };

      editor?.$el[0].scrollIntoView(opt);
    }

    if (user && user.id !== this.user.id) {
      this.appendMention(user);
    } else {
      window.getSelection().collapse(editor?.$el[0], 0);
    }

    this.focus();
    editor.selection.setAtEnd(editor.$el.get(0));
    editor.selection.restore();
    this.focused.emit();
  }
}
