import {Component, OnInit, Input, OnChanges, SimpleChanges, NgZone, OnDestroy} from '@angular/core';
import {GroupInfo} from '@app/groups/model/group-info.model';
import {BaseComponent} from '@app/core/components/base.component';
import {GroupsService} from '@app/groups/services/groups.service';
import {UsersService} from '@app/profile/services/users.service';
import {MatDialogRef, MatDialog} from '@angular/material/dialog';
import {GroupType} from '@app/groups/model/group-type.model';
import {SignalRService} from '@app/signalr/signalR.service';
import {IGroupUserResult} from '@app/signalr/group.hub';
import {IGroupUserLink, IGroupUserLinkViewModel} from '@app/groups/model/group-request.model';
import {HelperService} from '@app/core/services/helper.service';
import {GroupAddUsersComponent} from '../group-add-users/group-add-users.component';
import {GroupUsersFragment} from './group-users-list.component';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {IGroupUserStateChanged} from '@app/groups/model/group-user-state-changed';
import {GroupUserRole} from '@app/groups/model/group-user-role.model';
import {User} from '@app/profile/model/user.model';

@Component({
  selector: 'app-group-users',
  templateUrl: 'group-users.component.html',
})
export class GroupUsersComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  showAddButton = false;
  @Input() group: GroupInfo;
  users: IGroupUserLink[];
  usersCount: number;
  showWidget: boolean;
  routerLink: string;
  routerFragment = '';
  widgetTitle: string;

  addUsersDialog: MatDialogRef<GroupAddUsersComponent>;
  currentUser: User;

  constructor(
    private usersService: UsersService,
    private groupsService: GroupsService,
    private signalRService: SignalRService,
    private ngZone: NgZone,
    private dialog: MatDialog,
    public helper: HelperService,
  ) {
    super(helper);
  }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;

      this.signalRService.groupHub.onSubscribeForCurrent.subscribe((res: IGroupUserResult) => {
        this.operateJoinSubscribe(res);
        this.checkUsersInWidget(res);
      });

      this.signalRService.groupHub.onJoinForCurrent.subscribe((res: IGroupUserResult) => {
        this.operateJoinSubscribe(res);
        this.checkUsersInWidget(res);
      });

      this.signalRService.groupHub.onUnsubscribeForCurrent.subscribe((res: IGroupUserResult) => {
        this.operateJoinSubscribe(res);
        this.checkUsersInWidget(res);
      });

      this.signalRService.groupHub.onLeaveForCurrent.subscribe((res: IGroupUserResult) => {
        this.operateJoinSubscribe(res);
        this.checkUsersInWidget(res);
      });

      this.groupsService.userMemberStateChanged$.pipe(takeUntil(this.destroyed$)).subscribe(res => {
        this.checkWidget(res);
      });

      this.groupsService.userSubscriberStateChanged$.pipe(takeUntil(this.destroyed$)).subscribe(res => {
        this.checkWidget(res);
      });
    });
  }

  private operateJoinSubscribe(res: IGroupUserResult) {
    this.ngZone.run(() => {
      if (res && res.result && this.group.id === res.group.id) {
        this.group = res.group;
        this.showWidget = this.groupsService.canReadGroup(res.group, this.currentUser);
      }
    });
  }

  private checkUsersInWidget(res: IGroupUserResult) {
    this.ngZone.run(() => {
      if (res && res.result && this.group.id === res.group.id) {
        this.getUsers();
      }
    });
  }

  private checkWidget(res: IGroupUserStateChanged) {
    this.ngZone.run(() => {
      if (res && this.group.id === res.groupId) {
        this.getUsers();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['group']) {
      if (this.group) {
        this.usersService.currentUser.subscribe(currentUser => {
          // show "+" when is current user
          this.showAddButton = this.groupsService.isAdmin(this.group, currentUser);

          this.showWidget = this.groupsService.canReadGroup(this.group, currentUser);

          this.routerLink = `/group/${this.group.id}/users`;
          if (this.group.type === GroupType.Business) {
            this.routerFragment = GroupUsersFragment.Admins;
            this.widgetTitle = 'Администраторы';
          } else {
            this.routerFragment = '';
            this.widgetTitle = 'Участники';
          }

          if (this.showWidget) {
            this.getUsers();
          } else {
            this.loaded = true;
          }
        });
      }
    }
  }

  private getUsers() {
    let method: Observable<IGroupUserLinkViewModel>;
    if (this.group.type === GroupType.Business) {
      method = this.groupsService.getUsersByRole(this.group.id, GroupUserRole.Administrator, 0, 6);
    } else {
      method = this.groupsService.getUsers(this.group.id, 0, 6);
    }

    method.pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        if (res && res.items) {
          this.users = res.items;
          this.usersCount = res.count;
        } else {
          this.users = [];
          this.usersCount = 0;
        }
        this.loaded = true;
      },
      error => {
        this.error = error;
        this.loaded = true;
      },
    );
  }

  showAddUser() {
    this.addUsersDialog = this.dialog.open(GroupAddUsersComponent, {
      minWidth: '50vw',
      data: {
        group: this.group,
        placeholder: `Введите имя, чтобы добавить ${
          this.group.type === GroupType.Business ? 'администратора' : 'пользователя'
        }`,
        // onOk: this.addUsers.bind(this)
        onOk: () => {
          this.addUsersDialog.close();
          this.getUsers();
        },
      },
    });
  }
}
