<div class="card" *ngIf="statusChanges">
  <span class="header-text _modal _progress">
    <span class="header-text__text">История изменения статусов</span>
  </span>
  <ng-container *ngFor="let change of statusChanges.changes">
    <span class="header-text _modal _inner">
      <span class="header-text__text">{{change.title}}</span>&nbsp;
      <span class="header-text__count">({{getDateChangeText(change)}})</span>
    </span>
    <div class="co _modal">
      <!-- TODO: вынести в шаблон -->
      <div class="co__avatar">
        <app-user-avatar [user]="change.author" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'">
        </app-user-avatar>
      </div>
      <div class="co__info">
        <a href="#" class="link co__name" routerLink="/profile/{{change.author?.id}}"
          (click)="usersService.navigateProfile(change.author?.id)"
          [appUserTooltip]="change.author?.id">{{change.author?.fullName}}</a>
        <div class="co__occ" *ngIf="change.author?.jobTitle">{{change.author?.jobTitle}}</div>
        <div class="co__occ" *ngIf="change.author?.department">{{change.author?.department}}</div>
      </div>
    </div>
    <p>{{change.comment}}</p>
  </ng-container>
  <div class="card">
    <span class="header-text _modal _inner">
      <span class="header-text__text">Идея создана</span>&nbsp;
      <span class="header-text__count">({{statusChanges.created | dateSpLike}})</span>
    </span>
    <div class="co _modal">
      <!-- TODO: вынести в шаблон -->
      <div class="co__avatar">
        <app-user-avatar [user]="statusChanges.author" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'">
        </app-user-avatar>
      </div>
      <div class="co__info">
        <a href="#" class="link co__name" routerLink="/profile/{{statusChanges.author?.id}}"
          (click)="usersService.navigateProfile(statusChanges.author?.id)"
          [appUserTooltip]="statusChanges.author?.id">{{statusChanges.author?.fullName}}</a>
        <div class="co__occ" *ngIf="statusChanges.author?.jobTitle">{{statusChanges.author?.jobTitle}}</div>
        <div class="co__occ" *ngIf="statusChanges.author?.department">{{statusChanges.author?.department}}</div>
      </div>
    </div>
  </div>
</div>