<div class="card _colleagues" *ngIf="loaded">
  <div class="card__title">
    <a *ngIf="showAddButton" class="btn btn-plus pull-right" (click)="showAddUser()">+</a>
    <div class="header-text">
      <a class="header-text__text" routerLink="/group/{{group?.id}}/users"
        [fragment]="routerFragment">{{widgetTitle}}</a>&nbsp;
      <span class="header-text__count">{{usersCount}}</span>
    </div>
  </div>
  <div class="card__content" *ngIf="showWidget">
    <div class="nowrap">
      <app-user-avatar [user]="userLink?.user" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'"
        *ngFor="let userLink of users"></app-user-avatar>
    </div>
  </div>
  <div class="card__content" *ngIf="!showWidget">
    <app-group-forbidden></app-group-forbidden>
  </div>
</div>
<div class="card" *ngIf="!loaded">
  <div class="card__title">
    <span class="preloader">
      <span class="preloader__line"></span>
    </span>
  </div>
  <div class="card__content">
    <span class="preloader _avatar" *ngFor="let r of [0,1,2,3,4]">
      <span class="preloader__side"></span>
    </span>
  </div>
</div>
