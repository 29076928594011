import { Component, OnInit, Input, OnDestroy, Renderer2 } from '@angular/core';
import { GroupInfo } from '@app/groups/model/group-info.model';
import { FilesListComponent } from '@app/files/components/files-list/files-list.component';
import { UsersService } from '@app/profile/services/users.service';
import { GroupsService } from '@app/groups/services/groups.service';
import { FilesService } from '@app/files/services/files.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Tab } from '@app/shared/components/tabs/tab.model';
import { FilesListItem } from '@app/files/model/files-list-item.model';
import { Helper } from '@app/core/helpers/helper';
import { IGroupUserResult } from '@app/signalr/group.hub';
import { SignalRService } from '@app/signalr/signalR.service';
import { takeUntil } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { FilesDialogService } from '@app/files/services/files-dialog.service';

/**
 * Список файлов группы
 */
@Component({
  selector: 'app-group-files-list',
  templateUrl: './group-files-list.component.html',
  styleUrls: ['./group-files-list.component.css'],
})
export class GroupFilesListComponent extends FilesListComponent implements OnInit, OnDestroy {
  @Input() group: GroupInfo;

  filestListType = 2;

  constructor(
    protected usersService: UsersService,
    protected groupsService: GroupsService,
    public filesService: FilesService,
    protected router: Router,
    protected route: ActivatedRoute,
    private signalRService: SignalRService,
    protected filesDialog: FilesDialogService,
    protected renderer: Renderer2,
    public helper: HelperService,
  ) {
    super(usersService, filesService, router, route, filesDialog, renderer, helper);
  }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;
      this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
        if (group) {
          this.group = group;

          this.tabs = {
            title: 'Файлы',
            routerLink: `/group/${this.group.id}/files`,
            items: [
              {
                id: 1,
                title: 'Файлы',
                fragment: '',
                name: 'files',
                items: [],
                offset: 0,
                itemsCount: 0,
                method: this.getFiles.bind(this),
                default: true,
              },
              {
                id: 2,
                title: 'Последние',
                fragment: 'recent',
                name: 'recent',
                items: [],
                offset: 0,
                itemsCount: 0,
                method: this.getRecent.bind(this),
              },
            ],
          };

          // любой, кто может читать группу - может видеть файлы
          this.showFiles = this.groupsService.canReadGroup(this.group, currentUser);

          if (this.showFiles) {
            this.filesService.ownerId$.next(this.group.id);
            // this.listUrl.next(Helper.concatUrls(this.group.url, this.filesService.groupRootFolder));
          } else {
            this.loaded = true;
          }

          // show plus button
          this.calculateShowAddButton();

          // init parent component
          super.ngOnInit();
        }
      });
    });

    this.signalRService.groupHub.onSubscribeForCurrent.subscribe((res: IGroupUserResult) => {
      this.operateJoinSubscribe(res);
    });

    this.signalRService.groupHub.onJoinForCurrent.subscribe((res: IGroupUserResult) => {
      this.operateJoinSubscribe(res);
    });

    this.signalRService.groupHub.onUnsubscribeForCurrent.subscribe((res: IGroupUserResult) => {
      this.operateJoinSubscribe(res);
    });

    this.signalRService.groupHub.onLeaveForCurrent.subscribe((res: IGroupUserResult) => {
      this.operateJoinSubscribe(res);
    });
  }

  private operateJoinSubscribe(res: IGroupUserResult) {
    if (res && res.result && this.group.id === res.group.id) {
      this.group = res.group;
      this.showFiles = this.groupsService.canReadGroup(res.group, this.currentUser);
    }
  }

  getRecent(tab: Tab<FilesListItem>) {
    this.error = '';

    // const webUrl = this.webUrl.getValue();
    // const listUrl = this.listUrl.getValue();
    // const folderUrl = this.folderUrl.getValue();

    const ownerId = this.filesService.ownerId$.getValue();
    const folderId = this.filesService.folderId$.getValue();

    if (ownerId) {
      const folder = folderId ? folderId : null;
      // this.filesService
      //   .webApiGetLastAsStream(webUrl, listUrl, folder, tab.offset, this.limit, true)
      //   .pipe(takeUntil(this.destroyed$))
      //   .subscribe(
      //     filesList => {
      //       tab.data = filesList;
      //       tab.offset = filesList.lastRow;
      //       this.nextHref = filesList.nextHref;
      //       this.folderPermissions = filesList.folderPermissions;
      //       if (tab.data.listUrlDir) {
      //         this.rootFolderUrl.next(tab.data.listUrlDir);
      //       }
      //       this.operateWebApiResult(filesList, tab);
      //     },
      //     error => {
      //       this.error = error;
      //       this.loaded = tab.loaded = true;
      //     },
      //   );
    } else {
      // no list
      this.loaded = tab.loaded = true;
    }
  }

  /**
   * Отображать меню?
   *
   * @returns
   * @memberof GroupFilesListComponent
   */
  isShowMenu() {
    return this.currentTab && this.currentTab.name === 'files' && this.showAddButton;
  }

  isShowNoAccess(): boolean {
    return !this.error && !this.showFiles && this.loaded && this.group != null && this.group !== undefined;
  }

  protected calculateShowAddButton() {
    // show plus button
    this.showAddButton = this.groupsService.isParticipant(this.group, this.currentUser) || this.isEditor();
  }
}
