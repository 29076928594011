<div class="menu _static">
  <div class="menu__list _visible">
    <div class="menu-list">
      <a routerLink="/" class="logo icon menu-list__logo" (click)="usersService.navigateRoot()">
        <img src="{{assetsPrefix}}/assets/images/logo-big.svg?rev=6" />
      </a>
      <div class="menu-list__header">
        <div class="co _white-text _column">
          <div class="co__avatar">
            <app-user-avatar [user]="currentUser" [linkClass]="'avatar _80'" [imageClass]="'avatar__image'">
            </app-user-avatar>
          </div>
          <a class="co__info" routerLink="/profile/{{currentUser?.id}}">
            <span class="link co__name">{{currentUser?.fullName}}</span>
          </a>
        </div>
      </div>
      <app-left-menu-items [cssClass]="'menu-list__item'"></app-left-menu-items>
    </div>
    <div class="menu-list _bottom">
      <a [routerLink]="['/admin/pages']" class="menu-list__item" routerLinkActive="_active"
         [routerLinkActiveOptions]="{ exact: true }" (click)="navigate()" *ngIf="currentUser?.isAdmin">
        <div class="header-text _low">
          <span class="header-text__icon icon _header-settings"></span>
          <span class="header-text__text">Администрирование</span>
        </div>
      </a>
      <a href="#" class="menu-list__item" routerLink="/feedback" routerLinkActive="_active"
         [routerLinkActiveOptions]="{ exact: true }" (click)="navigate()">
        <div class="header-text _low">
          <span class="header-text__icon icon _header-ask"></span>
          <span class="header-text__text">Задать вопрос</span>&nbsp;
        </div>
      </a>
      <a href="javascript:;" class="menu-list__item" (click)="logout()">
        <div class="header-text _low">
          <span class="header-text__icon icon _exit-white"></span>
          <span class="header-text__text">Выйти</span>&nbsp;
        </div>
      </a>
    </div>
  </div>
</div>
