import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { UsersAPIService } from '@app/profile/services/users.api.service';
import { User, IUserProfileProperty, Privacy, IUserPhotoChanged, AbsenceType } from '@app/profile/model/user.model';
import { Subject, BehaviorSubject, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { AlertsService } from '@app/shared/services/alerts.service';
import { Helper } from '@app/core/helpers/helper';
import { DatePipe } from '@angular/common';

/**
 * Сервис получения и обработки данных пользователей
 *
 * @export
 * @class UsersService
 * @extends {UsersAPIService}
 */
@Injectable()
export class UsersService extends UsersAPIService {

    public users: User[] = [];

    public userPropertiesLoaded$: Subject<void> = new Subject();

    public userBirthdayProperty$: Subject<IUserProfileProperty> = new Subject();
    public cellPhoneProperty$: Subject<IUserProfileProperty> = new Subject();
    public russianPatronymicName$: Subject<IUserProfileProperty> = new Subject();
    public anyPropertyChanged$: Subject<any> = new Subject();

    public fbLinkProperty$: Subject<IUserProfileProperty> = new Subject();
    public vkLinkProperty$: Subject<IUserProfileProperty> = new Subject();
    public instagramLinkProperty$: Subject<IUserProfileProperty> = new Subject();
    public odnoklassnikiLinkProperty$: Subject<IUserProfileProperty> = new Subject();

    public userPhotoChanged$: Subject<IUserPhotoChanged> = new Subject();
    public userPhotoDeleted$: Subject<IUserPhotoChanged> = new Subject();

    /**
     * Кнопка "назад" была найдена
     *
     * @type {boolean}
     * @memberof UsersAPIService
     */
    public backPressed: boolean = undefined;

    constructor(
        private alertsService: AlertsService,
        protected http: HttpClient,
        protected router: Router,
        protected datePipe: DatePipe,
        public helper: HelperService
    ) { super(http, helper); }

    /**
     * Перейти на профиль пользователя
     *
     * @param {number} id ИД пользователя, на профиль которого нужно перейти
     * @param {string} [additional='']
     * @param {string} [fragment='']
     * @param {boolean} [force=true]
     * @memberof UsersService
     */
    navigateProfile(id: number, additional: string = '', fragment: string = '', force = false) {
        this.currentUser.subscribe(currentUser => {

            // при переходе на профиль юзера лучше обнулять текущего
            const user = this.user.getValue();
            if (user != null && id !== user.id || force) {
                this.user.next(null);
            }

            let url = `/profile/${id}`;

            if (additional) {
                url += `${additional ? '/' + additional : ''}`;
            }

            // дополнительно фрагмент
            if (fragment) {
                this.router.navigate([url], { fragment: fragment });
            } else {
                this.router.navigate([url]);
            }
        });
    }

    /**
     * Перейти на главную
     *
     * @memberof UsersService
     */
    navigateRoot() {
        this.currentUser.subscribe(currentUser => {
            const user = this.user.getValue();
            if (user != null && currentUser.id !== user.id) {
                this.user.next(null);
            }
            this.router.navigate(['/']);
        });
    }

    /**
     * Получить инициалы пользователя
     *
     * @param {User} user Пользователь, инцициалы которого нужно получить
     * @returns {string}
     * @memberof UsersService
     */
    getInitials(user: User): string {
        if (user && user.fullName) {
            return user.fullName.split(' ').map(function (s) { return s.charAt(0); }).join('');
        }
        return '';
    }

    /**
     * Получить пользователя по ИД.
     * Если пользователь уже существует - не отправлять запрос, а возвращать имеющегося.
     *
     * @param {number} id ИД пользователя, инфу о котором нужно получить
     * @returns
     * @memberof UsersService
     */
    getExistingUser(id: number) {
        if (!id) {
            return of(null);
        }
        const user = this.users.filter(u => u.id === id);
        if (!user) {
            return this.getUser(id);
        }
        return of(user);
    }

    getExistingUserBySharePoint(webUrl: string, id: number) {
        if (!id) {
            return of(null);
        }
        return this.getUserBySharePoint(webUrl, id).pipe(map(res => {
            if (!this.users.find(u => u.id === id)) {
                this.users.push(res);
            }
            return res;
        }));
    }

    /**
     * Получение пользователя по логину.
     * Если пользователь уже существует - не отправлять запрос, а возвращать имеющегося.
     *
     * @param {string} login Логин пользователя, инфу о котором нужно получить
     * @returns
     * @memberof UsersService
     */
    getExistingUserByLogin(login: string) {
        if (!login) {
            return of(null);
        }

        const index = login.toLowerCase().indexOf('@');

        if (index !== -1) {
            let temp = login.substr(index + 1);
            const pointIndex = temp.indexOf('.');

            if (pointIndex !== -1) {
                temp = temp.substr(0, pointIndex);
            }

            login = temp + '\\' + login.substr(0, index);
        }

        const user = this.users.find(u => u.accountName && u.accountName.toLowerCase() === login);
        if (!user) {
            return this.getUserByLogin(login);
        }
        return of(user);
    }

    isAnyAdmin(user: User) {
        return user && (user.isAdmin || user.isBusinessGroupsAdmin || user.isGroupsAdmin);
    }

    isCanEditPrivacy(property: IUserProfileProperty, user: User, currentUser: User) {
        return user
            && currentUser
            && user.id === currentUser.id
            && property
            && property.userOverridePrivacy;
    }

    getPropertyVisiblityText(property: IUserProfileProperty) {
        if (!property) {
            return '';
        }
        return property.privacy === Privacy.Private
            ? 'Отображается только вам'
            : 'Отображается всем';
    }

    togglePrivacy(property: IUserProfileProperty) {
        let newPrivacy: Privacy = null;
        if (property.privacy !== Privacy.Public) {
            newPrivacy = Privacy.Public;
        } else {
            newPrivacy = Privacy.Private;
        }
        this.setUserProfilePropertyPrivacy(property.name, newPrivacy)
            .pipe(take(1))
            .subscribe(res => {
                if (res) {
                    this.alertsService.riseSuccess(`Настройки видимости успешно изменены`);
                    property.privacy = newPrivacy;
                } else {
                    this.alertsService.riseError(`Произошла ошибка при изменении настроек видимости`);
                }
            }, error => {
                this.alertsService.riseError(`Произошла ошибка при изменении настроек видимости`);
            });
    }

    getPictureUrl(user: User) {
        if (user.pictureUrl) {
            return Helper.concatUrls(this.helper.getSiteUrl(), user.pictureUrl);
        }
        return '';
    }

    getAbsenceCss(user: User) {
        if (!user && !user.absenceType) {
            return '';
        }
        switch (user.absenceType) {
            case AbsenceType.vacation:
                return '_vacation';
            case AbsenceType.sick:
                return '_sick';
            case AbsenceType.errand:
                return '_trip';
            case AbsenceType.common:
                return '_common';
        }
    }

    getAbsenceText(user: User) {
        if (!user && !user.absenceType && !user.absenceDateStart) {
            return '';
        }
        if (user.absenceText !== undefined && user.absenceText !== null) {
            return user.absenceText;
        }
        try {
            let dateString = `${this.datePipe.transform(user.absenceDateStart, 'dd.MM.yyyy')}`;
            if (user.absenceDateEnd) {
                dateString += ` по ${this.datePipe.transform(user.absenceDateEnd, 'dd.MM.yyyy')}`;
            }
            switch (user.absenceType) {
                case AbsenceType.vacation:
                    user.absenceText = `В отпуске с ` + dateString;
                    break;
                case AbsenceType.sick:
                    user.absenceText = `На больничном c ` + dateString;
                    break;
                case AbsenceType.errand:
                    user.absenceText = `В командировке c ` + dateString;
                    break;
                case AbsenceType.common:
                    user.absenceText = `Отсутствует с ` + dateString;
                    break;
                default:
                    user.absenceText = ``;
                    break;
            }
        } catch (e) {
            console.error(e);
            user.absenceText = ``;
        }
        return user.absenceText;
    }
}
