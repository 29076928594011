import { OnInit, OnDestroy, Component } from '@angular/core';
import { FilesService } from '@app/files/services/files.service';
import { BaseComponent } from '@app/core/components/base.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { FilesListItem } from '@app/files/model/files-list-item.model';
import { HelperService } from '@app/core/services/helper.service';
import { IFileMenuConfig } from '../file-menu/file-menu.component';
import { takeUntil } from 'rxjs/operators';
import { FilesDialogService } from '@app/files/services/files-dialog.service';

/**
 * Базовый компонент виджета файлов
 *
 * @export
 * @abstract
 * @class FilesWidgetComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
    selector: 'app-files-widget-component',
    template: ''
})
export abstract class FilesWidgetComponent extends BaseComponent implements OnDestroy {

    items: Array<FilesListItem>;
    config: IFileMenuConfig = {};

    protected showAddButton: boolean;

    link = '/files';
    fragment = '';

    protected showWidget: boolean;

    constructor(
        public filesService: FilesService,
        protected filesDialog: FilesDialogService,
        public helper: HelperService
    ) {
        super(helper);
        this.filesService.filesUploaded$.pipe(takeUntil(this.destroyed$))
            .subscribe(res => {
                this.getItems(true);
            });
    }

    abstract navigate(force: boolean);

    abstract getItems(force: boolean);

    onFolderCreated() {
        this.navigate(true);
    }

    abstract onFilesAdded(folder: string);

    openFile(e: Event, item: FilesListItem): boolean {
        return this.filesDialog.openFile(e, item, this.items);
    }
}
