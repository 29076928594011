<div class="cos__header" *ngIf="loaded">
  <app-tabs [tabs]="tabs"></app-tabs>
  <a *ngIf="isCurrent" class="btn btn-plus pull-right" (click)="thank()">+</a>
</div>
<!-- header preloader -->
<app-tab-preloader *ngIf="!loaded"></app-tab-preloader>
<!--  header preloader END -->
<div class="cos__body" *ngIf="loaded && currentTab && currentTab.loaded">
  <ng-container *ngIf="currentTab?.loaded && currentTab?.items && currentTab?.items.length">
    <div class="cos__body _paddings">
      <span class="header-text _bigger _low _secondary">
        <span class="header-text__text">{{getHeaderText(currentTab)}}</span>
      </span>
    </div>
    <div *ngFor="let link of currentTab?.items" class="co _thanks">
      <div class="co__avatar">
        <app-user-avatar [user]="link.user" [linkClass]="'avatar _80'" [imageClass]="'avatar__image'"></app-user-avatar>
      </div>
      <div class="co__info">
        <a href="#" class="link co__name" routerLink="/profile/{{link.user?.id}}"
          (click)="usersService.navigateProfile(link.user?.id)" [appUserTooltip]="link.user?.id"
          [innerHTML]="link.user?.fullName | highlight:text:true"></a>
        <div class="co__occ">{{link.created | dateSpLike:true}}</div>
        <div class="co__text" (click)="showFullText(link)">
          <div class="post">
            <div class="post__text" [ngClass]="{'_trimmed': link.body?.length > 256}">{{link.body}}</div>
            <a *ngIf="link.body?.length > 256" (click)="showFullText(link)" class="post__read-more">Читать полностью</a>
          </div>
        </div>
      </div>
      <div class="co__action" *ngIf="currentUser?.id!==link.user?.id">
        <button class="btn btn-primary" type="button" (click)="thank(link.user)">Сказать спасибо</button>
      </div>
    </div>
  </ng-container>
  <div class="co" *ngIf="currentTab?.loading">
    <mat-spinner [diameter]="56"></mat-spinner>
  </div>
</div>
<!-- Empty tab -->
<div class="card"
  *ngIf="!currentTab?.loading && loaded && currentTab?.loaded && (!currentTab?.items || !currentTab?.items.length)">
  <div class="no-data">
    <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" class="no-data__img">
    <div class="no-data__text">{{currentTab?.emptyText}}</div>
    <div class="no-data__action">

    </div>
  </div>
</div>
<!-- Empty tab END-->
<!-- header preloader -->
<app-tabbed-list-preloader *ngIf="!currentTab?.loaded"></app-tabbed-list-preloader>
<!--  header preloader END -->
