import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { FilesService } from '@app/files/services/files.service';
import { Observable, of } from 'rxjs';
import { IntryAuthService } from '../services/intry-auth.service';
import { IntryAutoLoginGuard } from './intry-auto-login.guard';

@Injectable({ providedIn: 'root' })
export class IntryUserFilesGuard extends IntryAutoLoginGuard {

    constructor(
        protected auth: IntryAuthService,
        private filesService: FilesService,
        protected router: Router,
    ) {
        super(auth, router);
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const isAuthorized = super.canActivate(route, state);

        return isAuthorized ? this.filesService.showUserFiles$ : of(false);
    }
}
