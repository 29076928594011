<div class="cos__filters _tall" *ngIf="visible">
  <div class="dropdown _full">
    <div mat-button [matMenuTriggerFor]="statusMenu">{{selectedStatus?.title}}</div>
    <mat-menu #statusMenu="matMenu">
      <div class="icon-menu" #menuContainer>
        <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" (click)="setStatus(status)" *ngFor="let status of statuses">
          <span>{{status?.title}}</span>
        </a>
      </div>
    </mat-menu>
  </div>
</div>