import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '@app/core/services/helper.service';
import { Observable, of } from 'rxjs';
import { IFeedbackCategory, INewFeedback } from '../model/feedback';

/**
 * API сервис обратной связи
 *
 * @export
 * @class FeedbackService
 * @extends {BaseService}
 */
@Injectable({
  providedIn: 'root'
})
export class FeedbackService extends BaseService {

  protected serviceUrl = `${this.helper.environment.siteUrl}/api/feedback`;

  constructor(
    protected http: HttpClient,
    public helper: HelperService) { super(http, helper); }

  /**
   * Получение категорий обратной связи
   *
   * @memberof FeedbackService
   */
  getCategories(): Observable<IFeedbackCategory[]> {
    return super.get(`${this.helper.environment.siteUrl}/api/feedbackCategory`);
  }

  /**
   * Отправка вопроса обратной связи
   *
   * @returns {Observable<boolean>}
   * @memberof FeedbackService
   */
  send(data: INewFeedback): Observable<boolean> {
    return super.post('sendFeedback', data);
  }

  /**
   * Удаление категории обратной связи
   * @param id ИД категории обратной связи
   * @returns boolean
   */
  deleteCategory(id: number): Observable<boolean> {
    return super.get(`${this.helper.environment.siteUrl}/api/feedbackCategory/${id}`);
  }
}
