import { Component, OnDestroy } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { Router } from '@angular/router';
import { HelperService } from '@app/core/services/helper.service';
import { IIdea } from '@app/ideas/model/idea';

/**
 * Базовый компонент виджета идей
 *
 * @export
 * @class IdeaWidgetComponent
 * @extends {BaseComponent}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-idea-widget',
  template: ''
})
export class IdeaWidgetComponent extends BaseComponent implements OnDestroy {

  /**
   * Массив идей
   */
  ideas: IIdea[];

  /**
   * Ссылка перехода в раздел идей
   */
  link = '/ideas';

  /**
   * Отображать виджет (только для пользователей у которых есть права)
   */
  showWidget: boolean;

  /**
   * Отображать ссылку создания нового идеи
   */
  showAddButton: boolean;

  /**
   * Ссылка создания нового идеи
   */
  addButtonLink: string;

  constructor(
    protected router: Router,
    public helper: HelperService
  ) { super(helper); }

  navigate() {
    this.router.navigate([this.link]);
  }

  onDeleted(id: number) {
    this.ideas = this.ideas.filter(s => s.id !== id);
  }
}
