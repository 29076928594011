<div class="container _full-page _moved">

  <!-- <app-banner-widget></app-banner-widget> -->
  <app-feed-widget></app-feed-widget>

  <div class="horizontal-bar _pb">
    <div class="all-news">
      <a [routerLink]="['/news']" class="link _secondary">Все новости</a>
    </div>
  </div>

  <div class="horizontal-bar hidden-phone">
    <app-anniversary-widget></app-anniversary-widget>
    <app-birthdays-widget></app-birthdays-widget>
    <app-thanks-widget></app-thanks-widget>
  </div>

  <div class="horizontal-bar hidden-tablet hidden-desktop">
    <app-anniversary-widget></app-anniversary-widget>
    <div class="rich-tabs">
      <div class="rich-tabs__content">

        <div class="rich-tabs__item _birthdays">
          <a routerLink="/calendar" fragment="monthView&filter=all">
            <span class="rich-tabs__img">
              <img src="{{assetsPrefix}}/assets/images/widgets/rich-tabs/icon-birthdays.svg">
              <span class="rich-tabs__text">Дни<br/>рождения</span>
            </span>
          </a>
        </div>

        <div class="rich-tabs__item _thanks">
          <a routerLink="/thanks">
            <span class="rich-tabs__img">
              <img src="{{assetsPrefix}}/assets/images/widgets/rich-tabs/icon-thanks.svg">
              <span class="rich-tabs__text">Спасибо<br/>от сотрудников</span>
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="horizontal-bar hidden-phone">
    <ng-template #bigDynamicContainer appDynamicContainer></ng-template>
    <div class="card _simple">
      <div class="rich-tabs">
        <div class="rich-tabs__content">
          <ng-template #smallDynamicContainer appDynamicContainer></ng-template>
        </div>
      </div>
    </div>
  </div>
  <app-user-feed></app-user-feed>
  <div class="content">
    <div class="align-center hidden-desktop">
      <button [@visibilityChanged]="goTopVisible" (click)="scrollTop()" type="button" class="btn back-to-top"></button>
    </div>
  </div>
</div>
<app-onboarding page="index"></app-onboarding>
