<div class="card" appClickOutside (clickOutside)="collapse()">
  <div class="post _edit">
    <div class="new-comment__main">
      <app-user-avatar *ngIf="!fullView" [user]="currentUser" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'">
      </app-user-avatar>
      <div class="post__type post-type" *ngIf="fullView && allowNews">
        <a class="post-type__item _text" matTooltip="Текстовая публикация"
          (mouseover)="cdr.detectChanges()"
          (mouseout)="cdr.detectChanges()"
          [matTooltipShowDelay]="0"
          [matTooltipHideDelay]="0"
          [ngClass]="{'_active':postMode===postModes.text}" href="javascript:;"
          (click)="changePostMode(postModes.text)">Текст</a>
        <a class="post-type__item _news" matTooltip="Новостная публикация"
          (mouseover)="cdr.detectChanges()"
          (mouseout)="cdr.detectChanges()"
          [matTooltipShowDelay]="0"
          [matTooltipHideDelay]="0"
          [ngClass]="{'_active':postMode===postModes.news}" href="javascript:;"
          (click)="changePostMode(postModes.news)">Новость</a>
      </div>

      <div class="new-post post__news-fields" *ngIf="fullView && postMode===postModes.news">
        <div class="input-main new-post__title">
          <input type="text"
                 placeholder="Название новости*"
                 [(ngModel)]="title"
                 class="input-main__field"
                 (keyup)="onKey($event)"
          >
        </div>

        <div class="input-main new-post__subtitle">
          <textarea autosize rows="3" placeholder="Анонс новости" [(ngModel)]="announce" class="input-main__field"
            (keyup)="onKey($event)">
          </textarea>
        </div>
      </div>

      <app-froala-editor class="post__editor" (focused)="expand()" [ngClass]="{'_full' : fullView}"
        [simple]="postMode === postModes.text" [showToolbar]="fullView" [placeholder]="placeholder"
        [(editorModel)]="text" [(text)]="text" [(mentions)]="mentions"></app-froala-editor>
    </div>
    <ng-container *ngIf="fullView">
      <div class="post__about">Используйте «@», чтобы упомянуть коллегу</div>
      <app-post-profile-selector *ngIf="showScope" [postMode]="postMode" (profileSelected)="onProfileSelected($event)"
        [selectorType]="selectorType"></app-post-profile-selector>
      <app-add-tags *ngIf="postMode===postModes.news" placeholder="Теги" [tagType]="TagType.post"
        [mode]="AddTagsComponentMode.edit"></app-add-tags>
      <div class="post__attachments">
        <app-attachments-list #attachmentsList [attachments]="attachments" [isEdit]="true"
          (onRemove)="onRemoveAttachment($event)" (onFilesUploaded)="onFilesUploaded($event)" [ownerId]="ownerId"
          [ownerType]="ownerType" [elementType]="elementType">
        </app-attachments-list>
        <app-media-attachments-list #mediaAttachmentsList [mediaAttachments]="mediaAttachments" [isEdit]="true"
          (onRemove)="onRemoveMediaAttachment($event)" (onFilesUploaded)="onMediaFilesUploaded($event)"
          [ownerId]="ownerId" [ownerType]="ownerType" [elementType]="elementType"></app-media-attachments-list>
      </div>
      <div class="new-comment__actions">
        <mat-checkbox [(ngModel)]="disableComment">Запретить комментарии</mat-checkbox>
        <mat-checkbox [(ngModel)]="disableLike">Запретить лайки</mat-checkbox>
        <mat-checkbox *ngIf="isBusinessNews()" [(ngModel)]="showOnMainPage">Отображать на главной</mat-checkbox>
        <div *ngIf="isShowPinCheckbox()">
          <mat-checkbox [(ngModel)]="pin">{{pinText()}}</mat-checkbox>
          <div class="post__calendar" *ngIf="pin">
            Закрепить до
            <input placeholder="дд.мм.гггг" matInput [matDatepicker]="dp" [(ngModel)]="pinDateEnd">
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp></mat-datepicker>
          </div>
        </div>
        <mat-checkbox *ngIf="isShowNewEmployeeCheckbox()" [(ngModel)]="isNewEmployee">Новый сотрудник</mat-checkbox>
        <div class="post__calendar" *ngIf="postMode===postModes.news">
          Дата публикации*
          <input placeholder="дд.мм.гггг" matInput [matDatepicker]="dp" [(ngModel)]="publishDate" [max]="maxDate">
          <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
          <mat-datepicker #dp></mat-datepicker>
        </div>
      </div>
      <div class="post__actions" [ngClass]="{'loading _blue': posting}">
        <div>
          <app-media-attachments-menu title="Добавить медиафайл" (choose)="mediaAttachmentsList.onChooseFile($event)"
            (upload)="mediaAttachmentsList.onUploadFiles($event)"></app-media-attachments-menu>
          <app-attachments-menu title="Добавить вложение" (choose)="attachmentsList.onChooseFile($event)"
            (upload)="attachmentsList.onUploadFiles($event)"></app-attachments-menu>
        </div>
        <div class="post__submit">
          <button type="button" class="btn btn-simple" (click)="cancel()">Отмена</button>
          <button type="button" *ngIf="postMode===postModes.news" class="btn btn-simple" (click)="saveAsDraft()"
            [attr.disabled]="isValid()?null:''">Сохранить как черновик</button>
          <button type="button" class="btn btn-primary" (click)="submit()"
            [attr.disabled]="isValid()?null:''">Опубликовать</button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
