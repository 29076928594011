import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Breadcrumb } from '@app/files/model/files-list-item.model';
import { Subject, BehaviorSubject } from 'rxjs';
import { FilesService } from '@app/files/services/files.service';
import { takeUntil, take } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { BaseComponent } from '@app/core/components/base.component';

/**
 * Хлебные крошки файлов
 *
 * @export
 * @class FilesBreadcrumbsComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-files-breadcrumbs',
  templateUrl: './files-breadcrumbs.component.html'
})
export class FilesBreadcrumbsComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() folderUrl: Subject<string> = new BehaviorSubject<string>(null);
  @Input() rootFolderUrl: Subject<string> = new BehaviorSubject<string>(null);
  @Output() onNavigate: EventEmitter<any> = new EventEmitter();

  breadcrumbs: Array<Breadcrumb>;

  constructor(
    private filesService: FilesService,
    public helper: HelperService,
  ) { super(helper); }

  ngOnInit() {
    this.folderUrl.pipe(takeUntil(this.destroyed$)).subscribe(folderUrl => {
      if (folderUrl) {
        this.getBreadcrumbs(folderUrl);
      }
    });
  }

  /**
   * Получить хлебные крошки
   *
   * @param {string} folderUrl
   * @memberof FilesBreadcrumbsComponent
   */
  getBreadcrumbs(folderUrl: string) {
    // prepare breadcrumbs
    this.rootFolderUrl.pipe(take(1), takeUntil(this.destroyed$)).subscribe(rootFolderUrl => {
      if (rootFolderUrl) {
        this.breadcrumbs = this.filesService.getBreadcrumbs(decodeURIComponent(folderUrl), decodeURIComponent(rootFolderUrl));
      }
    });
  }

  /**
   * Перейти по хлебной крошке
   *
   * @param {Breadcrumb} b
   * @memberof FilesBreadcrumbsComponent
   */
  navigate(b: Breadcrumb) {
    if (b && b.url === '') {
      this.breadcrumbs = this.breadcrumbs.slice(0, 1);
    }
    this.onNavigate.emit(b);
  }
}
