import { Injectable } from '@angular/core';
import { AwardsApiService } from './awards-api.service';
import { IAward } from '../model/awards';
import { Helper } from '@app/core/helpers/helper';
import { BehaviorSubject } from 'rxjs';

/**
 * Cервис "Наград"
 *
 * @export
 * @class AwardsService
 * @extends {BaseService}
 */
@Injectable({
  providedIn: 'root'
})
export class AwardsService extends AwardsApiService {

  currentAward: BehaviorSubject<IAward> = new BehaviorSubject(null);

  defaultIcon: string = this.helper.environment.assetsPrefix + '/assets/images/awards/1.svg';

  getIconUrl(iconUrl: string): string {
    if (iconUrl) {
      const prefixUrl = iconUrl.indexOf('/api/documentFile') !== -1
                ? this.helper.getSiteUrl()
                : this.helper.getSiteHostUrl();
      return Helper.concatUrls(prefixUrl, iconUrl);
    }
    return this.defaultIcon;
  }
}
