import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HelperService } from './helper.service';
import { BaseApiService } from './base-api.service';

@Injectable()
export abstract class BaseService extends BaseApiService {

    protected abstract serviceUrl: string;

    constructor(
        protected http: HttpClient,
        public helper: HelperService) { super(http, helper); }

}
