import {
  Component,
  Input,
  Output,
  EventEmitter,
  NgZone,
  ChangeDetectorRef,
} from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { GalleryService } from '@app/gallery/services/gallery.service';
import { UsersService } from '@app/profile/services/users.service';
import { MediaFileType, IMediaFile } from '@app/gallery/model/media-file';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { ElementType } from '@app/core/model/element-type.enum';
import { Observable } from 'rxjs';
import { take, finalize, takeUntil } from 'rxjs/operators';
import { MediaAttachmentViewComponent } from '../media-attachment-view/media-attachment-view.component';
import { MediaAttachmentViewModel } from '../../model/media-attachment-view-model';
import { HelperService } from '@app/core/services/helper.service';
import { Helper } from '@app/core/helpers/helper';
import { ImageCropComponent } from '@app/shared/components/image-crop/image-crop.component';
import { AlertsService } from '@app/shared/services/alerts.service';

/**
 * Компонент списка вложенных медиа-файлов
 *
 * @export
 * @class MediaAttachmentsListComponent
 * @extends BaseComponent
 * @implements OnInit
 */
@Component({
  selector: 'app-media-attachments-list',
  templateUrl: './media-attachments-list.component.html',
  styleUrls: ['./media-attachments-list.component.scss'],
})
export class MediaAttachmentsListComponent extends BaseComponent {
  @Input() isEdit: boolean;
  @Input() mediaAttachments: MediaAttachmentViewModel[] = [];
  @Input() ownerId: number;
  @Input() ownerType: number;
  @Input() elementType: ElementType = ElementType.Event;

  @Output() onRemove: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFilesUploaded: EventEmitter<any> = new EventEmitter<any>();

  attachmentsToUpload: any = [];

  filesUploading: boolean;
  mediaFileType = MediaFileType;

  constructor(
    public galleryService: GalleryService,
    protected usersService: UsersService,
    protected alertsService: AlertsService,
    public sanitizer: DomSanitizer,
    public dialog: MatDialog,
    protected ngZone: NgZone,
    protected cdr: ChangeDetectorRef,
    public helper: HelperService
  ) {
    super(helper);
  }

  /**
   * Открыть всплывашку медиа-файла
   *
   * @param IMediaFile mediaFile
   * @memberof MediaListComponent
   */
  openMediaFile(e: Event, mediaFile: IMediaFile) {
    e.stopImmediatePropagation();

    const mediaFiles: IMediaFile[] = [];

    this.mediaAttachments.forEach((mediaAttachment) => {
      mediaFiles.push(mediaAttachment.mediaAttachment);
    });

    this.dialog.open(MediaAttachmentViewComponent, {
      data: {
        item: mediaFile,
        items: mediaFiles,
      },
    });
  }

  /**
   * Действие при выборе медиа-файла
   *
   * @param IMediaFile[] event
   * @memberof MediaAttachmentsListComponent
   */
  onChooseFile(event: IMediaFile[]) {
    if (event && event.length) {
      event.forEach((mediaFile) => {
        const attachment: MediaAttachmentViewModel = {
          mediaAttachment: {
            id: mediaFile.id,
            title: mediaFile.name,
            guid: mediaFile.guid,
            url: mediaFile.url,
            previewUrl: mediaFile.previewUrl,
            type: mediaFile.type,
          },
        };
        this.mediaAttachments.push(attachment);
      });
    }
    this.cdr.detectChanges();
  }

  /**
   * Действие при загрузке медиа-файла
   * @deprecated Следует пользоваться новым методом onUploadFiles
   *
   * @param * event
   * @memberof MediaAttachmentsListComponent
   */
  onUploadFile(event) {
    const files = event.target.files;
    const that = this;

    if (files && files.length) {
      for (let i = 0; i < files.length; i++) { }
    }

    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        this.attachmentsToUpload.push({
          name: files[i].name,
          file: files[i],
          id: `toUpload_${files[i].name}`,
          toUpload: true,
        });

        const mediaAttachment = {
          title: files[i].name,
          url: `toUpload_${files[i].name}`,
          toUpload: true,
          guid: Helper.guid(),
          type: MediaFileType.image,
          tempUrl: null,
        };

        if (files[i].name.match(/^.*\.(mp4|avi|wmv|webm|ogg)/gi)) {
          mediaAttachment.type = MediaFileType.video;
          this.mediaAttachments.push({
            mediaAttachment: mediaAttachment,
          });
          this.cdr.detectChanges();
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(files[i]);
          reader.onload = function (progressEvent: ProgressEvent) {
            mediaAttachment.tempUrl = (<FileReader>progressEvent.target).result;
            that.mediaAttachments.push({
              mediaAttachment: mediaAttachment,
            });
            that.cdr.detectChanges();
          };
        }
      }
    }
  }

  onUploadFiles(event) {
    this.filesUploading = true;
    this.cdr.detectChanges();

    const files = event.target.files;

    if (files && files.length) {
      // init promise
      let promise: Observable<IMediaFile[]>;
      // get files from form data
      const formData: FormData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i], files[i].name);

        // different attachment types upload into different libraries
        switch (this.elementType) {
          case ElementType.Comment:
            promise = this.galleryService.uploadCommentAttachedFiles(
              formData,
              this.ownerId
            );
            break;
          case ElementType.Event:
            promise = this.galleryService.uploadFeedAttachedFiles(
              formData,
              this.ownerId,
              this.ownerType
            );
            break;
        }

        // real request for upload
        promise
          .pipe(
            finalize(() => (this.filesUploading = false))
          )
          .subscribe(
            (res) => {
              // callback
              this.onFilesUploaded.emit(res);
            },
            (error) => {
              // error
              console.log(error);
            }
          );
      }
    }
  }

  uploadAttachedFiles(type: ElementType) {
    // only if there are anything to upload
    if (this.attachmentsToUpload && this.attachmentsToUpload.length) {
      // init promise
      let promise: Observable<IMediaFile[]>;
      // get files from form data
      const formData: FormData = new FormData();
      for (let i = 0; i < this.attachmentsToUpload.length; i++) {
        formData.append(
          'file',
          this.attachmentsToUpload[i].file,
          this.attachmentsToUpload[i].name
        );

        // different attachment types upload into different libraries
        switch (type) {
          case ElementType.Comment:
            promise = this.galleryService.uploadCommentAttachedFiles(
              formData,
              this.ownerId
            );
            break;
          case ElementType.Event:
            promise = this.galleryService.uploadFeedAttachedFiles(
              formData,
              this.ownerId,
              this.ownerType
            );
            break;
        }

        // real request for upload
        promise
          .pipe(
            finalize(() => (this.filesUploading = false))
          )
          .subscribe(
            (res) => {
              // callback
              this.onFilesUploaded.emit(res);
            },
            (error) => {
              // error
              console.log(error);
            }
          );
      }
    }
  }

  /**
   * Удаление медиа-вложения из списка
   *
   * @param MediaAttachmentViewModel attachment
   * @param number index
   * @memberof MediaAttachmentsListComponent
   */
  remove(attachment: MediaAttachmentViewModel, index: number) {
    this.ngZone.run(() => {
      this.mediaAttachments.splice(index, 1);
      // очистка из списка необходимых к загрузке
      if (attachment.mediaAttachment.toUpload) {
        this.attachmentsToUpload = this.attachmentsToUpload.filter(
          (s) => s.id !== attachment.mediaAttachment.url
        );
      }
    });

    // this.onRemove.emit(attachment);
  }

  crop(e: Event, attachment: MediaAttachmentViewModel, index: number) {
    e.stopImmediatePropagation();

    this.dialog.open(ImageCropComponent, {
      data: {
        item: attachment.mediaAttachment,
        onCropped: (mf: IMediaFile) => {
          this.mediaFileWasCropped(attachment, mf);
        },
      },
    });
  }

  private mediaFileWasCropped(
    attachment: MediaAttachmentViewModel,
    mf: IMediaFile
  ) {
    this.filesUploading = true;
    this.cdr.detectChanges();

    attachment.mediaAttachment = mf;
    const blob = Helper.dataURItoBlob(mf.croppedUrl);
    const formData = new FormData();
    const fileName = mf.url.substring(mf.url.lastIndexOf('/') + 1);
    const file = new File([blob], `cropped_${fileName}.png`, { type: 'image/png' });
    formData.append('file', file);
    this.galleryService
      .uploadMediaFilePreview(mf.id, this.ownerId, formData)
      .pipe(
        finalize(() => {
          this.filesUploading = false;
          this.cdr.detectChanges();
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (res) => {
          attachment.mediaAttachment.preview = res.preview;
          attachment.mediaAttachment.previewUrl = null;
          attachment.mediaAttachment.croppedUrl = null;
          this.cdr.detectChanges();
          this.alertsService.riseSuccess('Превью успешно выбрано');
        },
        (error) => {
          this.alertsService.riseError('Произошла ошибка при выборе превью');
        }
      );
  }

  /**
   * Это видео-файл?
   *
   * @param MediaAttachmentViewModel attachment
   * @returns
   * @memberof MediaAttachmentsListComponent
   */
  isVideo(attachment: MediaAttachmentViewModel) {
    return (
      attachment &&
      attachment.mediaAttachment &&
      attachment.mediaAttachment.title &&
      attachment.mediaAttachment.title.match(/^.*\.(mp4|avi|wmv|webm|ogv)/gi)
    );
  }
}
