<app-group-small-header [group]="group" *ngIf="loaded && group"></app-group-small-header>

<!-- Empty post -->
<div class="card" *ngIf="loaded && (!post || notFound)">
  <div class="group-empty">
    <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" class="group-empty__img">
    <div class="group-empty__text">Пост не найден</div>
  </div>
</div>
<!-- Empty post END-->
<!-- post -->
<app-group-post [fullText]="true" *ngIf="loaded" [isPostView]="true" [post]="post" [startEdit]="startEdit" (onRemove)="remove(post)" [allowEdit]="canEdit(post)"
  [allowDelete]="canDelete(post)"></app-group-post>
<!-- post END -->
<!-- preloaders -->
<app-post-preloader [loaded]="loaded" [loading]="loading"></app-post-preloader>
<!-- preloaders END -->
