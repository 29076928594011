import { Component, OnInit, Input, Output, EventEmitter, Inject, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { User } from '@app/profile/model/user.model';
import { HelperService } from '@app/core/services/helper.service';
import { BaseComponent } from '@app/core/components/base.component';
import { TagService } from '@app/shared/services/tag.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { ITag, TagType } from '@app/shared/model/tag';
import { UsersService } from '@app/profile/services/users.service';
import { ProfileType } from '@app/core/model/profile-type.enum';
import set = Reflect.set;

export enum AddTagsComponentMode {
  undefined = 0,
  view = 1,
  edit = 2
}

@Component({
  selector: 'app-add-tags',
  templateUrl: './add-tags.component.html'
})
export class AddTagsComponent extends BaseComponent implements OnChanges {
  @Input() placeholder: string;
  @Input() tagType: TagType = TagType.unknown;
  @Input() elementId: number = undefined;
  @Input() ownerType: ProfileType = undefined;
  @Input() ownerId: number = undefined;
  @Input() mode: AddTagsComponentMode = AddTagsComponentMode.undefined;

  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  @Output() onOk: EventEmitter<User[]> = new EventEmitter();

  tags: ITag[] = [];
  tagsToAdd: ITag[] = [];

  timeoutId: any;
  searchText: string;

  AddTagsComponentMode = AddTagsComponentMode;
  currentUser: User;

  constructor(
    public userService: UsersService,
    public tagService: TagService,
    public cdr: ChangeDetectorRef,
    public helper: HelperService
  ) {
    super(helper);
    this.userService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['elementId']) {
      if (this.elementId) {
        this.tagService.getElementTags(this.elementId)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(res => {
            if (res) {
              this.tags = res;
              this.cdr.detectChanges();
            }
          });
      }
    }
  }

  removeTag(tag: ITag) {
    this.tags = this.tags.filter(s => s.id !== tag.id);
    this.cdr.detectChanges();
  }

  addTag(tag: ITag) {
    if (!this.tags.find(s => s.id === tag.id)) {
      this.tags.push(tag);
    }
    this.tagsToAdd = [];
    this.loaded = false;
    this.cdr.detectChanges();
    this.searchText = '';
  }

  createTag() {
    this.tagService.createTag(this.searchText.trim(), this.tagType)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        if (res) {
          this.addTag(res);
        }
        this.cdr.detectChanges();
        this.searchText = '';
      });
  }

  onFocus() {
    if (!this.searchText || this.searchText.length === 0) {
      this.searchTags('');
    }
  }

  onBlur() {
    setTimeout(() => {
      this.tagsToAdd = [];
    }, 500);
  }

  onSearchKey(e) {
    clearTimeout(this.timeoutId);

    if (e.keyCode === 27) {
      // esq
      this.searchText = '';
      return false;
    }

    if (this.searchText && this.searchText.length > 1) {
      this.searchTags(encodeURIComponent(this.searchText.trim()));
    } else {
      // this.loaded = true;
      this.tagsToAdd = [];
    }

    if (e.keyCode === 13) {
      return false;
    }
  }

  searchTags(searchText: string) {
    const that = this;
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(function () {
      that.tagsToAdd = [];
      that.loaded = false;
      that.tagService.searchTags(searchText, that.tagType, 7)
        .pipe(finalize(() => {
          that.loaded = true;
          that.cdr.detectChanges();
        }))
        .subscribe(res => {
          if (res) {
            res.forEach(tag => {
              if (!that.tagsToAdd.find(s => s.id === tag.id)) {
                that.tagsToAdd.push(tag);
              }
            });
          }

          if (that.tagsToAdd.length === 0) {
            that.error = 'Совпадения не найдены';
          } else {
            that.error = null;
          }
        }, error => {
          console.log(error);
          that.error = 'Произошла ошибка при поиске данных';
        });
    }, 500);
  }

  getFragment(tag: ITag) {
    if (tag.fragment) {
      return tag.fragment;
    }

    switch (tag.type) {
      case TagType.post:
        tag.fragment = `feed&k=#${tag.name}`;
        return;
      case TagType.page:
        tag.fragment = `pages&k=#${tag.name}`;
        return;
      default:
        return ``;
    }
  }

  getRoute(tag: ITag) {
    if (tag.routeLink) {
      return tag.routeLink;
    }

    let url = ``;

    if (!this.ownerId) {
      url = `/profile/${this.currentUser.id}/search`;
      return;
    }

    switch (this.ownerType) {
      case ProfileType.Group:
        url = `/group/${this.ownerId}/search`;
        break;
      case ProfileType.User:
        url = `/profile/${this.ownerId}/search`;
        break;
    }

    return url;
  }
}
