import { switchMap, map, take, pluck, retry, shareReplay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { BaseService } from '@app/core/services/base.service';
import { User, IUserProfileProperty, IUserPhotoChanged } from '@app/profile/model/user.model';
import { UserLinksViewModel } from '@app/subscribe/model/user-links-view-model';
import { Helper } from '@app/core/helpers/helper';
import { HelperService } from '@app/core/services/helper.service';

/**
 * API сервис пользователей
 */
@Injectable()
export class UsersAPIService extends BaseService {
    /**
     * Текущий пользователь
     */
    public currentUser: BehaviorSubject<User> = new BehaviorSubject<User>(null);

    /**
     * Пользователь в профиле которого мы находимся
     */
    public user: BehaviorSubject<User> = new BehaviorSubject<User>(null);

    protected serviceUrl = `${environment.siteUrl}/api/user`;

    constructor(
        protected http: HttpClient,
        public helper: HelperService) { super(http, helper); }

    /**
     * Получить текущего пользователя
     */
    getCurrent(): Observable<User> {
        return this.get<User>(`current`).pipe(
            shareReplay(1),
            map(user => {
                this.currentUser.next(user);
                return user;
            }),
            retry(1)
        );
    }

    /**
     * Получить пользователя по ИД
     *
     * @param number id ИД пользователя
     */
    getUser(id: number): Observable<User> {
        return this.get(`${id}`);
    }

    /**
     * Получить пользователя по ИД из SharePoint
     *
     * @param number id ИД в списке UserInfo SharePoint
     */
    getUserBySharePoint(webUrl: string, id: number): Observable<User> {
        const url = webUrl ? `${(<any>window).signalRServer}/${webUrl}/_vti_bin/Intry/UsersService.svc` : this.serviceUrl;
        return this.get(`${url}/GetUserBySharePoint?id=${id}`, null, null, 'json', true);
    }

    /**
     * Получить пользователя по логину
     *
     * @param string login логин пользователя, которого нужно найти
     */
    getUserByLogin(login: string): Observable<User> {
        return this.get(`byAccount?accountName=${login}`);
    }

    getUserProperties(accountName: string): Observable<IUserProfileProperty[]> {
        return this.get(`userProfileProperties?accountName=${accountName}`);
    }

    setUserProfilePropertyPrivacy(propertyName: string, value: number): Observable<boolean> {
        return this.put(`userProfilePropertyPrivacy`, { propertyName, value });
    }

    getSubordinates(account: string, offset: number, limit: number): Observable<UserLinksViewModel> {
        return this.get(`subordinates?account=${account}&offset=${offset}&limit=${limit}`);
    }

    searchUsers(searchText: string, profileId: number, offset: number, limit: number): Observable<UserLinksViewModel> {
        return this.get(`search?searchText=${encodeURIComponent(searchText)}&profileId=${profileId}&offset=${offset}&limit=${limit}`);
    }

    getDepartments(searchText: string, offset: number, count: number): Observable<string[]> {
        return this.get(`GetDepartments?searchText=${encodeURIComponent(searchText)}&offset=${offset}&count=${count}`);
    }

    updateUserProperty(propertyName: string, value: Object, userId: number): Observable<boolean> {
        return this.put(`userProfileProperty`, { propertyName, value, userId });
    }

    uploadProfilePhoto(formData, accountName): Observable<any> {
        return super.post(`${environment.siteUrl}/api/userPhoto/uploadPhoto?accountName=${accountName}`, formData);
    }

    removeProfilePhoto(): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('remove', 'true');
        return super.post(`uploadPhoto`, formData);
    }

    /**
     * Получить доменное имя для использования в Skype for Business
     */
    getDomainName(): Observable<string> {
        return this.get(`GetDomain`);
    }

    isInternal(): Observable<boolean> {
        return this.get(`IsInternal`);
    }
}
