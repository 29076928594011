<div class="cos">
  <app-group-small-header></app-group-small-header>

  <!-- header preloader -->
  <app-tab-preloader *ngIf="!loaded"></app-tab-preloader>
  <!--  header preloader END -->

  <div class="cos__body" *ngIf="showForbiddenBlock()">
    <app-group-forbidden></app-group-forbidden>
  </div>

  <router-outlet *ngIf="canReadGroup()"></router-outlet>

  <!-- data preloader -->
  <app-tabbed-list-preloader *ngIf="!loaded"></app-tabbed-list-preloader>
  <!--  data preloader END -->
</div>