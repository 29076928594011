<!-- доабвь класс _active для отображения на мобилке -->
<label class="search" [ngClass]="{'_active':isActive}">
	<app-goback *ngIf="showGoBack" [target]="'search'" [cssClass]="'back'" [text]="'&nbsp;'" [prefix]="'/search'" [showAlways]="true"
	    (onGoBack)="goBack($event)"></app-goback>
	<input #searchText type="search" placeholder="Поиск сотрудников, файлов и групп" class="search__input" (keydown)="onSearchKey($event)"
	    (keyup)="onSearchKey($event)" [(ngModel)]="query" (ngModelChange)="query = $event" (blur)="onBlur($event)" (focus)="onFocus($event)">

	<!-- кнопку скрывать/показывать в зависимости от заполненности инпута -->
	<button #clearButton *ngIf="showClearButton()" class="btn search__clear icon _clear" type="button" (click)="clear($event)"></button>
	<button *ngIf="selectorVisible" type="button" class="btn btn-dots-vert-white search__settings tooltip _bottom" [ngClass]="{'_active':selectorActive}"
	    (click)="showSelector($event)">
		<div *ngIf="selectorActive" class="tooltip__body _active" appClickOutside (clickOutside)="selectorActive=false">
			<label class="checkbox" (click)="selectChoice('all')">
				<input type="radio" name="search-dir" class="checkbox__input" value="all" [(ngModel)]="selector">
				<span class="checkbox__icon"></span>
				<span class="checkbox__text">Искать по всему порталу</span>
			</label>
			<label class="checkbox" (click)="selectChoice('group')">
				<input type="radio" name="search-dir" class="checkbox__input" value="group" [(ngModel)]="selector">
				<span class="checkbox__icon"></span>
				<span class="checkbox__text">Искать в группе</span>
			</label>
		</div>
	</button>
	<div #mobileSelector *ngIf="selectorVisible && isActive" class="search__settings _mobile">
		<label class="checkbox" (click)="selectChoice('all')">
			<input type="radio" name="search-mobile" class="checkbox__input" value="all" [(ngModel)]="selector">
			<span class="checkbox__icon"></span>
			<span class="checkbox__text">Искать по всему порталу</span>
		</label>
		<label class="checkbox" (click)="selectChoice('group')">
			<input type="radio" name="search-mobile" class="checkbox__input" value="group" [(ngModel)]="selector">
			<span class="checkbox__icon"></span>
			<span class="checkbox__text">Искать в группе</span>
		</label>
	</div>

	<span *ngIf="showOverlay()" class="search__overlay" (click)="overlayClick($event)"></span>

	<button type="button" class="search__icon btn icon _header-search" (click)="toggle($event)"></button>
</label>
