import { Component, OnInit, Input, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IModalConfirmationData {
  okText?: string;
  onOk?: any;
  cancelText?: string;
  onCancel?: any;
  text?: string;
  error?: string;
  additionalText?: string;
  doNotHideOnOk?: boolean;
}

@Component({
  selector: 'app-modal-confirmation',
  templateUrl: './modal-confirmation.component.html'
})
export class ModalConfirmationComponent implements OnInit {
  @Input() text = 'Вы действительно хотите удалить?';
  @Input() additionalText = '';
  @Input() okText = 'Да, удалить';
  @Input() cancelText = 'Нет';

  doNotHideOnOk: boolean;

  constructor(
    public dialogRef: MatDialogRef<ModalConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IModalConfirmationData
  ) { }

  ngOnInit() {
    if (this.data) {
      if (this.data.okText) {
        this.okText = this.data.okText;
      }
      if (this.data.cancelText) {
        this.cancelText = this.data.cancelText;
      }
      if (this.data.text) {
        this.text = this.data.text;
      }
      if (this.data.additionalText) {
        this.additionalText = this.data.additionalText;
      }
      if (this.data.doNotHideOnOk) {
        this.doNotHideOnOk = this.data.doNotHideOnOk;
      }
    }
  }

  ok() {
    if (this.data.onOk) {
      if (this.data.onOk instanceof EventEmitter) {
        this.data.onOk.emit();
      } else {
        this.data.onOk();
      }
    }
  }

  cancel() {
    if (this.data.onCancel) {
      if (this.data.onCancel instanceof EventEmitter) {
        this.data.onCancel.emit();
      } else {
        this.data.onCancel();
      }
    }
  }
}
