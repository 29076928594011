<div class="post-profile dropdown _full-width">
  <div (click)="chooseProfile()">
    <div class="text-secondary" *ngIf="!selectedProfile">{{placeholder || "Выберите группу"}}{{required?'*':''}}</div>
    <ng-container *ngIf="selectedProfile">
      <ng-container *ngTemplateOutlet="profileItemView; context: {$implicit: selectedProfile}"></ng-container>
    </ng-container>
  </div>
  <div class="post-profile__overlay" *ngIf="show" (click)="show=false"></div>
  <div class="post-profile__wrapper" [ngClass]="{'_active': show}" appInfiniteScroll scrollPercent="70"
    immediateCallback="true" (scrollCallback)="scrollCallback()">
    <div *ngFor="let p of profiles; trackBy:trackBy" (click)="onProfileSelected(p)">
      <ng-container *ngTemplateOutlet="profileItemView; context: {$implicit: p}"></ng-container>
    </div>
  </div>
</div>

<ng-template #profileItemView let-p>
  <div class="post-profile__item" *ngIf="p.profile && p.profileType==profileType.Group">
    <app-user-avatar [avatarImage]="groupsService.getGroupSmallPicture(p.profile)" [linkClass]="'avatar _30'"
      [imageClass]="'avatar__image'" routerLink="/group/{{p.profile.id}}" [name]="p.profile.title" [noStatus]="true" [inNewTab]="true"></app-user-avatar>
    <div class="post-profile__body">
      <span class="post-profile__text">{{p.profile.title}}</span>
      <span class="post-profile__subtext">{{groupsService.getGroupTypeNameByEnum(p.profile.type)}} группа</span>
    </div>
  </div>
  <div class="post-profile__item" *ngIf="!p.profile">Ошибка отображения объекта</div>
</ng-template>
