import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService } from '@app/profile/services/users.service';
import { HelperService } from '@app/core/services/helper.service';
import { ICalendarDate, CalendarHelper, CalendarViewType, CalendarFilterType } from '@app/calendar/model/calendar';
import { CalendarService } from '@app/calendar/services/calendar.service';
import { Tabs, Tab } from '@app/shared/components/tabs/tab.model';
import { take, finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-calendar-base',
  template: ''
})
export class CalendarBaseComponent extends BaseComponent implements OnDestroy {

  /**
   * Все вкладки
   */
  tabs: Tabs<any>;

  /**
   * Текущая вкладка
   */
  currentTab: Tab<any>;

  viewType: CalendarViewType = CalendarViewType.month;

  selectedDate: ICalendarDate;
  calendarDays: ICalendarDate[] = [];

  CalendarHelper = CalendarHelper;

  filtersVisible = true;
  filterType: CalendarFilterType = CalendarFilterType.colleagues;
  filterDepartment: string;

  protected daysInSelectedMonth: number;

  private today = new Date();
  private minDaysInCalendar = 35;
  private maxDaysInCalendar = 42;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    public usersService: UsersService,
    public calendarService: CalendarService,
    public helper: HelperService
  ) { super(helper); }

  isSelectedMonth(date: ICalendarDate): boolean {
    return this.selectedDate && this.selectedDate.month === date.month;
  }

  isCurrentDay(date: ICalendarDate): boolean {
    return this.today.getMonth() === date.month && this.today.getDate() === date.day;
  }

  isHoliday(date: ICalendarDate): boolean {
    return date.dayOfWeek === 0 || date.dayOfWeek === 6;
  }

  onInit(selectedDate: ICalendarDate) {
    this.selectedDate = selectedDate;
    this.getMonthDays();
    this.GetBirthdays();
  }

  onPrev(selectedDate: ICalendarDate) {
    this.selectedDate = selectedDate;
    this.getMonthDays();
    this.GetBirthdays();
  }

  onNext(selectedDate: ICalendarDate) {
    this.selectedDate = selectedDate;
    this.getMonthDays();
    this.GetBirthdays();
  }

  private getMonthDays() {
    if (this.selectedDate) {
      // очищаем текущий календарь
      this.calendarDays = [];

      // получаем количество дней в выбранном месяце и первый день недели этого месяца
      this.daysInSelectedMonth = this.daysInMonth(this.selectedDate.month, this.selectedDate.year);
      const firstDayOfWeek = this.firstDayOfWeek(this.selectedDate.month, this.selectedDate.year);

      // определяем количестве предыдущих дней
      let daysToAdd = 0;

      if (firstDayOfWeek === 0) {
        // первый день - воскресенье, нужно прорисовать 6 предыдущих дней
        daysToAdd = 6;
      } else {
        daysToAdd = firstDayOfWeek - 1;
      }

      // добавляем дни предыдущего месяца
      let dayToAdd = 0;

      if (daysToAdd !== 0) {
        // учитываем смену года
        let previousMonth = this.selectedDate.month - 1;
        let year = this.selectedDate.year;

        if (this.selectedDate.month === 0) {
          previousMonth = 11;
          year = this.selectedDate.year - 1;
        }

        // получить количество дней в предыдущем месяце
        const daysInPreviousMonth = this.daysInMonth(previousMonth, year);
        dayToAdd = daysInPreviousMonth;
        while (dayToAdd > daysInPreviousMonth - daysToAdd) {
          this.calendarDays.unshift({
            day: dayToAdd,
            month: previousMonth,
            year: year,
            dayOfWeek: new Date(year, previousMonth, dayToAdd).getDay()
          });
          dayToAdd--;
        }
      }

      // добавляем дни текущего месяца
      dayToAdd = 1;

      while (dayToAdd < this.daysInSelectedMonth + 1) {
        this.calendarDays.push({
          day: dayToAdd,
          month: this.selectedDate.month,
          year: this.selectedDate.year,
          dayOfWeek: new Date(this.selectedDate.year, this.selectedDate.month, dayToAdd).getDay()
        });
        dayToAdd++;
      }

      // добавить количество дней после
      const maxDaysInCalendar = this.calendarDays.length <= this.minDaysInCalendar ? this.minDaysInCalendar : this.maxDaysInCalendar;
      let daysInCalendar = this.calendarDays.length;

      if (daysInCalendar < maxDaysInCalendar) {
        dayToAdd = 1;
        while (daysInCalendar < maxDaysInCalendar) {

          const date: ICalendarDate = {
            day: dayToAdd,
            month: this.selectedDate.month + 1,
            year: this.selectedDate.year
          };

          if (this.selectedDate.month === 11) {
            date.month = 0;
            date.year++;
          }

          date.dayOfWeek = new Date(date.year, date.month, dayToAdd).getDay();

          this.calendarDays.push(date);
          dayToAdd++;
          daysInCalendar++;
        }
      }
    }
  }

  protected GetBirthdays() {
    let dateStart: Date;
    let dateEnd: Date;

    switch (this.viewType) {
      case CalendarViewType.month:
        dateStart = new Date(this.selectedDate.year, this.selectedDate.month, this.selectedDate.day ? this.selectedDate.day : 1);
        dateEnd = new Date(this.selectedDate.year, this.selectedDate.month, this.daysInSelectedMonth);
        break;
      case CalendarViewType.day:
        dateStart = dateEnd = new Date(this.selectedDate.year, this.selectedDate.month, this.selectedDate.day);
        break;
    }

    this.calendarService.GetBirthdays(
      dateStart.toDateString(),
      dateEnd.toDateString(),
      this.filterType,
      this.filterDepartment)
      .pipe(
        take(1),
        finalize(() => {
          this.currentTab.loaded = true;
          this.currentTab.loading = false;
        }),
        takeUntil(this.destroyed$))
      .subscribe(res => {
        if (res) {
          this.currentTab.items = res;
          this.currentTab.itemsCount = res.length;

          if (this.viewType === CalendarViewType.month) {
            // для отображения по месяцам доп логика

            this.calendarDays.forEach(calendarDay => {
              calendarDay.birthdays = [];
            });

            res.forEach(item => {
              try {
                const birthday = new Date(item.birthday);
                const birthdayMonth = birthday.getMonth();
                const birthdayDay = birthday.getDate();
                const calendarDay = this.calendarDays.find(s => s.day === birthdayDay && s.month === birthdayMonth);
                if (calendarDay) {
                  if (!calendarDay.birthdays) {
                    calendarDay.birthdays = [];
                  }
                  if (!calendarDay.birthdays.find(s => s.id === item.id)) {
                    calendarDay.birthdays.push(item);
                  }
                }
              } catch (e) {
                console.log(`error on add birthday=`, e);
              }
            });
          }
        } else {
          this.currentTab.items = [];
          this.currentTab.itemsCount = 0;
        }
      });
  }

  protected daysInMonth(month, year) {
    return new Date(year, month + 1, 0).getDate();
  }

  private firstDayOfWeek(month, year) {
    return new Date(year, month, 1).getDay();
  }
}
