import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Subject, Observable } from 'rxjs';
import { TabbedListComponent } from '@app/shared/components/tabs/tabbed-list.component';
import { IThank } from '@app/thanks/model/thank';
import { User } from '@app/profile/model/user.model';
import { takeUntil, take } from 'rxjs/operators';
import { Tab } from '@app/shared/components/tabs/tab.model';
import { NewThankComponent } from '../new-thank/new-thank.component';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService } from '@app/profile/services/users.service';
import { ThanksService } from '@app/thanks/services/thanks.service';
import { AlertsService } from '@app/shared/services/alerts.service';
import { HelperService } from '@app/core/services/helper.service';

@Component({
  selector: 'app-thanks-list',
  templateUrl: './thanks-list.component.html',
  styleUrls: ['./thanks-list.component.scss']
})
export class ThanksListComponent extends TabbedListComponent<IThank> implements OnInit, OnDestroy {

  user: User;
  currentUser: User;

  isCurrent = false;

  limit = 10;

  protected maxItemHeight = 110;

  protected newThankDialog: MatDialogRef<NewThankComponent>;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    public dialog: MatDialog,
    public usersService: UsersService,
    protected thanksService: ThanksService,
    protected alertsService: AlertsService,
    public helper: HelperService) {
    super(router, route, helper);
  }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.usersService.user.pipe(takeUntil(this.destroyed$)).subscribe(user => {
        // set user
        this.user = user;
        // check user
        if (user) {

          this.currentUser = currentUser;
          this.isCurrent = this.user.id === currentUser.id;

          this.tabs = {
            title: 'Спасибо',
            routerLink: `/profile/${user.id}/thanks`,
            items: [
              {
                id: 1,
                title: 'От сотрудников',
                name: 'from',
                fragment: '',
                items: [],
                offset: 0,
                itemsCount: 0,
                method: this.getUserReceivedThanks.bind(this),
                default: true,
                emptyText: 'Пока нет «Спасибо» от сотрудников. \n Поблагодарите сами своих коллег.'
              },
              {
                id: 2, title: 'Отправлено',
                name: 'sent',
                fragment: 'sent',
                items: [],
                offset: 0,
                itemsCount: 0,
                method: this.getUserSentThanks.bind(this),
                emptyText: 'Пока нет отправленных «Спасибо»'
              }
            ]
          };
          this.route.fragment.pipe(takeUntil(this.destroyed$)).subscribe(val => {

            this.mobHeight = window.innerHeight;
            this.setNewLimit();

            // get hash
            if (val != null) {
              const keys = val.split('&');
              const hash = {};
              keys.forEach(key => {
                // tslint:disable-next-line:no-shadowed-variable
                const val = key.split('=');
                hash[val[0]] = val[1];
              });

              this.currentTab = null;

              this.tabs.items.forEach(tab => {
                if (tab.fragment && keys.find(k => k === tab.fragment)) {
                  this.currentTab = tab;
                }
              });

              if (!this.currentTab) {
                this.currentTab = this.tabs.items[0];
              }
            } else {
              this.currentTab = this.tabs.items[0];
            }

            // this.currentTab.method(this.currentTab, this);

            // получить данные по всем табикам
            this.tabs.items.forEach(tab => {
              if (!tab.loaded) {
                tab.method(tab, this);
              } else if (this.currentTab.id === tab.id) {
                tab.offset = 0;
                tab.method(tab, this);
              }
            });
          });
        }
      });
    });
  }

  getUserReceivedThanks(tab: Tab<IThank>) {
    // получение данных
    this.operateThanks(this.thanksService.getUserReceivedThanks(this.user.id, tab.offset, this.limit), tab);
    // получение счётчика
    this.thanksService.getUserReceivedThanksCount(this.user.id).subscribe(res => {
      tab.itemsCount = res ? res : 0;
    });
  }

  getUserSentThanks(tab: Tab<IThank>) {
    // получение данных
    this.operateThanks(this.thanksService.getUserSentThanks(this.user.id, tab.offset, this.limit), tab);
    // получение счётчика
    this.thanksService.getUserSentThanksCount(this.user.id).subscribe(res => {
      tab.itemsCount = res ? res : 0;
    });
  }

  private operateThanks(method: Observable<IThank[]>, tab: Tab<IThank>) {
    tab.loading = true;
    method.pipe(take(1)).subscribe(res => {
      // assign my subscriptions
      if (!tab.items || tab.offset === 0) {
        tab.items = [];
      }

      if (res) {
        res.forEach(link => {
          if (!tab.items.find(g => g.user.id === link.user.id && g.body === link.body && g.created === link.created)) {
            tab.items.push(link);
          }
        });
        tab.offset = tab.items.length;
      }

      this.loaded = tab.loaded = true;
      tab.loading = false;
    }, error => {
      this.loaded = tab.loaded = true;
      tab.loading = false;
    });
  }

  /**
   * Поблагодарить. Если в метод передаётся user, то он подставляется в окошко
   *
   * @param {User} [user=null]
   * @memberof ThanksListComponent
   */
  thank(user: User = null) {
    this.newThankDialog = this.dialog.open(NewThankComponent, {
      data: {
        usersToAdd: user ? [user] : (this.isCurrent
          ? []
          : [this.user]),
        onOk: () => {
          this.currentTab.method(this.currentTab, this);
          this.newThankDialog.close();
        }
      }
    });
  }

  showFullText(thank: IThank) {
    this.router.navigate([thank.id], { relativeTo: this.route });
  }

  getHeaderText(tab: Tab<IThank>) {
    if (tab.name === 'from') {
      return this.isCurrent
        ? 'Сотрудники сказали вам «Спасибо»:'
        : 'Сотрудники сказали «Спасибо» коллеге:';
    } else if (tab.name === 'sent') {
      return this.isCurrent
        ? 'Вы сказали «Спасибо»:'
        : 'Коллега сказал «Спасибо»:';
    }

    return '';
  }
}
