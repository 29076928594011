import { Directive, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Directive({
  selector: '[appIslast]'
})
export class IslastDirective implements OnInit {
  @Input() isLast: boolean;
  @Output() lastDone: EventEmitter<boolean> = new EventEmitter<boolean>();
  ngOnInit() {
    if (this.isLast) {
      this.lastDone.emit(true);
    }
  }
}
