import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlbumNewComponent } from '@app/gallery/components/forms/album-new/album-new.component';
import { GalleryService } from '@app/gallery/services/gallery.service';
import { Router } from '@angular/router';
import { GroupsService } from '@app/groups/services/groups.service';
import { Subject } from 'rxjs';
import { GroupInfo } from '@app/groups/model/group-info.model';
import { AlertsService } from '@app/shared/services/alerts.service';
import { UsersService } from '@app/profile/services/users.service';
import { take, takeUntil } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';

@Component({
  selector: 'app-group-album-new',
  templateUrl: './group-album-new.component.html',
  styleUrls: ['./group-album-new.component.scss']
})
export class GroupAlbumNewComponent extends AlbumNewComponent implements OnInit, OnDestroy {
  group: GroupInfo;

  constructor(
    protected galleryService: GalleryService,
    protected groupsService: GroupsService,
    protected userService: UsersService,
    protected router: Router,
    protected alertsService: AlertsService,
    protected breadcrumbsService: BreadcrumbsService,
    public helper: HelperService
  ) { super(galleryService, userService, router, alertsService, breadcrumbsService, helper); }

  ngOnInit() {
    this.groupsService.currentGroup
      .pipe(takeUntil(this.destroyed$))
      .subscribe(group => {
        if (group) {
          this.group = group;
          const galleryRoute = `/group/${group.id}/gallery`;
          this.cancelLink = galleryRoute;
          this.breadcrumbsService.breadcrumbs.next([
            { title: 'Галерея', routerUrl: galleryRoute },
            { title: 'Новый альбом', routerUrl: galleryRoute + '/new' }
          ]);
        }
      });
  }

  /**
   * Создание альбома группы
   *
   * @protected
   * @memberof AlbumNewComponent
   */
  create() {
    if (this.isValid) {
      // set creating flag
      this.creating = true;
      // send post request
      this.galleryService.addAlbum({
        title: this.albumName,
        description: this.albumDescription,
        ownerId: this.group.id
      })
        .pipe(take(1))
        .subscribe(newAlbumRes => {
          if (newAlbumRes) {
            this.alertsService.riseSuccess(`Альбом с названием '${this.albumName}' успешно создан.`);
            this.router.navigate(['group', this.group.id, 'gallery', newAlbumRes.id]);
          } else {
            this.alertsService.riseError(`Альбом не был создан.`);
          }
        }, error => {
          this.alertsService.riseError(`Альбом не был создан. Попробуйте позже.`);
          console.error(error);
        });
    } else {
      console.error(`show not valid error`);
    }
  }
}
