import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { MediaAttachmentsMenuComponent } from './components/media-attachments-menu/media-attachments-menu.component';
import { MediaAttachmentsListComponent } from './components/media-attachments-list/media-attachments-list.component';
import { MediaAttachmentMenuComponent } from './components/media-attachment-menu/media-attachment-menu.component';
import { MediaAttachmentViewComponent } from './components/media-attachment-view/media-attachment-view.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    RouterModule
  ],
  declarations: [
    MediaAttachmentsMenuComponent,
    MediaAttachmentsListComponent,
    MediaAttachmentMenuComponent,
    MediaAttachmentViewComponent,
  ],
  exports: [
    MediaAttachmentsMenuComponent,
    MediaAttachmentsListComponent,
    MediaAttachmentMenuComponent,
    MediaAttachmentViewComponent
  ],
})
export class MediaAttachmentsModule { }
