import { Component, OnDestroy, OnInit } from '@angular/core';
import { PagesService } from '@app/pages/services/pages.service';
import { AlertsService } from '@app/shared/services/alerts.service';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil, switchMap } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { PageEditComponent } from './page-edit.component';
import { GroupsService } from '@app/groups/services/groups.service';
import { UsersService } from '@app/profile/services/users.service';
import { GroupInfo } from '@app/groups/model/group-info.model';

/**
 * Форма редактирования страницы группы
 *
 * @export
 * @class GroupPageEditComponent
 * @extends {PageEditComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-group-page-edit',
  templateUrl: './page-edit.component.html',
  styleUrls: ['./page-edit.component.scss']
})
export class GroupPageEditComponent extends PageEditComponent implements OnInit, OnDestroy {

  group: GroupInfo;

  constructor(
    public groupsService: GroupsService,
    public pagesService: PagesService,
    protected alertsService: AlertsService,
    protected breadcrumbsService: BreadcrumbsService,
    protected usersService: UsersService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected dialog: MatDialog,
    public helper: HelperService
  ) {
    super(pagesService, alertsService, breadcrumbsService, usersService, route, router, dialog, helper);
  }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;

      this.groupsService.currentGroup.subscribe(group => {
        this.group = group;

        if (group != null) {

          if (!currentUser.isAdmin && !group.isAdmin) {
            return this.router.navigate(['/403'], { skipLocationChange: true });
          }

          this.route.params
            .pipe(takeUntil(this.destroyed$), switchMap((params: Params) => {
              this.page = null;
              const pageId = +params['pageId'];
              return this.pagesService.getById(pageId);
            }))
            .subscribe(page => {
              this.page = page;

              if (this.page) {
                this.setBreadcrumbs();
              } else {
                this.router.navigate(['/404'], { skipLocationChange: true });
              }

              this.error = '';
              this.loaded = true;
            }, error => {
              this.error = error;
              this.loaded = true;
            });
        }
      });
    });
  }

  /**
   * Переопределяем метод добавления хлебных крошек на страницу
   */
  protected setBreadcrumbs() {
    this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
      if (group) {
        // обновляем хлебные крошки
        const pageRoute = `/group/${group.id}/pages`;

        this.breadcrumbsService.breadcrumbs.next([
          { title: 'Страницы', routerUrl: pageRoute },
          { title: this.page.title, routerUrl: pageRoute + `/${this.page.id}` }
        ]);
      }
    });
  }
}
