import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IAward } from '@app/awards/model/awards';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import { AwardsService } from '@app/awards/services/awards.service';
import { take } from 'rxjs/operators';
import { AlertsService } from '@app/shared/services/alerts.service';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';

/**
 * Меню награды
 *
 * @export
 * @class AwardMenuComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-award-menu',
  templateUrl: './award-menu.component.html',
  styleUrls: ['./award-menu.component.scss']
})
export class AwardMenuComponent extends BaseComponent implements OnInit {

  @Input() item: IAward;
  @Input() selectedItems: IAward[];
  @Input() isAdmin: boolean;

  @Output() deleted: EventEmitter<number> = new EventEmitter();

  deletingDialog: MatDialogRef<ModalConfirmationComponent>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private awardsService: AwardsService,
    private alertsService: AlertsService,
    protected dialog: MatDialog,
    public helper: HelperService,
  ) { super(helper); }

  ngOnInit() {
  }

  showEditItem() {
    this.router.navigate([this.item.id, 'edit'], { relativeTo: this.route });
  }

  showDeleteItem() {
    this.deletingDialog = this.dialog.open(ModalConfirmationComponent, {
      data: {
        text: `Вы действительно хотите удалить награду`,
        onOk: this.deleteItem.bind(this),
        doNotHideOnOk: true
      }
    });
  }

  private deleteItem() {
    const errorText = 'Произошла ошибка при удалении награды.';

    this.awardsService.remove(this.item.id).pipe(take(1)).subscribe(res => {
      if (res) {
        this.alertsService.riseSuccess(`Удаление награды выполнено успешно`);
        this.deleted.emit(this.item.id);
        this.deletingDialog.close();
      } else {
        this.alertsService.riseError(errorText);
      }
    }, error => {
      console.log(`${errorText}: '${error}'`);
      this.alertsService.riseError(errorText);
      this.deletingDialog.componentInstance.data.error = errorText;
    });
  }
}
