<!-- Header -->
<app-group-small-header [group]="group" *ngIf="loaded && group"></app-group-small-header>
<!-- Header END -->
<!-- Empty post -->
<div class="card" *ngIf="loaded && (!post || notFound)">
    <div class="group-empty">
        <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" class="group-empty__img">
        <div class="group-empty__text">Пост не найден</div>
    </div>
</div>
<!-- Empty post END-->
<!-- post -->
<app-post-share [profileType]="0" [user]="user" *ngIf="loaded" [placeholder]="'Ваш комментарий к публикации'"
(onAdd)="redirectToTarget($event)" [selectorType]="selectorType" [post]="post"></app-post-share>
<!-- post END -->
<!-- preloaders -->
<app-post-preloader [loaded]="loaded" [loading]="loading"></app-post-preloader>
<!-- preloaders END -->
  