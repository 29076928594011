import {Component, OnInit, Renderer2} from '@angular/core';
import {PagesService} from '@app/pages/services/pages.service';
import {BreadcrumbsService} from '@app/shared/services/breadcrumbs.service';
import {Router, ActivatedRoute} from '@angular/router';
import {HelperService} from '@app/core/services/helper.service';
import {PageViewComponent} from './page-view.component';
import {GroupsService} from '@app/groups/services/groups.service';
import {takeUntil} from 'rxjs/operators';
import {GroupInfo} from '@app/groups/model/group-info.model';
import {IPage} from '@app/pages/model/page';
import {IPageMenuConfig} from '../../menu/page-menu/page-menu.component';
import {UsersService} from '@app/profile/services/users.service';
import {User} from '@app/profile/model/user.model';

/**
 * Форма просмотра страницы в группе
 *
 * @export
 * @class GroupPageViewComponent
 * @extends {PageViewComponent}
 */
@Component({
  selector: 'app-group-page-view',
  templateUrl: './page-view.component.html',
  styleUrls: ['./page-view.component.scss'],
})
export class GroupPageViewComponent extends PageViewComponent {
  group: GroupInfo;

  constructor(
    public groupsService: GroupsService,
    protected usersService: UsersService,
    protected pagesService: PagesService,
    protected breadcrumbsService: BreadcrumbsService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected renderer: Renderer2,
    public helper: HelperService,
  ) {
    super(usersService, pagesService, breadcrumbsService, router, route, renderer, helper);
  }

  protected checkPageConfig(page: IPage, currentUser: User): void {
    this.config = this.groupsService.isAdmin(this.group, currentUser)
      ? {
          allowEdit: true,
          allowDelete: true,
        }
      : null;
  }

  /**
   * Переопределяем метод добавления хлебных крошек на страницу
   *
   * @protected
   * @memberof GroupPageViewComponent
   */
  protected setBreadcrumbs() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
        if (group) {
          this.group = group;

          // обновляем хлебные крошки
          const pageRoute = `/group/${group.id}/pages`;

          this.breadcrumbsService.breadcrumbs.next([
            {title: 'Страницы', routerUrl: pageRoute},
            {title: this.page.title, routerUrl: pageRoute + `/${this.page.id}`},
          ]);

          this.checkPageConfig(this.page, currentUser);
        }
      });
    });
  }
}
