/**
 * GlobalValidator
 */
export class GlobalValidator {
    static mailIsValid(value): boolean {
        // tslint:disable-next-line:max-line-length
        const REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (value !== '' && (value.length <= 5 || !REGEXP.test(value))) {
            return false;
        }
        return true;
    }
    static phoneIsValid(value): boolean {
        const REGEXP = /^\+?\d+$/;
        if (value !== '' && !REGEXP.test(value)) {
            return false;
        }
        return true;
    }
    static urlIsValid(value): boolean {
        const REGEXP = /^(ftp|http|https):\/\/[^ "]+$/;
        if (value !== '' && !REGEXP.test(value)) {
            return false;
        }
        return true;
    }
}
