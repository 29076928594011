import { Component, OnInit } from '@angular/core';
import { User } from '@app/profile/model/user.model';
import { UsersService } from '@app/profile/services/users.service';
import { Router } from '@angular/router';
import { SearchService } from '@app/search/services/search.service';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { MenuOrientation } from '../menu-pages-list/menu-pages-list.component';
import { SettingsService } from '@app/shared/services/settings.service';

/**
 * Шапка портала
 *
 * @export
 * @class HeaderComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
    selector: 'app-header',
    templateUrl: 'header.component.html'
})

export class HeaderComponent extends BaseComponent implements OnInit {
    user: User;
    megaMenu: boolean = null;

    MenuOrientation = MenuOrientation;

    constructor(
        public settingsService: SettingsService,
        public searchService: SearchService,
        public usersService: UsersService,
        protected router: Router,
        public helper: HelperService) { super(helper); }

    ngOnInit() {
        this.settingsService.getValue('megaMenu').subscribe(res => {
            this.megaMenu = res === 'True';
        });
        this.usersService.currentUser.subscribe(currentUser => {
            // set user to current
            this.user = currentUser;
        });
    }
}
