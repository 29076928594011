<div class="card _vacancies" *ngIf="loaded && showWidget">
  <div class="card__title">
    <a *ngIf="showAddButton" class="btn btn-plus pull-right" [routerLink]="addButtonLink">+</a>
    <div class="header-text">
      <a class="header-text__text" (click)="navigate()" [routerLink]="link">Вакансии</a>&nbsp;<span
        class="header-text__count"></span>
    </div>
  </div>
  <div class="list-item" *ngFor="let vacancy of vacancies; trackBy:vacancy?.id">
    <a class="list-item__link" routerLink="/group/{{vacancy?.ownerId}}/vacancy/{{vacancy?.id}}">
      <img src="{{assetsPrefix}}/assets/images/vacancy/vacancy_simple.svg" class="list-item__status">
      <span class="list-item__desc">
        <span class="list-item__title">{{vacancy.title}}</span>
        <span class="list-item__subtitle" *ngIf="vacancy.status==VacancyStatus.published && vacancy.publishDate">
          {{vacancy?.location ? vacancy.location + ', ' : ''}}
          от&nbsp;{{vacancy.publishDate | date:'dd.MM.yyyy'}}</span>
      </span>
    </a>
    <app-vacancy-menu [whiteDots]="true" [item]="vacancy" [config]="getVacancyConfig(vacancy)" [vacancyLocation]="3"
      (deleted)="onDeleted($event)"></app-vacancy-menu>
  </div>
  <div class="card__content" *ngIf="!showWidget && group">
    <app-group-forbidden></app-group-forbidden>
  </div>
  <app-empty-widget-content *ngIf="isShowEmpty()" [text]="'Нет опубликованных вакансий'"></app-empty-widget-content>
</div>

<div class="card" *ngIf="!loaded && showWidget">
  <div class="card__title">
    <span class="preloader">
      <span class="preloader__line"></span>
    </span>
  </div>
  <div class="card__content">
    <span class="preloader _square" *ngFor="let r of [0,1,2,3]">
      <span class="preloader__side"></span>
      <span class="preloader__line"></span>
    </span>
  </div>
</div>
