import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignalRService } from '@app/signalr/signalR.service';
import { MainHub } from '@app/signalr/main.hub';
import { FeedHub } from '@app/signalr/feed.hub';
import { GroupHub } from '@app/signalr/group.hub';
import { CommentHub } from '@app/signalr/comment.hub';
import { LikeHub } from '@app/signalr/like.hub';
import { GalleryHub } from './gallery.hub';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    SignalRService,
    MainHub,
    FeedHub,
    CommentHub,
    LikeHub,
    GroupHub,
    GalleryHub
  ]
})
export class SignalRModule { }
