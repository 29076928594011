<div class="cos__body _paddings">
  <ng-container *ngIf="loaded && survey?.type!=SurveyType.test">
    <!-- отображение результатов опроса -->
    <ng-container *ngIf="mode===SurveyResultsMode.survey">
      <div class="news-title">{{survey?.title}}</div>
      <span class="header-text _low _secondary">
        <span class="header-text__text">{{getSurveyAnswersText()}}</span>
      </span>
      <div class="poll-section" *ngFor="let question of survey?.questions; trackBy:trackBy">
        <ng-container [ngSwitch]="question.type">
          <ng-container *ngSwitchCase="SurveyQuestionType.text">
            <ng-container *ngTemplateOutlet="textQuestionTemplate; context: {$implicit: {index:i, question: question}}"></ng-container>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <ng-container *ngTemplateOutlet="variantQuestionTemplate; context: {$implicit: {index:i, question: question}}"></ng-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
    <!-- отображение результатов опроса END -->
    <!-- отображение информации по текстовому опросу или варианту "Другое" -->
    <ng-container *ngIf="mode===SurveyResultsMode.option">
      <ng-container *ngIf="optionDetails">
        <div class="news-title">{{optionDetails?.surveyTitle}}</div>
        <div class="poll-section">
          <div class="news-announce">{{optionDetails.questionOrder}}.
            {{optionDetails.questionTitle}}{{optionDetails.questionRequired ? "*" : ""}}</div>
          <span class="header-text _low _secondary">
            <span class="header-text__text">Ответили {{answersCount}} из {{optionDetails.questionCompleteCount}}</span>
          </span>
          <ng-container *ngIf="optionDetails.questionAnswers?.length">
            <div class="poll-result" *ngFor="let answer of optionDetails.questionAnswers">
              <div class="poll-result__custom">{{answer.text}}</div>
              <div class="poll__subtitle">{{survey.date | date:'dd.MM.yy в HH:mm'}}</div>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="!optionDetails">
        <div class="news-title">Вопроса не существует или у вас нет прав на его просмотр</div>
      </ng-container>
    </ng-container>
    <!-- отображение информации по текстовому опросу или варианту "Другое" END -->
  </ng-container>
  <ng-container *ngIf="loaded && survey?.type===SurveyType.test">
    <div class="news-title">{{survey?.title}}</div>
    <span class="header-text _low _secondary">
      <span class="header-text__text">{{getSurveyAnswersText()}}</span>
    </span>
    <div class="poll-section" *ngFor="let surveyUser of surveyUsers; trackBy:trackBy">
      <div class="co">
        <div class="co__avatar">
          <app-user-avatar [user]="surveyUser.user" [linkClass]="'avatar _80'" [imageClass]="'avatar__image'">
          </app-user-avatar>
        </div>
        <div class="co__info">
          <a href="#" class="link co__name" routerLink="/profile/{{surveyUser?.user?.id}}"
            (click)="usersService.navigateProfile(surveyUser?.user?.id)">
            <span [appUserTooltip]="surveyUser?.user?.id">{{surveyUser?.user?.fullName}}</span>
            <app-user-absence [user]="surveyUser.user" [isLightMode]="true"></app-user-absence>
          </a>
          <div class="co__occ" *ngIf="surveyUser?.user?.jobTitle">{{surveyUser?.user?.jobTitle}}</div>
          <a class="co__occ" routerLink="/profile/{{currentUser?.id}}/search" fragment="k={{surveyUser?.user?.department}}"
            *ngIf="surveyUser?.user?.department">{{surveyUser?.user?.department}}</a>
          <div class="co__phones" *ngIf="surveyUser?.user?.mobilePhone || surveyUser?.user?.workPhone">
            <a href="tel:{{surveyUser?.user?.mobilePhone}}" class="link co__phone"
              *ngIf="surveyUser?.user?.mobilePhone">{{surveyUser?.user?.mobilePhone}}</a>
            <a href="tel:{{surveyUser?.user?.workPhone}}" class="link co__phone"
              *ngIf="surveyUser?.user?.workPhone">{{surveyUser?.user?.workPhone}}</a>
          </div>
        </div>
        <div class="co__action">
          <div class="poll-result-inline">
            <div class="poll-result-inline__score">{{surveyUser.percent}}</div>
            <div class="poll-result-inline__sign">{{getSurveyTestAnswersText(surveyUser)}}</div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<!-- Вариант вопроса с выбором -->
<ng-template #variantQuestionTemplate let-data>
  <div class="news-announce">{{data.question.order}}. {{data.question.title}}{{data.question.required ? "*" : ""}}</div>
  <span class="header-text _low _secondary">
    <span class="header-text__text">Ответили {{data.question.answersCount}} из {{getSurveyAnswersCount()}}</span>
  </span>
  <ng-container *ngFor="let option of data.question.options">
    <div class="poll-result">
      <div class="poll-result__title">{{option.type===SurveyQuestionOptionType.other ? 'Другое' : option.title}}</div>
      <div class="poll-result__bar-wrap" [attr.style]="getBarWidth(option, data.question)">
        <mat-progress-bar mode="determinate" [value]="getOptionPercent(option, data.question)"></mat-progress-bar>
        <!-- <div [ngStyle]="{'width': getOptionPercent(option, data.question)+'%'}" class="poll-result__bar"></div> -->
        <div class="poll-result__count">{{option.answersCount}} из {{data.question.answersCount}}</div>
      </div>
    </div>
    <a href="javascript:;" class="link _progress" *ngIf="option.answersCount > 0 && option.type===SurveyQuestionOptionType.other"
      (click)="getOptionDetails(option.id, 0, 100)">Показать
      все ответы поля</a>
  </ng-container>
</ng-template>
<!-- Вариант вопроса с выбором END -->

<!-- Текстовый вариант вопроса -->
<ng-template #textQuestionTemplate let-data>
  <div class="news-announce">{{data.question.order}}. {{data.question.title}}{{data.question.required ? "*" : ""}}</div>
  <span class="header-text _low _secondary">
    <span class="header-text__text">Ответили {{data.question.answersCount}} из {{getSurveyAnswersCount()}}</span>
  </span>
  <ng-container *ngIf="data.question.options?.length">
    <div class="poll-result" *ngFor="let answer of data.question.options[0].answers">
      <div class="poll-result__custom">{{answer.text}}</div>
    </div>
    <a href="javascript:;" class="link _progress" *ngIf="data.question.answersCount>3" (click)="getOptionDetails(data.question.options[0].id, 0, 100)">Показать
      все ответы поля</a>
  </ng-container>
</ng-template>
<!-- Текстовый вариант вопроса END -->
