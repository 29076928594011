import { Component, OnInit, Input, ViewChild, OnDestroy, Output, EventEmitter, ElementRef } from '@angular/core';
import { FilesService } from '@app/files/services/files.service';
import { Subject, BehaviorSubject } from 'rxjs';
import { FilesTreeComponent } from '@app/files/components/files-tree/files-tree.component';
import { Router } from '@angular/router';
import { UsersService } from '@app/profile/services/users.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CreateFolderComponent } from '@app/files/components/create-folder/create-folder.component';
import { DocumentTemplateType } from '@app/files/model/files-list-item.model';
import { AlertsService } from '@app/shared/services/alerts.service';
import { BaseResizableComponent } from '@app/core/components/base-resizable.component';
import { HelperService } from '@app/core/services/helper.service';
import { takeUntil, take } from 'rxjs/operators';
import { Helper } from '@app/core/helpers/helper';
import { FileUploadService } from '@app/shared/services/file-upload.service';
import { IntryFile } from '@app/files/model/data-file-model';

@Component({
    selector: 'app-files-menu',
    templateUrl: 'files-menu.component.html'
})

export class FilesMenuComponent extends BaseResizableComponent implements OnInit, OnDestroy {
    @Input() showAddButton: boolean;

    // folderId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    // ownerId: BehaviorSubject<number> = new BehaviorSubject<number>(null);

    @Input() selectedFolderId: BehaviorSubject<number> = new BehaviorSubject<number>(null);

    // @Input() webUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    // @Input() folderUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    // @Input() listUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    // @Input() selectedFolderUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    @Input() withTree: boolean;

    @Output() onFolderCreated: EventEmitter<any> = new EventEmitter();
    @Output() onFileAdded: EventEmitter<any> = new EventEmitter();
    @Output() onFilesAdded: EventEmitter<any> = new EventEmitter();
    @Output() onFileAddedError: EventEmitter<any> = new EventEmitter();

    deletingItem: boolean;
    fileUploading: boolean;

    filesToUpload: any[] = [];

    uploadingFilesDialog: MatDialogRef<FilesTreeComponent>;
    createNewOfficeFileDialog: MatDialogRef<FilesTreeComponent>;
    createNoteDialog: MatDialogRef<CreateFolderComponent>;

    @ViewChild('fileUpload', { static: true }) fileUpload: ElementRef;
    @ViewChild('newFileLinkAnchor', { static: true }) newFileLinkAnchor: ElementRef;

    documentTemplateType = DocumentTemplateType;

    constructor(
        public filesService: FilesService,
        public usersService: UsersService,
        public router: Router,
        public dialog: MatDialog,
        public alertsService: AlertsService,
        public helper: HelperService) { super(helper); }

    ngOnInit() {
        this.filesService.folderId$.subscribe(folder => {
            this.selectedFolderId.next(folder);
        });
    }

    openCreateFolder() {
        this.dialog.open(CreateFolderComponent, {
            data: {
                // ownerId: this.ownerId,
                // folderId: this.folderId,
                onFolderCreated: this.onFolderCreated
            }
        });
    }

    openCreateOfficeFile(type: DocumentTemplateType) {
        let func: Function;

        if (type === DocumentTemplateType.OneNote) {
            func = this.openCreateNote.bind(this);
        } else {
            func = this.createOOSFile.bind(this, type, null);
        }

        if (this.withTree) {
            this.createNewOfficeFileDialog = this.dialog.open(FilesTreeComponent, {
                data: {
                    folder: this.selectedFolderId,
                    foldersOnly: true,
                    // folderId: this.folderId,
                    // ownerId: this.ownerId,
                    // rootFolderId: this.rootFolderId,
                    onOk: func,
                    onFolderCreated: this.onFolderCreated,
                    headerText: 'Выберите папку для создания документа',
                    okText: 'Выбрать'
                }
            });
        } else {
            func();
        }
    }

    openCreateNote() {
        this.createNoteDialog = this.dialog.open(CreateFolderComponent, {
            data: {
                headerText: 'Записная книжка OneNote',
                placeholder: 'Введите новое имя',
                icon: '_onenote',
                onOk: this.createOOSFile.bind(this, DocumentTemplateType.OneNote)
            }
        });
    }

    createOOSFile(type: DocumentTemplateType, fileName: string) {
        this.createNewOfficeFile(type, fileName);
    }

    openUploadFileItem() {
        this.uploadingFilesDialog = this.dialog.open(FilesTreeComponent, {
            data: {
                folder: this.selectedFolderId,
                foldersOnly: true,
                // folderId: this.folderId,
                // ownerId: this.ownerId,
                // rootFolderUrl: this.listUrl,
                onOk: this.onUpload.bind(this),
                onFolderCreated: this.onFolderCreated,
                headerText: 'Выберите папку для загрузки',
                okText: 'Загрузить'
            }
        });
    }

    dropFiles(files) {
        this.operateUploadingFiles(files);
    }

    private operateUploadingFiles(files) {
        if (files && files.length) {
            this.filesToUpload = [];
            for (let i = 0; i < files.length; i++) {
                this.filesToUpload.push(files[i]);
            }
        }

        if (this.filesToUpload && this.filesToUpload.length) {
            // upload file
            this.fileUploading = true;
            // this.ownerId.pipe(take(1), takeUntil(this.destroyed$)).subscribe(ownerId => {
            this.selectedFolderId.pipe(take(1), takeUntil(this.destroyed$)).subscribe(folderId => {
                const uploadFolderId = folderId ? folderId : null;
                this.filesToUpload.forEach(file => {
                    this.uploadFile(uploadFolderId, this.filesService.ownerId$.getValue(), file);
                });
            });
            // });
        }
    }

    /**
     *  Хак для определения того, что нажали на кнопку при выборе файла
     */
    uploadClicked(e) {
        const that = this;
        window.onfocus = function () {
            if (that.uploadingFilesDialog) {
                that.uploadingFilesDialog.componentInstance.disabled = false;
            }
            window.onfocus = null;
        };
    }

    fileChangeEvent(fileInput) {
        const files = fileInput.target.files;
        this.operateUploadingFiles(files);
    }

    private uploadFile(folderId: number, ownerId: number, file: IntryFile) {
        file.uploading = true;

        // get files from form data
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);

        this.filesService.uploadFileV2(formData, ownerId, folderId).subscribe((res: any) => {
            if (res) {
                console.log('file uploading success');
                this.alertsService.riseSuccess(`Файл '${file.name}' был успешно добавлен`);
                if (this.onFileAdded) {
                    this.onFileAdded.emit();
                }
            } else {
                console.log('file uploading error');
                this.alertsService.riseError(`Произошла ошибка во время добавления файла '${file.name}'. ` + Helper.FILE_UPLOAD_ERROR);
            }
            this.fileUploadingFinished(file, folderId);
        }, error => {
            console.log(error);
            this.alertsService.riseError(`Произошла ошибка во время добавления файла '${file.name}'. ` + Helper.FILE_UPLOAD_ERROR);

            if (this.onFileAddedError) {
                this.onFileAddedError.emit(error);
            }
            this.fileUploadingFinished(file, folderId);
        });
    }

    private fileUploadingFinished(file: any, folderId: number) {
        file.uploading = false;
        if (!this.filesToUpload.filter(f => f.uploading === true).length) {
            this.fileUploading = false;
            if (this.onFilesAdded) {
                this.onFilesAdded.emit(folderId);
            }
            if (this.withTree) {
                this.uploadingFilesDialog.close();
            }
        }
    }

    onUpload(result: any) {
        console.log(result.folderUrl);
        this.selectedFolderId.next(result.folderUrl);
        this.fileUpload.nativeElement.click();
    }

    uploadFiles() {
        if (this.withTree) {
            this.openUploadFileItem();
        } else {
            this.fileUpload.nativeElement.click();
        }
    }

    createNewOfficeFile(type: DocumentTemplateType, fileName: string) {
        // const listUrl = this.listUrl.getValue();
        // let selectedFolderId = this.selectedFolderId.getValue();

        // console.log(`folderUrl=${selectedFolderId}`);

        // if (type === DocumentTemplateType.OneNote) {
        //     fileName = decodeURIComponent(fileName);
        //     // if (selectedFolderUrl) {
        //     //     selectedFolderUrl = decodeURIComponent(selectedFolderUrl);
        //     // } else {
        //     //     selectedFolderUrl = '';
        //     // }
        // }

        // this.filesService.webApiGetNewFileUrl(webUrl, listUrl, selectedFolderUrl, type, fileName).subscribe(res => {
        //     if (res && res.value) {
        //         this.newFileLinkAnchor.nativeElement.href = res.value;
        //         this.newFileLinkAnchor.nativeElement.click();
        //         if (this.withTree) {
        //             this.createNewOfficeFileDialog.close();
        //         } else {
        //             if (this.onFolderCreated) {
        //                 this.onFolderCreated.emit();
        //             }
        //         }
        //         if (type === DocumentTemplateType.OneNote) {
        //             this.createNoteDialog.close();
        //         }
        //     } else {
        //         if (type === DocumentTemplateType.OneNote) {
        //             this.createNoteDialog.componentInstance.data.error = 'Произошла ошибка при создании записной книжки OneNote';
        //         } else if (this.withTree) {
        //             this.createNewOfficeFileDialog.componentInstance.data.error = 'Произошла ошибка при создании файла';
        //         }
        //         this.alertsService.riseError('Произошла ошибка при создании файла');
        //     }
        // }, error => {
        //     console.log(error);
        //     this.alertsService.riseError('Произошла ошибка при создании файла');
        // });
    }

    onBlur(e) {
        const target = e.target || e.srcElement;
        if (!target.value.length) {
            console.log('File not selected: ', target.value);
        } else {
            console.log('File selected: ', target.value);
        }
    }
}
