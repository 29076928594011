<div class="cos">
  <div class="user-header" *ngIf="loaded && user">
    <a href="#" class="link-profile" routerLink="/profile/{{user?.id}}">
      <app-user-avatar [user]="user" [linkClass]="'avatar _30'" [imageClass]="'avatar__image'" [asSpan]="true"></app-user-avatar>
      <span class="link-profile__text" [appUserTooltip]="user?.id">{{user?.fullName}}</span>
    </a>
    <app-breadcrumbs></app-breadcrumbs>
  </div>
  <div class="cos__header" *ngIf="loaded">
    <app-tabs [tabs]="tabs"></app-tabs>
    <a *ngIf="showAddButton" class="btn btn-plus pull-right" routerLink="/group/new">+</a>
  </div>
  <!-- header preloader -->
  <app-tab-preloader *ngIf="!loaded"></app-tab-preloader>
  <!--  header preloader END -->
</div>
<ng-container *ngIf="loaded && currentTab && currentTab.loaded">
  <h2 class="cos-subtitle" *ngIf="businessGroups?.length">Бизнес-группы</h2>
  <div class="cos _business" *ngIf="businessGroups?.length">
    <div class="cos__body">
      <ng-container *ngFor="let group of businessGroups; trackBy:group?.id">
        <!-- добавить модификатор _white-text, добавить элемент co__bg с инлайн-стилем, если есть фон-->
        <div class="co" [ngClass]="{'_white-text':group.pictureUrl}" (click)="groupsService.navigateGroup(group?.id)">
          <div [attr.style]="getPictureUrl(group)" class="co__bg" *ngIf="group.pictureUrl"></div>
          <div class="co__avatar">
            <span [ngStyle]="{'background-color':groupsService.getColor(group)}" [attr.data-initials]="groupsService.getInitials(group)"
              class="avatar _80 _bg" [attr.title]="group.title" routerLink="/group/{{group.id}}">
              <span class="avatar__image-wrap" [attr.style]="getPictureUrl(group)">
                <img class="avatar__image" *ngIf="group.pictureUrl" [attr.src]="groupsService.getGroupSmallPicture(group)"
                  (error)="group.pictureUrl=null">
              </span>
            </span>
          </div>
          <div class="co__info">
            <a class="link co__name" routerLink="/group/{{group.id}}">{{group.title}}</a>
            <!-- <div class="co__occ" *ngIf="group.usersCount">{{group.usersCount}} {{groupsService.getUsersCountText(group)}}</div> -->
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <h2 class="cos-subtitle" *ngIf="otherGroups?.length">Рабочие группы</h2>
  <div class="cos" *ngIf="otherGroups?.length">
    <div class="cos__body">
      <div class="co" *ngFor="let group of otherGroups; trackBy:group?.id" (mouseover)="groupMenu.checkVisibility()">
        <div class="co__avatar">
          <span [ngStyle]="{'background-color':groupsService.getColor(group)}" [attr.data-initials]="groupsService.getInitials(group)"
            class="avatar _80 _bg" [attr.title]="group.title" routerLink="/group/{{group.id}}">
            <span class="avatar__image-wrap" [attr.style]="getPictureUrl(group)">
              <img class="avatar__image" *ngIf="group.pictureUrl" [attr.src]="groupsService.getGroupSmallPicture(group)"
                (error)="group.pictureUrl=null">
            </span>
          </span>
        </div>
        <div class="co__info">
          <a class="link co__name" routerLink="/group/{{group.id}}" (click)="groupsService.navigateGroup(group?.id)">{{group.title}}</a>
          <div class="co__occ">
            <span>{{groupsService.getGroupTypeNameByEnum(group.type)}} группа</span>,
            <span>{{group.usersCount}}&nbsp;</span>
            <span *ngIf="group.type!==groupType.Business">{{groupsService.getUsersCountText(group)}}</span>
          </div>
          <div class="co__occ" [ngClass]="{'_red':groupsService.isRequested(group, null)}">{{groupsService.getUserRoleByEnum(group)}}</div>
        </div>
        <div class="co__action">
          <app-group-menu #groupMenu [user]="user" [group]="group" [type]="menuType.Group" (onLeave)="onLeave($event)" (onJoin)="onJoin($event)"
            (onSubscribe)="onSubscribe($event)" (onUnsubscribe)="onUnsubscribe($event)" (onRemove)="onRemove($event)"></app-group-menu>
        </div>
      </div>
    </div>
  </div>
  <div class="cos" *ngIf="isCurrentUser && currentTab?.loaded && (!currentTab?.items || !currentTab?.items.length)">
    <div class="cos__body">
      <div class="group-empty">
        <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" class="group-empty__img">
        <div class="group-empty__text">Вступайте в
          <strong>группы</strong>
          <br /> и следите за обновлениями.</div>
      </div>
      <div class="group-empty">
        <div class="group-empty__text">Или создайте свою группу
          <br />и пригласите в неё коллег.</div>
        <div class="align-center group-empty__action">
          <a class="btn btn-plus" routerLink="/group/new">+</a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<!-- header preloader -->
<app-tabbed-list-preloader *ngIf="!currentTab?.loaded"></app-tabbed-list-preloader>
<!--  header preloader END -->
