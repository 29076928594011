import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HelperService } from '@app/core/services/helper.service';
import { UsersService } from '@app/profile/services/users.service';
import { Helper } from '@app/core/helpers/helper';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService extends BaseService {

  protected serviceUrl = `${environment.siteUrl}/api/files`;

  protected uploadedFiles: Array<any> = [];

  constructor(
    protected http: HttpClient,
    protected usersService: UsersService,
    public helper: HelperService) {
    super(http, helper);
  }

  uploadFile(
    url: string,
    folderUrl: string,
    filename: string,
    file: any,
    overwrite: boolean = true) {

    const newUpload = {
      id: Helper.guid(),
      url,
      folderUrl,
      filename,
      startTime: new Date(),
      digestTime: new Date(),
      endTime: null
    };

    this.uploadedFiles.push(newUpload);

    return super.getWebServerUrl(url, null)
      .toPromise()
      .then(web => {
        // 'folderUrl' is absolute url but 'web.value' is server relative
        const index = folderUrl.indexOf(web.value);
        folderUrl = folderUrl.substr(index);

        filename = filename.replace(/[\/\\#+$%'":*?<>]/g, ' ');
        console.log(filename);

        let offset = 0;
        // the total file size in bytes...
        const total = file.size;
        // 1MB Chunks as represented in bytes
        // (if the file is less than a MB, seperate it into two chunks of 80% and 20% the size)...
        let length = 10000000 > total ? Math.round(total * 0.8) : 10000000;
        const chunks = [];

        const formData: FormData = new FormData();
        formData.append('webUrl', url);
        formData.append('folderUrl', encodeURIComponent('\'' + decodeURIComponent(folderUrl) + '\''));
        formData.append('filename', encodeURIComponent('\'' + decodeURIComponent(filename) + '\''));
        formData.append('file', file, encodeURIComponent('\'' + decodeURIComponent(filename) + '\''));
        formData.append('overwrite', file, overwrite ? 'true' : 'false');

        if (10000000 > total) {
          return super.post<any>('uploadFile', formData).toPromise();
        } else {
          return new Promise((resolve, reject) => {
            while (offset < total) {
              // if we are dealing with the final chunk, we need to know...
              if (offset + length > total) {
                length = total - offset;
              }

              // work out the chunks that need to be processed and the associated REST method (start, continue or finish)
              chunks.push({
                offset,
                length,
                method: this.getUploadMethod(offset, length, total)
              });
              offset += length;
            }

            // each chunk is worth a percentage of the total size of the file...
            const chunkPercentage = (total / chunks.length) / total * 100;
            console.log('Chunk Percentage: ' + chunkPercentage);

            if (chunks.length > 0) {
              // Start the upload - send the data to S
              this.uploadFileRecursive(
                file, newUpload.id,
                url, folderUrl, filename, chunks, 0, 0, chunkPercentage, resolve, reject);
            }
          });
        }
      });
  }

  private getUploadingFile(id) {
    return this.uploadedFiles.find(s => s.id === id);
  }

  // Base64 - this method converts the blob arrayBuffer into a binary string to send in the REST request
  private convertDataBinaryString(data) {
    let fileData = '';
    const byteArray = new Uint8Array(data);
    for (let i = 0; i < byteArray.byteLength; i++) {
      fileData += String.fromCharCode(byteArray[i]);
    }
    return fileData;
  }

  // this method sets up the REST request and then sends the chunk of file along with the unique indentifier (uploadId)
  private uploadFileChunk(id, url, folderUrl, filename, chunk, data, byteOffset) {
      return this.execute(id, url, folderUrl, filename, chunk, data, byteOffset);
  }

  private execute(id, url, folderUrl, filename, chunk, data, byteOffset) {
    // post requests need X-RequestDigest
    return new Promise((resolve, reject) => {
      const uploadOffset = chunk.offset === 0 ? '' : ',fileOffset=' + chunk.offset;

      const fileUrl = Helper.concatUrls(decodeURIComponent(folderUrl), filename);

      const formData: FormData = new FormData();
        formData.append('webUrl', url);
        formData.append('folderUrl', folderUrl);
        formData.append('filename', filename);
        formData.append('file', data);
        formData.append('uploadOffset', uploadOffset);
        formData.append('id', id);
        formData.append('chunkMethod', chunk.method);
        formData.append('fileUrl', fileUrl);

      this.executePost('uploadFileChunk', formData).then(offset => resolve(offset)).catch(err => reject(err));
    });
  }

  // the primary method that resursively calls to get the chunks and upload them to the library (to make the complete file)
  private uploadFileRecursive(
    file, id, url, folderUrl, filename, chunks, index, byteOffset, chunkPercentage, resolve, reject) {

    // we slice the file blob into the chunk we need to send in this request (byteOffset tells us the start position)
    const data = this.convertFileToBlobChunks(file, chunks[index]);

    // upload the chunk to the server using REST, using the unique upload guid as the identifier
    this.uploadFileChunk(id, url, folderUrl, filename, chunks[index], data, byteOffset).then(value => {
      const isFinished = index === chunks.length - 1;
      index += 1;
      const percentageComplete = isFinished ? 100 : Math.round((index * chunkPercentage));
      console.log('Percentage Completed:' + percentageComplete);

      if (index < chunks.length) {
        this.uploadFileRecursive(
          file, id, url, folderUrl, filename, chunks, index, byteOffset, chunkPercentage, resolve, reject);
      } else {
        resolve(value);
        const currentUpload = this.getUploadingFile(id);
        currentUpload.uploaded = true;
      }
    }).catch(err => {
      console.log('Error in uploadFileChunk! ' + err);
      const currentUpload = this.getUploadingFile(id);
      currentUpload.error = err;
      reject(err);
    });
  }

  private getUploadMethod(offset, length, total) {
    if (offset + length + 1 > total) {
      return 'FinishUpload';
    } else if (offset === 0) {
      return 'StartUpload';
    } else if (offset < total) {
      return 'ContinueUpload';
    }
    return null;
  }

  private convertFileToBlobChunks(result, chunkInfo) {
    return result.slice(chunkInfo.offset, chunkInfo.offset + chunkInfo.length);
  }

  async executePost(url, data) {
    const res = await super.post(url, data).toPromise().catch((err: HttpErrorResponse) => {
      const error = err.error;
      return error;
    });
    return this.parseRetSingle(res);
  }

  private parseRetSingle(res) {
    if (res) {
      if (res.hasOwnProperty('d')) {
        return res.d;
      } else if (res.hasOwnProperty('error')) {
        const obj: any = res.error;
        obj.hasError = true;
        return obj;
      } else {
        return {
          hasError: true,
          comments: res
        };
      }
    } else {
      return {
        hasError: true,
        comments: 'Check the response in network trace'
      };
    }
  }
}
