import {
  Component,
  SimpleChanges,
  EventEmitter,
  Output,
  Input,
  OnChanges,
  OnDestroy
} from '@angular/core';
import { Subject } from 'rxjs';
import { IdeasService } from '@app/ideas/services/ideas.service';
import { IIdeaStatus } from '@app/ideas/model/idea';
import { take, takeUntil, finalize } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { BaseComponent } from '@app/core/components/base.component';

/**
 * Фильтр идей в списке идей
 *
 * @export
 * @class IdeaFiltersComponent
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-idea-filters',
  templateUrl: './idea-filters.component.html',
  styleUrls: ['./idea-filters.component.scss']
})
export class IdeaFiltersComponent extends BaseComponent implements OnChanges, OnDestroy {

  @Input() visible: boolean;

  @Output() statusChanged: EventEmitter<IIdeaStatus> = new EventEmitter();

  selectedStatus: IIdeaStatus;

  statuses: IIdeaStatus[] = [];

  constructor(private ideasService: IdeasService,
    public helper: HelperService,
  ) {
    super(helper);
    this.selectedStatus = this.statuses[0];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['visible']) {
      if (this.visible) {
        this.getIdeaStatuses();
      }
    }
  }

  /**
   * Получение статусов идей
   *
   * @memberof IdeaFiltersComponent
   */
  getIdeaStatuses() {
    this.ideasService.getIdeaStatusesInUse()
      .pipe(
        take(1),
        finalize(() => {
          // вставляем статус по умолчанию
          if (!this.statuses.find(s => s.id === 0)) {
            this.statuses.unshift({ id: 0, title: 'Все статусы', });
          }
          this.selectedStatus = this.statuses[0];
        }),
        takeUntil(this.destroyed$))
      .subscribe(res => {
        // заполняем массив статусов
        this.statuses = res ? res : [];
      });
  }

  /**
   * Установить статус
   *
   * @param {IIdeaStatus} status
   * @memberof IdeaFiltersComponent
   */
  setStatus(status: IIdeaStatus) {
    this.selectedStatus = status;
    this.statusChanged.emit(status);
  }
}
