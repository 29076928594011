import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { MenuComponent } from './components/menu/menu.component';
import { MenuPagesListComponent } from './components/menu-pages-list/menu-pages-list.component';
import { LeftMenuComponent } from './components/left-menu/left-menu.component';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { LeftMenuItemsComponent } from './components/left-menu-items/left-menu-items.component';
import { SharedModule } from '@app/shared/shared.module';
import { SearchModule } from '@app/search/search.module';
import { RouterModule } from '@angular/router';
import { NotificationsModule } from '@app/notifications/notifications.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    SearchModule,
    NotificationsModule
  ],
  declarations: [
    HeaderComponent,
    MenuComponent,
    MenuPagesListComponent,
    LeftMenuComponent,
    TopMenuComponent,
    LeftMenuItemsComponent
  ],
  exports: [
    HeaderComponent,
    MenuComponent,
    MenuPagesListComponent,
    LeftMenuComponent,
    TopMenuComponent,
    LeftMenuItemsComponent
  ]
})
export class HeaderModule { }
