<div *ngIf="loaded" class="card _thanks-main">
  <a class="card__title" routerLink="/thanks">
    <span class="header-text">
      <span class="header-text__text">Спасибо от сотрудников</span>
    </span>
  </a>

  <div class="card__content">
    <ngx-slick-carousel class="carousel" [config]="{}">
      <div ngxSlickItem class="co _new" *ngFor="let item of items">
        <div class="co__avatar">
          <app-user-avatar [user]="item.user" [linkClass]="'avatar _80'" [imageClass]="'avatar__image'">
          </app-user-avatar>
        </div>
        <div class="co__info">
          <a href="#" class="link co__name" routerLink="/profile/{{item.user?.id}}"
             (click)="usersService.navigateProfile(item.user?.id)">{{item.user?.fullName}}</a>
        </div>

        <div class="co__thanks">
          <div class="co _new">
            <div class="co__avatar">
              <app-user-avatar [user]="item.author" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'">
              </app-user-avatar>
            </div>
            <div class="co__body">
              <a href="#" class="link co__name" routerLink="/profile/{{item.author?.id}}"
                 (click)="usersService.navigateProfile(item.author?.id)">{{item.author?.fullName}}</a>

              <p>
                {{item.body}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </ngx-slick-carousel>

    <div *ngIf="loaded && !items?.length">
      Благодарите ваших коллег <br/> за помощь или отличную работу
    </div>
  </div>

  <div class="align-center">
    <button type="button" class="btn btn-white btn-stroke" (click)="thank()">Сказать спасибо</button>
  </div>
</div>

<div *ngIf="!loaded" class="card _thanks-main _preloader">
  <span class="card__title">
    <span class="preloader _white"><span class="preloader__line"></span></span>
  </span>

  <div class="card__content">
    <span class="preloader _white _avatar-big" *ngFor="let r of [0,1,2,3]">
      <span class="preloader__side"></span>
    </span>
  </div>

  <div class="align-center">
    <span class="preloader _white"><span class="preloader__line"></span></span>
  </div>
</div>
