<div class="btn btn-dots tooltip" mat-button [matMenuTriggerFor]="menu"></div>
<mat-menu #menu="matMenu">
  <div class="icon-menu" #menuContainer>
    <ng-container *ngIf="selectedItems?.length>1; then multipleFilesMenu; else singleFileMenu"></ng-container>
    <ng-template #singleFileMenu>
      <a mat-menu-item [attr.href]="filesService.webApiGetFileUrl(item, item.uniqueId)" target="_blank" class="icon-menu__item"
        *ngIf="!filesService.isFolder(item) && filesService.webApiFileIsOffice(item) && !filesService.isPdf(item)">
        <span class="icon-menu__icon">
          <img [attr.src]="filesService.getOfficeFileIcon(item)" alt="" class="icon-menu__img">
        </span>
        <span>Открыть в {{getOfficeOpeningName(item)}} Online</span>
      </a>
      <a mat-menu-item class="icon-menu__item" *ngIf="!filesService.isFolder(item) && filesService.webApiFileIsOffice(item) && !filesService.isPdf(item)" (click)="openInClientApp(item)">
        <span class="icon-menu__icon">
          <img [attr.src]="filesService.getOfficeFileIcon(item)" alt="" class="icon-menu__img">
        </span>
        <span>Открыть в {{getOfficeOpeningName(item)}}</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" (click)="copyToClipboard()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/link.svg" alt="" class="icon-menu__img">
        </span>
        <span>Копировать ссылку</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="isShowShare()" (click)="openShareItem()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/share.svg" alt="" class="icon-menu__img">
        </span>
        <span>Поделиться</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="!filesService.isFolder(item)" (click)="getItem()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/download.svg" alt="" class="icon-menu__img">
        </span>
        <span>Скачать</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="isShowRename()" (click)="showRenameItem()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/edit.svg" alt="" class="icon-menu__img">
        </span>
        <span>Переименовать</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="isShowMove()" (click)="openMoveItem()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/move.svg" alt="" class="icon-menu__img">
        </span>
        <span>Переместить</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="isShowMove()" (click)="openCopyItem()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/copy.svg" alt="" class="icon-menu__img">
        </span>
        <span>Копировать</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="isShowHistory()"
        (click)="showHistoryItem()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/versions.svg" alt="" class="icon-menu__img">
        </span>
        <span>Журнал версий</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="isShowDelete()" (click)="showDeleteItem()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/remove.svg" alt="" class="icon-menu__img">
        </span>
        <span>Удалить</span>
      </a>
    </ng-template>
    <ng-template #multipleFilesMenu>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="isShowMove()" (click)="openMoveItem()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/move.svg" alt="" class="icon-menu__img">
        </span>
        <span>Переместить</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="isShowMove()" (click)="openCopyItem()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/copy.svg" alt="" class="icon-menu__img">
        </span>
        <span>Копировать</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="isShowDelete()" (click)="showDeleteItem()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/remove.svg" alt="" class="icon-menu__img">
        </span>
        <span>Удалить</span>
      </a>
    </ng-template>
  </div>
</mat-menu>
