<div class="card">
  <span class="header-text _modal">
    <span class="header-text__text" mat-dialog-title>{{data.headerText}}</span>
  </span>
  <div class="tabs" *ngIf="groupsService.selectedGroup | async">
    <a class="tabs__item" [ngClass]="{'_active':galleryTreeMode==1}" href="javascript:;" (click)="myGallery()">
      <span class="header-text _low">
        <span class="header-text__text">Моя галерея</span>
      </span>
    </a>
    <a class="tabs__item" [ngClass]="{'_active':galleryTreeMode==2}" href="javascript:;" (click)="groupGallery()">
      <span class="header-text _low">
        <span class="header-text__text">Галерея группы</span>
      </span>
    </a>
  </div>
  <div class="files-search">
    <span class="icon _search"></span>
    <input class="main-input _files-search" type="text" [(ngModel)]="text" (ngModelChange)="text = $event"
      (keyup)="onSearch($event)" placeholder="введите название альбома" />
  </div>
  <!-- TODO: хлебные крошки -->
  <div>Галерея</div>
</div>
<div class="files-list">
  <app-album-list *ngIf="!currentAlbum" [hideAlbumSelector]="true" [hideAlbumMenu]="true" [selectAlbum]="true"
    (select)="onAlbumSelect($event)" [albums]="foundAlbums"></app-album-list>
  <app-media-list *ngIf="currentAlbum" [title]="'Медиафайлы альбома'" [albumId]="currentAlbum?.id" [limit]="10"
    [selectMediaFile]="!data.doNotSelectMediaFile" (select)="onMediaFileSelect($event)"></app-media-list>
</div>
<div class="error-message" *ngIf="data.error">{{data.error}}</div>
<div class="confirm _right">
  <div class="confirm__actions" mat-dialog-actions>
    <button type="button" mat-button mat-dialog-close class="btn btn-secondary"
      (click)="cancel()">{{data.cancelText}}</button>
    <button type="button" mat-button *ngIf="data.doNotHideOnOk" class="btn btn-primary" [attr.disabled]="disabledOk()"
      (click)="ok()">{{data.okText}}</button>
    <button type="button" mat-button *ngIf="!data.doNotHideOnOk" [mat-dialog-close]="true" class="btn btn-primary"
      [attr.disabled]="disabledOk()" (click)="ok()">{{data.okText}}</button>
  </div>
</div>