import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { UsersService } from '@app/profile/services/users.service';
import { IdeasService } from '@app/ideas/services/ideas.service';
import { IIdea } from '@app/ideas/model/idea';
import { GroupsService } from '@app/groups/services/groups.service';
import { GroupInfo } from '@app/groups/model/group-info.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { User } from '@app/profile/model/user.model';
import { IIdeaMenuConfig } from '../idea-menu/idea-menu.component';

/**
 * Список идей
 *
 * @export
 * @class IdeaListComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-idea-list',
  templateUrl: './idea-list.component.html',
  styleUrls: ['./idea-list.component.scss']
})
export class IdeaListComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input() ideas: IIdea[];

  private group: GroupInfo;
  private currentUser: User;

  constructor(
    public groupsService: GroupsService,
    public usersService: UsersService,
    public ideasService: IdeasService,
    public helper: HelperService
  ) { super(helper); }

  ngOnInit() {
    this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
      this.group = group;
    });
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;
    });
  }

  trackByFn(index, item: IIdea) {
    return item ? item.id : index; // or item.id
  }

  getIdeaConfig(idea: IIdea): IIdeaMenuConfig {
    return this.ideasService.getIdeaConfig(idea, this.groupsService.isAdmin(this.group, this.currentUser), this.currentUser);
  }
}
