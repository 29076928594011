/**
 * Уведомление в верхней части экрана
 *
 * @export
 * @class Alert
 */
export class Alert {
    id?: number;
    text: string;
    type: AlertType;
    /**
     * Задержка отображения, в миллисекундах
     *
     * @type {number}
     * @memberof Alert
     */
    delayShow?: number;
    show?: boolean;
    /**
     * Задержка скрытия, в миллисекундах
     *
     * @type {number}
     * @memberof Alert
     */
    delayHide?: number;
}

/**
 * Тип уведомления, бывают: Успешно (Success), Не успешно (Error)
 *
 * @export
 * @enum {number}
 */
export enum AlertType {
    Success,
    Error
}

