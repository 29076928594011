import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SurveysApiService } from './services/surveys-api.service';
import { SurveysService } from './services/surveys.service';
import { SurveyMenuComponent } from './components/survey-menu/survey-menu.component';
import { SurveyResultsComponent } from './components/survey-results/survey-results.component';
import { SurveyWidgetComponent } from './components/survey-widget/survey-widget.component';
import { SharedModule } from '@app/shared/shared.module';
import { CoreModule } from '@app/core/core.module';
import { FormsModule } from '@angular/forms';
import { SurveyListViewComponent } from './components/survey-list/survey-list-view.component';
import { RouterModule } from '@angular/router';
import { SurveyEditComponent } from './components/forms/survey-edit/survey-edit.component';
import { SurveyNewComponent } from './components/forms/survey-new/survey-new.component';
import { SurveyViewComponent } from './components/forms/survey-view/survey-view.component';
import { GroupSurveyNewComponent } from './components/forms/survey-new/group-survey-new.component';
import { SurveyListComponent } from './components/survey-list/survey-list.component';
import { SurveysMenuComponent } from './components/surveys-menu/surveys-menu.component';

/**
 * Модуль опросов. Импортируется в необходимые модули, либо в глобальный.
 *
 * @export
 * @class SurveysModule
 */
@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    FormsModule,
    RouterModule,
  ],
  declarations: [
    SurveyListComponent,
    SurveyListViewComponent,
    SurveyMenuComponent,
    SurveyNewComponent,
    SurveyViewComponent,
    SurveyEditComponent,
    SurveyResultsComponent,
    SurveyWidgetComponent,
    GroupSurveyNewComponent,
    SurveysMenuComponent,
  ],
  exports: [
    SurveyListViewComponent,
    SurveyMenuComponent,
    SurveyViewComponent,
    SurveysMenuComponent,
  ],
  providers: [
    SurveysApiService,
    SurveysService
  ]
})
export class SurveysModule { }
