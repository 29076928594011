<div class="card">
  <span class="header-text _modal">
    <span class="header-text__text">
      Добавление сотрудников в группу
    </span>
  </span>
  <div class="form _modal">
    <div class="form__line">
      <div class="dropdown _default" [ngClass]="{'_placeholder':!selectedRole}">
        <div mat-button [matMenuTriggerFor]="menu">
          {{selectedRole ? selectedRole : 'Выберите роль*'}}
        </div>
        <mat-menu #menu="matMenu">
          <div class="icon-menu" #menuContainer>
            <a mat-menu-item href="javascript:;" class="icon-menu__item" (click)="setRoleType(roleType)"
              *ngFor="let roleType of roleTypes">
              <span>{{roleType}}</span>
            </a>
          </div>
        </mat-menu>
      </div>
    </div>
    <!-- <div class="form__line" *ngIf="selectedChooseType==='typed' && isBusiness">
      <label class="checkbox">
        <input type="radio" name="role-type" class="checkbox__input" value="all" [(ngModel)]="selectedChooseType"
          [attr.disabled]="!isBusiness?null:''">
        <span class="checkbox__icon"></span>
        <span class="checkbox__text">Добавить всех сотрудников</span>
      </label>
      <label class="checkbox">
        <input type="radio" name="role-type" class="checkbox__input" value="typed" [(ngModel)]="selectedChooseType">
        <span class="checkbox__icon"></span>
        <span class="checkbox__text">Выбрать из списка</span>
      </label>
      <ng-container>
        <span mat-button [matMenuTriggerFor]="userTypeMenu">{{getSelectedType()}}</span>
        <mat-menu #userTypeMenu="matMenu">
          <div class="icon-menu" #userTypeMenuContainer>
            <a mat-menu-item href="javascript:;" class="icon-menu__item" (click)="setUserType(userType)"
              *ngFor="let userType of userTypes">
              <span>{{userType}}</span>
            </a>
          </div>
        </mat-menu>
      </ng-container>
    </div> -->
    <div class="form__line">
      <div class="cos-add _full" *ngIf="selectedRole">
        <div class="cos-add__tags">
          <div class="tag" *ngFor="let u of usersToAdd">
            <button type="button" class="btn btn-secondary btn-icon tag__button" (click)="removeUser(u)">
              <span class="tag__name">{{u.fullName}}</span>
              <img src="{{assetsPrefix}}/assets/images/icons/menu/remove.svg" />
            </button>
          </div>
        </div>
        <div class="input-main _full">
          <input #inputUser placeholder="{{placeholder}}" class="input-main__field" (keyup)="onSearchKey($event)"
            (keydown)="onSearchKey($event)" [ngModel]="searchText" (ngModelChange)="searchText = $event">
        </div>
        <div class="error-message" *ngIf="data.error">{{data.error}}</div>
        <div class="cos-add__controls" mat-dialog-actions>
          <button type="button" mat-button mat-dialog-close class="btn btn-simple" (click)="cancel()">Отмена</button>
          <button type="button" mat-button class="btn btn-primary" (click)="addUsers()"
            [ngClass]="{_inactive:!isValid}">Добавить</button>
        </div>
        <div class="cos-add__suggest">
          <div class="co _one-line" *ngFor="let u of usersFound" (click)="addUser(u)">
            <div class="co__avatar">
              <app-user-avatar [user]="u" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'" [noRedirect]="true">
              </app-user-avatar>
            </div>
            <div class="co__info">
              <a href="javascript:void(0)" class="link co__name">{{u.fullName}}</a>
              <span class="co__occ">{{u.jobTitle}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
