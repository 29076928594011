import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { Helper } from '@app/core/helpers/helper';
import { Router } from '@angular/router';
import { resetFakeAsyncZone } from '@angular/core/testing';
import { User } from '@app/profile/model/user.model';
import { UsersService } from '@app/profile/services/users.service';

@Component({
  selector: 'app-goback',
  templateUrl: './goback.component.html'
})
export class GobackComponent implements OnInit, OnChanges {
  @Input() target: any;
  @Input() cssClass: string;
  @Input() text = '';
  @Input() prefix = '';
  @Input() showAlways: boolean;
  @Input() currentUser: User;

  showBack = false;
  backUrl: string;

  @Output() onGoBack: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router, private usersService: UsersService) { }

  goBack() {
    if (this.onGoBack) {
      this.onGoBack.emit();
    }

    Helper.routes = Helper.routes.splice(0, Helper.routes.length - 1);
    const index = this.backUrl.indexOf('#');
    if (index !== -1) {
      this.router.navigate([this.backUrl.substr(0, index)], { fragment: this.backUrl.substring(index + 1) });
    } else {
      this.router.navigate([this.backUrl]);
    }
  }

  findPrevRoute(currentRoute: string) {
    let route: string = null;

    if (!Helper.routes || !Helper.routes.length) {
      return route;
    }

    let k = Helper.routes.length - 1;
    do {
      if (!this.prefix && currentRoute !== Helper.routes[k] || this.prefix && Helper.routes[k].indexOf(this.prefix) === -1) {
        route = Helper.routes[k];
        break;
      }
      k--;
    }
    while (k >= 0);

    // delete all routes after founded
    // if (route && k > 0 && Helper.routes.length > k) {
    //   const newRoutes = Helper.routes.slice();
    // }

    return route;
  }

  // private isAnyPrefix(route: string) {
  //   let anyPrefix = false;
  //   if (this.prefix && this.prefix.length) {
  //     this.prefix.forEach(p => {
  //       if (route.indexOf(p) === -1) {
  //         anyPrefix = true;
  //       }
  //     });
  //   }
  //   return anyPrefix;
  // }

  ngOnInit() {

  }

  private getDefaultBackUrlByContext(): string {
    const url = window.location.href;
    if (url) {
      if (url.match(/search#files/gi)) {
        return `profile/${this.currentUser.id}/files`;
      } else if (url.match(/search#groups/gi)) {
        return `profile/${this.currentUser.id}/groups`;
      } else {
        return `profile/${this.currentUser.id}`;
      }
    } else {
      return '/';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['target']) {
      this.usersService.currentUser.subscribe(currentUser => {
        // set current user
        this.currentUser = currentUser;

        if (Helper.routes.length > 0 || this.showAlways) {
          this.backUrl = this.findPrevRoute(this.router.url);
          // we need aurl anyway
          if (!this.backUrl && this.showAlways) {
            this.backUrl = this.getDefaultBackUrlByContext();
          }
          // show link
          this.showBack = true;
        } else {
          this.showBack = false;
        }
      });
    }
  }
}
