import { Input, HostListener, AfterViewInit, Directive } from '@angular/core';
import { BaseComponent } from './base.component';
import { ViewType } from '../model/view-type.enum';
import { HelperService } from '../services/helper.service';

/**
 * Базовый компонент, автоматически меняющий поведение при ресайзе
 *
 * @export
 * @class BaseResizableComponent
 * @extends {BaseComponent}
 * @implements {AfterViewInit}
 */
@Directive()
export class BaseResizableComponent extends BaseComponent implements AfterViewInit {
    @Input() protected view: ViewType;
    public viewType = ViewType;
    public innerWidth: number;

    constructor(public helper: HelperService) {
        super(helper);
    }

    ngAfterViewInit() {
        this.onBaseResize();
    }

    isMobileView() {
        return this.view === ViewType.Mobile;
    }

    isNotMobileView() {
        return this.view === ViewType.Desktop || this.view === ViewType.Tablet;
    }

    isNotDesktop() {
        return this.view === ViewType.Mobile || this.view === ViewType.Tablet;
    }

    isTablet() {
        return this.view === ViewType.Tablet;
    }

    isDesktop() {
        return this.view === ViewType.Desktop
            || this.view === ViewType.Desktop1200
            || this.view === ViewType.Desktop1600
            || this.view === ViewType.Desktop1900;
    }

    isDesktop1200() {
        return this.view === ViewType.Desktop1200
            || this.view === ViewType.Desktop1600
            || this.view === ViewType.Desktop1900;
    }

    isDesktop1600() {
        return this.view === ViewType.Desktop1600
            || this.view === ViewType.Desktop1900;
    }

    isDesktop1900() {
        return this.view === ViewType.Desktop1900;
    }

    @HostListener('window:resize')
    onBaseResize() {
        this.innerWidth = window.innerWidth;
        if (window.innerWidth >= ViewType.Desktop1900) {
            this.view = ViewType.Desktop1900;
        } else if (window.innerWidth >= ViewType.Desktop1600) {
            this.view = ViewType.Desktop1600;
        } else if (window.innerWidth >= ViewType.Desktop1200) {
            this.view = ViewType.Desktop1200;
        } else if (window.innerWidth >= ViewType.Desktop) {
            this.view = ViewType.Desktop;
        } else if (window.innerWidth > 490 && window.innerWidth < 1000) {
            this.view = ViewType.Tablet;
        } else {
            this.view = ViewType.Mobile;
        }
    }
}
