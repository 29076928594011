import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SurveysService } from '@app/surveys/services/surveys.service';
import {
  INewSurvey,
  SurveyQuestionType,
  ISurveyQuestion,
  ISurveyQuestionOption,
  SurveyQuestionOptionType,
  ISurveyQuestionRangeOptionValue,
  SurveyType
} from '@app/surveys/model/survey';
import { take } from 'rxjs/operators';
import { AlertsService } from '@app/shared/services/alerts.service';
import { Router, ActivatedRoute } from '@angular/router';
import { IIntryProfile } from '@app/shared/model/intry-profile';
import { ProfileType } from '@app/core/model/profile-type.enum';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import { UsersService } from '@app/profile/services/users.service';
import { CdkDragDrop, CdkDragMove, moveItemInArray } from '@angular/cdk/drag-drop';

/**
 * Компонент создания нового опроса или редактирования опроса
 *
 * @export
 * @class SurveyNewComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-survey-new',
  templateUrl: './survey-new.component.html',
  styleUrls: ['./survey-new.component.scss']
})
export class SurveyNewComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input() survey: INewSurvey = {
    title: '',
    description: '',
    questions: [],
    dateEnd: null,
    ownerId: null,
    published: false,
    type: SurveyType.none
  };

  draftButtonText = 'Сохранить как черновик';

  SurveyQuestionType = SurveyQuestionType;
  SurveyQuestionOptionType = SurveyQuestionOptionType;

  sending: boolean;
  isAlreadyExists: boolean;
  showScope = true;

  minDate = new Date();
  loaded = false;

  isTest: boolean;
  placeholderSuffix = 'опроса';
  placeholderPrefix = 'Опрос';

  constructor(
    public usersService: UsersService,
    public surveysService: SurveysService,
    protected alertsService: AlertsService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected breadcrumbsService: BreadcrumbsService,
    protected dialog: MatDialog,
    public helper: HelperService
  ) {
    super(helper);

    this.usersService.currentUser.subscribe(currentUser => {
      if (currentUser) {
        const surveysRoute = `/profile/${currentUser.id}/surveys`;

        this.route.fragment.pipe(take(1)).subscribe(fragment => {
          // для определения типа опроса - "тест"
          this.isTest = fragment && fragment.indexOf('test') !== -1;

          if (this.isTest) {
            this.placeholderSuffix = 'теста';
            this.placeholderPrefix = 'Тест';
          }

          this.breadcrumbsService.breadcrumbs.next([
            { title: 'Опросы', routerUrl: surveysRoute },
            { title: this.isTest ? 'Новый тест' : 'Новый опрос', routerUrl: surveysRoute + '/new' }
          ]);

          this.loaded = true;
        });
      }
    });
  }

  ngOnInit() {
    const surveyToCopy = this.surveysService.copySurvey$.getValue();
    if (!surveyToCopy) {
      this.newBlankQuestion();
    } else {
      this.survey = {
        ownerId: surveyToCopy.ownerId,
        title: surveyToCopy.title,
        description: surveyToCopy.description,
        dateEnd: surveyToCopy.dateEnd,
        published: surveyToCopy.published,
        questions: surveyToCopy.questions,
        type: surveyToCopy.type
      };
      this.surveysService.copySurvey$.next(null);
    }
  }

  /**
   * Отправить данные формы на сервер
   *
   * @memberof SurveyNewComponent
   */
  submit() {
    this.survey.published = true;
    this.send();
  }

  /**
   * Отмена изменений
   *
   * @memberof SurveyNewComponent
   */
  cancel() {
    this.dialog.open(ModalConfirmationComponent, {
      data: {
        text: `Вы действительно хотите уйти с формы?`,
        okText: 'Да, уйти',
        onOk: () => { this.router.navigate([`..`], { relativeTo: this.route }); }
      }
    });
  }

  /**
   * Сохранить как ерновик
   *
   * @memberof SurveyNewComponent
   */
  draft() {
    this.survey.published = false;
    this.send();
  }

  /**
   * Добавить вопрос
   *
   * @memberof SurveyNewComponent
   */
  addQuestion() {
    this.newBlankQuestion();
  }

  addChildQuestion(parentIndex: number) {
    this.newBlankQuestion(parentIndex);
  }

  /**
   * Добавить группу вопросов
   */
  addQuestionGroup() {
    this.newBlankQuestionGroup();
  }

  copyQuestionGroup(index: number) {
    try {
      const sourceQuestion = this.survey.questions[index];
      // копирование
      const copiedQuestion = <ISurveyQuestion>JSON.parse(JSON.stringify(sourceQuestion));
      // необходимо сбросить идентификаторы как у группы, так и у вопросов с вариантами ответов
      copiedQuestion.id = 0;
      copiedQuestion.title += ' _Копия';

      if (copiedQuestion.options) {
        copiedQuestion.options.forEach(option => {
          option.id = 0;
        });
      }

      if (copiedQuestion.questions) {
        copiedQuestion.questions.forEach(child => {
          child.id = 0;
          if (child.options) {
            child.options.forEach(option => {
              option.id = 0;
            });
          }
        });
      }
      this.survey.questions.splice(index + 1, 0, copiedQuestion);
    } catch (e) {
      this.alertsService.riseError('Произошла ошибка при копировании группы вопросов');
    }
  }

  /**
   * Удалить вопрс
   *
   * @param {number} index индекс
   * @memberof SurveyNewComponent
   */
  removeQuestion(index: number, questions: ISurveyQuestion[]) {
    if (questions) {
      questions.splice(index, 1);
    } else {
      this.survey.questions.splice(index, 1);
    }
  }

  /**
   * Получить название типа вопроса
   *
   * @param {SurveyQuestionType} type Тип вопроса
   * @returns
   * @memberof SurveyNewComponent
   */
  getQuestionType(type: SurveyQuestionType): string {
    switch (+type) {
      case SurveyQuestionType.single:
        return 'Единичный выбор';
      case SurveyQuestionType.multiple:
        return 'Множественный выбор';
      case SurveyQuestionType.text:
        return 'Текстовый ответ';
      case SurveyQuestionType.range:
        return 'Оценка';
    }
    console.log(type);
  }

  /**
   * Установить тип вопроса
   *
   * @param {SurveyQuestionType} type тип вопроса
   * @param {ISurveyQuestion} question вопро, для которого необходимо поменять тип
   * @memberof SurveyNewComponent
   */
  setQuestionType(type: SurveyQuestionType, question: ISurveyQuestion) {
    question.type = Number(type);

    if (question.type === SurveyQuestionType.text) {
      question.options = [{ title: `Текстовый вариант`, type: SurveyQuestionOptionType.other }];
    } else if (question.type === SurveyQuestionType.range) {
      question.options = [{ title: 'Оценка', type: SurveyQuestionOptionType.range }];
      question.meta = { min: 0, max: 10 };
    }
  }

  addOption(options: ISurveyQuestionOption[]) {
    this.newBlankOption(options, SurveyQuestionOptionType.normal);
  }

  addOtherOption(options: ISurveyQuestionOption[]) {
    this.newBlankOption(options, SurveyQuestionOptionType.other);
  }

  hasOtherOption(options: ISurveyQuestionOption[]) {
    return options.filter(option => option.type === SurveyQuestionOptionType.other).length;
  }

  removeOption(index: number, data: any, questions: ISurveyQuestion[]) {
    data.options.splice(index, 1);
    if (!data.options.length) {
      this.removeQuestion(data.index, questions);
    }
  }

  onProfileSelected(profile: IIntryProfile) {
    if (!profile || profile.profileType !== ProfileType.Group) {
      this.alertsService.riseError(`Ошибка при выборе группы для создания опроса`);
    } else {
      this.survey.ownerId = profile.profile.id;
    }
  }

  /**
   * Форма валидна
   *
   * @returns {boolean}
   * @memberof SurveyNewComponent
   */
  isValid(): boolean {
    return this.survey
      && this.survey.title
      && this.survey.ownerId
      && this.survey.questions
      && this.survey.questions.length
      && this.survey.questions.every(s => !!s.title)
      && this.survey.questions.every(s => !s.meta
        || s.meta && (<ISurveyQuestionRangeOptionValue>s.meta).max > (<ISurveyQuestionRangeOptionValue>s.meta).min)
      && this.survey.questions.every(s => s.options && !!s.options.length || s.isGroup)
      && this.survey.questions.every(s => s.isGroup || s.options.every(o => !!o.title || o.type === SurveyQuestionOptionType.other))
      && this.survey.questions.every(s => !s.questions || s.questions
        .every(c => c.options && !!c.options.length))
      && this.survey.questions.every(s => !s.questions || s.questions
        .every(c => c.options.every(o => !!o.title || o.type === SurveyQuestionOptionType.other)));
  }

  drop(event: CdkDragDrop<string[]>, questions: ISurveyQuestion[]) {
    moveItemInArray(questions, event.previousIndex, event.currentIndex);
    // обновление индексов
    questions.forEach(question => {
      question.order = questions.indexOf(question) + 1;
    });
  }

  moved($event: CdkDragMove<any>) {
    // $event.source.dropContainer;
  }

  allowEditSurvey() {
    return !!this.survey;
  }

  allowEditQuestion(question: ISurveyQuestion) {
    return !!this.survey;
  }

  /**
   * Запрет ввода букв и символов в поле календаря
   *
   * Коды:
   * 46 — delete, 8 — backspace, 190 - точка, 48-57 — цифры, 96-105 — numpad, 35-40 — home, end, стрелки
   *
   * @param event
   *
   * @memberof SurveyNewComponent
   */
  onCalendarKeydown(event: KeyboardEvent): void {
    const { keyCode, key } = event;
    const notRemoveKey = keyCode !== 46 && keyCode !== 8;
    const notDotKey = key !== '.';
    const notDigit = keyCode < 48 || keyCode > 57;
    const notNumpad = keyCode < 96 || keyCode > 105;
    const notArrows = keyCode < 35 || keyCode > 40;

    if (notRemoveKey && notDotKey && notDigit && notNumpad && notArrows) {
      event.preventDefault();
    }
  }

  /**
   * Отправить данные формы на сервер
   *
   * @private
   * @memberof SurveyNewComponent
   */
  protected send() {
    this.sending = true;
    this.isAlreadyExists = false;
    const today = new Date();

    this.survey.type = this.isTest ? SurveyType.test : SurveyType.survey;

    this.survey.questions.forEach(question => {
      question.order = this.survey.questions.indexOf(question) + 1;
      if (question.questions) {
        question.questions.forEach(childQuestion => {
          childQuestion.order = question.questions.indexOf(childQuestion) + 1;
        });
      }
    });

    this.surveysService.create(this.survey).pipe(take(1)).subscribe(res => {
      this.sending = false;
      if (this.survey.published) {
        this.alertsService.riseSuccess(`Опрос "${this.survey.title}" был успешно создан`);
        this.router.navigate([`../${res.id}`], { relativeTo: this.route });
      } else {
        this.router.navigate([`../${res.id}/edit`], { relativeTo: this.route });
        this.alertsService.riseSuccess(`Опрос "${this.survey.title}" был успешно сохранён как черновик`);
      }
      // отправляем создание в сервис
      this.surveysService.surveyAdded$.next();
    }, error => {
      this.sending = false;
      this.alertsService.riseError(`Произошла ошибка при создании опроса '${this.survey.title}'`);
      if (error && error.error && error.error.ErrorCode === 13000) {
        this.isAlreadyExists = true;
      }
    });
  }

  private newBlankQuestionGroup() {
    let order = 1;
    if (this.survey.questions.length) {
      order = this.survey.questions.length + 1;
    }

    const questionGroup: ISurveyQuestion = {
      title: '',
      isGroup: true,
      order: order,
      required: false,
      questions: [
        {
          title: '',
          type: SurveyQuestionType.single,
          options: [
            {
              title: '',
              type: SurveyQuestionOptionType.normal,
            },
            {
              title: '',
              type: SurveyQuestionOptionType.normal
            }],
          order: order,
          required: false
        }
      ],
      options: [
        {
          title: 'Группа вопросов',
          type: SurveyQuestionOptionType.none,
        }]
    };
    this.survey.questions.push(questionGroup);
  }

  private newBlankQuestion(parentIndex: number = null) {
    const question: ISurveyQuestion = {
      title: '',
      type: SurveyQuestionType.single,
      options: [
        {
          title: '',
          type: SurveyQuestionOptionType.normal,
        },
        {
          title: '',
          type: SurveyQuestionOptionType.normal
        }],
      required: false
    };

    let order = 1;

    if (parentIndex != null && parentIndex !== undefined) {
      const parentQuestion = this.survey.questions[parentIndex];
      if (parentQuestion) {
        if (!parentQuestion.questions) {
          parentQuestion.questions = [];
        }
        if (parentQuestion.questions.length) {
          order = parentQuestion.questions.length + 1;
        }
        question.parentId = parentQuestion.id;
        parentQuestion.questions.push(question);
      }
    } else {
      if (this.survey.questions.length) {
        order = this.survey.questions.length + 1;
      }
      this.survey.questions.push(question);
    }
  }

  private newBlankOption(options: ISurveyQuestionOption[], type: SurveyQuestionOptionType) {
    if (type === SurveyQuestionOptionType.other
      && options.filter(option => option.type === SurveyQuestionOptionType.other).length) {
      return;
    }

    const newOption: ISurveyQuestionOption = {
      title: '',
      type: type
    };

    if (!options.find(option => option.type === SurveyQuestionOptionType.other)) {
      options.push(newOption);
    } else {
      options.splice(options.length - 1, 0, newOption);
    }
  }

  checkboxSelected(option: ISurveyQuestionOption) {
    option.isCorrect = !option.isCorrect;
  }

  radioSelected(options: ISurveyQuestionOption[], option: ISurveyQuestionOption) {
    options.forEach(item => {
      item.isCorrect = false;
    });
    option.isCorrect = true;
  }
}
