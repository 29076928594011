<div class="cos__tabs">
  <div class="tabs _inline" *ngIf="tabs">
    <a href="javascript:;" class="tabs__item" *ngFor="let tab of tabs.items; let last=last"
      [ngClass]="{'_active':isActive(tab) || tab?.id==activeTab?.id}" appIslast [isLast]="last"
      (lastDone)="checkIsAnyActive()" [routerLink]="tabs.routerLink" [fragment]="getFragment(tab)"
      (click)="navigate(tab)">
      <span class="header-text _low">
        <span class="header-text__text">{{tab.title}}
          <span class="badge"
            *ngIf="!hideCounters && !tabs.hideCounters && !tab.hideCounter && tab.asBadge">{{tab.itemsCount}}</span>
        </span>&nbsp;
        <span class="header-text__count"
          *ngIf="!hideCounters && !tabs.hideCounters && !tab.hideCounter && !tab.asBadge">{{tab.itemsCount}}</span>
      </span>
    </a>
  </div>
  <div class="calendar-switch">
    <button type="button" class="btn calendar-switch__prev" (click)="prevClick()"></button>
    <span class="calendar-switch__current">
      <ng-container *ngIf="viewType===CalendarViewType.month">
        <span class="calendar-switch__month">{{monthName}}</span>
        <span class="calendar-switch__year">{{year}}</span>
      </ng-container>
      <ng-container *ngIf="viewType===CalendarViewType.day">
        <span class="calendar-switch__month">{{dateText}}</span>
      </ng-container>
    </span>
    <button type="button" class="btn calendar-switch__next" (click)="nextClick()"></button>
  </div>
</div>