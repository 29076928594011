import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerWidgetComponent } from './components/banner-widget/banner-widget.component';
import { BannerSettingsComponent } from './components/banner-settings/banner-settings.component';
import { BannersApiService } from './services/banners-api.service';
import { BannersService } from './services/banners.service';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { BannerTypeMenuComponent } from './components/banner-types/banner-type-menu/banner-type-menu.component';
import { BannerTypeNewComponent } from './components/banner-types/banner-type-new/banner-type-new.component';
import { BannerTypeEditComponent } from './components/banner-types/banner-type-edit/banner-type-edit.component';
import { BannerTypesListComponent } from './components/banner-types/banner-types-list/banner-types-list.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule,
  ],
  declarations: [
    BannerTypeMenuComponent,
    BannerTypeNewComponent,
    BannerTypeEditComponent,
    BannerTypesListComponent,
    BannerWidgetComponent,
    BannerSettingsComponent,
  ],
  exports: [
    BannerTypeMenuComponent,
    BannerTypeNewComponent,
    BannerTypeEditComponent,
    BannerTypesListComponent,
    BannerWidgetComponent,
    BannerSettingsComponent,
  ],
  providers: [
    BannersService,
    BannersApiService,
  ]
})
export class BannersModule { }
