<div class="cos">

  <!-- Header -->
  <app-user-header></app-user-header>
  <!-- Header END-->

  <!-- header preloader -->
  <app-tab-preloader *ngIf="!loaded"></app-tab-preloader>
  <!-- header preloader END -->

  <!-- Tabs -->
  <div class="cos__header" *ngIf="loaded">
    <app-tabs [tabs]="tabs"></app-tabs>
    <a [routerLink]="newLink" *ngIf="showAddButton" class="btn btn-plus pull-right">+</a>
  </div>
  <!-- Tabs END-->

</div>

<ng-container *ngIf="loaded && currentTab && !currentTab?.loading && currentTab?.items?.length">
  <ng-container *ngIf="currentTab.id==1">
    <app-media-last-list></app-media-last-list>
  </ng-container>
  <ng-container *ngIf="currentTab.id==2">
    <app-album-list></app-album-list>
    <app-media-list [title]="'Все медиафайлы'" [limit]="10" [all]="true"></app-media-list>
  </ng-container>
  <div class="co" *ngIf="currentTab?.loading">
    <mat-spinner [diameter]="56"></mat-spinner>
  </div>
</ng-container>

<!-- Empty tab -->
<div class="cos" *ngIf="!currentTab?.loading && loaded && currentTab?.loaded && (!currentTab?.items || !currentTab?.items.length)">
  <div class="cos__body">
    <div class="card">
      <div class="group-empty">
        <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" class="group-empty__img">
        <div class="group-empty__text">{{currentTab?.emptyText}}</div>
      </div>
    </div>
  </div>
</div>
<!-- Empty tab END-->

<!-- header preloader -->
<app-tabbed-list-preloader *ngIf="!currentTab?.loaded"></app-tabbed-list-preloader>
<!--  header preloader END -->
