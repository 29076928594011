import { map, take } from 'rxjs/operators';
import { Component, Input } from '@angular/core';
import { AttachmentViewModel } from '@app/attachments/file-attachments/model/attachment-view-model.model';
import { FilesService } from '@app/files/services/files.service';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';

/**
 * Меню элемента вложения
 *
 * @export
 * @class AttachmentMenuComponent
 * @extends {BaseComponent}
 */
@Component({
  selector: 'app-attachment-menu',
  templateUrl: './attachment-menu.component.html'
})
export class AttachmentMenuComponent extends BaseComponent {

  /**
   * Вложение
   *
   * @type {AttachmentViewModel}
   * @memberof AttachmentMenuComponent
   */
  @Input() attachment: AttachmentViewModel;

  /**
   * Ссылка на элемент (для копирования в буфер)
   *
   * @type {string}
   * @memberof AttachmentMenuComponent
   */
  @Input() itemLink: string;

  constructor(
    public helper: HelperService,
    public filesService: FilesService) { super(helper); }

  /**
   * Скачивание элемента
   *
   * @memberof AttachmentMenuComponent
   */
  getItem() {
    let index = -1;
    // if doc is in feed attachments
    index = this.attachment.attachment.url.indexOf('feedattachments');
    // if doc is in comment attachments
    if (index === -1) {
      index = this.attachment.attachment.url.indexOf('commentattachments');
    }
    // if selecting doc
    if (index === -1) {
      index = this.attachment.attachment.url.indexOf('/Documents/');
    }

    if (index !== -1) {
      // get web url
      const webUrl = this.attachment.attachment.url.substr(0, index);
      // get and update attachment
      this.filesService.webApiGetFileContent(webUrl, this.attachment.attachment.url)
        .pipe(take(1))
        .subscribe(blobContent => {
          const urlCreator = window.URL;
          const url = urlCreator.createObjectURL(blobContent);
          this.getItemByAbsUrl(url, this.attachment.attachment.title);
        });
    }
  }

  /**
   * Скачивание элемента по абсолютной ссылке
   *
   * @param {string} url
   * @param {string} fileName
   * @memberof AttachmentMenuComponent
   */
  getItemByAbsUrl(url: string, fileName: string) {
    const a = document.createElement('a');
    a.href = url; // xhr.response is a blob
    a.download = fileName; // Set the file name.
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
  }

  /**
   * Копировать в буфер обмена
   *
   * @memberof AttachmentMenuComponent
   */
  copyToClipboard() {
    const elem = <any>document.getElementById(this.itemLink);
    this.filesService.copyToClipboard(elem.href);
  }

  /**
   * Получить название приложения для открытия офисного файла
   *
   * @param {*} item аттачмент
   * @returns
   * @memberof AttachmentMenuComponent
   */
  getOfficeOpeningName(item: any) {
    return this.filesService.getOfficeOpeningName(item);
  }

  /**
   * Код открытия файлов
   * todo: проверить в MAC-OS
   * @param {*} item
   * @returns
   * @memberof AttachmentMenuComponent
   */
  openInClientApp(item: any) {
    const elem = <any>document.getElementById(this.itemLink);
    return this.filesService.openInClientAppByAbsUrl(item, elem.href);
  }
}
