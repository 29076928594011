import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HelperService } from '@app/core/services/helper.service';
import { GamificationApiService } from '@app/gamification/services/gamification-api.service';
import { IGift } from '@app/gifts/model/gift';
import { GiftService } from '@app/gifts/services/gift.service';
import { UsersService } from '@app/profile/services/users.service';
import { TabbedListComponent } from '@app/shared/components/tabs/tabbed-list.component';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { finalize, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-gift-list',
  templateUrl: './gift-list.component.html',
  styleUrls: ['./gift-list.component.scss']
})
export class GiftListComponent extends TabbedListComponent<IGift> implements OnInit, OnDestroy {

  margin = 200;
  isAdmin = false;

  constructor(
    public giftService: GiftService,
    public gamificationService: GamificationApiService,
    protected usersService: UsersService,
    protected router: Router,
    protected route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService,
    public helper: HelperService
  ) { super(router, route, helper); }

  ngOnInit(): void {

    // хлебные крошки
    this.breadcrumbsService.breadcrumbs.next([
      { title: 'Магазин сувениров', routerUrl: `/profile/gifts` }
    ]);

    this.tabs = {
      title: 'Магазин подарков',
      routerLink: `/profile/gifts`,
      hideCounters: true,
      items: []
    };

    this.usersService.currentUser.subscribe(currentUser => {
      if (currentUser) {

        this.isAdmin = currentUser.isAdmin;

        this.tabs.items = [
          {
            id: 1,
            title: 'Каталог',
            fragment: 'tile',
            items: [],
            offset: 0,
            itemsCount: 0,
            default: true,
            method: this.getTiles.bind(this),
            emptyText: 'Нет доступных подарков'
          },
        ];

        if (currentUser.isAdmin) {
          this.tabs.items.push({
            id: 2,
            title: 'Администрирование',
            fragment: 'admin',
            items: [],
            offset: 0,
            itemsCount: 0,
            method: this.getList.bind(this),
            emptyText: 'Ещё ничего не добавлено'
          });
        }

        this.route.fragment.pipe(takeUntil(this.destroyed$)).subscribe(val => {

          this.mobHeight = window.innerHeight;
          this.setNewLimit();

          // get hash
          if (val != null) {
            const keys = val.split('&');
            const hash = {};
            keys.forEach(key => {
              // tslint:disable-next-line:no-shadowed-variable
              const val = key.split('=');
              hash[val[0]] = val[1];
            });

            this.currentTab = null;

            this.tabs.items.forEach(tab => {
              if (tab.fragment && keys.find(k => k === tab.fragment)) {
                this.currentTab = tab;
              }
            });

            if (!this.currentTab) {
              this.currentTab = this.tabs.items[0];
            }
          } else {
            this.currentTab = this.tabs.items[0];
          }

          this.cleanTab(this.currentTab);
          this.currentTab.method(this.currentTab, this);
        });
      }
    });
  }

  navigate(id: number) {
    this.router.navigate([id], { relativeTo: this.route });
  }

  trackByFunc(index, item) {
    return item.id;
  }

  private getTiles() {
    this.maxItemHeight = 80;
    this.getItems(false);
  }

  private getList() {
    this.maxItemHeight = 280;
    this.getItems(true);
  }

  private getItems(showHidden: boolean) {
    this.giftService.getAll(showHidden, this.currentTab.offset, this.limit)
    .pipe(
      finalize(() => { this.loaded = true; this.currentTab.loaded = true; }),
      takeUntil(this.destroyed$))
    .subscribe(items => {
      if (!this.currentTab.items) {
        this.currentTab.items = [];
      }

      if (items && items.length) {
        items.forEach(item => {
          if (!this.currentTab.items.find(s => s.id === item.id)) {
            this.currentTab.items.push(item);
          }
        });
        this.currentTab.offset = this.currentTab.items.length;
      } else {
        this.currentTab.allLoaded = true;
      }
    });
  }

  /**
   * Действие при прокрутке страницы.
   * Загружаем остальные элементы, учитывая offset и limit
   */
  protected onScroll() {
    const number = window.innerHeight + window.pageYOffset + this.margin;
    if (this.currentTab && this.currentTab.items && number > this.maxItemHeight * this.currentTab.items.length) {
      if (!this.currentTab.loading) {
        if (!this.currentTab.allLoaded) {
          this.currentTab.method(this.currentTab, this);
        }
      }
    }
  }
}
