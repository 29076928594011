import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { CoreModule } from '@app/core/core.module';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { RouterModule, Routes } from '@angular/router';
import { ProfileViewComponent } from '@app/profile/components/profile/profile-view/profile-view.component';
import { FormsModule } from '@angular/forms';

const routes: Routes = [
  {
    path: 'feedback', component: ProfileViewComponent, children: [
      { path: '', component: FeedbackComponent }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    CoreModule,
    SharedModule
  ],
  declarations: [FeedbackComponent],
})
export class FeedbackModule { }
