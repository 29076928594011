import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { environment } from '@env/environment';
import { HelperService } from '@app/core/services/helper.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '@app/profile/model/user.model';
import { IThank } from '../model/thank';

@Injectable({
  providedIn: 'root'
})
export class ThanksApiService extends BaseService {
  protected serviceUrl = `${environment.siteUrl}/api/thank`;

  constructor(
    protected http: HttpClient,
    public helper: HelperService) { super(http, helper); }

  getAll(offset: number, limit: number): Observable<IThank[]> {
    return super.get(`?offset=${offset}&count=${limit}`);
  }

  getThanksCount(): Observable<number> {
    return super.get(`thanksCount`);
  }

  getById(id: number): Observable<IThank> {
    return super.get(`${id}`);
  }

  getUserReceivedThanks(userId: number, offset: number, limit: number): Observable<IThank[]> {
    return super.get(`user/${userId}/received?offset=${offset}&limit=${limit}`);
  }

  getUserSentThanks(userId: number, offset: number, limit: number): Observable<IThank[]> {
    return super.get(`user/${userId}/sent?offset=${offset}&limit=${limit}`);
  }

  getUserReceivedThanksCount(userId: number): Observable<number> {
    return super.get(`user/${userId}/receivedCount`);
  }

  getUserSentThanksCount(userId: number): Observable<number> {
    return super.get(`user/${userId}/sentCount`);
  }

  thank(userId: number, body: string): Observable<boolean> {
    return super.post('', { userId, body });
  }
}
