import { Component, OnInit, Input, OnDestroy, Renderer2 } from '@angular/core';
import { FilesListComponent } from '@app/files/components/files-list/files-list.component';
import { UsersService } from '@app/profile/services/users.service';
import { FilesService } from '@app/files/services/files.service';
import { User } from '@app/profile/model/user.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, BehaviorSubject } from 'rxjs';
import { Tab } from '@app/shared/components/tabs/tab.model';
import { Helper } from '@app/core/helpers/helper';
import { FilesList } from '@app/files/model/files-list-item.model';
import { PlatformLocation } from '@angular/common';
import { takeUntil, take } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { FilesDialogService } from '@app/files/services/files-dialog.service';

/**
 * Файлы пользователя
 */
@Component({
    selector: 'app-user-files-list',
    templateUrl: './user-files-list.component.html',
    styleUrls: ['./user-files-list.component.css']
})
export class UserFilesListComponent extends FilesListComponent implements OnInit, OnDestroy {
    @Input() user: User;
    personalSiteUrl: Subject<string> = new BehaviorSubject<string>(null);

    filestListType = 1;

    constructor(
        protected usersService: UsersService,
        public filesService: FilesService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected filesDialog: FilesDialogService,
        protected location: PlatformLocation,
        protected renderer: Renderer2,
        public helper: HelperService
    ) {
        super(usersService, filesService, router, route, filesDialog, renderer, helper);
    }

    ngOnInit() {
        // todo: разобраться с этим и исправить
        this.location.onPopState(() => {
            this.usersService.backPressed = true;
        });

        // init current component
        this.usersService.currentUser.subscribe(currentUser => {
            this.usersService.user.pipe(takeUntil(this.destroyed$)).subscribe(user => {
                if (user
                    && (this.user !== undefined && this.usersService.backPressed || !this.usersService.backPressed)
                    && this.user !== user) {

                    this.user = user;
                    this.usersService.backPressed = false;

                    this.currentUser = currentUser;
                    this.showAddButton = currentUser.id === user.id;

                    this.filesService.ownerId$.next(this.user.id);

                    this.tabs = {
                        title: 'Файлы',
                        routerLink: `/profile/${user.id}/files`,
                        items: []
                    };

                    if (this.showAddButton) {

                        this.tabs.items.push({
                            id: 1,
                            title: 'Последние',
                            fragment: 'recent',
                            name: 'recent',
                            items: [],
                            offset: 0,
                            itemsCount: 0,
                            method: this.getRecent.bind(this)
                        });

                        this.tabs.items.push({
                            id: 2,
                            title: 'Файлы',
                            fragment: '',
                            name: 'files',
                            items: [],
                            offset: 0,
                            itemsCount: 0,
                            method: this.getFiles.bind(this),
                            default: true
                        });

                        this.tabs.items.push({
                            id: 3,
                            title: 'Общие',
                            fragment: 'shared',
                            name: 'shared',
                            items: [],
                            offset: 0,
                            itemsCount: 0,
                            method: this.getShared.bind(this),
                            default: this.showAddButton ? false : true
                        });

                    }

                    if (!this.showAddButton) {
                        this.tabs.items.push({
                            id: 1,
                            title: 'Общие',
                            fragment: 'shared',
                            name: 'shared',
                            items: [],
                            offset: 0,
                            itemsCount: 0,
                            method: this.getSharedWithPerson.bind(this),
                            default: true
                        });
                        this.tabs.items.push({
                            id: 2,
                            title: 'Файлы',
                            fragment: 'files',
                            name: 'files',
                            items: [],
                            offset: 0,
                            itemsCount: 0,
                            method: this.getFiles.bind(this),
                            default: false
                        });
                    }

                    super.ngOnInit();
                } else if (this.usersService.backPressed) {
                    this.usersService.backPressed = false;
                }
            });
        });
    }

    /**
     * Данные для вкладки "Файлы" в профиле другого пользователя
     */
    getSharedWithPerson(tab: Tab<any>) {
        this.error = '';

        const ownerId = this.filesService.ownerId$.getValue();

        if (ownerId) {
            tab.loading = true;
            this.filesService.getSharedWithPersonV2(ownerId, tab.offset, tab.limit).pipe(take(1)).subscribe(driveRes => {
                tab.loading = false;
                this.operateSharedWebApiResult(driveRes, tab);
            }, error => {
                this.error = error;
                this.loaded = tab.loaded = true;
                tab.loading = false;
            });
        } else {
            this.loaded = tab.loaded = true;
            tab.loading = false;
        }
    }

    /**
     * Данные для вкладки "Общие"
     */
    getShared(tab: Tab<any>) {
        this.error = '';

        const ownerId = this.filesService.ownerId$.getValue();
        if (ownerId) {
            tab.loading = true;
            this.filesService.getSharedWithMeV2(ownerId, tab.offset, tab.limit).pipe(take(1)).subscribe(driveRes => {
                tab.loading = false;
                this.operateSharedWebApiResult(driveRes, tab);
            }, error => {
                this.error = error;
                this.loaded = tab.loaded = true;
                tab.loading = false;
            });
        } else {
            // personal url is null
            this.loaded = tab.loaded = true;
            tab.loading = false;
        }
    }

    operateSharedWebApiResult(filesList: FilesList, tab: Tab<any>) {
        if (filesList) {
            if (filesList.items && filesList.items.length) {
                filesList.items.forEach(rowItem => {
                    // tslint:disable-next-line:triple-equals
                    if (!this.filesService.isFolder(rowItem) && !tab.items.find(item => item.uniqueId == rowItem.uniqueId)) {
                        tab.items.push(rowItem);
                        rowItem.checked.subscribe(res => {
                            if (res !== null && !this.skipItemsCheck) {
                                this.setAllChecked();
                            }
                        });
                    }
                });

                if (filesList.firstRow + this.limit <= filesList.lastRow + 1) {
                    tab.itemsCount = filesList.lastRow + 1;
                } else {
                    tab.itemsCount = filesList.lastRow;
                }
            }
        }
        tab.loaded = true;
        this.loaded = true;
    }

    /**
     * Данные для вкладки "Последние"
     */
    getRecent(tab: Tab<any>) {
        this.error = '';

        const ownerId = this.filesService.ownerId$.getValue();

        if (ownerId) {
            this.filesService.getRecentListData(ownerId, tab.offset, tab.limit).subscribe(filesList => {
                this.operateApiResultV2(filesList, tab);
                this.calculateShowAddButton();
                this.error = '';
                tab.loading = false;
            }, error => {
                this.error = error;
                this.loaded = tab.loaded = true;
                tab.loading = false;
            });
        }

        // this.usersService.currentUser.subscribe(currentUser => {
        //     const webUrl = this.webUrl.getValue();

        //     if (webUrl) {
        //         tab.loading = true;
        //         if (this.user.id === currentUser.id) {
        //             if (!this.sortField) {
        //                 this.sortField = 'LastModifiedTime';
        //             }
        //             this.filesService.webApiGetRecent(webUrl, tab.offset, this.limit, this.sortField, this.sort)
        //                 .pipe(take(1))
        //                 .subscribe(filesList => {
        //                     filesList.items.forEach(item => {
        //                         // tslint:disable-next-line:triple-equals
        //                         if (!tab.items.find(i => i.uniqueId == item.uniqueId)) {
        //                             tab.items.push(item);
        //                             item.checked.subscribe(res => {
        //                                 if (res !== null && !this.skipItemsCheck) {
        //                                     this.setAllChecked();
        //                                 }
        //                             });
        //                         }
        //                     });
        //                     tab.itemsCount = filesList.totalItems;
        //                     tab.offset = tab.items.length;
        //                     this.loaded = tab.loaded = true;
        //                     tab.loading = false;
        //                 }, error => {
        //                     this.error = error;
        //                     this.loaded = tab.loaded = true;
        //                     tab.loading = false;
        //                 });
        //         }
        //     } else {
        //         // personal url is null
        //         this.error = 'Personal url is null';
        //         this.loaded = tab.loaded = true;
        //         tab.loading = false;
        //     }
        // });
    }
}
