import { Component } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { Location } from '@angular/common';
import { HelperService } from '@app/core/services/helper.service';

/**
 * Компонент для ссылки назад
 *
 * @export
 * @class BackLinkComponent
 * @extends {BaseComponent}
 */
@Component({
  selector: 'app-back-link',
  templateUrl: 'back-link.component.html'
})

export class BackLinkComponent extends BaseComponent {
  constructor(
    private location: Location,
    public helper: HelperService) {
    super(helper);
  }

  /**
   * Клик по ссылке открывает предыдущую страницу из истории
   *
   * @returns undefined
   * @memberof BackLinkComponent
   */
  goBack() {
    this.location.back();
  }
}
