import { Component, Inject } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertsService } from '@app/shared/services/alerts.service';
import { UsersService } from '@app/profile/services/users.service';
import { GalleryService } from '@app/gallery/services/gallery.service';
import { IMediaFile, IUpdateMediaFileModel, MediaFileType } from '@app/gallery/model/media-file';
import { take } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';

/**
 * Всплывающее окно редактирования медиа-файла
 *
 * @export
 * @class MediaFileEditComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-media-file-edit',
  templateUrl: './media-file-edit.component.html',
  styleUrls: ['./media-file-edit.component.scss']
})
export class MediaFileEditComponent extends BaseComponent {

  /**
   * Медиа-файл для редактирования
   *
   * @type {IMediaFile}
   * @memberof MediaFileEditComponent
   */
  mediaFile: IMediaFile;

  /**
   * Новое название
   *
   * @type {string}
   * @memberof MediaFileEditComponent
   */
  newName: string;

  /**
   * Новое описание
   *
   * @type {string}
   * @memberof MediaFileEditComponent
   */
  newDescription: string;

  isValid: boolean;

  mediaFileType = MediaFileType;

  previewFile: File;
  videoPreviewUrl: string | ArrayBuffer;

  constructor(
    public dialogRef: MatDialogRef<MediaFileEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected alertsService: AlertsService,
    public usersService: UsersService,
    protected galleryService: GalleryService,
    public helper: HelperService) {
    super(helper);
    this.mediaFile = this.data.item;
    this.newName = this.mediaFile.title;
    this.newDescription = this.mediaFile.description;

    if (this.data.item) {
      this.videoPreviewUrl = this.galleryService.getMediaFilePreviewUrl(this.data.item);
    }
  }

  /**
   * Сохранить изменения
   *
   * @memberof MediaFileEditComponent
   */
  save() {
    if (this.isValid) {
      const mediaFile: IUpdateMediaFileModel = {
        title: this.newName,
        description: this.newDescription,
        mediaFileId: this.mediaFile.id
      };
      this.galleryService.editMediaFile(mediaFile)
        .pipe(take(1))
        .subscribe(res => {
          if (res) {
            this.mediaFile.title = res.title;
            this.mediaFile.description = res.description;
            this.alertsService.riseSuccess(`Успешно обновлён медиа-файл ${this.mediaFile.title}`);
          } else {
            this.alertsService.riseError(`Ошибка во время обновления медиа-файла ${this.mediaFile.title}.`);
          }
          this.dialogRef.close();
        }, error => {
          this.dialogRef.close();
          console.log(error);
          this.alertsService.riseError(`Ошибка во время обновления медиа-файла ${this.mediaFile.title}. Обратитесь к администратору.`);
        });
    } else {
      this.validate();
    }
  }

  /**
   * Валидировать изменения
   *
   * @returns
   * @memberof MediaFileEditComponent
   */
  validate() {
    if (!this.newName) {
      this.isValid = false;
    } else {
      if (this.newDescription !== this.mediaFile.description
        || this.newName !== this.mediaFile.title
        || this.previewFile) {
        this.isValid = true;
      }
    }
    return this.isValid;
  }

  /**
   * При выборе файла
   *
   * @param {*} e
   * @memberof MediaFileEditComponent
   */
  onUpload(e) {
    const file = e.target.files && e.target.files.length
      ? e.target.files[0]
      : null;
    if (file) {
      this.previewFile = file;

      const formData: FormData = new FormData();
      formData.append('file', file, file.name);

      this.galleryService.uploadVideoPreview(this.mediaFile.id, formData)
        .pipe(take(1))
        .subscribe(res => {
          if (res) {
            this.alertsService.riseSuccess(`Успешно добавлено превью для медиа-файла ${this.mediaFile.title}`);
            const that = this;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function (event: ProgressEvent) {
              that.videoPreviewUrl = (<FileReader>event.target).result;
              that.mediaFile.preview = res.preview;
              that.validate();
            };

          } else {
            this.alertsService.riseError(`Ошибка во время добавления превью медиа-файла ${this.mediaFile.title}.`);
          }
        }, error => {
          console.log(error);
          // tslint:disable-next-line:max-line-length
          this.alertsService.riseError(`Ошибка во время добавления превью медиа-файла ${this.mediaFile.title}. Обратитесь к администратору.`);
        });
    } else {
      this.previewFile = null;
    }
  }

  /**
   * Текст количества введённых символов в поле
   *
   * @param string value
   * @param number max
   * @memberof MediaFileEditComponent
   */
  getMediaFileLengthText(value: string, max: number): string {
    const current: number = value ? value.length : 0;
    return `${current} из ${max}`;
  }

  getMediaFileTypeText(m: IMediaFile): string {
    if (!m) {
      return '';
    }
    switch (m.type) {
      case MediaFileType.image:
        return 'фотографии';
      case MediaFileType.video:
        return 'видео';
      default:
        return '';
    }
  }
}
