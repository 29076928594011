import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from 'intry-package';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { VacancyMenuComponent } from './components/menu/vacancy-menu/vacancy-menu.component';
import { VacancyWidgetComponent } from './components/vacancy-widget/vacancy-widget.component';
import { VacancyListViewComponent } from './components/vacancy-list-view/vacancy-list-view.component';
import { VacancyNewComponent } from './components/forms/vacancy-new/vacancy-new.component';
import { VacancyViewComponent } from './components/forms/vacancy-view/vacancy-view.component';
import { VacancyEditComponent } from './components/forms/vacancy-edit/vacancy-edit.component';
import { VacancyApiService } from './services/vacancy-api.service';
import { VacancyService } from './services/vacancy.service';
import { VacancyListComponent } from './components/vacancy-list/vacancy-list.component';
import { ResponseListComponent } from './components/response-list/response-list.component';
import { ResponseViewComponent } from './components/forms/response-view/response-view.component';
import { ResponseMenuComponent } from './components/menu/response-menu/response-menu.component';
import { VacancyFiltersComponent } from './components/vacancy-filters/vacancy-filters.component';
import { GroupVacancyNewComponent } from './components/forms/vacancy-new/group-vacancy-new.component';
import { AttachmentsModule } from '@app/attachments/file-attachments/attachments.module';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    FormsModule,
    AttachmentsModule,
    RouterModule,
  ],
  declarations: [
    VacancyMenuComponent,
    VacancyWidgetComponent,
    VacancyListViewComponent,
    VacancyNewComponent,
    VacancyViewComponent,
    VacancyEditComponent,
    VacancyListComponent,
    ResponseListComponent,
    ResponseViewComponent,
    ResponseMenuComponent,
    VacancyFiltersComponent,
    GroupVacancyNewComponent,
  ],
  exports: [
    VacancyListViewComponent,
    VacancyMenuComponent,
    VacancyFiltersComponent,
    GroupVacancyNewComponent,
  ],
  providers: [
    VacancyApiService,
    VacancyService
  ]
})
export class VacancyModule { }
