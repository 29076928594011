<div class="cos">

  <!-- Header -->
  <app-group-small-header></app-group-small-header>
  <!-- Header END-->

  <div class="new-gallery">
    <div class="input-main new-gallery__title">
      <textarea rows="1" placeholder="Введите название альбома *" [(ngModel)]="albumName" maxlength="255"
        class="input-main__field" (keyup)="onKey($event)" (focus)="albumNameFocused=true" (blur)="albumNameFocused=false"></textarea>
      <div class="form__note" *ngIf="albumNameFocused">{{albumName?.length || 0}} из 255</div>
    </div>
    <div class="error-message" *ngIf="isAlreadyExists">Альбом с таким названием уже существует</div>

    <div class="input-main new-gallery__about">
      <textarea autosize rows="3" placeholder="Введите описание альбома (необязательно)"
        [(ngModel)]="albumDescription" class="input-main__field"></textarea>
    </div>

    <div class="new-gallery__actions">
      <button [ngClass]="{_inactive:creating}" type="button" class="btn btn-secondary group__cancel"
        (click)="cancel()">Отмена</button>
      <button [ngClass]="{_inactive:!isValid || creating}" type="button" class="btn btn-primary group__create"
        (click)="create()">Создать</button>
    </div>
  </div>
</div>

<div class="card">
  <div class="group-empty"><img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" class="group-empty__img">
    <div class="group-empty__text">После создания альбома вы <br> сможете загрузить фотографии и видео</div>
  </div>
</div>
