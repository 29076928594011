import { Component, Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { HelperService } from '../services/helper.service';

/**
 * Базовый компонент.
 * От него должны наследоваться все компоненты
 *
 * @export
 * @class BaseComponent
 */
@Directive()
// tslint:disable-next-line:directive-class-suffix
export class BaseComponent implements OnDestroy {
    /**
     * Компонент был загружен
     *
     * @type {boolean}
     * @memberof BaseComponent
     */
    loaded: boolean;

    /**
     * Компонент загружается
     *
     * @type {boolean}
     * @memberof BaseComponent
     */
    loading: boolean;

    /**
     * Ошибка в компоненте
     *
     * @type {string}
     * @memberof BaseComponent
     */
    error: string;

    /**
     * Префикс ссылок на картинки/стили
     *
     * @memberof BaseComponent
     */
    public assetsPrefix = this.helper.environment.assetsPrefix;

    protected destroyed$: Subject<void> = new Subject();

    constructor(public helper: HelperService) { }

    stopPropagation(e: Event) {
        e.stopPropagation();
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.unsubscribe();
        // console.log(this);
    }
}
