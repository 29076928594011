import { Component, Input } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';

@Component({
  selector: 'app-empty-widget-content',
  templateUrl: './empty-widget-content.component.html',
  styleUrls: ['./empty-widget-content.component.scss']
})
export class EmptyWidgetContentComponent extends BaseComponent {
  @Input() text: string;

  constructor(public helper: HelperService) { super(helper); }

}
