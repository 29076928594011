<div class="cos__body hidden-phone">
  <div class="co-group" *ngIf="currentUser?.isAdmin">
    <div class="co">
      <div class="co__avatar"><span data-initials="НП" class="avatar _40 _blue"></span></div>
      <div class="co__info"><span class="link co__name text-upper">Настройки портала</span></div>
    </div>

    <div class="co _link"><a [routerLink]="['settings']" class="link co__link">Общие настройки</a></div>
    <div class="co _link"><a [routerLink]="['pages']" class="link co__link">Главное меню</a></div>
    <div class="co _link"><a [routerLink]="['gamification-types']" class="link co__link">Геймификация</a></div>
    <div class="co _link"><a href="/Lists/FeedbackCategories" target="_blank" class="link co__link">Задать вопрос</a></div>
    <!-- <div class="co _link"><a href="/Shared%20Documents" target="_blank" class="link co__link">Шаблон заявления на отпуск</a></div> -->
  </div>

  <div class="co-group" *ngIf="isVacancyAdmin()">
    <div class="co">
      <div class="co__avatar"><span data-initials="В" class="avatar _40 _green"></span></div>
      <div class="co__info"><span class="link co__name text-upper">Вакансии</span></div>
    </div>

    <div class="co _link"><a [routerLink]="['vacancy-categories']" class="link co__link">Настройка категорий вакансий и интеграции с hh.ru</a></div>
  </div>

  <div class="co-group" *ngIf="currentUser?.isAdmin || currentUser?.isBannerAdmin">
    <div class="co">
      <div class="co__avatar"><span data-initials="Б" class="avatar _40 _peach"></span></div>
      <div class="co__info"><span class="link co__name text-upper">Баннер</span></div>
    </div>

    <div class="co _link"><a [routerLink]="['banner-settings']" class="link co__link">Управление слайдами</a></div>
    <div class="co _link"><a [routerLink]="['banner-types']" class="link co__link">Настройка типов слайдов</a></div>
  </div>

  <div class="co-group" *ngIf="isIdeasAdmin()">
    <div class="co">
      <div class="co__avatar"><span data-initials="И" class="avatar _40 _pink"></span></div>
      <div class="co__info"><span class="link co__name text-upper">Идеи</span></div>
    </div>

    <div class="co _link"><a [routerLink]="['idea-statuses']" class="link co__link">Статусы идей</a></div>
  </div>
</div>
