import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { IPage } from '@app/pages/model/page';
import { IPageMenuConfig } from '@app/pages/components/menu/page-menu/page-menu.component';
import { PagesService } from '@app/pages/services/pages.service';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HelperService } from '@app/core/services/helper.service';
import { takeUntil } from 'rxjs/operators';
import { UsersService } from '@app/profile/services/users.service';
import { SettingsService } from '@app/shared/services/settings.service';

/**
 * Страницы меню портала
 *
 * @export
 * @class MenuPageListComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-menu-page-list',
  templateUrl: './menu-page-list.component.html',
  styleUrls: ['./menu-page-list.component.scss']
})
export class MenuPageListComponent extends BaseComponent {

  pages: IPage[] = [];
  showAddButton: boolean;
  showHidden = false;
  megaMenu: boolean = null;
  config: IPageMenuConfig;

  private pageRoute = `/admin/pages`;

  constructor(
    public usersService: UsersService,
    public pagesService: PagesService,
    public settingsService: SettingsService,
    protected breadcrumbsService: BreadcrumbsService,
    protected router: Router,
    protected route: ActivatedRoute,
    public helper: HelperService
  ) {
    super(helper);

    this.breadcrumbsService.breadcrumbs.next([
      { title: 'Страницы', routerUrl: this.pageRoute }
    ]);

    this.usersService.currentUser.subscribe(currentUser => {
      this.showAddButton = currentUser.isAdmin;

      if (this.showAddButton) {
        this.config = {
          allowEdit: true,
          allowDelete: true
        };
      }

      this.settingsService.getValue('megaMenu')
        .subscribe(res => {
          this.megaMenu = res === 'True';
          this.getItems();
        });
    });
  }

  toggleHidden(e: Event) {
    this.showHidden = !this.showHidden;
    this.getItems();
  }

  toggleMegaMenu(e: Event) {
    this.megaMenu = !this.megaMenu;
    this.settingsService.save('megaMenu', this.megaMenu ? 'True' : 'false').subscribe();
  }

  getLink() {
    return this.pageRoute;
  }

  getItems() {
    this.pagesService.getGroupPages(0, this.showHidden)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        if (res) {
          this.pages = res;
        }
        this.loaded = true;
      }, () => {
        this.loaded = true;
      });
  }
}
