import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAlbum } from '@app/gallery/model/album-model';
import * as moment from 'moment';

export enum AlbumVotingChangeType {
  enable = 1,
  change = 2,
  disable = 3
}

export interface IAlbumVotingComponentData {
  onOk: EventEmitter<IAlbumVotingComponentResult> | Function;
  album: IAlbum;
}

export interface IAlbumVotingComponentResult {
  dateEnd: moment.Moment;
  type: AlbumVotingChangeType;
}

/**
 * Всплывающее окно настройки голосования
 *
 * @export
 * @class AlbumVotingComponent
 */
@Component({
  selector: 'app-album-voting',
  templateUrl: './album-voting.component.html',
  styleUrls: ['./album-voting.component.scss']
})
export class AlbumVotingComponent {

  /**
   * Заголовок окна. Меняется в зависимости от того включено голосование для данного альбома или нет
   *
   * @type {string}
   * @memberof AlbumVotingComponent
   */
  text: string;

  /**
   * Дата окончания голосования
   *
   * @type {Date}
   * @memberof AlbumVotingComponent
   */
  dateEnd: moment.Moment;

  /**
   * Текст ошибки
   *
   * @type {string}
   * @memberof AlbumVotingComponent
   */
  error: string;

  /**
   * Статус загрузки
   *
   * @type {boolean}
   * @memberof AlbumVotingComponent
   */
  loading: boolean;

  minDate = new Date();

  private votingEndDate: moment.Moment;

  constructor(
    public dialogRef: MatDialogRef<AlbumVotingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IAlbumVotingComponentData
  ) {
    if (this.data.album) {
      if (this.data.album.votingEndDate) {
        this.dateEnd = moment(this.data.album.votingEndDate);
      }

      if (this.data.album.votingEndDate) {
        this.votingEndDate = moment(this.data.album.votingEndDate);
      }
    }
  }

  /**
   * Отправить данные формы на сервер
   *
   * @memberof AlbumVotingComponent
   */
  save() {
    this.setVoting(true);
  }

  /**
   * Отключить голосование
   *
   * @memberof AlbumVotingComponent
   */
  disableVoting() {
    this.setVoting(false);
  }

  /**
   * Голосование включено?
   *
   * @returns {boolean}
   * @memberof AlbumVotingComponent
   */
  isVotingEnabled(): boolean {
    if (this.isVotingWasEnabled()) {
      if (this.votingEndDate > moment(new Date())) {
        return true;
      }
    }
    return false;
  }

  isVotingWasEnabled(): boolean {
    return this.data.album && this.data.album.votingEndDate ? true : false;
  }

  /**
   * Валидна ли форма?
   *
   * @returns {boolean}
   * @memberof AlbumVotingComponent
   */
  isValid(): boolean {
    return this.dateEnd != null && this.dateEnd !== undefined;
  }

  /**
   * Включить/отключить голосование. Отправить данные формы.
   *
   * @private
   * @param {boolean} enable
   * @memberof AlbumVotingComponent
   */
  private setVoting(enable: boolean) {
    if (this.data.onOk) {

      const now = new Date();

      if (this.dateEnd) {
        this.dateEnd.set({ hour: 23, minute: 59, second: 59, millisecond: 0 });
      }

      const result: IAlbumVotingComponentResult = { dateEnd: this.dateEnd, type: AlbumVotingChangeType.enable };

      if (enable) {
        if (this.data.album.votingEndDate) {
          if (this.dateEnd <= moment(now)) {
            result.type = AlbumVotingChangeType.disable;
          } else {
            result.type = AlbumVotingChangeType.change;
          }
        } else {
          result.type = AlbumVotingChangeType.enable;
        }
      } else {
        result.type = AlbumVotingChangeType.disable;
        result.dateEnd =  moment(now);
      }

      if (this.data.onOk instanceof EventEmitter) {
        this.data.onOk.emit(result);
      } else {
        this.data.onOk(result);
      }
    }
  }
}
