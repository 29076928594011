import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BannersService } from '@app/banners/services/banners.service';
import { BaseComponent } from '@app/core/components/base.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertsService } from '@app/shared/services/alerts.service';
import { HelperService } from '@app/core/services/helper.service';
import { take } from 'rxjs/operators';
import { IBannerSlideType } from '@app/banners/model/banner';

@Component({
  selector: 'app-banner-type-menu',
  templateUrl: './banner-type-menu.component.html',
  styleUrls: ['./banner-type-menu.component.scss']
})
export class BannerTypeMenuComponent extends BaseComponent {

  @Input() item: IBannerSlideType;
  @Input() selectedItems: IBannerSlideType[];
  @Input() isAdmin: boolean;

  @Output() deleted: EventEmitter<number> = new EventEmitter();

  deletingDialog: MatDialogRef<ModalConfirmationComponent>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private bannersService: BannersService,
    private alertsService: AlertsService,
    protected dialog: MatDialog,
    public helper: HelperService,
  ) { super(helper); }

  showEditItem() {
    this.router.navigate([this.item.id], { relativeTo: this.route });
  }

  showDeleteItem() {
    this.deletingDialog = this.dialog.open(ModalConfirmationComponent, {
      data: {
        text: `Вы действительно хотите удалить тип слайда`,
        onOk: this.deleteItem.bind(this),
        doNotHideOnOk: true
      }
    });
  }

  private deleteItem() {
    const errorText = 'Произошла ошибка при удалении типа слайда.';

    this.bannersService.bannerSlideTypeApiService.remove(this.item.id).pipe(take(1)).subscribe(res => {
      if (res) {
        this.alertsService.riseSuccess(`Удаление типа слайда выполнено успешно`);
        this.deleted.emit(this.item.id);
        this.deletingDialog.close();
      } else {
        this.alertsService.riseError(errorText);
      }
    }, error => {
      console.log(`${errorText}: '${error}'`);
      this.alertsService.riseError(errorText);
      this.deletingDialog.componentInstance.data.error = errorText;
    });
  }
}
