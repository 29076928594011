import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { environment } from '@env/environment';
import { HelperService } from '@app/core/services/helper.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ITag, TagType } from '../model/tag';

@Injectable({
  providedIn: 'root'
})
export class TagService extends BaseService {
  protected serviceUrl = `${environment.siteUrl}/api/tag`;

  constructor(
    protected http: HttpClient,
    public helper: HelperService) { super(http, helper); }

  getTags(query: string, offset: number, limit: number): Observable<ITag[]> {
    return super.get(`all?query=${query}&offset=${offset}&limit=${limit}`);
  }

  getElementTags(id: number): Observable<ITag[]> {
    return super.get(`byElementId/${id}`);
  }

  searchTags(query: string, type: TagType, limit: number): Observable<ITag[]> {
    return super.get(`search?query=${query}&type=${type}&limit=${limit}`);
  }

  createTag(name: string, type: TagType): Observable<ITag> {
    return super.post(``, { name, type });
  }
}
