import {Component, OnInit, OnDestroy} from '@angular/core';
import {BaseComponent} from '@app/core/components/base.component';
import {GroupInfo} from '@app/groups/model/group-info.model';
import {Subject} from 'rxjs';
import {GroupsService} from '@app/groups/services/groups.service';
import {HelperService} from '@app/core/services/helper.service';
import {takeUntil} from 'rxjs/operators';
import {UsersService} from '@app/profile/services/users.service';
import {User} from '@app/profile/model/user.model';

@Component({
  selector: 'app-group-service',
  templateUrl: './group-service.component.html',
  styleUrls: ['./group-service.component.scss'],
})
export class GroupServiceComponent extends BaseComponent implements OnInit, OnDestroy {
  group: GroupInfo;
  currentUser: User;

  constructor(public usersService: UsersService, public groupsService: GroupsService, public helper: HelperService) {
    super(helper);
  }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;
    });
    this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(
      group => {
        if (group) {
          this.group = group;
          group.showCard = false;
        } else {
          this.group = null;
        }
        this.loaded = true;
      },
      error => {
        this.loaded = true;
      },
    );
  }

  showForbiddenBlock() {
    return this.group && this.loaded && !this.groupsService.isAnyRole(this.group, this.currentUser);
  }

  canReadGroup() {
    return this.group && this.groupsService.canReadGroup(this.group, this.currentUser);
  }
}
