import {
  Component,
  ElementRef,
  Input,
  AfterViewInit,
  ViewChild,
  HostListener,
  OnInit,
} from '@angular/core';
import {BaseResizableComponent} from '@app/core/components/base-resizable.component';
import {HelperService} from '@app/core/services/helper.service';
import {Helper} from '@app/core/helpers/helper';
import {Browser} from '@app/shared/browser';

import {CookieService} from 'ngx-cookie-service';

import {SLIDES} from './slides';
import {SettingsService} from '@app/shared/services/settings.service';

const COOKIE_NAME_PREFIX = 'onbrd_';
const TEN_YEARS_IN_DAYS = 3650;

interface Slide {
  active: boolean;
  bg: number;
  slide: number;
  top1200?: number;
  top1600?: number;
}

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent
  extends BaseResizableComponent
  implements OnInit, AfterViewInit
{
  slides: Slide[];
  cookieName: string;
  private window: any;

  @Input() page: 'index' | 'profile' | 'group';
  @ViewChild('wrapper') wrapper: ElementRef;

  constructor(
    private cookieService: CookieService,
    private settingsService: SettingsService,
    public helper: HelperService,
  ) {
    super(helper);
  }

  ngOnInit() {
    this.cookieName = COOKIE_NAME_PREFIX + this.page;
  }

  ngAfterViewInit() {
    this.settingsService.getValue('Онбординг').subscribe(res => {
      if (res && res.toLowerCase() === 'true') {
        const hasShown = this.getShownStatus();

        if (hasShown) {
          return;
        }

        this.onBaseResize();

        if (this.isDesktop()) {
          this.slides = SLIDES[this.page];
          Helper.addOrRemoveScrollLockongOnBody(true);
        }
      }
    });
  }

  getShownStatus() {
    return (
      this.cookieService.check(this.cookieName) || window.localStorage[this.cookieName]
    );
  }

  next() {
    if (this.slides) {
      let currentIndex = this.slides.map(slide => slide.active).indexOf(true) + 1;

      if (currentIndex >= this.slides.length) {
        currentIndex = 0;
      }

      this.set(currentIndex);
    }
  }

  prev() {
    if (this.slides) {
      let currentIndex = this.slides.map(slide => slide.active).indexOf(true) - 1;

      if (currentIndex <= -1) {
        currentIndex = this.slides.length - 1;
      }

      this.set(currentIndex);
    }
  }

  set(newIndex: number) {
    this.slides.forEach((slide, index) => {
      const isCurrent = newIndex === index;
      slide.active = isCurrent;

      const top = (this.isDesktop1600() ? slide.top1600 : slide.top1200) || 0;

      if (isCurrent && this.wrapper && this.wrapper.nativeElement) {
        const scrollTop = top === 0 ? 0 : top ? top + 100 : 0;
        const element = this.wrapper.nativeElement;

        if (!Browser.MsieEdge && element.scrollTo) {
          element.scrollTo({
            top: scrollTop,
            behavior: 'smooth',
          });
        } else if (element.scrollTop) {
          element.scrollTop = scrollTop;
        }
      }
    });
  }

  skip() {
    this.slides = null;
    Helper.addOrRemoveScrollLockongOnBody(false);
    this.cookieService.set(this.cookieName, '1', TEN_YEARS_IN_DAYS, '/');
    window.localStorage[this.cookieName] = 1;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 27:
        this.skip();
        break;
      case 37:
        this.prev();
        break;
      case 32:
      case 39:
        this.next();
        break;
      default:
        break;
    }
  }
}
