import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-post-preloader',
  templateUrl: './post-preloader.component.html',
  styleUrls: ['./post-preloader.component.css']
})
export class PostPreloaderComponent {
  @Input() loaded: boolean;
  @Input() loading: boolean;
}
