<div class="btn btn-dots-white tooltip" mat-button [matMenuTriggerFor]="menu"></div>
<mat-menu #menu="matMenu">
  <div class="icon-menu" #menuContainer>
    <ng-container *ngIf="selectedItems?.length>1; then multipleItemsMenu; else singleItemMenu"></ng-container>
    <ng-template #singleItemMenu>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" (click)="copyToClipboard()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/link.svg" alt="" class="icon-menu__img">
        </span>
        <span>Копировать ссылку</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" (click)="download()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/download.svg" alt="" class="icon-menu__img">
        </span>
        <span>Скачать</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="config?.allowEdit" (click)="showEditMediaFile()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/edit.svg" alt="" class="icon-menu__img">
        </span>
        <span>Редактировать</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="isCanSetMediaFileAsCover()" (click)="setMediaFileAsCover()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/gallery.svg" alt="" class="icon-menu__img">
        </span>
        <span>Сделать обложкой</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="config?.allowMove" (click)="showMoveMediaFile()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/move.svg" alt="" class="icon-menu__img">
        </span>
        <span>Переместить</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="config?.allowDelete" (click)="showDeleteItem()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/remove.svg" alt="" class="icon-menu__img">
        </span>
        <span>Удалить</span>
      </a>
    </ng-template>
    <ng-template #multipleItemsMenu>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="config?.allowMove" (click)="showMoveMediaFile()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/move.svg" alt="" class="icon-menu__img">
        </span>
        <span>Переместить</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="config?.allowDelete" (click)="showDeleteItem()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/remove.svg" alt="" class="icon-menu__img">
        </span>
        <span>Удалить</span>
      </a>
    </ng-template>
  </div>
</mat-menu>

<a #itemLinkHref [href]="getItemLinkUrl()" style="display:none"></a>
<a #itemDownloadHref [href]="" target="_blank" download="{{item?.title}}" style="display:none"></a>
