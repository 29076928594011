import {Component, OnInit, OnDestroy} from '@angular/core';
import {TabbedListComponent} from '@app/shared/components/tabs/tabbed-list.component';
import {Router, ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';
import {UsersService} from '@app/profile/services/users.service';
import {Tab} from '@app/shared/components/tabs/tab.model';
import {User} from '@app/profile/model/user.model';
import {HelperService} from '@app/core/services/helper.service';
import {takeUntil} from 'rxjs/operators';

/**
 * Галерея
 *
 * @export
 * @class GalleryViewComponent
 * @extends {TabbedListComponent<any>}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-gallery-view',
  templateUrl: './gallery-view.component.html',
  styleUrls: ['./gallery-view.component.scss'],
})
export class GalleryViewComponent
  extends TabbedListComponent<any>
  implements OnInit, OnDestroy {
  /**
   * Альбом (из строки запроса)
   *
   * @type {number}
   * @memberof GalleryViewComponent
   */
  albumId: number;
  user: User;

  isCurrent: boolean;
  showAddButton: boolean;

  newLink: string;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected usersService: UsersService,
    public helper: HelperService,
  ) {
    super(router, route, helper);
  }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.usersService.user.pipe(takeUntil(this.destroyed$)).subscribe(user => {
        if (user) {
          this.user = user;

          this.isCurrent = this.user.id === currentUser.id;
          const link = `/profile/${user.id}/gallery`;

          this.tabs = {
            title: 'Галерея',
            routerLink: link,
            hideCounters: true,
            items: [],
          };

          if (!this.isCurrent) {
            this.tabs.items = [
              {
                id: 2,
                title: 'Альбомы коллеги',
                fragment: '',
                items: [],
                offset: 0,
                itemsCount: 0,
                method: this.showTab.bind(this),
                default: true,
                emptyText: 'У коллеги нет альбомов',
              },
            ];
          } else {
            this.tabs.items = [
              {
                id: 1,
                title: 'Последние',
                fragment: 'last',
                items: [],
                offset: 0,
                itemsCount: 0,
                method: this.showTab.bind(this),
                default: true,
                emptyText: 'Ещё ничего не добавлено',
              },
              {
                id: 2,
                title: 'Мои альбомы',
                fragment: 'my',
                items: [],
                offset: 0,
                itemsCount: 0,
                method: this.showTab.bind(this),
                emptyText: 'У вас нет альбомов',
              },
            ];
          }

          this.newLink = link + '/new';

          this.showAddButton = this.isCurrent;

          this.route.fragment.pipe(takeUntil(this.destroyed$)).subscribe(val => {
            this.mobHeight = window.innerHeight;
            this.setNewLimit();

            // get hash
            if (val != null) {
              const keys = val.split('&');
              const hash = {};
              keys.forEach(key => {
                // tslint:disable-next-line:no-shadowed-variable
                const val = key.split('=');
                hash[val[0]] = val[1];
              });

              this.currentTab = null;

              this.tabs.items.forEach(tab => {
                if (tab.fragment && keys.find(k => k === tab.fragment)) {
                  this.currentTab = tab;
                }
              });

              if (!this.currentTab) {
                this.currentTab = this.tabs.items[0];
              }
            } else {
              this.currentTab = this.tabs.items[0];
            }

            // получить данные по всем табикам
            this.tabs.items.forEach(tab => {
              if (!tab.loaded || this.currentTab.id === tab.id) {
                tab.method(tab, this);
              }
            });
          });
        }
      });
    });
  }

  /**
   * При переходе по табику
   *
   * @private
   * @param {Tab<any>} tab
   * @memberof GalleryViewComponent
   */
  protected showTab(tab: Tab<any>) {
    this.loaded = tab.loaded = true;
    tab.items = [{id: 1}];
    tab.loading = false;
  }
}
