import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from '@app/core/services/helper.service';
import { GroupInfo } from '@app/groups/model/group-info.model';
import { GroupsService } from '@app/groups/services/groups.service';
import { ScrollableListComponent } from '@app/shared/components/scrollable-list/scrollable-list.component';
import { AlertsService } from '@app/shared/services/alerts.service';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { SettingsService } from '@app/shared/services/settings.service';
import { IHhVacancy, ISyncVacanciesModel } from '@app/vacancy/model/vacancy';
import { VacancyService } from '@app/vacancy/services/vacancy.service';
import { finalize, takeUntil } from 'rxjs/operators';

/**
 * Список вакансий с hh.ru
 *
 * @export
 * @class GroupVacancyHhListComponent
 */
@Component({
  selector: 'app-group-vacancy-hh-list',
  templateUrl: './group-vacancy-hh-list.component.html',
  styleUrls: ['./group-vacancy-hh-list.component.scss']
})
export class GroupVacancyHhListComponent extends ScrollableListComponent implements OnInit {

  /**
   * Текущая группа
   *
   * @type {GroupInfo}
   * @memberof GroupVacancyListComponent
   */
  group: GroupInfo;

  hhVacancies: IHhVacancy[] = [];

  limit = 40;
  allLoaded = false;
  maxItemHeight = 20;

  private companyId = 232186;
  private hhVacanciesInDbIds: number[] = [];

  constructor(
    protected groupsService: GroupsService,
    protected breadcrumbsService: BreadcrumbsService,
    protected vacancyService: VacancyService,
    protected alertsService: AlertsService,
    protected settingsService: SettingsService,
    protected router: Router,
    protected route: ActivatedRoute,
    public helper: HelperService
  ) {
    super(helper);
  }

  ngOnInit() {
    this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
      this.group = group;
      if (group) {
        this.breadcrumbsService.breadcrumbs.next([
          { title: 'Вакансии', routerUrl: `/group/${group.id}/vacancy`, fragment: 'admin' },
          { title: 'Вакансии c hh.ru', routerUrl: `/group/${group.id}/vacancy/hh` }
        ]);

        this.settingsService.getValue('hhCompanyId')
          .pipe(takeUntil(this.destroyed$))
          .subscribe(res => {
            if (res) {
              this.companyId = +res;
            }
            this.vacancyService.getHhVacanciesIds(this.group.id)
              .pipe(takeUntil(this.destroyed$))
              .subscribe(ids => {
                this.hhVacanciesInDbIds = ids;
                this.getVacanciesFromHh(this.companyId, 0, this.limit);
              });
          });
      }
    });
  }

  private getVacanciesFromHh(companyId: number, page: number, per_page: number) {
    this.loading = true;

    this.vacancyService.getFromHhList(companyId, page, per_page)
      .pipe(finalize(() => {
        this.loading = false;
        this.loaded = true;
      }), takeUntil(this.destroyed$))
      .subscribe(res => {
        if (res) {
          if (res.items) {
            res.items.forEach(item => {
              if (!this.hhVacancies.find(vacancy => vacancy.id === item.id)) {
                item.checked = this.hhVacanciesInDbIds.includes(+item.id);
                this.hhVacancies.push(item);
              }
            });

            if (this.hhVacancies.length) {
              this.offset = Math.round(this.hhVacancies.length / per_page);
            }

            if (res.items.length < per_page) {
              this.allLoaded = true;
            }
          } else {
            this.allLoaded = true;
          }
        } else {
          this.hhVacancies = [];
        }
      });
  }

  protected onScroll() {
    const number = window.innerHeight + window.pageYOffset + 20;
    if (this.hhVacancies && number > this.maxItemHeight * this.hhVacancies.length) {
      if (!this.loading) {
        if (!this.allLoaded) {
          this.getVacanciesFromHh(this.companyId, this.offset, this.limit);
        }
      }
    }
  }

  /**
   * Отправка данных формы на сервер
   *
   * @memberof GroupVacancyHhListComponent
   */
  submit() {
    const hhSync: ISyncVacanciesModel = {
      ownerId: this.group.id,
      hhIds: this.hhVacancies.filter(s => s.checked).map(s => s.id)
    };

    this.vacancyService.syncVacanciesFromHh(hhSync)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        if (res) {
          this.alertsService.riseSuccess('Синхронизация выполнена успешно');
          this.router.navigate(['..'], { relativeTo: this.route, fragment: 'admin' });
        } else {
          this.alertsService.riseError('Во время синхронизации произошла ошибка');
        }
      });
  }
}
