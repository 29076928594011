<div class="grid">
  <div class="grid__content">
    <div class="grid__item" *ngFor="let album of albums">
      <div [style.background-image]="getPreviewUrl(album)" class="gallery-preview" [ngClass]="{ '_checked' : album.selected }">
        <div class="gallery-preview__wrap">
          <label class="checkbox" (click)="stopPropagation($event)" *ngIf="!hideAlbumSelector">
            <input type="checkbox" class="checkbox__input" (change)="albumCheckChanged($event,album)">
            <span class="checkbox__icon"></span>
          </label>
          <div class="gallery-preview__menu" (click)="stopPropagation($event)" *ngIf="!hideAlbumMenu">
            <app-album-menu [item]="album" [config]="albumMenuConfig"
                  [whiteDots]="true" (vote)="onVote($event, album)"></app-album-menu>
          </div>

          <a class="gallery-preview__link" [routerLink]="getAlbumRouterLink(album)" (click)="navigate(album)"></a>

          <div class="gallery-preview__controls" (click)="stopPropagation($event)">
            <a class="gallery-preview__title" [routerLink]="getAlbumRouterLink(album)" (click)="navigate(album)">{{album.title}}</a>
            <div class="gallery-preview__actions">
              <button type="button" class="btn btn-social _pic">{{album.filesCount}}</button>
              <button type="button" class="btn btn-social _eye">{{album.viewCount}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="grid__footer align-center" *ngIf="showMoreBlock()">
    <button type="button" class="btn btn-full">
      <span class="link _progress" (click)="showMore()" *ngIf="showShowMore()">Показать ещё</span>
      <span class="link _progress" (click)="hideMore()" *ngIf="showHideMore()">Свернуть список</span>
    </button>
  </div>
</div>
