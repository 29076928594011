<div class="card _newbies">
  <a class="card__title" [routerLink]="['/employees']">
    <span class="header-text">
      <span class="header-text__text">Новые сотрудники</span>
    </span>
  </a>
  <div class="card__content">
    <ngx-slick-carousel class="carousel _bright-arrows" [config]="{}">
      <div ngxSlickItem class="co _new" *ngFor="let item of items">
        <div class="co__avatar">
          <app-user-avatar [user]="item.user" [linkClass]="'avatar _80'" [imageClass]="'avatar__image'">
          </app-user-avatar>
        </div>
        <div class="co__info">
          <a href="#" class="link co__name" routerLink="/profile/{{item.user?.id}}"
            (click)="usersService.navigateProfile(item.user?.id)"
            [appUserTooltip]="item.user?.id">{{item.user?.fullName}}</a>
          <div class="co__occ" *ngIf="item.user?.jobTitle">{{item.user?.jobTitle}}</div>
        </div>
        <div class="co__action">
          <a type="button" class="btn btn-primary"
             routerLink="/group/{{item?.groupId}}/post/{{item?.postId}}"
          >Подробнее</a>
        </div>
      </div>
    </ngx-slick-carousel>
  </div>
</div>