<div class="cos__header" *ngIf="loaded">
  <app-tabs [tabs]="tabs"></app-tabs>
  <a *ngIf="isAdmin" class="btn btn-plus pull-right" [routerLink]="'new'">+</a>
</div>

<!-- header preloader -->
<app-tab-preloader *ngIf="!loaded"></app-tab-preloader>
<!--  header preloader END -->

<div class="cos__body" *ngIf="loaded && currentTab && currentTab.loaded">
  <ng-container *ngIf="!currentTab?.loading">
    <div class="co" *ngFor="let item of currentTab?.items; trackBy:trackByFunc">
      <div class="co__avatar awards__item" (click)="navigate(item.id)" [ngStyle]="{'background-color': item.color}">
        <img class="awards__image" [attr.src]="bannersService.getIconUrl(item?.iconUrl)">
      </div>
      <div class="co__info" (click)="navigate(item.id)">
        <div class="co__name" *ngIf="item.title">{{item.title}}</div>
      </div>
      <app-banner-type-menu [item]="item" [isAdmin]="isAdmin" (deleted)="onDeleted($event)"></app-banner-type-menu>
    </div>
  </ng-container>
  <div class="co" *ngIf="currentTab?.loading">
    <mat-spinner [diameter]="56"></mat-spinner>
  </div>
</div>
<!-- Empty tab -->
<div class="card" *ngIf="!currentTab?.loading && loaded && currentTab?.loaded && (!currentTab?.items || !currentTab?.items.length)">
  <div class="group-empty">
    <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" class="group-empty__img">
    <div class="group-empty__text">{{currentTab?.emptyText}}</div>
  </div>
</div>
<!-- Empty tab END-->
<!-- header preloader -->
<app-tabbed-list-preloader *ngIf="!currentTab?.loaded"></app-tabbed-list-preloader>
<!--  header preloader END -->
