import { Injectable, EventEmitter } from '@angular/core';
import { HubConnection } from '@microsoft/signalr';

@Injectable()
export class MainHub {
    public onNotifyUsers: EventEmitter<any> = new EventEmitter<any>();
    public onAddUsers: EventEmitter<any> = new EventEmitter<any>();
    public onAddUsersForCurrent: EventEmitter<boolean> = new EventEmitter<boolean>();
    public onRemoveUsersForCurrent: EventEmitter<boolean> = new EventEmitter<boolean>();

    public onUserPictureChanged: EventEmitter<any> = new EventEmitter<any>();

    // register all client functions
    registerOnServerEvents(hubConnection: HubConnection): void {
        // on notify users
        hubConnection.on('onNotifyUsers', (data: any) => {
            this.onNotifyUsers.emit(data);
        });

        // add users to subscribers
        hubConnection.on('onAddUsers', (data: any) => {
            this.onAddUsers.emit(data);
        });
        hubConnection.on('onAddUsersForCurrent', (data: any) => {
            this.onAddUsersForCurrent.emit(data);
        });
        hubConnection.on('onRemoveUsersForCurrent', (data: any) => {
            this.onRemoveUsersForCurrent.emit(data);
        });

        // users
        hubConnection.on('onUserPictureChanged', (data: any) => {
            this.onUserPictureChanged.emit(data);
        });
    }
}
