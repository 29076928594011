import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '@env/environment';
import { NotificationList } from '@app/notifications/models/notification-list.interface';
import { HelperService } from '@app/core/services/helper.service';
import { INotificationCategory } from '../models/notification.interface';
import { map } from 'rxjs/operators';

/**
 * Сервис уведомлений
 *
 * @export
 * @class NotificationsService
 * @extends {BaseService}
 */
@Injectable()
export class NotificationsService extends BaseService {

    notificationCategories: INotificationCategory[];

    protected serviceUrl = `${environment.siteUrl}/api/notification`;
    constructor(
        protected http: HttpClient,
        public helper: HelperService) { super(http, helper); }

    /**
     * Получить все уведомления (для текущего пользователя)
     *
     * @param {Array<number>} categoryIds массив категорий
     * @param {number} offset сдвиг
     * @param {number} limit количество возвращаемых элементов
     * @returns {Observable<NotificationList>} список уведомлений
     */
    getNotifications(categoryIds: number[], offset: number, limit: number): Observable<NotificationList> {
        return this.post(`notifications?offset=${offset}&limit=${limit}`, categoryIds);
    }

    /**
     * Категории уведомлений
     *
     * @returns {Observable<INotificationCategory[]>}
     */
    getNotificationCategories(): Observable<INotificationCategory[]> {
        if (!this.notificationCategories || !this.notificationCategories.length) {
            return this.get(`notificationCategories`).pipe(map((res: INotificationCategory[]) => {
                if (res && res.length) {
                    res.forEach(category => {
                        category.selected = true;
                    });
                }
                this.notificationCategories = res;
                return res;
            }));
        } else {
            return of(this.notificationCategories);
        }
    }

    /**
     * Отметить все как прочитанные
     *
     * @returns {Observable<boolean>} true - если операция выполнена успешно
     */
    setAllViewed(): Observable<boolean> {
        return this.post<boolean>(`setAllViewed`, null);
    }

    /**
     * Отметить прочитанным
     *
     * @param {number} id ИД уведомления
     * @returns {Observable<boolean>} true - если операция выполнена успешно
     */
    setViewed(id: number): Observable<boolean> {
        return this.post<boolean>(`${id}/setViewed`, {});
    }
}
