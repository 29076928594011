import { Injectable } from '@angular/core';
import { BannersApiService } from './banners-api.service';
import { Helper } from '@app/core/helpers/helper';
import { DomSanitizer, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '@app/core/services/helper.service';
import { IBannerSlide } from '../model/banner';
import { BannerSlideTypeApiService } from './banner-slide-type-api.service';

@Injectable({
  providedIn: 'root'
})
export class BannersService extends BannersApiService {

  defaultIcon: string = this.helper.environment.assetsPrefix + '/assets/images/banner/icons/banner-1.svg';
  styleCache = new Map();
  iconsCache = new Map();

  constructor(
    private sanitizer: DomSanitizer,
    public bannerSlideTypeApiService: BannerSlideTypeApiService,
    protected http: HttpClient,
    public helper: HelperService
  ) { super(http, helper); }

  getIconUrl(iconUrl: string): string {
    if (iconUrl) {
      return Helper.concatUrls(this.helper.getSiteHostUrl(), iconUrl);
    }
    return this.defaultIcon;
  }

  getIconUrlServerRelative(iconUrl: string): string {
    if (iconUrl) {
      return Helper.concatUrls(this.helper.getSiteHostUrl(), iconUrl);
    }
    return this.defaultIcon;
  }

  getPictureUrl(item: IBannerSlide): SafeStyle {
      let style;

      if (this.styleCache.get(item)) {
          return this.styleCache.get(item);
      }

      if (item.typeColor && item.typeIconUrl) {
          style = this.getBannerIcon(item);
      }

      if (item.imageUrl) {
          style = `background-image: url('${this.getIconUrl(item.imageUrl)}')`;
          style = this.sanitizer.bypassSecurityTrustStyle(style);
      }

      this.styleCache.set(item, style);

      return style || '';
  }

  getExternalUrl(item: IBannerSlide): SafeUrl {
    if (item && item.externalUrl) {
      return this.sanitizer.bypassSecurityTrustUrl(item.externalUrl);
    }
    return this.sanitizer.bypassSecurityTrustUrl('javascript:;');
  }

  getBannerIcon(item: IBannerSlide): SafeStyle {
    if (this.iconsCache.get(item)) {
        return this.iconsCache.get(item);
    }

    if (item.typeIconUrl) {
      // tslint:disable-next-line:max-line-length
      const style = `background-image: url('${this.getIconUrl(item.typeIconUrl)}'); background-color: ${item.typeColor}; background-position: right bottom; background-size: auto;`;
      const sanitizedStyle = this.sanitizer.bypassSecurityTrustStyle(style);
      this.iconsCache.set(item, sanitizedStyle);

      return sanitizedStyle;
    }
    return '';
  }
}
