<div class="gift-shop">
  <div class="gift-shop__header">
    <div class="profile-score _shop">
      <span class="profile-score__title">Можно потратить</span>
      <span class="profile-score__count">{{ gamificationCountForCurrentUser }}</span>
      <span class="profile-score__title">баллов</span>
    </div>
    <div class="gift-shop__link">
      <a href="#" class="link">Как получить баллы?</a>
    </div>
  </div>
  <div class="gift-shop__body">
    <div class="gift-shop__item" *ngFor="let item of gifts; trackBy: trackByFunc">
      <div class="gift">
        <div class="gift__image-wrap">
          <img class="gift__image" [attr.src]="giftService.getIconUrl(item?.pictureUrl)"/>
        </div>
        <div class="gift__about">
          <div class="gift__title" *ngIf="item.title">{{ item.title }}</div>
          <div class="gift__desc" *ngIf="item.description">{{ item.description }}</div>
          <div class="gift__price" *ngIf="item.value">{{ item.value }}<span>баллов</span></div>
        </div>
        <div class="gift__actions">
            <button type="button" class="btn btn-primary" (click)="orderGift(item)"
              [attr.disabled]="gamificationCountForCurrentUser>=item.value?null:''">
              {{item.value>gamificationCountForCurrentUser?'Недостаточно баллов':'Получить'}}</button>
          </div>
      </div>
    </div>
  </div>
</div>
