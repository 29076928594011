<div class="modal _style-2">
  <div class="card">
    <span class="header-text _modal _thin">
      <span class="header-text__text _close" mat-dialog-title>
          <button mat-button mat-dialog-close type="button" class="btn icon _close-blue hidden-desktop hidden-tablet"></button>
          {{data.headerText}}</span>
    </span>
  </div>
  <div class="files-list" appInfiniteScroll scrollPercent=70 immediateCallback="true" (scrollCallback)="onScrollLikes()">
    <!-- header preloader -->
    <!-- <app-tab-preloader *ngIf="!loaded"></app-tab-preloader> -->
    <!--  header preloader END -->
    <div class="cos__body" *ngIf="loaded && users">
        <app-user-simple-card [link]="link" *ngFor="let link of users"></app-user-simple-card>
        <div class="co" *ngIf="loading">
        <mat-spinner [diameter]="56"></mat-spinner>
        </div>
    </div>
    <!-- Empty tab -->
    <div class="card" *ngIf="!loading && loaded && (!users || !users.length)">
        <div class="group-empty">
        <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" class="group-empty__img">
        <div class="group-empty__text">{{data.emptyText}}</div>
        </div>
    </div>
    <!-- Empty tab END-->
    <!-- header preloader -->
    <!-- <app-tabbed-list-preloader *ngIf="!loaded"></app-tabbed-list-preloader> -->
    <!--  header preloader END -->
  </div>
  <div class="error-message" *ngIf="data.error">{{data.error}}</div>
</div>
