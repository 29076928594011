<div class="content">
  <div class="cos">
    <app-group-small-header></app-group-small-header>

    <!-- header preloader -->
    <app-tab-preloader *ngIf="!loaded"></app-tab-preloader>
    <!--  header preloader END -->

    <!-- header -->
    <div class="cos__header" *ngIf="loaded">
      <!-- Tabs -->
      <app-tabs [tabs]="tabs"></app-tabs>
      <!-- Tabs END-->
      <div class="cos__actions pull-right">
        <button type="button" class="btn btn-filter" [ngClass]="{'_active': filtersVisible}" *ngIf="isShowFilters()"
          (click)="toggleFilters()"></button>
        <a [routerLink]="newLink" *ngIf="showAddButton" class="btn btn-plus pull-right">+</a>
      </div>
      <app-idea-filters [visible]="filtersVisible" (statusChanged)="onStatusChanged($event)"></app-idea-filters>
    </div>
    <!-- header END -->
  </div>
  <app-idea-list [ideas]="currentTab?.items"></app-idea-list>
  <div class="cos">
    <div class="cos__body" *ngIf="currentTab?.loaded && !currentTab?.items?.length">
      <!-- Empty list -->
      <div class="group-empty">
        <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" style="width:auto"
          class="group-empty__img">
        <div class="group-empty__text">{{currentTab.emptyText}}</div>
      </div>
      <!-- Empty list END-->
    </div>
  </div>
</div>
