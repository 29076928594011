<div class="post__action tooltip" mat-button [matMenuTriggerFor]="menu">
  <span class="btn btn-clip"></span>
</div>
<mat-menu #menu="matMenu">
  <div class="icon-menu">
    <a mat-menu-item href="javascript:;" class="icon-menu__item" (click)="showAttachmentsTree(attachmentsTreeType.myFiles)" *ngIf="showFilesMenu() | async">
      <span class="icon-menu__icon">
        <img src="{{assetsPrefix}}/assets/images/icons/menu/choose-file.svg" alt="" class="icon-menu__img">
      </span>
      <span>Выбрать из моих файлов</span>
    </a>
    <a mat-menu-item href="javascript:;" class="icon-menu__item" *ngIf="showGroupMenu() | async" (click)="showAttachmentsTree(attachmentsTreeType.groupFiles)">
      <span class="icon-menu__icon">
        <img src="{{assetsPrefix}}/assets/images/icons/menu/choose-group-file.svg" alt="" class="icon-menu__img">
      </span>
      <span>Выбрать из файлов группы</span>
    </a>
    <a mat-menu-item href="javascript:;" class="icon-menu__item" (click)="fileUpload.click()">
      <span class="icon-menu__icon">
        <img src="{{assetsPrefix}}/assets/images/icons/menu/upload.svg" alt="" class="icon-menu__img">
      </span>
      <span>Загрузить с компьютера</span>
    </a>
  </div>
</mat-menu>

<input type="file" #fileUpload (change)="onUpload($event)" [attr.multiple]="multiple?'':null" style="display: none" />
