import { Component, OnInit, Input, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { FilesService } from '@app/files/services/files.service';
import { FilesListItem, Breadcrumb } from '@app/files/model/files-list-item.model';
import { Subject, BehaviorSubject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelperService } from '@app/core/services/helper.service';
import { takeUntil } from 'rxjs/operators';

/**
 * Результат выполнения в дереве файлов
 *
 * @export
 * @class FilesTreeResult
 */
export class FilesTreeResult {
  folderUrl: string;
  portalUrl: string;
}

/**
 * Компонент дерева файлов
 *
 * @export
 * @class FilesTreeComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-files-tree',
  templateUrl: './files-tree.component.html'
})
export class FilesTreeComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() item: any;
  @Input() okText = 'Переместить сюда';
  @Input() headerText = 'Выберите папку для перемещения';

  @Input() foldersOnly: boolean;
  @Input() skipCurrent: boolean;

  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  @Output() onOk: EventEmitter<any> = new EventEmitter();
  @Output() onFolderCreated: EventEmitter<any> = new EventEmitter();
  @Output() onFileSelect: EventEmitter<any> = new EventEmitter();

  @Input() webUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  @Input() folderUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  @Input() rootFolderUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  @Input() folder: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  showTree: Subject<boolean> = new BehaviorSubject<boolean>(false);

  items: Array<FilesListItem>;

  disabled: boolean;
  text = '';

  private portalUrl: string;
  private newFolderName: string;
  private initialFolder: string;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public filesService: FilesService,
    public helper: HelperService) {

    super(helper);

    this.disabled = false;
    this.item = this.data.item;
    this.foldersOnly = this.data.foldersOnly;
    this.skipCurrent = this.data.skipCurrent;

    if (this.data.okText || this.data.okText === '') {
      this.okText = this.data.okText;
    }

    if (this.data.headerText || this.data.headerText === '') {
      this.headerText = this.data.headerText;
    }

    if (this.data.webUrl) {
      this.webUrl = this.data.webUrl;
    }
    if (this.data.folderUrl) {
      this.folderUrl = this.data.folderUrl;
    }
    if (this.data.rootFolderUrl) {
      this.rootFolderUrl = this.data.rootFolderUrl;
    }

    if (this.data.folder) {
      this.folder = this.data.folder;
    }

    if (this.data.onCancel) {
      this.onCancel = this.data.onCancel;
    }
    if (this.data.onOk) {
      this.onOk = this.data.onOk;
    }
    if (this.data.onFolderCreated) {
      this.onFolderCreated = this.data.onFolderCreated;
    }
    if (this.data.onFileSelect) {
      this.onFileSelect = this.data.onFileSelect;
    }
  }

  ngOnInit() {
    this.folderUrl.pipe(takeUntil(this.destroyed$)).subscribe(folder => {
      if (!this.initialFolder) {
        this.initialFolder = folder;
      }
      this.folder.next(folder);
    });
    this.getItems();
  }

  open() {
    this.showTree.next(true);
  }

  cancel() {
    this.disabled = false;
    this.unsubscribe();
    this.showTree.next(false);
    this.onCancel.emit();
    this.dialogRef.close();
  }

  createFolder() {
    console.log('creating folder');
    // this.creatingFolder = true;

    const webUrl = this.webUrl.getValue();
    const resList = this.rootFolderUrl.getValue();
    const folderUrl = this.folder.getValue();

    if (resList) {
      if (this.newFolderName) {
        const target = folderUrl ? folderUrl : resList;
        console.log(`creating, name: '${this.newFolderName}', target: '${target}`);
        this.filesService.webApiCreateFolder(webUrl, target, this.newFolderName)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(res => {
            if (res) {
              this.getItems();
              if (this.onFolderCreated) {
                this.onFolderCreated.emit(res);
              }
            }
            console.log('created');
            this.newFolderName = '';
            // this.creatingFolder = false;
          }, () => {
            this.error = 'Произошла ошибка при создании папки';
          });
      } else {
        this.error = 'Необходимо указать имя папки';
      }
    } else {
      this.error = 'Список не существует';
    }
  }

  onNewFolderKey(e) {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.newFolderName = '';
    }

    if (e.keyCode === 13 && this.newFolderName) {
      e.preventDefault();
      this.createFolder();
    }
  }

  ok() {
    const folder = this.folder.getValue();
    const resList = this.rootFolderUrl.getValue();

    if (resList) {
      this.disabled = true;
      const result: FilesTreeResult = {
        folderUrl: folder ? folder : resList,
        portalUrl: this.portalUrl
      };
      if (this.onOk instanceof EventEmitter) {
        this.onOk.emit(result);
      } else if (this.onOk) {
        (<any>this).onOk(result);
      }
    }
  }

  /**
   * Перейти в папку
   *
   * @param {FilesListItem} item
   * @memberof FilesTreeComponent
   */
  navigate(item: FilesListItem) {
    console.log(`navigated ${item.url}`);
    this.folder.next(item.url);
    this.getItems();
  }

  /**
   * Выбрать файл
   *
   * @param {FilesListItem} item
   * @memberof FilesTreeComponent
   */
  selectFile(item: FilesListItem) {
    console.log(`file selected ${item.url}`);
    this.onFileSelect.emit(item);
  }

  /**
   * Перейти по хлебным крошкам
   *
   * @param {Breadcrumb} b
   * @memberof FilesTreeComponent
   */
  navigateBreadcrumb(b: Breadcrumb) {
    console.log(`navigated ${b.url}`);
    this.folder.next(b.url);
    this.getItems();
  }

  getItems() {
    // get properties
    const url = this.webUrl.getValue();
    const rootFolderUrl = this.rootFolderUrl.getValue();
    const folderUrl = this.folder.getValue();

    if (url) {
      if (rootFolderUrl) {
        const folder = folderUrl ? folderUrl : rootFolderUrl;
        this.filesService.webApiGetTree(url, rootFolderUrl, folder, this.foldersOnly, this.text)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(filesList => {
            this.portalUrl = filesList.portalUrl;

            if (this.foldersOnly) {
              filesList.items = filesList.items.filter(item => item.itemType !== 'OneNote.Notebook');
            }

            if (this.skipCurrent) {
              this.items = filesList.items.filter(item => item.id !== this.item.id);
            } else {
              this.items = filesList.items;
            }
            this.loaded = true;
            this.unsubscribe();
          }, error => {
            this.error = error;
            this.loaded = true;
            this.unsubscribe();
          });
      } else {
        this.error = 'Список не существует';
      }
    } else {
      this.error = 'URL сайта не определён';
    }
  }

  isValid() {
    if (this.data.excludeCurrentFolder) {
      const folder = this.folder.getValue();
      return !this.disabled && this.initialFolder !== folder;
    } else {
      return !this.disabled;
    }
  }

  protected unsubscribe() {
    this.destroyed$.next();
  }
}
