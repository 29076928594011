<div class="cos" *ngFor="let mediaFileGroup of mediaFileGroups">
  <div class="cos__body">
    <div class="grid">
      <div class="grid__header _with-date">
        <a *ngIf="mediaFileGroup.ownerType==profileType.User" class="link-profile" routerLink="/profile/{{mediaFileGroup.ownerId}}"
          (click)="usersService.navigateProfile(mediaFileGroup.ownerId)">
          <app-user-avatar [user]="mediaFileGroup.user" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'"
            [asSpan]="true"></app-user-avatar>
          <span class="link-profile__text" [appUserTooltip]="mediaFileGroup.user?.id">{{mediaFileGroup.user?.fullName}}</span>
          <span class="link-profile__subtext">{{mediaFileGroup?.date}}</span>
        </a>
        <a *ngIf="mediaFileGroup.ownerType==profileType.Group" routerLink="/group/{{mediaFileGroup?.group?.id}}" class="link-profile hint"
          (click)="groupsService.navigateGroup(mediaFileGroup?.group?.id)">
          <span>
            <span title="" class="avatar _40" [ngStyle]="{'background-color':groupsService.getColor(mediaFileGroup?.group)}"
              [attr.data-initials]="groupsService.getInitials(mediaFileGroup?.group, true)">
              <span class="avatar__image-wrap" [attr.style]="groupsService.getPictureUrlStyle(mediaFileGroup?.group)"></span>
            </span>
          </span>
          <span class="link-profile__text">{{mediaFileGroup?.group?.title}}</span>
          <span class="link-profile__subtext">{{mediaFileGroup?.date}}</span>
        </a>
        <a href="javascript:;" [routerLink]="getOwnerLink(mediaFileGroup)" class="grid__header-link">{{getOwnerTypeText(mediaFileGroup)}}</a>
      </div>
      <div class="grid__content">
        <div
          class="grid__item"
          *ngFor="let m of mediaFileGroup.mediaFiles; let last = last"
          [attr.data-rest]="last && m.filesCountInBlock > 4 ? m.filesCountInBlock - 4 : null"
        >
          <!-- _checked если галочка-->
          <div [style.background-image]="galleryService.getMediaFilePreview(m)" class="gallery-preview _file" [ngClass]="{ '_checked' : m.selected }"
            (click)="openMediaFile($event,m,mediaFileGroup)">
            <div class="gallery-preview__wrap">
              <div class="gallery-preview__menu" (click)="stopPropagation($event)">
                <app-media-item-menu [item]="m" [config]="getMenuConfig(m)"></app-media-item-menu>
              </div>
              <div class="gallery-preview__controls">
                <div class="gallery-preview__title" *ngIf="m.type!==mediaFileType.video">{{m?.title}}</div>
                <div class="gallery-preview__actions">
                  <button type="button" class="btn btn-social _like-white">{{m.likesCount}}</button>
                  <button type="button" class="btn btn-social _comment-white" [ngClass]="{'transparent-text': m.commentsCount === 0}">{{m.commentsCount}}</button>
                  <a href="javascript:;" class="link" (click)="replyMediaFile($event,m,mediaFileGroup)">Ответить</a>
                  <button *ngIf="m.isAlbumPreview" type="button" class="btn btn-social _pic-stack pull-right"></button>
                </div>
              </div>
              <div class="gallery-preview__play" *ngIf="m.type===mediaFileType.video">
                <!--Длительность видео вставить здесь-->
                <!--<span>14:15</span>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
