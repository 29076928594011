import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { IGift } from '@app/gifts/model/gift';
import { GiftService } from '@app/gifts/services/gift.service';
import { AlertsService } from '@app/shared/services/alerts.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-gift-list-view',
  templateUrl: './gift-list-view.component.html',
  styleUrls: ['./gift-list-view.component.scss']
})
export class GiftListViewComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input() gifts: IGift[];

  constructor(
    public giftService: GiftService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected alertsService: AlertsService,
    public helper: HelperService
  ) { super(helper); }

  ngOnInit(): void {

  }

  moveUp(i: number) {
    this.changeSort(i, true);
  }

  moveDown(i: number) {
    this.changeSort(i, false);
  }

  private changeSort(i: number, up: boolean) {
    this.giftService.changeSort(this.gifts[i].id, up)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        if (res) {
          if (up) {
            this.moveItem(i, i - 1);
            const upIndex = this.gifts[i - 1].order;
            this.gifts[i - 1].order = this.gifts[i].order;
            this.gifts[i].order = upIndex;
          } else {
            this.moveItem(i, i + 1);
            const downIndex = this.gifts[i + 1].order;
            this.gifts[i + 1].order = this.gifts[i].order;
            this.gifts[i].order = downIndex;
          }
          // this.gifts.sort((a, b) => a.order - b.order);
          this.alertsService.riseSuccess('Сортировка была успешно изменена.');
        } else {
          this.alertsService.riseError('Произошла ошибка при изменении сортировки. Обратитесь к администратору.');
        }
      });
  }

  private moveItem(from, to) {
    // remove `from` item and store it
    const f = this.gifts.splice(from, 1)[0];
    // insert stored item into position `to`
    this.gifts.splice(to, 0, f);
  }

  toggle(gift: IGift) {
    this.giftService.toggle(gift.id, !gift.hidden)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        if (res) {
          gift.hidden = !gift.hidden;
          this.alertsService.riseSuccess('Подарок был успешно скрыт.');
        } else {
          this.alertsService.riseError('Произошла ошибка при скрытии подарка. Обратитесь к администратору.');
        }
      });
  }

  edit(id: number) {
    this.router.navigate(['/profile/gifts', id, 'edit']);
  }

  remove(id: number) {
    this.giftService.remove(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        if (res) {
          this.gifts = this.gifts.filter(s => s.id !== id);
          this.alertsService.riseSuccess('Подарок был успешно удалён.');
        } else {
          this.alertsService.riseError('Произошла ошибка при удалении подарка. Обратитесь к администратору.');
        }
      });
  }

  trackByFunc(index, item) {
    return item.id;
  }
}
