import {Component, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { IThank } from '@app/thanks/model/thank';
import { User } from '@app/profile/model/user.model';
import {takeUntil, finalize} from 'rxjs/operators';
import { NewThankComponent } from '../new-thank/new-thank.component';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService } from '@app/profile/services/users.service';
import { ThanksService } from '@app/thanks/services/thanks.service';
import { AlertsService } from '@app/shared/services/alerts.service';
import { HelperService } from '@app/core/services/helper.service';
import { ScrollableListComponent } from '@app/shared/components/scrollable-list/scrollable-list.component';

@Component({
  selector: 'app-thanks-all-list',
  templateUrl: './thanks-all-list.component.html',
  styleUrls: ['./thanks-all-list.component.scss']
})
export class ThanksAllListComponent extends ScrollableListComponent implements OnInit, OnDestroy {

  user: User;
  currentUser: User;

  isCurrent = false;

  offset = 0;
  limit = 10;
  items: IThank[] = [];
  count: number;

  protected maxItemHeight = 220;

  protected newThankDialog: MatDialogRef<NewThankComponent>;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    public dialog: MatDialog,
    public usersService: UsersService,
    protected cdr: ChangeDetectorRef,
    protected thanksService: ThanksService,
    protected alertsService: AlertsService,
    public helper: HelperService) {
    super(helper);
  }

  ngOnInit() {
    this.getAllThanks();
    this.getThanksCount();
  }

  thank() {
    this.newThankDialog = this.dialog.open(NewThankComponent, {
        data: {
            onOk: () => {
                this.getAllThanks();
                this.newThankDialog.close();
            }
        }
    });
  }

  private getAllThanks() {
    this.loading = true;

    this.thanksService.getAll(this.offset, this.limit)
        .pipe(finalize(() => {
          this.loaded = true;
          this.cdr.detectChanges();
        }), takeUntil(this.destroyed$))
        .subscribe((res) => {
          this.items = this.items.concat(res);

          this.offset = this.items?.length || 0;

          if (res.length < this.limit) {
              this.allLoaded = true;
          }

          this.loading = false;
        });
  }

  private getThanksCount() {
    this.thanksService.getThanksCount()
        .pipe(finalize(() => {
          this.loaded = true;
          this.cdr.detectChanges();
        }), takeUntil(this.destroyed$))
        .subscribe((res) => {
          this.count = res;
        });
  }

    protected onScroll() {
        const number = window.innerHeight + window.pageYOffset + 200;
        if (this.items && number > this.maxItemHeight * this.items.length) {
            if (!this.loading) {
                if (!this.allLoaded) {
                    this.getAllThanks();
                }
            }
        }
    }
}
