<div class="list-table list-table-search">
  <table class="list-table__inner">
    <thead>
      <tr>
        <th></th>
        <th>
          <span class="list-table__heading _image _disabled">
            <span>Название</span>
          </span>
        </th>
        <th></th>
        <th>
          <span class="list-table__heading _disabled">
            <span>Изменено</span>
          </span>
        </th>
        <th>
          <span class="list-table__heading _disabled">
            <span>Кем изменено</span>
          </span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr class="list-table__item" [ngClass]="{'_selected':item?.checked | async}" *ngFor="let item of items"
        (contextmenu)="showContextMenu($event, item, fileMenu)">
        <td></td>
        <td>
          <ng-container *ngIf="item.isFolder">
            <a attr.id="item_{{item.uniqueId}}" #hiddenLink style="display: none;" target="_blank"></a>
            <a href="javascript:;" class="link-file _folder-num" (click)="openFolder($event, item, hiddenLink)">
              <span class="link-file__text" [innerHTML]="item.fileName | highlight: highlightText"></span>
            </a>
          </ng-container>
          <a attr.id="item_{{item.uniqueId}}" target="_blank" *ngIf="!item.isFolder"
            [attr.href]="filesService.webApiGetFileUrl(item, item.uniqueId)" [ngClass]="filesService.getFileIcon(item)"
            class="link-file">
            <span class="link-file__text" [innerHTML]="item.fileName | highlight: highlightText"></span>
            <span class="link-file__subtext" style="color: black;"
              [innerHTML]="item.highlightedSummary | highlight: highlightText"></span>
            <span class="link-file__subtext">{{filesService.getSize(item)}} КБ</span>
          </a>
        </td>
        <td class="list-table__actions">
          <app-file-menu #fileMenu [item]="item" [webUrl]="webUrl" [folderUrl]="folderUrl"
            [rootFolderUrl]="rootFolderUrl" [isCurrentUser]="'true'" [config]="config"
            itemLink="item_{{item.uniqueId}}"></app-file-menu>
        </td>
        <td>{{item.modified | date:'dd.MM.yy HH:mm'}}</td>
        <td>{{item.editor}}</td>
      </tr>
      <tr *ngIf="loading">
        <div class="loading _blue _standalone"></div>
      </tr>
    </tbody>
  </table>
</div>