import { Injectable, EventEmitter } from '@angular/core';
import { LikeViewModel } from '@app/likes/model/like-view-model.model';
import { HubConnection } from '@microsoft/signalr';

@Injectable()
export class LikeHub {

    public liked: EventEmitter<LikeViewModel> = new EventEmitter<LikeViewModel>();

    registerOnServerEvents(hubConnection: HubConnection): void {
        hubConnection.on('liked', (data: LikeViewModel) => {
            this.liked.emit(data);
        });
    }
}
