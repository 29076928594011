<div class="card _ideas" *ngIf="loaded && showWidget">
  <div class="card__title">
    <a *ngIf="showAddButton" class="btn btn-plus pull-right" [routerLink]="addButtonLink">+</a>
    <div class="header-text">
      <a class="header-text__text" (click)="navigate()" [routerLink]="link">Идеи</a>&nbsp;
    </div>
  </div>
  <div class="card__content" *ngIf="showWidget">
    <div class="list-item" *ngFor="let idea of ideas; trackBy:idea?.id">
      <img [src]="ideasService.getStatusImageUrl(idea)" class="list-item__status">
      <div class="list-item__desc">
        <a class="list-item__title" routerLink="/group/{{idea.ownerId}}/ideas/{{idea.id}}">{{idea.title}}</a>
        <div class="list-item__subtitle">{{idea.status?.title}}</div>
      </div>
      <app-idea-menu [item]="idea" [config]="getIdeaConfig(idea)" [ideaLocation]="4" (deleted)="onDeleted($event)"></app-idea-menu>
    </div>
  </div>
  <div class="card__content" *ngIf="!showWidget && group">
    <app-group-forbidden></app-group-forbidden>
  </div>
</div>
<div class="card" *ngIf="!loaded">
  <div class="card__title">
    <span class="preloader">
      <span class="preloader__line"></span>
    </span>
  </div>
  <div class="card__content">
    <span class="preloader _square" *ngFor="let r of [0,1,2,3]">
      <span class="preloader__side"></span>
      <span class="preloader__line"></span>
    </span>
  </div>
</div>
