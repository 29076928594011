import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tab-preloader',
  templateUrl: './tab-preloader.component.html'
})
export class TabPreloaderComponent {
  @Input() loaded: boolean;
  @Input() loading: boolean;
}
