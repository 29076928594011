import { Component, EventEmitter, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from '@app/core/components/base.component';
import { ProfileType } from '@app/core/model/profile-type.enum';
import { HelperService } from '@app/core/services/helper.service';
import { IIntryProfile } from '@app/shared/model/intry-profile';
import { AlertsService } from '@app/shared/services/alerts.service';

@Component({
  selector: 'app-profile-selector-modal',
  templateUrl: './profile-selector-modal.component.html',
  styleUrls: ['./profile-selector-modal.component.scss']
})
export class ProfileSelectorModalComponent extends BaseComponent implements OnInit {

  @Input() text = 'Выберите группу?';
  @Input() okText = 'Да';
  @Input() cancelText = 'Нет';

  doNotHideOnOk: boolean;

  profile: IIntryProfile;

  constructor(
    public dialogRef: MatDialogRef<ProfileSelectorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertsService: AlertsService,
    public helper: HelperService) { super(helper); }

  ngOnInit() {
    if (this.data) {
      if (this.data.okText) {
        this.okText = this.data.okText;
      }
      if (this.data.cancelText) {
        this.cancelText = this.data.cancelText;
      }
      if (this.data.text) {
        this.text = this.data.text;
      }
      if (this.data.text) {
        this.text = this.data.text;
      }
      if (this.data.text) {
        this.text = this.data.text;
      }
      if (this.data.doNotHideOnOk) {
        this.doNotHideOnOk = this.data.doNotHideOnOk;
      }
    }
  }

  ok() {
    if (this.data.onOk) {
      if (this.data.onOk instanceof EventEmitter) {
        this.data.onOk.emit(this.profile);
      } else {
        this.data.onOk(this.profile);
      }
    }
  }

  cancel() {
    if (this.data.onCancel) {
      this.data.onCancel.emit();
    }
  }

  onProfileSelected(profile: IIntryProfile) {
    if (!profile || profile.profileType !== ProfileType.Group) {
      this.alertsService.riseError(`Ошибка при выборе группы для создания опроса`);
    } else {
      this.profile = profile;
    }
  }
}
