import { Component, OnInit, Input, Output, EventEmitter, NgZone, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { User } from '@app/profile/model/user.model';
import { UsersService } from '@app/profile/services/users.service';
import { FilesListItem } from '@app/files/model/files-list-item.model';
import { FilesService } from '@app/files/services/files.service';
import { ElementType } from '@app/core/model/element-type.enum';
import { Observable, of } from 'rxjs';
import { AttachmentViewModel } from '../../model/attachment-view-model.model';
import { takeUntil, finalize } from 'rxjs/operators';
import { AlertsService } from '@app/shared/services/alerts.service';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { IDatafileModel } from '@app/files/model/data-file-model';

/**
 * Список вложений
 *
 * @export
 * @class AttachmentsListComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-attachments-list',
  templateUrl: './attachments-list.component.html'
})
export class AttachmentsListComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() isEdit: boolean;
  @Input() attachments: AttachmentViewModel[] = [];
  @Input() ownerId: number;
  @Input() ownerType: number;
  @Input() elementType: ElementType = ElementType.Event;

  @Output() onRemove: EventEmitter<AttachmentViewModel> = new EventEmitter<AttachmentViewModel>();
  @Output() onFilesUploaded: EventEmitter<any> = new EventEmitter<any>();

  attachmentsToUpload: any = [];
  filesUploading: boolean;

  private currentUser: User;

  constructor(
    public alertsService: AlertsService,
    public filesService: FilesService,
    protected usersService: UsersService,
    protected ngZone: NgZone,
    protected cdr: ChangeDetectorRef,
    public helper: HelperService,
  ) { super(helper); }

  ngOnInit() {
    this.usersService.currentUser
      .subscribe(currentUser => {
        this.currentUser = currentUser;
      });
  }

  /**
   * Действие при выборе файла
   *
   * @param {Array<FilesListItem>} event
   * @memberof AttachmentsListComponent
   */
  onChooseFile(event: Array<FilesListItem>) {
    if (event && event.length) {
      event.forEach(file => {
        const attachment = {
          attachment:
          {
            title: file.name,
            modified: new Date(),
            ownerID: this.currentUser.id,
            guid: file.uniqueId,
            url: file.url
          },
          owner: this.currentUser
        };
        if (!this.attachments || !this.attachments.find(s => s.attachment.title === file.name)) {
          this.attachments.push(attachment);
          this.getAttachmentSize(attachment);
        } else {
          this.alertsService.riseError(`файл с названием "${file.name}" уже добавлен`);
        }
      });
    }
  }

  /**
   * Действие при загрузке вложения
   *
   * @param {*} event
   * @memberof AttachmentsListComponent
   */
  onUploadFile(event) {
    const files = event.target.files;
    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        this.attachmentsToUpload.push({ name: files[i].name, file: files[i], id: `toUpload_${files[i].name}`, toUpload: true });
        this.attachments.push({
          attachment:
          {
            title: files[i].name,
            modified: new Date(),
            ownerID: this.currentUser.id,
            url: `toUpload_${files[i].name}`,
            size: files[i].size,
            toUpload: true
          },
          owner: this.currentUser
        });
      }
    }
  }

  onUploadFiles(event) {
    this.filesUploading = true;
    this.cdr.detectChanges();

    const files = event.target.files;

    if (files && files.length) {
      // init promise
      let promise: Observable<IDatafileModel[]>;
      // get files from form data
      const formData: FormData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i], files[i].name);

      // different attachment types upload into different libraries
      switch (this.elementType) {
        case ElementType.Comment:
          promise = this.filesService.uploadCommentAttachedFiles(formData, this.ownerId);
          break;
        case ElementType.Event:
          promise = this.filesService.uploadFeedAttachedFiles(formData, this.ownerId);
          break;
        case ElementType.Vacancy:
        case ElementType.VacancyResponse:
          promise = this.filesService.uploadVacancyAttachment(formData, this.ownerId);
          break;
        case ElementType.Idea:
          promise = this.filesService.uploadIdeaAttachment(formData, this.ownerId);
          break;
      }

      // real request for upload
      promise
        .pipe(finalize(() => this.filesUploading = false), takeUntil(this.destroyed$))
        .subscribe(res => {
          // callback
          this.onFilesUploaded.emit(res);
        }, error => {
          // error
          console.log(error);
        });
      }
    }
  }

  /**
   * Загрузить вложенные файлы
   *
   * @param {ElementType} type
   * @memberof AttachmentsListComponent
   */
  uploadAttachedFiles(type: ElementType) {
    // only if there are anything to upload
    if (this.attachmentsToUpload && this.attachmentsToUpload.length) {

      this.filesUploading = true;

      // init promise
      let promise: Observable<IDatafileModel[]>;
      // get files from form data
      const formData: FormData = new FormData();
      for (let i = 0; i < this.attachmentsToUpload.length; i++) {
        formData.append('file', this.attachmentsToUpload[i].file, this.attachmentsToUpload[i].name);
      }

      // different attachment types upload into different libraries
      switch (type) {
        case ElementType.Comment:
          promise = this.filesService.uploadCommentAttachedFiles(formData, this.ownerId);
          break;
        case ElementType.Event:
          promise = this.filesService.uploadFeedAttachedFiles(formData, this.ownerId);
          break;
        case ElementType.Vacancy:
        case ElementType.VacancyResponse:
          promise = this.filesService.uploadVacancyAttachment(formData, this.ownerId);
          break;
        case ElementType.Idea:
          promise = this.filesService.uploadIdeaAttachment(formData, this.ownerId);
          break;
      }

      // real request for upload
      promise
        .pipe(finalize(() => this.filesUploading = false), takeUntil(this.destroyed$))
        .subscribe(res => {
          // callback
          this.onFilesUploaded.emit(res);
        }, error => {
          // error
          console.log(error);
        });
    }
  }

  /**
   * Удалить вложение из списка
   *
   * @param {AttachmentViewModel} attachment вложение, которое необходимо удалить
   * @memberof AttachmentsListComponent
   */
  remove(attachment: AttachmentViewModel) {
    this.ngZone.run(() => {
      this.attachments = this.attachments.filter(att => attachment.attachment.guid && att.attachment.guid !== attachment.attachment.guid
        || !attachment.attachment.guid && att.attachment.title !== attachment.attachment.title);
      this.attachmentsToUpload = this.attachmentsToUpload.filter(s => s.name !== attachment.attachment.title);
    });
    this.onRemove.emit(attachment);
  }

  /**
   * Получение размера вложения
   *
   * @private
   * @param {AttachmentViewModel} attachment
   * @returns {Observable<string>}
   * @memberof AttachmentsListComponent
   */
  private getAttachmentSize(attachment: AttachmentViewModel): Observable<string> {
    if (attachment.attachment.size !== undefined) {
      return of(attachment.attachment.size);
    }

    let index = -1;
    // if doc is in feed attachments
    index = attachment.attachment.url.indexOf('feedattachments');
    // if doc is in comment attachments
    if (index === -1) {
      index = attachment.attachment.url.indexOf('commentattachments');
    }
    // if selecting doc
    if (index === -1) {
      index = attachment.attachment.url.indexOf('/Documents/');
    }
    // if selecting doc
    if (index === -1) {
      index = attachment.attachment.url.indexOf('/api/');
    }

    attachment.attachment.size = '';

    if (index !== -1) {
      // get web url
      const url = attachment.attachment.url.substr(0, index);
      // get and update attachment size
      this.filesService.getFileSize(attachment.attachment.guid)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(res => {
          attachment.attachment.size = res;
          this.cdr.detectChanges();
        });
    }

    return of('');
  }
}
