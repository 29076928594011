import { Component, OnInit, OnDestroy } from '@angular/core';
import { NewAwardComponent } from '../new-award/new-award.component';
import { UsersService } from '@app/profile/services/users.service';
import { AwardsService } from '@app/awards/services/awards.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { AlertsService } from '@app/shared/services/alerts.service';
import { HelperService } from '@app/core/services/helper.service';
import { takeUntil, switchMap, take } from 'rxjs/operators';
import { IUpdateAwardModel, IAward } from '@app/awards/model/awards';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import { FilesService } from '@app/files/services/files.service';

/**
 * Редактирование награды
 *
 * @export
 * @class EditAwardComponent
 * @extends {NewAwardComponent} Наследуется от Создания новой награды, т.к. часть логики общая, чтобы не дублировать
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-edit-award',
  templateUrl: '../new-award/new-award.component.html'
})
export class EditAwardComponent extends NewAwardComponent implements OnInit, OnDestroy {

  award: IUpdateAwardModel;
  currentAward: IAward;
  buttonSaveText = 'Сохранить';

  constructor(
    protected filesService: FilesService,
    protected usersService: UsersService,
    protected awardsService: AwardsService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected breadcrumbsService: BreadcrumbsService,
    protected alertsService: AlertsService,
    protected dialog: MatDialog,
    public helper: HelperService
  ) {
    super(filesService, usersService, awardsService, router, route, breadcrumbsService, alertsService, dialog, helper);
  }

  ngOnInit() {
    // получение всех иконок наград
    this.getIcons();

    // получение пользователя профиля и добавление хлебных крошек
    this.usersService.user.pipe(takeUntil(this.destroyed$)).subscribe(user => {
      if (user) {
        this.user = user;

        this.breadcrumbsService.breadcrumbs.next([
          { title: 'Награды', routerUrl: `/profile/${this.user.id}/awards` }
        ]);

        // get award from id parameter
        this.route.params
          .pipe(takeUntil(this.destroyed$), switchMap((params: Params) => {
            const awardId = +params['awardId'];
            if (awardId) {
              return this.awardsService.getById(awardId).pipe(take(1));
            } else {
              this.router.navigate(['/404'], { skipLocationChange: true });
            }
          }))
          .subscribe(award => {
            if (award) {
              this.currentAward = award;
              this.award = {
                id: award.id,
                title: award.title,
                period: award.period,
                iconId: award.iconId,
                userId: user.id
              };

              this.breadcrumbsService.breadcrumbs.next([
                { title: 'Награды', routerUrl: `/profile/${this.user.id}/awards` },
                { title: this.award.title, routerUrl: `/profile/${this.user.id}/awards/${this.award.id}` },
              ]);

              this.error = '';
            } else {
              this.error = 'Награда не найдена или у вас нет прав не её просмотр.';
            }
            this.loaded = true;
          }, error => {
            this.error = error;
            this.loaded = true;
          });
      } else {
        this.user = null;
      }
    });
  }

  submit() {
    if (this.isValid()) {
      this.awardsService.update(this.award.id, this.award).pipe(take(1)).subscribe(res => {
        if (res) {
          this.alertsService.riseSuccess('Награда успешно изменена.');
          this.router.navigate(['../..'], { relativeTo: this.route });
        } else {
          this.alertsService.riseError('Произошла ошибка при отправке формы. Обратитесь к администратору.');
        }
      }, error => {
        this.alertsService.riseError('Произошла ошибка при отправке формы. Обратитесь к администратору.');
        console.log(error);
      });
    } else {
      this.alertsService.riseError('Произошла ошибка при отправке формы. Не заполнены обязательные поля');
    }
  }

  /**
   * Переопределяем отмену, т.к. редактирование на 1 уровень дальше
   *
   * @memberof EditAwardComponent
   */
  cancel() {
    if (this.currentAward && this.award
      && (this.currentAward.title !== this.award.title
        || this.currentAward.period !== this.award.period
        || this.currentAward.iconId !== this.award.iconId)
    ) {
      this.dialog.open(ModalConfirmationComponent, {
        data: {
          text: `Вы действительно хотите отменить редактирование?`,
          onOk: this.goBackToProfile.bind(this),
          okText: 'Да'
        }
      });
    } else {
      this.goBackToProfile();
    }
  }

  private goBackToProfile() {
    this.router.navigate(['../..'], { relativeTo: this.route });
  }
}
