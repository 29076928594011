<div class="card" *ngIf="post && post?.event?.eventType"
  [ngClass]="{'_anniversary-post': post?.event?.eventType === eventType.UserAnniversary}">

  <div class="post" [ngClass]="{'_edit': isEdit}">
    <ng-container *ngIf="isEdit; then edit else display"></ng-container>
    <ng-template #edit>
      <app-user-avatar *ngIf="!isEdit" [user]="currentUser" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'">
      </app-user-avatar>
      <div class="post__type post-type" *ngIf="allowNews && !allowShare">
        <a class="post-type__item _text" matTooltip="Текстовая публикация"
          [ngClass]="{'_active':postMode===postModes.text}" href="javascript:;"
          (click)="changePostMode(postModes.text)">Текст</a>
        <a class="post-type__item _news" matTooltip="Новостная публикация"
          [ngClass]="{'_active':postMode===postModes.news}" href="javascript:;"
          (click)="changePostMode(postModes.news)">Новость</a>
      </div>

      <div class="new-post post__news-fields" *ngIf="postMode===postModes.news">
        <div class="input-main new-post__title">
          <input type="text"
                 placeholder="Название новости*"
                 [(ngModel)]="title"
                 class="input-main__field"
                 (keyup)="onKey($event)"
          >
        </div>

        <div class="input-main new-post__subtitle">
          <textarea autosize rows="3" placeholder="Анонс новости" [(ngModel)]="announce" class="input-main__field"
            (keyup)="onKey($event)">
          </textarea>
        </div>
      </div>
      <app-froala-editor
        class="post__editor"
        [ngClass]="{'_full' : isEdit}"
        [simple]="postMode === postModes.text"
        [showToolbar]="isEdit"
        [(editorModel)]="text"
        [(mentions)]="mentionsEdit"
        [(text)]="text"
        (submited)="submit()"
      ></app-froala-editor>
      <div class="post__about">Используйте «@», чтобы упомянуть коллегу</div>

      <!-- post -->
      <div class="post__repost" *ngIf="post.repost">
        <app-post [post]="post.repost" [readOnly]="true"></app-post>
      </div>
      <!-- post END -->

      <app-add-tags *ngIf="postMode===postModes.news" placeholder="Теги" [tagType]="TagType.post" [elementId]="post.event.id" [tags]="tags"
      [mode]="AddTagsComponentMode.edit"></app-add-tags>

      <div class="post__attachments">
        <app-attachments-list #attachmentsList [attachments]="attachments" [isEdit]="true"
          (onRemove)="onRemoveAttachment($event)" (onFilesUploaded)="onFilesUploaded($event)" [ownerId]="ownerId"
          [ownerType]="ownerType" [elementType]="elementType"></app-attachments-list>
        <app-media-attachments-list #mediaAttachmentsList [mediaAttachments]="mediaAttachments" [isEdit]="true"
          (onRemove)="onRemoveMediaAttachment($event)" (onFilesUploaded)="onMediaFilesUploaded($event)"
          [ownerId]="ownerId" [ownerType]="ownerType" [elementType]="elementType"></app-media-attachments-list>
      </div>
      <div class="new-comment__actions">
        <mat-checkbox [(ngModel)]="disableComment">Запретить комментарии</mat-checkbox>
        <mat-checkbox [(ngModel)]="disableLike">Запретить лайки</mat-checkbox>
        <mat-checkbox *ngIf="isBusinessNews()" [(ngModel)]="showOnMainPage">Отображать на главной</mat-checkbox>
        <div *ngIf="ownerType===profileType.Group">
          <mat-checkbox [(ngModel)]="pin">{{pinText()}}</mat-checkbox>
          <div class="post__calendar" *ngIf="pin">
            Закрепить до
            <input placeholder="дд.мм.гггг" matInput [matDatepicker]="dp" [(ngModel)]="pinDateEnd">
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp></mat-datepicker>
          </div>
        </div>
        <mat-checkbox *ngIf="isShowNewEmployeeCheckbox()" [(ngModel)]="isNewEmployee">Новый сотрудник</mat-checkbox>
        <div class="post__calendar" *ngIf="postMode===postModes.news">
          Дата публикации*
          <input placeholder="дд.мм.гггг" matInput [matDatepicker]="dp" [(ngModel)]="publishDate" [max]="maxDate">
          <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
          <mat-datepicker #dp></mat-datepicker>
        </div>
      </div>
      <div class="post__actions" [ngClass]="{'loading _blue': posting}">
        <div>
          <app-media-attachments-menu title="Добавить медиафайл" (choose)="mediaAttachmentsList.onChooseFile($event)"
            (upload)="mediaAttachmentsList.onUploadFiles($event)"></app-media-attachments-menu>
          <app-attachments-menu title="Добавить вложение" (choose)="attachmentsList.onChooseFile($event)"
            (upload)="attachmentsList.onUploadFiles($event)"></app-attachments-menu>
        </div>
        <div class="post__submit">
          <button type="button" class="btn btn-simple" (click)="cancel()">Отмена</button>
          <button type="button" *ngIf="postMode===postModes.news" class="btn btn-simple" (click)="saveAsDraft()"
            [attr.disabled]="isValid()?null:''">Сохранить как черновик</button>
          <button type="button" class="btn btn-primary" (click)="publish()"
            [attr.disabled]="isValid()?null:''">Опубликовать</button>
        </div>
      </div>
    </ng-template>
    <ng-template #empty>
      <app-feed-event-menu (remove)="onRemove()" (edit)="onEdit()" *ngIf="allowEdit || allowDelete"
        [allowEdit]="allowEdit" [allowDelete]="allowDelete"></app-feed-event-menu>
    </ng-template>
    <ng-template #display>
      <ng-container [ngSwitch]="post?.event?.eventType">
        <!-- пользователь создал новую группу-->
        <ng-container *ngSwitchCase="eventType.UserNewGroup">
          <div class="post__author">
            <app-feed-event-menu (remove)="onRemove()" (edit)="onEdit()" (share)="onShare()" *ngIf="allowEdit || allowDelete"
              [allowEdit]="allowEdit" [allowDelete]="allowDelete" [allowShare]="allowShare"></app-feed-event-menu>
            <div class="link-profile">
              <app-user-avatar [user]="post.author" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'">
              </app-user-avatar>
              <span class="link-profile__text">
                <a routerLink="/profile/{{post?.author?.id}}" class="link"
                  (click)="usersService.navigateProfile(post.author?.id)"
                  [appUserTooltip]="post.author?.id">{{post.author?.fullName}}</a>
                создал группу
                <a routerLink="/group/{{post?.event?.ownerID}}"
                  (click)="groupsService.navigateGroup(post?.event?.ownerID)" class="link">{{post?.group?.title}}</a>
              </span>
              <span class="link-profile__subtext">{{post.event?.created | dateSpLike }}</span>
            </div>
          </div>
          <div class="post__text">
            <div class="group _feed">
              <div [attr.style]="groupBackground" class="group__header">
                <a routerLink="/group/{{post?.event?.ownerID}}"
                  (click)="groupsService.navigateGroup(post?.event?.ownerID)"
                  class="main-input group__title">{{post?.group?.title}}</a>
                <!-- добавить класс _active для открытия попапа-->
                <div class="main-input group__type">{{groupTypeName}}</div>
                <div class="group__controls">
                  <button type="button" class="btn btn-secondary btn-icon" *ngIf="showJoin" (click)="join($event)">
                    <img src="{{assetsPrefix}}/assets/images/icons/menu/add.svg">
                    <span>Вступить</span>
                  </button>
                  <button type="button" class="btn btn-secondary btn-icon" *ngIf="showSubscribe"
                    (click)="subscribe($event)">
                    <img src="{{assetsPrefix}}/assets/images/icons/menu/rss.svg">
                    <span>Подписаться</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- пользователь вступил в группу-->
        <ng-container *ngSwitchCase="eventType.GroupUserJoin">
          <ng-container *ngIf="feedType!=3; then groupJoinForUser; else groupJoinForGroup"></ng-container>
          <ng-template #groupJoinForUser>
            <div class="post__author">
              <app-feed-event-menu (remove)="onRemove()" (edit)="onEdit()" (share)="onShare()" *ngIf="allowEdit || allowDelete"
                [allowEdit]="allowEdit" [allowDelete]="allowDelete" [allowShare]="allowShare"></app-feed-event-menu>
              <div class="link-profile">
                <app-user-avatar [user]="post.author" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'">
                </app-user-avatar>
                <span class="link-profile__text">
                  <a routerLink="/profile/{{post?.author?.id}}" class="link"
                    (click)="usersService.navigateProfile(post.author?.id)"
                    [appUserTooltip]="post.author?.id">{{post.author?.fullName}}</a>
                  вступил(-а) в группу
                  <a routerLink="/group/{{group?.id}}" class="link">{{group?.title}}</a>
                </span>
                <span class="link-profile__subtext">{{post.event?.created | dateSpLike }}</span>
              </div>
            </div>
            <div class="post__text">
              <div class="group _feed">
                <div [attr.style]="groupBackground" class="group__header">
                  <a routerLink="/group/{{group?.id}}" (click)="groupsService.navigateGroup(group?.id)"
                    class="main-input group__title">{{group?.title}}</a>
                  <!-- добавить класс _active для открытия попапа-->
                  <div class="main-input group__type">{{getGroupType(group?.type)}}</div>
                  <div class="group__controls">
                    <button type="button" class="btn btn-secondary btn-icon" *ngIf="showJoin" (click)="join($event)">
                      <img src="{{assetsPrefix}}/assets/images/icons/menu/add.svg">
                      <span>Вступить</span>
                    </button>
                    <button type="button" class="btn btn-secondary btn-icon" *ngIf="showSubscribe"
                      (click)="subscribe($event)">
                      <img src="{{assetsPrefix}}/assets/images/icons/menu/rss.svg">
                      <span>Подписаться</span>
                    </button>
                    <!-- <app-group-menu #groupMenu class="btn btn-secondary btn-icon _by-content tooltip" [user]="currentUser" [group]="group" [type]="menuType.Group"
                      *ngIf="(!showSubscribe || !showJoin)" [hideJoin]="!showJoin" [hideSubscribe]="!showSubscribe" [hideDelete]="true"></app-group-menu> -->
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template #groupJoinForGroup>
            <div class="post__author">
              <app-feed-event-menu (remove)="onRemove()" (edit)="onEdit()" (share)="onShare()" *ngIf="allowEdit || allowDelete"
                [allowEdit]="allowEdit" [allowDelete]="allowDelete" [allowShare]="allowShare"></app-feed-event-menu>
              <div class="link-profile">
                <app-user-avatar [user]="post.author" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'">
                </app-user-avatar>
                <span class="link-profile__text">
                  <a routerLink="/profile/{{post?.author?.id}}" class="link"
                    (click)="usersService.navigateProfile(post.author?.id)"
                    [appUserTooltip]="post.author?.id">{{post.author?.fullName}}</a>
                  вступил(-а) в группу
                </span>
                <span class="link-profile__subtext">{{post.event?.created | dateSpLike }}</span>
              </div>
            </div>
          </ng-template>
        </ng-container>

        <!-- добавили нового пользователя в группу-->
        <ng-container *ngSwitchCase="eventType.GroupNewUser">
          <ng-container *ngTemplateOutlet="groupUsersStateChanged"></ng-container>
        </ng-container>

        <!-- удалили пользователя из группы-->
        <ng-container *ngSwitchCase="eventType.GroupUserRemoved">
          <ng-container *ngTemplateOutlet="groupUsersStateChanged"></ng-container>
        </ng-container>

        <!-- пользователь изменил аватар-->
        <ng-container *ngSwitchCase="eventType.UserAvatarChanged">
          <div class="post__author">
            <app-feed-event-menu (remove)="onRemove()" (edit)="onEdit()" (share)="onShare()" *ngIf="allowDelete" [allowEdit]="false"
              [allowDelete]="allowDelete" [allowShare]="allowShare"></app-feed-event-menu>
            <div class="link-profile">
              <app-user-avatar [user]="post.author" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'">
              </app-user-avatar>
              <span class="link-profile__text">
                <a routerLink="/profile/{{post?.author?.id}}" class="link"
                  (click)="usersService.navigateProfile(post.author?.id)"
                  [appUserTooltip]="post.author?.id">{{post.author?.fullName}}</a>
                изменил(-а) фото профиля 
                <ng-container *ngIf="post?.author?.id!=post?.owner?.id">
                  пользователя
                  <a routerLink="/profile/{{post?.owner?.id}}" class="link"
                    (click)="usersService.navigateProfile(post?.owner?.id)"
                    [appUserTooltip]="post.owner?.id">{{post.owner?.fullName}}</a>
                </ng-container>
              </span>
              <span class="link-profile__subtext">{{post.event?.created | dateSpLike }}</span>
            </div>
          </div>
          <div class="post__text">
            <app-user-avatar [avatarImage]="avatarImage" [linkClass]="'profile-photo'" [imageClass]="'avatar__image'"
              [asDiv]="true"></app-user-avatar>
          </div>
        </ng-container>

        <!-- пользователь изменил профиль-->
        <ng-container *ngSwitchCase="eventType.UserProfileEdit">
          <ng-container *ngTemplateOutlet="propertyChangedBody"></ng-container>
        </ng-container>

        <!-- пользователь изменил аватар группы-->
        <ng-container *ngSwitchCase="eventType.GroupAvatarChanged">
          <div class="post__author">
            <app-feed-event-menu (remove)="onRemove()" (edit)="onEdit()" (share)="onShare()" *ngIf="allowEdit || allowDelete"
              [allowEdit]="allowEdit" [allowDelete]="allowDelete" [allowShare]="allowShare"></app-feed-event-menu>
            <div class="link-profile">
              <app-user-avatar [user]="post.author" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'">
              </app-user-avatar>
              <span class="link-profile__text">
                <a routerLink="/profile/{{post?.author?.id}}" class="link"
                  (click)="usersService.navigateProfile(post.author?.id)"
                  [appUserTooltip]="post.author?.id">{{post.author?.fullName}}</a>
                изменил(-а) аватар группы
                <a routerLink="/group/{{post?.event?.ownerID}}" class="link"
                  (click)="groupsService.navigateGroup(post?.event?.ownerID)">{{post?.group?.title}}</a>
              </span>
              <span class="link-profile__subtext">{{post.event?.created | dateSpLike }}</span>
            </div>
          </div>
          <div class="post__text">
            <div class="group _feed _group-avatar-changed">
              <div [attr.style]="getPostPictureUrl()" class="group__header"></div>
            </div>
          </div>
        </ng-container>

        <!-- название группы было изменено -->
        <ng-container *ngSwitchCase="eventType.GroupNameChanged">
          <ng-container *ngTemplateOutlet="propertyChangedBody"></ng-container>
        </ng-container>

        <!-- тип группы был изменён -->
        <ng-container *ngSwitchCase="eventType.GroupTypeChanged">
          <ng-container *ngTemplateOutlet="propertyChangedBody"></ng-container>
        </ng-container>

        <!-- пользователь вышел из группы -->
        <ng-container *ngSwitchCase="eventType.GroupUserLeave">
          <div class="post__author">
            <app-feed-event-menu (remove)="onRemove()" (edit)="onEdit()" (share)="onShare()" *ngIf="allowEdit || allowDelete"
              [allowEdit]="allowEdit" [allowDelete]="allowDelete" [allowShare]="allowShare"></app-feed-event-menu>
            <div class="link-profile">
              <app-user-avatar [user]="post.author" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'">
              </app-user-avatar>
              <span class="link-profile__text">
                <a routerLink="/profile/{{post?.author?.id}}" class="link"
                  (click)="usersService.navigateProfile(post.author?.id)"
                  [appUserTooltip]="post.author?.id">{{post.author?.fullName}}</a>
                вышел из группы
              </span>
              <span class="link-profile__subtext">{{post.event?.created | dateSpLike }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="eventType.UserNewSubscription">
          <div class="post__author">
            <app-feed-event-menu (remove)="onRemove()" (edit)="onEdit()" (share)="onShare()" *ngIf="allowEdit || allowDelete"
              [allowEdit]="allowEdit" [allowDelete]="allowDelete" [allowShare]="allowShare"></app-feed-event-menu>
            <div class="link-profile">
              <app-user-avatar [user]="post.author" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'">
              </app-user-avatar>
              <span class="link-profile__text">
                <a routerLink="/profile/{{post?.author?.id}}" class="link"
                  (click)="usersService.navigateProfile(post.author?.id)"
                  [appUserTooltip]="post.author?.id">{{post.author?.fullName}}</a>
                подписался(-лась) на обновления
                <a routerLink="/profile/{{subscriptionUser?.user?.id}}" class="link"
                  (click)="usersService.navigateProfile(subscriptionUser?.user?.id)">{{subscriptionUser?.user?.fullName}}</a>
              </span>
              <span class="link-profile__subtext">{{post.event?.created | dateSpLike }}</span>
            </div>
          </div>
          <div class="post__text">
            <!-- обрати внимание на модификатор _feed-->
            <div class="profile _feed" *ngIf="subscriptionUser?.user">
              <div class="profile__photo">
                <app-user-avatar [user]="subscriptionUser?.user" [noTooltip]="true" [linkClass]="'profile-photo'"
                  [imageClass]="profile-photo__img"></app-user-avatar>
              </div>
              <div class="profile__name" *ngIf="subscriptionUser?.user?.fullName">
                <a routerLink="/profile/{{subscriptionUser?.user?.id}}"
                  (click)="usersService.navigateProfile(subscriptionUser?.user?.id)">{{subscriptionUser?.user?.fullName}}</a>
              </div>
              <div class="profile__info" *ngIf="subscriptionUser?.user?.jobTitle">{{subscriptionUser?.user?.jobTitle}}
              </div>
              <div class="profile__info" *ngIf="subscriptionUser?.user?.department">
                <a routerLink="/profile/{{subscriptionUser?.user?.id}}/search"
                  fragment="k={{subscriptionUser?.user?.department}}">{{subscriptionUser?.user?.department}}</a>
              </div>
              <div class="profile__info" *ngIf="subscriptionUser?.user?.workPhone">{{subscriptionUser?.user?.workPhone}}
              </div>
              <div class="profile__info" *ngIf="subscriptionUser?.user?.mobilePhone">
                {{subscriptionUser?.user?.mobilePhone}}</div>
              <div class="profile__subscribe" *ngIf="!isSubscribed && subscriptionUser?.user?.id!=currentUser?.id">
                <button type="button" class="btn btn-primary"
                  (click)="subscribe(subscriptionUser?.user?.id)">{{isSubscribed?'Отписаться':'Подписаться'}}</button>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- пост бизнес-группы -->
        <ng-container *ngSwitchCase="eventType.BusinessGroupNewPost">
          <ng-container *ngTemplateOutlet="businessPostTemplate"></ng-container>
        </ng-container>

        <!-- новостной пост бизнес-группы -->
        <ng-container *ngSwitchCase="eventType.BusinessGroupNewsPost">
          <ng-container *ngTemplateOutlet="businessNewsPostTemplate; context: {$implicit: false}"></ng-container>
        </ng-container>

        <!-- новостной пост нового сотрудника -->
        <ng-container *ngSwitchCase="eventType.BusinessGroupNewEmployeePost">
          <ng-container *ngTemplateOutlet="businessNewsPostTemplate; context: {$implicit: true}"></ng-container>
        </ng-container>

        <!-- пост группы -->
        <ng-container *ngSwitchCase="eventType.GroupNewPost">
          <ng-container *ngTemplateOutlet="groupPostTemplate"></ng-container>
        </ng-container>

        <!-- новостной пост группы -->
        <ng-container *ngSwitchCase="eventType.GroupNewsPost">
          <ng-container *ngTemplateOutlet="groupNewsPostTemplate"></ng-container>
        </ng-container>

        <!-- шаблон юбилейного поста -->
        <ng-container *ngSwitchCase="eventType.UserAnniversary">
          <ng-container *ngTemplateOutlet="userAnniversary"></ng-container>
        </ng-container>

        <!-- шаблон ре-поста -->
        <ng-container *ngSwitchCase="eventType.Repost">
          <ng-container *ngTemplateOutlet="repostTemplate"></ng-container>
        </ng-container>

        <!-- обычный пост -->
        <ng-container *ngSwitchDefault>
          <ng-container *ngIf="isExternalPost; then externalPostTemplate; else defaultBodyTemplate;"></ng-container>
        </ng-container>

        <!-- Шаблон бизнес поста -->
        <ng-template #businessPostTemplate>
          <div class="post__author">
            <ng-container *ngTemplateOutlet="postMenuTemplate"></ng-container>
            <div class="link-profile">
              <ng-container *ngIf="!isPostView">
                <app-user-avatar [avatarImage]="businessGroupAvatarImage" [linkClass]="'avatar _40'"
                  [imageClass]="'avatar__image'" routerLink="/group/{{group?.id}}" [name]="post.group?.title"
                  [noStatus]="true">
                </app-user-avatar>
                <span class="link-profile__text">
                  <a routerLink="/group/{{groupId}}" class="link"
                    (click)="navigateGroup($event,groupId)">{{post.group?.title}}</a>
                </span>
              </ng-container>
              <span class="link-profile__subtext">{{post.event?.created | dateSpLike }}</span>
            </div>
          </div>
          <app-message [text]="post.event?.body" [showBody]="showBody" [mentions]="mentions"
            [highlightText]="highlightText"></app-message>
          <app-attachments-list [attachments]="post.attachments" [isEdit]="false" [elementType]="elementType"></app-attachments-list>
          <app-media-attachments-list [mediaAttachments]="post.mediaAttachments" [isEdit]="false" [elementType]="elementType">
          </app-media-attachments-list>
        </ng-template>

        <!-- Шаблон новостного бизнес поста -->
        <ng-template #businessNewsPostTemplate let-isNewEmployee>
          <div class="post__author">
            <ng-container *ngTemplateOutlet="postMenuTemplate"></ng-container>
            <div class="link-profile">
              <ng-container *ngIf="!isPostView">
                <ng-container *ngTemplateOutlet="businessPostAvatar"></ng-container>
              </ng-container>
              <span class="link-profile__subtext">{{post.event?.created | dateSpLike }}</span>
            </div>
          </div>
          <div class="post__text">
            <a *ngIf="!showBody" routerLink="/group/{{post.event?.ownerID}}/post/{{post.event?.id}}"
              class="news-title">{{post.event?.title}}</a>
            <a *ngIf="!showBody && post.event?.announce"
              routerLink="/group/{{post.event?.ownerID}}/post/{{post.event?.id}}"
              class="news-announce">{{post.event?.announce}}</a>

            <div *ngIf="showBody" class="news-title">{{post.event?.title}}</div>
            <div *ngIf="showBody && post.event?.announce" class="news-announce">{{post.event?.announce}}</div>
          </div>
          <app-message *ngIf="showBody" [showBody]="showBody" [text]="post.event?.body" [mentions]="mentions"
            [highlightText]="highlightText" [mentionToCard]="isNewEmployee"></app-message>
          <app-attachments-list [attachments]="post.attachments" [isEdit]="false" [elementType]="elementType"></app-attachments-list>
          <app-media-attachments-list [mediaAttachments]="post.mediaAttachments" [isEdit]="false" [elementType]="elementType">
          </app-media-attachments-list>
        </ng-template>

        <!-- Шаблон поста в группе -->
        <ng-template #groupPostTemplate>
          <div class="post__author">
            <div class="post__menu pull-right">
              <span class="post__pin pull-right" title="Закреплённый пост" *ngIf="post?.event?.pin"></span>
              <app-feed-event-menu [post]="post" (remove)="onRemove()" (edit)="onEdit()" (share)="onShare()" (cancelPin)="onCancelPin()"
                [allowEdit]="allowEdit" [allowDelete]="allowDelete" [allowShare]="allowShare"></app-feed-event-menu>
            </div>
            <div class="link-profile">
              <app-user-avatar [user]="post.author" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'"
                [noStatus]="true">
              </app-user-avatar>
              <span class="link-profile__text">
                <a routerLink="/profile/{{post?.author?.id}}" class="link"
                  (click)="usersService.navigateProfile(post.author?.id)"
                  [appUserTooltip]="post.author?.id">{{post.author?.fullName}}</a>
                добавил(-а) публикацию в группу
                <a routerLink="/group/{{groupId}}" class="link"
                  (click)="navigateGroup($event,groupId)">{{post.group?.title}}</a>
              </span>
              <span class="link-profile__subtext">{{post.event?.created | dateSpLike }}</span>
            </div>
          </div>
          <app-message [text]="post.event?.body" [showBody]="showBody" [mentions]="mentions"
            [highlightText]="highlightText"></app-message>
          <app-attachments-list [attachments]="post.attachments" [isEdit]="false" [elementType]="elementType"></app-attachments-list>
          <app-media-attachments-list [mediaAttachments]="post.mediaAttachments" [isEdit]="false" [elementType]="elementType">
          </app-media-attachments-list>
        </ng-template>

        <!-- Шаблон новостного поста в группе -->
        <ng-template #groupNewsPostTemplate>
          <div class="post__author">
            <div class="post__menu pull-right">
              <span class="post__draft pull-right" title="Черновик" *ngIf="post?.event?.isDraft" matTooltip="Новость не опубликована. Для публикации перейдите в режим редактирования через контекстное меню «…»" matTooltipPosition="above"></span>
              <span class="post__pin pull-right" title="Закреплённый пост" *ngIf="post?.event?.pin"></span>
              <app-feed-event-menu [post]="post" (remove)="onRemove()" (edit)="onEdit()" (share)="onShare()" (cancelPin)="onCancelPin()"
                [allowEdit]="allowEdit" [allowDelete]="allowDelete" [allowShare]="allowShare"></app-feed-event-menu>
            </div>
            <div class="link-profile">
              <app-user-avatar [avatarImage]="groupAvatarImage" [linkClass]="'avatar _40'"
                [imageClass]="'avatar__image'" routerLink="/group/{{groupId}}" [name]="post.group?.title"
                [noStatus]="true">
              </app-user-avatar>
              <span class="link-profile__text">
                <a routerLink="/group/{{groupId}}" class="link"
                  (click)="navigateGroup($event,groupId)">{{post.group?.title}}</a>
              </span>
              <span class="link-profile__subtext">{{post.event?.created | dateSpLike }}</span>
            </div>
          </div>
          <div class="post__text">
            <a *ngIf="!showBody" routerLink="/group/{{post.event?.ownerID}}/post/{{post.event?.id}}"
              class="news-title">{{post.event?.title}}</a>
            <a *ngIf="!showBody && post.event?.announce"
              routerLink="/group/{{post.event?.ownerID}}/post/{{post.event?.id}}"
              class="news-announce">{{post.event?.announce}}</a>

            <div *ngIf="showBody" class="news-title">{{post.event?.title}}</div>
            <div *ngIf="showBody && post.event?.announce" class="news-announce">{{post.event?.announce}}</div>
          </div>
          <app-message *ngIf="showBody" [showBody]="showBody" [text]="post.event?.body" [mentions]="mentions"
            [highlightText]="highlightText"></app-message>
          <app-attachments-list [attachments]="post.attachments" [isEdit]="false" [elementType]="elementType"></app-attachments-list>
          <app-media-attachments-list [mediaAttachments]="post.mediaAttachments" [isEdit]="false" [elementType]="elementType">
          </app-media-attachments-list>
        </ng-template>

        <!-- Шаблон поста из чужого профиля -->
        <ng-template #externalPostTemplate>
          <div class="post__author">
            <app-feed-event-menu [post]="post" (remove)="onRemove()" (edit)="onEdit()" (share)="onShare()" [allowEdit]="allowEdit"
              [allowDelete]="allowDelete" [allowShare]="allowShare"></app-feed-event-menu>
            <div class="link-profile">
              <app-user-avatar [user]="post.author" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'">
              </app-user-avatar>
              <span class="link-profile__text">
                <a routerLink="/profile/{{post?.author?.id}}" class="link"
                  (click)="usersService.navigateProfile(post.author?.id)"
                  [appUserTooltip]="post.author?.id">{{post.author?.fullName}}</a>
                добавил(-а) публикацию пользователю
                <a routerLink="/profile/{{post?.owner?.id}}" class="link"
                  (click)="usersService.navigateProfile(post?.owner?.id)"
                  [appUserTooltip]="post.owner?.id">{{post.owner?.fullName}}</a>
              </span>
              <span class="link-profile__subtext">{{post.event?.created | dateSpLike }}</span>
            </div>
          </div>
          <app-message [text]="post.event?.body" [showBody]="showBody" [mentions]="mentions"
            [highlightText]="highlightText"></app-message>
          <app-attachments-list [attachments]="post.attachments" [isEdit]="false" [elementType]="elementType"></app-attachments-list>
          <app-media-attachments-list [mediaAttachments]="post.mediaAttachments" [isEdit]="false" [elementType]="elementType">
          </app-media-attachments-list>
        </ng-template>

        <!-- Шаблон юбилея -->
        <ng-template #userAnniversary>
          <div class="post__author _anniversary">
            <app-feed-event-menu [post]="post" (remove)="onRemove()" (edit)="onEdit()" (share)="onShare()" [allowEdit]="allowEdit"
              [allowDelete]="allowDelete" [allowShare]="allowShare"></app-feed-event-menu>
            <img src="{{assetsPrefix}}/assets/images/anniversary/party.svg" alt="" class="anniversary-party">
            <div class="link-profile">
              <app-user-avatar [user]="post.owner" [linkClass]="'avatar _80 _anniversary-post'"
                [imageClass]="'avatar__image'">
              </app-user-avatar>
            </div>
            <span class="anniversary _post" *ngIf="post?.event?.data?.anniversary">
              <span class="anniversary__number">{{post?.event?.data?.anniversary}}</span> лет с нами
            </span>
          </div>
          <div class="anniversary-greetings">{{post?.owner?.fullName}}</div>
          <div class="anniversary-greetings">С юбилеем работы в компании!</div>
          <app-message [text]="post.event?.body" [showBody]="true" [mentions]="mentions"
            [highlightText]="highlightText"></app-message>
          <div class="post__sign">
            <div class="co">
              <div class="co__avatar">
                <app-user-avatar [user]="post.author" [linkClass]="'avatar _80'" [imageClass]="'avatar__image'">
                </app-user-avatar>
              </div>
              <div class="co__info">
                <a href="javascript:;" routerLink="/profile/{{post?.author?.id}}" class="link co__name"
                  (click)="usersService.navigateProfile(post?.author?.id)"
                  [appUserTooltip]="post.author?.id">{{post.author?.fullName}}</a>
                <div class="co__occ" *ngIf="post.author?.jobTitle">{{post.author?.jobTitle}}</div>
                <span class="text-secondary">{{post.event?.created | dateSpLike }}</span>
              </div>
            </div>
          </div>
          <app-attachments-list [attachments]="post.attachments" [isEdit]="false" [elementType]="elementType"></app-attachments-list>
          <app-media-attachments-list [mediaAttachments]="post.mediaAttachments" [isEdit]="false" [elementType]="elementType">
          </app-media-attachments-list>
        </ng-template>

        <!-- Шаблон ре поста -->
        <ng-template #repostTemplate>

          <ng-container *ngIf="!isExternalPost">
            <ng-container *ngTemplateOutlet="simpleUserAuthor"></ng-container>
          </ng-container>
          <ng-container *ngIf="isExternalPost && ownerType === profileType.User">
            <ng-container *ngTemplateOutlet="anotherUserAuthor"></ng-container>
          </ng-container>
          <ng-container *ngIf="isExternalPost && ownerType === profileType.Group">
            <ng-container *ngTemplateOutlet="anotherGroupAuthor"></ng-container>
          </ng-container>

          <app-message [text]="post.event?.body" [showBody]="showBody" [mentions]="mentions"
            [highlightText]="highlightText"></app-message>

          <div class="post__repost" *ngIf="post.repost">
            <app-post [post]="post.repost" [readOnly]="true"></app-post>
          </div>

          <app-attachments-list [attachments]="post.attachments" [isEdit]="false" [elementType]="elementType"></app-attachments-list>
          <app-media-attachments-list [mediaAttachments]="post.mediaAttachments" [isEdit]="false" [elementType]="elementType">
          </app-media-attachments-list>
        </ng-template>

        <!-- Шаблон обычного поста -->
        <ng-template #defaultBodyTemplate>
          <!-- postId={{post?.event?.id}} eventType={{post?.event?.eventType}} -->
          <div class="post__author">
            <app-feed-event-menu [post]="post" (remove)="onRemove()" (edit)="onEdit()" (share)="onShare()" [allowEdit]="allowEdit"
              [allowDelete]="allowDelete" [allowShare]="allowShare"></app-feed-event-menu>
            <a routerLink="/profile/{{post?.author?.id}}" class="link-profile"
              (click)="usersService.navigateProfile(post.author?.id)">
              <app-user-avatar [user]="post.author" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'"
                [asSpan]="true"></app-user-avatar>
              <span class="link-profile__text" [appUserTooltip]="post.author?.id">{{post.author?.fullName}}</span>
              <span class="link-profile__subtext">{{post.event?.created | dateSpLike }}</span>
            </a>
          </div>
          <app-message [text]="post.event?.body" [showBody]="showBody" [mentions]="mentions"
            [highlightText]="highlightText"></app-message>
          <app-attachments-list [attachments]="post.attachments" [isEdit]="false" [elementType]="elementType"></app-attachments-list>
          <app-media-attachments-list [mediaAttachments]="post.mediaAttachments" [isEdit]="false" [elementType]="elementType">
          </app-media-attachments-list>
        </ng-template>

        <!-- шаблон сообщения об изменении свойства профиля -->
        <ng-template #propertyChangedBody>
          <div class="post__author">
            <app-feed-event-menu (remove)="onRemove()" (edit)="onEdit()" (share)="onShare()" *ngIf="allowDelete" [allowEdit]="false"
              [allowDelete]="allowDelete" [allowShare]="allowShare"></app-feed-event-menu>
            <div class="link-profile">
              <app-user-avatar [user]="post.author" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'">
              </app-user-avatar>
              <span class="link-profile__text">
                <a routerLink="/profile/{{post?.author?.id}}" class="link"
                  (click)="usersService.navigateProfile(post.author?.id)"
                  [appUserTooltip]="post.author?.id">{{post.author?.fullName}}</a>
                изменил(-а) {{propertyNameText}}
                <a *ngIf="post?.group" routerLink="/group/{{post?.event?.ownerID}}" class="link"
                  (click)="groupsService.navigateGroup(post?.event?.ownerID)">{{post?.group?.title}}</a>
              </span>
              <span class="link-profile__subtext">{{post.event?.created | dateSpLike }}</span>
            </div>
          </div>
          <div class="post__text" *ngIf="propertyDisplayNameText">{{propertyDisplayNameText}}: {{propertyValue}}</div>
        </ng-template>

        <!-- шаблон добавления удаления пользователя из группы -->
        <ng-template #groupUsersStateChanged>
          <div class="post__author">
            <app-feed-event-menu (remove)="onRemove()" (edit)="onEdit()" (share)="onShare()" *ngIf="allowEdit || allowDelete"
              [allowEdit]="allowEdit" [allowDelete]="allowDelete" [allowShare]="allowShare"></app-feed-event-menu>
            <div class="link-profile">
              <app-user-avatar [user]="post.author" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'">
              </app-user-avatar>
              <span class="link-profile__text">
                <a routerLink="/profile/{{post?.author?.id}}" class="link"
                  (click)="usersService.navigateProfile(post.author?.id)"
                  [appUserTooltip]="post.author?.id">{{post.author?.fullName}}</a>
                {{groupUsersAddedOrRemovedText}}
                <a *ngIf="post?.group" routerLink="/group/{{post?.event?.ownerID}}" class="link"
                  (click)="groupsService.navigateGroup(post?.event?.ownerID)">{{post?.group?.title}}</a>
                {{groupUsersAddedOrRemovedSuffix}}
                <ng-container *ngFor="let u of groupUsersAddedOrRemoved; let i = index; let last = last">
                  <a routerLink="/profile/{{u?.id}}" class="link" (click)="usersService.navigateProfile(u?.id)"
                    [appUserTooltip]="u?.id">{{u?.fullName}}</a>
                  <span *ngIf="!last">,</span>
                </ng-container>
              </span>
              <span class="link-profile__subtext">{{post.event?.created | dateSpLike }}</span>
            </div>
          </div>
        </ng-template>

        <!-- шаблон меню поста -->
        <ng-template #postMenuTemplate>
          <div class="post__menu pull-right">
            <span class="post__draft pull-right" title="Черновик" *ngIf="post?.event?.isDraft" matTooltip="Новость не опубликована. Для публикации перейдите в режим редактирования через контекстное меню «…»" matTooltipPosition="above"></span>
            <span class="post__pin pull-right" title="Закреплённый пост" *ngIf="post?.event?.pin"></span>
            <app-feed-event-menu [post]="post" (remove)="onRemove()" (edit)="onEdit()" (share)="onShare()" (cancelPin)="onCancelPin()"
              [allowEdit]="allowEdit" [allowDelete]="allowDelete" [allowShare]="allowShare"></app-feed-event-menu>
          </div>
        </ng-template>

        <!-- шаблон аватара бизнес-поста -->
        <ng-template #businessPostAvatar>
          <app-user-avatar [avatarImage]="businessGroupAvatarImage" [linkClass]="'avatar _40'"
            [imageClass]="'avatar__image'" routerLink="/group/{{groupId}}" [name]="post.group?.title"
            [noStatus]="true">
          </app-user-avatar>
          <span class="link-profile__text">
            <a routerLink="/group/{{groupId}}" class="link"
              (click)="navigateGroup($event,groupId)">{{post.group?.title}}</a>
          </span>
        </ng-template>

        <ng-template #simpleUserAuthor>
          <div class="post__author">
            <app-feed-event-menu [post]="post" (remove)="onRemove()" (edit)="onEdit()" (share)="onShare()" [allowEdit]="allowEdit"
              [allowDelete]="allowDelete" [allowShare]="allowShare"></app-feed-event-menu>
            <a routerLink="/profile/{{post?.author?.id}}" class="link-profile"
              (click)="usersService.navigateProfile(post.author?.id)">
              <app-user-avatar [user]="post.author" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'"
                [asSpan]="true"></app-user-avatar>
              <span class="link-profile__text" [appUserTooltip]="post.author?.id">{{post.author?.fullName}}</span>
              <span class="link-profile__subtext">{{post.event?.created | dateSpLike }}</span>
            </a>
          </div>
        </ng-template>

        <ng-template #anotherUserAuthor>
          <div class="post__author">
            <app-feed-event-menu [post]="post" (remove)="onRemove()" (edit)="onEdit()" (share)="onShare()" [allowEdit]="allowEdit"
              [allowDelete]="allowDelete" [allowShare]="allowShare"></app-feed-event-menu>
            <div class="link-profile">
              <app-user-avatar [user]="post.author" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'">
              </app-user-avatar>
              <span class="link-profile__text">
                <a routerLink="/profile/{{post?.author?.id}}" class="link"
                  (click)="usersService.navigateProfile(post.author?.id)"
                  [appUserTooltip]="post.author?.id">{{post.author?.fullName}}</a>
                добавил(-а) публикацию пользователю
                <a routerLink="/profile/{{post?.owner?.id}}" class="link"
                  (click)="usersService.navigateProfile(post?.owner?.id)"
                  [appUserTooltip]="post.owner?.id">{{post.owner?.fullName}}</a>
              </span>
              <span class="link-profile__subtext">{{post.event?.created | dateSpLike }}</span>
            </div>
          </div>
        </ng-template>

        <ng-template #anotherGroupAuthor>
          <div class="post__author">
            <div class="post__menu pull-right">
              <span class="post__pin pull-right" title="Закреплённый пост" *ngIf="post?.event?.pin"></span>
              <app-feed-event-menu [post]="post" (remove)="onRemove()" (edit)="onEdit()" (share)="onShare()" (cancelPin)="onCancelPin()"
                [allowEdit]="allowEdit" [allowDelete]="allowDelete" [allowShare]="allowShare"></app-feed-event-menu>
            </div>
            <div class="link-profile">
              <app-user-avatar [user]="post.author" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'"
                [noStatus]="true">
              </app-user-avatar>
              <span class="link-profile__text">
                <a routerLink="/profile/{{post?.author?.id}}" class="link"
                  (click)="usersService.navigateProfile(post.author?.id)"
                  [appUserTooltip]="post.author?.id">{{post.author?.fullName}}</a>
                добавил(-а) публикацию в группу
                <a routerLink="/group/{{groupId}}" class="link"
                  (click)="navigateGroup($event,groupId)">{{post.group?.title}}</a>
              </span>
              <span class="link-profile__subtext">{{post.event?.created | dateSpLike }}</span>
            </div>
          </div>
        </ng-template>

      </ng-container>
    <app-add-tags *ngIf="isPostView && postMode===postModes.news" placeholder="Теги" [tagType]="TagType.post" [elementId]="post.event.id" [ownerId]="ownerId" [ownerType]="ownerType" [mode]="AddTagsComponentMode.view"></app-add-tags>
    <div class="post__actions" *ngIf="isShowActions()">
        <app-like *ngIf="!post.event.disableLike" [count]="post.likes.count" [canLike]="post.likes.canLike"
          [elementId]="post.event.id" [elementType]="elementType"></app-like>
        <button *ngIf="!post.event.disableComment" class="btn btn-post _comment"
          [ngClass]="{'transparent-text': post.commentsCount === 0}"
          type="button">{{post.commentsCount}}</button>
        <a *ngIf="!post.event.disableComment" href="javascript:;" class="link" (click)="reply()">Ответить</a>
      </div>
    </ng-template>
    <app-comments-list *ngIf="!post.event.disableComment && !readOnly" #commentsList [count]="post.commentsCount"
      [comments]="post.comments" [elementId]="post.event.id" [elementType]="elementType"
      (commentReply)="commentNew.activate($event)" (commentAdded)="onCommentAdded($event)"
      (commentDeleted)="onCommentDeleted($event)" [highlightText]="highlightText" [elementAuthorId]="post.author.id"
      [showBody]="showBody"></app-comments-list>
    <app-comment-new *ngIf="!post.event.disableComment && !readOnly" #commentNew [elementId]="post.event.id"
      [elementType]="elementType" [autoHide]="post.commentsCount == 0" (commentAdded)="onNewCommentAdded($event)">
    </app-comment-new>
  </div>
</div>
