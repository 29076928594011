import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@app/core/components/base.component';
import { Tabs, Tab } from '@app/shared/components/tabs/tab.model';
import { HelperService } from '@app/core/services/helper.service';
import { BreadcrumbsService, IBreadcrumb } from '@app/shared/services/breadcrumbs.service';

/**
 * Компонент отображения табиков
 *
 * @export
 * @class TabsComponent
 * @extends {BaseComponent}
 */
@Component({
    selector: 'app-tabs',
    templateUrl: 'tabs.component.html'
})

export class TabsComponent extends BaseComponent implements OnDestroy {
    /**
     * Все табики
     *
     * @type {Tabs<any>}
     * @memberof TabsComponent
     */
    @Input() tabs: Tabs<any>;
    /**
     * Текущий табик
     *
     * @type {Tab<any>}
     * @memberof TabsComponent
     */
    activeTab: Tab<any>;
    /**
     * Скрыть счётчики
     *
     * @type {boolean}
     * @memberof TabsComponent
     */
    @Input() hideCounters: boolean;

    /**
     * Добавлять табик к хлебным крошкам
     *
     * @type {boolean}
     * @memberof TabsComponent
     */
    @Input() addTabToBreadcrumbs: boolean;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected breadcrumbsService: BreadcrumbsService,
        public helper: HelperService) { super(helper); }

    /**
     * Является ли табик активный
     *
     * @param {*} tab
     * @returns
     * @memberof TabsComponent
     */
    isActive(tab) {
        let isActive: boolean;
        if (tab.fragment) {
            isActive = this.router.url.startsWith(this.tabs.routerLink) && this.router.url.indexOf(`#${tab.fragment}`) !== -1;
        } else {
            let active = true;

            this.tabs.items.forEach(item => {
                if (item.fragment !== tab.fragment && this.router.url.indexOf(`#${item.fragment}`) !== -1) {
                    active = false;
                }
            });

            isActive = this.router.url.startsWith(this.tabs.routerLink) && active;
        }

        if (isActive) {
            this.activeTab = tab;
        }

        return isActive;
    }

    /**
     * Проверить, что есть хоть один активный элемент
     *
     * @memberof TabsComponent
     */
    checkIsAnyActive() {
        if (!this.activeTab) {
            const activeItems = this.tabs.items.filter(t => t.default);
            if (activeItems.length) {
                this.activeTab = activeItems[0];
            }
        }

        // проставляем хлебные крошки при инициализации
        this.addTab(this.activeTab);

        // this.breadcrumbsService.set(this.activeTab.title, null, this.tabs.routerLink, this.activeTab.fragment);
    }

    /**
     * Получить фрагмент (хэш)
     *
     * @param {*} tab
     * @returns
     * @memberof TabsComponent
     */
    getFragment(tab) {
        return tab.additionalFragment ? tab.fragment + tab.additionalFragment : tab.fragment;
    }

    /**
     * Перейти по табику
     *
     * @param {Tab<any>} tab
     * @memberof TabsComponent
     */
    navigate(tab: Tab<any>) {
        const fragment = this.getFragment(tab);
        if (this.getFragment) {
            this.router.navigate([this.tabs.routerLink], { fragment: fragment });
        } else {
            this.router.navigate([this.tabs.routerLink]);
        }

        this.addTab(tab);
    }

    private addTab(tab: Tab<any>): void {
        let breadcrumbs: IBreadcrumb[] = [];

        if (this.addTabToBreadcrumbs) {
            breadcrumbs = [
                {
                    title: tab.title,
                    url: null,
                    routerUrl: this.tabs.routerLink,
                    fragment: tab.fragment
                }
            ];
        }
        if (this.tabs && !this.tabs.noBreadcrumbs) {
            this.breadcrumbsService.breadcrumbs.next(this.addDefaultTab(tab, breadcrumbs));
        }
    }

    private addDefaultTab(tab: Tab<any>, breadCrumbs: IBreadcrumb[]): IBreadcrumb[] {
        const defaultTab = this.tabs.items.find(s => s.default);
        if (defaultTab && this.tabs.title) {
            breadCrumbs.unshift({
                title: this.tabs.title,
                url: null,
                routerUrl: this.tabs.routerLink,
                fragment: defaultTab.fragment
            });
        }
        return breadCrumbs;
    }

    ngOnDestroy(): void {
        this.breadcrumbsService.clear();
        super.ngOnDestroy();
    }
}
