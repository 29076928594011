import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BirthdaysWidgetComponent } from './components/birthdays-widget/birthdays-widget.component';
import { SharedModule } from '@app/shared/shared.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ],
  declarations: [
    BirthdaysWidgetComponent
  ],
  exports: [
    BirthdaysWidgetComponent
  ]
})
export class BirthdaysModule { }
