import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { IdeasService } from '@app/ideas/services/ideas.service';
import { AlertsService } from '@app/shared/services/alerts.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelperService } from '@app/core/services/helper.service';
import { take } from 'rxjs/operators';
import { IIdeaStatusChangesModel, IIdeaStatusChanges, IStatusChange } from '@app/ideas/model/idea';
import * as moment from 'moment';

/**
 * Данные, передаваемые в модальное окно
 *
 * @export
 * @interface IdeaStatusHistoryModalData
 */
export interface IdeaStatusHistoryModalData {
  onOk: EventEmitter<boolean> | Function;
  ideaId: number;
}

/**
 * История изменения статусов идеи
 *
 * @export
 * @class IdeaStatusHistoryModalComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-idea-status-history-modal',
  templateUrl: './idea-status-history-modal.component.html',
  styleUrls: ['./idea-status-history-modal.component.scss']
})
export class IdeaStatusHistoryModalComponent extends BaseComponent implements OnInit {

  statusChanges: IIdeaStatusChangesModel;

  constructor(
    private ideasService: IdeasService,
    protected alertsService: AlertsService,
    public dialogRef: MatDialogRef<IdeaStatusHistoryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IdeaStatusHistoryModalData,
    public helper: HelperService
  ) {
    super(helper);
    this.ideasService.getIdeaStatusHistory(this.data.ideaId)
      .pipe(take(1)).subscribe(res => {
        this.statusChanges = res;
      });
  }

  ngOnInit() {

  }

  getDateChangeText(change: IStatusChange) {
    let changeText = '';
    if (change) {
      if (change.dateChange) {
        changeText += `c ${moment(change.dateChange).format('DD.MM.YY')}`;

        if (change.dateEnd) {
          changeText += ` по ${moment(change.dateEnd).format('DD.MM.YY')}`;
        }
      }
    }
    return changeText;
  }

}
