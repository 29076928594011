import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  NgZone,
} from '@angular/core';
import { AlbumListComponent } from '@app/gallery/components/lists/album-list/album-list.component';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { GalleryService } from '@app/gallery/services/gallery.service';
import { UsersService } from '@app/profile/services/users.service';
import { HelperService } from '@app/core/services/helper.service';
import { IAlbum } from '@app/gallery/model/album-model';
import { SignalRService } from '@app/signalr/signalR.service';
import { ProfileType } from '@app/core/model/profile-type.enum';

/**
 * Компонент отображения результатов поиска по альбомам
 *
 * @export
 * @class SearchAlbumResultsComponent
 * @extends {AlbumListComponent}
 * @implements {OnInit}
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-search-album-results',
  templateUrl: './search-album-results.component.html',
  styleUrls: ['./search-album-results.component.scss'],
})
export class SearchAlbumResultsComponent
  extends AlbumListComponent
  implements OnInit, OnChanges {
  /**
   * Альбомы из результатов поиска
   *
   * @type {IAlbum[]}
   * @memberof SearchAlbumResultsComponent
   */
  @Input() albums: IAlbum[];

  /**
   * Все найденные альбомы отрисованы?
   *
   * @type {boolean}
   * @memberof SearchAlbumResultsComponent
   */
  @Input() albumsLoaded: boolean;

  /**
   * Событие при нажатии "Показать ещё альбомы"
   *
   * @type {EventEmitter<IAlbum[]>}
   * @memberof SearchAlbumResultsComponent
   */
  @Output() showMoreEvent: EventEmitter<IAlbum[]> = new EventEmitter();

  /**
   * Событие при нажатии "Свернуть список"
   *
   * @type {EventEmitter<IAlbum[]>}
   * @memberof SearchAlbumResultsComponent
   */
  @Output() hideMoreEvent: EventEmitter<IAlbum[]> = new EventEmitter();

  constructor(
    public sanitizer: DomSanitizer,
    public router: Router,
    public galleryService: GalleryService,
    public usersService: UsersService,
    public signalRService: SignalRService,
    public ngZone: NgZone,
    public helper: HelperService
  ) {
    super(
      sanitizer,
      router,
      galleryService,
      usersService,
      signalRService,
      ngZone,
      helper
    );
  }

  /**
   * Переопределяем OnInit, чтобы не выполнялся родитеслький метод получения медиафайлов
   *
   * @memberof SearchAlbumResultsComponent
   */
  ngOnInit() {}

  /**
   * Переопределяем OnChanges, чтобы не выполнялся родитеслький метод получения медиафайлов
   *
   * @param {SimpleChanges} changes
   * @memberof SearchAlbumResultsComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['albums']) {
      if (this.albums) {
        this.loaded = true;
      }
    }
  }

  /**
   * Пробрасываем содытие "наверх" при нажатии на "Показать ещё"
   *
   * @memberof SearchAlbumResultsComponent
   */
  showMore() {
    this.showMoreEvent.emit(this.albums);
  }

  /**
   * Отображать ссылку "Свернуть список"
   *
   * @returns
   * @memberof SearchAlbumResultsComponent
   */
  showHideMore() {
    return this.albums && this.albums.length > 4;
  }

  /**
   * Отображать блок "Показать ещё"
   *
   * @returns
   * @memberof SearchAlbumResultsComponent
   */
  showShowMore() {
    return this.albums && !this.albumsLoaded;
  }

  /**
   * Свернуть список
   * @memberof SearchAlbumResultsComponent
   */
  hideMore() {
    this.albums = this.albums.slice(0, 4);
    this.hideMoreEvent.emit(this.albums);
  }

  /**
   * Перейти в альбом
   * @memberof SearchAlbumResultsComponent
   */
  navigate(album: IAlbum): void {
    if (!album) {
      this.router.navigate(['/404'], { skipLocationChange: true });
      return;
    }
    this.router.navigate([this.getAlbumRouterLink(album)]);
  }

  /**
   * Получить ссылку на альбом
   * @memberof SearchAlbumResultsComponent
   */
  getAlbumRouterLink(album: IAlbum) {
    if (!album) {
      return '/404';
    }
    if (album.owner_Type === ProfileType.Group) {
      return `/group/${album.ownerId}/gallery/${album.id}`;
    } else {
      return `/profile/${album.ownerId}/gallery/${album.id}`;
    }
  }
}
