<div class="post__comments" *ngIf="count">
  <div class="card">
    <a href="javascript:;" *ngIf="previousCommentsCount > 0" class="link _block post__comments-before"
      (click)="showPrevious()">Предыдущие комментарии
      <span class="link__count">{{previousCommentsCount}}</span>
    </a>
    <a href="javascript:;" *ngIf="comments?.length > 2" class="link _block post__comments-before"
      (click)="hideComments()">Скрыть комментарии
    </a>
    <ng-container *ngFor="let comment of comments">
      <app-comment [comment]="comment" [config]="config" [elementAuthorId]="elementAuthorId"
        (onReply)="onCommentReply($event)" [highlightText]="highlightText" [showBody]="showBody" (onRemove)="removeComment($event)"></app-comment>
    </ng-container>
  </div>
</div>