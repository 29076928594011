import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { HelperService } from '@app/core/services/helper.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { IUserEmailSent, IUserRelation } from '../model/user-relation';

/**
 * API-сервис связей пользователей
 */
@Injectable({
  providedIn: 'root'
})
export class UserRelationsApiService extends BaseService {
  protected serviceUrl = `${environment.siteUrl}/api/usersRelation`;

  constructor(
    protected http: HttpClient,
    public helper: HelperService) { super(http, helper); }

  /**
   * Получить все связи пользователей
   */
  getUserRelations(unique: boolean, offset: number = 0, limit: number = 50): Observable<IUserRelation[]> {
    return super.get(`userRelations?unique=${unique}&offset=${offset}&limit=${limit}`);
  }

  /**
   * Есть ли связь у текущего пользователя
   */
  isUserHasRelation(): Observable<boolean> {
    return super.get(`isUserHasRelation`);
  }

  /**
   * Добавление связи для текущего пользователя
   */
  addUserRelation(): Observable<boolean> {
    return super.post(`addUserRelation`, {});
  }

  /**
   * Запустить формирование пар
   */
  startUserRelations(): Observable<boolean> {
    return super.post(`startUserRelations`, {});
  }

  removeUserRelation(): Observable<boolean> {
    return super.post(`removeUserRelation`, {});
  }

  removePairs(): Observable<boolean> {
    return super.post(`removePairs`, {});
  }

  notificateUsers(): Observable<IUserEmailSent[]> {
    return super.post(`notificateUsers`, {});
  }
}
