import { Component, OnInit, OnDestroy, forwardRef, ViewChild } from '@angular/core';
import { User } from '@app/profile/model/user.model';
import { INewPage, IUpdatePage } from '@app/pages/model/page';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { take, filter, takeUntil } from 'rxjs/operators';
import { PagesService } from '@app/pages/services/pages.service';
import { AlertsService } from '@app/shared/services/alerts.service';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { TagType } from '@app/shared/model/tag';
import { AddTagsComponent, AddTagsComponentMode } from '@app/shared/components/add-tags/add-tags.component';

/**
 * Форма создания новой страницы
 *
 * @export
 * @class PageNewComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-page-new',
  templateUrl: './page-new.component.html',
  styleUrls: ['./page-new.component.scss']
})
export class PageNewComponent extends BaseComponent implements OnInit, OnDestroy {

  @ViewChild(forwardRef(() => AddTagsComponent), { static: false })
  protected tagsList: AddTagsComponent;

  page: INewPage | IUpdatePage = {
    title: '',
    body: '',
    ownerId: null,
    tags: [],
  };

  mentions: User[] = [];
  sending: boolean;

  TagType = TagType;
  AddTagsComponentMode = AddTagsComponentMode;

  constructor(
    public pagesService: PagesService,
    protected alertsService: AlertsService,
    protected breadcrumbsService: BreadcrumbsService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected dialog: MatDialog,
    public helper: HelperService
  ) { super(helper); }

  ngOnInit(): void {
    this.route.queryParams.pipe(
      filter(params => params.parentId),
      takeUntil(this.destroyed$))
      .subscribe(params => {
        if (params.parentId) {
          this.page.parentId = params.parentId;
        }
      });
  }

  /**
   * Отправить данные формы на сервер
   *
   * @memberof SurveyNewComponent
   */
  submit() {
    this.page.published = true;
    this.send();
  }

  /**
   * Отмена изменений
   *
   * @memberof SurveyNewComponent
   */
  cancel() {
    this.dialog.open(ModalConfirmationComponent, {
      data: {
        text: `Вы действительно хотите уйти с формы?`,
        onOk: () => { this.router.navigate([`..`], { relativeTo: this.route }); }
      }
    });
  }

  /**
   * Сохранить как ерновик
   *
   * @memberof SurveyNewComponent
   */
  draft() {
    this.page.published = false;
    this.send();
  }

  /**
   * Отправить данные формы на сервер
   *
   * @private
   * @memberof SurveyNewComponent
   */
  protected send() {
    if (this.isValid()) {

      this.sending = true;
      this.page.tags = this.tagsList.tags.map(s => s.id);
      this.pagesService.createPage(this.page).pipe(takeUntil(this.destroyed$)).subscribe(res => {
        this.sending = false;
        if (this.page.published) {
          this.alertsService.riseSuccess(`Страница '${this.page.title}' была успешно создана`);
          this.router.navigate([`../${res.id}`], { relativeTo: this.route });
        } else {
          this.router.navigate([`../${res.id}/edit`], { relativeTo: this.route });
          this.alertsService.riseSuccess(`Страница '${this.page.title}' была успешно сохранена как черновик`);
        }
      }, error => {
        this.sending = false;
        this.alertsService.riseError(`Произошла ошибка при создании страницы '${this.page.title}'`);
      });
    }
  }

  /**
   * Валидна ли форма?
   */
  isValid(): boolean {
    return !this.sending && this.page.title && this.page.body ? true : false;
  }
}
