import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GalleryApiService } from '@app/gallery/services/gallery-api.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { IAlbum } from '@app/gallery/model/album-model';
import { Helper } from '@app/core/helpers/helper';
import { AlertsService } from '@app/shared/services/alerts.service';
import { Router } from '@angular/router';
import { ProfileType } from '@app/core/model/profile-type.enum';
import {
  IMediaFile,
  MediaFileType,
  IMediaFileMovedModel,
} from '../model/media-file';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { HelperService } from '@app/core/services/helper.service';
import { GroupInfo } from '@app/groups/model/group-info.model';
import { User } from '@app/profile/model/user.model';
import * as moment from 'moment';
import { MediaFileUploadV2Service } from '@app/shared/services/media-file-upload-v2.service';

export interface GalleryOwner {
  ownerType: ProfileType;
  owner: User | GroupInfo;
}

/**
 * Расширенный сервис галереи
 *
 * @export
 * @class GalleryService
 * @extends {GalleryApiService}
 */
@Injectable()
export class GalleryService extends GalleryApiService {
  mediaFilesUploading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  currentAlbum: BehaviorSubject<IAlbum> = new BehaviorSubject<IAlbum>(null);
  currentOwner: BehaviorSubject<GalleryOwner> =
    new BehaviorSubject<GalleryOwner>(null);

  albumDeleted: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  mediaFileDeleted: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  mediaFileMoved$: BehaviorSubject<IMediaFileMovedModel> =
    new BehaviorSubject<IMediaFileMovedModel>(null);
  mediaFileAdded: Subject<void> = new Subject();

  preview: string =
    environment.assetsPrefix + '/assets/images/gallery-preview.svg';
  videoPreview: string =
    environment.assetsPrefix + '/assets/images/video-preview.svg?v=2';

  constructor(
    protected mediaFileUploadSevice: MediaFileUploadV2Service,
    protected http: HttpClient,
    protected alertsService: AlertsService,
    protected router: Router,
    public sanitizer: DomSanitizer,
    public helper: HelperService
  ) {
    super(mediaFileUploadSevice, http, helper);
  }

  copyToClipboard(url: string, text: string): void {
    if (Helper.copyToClipboard(url)) {
      this.alertsService.riseSuccess(
        `Ссылка на ${text} была успешно скопирована в буфер обмена`
      );
    } else {
      this.alertsService.riseError(
        `Ссылка на ${text} не была скопирована в буфер обмена`
      );
    }
  }

  navigateAlbum(
    id: number,
    ownerId: number,
    ownerType: ProfileType,
    fragment: string = null
  ): void {
    const album = this.currentAlbum.getValue();
    if (album != null && id !== album.id) {
      this.currentAlbum.next(null);
    }

    let url = '';

    if (ownerType === ProfileType.User) {
      url += `/profile/${ownerId}/gallery/${id}`;
    } else {
      url += `/group/${ownerId}/gallery/${id}`;
    }

    if (fragment) {
      this.router.navigate([url], { fragment: fragment });
    } else {
      this.router.navigate([url]);
    }
  }

  /**
   * Стиль для превью медиа-файла
   *
   * @param {IMediaFile} m
   * @returns {SafeStyle}
   * @memberof MediaListComponent
   */
  getMediaFilePreview(m: IMediaFile): SafeStyle {
    let preview: string = this.preview;

    if (m) {
      if (m.croppedUrl) {
        preview = m.croppedUrl;
      } else if (m.previewUrl) {
        preview = m.previewUrl;
      } else {
        let url = this.getMediaFilePreviewUrl(m);
        if (m.toUpload) {
          if (m.tempUrl) {
            url = m.tempUrl;
          }
        }

        if (url) {
          preview = url;
          m.previewUrl = url;
        }
      }
    }

    if (preview === this.preview) {
      console.log(preview);
    }

    const style = `url('${preview}')`;

    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  /**
   * Получить url превью медиафайла
   *
   * @param {IMediaFile} mediaFile
   * @returns
   * @memberof GalleryService
   */
  getMediaFilePreviewUrl(mediaFile: IMediaFile) {
    let url = '';
    if (mediaFile && mediaFile.url) {
      switch (mediaFile.type) {
        case MediaFileType.image:
          url = mediaFile.preview;

          if (!url) {
            url = mediaFile.url;
          }
          break;
        case MediaFileType.video:
          url = mediaFile.preview;

          if (!url) {
            break;
            try {
              const extIndex = mediaFile.url.lastIndexOf('.');
              const nameIndex = mediaFile.url.lastIndexOf('/');
              url =
                mediaFile.url.substr(0, nameIndex) +
                '/Preview Images/' +
                mediaFile.url.substr(nameIndex + 1, extIndex - nameIndex - 1) +
                '_' +
                mediaFile.url.substr(extIndex + 1) +
                '.png';
            } catch (e) {
              console.log(`error on preview images`);
            }
          }

          break;
      }
      if (url) {
        const prefixUrl = url.indexOf('/api/documentFile') !== -1 ? this.helper.getSiteUrl() : this.helper.getSiteHostUrl();
        url = Helper.concatUrls(prefixUrl, decodeURIComponent(url.trim()));
      } else {
        if (mediaFile.type === MediaFileType.video) {
          url = this.videoPreview;
        } else {
          url = this.preview;
        }
      }
    }
    return url;
  }

  /**
   * Получить url медиафайла
   *
   * @param {IMediaFile} mediaFile
   * @returns
   * @memberof GalleryService
   */
  getMediaFileUrl(mediaFile: IMediaFile) {
    if (!mediaFile) {
      return '';
    }

    if (mediaFile.toUpload && mediaFile.tempUrl) {
      mediaFile.urlEncoded = mediaFile.tempUrl;
      return mediaFile.urlEncoded;
    } else if (mediaFile.url) {
      if (mediaFile.urlEncoded === undefined) {
        const prefixUrl = mediaFile.url.indexOf('/api/documentFile') !== -1 ? this.helper.getSiteUrl() : this.helper.getSiteHostUrl();
        mediaFile.urlEncoded = Helper.concatUrls(
          prefixUrl,
          decodeURIComponent(mediaFile.url.trim()).replace(new RegExp(' ', 'gi'), '%20'));
      }

      return mediaFile.urlEncoded;
    }
    return '';
  }

  isVoteEnabled(album: IAlbum) {
    return (
      album &&
      album.votingEndDate &&
      new Date() <= moment(album.votingEndDate).toDate()
    );
  }

  isVoteFinished(album: IAlbum) {
    return (
      album &&
      album.votingEndDate &&
      new Date() > moment(album.votingEndDate).toDate()
    );
  }
}
