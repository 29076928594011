import { switchMap, takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { User } from '@app/profile/model/user.model';
import { FeedEventViewModel } from '@app/feed/model/feed-event-view-model.model';
import { BaseComponent } from '@app/core/components/base.component';
import { UsersService } from '@app/profile/services/users.service';
import { FeedService } from '@app/feed/services/feed.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { GroupInfo } from '@app/groups/model/group-info.model';
import { HelperService } from '@app/core/services/helper.service';

@Component({
  selector: 'app-post-view',
  templateUrl: './post-view.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PostViewComponent extends BaseComponent implements OnInit, OnDestroy {
  currentUser: User;
  user: User;
  group: GroupInfo;
  post: FeedEventViewModel;
  postId: number;
  commentId: string;
  notFound = false;

  startEdit = false;

  constructor(
    protected usersService: UsersService,
    protected feedService: FeedService,
    protected route: ActivatedRoute,
    protected router: Router,
    public helper: HelperService
  ) { super(helper); }

  ngOnInit() {
    // текущий пользователь для блока создания поста
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;

      this.route.params.pipe(
        switchMap((params: Params) => {
          this.post = null;
          this.notFound = this.loaded = false;
          this.error = '';
          const postId = +params['postId'];
          this.postId = postId;
          if (postId) {
            if (this.post && postId !== this.post.event.id || !this.post) {
              const urlTree = (<any>this.router).currentUrlTree;
              if (urlTree && urlTree.fragment) {
                const keys = urlTree.fragment.split('&');
                const hash = {};
                keys.forEach(key => {
                  // tslint:disable-next-line:no-shadowed-variable
                  const val = key.split('=');
                  hash[val[0]] = val[1];

                  if (val[0] === 'edit') {
                    this.startEdit = true;
                  }
                });

                if (hash['comment'] != null) {
                  this.commentId = hash['comment'];
                  console.log(`comment=${this.commentId}`);
                }
              }

              let showAllComments = false;

              if (this.commentId) {
                showAllComments = true;
              }

              return this.feedService.getPost(postId, showAllComments);
            }
          } else {
            return new Observable<FeedEventViewModel>(observer => {
              observer.next(null);
            });
          }
        }),
        takeUntil(this.destroyed$))
        .subscribe(post => {
          this.post = post;
          if (!this.post) {
            // error on load or is empty
              this.notFound = true;
          } else {
            if (this.startEdit && !this.canEdit(this.post)) {
              this.router.navigate(['/403'], { skipLocationChange: true });
            }
          }

          this.loaded = true;
          this.error = '';
        }, error => {
          this.error = error;
        }, () => {
          this.loaded = true;
        });
    });
  }

  /**
   * Может ли текущий пользователь редактировать пост?
   *
   * @param {FeedEventViewModel} post
   * @returns {boolean}
   * @memberof PostViewComponent
   */
  canEdit(post: FeedEventViewModel): boolean {
    return post != null && post.author != null && post.author.id === this.currentUser.id;
  }

  /**
   * Может ли текущий пользователь удалить пост?
   *
   * @param {FeedEventViewModel} post
   * @returns {boolean}
   * @memberof PostViewComponent
   */
  canDelete(post: FeedEventViewModel): boolean {
    return post != null && post.author != null && post.author.id === this.currentUser.id;
  }
}
