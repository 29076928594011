<div class="cos__body _paddings" *ngIf="thank && loaded">
  <div class="news-title _bright">{{thankTitle}}</div>
  <span class="header-text _low _secondary _inline">
    <span class="header-text__text">{{thank.created | dateSpLike:true }}</span>
  </span>
  <div class="news-section _lower">
    <div class="news-body">
      <div class="co">
        <div class="co__avatar">
          <app-user-avatar [user]="userForView" [linkClass]="'avatar _80'" [imageClass]="'avatar__image'">
          </app-user-avatar>
        </div>
        <div class="co__info">
          <a href="#" class="link co__name" routerLink="/profile/{{userForView?.id}}"
            (click)="usersService.navigateProfile(userForView?.id)"
            [appUserTooltip]="userForView?.id">{{userForView?.fullName}}</a>
          <div class="co__occ" *ngIf="userForView?.jobTitle">{{userForView?.jobTitle}}</div>
          <a class="co__occ" routerLink="/profile/{{currentUser?.id}}/search" fragment="k={{userForView?.department}}"
            *ngIf="userForView?.department">{{userForView?.department}}</a>
          <div class="co__phones" *ngIf="userForView?.mobilePhone || userForView?.workPhone">
            <a href="tel:{{userForView?.mobilePhone}}" *ngIf="userForView?.mobilePhone"
              class="link co__phone">{{userForView?.mobilePhone}}</a>
            <a href="tel:{{userForView?.workPhone}}" *ngIf="userForView?.workPhone"
              class="link co__phone">{{userForView?.workPhone}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="thanks-message">
    {{thank.body}}
  </div>
  <div class="cos__actions" *ngIf="thankTargetType===ThankTargetType.iWasThanked">
    <button class="btn btn-primary" type="button" (click)="thankUser(thank.author)">Сказать спасибо в ответ</button>
  </div>
  <div class="cos__actions" *ngIf="thankTargetType===ThankTargetType.iThanked">
    <button class="btn btn-primary" type="button" (click)="thankUser(thank.user)">Сказать спасибо повторно</button>
  </div>
</div>