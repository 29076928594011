import { Component, Input, Output, EventEmitter } from '@angular/core';
import { HelperService } from '@app/core/services/helper.service';
import { BaseComponent } from '@app/core/components/base.component';
import { VacancyService } from '@app/vacancy/services/vacancy.service';
import { ResponseStatus } from '@app/vacancy/model/vacancy';
import { take } from 'rxjs/operators';
import { AlertsService } from '@app/shared/services/alerts.service';

export interface IVacancyResponseMenuConfig {
  allowChangeStatus?: boolean;
}

export interface IVacancyResponseMenuResult {
  responseId: number;
  status: ResponseStatus;
}

/**
 * Меню отклика
 *
 * @export
 * @class ResponseMenuComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-response-menu',
  templateUrl: './response-menu.component.html',
  styleUrls: ['./response-menu.component.scss']
})
export class ResponseMenuComponent extends BaseComponent {

  @Input() responseId: number;
  @Input() config: IVacancyResponseMenuConfig;
  @Output() statusChanged: EventEmitter<IVacancyResponseMenuResult> = new EventEmitter();

  constructor(
    private vacancyService: VacancyService,
    private alertsService: AlertsService,
    public helper: HelperService) { super(helper); }

  inProcessClick() {
    this.setStatus(ResponseStatus.inProcess);
  }

  prospectiveClick() {
    this.setStatus(ResponseStatus.prospective);
  }

  declineClick() {
    this.setStatus(ResponseStatus.declined);
  }

  private setStatus(status: ResponseStatus) {
    this.vacancyService.updateResponseStatus(this.responseId, status)
      .pipe(take(1))
      .subscribe(res => {
        if (res) {
          this.statusChanged.emit({ responseId: this.responseId, status });
          this.alertsService.riseSuccess('Успешно изменён статус');
        } else {
          this.alertsService.riseError('Произошла ошибка при изменении статуса');
        }
      }, error => {
        console.log(error);
        this.alertsService.riseError('Произошла ошибка при изменении статуса');
      });
  }
}
