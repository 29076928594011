<div class="form cos__body" *ngIf="loaded">
  <div class="form__section">
    <div class="form__line">
      <p class="header-text _bigger _bold _low _dark">
        <span class="header-text__text">Выберите вакансии для публикации на портале</span>
      </p>
    </div>
    <div class="form__line" *ngFor="let vacancy of hhVacancies">
      <label class="checkbox _bigger">
        <input type="checkbox" [(checked)]="vacancy.checked" (change)="vacancy.checked=!vacancy.checked"
          name="search-dir" class="checkbox__input">
        <span class="checkbox__icon"></span>
        <span class="checkbox__text">
          {{vacancy.name}}
        </span>
        <span class="checkbox__subtext">
          <span class="text-secondary">{{vacancy.area.name}}</span>
          <br />
          <a [attr.href]="vacancy.alternate_url" target="_blank">(посмотреть на hh.ru)</a>
        </span>
      </label>
    </div>
  </div>
  <div class="form__actions" [ngClass]="{'loading _blue': sending}">
    <button type="button" class="btn btn-simple" [routerLink]="['..']" fragment="admin">Отмена</button>
    <button type="button" class="btn btn-primary" (click)="submit()">Сохранить</button>
  </div>
</div>

<div class="cos__body" *ngIf="loaded && !hhVacancies?.length">
  <!-- Empty list -->
  <div class="group-empty">
    <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" style="width:auto" class="group-empty__img">
    <div class="group-empty__text">Нет вакансий в hh</div>
  </div>
  <!-- Empty list END-->
</div>