<div *ngIf="loaded" class="card _birthdays">
  <a class="card__title" routerLink="/calendar" fragment="monthView&filter=all">
    <span class="header-text">
      <span class="header-text__text">Дни рождения</span>
    </span>
  </a>
  <div class="card__content">
    <div>
      <ngx-slick-carousel class="carousel" [config]="{}">
        <div ngxSlickItem class="co" *ngFor="let slide of slides">
          <div class="co _birthday" *ngFor="let user of slide">
            <div class="co__avatar">
              <app-user-avatar [user]="user" [linkClass]="'avatar _80'" [imageClass]="'avatar__image'">
              </app-user-avatar>
            </div>
            <div class="co__wrap">
              <div class="co__info">
                <a href="javascript:;" class="link co__name" routerLink="/profile/{{user?.id}}"
                  (click)="usersService.navigateProfile(user?.id)">{{user?.fullName}}</a>
                <div class="co__occ">{{getDateText(user)}}</div>
              </div>
              <div class="co__line" *ngIf="currentUser?.id!==user.id">
                <button
                  type="button"
                  class="btn btn-white btn-stroke"
                  (click)="congratulate(user)"
                >Поздравить</button>
              </div>
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</div>

<div *ngIf="!loaded" class="card _birthdays">
  <span class="card__title">
    <span class="preloader _white"><span class="preloader__line"></span></span>
  </span>
  <div class="card__content">
    <span class="preloader _co" *ngFor="let r of [0,1]">
      <span class="preloader__side"></span>
      <span class="preloader__lines">
        <span class="preloader__line"></span>
        <span class="preloader__line"></span>
      </span>
    </span>
  </div>
</div>
