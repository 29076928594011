import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@app/core/components/base.component';
import { Helper } from '@app/core/helpers/helper';
import { HelperService } from '@app/core/services/helper.service';

@Component({
  selector: 'app-internal-server-error',
  templateUrl: './internal-server-error.component.html'
})
export class InternalServerErrorComponent extends BaseComponent {

  showDefault = true;
  errorText: string;

  constructor(
    public helper: HelperService,
    public route: ActivatedRoute,
    public router: Router) {
    super(helper);

    const error = this.route.snapshot.paramMap.get('error');

    if (error === '2ba01c92-2e67-4384-b672-4dea5439ceb5') {
      this.errorText = 'Истёк срок использования бесплатной пробной версии';
      this.showDefault = false;
    }
  }

  /**
   * При нажатии на "вернуться назад"
   */
  goBack() {
    if (Helper.routes && Helper.routes.length && Helper.routes.length > 1) {
      this.router.navigate([Helper.routes[Helper.routes.length - 2]]);
    } else {
      this.router.navigate(['/']);
    }
  }
}
