import { Component, OnInit, Input, OnDestroy, HostListener, ViewChild, ComponentFactoryResolver, Type } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SignalRService } from '@app/signalr/signalR.service';
import { trigger, state, animate, transition, style } from '@angular/animations';
import { BaseComponent } from '@app/core/components/base.component';
import { User } from '@app/profile/model/user.model';
import { UsersService } from '@app/profile/services/users.service';
import { switchMap, takeUntil, take } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { DynamicContainerDirective } from '@app/shared/directives/dynamic-container.directive';
import { ColleaguesWidgetComponent } from '@app/subscribe/components/colleagues-widget/colleagues-widget.component';
import { GroupsWidgetComponent } from '@app/groups/components/groups-widget/groups-widget.component';
import { UserFilesWidgetComponent } from '@app/files/components/files-widget/user-files-widget.component';
import { GalleryWidgetComponent } from '@app/gallery/components/gallery-widget/gallery-widget.component';
import { UserSurveysWidgetComponent } from '../../surveys/user-surveys-widget/user-surveys-widget.component';
import { UserVacancyWidgetComponent } from '../../vacancy/user-vacancy-widget/user-vacancy-widget.component';
import { FilesService } from '@app/files/services/files.service';

@Component({
    selector: 'app-profile-view',
    templateUrl: 'profile-view.component.html',
    animations: [
        trigger('visibilityChanged', [
            state('true', style({ opacity: 1, display: 'inherit' })),
            state('false', style({ opacity: 0, display: 'none' })),
            transition('* => *', animate('.5s'))
        ])
    ]
})

export class ProfileViewComponent extends BaseComponent implements OnInit, OnDestroy {
    @Input() goTopVisible = false;

    user: User;

    /**
     * Контейнер для динамического добавления компонентов на страницу
     *
     * @type {DynamicContainerDirective}
     * @memberof ProfileViewComponent
     */
    @ViewChild(DynamicContainerDirective, { static: true }) dynamicContainer: DynamicContainerDirective;

    constructor(
        protected signalRService: SignalRService,
        private filesService: FilesService,
        private route: ActivatedRoute,
        private router: Router,
        private usersService: UsersService,
        protected componentFactoryResolver: ComponentFactoryResolver,
        public helper: HelperService) {
        super(helper);
    }

    ngOnInit() {

        this.usersService.currentUser.subscribe(currentUser => {
            // get user from id parameter
            this.route.params
                .pipe(takeUntil(this.destroyed$), switchMap((params: Params) => {
                    this.usersService.user.next(null);
                    const userId = +params['id'];
                    if (userId) {
                        if (this.user && userId !== this.user.id || !this.user) {
                            return this.usersService.getUser(userId).pipe(take(1));
                        } else {
                            return this.usersService.currentUser;
                        }
                    } else {
                        return this.usersService.currentUser;
                    }
                }))
                .subscribe(user => {
                    this.user = user;
                    this.usersService.user.next(user);

                    // очищаем текущий список виджетов
                    this.dynamicContainer.viewContainerRef.clear();

                    // добавление виджетов
                    this.addComponent(ColleaguesWidgetComponent);
                    this.addComponent(GroupsWidgetComponent);
                    if (this.filesService.showUserFiles$.getValue()) {
                        this.addComponent(UserFilesWidgetComponent);
                    }
                    this.addComponent(GalleryWidgetComponent);

                    if (this.user && currentUser.id === this.user.id) {
                        // виджет опросов отображаем только у текущего пользователя
                        this.addComponent(UserSurveysWidgetComponent);
                        // виджет вакансий отображаем только у текущего пользователя
                        this.addComponent(UserVacancyWidgetComponent);
                    }

                    this.error = '';
                }, error => {
                    this.error = error;
                }, () => {
                    this.loaded = true;
                });
        });
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        this.goTopVisible = window.pageYOffset > window.innerHeight / 2;
    }

    scrollTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    private addComponent(type: Type<any>) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(type);
        this.dynamicContainer.viewContainerRef.createComponent(componentFactory);
    }
}
