<div class="form cos__body" *ngIf="loaded">
  <div cdkDropList (cdkDropListDropped)="drop($event)">
    <div cdkDrag *ngFor="let status of statuses; let i=index">
      <div class="form__line draggable-line">
        <div class="draggable-line__content">
          <button type="button" class="btn btn-drag draggable-line__icon"></button>
          <div class="input-main form__about _filled" *ngIf="showStatusButtons(status)">
            <input placeholder="Введите название статуса*" [(ngModel)]="status.title" type="text" maxlength="255"
              class="input-main__field" (focus)="onFocus($event, status)" (blur)="onBlur($event, status)" (keyup)="onBlur($event, status)" />
            <div class="form__note" *ngIf="status.focused">{{status.title?.length || 0}} из 255</div>
          </div>
          <span *ngIf="!showStatusButtons(status)"
            matTooltip="Системный статус нельзя редактировать или удалять">{{status.title}}</span>
          <!-- 'btn-content-eye-hide-inactive' если неактивен -->
          <ng-container *ngIf="showStatusButtons(status)">
            <button type="button" class="btn"
              [ngClass]="{'btn-content-eye-hide': !isHidden(status), 'btn-content-eye-hide-inactive': isHidden(status)}" (click)="toggle($event, status)"
              [matTooltip]="getToggleStatusText(status)" matTooltipPosition="above"></button>
            <button type="button" class="btn btn-remove" (click)="remove($event, status, i)" matTooltip="Удалить статус"
              matTooltipPosition="above"></button>
          </ng-container>
        </div>
        <div class="form__line _secondary">
          <label class="checkbox">
            <input type="checkbox" name="search-dir" class="checkbox__input" [checked]="status.commentRequired"
              (change)="commentRequiredChanged($event, status)">
            <span class="checkbox__icon"></span>
            <span class="checkbox__text">Комментарий обязателен</span>
          </label>
          <label class="checkbox">
            <input type="checkbox" name="search-dir" class="checkbox__input" [checked]="status.dateEndRequired"
              (change)="dateEndRequiredChanged($event, status)">
            <span class="checkbox__icon"></span>
            <span class="checkbox__text">Указывать срок действия статуса</span>
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="form__line">
    <div type="button" class="btn btn-add" (click)="addStatus()">Добавить статус</div>
  </div>
  <div class="form__line">
    <div class="form__note">Новым созданным идеям по умолчанию будет присваиваться статус «Новая». Дополнительно
      данный статус создавать не нужно. Дополните список статусов иными значениями при необходимости.</div>
  </div>
  <div class="form__actions">
    <button type="button" class="btn btn-simple" (click)="cancel()">Отмена</button>
    <button type="button" [attr.disabled]="isValid()?null:''" class="btn btn-primary"
      (click)="submit()">Сохранить</button>
  </div>
</div>
