import { Injectable } from '@angular/core';
import { WebStorageService } from './web-storage.service';

@Injectable()
export class SessionStorageService extends WebStorageService {

  public constructor() {
    super();
  }

  protected getNativeStorage(): Storage {
    return window.sessionStorage;
  }

}
