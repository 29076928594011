import { switchMap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { UsersService } from '@app/profile/services/users.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { FilesService } from '@app/files/services/files.service';
import { SearchResult } from '@app/search/model/search-result.model';
import { SearchResultItem } from '@app/search/model/search-result-item.model';
import { HelperService } from '@app/core/services/helper.service';

/**
 * Service for SharePoint search engine.
 */
@Injectable()
export class SearchService extends BaseService {

  public searchOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  protected serviceUrl = `${this.helper.environment.siteUrl}`;

  constructor(
    protected http: HttpClient,
    protected usersService: UsersService,
    protected filesService: FilesService,
    public helper: HelperService) {
    super(http, helper);
  }

  /**
   * Gets the search results for files and folders.
   * @param text The query text.
   * @param offset The index of starting position in search results to retrieve.
   * @param count The number of items to retrieve.
   * @param siteUrl Absolute url of the target site.
   */
  search(
    text: string,
    offset: number,
    count: number,
    order: string = null,
    orderBy: string = null,
    siteUrl: string = null,
    enableSorting: boolean = true): Observable<SearchResult> {

    const properties = [
      'Title',
      'Filename',
      'EditorOWSUser',
      'SPSiteURL',
      'Created',
      'LastModifiedTime',
      'IsContainer',
      'IsDocument',
      'FolderChildCount',
      'HitHighlightedSummary',
      'ServerRedirectedURL',
      'OriginalPath',
      'Path',
      'UniqueId',
      'SPWebUrl',
      'SharedWithUsersOWSUser',
      'Size'];

    const fileExtensions = this.filesService.getAllSearchExtArray();

    let exts = '';

    fileExtensions.forEach(ext => {
      exts += `"${ext}"`;
      if (fileExtensions.indexOf(ext) < fileExtensions.length - 1) {
        exts += ',';
      }
    });

    let direction = 0;
    if (order === 'desc') {
      direction = 1;
    }

    if (!orderBy) {
      orderBy = 'Filename';
    }

    const queryText = `((${text}) OR (${text}*))`;

    let queryTemplate = '{searchTerms}';

    if (siteUrl) {
      queryTemplate += ` (path:${siteUrl} OR Path:"${siteUrl}")`;
    } else {
      queryTemplate += ` (path:${this.helper.getSiteHostUrl()}* OR Path:"${this.helper.getSiteHostUrl()}*")`;
    }

    queryTemplate += `(ContentTypeId:0x010100* OR ContentTypeId:0x012000*) `
      + `-ContentClass=STS_List_* `
      + `-ContentClass=STS_Site `
      + `-ContentClass=STS_Web `
      + `-ContentClass=STS_ListItem_544 `
      + `-ContentClass=STS_ListItem_550`;

    const requestData = {
      request: {
        QueryTemplate: queryTemplate,
        Querytext: queryText, // IsDocument=true & path:${siteUrl}
        StartRow: offset,
        RowsPerPage: count,
        RowLimit: count,
        SelectProperties: {
          results: properties
        },
        SortList: {
          results: [
            {
              Property: orderBy,
              Direction: direction
            }
          ]
        },
        EnableSorting: enableSorting,
        TrimDuplicates: true// ,
        // RefinementFilters: {
        //   // results: [`fileExtension:not("aspx")`]
        //   // results: [`fileExtension:or("png","jpeg")`]
        // }
      }
    };

    return this.post<any>('/api/search', requestData)
      .pipe(map(data => {
        const result = new SearchResult();
        result.items = new Array<SearchResultItem>();
        result.total = 0;

        if (!data || !data.d || !data.d.postquery) {
          return result;
        }

        const primaryQueryResult = data.d.postquery.primaryQueryResult;

        if (primaryQueryResult.relevantResults.rowCount > 0) {
          result.total = primaryQueryResult.relevantResults.totalRows;

          primaryQueryResult.relevantResults.table.rows.results.forEach(row => {
            const item = new SearchResultItem();

            row.cells.results.forEach(cell => {
              switch (cell.key) {
                case 'Title':
                  item.title = cell.value;
                  break;
                case 'Filename':
                  item.fileName = cell.value;
                  break;
                case 'EditorOWSUser':
                  if (cell.value) {
                    const user = cell.value.split('|');
                    if (user.length) {
                      item.editor = user.length >= 2 && user[1] ? user[1].trim() : '';
                      item.editorLogin = user.length >= 4 ? user[3] : '';
                    }
                  }
                  break;
                case 'LastModifiedTime':
                  item.modified = cell.value;
                  break;
                case 'Created':
                  item.created = cell.value;
                  break;
                case 'FolderChildCount':
                  item.childCount = cell.value;
                  break;
                case 'HitHighlightedSummary':
                  item.highlightedSummary = cell.value;
                  break;
                case 'ServerRedirectedURL':
                  item.serverRedirectedUrl = cell.value;
                  break;
                case 'OriginalPath':
                  item.url = cell.value;
                  break;
                case 'IsContainer':
                  item.isFolder = cell.value === 'true';
                  break;
                case 'UniqueId':
                  item.uniqueId = cell.value;
                  break;
                case 'SPWebUrl':
                  item.webUrl = cell.value;
                  break;
                case 'Size':
                  item.size = cell.value;
                  break;
              }
            });

            if (item.isFolder && item.url) {
              if (item.url.indexOf('/personal/') !== -1) {
                item.isPersonal = true;
              } else if (item.url.indexOf('/groups/group') !== -1) {
                item.isGroup = true;
              }
            }

            result.items.push(item);
          });
        }
        return result;
      }));
  }
}
