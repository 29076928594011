export interface IGroupUserStateChanged {
    userId: number;
    groupId: number;
    state: GroupUserStateChangedType;
}

export enum GroupUserStateChangedType {
    joined = 0,
    leaved = 1
}
