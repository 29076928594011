import { Component } from '@angular/core';
import { UsersService } from '@app/profile/services/users.service';
import { HelperService } from '@app/core/services/helper.service';
import {SurveysService} from '@app/surveys/services/surveys.service';
import {GroupsService} from '@app/groups/services/groups.service';
import {Router} from '@angular/router';
import { UserSurveysWidgetComponent } from '@app/profile/components/surveys/user-surveys-widget/user-surveys-widget.component';

@Component({
  selector: 'app-user-surveys-widget-small.rich-tabs__item._surveys',
  templateUrl: './user-surveys-widget-small.component.html',
  styleUrls: ['./user-surveys-widget-small.component.scss']
})

export class UserSurveysWidgetSmallComponent extends UserSurveysWidgetComponent {

    constructor(
        protected usersService: UsersService,
        public surveysService: SurveysService,
        public groupsService: GroupsService,
        protected router: Router,
        public helper: HelperService) { super(usersService, surveysService, groupsService, router, helper); }
}
