<div class="container _full-page _moved">

    <div class="card">
        <div class="no-data">
            <h1 class="no-data__title">403</h1><img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt=""
                class="no-data__img">
            <div class="no-data__text">У вас нет прав на просмотр данной страницы.
                <br>Вернуться <a routerLink="/" (click)="goBack()">назад</a>.
            </div>
        </div>
    </div>
</div>