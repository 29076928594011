<div class="cos__body _paddings" *ngIf="page && loaded">
  <div class="cos__actions pull-right">
    <app-page-menu *ngIf="config" [item]="page" [config]="config" (deleted)="onDeleted($event)"></app-page-menu>
  </div>
  <h1 class="news-title _bright">{{page.title}}</h1>
  <div class="content-about">
    <span class="text-secondary">Последние изменения:</span>&nbsp;
    <a class="link" routerLink="/profile/{{page.editor?.id}}" (click)="usersService.navigateProfile(page.editor?.id)"
      [appUserTooltip]="page.editor?.id">{{page.editor?.fullName}}</a>&nbsp;
    <span class="text-secondary">({{page.modified | dateSpLike}})</span></div>
  <section class="content-section">
    <app-message [text]="page?.body" [mentions]="[]" [showBody]="true"></app-message>
  </section>
  <app-add-tags placeholder="Теги" [tagType]="TagType.page" [elementId]="page.id" [ownerId]="page.ownerId" [ownerType]="ownerType" [mode]="AddTagsComponentMode.view"></app-add-tags>
</div>
