<div class="card">
  <span class="header-text _modal">
    <span class="header-text__text">
      {{!isVotingWasEnabled() ? 'Включить голосование' : 'Настроить голосование'}}
    </span>
  </span>
  <div class="input-main _big">
    <div class="post__calendar">
      Срок завершения*
      <input placeholder="дд.мм.гггг" matInput [matDatepicker]="dp" [(ngModel)]="dateEnd" [min]="minDate">
      <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
      <mat-datepicker #dp></mat-datepicker>
    </div>
  </div>
  <div class="subinput">
    <span>Укажите дату, до которой включительно будут подсчитываться голоса</span>
  </div>
  <div class="error-message" *ngIf="error">{{error}}</div>
</div>
<div class="confirm _right">
  <div class="confirm__actions" mat-dialog-actions [ngClass]="{'loading _blue':loading}">
    <button type="button" mat-button mat-dialog-close class="btn btn-white">Отмена</button>
    <button type="button" mat-button class="btn btn-white" (click)="disableVoting()" *ngIf="isVotingEnabled()">Закрыть
      голосование</button>
    <button type="button" mat-button class="btn btn-primary" (click)="save()" [attr.disabled]="isValid() ? null :'' ">{{!isVotingEnabled()
      ? 'Включить' : 'Сохранить'}}</button>
  </div>
</div>