import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { HelperService } from '@app/core/services/helper.service';
import { INewAwardModel, IAward, IAwardIcon, IUpdateAwardModel } from '../model/awards';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

/**
 * API - сервис "Наград"
 *
 * @export
 * @class AwardsService
 * @extends {BaseService}
 */
@Injectable({
  providedIn: 'root'
})
export class AwardsApiService extends BaseService {

  protected serviceUrl = `${this.helper.environment.siteUrl}/api/award`;

  constructor(
    protected http: HttpClient,
    public helper: HelperService) { super(http, helper); }

  /**
   * Создать награду пользователю
   *
   * @param {*} data
   * @returns {Observable<IAward>}
   * @memberof AwardsService
   */
  create(data: INewAwardModel): Observable<IAward> {
    return super.post('', data);
  }

  /**
   * Обновление информации по награде
   *
   * @param {IUpdateAwardModel} data
   * @returns {Observable<IAward>}
   * @memberof AwardsApiService
   */
  update(id: number, data: INewAwardModel): Observable<IAward> {
    return super.put(`${id}`, data);
  }

  /**
   * Получить награды пользователя
   *
   * @param number userId ИД пользователя, награды по которому нужно получить
   * @returns Observable<IAward[]>
   * @memberof AwardsService
   */
  getUserAwards(userId: number): Observable<IAward[]> {
    return super.get(`allByUser/${userId}`);
  }

  getById(id: number): Observable<IAward> {
    return super.get(`${id}`);
  }

  /**
   * Является пользователь ли администратором наград
   *
   * @returns {Observable<boolean>}
   * @memberof AwardsService
   */
  isAdmin(): Observable<boolean> {
    return super.get('isAdmin', null, null, 'text');
  }

  /**
   * Получение всех иконок наград
   *
   * @returns {Observable<IAwardIcon>}
   * @memberof AwardsService
   */
  getAwardIcons(): Observable<IAwardIcon[]> {
    return super.get(`${this.helper.environment.siteUrl}/api/awardIcon`);
  }

  /**
   * Удаление иконки награды
   * @param id ИД иконки
   * @returns boolean
   */
  removeAwardIcon(id: number): Observable<boolean> {
    return super.delete(`${this.helper.environment.siteUrl}/api/awardIcon/${id}`);
  }

  /**
   * Загрузить иконку
   *
   * @param {FormData} formData
   * @returns {Observable<IAwardIcon>}
   * @memberof AwardsApiService
   */
  uploadIcon(formData: FormData): Observable<IAwardIcon> {
    return super.post(`${this.helper.environment.siteUrl}/api/awardIcon/upload`, formData);
  }

  /**
   * Удаление награды
   *
   * @param number id ИД награды, которую нужно удалить
   * @returns Observable<IAward>
   * @memberof AwardsApiService
   */
  remove(id: number): Observable<IAward> {
    return super.delete(`${id}`);
  }
}
