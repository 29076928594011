<div class="card">
  <span class="header-text _modal">
        <span class="header-text__text">Переименование {{data?.item?.name}}</span>
  </span>
  <div class="input-main _big">
    <a href="javascript:;" *ngIf="filesService.isFolder(data.item)" class="link-file _folder"></a>
    <a href="javascript:;" *ngIf="!filesService.isFolder(data.item)" class="link-file" [ngClass]="filesService.getFileIcon(data?.item)"></a>
    <input #itemName type="text" placeholder="Введите название {{data?.item?.type==0?'файла':'папки'}}" [(ngModel)]="newName" class="input-main__field">
    <button *ngIf="newName" type="button" class="btn icon _remove input-main__clear" (click)="newName=''"></button>
  </div>
  <div class="error-message" *ngIf="error">{{error}}</div>
</div>
<div class="confirm _right">
  <div class="confirm__actions" mat-dialog-actions [ngClass]="{'loading _blue':loading}">
    <button type="button" mat-button mat-dialog-close class="btn btn-simple">Отмена</button>
    <button type="button" mat-button class="btn btn-primary" (click)="rename()" [attr.disabled]="newName?null:''">Переименовать</button>
  </div>
</div>
