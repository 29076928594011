<div class="confirm _right _fixed">
  <div class="confirm__text" mat-dialog-content>{{text}}</div>

  <app-profile-selector [required]="true" [placeholder]="'Выберите группу для копирования'"
    (profileSelected)="onProfileSelected($event)" [selectedProfileId]="data?.profileId">
  </app-profile-selector>

  <div class="error-message" *ngIf="data.error">{{data.error}}</div>
  <div class="confirm__actions" mat-dialog-actions>
    <button type="button" mat-button mat-dialog-close class="btn btn-secondary"
      (click)="cancel()">{{cancelText}}</button>
    <button type="button" mat-button *ngIf="doNotHideOnOk" class="btn btn-primary" (click)="ok()">{{okText}}</button>
    <button type="button" mat-button *ngIf="!doNotHideOnOk" [mat-dialog-close]="true" class="btn btn-primary"
      (click)="ok()">{{okText}}</button>
  </div>
</div>