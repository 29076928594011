import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { GalleryService } from '@app/gallery/services/gallery.service';
import { WidgetBaseComponent } from '@app/shared/components/widget-base.component';
import { UsersService } from '@app/profile/services/users.service';
import { IMediaFile, MediaFileType } from '@app/gallery/model/media-file';
import { MediaFileViewComponent } from '@app/gallery/components/forms/media-file-view/media-file-view.component';
import { MatDialog } from '@angular/material/dialog';
import { User } from '@app/profile/model/user.model';
import { Router } from '@angular/router';
import { HelperService } from '@app/core/services/helper.service';
import { takeUntil, take, finalize } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';

/**
 * Виджет галереи
 */
@Component({
  selector: 'app-gallery-widget',
  templateUrl: './gallery-widget.component.html',
  styleUrls: ['./gallery-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryWidgetComponent extends WidgetBaseComponent implements OnInit, OnDestroy {

  private user: User;
  protected offset = 0;
  protected limit = 10;

  newLink: string;
  showAddButton: boolean;
  fragment: string;

  /**
   * Массив медиа-файлов виджета
   */
  mediaFiles: IMediaFile[];

  mediaFileType = MediaFileType;

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000
  };

  isAdmin: boolean;

  constructor(
    public dialog: MatDialog,
    protected usersService: UsersService,
    protected sanitizer: DomSanitizer,
    public galleryService: GalleryService,
    protected router: Router,
    protected cdr: ChangeDetectorRef,
    public helper: HelperService) {
    super(helper);

    // обработка удаления альбома
    this.galleryService.albumDeleted.pipe(takeUntil(this.destroyed$)).subscribe(id => {
      if (id && this.mediaFiles) {
        this.mediaFiles = this.mediaFiles.filter(s => s.album && s.album.id !== id);
      }
    });

    // обработка удаления медиа-файла
    this.galleryService.mediaFileDeleted.pipe(takeUntil(this.destroyed$)).subscribe(id => {
      if (id && this.mediaFiles) {
        this.mediaFiles = this.mediaFiles.filter(s => s.id !== id);
      }
    });

    this.galleryService.mediaFileAdded.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.loadMediaFiles();
    });
  }

  ngOnInit() {
    this.loadMediaFiles();
  }

  protected loadMediaFiles() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.usersService.user.pipe(takeUntil(this.destroyed$)).subscribe(user => {

        this.user = user;

        if (user) {
          this.link = `/profile/${this.user.id}/gallery`;
          this.newLink = this.link + '/new';

          if (this.user.id === currentUser.id) {
            this.isAdmin = true;
            this.fragment = 'last';
            // пользователь может создавать альбомы/загружать медиа-файлы в своём профиле
            this.showAddButton = true;
            // в своём профиле для текущего пользователя отображаем посление загруженные
            // от пользователей и групп на которые он подписан
            this.operateMediaFiles(this.galleryService.getLastMediaFiles(user.id, this.offset, this.limit));
          } else {
            // в чужом профиле пользователь видит в виджете последние загруженные файле данного коллеги
            this.operateMediaFiles(this.galleryService.getProfileMediaFiles(user.id, this.offset, this.limit));
          }

          this.showWidget.next(true);
        }
        this.cdr.detectChanges();
      });
    });
  }

  protected operateMediaFiles(method: Observable<IMediaFile[]>) {
    return method.pipe(
      take(1),
      finalize(() => {
        this.loaded = true;
        this.cdr.detectChanges();
      }),
      takeUntil(this.destroyed$))
      .subscribe(mediaFiles => {

        if (mediaFiles && mediaFiles.length) {
          this.mediaFiles = mediaFiles;
        }
      }, error => {
        this.error = error;
      });
  }

  /**
   * Открыть всплывашку медиафайла
   *
   * @param {IMediaFile} mediaFile
   */
  openMediaFile(e: Event, mediaFile: IMediaFile) {
    e.stopImmediatePropagation();
    this.dialog.open(MediaFileViewComponent, {
      minWidth: window.innerWidth - 100,
      data: {
        item: mediaFile,
        items: this.mediaFiles,
        isAdmin: this.isAdmin
      }
    });
  }

  /**
   * Переход по ссылке в заголовке виджета
   */
  navigate() {
    if (this.fragment) {
      this.router.navigate([this.link], { fragment: this.fragment });
    } else {
      this.router.navigate([this.link]);
    }
  }

  onImageLoadError(e) {
    // прозрачный пиксель
    e.target.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8/5+hHgAHggJ/PchI7wAAAABJRU5ErkJggg==';
  }

  /**
   * Отображать заглушку?
   *
   * @returns {boolean}
   */
  isShowEmpty(): boolean {
    return this.showWidget.getValue() && this.loaded && (!this.mediaFiles || !this.mediaFiles.length);
  }
}
