<div class="survey" *ngIf="gifts">
  <div class="survey__section _banner"
       *ngFor="let item of gifts; let i=index; trackBy:trackByFunc"
       [ngClass]="{'_first': i === 0}">
    <div class="section-move _style-2">
      <div class="section-move__number">{{item.order}}.</div>
      <div class="section-move__buttons">
        <button type="button" class="btn btn-content-up" *ngIf="i!==0" (click)="moveUp(i)"></button>
        <button type="button" class="btn btn-content-down" *ngIf="i!==gifts.length-1"
                (click)="moveDown(i)"></button>
        <button type="button" class="btn btn-content-can-view"
                [ngClass]="{'btn-content-can-view': !item.hidden, 'btn-content-eye-hide': item.hidden}" (click)="toggle(item)"></button>
        <button type="button" class="btn btn-content-edit" (click)="edit(item.id)"></button>
        <button type="button" class="btn btn-remove" (click)="remove(item.id)"></button>
      </div>
    </div>
    <div class="gift _preview">
      <div class="gift__image-wrap">
        <img class="gift__image" [attr.src]="giftService.getIconUrl(item.pictureUrl)"/>
      </div>
      <div class="gift__about">
        <div class="gift__title" *ngIf="item.title">{{ item.title }}</div>
        <div class="gift__desc" *ngIf="item.description">{{ item.description }}</div>
        <div class="gift__price" *ngIf="item.value">{{ item.value }}<span>баллов</span></div>
      </div>
    </div>
  </div>
</div>
