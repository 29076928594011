import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { HelperService } from '@app/core/services/helper.service';
import { Observable, of } from 'rxjs';
import { IGift, INewGiftModel, IUpdateGiftModel } from '../model/gift';

@Injectable({
  providedIn: 'root'
})
export class GiftApiService extends BaseService {
  protected serviceUrl = `${this.helper.environment.siteUrl}/api/gift`;

  protected gifts: IGift[] = [];

  constructor(
    protected http: HttpClient,
    public helper: HelperService) {
    super(http, helper);

    if (!this.gifts.length) {
      for (let i = 1; i <= 100; i++) {
        this.gifts.push({
          id: i,
          order: i,
          title: `Подарок ${i}`,
          description: `Описание подарка ${i}`,
          pictureUrl: null,
          value: i
        });
      }
    }
  }

  /**
   * Вернуть из бд подарок по идентификатору
   * @param id ИД подарка
   * @returns подарок
   */
  getById(id: number): Observable<IGift> {
    // return of(this.gifts.find(s => s.id === id));
    return super.get(`${id}`);
  }

  /**
   * Получение списка подарков
   * @param offset сдвиг
   * @param limit лимит
   * @returns массив подарков
   */
  getAll(showHidden: boolean, offset: number, limit: number): Observable<IGift[]> {
    // return of(this.gifts.slice(offset, limit + offset));
    return super.get(`?showHidden=${showHidden}&offset=${offset}&limit=${limit}`);
  }

  /**
   * Создать подарок
   * @param data Данные для создания
   * @returns созданный подарок
   */
  add(data: INewGiftModel): Observable<IGift> {
    return super.post('', data);
  }

  /**
   * Обновить существующий
   * @param data Данные для изменения, обязательно должны содержать ИД
   * @returns обновленный подарок
   */
  update(data: IUpdateGiftModel): Observable<IGift> {
    return super.put('', data );
  }

  /**
   * Скрыть/отобразить подарок. Для админа он всё равно отображается во вкладке "Администрирование".
   * @param id ИД подарка
   * @param hide true - для скрытия элемента
   * @returns true - если операция была выполнена успешно
   */
  toggle(id: number, hide: boolean): Observable<boolean> {
    return super.put(`toggle/${id}?hide=${hide}`, {  });
  }

  /**
   * Изменить сортировку подарка
   * @param id ИД подарка
   * @param up true - для движения вверх
   * @returns true - если операция была выполнена успешно
   */
  changeSort(id: number, up: boolean): Observable<boolean> {
    return super.put(`sort/${id}?up=${up}`, {});
  }

  /**
   * Удалить подарок по идентификатору
   * @param id Ид подарка
   * @returns true - если успешно удалён
   */
  remove(id: number): Observable<boolean> {
    return super.delete(`${id}`);
  }

  /**
   * Загрузка фотографии подарка
   * @param formData файл для загрущки
   * @returns url файла
   */
  uploadPhoto(formData: FormData): Observable<string> {
    const url = `${(<any>window).signalRServer}/_layouts/15/Intry/handlers/RootMediaGalleryItemUploadHandler.ashx?module=gifts`;
    return super.post(url, formData);
  }

  /**
   * Получение выбранного подарка
   * @param id ИД подарка
   * @returns true - если заказ подарка успешно выполнен (списаны баллы и отправлено письмо)
   */
  orderGift(id: number): Observable<boolean> {
    return super.post(`order/${id}`);
  }
}
