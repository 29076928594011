import { Component, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IIdeaStatus } from '@app/ideas/model/idea';
import { IdeasService } from '@app/ideas/services/ideas.service';
import { take } from 'rxjs/operators';
import { AlertsService } from '@app/shared/services/alerts.service';

/**
 * Данные, передаваемые в модальное окно
 *
 * @export
 * @interface IIdeaStatusModalComponentData
 */
export interface IIdeaStatusModalComponentData {
  onOk: EventEmitter<boolean> | Function;
  ideaId: number;
  status: IIdeaStatus;
}

/**
 * Модальное окно изменения статуса идеи
 *
 * @export
 * @class IdeaStatusModalComponent
 */
@Component({
  selector: 'app-idea-status-modal',
  templateUrl: './idea-status-modal.component.html',
  styleUrls: ['./idea-status-modal.component.scss']
})
export class IdeaStatusModalComponent {

  /**
   * Комментарий к статусу
   *
   * @type {string}
   * @memberof AlbumVotingComponent
   */
  comment: string;

  /**
   * Дата окончания действия статуса
   *
   * @type {Date}
   * @memberof AlbumVotingComponent
   */
  dateEnd: Date;

  /**
   * Текст ошибки
   *
   * @type {string}
   * @memberof AlbumVotingComponent
   */
  error: string;

  /**
   * Статус загрузки
   *
   * @type {boolean}
   * @memberof AlbumVotingComponent
   */
  loading: boolean;

  constructor(
    private ideasService: IdeasService,
    protected alertsService: AlertsService,
    public dialogRef: MatDialogRef<IdeaStatusModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IIdeaStatusModalComponentData
  ) {

  }

  /**
   * Отправить данные формы на сервер
   *
   * @memberof AlbumVotingComponent
   */
  save() {
    const body = {
      ideaId: this.data.ideaId,
      statusId: this.data.status.id,
      comment: this.comment ? this.comment : null,
      dateEnd: this.dateEnd ? this.dateEnd : null
    };
    this.ideasService.updateIdeaStatus(body).pipe(take(1)).subscribe(res => {
      if (res) {
        this.alertsService.riseSuccess(`Успешно изменён статус идеи`);
      } else {
        this.alertsService.riseError(`Произошла ошибка изменения статуса идеи`);
      }

      if (this.data.onOk) {
        if (this.data.onOk instanceof EventEmitter) {
          this.data.onOk.emit(res);
        } else {
          this.data.onOk(res);
        }
      }
    }, error => {
      console.log(error);
      this.alertsService.riseError(`Произошла ошибка изменения статуса идеи`);
    });
  }

  /**
   * Валидна ли форма?
   *
   * @returns {boolean}
   * @memberof AlbumVotingComponent
   */
  isValid(): boolean {
    return this.data.status
      && (this.data.status.commentRequired && this.comment || !this.data.status.commentRequired)
      && (this.data.status.dateEndRequired && this.dateEnd != null && this.dateEnd !== undefined || !this.data.status.dateEndRequired);
  }
}
