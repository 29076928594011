import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TabsComponent } from '@app/shared/components/tabs/tabs.component';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { HelperService } from '@app/core/services/helper.service';
import { CalendarHelper, ICalendarDate, CalendarViewType } from '@app/calendar/model/calendar';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-calendar-tabs',
  templateUrl: './calendar-tabs.component.html',
  styleUrls: ['./calendar-tabs.component.scss']
})
export class CalendarTabsComponent extends TabsComponent implements OnChanges {

  @Input() selectedDate: ICalendarDate;

  day: number;
  year: number;
  monthName: string;
  month: number;

  dateText: string;

  @Input() viewType: CalendarViewType = CalendarViewType.month;

  CalendarViewType = CalendarViewType;

  @Output() init: EventEmitter<ICalendarDate> = new EventEmitter();
  @Output() prev: EventEmitter<ICalendarDate> = new EventEmitter();
  @Output() next: EventEmitter<ICalendarDate> = new EventEmitter();

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected datePipe: DatePipe,
    protected breadcrumbsService: BreadcrumbsService,
    public helper: HelperService) {
    super(router, route, breadcrumbsService, helper);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['viewType']) {
      if (this.selectedDate) {
        this.getText();
      }
    }

    if (changes['selectedDate']) {
      this.getText();
    }
  }

  private getText() {
    const date = new Date(this.selectedDate.year, this.selectedDate.month, this.selectedDate.day);
    switch (this.viewType) {
      case CalendarViewType.month:
        this.getTextMonth(date);
        break;
      case CalendarViewType.day:
        this.getTextDay(date);
        break;
    }
  }

  protected getTextMonth(date: Date) {
    this.getDateData(date);
    this.monthName = CalendarHelper.getMonth(this.month);
    this.init.emit({ day: this.day, month: this.month, year: this.year });
  }

  private getTextDay(date: Date) {
    this.getDateData(date);

    if (new Date().toDateString() === date.toDateString()) {
      this.dateText = 'Сегодня, ';
    } else {
      this.dateText = '';
    }

    this.dateText += this.datePipe.transform(date, 'd MMMM, EEEEEE', 'ru');
    this.init.emit({ day: this.day, month: this.month, year: this.year });
  }

  private getDateData(date: Date) {
    this.year = date.getFullYear();
    this.month = date.getMonth();
    this.day = date.getDate();
  }

  prevClick() {
    switch (this.viewType) {
      case CalendarViewType.month:
        // переход на предыдущий год
        this.prevClickMonth();
        break;
      case CalendarViewType.day:
        // переход на предыдущий день
        this.prevClickDay();
        break;
    }
  }

  private prevClickMonth() {
    if (this.month === 0) {
      this.year--;
      this.month = 11;
    } else {
      this.month--;
    }
    this.monthName = CalendarHelper.getMonth(this.month);
    this.prev.emit({ month: this.month, year: this.year });
  }

  private prevClickDay() {
    this.addTextDays(-1);
    this.prev.emit({ day: this.day, month: this.month, year: this.year });
  }

  nextClick() {
    switch (this.viewType) {
      case CalendarViewType.month:
        // переход на следующий год
        this.nextClickMonth();
        break;
      case CalendarViewType.day:
        // переход на следующий день
        this.nextClickDay();
        break;
    }
  }

  private nextClickMonth() {
    if (this.month === 11) {
      this.year++;
      this.month = 0;
    } else {
      this.month++;
    }
    this.monthName = CalendarHelper.getMonth(this.month);
    this.next.emit({ month: this.month, year: this.year });
  }

  private nextClickDay() {
    this.addTextDays(1);
    this.next.emit({ day: this.day, month: this.month, year: this.year });
  }

  private addTextDays(daysToAdd: number) {
    const currentDay = new Date(this.year, this.month, this.day);
    currentDay.setDate(currentDay.getDate() + daysToAdd);
    this.getTextDay(currentDay);
  }
}
