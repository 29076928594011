export enum ElementType {
    Undefined = 0,
    Event = 1,
    Comment = 2,
    Group = 3,
    Media = 4,
    Vacancy = 5,
    VacancyResponse = 6,
    Idea = 7
}
