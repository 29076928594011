export enum NotificationType {
    Unknown,
    Subsribed,
    AddedToGroup,
    SharedFile,
    GroupJoinApproved,
    GroupJoinRejected,
    GroupSubscriptionApproved,
    GroupSubscriptionRejected,
    GroupJoinRequest,
    GroupSubscriptionRequest,
    PostMention,
    PostCommentMention,
    PostLike,
    PostCommentLike,
    GroupPostMention,
    GroupCommentMention,
    GroupPostLike,
    GroupCommentLike,
    PostResponse,
    PostCommentResponse,
    GroupPostResponse,
    GroupCommentResponse,
    PostResponseForMentioned,
    PostCommentResponseForMentioned,
    GroupPostResponseForMentioned,
    GroupCommentResponseForMentioned,
    SharedGroupFile,
    RemovedFromGroup,
    NewVacancyResponse,
    NewIdea,
    ThanksAdded,
    BirthdayToday,
    TakeSurvey,
    SurveyExpired,
    AwardAdded
}
