<div class="card post _edit" appClickOutside (clickOutside)="collapse()" [hidden]="isHidden">
  <div class="new-comment__main">
    <app-user-avatar *ngIf="currentUser" [user]="currentUser" [linkClass]="'avatar _30'" [imageClass]="'avatar__image'"></app-user-avatar>
    <app-froala-inline-editor
      class="post__editor"
      [focusOnInit]="false"
      [(editorModel)]="text"
      [placeholder]="'Написать комментарий'"
      (focused)="expand()"
      [(text)]="text"
      [(mentions)]="mentions"
      [user]="currentUser"
    ></app-froala-inline-editor>
  </div>
  <ng-container *ngIf="fullView">
    <app-attachments-list #attachmentsList [attachments]="attachments" [isEdit]="true" (onFilesUploaded)="onFilesUploaded($event)"
      (onRemove)="onRemoveAttachment($event)" [ownerId]="ownerId" [elementType]="elementType"></app-attachments-list>
    <app-media-attachments-list #mediaAttachmentsList [mediaAttachments]="mediaAttachments" [isEdit]="true" (onRemove)="onRemoveAttachment($event)"
      (onFilesUploaded)="onMediaFilesUploaded($event)" [ownerId]="ownerId" [ownerType]="ownerType" [elementType]="elementType"></app-media-attachments-list>
    <div class="post__actions" [ngClass]="{'loading _blue': isBusy}">
      <app-attachments-menu *ngIf="!hideAddAttachment" (choose)="attachmentsList.onChooseFile($event)" (upload)="attachmentsList.onUploadFile($event)"></app-attachments-menu>
      <app-media-attachments-menu (choose)="mediaAttachmentsList.onChooseFile($event)" (upload)="mediaAttachmentsList.onUploadFile($event)"></app-media-attachments-menu>
      <div class="post__submit">
        <button type="button" class="btn btn-simple" (click)="cancel()">Отмена</button>
        <button type="button" class="btn btn-primary" (click)="submit()">Опубликовать</button>
      </div>
    </div>
  </ng-container>
</div>
