<div class="btn tooltip" [ngClass]="{'btn-dots':isMobileView(), 'btn-plus-simple':!isMobileView() && !isBigPlus(), 'btn-plus':isBigPlus(), 'pull-right':isBigPlus()}"
     matTooltip="Добавить дочерний элемент"
  *ngIf="showAddButton" mat-button [matMenuTriggerFor]="menu">+</div>
<mat-menu #menu="matMenu">
  <div class="icon-menu">
    <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" (click)="navigateToNewForm()">
      <span class="icon-menu__icon">
        <img src="{{assetsPrefix}}/assets/images/icons/menu/choose-file.svg" alt="" class="icon-menu__img">
      </span>
      <span>Добавить страницу</span>
    </a>
    <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" (click)="openCreateLink()">
      <span class="icon-menu__icon">
        <img src="{{assetsPrefix}}/assets/images/icons/menu/link.svg" alt="" class="icon-menu__img">
      </span>
      <span>Добавить ссылку</span>
    </a>
  </div>
</mat-menu>
