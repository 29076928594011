<!-- Header -->
<div class="user-header" *ngIf="loaded && post?.owner">
  <span class="user-header__back">
    <a routerLink="/profile/{{post?.owner?.id}}" class="back"
      (click)="usersService.navigateProfile(post?.owner?.id)"></a>
  </span>
  <a routerLink="/profile/{{post?.owner?.id}}" class="link-profile"
    (click)="usersService.navigateProfile(post?.owner?.id)">
    <app-user-avatar [user]="post.owner" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'" [asSpan]="true">
    </app-user-avatar>
    <span class="link-profile__text" [appUserTooltip]="post.owner?.id">{{post.owner?.fullName}}</span>
  </a>
  <app-breadcrumbs></app-breadcrumbs>
</div>
<!-- Header END -->
<!-- Empty post -->
<div class="card" *ngIf="loaded && (!post || notFound)">
  <div class="group-empty">
    <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" class="group-empty__img">
    <div class="group-empty__text">Пост не найден</div>
  </div>
</div>
<!-- Empty post END-->
<!-- post -->
<app-post *ngIf="loaded" [showBody]="true" [user]="user" [post]="post" (onRemove)="remove(post)"
  [allowEdit]="canEdit(post)" [allowDelete]="canDelete(post)"></app-post>
<!-- post END -->
<!-- preloaders -->
<app-post-preloader [loaded]="loaded" [loading]="loading"></app-post-preloader>
<!-- preloaders END -->
