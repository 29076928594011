import { Injectable, Inject } from '@angular/core';
import {
  AbstractSecurityStorage,
  OidcSecurityService,
  PublicEventsService,
  TokenHelperService,
  TokenValidationService
} from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { INTRY_AUTH_CONFIG, IntryAuthConfig } from '../models/intry-auth.config';
import { IntryAuthRedirectService } from './intry-auth-redirect.service';
import { SessionStorageService } from '@app/intry-storage/services/session-storage.service';

@Injectable()
export class IntryAuthService {

  public readonly isAuthenticated$ = this.oidcSecurityService.isAuthenticated$;
  public readonly userData$ = this.oidcSecurityService.userData$;
  public readonly configuration = this.oidcSecurityService.configuration.configuration;

  constructor(
    @Inject(INTRY_AUTH_CONFIG) private readonly config: IntryAuthConfig,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly router: Router,
    private readonly eventService: PublicEventsService,
    private readonly storage: SessionStorageService,
    private readonly redirectService: IntryAuthRedirectService,
    private readonly tokenValidationService: TokenValidationService,
    private readonly oidcSecurityStorage: AbstractSecurityStorage,
    private readonly tokenHelperService: TokenHelperService
  ) {
  }

  /**
   * Выполнить вход
   */
  public login(redirectUrl: string | null = null): void {
    this.redirectService.setAuthRedirectRoute(redirectUrl || this.router.url);
    this.oidcSecurityService.authorize();
  }

  /**
   * Выполнить вход
   */
  public loginAsExternal(redirectUrl: string | null = null): void {
    this.redirectService.setAuthRedirectRoute(redirectUrl || this.router.url);
    this.oidcSecurityService.authorize({
      customParams: {
        login_hint: 'external',
      },
    });
  }

  /**
   * Выполнить выход
   */
  public logout(): void {
    this.redirectService.reset();
    this.oidcSecurityService.logoff();
  }

  /**
   * Получить access токен текущего пользователя
   */
  public getToken(): string {
    return this.oidcSecurityService.getToken();
  }

  /**
   * Получить ID токен текущего пользователя
   */
  public getIdToken(): string {
    return this.oidcSecurityService.getIdToken();
  }

  /**
   * Получить значение для заголовка Authorization с токеном текущего пользователя 'Bearer ...'
   */
  public getAuthorizationHeaderValue(): string {
    return `Bearer ${this.oidcSecurityService.getToken()}`;
  }

  /**
   * Принудительное обновление сессии
   */
  public forceRefreshSession(): Observable<any> {
    return this.oidcSecurityService.forceRefreshSession();
  }

  /**
   * Является ли пользователь внешним?
   */
  public isExternalUser(): boolean {
    const token = this.oidcSecurityService.getPayloadFromIdToken(false);
    return token && token.idp === 'External';
  }

  /**
   * Разрешена ли авторизация для внешних пользователей?
   */
  public isExternalUsersAllowed(): boolean {
    return this.config.allowExternal;
  }

  public isTokenExpired(renewTimeBeforeTokenExpiresInSeconds: number | null = null): boolean {
    const accessTokenExpiresIn = this.getTokenExpiresAt();
    const accessTokenHasNotExpired = this.tokenValidationService.validateAccessTokenNotExpired(
      accessTokenExpiresIn,
      renewTimeBeforeTokenExpiresInSeconds || this.configuration.renewTimeBeforeTokenExpiresInSeconds
    );

    return !accessTokenHasNotExpired;
  }

  public getTokenExpiresAt(): Date {
    const prefix = this.configuration.clientId || '';
    const timestamp = this.oidcSecurityStorage.read(`${prefix}_access_token_expires_at`);

    return new Date(timestamp);
  }

  public isIdTokenExpired(renewTimeBeforeTokenExpiresInSeconds: number | null = null): boolean {
    const decodedIdToken = this.tokenHelperService.getPayloadFromToken(this.getIdToken(), false);

    const idTokenHasNotExpired = this.tokenValidationService.validateIdTokenExpNotExpired(
      decodedIdToken,
      renewTimeBeforeTokenExpiresInSeconds || this.configuration.renewTimeBeforeTokenExpiresInSeconds
    );

    return !idTokenHasNotExpired;
  }

  public getIdTokenExpiresAt(): Date {
    const decodedIdToken = this.tokenHelperService.getPayloadFromToken(this.getIdToken(), false);
    const tokenExpirationDate = this.tokenHelperService.getTokenExpirationDate(decodedIdToken);

    return new Date(tokenExpirationDate);
  }

  public authCallbackIfRequired() {
    // this.headerService.hide();
    this.oidcSecurityService.checkAuth().subscribe((auth) => {
      // tslint:disable-next-line:no-console
      console.debug('[IntryAuthService] Authentication was ' + (auth ? 'succeeded' : 'failed'));
      if (auth && (!this.isExternalUser() || (this.config.allowExternal && this.config.showHeaderForExternal))) {
        // this.headerService.show();
      }
    });
  }

  // ------------------------ DEPRECATIONS ------------------------ //
  /**
   * @deprecated
   * @see isAuthenticated$
   */
  public getIsAuthorized(): Observable<boolean> {
    return this.isAuthenticated$;
  }

  /**
   * @deprecated
   * @see isAuthenticated$
   */
  public isAuthorized(): Observable<boolean> {
    return this.isAuthenticated$;
  }

  /**
   * @deprecated
   * @see forceRefreshSession
   */
  public refreshSession(): Observable<any> {
    return this.forceRefreshSession();
  }

  /**
   * @deprecated
   * @see userData$
   */
  public getUserData(): Observable<any> {
    return this.userData$;
  }

  /**
   * @deprecated Пользуйтесь гвардами, этот метод не должен бы быть публичным, и не должен был быть объявлен в этом сервисе
   */
  public checkExternalUsers() {
    // если пользователь внешний, но для внешних пользователей не разрешён доступ, то нужно переводить на 403
    if (!this.config.allowExternal) {
      if (this.isExternalUser()) {
        this.router.navigate([this.configuration.forbiddenRoute], {
          skipLocationChange: true,
          queryParams: { from_url: encodeURIComponent(location.href) }
        });
        return;
      }
    }
  }

}
