import { Component } from '@angular/core';
import { VacancyService } from '@app/vacancy/services/vacancy.service';
import { AlertsService } from '@app/shared/services/alerts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { MatDialog } from '@angular/material/dialog';
import { HelperService } from '@app/core/services/helper.service';
import { VacancyNewComponent } from './vacancy-new.component';
import { GroupsService } from '@app/groups/services/groups.service';
import { takeUntil } from 'rxjs/operators';
import { UsersService } from '@app/profile/services/users.service';

/**
 * Компонент создания вакансии в группе
 *
 * @export
 * @class GroupVacancyNewComponent
 * @extends {VacancyNewComponent}
 */
@Component({
  selector: 'app-vacancy-new',
  templateUrl: './vacancy-new.component.html',
  styleUrls: ['./vacancy-new.component.scss']
})
export class GroupVacancyNewComponent extends VacancyNewComponent {

  showScope = false;

  constructor(
    public groupsService: GroupsService,
    public usersService: UsersService,
    public vacancyService: VacancyService,
    protected alertsService: AlertsService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected breadcrumbsService: BreadcrumbsService,
    protected dialog: MatDialog,
    public helper: HelperService
  ) {
    super(usersService, vacancyService, alertsService, route, router, breadcrumbsService, dialog, helper);
    this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
      if (group) {
        this.vacancy.ownerId = group.id;

        // обновляем хлебные крошки
        const vacancyRoute = `/group/${group.id}/vacancy`;

        this.breadcrumbsService.breadcrumbs.next([
          { title: 'Вакансии', routerUrl: vacancyRoute },
          { title: 'Новая вакансия' }
        ]);
      }
    });
  }
}
