import { Injectable } from '@angular/core';
import { ThanksApiService } from './thanks-api.service';

@Injectable({
  providedIn: 'root'
})
export class ThanksService extends ThanksApiService {

  public ERROR_ON_THANK = '«Спасибо» не отправлено. Попробуйте позже.';

}
