import { Component, OnInit, Input } from '@angular/core';
import { IVacancy, VacancyStatus } from '@app/vacancy/model/vacancy';
import { VacancyService } from '@app/vacancy/services/vacancy.service';

/**
 * Прорисовка списка вакансий. Список элементов, меню для каждого элемента.
 *
 * @export
 * @class VacancyListViewComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-vacancy-list-view',
  templateUrl: './vacancy-list-view.component.html',
  styleUrls: ['./vacancy-list-view.component.scss']
})
export class VacancyListViewComponent {

  @Input() vacancies: IVacancy[];
  @Input() showMenu: boolean;
  @Input() hideGroupsTitle: boolean;

  constructor(public vacancyService: VacancyService) { }

  trackBy(index, item: IVacancy) {
    if (item) {
      return item.id;
    }
  }

  getRouterLink(vacancy: IVacancy) {
    if (vacancy) {
      return `/group/${vacancy.ownerId}/vacancy/${vacancy.id}`;
    }
  }

  getVacancyConfig(vacancy: IVacancy) {
    if (!this.showMenu || !vacancy.userAdmin) {
      return false;
    } else {
      return {
        allowArchive: vacancy.status === VacancyStatus.published,
        allowEdit: true,
        allowDelete: true
      };
    }
  }

  /**
   * Действие при событии "Вакансия удалена"
   *
   * @param {number} id
   * @memberof UserVacancyWidgetComponent
   */
  onDeleted(id: number) {
    this.vacancies = this.vacancies.filter(s => s.id !== id);
  }
}
