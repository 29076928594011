import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThanksWidgetComponent } from './components/thanks-widget/thanks-widget.component';
import { ThanksListComponent } from './components/thanks-list/thanks-list.component';
import { SharedModule } from '@app/shared/shared.module';
import { ThanksService } from './services/thanks.service';
import { ThanksApiService } from './services/thanks-api.service';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NewThankComponent } from './components/new-thank/new-thank.component';
import { UserThanksWidgetComponent } from './components/user-thanks-widget/user-thanks-widget.component';
import { AddThankButtonComponent } from './components/add-thank-button/add-thank-button.component';
import { ThankViewComponent } from './components/thank-view/thank-view.component';
import { ThanksAllListComponent } from '@app/thanks/components/thanks-all-list/thanks-all-list.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule
  ],
  declarations: [
    ThanksWidgetComponent,
    ThanksListComponent,
    ThanksAllListComponent,
    NewThankComponent,
    UserThanksWidgetComponent,
    AddThankButtonComponent,
    ThankViewComponent
  ],
  exports: [
    ThanksWidgetComponent,
    ThanksListComponent,
    ThanksAllListComponent,
    UserThanksWidgetComponent,
    AddThankButtonComponent,
    ThankViewComponent
  ],
  providers: [
    ThanksService,
    ThanksApiService
  ],
})
export class ThanksModule { }
