import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

export interface IBreadcrumb {
  title?: string | '';
  url?: string | SafeUrl | '';
  routerUrl?: string | '';
  fragment?: string | '';
}

/**
 * Сервис хлебных крошек
 *
 * @export
 * @class BreadcrumbsService
 */
@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {
  public breadcrumbs: BehaviorSubject<IBreadcrumb[]> = new BehaviorSubject<IBreadcrumb[]>(null);
  private sanitizer: DomSanitizer;

  constructor() { }

  set(
    title: string = '',
    url: SafeUrl = this.sanitizer.bypassSecurityTrustUrl('javascript:;'),
    routerUrl: string = '',
    fragment: string = '') {
    this.breadcrumbs.next([{ title: title, routerUrl: routerUrl, fragment: fragment, url: url }]);
  }

  add(
    title: string = '',
    url: SafeUrl = this.sanitizer.bypassSecurityTrustUrl('javascript:;'),
    routerUrl: string = '',
    fragment: string = '') {

    let breadCrumbs = this.breadcrumbs.getValue();

    if (!breadCrumbs) {
      breadCrumbs = [];
    }

    if (!breadCrumbs.find(s => s.title === title && s.url === url && s.fragment === routerUrl && s.routerUrl === fragment)) {
      breadCrumbs.push({ title: title, routerUrl: routerUrl, fragment: fragment, url: url });
      this.breadcrumbs.next(breadCrumbs);
    }
  }

  remove(title: string) {
    let breadcrumbs = this.breadcrumbs.getValue();

    if (breadcrumbs) {
      const breadcrumb = breadcrumbs.find(s => s.title === title);
      if (breadcrumb) {
        breadcrumbs = breadcrumbs.filter(s => s.title !== title);
        this.breadcrumbs.next(breadcrumbs);

      }
    }
  }

  clear() {
    this.breadcrumbs.next(null);
  }
}
