import { Component, OnInit, OnDestroy } from '@angular/core';
import { IVacancy, IVacancyCategory, IVacancyStatus } from '@app/vacancy/model/vacancy';
import { TabbedListComponent } from '@app/shared/components/tabs/tabbed-list.component';
import { UsersService } from '@app/profile/services/users.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HelperService } from '@app/core/services/helper.service';
import { takeUntil } from 'rxjs/operators';

/**
 * Список вакансий
 *
 * @export
 * @class VacancyListComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-vacancy-list',
  template: ''
})
export class VacancyListComponent extends TabbedListComponent<IVacancy> implements OnInit, OnDestroy {

  showAddButton: boolean;
  filtersVisible: boolean;

  currentStatus: IVacancyStatus;
  currentCategory: IVacancyCategory;

  maxItemHeight: 80;

  constructor(
    protected usersService: UsersService,
    protected router: Router,
    protected route: ActivatedRoute,
    public helper: HelperService
  ) { super(router, route, helper); }

  ngOnInit() {
    // find what has is now and route navigate with hash
    this.route.fragment
      .pipe(takeUntil(this.destroyed$))
      .subscribe(val => {
        // get hash
        if (val != null) {
          const keys = val.split('&');
          const hash = {};
          keys.forEach(key => {
            // tslint:disable-next-line:no-shadowed-variable
            const val = key.split('=');
            hash[val[0]] = val[1];
          });

          const category = hash['category'];
          const status = hash['status'];

          if (category || status) {
            this.filtersVisible = true;
            if (category) {
              if (!this.currentCategory) {
                this.currentCategory = {};
              }
              this.currentCategory.id = category;
            }
          }

          // очищаем текущий табик
          this.currentTab = null;

          this.tabs.items.forEach(tab => {
            const fragment = keys.find(k => k === tab.fragment);

            if (tab.fragment && fragment) {
              const currentTabId = this.currentTab ? this.currentTab.id : null;
              this.currentTab = tab;
              if (currentTabId !== tab.id) {
                this.cleanTab(this.currentTab);
              }
            }
          });

          if (!this.currentTab) {
            this.currentTab = this.tabs.items.find(tab => !tab.fragment || tab.default);
          }
        } else {
          this.currentTab = this.tabs.items.find(tab => !tab.fragment || tab.default);
        }

        // получить данные по текущему табику
        this.cleanTab(this.currentTab);
        this.currentTab.method(this.currentTab, this);
      });
  }

  isShowMenu(): boolean {
    return false;
  }

  onCategoryChanged(category: IVacancyCategory) {
    this.currentCategory = category;
    this.navigateOnStatusOrCategoryChanged();
  }

  onStatusChanged(status: IVacancyStatus) {
    this.currentStatus = status;
    this.navigateOnStatusOrCategoryChanged();
  }

  private navigateOnStatusOrCategoryChanged() {
    let fragment = 'admin';

    if (this.currentStatus && this.currentStatus.id) {
      fragment = this.setFragment(fragment, 'status', this.currentStatus.id);
    }

    if (this.currentCategory && this.currentCategory.id) {
      fragment = this.setFragment(fragment, 'category', this.currentCategory.id);
    }

    if (fragment) {
      this.router.navigate(['..'], { fragment: fragment, relativeTo: this.route });
    } else {
      this.router.navigate(['..'], { relativeTo: this.route });
    }
  }

  private setFragment(fragment: string, filterName: string, filterValue: any): string {
    if (fragment) {
      fragment += '&';
    }
    fragment += `${filterName}=${filterValue}`;
    return fragment;
  }

  /**
  * Действие при прокрутке страницы.
  * Загружаем остальные элементы, учитывая offset и limit
  */
  protected onScroll() {
    const number = window.innerHeight + window.pageYOffset + 20;
    if (this.currentTab && this.currentTab.items
      && (number > this.maxItemHeight * this.currentTab.items.length
        || this.currentTab.offset > 0 && this.currentTab.offset <= this.currentTab.items.length)) {

      if (!this.currentTab.loading) {
        if (this.currentTab.itemsCount > this.currentTab.items.length) {
          this.currentTab.method(this.currentTab, this);
        }
      }
    }
  }
}
