import {Component, OnInit, OnDestroy} from '@angular/core';
import {VacancyWidgetComponent} from '@app/vacancy/components/vacancy-widget/vacancy-widget.component';
import {GroupInfo} from '@app/groups/model/group-info.model';
import {GroupsService} from '@app/groups/services/groups.service';
import {Router} from '@angular/router';
import {HelperService} from '@app/core/services/helper.service';
import {takeUntil, take} from 'rxjs/operators';
import {VacancyService} from '@app/vacancy/services/vacancy.service';
import {IVacancy, VacancyStatus} from '@app/vacancy/model/vacancy';
import {GroupType} from '@app/groups/model/group-type.model';
import { UsersService } from '@app/profile/services/users.service';
import { User } from '@app/profile/model/user.model';

@Component({
  selector: 'app-group-vacancy-widget',
  templateUrl: './group-vacancy-widget.component.html',
  styleUrls: ['./group-vacancy-widget.component.scss'],
})
export class GroupVacancyWidgetComponent extends VacancyWidgetComponent implements OnInit, OnDestroy {
  /**
   * Группа, в которой находится виджет
   *
   * @type {GroupInfo}
   * @memberof GroupSurveysWidgetComponent
   */
  group: GroupInfo;
  currentUser: User;

  constructor(
    protected usersService: UsersService,
    protected groupsService: GroupsService,
    public vacancyService: VacancyService,
    protected router: Router,
    public helper: HelperService,
  ) {
    super(router, helper);
  }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;

      this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
        if (group) {
          this.group = group;

          this.link = `/group/${this.group.id}/vacancy`;

          this.getItems(true);
        } else {
          this.group = null;
        }
      });
    });
  }

  getItems(force: boolean) {
    if (!this.loaded || force) {
      this.vacancyService
        .getProfileVacancies(this.group.id, 0, 3)
        .pipe(take(1))
        .subscribe(
          res => {
            this.vacancies = res && res.length ? res : null;

            this.showWidget = this.groupsService.canReadGroup(this.group, this.currentUser);

            if (this.showWidget) {
              this.showAddButton = this.groupsService.isAdmin(this.group, this.currentUser);
              this.addButtonLink = this.link + `/new`;
            }

            // если вакансий нет, то виджет видим только админам группы
            if (!this.groupsService.isAdmin(this.group, this.currentUser) && (!this.vacancies || !this.vacancies.length)) {
              this.showWidget = false;
            }

            this.loaded = true;
          },
          error => {
            this.loaded = true;
          },
        );
    }
  }

  getVacancyConfig(vacancy: IVacancy) {
    return {
      allowArchive: this.showAddButton && vacancy.status === VacancyStatus.published,
      allowEdit: this.showAddButton,
      allowDelete: this.showAddButton,
    };
  }

  /**
   * Действие при событии "Вакансия удалена"
   *
   * @param {number} id
   * @memberof UserVacancyWidgetComponent
   */
  onDeleted(id: number) {
    this.vacancies = this.vacancies.filter(s => s.id !== id);
  }
}
