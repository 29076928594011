import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { User } from '@app/profile/model/user.model';
import { Subject } from 'rxjs';
import { UsersService } from '@app/profile/services/users.service';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { HelperService } from '@app/core/services/helper.service';
import { takeUntil } from 'rxjs/operators';

/**
 * Корневой компонент для лубого сервиса. Содержит шапку с юзером и хлебными крошками
 *
 * @export
 * @class ProfileServiceComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-profile-service',
  templateUrl: './profile-service.component.html',
  styleUrls: ['./profile-service.component.scss']
})
export class ProfileServiceComponent extends BaseComponent implements OnInit, OnDestroy {

  user: User;

  constructor(
    protected usersService: UsersService,
    protected breadcrumbsService: BreadcrumbsService,
    public helper: HelperService) { super(helper); }

  ngOnInit() {
    this.usersService.user.pipe(takeUntil(this.destroyed$)).subscribe(user => {
      this.user = user ? user : null;
    });
  }
}
