import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from '@app/profile/services/users.service';
import { takeUntil, finalize, take } from 'rxjs/operators';
import { User, IUserProfileProperty, Privacy } from '@app/profile/model/user.model';
import { Subject } from 'rxjs';
import { GlobalValidator } from '@app/shared/global-validator';
import { AlertsService } from '@app/shared/services/alerts.service';
import { UserLink } from '@app/subscribe/model/user-link';
import { HelperService } from '@app/core/services/helper.service';
import { BaseResizableComponent } from '@app/core/components/base-resizable.component';

import * as moment from 'moment';
import { Helper } from '@app/core/helpers/helper';

/**
 * Свойства профиля пользователя
 *
 * @export
 * @class ProfilePropertiesComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-profile-properties',
  templateUrl: './profile-properties.component.html',
  styleUrls: ['./profile-properties.component.scss']
})
export class ProfilePropertiesComponent extends BaseResizableComponent implements OnInit, OnDestroy {

  /**
   * Пользователь, свойства профиля которого необходимо получить
   *
   * @type {User}
   * @memberof ProfilePropertiesComponent
   */
  user: User;

  /**
   * Текущий пользователь
   *
   * @type {User}
   * @memberof ProfilePropertiesComponent
   */
  currentUser: User;

  isCurrent: boolean;

  profileProperties: IUserProfileProperty[] = [];
  originProperties: IUserProfileProperty[] = [];

  Privacy = Privacy;

  searchText: string;

  timeoutId: any;
  usersFound: User[];

  subordinates: UserLink[];
  subordinatesMoreCount: number;

  manager: IUserProfileProperty;
  hireDate: IUserProfileProperty;

  showMoreSubordinates: boolean;

  private updatingProperty: boolean;

  constructor(
    private usersService: UsersService,
    private alertsService: AlertsService,
    public helperService: HelperService
  ) { super(helperService); }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;
      if (this.currentUser) {
        this.usersService.user.pipe(takeUntil(this.destroyed$)).subscribe(user => {
          this.clear();
          this.user = user;
          if (this.user) {
            this.getAdditionalProperties();
            this.getSubordinates();
          }
        });
      }
    });
  }

  clear() {
    this.manager = null;
    this.hireDate = null;
    this.subordinates = [];
    this.subordinatesMoreCount = 0;
    this.originProperties = [];
    this.profileProperties = [];
    this.showMoreSubordinates = false;
  }

  getPropertyName(property: IUserProfileProperty): string {
    // Костыль
    if (property.name === 'Assistant') {
      return 'Заместитель';
    }
    return property.displayName;
  }

  getUser(property: IUserProfileProperty) {
    if (property.data || property.loaded) {
      return property.data;
    }

    const user = this.usersService.users.find(s => s.accountName
      && property.value
      && s.accountName.toLowerCase() === property.value.toLowerCase());

    if (user) {
      property.data = user;
      property.loaded = true;
      if (property.name === 'Manager') {
        this.manager = property;
      }
      return property.data;
    }

    if (property.value) {
      this.usersService.getUserByLogin(property.value)
        .pipe(finalize(() => property.loaded = true), takeUntil(this.destroyed$))
        .subscribe(res => {
          property.data = res;
          if (property.name === 'Manager') {
            this.manager = property;
          }
        });
    }
  }

  /**
     * Отслеживать нажатие 'enter'
     *
     * @param {*} e
     * @memberof UserCardComponent
     */
  onKey(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.target.blur();
    }
  }

  /**
   * Сохранение свойства при потере фокуса
   *
   * @param {*} e
   * @memberof UserCardComponent
   */
  onBlur(e, propertyName: string) {
    this.usersService.currentUser.subscribe(currentUser => {

      if (!currentUser) {
        return;
      }

      console.log('on blur for user: ' + this.user.accountName + ' and prop ' + propertyName + ' with value: ' + e.target.value);
      if (this.isCurrent) {
        let update = false;
        switch (propertyName) {
          case 'WorkEmail':
            // tslint:disable-next-line:triple-equals
            if (this.user.email != e.target.value) {
              if (!GlobalValidator.mailIsValid(e.target.value)) {
                console.log('email is invalid ' + e.target.value);
                this.alertsService.riseError(`Ошибка сохранения email адреса. Введён некорректный email.`);
                update = false;
                e.target.value = this.user.email;
              } else {
                update = true;
              }
            }
            break;
          case 'CellPhone':
            // tslint:disable-next-line:triple-equals
            if (this.user.mobilePhone != e.target.value) {
              if (!GlobalValidator.phoneIsValid(e.target.value)) {
                console.log('phone is invalid ' + e.target.value);
                this.alertsService.riseError(`Ошибка сохранения номера мобильного телефона. Введён некорректный номер.`);
                update = false;
                e.target.value = this.user.mobilePhone;
              } else {
                update = true;
              }
            }
            break;
          default:
            update = true;
            break;
        }
        if (update) {
          // получить старое значение свойства
          const originProperty = this.originProperties.find(s => s.name === propertyName);

          if (!this.updatingProperty && (!originProperty || originProperty.value !== e.target.value)) {
            this.updateUserProperty(currentUser, propertyName, e.target.value);
          }
        }
      } else {
        console.log('u can\'t change property of another user');
      }
    });
  }

  changeUser($event: Event, property: IUserProfileProperty) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    $event.stopPropagation();
    property.showPopup = !property.showPopup;
  }

  selectUser($event: Event, user: User, property: IUserProfileProperty) {

    this.usersService.currentUser.subscribe(currentUser => {
      if (!currentUser) {
        return;
      }

      // пользователя можно сбросить
      const accountName = user ? user.accountName : '';
      this.updateUserProperty(currentUser, property.name, accountName, () => {
        property.data = user ? user : null;
      });
    });

    this.clearChangeUser($event, property);
  }

  clearChangeUser($event: Event, property: IUserProfileProperty) {
    property.showPopup = false;
    property.searchText = '';
    property.dataLoaded = false;
    property.dataFound = null;
  }

  private updateUserProperty(
    currentUser: User,
    propertyName: string,
    propertyValue: string,
    successCallback: Function = null) {

    this.updatingProperty = true;
    this.usersService.updateUserProperty(propertyName, propertyValue, currentUser.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        if (res) {

          switch (propertyName) {
            case 'WorkEmail':
              this.user.email = propertyValue;
              break;
            case 'CellPhone':
              this.user.mobilePhone = propertyValue;
              break;
          }

          if (this.user.id === currentUser.id) {
            this.usersService.currentUser.next(this.user);
          }

          try {
            this.originProperties.find(s => s.name === propertyName).value = propertyValue;
            this.profileProperties.find(s => s.name === propertyName).value = propertyValue;
          } catch (e) {
            console.log(`error on updating property object=`, e);
          }

          this.updatingProperty = false;
          this.alertsService.riseSuccess(`Свойство профиля успешно изменено`);
          this.usersService.anyPropertyChanged$.next({ propertyName, propertyValue });
          if (successCallback) {
            successCallback();
          }
        } else {
          this.alertsService.riseError(`Произошла ошибка при обновлении свойства профиля`);
        }
      }, err => {
        console.log('error on update user property: ' + err);
        this.updatingProperty = false;
        this.alertsService.riseError(`Произошла ошибка при обновлении свойства профиля`);
      });
  }

  onSearchKey(e, property: IUserProfileProperty) {
    clearTimeout(this.timeoutId);

    if (e.keyCode === 27) {
      // ESC
      property.searchText = '';
      return false;
    }

    if (property.searchText && property.searchText.length > 1) {
      const that = this;
      this.timeoutId = setTimeout(function () {
        property.dataLoaded = false;
        that.usersService.searchUsers(encodeURIComponent(property.searchText.trim()), 0, 0, 10)
          .pipe(take(1), finalize(() => property.dataLoaded), takeUntil(that.destroyed$))
          .subscribe(res => {
            if (res) {
              property.dataFound = res.items.map(u => u.user);
            }

            if (property.dataFound.length === 0) {
              property.error = 'Совпаденияне не найдены';
            } else {
              property.error = null;
            }
          }, error => {
            console.log(error);
            property.error = 'Произошла ошибка при поиске данных';
          });
      }, 500);
    } else {
      property.dataLoaded = true;
      property.dataFound = [];
      if (property.searchText && property.searchText.length) {
        property.error = 'Введите хотя бы 2 символа';
      }
    }

    if (e.keyCode === 13) {
      return false;
    }
  }

  getProfileProperties() {
    return this.profileProperties.filter(s => !s.hidden);
  }

  /**
   * Получение дополнительных свойств пользователя.
   * Отображаются только те, в которых есть данные, при этом:
   * 1. в своём профиле пользователь:
   * 1.1. может редактировать свойства, если isEditable==true
   * 1.2. может изменять видимость, если userOverridePrivacy
   * 2. в чужом профиле:
   * 2.1. только чтение, только privacy==1 и только если заполнены
   *
   * @private
   * @memberof ProfilePropertiesComponent
   */
  private getAdditionalProperties() {
    this.usersService.getUserProperties(this.user.accountName)
      .pipe(
        finalize(() => {
          this.usersService.userPropertiesLoaded$.next();
        }),
        takeUntil(this.destroyed$))
      .subscribe(res => {
        if (res) {
          this.isCurrent = this.currentUser && this.user && this.currentUser.id === this.user.id;

          // временно получение пользователя через managerLogin
          if (!res.find(s => s.name === 'Manager') && this.user.managerLogin) {
            res.push({
              data: null,
              name: 'Manager',
              value: this.user.managerLogin,
              displayName: 'Manager',
              type: 'Person',
              isEditable: false,
              privacy: Privacy.Public,
              userOverridePrivacy: false,
            });
          }

          this.profileProperties = res.filter(s => {
            if (s.name === 'SPS-Birthday'
              || s.name === 'Birthday'
              || s.name === 'CellPhone'
              || s.name === 'FB'
              || s.name === 'VK'
              || s.name === 'Instagram'
              || s.name === 'OK'
              || s.name === 'Title' // Должность
              || s.name === 'RussianPatronymicName') {
              return false;
            }

            if (s.name === 'Manager' || s.name === 'HireDate') {
              s.hidden = true;
            }

            if (s.privacy === Privacy.Private) {
              return this.isCurrent ? true : false;
            } else {
              return s.value || this.isCurrent && s.isEditable;
            }
          });

          // отправить изменения в другие компоненты
          this.usersService.userBirthdayProperty$.next(res.find(s => s.name === 'SPS-Birthday'));
          this.usersService.cellPhoneProperty$.next(res.find(s => s.name === 'CellPhone'));
          this.usersService.russianPatronymicName$.next(res.find(s => s.name === 'RussianPatronymicName'));

          this.usersService.fbLinkProperty$.next(res.find(s => s.name === 'FB'));
          this.usersService.vkLinkProperty$.next(res.find(s => s.name === 'VK'));
          this.usersService.instagramLinkProperty$.next(res.find(s => s.name === 'Instagram'));
          this.usersService.odnoklassnikiLinkProperty$.next(res.find(s => s.name === 'OK'));

          this.profileProperties.forEach(property => {
            if (property.type === 'Person') {
              this.getUser(property);
            }
            // Выводить в формате «X лет Y месяцев Z дней» например.
            // Если сотрудник работает меньше месяца, то выводить только «Z дней», если меньше года – «Y месяцев Z дней».
            // Выводим эту информацию только в профиле пользователя.
            // Она доступна всем сотрудникам, управлять настойками ее приватности пользователи не могут.
            if (property.name === 'HireDate' && property.value) {
              try {
                const now = new Date();
                const value = property.value;
                const first = value.indexOf('.');
                const last = value.lastIndexOf('.');
                const year = +value.substr(last + 1);
                const month = +value.substr(first + 1, last - first) - 1;
                const day = +value.substr(0, first);
                const hireDate = new Date(year, month, day);

                if (hireDate > now) {
                  return;
                }

                const diff = this.diffDate(hireDate, now);
                if (diff) {
                  const diffText = this.getDiffDateText(diff);
                  this.hireDate = property;
                  this.hireDate.value = diffText;
                } else {
                  console.error(`error on calc hireDate diff`);
                }
              } catch (e) {
                console.error(`error on calc hireDate`, e);
              }
            }
          });

          this.profileProperties = this.profileProperties.filter(s => !s.hidden);

          this.originProperties = JSON.parse(JSON.stringify(this.profileProperties));
        } else {
          this.profileProperties = [];
          this.originProperties = [];
        }
      });
  }

  private diffDate(startDate, endDate) {
    const b = moment(startDate),
      a = moment(endDate),
      intervals = ['years', 'months', 'days'],
      out = {};

    for (let i = 0; i < intervals.length; i++) {
      const diff = a.diff(b, intervals[i] as moment.unitOfTime.Diff);
      b.add(diff, intervals[i] as moment.unitOfTime.Diff);
      out[intervals[i]] = diff;
    }
    return out;
  }

  private getDiffDateText(obj) {
    let str = '';
    const value = <any>obj;
    if (value.days) {
      str = ` ${value.days} ${Helper.getNoun(value.days, 'день', 'дня', 'дней')}`;
    }
    if (value.months) {
      str = ` ${value.months} ${Helper.getNoun(value.months, 'месяц', 'месяца', 'месяцев')}` + str;
    }
    if (value.years) {
      str = `${value.years} ${Helper.getNoun(value.years, 'год', 'года', 'лет')}` + str;
    }
    return str;
  }

  toggleSubordinatesShow() {
    this.showMoreSubordinates = !this.showMoreSubordinates;
  }

  private getSubordinates() {
    this.usersService.getSubordinates(this.user.accountName, 0, 50)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        if (res) {
          this.subordinates = res.items;
          this.subordinatesMoreCount = res.count > this.subordinates.length ? res.count - this.subordinates.length : 0;

        } else {
          this.subordinates = [];
          this.subordinatesMoreCount = 0;
        }
      });
  }
}
