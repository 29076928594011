import { Component, OnInit, Input, OnDestroy, Inject } from '@angular/core';
import { AddUsersBaseComponent } from '@app/shared/components/add-users-base/add-users-base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '@app/profile/services/users.service';
import { FilesService } from '@app/files/services/files.service';
import { Subject, BehaviorSubject } from 'rxjs';
import { ListItemShareBody, ShareType, PeoplePickerInput } from '@app/files/model/list-item-share-body.model';
import { Helper } from '@app/core/helpers/helper';
import { FilesListItem } from '@app/files/model/files-list-item.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertsService } from '@app/shared/services/alerts.service';
import { HelperService } from '@app/core/services/helper.service';
import { takeUntil, take, finalize } from 'rxjs/operators';

/**
 * Всплывающее окно "Поделиться"
 */
@Component({
  selector: 'app-file-share',
  templateUrl: './file-share.component.html',
  styleUrls: ['./file-share.component.scss']
})
export class FileShareComponent extends AddUsersBaseComponent implements OnInit, OnDestroy {

  @Input() items: Array<FilesListItem>;
  @Input() public placeholder = 'Введите имя или почту, чтобы поделиться';
  @Input() url: Subject<string> = new BehaviorSubject<string>(null);

  shareType = 'read';
  shareTypeRead = 'На чтение';
  shareTypeWrite = 'На редактирование';

  additionalLink: string;

  errors: Array<string>;

  constructor(
    public dialogRef: MatDialogRef<FileShareComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public route: ActivatedRoute,
    public router: Router,
    public usersService: UsersService,
    public filesService: FilesService,
    public alertsService: AlertsService,
    public helper: HelperService
  ) { super(route, router, usersService, helper); }

  ngOnInit() {
    if (this.data.url) {
      this.url = this.data.url;
    }
    if (this.data.placeholder) {
      this.placeholder = this.data.placeholder;
    }
    if (this.data.items) {
      this.items = this.data.items;
    }
    super.ngOnInit();

    this.getAdditionalLink();
  }

  /**
   * Отменить
   */
  cancel() {
    super.cancel();
  }

  /**
   * Поделиться
   */
  share() {
    this.url.pipe(take(1), takeUntil(this.destroyed$)).subscribe(resUrl => {
      if (resUrl) {
        if (resUrl.startsWith('http')) {
          this.shareFiles(resUrl, null);
        } else {
          this.filesService.webApiGetFilePrefixUrl(resUrl)
            .pipe(take(1), takeUntil(this.destroyed$))
            .subscribe(prefix => {
              this.shareFiles(resUrl, prefix);
            });
        }
      } else {
        console.log(`resUrl is null`);
      }
    });
  }

  private shareFiles(resUrl: string, prefix: string) {
    // для блокировки кнопок
    this.loading = true;

    const recipients: Array<PeoplePickerInput> = [];

    this.usersToAdd.forEach(user => {
      recipients.push(
        {
          Key: 'i:0#.w|' + user.accountName, // опасная хрень TODO: исправить
          EntityType: 'User',
          IsResolved: true,
          ProviderDisplayName: 'Active Directory',
          ProviderName: 'AD',
          EntityData: {
            Email: user.email,
            PrincipalType: 'User'
          }
        });
    });

    this.items.forEach(item => {
      let url = '';
      let docIndex = decodeURIComponent(item.url).indexOf('/Documents/');
      if (docIndex === -1) {
        docIndex = decodeURIComponent(item.url).indexOf('/Shared documents/');
      }

      if (docIndex !== -1) {
        url = item.url.substr(docIndex);
      } else {
        url = item.url;
      }

      const body: ListItemShareBody = {
        url: Helper.concatUrls(prefix ? prefix : resUrl, url),
        peoplePickerInput: JSON.stringify(recipients),
        roleValue: `role:${(this.shareType === 'read' ? ShareType.Read : ShareType.Write)}`,
        sendEmail: false,
        emailBody: '',
        includeAnonymousLinkInEmail: false,
        propagateAcl: false
      };

      console.log(`try to share file url=${body.url}`);

      const errorText = 'Произошла ошибка при предоставлении совместного доступа';
      this.errors = [];
      this.filesService.webApiShareItems(resUrl, body)
        .pipe(
          take(1),
          finalize(() => this.loading = false),
          takeUntil(this.destroyed$)).subscribe(res => {
            if (res && !res.ErrorMessage) {
              this.dialogRef.close();
              this.alertsService.riseSuccess('Доступ успешно предоставлен');
            } else {
              this.alertsService.riseError(errorText);
              this.errors.push(errorText);
              console.log(res.ErrorMessage);
            }
          }, error => {
            this.alertsService.riseError(errorText);
            this.errors.push(errorText);
            console.log(error);
          });
    });
  }

  /**
   * Копировать ссылку в буфер обмена
   */
  copyToClipboard() {
    let elem = null;
    if (this.items[0].link) {
      elem = <any>document.getElementById(this.items[0].link);
    } else {
      elem = <any>document.getElementById('documentLibraryLink');
    }

    if (!elem.href) {
      this.alertsService.riseError('Произошла ошибка при попытке скопировать ссылку');
      return;
    }
    this.filesService.copyToClipboard(elem.href);
  }

  setShareType(value: string) {
    this.shareType = value;
  }

  /**
   * Получить тип доступа
   *
   * @param {string} value значение типа доступа
   */
  getShareType(value: string) {
    switch (value) {
      case 'read':
        return this.shareTypeRead;
      case 'write':
        return this.shareTypeWrite;
    }
  }

  /**
   * Ссылка дополнительных настроек
   */
  getAdditionalLink(): void {
    this.url.pipe(takeUntil(this.destroyed$)).subscribe(resUrl => {
      const listId = encodeURIComponent(Helper.guidToLower(this.data.listId));
      if (!this.items[0].id) {
        // rights to list
        this.additionalLink = `/_layouts/15/user.aspx?List=${listId}`;
      } else {
        // rights to item
        this.additionalLink = `/_layouts/15/user.aspx?obj=${listId},${this.items[0].id},LISTITEM`;
      }
      if (resUrl) {
        this.additionalLink = Helper.concatUrls(resUrl, this.additionalLink);
      }
    });
  }
}
