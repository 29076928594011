import { Component, OnInit, OnDestroy } from '@angular/core';
import { IdeaNewComponent } from '../idea-new/idea-new.component';
import { IdeasService } from '@app/ideas/services/ideas.service';
import { AlertsService } from '@app/shared/services/alerts.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { HelperService } from '@app/core/services/helper.service';
import { IUpdateIdea, IIdea } from '@app/ideas/model/idea';
import { takeUntil, switchMap, take } from 'rxjs/operators';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import { GroupsService } from '@app/groups/services/groups.service';
import { AttachmentViewModel } from '@app/attachments/file-attachments/model/attachment-view-model.model';

/**
 * Страница редактирования идеи
 *
 * @export
 * @class IdeaEditComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-idea-edit',
  templateUrl: './idea-edit.component.html',
  styleUrls: ['./idea-edit.component.scss']
})
export class IdeaEditComponent extends IdeaNewComponent implements OnInit, OnDestroy {

  /**
   * Идея
   *
   * @type {IUpdateIdea}
   * @memberof IdeaEditComponent
   */
  idea: IUpdateIdea = {
    id: null,
    title: '',
    description: '',
    ownerId: null,
    docs: []
  };

  /**
   * Оригинальная идея, не изменяется при редактировании формы
   *
   * @type {IIdea}
   * @memberof IdeaEditComponent
   */
  originIdea: IIdea;

  originAttachments: AttachmentViewModel[] = [];

  constructor(
    public groupsService: GroupsService,
    public ideasService: IdeasService,
    protected alertsService: AlertsService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected breadcrumbsService: BreadcrumbsService,
    protected dialog: MatDialog,
    public helper: HelperService) {
    super(groupsService, ideasService, alertsService, route, router, breadcrumbsService, dialog, helper);
  }

  ngOnInit() {
    // отслеживаем изменения маршрута для получения элемента идеи
    this.route.params
      .pipe(takeUntil(this.destroyed$), switchMap((params: Params) => {
        this.idea = {
          id: null,
          title: '',
          description: '',
          ownerId: null,
          docs: []
        };
        const ideaId = +params['ideaId'];
        return this.ideasService.getById(ideaId);
      }))
      .subscribe(idea => {

        if (!idea) {
          this.router.navigate(['/404'], { skipLocationChange: true });
        }

        // обновляем хлебные крошки
        const ideaRoute = `/group/${this.idea.ownerId}/ideas`;

        this.breadcrumbsService.breadcrumbs.next([
          { title: 'Идеи', routerUrl: ideaRoute },
          { title: 'Идея', routerUrl: ideaRoute + `/${this.idea.id}` }
        ]);

        this.originIdea = idea;

        this.idea = {
          id: idea.id,
          title: idea.title,
          description: idea.description,
          ownerId: idea.ownerId,
          docs: idea.docs ? idea.docs : []
        };

        // отображаем аттачи
        if (this.idea.docs) {
          this.attachments = this.idea.docs.map(s => {
            return { attachment: s, owner: null };
          });

          this.originAttachments = JSON.parse(JSON.stringify(this.attachments));
        }

        this.error = '';
        this.loaded = true;
      }, error => {
        this.error = error;
        this.loaded = true;
      });
  }

  /**
   * Отправить данные формы на сервер
   *
   * @memberof SurveyNewComponent
   */
  submit() {
    this.sending = true;
    this.isAlreadyExists = false;
    this.checkAttachments();

    this.ideasService.update(this.idea).pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.sending = false;
      this.alertsService.riseSuccess(`Идея '${this.idea.title}' была успешно сохранена`);
      this.router.navigate([`..`], { relativeTo: this.route });
      // отправляем событие о изменении в сервис
      this.ideasService.ideaChanged$.next();
    }, error => {
      this.sending = false;
      this.alertsService.riseError(`Произошла ошибка при создании идеи '${this.idea.title}'`);
      if (error && error.error && error.error.ErrorCode === 17000) {
        this.isAlreadyExists = true;
      }
    });
  }

  /**
   * Отмена редактирования, уходим с формы
   *
   * @memberof IdeaEditComponent
   */
  cancel() {
    if (this.isChanged()) {
      this.dialog.open(ModalConfirmationComponent, {
        data: {
          text: `Вы действительно хотите уйти с формы?`,
          onOk: () => { this.router.navigate([`../..`], { relativeTo: this.route }); }
        }
      });
    } else {
      this.router.navigate([`../..`], { relativeTo: this.route });
    }
  }

  isValid(): boolean {
    return !this.sending
      && this.idea
      && !!this.idea.title
      && !!this.idea.description
      && this.isChanged();
  }

  private isChanged() {
    return this.idea && this.originIdea
      && (this.idea.title !== this.originIdea.title
        || this.idea.description !== this.originIdea.description
        || this.idea.docs !== this.originIdea.docs
        || this.attachments !== this.originAttachments);
  }
}
