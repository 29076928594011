import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '@app/core/services/helper.service';
import { Observable } from 'rxjs';
import { User } from '@app/profile/model/user.model';

@Injectable({
  providedIn: 'root'
})
export class CalendarApiService extends BaseService {
  protected serviceUrl = `${environment.siteUrl}/api/user`;

  constructor(
    protected http: HttpClient,
    public helper: HelperService) { super(http, helper); }

  GetBirthdays(start: string, end: string, filter: number, department: string): Observable<User[]> {
    const url = `birthdays?start=${start}&end=${end}&filter=${filter}&department=${department ? department : ''}`;
    return super.get(url);
  }

  GetMainBirthdays(start: string, offset: number = 0, limit: number = 10): Observable<User[]> {
    return super.get(`mainBirthdays?start=${start}&offset=${offset}&limit=${limit}`);
  }

  GetDepartments(searchText: string): Observable<string> {
    return super.get(`departments?searchText=${searchText}`);
  }
}
