<!-- TODO: вынести кнопку закрытия на уровень попапа -->
<div class="card _close-button">
  <button mat-button mat-dialog-close type="button" class="btn icon _close-blue"></button>
</div>
<div class="gallery-view">
  <div class="gallery-view__image">
    <ngx-slick-carousel class="carousel" style="width: 100%; max-width: 100vw;" #slickModal="slick-carousel" [config]="slideConfig"
      (afterChange)="onAfterChange($event)">
      <div ngxSlickItem *ngFor="let mediaFileItem of mediaFiles" class="slide">
        <div class="carousel__slide">
          <!-- show image -->
          <ng-container *ngIf="mediaFileItem && mediaFileItem.type===mediaFileType.image">
            <img [src]="galleryService.getMediaFileUrl(mediaFileItem)" class="gallery-view__img" [alt]="mediaFileItem.title">
          </ng-container>
          <!-- show video -->
          <ng-container *ngIf="mediaFileItem && mediaFileItem.type===mediaFileType.video">
            <app-video-player
              [options]="{
                autoplay: false,
                controls: true,
                sources: [{ src: galleryService.getMediaFileUrl(mediaFileItem),
                  type: 'video/mp4'
                }]
              }"
              [current]="mediaFile === mediaFileItem"
            ></app-video-player>
          </ng-container>
        </div>
      </div>
    </ngx-slick-carousel>

    <div class="gallery-view__top" *ngIf="mediaFile?.title">
      <div class="gallery-view__title">{{getMediaFileTitle(mediaFile)}}</div>
    </div>
    <div class="gallery-view__bottom" *ngIf="mediaFile?.album?.title">
      <div class="gallery-view__subtitle _light">Альбом&nbsp;</div>
      <a class="gallery-view__subtitle" [routerLink]="getAlbumLink(mediaFile)"
        (click)="navigateAlbum(mediaFile)">{{getAlbumTitle(mediaFile)}}</a>
    </div>
  </div>
  <div class="gallery-view__comments" *ngIf="false">
    <div class="gallery-view__main">
      <div class="card">
        <button mat-button mat-dialog-close type="button" class="btn icon _close-black pull-right hidden-phone"></button>
        <div class="post">
          <div class="post__author">
            <div class="link-profile">
              <app-user-avatar [user]="mediaFile.author" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'">
              </app-user-avatar>
              <span class="link-profile__text">
                <a routerLink="/profile/{{mediaFile?.author?.id}}" class="link"
                  (click)="usersService.navigateProfile(mediaFile.author?.id)"
                  [appUserTooltip]="mediaFile.author?.id">{{mediaFile.author?.fullName}}</a>
              </span>
              <span class="link-profile__subtext">{{mediaFile.created | dateSpLike:true }}</span>
            </div>
          </div>
          <div class="post__text">{{mediaFile.description}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
