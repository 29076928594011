<!-- Header -->
<div class="user-header" *ngIf="loaded && user">
  <span class="user-header__back hidden-desktop">
    <app-back-link></app-back-link>
  </span>
  <a href="#" class="link-profile" routerLink="/profile/{{user?.id}}" (click)="usersService.navigateProfile(user?.id)">
    <app-user-avatar [user]="user" [linkClass]="'avatar _30'" [imageClass]="'avatar__image'" [asSpan]="true"></app-user-avatar>
    <span class="link-profile__text" [appUserTooltip]="user?.id">{{user?.fullName}}</span>
  </a>
  <app-breadcrumbs></app-breadcrumbs>
</div>
<!-- Header END-->
