import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MenuPageListComponent } from '@app/admin/components/pages/menu-page-list/menu-page-list.component';
import { IntryAutoLoginGuard } from '@app/auth/shared/guards/intry-auto-login.guard';
import { EditAwardComponent } from '@app/awards/components/forms/edit-award/edit-award.component';
import { NewAwardComponent } from '@app/awards/components/forms/new-award/new-award.component';
import { UserAwardsListComponent } from '@app/awards/components/user-awards-list/user-awards-list.component';
import { UserAwardsMainComponent } from '@app/awards/components/user-awards-main/user-awards-main.component';
import { CalendarComponent } from '@app/calendar/components/calendar/calendar.component';
import { NewEmployeesListComponent } from '@app/employees/components/new-employees-list/new-employees-list.component';
import { BusinessFeedComponent } from '@app/feed/components/business-feed/business-feed.component';
import { AlbumNewComponent } from '@app/gallery/components/forms/album-new/album-new.component';
import { AlbumViewComponent } from '@app/gallery/components/forms/album-view/album-view.component';
import { GalleryViewComponent } from '@app/gallery/components/forms/gallery-view/gallery-view.component';
import { GalleryComponent } from '@app/gallery/components/gallery-main/gallery.component';
import { GiftEditComponent } from '@app/gifts/components/forms/gift-edit/gift-edit.component';
import { GiftNewComponent } from '@app/gifts/components/forms/gift-new/gift-new.component';
import { GiftViewComponent } from '@app/gifts/components/forms/gift-view/gift-view.component';
import { GiftListComponent } from '@app/gifts/components/gift-list/gift-list.component';
import { GroupsListComponent } from '@app/groups/components/groups-list/groups-list.component';
import { PageEditComponent } from '@app/pages/components/forms/page-edit/page-edit.component';
import { PageNewComponent } from '@app/pages/components/forms/page-new/page-new.component';
import { PageViewComponent } from '@app/pages/components/forms/page-view/page-view.component';
import { SearchResultsComponent } from '@app/search/components/search-results/search-results.component';
import { ContainerComponent } from '@app/shared/components/container/container.component';
import { UserRedirectComponent } from '@app/shared/components/user-redirect-component/user-redirect-component.component';
import { ColleaguesListComponent } from '@app/subscribe/components/colleagues-list/colleagues-list.component';
import { SurveyEditComponent } from '@app/surveys/components/forms/survey-edit/survey-edit.component';
import { SurveyNewComponent } from '@app/surveys/components/forms/survey-new/survey-new.component';
import { SurveyViewComponent } from '@app/surveys/components/forms/survey-view/survey-view.component';
import { SurveyResultsComponent } from '@app/surveys/components/survey-results/survey-results.component';
import { ThankViewComponent } from '@app/thanks/components/thank-view/thank-view.component';
import { ThanksListComponent } from '@app/thanks/components/thanks-list/thanks-list.component';
import { UserRelationsComponent } from '@app/user-relations/components/user-relations/user-relations.component';
import { VacancyEditComponent } from '@app/vacancy/components/forms/vacancy-edit/vacancy-edit.component';
import { VacancyNewComponent } from '@app/vacancy/components/forms/vacancy-new/vacancy-new.component';
import { VacancyViewComponent } from '@app/vacancy/components/forms/vacancy-view/vacancy-view.component';
import { FruitfacesComponent } from '@app/fruitfaces/components/fruitfaces.component';
import { ProfileFeedViewComponent } from './components/profile/profile-feed-view/profile-feed-view.component';
import { ProfilePostShareComponent } from './components/profile/profile-post-share/profile-post-share.component';
import { ProfilePostViewComponent } from './components/profile/profile-post-view/profile-post-view.component';
import { ProfileServiceComponent } from './components/profile/profile-service/profile-service.component';
import { ProfileViewComponent } from './components/profile/profile-view/profile-view.component';
import { UserFilesListComponent } from './components/profile/user-files-list/user-files-list.component';
import { UserSubordinatesListComponent } from './components/profile/user-subordinates-list/user-subordinates-list.component';
import { UserSurveyListComponent } from './components/surveys/user-survey-list/user-survey-list.component';
import { UserVacancyListComponent } from './components/vacancy/user-vacancy-list/user-vacancy-list.component';
import { ThanksAllListComponent } from '@app/thanks/components/thanks-all-list/thanks-all-list.component';
import { IntryUserFilesGuard } from '@app/auth/shared/guards/intry-user-files.guard';

const routes: Routes = [
    {
        path: 'news', component: ProfileViewComponent,
        canActivate: [IntryAutoLoginGuard],
        children: [
            { path: '', component: BusinessFeedComponent }
        ]
    },
    {
        path: 'employees', component: ProfileViewComponent,
        canActivate: [IntryAutoLoginGuard],
        children: [
            { path: '', component: NewEmployeesListComponent }
        ]
    },
    {
        path: 'profile', component: ProfileViewComponent,
        canActivate: [IntryAutoLoginGuard],
        children: [
            { path: '', component: ProfileFeedViewComponent, data: { title: 'Профиль пользователя' } },
            {
                path: 'gifts', component: ProfileServiceComponent, data: { title: 'Магазин подарков' },
                children: [
                    { path: '', component: GiftListComponent, data: { title: 'Магазин подарков' } },
                    { path: 'new', component: GiftNewComponent, data: { title: 'Магазин подарков - Создание подарка' } },
                    { path: ':giftId', component: GiftViewComponent, data: { title: 'Магазин подарков - Подарок' } },
                    { path: ':giftId/edit', component: GiftEditComponent, data: { title: 'Магазин подарков - Редактирование подарка' } }
                ]
            }
        ]
    },
    {
        path: 'calendar', component: CalendarComponent, data: { title: 'Календарь' },
        canActivate: [IntryAutoLoginGuard],
    },
    {
        path: 'fruitfaces', component: FruitfacesComponent, data: { title: 'Фрутомоськи' },
        canActivate: [IntryAutoLoginGuard],
    },
    {
        path: 'santa', component: UserRelationsComponent, data: { title: 'Тайный санта' },
        canActivate: [IntryAutoLoginGuard],
    },
    {
        path: 'santa', component: UserRelationsComponent, data: { title: 'Тайный санта' },
        canActivate: [IntryAutoLoginGuard],
    },
    {
        path: 'thanks', component: ThanksAllListComponent, data: { title: 'Спасибо от сотрудников' },
        canActivate: [IntryAutoLoginGuard],
    },
    {
        path: 'pages', component: ContainerComponent, data: { title: 'Страницы' },
        canActivate: [IntryAutoLoginGuard],
        children: [
            { path: '', component: MenuPageListComponent, data: { title: 'Страницы' } },
            { path: 'new', component: PageNewComponent, data: { title: 'Страницы - Создание страницы' } },
            { path: ':pageId', component: PageViewComponent, data: { title: 'Страницы - Страница' } },
            { path: ':pageId/edit', component: PageEditComponent, data: { title: 'Страницы - Редактирование страницы' } }
        ]
    },
    {
        path: 'surveys', component: UserRedirectComponent, data: { subUrl: 'surveys' },
        canActivate: [IntryAutoLoginGuard],
    },
    {
        path: 'profile/:id', component: ProfileViewComponent,
        canActivate: [IntryAutoLoginGuard],
        children: [
            { path: '', component: ProfileFeedViewComponent, data: { title: 'Профиль пользователя' } },
            { path: 'post/:postId', component: ProfilePostViewComponent, data: { title: 'Профиль пользователя - Публикация' } },
            { path: 'post/:postId/share', component: ProfilePostShareComponent, data: { title: 'Профиль пользователя - Публикация - Поделиться' } },
            { path: 'news', component: BusinessFeedComponent },
            { path: 'colleagues', component: ColleaguesListComponent, data: { title: 'Профиль пользователя - Коллеги' } },
            { path: 'groups', component: GroupsListComponent, data: { title: 'Профиль пользователя - Группы' } },
            {
                path: 'files', component: UserFilesListComponent,
                canActivate: [IntryUserFilesGuard],
                data: { title: 'Профиль пользователя - Файлы' }
            },
            { path: 'search', component: SearchResultsComponent, data: { title: 'Поиск' } },
            { path: 'subordinates', component: UserSubordinatesListComponent, data: { title: 'Подчинённые' } },
            {
                path: 'gallery', component: GalleryComponent, children: [
                    { path: '', component: GalleryViewComponent, data: { title: 'Профиль пользователя - Галерея' } },
                    { path: 'new', component: AlbumNewComponent, data: { title: 'Профиль пользователя - Галерея - Создание альбома' } },
                    { path: ':id', component: AlbumViewComponent, data: { title: 'Профиль пользователя - Галерея - Альбом' } },
                ]
            },
            {
                path: 'surveys', component: ProfileServiceComponent, children: [
                    { path: '', component: UserSurveyListComponent, data: { title: 'Профиль пользователя - Опросы' } },
                    { path: 'new', component: SurveyNewComponent, data: { title: 'Профиль пользователя - Опросы - Создание опроса' } },
                    { path: ':surveyId', component: SurveyViewComponent, data: { title: 'Профиль пользователя - Опросы - Опрос' } },
                    {
                        path: ':surveyId/results', component: SurveyResultsComponent,
                        data: { title: 'Профиль пользователя - Опросы - Результаты опроса' }
                    },
                    {
                        path: ':surveyId/edit', component: SurveyEditComponent,
                        data: { title: 'Профиль пользователя - Опросы - Редактирование опроса' }
                    },
                ]
            },
            {
                path: 'awards', component: UserAwardsMainComponent,
                children: [
                    { path: '', component: UserAwardsListComponent, data: { title: 'Профиль пользователя - Награды' } },
                    { path: 'new', component: NewAwardComponent, data: { title: 'Профиль пользователя - Награды - Добавление награды' } },
                    {
                        path: ':awardId/edit', component: EditAwardComponent,
                        data: { title: 'Профиль пользователя - Награды - Редактирование награды' }
                    },
                ]
            },
            {
                path: 'vacancy', component: ProfileServiceComponent, children: [
                    { path: '', component: UserVacancyListComponent, data: { title: 'Профиль пользователя - Вакансии' } },
                    { path: 'new', component: VacancyNewComponent, data: { title: 'Профиль пользователя - Вакансии - Создание вакансии' } },
                    {
                        path: ':vacancyId', component: VacancyViewComponent,
                        data: { title: 'Профиль пользователя - Вакансии - Вакансия' }
                    },
                    {
                        path: ':vacancyId/edit', component: VacancyEditComponent,
                        data: { title: 'Профиль пользователя - Вакансии - Редактирование вакансии' }
                    },
                ]
            },
            {
                path: 'thanks', component: ProfileServiceComponent, children: [
                    { path: '', component: ThanksListComponent, data: { title: 'Профиль пользователя - Спасибо' } },
                    {
                        path: ':thankId', component: ThankViewComponent,
                        data: { title: 'Профиль пользователя - Спасибо - Спасибо от сотрудника' }
                    },
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class ProfileRoutingModule { }
