<div class="cos">
  <app-group-small-header [group]="group"></app-group-small-header>
  <div class="cos__header" *ngIf="loaded">
    <app-tabs [tabs]="tabs" [hideCounters]="true"></app-tabs>
  </div>
  <!-- header preloader -->
  <app-tab-preloader *ngIf="!loaded"></app-tab-preloader>
  <!--  header preloader END -->
  <!--Разметка пользователей-->
  <div class="cos__body" *ngIf="isVisible('users')">
    <app-user-simple-card [link]="link" *ngFor="let link of currentTab?.items"></app-user-simple-card>
  </div>
  <!--Разметка пользователей END-->
  <!--Разметка файлов-->
  <div class="cos__body" *ngIf="isVisible('files')">
    <app-files-search-list [items]="currentTab.items" [allowSort]="true" [sort]="sort" [sortBy]="sortBy"
      [sortTitle]="sortTitle" [sortAsc]="sortAsc" [sortField]="sortField" (sortItems)="sortSearchTab($event)" [highlightText]="text">
    </app-files-search-list>
  </div>
  <!--Разметка файлов END -->
  <!--Разметка ленты-->
  <div class="cos__body" *ngIf="isVisible('feed')">
    <ng-container *ngFor="let post of currentTab?.items">
      <app-post [post]="post" [feedType]="2" [highlightText]="text"></app-post>
    </ng-container>
  </div>
  <!--Разметка ленты END -->
  <!--Разметка галереи-->
  <div class="cos__body" *ngIf="isVisible('gallery')">
    <app-search-album-results [albums]="currentTab?.data?.albums" [albumsLoaded]="currentTab?.data?.albumsLoaded"
      (showMoreEvent)="onAlbumsShowMore($event)" (hideMoreEvent)="onAlbumsHideMore($event)"></app-search-album-results>
    <app-search-media-result [title]="'Найденные медиафайлы'" [mediaFiles]="currentTab?.data?.mediaFiles">
    </app-search-media-result>
  </div>
  <!--Разметка галереи END -->
  <!-- Пустая выдача -->
  <div class="cos__body" *ngIf="showEmptyBlock()">
    <div class="group-empty">
      <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" class="group-empty__img">
      <div class="group-empty__text">Нет результатов.
        <br> Попробуйте уточнить запрос.</div>
    </div>
  </div>
  <!-- пустая выдача END -->
  <!-- Меньше 2 символов -->
  <div class="cos__body" *ngIf="currentTab && currentTab.loaded && (!text || text?.length<2)">
    <div class="group-empty">
      <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" class="group-empty__img">
      <div class="group-empty__text">
        Необходимо ввести не менее двух символов.
      </div>
    </div>
  </div>
  <!-- Меньше 2 символов END -->
  <!-- body preloader -->
  <div class="cos__body" *ngIf="currentTab?.id==1 && !currentTab?.loaded">
    <span class="preloader _square _co" *ngFor="let r of [0,1,2,3,4,5]">
      <span class="preloader__side"></span>
      <span class="preloader__line"></span>
    </span>
  </div>
  <!-- header preloader -->
  <app-tabbed-list-preloader *ngIf="currentTab?.id!=1 && !currentTab?.loaded"></app-tabbed-list-preloader>
  <!--  header preloader END -->
</div>

<!--Разметка разметка контентных страниц -->
<ng-container *ngIf="isVisible('pages')">
  <app-search-other-results [pages]="currentTab.items" [highlightText]="text"></app-search-other-results>
</ng-container>
<!--Разметка разметка контентных страниц END -->
