export abstract class KeyCodes {
    // Basic
    public static BACKSPACE = 8;
    public static TAB = 9;
    public static CLEAR = 12;
    public static ENTER = 13;
    public static ESC = 27;
    public static SPACE = 32;

    // Navigation
    public static PAGEUP = 33;
    public static PAGEDOWN = 34;
    public static END = 35;
    public static HOME = 36;

    // Arrows
    public static LEFT = 37;
    public static UP = 38;
    public static RIGHT = 39;
    public static DOWN = 40;

    // Insert/delete
    public static INSERT = 45;
    public static DELETE = 46;

    // Modifiers
    public static SHIFT = 16;
    public static CTRL = 17;
    public static ALT = 18;
    public static BREAK = 19;
    public static CAPSLOCK = 20;
    public static CMDLEFT = 91;
    public static CMDRIGHT = 93;

    // Special characters.
    public static COMMA = 188;
    public static COLON = 190;
    public static DASH = 189;
    public static EQUALS = 187;
    public static QUOTE = 222;
    public static BACK_TICK = 192;

    /**
     * Combined character keycode.
     */
    public static COMBINED = 229;

    /**
    * Checks key code for numeric input.
    */
    public static isNumeric(keyCode: number) {
        return 48 >= keyCode && keyCode <= 57;
    }

    /**
    * Checks key code for alpha input.
    */
    public static isAlpha(keyCode: number) {
        return 65 >= keyCode && keyCode <= 90;
    }
}
