import {Component, OnDestroy, AfterViewInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {GroupInfo} from '@app/groups/model/group-info.model';
import {UsersService} from '@app/profile/services/users.service';
import {Subject, forkJoin, Observable} from 'rxjs';
import {GroupsService} from '@app/groups/services/groups.service';
import {Tab} from '@app/shared/components/tabs/tab.model';
import {FeedService} from '@app/feed/services/feed.service';
import {FeedType} from '@app/feed/model/feed-type.model';
import {SearchService} from '@app/search/services/search.service';
import {Helper} from '@app/core/helpers/helper';
import {User} from '@app/profile/model/user.model';
import {takeUntil, take, map, finalize} from 'rxjs/operators';
import {HelperService} from '@app/core/services/helper.service';
import {GallerySearchResult} from '@app/search/model/gallery-search-result.model';
import {SearchResultsComponent} from '@app/search/components/search-results/search-results.component';
import {SubscribeService} from '@app/subscribe/services/subscribe.service';
import {GalleryService} from '@app/gallery/services/gallery.service';
import {PagesService} from '@app/pages/services/pages.service';
import {FeedEventViewModel} from '@app/feed/model/feed-event-view-model.model';
import { FilesService } from '@app/files/services/files.service';

/**
 * Компонент результатов поиска внутри группы
 */
@Component({
  selector: 'app-group-search-results',
  templateUrl: './group-search-results.component.html',
  styleUrls: ['./group-search-results.component.css'],
})
export class GroupSearchResultsComponent extends SearchResultsComponent implements AfterViewInit, OnDestroy {
  pageSize: 30;
  checkedText: string;
  // результаты поиска в текущем представлении
  searchItems: any;
  // search text
  text = '';
  lastText = '';
  limit = 20;

  group: GroupInfo;
  currentUser: User;

  // sorting
  sort: boolean;
  sortBy: string;
  showSortTooltip: boolean;
  sortTitle = 'По дате изменения';
  sortAsc = 'asc';
  sortField = 'Filename';

  constructor(
    protected usersService: UsersService,
    protected groupsService: GroupsService,
    protected subscribeService: SubscribeService,
    protected feedService: FeedService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected searchService: SearchService,
    protected galleryService: GalleryService,
    protected pagesService: PagesService,
    protected filesService: FilesService,
    public helper: HelperService,
  ) {
    super(
      usersService,
      groupsService,
      subscribeService,
      feedService,
      router,
      route,
      searchService,
      galleryService,
      pagesService,
      filesService,
      helper,
    );
  }

  ngAfterViewInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      // get current user
      this.currentUser = currentUser;

      // get current group
      this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
        // set current group
        this.group = group;

        if (this.group) {
          // init tabs
          this.tabs = {
            title: 'Поиск',
            routerLink: `/group/${this.group.id}/search`,
            items: [
              {
                id: 1,
                title: 'Участники',
                fragment: 'users',
                name: 'users',
                items: [],
                offset: 0,
                itemsCount: 0,
                method: this.searchUsers.bind(this),
                default: true,
              },
              {
                id: 2,
                title: 'Файлы',
                fragment: 'files',
                name: 'files',
                items: [],
                offset: 0,
                itemsCount: 0,
                method: this.searchFiles.bind(this),
                maxItemHeight: 50,
                limit: 30,
              },
              {
                id: 3,
                title: 'Лента',
                fragment: 'feed',
                name: 'feed',
                items: [],
                offset: 0,
                itemsCount: 0,
                method: this.searchFeed.bind(this),
              },
              {
                id: 4,
                title: 'Галерея',
                name: 'gallery',
                fragment: 'gallery',
                items: [],
                offset: 0,
                itemsCount: 0,
                method: this.searchGallery.bind(this),
              },
              {
                id: 5,
                title: 'Страницы',
                fragment: 'pages',
                name: 'pages',
                items: [],
                offset: 0,
                itemsCount: 0,
                method: this.searchPage.bind(this),
              },
            ],
          };

          this.loaded = true;

          this.route.fragment.pipe(takeUntil(this.destroyed$)).subscribe(val => {
            // get hash
            this.lastText = this.text;

            if (val != null) {
              const keys = val.split('&');
              const hash = {};
              keys.forEach(key => {
                // tslint:disable-next-line:no-shadowed-variable
                const val = key.split('=');
                hash[val[0]] = val[1];
                if (val[0] === 'k') {
                  this.text = decodeURIComponent(val[1]);
                }
              });

              this.tabs.items.forEach(tab => {
                if (tab.fragment && keys.find(k => k === tab.fragment)) {
                  this.currentTab = tab;
                }
              });

              if (!this.currentTab) {
                this.currentTab = this.tabs.items[0];
              }
            } else {
              this.currentTab = this.tabs.items[0];
            }

            this.tabs.items.forEach(tab => {
              tab.additionalFragment = `&k=${this.text}`;
            });

            if (this.text) {
              if (this.text.length > 1) {
                // получить данные по всем табикам
                this.currentTab.items = [];
                this.currentTab.loaded = false;
                this.currentTab.loading = true;
                this.currentTab.method(this.currentTab, this);
              } else {
                this.currentTab.loaded = true;
                this.currentTab.loading = false;
                this.currentTab.items = [];
              }
            } else {
              this.currentTab.items = [];
              this.currentTab.loaded = true;
              this.currentTab.loading = false;
            }
          });
        }
      });
    });
  }

  /**
   * Поиск по пользователям
   *
   * @param {Tab<any>} tab
   * @memberof SearchResultsComponent
   */
  searchUsers(tab: Tab<any>) {
    this.usersService
      .searchUsers(this.text, this.group.id, tab.items.length, this.limit)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        res => {
          if (!tab.items) {
            tab.items = [];
          }

          if (res) {
            res.items.forEach(link => {
              if (!tab.items.filter(g => g.user.id === link.user.id).length) {
                tab.items.push(link);
              }
            });
            tab.itemsCount = res.count;
            if (!res.count) {
              tab.items = [];
            }
          } else {
            tab.itemsCount = 0;
            tab.items = [];
          }

          tab.loaded = true;
          tab.loading = false;
        },
        error => {
          tab.loaded = true;
          tab.loading = false;
        },
      );
  }

  searchFeed(tab: Tab<any>) {
    tab.loading = true;

    let promise: Observable<FeedEventViewModel[]> = null;

    if (this.text.startsWith('#')) {
      promise = this.feedService.searchTags(this.text, this.group.id, FeedType.GroupFeed, tab.items.length, this.limit);
    } else {
      promise = this.feedService.search(this.text, this.group.id, FeedType.GroupFeed, tab.items.length, this.limit);
    }

    promise
      .pipe(
        finalize(() => {
          tab.loaded = true;
          tab.loading = false;
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe(res => {
        this.operateFeedResult(tab, res);
      });
  }

  searchFiles(tab: Tab<any>) {
    tab.loading = true;

    const group = this.groupsService.currentGroup.getValue();

    this.searchService
      .search(
        this.text,
        tab.offset,
        tab.limit,
        this.sortAsc,
        this.sortField,
        Helper.concatUrls((<any>window).signalRServer, group.url),
        this.enableSorting,
      )
      .pipe(
        take(1),
        finalize(() => {
          tab.loaded = true;
          tab.loading = false;
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe(result => {
        if (!tab.items) {
          tab.items = [];
        }

        if (result && result.items) {
          result.items.forEach(item => {
            if (!tab.items.find(s => s.uniqueId === item.uniqueId)) {
              tab.items.push(item);
            }
          });

          tab.offset = tab.items.length;
          tab.itemsCount = tab.items.length + this.limit;
        } else if (this.offset === 0) {
          tab.itemsCount = 0;
          tab.items = [];
        } else {
          tab.itemsCount = tab.items.length;
        }
      });
  }

  /**
   * Поиск по галереи
   *
   * @param {Tab<any>} tab
   * @memberof SearchResultsComponent
   */
  searchGallery(tab: Tab<any>) {
    tab.data = new GallerySearchResult();

    const getAlbums = this.galleryService
      .searchAlbums(
        this.text,
        this.group.id,
        tab.data && tab.data.albums && tab.data.albums.length ? tab.data.albums.length : 0,
        this.albumsLimit,
      )
      .pipe(take(1), takeUntil(this.destroyed$));

    const getMediafiles = this.galleryService
      .searchMediaFiles(
        this.text,
        this.group.id,
        tab.data && tab.data.mediaFiles && tab.data.mediaFiles.length ? tab.data.mediaFiles.length : 0,
        this.mediaFilesLimit,
      )
      .pipe(take(1), takeUntil(this.destroyed$));

    forkJoin(getAlbums, getMediafiles)
      .pipe(
        map(res => {
          return {albums: res[0], mediaFiles: res[1]};
        }),
      )
      .subscribe(
        res => {
          this.operateAlbumsResult(tab, res.albums);
          this.operateMediaFilesResult(tab, res.mediaFiles);

          tab.loaded = true;
          tab.loading = false;
        },
        error => {
          tab.loaded = true;
          tab.loading = false;
        },
      );
  }

  searchPage(tab: Tab<any>) {
    this.pagesService
      .search(this.text, this.group.id, tab.offset, 100)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        res => {
          if (res) {
            res.forEach(page => {
              if (!tab.items.filter(g => g.id === page.id).length) {
                tab.items.push(page);
              }
            });
          }

          tab.itemsCount = tab.items.length + 100;
          tab.loaded = true;
          tab.loading = false;
        },
        error => {
          tab.loaded = true;
          tab.loading = false;
        },
      );
  }
}
