import { Component, OnInit, OnDestroy } from '@angular/core';
import { SurveysService } from '@app/surveys/services/surveys.service';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { HelperService } from '@app/core/services/helper.service';
import { GroupInfo } from '@app/groups/model/group-info.model';
import { GroupsService } from '@app/groups/services/groups.service';
import { takeUntil } from 'rxjs/operators';
import { SurveyListComponent } from '@app/surveys/components/survey-list/survey-list.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ISurvey } from '@app/surveys/model/survey';
import { Tab } from '@app/shared/components/tabs/tab.model';

@Component({
  selector: 'app-group-survey-list',
  templateUrl: './group-survey-list.component.html',
  styleUrls: ['./group-survey-list.component.scss']
})
export class GroupSurveyListComponent extends SurveyListComponent implements OnInit, OnDestroy {

  group: GroupInfo;

  constructor(
    public groupsService: GroupsService,
    protected surveysService: SurveysService,
    protected breadcrumbsService: BreadcrumbsService,
    protected router: Router,
    protected route: ActivatedRoute,
    public helper: HelperService) { super(surveysService, breadcrumbsService, router, route, helper); }

  ngOnInit() {
    this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
      if (group) {
        this.group = group;

        this.tabs = {
          title: 'Опросы',
          routerLink: `/group/${group.id}/surveys`,
          items: [
            {
              id: 1,
              name: 'all',
              title: 'Все опросы',
              fragment: '',
              items: [],
              offset: 0,
              itemsCount: 0,
              method: this.getSurveysForUser.bind(this),
              emptyText: 'Пока нет опросов'
            },
          ]
        };

        this.showAddButton = group.isAdmin;

        if (this.showAddButton && this.tabs.items.find(s => s.name !== 'admin')) {
          this.tabs.items.push({
            id: 2,
            name: 'admin',
            title: 'Администрирование',
            fragment: 'admin',
            items: [],
            offset: 0,
            itemsCount: 0,
            method: this.getOwnerSurveys.bind(this),
            default: true,
            emptyText: 'Пока нет опросов'
          });
        } else {
          this.tabs.items[0].default = true;
        }

        this.breadcrumbsService.breadcrumbs.next([
          { title: 'Опросы', routerUrl: `/group/${this.group.id}/surveys` }
        ]);

        // выполняем базовый метод инициализации, который обрабатывает табики
        super.ngOnInit();
      }
    });
  }

  protected getSurveysForUser(tab: Tab<ISurvey>): void {
    this.operateSurveys(tab, this.surveysService.getGroupSurveysForUser(this.group.id, tab.offset, this.limit));
  }

  protected getOwnerSurveys(tab: Tab<ISurvey>): void {
    this.showAddButton = true;
    this.operateSurveys(tab, this.surveysService.getGroupSurveys(this.group.id, tab.offset, this.limit));
  }

  isShowMenu(): boolean {
    return this.showAddButton;
  }
}
