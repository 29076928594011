import { ChangeDetectorRef, Input, OnDestroy, SimpleChanges } from '@angular/core';
import { OnChanges } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from '@app/core/services/helper.service';
import { FeedService } from '@app/feed/services/feed.service';
import { GroupsService } from '@app/groups/services/groups.service';
import { UsersService } from '@app/profile/services/users.service';
import { AlertsService } from '@app/shared/services/alerts.service';
import { SubscribeService } from '@app/subscribe/services/subscribe.service';
import { PostNewComponent } from '../post-new/post-new.component';

import * as moment from 'moment';
import { FeedEventViewModel } from '@app/feed/model/feed-event-view-model.model';

@Component({
  selector: 'app-post-share',
  templateUrl: './post-share.component.html',
  styleUrls: ['./post-share.component.scss']
})
export class PostShareComponent extends PostNewComponent implements OnInit, OnChanges {

  loaded: boolean;

  hashFrom: string;

  @Input() post: FeedEventViewModel;

  constructor(
    protected usersService: UsersService,
    protected groupsService: GroupsService,
    protected sanitizer: DomSanitizer,
    protected subscribeService: SubscribeService,
    protected feedService: FeedService,
    protected alertsService: AlertsService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected cdr: ChangeDetectorRef,
    public helper: HelperService) {
    super(usersService, groupsService, feedService, alertsService, cdr, helper);
  }

  ngOnInit(): void {
    this.fullView = true;

    this.usersService.currentUser
      .subscribe(currentUser => {
        this.currentUser = currentUser;
        this.user = this.currentUser;
        this.cdr.detectChanges();
      });
    this.publishDate = moment(new Date());

    this.hashFrom = new URLSearchParams(this.route.snapshot.fragment).get('from');
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes['post']) {
      this.loaded = true;
      if (this.post) {
        this.repostId = this.post.event.id;
      }
    }
  }

  cancel() {
    if (this.hashFrom) {
      this.router.navigate([this.hashFrom]);
    } else {
      this.router.navigate(['profile', this.currentUser.id]);
    }
  }
}
