import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageViewComponent } from './components/forms/page-view/page-view.component';
import { PageNewComponent } from './components/forms/page-new/page-new.component';
import { PageEditComponent } from './components/forms/page-edit/page-edit.component';
import { PagesListComponent } from './components/pages-list/pages-list.component';
import { PagesWidgetComponent } from './components/pages-widget/pages-widget.component';
import { PagesService } from './services/pages.service';
import { PagesApiService } from './services/pages-api.service';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@app/core/core.module';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { GroupPageNewComponent } from './components/forms/page-new/group-page-new.component';
import { GroupPageViewComponent } from './components/forms/page-view/group-page-view.component';
import { PageMenuComponent } from './components/menu/page-menu/page-menu.component';
import { PagesMenuComponent } from './components/menu/pages-menu/pages-menu.component';
import { GroupPageEditComponent } from './components/forms/page-edit/group-page-edit.component';
import { PageLinkModalComponent } from './components/dialogs/page-link-modal/page-link-modal.component';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    CoreModule,
    SharedModule,
    RouterModule,
  ],
  declarations: [
    PageViewComponent,
    PageNewComponent,
    PageEditComponent,
    PagesListComponent,
    PagesWidgetComponent,
    GroupPageNewComponent,
    GroupPageViewComponent,
    GroupPageEditComponent,
    PageMenuComponent,
    PagesMenuComponent,
    PageLinkModalComponent
  ],
  exports: [
    PageViewComponent,
    PageNewComponent,
    PageEditComponent,
    PagesListComponent,
    PagesWidgetComponent,
    GroupPageNewComponent,
    GroupPageViewComponent,
    GroupPageEditComponent,
    PageMenuComponent,
    PagesMenuComponent,
    PageLinkModalComponent
  ],
  providers: [
    PagesService,
    PagesApiService
  ],
})
export class PagesModule { }
