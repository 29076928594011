import { Component, AfterViewInit, HostListener, OnDestroy } from '@angular/core';
import { BaseResizableComponent } from '@app/core/components/base-resizable.component';
import { HelperService } from '@app/core/services/helper.service';

declare var $: any;

@Component({
  selector: 'app-scrollable-list',
  templateUrl: './scrollable-list.component.html'
})
export class ScrollableListComponent extends BaseResizableComponent implements AfterViewInit {

  protected offset = 0;
  // max elements by default
  protected limit = 20;
  // max height of each element
  protected maxItemHeight = 100;

  protected containerSelector = '.container .content';
  protected container: any;

  mobHeight: number;
  defaultLimit: number;

  protected lastScroll = 0;
  protected allLoaded: boolean;

  constructor(public helper: HelperService) { super(helper); }

  protected onScroll() {
    console.log('no scroll action implemented');
  }

  protected onResize(event) {
    this.mobHeight = event.target.innerHeight;
    this.setNewLimit();
  }

  ngAfterViewInit(): void {
    this.mobHeight = window.innerHeight;
    this.setNewLimit();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    this.onBaseResize();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    // console.log(`onWindowScroll`);
    if (this.lastScroll < window.pageYOffset) {
      // console.log(`scrolled window.innerHeight=${window.innerHeight},window.pageYOffset=${window.pageYOffset}`);
      if (this.containerSelector) {
        this.container = $(this.containerSelector)[0];
      }

      this.onScroll();
    }
    this.lastScroll = window.pageYOffset;
  }

  protected setNewLimit() {
    const newLimit = Math.round(this.mobHeight / this.maxItemHeight);
    if (newLimit > this.limit) {
      this.limit = newLimit + 1;
    }
    // console.log(`win height: ${this.mobHeight}, calculated limit: ${newLimit}, new limit=${this.limit}`);
  }
}
