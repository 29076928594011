import { IAlbum } from '@app/gallery/model/album-model';
import { IMediaFile } from '@app/gallery/model/media-file';

/**
 * Результат поиска по галерее
 *
 * @export
 * @class GallerySearchResult
 */
export class GallerySearchResult {
    public albums: IAlbum[];
    public albumsCount: number;
    public albumsLoaded: boolean;
    public mediaFiles: IMediaFile[];
    public mediaFilesCount: number;
    public mediaFilesLoaded: boolean;
    public mediaFilesLoading: boolean;

    constructor() {
        this.albums = [];
        this.mediaFiles = [];
    }
}
