import { Component, OnInit, ViewEncapsulation, Input, OnDestroy } from '@angular/core';
import { GroupInfo } from '@app/groups/model/group-info.model';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { GroupsService } from '@app/groups/services/groups.service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';

@Component({
  selector: 'app-group-small-header',
  templateUrl: './group-small-header.component.html',
  encapsulation: ViewEncapsulation.None
})
export class GroupSmallHeaderComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() group: GroupInfo;

  constructor(
    private sanitizer: DomSanitizer,
    public groupsService: GroupsService,
    public helper: HelperService) { super(helper); }

  ngOnInit() {
    this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
      if (group) {
        group.showCard = false;
      }
      this.group = group ? group : null;
    });
  }

  getPictureUrl(group: GroupInfo): SafeStyle {
    if (group) {
      const style = `background-image: url('${this.groupsService.getGroupSmallPicture(group)}')`;
      return this.sanitizer.bypassSecurityTrustStyle(style);
    }
    return '';
  }
}
