import { Component, Output, EventEmitter, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GalleryTreeComponent, GalleryTreeMode, GalleryTreeType } from '@app/gallery/components/lists/gallery-tree/gallery-tree.component';
import { HelperService } from '@app/core/services/helper.service';
import { GroupsService } from '@app/groups/services/groups.service';
import { FeedService } from '@app/feed/services/feed.service';
import { race, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProfileType } from '@app/core/model/profile-type.enum';

/**
 * Меню добавления медиа-вложения
 *
 * @export
 * @class MediaAttachmentsMenuComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-media-attachments-menu',
  templateUrl: './media-attachments-menu.component.html',
  styleUrls: ['./media-attachments-menu.component.scss']
})
export class MediaAttachmentsMenuComponent extends BaseComponent implements OnDestroy {

  @ViewChild('mediaUpload', { static: true }) mediaUpload: ElementRef;

  /**
  * Пробрасывание события при выборе из галереи
  *
  * @type {EventEmitter<any>}
  * @memberof MediaAttachmentsMenuComponent
  */
  @Output() choose: EventEmitter<any> = new EventEmitter();

  /**
   * Пробрасывание события при загрузке с диска
   *
   * @type {EventEmitter<any>}
   * @memberof MediaAttachmentsMenuComponent
   */
  @Output() upload: EventEmitter<any> = new EventEmitter();

  isVisible: boolean;

  galleryTreeDialog: MatDialogRef<GalleryTreeComponent>;

  galleryTreeMode = GalleryTreeMode;

  constructor(
    public feedService: FeedService,
    public groupsService: GroupsService,
    protected dialog: MatDialog,
    public helper: HelperService) { super(helper); }

  hide() {
    this.isVisible = false;
  }

  toggleVisibility() {
    this.isVisible = !this.isVisible;
  }

  showGalleryTree(mode: GalleryTreeMode) {
    this.galleryTreeDialog = this.dialog.open(GalleryTreeComponent, {
      minWidth: window.innerWidth - 100,
      data: {
        onOk: this.onChoose.bind(this),
        cancelText: 'Отмена',
        okText: 'Добавить',
        headerText: 'Выберите фото и видео',
        mode: mode,
        type: GalleryTreeType.selectMediaFiles
      }
    });
  }

  onChoose(event) {
    this.choose.emit(event);
    this.galleryTreeDialog.close();
  }

  onUpload(event) {
    this.upload.emit(event);
    // hide menu
    this.hide();
  }

  /**
   * Отобразить элемент меню выбора в группе
   *
   * @returns {Observable<boolean>}
   * @memberof MediaAttachmentsMenuComponent
   */
  showGroupMenu(): Observable<boolean> {
    return this.groupsService.selectedGroup.pipe(map(res => res ? true : false));
  }

  ngOnDestroy(): void {
    if (this.mediaUpload) {
      this.mediaUpload.nativeElement.value = '';
    }
    super.ngOnDestroy();
  }
}
