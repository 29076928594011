import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  transform(bytesInput = '0', args?: any): string {
    if (!bytesInput || bytesInput === '0') {
      return '0 байт';
    }

    let bytes = 0;

    try {
      bytes = +bytesInput;

      const k = 1024;
      const sizes: string[] = ['Б', 'KБ', 'MБ', 'ГБ', 'TБ', 'ПБ'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];

    } catch (e) {
      console.log(e);
      return '0 байт';
    }
  }
}
