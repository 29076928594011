import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { AlbumListComponent } from '@app/gallery/components/lists/album-list/album-list.component';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { GalleryService } from '@app/gallery/services/gallery.service';
import { UsersService } from '@app/profile/services/users.service';
import { GroupsService } from '@app/groups/services/groups.service';
import { GroupInfo } from '@app/groups/model/group-info.model';
import { takeUntil } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { IAlbumMenuConfig } from '@app/gallery/components/menu/album-menu/album-menu.component';
import { IAlbum } from '@app/gallery/model/album-model';
import { User } from '@app/profile/model/user.model';
import { SignalRService } from '@app/signalr/signalR.service';

@Component({
  selector: 'app-group-album-list',
  templateUrl: './../../../../gallery/components/lists/album-list/album-list.component.html',
  styleUrls: ['./group-album-list.component.scss'],
})
export class GroupAlbumListComponent extends AlbumListComponent implements OnInit, OnDestroy {
  group: GroupInfo;
  currentUser: User;

  private isAdmin: boolean;

  constructor(
    public sanitizer: DomSanitizer,
    public router: Router,
    public galleryService: GalleryService,
    public usersService: UsersService,
    public groupsService: GroupsService,
    public signalRService: SignalRService,
    public ngZone: NgZone,
    public helper: HelperService,
  ) {
    super(sanitizer, router, galleryService, usersService, signalRService, ngZone, helper);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  /**
   * Получить ссылку на альбом группы
   *
   * @param {number} albumId
   * @returns
   * @memberof AlbumListComponent
   */
  getRouterLink(albumId: number) {
    if (this.group) {
      return `/group/${this.group.id}/gallery/${albumId}`;
    }
    return '';
  }

  protected getAlbums() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;
      this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
        if (group) {
          this.group = group;
          this.isAdmin = this.groupsService.isAdmin(this.group, this.currentUser);
          this.operateAlbums(this.galleryService.getAlbums(group.id, this.offset, this.limit));
        }
      });
    });
  }

  protected getMenuConfig(album: IAlbum) {
    const isAuthor = album.author && album.author.id === this.currentUser.id;
    const isAdminOrAuthor = this.isAdmin || isAuthor || this.currentUser.isAdmin;
    const albumMenuConfig: IAlbumMenuConfig = {
      allowAdd: false,
      allowEdit: isAuthor,
      allowDelete: isAdminOrAuthor,
      allowVoting: this.isAdmin,
    };
    return albumMenuConfig;
  }
}
