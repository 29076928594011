<div class="btn btn-dots tooltip" mat-button [matMenuTriggerFor]="menu"></div>
<mat-menu #menu="matMenu">
  <div class="icon-menu" #menuContainer>
    <ng-container *ngIf="selectedItems?.length>1; then multipleItemsMenu; else singleItemsMenu"></ng-container>
    <ng-template #singleItemsMenu>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="isAdmin" (click)="showEditItem()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/edit.svg" alt="" class="icon-menu__img">
        </span>
        <span>Редактировать</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="isAdmin" (click)="showDeleteItem()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/remove.svg" alt="" class="icon-menu__img">
        </span>
        <span>Удалить</span>
      </a>
    </ng-template>
    <ng-template #multipleItemsMenu>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="isAdmin" (click)="showDeleteItem()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/remove.svg" alt="" class="icon-menu__img">
        </span>
        <span>Удалить</span>
      </a>
    </ng-template>
  </div>
</mat-menu>
