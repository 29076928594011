import { Component, OnInit, OnDestroy } from '@angular/core';
import { VacancyListComponent } from '@app/vacancy/components/vacancy-list/vacancy-list.component';
import { UsersService } from '@app/profile/services/users.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HelperService } from '@app/core/services/helper.service';
import { takeUntil, take } from 'rxjs/operators';
import { Tab } from '@app/shared/components/tabs/tab.model';
import { IVacancy, VacancyStatus } from '@app/vacancy/model/vacancy';
import { GroupsService } from '@app/groups/services/groups.service';
import { GroupInfo } from '@app/groups/model/group-info.model';
import { VacancyService } from '@app/vacancy/services/vacancy.service';
import { Observable } from 'rxjs';
import { GroupType } from '@app/groups/model/group-type.model';

/**
 * Вакансии группы
 *
 * @export
 * @class GroupVacancyListComponent
 * @extends {VacancyListComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-group-vacancy-list',
  templateUrl: './group-vacancy-list.component.html',
  styleUrls: ['./group-vacancy-list.component.scss']
})
export class GroupVacancyListComponent extends VacancyListComponent implements OnInit, OnDestroy {

  /**
   * Текущая группа
   *
   * @type {GroupInfo}
   * @memberof GroupVacancyListComponent
   */
  group: GroupInfo;

  constructor(
    protected usersService: UsersService,
    protected groupsService: GroupsService,
    protected vacancyService: VacancyService,
    protected router: Router,
    protected route: ActivatedRoute,
    public helper: HelperService
  ) { super(usersService, router, route, helper); }

  ngOnInit() {
    this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
      this.group = group;
      if (group) {

        this.tabs = {
          title: 'Вакансии',
          routerLink: `/group/${group.id}/vacancy`,
          items: [
            {
              id: 1,
              title: 'Все вакансии',
              fragment: '',
              items: [],
              offset: 0,
              itemsCount: 0,
              method: this.getPublishedVacancies.bind(this),
              default: true,
              emptyText: 'Нет вакансий'
            }
          ]
        };

        this.showAddButton = group.isAdmin;

        if (this.showAddButton && !this.tabs.items.find(s => s.name === 'admin')) {
          this.tabs.items.push({
            id: 2,
            title: 'Администрирование',
            name: 'admin',
            fragment: 'admin',
            items: [],
            offset: 0,
            itemsCount: 0,
            method: this.getGroupVacancies.bind(this),
            default: true,
            emptyText: 'Нет вакансий'
          });
        }

        // выполняем базовый метод инициализации, который обрабатывает табики
        super.ngOnInit();
      }
    });
  }

  isShowFilters(): boolean {
    return this.currentTab && this.currentTab.fragment === 'admin';
  }

  showFilters() {
    this.filtersVisible = !this.filtersVisible;
  }

  isShowNavigateToHhIntegration() {
    return this.isShowFilters() && this.group && this.group.type === GroupType.Business;
  }

  navigateToHhIntegration() {
    this.router.navigate(['../hh'], { relativeTo: this.route });
  }

  /**
   * Получить опубликованные вакансии группы
   *
   * @param {Tab<IVacancy>} tab
   * @memberof GroupVacancyListComponent
   */
  getPublishedVacancies(tab: Tab<IVacancy>) {
    this.operateVacancies(tab, this.vacancyService.getGroupVacancies(this.group.id, VacancyStatus.published, 0, tab.offset, this.limit));
  }

  /**
   * Получить вакансии группы
   *
   * @memberof UserVacancyListComponent
   */
  getGroupVacancies(tab: Tab<IVacancy>) {
    const categoryId = this.currentCategory ? this.currentCategory.id : 0;
    const statusId = this.currentStatus ? this.currentStatus.id : 0;

    this.operateVacancies(tab, this.vacancyService.getGroupVacancies(this.group.id, statusId, categoryId, tab.offset, this.limit));
  }

  private operateVacancies(tab: Tab<IVacancy>, method: Observable<IVacancy[]>) {
    tab.loading = true;
    method.pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        if (res) {
          res.forEach(item => {
            if (!tab.items.find(vacancy => vacancy.id === item.id)) {
              tab.items.push(item);
            }
          });
          tab.offset = tab.items.length;
          tab.limit = this.limit;

          if (res.length) {
            tab.itemsCount = tab.items.length + tab.offset;
          } else {
            tab.itemsCount = tab.items.length;
          }
        } else {
          tab.itemsCount = tab.items.length;
        }
        this.loaded = tab.loaded = true;
        tab.loading = false;
      }, () => {
        this.loaded = tab.loaded = true;
        tab.loading = false;
      });
  }
}
