import { Component, OnInit, OnDestroy } from '@angular/core';
import { BannersService } from '@app/banners/services/banners.service';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { HelperService } from '@app/core/services/helper.service';
import { BannerTypeNewComponent } from '../banner-type-new/banner-type-new.component';
import { AlertsService } from '@app/shared/services/alerts.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FilesService } from '@app/files/services/files.service';
import { IUpdateBannerSlideTypeModel, IBannerSlideType } from '@app/banners/model/banner';
import { takeUntil, switchMap, take } from 'rxjs/operators';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';

@Component({
  selector: 'app-banner-type-edit',
  templateUrl: '../banner-type-new/banner-type-new.component.html',
  styleUrls: ['./banner-type-edit.component.scss']
})
export class BannerTypeEditComponent extends BannerTypeNewComponent implements OnInit, OnDestroy {

  bannerSlideType: IUpdateBannerSlideTypeModel;
  currentBannerSlideType: IBannerSlideType;
  buttonSaveText = 'Сохранить';

  constructor(
    public bannersService: BannersService,
    protected filesService: FilesService,
    protected alertsService: AlertsService,
    protected breadcrumbsService: BreadcrumbsService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected dialog: MatDialog,
    public helper: HelperService
  ) { super(bannersService, filesService, alertsService, breadcrumbsService, router, route, dialog, helper); }

  ngOnInit() {
    // получение всех иконок
    this.getIcons();

    // get item from id parameter
    this.route.params
      .pipe(takeUntil(this.destroyed$), switchMap((params: Params) => {
        const bannerTypeId = +params['bannerTypeId'];
        if (bannerTypeId) {
          return this.bannersService.bannerSlideTypeApiService.getById(bannerTypeId).pipe(take(1));
        } else {
          this.router.navigate(['/404'], { skipLocationChange: true });
        }
      }))
      .subscribe(bannerSlideType => {
        if (bannerSlideType) {
          this.currentBannerSlideType = bannerSlideType;
          this.bannerSlideType = {
            id: bannerSlideType.id,
            title: bannerSlideType.title,
            color: bannerSlideType.color,
            iconUrl: bannerSlideType.iconUrl
          };

          this.breadcrumbsService.breadcrumbs.next([
            { title: 'Настройка баннера', routerUrl: `/admin/banner-settings` },
            { title: 'Типы слайдов баннера', routerUrl: `/admin/banner-types` },
            { title: this.bannerSlideType.title, routerUrl: `/admin/banner-types/${this.bannerSlideType.id}` }
          ]);

          this.error = '';
        } else {
          this.error = 'Тип слайда баннера не найдена или у вас нет прав не его просмотр.';
        }
        this.loaded = true;
      }, error => {
        this.error = error;
        this.loaded = true;
      });
  }

  submit() {
    if (this.isValid()) {
      this.bannersService.bannerSlideTypeApiService.update(this.bannerSlideType)
        .pipe(take(1), takeUntil(this.destroyed$)).subscribe(res => {
          if (res) {
            this.alertsService.riseSuccess('Тип слайда баннера успешно изменен.');
            this.router.navigate(['..'], { relativeTo: this.route });
          } else {
            this.alertsService.riseError('Произошла ошибка при отправке формы. Обратитесь к администратору.');
          }
        }, error => {
          this.alertsService.riseError('Произошла ошибка при отправке формы. Обратитесь к администратору.');
          console.log(error);
        });
    } else {
      this.alertsService.riseError('Произошла ошибка при отправке формы. Не заполнены обязательные поля');
    }
  }

  /**
   * Переопределяем отмену, т.к. редактирование на 1 уровень дальше
   *
   * @memberof EditAwardComponent
   */
  cancel() {
    if (this.currentBannerSlideType && this.bannerSlideType
      && (this.currentBannerSlideType.title !== this.bannerSlideType.title
        || this.currentBannerSlideType.color !== this.bannerSlideType.color
        || this.currentBannerSlideType.iconUrl !== this.bannerSlideType.iconUrl)
    ) {
      this.dialog.open(ModalConfirmationComponent, {
        data: {
          text: `Вы действительно хотите отменить редактирование?`,
          onOk: this.goBackToProfile.bind(this),
          okText: 'Да'
        }
      });
    } else {
      this.goBackToProfile();
    }
  }

  private goBackToProfile() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
