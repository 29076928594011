<div class="btn tooltip" [ngClass]="{'btn-dots':isMobileView(), 'btn-plus':!isMobileView()}" *ngIf="showAddButton" mat-button [matMenuTriggerFor]="menu">+</div>
<mat-menu #menu="matMenu">
    <div class="icon-menu">
        <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" (click)="openCreateFolder()">
            <span class="link-file _folder"></span>
            <span>Папка</span>
        </a>
        <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="filesService.newWOPIDocumentEnabled" (click)="openCreateOfficeFile(documentTemplateType.Word)">
            <span class="link-file _doc"></span>
            <span>Документ Word</span>
        </a>
        <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="filesService.newWOPIDocumentEnabled" (click)="openCreateOfficeFile(documentTemplateType.Excel)">
            <span class="link-file _xls"></span>
            <span>Книга Excel</span>
        </a>
        <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="filesService.newWOPIDocumentEnabled" (click)="openCreateOfficeFile(documentTemplateType.PowerPoint)">
            <span class="link-file _ppt"></span>
            <span>Презентация PowerPoint</span>
        </a>
        <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="filesService.newWOPIDocumentEnabled" (click)="openCreateOfficeFile(documentTemplateType.OneNote)">
            <span class="link-file _onenote"></span>
            <span>Записная книжка OneNote</span>
        </a>
        <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" (click)="uploadFiles()">
            <span class="link-file"></span>
            <span>Загрузить</span>
        </a>
    </div>
</mat-menu>

<input type="file" (change)="fileChangeEvent($event)" multiple #fileUpload style="display: none" (blur)="onBlur($event)" (click)="uploadClicked($event)" />

<div class="modal _loading" *ngIf="fileUploading" style="z-index: 20001">
    <div class="modal__container">
        <mat-spinner [diameter]="80"></mat-spinner>
    </div>
</div>

<a #newFileLinkAnchor target="_blank" style="display: none"></a>