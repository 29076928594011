import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { GroupInfo } from '@app/groups/model/group-info.model';
import { GroupsService } from '@app/groups/services/groups.service';
import { UsersService } from '@app/profile/services/users.service';
import { take, takeUntil } from 'rxjs/operators';
import { ProfileType } from '@app/core/model/profile-type.enum';
import { PostMode } from '../post-new/post-new.component';
import { IIntryProfile } from '@app/shared/model/intry-profile';
import { PostProfileSelectorType } from '@app/feed/model/post-profile-selector-type';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';

/**
 * Компонент выбора профиля для создания поста
 *
 * @export
 * @class PostProfileSelectorComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-post-profile-selector',
  templateUrl: './post-profile-selector.component.html'
})
export class PostProfileSelectorComponent extends BaseComponent implements OnChanges {

  @Input() postMode: PostMode;
  @Input() currentProfile: IIntryProfile;
  @Input() selectorType: PostProfileSelectorType = PostProfileSelectorType.all;
  @Output() profileSelected: EventEmitter<IIntryProfile> = new EventEmitter();

  postProfiles: IIntryProfile[] = [];
  profileType = ProfileType;

  loaded: boolean;
  fullLoaded: boolean;

  show: boolean;
  selectedProfile: IIntryProfile;

  private limit = 20;
  private offset = 0;

  constructor(
    protected groupsService: GroupsService,
    public usersService: UsersService,
    public helper: HelperService) {
    super(helper);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['postMode']) {
      if ((this.postMode === PostMode.news || this.selectorType !== PostProfileSelectorType.all) && !this.loaded) {
        this.getUserGroups();
      }
      // в ленте бизнес-постов выбирать свою ленту нельзя
      if (this.selectorType !== PostProfileSelectorType.onlyBusiness
        && this.selectorType !== PostProfileSelectorType.onlyBusinessWithNewEmployee) {
        this.addCurrentUserProfile();
      }
    }
  }

  trackBy(index: number, item: IIntryProfile) {
    return item != null ? item.profile.id : index;
  }

  onProfileSelected(profile: IIntryProfile) {
    this.selectedProfile = profile;
    if (this.selectedProfile.profileType === ProfileType.Group) {
      this.groupsService.selectedGroup.next(this.selectedProfile.profile as GroupInfo);
    } else {
      this.groupsService.selectedGroup.next(null);
    }
    this.profileSelected.emit(this.selectedProfile);
    this.show = false;
  }

  chooseProfile() {
    this.show = !this.show;
    if (!this.loaded) {
      this.getUserGroups();
    }
  }

  scrollCallback() {
    this.getUserGroups();
  }

  /**
   * Получение групп пользователя
   *
   * @private
   * @memberof PostProfileSelectorComponent
   */
  private getUserGroups(): void {
    if (this.fullLoaded) {
      return;
    }
    this.usersService.currentUser.subscribe(currentUser => {
      this.groupsService.getUserGroupsCanCreatePost(currentUser.id, this.selectorType, this.offset, this.limit)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(res => {
          if (res) {
            res.groups.forEach(group => {
              if (!this.postProfiles.find(p => p.profileType === ProfileType.Group && p.profile && p.profile.id === group.id)) {
                this.postProfiles.push({ profileType: ProfileType.Group, profile: group });
              }
            });
            // сдвигаем выборку
            this.offset = this.offset + res.groups.length;
          } else {
            this.fullLoaded = true;
          }

          // в ленте бизнес-постов выбирать свою ленту нельзя
          if (this.selectorType !== PostProfileSelectorType.onlyBusiness
            && this.selectorType !== PostProfileSelectorType.onlyBusinessWithNewEmployee) {
            this.addCurrentUserProfile();
          } else {
            this.setSelectedProfile();
          }

          this.loaded = true;
        }, error => {
          this.loaded = true;
          console.log(`error=${error}`);
        });
    });
  }

  /**
   * Добавление блока профиля текущего пользователя
   *
   * @private
   * @memberof PostProfileSelectorComponent
   */
  private addCurrentUserProfile() {
    // В свою ленту можно добавить только обычные посты
    if (this.postMode === PostMode.text) {
      if (!this.postProfiles.find(p => p.profileType === ProfileType.User)) {
        this.usersService.currentUser.subscribe(currentUser => {
          this.postProfiles.unshift({ profileType: ProfileType.User, profile: currentUser });
          this.setSelectedProfile();
        });
      }
    } else {
      this.postProfiles = this.postProfiles.filter(p => p.profileType !== ProfileType.User);
      this.setSelectedProfile();
    }
  }

  private setSelectedProfile() {
    if (!this.currentProfile) {
      this.selectedProfile = this.postProfiles[0];
    } else {
      this.selectedProfile = this.currentProfile;
    }
    if (this.selectedProfile && this.selectedProfile.profileType === ProfileType.Group) {
      this.groupsService.selectedGroup.next(this.selectedProfile.profile as GroupInfo);
    } else {
      this.groupsService.selectedGroup.next(null);
    }
    this.profileSelected.emit(this.selectedProfile);
  }
}
