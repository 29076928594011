import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminMainComponent } from './components/admin-main/admin-main.component';
import { CategoriesListComponent } from './components/vacancy/categories-list/categories-list.component';
import { Routes, RouterModule } from '@angular/router';
import { CoreModule } from '@app/core/core.module';
import { SharedModule } from '@app/shared/shared.module';
import { AdminPageComponent } from './components/admin-page/admin-page.component';
import { FormsModule } from '@angular/forms';
import { IdeaStatusListComponent } from './components/ideas/idea-status-list/idea-status-list.component';
import { MenuPageListComponent } from './components/pages/menu-page-list/menu-page-list.component';
import { PagesModule } from '@app/pages/pages.module';
import { PageNewComponent } from '@app/pages/components/forms/page-new/page-new.component';
import { PageViewComponent } from '@app/pages/components/forms/page-view/page-view.component';
import { PageEditComponent } from '@app/pages/components/forms/page-edit/page-edit.component';
import { IsAdminGuard } from './guards/is-admin.guard';
import { BannerSettingsComponent } from '@app/banners/components/banner-settings/banner-settings.component';
import { BannerTypeEditComponent } from '@app/banners/components/banner-types/banner-type-edit/banner-type-edit.component';
import { BannerTypeNewComponent } from '@app/banners/components/banner-types/banner-type-new/banner-type-new.component';
import { BannerTypesListComponent } from '@app/banners/components/banner-types/banner-types-list/banner-types-list.component';
import { SettingsComponent } from './components/settings/settings.component';
import { GamificationTypeListComponent } from './components/gamification/gamification-type-list/gamification-type-list.component';
import { GamificationModule } from '@app/gamification/gamification.module';
import { GiftListComponent } from '@app/gifts/components/gift-list/gift-list.component';
import { GiftsModule } from '@app/gifts/gifts.module';

const routes: Routes = [
    {
        path: 'admin', component: AdminMainComponent, canActivate: [IsAdminGuard],
        children: [
            { path: '', component: AdminPageComponent, data: { title: 'Администрирование' } },
            { path: 'vacancy-categories', component: CategoriesListComponent, data: { title: 'Администрирование - Категории вакансий' } },
            { path: 'idea-statuses', component: IdeaStatusListComponent, data: { title: 'Администрирование - Статусы идей' } },
            { path: 'gamification-types', component: GamificationTypeListComponent, data: { title: 'Администрирование - Геймификация' } },
            { path: 'banner-settings', component: BannerSettingsComponent, data: { title: 'Администрирование - Настройка баннера' } },
            {
                path: 'banner-types',
                component: BannerTypesListComponent,
                data: { title: 'Администрирование - Типы слайдов баннера' }
            },
            {
                path: 'banner-types/new',
                component: BannerTypeNewComponent,
                data: { title: 'Администрирование - Новый тип слайда' }
            },
            {
                path: 'banner-types/:bannerTypeId',
                component: BannerTypeEditComponent,
                data: { title: 'Администрирование - редактирование типа слайда' }
            },
            { path: 'gifts', component: GiftListComponent, data: { title: 'Администрирование - Каталог подарков' } },
            { path: 'pages', component: MenuPageListComponent, data: { title: 'Страницы' } },
            { path: 'pages/new', component: PageNewComponent, data: { title: 'Страницы - Создание страницы' } },
            { path: 'pages/:pageId', component: PageViewComponent, data: { title: 'Страницы - Страница' } },
            { path: 'pages/:pageId/edit', component: PageEditComponent, data: { title: 'Страницы - Редактирование страницы' } },
            { path: 'settings', component: SettingsComponent, data: { title: 'Настройки' } },
        ],
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        CoreModule,
        PagesModule,
        SharedModule,
        GamificationModule,
        GiftsModule,
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    ],
    declarations: [
        AdminMainComponent,
        AdminPageComponent,
        CategoriesListComponent,
        IdeaStatusListComponent,
        MenuPageListComponent,
        SettingsComponent,
        GamificationTypeListComponent,
    ],
})
export class AdminModule { }
