import { Component, OnDestroy, NgZone, AfterViewInit, ViewChild, forwardRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { User } from '@app/profile/model/user.model';
import { UsersService } from '@app/profile/services/users.service';
import { FeedService } from '@app/feed/services/feed.service';
import { FeedEventViewModel } from '@app/feed/model/feed-event-view-model.model';
import { SubscribeService } from '@app/subscribe/services/subscribe.service';
import { LikesService } from '@app/likes/services/likes.service';
import { ScrollableListComponent } from '@app/shared/components/scrollable-list/scrollable-list.component';
import { FeedType } from '@app/feed/model/feed-type.model';
import { takeUntil } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { PostProfileSelectorType } from '@app/feed/model/post-profile-selector-type';
import { ProfileType } from '@app/core/model/profile-type.enum';
import { PostNewComponent } from '../post-new/post-new.component';

/**
 * Базовый компонент ленты. Переопределяется в зависимости от типа ленты (Профиль пользователя, Новостная лента, Профиль группы)
 */
@Component({
  selector: 'app-feed-base',
  templateUrl: './feed-base.component.html'
})
export class FeedBaseComponent extends ScrollableListComponent implements OnDestroy, AfterViewInit {
  currentUser: User;
  user: User;
  posts: Array<FeedEventViewModel> = [];
  colleagues: Array<any>;

  // for scrolling
  limit = 10;
  offset = 0;
  lastOffset = 0;
  loading: boolean;

  // method to get data
  feedMethod: Promise<FeedEventViewModel[]> = null;

  feedType = FeedType;
  ProfileType = ProfileType;
  selectorType: PostProfileSelectorType = PostProfileSelectorType.all;

  @ViewChild(forwardRef(() => PostNewComponent)) private postNew: PostNewComponent;

  // логика для отправки поздравления
  private focusNewPost$: Subject<void> = new Subject();
  private congratulate: boolean;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected usersService: UsersService,
    protected subscribeService: SubscribeService,
    protected feedService: FeedService,
    protected likesService: LikesService,
    protected ngZone: NgZone,
    public helper: HelperService) {
    super(helper);
  }

  getFeed() {
    // текущий пользователь для блока создания поста
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;
      this.getFeedInternal();
    });

    if (this.getFeedType() === FeedType.ProfileFeed) {
      // find what has is now and route navigate with hash
      this.route.fragment
        .pipe(takeUntil(this.destroyed$))
        .subscribe(val => {
          // get hash
          if (val != null) {
            const keys = val.split('&');
            const hash = {};
            keys.forEach(key => {
              // tslint:disable-next-line:no-shadowed-variable
              const val = key.split('=');
              hash[val[0]] = val[1];

              if (val[0] === 'congratulate') {
                this.congratulate = true;
              }
            });
          }
        });

      this.focusNewPost$.pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          const that = this;
          setTimeout(() => {
            that.postNew.focus();
            that.postNew.expand();
          }, 300);
        });
    }
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    if (this.congratulate) {
      this.focusNewPost$.next();
    }
  }

  protected getFeedInternal() {
    throw new Error('Not implemented exception');
  }

  /**
   * Удаление поста из ленты
   *
   * @param {FeedEventViewModel} item
   * @memberof FeedBaseComponent
   */
  onRemove(item: FeedEventViewModel) {
    this.posts = this.posts.filter(post => {
      return post.event.id !== item.event.id;
    });
  }

  /**
   * Действие при добавлении нового поста. Добавляем пост в начало массива.
   *
   * @param {FeedEventViewModel} item
   * @memberof FeedBaseComponent
   */
  onFeedNewPost(item: FeedEventViewModel) {
    if (!this.posts.filter(post => post.event.id === item.event.id).length) {
      this.ngZone.run(() => {
        // если пост закреплённый - добавляем в самый верх
        // иначе вставляем после всех закреплённых
        if (item.event.pin) {
          this.posts.unshift(item);
        } else {
          const firstUnpinned = this.posts.find(s => !s.event.pin);
          const index = this.posts.indexOf(firstUnpinned);
          this.posts.splice(index, 0, item);
        }
      });
    }
  }

  /**
   * Действие при обновлении поста. Заменяем элемент массива.
   *
   * @param {FeedEventViewModel} item
   * @memberof FeedBaseComponent
   */
  onFeedPostEdited(item: FeedEventViewModel) {
    this.ngZone.run(() => {
      const post = this.posts.filter(o => o.event.id === item.event.id)[0];
      if (post) {
        const i = this.posts.indexOf(post);
        if (i >= 0) {
          this.posts[i] = item;
        }
      }
    });
  }

  /**
   * Действие при удалении поста. Удаляем его пост из списка.
   *
   * @param {number} id
   * @memberof FeedBaseComponent
   */
  onFeedPostRemoved(id: number) {
    this.ngZone.run(() => {
      this.posts = this.posts.filter(post => post.event.id !== id);
    });
  }

  /**
   * Обработка результата списка постов с бэка
   */
  protected operateFeedResult(method: Observable<FeedEventViewModel[]>) {
    this.loading = true;
    this.lastOffset = this.offset;
    method
      .pipe(takeUntil(this.destroyed$))
      .subscribe(resFeed => {
        resFeed.forEach(item => {
          if (!this.posts.find(p => p.event.id === item.event.id)) {
            this.posts.push(item);
          }
        });
        this.offset = this.posts.length;
        this.loaded = true;
        this.loading = false;
      }, error => {
        this.loaded = false;
        this.loading = false;
      });
  }

  /**
   * Догрузка постов при скролле
   *
   * @memberof FeedBaseComponent
   */
  onScroll() {
    const number = window.innerHeight + window.pageYOffset + 20;
    let containerHeight = 0;

    if (this.container) {
      containerHeight = this.container.clientHeight;
      // console.log(`container height=${containerHeight}, number=${number}`);
    }

    if (containerHeight && number + 200 > containerHeight
      || !containerHeight && number > this.maxItemHeight * this.posts.length) {
      // console.log('prepare for upload');
      if (!this.loading) {
        // console.log('check for upload');
        if (this.lastOffset < this.offset) {
          // console.log('uploading');
          this.getFeed();
        }
      }
    }
  }

  trackBy(index, item: FeedEventViewModel) {
    if (item && item.event) {
      return item.event.id;
    }
  }

  getFeedType(): FeedType {
    return null;
  }

  /**
   * Отписываемся от подписок
   *
   * @memberof FeedBaseComponent
   */
  ngOnDestroy(): void {
    this.lastOffset = this.offset = 0;
    this.posts = [];
    super.ngOnDestroy();
  }
}
