import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '@app/core/services/helper.service';
import { ISurvey, INewSurvey, IUpdateSurvey, ITakeSurvey, ISurveyOptionDetails, ISurveyResult } from '../model/survey';
import { Observable } from 'rxjs';

/**
 * API - сервис Опросов
 *
 * @export
 * @class SurveysApiService
 * @extends {BaseService}
 */
@Injectable({
  providedIn: 'root'
})
export class SurveysApiService extends BaseService {
  protected serviceUrl = `${environment.siteUrl}/api/survey`;

  constructor(
    protected http: HttpClient,
    public helper: HelperService) { super(http, helper); }

  /**
   * Опросы, созданные пользователем
   *
   * @param {number} offset сдвиг
   * @param {number} limit количество возвращаемых значений
   * @returns {Observable<ISurvey[]>}
   * @memberof SurveysApiService
   */
  getUserSurveys(offset: number, limit: number): Observable<ISurvey[]> {
    return super.get(`userSurveys?offset=${offset}&limit=${limit}`);
  }

  /**
   * Опросы, которые пользователь может пройти
   *
   * @param {number} offset сдвиг
   * @param {number} limit количество возвращаемых значений
   * @returns {Observable<ISurvey[]>}
   * @memberof SurveysApiService
   */
  getSurveysForUser(offset: number, limit: number): Observable<ISurvey[]> {
    return super.get(`surveysForUser?offset=${offset}&limit=${limit}`);
  }

  /**
   * Опросы для виджета
   *
   * @param {number} profileId ИД профиля (пользователь или виджет)
   * @param {number} offset сдвиг
   * @param {number} limit количество возвращаемых значений
   * @returns {Observable<ISurvey[]>}
   * @memberof SurveysApiService
   */
  getProfileSurveys(profileId: number, offset: number, limit: number): Observable<ISurvey[]> {
    return super.get(`profile/${profileId}?offset=${offset}&limit=${limit}`);
  }

  /**
   * Опросы, созданные в группе
   *
   * @param {number} groupId ИД группы
   * @param {number} offset сдвиг
   * @param {number} limit количество возвращаемых значений
   * @returns {Observable<ISurvey[]>}
   * @memberof SurveysApiService
   */
  getGroupSurveys(groupId: number, offset: number, limit: number): Observable<ISurvey[]> {
    return super.get(`group/${groupId}?offset=${offset}&limit=${limit}`);
  }

  /**
   * Опросы в группе, которые пользователь может пройти
   *
   * @param {number} groupId ИД группы
   * @param {number} offset сдвиг
   * @param {number} limit количество возвращаемых значений
   * @returns {Observable<ISurvey[]>}
   * @memberof SurveysApiService
   */
  getGroupSurveysForUser(groupId: number, offset: number, limit: number): Observable<ISurvey[]> {
    return super.get(`group/${groupId}/forUser?offset=${offset}&count=${limit}`);
  }

  /**
   * Получить опрос по идентификатору
   *
   * @param {number} id ИД опроса
   * @returns {Observable<ISurvey>}
   * @memberof SurveysApiService
   */
  getById(id: number, forEdit: boolean = false): Observable<ISurvey> {
    return super.get(`${id}?forEdit=${forEdit}`);
  }

  /**
   * Создать опрос
   *
   * @param {INewSurvey} survey
   * @returns
   * @memberof SurveysApiService
   */
  create(survey: INewSurvey): Observable<ISurvey> {
    return super.post(``, survey);
  }

  /**
   * Обновить опрос
   *
   * @param {IUpdateSurvey} survey
   * @returns
   * @memberof SurveysApiService
   */
  update(id: number, survey: INewSurvey): Observable<boolean> {
    return super.put(`${id}`, survey);
  }

  /**
   * Пройти опрос
   *
   * @param {ITakeSurvey} survey
   * @returns
   * @memberof SurveysApiService
   */
  take(survey: ITakeSurvey): Observable<ITakeSurvey> {
    return super.post(`take`, survey);
  }

  /**
   * Удалить опрос
   *
   * @param {number} id
   * @returns
   * @memberof SurveysApiService
   */
  remove(id: number): Observable<boolean> {
    return super.delete(`${id}`);
  }

  /**
   * Получить результаты опроса
   *
   * @param {number} id ИД опроса
   * @returns
   * @memberof SurveysApiService
   */
  results(id: number, offset: number, limit: number): Observable<ISurveyResult> {
    return super.get(`${id}/results?offset=${offset}&limit=${limit}`);
  }

  calculate(id: number, userId: number): Observable<ISurveyResult> {
    return super.get(`${id}/calculate/${userId}/result`);
  }

  /**
   * Получить развёрнутые данные по текстовому вопросу или варианту "Другое"
   *
   * @param {number} id ИД варианта ответа
   * @param {number} offset сдвиг
   * @param {number} limit количество возвращаемых значений
   * @returns
   * @memberof SurveysApiService
   */
  getOptionDetails(id: number, offset: number, limit: number): Observable<ISurveyOptionDetails> {
    return super.get(`option/${id}/details?offset=${offset}&count=${limit}`);
  }
}
