import { Component, OnInit, Input, OnDestroy, Inject, EventEmitter } from '@angular/core';
import { FilesTreeComponent } from '@app/files/components/files-tree/files-tree.component';
import { FilesService } from '@app/files/services/files.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FilesListItem } from '@app/files/model/files-list-item.model';
import { HelperService } from '@app/core/services/helper.service';
import { GroupsService } from '@app/groups/services/groups.service';
import { Helper } from '@app/core/helpers/helper';
import { UsersService } from '@app/profile/services/users.service';

/**
 * Режим работы дерева вложений.
 * 1 - мои файлы
 * 2 - файлы группы
 *
 * @export
 * @enum {number}
 */
export enum AttachmentsDialogMode {
  myFiles = 1,
  groupFiles = 2
}

/**
 * Дерево выбора вложений
 *
 * @export
 * @class AttachmentsTreeComponent
 * @extends {FilesTreeComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-attachments-tree',
  templateUrl: './attachments-tree.component.html'
})
export class AttachmentsTreeComponent extends FilesTreeComponent implements OnInit, OnDestroy {

  @Input() allowCreateFolder = true;

  widgetMode: AttachmentsDialogMode = AttachmentsDialogMode.myFiles;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public usersService: UsersService,
    public filesService: FilesService,
    public groupsService: GroupsService,
    public helper: HelperService) {

    super(dialogRef, data, filesService, helper);
    // устанавливаем режим отображения
    if (data && data.mode) {
      this.widgetMode = data.mode;
    }
  }

  ngOnInit() {
    this.allowCreateFolder = this.data.allowCreateFolder;

    this.items = [];

    // base init
    this.load();
  }

  /**
   * При нажатии ОК
   *
   * @memberof AttachmentsTreeComponent
   */
  ok() {
    if (this.onOk instanceof EventEmitter) {
      this.onOk.emit(this.getSelectedItems());
    } else {
      (<any>this).onOk(this.getSelectedItems());
    }
  }

  /**
   * Нажатие табика "Мои файлы"
   *
   * @memberof AttachmentsTreeComponent
   */
  myFiles(force: boolean = false): void {
    if (this.widgetMode === AttachmentsDialogMode.myFiles && !force) {
      return;
    }
    this.items = [];
    this.loaded = false;
    this.widgetMode = AttachmentsDialogMode.myFiles;
    this.usersService.currentUser.subscribe(currentUser => {
      super.getItems();
    });
  }

  /**
   * Нажатие табика "Мои группы"
   *
   * @memberof AttachmentsTreeComponent
   */
  groupFiles(force: boolean = false): void {
    if (this.widgetMode === AttachmentsDialogMode.groupFiles && !force) {
      return;
    }
    this.items = [];
    this.loaded = false;
    this.widgetMode = AttachmentsDialogMode.groupFiles;

    let ownerId = this.groupsService.currentGroup.getValue()?.id;

    if (!ownerId) {
      const selectedGroup = this.groupsService.selectedGroup.getValue();
      if (selectedGroup) {
        ownerId = selectedGroup.id;
      }
    }

    if (ownerId) {
      super.getItems();
    } else {
      this.loaded = true;
    }
  }

  /**
   * Получение всех отмеченных файлов
   *
   * @returns {FilesListItem[]}
   * @memberof AttachmentsTreeComponent
   */
  getSelectedItems(): FilesListItem[] {
    return this.items.filter(item => item.checked.getValue());
  }

  /**
   * При выполнении поиска по файлам
   *
   * @param {*} e
   * @returns
   * @memberof AttachmentsTreeComponent
   */
  onSearch(e) {
    // ESC
    if (e.keyCode === 27) {
      this.text = '';
      this.load();
      e.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    // ENTER
    if (e.keyCode === 13) {
      if (!this.text) {
        return false;
      } else if (this.text.length > 2) {
        this.load();
        return false;
      }
    }
  }

  /**
   * Выюор элемента дерева
   *
   * @protected
   * @param {any} event
   * @param {*} item
   * @memberof AttachmentsTreeComponent
   */
  protected selectItem(event, item: any) {
    if (event.target.tagName !== 'A') {
      item.checked.next(!item.checked.getValue());
      event.preventDefault();
      event.stopImmediatePropagation();
      console.log(`file selected ${item.url}`);
    }
  }

  private load() {
    if (this.widgetMode) {
      switch (this.widgetMode) {
        case AttachmentsDialogMode.myFiles:
          this.myFiles(true);
          break;
        case AttachmentsDialogMode.groupFiles:
          this.groupFiles(true);
          break;
      }
    }
  }
}
