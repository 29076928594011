import { Component, OnInit } from '@angular/core';
import { WidgetBaseComponent } from '@app/shared/components/widget-base.component';
import { User } from '@app/profile/model/user.model';
import { UsersService } from '@app/profile/services/users.service';
import { HelperService } from '@app/core/services/helper.service';
import { Helper } from '@app/core/helpers/helper';
import { environment } from '@env/environment';
import { IntryAuthService } from '@app/auth/shared/services/intry-auth.service';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent extends WidgetBaseComponent implements OnInit {
  currentUser: User;

  constructor(
    public usersService: UsersService,
    public authService: IntryAuthService,
    public helper: HelperService) {
    super(helper);
  }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;
    });
  }

  navigate() {
    Helper.addOrRemoveClassToBody(false);
  }

  logout() {
    this.authService.logout();
  }
}
