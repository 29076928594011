import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from '@app/profile/services/users.service';
import { User } from '@app/profile/model/user.model';
import { takeUntil, take } from 'rxjs/operators';
import { SurveysService } from '@app/surveys/services/surveys.service';
import { HelperService } from '@app/core/services/helper.service';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { GroupsService } from '@app/groups/services/groups.service';
import { SurveyListComponent } from '@app/surveys/components/survey-list/survey-list.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ISurvey } from '@app/surveys/model/survey';
import { Tab } from '@app/shared/components/tabs/tab.model';

/**
 * Список опросов пользователя
 *
 * @export
 * @class UserSurveyListComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-user-survey-list',
  templateUrl: './user-survey-list.component.html',
  styleUrls: ['./user-survey-list.component.scss']
})
export class UserSurveyListComponent extends SurveyListComponent implements OnInit, OnDestroy {

  user: User;

  constructor(
    protected usersService: UsersService,
    protected surveysService: SurveysService,
    protected groupsService: GroupsService,
    protected breadcrumbsService: BreadcrumbsService,
    protected router: Router,
    protected route: ActivatedRoute,
    public helper: HelperService) { super(surveysService, breadcrumbsService, router, route, helper); }

  ngOnInit() {

    this.usersService.user.pipe(takeUntil(this.destroyed$)).subscribe(user => {
      if (user) {
        this.user = user;

        this.tabs = {
          title: 'Опросы',
          routerLink: `/profile/${user.id}/surveys`,
          items: [
            {
              id: 1,
              name: 'all',
              title: 'Все опросы',
              fragment: '',
              items: [],
              offset: 0,
              itemsCount: 0,
              method: this.getSurveysForUser.bind(this),
              emptyText: 'Пока нет опросов'
            },
          ]
        };

        // Создавать опросы может пользователь, который админ хотя бы в одной группе
        this.groupsService.getUserGroupsCanCreateSurveys(user.id, 0, 1)
          .pipe(take(1), takeUntil(this.destroyed$))
          .subscribe(res => {
            this.showAddButton = res && res.groups && res.groups.length ? true : false;

            if (this.showAddButton && !this.tabs.items.find(s => s.name === 'admin')) {
              this.tabs.items.push({
                id: 2,
                name: 'admin',
                title: 'Администрирование',
                fragment: 'admin',
                items: [],
                offset: 0,
                itemsCount: 0,
                method: this.getOwnerSurveys.bind(this),
                default: true,
                emptyText: 'Пока нет опросов'
              });
            } else {
              this.tabs.items[0].default = true;
            }
            // выполняем базовый метод инициализации, который обрабатывает табики
            super.ngOnInit();
          });

        this.breadcrumbsService.breadcrumbs.next([
          { title: 'Опросы', routerUrl: `/profile/${this.user.id}/surveys` }
        ]);
      } else {
        this.user = null;
      }
    });
  }

  isShowMenu(): boolean {
    return this.showAddButton;
  }

  protected getSurveysForUser(tab: Tab<ISurvey>): void {
    this.operateSurveys(tab, this.surveysService.getSurveysForUser(tab.offset, this.limit));
  }

  protected getOwnerSurveys(tab: Tab<ISurvey>): void {
    this.showAddButton = true;
    this.operateSurveys(tab, this.surveysService.getUserSurveys(tab.offset, this.limit));
  }
}
