<div class="content">
  <div class="cos">
    <div class="user-header" *ngIf="loaded && user && filestListType==1">
      <a href="#" class="link-profile" routerLink="/profile/{{user?.id}}">
        <app-user-avatar [user]="user" [linkClass]="'avatar _30'" [imageClass]="'avatar__image'" [asSpan]="true">
        </app-user-avatar>
        <span class="link-profile__text" [appUserTooltip]="user?.id">{{user?.fullName}}</span>
      </a>
      <app-breadcrumbs></app-breadcrumbs>
    </div>
    <div class="cos__header" *ngIf="loaded">
      <div class="cos__actions pull-right" *ngIf="isShowMenu()">
        <app-files-menu #topFilesMenu [showAddButton]="showAddButton" (onFolderCreated)="onCreated($event)" (onFileAdded)="onFileAdded($event)"
          (onFilesAdded)="onFilesAdded($event)" title="Добавить"></app-files-menu>
        <button *ngIf="showAddButton && selectedItems().length < 2" type="button" title="Предоставить доступ"
          class="btn btn-share hidden-phone" (click)="openShareItem()"></button>
        <button *ngIf="showAddButton" type="button" title="Синхронизировать"
          class="btn btn-sync hidden-phone hidden-tablet" (click)="syncFolder()"></button>
        <button *ngIf="showAddButton" type="button" title="Перейти в представление SharePoint"
          class="btn btn-sync-sp hidden-phone hidden-tablet" (click)="sharePointLink.click()"></button>
        <a #sharePointLink [href]="listAbsoluteUrl | async" target="_blank" style="display:none"></a>
      </div>
      <app-tabs [tabs]="tabs" [hideCounters]="true"></app-tabs>
    </div>
    <!-- header preloader -->
    <app-tab-preloader *ngIf="!loaded"></app-tab-preloader>
    <!--  header preloader END -->
    <div class="cos__body" *ngIf="showFiles && loaded && currentTab && currentTab.loaded && (filesService.folderId$ | async)">
      <!-- <app-files-breadcrumbs (onNavigate)="navigateBreadcrumb($event)" [folderUrl]="folderUrl"
        [rootFolderUrl]="rootFolderUrl"></app-files-breadcrumbs> -->
    </div>
    <div class="cos__body" *ngIf="isShowEmpty()">
      <div class="group-empty">
        <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" class="group-empty__img">
        <div class="group-empty__text" *ngIf="!showAddButton && filestListType==1">У коллеги нет файлов
          <br />в данной папке.</div>
        <div class="group-empty__text" *ngIf="showAddButton && filestListType==1 && currentTab?.id==3">Пользователи не
          предоставили Вам доступ
          <br />к своим файлам.</div>
        <div class="group-empty__text" *ngIf="showAddButton && filestListType==1 && currentTab?.id==1">У Вас нет
          последних
          <br />открытых файлов.</div>
        <div class="group-empty__text" *ngIf="showAddButton && currentTab?.id==2 && !currentFolderId">Добавляйте файлы
          <br /> для совместной работы с коллегами.</div>
        <div class="group-empty__text" *ngIf="showAddButton && currentTab?.id==2 && currentFolderId">В папке нет
          файлов,
          <br /> но Вы можете их добавить.</div>
        <div class="group-empty__text" *ngIf="!showAddButton && currentTab?.id==2 && currentFolderId">В папке нет
          файлов
          <br /> или у Вас нет к ним доступа.</div>
        <div class="align-center group-empty__action" *ngIf="showAddButton && currentTab?.id==2">
          <!-- <app-files-menu [showAddButton]="showAddButton" [webUrl]="webUrl" [listUrl]="rootFolderUrl"
            [folderUrl]="folderUrl" (onFolderCreated)="onCreated($event)" (onFileAdded)="onFileAdded($event)"
            (onFilesAdded)="onFilesAdded($event)"></app-files-menu> -->
        </div>
      </div>
      <div class="group-empty hidden-tablet hidden-phone" *ngIf="showAddButton && currentTab?.id==2">
        <div class="group-empty__text">Вы можете синхронизировать файлы со своим компьютером.</div>
        <div class="align-center group-empty__action">
          <button type="button" class="btn btn-sync hidden-phone hidden-tablet" (click)="syncFolder()"></button>
        </div>
        <div class="group-empty__text">Для этого Вам потребуется OneDrive для бизнеса.
          <br />
          <a target="_blank"
            href="https://support.microsoft.com/help/2903984/how-to-install-onedrive-for-business-for-sharepoint-and-sharepoint-onl">Нужна
            помощь?</a>
        </div>
      </div>
    </div>
    <div class="cos__body" *ngIf="isShowItems()">
      <!-- добавить класс _select, если выбран хотя бы один файл. -->
      <!-- Это нужно, чтобы на мобилке отображался первый столбец с чекбоксами-->
      <div class="list-table" [ngClass]="{'_select':isAnyChecked()}">
        <div class="list-table__mobile-header tooltip _bottom"
          [ngClass]="{'_active': showSortTooltip,'_up':sort, '_down':!sort}" (click)="toggleSortTooltip()"
          appClickOutside (clickOutside)="showSortTooltip=false" *ngIf="allowSort()">{{sortTitle}}
          <div class="tooltip__body" *ngIf="true">
            <div class="icon-menu">
              <button type="button" class="icon-menu__item btn" (click)="sortColumn('name', !sort)">
                <span class="icon-menu__icon">
                  <img src="{{assetsPrefix}}/assets/images/icons/check.svg" alt="" class="icon-menu__img"
                    *ngIf="sortBy=='name'">
                </span>
                <span>По названию</span>
              </button>
              <button type="button" class="icon-menu__item btn" (click)="sortColumn('modified', !sort)">
                <span class="icon-menu__icon">
                  <img src="{{assetsPrefix}}/assets/images/icons/check.svg" alt="" class="icon-menu__img"
                    *ngIf="sortBy=='modified'">
                </span>
                <span>По дате изменения</span>
              </button>
              <button type="button" class="icon-menu__item btn" (click)="sortColumn('user', !sort)">
                <span class="icon-menu__icon">
                  <img src="{{assetsPrefix}}/assets/images/icons/check.svg" alt="" class="icon-menu__img"
                    *ngIf="sortBy=='user'">
                </span>
                <span>По автору изменения</span>
              </button>
            </div>
          </div>
        </div>
        <table class="list-table__inner">
          <thead>
            <tr>
              <th *ngIf="showAddButton">
                <label class="checkbox _hover">
                  <input type="checkbox" class="checkbox__input" [checked]="itemsChecked"
                    (change)="onAllChecked($event)" />
                  <span class="checkbox__icon"></span>
                </label>
              </th>
              <th *ngIf="!showAddButton"></th>
              <ng-container *ngIf="allowSort(); then sortable; else simple"></ng-container>
              <ng-template #sortable>
                <th>
                  <span class="list-table__heading _image"
                    [ngClass]="{'_up':sort && sortBy=='name', '_down':!sort && sortBy=='name'}"
                    (click)="sortColumn('name', !sort)">
                    <span>Название</span>
                  </span>
                </th>
                <th></th>
                <th>
                  <span class="list-table__heading"
                    [ngClass]="{'_up':sort && sortBy=='modified', '_down':!sort && sortBy=='modified'}"
                    (click)="sortColumn('modified', !sort)">
                    <span>Изменено</span>
                  </span>
                </th>
                <th>
                  <span class="list-table__heading"
                    [ngClass]="{'_up':sort && sortBy=='user', '_down':!sort && sortBy=='user'}"
                    (click)="sortColumn('user', !sort)">
                    <span>Кем изменено</span>
                  </span>
                </th>
              </ng-template>
              <ng-template #simple>
                <th>
                  <span class="list-table__heading _image _disabled">
                    <span>Название</span>
                  </span>
                </th>
                <th></th>
                <th>
                  <span class="list-table__heading _disabled">
                    <span>Изменено</span>
                  </span>
                </th>
                <th>
                  <span class="list-table__heading _disabled">
                    <span>Кем изменено</span>
                  </span>
                </th>
              </ng-template>
            </tr>
          </thead>
          <tbody>
            <tr class="list-table__item" [ngClass]="{'_selected':item.checked | async}"
              *ngFor="let item of currentTab.items" (mouseover)="itemMouseOver($event, item)"
              (mouseout)="itemMouseOut($event, item)" (mousedown)="itemMouseDown($event, item)"
              (mouseup)="itemMouseUp($event, item, fileMenu)" (contextmenu)="showContextMenu($event, item, fileMenu)">
              <td *ngIf="showAddButton">
                <label class="checkbox _hover" (click)="clickItem($event, item)">
                  <input type="checkbox" class="checkbox__input" [checked]="item.checked | async" />
                  <span class="checkbox__icon"></span>
                </label>
              </td>
              <td *ngIf="!showAddButton"></td>
              <td>
                <!--Если папка-->
                <a attr.id="item_{{item.uniqueId}}" *ngIf="filesService.isFolder(item)" [routerLink]="tabs.routerLink"
                  fragment="{{currentTab.fragment}}&folder={{item.url}}" class="link-file _folder-num">
                  <span class="link-file__text">{{item.name}}</span>
                  <span class="link-file__number">{{item.count}}</span>
                  <span class="link-file__subtext" *ngIf="item?.shared?.length">
                    <span class="icon _shared"></span>
                  </span>
                </a>
                <!--Если файл-->
                <a attr.id="item_{{item.uniqueId}}" target="_blank" *ngIf="!filesService.isFolder(item)"
                  [attr.href]="filesService.getFileUrlV2(item)"
                  [ngClass]="filesService.getFileIcon(item)" class="link-file" (click)="openFile($event,item)">
                  <span class="link-file__text">{{item.name}}</span>
                  <span class="link-file__subtext">
                    <ng-container *ngIf="item.size">{{filesService.getSize(item)}} КБ</ng-container>
                    <span class="link-file__data hidden-desktop hidden-tablet">
                      <ng-container *ngIf="item.size">, </ng-container>{{item.modified | dateSpLike }},
                      {{item.editor && item.editor.length ? item.editor[0].title : ''}}
                    </span>
                    <span class="icon _shared" *ngIf="item?.shared?.length"></span>
                  </span>
                </a>
              </td>
              <td class="list-table__actions">
                <!-- <app-file-menu #fileMenu [item]="item" [webUrl]="webUrl" [folderUrl]="folderUrl"
                  [rootFolderUrl]="rootFolderUrl" [isCurrentUser]="showAddButton" (onRenamed)="onRenamed($event)"
                  (onDeleted)="onDeleted($event)" (onMoved)="onMoved($event)" (onCopied)="onCopied($event)" [config]="config"
                  itemLink="item_{{item.uniqueId}}" [listId]="listId" [portalUrl]="portalUrl"></app-file-menu> -->
              </td>
              <td>{{item.modified | dateSpLike }}</td>
              <td>{{item.editor && item.editor.length ? item.editor[0].title : ''}}</td>
            </tr>
            <tr *ngIf="currentTab?.loading">
              <div class="loading _blue _standalone"></div>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="cos__body" *ngIf="isShowNotFound()">
      <div class="group-empty">
        <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" class="group-empty__img">
        <div class="group-empty__text">Папка не существует,
          <br /> возможно она была перемещена или удалена</div>
      </div>
    </div>
    <div class="cos__body" *ngIf="isShowError()">
      <div class="group-empty">
        <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" class="group-empty__img">
        <div class="group-empty__text">Произошла ошибка при отображении списка файлов</div>
      </div>
    </div>
    <!-- body preloader -->
    <div class="cos__body" *ngIf="showFiles && !currentTab?.loaded">
      <span class="preloader _square _co" *ngFor="let r of [0,1,2,3,4,5]">
        <span class="preloader__side"></span>
        <span class="preloader__line"></span>
      </span>
    </div>
    <!--  body preloader END -->
  </div>
</div>
