import { Component, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import { BaseComponent } from '@app/core/components/base.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { PagesService } from '@app/pages/services/pages.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { AlertsService } from '@app/shared/services/alerts.service';
import { HelperService } from '@app/core/services/helper.service';
import { IPage } from '@app/pages/model/page';
import { PageLinkModalComponent } from '../../dialogs/page-link-modal/page-link-modal.component';

export interface IPageMenuConfig {
  allowEdit?: boolean;
  allowDelete?: boolean;
}

@Component({
  selector: 'app-page-menu',
  templateUrl: './page-menu.component.html',
  styleUrls: ['./page-menu.component.scss']
})
export class PageMenuComponent extends BaseComponent implements OnDestroy {

  @Input() config: IPageMenuConfig;

  @Input() selectedItems: IPage[];
  @Input() item: IPage;

  @Input() showExternalLinks: boolean;

  @Output() added: EventEmitter<IPage> = new EventEmitter();
  @Output() deleted: EventEmitter<number> = new EventEmitter();

  private deletingDialog: MatDialogRef<ModalConfirmationComponent>;
  private updateLinkDialog: MatDialogRef<PageLinkModalComponent>;
  private createLinkDialog: MatDialogRef<PageLinkModalComponent>;

  constructor(
    protected pagesService: PagesService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected alertsService: AlertsService,
    protected dialog: MatDialog,
    public helper: HelperService) {
    super(helper);
  }

  editClick() {
    if (this.item.isLink) {
      this.updateLinkDialog = this.dialog.open(PageLinkModalComponent, {
        data: {
          isNew: false,
          link: this.item,
          text: `Редактирование ссылки '${this.item.title}'`,
          ownerId: this.item.ownerId,
          parentId: this.item.parentId,
          onOk: (res: IPage) => {
            if (res) {
              console.log(`updated`);
              if (!this.item.ownerId) {
                this.pagesService.pageInMenuUpdated$.next();
              }
            }
            this.updateLinkDialog.close();
          }
        }
      });
    } else {
      if (this.item.ownerId) {
        this.router.navigate([`/group/${this.item.ownerId}/pages/${this.item.id}/edit`]);
      } else {
        this.router.navigate([`/admin/pages/${this.item.id}/edit`]);
      }
    }
  }

  /**
   * Всплывающее окно подтверждения удаления страницы
   *
   * @private
   * @memberof MediaItemMenuComponent
   */
  deleteClick() {
    this.selectedItems = [this.item];
    let text;
    if (this.selectedItems && this.selectedItems.length > 1) {
      text = 'страницы?';
    } else {
      text = 'страницу?';
    }
    this.deletingDialog = this.dialog.open(ModalConfirmationComponent, {
      data: {
        text: `Вы действительно хотите удалить ${text}`,
        onOk: this.deleteItem.bind(this),
        doNotHideOnOk: true
      }
    });
  }

  /**
   * Удаление страницы
   *
   * @private
   * @memberof MediaItemMenuComponent
   */
  private deleteItem() {
    this.selectedItems.forEach(item => {
      this.pagesService.remove(this.item.id).subscribe(() => {
        if (this.deleted) {
          this.deleted.emit(item.id);
          if (!this.item.ownerId) {
            this.pagesService.pageInMenuUpdated$.next();
          }
        }
        this.deletingDialog.close();
        this.alertsService.riseSuccess(`Страница ${item.title} была успешно удалёна`);
      }, error => {
        const errorText = 'Произошла ошибка при удалении страницы.';
        console.log(`${errorText}: '${error}'`);
        this.alertsService.riseError(errorText);
      });
    });
  }

  navigateToNewForm() {
    let queryParams: Params = null;

    if (this.item.id) {
      queryParams = { parentId: this.item.id };
    }

    if (this.item.ownerId) {
      this.router.navigate([`/group/${this.item.ownerId}/pages/new`], { queryParams: queryParams });
    } else {
      this.router.navigate([`/admin/pages/new`], { queryParams: queryParams });
    }
  }

  openCreateLink() {
    this.createLinkDialog = this.dialog.open(PageLinkModalComponent, {
      data: {
        isNew: true,
        link: null,
        text: `Добавление ссылки`,
        ownerId: this.item.ownerId ? this.item.ownerId : null,
        parentId: this.item.id,
        onOk: this.onLinkCreated.bind(this)
      }
    });
  }

  private onLinkCreated(res: IPage) {
    if (res) {
      console.log(`created`);
      this.added.emit(res);
      if (!this.item.ownerId) {
        this.pagesService.pageInMenuUpdated$.next();
      }
    }
    this.createLinkDialog.close();
  }
}
