import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { VacancyService } from '@app/vacancy/services/vacancy.service';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { AlertsService } from '@app/shared/services/alerts.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HelperService } from '@app/core/services/helper.service';
import { takeUntil, switchMap, take } from 'rxjs/operators';
import { IVacancyResponse } from '@app/vacancy/model/vacancy';
import { IVacancyResponseMenuResult, IVacancyResponseMenuConfig } from '../../menu/response-menu/response-menu.component';
import { AttachmentViewModel } from '@app/attachments/file-attachments/model/attachment-view-model.model';
import { User } from '@app/profile/model/user.model';
import { UsersService } from '@app/profile/services/users.service';

/**
 * Компонент просмотра отклика
 *
 * @export
 * @class ResponseViewComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-response-view',
  templateUrl: './response-view.component.html',
  styleUrls: ['./response-view.component.scss']
})
export class ResponseViewComponent extends BaseComponent implements OnInit, OnDestroy {

  vacancyId: number;

  response: IVacancyResponse;
  attachments: AttachmentViewModel[];

  responseConfig: IVacancyResponseMenuConfig;

  authorManager: User;

  constructor(
    protected usersService: UsersService,
    protected vacancyService: VacancyService,
    protected breadcrumbsService: BreadcrumbsService,
    private alertsService: AlertsService,
    private router: Router,
    private route: ActivatedRoute,
    public helper: HelperService
  ) { super(helper); }


  ngOnInit() {
    this.route.params
      .pipe(takeUntil(this.destroyed$), switchMap((params: Params) => {
        this.vacancyId = +params['vacancyId'];
        const responseId = +params['responseId'];
        return this.vacancyService.getResponse(responseId);
      }))
      .subscribe(response => {
        this.response = response;

        if (this.response && this.response.userAdmin) {

          this.fillUserManager();

          const vacancyRoute = `/group/${this.response.vacancyOwnerId}/vacancy`;

          this.breadcrumbsService.breadcrumbs.next([
            { title: 'Вакансии', routerUrl: vacancyRoute },
            { title: 'Вакансия', routerUrl: vacancyRoute + `/${response.vacancyId}` },
            { title: 'Отклик', routerUrl: vacancyRoute + `/${response.vacancyId}/response/${response.id}` }
          ]);

          // TODO: check group rights
          this.responseConfig = {
            allowChangeStatus: true
          };

          // отображаем аттачи
          if (this.response.docs) {
            this.attachments = this.response.docs.map(s => {
              return { attachment: s, owner: null };
            });
          }

        } else {
          this.router.navigate(['/404'], { skipLocationChange: true });
        }

        this.error = '';
        this.loaded = true;
      }, error => {
        this.error = error;
        this.loaded = true;
      });
  }

  private fillUserManager() {
    this.usersService.getUserByLogin(this.response.author.accountName)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        if (res && res.managerLogin) {
          const manager = res.managerLogin;
          const user = this.usersService.users.find(s => s.accountName
            && s.accountName.toLowerCase() === manager.toLowerCase());

          if (!user) {
            this.usersService.getUserByLogin(manager)
              .pipe(takeUntil(this.destroyed$))
              .subscribe(authorManager => {
                this.authorManager = authorManager;
              });
          } else {
            this.authorManager = user;
          }
        }
      });
  }

  onStatusChanged(result: IVacancyResponseMenuResult) {
    if (this.response) {
      this.response.status = result.status;
    }
  }
}
