<div class="card">
  <span class="header-text _modal">
    <span class="header-text__text">{{headerText}}</span>&nbsp;<span class="header-text__count"></span>
  </span>
  <app-files-breadcrumbs (onNavigate)="navigateBreadcrumb($event)" [folderUrl]="folder" [rootFolderUrl]="rootFolderUrl">
  </app-files-breadcrumbs>
</div>
<div class="files-list">
  <div class="list-table">
    <table class="list-table__inner" *ngIf="loaded">
      <tbody>
        <tr *ngFor="let item of items">
          <td>
            <!--Если папка-->
            <a href="javascript:" *ngIf="filesService.isFolder(item)" (click)="navigate(item)"
              class="link-file _folder-num">
              <span class="link-file__text">{{item.name}}</span>
              <span class="link-file__number">{{item.count}}</span>
            </a>
            <!--Если файл-->
            <a href="javascript:" *ngIf="!filesService.isFolder(item)" (click)="selectFile(item)"
              [ngClass]="filesService.getFileIcon(item)" class="link-file">
              <span class="link-file__text">{{item.name}}</span>
              <span class="link-file__subtext" *ngIf="item.size">{{filesService.getSize(item)}} КБ</span>
              <span class="link-file__subtext">{{item.shared && item.shared.length ? item.shared.length : ''}}</span>
            </a>
          </td>
        </tr>
        <tr>
          <td>
            <div class="new-file">
              <input placeholder="Новая папка" class="new-file__name" [(ngModel)]="newFolderName"
                (keyup)="onNewFolderKey($event)">
              <button type="button" class="btn btn-simple icon _close new-file__clear" *ngIf="newFolderName"
                (click)="newFolderName=''"></button>
              <button type="button" class="btn btn-check new-file__add" *ngIf="newFolderName"
                [attr.disabled]="newFolderName?null:''" (click)="createFolder()"></button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="!loaded">
      <div class="loading _blue _standalone"></div>
    </div>
  </div>
  <div class="card" *ngIf="data.error">
    <div class="error-message">{{data.error}}</div>
  </div>
</div>
<div class="confirm _right">
  <div class="confirm__actions" mat-dialog-actions>
    <button type="button" mat-button mat-dialog-close class="btn btn-secondary" (click)="cancel()">Отмена</button>
    <button type="button" mat-button class="btn btn-primary" (click)="ok()"
      [attr.disabled]="isValid()?null:''">{{okText}}</button>
  </div>
</div>