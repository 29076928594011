import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { environment } from '@env/environment';
import { HelperService } from '@app/core/services/helper.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPage, INewPage, IUpdatePage, INewLink, IUpdateLink, IPageSettings, IPageSearch } from '../model/page';

/**
 * АПИ сервис Контентных страниц
 *
 * @export
 * @class PagesApiService
 * @extends {BaseService}
 */
@Injectable({
  providedIn: 'root'
})
export class PagesApiService extends BaseService {

  protected serviceUrl = `${environment.siteUrl}/api/page`;

  constructor(
    protected http: HttpClient,
    public helper: HelperService) { super(http, helper); }

  /**
   * Получить страницу по идентификатору
   *
   * @param {number} id ИД опроса
   * @returns {Observable<IPage>}
   * @memberof PagesApiService
   */
  getById(id: number): Observable<IPage> {
    return super.get(`${id}`);
  }

  /**
   * Создать страницу
   *
   * @param {INewPage} page новая страница
   * @returns
   * @memberof PagesApiService
   */
  createPage(page: INewPage): Observable<IPage> {
    return super.post(``, page);
  }

  /**
   * Обновить страницу
   *
   * @param {IUpdatePage} page
   * @returns
   * @memberof PagesApiService
   */
  updatePage(id: number, page: INewPage): Observable<boolean> {
    return super.put(`${id}/page`, page);
  }

  /**
   * Создать ссылку
   *
   * @param {INewLink} link новая ссылка
   * @returns
   * @memberof PagesApiService
   */
  createLink(link: INewLink): Observable<IPage> {
    return super.post(`link`, link);
  }

  /**
   * Обновить ссылку
   *
   * @param {IUpdateLink} link обновлённая ссылка
   * @returns
   * @memberof PagesApiService
   */
  updateLink(id: number, link: INewLink): Observable<boolean> {
    return super.put(`${id}/link`, link);
  }

  /**
   * Удаление страницы
   *
   * @param {number} id
   * @returns {Observable<boolean>}
   * @memberof PagesApiService
   */
  remove(id: number): Observable<boolean> {
    return super.delete(`${id}`);
  }

  /**
   * Изменить порядковый номер страницы
   *
   * @param {number} pageId ИД страницы
   * @param {number} order Новый порядковый номер
   * @returns {Observable<boolean>}
   * @memberof PagesApiService
   */
  changeOrder(pageId: number, order: number): Observable<boolean> {
    return this.put(`order`, { pageId, order });
  }

  /**
   * Изменить настройки страницы/ссылки
   *
   * @param {IPageSettings} settings
   * @returns {Observable<IPage>}
   * @memberof PagesApiService
   */
  changeSettings(settings: IPageSettings): Observable<boolean> {
    return this.put(`settings`, settings);
  }

  /**
   * Страницы, созданные в профиле (для виджета)
   *
   * @param {number} profileId ИД профиля
   * @returns {Observable<IPage[]>}
   * @memberof PagesApiService
   */
  getProfilePages(profileId: number): Observable<IPage[]> {
    return super.get(`profile/${profileId}/pages`);
  }

  /**
   * Страницы, созданные в группе (в группе они только и создаются)
   *
   * @param {number} groupId ИД группы
   * @returns {Observable<IPage[]>}
   * @memberof PagesApiService
   */
  getGroupPages(groupId: number, showHidden: boolean): Observable<IPage[]> {
    return super.get(`group/${groupId}/pages?showHidden=${showHidden}`);
  }

  /**
   * Получить страницы для меню
   *
   * @returns {Observable<IPage[]>}
   * @memberof PagesApiService
   */
  getMenuPages(): Observable<IPage[]> {
    return super.get(`menuPages`);
  }

  /**
   * Поиск по контентным страницам
   *
   * @param {string} text текст запроса
   * @memberof PagesApiService
   */
  search(text: string, groupId: number, offset: number, limit: number): Observable<IPageSearch[]> {
    return super.get(`search?text=${encodeURIComponent(text)}&groupId=${groupId}&offset=${offset}&limit=${limit}`);
  }
}
