import { ProfileType } from '@app/core/model/profile-type.enum';
import { GroupInfo } from '@app/groups/model/group-info.model';
import { User } from '@app/profile/model/user.model';
import { IAlbumInfo } from '@app/gallery/model/album-model';

/**
 * Тип элемента галереи
 *
 * @export
 * @enum {number}
 */
export enum MediaFileType {
    undefined = 0,
    image = 1,
    video = 2
}

/**
 * Элемент галереи (фото или видео)
 *
 * @export
 * @interface IMediaFile
 */
export interface IMediaFile {
    id?: number;
    title: string;
    name?: string;
    type?: MediaFileType;
    description?: string;
    isAlbumPreview?: boolean;
    created?: Date;
    url?: string;
    preview?: string;
    modified?: string;
    guid?: string;
    group?: GroupInfo;
    author?: User;
    owner?: User;
    ownerId?: number;
    ownerType?: ProfileType;
    album?: IAlbumInfo;
    commentsCount?: number;
    likeCount?: number;
    canLike?: boolean;
    filesCountInBlock?: number;
    // ui
    selected?: boolean;
    albumLink?: string;
    toUpload?: boolean;
    tempUrl?: string;
    urlEncoded?: string;
    previewUrl?: string;
    croppedUrl?: string;
}

export interface IMediaFileGrouped {
    ownerType: ProfileType;
    ownerId: number;
    user?: User;
    group?: GroupInfo;
    date: string;
    mediaFiles: IMediaFile[];
    filesCountInBlock: number;
}

export interface IMediaFileRequestModel {
    title?: string;
    description?: string;
    preview?: string;
    url?: string;
    guid?: string;

}

export interface IUpdateMediaFileModel extends IMediaFileRequestModel {
    mediaFileId: number;
}

export interface IMoveMediaFileModel {
    mediaFileId: number;
    newAlbumId: number;
}

export interface ISetMediaFileAsCoverModel {
    mediaFileId: number;
    albumId: number;
    url: string;
}

export interface IMediaFileMovedModel {
    id: number;
    fromAlbumId: number;
    toAlbumId: number;
}

export class UploadingFile extends File {
    uploading?: boolean;
}
