<div class="cos" *ngIf="loaded">
  <div class="user-header">
    <span class="user-header__back hidden-desktop">
      <app-back-link></app-back-link>
    </span>
    <a href="javascript:;" class="link-profile hint">
      <span title="" class="avatar _30">
        <span class="avatar__image-wrap">
          <img src="{{assetsPrefix}}/assets/images/icons/ask.svg" alt="" class="avatar__image">
        </span>
      </span>
      <span class="link-profile__text">Задать вопрос</span>
    </a>
  </div>
  <div class="form cos__body">
    <div class="form__line">
      <div class="dropdown _default" [ngClass]="{'_placeholder':!category}">
        <div mat-button [matMenuTriggerFor]="menu">
          {{category ? category.title : 'Выберите категорию вопроса*'}}
        </div>
        <mat-menu #menu="matMenu">
          <div class="icon-menu" #menuContainer>
            <a mat-menu-item href="javascript:;" class="icon-menu__item" (click)="setCategory(cat)" *ngFor="let cat of categories">
              <span>{{cat.title}}</span>
            </a>
          </div>
        </mat-menu>
      </div>
    </div>
    <div class="form__line">
      <div class="input-main form__about _full-width">
        <textarea autosize rows="1" placeholder="Напишите ваш вопрос *" [(ngModel)]="feedback" style="min-height: 1.5rem;"
          class="input-main__field"></textarea>
      </div>
    </div>
    <div class="form__line">
      <div class="form__note">Ответ на ваш вопрос вы получите по электронной почте</div>
    </div>
    <div class="form__actions" [ngClass]="{'loading _blue': loading}">
      <button type="button" class="btn btn-primary group__create" (click)="submit()" [attr.disabled]="isValid()?null:''">Задать
        вопрос</button>
    </div>
  </div>
</div>
