import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserLinksViewModel } from '@app/subscribe/model/user-links-view-model';
import { UserLink } from '@app/subscribe/model/user-link';
import { SubscriptionResultType } from '@app/subscribe/model/subscription-result-type';
import { HelperService } from '@app/core/services/helper.service';

/**
 * API - сервис "Коллег"
 *
 * @export
 * @class SubscribeApiService
 * @extends {BaseService}
 */
@Injectable()
export class SubscribeApiService extends BaseService {
  protected serviceUrl = `${environment.siteUrl}/api/subscription`;

  constructor(
    protected http: HttpClient,
    public helper: HelperService) { super(http, helper); }

  /**
   * Получить всех коллег пользователя
   *
   * @param {number} userId ИД пользователя, коллег которого нужно получить
   * @param {number} offset сдвиг
   * @param {number} [limit=20] количество возвращаемых элементов
   * @returns {Observable<UserLinksViewModel>}
   * @memberof SubscribeApiService
   */
  getAllOnUser(userId: number, offset: number, limit: number = 20): Observable<UserLinksViewModel> {
    return this.get<UserLinksViewModel>(`${userId}/allOnUser?offset=${offset}&limit=${limit}&t=${new Date().getTime()}`);
  }

  /**
   * Искать по коллегам пользователя
   *
   * @param {string} query текст запроса
   * @param {number} userId ИД пользователя, коллег которого нужно найти
   * @param {number} offset сдвиг
   * @param {number} [limit=20] количество возвращаемых элементов
   * @returns {Observable<UserLinksViewModel>}
   * @memberof SubscribeApiService
   */
  searchOnUser(query: string, userId: number, offset: number, limit: number = 20): Observable<UserLinksViewModel> {
    // tslint:disable-next-line:max-line-length
    return this.get<UserLinksViewModel>(`searchOnUser?query=${query}&userId=${userId}&offset=${offset}&limit=${limit}&t=${new Date().getTime()}`);
  }

  /**
   * Получить подписчиков пользователя
   *
   * @param {number} userId ИД пользователя, подписчиков которого нужно получить
   * @param {number} offset сдвиг
   * @param {number} [limit=20] количество возвращаемых элементов
   * @returns {Observable<UserLinksViewModel>}
   * @memberof SubscribeApiService
   */
  getSubscribers(userId: number, offset: number, limit: number = 20): Observable<UserLinksViewModel> {
    return this.get<UserLinksViewModel>(`${userId}/subscribers?offset=${offset}&limit=${limit}`);
  }

  /**
   * Получить пользователей, на которых подписан текущий пользователь
   *
   * @param {number} userId ИД пользователя, подписантов которого нужно получить
   * @param {number} offset сдвиг
   * @param {number} [limit=20] количество возвращаемых элементов
   * @returns {Observable<UserLinksViewModel>}
   * @memberof SubscribeApiService
   */
  getSubscriptions(userId: number, offset: number, limit: number = 20): Observable<UserLinksViewModel> {
    return this.get<UserLinksViewModel>(`${userId}/subscriptions?offset=${offset}&limit=${limit}`);
  }

  /**
   * Подписаться на пользователя
   *
   * @param {number} userId ИД пользователя, на которого текущий пользователь хочет подписаться
   * @returns {Observable<any>}
   * @memberof SubscribeApiService
   */
  subscribe(userId: number): Observable<any> {
    return this.post<any>(`${userId}/subscribe`, { });
  }

  /**
   * Подписаться на пользователей
   *
   * @param {number[]} userIds ИД пользователей, на которых текущий пользователь хочет подписаться
   * @returns {Observable<any>}
   * @memberof SubscribeApiService
   */
  subscribeAll(userIds: number[]): Observable<any> {
    return this.post<any>(`subscribeAll`,  userIds);
  }

  /**
   * Отписаться от пользователя
   *
   * @param {number} userId ИД пользователя, от которого текущий пользователя хочет отписаться
   * @returns {Observable<any>}
   * @memberof SubscribeApiService
   */
  unsubscribe(userId: number): Observable<any> {
    return this.post<any>(`${userId}/unsubscribe`, { });
  }

  /**
   * Получить количество коллег пользователя по типу.
   *
   * @param {number} userId ИД пользователя, подписчиков которого нужно получить
   * @param {SubscriptionResultType} type 0 - все, 1 - подписки, 2 - на кого подписан.
   * @returns {Observable<number>}
   * @memberof SubscribeApiService
   */
  getCount(userId: number, type: SubscriptionResultType): Observable<number> {
    return this.get<number>(`${userId}/count?type=${type}`);
  }

  /**
   * Подписан ли пользователь на другого пользователя?
   *
   * @param {number} userId ИД пользователя, который хочет проверить
   * @param {number} targetId ИД пользователя, которого нужно проверить
   * @returns {Observable<boolean>}
   * @memberof SubscribeApiService
   */
  isSubscribedOn(userId: number, targetId: number): Observable<boolean> {
    return this.get<boolean>(`${userId}/isSubscribedOn/${targetId}`);
  }

  /**
   * Получить объект подписки, если пользователь userId подписан на пользователя targetId
   *
   * @param {number} userId ИД пользователя, который хочет проверить
   * @param {number} targetId ИД пользователя, которого нужно проверить
   * @returns {Observable<UserLink>}
   * @memberof SubscribeApiService
   */
  subscribedOn(userId: number, targetId: number): Observable<UserLink> {
    return this.get<UserLink>(`${userId}/subscribedOn/${targetId}`);
  }
}
