import {
  Component,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IPage, IPageSettings } from '@app/pages/model/page';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { PagesService } from '@app/pages/services/pages.service';
import { IPageMenuConfig } from '../menu/page-menu/page-menu.component';
import { takeUntil, take } from 'rxjs/operators';
import { AlertsService } from '@app/shared/services/alerts.service';
import { ITreeState, ITreeOptions } from '@circlon/angular-tree-component';
import { v4 } from 'uuid';

/**
 * Список страниц
 *
 * @export
 * @class PagesListComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-pages-list',
  templateUrl: './pages-list.component.html',
  styleUrls: ['./pages-list.component.scss'],
})
export class PagesListComponent extends BaseComponent implements OnChanges, OnDestroy {
  @Input() pages: IPage[] = [];
  @Input() config: IPageMenuConfig;
  @Input() enableShowInWidget: boolean;

  pagesTree: IPage[] = [];
  showHidden = false;

  state: ITreeState = {
    expandedNodeIds: {},
    hiddenNodeIds: {},
    activeNodeIds: {},
  };

  expandedNodeIds: number[] = [];

  options: ITreeOptions = {
    allowDrag: node => this.isAllowDrag(),
    getNodeClone: node => ({
      ...node.data,
      id: v4(),
      title: `copy of ${node.data.title}`,
    }),
    useVirtualScroll: false,
  };

  @ViewChild('tree', { static: true }) tree;

  constructor(
    public pagesService: PagesService,
    public alertsService: AlertsService,
    public helper: HelperService,
  ) {
    super(helper);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pages']) {
      if (this.pages) {
        this.pagesTree = this.pagesService.plainToTree(this.pages, this.expandedNodeIds);
        const that = this;
        setTimeout(() => {
          if (that.tree.treeModel.roots) {
            that.tree.treeModel.expandAll();
          }
        }, 100);
      }
    }
  }

  onAdded(e: IPage) {
    if (e) {
      this.pages.push(e);
      this.pagesTree = this.pagesService.plainToTree(this.pages, this.expandedNodeIds);
    }
  }

  onDeleted(id: number) {
    if (id) {
      this.pages = this.pages.filter(s => s.id !== id);
      this.pagesTree = this.pagesService.plainToTree(this.pages, this.expandedNodeIds);
    }
  }

  toggleShowInWidget(page: IPage) {
    if (page) {
      const showInWidget = !page.showInWidget;
      this.pagesService
        .changeSettings({ id: page.id, showInWidget: showInWidget })
        .pipe(take(1), takeUntil(this.destroyed$))
        .subscribe(
          res => {
            if (res) {
              const text = showInWidget
                ? 'Успешно отображается в виджете'
                : 'Успешно скрыто из виджета';
              page.showInWidget = showInWidget;
              if (page.ownerId) {
                this.pagesService.pageInWidgetUpdated$.next();
              }
              this.alertsService.riseSuccess(text);
            } else {
              this.alertsService.riseError(
                `Произошла ошибка ${showInWidget ? 'отображения' : 'скрытия'} в виджете`,
              );
            }
          },
          error => {
            console.log(error);
            this.alertsService.riseError(
              `Произошла ошибка ${showInWidget ? 'отображения' : 'скрытия'} в виджете`,
            );
          },
        );
    }
  }

  toggleVisible(page: IPage) {
    if (page) {
      const visible = !page.visible;
      this.pagesService
        .changeSettings({ id: page.id, visible: visible })
        .pipe(take(1), takeUntil(this.destroyed$))
        .subscribe(
          res => {
            if (res) {
              const text = visible ? 'Успешно отображается' : 'Успешно скрыто';
              page.visible = visible;
              if (page.ownerId) {
                this.pagesService.pageInWidgetUpdated$.next();
              } else {
                this.pagesService.pageInMenuUpdated$.next();
              }
              this.alertsService.riseSuccess(text);
            } else {
              this.alertsService.riseError(
                `Произошла ошибка ${visible ? 'отображения' : 'скрытия'}`,
              );
            }
          },
          error => {
            console.log(error);
            this.alertsService.riseError(
              `Произошла ошибка ${visible ? 'отображения' : 'скрытия'}`,
            );
          },
        );
    }
  }

  getShowInWidgetTooltip(page: IPage) {
    return page.showInWidget ? 'Убрать из виджета' : 'Добавить в виджет';
  }

  onMoveNode($event) {
    console.log(
      'Moved',
      $event.node.title,
      'to',
      $event.to.parent.title,
      'at index',
      $event.to.index,
    );

    const data: IPageSettings = { id: $event.node.id };

    // указываем нового родителя
    if ($event.to.parent && !$event.to.parent.virtual) {
      data.parentId = $event.to.parent.id;
    } else {
      data.parentId = 0;
    }
    // устанавливаем сортировку для всех элементов того же уровня
    if ($event.to.parent && $event.to.parent.children) {
      data.orders = $event.to.parent.children.map(s => {
        return { id: s.id, order: $event.to.parent.children.indexOf(s) };
      });
    }

    this.pagesService
      .changeSettings(data)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        res => {
          if (res) {
            this.alertsService.riseSuccess(`Успешно внесены изменения`);
          } else {
            this.alertsService.riseError(`Произошла ошибка при внесении изменений`);
          }
        },
        error => {
          this.alertsService.riseError(`Произошла ошибка при внесении изменений`);
        },
      );
  }

  isShowMenu(page: IPage) {
    return this.config && (this.config.allowEdit || this.config.allowDelete);
  }

  isAllowDrag() {
    return this.config && (this.config.allowEdit || this.config.allowDelete);
  }

  // private updateTreeView() {
  //   this.pagesTree = this.pagesService.plainToTree(this.pages, this.expandedNodeIds);
  //   const tuple: Map<number, boolean> = new Map<number, boolean>();
  //   this.expandedNodeIds.forEach(id => {
  //     tuple.set(id, true);
  //   });
  //   this.state.expandedNodeIds = <any>tuple;
  // }

  protected getLink() { }

  protected getItems() { }
}
