import { Component, OnInit, OnDestroy } from '@angular/core';
import { IAward } from '@app/awards/model/awards';
import { UsersService } from '@app/profile/services/users.service';
import { AwardsService } from '@app/awards/services/awards.service';
import { HelperService } from '@app/core/services/helper.service';
import { UserAwardsComponent } from '../user-awards/user-awards.component';
import { Tabs, Tab } from '@app/shared/components/tabs/tab.model';
import { takeUntil, take, finalize } from 'rxjs/operators';
import { User } from '@app/profile/model/user.model';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';

/**
 * Список всех наград пользователя
 *
 * @export
 * @class UserAwardsListComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-user-awards-list',
  templateUrl: './user-awards-list.component.html',
  styleUrls: ['./user-awards-list.component.scss']
})
export class UserAwardsListComponent extends UserAwardsComponent implements OnInit, OnDestroy {

  /**
   * Текущий пользователь администратор наград?
   *
   * @type {boolean}
   * @memberof UserAwardsComponent
   */
  isAdmin: boolean;

  tabs: Tabs<IAward>;
  currentTab: Tab<IAward>;

  constructor(
    public usersService: UsersService,
    public awardsService: AwardsService,
    public breadcrumbsService: BreadcrumbsService,
    public helper: HelperService
  ) {
    super(usersService, awardsService, helper);
  }

  protected getAwards(user: User) {
    this.tabs = {
      title: 'Награды',
      routerLink: `/profile/${user.id}/awards`,
      items: [
        {
          id: 1,
          title: 'Награды',
          fragment: '',
          items: [],
          offset: 0,
          itemsCount: 0,
          method: () => { },
          default: true,
          emptyText: 'У пользователя нет наград'
        }
      ]
    };

    this.currentTab = this.tabs.items[0];

    this.breadcrumbsService.breadcrumbs.next([
      { title: 'Награды', routerUrl: `/profile/${user.id}/awards` }
    ]);

    this.awardsService.getUserAwards(user.id)
      .pipe(finalize(() => { this.loaded = true; this.currentTab.loaded = true; }), takeUntil(this.destroyed$))
      .subscribe((awards = []) => {
        this.currentTab.items = awards.sort((a, b) => b.id - a.id);
        this.currentTab.itemsCount = awards.length;
      });
  }

  onDeleted(id: number) {
    this.currentTab.items = this.currentTab.items.filter(s => s.id !== id);
  }
}
