import { map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { Helper } from '@app/core/helpers/helper';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { UsersService } from '@app/profile/services/users.service';
import { HttpClient } from '@angular/common/http';
import { AlertsService } from '@app/shared/services/alerts.service';
import { ListItemShareBody } from '@app/files/model/list-item-share-body.model';
import { FileVersion } from '@app/files/model/file-version.model';
import { ShareableObject } from '@app/files/model/shareable-object.model';
import { FSObjType } from '@app/files/model/list-data';
import {
    FilesList,
    FilesListItem,
    FilesListItemType,
    FilesListItemShared,
    FolderItem,
    DocumentTemplateType,
    IRootOdataResponseModel
} from '@app/files/model/files-list-item.model';
import { HelperService } from '@app/core/services/helper.service';
import { FileUploadService } from '@app/shared/services/file-upload.service';
import { environment } from '@env/environment';
import { IDatafileModel } from '../model/data-file-model';

@Injectable()
export class FilesApiService extends BaseService {
    protected serviceUrl = `${environment.siteUrl}/api/file`;

    public userRootFolder = 'Documents';
    public groupRootFolder = 'Shared documents';
    // public currentUserFilesCount: Subject<number> = new BehaviorSubject<number>(0);

    // public currentFilesWebUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    // public currentFilesRootUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    // public currentFilesListUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    // public currentFilesFolderUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    // public currentUserFilesListUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    // public currentUserFilesRootUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    // public currentGroupFilesWebUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    // public currentGroupFilesListUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    // public currentGroupFilesRootUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    public newWOPIDocumentEnabled: boolean;

    constructor(
        protected http: HttpClient,
        protected usersService: UsersService,
        protected alertsService: AlertsService,
        protected fileUploadService: FileUploadService,
        public helper: HelperService) {
        super(http, helper);
    }

    /* Новое API файлов  */

    getListData(ownerId: number, parentId: number) {
        return this.post<any>(`listData`, { ownerId, parentId }).pipe(map(data => {
            const result = new FilesList();
            if (data) {
                // result.listId = data.listName;
                // result.rootFolder = data.rootFolder;
                // result.listUrlDir = data.listUrlDir;
                // result.prevHref = data.ListData.PrevHref;
                // result.nextHref = data.ListData.NextHref;
                // result.lastRow = data.ListData.LastRow;
                // result.firstRow = data.ListData.FirstRow;
                // result.folderPermissions = data.ListData.FolderPermissions;
                // result.newWOPIDocumentEnabled = data.NewWOPIDocumentEnabled;

                if (data.items && data.items.length) {
                    data.items.forEach(row => {
                        const item = new FilesListItem();
                        item.name = row.name;
                        // item.url = row.FileRef;
                        item.count = 0; // +row.ItemChildCount + +row.FolderChildCount;
                        item.id = row.id;
                        item.type = row.type;
                        // item.size = row.File_x0020_Size;
                        item.modified = row.modified;
                        item.uniqueId = row.guid;
                        item.version = row._UIVersionString;
                        item.itemType = row.ProgId;
                        item.checked = new BehaviorSubject<boolean>(null);
                        item.permMask = row.PermMask;
                        if (row.editor) {
                            item.editor = row.editor;
                        }
                        if (row.SharedWithUsers) {
                            item.shared = row.SharedWithUsers;
                        }
                        result.items.push(item);
                    });
                }

            } else {
                return null;
            }

            return result;
        }));
    }

    getLastListData(ownerId: number, offset: number = 0, limit: number = 20, filesOnly: boolean = false) {
        return this.getListData(ownerId, null);
    }

    getRecentListData(ownerId: number, offset: number = 0, limit: number = 20, filesOnly: boolean = false) {
        return this.getListData(ownerId, null);
    }

    getSharedWithPersonV2(ownerId: number, offset: number = 0, limit: number = 20, filesOnly: boolean = false) {
        return this.getListData(ownerId, null);
    }

    getSharedWithMeV2(ownerId: number, offset: number = 0, limit: number = 20, filesOnly: boolean = false) {
        return this.getListData(ownerId, null);
    }

    createFolder(name: string, parentId: number, ownerId: number) {
        return super.post<any>('folder', { name, parentId, ownerId });
    }

    /* Web API methods */

    uploadFile(url: string, folderUrl: string, filename: string, file: any, overwrite: boolean = true) {
        return this.fileUploadService.uploadFile(url, folderUrl, filename, file, overwrite);
    }

    getFolderByServerRelativeUrl(url: string, library: string): Observable<any> {
        // tslint:disable-next-line:max-line-length
        return this.get<any>(Helper.concatUrls(url, `/_api/Web/GetFolderByServerRelativeUrl('${decodeURIComponent(Helper.concatUrls(url, library))}')`));
    }

    getUserFolderByServerRelativeUrl(url): Observable<any> {
        return this.getFolderByServerRelativeUrl(url, this.userRootFolder);
    }

    getGroupFolderByServerRelativeUrl(url): Observable<any> {
        return this.getFolderByServerRelativeUrl(url, this.groupRootFolder);
    }

    syncFolder(url): Observable<any> {
        const operationUrl = Helper.concatUrls(this.serviceUrl, url);
        return this.getUserFolderByServerRelativeUrl(url).pipe(
            switchMap(res => {
                return res;
            }));
    }

    /**
     * Скачать файл
     */
    webApiGetFileContent(webUrl: string, fileUrl: string) {
        return this.get(`getFileContent?url=${decodeURIComponent(Helper.concatUrls((<any>window).Intry.siteHost, fileUrl))}`,
            null,
            null,
            'blob'
        );
        // binaryStringResponseBody: true,
    }

    getRenderListDataAsStream(
        webUrl: string,
        listUrl: string,
        folderUrl: string,
        sortField: string = 'LinkFilename',
        sortDir: string = 'Asc',
        offset: number = 0,
        rowLimit: number = 0,
        scope: string = '',
        foldersOnly: boolean = false,
        nextHref = '',
        titleFilter = ''): Observable<FilesList> {

        const foldersQuery = foldersOnly ?
            `<Where>
                    <Eq>
                        <FieldRef Name='FSObjType' />
                        <Value Type='Integer'>1</Value>
                    </Eq>
                </Where>` : '';


        const titleQuery = titleFilter ?
            `<Where>
                    <Contains><FieldRef Name =\"LinkFilename\"/><Value Type = \"Text\">${titleFilter}</Value></Contains>
                </Where>` : '';

        const folder = folderUrl && !titleFilter
            ? `&RootFolder=${encodeURIComponent(decodeURIComponent(folderUrl))}`
            : '';

        const body = {
            'parameters':
            {
                'RenderOptions': 23,
                'ViewXml': `<View ${scope ? scope : ''}>
                    <Query>
                        <OrderBy>
                            <FieldRef Name=\"LinkFilename\" Ascending=\"true\"></FieldRef>
                        </OrderBy>
                        ${foldersQuery}
                        ${titleQuery}
                    </Query>
                    <ViewFields>
                        <FieldRef Name=\"FSObjType\"/>
                        <FieldRef Name=\"LinkFilename\"/>
                        <FieldRef Name=\"Modified\"/>
                        <FieldRef Name=\"Editor\"/>
                        <FieldRef Name=\"FileSizeDisplay\"/>
                        <FieldRef Name=\"SharedWith\"/>
                        <FieldRef Name=\"_ip_UnifiedCompliancePolicyUIAction\"/>
                        <FieldRef Name=\"ItemChildCount\"/>
                        <FieldRef Name=\"FolderChildCount\"/>
                        <FieldRef Name=\"SMTotalFileCount\"/>
                        <FieldRef Name=\"SMTotalSize\"/>
                        <FieldRef Name=\"_UIVersionString\" />
                    </ViewFields>
                    <RowLimit Paged=\"TRUE\">${rowLimit}</RowLimit></View>`
            }
        };


        let operationUrl = '';
        if (nextHref) {
            nextHref = nextHref.replace('00000000-0000-0000-0000-000000000000', '');
            operationUrl = `/_api/web/GetList(@listUrl)/RenderListDataAsStream${nextHref}&@listUrl='${decodeURIComponent(listUrl)}'`;
        } else {
            // tslint:disable-next-line:max-line-length
            operationUrl = `/_api/web/GetList(@listUrl)/RenderListDataAsStream?@listUrl='${decodeURIComponent(listUrl)}'&View=${folder}&SortField=${sortField}&SortDir=${sortDir}`;
        }
        return this.post<any>(`renderListDataAsStream`,
            { url: Helper.concatUrls(webUrl, operationUrl), body: JSON.stringify(body) }, null, 'text')
            .pipe(map(response => {

                if (!response) {
                    return null;
                }

                const data = JSON.parse(response);
                const result = new FilesList();
                if (data) {
                    result.listId = data.listName;
                    result.rootFolder = data.rootFolder;
                    result.listUrlDir = data.listUrlDir;
                    result.prevHref = data.ListData.PrevHref;
                    result.nextHref = data.ListData.NextHref;
                    result.lastRow = data.ListData.LastRow;
                    result.firstRow = data.ListData.FirstRow;
                    result.folderPermissions = data.ListData.FolderPermissions;
                    result.newWOPIDocumentEnabled = data.NewWOPIDocumentEnabled;

                    result.portalUrl = data.HttpRoot
                        .toLowerCase()
                        .replace(data.listUrlDir.substr(0, data.listUrlDir.lastIndexOf('/')).toLowerCase(), '');

                    if (data.ListData && data.ListData.Row) {
                        data.ListData.Row.forEach(row => {
                            const item = new FilesListItem();
                            item.name = row.LinkFilename;
                            item.url = row.FileRef;
                            item.count = +row.ItemChildCount + +row.FolderChildCount;
                            item.id = row.ID;
                            // tslint:disable-next-line:triple-equals
                            item.type = row.FSObjType == FSObjType.File ? FilesListItemType.File : FilesListItemType.Folder;
                            item.size = row.File_x0020_Size;
                            item.modified = Helper.checkDate(row.Modified);
                            item.uniqueId = row.UniqueId;
                            item.version = row._UIVersionString;
                            item.itemType = row.ProgId;
                            item.checked = new BehaviorSubject<boolean>(null);
                            item.permMask = row.PermMask;
                            if (row.Editor) {
                                item.editor = row.Editor;
                            }
                            if (row.SharedWithUsers) {
                                item.shared = row.SharedWithUsers;
                            }
                            result.items.push(item);
                        });
                    }

                } else {
                    return null;
                }

                return result;
            }));
    }

    webApiGetLastAsStream(webUrl: string,
        listUrl: string,
        folderUrl: string,
        offset: number = 0,
        rowLimit: number = 4,
        onlyFiles: boolean = false) {
        const scope = onlyFiles ? 'Scope=\"Recursive\"' : 'Scope=\"RecursiveAll\"';
        return this.getRenderListDataAsStream(webUrl, listUrl, folderUrl, 'Modified', 'Desc', offset, rowLimit, scope);
    }

    webApiCreateFolder(webUrl: string, folder: string, name: string): Observable<any> {
        return super.post<any>('createFolder', { webUrl: Helper.concatUrls(this.helper.getSiteHostUrl(), webUrl), folder, name });
    }

    webApiUpdateFolder(webUrl: string, listUrl: string, item: FilesListItem, name: string): Observable<any> {
        return super.post<any>('updateFolder', {
            webUrl,
            targetUrl: `/_api/web/GetList(@listUrl)/Items(${item.id})?@listUrl='${decodeURIComponent(listUrl)}'`,
            name
        });
    }

    webApiDeleteItem(url: string, item: FilesListItem) {
        let targetUrl = '';
        // tslint:disable-next-line:triple-equals
        if (item.type == FilesListItemType.Folder) {
            targetUrl = `/_api/web/getfolderbyid(@url)/recycle?@url='${decodeURIComponent(item.uniqueId)}'`;
        } else {
            targetUrl = `/_api/Web/GetFileByServerRelativeUrl('${decodeURIComponent(item.url)}')/recycle()`;
        }
        return super.post<any>('deleteItem', { webUrl: url, fileUrl: targetUrl }, null, 'text').pipe(map(result => {
            return result;
        }));
    }

    webApiShareItems(url: string, shareBody: ListItemShareBody): Observable<any> {
        return super.post<any>('shareItems', { webUrl: url, shareBody });
    }

    webApiGetSharedWithMe(webUrl: string, sortField: string = 'Modified', asc: boolean = false, firstRow = 0, limit = 20) {
        const url = `getsharedwithmedocs?webUrl=${webUrl}&SortFieldName=%27${sortField}%27&IsAscending=${asc}&limit=${limit}`;
        return super.get<any>(url).pipe(map(response => {
            let data: any = null;

            try {
                data = JSON.parse(response);
            } catch (e) {
                console.error(e);
            }

            const result = new FilesList();
            if (data) {
                result.listId = data.listName;
                // result.rootFolder = data.rootFolder;
                if (data.value) {
                    data.value.forEach(row => {
                        const item = new FilesListItemShared();
                        item.name = row.FileLeafRef;
                        item.url = row.FileRef;
                        item.id = row.UniqueId;
                        item.type = !row.IsContainer ? FilesListItemType.File : FilesListItemType.Folder;
                        item.modified = row.Modified;
                        item.listId = row.ListId;
                        item.uniqueId = row.UniqueId;
                        item.itemType = row.ProgId;
                        item.checked = new BehaviorSubject<boolean>(null);
                        if (row.Editors) {
                            row.Editors.forEach(editor => {
                                item.editor.push({ title: editor.Title });
                            });
                        }
                        result.items.push(item);
                    });
                }

            } else {
                return null;
            }

            return result;
        }));
    }

    webApiGetSharedWithPerson(
        webUrl: string,
        sortField: string = 'Modified',
        asc: boolean = false,
        firstRow = 0,
        limit = 20): Observable<FilesList> {

        const url = `getsharedwithpersondocs?webUrl=${webUrl}&SortFieldName=%27${sortField}%27&IsAscending=${asc}&limit=${limit}`;

        return super.post<any>(url, null)
            .pipe(map(response => {
                let data: any = null;

                try {
                    data = JSON.parse(response);
                } catch (e) {
                    console.error(e);
                }

                const result = new FilesList();
                if (data) {
                    result.listId = data.listName;
                    // result.rootFolder = data.rootFolder;
                    if (data.value) {
                        data.value.forEach(row => {
                            const item = new FilesListItemShared();
                            item.name = row.FileLeafRef;
                            item.url = row.FileRef;
                            item.id = row.UniqueId;
                            item.uniqueId = row.UniqueId;
                            item.type = !row.IsContainer ? FilesListItemType.File : FilesListItemType.Folder;
                            item.modified = row.Modified;
                            item.listId = row.ListId;
                            item.checked = new BehaviorSubject<boolean>(null);
                            item.itemType = row.ProgId;
                            if (row.Editors) {
                                row.Editors.forEach(editor => {
                                    item.editor.push({ title: editor.Title });
                                });
                            }
                            result.items.push(item);
                        });
                    }

                } else {
                    return null;
                }

                return result;
            }));
    }

    webApiGetTree(webUrl: string,
        listUrl: string,
        folderUrl: string,
        foldersOnly: boolean = false,
        text: string = '',
        offset: number = 0,
        rowLimit: number = 200
    ): Observable<FilesList> {
        return this.getRenderListDataAsStream(
            webUrl, listUrl, folderUrl, 'LinkFilename', 'Asc', offset, rowLimit, null, foldersOnly, null, text);
    }

    webApiGetFolders(webUrl: string, folderUrl: string): Observable<FolderItem[]> {
        folderUrl = encodeURIComponent(decodeURIComponent(folderUrl));
        // tslint:disable-next-line:max-line-length
        return this.get<any>(Helper.concatUrls(webUrl, `_api/web/getfolderbyserverrelativeurl('${folderUrl}')/folders?$expand=ParentFolder`))
            .pipe(map(response => {
                const data = response;
                if (data.value) {
                    data.value = data.value.filter(folder => {
                        return folder.ServerRelativeUrl.toLowerCase().indexOf(`/${this.userRootFolder.toLowerCase()}/forms`) === -1
                            && folder.ServerRelativeUrl.toLowerCase().indexOf(`/${this.groupRootFolder.toLowerCase()}/forms`) === -1;
                    });
                }
                return data.value;
            }));
    }

    webApiGetFolder(webUrl: string, folderUrl: string): Observable<FolderItem> {
        return this.get<FolderItem>(`getFolder?webUrl=${webUrl}&folderUrl=${encodeURIComponent(decodeURIComponent(folderUrl))}`);
    }

    webApiMoveFolder(webUrl: string, oldUrl: string, newUrl: string): Observable<any> {
        return this.webApiMoveCopyFunc(webUrl, oldUrl, newUrl, 'MoveFolder');
    }

    webApiCopyFolder(webUrl: string, oldUrl: string, newUrl: string): Observable<any> {
        return this.webApiMoveCopyFunc(webUrl, oldUrl, newUrl, 'copyFolder');
    }

    webApiMoveFile(webUrl: string, oldUrl: string, newUrl: string): Observable<any> {
        return this.webApiMoveCopyFunc(webUrl, oldUrl, newUrl, 'moveFile');
    }

    webApiCopyFile(webUrl: string, oldUrl: string, newUrl: string): Observable<any> {
        return this.webApiMoveCopyFunc(webUrl, oldUrl, newUrl, 'copyFile');
    }

    private webApiMoveCopyFunc(
        webUrl: string,
        oldUrl: string,
        newUrl: string,
        methodName: string): Observable<any> {

        oldUrl = encodeURIComponent(decodeURIComponent(oldUrl));
        newUrl = encodeURIComponent(decodeURIComponent(newUrl));

        return this.post<any>('moveCopy', {
            webUrl,
            oldUrl,
            newUrl,
            methodName
        });
    }

    webApiGetFile(webUrl: string, fileUrl: string, expand: string = null): Observable<FilesListItem> {
        fileUrl = encodeURIComponent(decodeURIComponent(fileUrl));
        const expandParam = expand ? `?$expand=${expand}` : '';
        const methodUrl = `_api/web/GetFileByServerRelativeUrl('${fileUrl}')${expandParam}`;
        const requestUrl = webUrl ? Helper.concatUrls(webUrl, methodUrl) : methodUrl;

        return this.get<IRootOdataResponseModel>(`getFile?url=${requestUrl}`).pipe(map(res => {
            if (res) {
                try {
                    return FilesListItem.fromSpFile(res);
                } catch (e) {

                }
            }
            return null;
        }));
    }

    getFileSize(guid: string): Observable<string> {
        return this.get<string>(`${environment.siteUrl}/api/documentFile/${guid}/fileSize`);
    }

    webApiGetHistory(webUrl: string, fileUrl: string): Observable<FileVersion[]> {
        fileUrl = encodeURIComponent(decodeURIComponent(fileUrl));
        return this.get<any>(`getHistory?webUrl=${webUrl}&fileUrl=${fileUrl}`)
            .pipe(map(res => {
                return JSON.parse(res).value;
            }));
    }

    webApiFileVersionDeleteAll(webUrl: string, fileUrl: string) {
        fileUrl = encodeURIComponent(decodeURIComponent(fileUrl));
        return this.post<any>('deleteVersionAll', { webUrl, fileUrl }).pipe(map(res => {
            return JSON.parse(res);
        }));
    }

    webApiFileVersionDelete(webUrl: string, fileUrl: string, version: number) {
        fileUrl = encodeURIComponent(decodeURIComponent(fileUrl));
        return this.post<any>('deleteVersion', { webUrl, fileUrl, version }).pipe(map(res => {
            return JSON.parse(res);
        }));
    }

    webApiFileVersionDeleteByLabel(webUrl: string, fileUrl: string, label: string) {
        fileUrl = encodeURIComponent(decodeURIComponent(fileUrl));
        return this.post<any>('deleteVersionByLabel', { webUrl, fileUrl, label }).pipe(map(res => {
            return JSON.parse(res);
        }));
    }

    webApiFileVersionGetById(webUrl: string, fileUrl: string, id: number): Observable<FileVersion> {
        fileUrl = encodeURIComponent(decodeURIComponent(fileUrl));
        return this.get<any>(`getVersionById?webUrl=${webUrl}&fileUrl=${fileUrl}&id=${id}`)
            .pipe(map(res => {
                return JSON.parse(res).value;
            }));
    }

    webApiFileVersionRestoreByLabel(webUrl: string, fileUrl: string, label: string) {
        fileUrl = encodeURIComponent(decodeURIComponent(fileUrl));
        return this.post<any>('restoreVersionByLabel', { webUrl, fileUrl, label }).pipe(map(res => {
            return JSON.parse(res);
        }));
    }

    uploadFileV2(formData, ownerId: number, folderId: number): Observable<IDatafileModel[]> {
        return super.post(`uploadFile/${ownerId}?folderId=${folderId}`, formData);
    }

    uploadFeedAttachedFiles(formData, ownerId: number): Observable<IDatafileModel[]> {
        return super.post(`uploadPostAttachment/${ownerId}`, formData);
    }

    uploadCommentAttachedFiles(formData, ownerId: number): Observable<IDatafileModel[]> {
        return super.post(`uploadCommentAttachment/${ownerId}`, formData);
    }

    uploadVacancyAttachment(formData, ownerId: number): Observable<IDatafileModel[]> {
        return super.post(`uploadVacancyAttachment/${ownerId}`, formData);
    }

    uploadIdeaAttachment(formData, ownerId: number): Observable<IDatafileModel[]> {
        return super.post(`uploadIdeaAttachment/${ownerId}`, formData);
    }

    webApiGetRecent(
        webUrl: string,
        startRow: number,
        rowLimit: number,
        sortBy: string = 'LastModifiedTime',
        sortAsc: boolean = false): Observable<FilesList> {
        const properties = [
            'Title',
            'Filename',
            'EditorOWSUser',
            'Created',
            'LastModifiedTime',
            'IsContainer',
            'FolderChildCount',
            'HitHighlightedSummary',
            'ServerRedirectedURL',
            'OriginalPath'];

        return this.get<any>(`getRecent?webUrl=${webUrl}&startRow=${startRow}&rowLimit=${rowLimit}&sortBy=${sortBy}&sortAsc=${sortAsc}`)
            .pipe(map(res => {
                const data = JSON.parse(res);
                const result = new FilesList();
                if (data && data.PrimaryQueryResult.RelevantResults.Table.Rows) {
                    result.items = new Array<FilesListItem>();
                    result.totalItems = +data.PrimaryQueryResult.RelevantResults.TotalRows;
                    data.PrimaryQueryResult.RelevantResults.Table.Rows.forEach(row => {
                        const item = new FilesListItem();
                        row.Cells.forEach(cell => {
                            switch (cell.Key) {
                                case 'Title':
                                    item.title = cell.Value;
                                    break;
                                case 'Filename':
                                    item.name = cell.Value;
                                    break;
                                case 'EditorOWSUSER':
                                    if (cell.Value) {
                                        const user = cell.Value.split('|');
                                        item.editor = [{
                                            email: user[0],
                                            title: user[1]
                                        }];
                                    }
                                    break;
                                case 'LastModifiedTime':
                                    item.modified = cell.Value;
                                    break;
                                case 'Created':
                                    item.created = cell.Value;
                                    break;
                                case 'FolderChildCount':
                                    item.count = cell.Value;
                                    break;
                                case 'OriginalPath':
                                    item.url = cell.Value;
                                    break;
                                case 'IsContainer':
                                    item.type = cell.Value === 'true' ? FilesListItemType.Folder : FilesListItemType.File;
                                    break;
                                case 'UniqueID':
                                    item.uniqueId = cell.Value;
                                    break;
                                case 'Size':
                                    item.size = cell.Value;
                                    break;
                            }
                        });

                        item.checked = new BehaviorSubject<boolean>(null);
                        result.items.push(item);
                    });

                    return result;
                }
            }));
    }

    webApiGetNewFileUrl(
        webUrl: string,
        listUrl: string,
        folderUrl: string,
        type: DocumentTemplateType,
        fileName: string): Observable<any> {

        if (fileName === null || fileName === undefined) {
            fileName = '';
        } else {
            fileName = encodeURI(decodeURIComponent(fileName));
        }

        const tempListUrl = decodeURIComponent(listUrl);

        if (folderUrl === null || folderUrl === undefined) {
            folderUrl = '';
        } else {
            let tempFolderUrl = decodeURIComponent(folderUrl);
            if (tempFolderUrl && tempFolderUrl.indexOf(tempListUrl) !== -1) {
                tempFolderUrl = tempFolderUrl.substr(tempListUrl.length + 1);
                if (tempFolderUrl.startsWith('//')) {
                    tempFolderUrl = tempFolderUrl.substr(1);
                }
            }
            folderUrl = encodeURIComponent(tempFolderUrl);
        }

        listUrl = encodeURIComponent(tempListUrl);

        return this.post<any>(`getNewFileUrl`, { webUrl, listUrl, folderUrl, type, fileName }).pipe(
            map(res => {
                if (res) {
                    res = JSON.parse(res);

                    if (res.value) {
                        res.value = res.value.replace('action=edit', 'action=editnew');
                    }
                }
                return res;
            }));

    }

    // todo: fix it
    webApiGetFilePrefixUrl(webUrl: string): Observable<any> {
        let url = '';

        if (webUrl) {
            webUrl = webUrl.toLowerCase();
            if (webUrl.endsWith('/')) {
                webUrl = webUrl.substr(0, webUrl.length - 1);
            }
            if (webUrl.startsWith('http')) {
                url = webUrl;
            } else {
                url = Helper.concatUrls(this.serviceUrl, webUrl);
            }
        }

        return this.get(`getPrefixUrl?webUrl=${webUrl}`).pipe(switchMap((webFullUrl: string) => {
            return new Observable(observer => {
                observer.next(webFullUrl.toLowerCase().replace(webUrl, ''));
                observer.complete();
            });
        }));
    }
    /* Web API methods END */

    getFile(absoluteUrl: string): Observable<any> {
        return this.get(
            absoluteUrl,
            null,
            null,
            'blob'
        );
    }
}
