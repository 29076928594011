import { Component, OnInit, Inject, OnDestroy, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FilesService } from '@app/files/services/files.service';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { take, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { Helper } from '@app/core/helpers/helper';
import { AlertsService } from '@app/shared/services/alerts.service';

export interface ICreateFolderComponentData {
  onOk: any;
  headerText: string;
  placeholder: string;
  error: string;
  icon: string;
  // ownerId: BehaviorSubject<number>;
  // folderId: BehaviorSubject<number>;
  onFolderCreated: EventEmitter<any>;
}

/**
 * Компонент создания папки
 *
 * @export
 * @class CreateFolderComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-create-folder',
  templateUrl: './create-folder.component.html'
})
export class CreateFolderComponent extends BaseComponent implements OnInit, OnDestroy {

  folderName: string;

  onOk: EventEmitter<any> = new EventEmitter();

  constructor(
    private alertService: AlertsService,
    public dialogRef: MatDialogRef<CreateFolderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ICreateFolderComponentData,
    private filesService: FilesService,
    public helper: HelperService) { super(helper); }

  ngOnInit() {
    if (!this.data.headerText) {
      this.data.headerText = 'Новая папка';
    }
    if (!this.data.placeholder) {
      this.data.placeholder = 'Введите название папки';
    }
    if (!this.data.icon) {
      this.data.icon = '_folder';
    }
  }

  ok() {
    if (this.data.onOk) {
      if (this.data.onOk instanceof EventEmitter) {
        this.data.onOk.emit(this.folderName);
      } else {
        this.data.onOk(this.folderName);
      }
    } else {
      this.createFolder();
    }
  }


  /**
   * Создание папки в определённом списке текущего узла
   *
   * @memberof CreateFolderComponent
   */
  createFolder() {
    const ownerId = this.filesService.ownerId$.getValue();
    const folderId = this.filesService.folderId$.getValue();

    if (ownerId) {
      this.loading = true;
      if (this.folderName) {
        const target = folderId ? folderId : null;
        console.log(`creating, name: '${this.folderName}', target: '${target}`);
        this.filesService.createFolder(this.folderName, target, ownerId).subscribe(res => {
          this.loading = false;
          if (res) {
            if (this.data.onFolderCreated) {
              this.data.onFolderCreated.emit(res);
            }
          }
          this.alertService.riseSuccess(`Папка '${decodeURIComponent(this.folderName)}' была успешно создана`);
          this.dialogRef.close();
          this.folderName = '';
        }, error => {
          this.loading = false;
          this.folderName = '';
          this.error = `Возникла ошибка при создании папки${Helper.getErrorMsg(error)}`;
          this.alertService.riseError(this.error);
        });
      } else {
        this.loading = false;
        this.error = 'Необходимо ввести название папки';
      }
    } else {
      this.error = 'Список не существует';
    }
  }
}
