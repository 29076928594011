import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { ThanksService } from '@app/thanks/services/thanks.service';
import { takeUntil, finalize } from 'rxjs/operators';
import { User } from '@app/profile/model/user.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NewThankComponent } from '../new-thank/new-thank.component';
import { AlertsService } from '@app/shared/services/alerts.service';
import { IThank } from '@app/thanks/model/thank';
import { UsersService } from '@app/profile/services/users.service';

@Component({
  selector: 'app-thanks-widget',
  templateUrl: './thanks-widget.component.html',
  styleUrls: ['./thanks-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThanksWidgetComponent extends BaseComponent implements OnInit, OnDestroy {

  items: IThank[];
  currentUser: User;

  protected newThankDialog: MatDialogRef<NewThankComponent>;

  constructor(
    public dialog: MatDialog,
    public usersService: UsersService,
    protected thanksService: ThanksService,
    protected alertsService: AlertsService,
    protected cdr: ChangeDetectorRef,
    public helper: HelperService) {
    super(helper);
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;
    });
  }

  ngOnInit() {
    this.getAll();
  }

  thank() {
    this.newThankDialog = this.dialog.open(NewThankComponent, {
      data: {
        onOk: () => {
          this.getAll();
          this.newThankDialog.close();
        }
      }
    });
  }

  protected getAll() {
    this.thanksService.getAll(0, 10)
      .pipe(finalize(() => {
        this.loaded = true;
        this.cdr.detectChanges();
      }), takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.items = res;
      });
  }
}
