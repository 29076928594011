<div class="btn btn-dots tooltip" mat-button [matMenuTriggerFor]="menu"></div>
<mat-menu #menu="matMenu">
  <div class="icon-menu">
    <a mat-menu-item [attr.href]="filesService.webApiGetFileUrl(attachment.attachment, attachment.attachment?.guid)" target="_blank" class="icon-menu__item"
      *ngIf="filesService.webApiFileIsOffice(attachment.attachment) && !filesService.isPdf(attachment.attachment)">
      <span class="icon-menu__icon">
        <img [attr.src]="filesService.getOfficeFileIcon(attachment.attachment)" alt="" class="icon-menu__img">
      </span>
      <span>Открыть в {{getOfficeOpeningName(attachment.attachment)}} Online</span>
    </a>
    <a mat-menu-item class="icon-menu__item" *ngIf="filesService.webApiFileIsOffice(attachment.attachment) && !filesService.isPdf(attachment.attachment)"
      (click)="openInClientApp(attachment.attachment)">
      <span class="icon-menu__icon">
        <img [attr.src]="filesService.getOfficeFileIcon(attachment.attachment)" alt="" class="icon-menu__img">
      </span>
      <span>Открыть в {{getOfficeOpeningName(attachment.attachment)}}</span>
    </a>
    <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" (click)="copyToClipboard()">
      <span class="icon-menu__icon">
        <img src="{{assetsPrefix}}/assets/images/icons/menu/link.svg" alt="" class="icon-menu__img">
      </span>
      <span>Копировать ссылку</span>
    </a>
    <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" (click)="getItem()">
      <span class="icon-menu__icon">
        <img src="{{assetsPrefix}}/assets/images/icons/menu/download.svg" alt="" class="icon-menu__img">
      </span>
      <span>Скачать</span>
    </a>
  </div>
</mat-menu>