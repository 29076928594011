<div class="cos">
  <div class="cos__body">
    <div #mediaList class="grid" (drop)="drop($event)" (dragover)="allowDrop($event)">
      <div class="grid__header _space-between">
        <span class="header-text _low" *ngIf="mediaFilesCount">
          <span class="header-text__text">{{title}}</span>&nbsp;
          <span class="header-text__count">{{mediaFilesCount}}</span>
        </span>
        <a *ngIf="loaded && albumId && allowEdit && mediaFiles?.length" class="btn btn-plus pull-right"
          (click)="fileUpload.click()">+</a>
      </div>

      <!-- Empty list -->
      <div class="grid__content" *ngIf="loaded && !mediaFiles?.length">
        <div class="group-empty">

          <ng-container *ngIf="albumId && allowEdit">
            <button type="button" class="btn btn-plus" *ngIf="albumId && allowEdit"
              (click)="fileUpload.click()"></button>
            <div class="group-empty__text" *ngIf="albumId && allowEdit">Добавьте фотографии и видео в альбом</div>
          </ng-container>

          <ng-container *ngIf="albumId && !allowEdit">
            <img *ngIf="albumId && !allowEdit || !albumId" src="{{assetsPrefix}}/assets/images/icons/no_data.svg"
              alt="" style="width:auto" class="group-empty__img">
            <div class="group-empty__text">В альбоме нет фотографий или видео</div>
          </ng-container>
          <div class="group-empty__text" *ngIf="!albumId">В галерее нет фотографий или видео</div>

        </div>
      </div>
      <!-- Empty list END-->

      <!-- upload input-->
      <ng-container>
        <input type="file" (change)="onUpload($event)" accept=".jpeg,.jpg,.png,.bmp,.gif,.mp4,.avi,.wmv,.webm,.ogg"
          multiple #fileUpload style="display: none" />

        <div class="modal _loading" *ngIf="galleryService.mediaFilesUploading | async" style="z-index: 20001">
          <div class="modal__container">
            <mat-spinner [diameter]="80"></mat-spinner>
          </div>
        </div>
      </ng-container>
      <!-- upload input END-->

      <div class="grid__content">
        <div class="grid__item" *ngFor="let m of mediaFiles; let odd = odd" (contextmenu)="showContextMenu($event, m, mediaMenu)">
          <!-- trackBy:m?.id -->
          <!-- _checked если галочка-->
          <div [style.background-image]="galleryService.getMediaFilePreview(m)" class="gallery-preview _file"
            [ngClass]="{ '_checked' : m.selected }" (click)="openMediaFile($event,m)">
<!--            <video *ngIf="m.type===mediaFileType.video"-->
<!--                   class="gallery-preview__video"-->
<!--                   width="640" height="480" preload="metadata">-->
<!--              <source [src]="galleryService.getMediaFileUrl(m)" type="video/mp4">-->
<!--            </video>-->
            <div class="gallery-preview__wrap">
              <label class="checkbox" (click)="mediaFileChecked($event,m)">
                <input type="checkbox" class="checkbox__input" (change)="mediaFileCheckChanged($event,m)">
                <span class="checkbox__icon"></span>
              </label>
              <div class="gallery-preview__menu" (click)="stopPropagation($event)">
                <app-media-item-menu #mediaMenu [selectedItems]="selectedItems" [item]="m" [config]="getMenuConfig(m)">
                </app-media-item-menu>
              </div>
              <div class="gallery-preview__controls">
                <div class="gallery-preview__title" *ngIf="m.type!==mediaFileType.video">{{m.title}}</div>
                <div class="gallery-preview__actions">
                  <button type="button" class="btn btn-social _like-white">{{m.likesCount}}</button>
                  <button type="button" class="btn btn-social _comment-white" [ngClass]="{'transparent-text': m.commentsCount === 0}">{{m.commentsCount}}</button>
                  <a href="javascript:;" class="link" (click)="replyMediaFile($event,m)">Ответить</a>
                  <button *ngIf="m.isAlbumPreview" type="button" class="btn btn-social _pic-stack pull-right"></button>
                </div>
              </div>
              <div class="gallery-preview__play" *ngIf="m.type===mediaFileType.video">
                <!--Длительность видео вставить здесь-->
                <!--<span>14:15</span>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <video controls width="400" height="300">
    <source src="https://localhost:44399/api/documentFile/BA44BE1C-7DAF-4F00-84C9-7F3E4E1AA703" type="video/mp4">
  </video>
  <img src='https://localhost:44399/api/documentFile/DBA83A7B-B0C1-4177-B91A-8E7F42EA9D2C' />
  <img src='https://localhost:44399/api/documentFile/9cfcdbc1-06b5-4729-a46d-191ddcf13eef' /> -->
</div>
