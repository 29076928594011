import {Component, OnInit, OnDestroy} from '@angular/core';
import {IdeaWidgetComponent} from '@app/ideas/components/idea-widget/idea-widget.component';
import {GroupsService} from '@app/groups/services/groups.service';
import {IdeasService} from '@app/ideas/services/ideas.service';
import {HelperService} from '@app/core/services/helper.service';
import {Router} from '@angular/router';
import {GroupInfo} from '@app/groups/model/group-info.model';
import {takeUntil, take} from 'rxjs/operators';
import {IIdea} from '@app/ideas/model/idea';
import {IIdeaMenuConfig} from '@app/ideas/components/idea-menu/idea-menu.component';
import {UsersService} from '@app/profile/services/users.service';
import {User} from '@app/profile/model/user.model';
import {GroupType} from '@app/groups/model/group-type.model';

/**
 * Компонент виджета идей в группе
 *
 * @export
 * @class GroupIdeasWidgetComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-group-ideas-widget',
  templateUrl: './group-ideas-widget.component.html',
  styleUrls: ['./group-ideas-widget.component.scss'],
})
export class GroupIdeasWidgetComponent extends IdeaWidgetComponent implements OnInit, OnDestroy {
  /**
   * Группа, в которой находится виджет
   *
   * @type {GroupInfo}
   * @memberof GroupSurveysWidgetComponent
   */
  group: GroupInfo;
  private currentUser: User;

  constructor(
    private usersService: UsersService,
    protected groupsService: GroupsService,
    public ideasService: IdeasService,
    protected router: Router,
    public helper: HelperService,
  ) {
    super(router, helper);
  }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;

      this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(
        group => {
          if (group) {
            this.group = group;

            this.link = `/group/${this.group.id}/ideas`;

            this.showWidget = this.groupsService.canReadGroup(this.group, currentUser);

            if (this.showWidget) {
              this.showAddButton =
                this.groupsService.isParticipant(this.group, currentUser) || this.group.type === GroupType.Business;
              this.addButtonLink = this.link + `/new`;
            }

            this.getItems(true);

            this.ideasService.ideaAdded$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
              this.getItems(true);
            });

            this.ideasService.ideaChanged$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
              this.getItems(true);
            });

            this.ideasService.ideaDeleted$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
              this.getItems(true);
            });
          } else {
            this.group = null;
          }
          this.loaded = true;
        },
        () => {
          this.loaded = true;
        },
      );
    });
  }

  getItems(force: boolean) {
    if (!this.loaded || force) {
      this.ideasService
        .getProfileIdeas(this.group.id, 0, 3)
        .pipe(take(1))
        .subscribe(
          res => {
            this.ideas = res && res.length ? res : null;
            this.loaded = true;
          },
          () => {
            this.loaded = true;
          },
        );
    }
  }

  getIdeaConfig(idea: IIdea): IIdeaMenuConfig {
    return this.ideasService.getIdeaConfig(idea, this.groupsService.isAdmin(this.group, this.currentUser), this.currentUser);
  }
}
