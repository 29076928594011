<div class="pull-right btn btn-dots tooltip" mat-button [matMenuTriggerFor]="menu" *ngIf="isVisible()"></div>
<mat-menu #menu="matMenu">
  <div class="icon-menu">
    <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="isAllowEdit()" (click)="edit()">
      <span class="icon-menu__icon">
        <img src="{{assetsPrefix}}/assets/images/icons/menu/edit.svg" alt="" class="icon-menu__img">
      </span>
      <span>Редактировать</span>
    </a>
    <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" (click)="removeComment()">
      <span class="icon-menu__icon">
        <img src="{{assetsPrefix}}/assets/images/icons/menu/remove.svg" alt="" class="icon-menu__img"></span>
      <span>Удалить</span>
    </a>
  </div>
</mat-menu>