import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscribeService } from '@app/subscribe/services/subscribe.service';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ColleaguesListComponent } from '@app/subscribe/components/colleagues-list/colleagues-list.component';
import { ColleaguesWidgetComponent } from '@app/subscribe/components/colleagues-widget/colleagues-widget.component';
import { ColleaguesWidgetSmallComponent } from './components/colleagues-widget-small/colleagues-widget-small.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    SharedModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    ColleaguesListComponent,
    ColleaguesWidgetComponent,
    ColleaguesWidgetSmallComponent
  ],
  exports: [
    ColleaguesListComponent,
    ColleaguesWidgetComponent,
    ColleaguesWidgetSmallComponent
  ],
  providers: [
    SubscribeService
  ],
})
export class SubscribeModule { }
