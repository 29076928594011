import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IdeaNewComponent } from './components/forms/idea-new/idea-new.component';
import { IdeaViewComponent } from './components/forms/idea-view/idea-view.component';
import { IdeaEditComponent } from './components/forms/idea-edit/idea-edit.component';
import { IdeaListComponent } from './components/idea-list/idea-list.component';
import { IdeaWidgetComponent } from './components/idea-widget/idea-widget.component';
import { IdeaMenuComponent } from './components/idea-menu/idea-menu.component';
import { CoreModule } from 'intry-package';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { AttachmentsModule } from '@app/attachments/file-attachments/attachments.module';
import { RouterModule } from '@angular/router';
import { IdeaFiltersComponent } from './components/idea-filters/idea-filters.component';
import { CommentsModule } from '@app/comments/comments.module';
import { LikesModule } from '@app/likes/likes.module';
import { IdeaStatusModalComponent } from './components/dialogs/idea-status-modal/idea-status-modal.component';
import { IdeaStatusHistoryModalComponent } from './components/dialogs/idea-status-history-modal/idea-status-history-modal.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    FormsModule,
    AttachmentsModule,
    LikesModule,
    CommentsModule,
    RouterModule,
  ],
  declarations: [
    IdeaNewComponent,
    IdeaViewComponent,
    IdeaEditComponent,
    IdeaListComponent,
    IdeaWidgetComponent,
    IdeaMenuComponent,
    IdeaFiltersComponent,
    IdeaStatusModalComponent,
    IdeaStatusHistoryModalComponent
  ],
  exports: [
    IdeaListComponent,
    IdeaMenuComponent,
    IdeaFiltersComponent,
    IdeaStatusModalComponent,
    IdeaStatusHistoryModalComponent
  ],
})
export class IdeasModule { }
