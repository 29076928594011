import { Component, OnInit, OnDestroy } from '@angular/core';
import { IdeasService } from '@app/ideas/services/ideas.service';
import { takeUntil, take, finalize } from 'rxjs/operators';
import { IIdeaStatus, IIdeaStatusChanges, IUpdateIdeaStatus } from '@app/ideas/model/idea';
import { MatDialog } from '@angular/material/dialog';
import { ModalResultComponent } from '@app/shared/components/modals/modal-result/modal-result.component';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { AlertsService } from '@app/shared/services/alerts.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';

/**
 * Управление списком статусов
 *
 * @export
 * @class IdeaStatusListComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-idea-status-list',
  templateUrl: './idea-status-list.component.html',
  styleUrls: ['./idea-status-list.component.scss']
})
export class IdeaStatusListComponent extends BaseComponent implements OnInit, OnDestroy {

  loaded: boolean;

  statuses: IUpdateIdeaStatus[];
  originalStatuses: IIdeaStatus[];
  changes: IIdeaStatusChanges = {
    added: [],
    updated: [],
    hidden: [],
    visible: [],
    deleted: []
  };

  constructor(
    public ideasService: IdeasService,
    private breadcrumbsService: BreadcrumbsService,
    private alertsService: AlertsService,
    private dialog: MatDialog,
    public helper: HelperService,
  ) { super(helper); }

  ngOnInit() {
    this.breadcrumbsService.breadcrumbs.next([
      { title: 'Статусы идей', routerUrl: `/admin/idea-statuses` }
    ]);

    this.getStatuses();
  }

  onFocus(e: any, status: IUpdateIdeaStatus) {
    status.focused = true;
  }

  onBlur(e: any, status: IUpdateIdeaStatus) {
    status.focused = false;
    if (status.id) {
      const originalStatus = this.originalStatuses.find(s => s.id === status.id);
      if (originalStatus.title !== status.title
        || originalStatus.commentRequired !== status.commentRequired
        || originalStatus.dateEndRequired !== status.dateEndRequired) {
        const updated = this.changes.updated.find(s => s.id === status.id);
        if (!updated) {
          this.changes.updated.push(status);
        } else {
          updated.title = status.title;
          updated.commentRequired = status.commentRequired;
          updated.dateEndRequired = status.dateEndRequired;
          updated.order = status.order;
        }
      }
    } else {
      const added = this.changes.added.find(s => s.title === status.title);
      if (added) {
        added.title = status.title;
        added.commentRequired = status.commentRequired;
        added.dateEndRequired = status.dateEndRequired;
      } else {
        this.changes.added.push(status);
      }
    }
    console.log(this.changes);
  }

  showStatusButtons(status: IIdeaStatus) {
    if (status.id) {
      if (
        status.title === 'Новая'
        || status.title === 'На рассмотрении'
        || status.title === 'В работе'
        || status.title === 'Отклонена'
        || status.title === 'Реализована'
        || status.title === 'Снята с реализации'
      ) {
        return false;
      }
      return true;
    }
    return true;
  }

  getToggleStatusText(status: IIdeaStatus) {
    if (status.deleted) {
      return 'Отобразить статус';
    } else {
      return 'Скрыть статус';
    }
  }

  isHidden(status: IIdeaStatus) {
    return status.deleted ? true : false;
  }

  toggle(e: any, status: IIdeaStatus) {
    if (status.id) {
      if (status.deleted) {
        // отмечаем, что нужно отобразить
        this.changes.visible.push(status.id);
        // снимаем дату удаления
        status.deleted = null;
        // убираем из статусов на скрытие, отправляемых на сервер
        this.changes.hidden = this.changes.hidden.filter(s => s !== status.id);
      } else {
        // отмечаем, что нужно скрыть
        this.changes.hidden.push(status.id);
        // проставляем дату удаления
        status.deleted = new Date();
        // убираем из статусов на отображение, отправляемых на сервер
        this.changes.visible = this.changes.visible.filter(s => s !== status.id);
      }
    }
  }

  remove(e: any, status: IUpdateIdeaStatus, index: number) {
    if (status.id) {
      this.ideasService.checkIdeaStatusInUse(status.id).pipe(take(1), takeUntil(this.destroyed$))
        .subscribe(res => {
          // Если false - т.е. статус не используется, то добавляем его в удалённые
          if (!res) {
            this.changes.deleted.push(status.id);
            this.statuses = this.statuses.filter(s => s.id !== status.id);
          } else {
            // иначе сообщаем об ошибке
            this.dialog.open(ModalResultComponent, {
              data: {
                text: 'Статус используется и не может быть удалён',
                okText: 'Понятно',
                showOk: true
              }
            });
          }
        });
    } else {
      this.statuses.splice(index, 1);
    }
  }

  commentRequiredChanged(e, status: IUpdateIdeaStatus) {
    status.commentRequired = !status.commentRequired;
    this.onBlur(e, status);
  }

  dateEndRequiredChanged(e, status: IUpdateIdeaStatus) {
    status.dateEndRequired = !status.dateEndRequired;
    this.onBlur(e, status);
  }

  addStatus() {
    const index = this.statuses.length
      ? this.statuses.length + 1
      : 1;
    this.statuses.push({ id: 0, title: '', order: index });
  }

  cancel() {
    this.statuses = JSON.parse(JSON.stringify(this.originalStatuses));
    this.clearChanges();
  }

  submit() {
    if (this.hasAnyChanges()) {
      this.ideasService.editIdeaStatuses(this.changes)
        .pipe(take(1), takeUntil(this.destroyed$))
        .subscribe(res => {
          if (res) {
            this.alertsService.riseSuccess(`Список изменен`);
            this.clearChanges();
            this.getStatuses();
          } else {
            this.alertsService.riseError(`Не удалось сохранить список`);
          }
        }, error => {
          this.alertsService.riseError(`Не удалось сохранить список`);
          console.log(error);
        });
    }
  }

  hasAnyChanges(): boolean {
    return this.changes
      && (this.changes.added && !!this.changes.added.length
        || this.changes.updated && !!this.changes.updated.length
        || this.changes.hidden && !!this.changes.hidden.length
        || this.changes.visible && !!this.changes.visible.length
        || this.changes.deleted && !!this.changes.deleted.length);
  }

  isValid() {
    if (!this.changes) {
      return false;
    }
    const isValid = this.hasAnyChanges();
    if (this.changes.added && this.changes.added.length && this.changes.added.find(s => !s.title)) {
      return false;
    }
    if (this.changes.updated && this.changes.updated.length && this.changes.updated.find(s => !s.title)) {
      return false;
    }
    return isValid;
  }

  drop(event: CdkDragDrop<string[]>) {
    console.log(`drop`);
    moveItemInArray(this.statuses, event.previousIndex, event.currentIndex);
    this.ideasService.changeIdeaStatusOrder({
      id: this.statuses[event.currentIndex].id,
      orders: this.statuses.map(s => {
        return { id: s.id, order: this.statuses.indexOf(s) };
      })
    })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        if (res) {
          this.alertsService.riseSuccess(`Успешно внесены изменения`);
        } else {
          this.alertsService.riseError(`Произошла ошибка при внесении изменений`);
        }
      }, error => {
        this.alertsService.riseError(`Произошла ошибка при внесении изменений`);
      });
  }

  private clearChanges() {
    this.changes.added = [];
    this.changes.updated = [];
    this.changes.deleted = [];
    this.changes.hidden = [];
    this.changes.visible = [];
  }

  private getStatuses() {
    this.ideasService.getIdeaStatuses(true)
      .pipe(take(1), finalize(() => this.loaded = true), takeUntil(this.destroyed$))
      .subscribe(res => {
        this.statuses = res ? res : [];
        this.originalStatuses = JSON.parse(JSON.stringify(this.statuses));
      });
  }
}
