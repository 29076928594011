import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { VacancyService } from '@app/vacancy/services/vacancy.service';
import { HelperService } from '@app/core/services/helper.service';
import { ScrollableListComponent } from '@app/shared/components/scrollable-list/scrollable-list.component';
import { takeUntil, take, finalize } from 'rxjs/operators';
import { IVacancyCategory, IVacancyCategoryChanges } from '@app/vacancy/model/vacancy';
import { AlertsService } from '@app/shared/services/alerts.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from '@app/shared/services/settings.service';

/**
 * Список категорий вакансий
 *
 * @export
 * @class CategoriesListComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-categories-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.scss']
})
export class CategoriesListComponent extends ScrollableListComponent implements OnInit, OnDestroy {

  offset = 0;
  limit = 50;

  categories: IVacancyCategory[];
  originalCategories: IVacancyCategory[];
  changes: IVacancyCategoryChanges = {
    added: [],
    deleted: [],
    updated: []
  };

  hhCompanyIdOriginal: string;
  hhCompanyId: string;
  hhCompanyIdChanged: boolean;

  constructor(
    private vacancyService: VacancyService,
    private settingsService: SettingsService,
    private breadcrumbsService: BreadcrumbsService,
    private alertsService: AlertsService,
    protected dialog: MatDialog,
    public helper: HelperService
  ) { super(helper); }

  ngOnInit() {
    this.breadcrumbsService.breadcrumbs.next([
      { title: 'Категории вакансий', routerUrl: `/admin/vacancy-categories` }
    ]);

    this.vacancyService.getVacancyCategories(this.offset, this.limit)
      .pipe(finalize(() => this.loaded = true), take(1), takeUntil(this.destroyed$))
      .subscribe(res => {
        this.categories = res ? res : [];
        this.originalCategories = JSON.parse(JSON.stringify(this.categories));
      });

    this.settingsService.getValue('hhCompanyId')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        this.hhCompanyId = res;
        this.hhCompanyIdOriginal = res;
      });
  }

  onFocus(e: Event, category: IVacancyCategory) {
    category.focused = true;
  }

  onBlur(e: any, category: IVacancyCategory) {
    category.focused = false;
    if (category.id) {
      const originalCategory = this.originalCategories.find(s => s.id === category.id);
      if (originalCategory.title !== category.title) {
        const updated = this.changes.updated.find(s => s.id === category.id);
        if (!updated) {
          this.changes.updated.push(category);
        } else {
          updated.title = category.title;
          updated.order = category.order;
        }
      }
    } else {
      const added = this.changes.added.find(s => s.title === category.title);
      if (added) {
        added.title = category.title;
      } else {
        this.changes.added.push(category);
      }
    }
    console.log(this.changes);
  }

  onRemove(category) {
    this.dialog.open(ModalConfirmationComponent, {
      data: {
        text: `Вы действительно хотите удалить категорию из списка?`,
        okText: 'Да',
        onOk: () => { this.removeCategory(category); }
      }
    });
  }

  removeCategory(category) {
    if (!this.changes.deleted.find(s => s === category.id)) {
      this.changes.deleted.push(category.id);
    }
    this.categories = this.categories.filter(s => s.id !== category.id && s.title !== category.title);
    console.log(this.changes);
  }

  addCategory() {
    const index = this.categories.length
      ? this.categories.length + 1
      : 1;
    this.categories.push({ id: 0, title: '', order: index });
  }

  onCancel() {
    this.dialog.open(ModalConfirmationComponent, {
      data: {
        text: `Вы действительно отменить изменения?`,
        okText: 'Да',
        onOk: () => { this.cancel(); }
      }
    });
  }

  cancel() {
    this.categories = JSON.parse(JSON.stringify(this.originalCategories));
    this.clearChanges();
  }

  submit() {
    if (this.hasCategoryChanges()) {
      this.vacancyService.editVacancyCategories(this.changes)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(res => {
          if (res) {
            this.alertsService.riseSuccess(`Список изменен`);
            this.clearChanges();
          } else {
            this.alertsService.riseError(`Не удалось сохранить список`);
          }
        }, error => {
          this.alertsService.riseError(`Не удалось сохранить список`);
          console.log(error);
        });
    }
    if (this.hasHHCompanyIdChanges()) {
      this.settingsService.save('hhCompanyId', this.hhCompanyId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(res => {
          if (res) {
            this.alertsService.riseSuccess(`ID компании на hh.ru изменено`);
            this.hhCompanyIdOriginal = this.hhCompanyId;
          } else {
            this.alertsService.riseError(`Не удалось сохранить ID компании на hh.ru`);
          }
        }, error => {
          this.alertsService.riseError(`Не удалось сохранить ID компании на hh.ru`);
          console.log(error);
        });
    }
  }

  hasAnyChanges() {
    return this.hasCategoryChanges() || this.hasHHCompanyIdChanges();
  }

  hasCategoryChanges() {
    return this.changes
      && (this.changes.added && this.changes.added.length
        || this.changes.updated && this.changes.updated.length
        || this.changes.deleted && this.changes.deleted.length);
  }

  hasHHCompanyIdChanges() {
    return this.hhCompanyIdOriginal !== this.hhCompanyId;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
    this.vacancyService.changeVacancyCategoryOrder({
      id: this.categories[event.currentIndex].id,
      orders: this.categories.map(s => {
        return { id: s.id, order: this.categories.indexOf(s) };
      })
    })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        if (res) {
          this.alertsService.riseSuccess(`Успешно внесены изменения`);
        } else {
          this.alertsService.riseError(`Произошла ошибка при внесении изменений`);
        }
      }, error => {
        this.alertsService.riseError(`Произошла ошибка при внесении изменений`);
      });
  }

  private clearChanges() {
    this.changes.added = [];
    this.changes.updated = [];
    this.changes.deleted = [];
    this.hhCompanyId = this.hhCompanyIdOriginal;
  }
}
