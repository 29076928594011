import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileType } from '@app/core/model/profile-type.enum';
import { HelperService } from '@app/core/services/helper.service';
import { PostViewComponent } from '@app/feed/components/post-view/post-view.component';
import { FeedEventViewModel } from '@app/feed/model/feed-event-view-model.model';
import { PostProfileSelectorType } from '@app/feed/model/post-profile-selector-type';
import { FeedService } from '@app/feed/services/feed.service';
import { UsersService } from '@app/profile/services/users.service';

@Component({
  selector: 'app-profile-post-share',
  templateUrl: './profile-post-share.component.html',
  styleUrls: ['./profile-post-share.component.scss']
})
export class ProfilePostShareComponent extends PostViewComponent implements OnInit, OnDestroy {

  selectorType: PostProfileSelectorType = PostProfileSelectorType.all;

  constructor(
    protected usersService: UsersService,
    protected feedService: FeedService,
    protected route: ActivatedRoute,
    protected router: Router,
    public helper: HelperService
  ) { super(usersService, feedService, route, router, helper); }

  ngOnInit(): void {
    super.ngOnInit();
  }

  redirectToTarget(post: FeedEventViewModel) {
    if (post) {
      if (post.event.ownerType === ProfileType.User) {
        this.router.navigate(['profile', post.event.ownerId]);
      } else {
        this.router.navigate(['group', post.event.ownerId]);
      }
    }
  }
}
