<div class="cos">

  <!-- Header -->
  <app-group-small-header></app-group-small-header>
  <!-- Header END-->

  <div class="new-gallery" *ngIf="group">
    <div class="new-gallery__line">
      <div class="input-main new-gallery__title" *ngIf="allowEdit">
        <textarea rows="1" placeholder="Введите название альбома *" [(ngModel)]="albumName" maxlength="255"
          class="input-main__field" (keyup)="onKey($event)" [value]="album?.title" (focus)="albumNameFocused=true" (blur)="albumNameFocused=false"></textarea>
        <div class="form__note" *ngIf="albumNameFocused">{{albumName?.length || 0}} из 255</div>
      </div>
      <app-album-menu [item]="album" [config]="albumMenuConfig"
        (filesUploading)="onFilesUploading($event)" (vote)="onVote($event)" (deleted)="onDeleted($event)"></app-album-menu>
    </div>
    <div class="error-message" *ngIf="isAlreadyExists">Альбом с таким названием уже существует</div>

    <div class="input-main new-gallery__about" *ngIf="allowEdit">
      <textarea autosize rows="3" placeholder="Введите описание альбома (необязательно)"
        [(ngModel)]="albumDescription" class="input-main__field" (keyup)="onKeyDescription()"
        [value]="album?.description"></textarea>
    </div>
    <div *ngIf="!allowEdit" class="new-gallery__title _static">
      <span>{{album?.title}}</span>
    </div>
    <div *ngIf="!allowEdit" class="new-gallery__about _static" [ngClass]="showButton === true ? '_trimmed' : '_full'">
      <span #albumDescription>{{album?.description}}</span>
    </div>
    <span *ngIf="showButton" (click)="showFullText()" class="new-gallery__read-more">Читать полностью</span>

    <div class="new-gallery__actions" *ngIf="wasEdit">
      <button [ngClass]="{_inactive:updating}" type="button" class="btn btn-secondary group__cancel"
        (click)="cancel()">Отмена</button>
      <button [ngClass]="{_inactive:!isValid || updating}" type="button" class="btn btn-primary group__create"
        (click)="update()">Сохранить</button>
    </div>
  </div>

  <app-group-forbidden></app-group-forbidden>

</div>

<ng-template appDynamicContainer></ng-template>
