import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { User } from '@app/profile/model/user.model';
import { UsersService } from '@app/profile/services/users.service';
import { SubscribeService } from '@app/subscribe/services/subscribe.service';
import { Subject, BehaviorSubject } from 'rxjs';
import { UserLink } from '@app/subscribe/model/user-link';
import { SignalRService } from '@app/signalr/signalR.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddUsersComponent } from '@app/shared/components/add-users/add-users.component';
import { WidgetBaseComponent } from '@app/shared/components/widget-base.component';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';

@Component({
    selector: 'app-colleagues-widget',
    templateUrl: 'colleagues-widget.component.html'
})

export class ColleaguesWidgetComponent extends WidgetBaseComponent implements OnInit, OnDestroy {
    showAddButton = false;
    protected user: User;
    userLinks: Subject<UserLink[]> = new BehaviorSubject<UserLink[]>(null);
    userLinksCount: Subject<number> = new BehaviorSubject<number>(null);

    force = false;

    addUsersDialog: MatDialogRef<AddUsersComponent>;

    constructor(
        private usersService: UsersService,
        private subscribeService: SubscribeService,
        private signalRService: SignalRService,
        private ngZone: NgZone,
        private dialog: MatDialog,
        public helper: HelperService) { super(helper); }

    ngOnInit() {
        super.ngOnInit();

        this.subscribeService.unsubscribedFromUser$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(res => {
                this.getAllOnUser(true);
            });

        this.subscribeService.subscribedOnUser$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(res => {
                this.getAllOnUser(true);
            });

        this.usersService.user
            .pipe(takeUntil(this.destroyed$)).subscribe(user => {
                this.user = user;
                this.loaded = false;
                this.getAllOnUser(true);
            });
    }

    private getAllOnUser(force: boolean = false) {
        this.force = force;

        this.usersService.currentUser.subscribe(currentUser => {
            // check user
            if (this.user) {
                // simple link for current user
                this.link = `/profile/${this.user.id}/colleagues`;

                this.showWidget.pipe(takeUntil(this.destroyed$)).subscribe(res => {
                    if (res
                        && (!this.loaded || res !== this.showWidgetOld && this.loaded || this.force)
                        && !this.loading) {

                        this.showWidgetOld = res;
                        this.loading = true;

                        // show "+" when is current user
                        this.showAddButton = this.user.id === currentUser.id;

                        this.subscribeService.getSubscriptions(this.user.id, 0, 6)
                            .pipe(
                                finalize(() => {
                                    this.loaded = true;
                                    this.loading = false;
                                    this.force = false;
                                }),
                                takeUntil(this.destroyed$))
                            .subscribe(resColleagues => {
                                this.userLinks.next(resColleagues.items);
                                this.userLinksCount.next(resColleagues.count);
                                // указываем новый набор значений коллег текущего пользователя
                                if (this.user.id === currentUser.id) {
                                    this.subscribeService.currentUserColleaguesCount.next(resColleagues.count);
                                }
                            }, error => {
                                this.error = error;
                            });
                    } else if (res === false) {
                        this.loaded = true;
                    }
                });
            }
        });
    }

    showAddUser() {
        this.addUsersDialog = this.dialog.open(AddUsersComponent, {
            data: {
                placeholder: 'Введите имя, чтобы добавить коллегу',
                onOk: this.addUsers.bind(this)
            }
        });
    }

    addUsers(users: any) {
        if (users && users.length) {
            const usersIds = users.map(u => u.id);
            this.subscribeService.subscribeAll(usersIds)
                .pipe(takeUntil(this.destroyed$))
                .subscribe(res => {
                    if (res) {
                        this.subscribeService.subscribedOnUser$.next();
                        this.addUsersDialog.close();
                    }
                }, error => {
                    this.addUsersDialog.componentInstance.data.error = 'Не удалось подписаться на пользователя';
                });
        }
    }
}
