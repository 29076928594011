import { Component, OnInit, Input } from '@angular/core';
import { IPage, IPageSearch } from '@app/pages/model/page';
import { PagesService } from '@app/pages/services/pages.service';
import { Helper } from '@app/core/helpers/helper';
import { GroupsService } from '@app/groups/services/groups.service';

/**
 * Результаты поиска по страницам (вкладка "Ещё")
 *
 * @export
 * @class SearchOtherResultsComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-search-other-results',
  templateUrl: './search-other-results.component.html',
  styleUrls: ['./search-other-results.component.scss']
})
export class SearchOtherResultsComponent {

  @Input() pages: IPage[];
  @Input() highlightText: string;

  constructor(
    public pagesService: PagesService,
    public groupsService: GroupsService
  ) { }

  getGroupAvatar(page: IPageSearch) {
    return page && page.group && page.group.pictureUrl
      ? Helper.concatUrls((<any>window).signalRServer, page.group.pictureUrl)
      : null;
  }

}
