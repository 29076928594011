import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from '@app/profile/services/users.service';
import { SurveyWidgetComponent } from '@app/surveys/components/survey-widget/survey-widget.component';
import { SurveysService } from '@app/surveys/services/surveys.service';
import { Router } from '@angular/router';
import { HelperService } from '@app/core/services/helper.service';
import { take, takeUntil } from 'rxjs/operators';
import { User } from '@app/profile/model/user.model';
import { GroupsService } from '@app/groups/services/groups.service';

/**
 * Компонент виджета опросов пользователя
 *
 * @export
 * @class UserSurveysWidgetComponent
 * @extends {SurveyWidgetComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-user-surveys-widget',
  templateUrl: './user-surveys-widget.component.html',
  styleUrls: ['./user-surveys-widget.component.scss']
})
export class UserSurveysWidgetComponent extends SurveyWidgetComponent implements OnInit, OnDestroy {

  user: User;

  constructor(
    protected usersService: UsersService,
    public surveysService: SurveysService,
    public groupsService: GroupsService,
    protected router: Router,
    public helper: HelperService) { super(router, helper); }

  ngOnInit() {
    this.usersService.user.pipe(takeUntil(this.destroyed$)).subscribe(user => {
      if (user) {
        this.user = user;

        this.link = `/profile/${this.user.id}/surveys`;

        // Создавать опросы может пользователь, который админ хотя бы в одной группе
        this.groupsService.getUserGroupsCanCreateSurveys(user.id, 0, 1).pipe(take(1)).subscribe(res => {
          this.showAddButton = res && res.groups && res.groups.length ? true : false;

          if (this.showAddButton) {
            this.addButtonLink = this.link + `/new`;
          }

          this.getItems(true);
        });
      } else {
        this.user = null;
      }
    });
  }

  getItems(force: boolean) {
    if (!this.loaded || force) {
      this.surveysService.getProfileSurveys(this.user.id, 0, 3)
        .pipe(take(1)).subscribe(res => {
          this.surveys = res && res.length ? res : null;
          this.showWidget = this.surveys && this.surveys.length || this.showAddButton ? true : false;
          this.loaded = true;
        }, error => {
          this.loaded = true;
        });
    }
  }
}
