import {Component, OnInit, OnDestroy, ViewChild, forwardRef, NgZone} from '@angular/core';
import {UsersService} from '@app/profile/services/users.service';
import {GroupsService} from '@app/groups/services/groups.service';
import {User} from '@app/profile/model/user.model';
import {GroupInfo} from '@app/groups/model/group-info.model';
import {ActivatedRoute, Router} from '@angular/router';
import {AddUsersComponent} from '@app/shared/components/add-users/add-users.component';
import {Tab} from '@app/shared/components/tabs/tab.model';
import {SignalRService} from '@app/signalr/signalR.service';
import {TabbedListComponent} from '@app/shared/components/tabs/tabbed-list.component';
import {MatDialogRef, MatDialog} from '@angular/material/dialog';
import {GroupType} from '@app/groups/model/group-type.model';
import {IGroupUserLink} from '@app/groups/model/group-request.model';
import {IGroupUserRoleResult, IGroupIdUserRoleResult} from '@app/signalr/group.hub';
import {GroupRequestState} from '@app/groups/model/group-request-state.model';
import {GroupUserRole} from '@app/groups/model/group-user-role.model';
import {takeUntil, take, finalize} from 'rxjs/operators';
import {HelperService} from '@app/core/services/helper.service';
import {GroupAddUsersComponent} from '../group-add-users/group-add-users.component';
import {UserLink} from '@app/subscribe/model/user-link';
import {GroupUserLinkObjectType} from '../group-settings/group-settings.component';
import {IGroupUsersConfig} from '@app/groups/model/group-users-config';

export enum GroupUsersFragment {
  Members = 'members',
  Subscribers = 'subscribers',
  Admins = 'admins',
  Requests = 'requests',
}

/**
 * Компонент списка пользователей группы
 *
 * @export
 * @class GroupUsersListComponent
 * @extends {TabbedListComponent<IGroupUserLink>}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-group-users-list',
  templateUrl: 'group-users-list.component.html',
})
export class GroupUsersListComponent extends TabbedListComponent<IGroupUserLink> implements OnInit, OnDestroy {
  @ViewChild(forwardRef(() => AddUsersComponent)) public addUsersComponent: AddUsersComponent;

  currentUser: User;

  group: GroupInfo;

  protected maxItemHeight = 110;

  private fragment: string;

  role: GroupUserRole;

  showAddButton: boolean;

  showUsers: boolean;

  addUsersDialog: MatDialogRef<GroupAddUsersComponent>;

  protected groupUserSettings: IGroupUsersConfig;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private usersService: UsersService,
    private groupsService: GroupsService,
    private signalRService: SignalRService,
    protected ngZone: NgZone,
    private dialog: MatDialog,
    public helper: HelperService,
  ) {
    super(router, route, helper);
  }

  ngOnInit() {
    this.signalRService.groupHub.onAddUsers.subscribe(res => {
      // todo: перенести в компонент уведомлений
    });

    this.signalRService.groupHub.onApproveRequestForCurrent.subscribe((res: IGroupIdUserRoleResult) => {
      this.operateRequestsTab(res, true);
    });

    this.signalRService.groupHub.onDeclineRequestForCurrent.subscribe((res: IGroupIdUserRoleResult) => {
      this.operateRequestsTab(res, false);
    });

    this.signalRService.groupHub.onJoinForCurrent.subscribe((res: IGroupUserRoleResult) => {
      this.operateTab(res, true);
    });

    this.signalRService.groupHub.onSubscribeForCurrent.subscribe((res: IGroupUserRoleResult) => {
      this.operateTab(res, true);
    });

    this.signalRService.groupHub.onLeaveForCurrent.subscribe((res: IGroupUserRoleResult) => {
      this.operateTab(res, false);
    });

    this.signalRService.groupHub.onUnsubscribeForCurrent.subscribe((res: IGroupUserRoleResult) => {
      this.operateTab(res, false);
    });

    this.usersService.currentUser.subscribe(currentUser => {
      // get current user
      this.currentUser = currentUser;
      // get current group
      this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
        // set current group
        this.group = group;
        // check group
        if (group) {
          this.showAddButton = this.groupsService.isAdmin(this.group, currentUser);
          this.showUsers = this.groupsService.canReadGroup(this.group, currentUser);

          // check component visibility
          if (!this.showUsers) {
            this.loaded = true;
          }

          // init tabs
          this.tabs = {
            title: '',
            routerLink: `/group/${group.id}/users`,
            hideCounters: !this.showUsers,
            items: [
              {
                id: 3,
                title: 'Администраторы',
                emptyText: 'Нет администраторов',
                name: GroupUsersFragment.Admins,
                fragment: GroupUsersFragment.Admins,
                items: [],
                offset: 0,
                itemsCount: 0,
                method: this.getAdmins.bind(this),
              },
            ],
          };

          if (this.group.type !== GroupType.Business) {
            this.tabs.items.unshift({
              id: 2,
              title: 'Подписчики',
              emptyText: 'Нет подписчиков',
              name: GroupUsersFragment.Subscribers,
              fragment: GroupUsersFragment.Subscribers,
              items: [],
              offset: 0,
              itemsCount: 0,
              method: this.getSubscribers.bind(this),
            });

            this.tabs.items.unshift({
              id: 1,
              title: 'Участники',
              emptyText: 'Нет участников',
              name: GroupUsersFragment.Members,
              fragment: GroupUsersFragment.Members,
              items: [],
              offset: 0,
              itemsCount: 0,
              method: this.getMembers.bind(this),
              default: true,
            });
          } else {
            this.tabs.items[0].default = true;

            this.tabs.items.unshift({
              id: 2,
              title: 'Подписчики',
              emptyText: 'Нет подписчиков',
              name: GroupUsersFragment.Subscribers,
              fragment: GroupUsersFragment.Subscribers,
              items: [],
              offset: 0,
              itemsCount: 0,
              method: this.getBusinessGroupSubscribers.bind(this),
            });
          }

          // только для админов закрытой группы
          if (this.group.type === GroupType.Closed && this.groupsService.isAdmin(this.group, currentUser)) {
            this.tabs.items.push({
              id: 4,
              title: 'Запросы',
              emptyText: 'Нет запросов',
              fragment: GroupUsersFragment.Requests,
              items: [],
              offset: 0,
              itemsCount: 0,
              method: this.getRequests.bind(this),
            });
          }

          this.route.fragment.pipe(takeUntil(this.destroyed$)).subscribe(val => {
            // get hash
            if (val != null) {
              const keys = val.split('&');
              const hash = {};
              keys.forEach(key => {
                // tslint:disable-next-line:no-shadowed-variable
                const val = key.split('=');
                hash[val[0]] = val[1];
              });

              this.currentTab = null;

              this.tabs.items.forEach(tab => {
                if (tab.fragment && keys.find(k => k === tab.fragment)) {
                  this.currentTab = tab;
                }
              });

              if (!this.currentTab) {
                this.currentTab = this.tabs.items[0];
              }
            } else {
              this.currentTab = this.tabs.items[0];
            }

            switch (this.currentTab.id) {
              case 1:
                this.role = GroupUserRole.Member;
                break;
              case 2:
                this.role = GroupUserRole.Subscriber;
                break;
              case 3:
                this.role = GroupUserRole.Administrator;
                break;
            }

            // получить данные по всем табикам
            this.groupsService
              .getGroupUsersCounters(this.group.id)
              .pipe(take(1))
              .subscribe(counters => {
                if (counters) {
                  this.setTabCounters(GroupUsersFragment.Members, counters.members);
                  this.setTabCounters(GroupUsersFragment.Subscribers, counters.subscribers);
                  this.setTabCounters(GroupUsersFragment.Admins, counters.administrators);
                  this.setTabCounters(GroupUsersFragment.Requests, counters.requests);
                }
              });
            this.currentTab.method(this.currentTab);
          });
        }
      });
    });
  }

  private setTabCounters(fragment: GroupUsersFragment, count: number) {
    const tab = this.tabs.items.find(t => t.fragment === fragment);
    if (tab) {
      tab.itemsCount = count;
    }
  }

  getMembers(tab: Tab<IGroupUserLink>) {
    this.getUsers(tab, GroupUserRole.Member);
  }

  getSubscribers(tab: Tab<IGroupUserLink>) {
    this.getUsers(tab, GroupUserRole.Subscriber);
  }

  /**
   * Получение подписчиков бизнес-группы
   *
   * @param {Tab<IGroupUserLink>} tab
   */
  getBusinessGroupSubscribers(tab: Tab<IGroupUserLink>) {
    tab.loading = true;
    this.groupsService
      .getGroupUsersSettings(this.group.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        this.groupUserSettings = res;
        if (res && res.linkObjectType !== GroupUserLinkObjectType.allUsers) {
          tab.emptyText =
            'Подписчиков нет. ' +
            'Возможно в настройках группы Вы указали несуществующую группу рассылки или доменную группу,' +
            ' либо синхронизация пользователей еще не завершилась.';
          this.getUsers(tab, GroupUserRole.Subscriber);
        } else {
          tab.emptyText = 'Все сотрудники компании подписаны на эту группу.';
          this.loaded = tab.loaded = true;
          tab.loading = false;
        }
      });
  }

  getAdmins(tab: Tab<IGroupUserLink>) {
    this.getUsers(tab, GroupUserRole.Administrator);
  }

  getRequests(tab: Tab<IGroupUserLink>) {
    // do not call method if there is no right
    if (!this.showUsers) {
      tab.loaded = true;
      return;
    }
    this.groupsService
      .getRequests(this.group.id, tab.offset, this.limit)
      .pipe(
        finalize(() => {
          tab.loading = false;
          this.loaded = tab.loaded = true;
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe(res => {
        // assign my subscriptions
        if (!tab.items || tab.offset === 0) {
          tab.items = [];
          tab.itemsCount = 0;
        }

        if (res && res.items) {
          res.items.forEach(user => {
            if (
              !tab.items.filter(
                u =>
                  u.user.id === user.user.id &&
                  u.groupUser.role === user.groupUser.role &&
                  u.groupUser.state === user.groupUser.state,
              ).length
            ) {
              tab.items.push(user);
            }
          });

          tab.itemsCount = res.count;
          tab.offset += tab.items.length;
        } else {
          tab.allLoaded = true;
        }
      });
  }

  private getUsers(tab: Tab<IGroupUserLink>, role: GroupUserRole) {
    // do not call method if there is no right
    if (!this.showUsers) {
      tab.loaded = true;
      return;
    }
    this.groupsService
      .getUsersByRole(this.group.id, role, tab.offset, this.limit)
      .pipe(
        finalize(() => {
          tab.loading = false;
          this.loaded = tab.loaded = true;
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe(res => {
        // assign my subscriptions
        if (!tab.items || tab.offset === 0) {
          tab.items = [];
          tab.itemsCount = 0;
        }

        if (res && res.items && res.items.length) {
          res.items.forEach(userLink => {
            if (!tab.items.filter(u => u.user.id === userLink.user.id).length) {
              tab.items.push(userLink);
            }
          });

          tab.itemsCount = res.count;
          tab.offset += tab.items.length;
        } else {
          tab.allLoaded = true;
        }
      });
  }

  leave(event) {
    const userId = event.res;
    if (this.currentTab.id === 1 || this.currentTab.id === 3) {
      this.currentTab.items = this.currentTab.items.filter(m => m.user.id !== userId);
    }
  }

  join(event) {
    const userId = event.res;
    const role = event.role;
    const resTab = this.tabs.items.find(t => t.fragment === GroupUsersFragment.Requests);
    if (resTab && this.currentTab.id === resTab.id) {
      if (role !== undefined && role != null) {
        this.currentTab.items = this.currentTab.items.filter(m => !(m.user.id === userId && m.groupUser.role === role));
      } else {
        this.currentTab.items = this.currentTab.items.filter(m => m.user.id !== userId);
      }
    }
  }

  isCurrentUser(u): boolean {
    return this.currentUser.id === u.id;
  }

  showAddUser() {
    this.addUsersDialog = this.dialog.open(GroupAddUsersComponent, {
      minWidth: '50vw',
      data: {
        group: this.group,
        placeholder: `Введите имя, чтобы добавить ${
          this.group.type === GroupType.Business ? 'администратора' : 'пользователя'
        }`,
        // onOk: this.addUsers.bind(this)
        onOk: usersResult => {
          this.addUsersDialog.close();
          // todo: переделать это всё на signalr
          let id = null;
          switch (usersResult.role) {
            case GroupUserRole.Member:
              id = 1;
              break;
            case GroupUserRole.Subscriber:
              id = 2;
              break;
            case GroupUserRole.Administrator:
              id = 3;
              break;
          }
          if (this.currentTab.id === id) {
            this.ngZone.run(() => {
              usersResult.users.forEach(user => {
                if (!this.currentTab.items.find(m => m.user.id === user.id)) {
                  const link: IGroupUserLink = {
                    user: user,
                    groupUser: {
                      groupID: this.group.id,
                      role: usersResult.role,
                      state: GroupRequestState.Approved,
                      userID: user.id,
                    },
                  };

                  this.currentTab.items.unshift(link);
                  this.currentTab.itemsCount += 1;
                }
              });
            });
          }
        },
      },
    });
  }

  private operateTab(res: IGroupUserRoleResult, isIn: boolean) {
    if (res && res.result && this.group.id === res.group.id) {
      this.ngZone.run(() => {
        this.group = res.group;
        this.showUsers = this.groupsService.canReadGroup(res.group, this.currentUser);
        switch (res.role) {
          case GroupUserRole.Member:
            this.operateTabInternal(
              res,
              this.tabs.items.find(t => t.fragment === GroupUsersFragment.Members),
              isIn,
            );
            break;
          case GroupUserRole.Subscriber:
            this.operateTabInternal(
              res,
              this.tabs.items.find(t => t.fragment === GroupUsersFragment.Subscribers),
              isIn,
            );
            break;
          case GroupUserRole.Administrator:
            this.operateTabInternal(
              res,
              this.tabs.items.find(t => t.fragment === GroupUsersFragment.Admins),
              isIn,
            );
            break;
        }
      });
    }
  }

  private operateTabInternal(res: IGroupUserRoleResult, tab: Tab<IGroupUserLink>, isIn: boolean) {
    // update counters for target tab
    if (isIn) {
    } else {
      tab.items = tab.items.filter(g => g.user.id !== res.userId);
      // tab.items.forEach(i => {
      //     i.removed = true;
      // });

      if (tab.itemsCount > 0) {
        tab.itemsCount -= 1;
      } else {
        tab.itemsCount = 0;
      }
    }
  }

  private operateRequestsTab(res: IGroupIdUserRoleResult, approve: boolean) {
    if (res && res.result && this.group.id === res.groupId) {
      this.ngZone.run(() => {
        const requestsTab = this.tabs.items.find(t => t.fragment === GroupUsersFragment.Requests);
        requestsTab.items = requestsTab.items.filter(i => !(i.user.id === res.userId && i.groupUser.role === res.role));

        // update counters for requests tab
        if (requestsTab.itemsCount > 0) {
          requestsTab.itemsCount -= 1;
        } else {
          requestsTab.itemsCount = 0;
        }

        let fragment: GroupUsersFragment;

        switch (res.role) {
          case GroupUserRole.Member:
            fragment = GroupUsersFragment.Members;
            break;
          case GroupUserRole.Subscriber:
            fragment = GroupUsersFragment.Subscribers;
            break;
          case GroupUserRole.Administrator:
            fragment = GroupUsersFragment.Admins;
            break;
        }

        const targetTab: Tab<IGroupUserLink> = this.tabs.items.find(t => t.fragment === fragment);

        // update counters for target tab
        if (approve) {
          targetTab.itemsCount += 1;
        } else {
          targetTab.items = targetTab.items.filter(g => g.user.id !== res.userId);
          // targetTab.items.forEach(i => {
          //     i.removed = true;
          // });

          if (targetTab.itemsCount > 0) {
            targetTab.itemsCount -= 1;
          } else {
            targetTab.itemsCount = 0;
          }
        }
      });
    }
  }

  isShowMenu(userLink: UserLink): boolean {
    return (
      this.group &&
      (this.groupsService.isAdmin(this.group, this.currentUser) ||
        (this.groupsService.isAnyRole(this.group, this.currentUser) &&
          userLink &&
          userLink.user &&
          this.currentUser &&
          this.currentUser.id === userLink.user.id))
    );
  }
}
