import { Component } from '@angular/core';
import { ISetting } from '@app/shared/model/setting';
import { SettingsService } from '@app/shared/services/settings.service';
import { finalize, take } from 'rxjs/operators';

@Component({
  selector: 'app-mission',
  templateUrl: './mission.component.html',
  styleUrls: ['./mission.component.scss']
})
export class MissionComponent {

  missionText: string;
  loaded: boolean;

  constructor(private settingsService: SettingsService) {
    this.settingsService.getValues(['Текст миссии', 'Отображать миссию на портале']).pipe(
      finalize(() => this.loaded = true)
    ).subscribe((res: ISetting[]) => {
      if (res) {
        const missionText = res.find(s => s.key === 'Текст миссии');
        const showMission = res.find(s => s.key === 'Отображать миссию на портале');

        if (missionText && missionText.value && showMission && showMission.value
          && showMission.value && (showMission.value.toLowerCase() === 'да' || showMission.value.toLowerCase() === 'true')) {
          this.missionText = missionText.value;
        }

      } else {
        this.missionText = '';
      }
    });
  }
}
