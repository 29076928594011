<div class="cos-add _active">
  <span class="header-text _modal">
    <span class="header-text__text">Совместный доступ</span>&nbsp;
    <span class="header-text__count"></span>
  </span>
  <div class="cos-add__tags">
    <div class="tag" *ngFor="let u of usersToAdd">
      <button type="button" class="btn btn-secondary btn-icon tag__button" (click)="removeUser(u)">
        <span class="tag__name">{{u.fullName}}</span>
        <img src="{{assetsPrefix}}/assets/images/icons/menu/remove.svg" />
      </button>
    </div>
  </div>
  <input placeholder="{{placeholder}}" class="main-input cos-add__input" (keyup)="onSearchKey($event)"
    (keydown)="onSearchKey($event)" [ngModel]="searchText" (ngModelChange)="searchText = $event">
  <div class="cos-add__suggest">
    <div class="co _one-line" *ngFor="let u of usersFound">
      <div class="co__avatar">
        <app-user-avatar [user]="u" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'"></app-user-avatar>
      </div>
      <div class="co__info">
        <a href="javascript:void(0)" class="link co__name" (click)="addUser(u)">{{u?.fullName}}</a>
        <span class="co__occ">{{u?.jobTitle}}</span>
      </div>
    </div>
  </div>
  <div class="co_type">
    <div class="" mat-button [matMenuTriggerFor]="menu"
      [ngClass]="{'access_type_edit':shareType=='write','access_type_view':shareType=='read'}">
      {{getShareType(shareType)}}
    </div>
    <mat-menu #menu="matMenu">
      <div class="icon-menu" #menuContainer>
        <a mat-menu-item href="javascript:void(0)" class="icon-menu__item access_type_view"
          (click)="setShareType('read')">
          <span>{{getShareType('read')}}</span>
        </a>
        <a mat-menu-item href="javascript:void(0)" class="icon-menu__item access_type_edit"
          (click)="setShareType('write')">
          <span>{{getShareType('write')}}</span>
        </a>
      </div>
    </mat-menu>
  </div>
  <div class="cos-add__controls" mat-dialog-actions [ngClass]="{'loading _blue':loading}">
    <button type="button" mat-button mat-dialog-close class="btn btn-simple" (click)="cancel()">Отмена</button>
    <button type="button" mat-button class="btn btn-primary" [attr.disabled]="isValid?null:''"
      (click)="share()">Поделиться</button>
  </div>
  <div class="error-message" *ngIf="errors?.length>0">
    <span *ngFor="let e of errors">{{e}}</span>
  </div>
  <div class="clearfix"></div>
  <div class="cos-add__extra">
    <a id="documentLibraryLink" [attr.href]="items[0].url" style="display: none" *ngIf="items?.length"></a>
    <a href="javascript:;" class="link _icon" (click)="copyToClipboard()">Скопировать ссылку</a>
    <a [attr.href]="additionalLink" class="link pull-right" target="_blank">Дополнительно</a>
  </div>
</div>