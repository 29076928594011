export abstract class Browser {

    private static _version: string;
    private static _opera: boolean;
    private static _vivaldi: boolean;
    private static _amigo: boolean;
    private static _msie: boolean;
    private static _msie6: boolean;
    private static _msie7: boolean;
    private static _msie8: boolean;
    private static _msie9: boolean;
    private static _msie_edge: boolean;
    private static _mozilla: boolean;
    private static _chrome: boolean;
    private static _safari: boolean;
    private static _iphone: boolean;
    private static _ipod: boolean;
    private static _iphone4: boolean;
    private static _ipod4: boolean;
    private static _ipad: boolean;
    private static _android: boolean;
    private static _bada: boolean;
    private static _mobile: boolean;
    private static _msie_mobile: boolean;
    private static _safari_mobile: boolean;
    private static _opera_mobile: boolean;
    private static _opera_mini: boolean;
    private static _mac: boolean;
    private static _search_bot: boolean;

    public static get Version(): string {
        return this._version;
    }

    public static get Opera(): boolean {
        return this._opera;
    }

    public static get Vivaldi(): boolean {
        return this._vivaldi;
    }

    public static get Amigo(): boolean {
        return this._amigo;
    }

    public static get Msie(): boolean {
        return this._msie;
    }

    public static get Msie6(): boolean {
        return this._msie6;
    }

    public static get Msie7(): boolean {
        return this._msie7;
    }

    public static get Msi8(): boolean {
        return this._msie8;
    }

    public static get Msie9(): boolean {
        return this._msie9;
    }

    public static get MsieEdge(): boolean {
        return this._msie_edge;
    }

    public static get Mozilla(): boolean {
        return this._mozilla;
    }

    public static get Chrome(): boolean {
        return this._chrome;
    }

    public static get Safari(): boolean {
        return this._safari;
    }

    public static get Iphone(): boolean {
        return this._iphone;
    }

    public static get Ipod(): boolean {
        return this._ipod;
    }

    public static get Iphone4(): boolean {
        return this._iphone4;
    }

    public static get Ipod4(): boolean {
        return this._ipod4;
    }

    public static get Ipad(): boolean {
        return this._ipad;
    }

    public static get Android(): boolean {
        return this._android;
    }

    public static get Bada(): boolean {
        return this._bada;
    }

    public static get Mobile(): boolean {
        return this._mobile;
    }

    public static get MsieMobile(): boolean {
        return this._msie_mobile;
    }

    public static get SafariMobile(): boolean {
        return this._safari_mobile;
    }

    public static get OperaMobile(): boolean {
        return this._opera_mobile;
    }

    public static get OperaMini(): boolean {
        return this._opera_mini;
    }

    public static get Mac(): boolean {
        return this._mac;
    }

    public static get SearchBot(): boolean {
        return this._search_bot;
    }

    static Initialize() {
        const userAgent = navigator.userAgent.toLowerCase();
        this._version = (userAgent.match(/.+(?:me|ox|on|rv|it|era|opr|ie|edge)[\/: ]([\d.]+)/) || [0, '0'])[1] as string;
        this._opera = (/opera/i.test(userAgent) || /opr/i.test(userAgent));
        this._vivaldi = /vivaldi/i.test(userAgent);
        this._amigo = /amigo.*mrchrome soc/i.test(userAgent);
        this._msie = (/msie/i.test(userAgent) && !/opera/i.test(userAgent) || /trident\//i.test(userAgent)) || /edge/i.test(userAgent);
        this._msie6 = (/msie 6/i.test(userAgent) && !/opera/i.test(userAgent));
        this._msie7 = (/msie 7/i.test(userAgent) && !/opera/i.test(userAgent));
        this._msie8 = (/msie 8/i.test(userAgent) && !/opera/i.test(userAgent));
        this._msie9 = (/msie 9/i.test(userAgent) && !/opera/i.test(userAgent));
        this._msie_edge = (/edge/i.test(userAgent) && !/opera/i.test(userAgent));
        this._mozilla = /firefox/i.test(userAgent);
        this._chrome = /chrome/i.test(userAgent) && !/edge/i.test(userAgent);
        this._safari = (!(/chrome/i.test(userAgent)) && /webkit|safari|khtml/i.test(userAgent));
        this._iphone = /iphone/i.test(userAgent);
        this._ipod = /ipod/i.test(userAgent);
        this._iphone4 = /iphone.*OS 4/i.test(userAgent);
        this._ipod4 = /ipod.*OS 4/i.test(userAgent);
        this._ipad = /ipad/i.test(userAgent);
        this._android = /android/i.test(userAgent);
        this._bada = /bada/i.test(userAgent);
        this._mobile = /iphone|ipod|ipad|opera mini|opera mobi|iemobile|android/i.test(userAgent);
        this._msie_mobile = /iemobile/i.test(userAgent);
        this._safari_mobile = /iphone|ipod|ipad/i.test(userAgent);
        this._opera_mobile = /opera mini|opera mobi/i.test(userAgent);
        this._opera_mini = /opera mini/i.test(userAgent);
        this._mac = /mac/i.test(userAgent);
        this._search_bot = /(yandex|google|stackrambler|aport|slurp|msnbot|bingbot|twitterbot|ia_archiver|facebookexternalhit)/i.test(userAgent);
    }
}

Browser.Initialize();
