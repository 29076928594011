<div class="container _full-page _moved _with-sidebar">

  <div class="sidebar">
    <app-group-users [group]="group"></app-group-users>
    <ng-template appDynamicContainer></ng-template>
    <button [@visibilityChanged]="goTopVisible" (click)="scrollTop()" type="button" class="btn back-to-top"></button>
  </div>
  <div class="content">
    <app-group-card [formType]="0" *ngIf="group?.showCard"></app-group-card>
    <div class="card _simple" *ngIf="group?.showCard && (groupsService.currentGroupShowCounters$ | async)">
      <div class="rich-tabs">
        <div class="rich-tabs__content">
          <a href="#" class="rich-tabs__item _users" routerLink="/group/{{group?.id}}/users" routerLinkActive="_active">
            <span class="badge"
              *ngIf="groupRequestsCount && groupsService.isAdmin(this.group)">{{groupRequestsCount}}</span>
            <span class="rich-tabs__img">
              <img src="{{assetsPrefix}}/assets/images/widgets/rich-tabs/icon-users.svg">
            </span>
            <span class="rich-tabs__text">Участники</span>
          </a>

          <a href="#" class="rich-tabs__item _pages" routerLink="/group/{{group?.id}}/pages" routerLinkActive="_active"
            *ngIf="isShowModule('Страницы')">
            <span class="rich-tabs__img">
              <img src="{{assetsPrefix}}/assets/images/widgets/rich-tabs/icon-pages.svg">
            </span>
            <span class="rich-tabs__text">Страницы</span>
          </a>

          <a href="#" class="rich-tabs__item _files" routerLink="/group/{{group?.id}}/files" routerLinkActive="_active"
            *ngIf="isShowModule('Файлы') && currentUser?.userType!==UserType.local">
            <span class="rich-tabs__img">
              <img src="{{assetsPrefix}}/assets/images/widgets/rich-tabs/icon-files.svg">
            </span>
            <span class="rich-tabs__text">Файлы</span>
          </a>

          <a href="#" class="rich-tabs__item _gallery" routerLink="/group/{{group?.id}}/gallery"
            routerLinkActive="_active" *ngIf="isShowModule('Галерея')">
            <span class="rich-tabs__img">
              <img src="{{assetsPrefix}}/assets/images/widgets/rich-tabs/icon-gallery.svg">
            </span>
            <span class="rich-tabs__text">Галерея</span>
          </a>

          <a href="#" class="rich-tabs__item _surveys" routerLink="/group/{{group?.id}}/surveys"
            routerLinkActive="_active" *ngIf="isShowModule('Опросы')">
            <span class="rich-tabs__img">
              <img src="{{assetsPrefix}}/assets/images/widgets/rich-tabs/icon-surveys.svg">
            </span>
            <span class="rich-tabs__text">Опросы</span>
          </a>

          <a href="#" class="rich-tabs__item _vacancy" routerLink="/group/{{group?.id}}/vacancy"
            routerLinkActive="_active" *ngIf="isShowModule('Вакансии')">
            <span class="rich-tabs__img">
              <img src="{{assetsPrefix}}/assets/images/widgets/rich-tabs/icon-vacancy.svg">
            </span>
            <span class="rich-tabs__text">Вакансии</span>
          </a>

          <a href="#" class="rich-tabs__item _ideas" routerLink="/group/{{group?.id}}/ideas" routerLinkActive="_active"
            *ngIf="isShowModule('Идеи')">
            <span class="rich-tabs__img">
              <img src="{{assetsPrefix}}/assets/images/widgets/rich-tabs/icon-ideas.svg">
            </span>
            <span class="rich-tabs__text">Идеи</span>
          </a>
        </div>
      </div>
    </div>
    <router-outlet></router-outlet>
    <div class="align-center hidden-desktop">
      <button [@visibilityChanged]="goTopVisible" (click)="scrollTop()" type="button" class="btn back-to-top"></button>
    </div>
  </div>
</div>
