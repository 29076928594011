<div class="container _moved _full-page">
  <div class="cos">
    <div class="user-header">
      <a href="javascript:;" class="link-profile hint">
          <span title="" class="avatar _30">
            <span class="avatar__image-wrap">
              <img src="{{assetsPrefix}}/assets/images/icons/icon-thanks-white.svg" alt="" class="avatar__image">
            </span>
          </span>
        <span class="link-profile__text">Спасибо от сотрудников</span>
      </a>
    </div>

    <div class="cos__body _paddings" *ngIf="items && loaded">
      <div class="thanks-header">
        <div class="thanks-header__total">
          <span>{{count}}</span>
          <p>Спасибо уже отправлено</p>
        </div>
        <div class="thanks-header__desc">
          Получать благодарности всегда приятно. Коллеги говорят друг другу спасибо за помощь, участие в проектах, добрые слова и многое другое.  Вы тоже можете уделить внимание своим коллегам. Наверняка вам есть за что сказать Спасибо.
        </div>
        <div class="thanks-header__thank">
          <button type="button" class="btn btn-primary" (click)="thank()">Сказать спасибо коллеге</button>
        </div>
      </div>
    <!--  <div class="news-title _bright">{{thankTitle}}</div>-->
    <!--  <span class="header-text _low _secondary _inline">-->
    <!--    <span class="header-text__text">{{thank.created | dateSpLike:true }}</span>-->
    <!--  </span>-->

      <div class="thanks-message-wrap" *ngFor="let item of items">
        <div class="news-section _lower">
          <div class="news-body">
            <div class="co _static">
              <div class="co__avatar">
                <app-user-avatar [user]="item.user" [linkClass]="'avatar _80'" [imageClass]="'avatar__image'">
                </app-user-avatar>
              </div>
              <div class="co__info">
                <a href="#" class="link co__name" routerLink="/profile/{{item.user?.id}}"
                   (click)="usersService.navigateProfile(item.user?.id)">{{item.user?.fullName}}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="thanks-message-inner-wrap">
          <div class="thanks-message _with-author">
            <div class="thanks-message__author">
              <app-user-avatar [user]="item.author" [linkClass]="'avatar _80'" [imageClass]="'avatar__image'">
              </app-user-avatar>
            </div>

            <div class="thanks-message__body">
              <a href="#" class="link co__name" routerLink="/profile/{{item.author?.id}}"
                 (click)="usersService.navigateProfile(item.author?.id)">{{item.author?.fullName}}</a>
              <p class="text-secondary">{{item.created | dateSpLike:true }}</p>
              <p>{{item.body}}</p>
            </div>
          </div>
        </div>
      </div>
    <!--  <div class="cos__actions" *ngIf="thankTargetType===ThankTargetType.iWasThanked">-->
    <!--    <button class="btn btn-primary" type="button" (click)="thankUser(thank.author)">Сказать спасибо в ответ</button>-->
    <!--  </div>-->
    <!--  <div class="cos__actions" *ngIf="thankTargetType===ThankTargetType.iThanked">-->
    <!--    <button class="btn btn-primary" type="button" (click)="thankUser(thank.user)">Сказать спасибо повторно</button>-->
    <!--  </div>-->
    </div>
  </div>
</div>
