<div class="card" *ngIf="post" [ngClass]="{'_simple':isSystemPost()}">
  <div class="post" [ngClass]="{'_edit': isEdit, '_system':isSystemPost()}">
    <ng-container *ngIf="isEdit; then edit else display"></ng-container>
    <ng-template #edit>
      <app-user-avatar *ngIf="!isEdit" [user]="currentUser" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'">
      </app-user-avatar>
      <div class="post__type post-type" *ngIf="allowNews && !allowShare">
        <a class="post-type__item _text" matTooltip="Текстовая публикация" [ngClass]="{'_active':postMode===postModes.text}"
          href="javascript:;" (click)="changePostMode(postModes.text)">Текст</a>
        <a class="post-type__item _news" matTooltip="Новостная публикация" [ngClass]="{'_active':postMode===postModes.news}"
          href="javascript:;" (click)="changePostMode(postModes.news)">Новость</a>
      </div>

      <div class="new-post post__news-fields" *ngIf="postMode===postModes.news">
        <div class="input-main new-post__title">
          <input type="text"
                 placeholder="Название новости*"
                 [(ngModel)]="title"
                 class="input-main__field"
                 (keyup)="onKey($event)"
          >
        </div>

        <div class="input-main new-post__subtitle">
          <textarea autosize rows="3" placeholder="Анонс новости" [(ngModel)]="announce" class="input-main__field"
            (keyup)="onKey($event)">
          </textarea>
        </div>
      </div>

      <app-froala-editor
        class="post__editor"
        [focusOnInit]="true"
        [ngClass]="{'_full' : isEdit}"
        [simple]="postMode === postModes.text"
        [showToolbar]="isEdit"
        [(editorModel)]="text"
        [(text)]="text"
        [(mentions)]="mentionsEdit"
      ></app-froala-editor>
      <div class="post__about">Используйте «@», чтобы упомянуть коллегу</div>

      <!-- post -->
      <div class="post__repost" *ngIf="post.repost">
        <app-post [post]="post.repost" [readOnly]="true"></app-post>
      </div>
      <!-- post END -->

      <app-add-tags *ngIf="postMode===postModes.news" placeholder="Теги" [tagType]="TagType.post" [elementId]="post.event.id" [tags]="tags"
      [mode]="AddTagsComponentMode.edit"></app-add-tags>

      <div class="post__attachments">
        <app-attachments-list #attachmentsList [attachments]="attachments" [isEdit]="true"
          (onRemove)="onRemoveAttachment($event)" (onFilesUploaded)="onFilesUploaded($event)" [ownerId]="ownerId"
          [ownerType]="ownerType" [elementType]="elementType"></app-attachments-list>
        <app-media-attachments-list #mediaAttachmentsList [mediaAttachments]="mediaAttachments" [isEdit]="true"
          (onRemove)="onRemoveMediaAttachment($event)" (onFilesUploaded)="onMediaFilesUploaded($event)"
          [ownerId]="ownerId" [ownerType]="ownerType" [elementType]="elementType"></app-media-attachments-list>
      </div>
      <div class="new-comment__actions">
        <mat-checkbox [(ngModel)]="disableComment">Запретить комментарии</mat-checkbox>
        <mat-checkbox [(ngModel)]="disableLike">Запретить лайки</mat-checkbox>
        <mat-checkbox *ngIf="isBusinessNews()" [(ngModel)]="showOnMainPage">Отображать на главной</mat-checkbox>
        <div>
          <mat-checkbox [(ngModel)]="pin">{{pinText()}}</mat-checkbox>
          <div class="post__calendar" *ngIf="pin">
            Закрепить до
            <input placeholder="дд.мм.гггг" matInput [matDatepicker]="dp" [(ngModel)]="pinDateEnd">
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp></mat-datepicker>
          </div>
        </div>
        <mat-checkbox *ngIf="isShowNewEmployeeCheckbox()" [(ngModel)]="isNewEmployee">Новый сотрудник</mat-checkbox>
        <div class="post__calendar" *ngIf="postMode===postModes.news">
          Дата публикации*
          <input placeholder="дд.мм.гггг" matInput [matDatepicker]="dp" [(ngModel)]="publishDate" [max]="maxDate">
          <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
          <mat-datepicker #dp></mat-datepicker>
        </div>
      </div>
      <div class="post__actions" [ngClass]="{'loading _blue': posting || mediaAttachmentsList?.filesUploading}">
        <div>
          <app-media-attachments-menu title="Добавить медиафайл" (choose)="mediaAttachmentsList.onChooseFile($event)"
            (upload)="mediaAttachmentsList.onUploadFiles($event)"></app-media-attachments-menu>
          <app-attachments-menu title="Добавить вложение" (choose)="attachmentsList.onChooseFile($event)"
            (upload)="attachmentsList.onUploadFiles($event)"></app-attachments-menu>
        </div>
        <div class="post__submit">
          <button type="button" class="btn btn-simple" (click)="cancel()">Отмена</button>
          <button type="button" *ngIf="postMode===postModes.news" class="btn btn-simple" (click)="saveAsDraft()"
          [attr.disabled]="isValid()?null:''">Сохранить как черновик</button>
          <button type="button" class="btn btn-primary" (click)="publish()"
            [attr.disabled]="isValid()?null:''">Опубликовать</button>
        </div>
      </div>
    </ng-template>
    <ng-template #display>
      <ng-container [ngSwitch]="post?.event?.eventType">
        <!-- пользователь создал группу-->
        <ng-container *ngSwitchCase="eventType.UserNewGroup">
          <a routerLink="/profile/{{post?.author?.id}}" class="post__author"
            (click)="usersService.navigateProfile(post.author?.id)"
            [appUserTooltip]="post.author?.id">{{post.author?.fullName}}</a>
          <div class="post__text">создал группу. {{post.event?.created | dateSpLike }}</div>
        </ng-container>

        <!-- пользователь вступил в группу-->
        <ng-container *ngSwitchCase="eventType.GroupUserJoin">
          <a routerLink="/profile/{{post?.author?.id}}" class="post__author"
            (click)="usersService.navigateProfile(post.author?.id)"
            [appUserTooltip]="post.author?.id">{{post.author?.fullName}}</a>
          <div class="post__text">вступил(-а) в группу. {{post.event?.created | dateSpLike }}</div>
        </ng-container>

        <!-- добавили нового пользователя в группу-->
        <ng-container *ngSwitchCase="eventType.GroupNewUser">
          <ng-container *ngTemplateOutlet="groupUsersStateChanged"></ng-container>
        </ng-container>

        <!-- удалили пользователя из группы-->
        <ng-container *ngSwitchCase="eventType.GroupUserRemoved">
          <ng-container *ngTemplateOutlet="groupUsersStateChanged"></ng-container>
        </ng-container>

        <!-- изменеие названия группы-->
        <ng-container *ngSwitchCase="eventType.GroupNameChanged">
          <ng-container *ngTemplateOutlet="propertyChangedBody"></ng-container>
        </ng-container>

        <!-- изменеие типа группы-->
        <ng-container *ngSwitchCase="eventType.GroupTypeChanged">
          <ng-container *ngTemplateOutlet="propertyChangedBody"></ng-container>
        </ng-container>

        <!-- пользователь покинул группу-->
        <ng-container *ngSwitchCase="eventType.GroupUserLeave">
          <a routerLink="/profile/{{post?.author?.id}}" class="post__author"
            (click)="usersService.navigateProfile(post.author?.id)"
            [appUserTooltip]="post.author?.id">{{post.author?.fullName}}</a>
          <div class="post__text">вышел(-ла) из группы. {{post.event?.created | dateSpLike }}</div>
        </ng-container>

        <!-- пост бизнес-группы -->
        <ng-container *ngSwitchCase="eventType.BusinessGroupNewPost">
          <ng-container *ngTemplateOutlet="businessPostTemplate"></ng-container>
        </ng-container>

        <!-- новостной пост бизнес-группы -->
        <ng-container *ngSwitchCase="eventType.BusinessGroupNewsPost">
          <ng-container *ngTemplateOutlet="businessNewsPostTemplate; context: {$implicit: false}"></ng-container>
        </ng-container>

        <!-- новостной пост нового сотрудника -->
        <ng-container *ngSwitchCase="eventType.BusinessGroupNewEmployeePost">
          <ng-container *ngTemplateOutlet="businessNewsPostTemplate; context: {$implicit: true}"></ng-container>
        </ng-container>

        <!-- новостной пост группы -->
        <ng-container *ngSwitchCase="eventType.GroupNewsPost">
          <ng-container *ngTemplateOutlet="defaultNewsBodyTemplate"></ng-container>
        </ng-container>

        <!-- шаблон ре-поста -->
        <ng-container *ngSwitchCase="eventType.Repost">
          <ng-container *ngTemplateOutlet="repostTemplate"></ng-container>
        </ng-container>

        <!-- обычный пост -->
        <ng-container *ngSwitchDefault>
          <ng-container *ngTemplateOutlet="defaultBodyTemplate"></ng-container>
        </ng-container>

        <!-- Шаблон бизнес поста -->
        <ng-template #businessPostTemplate>
          <div class="post__author">
            <ng-container *ngTemplateOutlet="postMenuTemplate"></ng-container>
            <div class="link-profile">
              <ng-container *ngIf="!isPostView">
                <ng-container *ngTemplateOutlet="businessPostAvatar"></ng-container>
              </ng-container>
              <span class="link-profile__subtext">{{post.event?.created | dateSpLike }}</span>
            </div>
          </div>
          <app-message [text]="post.event?.body" [showBody]="showBody" [mentions]="mentions" [highlightText]="highlightText"></app-message>
          <app-attachments-list [attachments]="post.attachments" [isEdit]="false" [elementType]="elementType"></app-attachments-list>
          <app-media-attachments-list [mediaAttachments]="post.mediaAttachments" [isEdit]="false" [elementType]="elementType">
          </app-media-attachments-list>
        </ng-template>

        <!-- Шаблон новостного бизнес поста -->
        <ng-template #businessNewsPostTemplate let-isNewEmployee>
          <div class="post__author">
            <ng-container *ngTemplateOutlet="postMenuTemplate"></ng-container>
            <div class="link-profile">
              <ng-container *ngIf="!isPostView">
                <ng-container *ngTemplateOutlet="businessPostAvatar"></ng-container>
              </ng-container>
              <span class="link-profile__subtext">{{post.event?.created | dateSpLike }}</span>
            </div>
          </div>
          <div class="post__text">
            <a *ngIf="!showBody" routerLink="/group/{{post.event?.ownerID}}/post/{{post.event?.id}}" class="news-title">{{post.event?.title}}</a>
            <a *ngIf="!showBody && post.event?.announce" routerLink="/group/{{post.event?.ownerID}}/post/{{post.event?.id}}" class="news-announce">{{post.event?.announce}}</a>

            <div *ngIf="showBody" class="news-title">{{post.event?.title}}</div>
            <div *ngIf="showBody && post.event?.announce" class="news-announce">{{post.event?.announce}}</div>
          </div>
          <app-message *ngIf="showBody" [showBody]="showBody" [text]="post.event?.body" [mentions]="mentions"
            [highlightText]="highlightText" [mentionToCard]="isNewEmployee"></app-message>
          <app-attachments-list [attachments]="post.attachments" [isEdit]="false" [elementType]="elementType"></app-attachments-list>
          <app-media-attachments-list [mediaAttachments]="post.mediaAttachments" [isEdit]="false" [elementType]="elementType">
          </app-media-attachments-list>
        </ng-template>

        <!-- Шаблон ре поста -->
        <ng-template #repostTemplate>
          
          <ng-container *ngIf="isBusinessGroup===false">
            <div class="post__author">
              <ng-container *ngTemplateOutlet="postMenuTemplate"></ng-container>
              <a routerLink="/profile/{{post?.author?.id}}" class="link-profile"
                (click)="usersService.navigateProfile(post.author?.id)">
                <app-user-avatar [user]="post.author" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'"
                  [asSpan]="true"></app-user-avatar>
                <span class="link-profile__text" [appUserTooltip]="post.author?.id">{{post.author?.fullName}}</span>
                <span class="link-profile__subtext">{{post.event?.created | dateSpLike }}</span>
              </a>
            </div>
          </ng-container>

          <ng-container *ngIf="isBusinessGroup===true">
            <div class="post__author">
              <ng-container *ngTemplateOutlet="postMenuTemplate"></ng-container>
              <div class="link-profile">
                <ng-container *ngIf="!isPostView">
                  <ng-container *ngTemplateOutlet="businessPostAvatar"></ng-container>
                </ng-container>
                <span class="link-profile__subtext">{{post.event?.created | dateSpLike }}</span>
              </div>
            </div>
          </ng-container>
          
          <app-message [text]="post.event?.body" [showBody]="showBody" [mentions]="mentions" [highlightText]="highlightText"></app-message>

          <div class="post__repost" *ngIf="post.repost">
            <app-post [post]="post.repost" [readOnly]="true"></app-post>
          </div>

          <app-attachments-list [attachments]="post.attachments" [isEdit]="false" [elementType]="elementType"></app-attachments-list>
          <app-media-attachments-list [mediaAttachments]="post.mediaAttachments" [isEdit]="false" [elementType]="elementType">
          </app-media-attachments-list>
        </ng-template>

        <!-- Шаблон обычного поста -->
        <ng-template #defaultBodyTemplate>
          <!-- postId={{post?.event?.id}} eventType={{post?.event?.eventType}} -->
          <div class="post__author">
            <ng-container *ngTemplateOutlet="postMenuTemplate"></ng-container>
            <a routerLink="/profile/{{post?.author?.id}}" class="link-profile"
              (click)="usersService.navigateProfile(post.author?.id)">
              <app-user-avatar [user]="post.author" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'"
                [asSpan]="true"></app-user-avatar>
              <span class="link-profile__text" [appUserTooltip]="post.author?.id">{{post.author?.fullName}}</span>
              <span class="link-profile__subtext">{{post.event?.created | dateSpLike }}</span>
            </a>
          </div>
          <app-message [text]="post.event?.body" [showBody]="showBody" [mentions]="mentions" [highlightText]="highlightText"></app-message>
          <app-attachments-list [attachments]="post.attachments" [isEdit]="false" [elementType]="elementType"></app-attachments-list>
          <app-media-attachments-list [mediaAttachments]="post.mediaAttachments" [isEdit]="false" [elementType]="elementType">
          </app-media-attachments-list>
        </ng-template>

        <!-- Шаблон новостного поста -->
        <ng-template #defaultNewsBodyTemplate>
          <div class="post__author">
            <ng-container *ngTemplateOutlet="postMenuTemplate"></ng-container>
            <div class="link-profile">
              <ng-container *ngIf="!isPostView">
                <app-user-avatar [avatarImage]="groupAvatarImage" [linkClass]="'avatar _40'"
                  [imageClass]="'avatar__image'" routerLink="/group/{{group?.id}}" [name]="post.group?.title"
                  [noStatus]="true">
                </app-user-avatar>
                <span class="link-profile__text">
                  <a routerLink="/group/{{groupId}}" class="link"
                    (click)="navigateGroup($event,groupId)">{{post.group?.title}}</a>
                </span>
              </ng-container>
              <span class="link-profile__subtext">{{post.event?.created | dateSpLike }}</span>
            </div>
          </div>
          <div class="post__text">
            <a *ngIf="!showBody" routerLink="/group/{{post.event?.ownerID}}/post/{{post.event?.id}}" class="news-title">{{post.event?.title}}</a>
            <a *ngIf="!showBody && post.event?.announce" routerLink="/group/{{post.event?.ownerID}}/post/{{post.event?.id}}" class="news-announce">{{post.event?.announce}}</a>

            <div *ngIf="showBody" class="news-title">{{post.event?.title}}</div>
            <div *ngIf="showBody && post.event?.announce" class="news-announce">{{post.event?.announce}}</div>
          </div>
          <app-message *ngIf="showBody" [showBody]="showBody" [text]="post.event?.body" [mentions]="mentions"
            [highlightText]="highlightText"></app-message>
          <app-attachments-list [attachments]="post.attachments" [isEdit]="false" [elementType]="elementType"></app-attachments-list>
          <app-media-attachments-list [mediaAttachments]="post.mediaAttachments" [isEdit]="false" [elementType]="elementType">
          </app-media-attachments-list>
        </ng-template>

        <!-- шаблон сообщения об изменении свойства -->
        <ng-template #propertyChangedBody>
          <a routerLink="/profile/{{post?.author?.id}}" class="post__author"
            (click)="usersService.navigateProfile(post.author?.id)"
            [appUserTooltip]="post.author?.id">{{post.author?.fullName}}</a>
          <div class="post__text">изменил(-а) {{propertyNameText}} на "{{propertyValue}}". {{post.event?.created |
            dateSpLike }}</div>
        </ng-template>

        <!-- шаблон добавления удаления пользователя из группы -->
        <ng-template #groupUsersStateChanged>
          <a routerLink="/profile/{{post?.author?.id}}" class="post__author"
            (click)="usersService.navigateProfile(post.author?.id)"
            [appUserTooltip]="post.author?.id">{{post.author?.fullName}}</a>
          <div class="post__text">&nbsp;{{groupUsersAddedOrRemovedText}}&nbsp;{{groupUsersAddedOrRemovedSuffix}}&nbsp;
          </div>
          <ng-container *ngFor="let u of groupUsersAddedOrRemoved; let i = index; let last = last">
            <a routerLink="/profile/{{u?.id}}" class="post__author" (click)="usersService.navigateProfile(u?.id)"
              [appUserTooltip]="u?.id">{{u?.fullName}}</a>
            <span *ngIf="!last">,</span>
          </ng-container>
          <div class="post__text">&nbsp;{{post.event?.created | dateSpLike }}</div>
        </ng-template>

        <!-- шаблон меню поста -->
        <ng-template #postMenuTemplate>
          <div class="post__menu pull-right">
            <span class="post__draft pull-right" title="Черновик" *ngIf="post?.event?.isDraft" matTooltip="Новость не опубликована. Для публикации перейдите в режим редактирования через контекстное меню «…»" matTooltipPosition="above"></span>
            <span class="post__pin pull-right" title="Закреплённый пост" *ngIf="post?.event?.pin"></span>
            <app-feed-event-menu [post]="post" (remove)="onRemove()" (edit)="onEdit()" (cancelPin)="onCancelPin()" (share)="onShare()"
              [allowEdit]="allowEdit" [allowDelete]="allowDelete" [allowShare]="allowShare"></app-feed-event-menu>
          </div>
        </ng-template>

        <!-- шаблон аватара бизнес-поста -->
        <ng-template #businessPostAvatar>
          <app-user-avatar [avatarImage]="businessGroupAvatarImage" [linkClass]="'avatar _40'"
            [imageClass]="'avatar__image'" routerLink="/group/{{group?.id}}" [name]="post.group?.title"
            [noStatus]="true">
          </app-user-avatar>
          <span class="link-profile__text">
            <a routerLink="/group/{{groupId}}" class="link"
              (click)="navigateGroup($event,groupId)">{{post.group?.title}}</a>
          </span>
        </ng-template>

      </ng-container>
      <app-add-tags *ngIf="isPostView && postMode===postModes.news" placeholder="Теги" [tagType]="TagType.post" [elementId]="post.event.id" [ownerId]="ownerId" [ownerType]="ownerType" [mode]="AddTagsComponentMode.view"></app-add-tags>
      <div class="post__actions" *ngIf="isShowActions() && !isSystemPost()">
        <app-like *ngIf="!post.event.disableLike" [count]="post.likes.count" [canLike]="post.likes.canLike"
          [elementId]="post.event.id" [elementType]="elementType"></app-like>
        <button *ngIf="!post.event.disableComment" class="btn btn-post _comment"
          [ngClass]="{'transparent-text': post.commentsCount === 0}"
          type="button">{{post.commentsCount}}</button>
        <a *ngIf="!post.event.disableComment" href="javascript:;" class="link" (click)="reply()">Ответить</a>
      </div>
    </ng-template>
    <app-comments-list *ngIf="!post.event.disableComment" #commentsList [count]="post.commentsCount"
      [comments]="post.comments" [elementId]="post.event.id" [elementType]="elementType"
      [isAdmin]="this.groupsService.isAdmin(this.group)" (commentReply)="commentNew.activate($event)"
      (commentAdded)="onCommentAdded($event)" (commentDeleted)="onCommentDeleted($event)"
      [highlightText]="highlightText"></app-comments-list>
    <app-comment-new *ngIf="!post.event.disableComment" #commentNew [elementId]="post.event.id"
      [elementType]="elementType" [autoHide]="post.commentsCount == 0" (commentAdded)="onNewCommentAdded($event)">
    </app-comment-new>
  </div>
</div>
