<div *ngIf="link" class="co" [ngClass]="{'_inactive':link?.unsubscribed && currentUser?.id!=link?.user?.id}">
  <ng-container *ngTemplateOutlet="userSimpleTemplate; context: {$implicit: link.user}"></ng-container>
  <div class="co__action" *ngIf="showSubscribe(link)">
    <button class="btn btn-action {{subscribeService.isSubscribed(link) ? '': 'btn-primary'}}" type="button"
      (click)="subscribeService.changeSubscribe(link)">{{subscribeService.isSubscribed(link) ? 'Отписаться': 'Подписаться'}}</button>
  </div>
</div>
<div *ngIf="user" class="co">
  <ng-container *ngTemplateOutlet="userSimpleTemplate; context: {$implicit: user}"></ng-container>
</div>

<ng-template #userSimpleTemplate let-u>
  <div class="co__avatar">
    <app-user-avatar mat-dialog-close [user]="u" [linkClass]="'avatar _80'" [imageClass]="'avatar__image'"></app-user-avatar>
  </div>
  <div class="co__info">
    <a mat-dialog-close href="#" class="link co__name" routerLink="/profile/{{u?.id}}"
      (click)="usersService.navigateProfile(u?.id)">
      <span [appUserTooltip]="u?.id" [innerHTML]="u?.fullName | highlight:text:true"></span>
      <app-user-absence [user]="u" [isLightMode]="true"></app-user-absence>
    </a>
    <div class="co__occ" *ngIf="u?.jobTitle" [innerHTML]="u?.jobTitle | highlight: text"></div>
    <a mat-dialog-close class="co__occ" routerLink="/profile/{{currentUser?.id}}/search" fragment="k={{u?.department}}"
      *ngIf="u?.department" [innerHTML]="u?.department | highlight: text"></a>
    <div class="co__phones" *ngIf="u?.mobilePhone || u?.workPhone">
      <a href="tel:{{u?.mobilePhone}}" *ngIf="u?.mobilePhone" class="link co__phone"
        [innerHTML]="u?.mobilePhone | highlight: text"></a>
      <a href="tel:{{u?.workPhone}}" *ngIf="u?.workPhone" class="link co__phone"
        [innerHTML]="u?.workPhone | highlight: text"></a>
    </div>
  </div>
</ng-template>