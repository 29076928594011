import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { AttachmentsModule } from '@app/attachments/file-attachments/attachments.module';
import { MediaAttachmentsModule } from '@app/attachments/media-attachments/media-attachments.module';
import { CommentsModule } from '@app/comments/comments.module';
import { LikesModule } from '@app/likes/likes.module';
import { SharedModule } from '@app/shared/shared.module';
import { AnniversaryWidgetComponent } from './components/anniversary-widget/anniversary-widget.component';
import { FeedModule } from '@app/feed/feed.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule,
    CommentsModule,
    AttachmentsModule,
    MediaAttachmentsModule,
    LikesModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    FeedModule,
  ],
  declarations: [
    AnniversaryWidgetComponent,
  ],
  exports: [
    AnniversaryWidgetComponent,
  ]
})
export class AnniversaryModule { }
