import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { UserLink } from '@app/subscribe/model/user-link';
import { UserLinksViewModel } from '@app/subscribe/model/user-links-view-model';
import { User } from '@app/profile/model/user.model';
import { UsersService } from '@app/profile/services/users.service';
import { SubscribeService } from '@app/subscribe/services/subscribe.service';
import { AddUsersComponent } from '@app/shared/components/add-users/add-users.component';
import { Tab } from '@app/shared/components/tabs/tab.model';
import { SignalRService } from '@app/signalr/signalR.service';
import { TabbedListComponent } from '@app/shared/components/tabs/tabbed-list.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { takeUntil, take, finalize } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';

@Component({
    selector: 'app-colleagues-list',
    templateUrl: 'colleagues-list.component.html'
})

export class ColleaguesListComponent extends TabbedListComponent<UserLink> implements OnInit, OnDestroy {
    user: User;
    currentUser: User;
    showPicture = true;

    isCurrent = false;

    limit = 10;

    protected maxItemHeight = 110;

    addUsersDialog: MatDialogRef<AddUsersComponent>;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        private usersService: UsersService,
        private subscribeService: SubscribeService,
        private signalRService: SignalRService,
        private dialog: MatDialog,
        public helper: HelperService) { super(router, route, helper); }

    ngOnInit() {
        this.usersService.currentUser.subscribe(currentUser => {
            this.usersService.user.pipe(takeUntil(this.destroyed$)).subscribe(user => {
                // set user
                this.user = user;
                // check user
                if (user) {

                    this.currentUser = currentUser;
                    this.isCurrent = this.user.id === currentUser.id;

                    this.tabs = {
                        title: 'Коллеги',
                        routerLink: `/profile/${user.id}/colleagues`,
                        items: [
                            {
                                id: 1,
                                title: 'Подписан',
                                fragment: 'subscriptions',
                                name: 'subscriptions',
                                items: [],
                                offset: 0,
                                itemsCount: 0,
                                method: this.getSubscriptions.bind(this),
                                default: true,
                                emptyText: 'Вы ещё ни на кого не подписались'
                            },
                            {
                                id: 2,
                                title: 'Подписчики',
                                fragment: 'subscribers',
                                name: 'subscribers',
                                items: [],
                                offset: 0,
                                itemsCount: 0,
                                method: this.getSubscribers.bind(this),
                                emptyText: 'На вас ещё никто не подписан'
                            }
                        ]
                    };
                    this.route.fragment.pipe(takeUntil(this.destroyed$)).subscribe(val => {

                        this.mobHeight = window.innerHeight;
                        this.setNewLimit();

                        // get hash
                        if (val != null) {
                            const keys = val.split('&');
                            const hash = {};
                            keys.forEach(key => {
                                // tslint:disable-next-line:no-shadowed-variable
                                const val = key.split('=');
                                hash[val[0]] = val[1];
                            });

                            this.currentTab = null;

                            this.tabs.items.forEach(tab => {
                                if (tab.fragment && keys.find(k => k === tab.fragment)) {
                                    this.currentTab = tab;
                                }
                            });

                            if (!this.currentTab) {
                                this.currentTab = this.tabs.items[0];
                            }
                        } else {
                            this.currentTab = this.tabs.items[0];
                        }

                        // получить данные по всем табикам
                        this.tabs.items.forEach(tab => {
                            if (!tab.loaded) {
                                tab.method(tab, this);
                            } else if (this.currentTab.id === tab.id) {
                                tab.offset = 0;
                                tab.method(tab, this);
                            }
                        });
                    });
                }
            });
        });

        this.subscribeService.subscribedOnUser$.subscribe(res => {
            if (this.currentTab && this.currentTab.name === 'subscriptions') {
                this.currentTab.offset = 0;
                this.currentTab.method(this.currentTab, this);
            }
        });
    }

    operateSubscriptions(method: Observable<UserLinksViewModel>, tab: Tab<UserLink>) {
        tab.loading = true;
        method.pipe(
            finalize(() => {
                this.loaded = tab.loaded = true;
                tab.loading = false;
            }),
            takeUntil(this.destroyed$)).subscribe(res => {
                if (!tab.items || tab.offset === 0) {
                    tab.items = [];
                }

                if (res) {
                    res.items.forEach(link => {
                        if (!tab.items.filter(g => g.user.id === link.user.id).length) {
                            tab.items.push(link);
                        }
                    });
                    tab.itemsCount = res.count;
                    tab.offset = tab.items.length;
                }
            });
    }

    getSubscriptions(tab: Tab<UserLink>) {
        if (this.user) {
            this.operateSubscriptions(this.subscribeService.getSubscriptions(this.user.id, tab.offset, this.limit), tab);
        } else {
            tab.items = [];
            tab.itemsCount = 0;
        }
    }

    getSubscribers(tab: Tab<UserLink>) {
        if (this.user) {
            this.operateSubscriptions(this.subscribeService.getSubscribers(this.user.id, tab.offset, this.limit), tab);
        } else {
            tab.items = [];
            tab.itemsCount = 0;
        }
    }

    showAddUser() {
        this.addUsersDialog = this.dialog.open(AddUsersComponent, {
            data: {
                placeholder: 'Введите имя, чтобы добавить коллегу',
                onOk: this.addUsers.bind(this)
            }
        });
    }

    addUsers(users: any) {
        if (users && users.length) {
            this.subscribeService.subscribeAll(users.map(u => u.id))
                .pipe(take(1))
                .subscribe(res => {
                    if (res) {
                        this.addUsersDialog.close();
                        // отправить изменение состояния подписки в другие виджеты
                        this.subscribeService.subscribedOnUser$.next();
                    } else {
                        this.addUsersDialog.componentInstance.data.error = 'Не удалось подписаться на пользователя';
                    }
                }, error => {
                    this.addUsersDialog.componentInstance.data.error = 'Не удалось подписаться на пользователя';
                });
        }
    }

    /**
     * Отображать кнопки подписки пользователя
     *
     * @param {UserLink} link
     * @returns
     * @memberof ColleaguesListComponent
     */
    showSubscribeButtons(link: UserLink) {
        return this.currentUser && link && link.user && this.currentUser.id !== link.user.id;
    }
}
