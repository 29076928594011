import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Helper } from '@app/core/helpers/helper';
import { BaseService } from '@app/core/services/base.service';
import { HelperService } from '@app/core/services/helper.service';
import { UsersService } from '@app/profile/services/users.service';
import { environment } from '@env/environment';

export interface IFileUpload {
  guid: string;
  ownerId: number;
  authorId?: number;
  fileName: string;
  startTime: Date;
  digestTime: Date;
  endTime: Date;
  overwrite?: boolean;

  // ui
  uploaded?: boolean;
  error?: any;
}

@Injectable({
  providedIn: 'root'
})
export class FileUploadV2Service extends BaseService {

  protected serviceUrl = `${environment.siteUrl}/api/files`;

  protected uploadedFiles: Array<IFileUpload> = [];

  protected chunkSize = 10 * 1024 * 1024;

  constructor(
    protected http: HttpClient,
    protected usersService: UsersService,
    public helper: HelperService) {
    super(http, helper);
  }

  uploadFile(
    albumId: number,
    fileName: string,
    file: any,
    overwrite: boolean,
    authorId: number,
    ownerId: number,
  ) {

    const newUpload = {
      guid: Helper.guid(),
      albumId,
      ownerId,
      fileName,
      startTime: new Date(),
      digestTime: new Date(),
      endTime: null,
      authorId,
      overwrite,
    };

    return this.uploadFileInternal(newUpload, fileName, file);
  }

  protected uploadFileInternal(
    fileUpload: IFileUpload,
    fileName: string,
    file: any,
  ) {

    this.uploadedFiles.push(fileUpload);

    fileName = fileName.replace(/[\/\\#+$%'":*?<>]/g, ' ');
    console.log(fileName);

    let offset = 0;
    const total = file.size;
    let totalChunks = 1;
    if (total > this.chunkSize) {
      totalChunks = Math.ceil(total / this.chunkSize);
    }
    totalChunks++;
    const chunks = [];

    return new Promise((resolve, reject) => {
      for (let i = 0; i < totalChunks; i++) {
        offset = i * this.chunkSize;
        if (this.chunkSize > total) {
          length = total;
        } else if (total - offset > this.chunkSize) {
          length = this.chunkSize;
        } else {
          length = total - offset;
        }
        chunks.push({
          offset,
          length,
          method: this.getUploadMethod(fileUpload.guid, i, totalChunks),
          upload: fileUpload
        });
      }

      // each chunk is worth a percentage of the total size of the file...
      const chunkPercentage = (total / chunks.length) / total * 100;
      console.log('Chunk Percentage: ' + chunkPercentage);

      if (chunks.length > 0) {
        // Start the upload - send the data to S
        this.uploadFileRecursive(
          file, fileUpload.guid, fileName, chunks, 0, chunkPercentage, resolve, reject);
      }
    });
  }

  // the primary method that resursively calls to get the chunks and upload them to the library (to make the complete file)
  private uploadFileRecursive(
    file, guid, fileName, chunks, index, chunkPercentage, resolve, reject) {

    // we slice the file blob into the chunk we need to send in this request (byteOffset tells us the start position)
    const data = this.convertFileToBlobChunks(file, chunks[index]);

    // upload the chunk to the server using REST, using the unique upload guid as the identifier
    this.uploadFileChunk(guid, fileName, chunks[index], data).then(value => {
      const isFinished = index === chunks.length - 1;
      index += 1;
      const percentageComplete = isFinished ? 100 : Math.round((index * chunkPercentage));
      console.log('Percentage Completed:' + percentageComplete);

      if (index < chunks.length) {
        this.uploadFileRecursive(
          file, guid, fileName, chunks, index, chunkPercentage, resolve, reject);
      } else {
        resolve(value);
        const currentUpload = this.getUploadingFile(guid);
        currentUpload.uploaded = true;
      }
    }).catch(err => {
      console.log('Error in uploadFileChunk! ' + err);
      const currentUpload = this.getUploadingFile(guid);
      currentUpload.error = err;
      reject(err);
    });
  }

  private convertFileToBlobChunks(result, chunkInfo) {
    try {
      return result.slice(chunkInfo.offset, chunkInfo.offset + chunkInfo.length);
    } catch (e) {
      return null;
    }
  }

  private execute(guid, fileName, chunk, data) {
    return new Promise((resolve, reject) => {
      const formData: FormData = new FormData();
      formData.append('filename', decodeURIComponent(fileName));
      if (data) {
        formData.append('file', data, decodeURIComponent(fileName));
      }
      formData.append('overwrite', chunk.overwrite ? 'true' : 'false');
      formData.append('authorId', chunk.authorId ? `${chunk.authorId}` : null);
      formData.append('ownerId', chunk.ownerId ? `${chunk.ownerId}` : null);
      formData.append('albumId', chunk.albumId ? `${chunk.albumId}` : null);
      formData.append('guid', guid);
      this.executePost(chunk.method, formData).then(offset => resolve(offset)).catch(err => reject(err));
    });
  }

  async executePost(url: string, data: FormData) {
    const res = await super.post(url, data).toPromise().catch((err: HttpErrorResponse) => {
      const error = err.error;
      return error;
    });
    return this.parseRetSingle(res);
  }

  private parseRetSingle(res) {
    if (res) {
      if (res.hasOwnProperty('d')) {
        return res.d;
      } else if (res.hasOwnProperty('error')) {
        const obj: any = res.error;
        obj.hasError = true;
        return obj;
      } else {
        return {
          hasError: true,
          comments: res
        };
      }
    } else {
      return {
        hasError: true,
        comments: 'Check the response in network trace'
      };
    }
  }

  private uploadFileChunk(guid, fileName, chunk, data) {
    return this.execute(guid, fileName, chunk, data);
  }

  private getUploadingFile(guid) {
    return this.uploadedFiles.find(s => s.guid === guid);
  }

  protected getUploadMethod(guid: string, chunkIndex: number, chunkCount: number) {
    if (chunkIndex === chunkCount - 1) {
      return `upload/${guid}/finish`;
    } else if (chunkIndex === 0) {
      return `upload/${guid}/start`;
    } else if (chunkIndex < chunkCount) {
      return `upload/${guid}/continue`;
    }
    return null;
  }
}
