import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from '@app/profile/services/users.service';
import { SubscribeService } from '@app/subscribe/services/subscribe.service';
import { SubscriptionResultType } from '@app/subscribe/model/subscription-result-type';
import { GroupsService } from '@app/groups/services/groups.service';
import { User } from '@app/profile/model/user.model';
import { FilesService } from '@app/files/services/files.service';
import { Helper } from '@app/core/helpers/helper';
import { BaseComponent } from '@app/core/components/base.component';
import { take, takeUntil } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';

/**
 * Компонент левого меню
 */
@Component({
    selector: 'app-menu',
    templateUrl: 'menu.component.html'
})
export class MenuComponent extends BaseComponent implements OnInit, OnDestroy {
    visible: boolean;
    userId: number;
    colleaguesCount = 0;
    groupsCount = 0;
    filesCount = 0;

    colleaguesLoaded = false;
    groupsLoaded = false;
    filesLoaded = false;

    currentUser: User;

    constructor(
        private router: Router,
        private usersService: UsersService,
        private groupsService: GroupsService,
        private filesService: FilesService,
        private subscribeService: SubscribeService,
        public helper: HelperService) { super(helper); }

    ngOnInit() {
        this.usersService.currentUser.subscribe(currentUser => {
            this.userId = currentUser.id;
            this.currentUser = currentUser;
        }, error => {
            console.log(error);
        });

        this.subscribeService.currentUserColleaguesCount.pipe(takeUntil(this.destroyed$)).subscribe(resCount => {
            this.colleaguesCount = resCount;
        });

        this.groupsService.currentUserGroupsCount.pipe(takeUntil(this.destroyed$)).subscribe(resCount => {
            this.groupsCount = resCount;
        });

        this.helper.hideMenu$.subscribe(() => {
            this.visible = false;
        });
    }

    toggleMenu(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.visible = !this.visible;
        if (this.visible) {
            this.usersService.currentUser.subscribe(resCurrentUser => {
                this.subscribeService.getCount(resCurrentUser.id, SubscriptionResultType.All)
                    .pipe(take(1))
                    .subscribe(resCount => {
                        this.subscribeService.currentUserColleaguesCount.next(resCount);
                        this.colleaguesLoaded = true;
                    }, error => {
                        this.colleaguesLoaded = true;
                    });
                this.groupsService.getUserGroupsCount(resCurrentUser.id)
                    .pipe(take(1))
                    .subscribe(resCount => {
                        this.groupsService.currentUserGroupsCount.next(resCount);
                        this.groupsLoaded = true;
                    }, error => {
                        this.groupsLoaded = true;
                    });
            });
        }
        Helper.addOrRemoveClassToBody(this.visible);
    }

    navigate() {
        this.visible = false;
        Helper.addOrRemoveClassToBody(this.visible);
    }

    createPost(e) {
        throw new Error('Method not implemented.');
    }

    addColleague(e) {
        e.preventDefault();
        e.stopPropagation();
        this.usersService.currentUser.subscribe(currentUser => {
            this.visible = false;
            this.router.navigate([`profile/${currentUser.id}/colleagues`], { fragment: 'add' });
        });
    }

    createGroup(e) {
        e.preventDefault();
        e.stopPropagation();
        this.visible = false;
        this.router.navigate([`/group/new`]);
    }

    createFile(e) {
        throw new Error('Method not implemented.');
    }

    clickOutside() {
        this.visible = false;
        Helper.addOrRemoveClassToBody(this.visible);
    }
}
