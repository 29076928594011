import { BehaviorSubject } from 'rxjs';

/**
 * User
 */
export class User {
    public id?: number;
    public firstName?: string;
    public lastName?: string;
    public fullName?: string;
    public accountName?: string;
    public pictureUrl?: string;
    public email?: string;
    public jobTitle?: string;
    public department?: string;
    public birthday?: Date;
    public workPhone?: string;
    public mobilePhone?: string;
    public isAdmin?: boolean;
    public isBusinessGroupsAdmin?: boolean;
    public isGroupsAdmin?: boolean;
    public isVacancyAdmin?: boolean;
    public isIdeasAdmin?: boolean;
    public isAwardsAdmin?: boolean;
    public isBannerAdmin?: boolean;
    public url?: string;
    public lcid?: number;
    public canSubscribe?: boolean;
    public disabled?: boolean;
    public deleted?: Date;
    public russianPatronymic?: string;
    public absenceType?: AbsenceType;
    public absenceDateStart?: Date;
    public absenceDateEnd?: Date;
    public managerLogin?: string;
    public userType?: UserType;

    public skypeStatus: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    public image: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

    // ui
    birthdayText: string;
    congratulate: boolean;
    imageUrl?: string;
    absenceText?: string;
}

export interface IUserProfileProperty {
    displayName: string;
    type: string;
    isEditable: boolean;
    name: string;
    privacy: Privacy;
    userOverridePrivacy: boolean;
    value: string;

    // ui
    data: any;
    loaded?: boolean;
    showPopup?: boolean;
    dataLoaded?: boolean;
    error?: string;
    searchText?: string;
    dataFound?: any[];
    hidden?: boolean;
}

export enum Privacy {
    //
    // Summary:
    //     Privacy level gives visibility of users' profile properties, and other My Site
    //     content, to everyone.
    Public = 1,
    //
    // Summary:
    //     Privacy level limits the visibility of users' profile properties, and other My
    //     Site content, to my colleagues.
    Contacts = 2,
    //
    // Summary:
    //     Privacy level limits the visibility of users' profile properties, and other My
    //     Site content, to my workgroup.
    Organization = 4,
    //
    // Summary:
    //     Privacy level limits the visibility of users' profile properties, and other My
    //     Site content, to my manager and me.
    Manager = 8,
    //
    // Summary:
    //     Privacy level limits the visibility of users' profile properties, and other My
    //     Site content, to me only.
    Private = 16,
    //
    // Summary:
    //     Privacy level is not set.
    NotSet = 1073741824
}


export interface IUserPhotoChanged {
    userId: number;
    url: string;
}

export enum AbsenceType {
    none = 0,
    common = 1,
    vacation = 2,
    sick = 3,
    errand = 4,
}

export enum UserType {
    unknown = 0,
    local = 1,
    external = 2,
}
