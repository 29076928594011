import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ComponentFactoryResolver,
  Type,
  ElementRef,
  AfterViewChecked,
  ChangeDetectorRef
} from '@angular/core';
import { UsersService } from '@app/profile/services/users.service';
import { GalleryService } from '@app/gallery/services/gallery.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { IAlbum } from '@app/gallery/model/album-model';
import { BaseComponent } from '@app/core/components/base.component';
import { AlertsService } from '@app/shared/services/alerts.service';
import { DynamicContainerDirective } from '@app/shared/directives/dynamic-container.directive';
import { User } from '@app/profile/model/user.model';
import { MediaListComponent } from '../../lists/media-list/media-list.component';
import { switchMap, takeUntil, take, finalize } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { IAlbumMenuConfig } from '../../menu/album-menu/album-menu.component';
import { IAlbumVotingComponentResult } from '../album-voting/album-voting.component';

@Component({
  selector: 'app-album-view',
  templateUrl: './album-view.component.html',
  styleUrls: ['./album-view.component.scss']
})
export class AlbumViewComponent extends BaseComponent implements AfterViewChecked, OnInit, OnDestroy {
  /**
   * Сообщение слишком длинное и нужно показать кнопку "Читать дальше"
   *
   * @type {boolean}
   * @memberof MessageComponent
   */
  showButton: boolean;
  heightIsChecked: boolean;
  @ViewChild('albumDescription') albumDescriptionElement: ElementRef;

  albumName = '';
  albumNameFocused: boolean;
  albumDescription: string;
  updating: boolean;
  isAlreadyExists: boolean;

  isValid = false;
  wasEdit = false;
  allowEdit = false;

  album: IAlbum;

  @ViewChild(DynamicContainerDirective, { static: true }) dynamicContainer: DynamicContainerDirective;

  mediaListComponent: any;

  albumMenuConfig: IAlbumMenuConfig = {};

  protected mediaListType: Type<any> = MediaListComponent;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected usersService: UsersService,
    protected galleryService: GalleryService,
    protected alertsService: AlertsService,
    protected breadcrumbsService: BreadcrumbsService,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected cdr: ChangeDetectorRef,
    public helper: HelperService
  ) { super(helper); }

  ngOnInit() {

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.mediaListType);

    const viewContainerRef = this.dynamicContainer.viewContainerRef;
    viewContainerRef.clear();

    this.mediaListComponent = viewContainerRef.createComponent(componentFactory);
    (<MediaListComponent>this.mediaListComponent.instance).title = 'Медиафайлы альбома';
    (<MediaListComponent>this.mediaListComponent.instance).limit = 10;

    this.usersService.currentUser.subscribe(currentUser => {
      // get group from id parameter
      this.route.params
        .pipe(takeUntil(this.destroyed$), switchMap((params: Params) => {
          this.galleryService.currentAlbum.next(null);
          const albumId = +params['id'];
          if (albumId) {
            return this.galleryService.getAlbum(albumId);
          } else {
            this.router.navigate(['/404'], { skipLocationChange: true });
          }
        }))
        .subscribe(album => {
          this.album = album;
          this.loaded = true;
          this.galleryService.currentAlbum.next(album);

          if (this.album) {
            this.setBreadcrumbs(this.album);

            this.albumName = this.album.title;
            this.albumDescription = this.album.description;

            // проверка возможности редактирования альбома
            this.allowEditCheck(currentUser, this.album);

            (<MediaListComponent>this.mediaListComponent.instance).albumId = this.album.id;
            (<MediaListComponent>this.mediaListComponent.instance).load();
          } else {
            this.router.navigate(['/404'], { skipLocationChange: true });
          }
        }, error => {
          this.loaded = true;
          this.error = error;
        });
    });
  }

  /**
   * Редактировать альбом может только автор альбома или тот, чей профиль.
   * Так как это происходит в профиле пользователя.
   *
   * @protected
   * @param {User} currentUser текущий пользователь
   * @param {IAlbum} album Альбом
   * @memberof AlbumViewComponent
   */
  protected allowEditCheck(currentUser: User, album: IAlbum) {
    if (currentUser.id === album.ownerId
      || currentUser.id === album.author.id
      || currentUser.isAdmin) {
      this.allowEdit = true;
      this.albumMenuConfig = {
        allowEdit: false,
        allowDelete: true,
        allowAdd: true
      };
    } else {
      this.albumMenuConfig = {};
    }
  }

  /**
   * Установить хлебные крошки страницы
   *
   * @protected
   * @param {IAlbum} album текущий альбом
   * @memberof AlbumViewComponent
   */
  protected setBreadcrumbs(album: IAlbum) {
    this.breadcrumbsService.breadcrumbs.next([
      { title: 'Галерея', routerUrl: `/profile/${album.ownerId}/gallery` },
      { title: this.album.title, routerUrl: `/profile/${album.ownerId}/gallery/${album.id}` }
    ]);
  }

  ngAfterViewChecked() {
    if (!this.albumDescriptionElement) {
      return;
    }

    if (this.heightIsChecked) {
      return;
    }

    const albumDescriptionNativeElement = this.albumDescriptionElement.nativeElement;

    const editorHeight = Math.max(albumDescriptionNativeElement.scrollHeight, albumDescriptionNativeElement.offsetHeight);
    const MAX_EDITOR_HEIGHT = 70;

    if (editorHeight > MAX_EDITOR_HEIGHT) {
      this.showButton = true;

      this.heightIsChecked = true;
    }
  }

  showFullText() {
    this.showButton = false;
  }

  /**
   * Обновление альбома
   *
   * @protected
   * @memberof AlbumNewComponent
   */
  update() {
    if (this.isValid) {
      this.updating = true;
      this.isAlreadyExists = false;

      const updatedAlbum = {
        albumId: this.album.id,
        title: this.albumName,
        description: this.albumDescription,
      };

      this.galleryService.editAlbum(this.album.id, updatedAlbum).pipe(take(1), finalize(() => this.updating = false))
        .subscribe(newAlbumRes => {
          if (newAlbumRes) {
            this.album.title = this.albumName;
            this.album.description = this.albumDescription;
            this.wasEdit = false;
            this.alertsService.riseSuccess(`Альбом ${this.albumName} был успешно обновлён`);
          } else {
            this.alertsService.riseError(`Произошла ошибка при обновлении альбома ${this.album.title}`);
          }
        }, error => {
          this.alertsService.riseError(`Произошла ошибка при обновлении альбома ${this.album.title}`);
          if (error && error.error && error.error.ErrorCode === 14000) {
            this.isAlreadyExists = true;
          }
        });
    } else {
      console.error(`show not valid error`);
    }
  }

  /**
   * Сброс всех изменений
   *
   * @protected
   * @memberof AlbumNewComponent
   */
  cancel() {
    this.albumName = this.album.title;
    this.albumDescription = this.album.description;

    this.wasEdit = false;
  }

  /**
   * Обработка изменения значений в поле Название
   *
   * @protected
   * @param {*} e
   * @memberof AlbumNewComponent
   */
  onKey(e) {
    this.isValid = e.target.value && /\S/.test(e.target.value) ? true : false;
    this.wasEdit = true;
  }

  /**
   * Обработка изменения значений в поле Описание
   *
   * @protected
   * @memberof AlbumNewComponent
   */
  onKeyDescription(): void {
    this.isValid = this.albumDescription !== this.album.description;
    this.wasEdit = true;
  }

  /**
   * Действие при выборе файлов для загрузки
   *
   * @param {*} files
   * @memberof AlbumViewComponent
   */
  onFilesUploading(files): void {
    (<MediaListComponent>this.mediaListComponent.instance).dropFiles(files);
  }

  onVote(e: IAlbumVotingComponentResult) {
    if (e.dateEnd) {
      this.album.votingEndDate = new Date(e.dateEnd.format('YYYY-MM-DDTHH:mm:ss'));
      this.cdr.detectChanges();
    }
  }

  onDeleted(e: number) {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.galleryService.currentAlbum.next(null);
    this.breadcrumbsService.breadcrumbs.next(null);
  }
}
