import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { User } from '@app/profile/model/user.model';
import { UsersService } from '@app/profile/services/users.service';

/**
 * Главная страница администрирования
 *
 * @export
 * @class AdminMainComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-admin-main',
  templateUrl: './admin-main.component.html',
  styleUrls: ['./admin-main.component.scss']
})
export class AdminMainComponent extends BaseComponent {
  currentUser: User;

  constructor(
    public helper: HelperService,
    public usersService: UsersService
  ) {
    super(helper);

    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;
    });
  }

  isIdeasAdmin() {
    return this.currentUser && (this.currentUser.isAdmin || this.currentUser.isIdeasAdmin);
  }

  isVacancyAdmin() {
    return this.currentUser && (this.currentUser.isAdmin || this.currentUser.isVacancyAdmin);
  }
}
