import {Component, OnInit, OnDestroy, ViewChild, forwardRef} from '@angular/core';
import {BaseComponent} from '@app/core/components/base.component';
import {INewIdea, IUpdateIdea} from '@app/ideas/model/idea';
import {AttachmentViewModel} from '@app/attachments/file-attachments/model/attachment-view-model.model';
import {HelperService} from '@app/core/services/helper.service';
import {AlertsService} from '@app/shared/services/alerts.service';
import {IdeasService} from '@app/ideas/services/ideas.service';
import {takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ModalConfirmationComponent} from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import {ElementType} from '@app/core/model/element-type.enum';
import {AttachmentsListComponent} from '@app/attachments/file-attachments/components/attachments-list/attachments-list.component';
import {BreadcrumbsService} from '@app/shared/services/breadcrumbs.service';
import {GroupsService} from '@app/groups/services/groups.service';
import {IDatafileModel} from '@app/files/model/data-file-model';

/**
 * Страница создания идеи
 *
 * @export
 * @class IdeaNewComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-idea-new',
  templateUrl: './idea-new.component.html',
  styleUrls: ['./idea-new.component.scss'],
})
export class IdeaNewComponent extends BaseComponent implements OnInit, OnDestroy {
  elementType = ElementType.Idea;

  /**
   * Новая идея
   *
   * @type {(INewIdea | IUpdateIdea)}
   * @memberof IdeaNewComponent
   */
  idea: INewIdea | IUpdateIdea = {
    title: '',
    description: '',
    ownerId: null,
    docs: [],
  };

  /**
   * Отправка формы
   *
   * @type {boolean}
   * @memberof IdeaNewComponent
   */
  sending: boolean;

  /**
   * Вложения
   *
   * @type {AttachmentViewModel[]}
   * @memberof IdeaNewComponent
   */
  attachments: AttachmentViewModel[] = [];

  @ViewChild(forwardRef(() => AttachmentsListComponent)) protected attachmentsList: AttachmentsListComponent;

  isAlreadyExists: boolean;

  constructor(
    public groupsService: GroupsService,
    public ideasService: IdeasService,
    protected alertsService: AlertsService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected breadcrumbsService: BreadcrumbsService,
    protected dialog: MatDialog,
    public helper: HelperService,
  ) {
    super(helper);
  }

  ngOnInit() {
    this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
      if (group) {
        this.idea.ownerId = group.id;

        // обновляем хлебные крошки
        const ideasRoute = `/group/${group.id}/ideas`;

        this.breadcrumbsService.breadcrumbs.next([{title: 'Идеи', routerUrl: ideasRoute}, {title: 'Новая идея'}]);
      }
    });
  }

  /**
   * Отправить данные формы на сервер
   *
   * @memberof SurveyNewComponent
   */
  submit() {
    this.sending = true;
    this.isAlreadyExists = false;
    this.checkAttachments();

    this.ideasService
      .create(this.idea)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        res => {
          this.sending = false;
          this.alertsService.riseSuccess(`Идея '${this.idea.title}' была успешно создана`);
          this.router.navigate([`../${res.id}`], {relativeTo: this.route});
          // отправляем событие о создании в сервис
          this.ideasService.ideaAdded$.next();
        },
        error => {
          this.sending = false;
          this.alertsService.riseError(`Произошла ошибка при создании идеи '${this.idea.title}'`);
          if (error && error.error && error.error.ErrorCode === 17000) {
            this.isAlreadyExists = true;
          }
        },
      );
  }

  /**
   * Отмена изменений
   *
   * @memberof SurveyNewComponent
   */
  cancel() {
    this.dialog.open(ModalConfirmationComponent, {
      data: {
        text: `Вы действительно хотите уйти с формы?`,
        onOk: () => {
          this.router.navigate([`..`], {relativeTo: this.route});
        },
      },
    });
  }

  /**
   * Действие при удалении вложения
   *
   * @param {AttachmentViewModel} attachment
   * @memberof VacancyNewComponent
   */
  onRemoveAttachment(attachment: AttachmentViewModel) {
    this.attachments = this.attachments.filter(
      att =>
        (attachment.attachment.guid && att.attachment.guid !== attachment.attachment.guid) ||
        (!attachment.attachment.guid && att.attachment.title !== attachment.attachment.title),
    );

    this.idea.docs = this.idea.docs.filter(
      att =>
        (attachment.attachment.guid && att.guid !== attachment.attachment.guid) ||
        (!attachment.attachment.guid && att.title !== attachment.attachment.title),
    );
  }

  /**
   * Действие при загрузке вложения
   *
   * @param {ShareableObject[]} event
   * @memberof VacancyNewComponent
   */
  onFilesUploaded(event: IDatafileModel[]) {
    // add uploaded attachments
    if (event) {
      event.forEach(file => {
        if (!this.idea.docs.find(s => s.guid === file.guid || s.title === file.name)) {
          this.idea.docs.push({
            title: file.name,
            modified: file.created,
            ownerID: file.authorId,
            url: `/api/documentFile/${file.guid}`,
            guid: file.guid,
          });
        }
      });
    }
    this.sending = false;
  }

  /**
   * Событие на обновлении файла
   *
   * @param {*} e
   * @memberof IdeaNewComponent
   */
  onUploadFile(e) {
    this.sending = true;
    this.attachmentsList.onUploadFile(e);
    this.attachmentsList.uploadAttachedFiles(ElementType.Idea);
  }

  /**
   * Проверить вложения перед созданием
   *
   * @protected
   * @memberof IdeaNewComponent
   */
  protected checkAttachments(): void {
    if (this.attachments) {
      this.attachments.forEach(att => {
        if (!this.idea.docs.find(s => s.guid === att.attachment.guid || s.title === att.attachment.title)) {
          this.idea.docs.push({
            title: att.attachment.title,
            modified: att.attachment.modified,
            ownerID: this.idea.ownerId,
            url: att.attachment.url,
            guid: att.attachment.guid,
          });
        }
      });
    }
  }

  isValid(): boolean {
    return !this.sending && this.idea && !!this.idea.title && !!this.idea.description;
  }
}
