import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { IIdea } from '@app/ideas/model/idea';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import { GroupsService } from '@app/groups/services/groups.service';
import { UsersService } from '@app/profile/services/users.service';
import { IdeasService } from '@app/ideas/services/ideas.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertsService } from '@app/shared/services/alerts.service';
import { HelperService } from '@app/core/services/helper.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { User } from '@app/profile/model/user.model';

export interface IIdeaMenuConfig {
  allowPublish?: boolean;
  allowEdit?: boolean;
  allowDelete?: boolean;
}

export enum IdeaMenuLocation {
  view = 1,
  ideasList = 2,
  groupIdeasWidget = 3
}

@Component({
  selector: 'app-idea-menu',
  templateUrl: './idea-menu.component.html',
  styleUrls: ['./idea-menu.component.scss']
})
export class IdeaMenuComponent extends BaseComponent implements OnDestroy {

  @Input() config: IIdeaMenuConfig;

  @Input() selectedItems: IIdea[];
  @Input() item: IIdea;
  @Input() ideaLocation: IdeaMenuLocation;

  @Output() deleted: EventEmitter<number> = new EventEmitter();

  private currentUser: User;
  private deletingDialog: MatDialogRef<ModalConfirmationComponent>;

  constructor(
    protected dialog: MatDialog,
    protected groupsService: GroupsService,
    protected usersService: UsersService,
    protected ideasService: IdeasService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected alertsService: AlertsService,
    public helper: HelperService) {

    super(helper);
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;
    });
  }

  /**
   * Редактировать можно только свои в статусе "Новая"
   *
   * @returns
   * @memberof IdeaMenuComponent
   */
  isShowEditButton(): boolean {
    return this.config && this.config.allowEdit
      && this.item
      && (this.item.status
        && this.item.status.title === 'Новая'
        && this.item.author
        && this.currentUser
        && this.item.author.id === this.currentUser.id);
  }

  isAnyItemInMenu(): boolean {
    const allowPublish = this.config && this.config.allowPublish;
    const allowDelete = this.config && this.config.allowDelete;

      return this.isShowEditButton()
          || allowPublish
          || allowDelete;
  }

  editClick() {
    this.router.navigate([`/group/${this.item.ownerId}/ideas/${this.item.id}/edit`]);
  }

  publishClick() {
    this.ideasService.publish(this.item.id)
      .pipe(take(1), takeUntil(this.destroyed$))
      .subscribe(res => {
        if (res) {
          this.item.published = true;
          this.alertsService.riseSuccess(`Идея ${this.item.title} была успешно опубликована`);
          // отправляем событие о изменении в сервис
          this.ideasService.ideaChanged$.next();
        } else {
          this.alertsService.riseError(`Произошла ошибка при публикации идеи`);
        }
      }, error => {
        const errorText = 'Произошла ошибка при публикации идеи.';
        console.log(`${errorText}: '${error}'`);
        this.alertsService.riseError(errorText);
      });
  }

  unPublishClick() {
    this.ideasService.unpublish(this.item.id)
      .pipe(take(1), takeUntil(this.destroyed$))
      .subscribe(res => {
        if (res) {
          this.item.published = false;
          this.alertsService.riseSuccess(`Идея ${this.item.title} была успешно снята с публикации`);
          // отправляем событие о изменении в сервис
          this.ideasService.ideaChanged$.next();
        } else {
          this.alertsService.riseError(`Произошла ошибка при снятии идеи с публикации`);
        }
      }, error => {
        const errorText = 'Произошла ошибка при снятии идеи с публикации.';
        console.log(`${errorText}: '${error}'`);
        this.alertsService.riseError(errorText);
      });
  }

  /**
   * Всплывающее окно подтверждения удаления идеи
   *
   * @private
   * @memberof MediaItemMenuComponent
   */
  deleteClick() {
    this.selectedItems = [this.item];
    let text;
    if (this.selectedItems && this.selectedItems.length > 1) {
      text = 'идеи?';
    } else {
      text = 'идею?';
    }
    this.deletingDialog = this.dialog.open(ModalConfirmationComponent, {
      data: {
        text: `Вы действительно хотите удалить ${text}`,
        onOk: this.deleteItem.bind(this),
        doNotHideOnOk: true
      }
    });
  }

  /**
   * Удаление медиа-файла
   *
   * @private
   * @memberof MediaItemMenuComponent
   */
  private deleteItem() {
    this.selectedItems.forEach(item => {
      this.ideasService.remove(this.item.id).subscribe(res => {
        if (this.deleted) {
          this.deleted.emit(item.id);
        }
        this.deletingDialog.close();
        this.alertsService.riseSuccess(`Идея ${item.title} была успешно удалёна`);
        // отправляем событие о удалении в сервис
        this.ideasService.ideaDeleted$.next();
      }, error => {
        const errorText = 'Произошла ошибка при удалении идеи.';
        console.log(`${errorText}: '${error}'`);
        this.alertsService.riseError(errorText);
      });
    });
  }
}
