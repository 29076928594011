import {Component, OnInit, OnDestroy, ViewChild, forwardRef} from '@angular/core';
import {IVacancyCategory, INewVacancy, VacancyStatus, IUpdateVacancy} from '@app/vacancy/model/vacancy';
import {BaseComponent} from '@app/core/components/base.component';
import {ModalConfirmationComponent} from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import {VacancyService} from '@app/vacancy/services/vacancy.service';
import {AlertsService} from '@app/shared/services/alerts.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BreadcrumbsService} from '@app/shared/services/breadcrumbs.service';
import {MatDialog} from '@angular/material/dialog';
import {HelperService} from '@app/core/services/helper.service';
import {take, takeUntil, finalize} from 'rxjs/operators';
import {IIntryProfile} from '@app/shared/model/intry-profile';
import {ProfileType} from '@app/core/model/profile-type.enum';
import {UsersService} from '@app/profile/services/users.service';
import {AttachmentsListComponent} from '@app/attachments/file-attachments/components/attachments-list/attachments-list.component';
import {User} from '@app/profile/model/user.model';
import {AttachmentViewModel} from '@app/attachments/file-attachments/model/attachment-view-model.model';
import {ElementType} from '@app/core/model/element-type.enum';
import {IDatafileModel} from '@app/files/model/data-file-model';

@Component({
  selector: 'app-vacancy-new',
  templateUrl: './vacancy-new.component.html',
  styleUrls: ['./vacancy-new.component.scss'],
})
export class VacancyNewComponent extends BaseComponent implements OnInit, OnDestroy {
  elementType = ElementType;

  vacancy: INewVacancy | IUpdateVacancy = {
    title: '',
    description: '',
    location: '',
    ownerId: null,
    categoryId: null,
    status: null,
    docs: [],
  };

  category: IVacancyCategory;
  categories: IVacancyCategory[];

  @ViewChild(forwardRef(() => AttachmentsListComponent)) protected attachmentsList: AttachmentsListComponent;

  sending: boolean;
  showScope = true;
  fullView = false;

  mentions: User[] = [];
  attachments: AttachmentViewModel[] = [];

  categoriesLoaded: boolean;

  constructor(
    public usersService: UsersService,
    public vacancyService: VacancyService,
    protected alertsService: AlertsService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected breadcrumbsService: BreadcrumbsService,
    protected dialog: MatDialog,
    public helper: HelperService,
  ) {
    super(helper);
    // обновляем хлебные крошки
    this.usersService.currentUser.subscribe(currentUser => {
      const vacancyRoute = `/profile/${currentUser.id}/vacancy`;

      this.breadcrumbsService.breadcrumbs.next([
        {title: 'Вакансии', routerUrl: vacancyRoute},
        {title: 'Новая вакансия'},
      ]);
    });
  }

  ngOnInit() {
    // получаем категории вакансий
    this.vacancyService
      .getVacancyCategories(0, 20)
      .pipe(
        take(1),
        finalize(() => (this.categoriesLoaded = true)),
        takeUntil(this.destroyed$),
      )
      .subscribe(res => {
        this.categories = res ? res : [];
      });
  }

  /**
   * Отправить данные формы на сервер
   *
   * @memberof SurveyNewComponent
   */
  submit() {
    this.vacancy.status = VacancyStatus.published;
    this.send();
  }

  /**
   * Отмена изменений
   *
   * @memberof SurveyNewComponent
   */
  cancel() {
    this.dialog.open(ModalConfirmationComponent, {
      data: {
        text: `Вы действительно хотите уйти с формы?`,
        onOk: () => {
          this.router.navigate([`..`], {relativeTo: this.route});
        },
      },
    });
  }

  /**
   * Сохранить как ерновик
   *
   * @memberof SurveyNewComponent
   */
  draft() {
    this.vacancy.status = VacancyStatus.draft;
    this.send();
  }

  /**
   * Отправить данные формы на сервер
   *
   * @private
   * @memberof SurveyNewComponent
   */
  protected send() {
    this.vacancy.categoryId = this.category ? this.category.id : null;
    this.sending = true;

    this.checkAttachments();

    this.vacancyService
      .create(this.vacancy)
      .pipe(take(1))
      .subscribe(
        res => {
          this.sending = false;
          if (this.vacancy.status === VacancyStatus.published) {
            this.alertsService.riseSuccess(`Вакансия '${this.vacancy.title}' была успешно создана`);
            this.router.navigate([`../${res.id}`], {relativeTo: this.route});
          } else {
            this.router.navigate([`../${res.id}/edit`], {relativeTo: this.route});
            this.alertsService.riseSuccess(`Вакансия '${this.vacancy.title}' была успешно сохранена как черновик`);
          }
        },
        error => {
          this.sending = false;
          this.alertsService.riseError(`Произошла ошибка при создании вакансии '${this.vacancy.title}'`);
        },
      );
  }

  onProfileSelected(profile: IIntryProfile) {
    if (!profile || profile.profileType !== ProfileType.Group) {
      this.alertsService.riseError(`Ошибка при выборе группы для создания опроса`);
    } else {
      this.vacancy.ownerId = profile.profile.id;
    }
  }

  expand() {
    this.fullView = true;
  }

  /**
   * Действие при удалении вложения
   *
   * @param {AttachmentViewModel} attachment
   * @memberof VacancyNewComponent
   */
  onRemoveAttachment(attachment: AttachmentViewModel) {
    this.attachments = this.attachments.filter(
      att =>
        (attachment.attachment.guid && att.attachment.guid !== attachment.attachment.guid) ||
        (!attachment.attachment.guid && att.attachment.title !== attachment.attachment.title),
    );

    this.vacancy.docs = this.vacancy.docs.filter(
      att =>
        (attachment.attachment.guid && att.guid !== attachment.attachment.guid) ||
        (!attachment.attachment.guid && att.title !== attachment.attachment.title),
    );
  }

  /**
   * Действие при загрузке вложения
   *
   * @param IDatafileModel[] event
   * @memberof VacancyNewComponent
   */
  onFilesUploaded(event: IDatafileModel[]) {
    // add uploaded attachments
    if (event) {
      event.forEach(file => {
        if (!this.vacancy.docs.find(s => s.guid === file.guid || s.title === file.name)) {
          this.vacancy.docs.push({
            title: file.name,
            modified: file.created,
            ownerID: file.authorId,
            url: `/api/documentFile/${file.guid}`,
            guid: file.guid,
          });
        }
      });
    }
    this.sending = false;
  }

  onUploadFile(e) {
    this.attachmentsList.onUploadFile(e);
    this.sending = true;
    this.attachmentsList.uploadAttachedFiles(ElementType.Vacancy);
  }

  isValid() {
    return (
      !this.sending &&
      this.vacancy &&
      this.vacancy.title &&
      this.vacancy.description &&
      this.vacancy.description !== '<div><br></div>' &&
      this.vacancy.ownerId
    );
  }

  protected checkAttachments(): void {
    if (this.attachments) {
      this.attachments.forEach(att => {
        if (!this.vacancy.docs.find(s => s.guid === att.attachment.guid || s.title === att.attachment.title)) {
          this.vacancy.docs.push({
            title: att.attachment.title,
            modified: att.attachment.modified,
            ownerID: this.vacancy.ownerId,
            url: att.attachment.url,
            guid: att.attachment.guid,
          });
        }
      });
    }
  }
}
