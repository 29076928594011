import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from '@app/core/components/base.component';
import { Helper } from '@app/core/helpers/helper';
import { HelperService } from '@app/core/services/helper.service';

export interface IUserAvatarViewData {
  url: string,
  fullName: string,
}

@Component({
  selector: 'app-user-avatar-view',
  templateUrl: './user-avatar-view.component.html',
  styleUrls: ['./user-avatar-view.component.scss']
})
export class UserAvatarViewComponent extends BaseComponent implements OnInit {

  pictureUrl: string;
  originPictureUrl: string;

  constructor(
    public dialogRef: MatDialogRef<UserAvatarViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IUserAvatarViewData,
    // public usersService: UsersService,
    public helper: HelperService) {

    super(helper);

    const win = <any>window;

    if (this.data.url) {
      this.pictureUrl = Helper.concatUrls(Helper.concatUrls(win.Intry.mySiteHost, 'User photos/Profile pictures'), this.data.url)
      this.originPictureUrl = this.pictureUrl.replace('_LThumb', '_origin');
    }
  }

  ngOnInit() {

  }

}
