import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '@app/profile/services/users.service';
import { FeedService } from '@app/feed/services/feed.service';
import { SubscribeService } from '@app/subscribe/services/subscribe.service';
import { LikesService } from '@app/likes/services/likes.service';
import { SignalRService } from '@app/signalr/signalR.service';
import { UserFeedComponent } from '@app/feed/components/user-feed/user-feed.component';
import { takeUntil } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { FeedType } from '@app/feed/model/feed-type.model';
import { PostProfileSelectorType } from '@app/feed/model/post-profile-selector-type';

/**
 * Лента публикаций пользователя по ссылке profile/{id}
 * Отображаются только свои посты или посты которые коллеги напрямую добавили в ленту пользователя
 *
 * @export
 * @class ProfileFeedComponent
 * @extends {UserFeedComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-profile-feed',
  templateUrl: '../feed-base/feed-base.component.html'
})
export class ProfileFeedComponent extends UserFeedComponent implements OnInit, OnDestroy {

  selectorType: PostProfileSelectorType = PostProfileSelectorType.exceptBusiness;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected usersService: UsersService,
    protected subscribeService: SubscribeService,
    protected feedService: FeedService,
    protected likesService: LikesService,
    protected signalrService: SignalRService,
    protected ngZone: NgZone,
    public helper: HelperService) {
    super(router, route, usersService, subscribeService, feedService, likesService, signalrService, ngZone, helper);
  }

  ngOnInit() {
    this.onFeedUpdate();
    this.usersService.user
      .pipe(takeUntil(this.destroyed$))
      .subscribe(user => {
        this.user = user;
        this.offset = this.lastOffset = 0;
        this.posts = [];
        this.getFeed();
      });
  }

  protected getFeedInternal() {
    if (this.user) {
      // получение постов
      this.operateFeedResult(this.feedService.getProfileFeed(this.user.id, this.offset, this.limit));
    } else {
      this.loading = true;
      this.loaded = false;
    }
  }

  getFeedType(): FeedType {
    return FeedType.ProfileFeed;
  }
}
