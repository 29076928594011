import {Component} from '@angular/core';
import {BaseComponent} from '@app/core/components/base.component';
import {takeUntil, take} from 'rxjs/operators';
import {GroupInfo} from '@app/groups/model/group-info.model';
import {GroupsService} from '@app/groups/services/groups.service';
import {PagesService} from '@app/pages/services/pages.service';
import {BreadcrumbsService} from '@app/shared/services/breadcrumbs.service';
import {Router, ActivatedRoute} from '@angular/router';
import {HelperService} from '@app/core/services/helper.service';
import {IPage} from '@app/pages/model/page';
import {IPageMenuConfig} from '@app/pages/components/menu/page-menu/page-menu.component';
import { UsersService } from '@app/profile/services/users.service';

@Component({
  selector: 'app-group-page-list',
  templateUrl: './group-page-list.component.html',
  styleUrls: ['./group-page-list.component.scss'],
})
export class GroupPageListComponent extends BaseComponent {
  group: GroupInfo;
  pages: IPage[] = [];
  showAddButton: boolean;
  showHidden = false;
  config: IPageMenuConfig;

  constructor(
    public usersService: UsersService,
    public groupsService: GroupsService,
    public pagesService: PagesService,
    protected breadcrumbsService: BreadcrumbsService,
    protected router: Router,
    protected route: ActivatedRoute,
    public helper: HelperService,
  ) {
    super(helper);
    this.usersService.currentUser.subscribe(currentUser => {
      this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
        if (group) {
          this.group = group;

          // обновляем хлебные крошки
          const pageRoute = `/group/${group.id}/pages`;

          this.breadcrumbsService.breadcrumbs.next([{title: 'Страницы', routerUrl: pageRoute}]);

          this.showAddButton = this.groupsService.isAdmin(this.group, currentUser);

          if (this.showAddButton) {
            this.config = {
              allowEdit: true,
              allowDelete: true,
            };
          }

          this.getItems();
        }
      });
    });
  }

  toggleHidden(e: Event) {
    this.showHidden = !this.showHidden;
    this.getItems();
  }

  getLink() {
    return this.group ? `/group/${this.group.id}/pages` : '';
  }

  getItems() {
    this.pagesService
      .getGroupPages(this.group.id, this.showHidden)
      .pipe(take(1))
      .subscribe(
        res => {
          if (res) {
            this.pages = res;
          }
          this.loaded = true;
        },
        error => {
          this.loaded = true;
        },
      );
  }
}
