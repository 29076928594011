<div class="card _files" *ngIf="loaded">
  <div class="card__title">
    <div class="pull-right">
      <app-files-menu [showAddButton]="showAddButton" (onFolderCreated)="onFolderCreated()" [withTree]="true" (onFilesAdded)="onFilesAdded($event)"></app-files-menu>
    </div>
    <div class="header-text">
      <a class="header-text__text" (click)="navigate()" [routerLink]="link" [fragment]="fragment">Файлы</a>&nbsp;
    </div>
  </div>
  <div class="card__content" *ngIf="showWidget">
    <div *ngFor="let item of items">
      <a attr.id="item_{{item.uniqueId}}" target="_blank"
        [attr.href]="filesService.webApiGetFileUrl(item, item.uniqueId)" [ngClass]="filesService.getFileIcon(item)"
        class="link-file" (click)="openFile($event,item)">
        <span class="link-file__text">{{item.name}}</span>
        <span class="link-file__subtext" *ngIf="filesService.getSize(item)">{{filesService.getSize(item)}} КБ</span>
      </a>
      <app-file-menu #fileMenu [item]="item" [webUrl]="webUrl" [isCurrentUser]="false" [config]="config"
        itemLink="item_{{item.uniqueId}}" [portalUrl]="portalUrl"></app-file-menu>
    </div>
  </div>
  <div class="card__content" *ngIf="!showWidget && group">
    <div class="group-empty">
      <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" class="group-empty__img">
      <div class="group-empty__text">Чтобы увидеть контент группы, вам необходимо
        <br /> вступить в нее или подписаться</div>
    </div>
  </div>
</div>
<div class="card" *ngIf="!loaded">
  <div class="card__title">
    <span class="preloader">
      <span class="preloader__line"></span>
    </span>
  </div>
  <div class="card__content">
    <span class="preloader _square" *ngFor="let r of [0,1,2,3]">
      <span class="preloader__side"></span>
      <span class="preloader__line"></span>
    </span>
  </div>
</div>