import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { BehaviorSubject } from 'rxjs';
import { HelperService } from '@app/core/services/helper.service';

/**
 * Базовый компонент виджетов
 *
 * @export
 * @class WidgetBaseComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-widget-base',
  template: ''
})
export class WidgetBaseComponent extends BaseComponent implements OnInit, OnDestroy {

  public showWidgetOld: boolean;
  public showWidget: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  /**
   * Ссылка на соответствующий раздел
   *
   * @type {string}
   * @memberof ColleaguesWidgetComponent
   */
  link: string;

  constructor(public helper: HelperService) { super(helper); }

  ngOnInit() {
    this.showWidget.next(this.calculate());
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.showWidget.next(this.calculate());
  }

  private calculate() {
    return window.innerWidth >= 1000 || window.innerWidth > window.innerHeight;
  }

  ngOnDestroy(): void {
    this.loaded = false;
    this.loading = false;
    this.showWidgetOld = false;
    this.showWidget.next(this.showWidgetOld);
    super.ngOnDestroy();
  }
}
