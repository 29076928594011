import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertsService } from '@app/shared/services/alerts.service';
import { Alert } from '@app/shared/components/alerts/alert';
import { Helper } from '@app/core/helpers/helper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { BaseComponent } from '@app/core/components/base.component';

/**
 * Компонент всплывающих уведомлений (об успешности или неуспешности выполнения какого-либо действия)
 *
 * @export
 * @class AlertsComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html'
})
export class AlertsComponent extends BaseComponent implements OnInit, OnDestroy {

  /**
   * Отображаемые уведомления
   *
   * @type {Alert[]}
   * @memberof AlertsComponent
   */
  items: Alert[] = [];

  /**
   * Задержка появления уведомления по умолчанию
   *
   * @memberof AlertsComponent
   */
  defaultDelay = 300;

  constructor(private alertsService: AlertsService,
    public helper: HelperService,
  ) { super(helper); }

  ngOnInit() {
    this.alertsService.alert.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      if (res) {
        this.items.push(res);
        res.id = Helper.getRandomArbitrary(1, 100);
        const that = this;
        setTimeout(() => {
          res.show = true;
        }, res.delayShow ? res.delayShow : that.defaultDelay);
        setTimeout(() => {
          that.items = that.items.filter(f => f.id !== res.id);
        }, res.delayHide ? res.delayHide : that.alertsService.DELAY_HIDE);
        this.alertsService.alert.next(null);
      }
    });
  }

  hideAlert(index: number) {
    try {
      this.items.splice(index, 1);
    } catch (e) {
      console.log(e);
    }
  }
}
