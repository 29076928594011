import { Injectable, EventEmitter } from '@angular/core';
import { CommentViewModel } from '@app/comments/model/comment-view-model.model';
import { HubConnection } from '@microsoft/signalr';

@Injectable()
export class CommentHub {

    public onNew: EventEmitter<CommentViewModel> = new EventEmitter<CommentViewModel>();
    public onUpdated: EventEmitter<CommentViewModel> = new EventEmitter<CommentViewModel>();
    public onDeleted: EventEmitter<string> = new EventEmitter<string>();

    // TODO: actions

    // TODO: register all client functions
    registerOnServerEvents(hubConnection: HubConnection): void {
        hubConnection.on('onNew', (data: CommentViewModel) => {
            this.onNew.emit(data);
        });
        hubConnection.on('onUpdated', (data: CommentViewModel) => {
            this.onUpdated.emit(data);
        });
        hubConnection.on('onDeleted', (data: string) => {
            this.onDeleted.emit(data);
        });
    }
}
