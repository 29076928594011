import { Injectable, EventEmitter } from '@angular/core';
import { PushNotification } from '@app/notifications/models/push-notification.interface';

declare const Notification: any;

/**
 * todo: https://sites.google.com/a/chromium.org/dev/Home/chromium-security/deprecating-powerful-features-on-insecure-origins
 */
@Injectable()
export class PushNotificationsService {

  onclick: EventEmitter<any> = new EventEmitter();

  private window: any;

  constructor() {
    this.window = window;
  }

  isSupported(): boolean {
    return 'Notification' in window;
  }

  isDenied(): Promise<boolean> {
    if (!this.isSupported()) {
      return Promise.resolve(true);
    }

    return this.getPermission()
      .then(permission => {
        return permission === 'denied';
      });
  }

  isDisabled(): boolean {
    return !this.getLocalStorageValue();
  }

  disable() {
    this.setLocalStorageValue(false);
  }

  enable() {
    this.requestPermission()
      .then(granted => {
        if (granted) {
          this.setLocalStorageValue(true);
        }
      });
  }

  requestPermission(): Promise<boolean> {
    return new Promise<string>(function (resolve, reject) {
      const promise = Notification.requestPermission(function (result) {
        resolve(result);
      });
      if (promise) {
        promise.then(resolve, reject);
      }
    }).then(function (permission) {
      return permission === 'granted';
    });
  }

  show(title: string, options?: PushNotification) {
    if (this.isDisabled()) {
      return;
    }

    this.requestPermission()
      .then(granted => {
        if (granted) {
          const notification = new Notification(title, options);
          notification.onclick = (e: any) => {
            this.onclick.emit(notification.data || {});
            e.target.close();
          };
        }
      });
  }

  getPermission(): Promise<string> {
    if (this.window.navigator.permissions) {
      return this.window.navigator.permissions.query({ name: 'notifications' })
        .then((result) => {
          return result.state;
        });
    }

    return new Promise((resolve) => {
      resolve(Notification.permission);
    });
  }

  getLocalStorageValue(): boolean {
    return (window.localStorage['web-notifications-enabled'] || '1') === '1';
  }

  setLocalStorageValue(value: boolean) {
    window.localStorage.setItem('web-notifications-enabled', value ? '1' : '0');
  }
}
