import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import { SurveysService } from '@app/surveys/services/surveys.service';
import { ISurvey } from '@app/surveys/model/survey';
import { take } from 'rxjs/operators';
import { AlertsService } from '@app/shared/services/alerts.service';
import { Router, ActivatedRoute } from '@angular/router';
import { GroupsService } from '@app/groups/services/groups.service';
import { UsersService } from '@app/profile/services/users.service';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { ProfileSelectorModalComponent } from '@app/shared/components/profile-selector-modal/profile-selector-modal.component';
import { IIntryProfile } from '@app/shared/model/intry-profile';
import { ProfileType } from '@app/core/model/profile-type.enum';

/**
 * Конфигурация меню (скрытие/отображение определённых элементов)
 *
 * @export
 * @interface ISurveyMenuConfig
 */
export interface ISurveyMenuConfig {
  allowResults?: boolean;
  allowEdit?: boolean;
  allowDelete?: boolean;
}

export enum SurveyMenuLocation {
  view = 1,
  surveysList = 2,
  userSurveysWidget = 3,
  groupSurveysWidget = 4,
}

/**
 * Компонент меню Опроса
 *
 * @export
 * @class SurveyMenuComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-survey-menu',
  templateUrl: './survey-menu.component.html',
  styleUrls: ['./survey-menu.component.scss']
})
export class SurveyMenuComponent extends BaseComponent {

  @Input() config: ISurveyMenuConfig;

  @Input() selectedItems: ISurvey[];
  @Input() item: ISurvey;
  @Input() surveyLocation: SurveyMenuLocation;

  @Input() whiteDots: boolean;

  @Output() deleted: EventEmitter<number> = new EventEmitter();

  private deletingDialog: MatDialogRef<ModalConfirmationComponent>;
  private copyingDialog: MatDialogRef<ProfileSelectorModalComponent>;

  constructor(
    protected dialog: MatDialog,
    protected groupsService: GroupsService,
    protected usersService: UsersService,
    protected surveysService: SurveysService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected alertsService: AlertsService,
    public helper: HelperService) { super(helper); }

  isVisible() {
    return this.config
      && (this.config.allowResults || this.config.allowEdit || this.config.allowDelete);
  }

  /**
   * Перейти на страницу результатов
   *
   * @memberof SurveyMenuComponent
   */
  resultsClick() {
    this.navigateToRoute('results');
  }

  /**
   * Перейти на страницу редактирования
   *
   * @memberof SurveyMenuComponent
   */
  editClick() {
    this.navigateToRoute('edit');
  }

  /**
   * Открыть всплывающее окно подтверждения удаления опроса
   *
   * @memberof SurveyMenuComponent
   */
  deleteClick() {
    this.deletingDialog = this.dialog.open(ModalConfirmationComponent, {
      data: {
        text: `Вы действительно хотите удалить опрос?`,
        onOk: this.delete.bind(this),
        doNotHideOnOk: true
      }
    });
  }

  openCopyItem() {
    this.copyingDialog = this.dialog.open(ProfileSelectorModalComponent, {
      minWidth: window.innerWidth - 300,
      data: {
        text: `В какую группу скопировать опрос?`,
        profileId: this.item.ownerId,
        onOk: this.copy.bind(this, this.item.id),
        okText: 'Копировать',
        doNotHideOnOk: true
      }
    });
  }

  private copy(surveyId: number, profile: IIntryProfile) {
    if (!profile || profile.profileType !== ProfileType.Group) {
      this.alertsService.riseError('Профиль не выбран, скопировать опрос невозможно');
      return;
    }

    this.surveysService.getById(surveyId)
      .subscribe(survey => {
        if (survey) {
          survey.ownerId = profile.profile.id;
          this.surveysService.copySurvey$.next(survey);
          if (this.copyingDialog) {
            this.copyingDialog.close();
          }
          this.router.navigate(['group', profile.profile.id, 'surveys', 'new']);
        } else {
          this.alertsService.riseError('Произошла ошибка при копировании опроса');
        }
      });
  }

  /**
   * Метод удаления всех выделенных опросов
   *
   * @private
   * @memberof SurveyMenuComponent
   */
  private delete() {
    this.checkSelectedItems();
    this.selectedItems.forEach(item => {
      const errorText = `Произошла ошибка при удалении опроса '${item.title}'`;
      this.surveysService.remove(item.id).pipe(take(1)).subscribe(res => {
        if (res) {
          this.alertsService.riseSuccess(`Опрос "${item.title}" был успешно удалён`);
        } else {
          this.alertsService.riseError(errorText);
        }
        this.deletingDialog.close();
        this.deleted.emit(item.id);
      }, error => {
        this.alertsService.riseError(errorText);
        this.deletingDialog.componentInstance.data.error = errorText;
      });
    });
  }

  private checkSelectedItems() {
    if (this.item) {
      this.selectedItems = [this.item];
    } else {
      this.selectedItems = [];
    }
  }

  private navigateToRoute(routeName) {
    let route = '';
    if (!this.surveyLocation || this.surveyLocation !== SurveyMenuLocation.view) {
      if (this.surveyLocation === SurveyMenuLocation.groupSurveysWidget) {
        const group = this.groupsService.currentGroup.getValue();
        if (group) {
          route = `group/${group.id}/surveys/${this.item.id}/${routeName}`;
          this.router.navigate([route]);
        }
      } else if (this.surveyLocation === SurveyMenuLocation.userSurveysWidget) {
        const user = this.usersService.user.getValue();
        if (user) {
          route = `profile/${user.id}/surveys/${this.item.id}/${routeName}`;
          this.router.navigate([route]);
        }
      } else {
        route = `${this.item.id}/${routeName}`;
        this.router.navigate([route], { relativeTo: this.route });
      }
    } else {
      this.router.navigate([routeName], { relativeTo: this.route });
    }
  }
}
