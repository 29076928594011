import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UsersService } from '@app/profile/services/users.service';

@Injectable({
  providedIn: 'root'
})
export class IsAdminGuard implements CanActivate {

  constructor(private usersService: UsersService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve) => {
      this.usersService.currentUser.subscribe(user => {
        if (user) {
          resolve(user.isAdmin || user.isVacancyAdmin || user.isIdeasAdmin);
        } else {
          resolve(false);
        }
      }, err => resolve(false));
    });
  }
}
