import { filter, pairwise } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { SignalRService } from '@app/signalr/signalR.service';
import { RoutesRecognized, Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { UsersService } from '@app/profile/services/users.service';
import { BaseComponent } from '@app/core/components/base.component';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from '@app/core/helpers/helper';
import { FilesService } from '@app/files/services/files.service';
import { TooltipHelper } from '@app/core/helpers/tooltip.helper';
import { HelperService } from './core/services/helper.service';
import { SkypeService } from './skype/services/skype.service';
import { Title } from '@angular/platform-browser';
import { DateAdapter } from '@angular/material/core';
import { SettingsService } from './shared/services/settings.service';
import { GroupsService } from './groups/services/groups.service';
import { IntryAuthService } from './auth/shared/services/intry-auth.service';
import { User, UserType } from './profile/model/user.model';

declare var ym: any;

/**
 * Глобальный компонент - точка входа в приложение
 *
 * @export
 * @class AppComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-intry',
  templateUrl: 'app.component.html',
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  private static SettingsMetricName = 'Яндекс метрика';
  private static SettingsOrgNewsName = 'Группа для Организационных объявлений';
  private static SettingsShowFiles = 'Отображать файлы';

  currentUser: User;

  constructor(
    private dateAdapter: DateAdapter<any>,
    private titleService: Title,
    private signalRService: SignalRService,
    private usersService: UsersService,
    private groupsService: GroupsService,
    private filesService: FilesService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private skypeService: SkypeService,
    private settingsService: SettingsService,
    private auth: IntryAuthService,
    public helper: HelperService,
  ) {
    super(helper);

    // set default locale values
    this.translate.addLangs(['ru', 'en']);
    this.translate.setDefaultLang('ru');

    const browserLang = this.translate.getBrowserLang();
    this.translate.use(browserLang.match(/ru|en/) ? browserLang : 'ru');

    // установить локализацию для поля выбора даты/времени
    this.dateAdapter.setLocale('ru');
    this.dateAdapter.getFirstDayOfWeek = () => 1;

    this.auth.authCallbackIfRequired();
  }

  /**
   * Получение текущего пользователя, url его личного сайта, библиотек и т.д.
   *
   * @memberof AppComponent
   */
  ngOnInit(): void {
    this.setBodyPaddingIfWindows();

    this.checkUser();
    this.setCurrentRouteTitle();
    this.skypeService.initialize();

    this.checkSignalRConnection();

    this.getCurrentUser();

    // this.getMetrics();

    this.getRoutes();
  }

  private checkSignalRConnection(): void {
    this.signalRService.connectionEstablished.subscribe(
      data => {
        console.log('signalr service mainHubService connectionEstablished');
      },
      error => {
        console.warn('signalr service mainHubService failed to start!');
      },
    );
  }

  private getCurrentUser(): void {
    this.usersService.getCurrent().subscribe(currentUser => {
      this.currentUser = currentUser;
      if (currentUser) {
        // get current user locale for UI localization
        const locale = Helper.getLocaleByLcid(currentUser.lcid);
        this.translate.use(locale);

        this.getMetrics();

        // for local user we don't have a personal url
        if (currentUser.userType === UserType.local) {
          return;
        }
      }
    }, error => {
      if (error && error.error && error.error.ErrorCode === 6666) {
        this.router.navigate(
          ['/503', { error: '2ba01c92-2e67-4384-b672-4dea5439ceb5' }],
          {
            skipLocationChange: true,
          },
        );
      }
    });
  }

  private getMetrics(): void {
    this.settingsService
      .getValues([
        AppComponent.SettingsMetricName,
        AppComponent.SettingsOrgNewsName,
        AppComponent.SettingsShowFiles])
      .subscribe(res => {

        if (!res) {
          return;
        }

        const resOrgNews = res.find(s => s.key === AppComponent.SettingsOrgNewsName);

        if (resOrgNews && resOrgNews.value) {
          this.groupsService.groupOrgNews$.next(resOrgNews.value);
        }

        const resShowFiles = res.find(s => s.key === AppComponent.SettingsShowFiles);

        this.filesService.showUserFiles$.next(resShowFiles && Helper.isYesNo(resShowFiles.value));

        // always scroll top on every route change
        this.router.events
          .pipe(filter((e: NavigationEnd) => e instanceof NavigationEnd))
          .subscribe(e => {
            // hide tooltip
            TooltipHelper.hideUserTooltip(null);
            // scroll top
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });

            const resMetric = res.find(s => s.key === AppComponent.SettingsMetricName);

            if (!resMetric || !resMetric.value) {
              return;
            }

            // проставлять title
            const pageTitle = this.setCurrentRouteTitle();

            // yandex metric
            try {
              if (ym) {
                let url = e.urlAfterRedirects;

                if (
                  e.urlAfterRedirects &&
                  e.urlAfterRedirects.indexOf(this.helper.environment.startPage) === -1
                ) {
                  url = Helper.concatUrls(
                    this.helper.environment.startPage,
                    e.urlAfterRedirects,
                  );
                }

                if (url) {
                  url = url.replace(/\/$/, '');
                } else if (this.helper.environment.startPage !== '/') {
                  url = this.helper.environment.startPage.replace(/\/$/, '');
                } else {
                  url = '/';
                }

                ym(resMetric.value, 'hit', url, {
                  title: pageTitle,
                });
              }
            } catch { }
          });
      });
  }

  private getRoutes(): void {
    Helper.routes = [];

    this.router.events
      .pipe(
        filter((e: any) => e instanceof RoutesRecognized),
        pairwise(),
      )
      .subscribe((e: RoutesRecognized[]) => {
        if (!Helper.routes || !Helper.routes.length) {
          Helper.addUniqueRoute(e[0].url);
        }
        // 404 не добавляем в пути
        if (e[1].url !== '/404') {
          Helper.addRoute(e[1].url);
        }
        // this.checkUser();
      });
  }

  private checkUser() {
    if (!Helper.checkUserDate()) {
      this.router.navigate(['/503', { error: '2ba01c92-2e67-4384-b672-4dea5439ceb5' }], {
        skipLocationChange: true,
      });
    }
  }

  private setCurrentRouteTitle() {
    const newTitle = this.getCurrentRouteTitle();

    if (newTitle) {
      const currentTitle = this.titleService.getTitle();
      if (currentTitle !== newTitle) {
        this.titleService.setTitle(newTitle);
      }
    }

    return newTitle;
  }

  private getCurrentRouteTitle() {
    let child = this.route.firstChild;
    let newTitle = '';
    while (child) {
      if (child.firstChild) {
        child = child.firstChild;
      } else if (child.snapshot.data && child.snapshot.data['title']) {
        newTitle = child.snapshot.data['title'];
        child = null;
      } else {
        child = null;
      }
    }
    return newTitle;
  }

  private setBodyPaddingIfWindows() {
    if (navigator?.appVersion?.indexOf('Win') !== -1) {
      document.body?.classList?.add('scroll-right-moved');
    }
  }
}
