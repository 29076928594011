import { Component, OnInit, AfterViewInit, Input, ViewChild, NgZone, forwardRef, Output, EventEmitter } from '@angular/core';
import { User } from '@app/profile/model/user.model';
import { UsersService } from '@app/profile/services/users.service';
import { AttachmentViewModel } from '@app/attachments/file-attachments/model/attachment-view-model.model';
import { EditorComponent } from '@app/shared/components/editor/editor.component';
import { ElementType } from '@app/core/model/element-type.enum';
import { CommentsService } from '@app/comments/services/comments.service';
import { ShareableObject } from '@app/files/model/shareable-object.model';
import { AttachmentsListComponent } from '@app/attachments/file-attachments/components/attachments-list/attachments-list.component';
import { ICommentReply } from '@app/comments/components/comments-list/comments-list.component';
import { IMediaFile } from '@app/gallery/model/media-file';
import { MediaAttachmentViewModel } from '@app/attachments/media-attachments/model/media-attachment-view-model';
// tslint:disable-next-line:max-line-length
import { MediaAttachmentsListComponent } from '@app/attachments/media-attachments/components/media-attachments-list/media-attachments-list.component';
import { take } from 'rxjs/operators';
import { AlertsService } from '@app/shared/services/alerts.service';
import { IDatafileModel } from '@app/files/model/data-file-model';
import { FroalaInlineEditorComponent } from '@app/shared/components/froala-editor/inline/inline.component';

/**
 * Компонент создания нового комментария
 *
 * @export
 * @class CommentNewComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-comment-new',
  templateUrl: './comment-new.component.html'
})
export class CommentNewComponent implements OnInit {

  /**
   * ИД элемента для которого будет добавлен комментарий
   *
   * @type {number}
   * @memberof CommentNewComponent
   */
  @Input() elementId: number;

  /**
   * Тип элемента, для которого будет добавлен комментарий
   *
   * @type {ElementType}
   * @memberof CommentNewComponent
   */
  @Input() elementType: ElementType.Comment;
  @Input() autoHide: boolean;
  @Input() hideAddAttachment: boolean;
  @Input() inline: boolean;

  /**
   * Действие при добавлении нового комментария
   *
   * @type {EventEmitter<any>}
   * @memberof CommentNewComponent
   */
  @Output() commentAdded: EventEmitter<any> = new EventEmitter();

  /**
   * Компонент списка вложений
   *
   * @private
   * @type {AttachmentsListComponent}
   * @memberof CommentNewComponent
   */
  @ViewChild(forwardRef(() => AttachmentsListComponent)) private attachmentsList: AttachmentsListComponent;

  /**
   * Компонент списка медиа вложений
   *
   * @protected
   * @type {MediaAttachmentsListComponent}
   * @memberof CommentNewComponent
   */
  @ViewChild(forwardRef(() => MediaAttachmentsListComponent)) protected mediaAttachmentsList: MediaAttachmentsListComponent;

  /**
   * Компонент WYSIWYG редактора
   *
   * @private
   * @type {EditorComponent}
   * @memberof CommentNewComponent
   */
  @ViewChild(FroalaInlineEditorComponent, { static: true }) private editor: FroalaInlineEditorComponent;

  currentUser: User;
  attachments: AttachmentViewModel[] = [];
  mediaAttachments: MediaAttachmentViewModel[] = [];
  fullView: boolean;
  text: string;
  isBusy = false;
  isHidden = false;
  suppressCollapse = false;
  uploadFiles = false;
  uploadMediaFiles = false;

  ownerId: number;

  mentions: User[] = [];

  parentId: string;

  constructor(
    private commentsService: CommentsService,
    private usersService: UsersService,
    private zone: NgZone,
    protected alertsService: AlertsService) {
  }

  ngOnInit() {
    this.usersService.currentUser
      .subscribe(currentUser => {
        this.currentUser = currentUser;
        this.ownerId = this.currentUser.id;
      });

    this.isHidden = this.autoHide;
  }

  expand() {
    this.fullView = true;
  }

  collapse() {
    if (this.suppressCollapse) {
      this.suppressCollapse = false;
      return;
    }

    if (!this.text && this.attachments.length === 0) {
      if (this.fullView) {
        this.fullView = false;
      }
      if (this.autoHide) {
        this.isHidden = true;
      }
    }
  }

  activateNoParent(user: User) {
    this.activate({ user: user });
  }

  activate(reply: ICommentReply) {
    this.parentId = reply.parentId;
    this.isHidden = false;

    const that = this;

    window.setTimeout(() => {
      that.zone.run(() => { that.editor?.activate(reply.user); });
    }, 0);

    window.setTimeout(() => {
      that.editor?.focus();
    }, 500);

    this.suppressCollapse = true;
  }

  focus() {
    this.editor.focus();
  }

  /**
   * Отменить создание комментария
   *
   * @memberof CommentNewComponent
   */
  cancel() {
    this.isHidden = this.autoHide;

    this.clear();
  }

  /**
   * Создать комментарий
   *
   * @memberof CommentNewComponent
   */
  submit() {
    if (this.text && !this.isBusy || (this.attachments && this.attachments.length)
      || (this.attachmentsList && this.attachmentsList.attachmentsToUpload && this.attachmentsList.attachmentsToUpload.length)
      || (this.mediaAttachments && this.mediaAttachments.length)
      || (this.mediaAttachmentsList && this.mediaAttachmentsList.attachmentsToUpload
        && this.mediaAttachmentsList.attachmentsToUpload.length)) {

      this.isBusy = true;

      if (this.attachmentsList
        && this.attachmentsList.attachmentsToUpload
        && this.attachmentsList.attachmentsToUpload.length) {

        this.uploadFiles = true;
        // upload attached files at first
        this.attachmentsList.uploadAttachedFiles(ElementType.Comment);
      }
      if (this.mediaAttachmentsList
        && this.mediaAttachmentsList.attachmentsToUpload
        && this.mediaAttachmentsList.attachmentsToUpload.length) {

        this.uploadMediaFiles = true;
        // upload attached media files at first
        this.mediaAttachmentsList.uploadAttachedFiles(ElementType.Comment);
      }

      if (!this.uploadFiles && !this.uploadMediaFiles) {
        // send to server
        this.send();
      }
    } else {
      // todo: error empty text
    }
  }

  /**
   * Отправить данные на сервер
   *
   * @private
   * @memberof CommentNewComponent
   */
  private send() {
    // attachments
    const attachmentsIds = this.attachments
      ? this.attachments.map((att) => att.attachment.guid)
      : null;
    // media attachments
    const mediaAttachmentsIds = this.mediaAttachments
      ? this.mediaAttachments.map((att) => att.mediaAttachment.id)
      : null;
    // mentions
    const mentionsIds = this.mentions
      ? this.mentions.map(item => item.id)
      : null;

    this.commentsService.addComment(
      this.elementId,
      this.elementType,
      this.text,
      this.parentId,
      mentionsIds,
      attachmentsIds,
      mediaAttachmentsIds)
      .pipe(take(1))
      .subscribe(res => {
        this.clear();
        this.isBusy = false;
        // send callback to another component
        this.commentAdded.emit(res);
        this.alertsService.riseSuccess('Комментарий создан.');
      }, error => {
        this.isBusy = false;
        this.alertsService.riseError('Произошла ошибка при создании комментария. Попробуйте позже.');
      });
  }

  /**
   * Действие при удалении вложения
   *
   * @param {AttachmentViewModel} attachment
   * @memberof CommentNewComponent
   */
  onRemoveAttachment(attachment: AttachmentViewModel) {
    this.attachments = this.attachments.filter(att =>
      attachment.attachment.guid && att.attachment.guid !== attachment.attachment.guid
      || !attachment.attachment.guid && att.attachment.title !== attachment.attachment.title);
  }

  /**
   * Действие при завершении загрузки вложений
   *
   * @param IDatafileModel[] event
   * @memberof CommentNewComponent
   */
  onFilesUploaded(event: IDatafileModel[]) {
    // add uploaded attachments
    this.attachments = this.attachments.filter(att => att.attachment.guid);
    if (event) {
      event.forEach(file => {
        this.attachments.push({
          attachment:
          {
            title: file.name,
            modified: file.created,
            ownerID: file.authorId,
            url: `/api/documentFile/${file.guid}`,
            guid: file.guid
          },
          owner: null // file.author
        });
      });
    }
    this.uploadFiles = false;

    // send to server
    if (!this.uploadFiles && !this.uploadMediaFiles) {
      this.send();
    }
  }

  /**
   * Действие при завершении загрузки медиа вложений
   *
   * @param {IMediaFile[]} event
   * @memberof CommentNewComponent
   */
  onMediaFilesUploaded(event: IMediaFile[]) {
    // add uploaded attachments
    this.mediaAttachments = this.mediaAttachments.filter(att => att.mediaAttachment.id);
    if (event) {
      event.forEach(mediaFile => {
        this.mediaAttachments.push({
          mediaAttachment:
          {
            id: mediaFile.id,
            title: mediaFile.name,
            url: mediaFile.url,
            previewUrl: mediaFile.previewUrl,
            guid: mediaFile.guid,
            type: mediaFile.type,
          }
        });
      });
    }
    this.uploadMediaFiles = false;

    // send to server
    if (!this.uploadFiles && !this.uploadMediaFiles) {
      this.send();
    }
  }

  /**
   * Очистить блок создания комментария
   *
   * @private
   * @memberof CommentNewComponent
   */
  private clear() {
    this.text = '';
    this.attachments = [];
    this.mediaAttachments = [];
    this.fullView = false;
    this.editor.clear();
  }
}
