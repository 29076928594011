<div class="card">
  <span class="header-text _modal">
    <span class="header-text__text">
      {{data.text}}
    </span>
  </span>
  <div class="form _modal">
    <div class="form__line">
      <div class="input-main _huge">
        <textarea rows="1" placeholder="Введите название ссылки*" [(ngModel)]="title" class="input-main__field"
          maxLength="255"></textarea>
      </div>
    </div>
    <div class="form__line">
      <div class="input-main _huge">
        <textarea rows="3" autosize placeholder="Введите url ссылки" [(ngModel)]="url" class="input-main__field"
          maxLength="500"></textarea>
      </div>
    </div>
    <div class="form__line">
      <div class="input-main _huge">
        <label class="checkbox">
          <input type="checkbox" name="search-dir" class="checkbox__input" [checked]="inNewTab" (change)="inNewTab=!inNewTab">
          <span class="checkbox__icon"></span><span class="checkbox__text">Открывать в новой вкладке браузера</span>
        </label>
      </div>
    </div>
    <div class="form__line">
      <div class="form__note">Ссылки на внешние страницы рекомендуется открывать в новой вкладке браузера</div>
    </div>
    <div class="error-message" *ngIf="error">{{error}}</div>
  </div>
</div>
<div class="confirm _modal _right">
  <div class="confirm__actions" mat-dialog-actions [ngClass]="{'loading _blue':loading}">
    <button type="button" mat-button (click)="showConfirmDialog()" class="btn btn-simple">Отмена</button>
    <button type="button" mat-button class="btn btn-primary" (click)="save()"
      [attr.disabled]="isValid() ? null :''">Опубликовать</button>
  </div>
</div>
