import { User } from '@app/profile/model/user.model';

export interface ICalendarDate {
    day?: number;
    month?: number;
    year?: number;
    dayOfWeek?: number;

    // todo: в будущем переделать на универсальный массив с указанием типа события
    birthdays?: User[];
    birthdaysCount?: number;

    // ui
    mouseOver?: boolean;
}

export interface ICalendarEvent {
    id: number;
}

export enum CalendarViewType {
    month = 1,
    day = 2
}

export enum CalendarFilterType {
    all = 0,
    colleagues = 1,
    departments = 2
}

export class CalendarHelper {
    public static daysOfWeek: Array<string> = [
        'Пн',
        'Вт',
        'Ср',
        'Чт',
        'Пт',
        'Сб',
        'Вс'
    ];

    public static months: Array<string> = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
    ];

    public static getMonth(index: number): string {
        if (index < this.months.length) {
            return this.months[index];
        }
        return '';
    }
}
