<div class="user-header" [attr.style]="getPictureUrl(group)" *ngIf="group">
  <span class="user-header__back">
    <a routerLink="/group/{{group?.id}}" (click)="groupsService.navigateGroup(group?.id)" class="back _white"></a>
  </span>
  <a routerLink="/group/{{group?.id}}" class="link-profile hint" (click)="groupsService.navigateGroup(group?.id)">
    <span title="" class="avatar _30" [ngStyle]="{'background-color':groupsService.getColor(group)}"
      [attr.data-initials]="groupsService.getInitials(group, true)">
      <span class="avatar__image-wrap" [attr.style]="getPictureUrl(group)"></span>
    </span>
    <span class="link-profile__text">{{group?.title}}</span>
  </a>
  <app-breadcrumbs></app-breadcrumbs>
</div>

<!-- header preloader -->
<app-tab-preloader *ngIf="!group"></app-tab-preloader>
<!--  header preloader END -->
