<div class="card">
  <span class="header-text _modal">
    <span class="header-text__text">
      Параметры статуса "{{data.status.title}}"
    </span>
  </span>
  <div class="input-main _big">
    <textarea autosize placeholder="Добавьте комментарий к статусу {{data.status.commentRequired ? '*':''}}" [(ngModel)]="comment"
      class="input-main__field"></textarea>
  </div>
  <div class="input-main _big">
    <div class="post__calendar">
      Срок действия статуса{{data.status.dateEndRequired ? '*':''}}
      <input placeholder="дд.мм.гггг" matInput [matDatepicker]="dp" [(ngModel)]="dateEnd">
      <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
      <mat-datepicker #dp></mat-datepicker>
    </div>
  </div>
  <div class="subinput">
    <span>Укажите дату, до которой идея будет находиться в данном статусе</span>
  </div>
  <div class="error-message" *ngIf="error">{{error}}</div>
</div>
<div class="confirm _right">
  <div class="confirm__actions" mat-dialog-actions [ngClass]="{'loading _blue':loading}">
    <button type="button" mat-button mat-dialog-close class="btn btn-simple">Отмена</button>
    <button type="button" mat-button class="btn btn-primary" (click)="save()"
      [attr.disabled]="isValid() ? null :''">Сохранить</button>
  </div>
</div>