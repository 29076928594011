import { Component, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService } from '@app/profile/services/users.service';
import { SubscribeService } from '@app/subscribe/services/subscribe.service';
import { FeedService } from '@app/feed/services/feed.service';
import { LikesService } from '@app/likes/services/likes.service';
import { SignalRService } from '@app/signalr/signalR.service';
import { HelperService } from '@app/core/services/helper.service';
import { UserFeedComponent } from '../user-feed/user-feed.component';
import { PostProfileSelectorType } from '@app/feed/model/post-profile-selector-type';
import { FeedType } from '@app/feed/model/feed-type.model';
import { GroupsService } from '@app/groups/services/groups.service';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-business-feed',
  templateUrl: './business-feed.component.html',
  styleUrls: ['./business-feed.component.scss']
})
export class BusinessFeedComponent extends UserFeedComponent {

  selectorType: PostProfileSelectorType = PostProfileSelectorType.onlyBusiness;
  allowCreate: boolean;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected usersService: UsersService,
    protected groupsService: GroupsService,
    protected subscribeService: SubscribeService,
    protected feedService: FeedService,
    protected likesService: LikesService,
    protected signalRService: SignalRService,
    protected ngZone: NgZone,
    public helper: HelperService) {

    super(router, route, usersService, subscribeService, feedService, likesService, signalRService, ngZone, helper);

    // проверка на возможность создания постов в данной ленте
    this.usersService.currentUser.subscribe(currentUser => {
      this.groupsService.getUserGroupsCanCreatePost(currentUser.id, this.selectorType, 0, 1)
        .pipe(take(1), takeUntil(this.destroyed$))
        .subscribe(res => {
          this.allowCreate = res && !!res.count;
        }, error => {
          console.log(`error=${error}`);
        });
    });
  }

  protected getFeedInternal() {
    if (this.currentUser) {
      this.user = this.currentUser;
      // получение постов
      this.operateFeedResult(this.feedService.getBusinessFeed(this.offset, this.limit));
    }
  }

  getFeedType(): FeedType {
    return FeedType.BusinessFeed;
  }
}
