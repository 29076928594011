<div class="cos__body _paddings" *ngIf="loaded && vacancy">
  <div class="new-post">
    <div class="input-main new-post__title _active">
      <textarea rows="1" placeholder="Введите название вакансии*" [(ngModel)]="vacancy.title"
        class="input-main__field"></textarea>
    </div>
    <div class="input-main _full _active">
      <textarea autosize rows="3" placeholder="Введите локацию*" [(ngModel)]="vacancy.location"
        class="input-main__field"></textarea>
    </div>
    <div class="new-comment__main">
      <app-froala-editor
        class="post__editor _full _smaller"
        placeholder="Введите описание вакансии*"
        [showToolbar]="true"
        [(editorModel)]="vacancy.description"
        [(text)]="vacancy.description"
        (submited)="submit()"
        (focused)="expand()"
        [(mentions)]="mentions"
      ></app-froala-editor>
    </div>
    <div class="dropdown _full-width">
      <div class="co_type">
        <div class="" mat-button [matMenuTriggerFor]="menu">{{category?.title ? category?.title : 'Выберите категорию'}}
        </div>
        <mat-menu #menu="matMenu">
          <div class="icon-menu" #menuContainer>
            <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" (click)="category=c"
              *ngFor="let c of categories">
              <span>{{c?.title}}</span>
            </a>
          </div>
        </mat-menu>
      </div>
    </div>
    <div class="news-section">
      <app-attachments-list #attachmentsList [attachments]="attachments" [isEdit]="true"
        (onRemove)="onRemoveAttachment($event)" (onFilesUploaded)="onFilesUploaded($event)" [ownerId]="vacancy.ownerId" [elementType]="elementType.Vacancy">
      </app-attachments-list>
    </div>
    <div class="new-post__actions">
      <div class="new-post__actions-extra" *ngIf="vacancy.ownerId">
        <app-attachments-menu title="Добавить вложение" (choose)="attachmentsList.onChooseFile($event)"
          (upload)="onUploadFile($event)" [multiple]="false"></app-attachments-menu>
      </div>
      <button type="button" class="btn btn-simple" (click)="cancel()">Отмена</button>
      <button type="button" class="btn btn-white" (click)="draft()" [attr.disabled]="isValid()?null:''">Сохранить как
        черновик</button>
      <button type="button" class="btn btn-primary" (click)="submit()" [attr.disabled]="isValid()?null:''">Сохранить и
        опубликовать</button>
    </div>
  </div>
</div>
