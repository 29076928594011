<div class="container _full-page _moved">

  <div class="">
    <div class="cos">
      <div class="user-header" *ngIf="user">
        <a href="#" class="link-profile" routerLink="/profile/{{user?.id}}">
          <app-user-avatar [user]="user" [linkClass]="'avatar _30'" [imageClass]="'avatar__image'" [asSpan]="true">
          </app-user-avatar>
          <span class="link-profile__text" [appUserTooltip]="user?.id">{{user?.fullName}}</span>
        </a>
        <app-breadcrumbs></app-breadcrumbs>
      </div>
      <router-outlet></router-outlet>
    </div>
    <div class="align-center hidden-desktop">
      <button [@visibilityChanged]="goTopVisible" (click)="scrollTop()" type="button" class="btn back-to-top"></button>
    </div>
  </div>
</div>