import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { PagesService } from '@app/pages/services/pages.service';
import { AlertsService } from '@app/shared/services/alerts.service';
import { PageNewComponent } from './page-new.component';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { GroupsService } from '@app/groups/services/groups.service';
import { HelperService } from '@app/core/services/helper.service';

/**
 * Форма создания новой страницы
 *
 * @export
 * @class PageNewComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-group-page-new',
  templateUrl: './page-new.component.html',
  styleUrls: ['./page-new.component.scss']
})
export class GroupPageNewComponent extends PageNewComponent {

  constructor(
    public groupsService: GroupsService,
    public pagesService: PagesService,
    protected alertsService: AlertsService,
    protected breadcrumbsService: BreadcrumbsService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected dialog: MatDialog,
    public helper: HelperService
  ) {
    super(pagesService, alertsService, breadcrumbsService, route, router, dialog, helper);
    this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
      if (group) {
        this.page.ownerId = group.id;

        // обновляем хлебные крошки
        const pageRoute = `/group/${group.id}/pages`;

        this.breadcrumbsService.breadcrumbs.next([
          { title: 'Страницы', routerUrl: pageRoute },
          { title: 'Новая страница' }
        ]);
      }
    });
  }
}
