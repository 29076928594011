<div class="card _groups" *ngIf="loaded">
  <div class="card__title">
    <a *ngIf="showAddButton" class="btn btn-plus pull-right" routerLink="/group/new">+</a>
    <div class="header-text">
      <a class="header-text__text" [routerLink]="link">Группы</a>&nbsp;
      <span class="header-text__count">{{groupsCount}}</span>
    </div>
  </div>
  <div class="card__content" *ngIf="loaded">
    <a routerLink="/group/{{group?.id}}" class="link _block" *ngFor="let group of groups">{{group.title}}</a>
  </div>
</div>
<div class="card" *ngIf="!loaded">
  <div class="card__title"><span class="preloader"><span class="preloader__line"></span></span>
  </div>
  <div class="card__content"><span class="preloader _cascade"><span class="preloader__line"></span></span><span class="preloader _cascade"><span class="preloader__line"></span></span>
    <span
      class="preloader _cascade"><span class="preloader__line"></span></span><span class="preloader _cascade"><span class="preloader__line"></span></span>
  </div>
</div>
