import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ThanksWidgetComponent } from '../thanks-widget/thanks-widget.component';
import { MatDialog } from '@angular/material/dialog';
import { UsersService } from '@app/profile/services/users.service';
import { ThanksService } from '@app/thanks/services/thanks.service';
import { AlertsService } from '@app/shared/services/alerts.service';
import { HelperService } from '@app/core/services/helper.service';
import { User } from '@app/profile/model/user.model';
import { takeUntil, take, finalize } from 'rxjs/operators';
import { NewThankComponent } from '../new-thank/new-thank.component';

@Component({
  selector: 'app-user-thanks-widget',
  templateUrl: './user-thanks-widget.component.html',
  styleUrls: ['./user-thanks-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserThanksWidgetComponent extends ThanksWidgetComponent implements OnInit, OnDestroy {

  user: User;
  itemsCount: number;

  constructor(
    public dialog: MatDialog,
    public usersService: UsersService,
    protected thanksService: ThanksService,
    protected alertsService: AlertsService,
    protected cdr: ChangeDetectorRef,
    public helper: HelperService) {
    super(dialog, usersService, thanksService, alertsService, cdr, helper);
  }

  ngOnInit() {
    this.usersService.user
      .pipe(takeUntil(this.destroyed$))
      .subscribe(user => {
        this.itemsCount = 0;
        this.user = user;
        if (this.user) {
          this.getAll();
        }
        this.cdr.detectChanges();
      });
  }

  isShowAddButton(): boolean {
    return this.isNotCurrent()
      && (this.items && !!this.items.length);
  }

  isShowThankButton() {
    return this.isNotCurrent() && this.items?.length;
  }

  private isNotCurrent() {
    return this.user
      && this.currentUser
      && this.loaded
      && this.user.id !== this.currentUser.id;
  }

  thank() {
    let usersToAdd: User[] = [];

    if (this.isNotCurrent()) {
      usersToAdd = [this.user];
    }

    const newThankDialog = this.dialog.open(NewThankComponent, {
      data: {
        usersToAdd: usersToAdd,
        onOk: () => {
          this.getAll();
          newThankDialog.close();
        }
      }
    });
  }

  protected getAll() {
    this.thanksService.getUserReceivedThanks(this.user.id, 0, 3)
      .pipe(finalize(() => {
        this.loaded = true;
        this.cdr.detectChanges();
      }), takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.items = res ? res : [];
      });

    this.thanksService.getUserReceivedThanksCount(this.user.id)
      .pipe(
        finalize(() => this.cdr.detectChanges()),
        takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.itemsCount = res ? res : 0;
      });
  }
}
