import { GroupInfo } from '@app/groups/model/group-info.model';
import { User } from '@app/profile/model/user.model';

export enum SurveyType {
    none = 0,
    survey = 1,
    test = 2
}

/**
 * Опрос
 *
 * @export
 * @interface ISurvey
 */
export interface ISurvey {
    id: number;
    title: string;
    description: string;
    dateEnd: Date;
    answersCount: number;
    group: GroupInfo;
    type: SurveyType;
    authorId?: number;
    ownerId?: number;
    questions?: ISurveyQuestion[];
    published: boolean;
    created?: Date;
    modified?: Date;
    complete: boolean;
    userAdmin?: boolean;
}

export interface ISurveyResult extends ISurvey {
    users?: ISurveyResultUser[];
}

export interface ISurveyResultUser {
    user: User;
    percent: number;
}

export interface INewSurvey {
    title: string;
    description: string;
    dateEnd: Date;
    ownerId: number;
    questions: ISurveyQuestion[];
    published: boolean;
    type: SurveyType;

    // ui
    titleFocused?: boolean;
    descriptionFocused?: boolean;
}

export interface IUpdateSurvey extends INewSurvey {
    id: number;
}

export interface ISurveyQuestion {
    id?: number;
    title: string;
    description?: string;
    order?: number;
    required: boolean;
    type?: SurveyQuestionType;
    answersCount?: number;
    options?: ISurveyQuestionOption[];
    isGroup?: boolean;
    parentId?: number;
    questions?: ISurveyQuestion[];
    meta?: ISurveyQuestionMeta;

    // ui
    focused?: boolean;
    focusedDescription?: boolean;
}

export interface ISurveyQuestionOption {
    id?: number;
    title: string;
    type: SurveyQuestionOptionType;
    value?: string;
    answersCount?: number;
    selected?: boolean;
    isCorrect?: boolean;
    isAnswered?: boolean;
}

export interface ISurveyQuestionMeta {

}

export interface ISurveyQuestionRangeOptionValue extends ISurveyQuestionMeta {
    min?: number;
    max?: number;
}

export interface ISurveyAnswer {
    title: string;
    count: number;
}

export enum SurveyQuestionType {
    single = 1,
    multiple = 2,
    text = 3,
    range = 4
}

export enum SurveyQuestionOptionType {
    none = 0,
    normal = 1,
    other = 2,
    range = 3
}

export namespace SurveyQuestionType {
    export function values() {
        return Object.keys(SurveyQuestionType).filter(
            (type) => !isNaN(<any>type) && type !== 'values'
        );
    }
}

export interface ITakeSurvey {
    id: number;
    fakeTest?: string[];
    sent?: boolean;
    questions: ITakeSurveyQuestion[];
}

export interface ITakeSurveyQuestion {
    id: number;
    options: ITakeSurveyQuestionOption[];
}

export interface ITakeSurveyQuestionOption {
    id: number;
    value?: string;
}

export interface ISurveyOptionDetails {
    id: number;
    title: string;
    ownerId: number;
    authorId: number;
    dateEnd: Date;
    answersCount: number;
    complete: boolean;
    surveyId: number;
    surveyTitle: string;
    surveyCompleteCount: number;
    questionTitle: string;
    questionDescription: string;
    questionCompleteCount: number;
    questionAnswers: ISurveyQuestionOptionAnswer[];
}

export interface ISurveyQuestionOptionAnswer {
    id: number;
    text: string;
    date: Date;
}
