import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { UserFilesListComponent } from '@app/profile/components/profile/user-files-list/user-files-list.component';
import { ProfilePostViewComponent } from '@app/profile/components/profile/profile-post-view/profile-post-view.component';
import { SharedModule } from '@app/shared/shared.module';
import { SubscribeModule } from '@app/subscribe/subscribe.module';
import { GroupsModule } from '@app/groups/groups.module';
import { FilesModule } from '@app/files/files.module';
import { FeedModule } from '@app/feed/feed.module';
import { GalleryModule } from '@app/gallery/gallery.module';
import { ProfileViewComponent } from '@app/profile/components/profile/profile-view/profile-view.component';
import { UserCardComponent } from '@app/profile/components/profile/user-card/user-card.component';
import { ProfileFeedViewComponent } from '@app/profile/components/profile/profile-feed-view/profile-feed-view.component';
import { UsersService } from '@app/profile/services/users.service';
import { UsersAPIService } from '@app/profile/services/users.api.service';
import { SurveysModule } from '@app/surveys/surveys.module';
import { UserSurveyListComponent } from './components/surveys/user-survey-list/user-survey-list.component';
import { UserSurveysWidgetComponent } from './components/surveys/user-surveys-widget/user-surveys-widget.component';
import { UserSurveysWidgetSmallComponent } from './components/surveys/user-surveys-widget-small/user-surveys-widget-small.component';
import { VacationsModule } from '@app/vacations/vacations.module';
import { AwardsModule } from '@app/awards/awards.module';
import { UserVacancyListComponent } from './components/vacancy/user-vacancy-list/user-vacancy-list.component';
import { UserVacancyWidgetComponent } from './components/vacancy/user-vacancy-widget/user-vacancy-widget.component';
import { ProfileServiceComponent } from './components/profile/profile-service/profile-service.component';
import { VacancyModule } from '@app/vacancy/vacancy.module';
import { ProfilePropertiesComponent } from './components/profile/profile-properties/profile-properties.component';
import { UserVacancyWidgetSmallComponent } from './components/vacancy/user-vacancy-widget-small/user-vacancy-widget-small.component';
import { ThanksModule } from '@app/thanks/thanks.module';
import { CalendarModule } from '@app/calendar/calendar.module';
import { OnboardingModule } from '@app/onboarding/onboarding.module';
import { UserSubordinatesListComponent } from './components/profile/user-subordinates-list/user-subordinates-list.component';
import { UserRelationsModule } from '@app/user-relations/user-relations.module';
import { ProfilePostShareComponent } from './components/profile/profile-post-share/profile-post-share.component';
import { ProfileRoutingModule } from './profile.routing';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ProfileRoutingModule,
        SharedModule,
        SubscribeModule,
        GroupsModule,
        FilesModule,
        MatMenuModule,
        FeedModule,
        GalleryModule,
        SurveysModule,
        VacationsModule,
        AwardsModule,
        VacancyModule,
        ThanksModule,
        OnboardingModule,
        CalendarModule,
        UserRelationsModule,
    ],
    declarations: [
        ProfileViewComponent,
        UserCardComponent,
        UserFilesListComponent,
        ProfileFeedViewComponent,
        ProfilePostViewComponent,
        UserSurveyListComponent,
        UserSurveysWidgetComponent,
        UserVacancyListComponent,
        UserVacancyWidgetComponent,
        ProfileServiceComponent,
        ProfilePropertiesComponent,
        UserVacancyWidgetSmallComponent,
        UserSurveysWidgetSmallComponent,
        UserSubordinatesListComponent,
        ProfilePostShareComponent,
    ],
    exports: [
        ProfileViewComponent,
        UserCardComponent,
        UserVacancyWidgetSmallComponent,
        UserSurveysWidgetSmallComponent,
        ProfilePostShareComponent,
    ],
    providers: [
        UsersService,
        UsersAPIService
    ],
})
export class ProfileModule { }
