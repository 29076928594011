<div *ngIf="mode === AddTagsComponentMode.view" class="content-tags">
  <a
    *ngFor="let tag of tags"
    fragment="{{getFragment(tag)}}"
    class="tag"
    routerLink="{{getRoute(tag)}}"
  >
    #{{tag.name}}
  </a>
</div>

<div *ngIf="mode === AddTagsComponentMode.edit" class="cos-add _simple _tags">
  <div class="cos-add__tags">
    <div *ngFor="let tag of tags" class="tag">
      <button
        type="button"
        class="btn btn-secondary btn-icon tag__button"
        (click)="removeTag(tag)"
      >
        <span class="tag__name">{{tag.name}}</span>
        <span class="tag__img">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/remove.svg" />
        </span>
      </button>
    </div>
  </div>
  <div class="input-main _full cos-add__input">
    <input
      placeholder="{{placeholder}}"
      type="text"
      class="input-main__field"
      [(ngModel)]="searchText"
      (keyup)="onSearchKey($event)"
      (focus)="onFocus()"
      (blur)="onBlur()"
      (keydown)="onSearchKey($event)"
      (ngModelChange)="searchText = $event"
    />
  </div>
  <div class="cos-add__suggest">
    <div
      *ngFor="let tag of tagsToAdd"
      class="co _one-line _suggest"
      (click)="addTag(tag)"
    >
      <a href="javascript:void(0)" class="link co__name">{{tag.name}}</a>
    </div>
    <div
      *ngIf="!tagsToAdd?.length && loaded && searchText?.length"
      class="co _one-line _suggest"
      (click)="createTag()"
    >
      <span class="co__name">
        <a href="javascript:void(0)" class="link btn btn-add">Добавить тег</a>
      </span>
    </div>
  </div>
</div>
