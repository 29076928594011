import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@env/environment';
import { AppModule } from '@app/app.module';

if (environment.siteUrl) {
  const script = document.createElement('script');
  script.innerHTML = `
    window.signalRServer = '${environment.siteUrl}';
    window.Intry = {};
    window.Intry.mySiteHost = '${environment.siteHost}/my';
    window.Intry.siteHost = '${environment.siteHost}';
  `;
  // window.Intry.isActive = false;
  document.head.appendChild(script);

  // const signalrScript = document.createElement('script');
  // signalrScript.src = `${environment.siteUrl}/signalr/hubs`;
  // signalrScript.defer = true;
  // document.head.appendChild(signalrScript);
}

enableProdMode();

platformBrowserDynamic().bootstrapModule(AppModule);
