import { Component, OnInit, OnChanges, SimpleChanges, OnDestroy, Input, NgZone, ChangeDetectorRef } from '@angular/core';
import { MediaListComponent } from '@app/gallery/components/lists/media-list/media-list.component';
import { UsersService } from '@app/profile/services/users.service';
import { GalleryService } from '@app/gallery/services/gallery.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { AlertsService } from '@app/shared/services/alerts.service';
import { HelperService } from '@app/core/services/helper.service';
import { IMediaFile } from '@app/gallery/model/media-file';
import { SignalRService } from '@app/signalr/signalR.service';
import { GroupsService } from '@app/groups/services/groups.service';
import { ActivatedRoute } from '@angular/router';
import { MediaFileUploadV2Service } from '@app/shared/services/media-file-upload-v2.service';

/**
 * Компонент результатов поиска по медиафайлам
 *
 * @export
 * @class SearchMediaResultComponent
 * @extends {MediaListComponent}
 * @implements {OnInit}
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-search-media-result',
  templateUrl: './search-media-result.component.html',
  styleUrls: ['./search-media-result.component.scss']
})
export class SearchMediaResultComponent extends MediaListComponent implements OnInit, OnChanges, OnDestroy {

  constructor(
    public groupsService: GroupsService,
    public usersService: UsersService,
    public galleryService: GalleryService,
    public sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public alertsService: AlertsService,
    public signalRService: SignalRService,
    public mediaFileUploadSevice: MediaFileUploadV2Service,
    public ngZone: NgZone,
    public route: ActivatedRoute,
    public cdr: ChangeDetectorRef,
    public helper: HelperService
  ) {
    super(
      usersService,
      groupsService,
      galleryService,
      sanitizer,
      dialog,
      alertsService,
      signalRService,
      mediaFileUploadSevice,
      ngZone,
      route,
      cdr,
      helper);
  }

  /**
   * Переопределяем OnInit, чтобы не выполнялся родительcкий метод получения медиафайлов
   *
   * @memberof SearchMediaResultComponent
   */
  ngOnInit() {

  }

  /**
   * Переопределяем OnChanges, чтобы не выполнялся родительcкий метод получения медиафайлов
   *
   * @param {SimpleChanges} changes
   * @memberof SearchMediaResultComponent
   */
  ngOnChanges(changes: SimpleChanges): void {

  }

}
