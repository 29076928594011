<div class="container _moved _full-page">
    <div class="cos">
        <div class="fruitfaces">
            <h1 class="fruitfaces__title">Фрутомоськи</h1>
            <div class="fruitfaces__steps hidden-desktop">
                <span>{{this.currentStepIndex+1}}</span>
                {{this.currentStep.caption}}
            </div>

            <div class="fruitfaces__layout" [ngClass]="{'_initial': currentStep.canUpload}">
                <div class="fruitfaces__column _editor">
                    <div class="fruitfaces__editor" id="fruitfacesContainerParent">
                        <div class="cropper-form _fruitfaces" [ngClass]="{'_hidden': !currentStep.croppable}">
                            <image-cropper format="jpeg" class="cropper-form__cropper" [imageURL]="imageURL"
                               [maintainAspectRatio]="true" [aspectRatio]="16 / 9" format="png" (imageLoaded)="imageLoaded()"
                               (imageCropped)="imageCropped($event)"></image-cropper>
                        </div>
                        <div id="fruitfacesContainer">

                        </div>
                        <a
                            *ngIf="currentStep.canUpload"
                            href="javascript:;"
                            class="fruitfaces__edit"
                            (click)="fruitFacesUpload.click()"
                            [ngClass]="{'_initial': !hasImage}"
                        >
                            <img src="{{assetsPrefix}}/assets/images/icons/edit-photo-pencil.svg"
                                 alt="{{'Заменить'}}">
                            <span>
                                Кликните, чтобы загрузить
                            </span>
                        </a>
                    </div>
                    <div class="fruitfaces__tip">{{currentStep.tip}}</div>
                </div>
                <div class="fruitfaces__column _controls">
                    <div class="fruitfaces__steps hidden-phone hidden-tablet">
                        <span>{{this.currentStepIndex+1}}</span>
                        {{this.currentStep.caption}}
                    </div>

                    <div class="fruitfaces__sliders" *ngIf="currentStep.editable">
                        <div class="fruitfaces__slider">
                            <ngx-slick-carousel
                                #slickModalFaces
                                class="carousel"
                                [config]="{}"
                                (afterChange)="handleMasksSet($event, 'face')"
                            >
                                <div class="fruitfaces__slider-item" ngxSlickItem *ngFor="let face of faces">
                                    <img src="{{face}}" alt="" (click)="showFacesOverlay = true">
                                </div>
                            </ngx-slick-carousel>
                        </div>
                        <div class="fruitfaces__slider">
                            <ngx-slick-carousel
                                #slickModalAnimals
                                class="carousel"
                                [config]="{}"
                                (afterChange)="handleMasksSet($event, 'animal')"
                            >
                                <div class="fruitfaces__slider-item _bigger" ngxSlickItem *ngFor="let animal of animals">
                                    <img src="{{animal}}" alt="" (click)="showAnimalsOverlay = true">
                                </div>
                            </ngx-slick-carousel>
                        </div>

                        <div class="fruitfaces__overlay _animals" *ngIf="showAnimalsOverlay">
                            <img
                                *ngFor="let animal of animals; let i = index;"
                                src="{{animal}}"
                                (click)="handleMaskClick(i, 'animal')"
                                alt=""
                            >
                        </div>

                        <div class="fruitfaces__overlay _faces" *ngIf="showFacesOverlay">
                            <img
                                *ngFor="let face of faces; let i = index;"
                                src="{{face}}"
                                (click)="handleMaskClick(i, 'face')"
                                alt=""
                            >
                        </div>
                    </div>

                    <div class="fruitfaces__album-actions">
                        <button
                            *ngIf="currentStep.completed && !redirect"
                            type="button"
                            (click)="save()"
                            [attr.disabled]="saving?'':null"
                            class="btn btn-white btn-stroke fruitfaces__album"
                        >Загрузить в альбом</button>

                        <a
                            *ngIf="currentStep.completed && redirect"
                            (click)="goToAlbum()"
                            href="javascript:;"
                            class="link fruitfaces__album"
                        >Перейти в альбом конкурса</a>
                    </div>
                </div>
            </div>
            <div class="fruitfaces__actions">
                <button
                    [ngClass]="{'_visible': currentStep.showBack}"
                    type="button"
                    (click)="prevStep()"
                    class="btn btn-white btn-stroke fruitfaces__back"
                >Назад</button>
                <button
                    [ngClass]="{'_visible': currentStep.showNext}"
                    [disabled]="!hasImage"
                    type="button"
                    (click)="nextStep()"
                    class="btn btn-white btn-stroke fruitfaces__next"
                >Далее</button>
            </div>
        </div>
    </div>
</div>

<input type="file" #fruitFacesUpload (change)="onSetMainPicture($event)" style="display: none" accept=".jpeg,.jpg,.png,.bmp,.gif" />
