import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { HelperService } from '@app/core/services/helper.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GroupViewModel } from '../model/group-view-model.model';
import { User } from '@app/profile/model/user.model';
import { GroupUserRole } from '../model/group-user-role.model';
import { GroupType } from '../model/group-type.model';
import { GroupInfo, IGroupCounters, IUserGroupCounters } from '../model/group-info.model';
import { IGroupUserLinkViewModel } from '../model/group-request.model';
import { IModuleInfo, IModule } from '../model/module';
import { IGroupUsersConfig } from '../model/group-users-config';
import { PostProfileSelectorType } from '@app/feed/model/post-profile-selector-type';

/**
 * API - сервис групп
 *
 * @export
 * @class GroupsApiService
 * @extends {BaseService}
 */
@Injectable({
  providedIn: 'root'
})
export class GroupsApiService extends BaseService {
  protected serviceUrl = `${this.helper.environment.siteUrl}/api/group`;

  constructor(
    protected http: HttpClient,
    public helper: HelperService) { super(http, helper); }


  getGroups(offset: number, limit: number): Observable<GroupViewModel> {
    return this.get<GroupViewModel>(`?offset=${offset}&limit=${limit}`);
  }

  searchGroups(query: string, offset: number, limit: number): Observable<GroupViewModel> {
    return this.get<GroupViewModel>(`searchGroups?query=${encodeURIComponent(query)}&offset=${offset}&limit=${limit}`);
  }

  /**
   * Получить все группы пользователя, в которых он Подписчик, Участник или Администратор
   *
   * @param {number} userId
   * @param {number} [offset=0]
   * @param {number} [count=20]
   * @returns {Observable<GroupViewModel>}
   * @memberof GroupsService
   */
  getUserGroups(userId: number, offset: number = 0, limit: number = 20): Observable<GroupViewModel> {
    return this.get<GroupViewModel>(`user/${userId}/groups?offset=${offset}&limit=${limit}`);
  }

  getUserGroupsCanCreatePost(
    userId: number,
    selectorType: PostProfileSelectorType,
    offset: number = 0,
    limit: number = 20): Observable<GroupViewModel> {
    // tslint:disable-next-line:max-line-length
    return this.get<GroupViewModel>(`user/${userId}/groupsCanCreatePost?selectorType=${selectorType}&offset=${offset}&limit=${limit}`);
  }

  getUserGroupsCanCreateSurveys(userId: number, offset: number = 0, limit: number = 20): Observable<GroupViewModel> {
    return this.get<GroupViewModel>(`user/${userId}/groupsCanCreateSurveys?offset=${offset}&limit=${limit}`);
  }

  addUsers(groupId: number, users: User[], role: GroupUserRole): Observable<any> {
    return this.post<any>(`addMembers`, { groupId: groupId, users: users, role: role });
  }

  approveRequest(groupId: number, userId: number, role: GroupUserRole): Observable<boolean> {
    return this.post<boolean>(`${groupId}/approveRequest/${userId}`, { role });
  }

  declineRequest(groupId: number, userId: number, role: GroupUserRole): Observable<boolean> {
    return this.post<boolean>(`${groupId}/declineRequest/${userId}`, { role });
  }

  join(groupId: number): Observable<boolean> {
    return this.post<boolean>(`${groupId}/join`);
  }

  leave(groupId: number, userId: number): Observable<boolean> {
    return this.post<boolean>(`leave`, { groupId: groupId, userId: userId });
  }

  removeModerator(groupId: number, userId: number): Observable<boolean> {
    return this.post<boolean>(`${groupId}/removeModerator/${userId}`, {  });
  }

  stayAsSubscriber(groupId: number, userId: number): Observable<boolean> {
    return this.post<boolean>(`stayAsSubscriber`, { groupId: groupId, userId: userId });
  }

  subscribe(groupId: number): Observable<boolean> {
    return this.post<boolean>(`${groupId}/subscribe`);
  }

  unsubscribe(groupId: number, userId: number): Observable<boolean> {
    return this.post<boolean>(`unsubscribe`, { groupId: groupId, userId: userId });
  }

  createGroup(title: string, description: string, type: GroupType): Observable<GroupInfo> {
    return this.post<GroupInfo>(`createGroup`, { title: title, description: description, type: type });
  }

  editGroup(group: GroupInfo): Observable<boolean> {
    return this.put<boolean>(`editGroup`, group);
  }

  createSite(group: GroupInfo): Observable<boolean> {
    return this.post<boolean>(`createSite`, { group: group });
  }

  changeGroupType(groupId: number, type: GroupType): Observable<boolean> {
    return this.put<boolean>(`changeGroupType`, { groupId: groupId, type: type });
  }

  deleteGroup(id: number): Observable<boolean> {
    return this.delete<boolean>(`${id}/deleteGroup`);
  }

  addMembers(groupId: number, users: User[], role: GroupUserRole): Observable<boolean> {
    return this.post<boolean>(`addMembers`, { groupId: groupId, users: users, role: role });
  }

  getGroup(groupId: number): Observable<GroupInfo> {
    return this.get<GroupInfo>(`${groupId}`);
  }

  getUsers(groupId: number, offset: number, limit: number): Observable<IGroupUserLinkViewModel> {
    return this.get<IGroupUserLinkViewModel>(`${groupId}/users?offset=${offset}&limit=${limit}`);
  }

  getUsersByRole(groupId: number, role: GroupUserRole, offset: number, limit: number): Observable<IGroupUserLinkViewModel> {
    return this.get<IGroupUserLinkViewModel>(`${groupId}/usersByRole?role=${role}&offset=${offset}&limit=${limit}`);
  }

  getRequests(groupId: number, offset: number, limit: number): Observable<IGroupUserLinkViewModel> {
    return this.get<IGroupUserLinkViewModel>(`${groupId}/requests?offset=${offset}&limit=${limit}`);
  }

  getUsersCountByRole(groupId: number, role: GroupUserRole): Observable<number> {
    return this.get<number>(`${groupId}/usersCountByRole?role=${role}`);
  }

  getUserGroupsCount(userId: number): Observable<number> {
    return this.get<number>(`user/${userId}/groupsCount`);
  }

  getGroupUsersCounters(groupId: number): Observable<IGroupCounters> {
    return this.get<IGroupCounters>(`${groupId}/groupUsersCounters`);
  }

  /**
   * Получение статистики во скольки группах пользователь администратор, подписчик или участник
   *
   * @param {number} userId ИД пользователя, статистику по которому нужно получить
   * @returns {Observable<IUserGroupCounters>}
   * @memberof GroupsService
   */
  getUserGroupCounters(userId: number): Observable<IUserGroupCounters> {
    return this.get(`user/${userId}/groupCounters`);
  }

  getUserGroupRequestsCount(userId: number, groupId: number): Observable<number> {
    const group = groupId ? `&groupId=${groupId}` : '';
    return this.get<number>(`userGroupRequestsCount?userId=${userId}${group}`);
  }

  uploadGroupPhoto(formData: any, groupId: number): Observable<any> {
    return super.post(`${groupId}/uploadPhoto`, formData);
  }

  removeGroupPhoto(groupId: number): Observable<any> {
    return super.delete(`${groupId}/removePhoto`);
  }

  getGroupModules(groupId: number): Observable<IModuleInfo[]> {
    return super.get(`${groupId}/modules`);
  }

  getModules(): Observable<IModule[]> {
    return super.get(`modules`);
  }

  getGroupUsersSettings(groupId: number): Observable<IGroupUsersConfig> {
    return super.get(`${groupId}/groupUsersSettings`);
  }

  changeGroupSettings(groupId: number, modules: IModuleInfo[], settings: IGroupUsersConfig) {
    return super.post(`${groupId}/changeSettings`, { modules, settings });
  }
}
