import { Component, OnInit, NgZone } from '@angular/core';
import { UserFeedComponent } from '@app/feed/components/user-feed/user-feed.component';
import { PostProfileSelectorType } from '@app/feed/model/post-profile-selector-type';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService } from '@app/profile/services/users.service';
import { SubscribeService } from '@app/subscribe/services/subscribe.service';
import { FeedService } from '@app/feed/services/feed.service';
import { LikesService } from '@app/likes/services/likes.service';
import { SignalRService } from '@app/signalr/signalR.service';
import { HelperService } from '@app/core/services/helper.service';
import { FeedType } from '@app/feed/model/feed-type.model';

@Component({
  selector: 'app-new-employees-list',
  templateUrl: './new-employees-list.component.html',
  styleUrls: ['./new-employees-list.component.scss']
})
export class NewEmployeesListComponent extends UserFeedComponent {

  selectorType: PostProfileSelectorType = PostProfileSelectorType.onlyBusinessWithNewEmployee;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected usersService: UsersService,
    protected subscribeService: SubscribeService,
    protected feedService: FeedService,
    protected likesService: LikesService,
    protected signalRService: SignalRService,
    protected ngZone: NgZone,
    public helper: HelperService) {
    super(router, route, usersService, subscribeService, feedService, likesService, signalRService, ngZone, helper);
  }

  protected getFeedInternal() {
    if (this.currentUser) {
      this.user = this.currentUser;
      // получение постов
      this.operateFeedResult(this.feedService.getNewEmployeeFeed(this.offset, this.limit));
    }
  }

  getFeedType(): FeedType {
    return FeedType.NewEmployeeFeed;
  }
}
