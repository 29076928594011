/**
 * Тип ленты. 1 - Лента профиля, 2 - Лента новостей, 3 - Лента группы
 *
 * @export
 * @enum {number}
 */
export enum FeedType {
    ProfileFeed = 1,
    UserFullFeed = 2,
    GroupFeed = 3,
    BusinessFeed = 4,
    NewEmployeeFeed = 5,
    BusinessGroupFeed = 6,
    UserFeedOtherViewer = 7,
    UserFeedSearchTags = 8
}
