/**
 * Помощник обработки всплывающего окошки пользователя
 *
 * @export
 * @class TooltipHelper
 */
export class TooltipHelper {

  private static create(container, tooltip) {
    // elm_edges relative to the viewport.
    const elm_edges = container.getBoundingClientRect();

    // Remove no-display + set the correct classname based on the position
    // of the elm.

    tooltip.className = 'hint__body';

    if (elm_edges.left > window.innerWidth - 120) {
      tooltip.className += ' hint__body-left';

      if (elm_edges.top + elm_edges.width + 20 > window.innerHeight) {
        tooltip.className += ' hint__body-top';
      }

    } else if ((elm_edges.left + (elm_edges.width / 2)) < 120) {
      tooltip.className += ' hint__body-right';

      if (elm_edges.top + elm_edges.width + 20 > window.innerHeight) {
        tooltip.className += ' hint__body-top';
      }

    } else {
      tooltip.className += ' hint__body-center';

      if (elm_edges.top + elm_edges.width + 20 > window.innerHeight) {
        tooltip.className += ' hint__body-top';
      }
    }
  }

  private static position(container, tooltip) {
    const elm_edges = container.getBoundingClientRect();
    // 10 = arrow height
    const elm_top = elm_edges.top + elm_edges.height + 10;
    const elm_bottom = elm_edges.top - tooltip.offsetHeight - 10;
    const viewport_edges = window.innerWidth - 120;

    // Position tooltip on the left side of the elm if the elm touches
    // the viewports right edge and elm width is < 50px.
    if (elm_edges.left > viewport_edges && elm_edges.width < 50) {
      // tslint:disable-next-line:max-line-length
      tooltip.style.left = (elm_edges.left - (tooltip.offsetWidth + elm_edges.width)) + 'px';
      this.setTooltipTop(elm_edges, tooltip, elm_bottom, elm_top);
      // Position tooltip on the left side of the elm if the elm touches
      // the viewports right edge and elm width is > 50px.
    } else if (elm_edges.left > viewport_edges && elm_edges.width > 50) {
      tooltip.style.left = (elm_edges.left - tooltip.offsetWidth) + 'px';
      this.setTooltipTop(elm_edges, tooltip, elm_bottom, elm_top);
    } else if ((elm_edges.left + (elm_edges.width / 2)) < 120) {
      // position tooltip on the right side of the elm.
      tooltip.style.left = (elm_edges.left + elm_edges.width) + 'px';

      this.setTooltipTop(elm_edges, tooltip, elm_bottom, elm_top);
    } else {
      // Position the toolbox in the center of the elm.
      const centered = (elm_edges.left + (elm_edges.width / 2)) - (tooltip.offsetWidth / 2);
      tooltip.style.left = centered + 'px';
      this.setTooltipTop(elm_edges, tooltip, elm_bottom, elm_top);
    }
  }

  private static setTooltipTop(elm_edges, tooltip, elm_bottom, elm_top) {
    if (elm_edges.top + elm_edges.width + 20 > window.innerHeight) {
      // position top
      tooltip.style.top = elm_bottom + 'px';
    } else {
      // position bottom
      tooltip.style.top = elm_top + 'px';
    }
  }

  /**
     * Отображение всплывашки пользователя
     *
     * @static
     * @param {*} user
     * @memberof Helper
     */
  public static showUserTooltip(e: MouseEvent, user) {
    if (window.innerWidth < 1000) {
      return;
    }

    if (user && user.fullName) {
      const tooltip = window.document.getElementById('user_tooltip');

      this.create(e.target, tooltip);
      this.position(e.target, tooltip);

      const name = window.document.getElementById('user_tooltip-name');
      name.innerText = user.fullName;

      const jobTitle = window.document.getElementById('user_tooltip-jobTitle');

      if (user.jobTitle) {
        jobTitle.innerText = user.jobTitle;
        jobTitle.style.display = 'block';
      } else {
        jobTitle.innerText = '';
        jobTitle.style.display = 'none';
      }

      const department = window.document.getElementById('user_tooltip-department');

      if (user.department) {
        department.innerText = user.department;
        department.style.display = 'block';
      } else {
        department.innerText = '';
        department.style.display = 'none';
      }

      const workPhone = window.document.getElementById('user_tooltip-workPhone');

      if (user.workPhone) {
        workPhone.innerText = user.workPhone;
        if (user.mobilePhone) {
          workPhone.innerText += ', ';
        }
        workPhone.style.display = 'inline-block';
      } else {
        workPhone.innerText = '';
        workPhone.style.display = 'none';
      }

      const mobilePhone = window.document.getElementById('user_tooltip-mobilePhone');

      if (user.mobilePhone) {
        mobilePhone.innerText = user.mobilePhone;
        mobilePhone.style.display = 'inline-block';
      } else {
        mobilePhone.innerText = '';
        mobilePhone.style.display = 'none';
      }

      tooltip.style.display = 'block';

      setTimeout(() => {
        tooltip.style.display = 'none';
      }, 5000);
    }
  }

  public static hideUserTooltip(e: MouseEvent) {
    const tooltip = window.document.getElementById('user_tooltip');
    tooltip.style.display = 'none';
  }
}
