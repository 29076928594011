import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FileMenuComponent, IFileMenuConfig } from '@app/files/components/file-menu/file-menu.component';
import { FilesService } from '@app/files/services/files.service';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { SearchResultItem } from '@app/search/model/search-result-item.model';
import { UsersService } from '@app/profile/services/users.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

/**
 * Поиск по файлам
 *
 * @export
 * @class FilesSearchListComponent
 * @extends {BaseComponent}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-files-search-list',
  templateUrl: './files-search-list.component.html',
  styleUrls: ['./files-search-list.component.scss']
})
export class FilesSearchListComponent extends BaseComponent implements OnDestroy {

  @Input() items: any[];
  @Input() allowSort: boolean;
  @Input() highlightText: string;

  // sorting
  @Input() sort: boolean;
  @Input() sortBy: string;
  @Input() showSortTooltip: boolean;
  @Input() sortTitle = 'По дате изменения';
  @Input() sortAsc = 'asc';
  @Input() sortField = 'Filename';

  @Output() sortItems: EventEmitter<any> = new EventEmitter();

  config: IFileMenuConfig = {};

  constructor(
    public router: Router,
    public usersService: UsersService,
    public filesService: FilesService,
    public helper: HelperService) { super(helper); }

  showContextMenu(event, itemMenu: FileMenuComponent) {
    // event.preventDefault();
    // event.stopImmediatePropagation();
    // itemMenu.show(event);
  }

  protected sortColumn(sortBy: string, sort: boolean) {
    // сортировку пока отключили bug #1574
    return;

    this.sort = sort;
    this.sortBy = sortBy;
    this.showSortTooltip = false;

    if (this.sortBy) {
      this.sortAsc = this.sort ? 'asc' : 'desc';
      switch (this.sortBy) {
        case 'modified':
          this.sortField = 'LastModifiedTime';
          this.sortTitle = 'По дате изменения';
          break;
        case 'user':
          this.sortField = 'EditorOWSUser';
          this.sortTitle = 'По автору изменения';
          break;
        default:
          this.sortField = 'Filename';
          this.sortTitle = 'По названию';
          break;
      }
    }
    const params = {
      sort: this.sort,
      sortAsc: this.sortAsc,
      sortBy: this.sortBy,
      showSortTooltip: this.showSortTooltip,
      sortField: this.sortField,
      sortTitle: this.sortTitle,
    };

    if (this.sortItems) {
      this.sortItems.emit(params);
    }
  }

  toggleSortTooltip() {
    this.showSortTooltip = !this.showSortTooltip;
  }

  openFolder(event: Event, item: SearchResultItem, hiddenLink: any) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    event.preventDefault();

    if (item.isPersonal) {
      if (item.editorLogin) {
        this.usersService.getExistingUserByLogin(item.editorLogin)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(user => {
            if (user) {
              // Костыль для получения логина сотрудника
              const index = item.url.indexOf('/personal/');
              const folderPath = item.url.substring(index);

              hiddenLink.href = `${this.helper.environment.startPage}/profile/${user.id}/files#files&folder=${folderPath}`;
              hiddenLink.click();
            } else {
              // TODO: Error alert
            }
          });
      }
    } else if (item.isGroup) {
      // костыль для получения Ид группы
      const index = item.url.indexOf('/groups/group');
      const folderPath = item.url.substring(index);

      let numberString = item.url.substring(index + 13);
      numberString = numberString.substring(0, numberString.indexOf('/'));

      hiddenLink.href = `${this.helper.environment.startPage}/group/${numberString}/files#files&folder=${folderPath}`;
      hiddenLink.click();
    } else {
      hiddenLink.href = item.url;
      hiddenLink.click();
    }
  }
}
