import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { BreadcrumbsService, IBreadcrumb } from '@app/shared/services/breadcrumbs.service';
import { takeUntil } from 'rxjs/operators';

/**
 * Компонент хлебных крошек
 *
 * @export
 * @class BreadcrumbsComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent extends BaseComponent implements OnInit, OnDestroy {

  /**
   * Хлебный крошки для отображения
   *
   * @type {IBreadcrumb[]}
   * @memberof BreadcrumbsComponent
   */
  breadcrumbs: IBreadcrumb[] = null;

  constructor(public breadcrumbsService: BreadcrumbsService,
    public helper: HelperService,
    ) { super(helper); }

  ngOnInit(): void {
    this.breadcrumbsService.breadcrumbs.pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        this.breadcrumbs = res;
      });
  }
}
