import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IdeaEditComponent } from '@app/ideas/components/forms/idea-edit/idea-edit.component';
import { IdeaNewComponent } from '@app/ideas/components/forms/idea-new/idea-new.component';
import { IdeaViewComponent } from '@app/ideas/components/forms/idea-view/idea-view.component';
import { GroupPageEditComponent } from '@app/pages/components/forms/page-edit/group-page-edit.component';
import { GroupPageNewComponent } from '@app/pages/components/forms/page-new/group-page-new.component';
import { GroupPageViewComponent } from '@app/pages/components/forms/page-view/group-page-view.component';
import { SurveyEditComponent } from '@app/surveys/components/forms/survey-edit/survey-edit.component';
import { GroupSurveyNewComponent } from '@app/surveys/components/forms/survey-new/group-survey-new.component';
import { SurveyViewComponent } from '@app/surveys/components/forms/survey-view/survey-view.component';
import { SurveyResultsComponent } from '@app/surveys/components/survey-results/survey-results.component';
import { ResponseViewComponent } from '@app/vacancy/components/forms/response-view/response-view.component';
import { VacancyEditComponent } from '@app/vacancy/components/forms/vacancy-edit/vacancy-edit.component';
import { GroupVacancyNewComponent } from '@app/vacancy/components/forms/vacancy-new/group-vacancy-new.component';
import { VacancyViewComponent } from '@app/vacancy/components/forms/vacancy-view/vacancy-view.component';
import { GroupFeedComponent } from '@app/groups/components/feed/group-feed/group-feed.component';
import { GroupPostShareComponent } from '@app/groups/components/feed/group-post-share/group-post-share.component';
import { GroupPostViewComponent } from '@app/groups/components/feed/group-post-view/group-post-view.component';
import { GroupAlbumNewComponent } from '@app/groups/components/gallery/group-album-new/group-album-new.component';
import { GroupAlbumViewComponent } from '@app/groups/components/gallery/group-album-view/group-album-view.component';
import { GroupGalleryMainComponent } from '@app/groups/components/gallery/group-gallery-main/group-gallery-main.component';
import { GroupGalleryViewComponent } from '@app/groups/components/gallery/group-gallery-view/group-gallery-view.component';
import { GroupFilesListComponent } from '@app/groups/components/group/group-files-list/group-files-list.component';
import { GroupNewComponent } from '@app/groups/components/group/group-form/group-new.component';
import { GroupViewComponent } from '@app/groups/components/group/group-form/group-view.component';
import { GroupSearchResultsComponent } from '@app/groups/components/group/group-search-results/group-search-results.component';
import { GroupServiceComponent } from '@app/groups/components/group/group-service/group-service.component';
import { GroupSettingsComponent } from '@app/groups/components/group/group-settings/group-settings.component';
import { GroupUsersListComponent } from '@app/groups/components/group/group-users/group-users-list.component';
import { GroupIdeaListComponent } from '@app/groups/components/ideas/group-idea-list/group-idea-list.component';
import { GroupPageListComponent } from '@app/groups/components/pages/group-page-list/group-page-list.component';
import { GroupSurveyListComponent } from '@app/groups/components/surveys/group-survey-list/group-survey-list.component';
import { GroupVacancyHhListComponent } from '@app/groups/components/vacancy/group-vacancy-hh-list/group-vacancy-hh-list.component';
import { GroupVacancyListComponent } from '@app/groups/components/vacancy/group-vacancy-list/group-vacancy-list.component';
import { IntryAutoLoginGuard } from '@app/auth/shared/guards/intry-auto-login.guard';
import { GetCurrentUserResolver } from './resolvers/current-user-resolver';

const routes: Routes = [
    {
        path: 'group/new',
        canActivate: [IntryAutoLoginGuard],
        component: GroupNewComponent, data: { title: 'Создание группы' }
    },
    {
        path: 'group/:id', component: GroupViewComponent,
        canActivate: [IntryAutoLoginGuard],
        // resolve: {
        //     currentUser: GetCurrentUserResolver,
        // },
        children: [
            {
                path: '',
                component: GroupFeedComponent,
                data: { title: 'Группа' }
            },
            {
                path: 'post/:postId',
                component: GroupPostViewComponent,
                data: { title: 'Группа - Публикация' }
            },
            {
                path: 'post/:postId/share',
                component: GroupPostShareComponent,
                data: { title: 'Группа - Публикация - Поделиться' }
            },
            {
                path: 'users',
                component: GroupUsersListComponent,
                data: { title: 'Группа - Участники' }
            },
            {
                path: 'files',
                component: GroupFilesListComponent,
                data: { title: 'Группа - Файлы' }
            },
            {
                path: 'search',
                component: GroupSearchResultsComponent,
                data: { title: 'Группа - Поиск' }
            },
            {
                path: 'settings',
                component: GroupSettingsComponent,
                data: { title: 'Группа - Настройки' }
            },
            {
                path: 'gallery', component: GroupGalleryMainComponent, children: [
                    {
                        path: '',
                        component: GroupGalleryViewComponent,
                        data: { title: 'Группа - Галерея' }
                    },
                    {
                        path: 'new',
                        component: GroupAlbumNewComponent,
                        data: { title: 'Группа - Галерея - Создание альбома' }
                    },
                    {
                        path: ':id',
                        component: GroupAlbumViewComponent,
                        data: { title: 'Группа - Галерея - Альбом' }
                    },
                ]
            },
            {
                path: 'surveys', component: GroupServiceComponent, children: [
                    {
                        path: '',
                        component: GroupSurveyListComponent,
                        data: { title: 'Группа - Опросы' }
                    },
                    {
                        path: 'new',
                        component: GroupSurveyNewComponent,
                        data: { title: 'Группа - Опросы - Создание опроса' }
                    },
                    {
                        path: ':surveyId',
                        component: SurveyViewComponent,
                        data: { title: 'Группа - Опросы - Опрос' }
                    },
                    {
                        path: ':surveyId/results',
                        component: SurveyResultsComponent,
                        data: { title: 'Группа - Опросы - Результаты опроса' }
                    },
                    {
                        path: ':surveyId/edit',
                        component: SurveyEditComponent,
                        data: { title: 'Группа - Опросы - Редактирование опроса' }
                    },
                ]
            },
            {
                path: 'vacancy', component: GroupServiceComponent, children: [
                    {
                        path: '',
                        component: GroupVacancyListComponent,
                        data: { title: 'Группа - Вакансии' }
                    },
                    {
                        path: 'new',
                        component: GroupVacancyNewComponent,
                        data: { title: 'Группа - Вакансии - Создание вакансии' }
                    },
                    {
                        path: 'hh',
                        component: GroupVacancyHhListComponent,
                        data: { title: 'Группа - Вакансии - Вакансии с hh.ru' }
                    },
                    {
                        path: ':vacancyId',
                        component: VacancyViewComponent,
                        data: { title: 'Группа - Вакансии - Вакансия' }
                    },
                    {
                        path: ':vacancyId/edit',
                        component: VacancyEditComponent,
                        data: { title: 'Группа - Вакансии - Редактирование вакансии' }
                    },
                    {
                        path: ':vacancyId/response/:responseId',
                        component: ResponseViewComponent,
                        data: { title: 'Группа - Вакансии - Отклик на вакансию' }
                    },
                    // {
                    //     path: 'hh/:vacancyId',
                    //     component: GroupVacancyHhListComponent,
                    //     data: { title: 'Группа - Вакансии - Вакансии с hh.ru' }
                    // },
                ]
            },
            {
                path: 'pages', component: GroupServiceComponent, children: [
                    {
                        path: '',
                        component: GroupPageListComponent,
                        data: { title: 'Группа - Страницы' }
                    },
                    {
                        path: 'new',
                        component: GroupPageNewComponent,
                        data: { title: 'Группа - Создание страницы' }
                    },
                    {
                        path: ':pageId',
                        component: GroupPageViewComponent,
                        data: { title: 'Группа - Страницы - Страница' }
                    },
                    {
                        path: ':pageId/edit',
                        component: GroupPageEditComponent,
                        data: { title: 'Группа - Страницы - Редактирование страницы' }
                    }
                ]
            },
            { path: 'ideas', component: GroupIdeaListComponent, data: { title: 'Группа - Идеи' }, pathMatch: 'full' },
            {
                path: 'ideas', component: GroupServiceComponent, children: [
                    {
                        path: 'new',
                        component: IdeaNewComponent,
                        data: { title: 'Группа - Идеи - Создание идеи' }
                    },
                    {
                        path: ':ideaId',
                        component: IdeaViewComponent,
                        data: { title: 'Группа - Идеи - Идея' }
                    },
                    {
                        path: ':ideaId/edit',
                        component: IdeaEditComponent,
                        data: { title: 'Группа - Идеи - Редактирование идеи' }
                    }
                ]
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class GroupsRoutingModule { }
