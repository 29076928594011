import { InjectionToken } from '@angular/core';
import { OpenIdConfiguration } from 'angular-auth-oidc-client';

type OmittedOpenIdConfigurationKeys = 'stsServer' | 'authWellknownEndpoint' | 'triggerAuthorizationResultEvent';

export interface IntryAuthConfig extends Omit<OpenIdConfiguration, OmittedOpenIdConfigurationKeys> {
  frontUrl?: string;
  refreshSessionOffsetAllowedInSeconds?: number;
  allowExternal?: boolean;
  showHeaderForExternal?: boolean;
  authority?: string;
}

export const INTRY_AUTH_CONFIG = new InjectionToken<IntryAuthConfig>('IntryAuthConfig');
