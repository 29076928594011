import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
// @ts-ignore
import videojs from 'video.js';

@Component({
    selector: 'app-video-player',
    template: `
    <video
        #target
        width="640"
        height="480"
        class="video-js vjs-theme-forest"
        controls 
        playsinline 
        preload="metadata">
    </video>
  `,
    styleUrls: [
        './video-player.component.scss'
    ],
    encapsulation: ViewEncapsulation.None,
})
export class VideoPlayerComponent extends BaseComponent implements OnChanges, OnDestroy {
    @ViewChild('target', { static: true }) target: ElementRef;
    // see options: https://github.com/videojs/video.js/blob/mastertutorial-options.html
    @Input() options: {
        fluid?: boolean,
        aspectRatio?: string,
        autoplay: boolean,
        sources: {
            src: string,
            type: string,
        }[],
    };
    @Input() current: boolean;
    player: videojs.Player;

    constructor(
        private elementRef: ElementRef,
        public helper: HelperService
    ) { super(helper); }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.player && changes.current && this.current) {
            const video = this.target.nativeElement;
            this.player = videojs(video, this.options);
            // this.player.play();
        }

        if (this.player && changes.current && !this.current) {
            this.player.pause();
        }
    }

    ngOnDestroy() {
        // destroy player
        if (this.player) {
            this.player.dispose();
        }
        super.ngOnDestroy();
    }
}
