<div class="cos__body">
    <div class="poll" *ngFor="let survey of surveys; trackBy:trackBySurvey">
        <img [src]="surveysService.getStatusImageUrl(survey)" class="poll__status">
        <div class="poll__desc">
            <a class="poll__title" routerLink="/group/{{survey.group?.id}}/surveys/{{survey.id}}">{{survey.title}}</a>
            <!-- Для черновика отображается дата создания -->
            <div class="poll__subtitle" *ngIf="!survey.published">от {{survey.created | dateSpLike}},
                {{getText(survey)}}</div>
            <!-- Для опубликованного отображается дата окончания опроса -->
            <div class="poll__subtitle" *ngIf="survey.published">до {{survey.dateEnd | date:'dd.MM.yy'}},
                {{getText(survey)}}</div>
            <a class="poll__dir" routerLink="/group/{{survey.group?.id}}">{{survey.group.title}}</a>
        </div>
        <app-survey-menu [item]="survey" [config]="surveysService.getMenuConfig(survey)" (deleted)="onDeleted($event)">
        </app-survey-menu>
    </div>
</div>