<div class="card _surveys" *ngIf="loaded && showWidget">
  <div class="card__title">
    <div class="pull-right">
      <app-surveys-menu [showAddButton]="showAddButton" [fromWidget]="true"></app-surveys-menu>
    </div>
    <div class="header-text">
      <a class="header-text__text" (click)="navigate()" [routerLink]="link">Опросы</a>&nbsp;
    </div>
  </div>
  <div class="card__content" *ngIf="showWidget">
    <div class="poll" *ngFor="let survey of surveys; trackBy:survey?.id">
      <a class="poll__link" routerLink="/group/{{survey.group?.id}}/surveys/{{survey.id}}">
        <img src="{{assetsPrefix}}/assets/images/polls/poll-widget.svg" class="poll__status">
        <div class="poll__desc">
          <span class="poll__title">{{survey.title}}</span>
          <div class="poll__subtitle">до {{survey.dateEnd | date:'dd.MM.yy'}}</div>
        </div>
      </a>
      <app-survey-menu [whiteDots]="true" [item]="survey" [config]="surveysService.getMenuConfig(survey)"
        [surveyLocation]="4" (deleted)="onDeleted($event)"></app-survey-menu>
    </div>
  </div>
  <div class="card__content" *ngIf="!showWidget && group">
    <app-group-forbidden></app-group-forbidden>
  </div>
  <app-empty-widget-content *ngIf="isShowEmpty()" [text]="'Нет опубликованных опросов'"></app-empty-widget-content>
</div>
<div class="card" *ngIf="!loaded">
  <div class="card__title">
    <span class="preloader">
      <span class="preloader__line"></span>
    </span>
  </div>
  <div class="card__content">
    <span class="preloader _square" *ngFor="let r of [0,1,2,3]">
      <span class="preloader__side"></span>
      <span class="preloader__line"></span>
    </span>
  </div>
</div>