/**
 * Group
 */
import { GroupType } from '@app/groups/model/group-type.model';

export class GroupInfo {
    public id: number;
    public created?: Date;
    public description?: string;
    public pictureUrl: string;
    public title: string;
    public type: GroupType;
    public url?: string;
    public usersCount?: number;
    public siteId?: string;

    public memberState?: number;
    public isMember?: boolean;

    public subscriberState?: number;
    public isSubscriber?: boolean;

    public adminState?: number;
    public isAdmin?: boolean;

    // ui
    public showCard?: boolean;
    public smallPicture?: string;
    public isCanView?: boolean;
}

export interface IGroupCounters {
    members: number;
    subscribers: number;
    administrators: number;
    requests: number;
}

export interface IUserGroupCounters {
    asMember: number;
    asSubscriber: number;
    asAdministrator: number;
}
