<div class="card hidden-desktop hidden-tablet _close-button">
  <button mat-button mat-dialog-close type="button" class="btn icon _close-blue"></button>
</div>
<div class="cropper-form">
  <span class="header-text _modal">
    <span class="header-text__text">Редактирование фотографии</span>
  </span>
  <p class="cropper-form__desc">Отображаемая область фотографии изменяется с сохранением соотношения сторон 16:9.
    Дополнительно в центральной части пунктирной линией обозначена область, которая будет гарантированно
    отображаться для одного из новостных блоков на главной странице, формат которого отличается от 16:9.</p>
  <div class="cropper-form__image" *ngIf="mediaFileItem">
    <img style="display: none;" [src]="galleryService.getMediaFileUrl(mediaFileItem)"
      (load)="onImgLoad($event.target)" />
    <image-cropper format="jpeg" class="cropper-form__cropper" [imageBase64]="mediaFileItem.tempUrl"
      [maintainAspectRatio]="true" [aspectRatio]="16 / 9" format="png" (imageLoaded)="imageLoaded()"
      (imageCropped)="imageCropped($event)"></image-cropper>
  </div>
  <div class="form__actions">
    <button type="button" class="btn btn-simple" (click)="cancel()">
      Отмена
    </button>
    <button type="button" [attr.disabled]="isValid() ? null : ''" class="btn btn-primary" (click)="submit()">
      Сохранить
    </button>
  </div>
  <div class="cropper-form__error">
    <span *ngIf="errorMessage">{{ errorMessage }}</span>
  </div>
</div>
