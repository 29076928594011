import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { environment } from '@env/environment';
import { HelperService } from '@app/core/services/helper.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  IIdea,
  INewIdea,
  IUpdateIdea,
  IIdeaStatus,
  IUpdateIdeaStatus,
  IIdeaStatusUpdate,
  INewIdeaStatus,
  IIdeaStatusChanges,
  IIdeaStatusChangesModel,
  IdeasListType
} from '../model/idea';
import { IChangeOrders } from '@app/shared/model/item-order';

/**
 * АПИ сервис идей
 *
 * @export
 * @class IdeasApiService
 * @extends {BaseService}
 */
@Injectable({
  providedIn: 'root'
})
export class IdeasApiService extends BaseService {

  protected serviceUrl = `${environment.siteUrl}/api/idea`;

  constructor(
    protected http: HttpClient,
    public helper: HelperService) { super(http, helper); }

  /**
   * Идеи для виджета
   *
   * @param {number} profileId ИД профиля (пользователь или виджет)
   * @param {number} offset сдвиг
   * @param {number} limit количество возвращаемых значений
   * @returns {Observable<IIdea[]>}
   * @memberof IdeaApiService
   */
  getProfileIdeas(profileId: number, offset: number, limit: number): Observable<IIdea[]> {
    return super.get(`profile/${profileId}?offset=${offset}&limit=${limit}`);
  }

  /**
   * Идеи, созданные в группе
   *
   * @param {number} groupId ИД группы
   * @param {number} offset сдвиг
   * @param {number} limit количество возвращаемых значений
   * @returns {Observable<IIdea[]>}
   * @memberof IdeaApiService
   */
  getGroupIdeas(groupId: number, statusId: number, listType: IdeasListType, offset: number, limit: number): Observable<IIdea[]> {
    return super.get(`group/${groupId}?statusId=${statusId}&listType=${listType}&offset=${offset}&limit=${limit}`);
  }

  /**
   * История изменений статусов идеи
   *
   * @param {number} id ИД идеи
   * @returns {Observable<IIdeaStatusChangesModel>}
   * @memberof IdeasApiService
   */
  getIdeaStatusHistory(id: number): Observable<IIdeaStatusChangesModel> {
    return super.get(`${id}/statusHistory`);
  }

  /**
   * Получить идею по идентификатору
   *
   * @param {number} id ИД идеи
   * @returns {Observable<IIdea>}
   * @memberof IdeaApiService
   */
  getById(id: number): Observable<IIdea> {
    return super.get(`${id}`);
  }

  /**
   * Создать идею
   *
   * @param {INewIdea} idea новая идея
   * @returns
   * @memberof IdeaApiService
   */
  create(idea: INewIdea): Observable<IIdea> {
    return super.post(``, idea);
  }

  /**
   * Обновить идею
   *
   * @param {IUpdateIdea} survey
   * @returns
   * @memberof IdeaApiService
   */
  update(idea: IUpdateIdea): Observable<boolean> {
    return super.put(``, idea);
  }

  /**
   * Опубликовать идею
   *
   * @param {IUpdateIdea} survey
   * @returns
   * @memberof IdeaApiService
   */
  publish(id: number): Observable<boolean> {
    return super.put(`${id}/publish`, {});
  }

  /**
   * Отменить публикацию идеи
   *
   * @param {IUpdateIdea} survey
   * @returns
   * @memberof IdeaApiService
   */
  unpublish(id: number): Observable<boolean> {
    return super.put(`${id}/unPublish`, {});
  }

  /**
   * Удалить идею
   *
   * @param {number} id
   * @returns
   * @memberof IdeaApiService
   */
  remove(id: number): Observable<boolean> {
    return super.delete(`${id}`);
  }

  /**
   * Обновить статус идеи
   *
   * @param {IUpdateIdeaStatus} status
   * @returns {Observable<boolean>}
   * @memberof IdeasApiService
   */
  updateIdeaStatus(status: IIdeaStatusUpdate): Observable<boolean> {
    return super.put(`updateStatus`, status);
  }

  /**
   * Получить все статусы идей, вне зависимости от того, используются они или нет
   *
   * @param {boolean} [inactive=false] Возвращать неактивные статусы
   * @returns {Observable<IIdeaStatus[]>}
   * @memberof IdeasApiService
   */
  getIdeaStatuses(inactive: boolean = false): Observable<IIdeaStatus[]> {
    return super.get(`statuses?inactive=${inactive}`);
  }

  /**
   * Получить только те статусы, которые используются и есть в уже созданных идеях
   *
   * @returns {Observable<IIdeaStatus[]>}
   * @memberof IdeasApiService
   */
  getIdeaStatusesInUse(): Observable<IIdeaStatus[]> {
    return super.get(`statusesInUse`);
  }

  addIdeaStatus(status: INewIdeaStatus): Observable<IIdeaStatus> {
    return super.post(`status`, status);
  }

  editIdeaStatus(status: IUpdateIdeaStatus): Observable<IIdeaStatus> {
    return super.put(`status/edit`, status);
  }

  editIdeaStatuses(statuses: IIdeaStatusChanges): Observable<boolean> {
    return super.put(`statuses`, statuses);
  }

  hideIdeaStatus(id: number): Observable<boolean> {
    return super.put(`status/${id}/hide`, {});
  }

  /**
   * Возвращает true, если статус используется
   *
   * @param {number} id Ид статуса
   * @returns {Observable<boolean>}
   */
  checkIdeaStatusInUse(id: number): Observable<boolean> {
    return super.post(`status/${id}/inUse`, {});
  }

  removeIdeaStatus(id: number): Observable<boolean> {
    return super.delete(`status/${id}`);
  }

  changeIdeaStatusOrder(settings: IChangeOrders): Observable<boolean> {
    return super.put(`statusOrder`, settings);
  }
}
