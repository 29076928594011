<div class="cos__body _paddings" *ngIf="loaded && survey">
  <ng-container *ngIf="isEdit; then edit else display"></ng-container>
  <ng-template #display>
    <div class="cos__actions pull-right" *ngIf="survey">
      <app-survey-menu [item]="survey" [config]="surveysService.getMenuConfig(survey)" [surveyLocation]="1"
        (deleted)="onDeleted($event)"></app-survey-menu>
    </div>
    <div class="news-title _bright" *ngIf="!fakeTest">{{survey?.title}}</div>
    <span class="header-text _low" *ngIf="survey?.description && !fakeTest">
      <span class="header-text__text">{{survey?.description}}</span>
    </span>
    <ng-container *ngIf="showSurveyCompleted()">
      <div>Спасибо, что прошли данный опрос. Вернуться в <a [routerLink]="['..']">список</a> опросов.</div>
    </ng-container>
    <ng-container *ngIf="showFakeSurveyCompleted()">
      <div class="milk-day">
        <h1 class="milk-day__title">Поздравляем! <br />Вы - {{fakeTest[1]}} «ФрутоНяня»!</h1>
        <div class="milk-day__image-wrapper"><img src="{{assetsPrefix}}/assets/images/milk-day/{{fakeTest[2]}}"
            class="milk-day__image"></div>
        <h2 class="milk-day__info">два простых шага для получения ценного приза</h2>
        <ol class="milk-day__steps">
          <li class="milk-day__step">
            <span>Сделайте креативное фото с {{fakeTest[0]}} «ФрутоНяня»</span>
          </li>
          <li class="milk-day__step">
            <span>Загрузите фотографию в<a href="https://portal.progressfood.ru/default.aspx/group/2093/gallery/70">
                альбом конкурса</a></span>
          </li>
        </ol>
      </div>
    </ng-container>
    <ng-container *ngIf="!isSurveyActive()">
      <div>Опрос завершён. Вернуться в <a [routerLink]="['..']">список</a> опросов.</div>
    </ng-container>
    <ng-container *ngIf="allowTakeSurvey()">
      <div class="poll-section" *ngFor="let question of survey?.questions; let i=index">
        <ng-container
          *ngTemplateOutlet="question.isGroup ? questionGroupTemplate : questionBaseTemplate; context: {$implicit: {i:i, questions:survey.questions, question: question}}">
        </ng-container>
      </div>
      <div class="poll-section">
        <button type="button" class="button btn btn-primary pull-right" [attr.disabled]="isValid()?null:''"
          (click)="submit()">
          Ответить</button>
      </div>
    </ng-container>
    <ng-container *ngIf="isTestCompleted()">
      <div class="poll-total">
        <div class="poll-total__wrap">
          <div class="poll-total__title">результат</div>
          <div class="poll-total__result">{{testResultCount}}</div>
          <div class="poll-total__limit">из 100</div>
        </div>
      </div>

      <div class="poll-section" *ngFor="let question of survey?.questions; let i=index">
        <ng-container
          *ngTemplateOutlet="question.isGroup ? questionGroupTemplate : questionBaseTemplate; context: {$implicit: {i:i, questions:survey.questions, question: question}}">
        </ng-container>
      </div>
    </ng-container>
  </ng-template>
  <ng-template #edit>
    <app-survey-edit [survey]="survey"></app-survey-edit>
  </ng-template>
</div>

<div class="cos__body _paddings" *ngIf="loaded && !survey">
  Опроса не существует или у вас нет прав на его просмотр
</div>

<ng-template #questionGroupTemplate let-data>
  <div class="news-announce _upper">{{data.question.order}}. {{data.question.title}}{{data.question.required ? "*" :
    ""}}</div>
  <div class="news-announce _secondary" *ngIf="data.question.description">{{data.question.description}}</div>
  <div class="poll-section" *ngFor="let childQuestion of data.question.questions; let childIndex=index;">
    <ng-container
      *ngTemplateOutlet="questionBaseTemplate; context: {$implicit: {parentIndex:data.i, i:childIndex, questions:data.question.questions, question: childQuestion}}">
    </ng-container>
  </div>
</ng-template>

<ng-template #questionBaseTemplate let-data>
  <ng-container [ngSwitch]="data.question.type">
    <ng-container *ngSwitchCase="SurveyQuestionType.single">
      <ng-container
        *ngTemplateOutlet="simpleQuestionTemplate; context: {$implicit: {parentIndex:data.parentIndex, index:data.i, question: data.question}}">
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="SurveyQuestionType.multiple">
      <ng-container
        *ngTemplateOutlet="multipleQuestionTemplate; context: {$implicit: {parentIndex:data.parentIndex, index:data.i, question: data.question}}">
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="SurveyQuestionType.text">
      <ng-container
        *ngTemplateOutlet="textQuestionTemplate; context: {$implicit: {parentIndex:data.parentIndex, index:data.i, question: data.question}}">
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="SurveyQuestionType.range">
      <ng-container
        *ngTemplateOutlet="rangeQuestionTemplate; context: {$implicit: {parentIndex:data.parentIndex, index:data.i, question: data.question}}">
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<!-- Вариант вопроса с единственным выбором -->
<ng-template #simpleQuestionTemplate let-data>
  <div class="news-announce"><span *ngIf="data.parentIndex>=0">{{data.parentIndex+1}}.</span>{{data.index+1}}.
    {{data.question.title}}{{data.question.required ? "*" : ""}}</div>
  <div class="poll__wrap">
    <div class="news-announce _secondary" *ngIf="data.question.description">{{data.question.description}}</div>
    <ng-container *ngFor="let option of data.question.options">
      <label class="checkbox" [ngClass]="{
          '_full-width _with-input': option.type===SurveyQuestionOptionType.other,
          '_right': isTestCompleted() && option.isCorrect,
          '_wrong': isTestCompleted() && option.isAnswered && !option.isCorrect
        }">
        <input type="radio" name="question_{{data.question.id}}" (change)="radioSelected(data.question, option)"
          class="checkbox__input" [disabled]="isTestCompleted()" [checked]="isTestCompleted() && option.isAnswered || !isTestCompleted() && option.selected">
        <span class="checkbox__icon"></span>
        <span class="checkbox__text" *ngIf="option.type===SurveyQuestionOptionType.normal">{{option.title}}</span>
        <span class="checkbox__text" *ngIf="option.type===SurveyQuestionOptionType.other">Другое</span>
        <span class="input-main _huge">
          <textarea autosize rows="1" type="text" placeholder="Введите свой ответ" *ngIf="option.type===SurveyQuestionOptionType.other"
            [(ngModel)]="option.value" class="input-main__field" (focus)="radioOtherFocused(option)"></textarea>
        </span>
      </label><br>
    </ng-container>
  </div>
</ng-template>
<!-- Вариант вопроса с единственным выбором END -->

<!-- Вариант вопроса с множественным выбором -->
<ng-template #multipleQuestionTemplate let-data>
  <div class="news-announce"><span *ngIf="data.parentIndex>=0">{{data.parentIndex+1}}.</span>{{data.index+1}}.
    {{data.question.title}}{{data.question.required ? "*" : ""}}</div>
  <div class="poll__wrap">
    <div class="news-announce _secondary" *ngIf="data.question.description">{{data.question.description}}</div>
    <ng-container *ngFor="let option of data.question.options">
      <label class="checkbox" [ngClass]="{
         '_full-width _with-input': option.type===SurveyQuestionOptionType.other,
         '_right': isTestCompleted() && option.isCorrect,
         '_wrong': isTestCompleted() && option.isAnswered && !option.isCorrect
       }">
        <input type="checkbox" name="question_{{data.question.id}}" (change)="checkboxSelected(option)"
          class="checkbox__input" [disabled]="isTestCompleted()" [checked]="isTestCompleted() && option.isAnswered || !isTestCompleted() && option.selected">
        <span class="checkbox__icon"></span>
        <span class="checkbox__text" *ngIf="option.type===SurveyQuestionOptionType.normal">{{option.title}}</span>
        <span class="checkbox__text" *ngIf="option.type===SurveyQuestionOptionType.other">Другое</span>
        <span class="input-main _huge">
          <textarea autosize rows="1" type="text" placeholder="Введите свой ответ" *ngIf="option.type===SurveyQuestionOptionType.other"
            [(ngModel)]="option.value" class="input-main__field" (focus)="checkboxOtherFocused(option)"></textarea>
        </span>
      </label><br>
    </ng-container>
  </div>
</ng-template>
<!-- Вариант вопроса с множественным выбором END -->

<!-- Текстовый вариант вопроса -->
<ng-template #textQuestionTemplate let-data>
  <div class="news-announce"><span *ngIf="data.parentIndex>=0">{{data.parentIndex+1}}.</span>{{data.index+1}}.
    {{data.question.title}}{{data.question.required ? "*" : ""}}</div>
  <div class="poll__wrap">
    <div class="news-announce _secondary" *ngIf="data.question.description">{{data.question.description}}</div>
    <div class="input-main _huge">
      <textarea autosize rows="1" type="text" placeholder="Введите свой ответ" [(ngModel)]="data.question.options[0].value"
        class="input-main__field"></textarea>
      <!--button(type="button").btn.btn-simple.icon._clear.input-main__clear-->
    </div>
  </div>
</ng-template>
<!-- Текстовый вариант вопроса END -->

<!-- Вариант вопроса с оценкой -->
<ng-template #rangeQuestionTemplate let-data>
  <div class="news-announce"><span *ngIf="data.parentIndex>=0">{{data.parentIndex+1}}.</span>{{data.index+1}}.
    {{data.question.title}}{{data.question.required ? "*" : ""}}</div>
  <div class="poll__wrap">
    <div class="news-announce _secondary" *ngIf="data.question.description">{{data.question.description}}</div>
    <ng-container *ngFor="let option of getQuestionRange(data.question.meta)">
      <label class="checkbox _tiny">
        <input type="radio" name="question_{{data.question.id}}" [checked]="data.question.options[0].value == option"
          (change)="questionRangeRadioSelected(data.question, option)" class="checkbox__input">
        <span class="checkbox__icon"></span>
        <span class="checkbox__text">{{option}}</span>
      </label>
    </ng-container>
  </div>
</ng-template>
<!-- Вариант вопроса с оценкой END -->