<div class="container _full-page _moved _with-sidebar">

    <div class="sidebar">
        <ng-template appDynamicContainer></ng-template>
        <button [@visibilityChanged]="goTopVisible" (click)="scrollTop()" type="button"
            class="btn back-to-top"></button>
    </div>
    <div class="content">
        <router-outlet></router-outlet>
        <div class="align-center hidden-desktop">
            <button [@visibilityChanged]="goTopVisible" (click)="scrollTop()" type="button"
                class="btn back-to-top"></button>
        </div>
    </div>
</div>
