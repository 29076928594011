import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { HelperService } from '@app/core/services/helper.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { ISetting, ISettingsChanges } from '../model/setting';

@Injectable({
  providedIn: 'root'
})
export class SettingsService extends BaseService {
  protected serviceUrl = `${environment.siteUrl}/api/settings`;

  constructor(
    protected http: HttpClient,
    public helper: HelperService) { super(http, helper); }

  getValue(key: string): Observable<string> {
    return super.get(`value?key=${key}`, null, null, 'text');
  }

  getValues(keys: string[], cached = true): Observable<ISetting[]> {
    return super.post(`values?cached=${cached}`, keys);
  }

  getAll(): Observable<ISetting[]> {
    return super.get(`all`);
  }

  save(key: string, value: string): Observable<ISetting> {
    return super.post(`save`, { key: key, value: value });
  }

  editSettings(settings: ISettingsChanges): Observable<boolean> {
    return super.put(`editSettings`, settings);
  }
}
