import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { HelperService } from '@app/core/services/helper.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GamificationApiService extends BaseService {

  protected serviceUrl = `${this.helper.environment.siteUrl}/api/gamification`;

  constructor(
    protected http: HttpClient,
    public helper: HelperService) { super(http, helper); }

  getSumForUser(userId: number): Observable<number> {
    return super.get(`${userId}/sum`);
  }

  getCountForUser(userId: number): Observable<number> {
    return super.get(`${userId}/count`);
  }
}
