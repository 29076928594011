<div class="card _profile">
  <input #uploadPhoto type="file" class="profile-photo__file" accept="image/jpeg,image/png"
    (change)="imageChangeEvent($event)" style="display: none">
  <div class="profile" [ngClass]="{'_current': isCurrentUser, '_gaming': gamificationEnabled}">

    <!-- Preloaders -->
    <div *ngIf="!loaded || !propertiesLoaded">
      <span class="preloader _profile">
        <span class="preloader__side"></span>
        <span class="preloader__lines">
          <span class="preloader__line"></span>
          <span class="preloader__line"></span>
          <span class="preloader__line"></span>
          <span class="preloader__line"></span>
          <span class="preloader__line"></span>
        </span>
      </span>

      <div class="profile__contacts">
        <div class="profile__contacts-row _center" *ngFor="let r of [0,1]">
          <div class="profile__label">
            <span class="preloader">
              <span class="preloader__line"></span>
            </span>
          </div>
          <div class="profile__info">
            <span class="preloader _profile">
              <span class="preloader__lines"><span class="preloader__line"></span></span>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="profile__photo" *ngIf="loaded && propertiesLoaded">
      <ng-container *ngIf="innerWidth < 768; then mobile; else noMobile"></ng-container>
      <!-- _online, _offline, _away-->
      <ng-container *ngIf="allowEdit">
        <button type="button" class="btn profile__status" *ngIf="skypeService.initialized | async"
          [ngClass]="{'_online': isOnline(user), '_offline': isBusy(user), '_away': isAway(user)}"></button>
      </ng-container>
      <ng-container *ngIf="!allowEdit">
        <button mat-button [matMenuTriggerFor]="skypeMenu" type="button" class="btn btn-dots-white profile__status"
          [ngClass]="{'_online': isOnline(user), '_offline': isBusy(user), '_away': isAway(user)}"></button>
        <mat-menu #skypeMenu="matMenu" yPosition="below" [overlapTrigger]="false">
          <div class="icon-menu">
            <a mat-menu-item [href]="getSip() | safe:'url'" class="icon-menu__item">
              <span class="icon-menu__icon">
                <img src="{{assetsPrefix}}/assets/images/icons/menu/edit.svg" alt="" class="icon-menu__img">
              </span>
              <span>Написать сообщение</span>
            </a>
          </div>
        </mat-menu>
      </ng-container>
    </div>
    <ng-template #mobile>
      <div class="profile-photo" mat-button [matMenuTriggerFor]="menu" [ngClass]="{'_loading': changingPicture}">
        <ng-container *ngTemplateOutlet="avatar"></ng-container>
      </div>
      <mat-menu #menu="matMenu" yPosition="below" [overlapTrigger]="false">
        <div class="icon-menu" #menuContainer>
          <a mat-menu-item href="javascript:;" class="icon-menu__item" (click)="openUserAvatar($event)"
            *ngIf="user?.imageUrl">
            <span class="icon-menu__icon">
              <img src="{{assetsPrefix}}/assets/images/icons/menu/gallery.svg" alt="" class="icon-menu__img">
            </span>
            <span>Посмотреть</span>
          </a>
          <a mat-menu-item href="javascript:;" target="_blank" class="icon-menu__item"
            (click)="changeUserAvatar($event, uploadPhoto)" *ngIf="isCurrentOrAdmin">
            <span class="icon-menu__icon">
              <img src="{{assetsPrefix}}/assets/images/icons/menu/edit.svg" alt="" class="icon-menu__img">
            </span>
            <span>Редактировать</span>
          </a>
        </div>
      </mat-menu>
    </ng-template>

    <ng-template #noMobile>
      <div class="profile-photo" [ngClass]="{'_loading': changingPicture}">
        <ng-container *ngTemplateOutlet="avatar"></ng-container>
      </div>
    </ng-template>

    <ng-template #avatar>
      <app-user-avatar *ngIf="user" [user]="user" [linkClass]="'avatar _100'" [imageClass]="'avatar__image'"
        [asSpan]="true"></app-user-avatar>
      <div class="profile-photo__edit" [ngClass]="{'_no-photo': !user?.imageUrl}" (click)="openUserAvatar($event)">
        <mat-spinner *ngIf="changingPicture" [diameter]="56"></mat-spinner>
        <div class="profile-photo__input-wrap" (click)="changeUserAvatar($event, uploadPhoto)"
          *ngIf="isCurrentOrAdmin && !changingPicture">
          <img src="{{assetsPrefix}}/assets/images/icons/edit-photo-pencil.svg"
            alt="{{user?.imageUrl ? 'Заменить': 'Добавить'}}">
        </div>
      </div>
    </ng-template>

    <!-- основные свойства профиля -->
    <ng-container *ngIf="loaded && propertiesLoaded">
      <div class="profile__name">{{user.fullName}}</div>
      <div class="profile__middle-name" *ngIf="russianPatronymicProperty?.value">{{russianPatronymicProperty?.value}}
      </div>

      <div class="profile__absence" *ngIf="user.disabled">
        <div class="absence _fired"><span>Не работает в компании</span></div>
      </div>

      <div class="profile__absence" *ngIf="user && user?.absenceType && user?.absenceDateStart">
        <app-user-absence [user]="user"></app-user-absence>
      </div>

      <div class="profile__info _birthday" *ngIf="isShowUserBirthday()">
        <span style="margin-right: 1rem">{{user.birthday | date:'dd MMMM'}}</span>
        <button type="button" class="btn" *ngIf="usersService.isCanEditPrivacy(birthdayProperty, user, currentUser)"
          [matTooltip]="usersService.getPropertyVisiblityText(birthdayProperty)"
          (click)="usersService.togglePrivacy(birthdayProperty)" [ngClass]="{'btn-content-can-view _visible': birthdayProperty && birthdayProperty?.privacy==Privacy.Public,
          'btn-content-eye-hide _visible': birthdayProperty && birthdayProperty?.privacy==Privacy.Private}"></button>
      </div>
      <div class="profile__subscribe" *ngIf="!allowEdit && isSubscribed!==undefined">
        <button type="button" class="btn" [ngClass]="{'btn-secondary': isSubscribed, 'btn-primary': !isSubscribed}"
          (click)="subscribe()">{{isSubscribed?'Отписаться':'Подписаться'}}</button>
      </div>
      <div class="profile__subscribe" style="top:220px" *ngIf="!allowEdit">
        <app-add-thank-button [user]="user"></app-add-thank-button>
      </div>
      <div class="profile__gamification" *ngIf="gamificationEnabled && isCurrentUser">
        <div class="profile-score">
          <div class="profile-score__title">Получено баллов</div>
          <div class="profile-score__count">{{gamificationCount}}</div>
        </div>
        <a type="button" class="btn btn-white _block" [disabled]="!gamificationCount" routerLink="/profile/gifts">Потратить</a>
        <a href="#" class="link _block">Как получить?</a>
      </div>
      <span *ngIf="user.jobTitle" class="profile__info">{{user.jobTitle}}</span>
      <a *ngIf="user.department" class="profile__info" routerLink="/profile/{{user?.id}}/search"
        fragment="k={{user.department}}">{{user.department}}</a>
      <a *ngIf="user.email" class="profile__info" href="mailto:{{user.email}}">{{user.email}}</a>
      <a *ngIf="user.workPhone" class="profile__info" href="tel:{{user.workPhone}}">{{user.workPhone}}</a>

      <ng-container *ngIf="isShowUserMobile()">
        <input *ngIf="isAllowEditMobile()" name="CellPhone" type="tel" value="{{user.mobilePhone}}"
          class="profile__info" (keydown)="onKey($event)" (keyup)="onKey($event)" (blur)="onBlur($event)"
          [attr.disabled]="allowEdit?null:''" placeholder="Мобильный телефон" pattern="^\+|[0-9]{11}">
        <a class="profile__info" *ngIf="!isAllowEditMobile() && user.mobilePhone"
          href="tel:{{user.mobilePhone}}">{{user.mobilePhone}}</a>
      </ng-container>

      <ng-container *ngIf="propertiesLoaded">

        <div *ngIf="!isShowEditSocial && !isShowSocial && allowEdit" class="profile__info">
          <button type="button" class="btn btn-add"
            (click)="isShowEditSocial=true">Добавить социальную сеть</button>
        </div>

        <ng-container *ngIf="isShowEditSocial && allowEdit">
<!--          <div class="profile__info _oneline">-->
<!--            <span class="icon _social _fb"></span>-->
<!--            <input class="input-main__field" name="FB" type="url" value="{{fbLink}}"-->
<!--              (keydown)="onKey($event)" (keyup)="onKey($event)" (blur)="onBlur($event)" [attr.disabled]="allowEdit?null:''"-->
<!--              placeholder="Ссылка на профиль в Facebook">-->
<!--          </div>-->

          <div class="profile__info _oneline">
            <span class="icon _social _vk"></span>
            <input class="input-main__field" name="VK" type="url" value="{{vkLink}}"
              (keydown)="onKey($event)" (keyup)="onKey($event)" (blur)="onBlur($event)" [attr.disabled]="allowEdit?null:''"
              placeholder="Ссылка на профиль в ВКонтакте">
          </div>

<!--          <div class="profile__info _oneline">-->
<!--            <span class="icon _social _insta"></span>-->
<!--            <input class="input-main__field" name="Instagram" type="url" value="{{instagramLink}}"-->
<!--              (keydown)="onKey($event)" (keyup)="onKey($event)" (blur)="onBlur($event)" [attr.disabled]="allowEdit?null:''"-->
<!--              placeholder="Ссылка на профиль в Инстаграм">-->
<!--          </div>-->

          <div class="profile__info _oneline">
            <span class="icon _social _ok"></span>
            <input class="input-main__field" name="OK" type="url" value="{{odnoklassnikiLink}}"
              (keydown)="onKey($event)" (keyup)="onKey($event)" (blur)="onBlur($event)" [attr.disabled]="allowEdit?null:''"
              placeholder="Ссылка на профиль в Одноклассники">
          </div>

          <div class="profile__info _oneline">
            <div type="button" class="btn btn-content-up" (click)="hideEditSocialClick()">Скрыть</div>
          </div>
        </ng-container>

        <ng-container *ngIf="!isShowEditSocial && isShowSocial">
          <div class="profile__info _oneline _icons">
<!--            <a *ngIf="fbLink" target="_blank" [attr.href]="fbLink"><span class="icon _social _fb"></span></a>-->
            <a *ngIf="vkLink" target="_blank" [attr.href]="vkLink"><span class="icon _social _vk"></span></a>
<!--            <a *ngIf="instagramLink" target="_blank" [attr.href]="instagramLink"><span class="icon _social _insta"></span></a>-->
            <a *ngIf="odnoklassnikiLink" target="_blank" [attr.href]="odnoklassnikiLink"><span class="icon _social _ok"></span></a>
            <a *ngIf="allowEdit" type="button" href="javascript:void(0)" class="btn btn-edit" (click)="isShowEditSocial=true">&nbsp;</a>
          </div>
        </ng-container>

      </ng-container>

    </ng-container>

    <!-- дополнительные свойства профиля -->
    <app-profile-properties></app-profile-properties>

    <div class="clearfix"></div>
  </div>
</div>

<div class="horizontal-bar _compact">
  <!-- Остатки дней отпуска -->
  <app-vacation-days *ngIf="loaded"></app-vacation-days>
  <!-- Награды -->
  <app-user-awards *ngIf="loaded"></app-user-awards>
  <!-- Спасибо -->
  <app-user-thanks-widget></app-user-thanks-widget>
</div>

<div class="card _simple" *ngIf="showCounters | async">
  <div class="rich-tabs">
    <div class="rich-tabs__content">
      <a href="#" class="rich-tabs__item _users" routerLink="/profile/{{user?.id}}/colleagues"
        routerLinkActive="_active">
        <span class="rich-tabs__img">
          <img src="{{assetsPrefix}}/assets/images/widgets/rich-tabs/icon-users.svg">
        </span>
        <span class="rich-tabs__text">Коллеги</span>
      </a>

      <a href="#" class="rich-tabs__item _groups" routerLink="/profile/{{user?.id}}/groups" routerLinkActive="_active">
        <span class="rich-tabs__img">
          <img src="{{assetsPrefix}}/assets/images/widgets/tabs/icon-groups.svg">
        </span>
        <span class="rich-tabs__text">Группы</span>
      </a>

      <a href="#" class="rich-tabs__item _files" routerLink="/profile/{{user?.id}}/files" routerLinkActive="_active" *ngIf="user?.userType!==UserType.local">
        <span class="rich-tabs__img">
          <img src="{{assetsPrefix}}/assets/images/widgets/rich-tabs/icon-files.svg">
        </span>
        <span class="rich-tabs__text">Файлы</span>
      </a>

      <a href="#" class="rich-tabs__item _gallery" routerLink="/profile/{{user?.id}}/gallery"
        routerLinkActive="_active">
        <span class="rich-tabs__img">
          <img src="{{assetsPrefix}}/assets/images/widgets/rich-tabs/icon-gallery.svg">
        </span>
        <span class="rich-tabs__text">Галерея</span>
      </a>

      <a href="#" class="rich-tabs__item _surveys" routerLink="/profile/{{user?.id}}/surveys"
        routerLinkActive="_active">
        <span class="rich-tabs__img">
          <img src="{{assetsPrefix}}/assets/images/widgets/rich-tabs/icon-surveys.svg">
        </span>
        <span class="rich-tabs__text">Опросы</span>
      </a>

      <a href="#" class="rich-tabs__item _vacancy" routerLink="/profile/{{user?.id}}/vacancy"
        routerLinkActive="_active">
        <span class="rich-tabs__img">
          <img src="{{assetsPrefix}}/assets/images/widgets/rich-tabs/icon-vacancy.svg">
        </span>
        <span class="rich-tabs__text">Вакансии</span>
      </a>
    </div>
  </div>
</div>
<app-onboarding page="profile"></app-onboarding>
