import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilesService } from '@app/files/services/files.service';
import { FilesApiService } from '@app/files/services/files.api.service';
import { SharedModule } from '@app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { CreateFolderComponent } from '@app/files/components/create-folder/create-folder.component';
import { FileShareComponent } from '@app/files/components/file-share/file-share.component';
import { FilesRenameComponent } from '@app/files/components/files-rename/files-rename.component';
import { FilesTreeComponent } from '@app/files/components/files-tree/files-tree.component';
import { FileHistoryComponent } from '@app/files/components/file-history/file-history.component';
import { FileMenuComponent } from '@app/files/components/file-menu/file-menu.component';
import { FilesBreadcrumbsComponent } from '@app/files/components/files-breadcrumbs/files-breadcrumbs.component';
import { FilesListComponent } from '@app/files/components/files-list/files-list.component';
import { FilesMenuComponent } from '@app/files/components/files-menu/files-menu.component';
import { GroupFilesWidgetComponent } from '@app/files/components/files-widget/group-files-widget.component';
import { UserFilesWidgetComponent } from '@app/files/components/files-widget/user-files-widget.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { FilesSearchListComponent } from '@app/files/components/files-search-list/files-search-list.component';
import { FileMediaViewComponent } from './components/file-media-view/file-media-view.component';
import { FilesWidgetSmallComponent } from './components/files-widget-small/files-widget-small.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    SharedModule,
    MatMenuModule,
    MatDialogModule,
  ],
  declarations: [
    CreateFolderComponent,
    FileHistoryComponent,
    FileMenuComponent,
    FileShareComponent,
    FilesBreadcrumbsComponent,
    FilesListComponent,
    FilesMenuComponent,
    FilesRenameComponent,
    FilesTreeComponent,
    UserFilesWidgetComponent,
    GroupFilesWidgetComponent,
    FilesSearchListComponent,
    FileMediaViewComponent,
    FilesWidgetSmallComponent
  ],
  exports: [
    CreateFolderComponent,
    FileHistoryComponent,
    FileMenuComponent,
    FileShareComponent,
    FilesBreadcrumbsComponent,
    FilesListComponent,
    FilesMenuComponent,
    FilesRenameComponent,
    FilesTreeComponent,
    UserFilesWidgetComponent,
    GroupFilesWidgetComponent,
    FilesSearchListComponent,
    FilesWidgetSmallComponent
  ],
  providers: [
    FilesService,
    FilesApiService
  ],
})
export class FilesModule { }
