import { Component, OnInit, Inject, OnDestroy, EventEmitter, Output } from '@angular/core';
import { AddUsersComponent } from '@app/shared/components/add-users/add-users.component';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '@app/profile/services/users.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelperService } from '@app/core/services/helper.service';
import { GroupInfo } from '@app/groups/model/group-info.model';
import { GroupType } from '@app/groups/model/group-type.model';
import { GroupUserRole } from '@app/groups/model/group-user-role.model';
import { takeUntil } from 'rxjs/operators';
import { GroupsService } from '@app/groups/services/groups.service';
import { Subject } from 'rxjs';
import { GroupUserType } from '@app/groups/model/group-user-type.model';
import { AlertsService } from '@app/shared/services/alerts.service';

export interface IGroupAddUsersData {
  onOk: any;
  headerText: string;
  placeholder: string;
  error: string;
  group: GroupInfo;
}

/**
 * Компонент добавления пользователей в группу. Пользователи могут быть:
 * - сотрудники:
 *  - участники
 *  - подписчики
 *  - администраторы
 * - доаменная группа
 * - группа рассылки
 *
 * @export
 * @class GroupAddUsersComponent
 * @extends {AddUsersComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-group-add-users',
  templateUrl: './group-add-users.component.html',
  styleUrls: ['./group-add-users.component.scss']
})
export class GroupAddUsersComponent extends AddUsersComponent implements OnInit, OnDestroy {
  @Output() onOk: EventEmitter<any> = new EventEmitter();
  selectedChooseType = 'typed';

  selectedUser: string;
  selectedUserType: GroupUserType;

  selectedRole: string;
  selectedRoleType: GroupUserRole;

  roleTypes: string[] = [];
  // userTypes: string[] = ['Сотрудники', 'Доменная группа', 'Группа рассылки'];

  isBusiness: boolean;

  constructor(
    public groupsService: GroupsService,
    public alersService: AlertsService,
    public route: ActivatedRoute,
    public router: Router,
    public usersService: UsersService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: IGroupAddUsersData,
    public helper: HelperService
  ) {
    super(route, router, usersService, dialogRef, data, helper);
    if (this.data.group) {
      if (this.data.group.type === GroupType.Business) {
        this.roleTypes = ['Администратор'];
        this.isBusiness = true;
      } else {
        this.roleTypes = ['Участник', 'Подписчик', 'Администратор'];
      }
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.placeholder = this.data.placeholder;
  }

  setRoleType(roleType: string) {
    this.selectedRole = roleType;
    switch (roleType) {
      case 'Участник':
        this.data.headerText = `Добавить участников`;
        this.selectedRoleType = GroupUserRole.Member;
        break;
      case 'Подписчик':
        this.data.headerText = `Добавить подписчиков`;
        this.selectedRoleType = GroupUserRole.Subscriber;
        break;
      case 'Администратор':
        this.data.headerText = `Добавить администраторов`;
        this.selectedRoleType = GroupUserRole.Administrator;
        break;
    }
  }

  // setUserType(userType: string) {
  //   this.selectedUser = userType;
  //   switch (userType) {
  //     case this.userTypes[0]:
  //       this.selectedUserType = GroupUserType.User;
  //       this.placeholder = 'Введите имя, чтобы добавить пользователя';
  //       break;
  //     case this.userTypes[1]:
  //       this.selectedUserType = GroupUserType.DomainGroup;
  //       this.placeholder = 'Введите название доменной группы';
  //       break;
  //     case this.userTypes[2]:
  //       this.selectedUserType = GroupUserType.ExchangeGroup;
  //       this.placeholder = 'Введите название группы рассылки';
  //       break;
  //   }
  // }

  addUsers() {
    this.addUsersToGroup(this.usersToAdd);
  }

  addUsersToGroup(users: any) {
    if (users && users.length) {
      const errorFailEnd = users.length > 1 ? 'ов' : 'а';
      const errorUserType = this.data.group.type === GroupType.Business ? 'администратор' : 'сотрудник';
      const errorMessage = `Не удалось добавить ${errorUserType}` + errorFailEnd;

      const role = this.data.group.type === GroupType.Business ? GroupUserRole.Administrator : this.selectedRoleType;

      this.groupsService.addUsers(this.data.group.id, users, role)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(res => {
          if (res) {

            if (this.onOk instanceof EventEmitter) {
              this.onOk.emit({ users: this.usersToAdd, role });
            } else {
              (<any>this).onOk({ users: this.usersToAdd, role });
            }

            this.cancel();

            const textSuccess = users.length > 1
              ? 'Пользователи были добавлены  в группу успешно'
              : 'Пользователь был добавлен в группу успешно';

            this.alersService.riseSuccess(textSuccess);
          } else {
            this.data.error = errorMessage;
            this.alersService.riseError(errorMessage);
          }
        }, error => {
          this.data.error = errorMessage;
          this.alersService.riseError(errorMessage);
        });
    }
  }

  // getSelectedType() {
  //   if (!this.selectedUser) {
  //     this.selectedUser = this.userTypes[0];
  //     this.setUserType(this.selectedUser);
  //   }
  //   return this.selectedUser;
  // }
}
