import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, NgZone } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { GalleryService, GalleryOwner } from '@app/gallery/services/gallery.service';
import { UsersService } from '@app/profile/services/users.service';
import { Observable, Subject } from 'rxjs';
import { IAlbum } from '@app/gallery/model/album-model';
import { environment } from '@env/environment';
import { Helper } from '@app/core/helpers/helper';
import { IAlbumMenuConfig, AlbumMenuComponent } from '../../menu/album-menu/album-menu.component';
import { takeUntil, take } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { ProfileType } from '@app/core/model/profile-type.enum';
import { IAlbumVotingComponentResult } from '../../forms/album-voting/album-voting.component';
import { SignalRService } from '@app/signalr/signalR.service';

/**
 * Список альбомов
 *
 * @export
 * @class AlbumListComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-album-list',
  templateUrl: './album-list.component.html',
  styleUrls: ['./album-list.component.scss']
})
export class AlbumListComponent extends BaseComponent implements OnInit, OnDestroy {

  /**
   * Скрывать меню альбома
   *
   * @type {boolean}
   * @memberof AlbumListComponent
   */
  @Input() hideAlbumMenu: boolean;

  /**
   * Скрывать выбор альбома (checkbox слева)
   *
   * @type {boolean}
   * @memberof AlbumListComponent
   */
  @Input() hideAlbumSelector: boolean;

  /**
   * Если true, то альбом будет выбран, а не выполнится переход на него
   *
   * @type {boolean}
   * @memberof AlbumListComponent
   */
  @Input() selectAlbum: boolean;

  /**
   * Действие при выборе альбома
   *
   * @type {EventEmitter<IAlbum>}
   * @memberof AlbumListComponent
   */
  @Output() select: EventEmitter<IAlbum> = new EventEmitter();

  /**
   * Массив альбомов
   *
   * @type {IAlbum[]}
   * @memberof AlbumListComponent
   */
  @Input() albums: IAlbum[];

  preview = this.galleryService.preview;

  albumMenuConfig: IAlbumMenuConfig = { allowAdd: false };

  /**
   * Сдвиг при запросе альбомов
   *
   * @memberof AlbumListComponent
   */
  offset = 0;

  /**
   * Количество получаемых альбомов за запрос.
   * Первый раз отображаются 4 альбома. Все последующие запросы идут по 10.
   *
   * @memberof AlbumListComponent
   */
  limit = 11;

  maxLimit = 10;

  firstLimit = 4;

  fullLoaded: boolean;

  galleryOwner: GalleryOwner;

  selectedItems: IAlbum[] = [];

  private routerLinkPrefix: string;

  constructor(
    public sanitizer: DomSanitizer,
    public router: Router,
    public galleryService: GalleryService,
    public usersService: UsersService,
    public signalRService: SignalRService,
    public ngZone: NgZone,
    public helper: HelperService
  ) {
    super(helper);

    this.signalRService.galleryHub.onSetMediaFileAsCover.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.ngZone.run(() => {
        if (!res || !res.albumId || !res.mediaFileId || !res.url || !this.albums) {
          return;
        }

        const album = this.albums.find(m => m.id === res.albumId);
        if (album) {
          album.pictureUrl = res.url;
        }
      });
    });

    this.galleryService.albumDeleted.pipe(takeUntil(this.destroyed$)).subscribe(albumId => {
      if (albumId && this.albums) {
        this.albums = this.albums.filter(s => s.id !== albumId);
      }
    });
  }

  ngOnInit() {
    this.getAlbums();
  }

  /**
   * Перейти в альбом
   *
   * @param {IAlbum} album
   * @memberof AlbumListComponent
   */
  navigate(album: IAlbum): void {
    if (this.selectAlbum) {
      this.select.emit(album);
    } else {
      this.router.navigate([this.getRouterLink(album.id)]);
    }
  }

  /**
   * Получить ссылку на альбом
   *
   * @param number albumId
   * @returns
   * @memberof AlbumListComponent
   */
  getRouterLink(albumId: number) {
    if (this.routerLinkPrefix) {
      return `${this.routerLinkPrefix}/gallery/${albumId}`;
    }
    return `/gallery/${albumId}`;
  }

  /**
   * Получить стиль для превью альбома
   *
   * @returns SafeStyle
   * @memberof AlbumListComponent
   */
  getPreviewUrl(album: IAlbum): SafeStyle {
    let style = `url('${this.preview}')`;
    if (album && album.id && album.pictureUrl) {
      const prefixUrl = album.pictureUrl.indexOf('/api/documentFile/') !== -1
        ? this.helper.getSiteUrl()
        : this.helper.getSiteHostUrl();

      style = `url('${Helper.concatUrls(prefixUrl, album.pictureUrl)}')`;
    }
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  /**
   * Получить список альбомов
   *
   * @protected
   * @memberof AlbumListComponent
   */
  protected getAlbums(): void {
    this.galleryService.currentOwner.pipe(
      takeUntil(this.destroyed$))
      .subscribe(galleryOwner => {
        if (galleryOwner && galleryOwner.owner) {
          if (galleryOwner.ownerType === ProfileType.User) {

            this.routerLinkPrefix = `/profile/${galleryOwner.owner.id}`;

            this.usersService.currentUser.subscribe(currentUser => {
              // голосование возможно только в группах
              this.albumMenuConfig = { allowVoting: false };
              // только владелец альбома может им управлять
              if (currentUser.id === galleryOwner.owner.id || currentUser.isAdmin) {
                this.albumMenuConfig.allowDelete =
                  this.albumMenuConfig.allowEdit = true;
              }
            });
          } else if (galleryOwner.ownerType === ProfileType.Group) {
            this.routerLinkPrefix = `/group/${galleryOwner.owner.id}`;
            // в группе разрешено включение голосования
            this.albumMenuConfig = { allowVoting: true };
          } else {
            return;
          }
          if (!this.galleryOwner || this.galleryOwner.owner.id !== galleryOwner.owner.id) {
            this.offset = 0;
            this.limit = 5;
            this.albums = [];
          } else {
            this.limit = 11;
          }
          this.galleryOwner = galleryOwner;
          this.operateAlbums(this.galleryService.getAlbums(galleryOwner.owner.id, this.offset, this.limit));
        }
      });
  }

  protected operateAlbums(method: Observable<IAlbum[]>) {
    return method.pipe(take(1), takeUntil(this.destroyed$)).subscribe(albums => {
      if (!this.albums) {
        this.albums = [];
      }

      if (albums) {
        albums.slice(0, this.limit - 1).forEach(album => {
          if (!this.albums.find(s => s.id === album.id)) {
            this.albums.push(album);
          }
        });
        this.offset = this.albums.length;
        this.fullLoaded = albums.length < this.limit - 1;
      }

      this.loaded = true;
    }, error => {
      this.loaded = true;
      this.error = error;
    });
  }

  protected getMenuConfig(album: IAlbum) {
    return this.albumMenuConfig;
  }

  /**
   * Показать ещё альбомы
   *
   * @memberof AlbumListComponent
   */
  showMore() {
    this.limit = 11;
    this.getAlbums();
  }

  /**
   * Отображать ссылку "Свернуть список"
   *
   * @returns
   * @memberof AlbumListComponent
   */
  showHideMore() {
    return this.albums && this.albums.length > this.firstLimit;
  }

  /**
   * Свернуть список
   *
   * @memberof AlbumListComponent
   */
  hideMore() {
    this.albums = this.albums.slice(0, this.firstLimit);
    this.offset = this.albums.length;
    this.fullLoaded = false;
  }

  /**
   * Отображать блок "Показать ещё"
   *
   * @returns
   * @memberof AlbumListComponent
   */
  showMoreBlock() {
    return this.loaded && this.albums && this.albums.length && !this.fullLoaded;
  }

  /**
   * При выделении альбома
   *
   * @param {*} e
   * @param {IAlbum} album
   * @memberof AlbumListComponent
   */
  albumCheckChanged(e: any, album: IAlbum) {
    e.stopImmediatePropagation();
    album.selected = e.target.checked;
    this.selectedItems = this.albums ? this.albums.filter(s => s.selected) : [];
  }

  onVote(e: IAlbumVotingComponentResult, album: IAlbum) {
    if (e.dateEnd) {
      album.votingEndDate = e.dateEnd.toDate();
    }
  }

  showContextMenu(e: any, m: IAlbum, itemMenu: AlbumMenuComponent) {
    e.preventDefault();
    e.stopImmediatePropagation();
    itemMenu.show(event);
  }
}
