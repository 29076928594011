<div class="content">
  <!-- List of posts -->
  <ng-container *ngIf="isDesktop1200()">
    <div class="content__column">
      <!-- New post -->
      <app-post-new [profileType]="0" [user]="user" *ngIf="loaded" [placeholder]="'Что у вас нового?'"
        (onAdd)="onFeedNewPost($event)" [selectorType]="selectorType"></app-post-new>
      <!-- New post END-->
      <ng-container *ngFor="let post of posts; trackBy:trackBy; let even=even;">
        <app-post *ngIf="even" [post]="post" [user]="user" [feedType]="getFeedType()" (remove)="onRemove($event)">
        </app-post>
      </ng-container>
      <!-- preloaders -->
      <app-feed-preloader [loaded]="loaded" [loading]="loading"></app-feed-preloader>
      <!-- preloaders END -->
    </div>
    <div class="content__column">
      <ng-container *ngFor="let post of posts; trackBy:trackBy; let odd=odd;">
        <app-post *ngIf="odd" [post]="post" [user]="user" [feedType]="getFeedType()" (remove)="onRemove($event)">
        </app-post>
      </ng-container>
      <!-- preloaders -->
      <app-feed-preloader [loaded]="loaded" [loading]="loading"></app-feed-preloader>
      <!-- preloaders END -->
    </div>
  </ng-container>
  <ng-container *ngIf="!isDesktop1200()">
    <div class="content__column">
      <!-- New post -->
      <app-post-new [profileType]="0" [user]="user" *ngIf="loaded" [placeholder]="'Что у вас нового?'"
        (onAdd)="onFeedNewPost($event)"></app-post-new>
      <!-- New post END-->
      <ng-container *ngFor="let post of posts; trackBy:trackBy;">
        <app-post [post]="post" [user]="user" [feedType]="getFeedType()" (remove)="onRemove($event)">
        </app-post>
      </ng-container>
      <!-- preloaders -->
      <app-feed-preloader [loaded]="loaded" [loading]="loading"></app-feed-preloader>
      <!-- preloaders END -->
    </div>
  </ng-container>
  <!-- List of Posts END -->
  <div class="feed-spinner" *ngIf="loaded && loading">
    <mat-spinner [diameter]="56"></mat-spinner>
  </div>
</div>