import {Component, OnInit, ComponentFactoryResolver, ChangeDetectorRef} from '@angular/core';
import {AlbumViewComponent} from '@app/gallery/components/forms/album-view/album-view.component';
import {Router, ActivatedRoute} from '@angular/router';
import {UsersService} from '@app/profile/services/users.service';
import {GalleryService} from '@app/gallery/services/gallery.service';
import {AlertsService} from '@app/shared/services/alerts.service';
import {GroupMediaListComponent} from '../group-media-list/group-media-list.component';
import {HelperService} from '@app/core/services/helper.service';
import {BreadcrumbsService} from '@app/shared/services/breadcrumbs.service';
import {IAlbum} from '@app/gallery/model/album-model';
import {User} from '@app/profile/model/user.model';
import {GroupsService} from '@app/groups/services/groups.service';
import {takeUntil} from 'rxjs/operators';
import {GroupInfo} from '@app/groups/model/group-info.model';

@Component({
  selector: 'app-group-album-view',
  templateUrl: './group-album-view.component.html',
  styleUrls: ['./group-album-view.component.scss'],
})
export class GroupAlbumViewComponent extends AlbumViewComponent implements OnInit {
  group: GroupInfo;
  mediaListType = GroupMediaListComponent;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected groupsService: GroupsService,
    protected usersService: UsersService,
    protected galleryService: GalleryService,
    protected alertsService: AlertsService,
    protected breadcrumbsService: BreadcrumbsService,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected cdr: ChangeDetectorRef,
    public helper: HelperService,
  ) {
    super(
      router,
      route,
      usersService,
      galleryService,
      alertsService,
      breadcrumbsService,
      componentFactoryResolver,
      cdr,
      helper,
    );
  }

  ngOnInit() {
    this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
      this.group = group;
      if (group) {
        super.ngOnInit();
      }
    });
  }

  protected setBreadcrumbs(album: IAlbum) {
    this.breadcrumbsService.breadcrumbs.next([
      {title: 'Галерея', routerUrl: `/group/${album.ownerId}/gallery`},
      {title: this.album.title, routerUrl: `/group/${album.ownerId}/gallery/${album.id}`},
    ]);
  }

  protected allowEditCheck(currentUser: User, album: IAlbum) {
    // администратор может удалять альбом, добавлять в него медиа-файлы
    if (this.groupsService.isAdmin(this.group, currentUser)) {
      this.albumMenuConfig.allowDelete = this.albumMenuConfig.allowVoting = this.albumMenuConfig.allowAdd = true;
    }

    // автор альбома может делать с ним всё
    if (currentUser.id === album.author.id || currentUser.isAdmin) {
      this.allowEdit = true;
      this.albumMenuConfig.allowDelete = this.albumMenuConfig.allowAdd = true;
    } else if (this.groupsService.isParticipant(this.group, currentUser) || (this.group && this.group.id === 2864)) {
      // участник группы может только добавлять медиа-файлы
      this.albumMenuConfig.allowAdd = true;
    } else {
      // все остальные не могут ничего
      this.albumMenuConfig = {};
    }
  }
}
