import { Injectable } from '@angular/core';
import { PagesApiService } from './pages-api.service';
import { HelperService } from '@app/core/services/helper.service';
import { HttpClient } from '@angular/common/http';
import { IPage, IPageSearch } from '../model/page';
import { Subject } from 'rxjs';

/**
 * Сервис контентных страниц
 *
 * @export
 * @class PageService
 * @extends {PageApiService}
 */
@Injectable({
  providedIn: 'root'
})
export class PagesService extends PagesApiService {

  pageInWidgetUpdated$: Subject<void> = new Subject();
  pageInMenuUpdated$: Subject<void> = new Subject();

  constructor(
    protected http: HttpClient,
    public helper: HelperService) { super(http, helper); }

  getPageUrl(page: IPage) {
    if (page) {
      if (page.isLink) {
        return page.url;
      } else if (page.ownerId) {
        return `/group/${page.ownerId}/pages/${page.id}`;
      } else {
        return `/pages/${page.id}`;
      }
    }
    return '';
  }

  getPageSearchUrl(page: IPageSearch) {
    if (page) {
      if (page.isLink) {
        return page.url;
      } else if (page.group && page.group.id) {
        return `/group/${page.group.id}/pages/${page.id}`;
      } else {
        return `/pages/${page.id}`;
      }
    }
    return '';
  }

  navigatePage(e: Event, page: IPage) {
    e.preventDefault();
    e.stopImmediatePropagation();

    // скрыть меню
    this.helper.hideMenu$.next();

    if (page) {
      if (page.ownerId) {
        return `/group/${page.ownerId}/pages/${page.id}`;
      } else {
        return `/pages/${page.id}`;
      }
    }
  }

  findPageRecursive(id: number, pages: IPage[]) {
    let result: IPage = null;
    if (pages) {
      for (let index = 0; index < pages.length; index++) {
        const page = pages[index];
        if (page.id === id) {
          result = page;
          break;
        } else if (page.children) {
          result = this.findPageRecursive(id, page.children);
        }
      }
    }
    return result;
  }

  plainToTree(pages: IPage[], withChildren: number[]): IPage[] {
    if (!withChildren) {
      withChildren = [];
    }
    if (pages && pages.length) {
      const baseLevelPages = pages.filter(s => !s.parentId);
      if (baseLevelPages) {
        baseLevelPages.forEach(p => {
          this.setChildrenRecursive(p, pages, withChildren);
        });
        this.sortPages(baseLevelPages);
        return baseLevelPages;
      }
    }
  }

  private setChildrenRecursive(page: IPage, allPages: IPage[], withChildren: number[]) {
    if (page) {
      if (!page.children) {
        page.children = [];
      }
      allPages.forEach(p => {
        if (p.parentId === page.id) {
          if (!page.children.find(s => s.id === p.id)) {
            page.children.push(p);
          }
        }
      });
      if (page.children) {
        if (!withChildren.find(s => s === page.id)) {
          withChildren.push(page.id);
        }
        page.children.forEach(p => {
          this.setChildrenRecursive(p, allPages, withChildren);
        });
        this.sortPages(page.children);
      }
    }
  }

  private sortPages(pages: IPage[]) {
    pages.sort((a, b) => {
      if (a.order < b.order) {
        return -1;
      } else if (a.order < b.order) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}
