import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { IAlbum } from '@app/gallery/model/album-model';
import { GalleryService } from '@app/gallery/services/gallery.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { AlertsService } from '@app/shared/services/alerts.service';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import { Router } from '@angular/router';
import { ProfileType } from '@app/core/model/profile-type.enum';
import { Helper } from '@app/core/helpers/helper';
import { User } from '@app/profile/model/user.model';
import { UsersService } from '@app/profile/services/users.service';
import { HelperService } from '@app/core/services/helper.service';
import { AlbumVotingComponent, IAlbumVotingComponentResult, AlbumVotingChangeType } from '../../forms/album-voting/album-voting.component';

/**
 * Меню элемента альбома
 *
 * @export
 * @class AlbumMenuComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-album-menu',
  templateUrl: './album-menu.component.html',
  styleUrls: ['./album-menu.component.scss']
})
export class AlbumMenuComponent extends BaseComponent implements OnInit {

  @Input() item: IAlbum;
  @Input() config: IAlbumMenuConfig = {};

  @Input() whiteDots: boolean;

  @Output() deleted: EventEmitter<any> = new EventEmitter();
  @Output() vote: EventEmitter<IAlbumVotingComponentResult> = new EventEmitter();
  @Output() filesUploading: EventEmitter<any> = new EventEmitter();

  @ViewChild('itemLinkHref', { static: true }) itemLinkHref: ElementRef;
  @ViewChild(MatMenuTrigger, { static: true }) trigger: MatMenuTrigger;

  @Input() selectedItems: Array<any>;
  votingText: string;

  currentUser: User;

  private deletingDialog: MatDialogRef<ModalConfirmationComponent>;
  private votingDialog: MatDialogRef<AlbumVotingComponent>;

  constructor(
    public galleryService: GalleryService,
    protected usersService: UsersService,
    protected dialog: MatDialog,
    protected alertsService: AlertsService,
    protected router: Router,
    public helper: HelperService
  ) { super(helper); }

  ngOnInit(): void {
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;
    });
  }

  /**
     * Всплывающее окно подтверждения удаления альбома
     *
     * @private
     * @memberof MediaItemMenuComponent
     */
  showDeletePopup() {
    this.selectedItems = this.selectedItems && this.selectedItems.length ? this.selectedItems : [this.item];
    let text;
    if (this.selectedItems && this.selectedItems.length > 1) {
      text = 'альбомы?';
    } else {
      text = 'альбом?';
    }
    this.deletingDialog = this.dialog.open(ModalConfirmationComponent, {
      data: {
        text: `Вы действительно хотите удалить ${text}`,
        onOk: this.deleteItem.bind(this),
        doNotHideOnOk: true
      }
    });
  }

  /**
   * Всплывающее окно настройки голосования
   *
   * @memberof AlbumMenuComponent
   */
  showVotingPopup() {
    this.votingDialog = this.dialog.open(AlbumVotingComponent, {
      minWidth: '50vw',
      data: {
        onOk: this.setVoting.bind(this),
        album: this.item
      }
    });
  }

  isVotingEnabled(): boolean {
    if (this.item && this.item.votingEndDate) {
      return true;
    }
  }

  getVotingtext(): string {
    if (this.isVotingEnabled()) {
      return 'Настроить голосование';
    } else {
      return 'Включить голосование';
    }
  }

  /**
   * Удаление медиа-файла
   *
   * @private
   * @memberof MediaItemMenuComponent
   */
  private deleteItem() {
    this.selectedItems.forEach(item => {
      this.galleryService.deleteAlbum(item.id).subscribe(res => {
        console.log('deleted');
        if (this.deleted) {
          this.deleted.emit(item.id);
        }
        // отправить событие в сервис
        this.galleryService.albumDeleted.next(item.id);
        this.deletingDialog.close();
        this.alertsService.riseSuccess(`Альбом ${item.title} был успешно удалён`);
      }, error => {
        const errorText = 'Произошла ошибка при удалении альбома.';
        console.log(`${errorText}: '${error}'`);
        this.alertsService.riseError(errorText);
      });
    });
  }

  private setVoting(data: IAlbumVotingComponentResult) {

    let changeText = '';
    let changeErrorText = '';

    switch (data.type) {
      case AlbumVotingChangeType.enable:
        changeText = 'включено';
        changeErrorText = 'включении';
        break;
      case AlbumVotingChangeType.change:
        changeText = 'изменено';
        changeErrorText = 'изменении';
        break;
      case AlbumVotingChangeType.disable:
        changeText = 'выключено';
        changeErrorText = 'выключении';
        break;
    }

    this.galleryService.albumVoting(this.item.id, data.dateEnd.toDate(), data.type !== AlbumVotingChangeType.disable).subscribe(res => {
      console.log('vote enabled');
      if (this.vote) {
        this.vote.emit(data);
      }
      this.votingDialog.close();

      this.alertsService.riseSuccess(`Для альбома '${this.item.title}' было успешно ${changeText} голосование.`);
    }, error => {
      const errorText = `Произошла ошибка при ${changeErrorText} голосования.`;
      console.log(`${errorText}: '${error}'`);
      this.alertsService.riseError(errorText);
    });
  }

  /**
   * Отображать пункт редактирования альбома
   *
   * @memberof AlbumMenuComponent
   */
  showEditItem() {
    return true;
  }

  /**
   * Редактировать альбом
   *
   * @memberof AlbumMenuComponent
   */
  editItem() {
    this.router.navigate([this.getNavigateUrl()]);
  }

  /**
   *Получение ссылки на альбом
   *
   * @private
   * @returns {string}
   * @memberof AlbumMenuComponent
   */
  getNavigateUrl(): string {
    if (!this.item) {
      return '';
    }
    if (this.item.owner_Type === ProfileType.Group) {
      return `/group/${this.item.group.id}/gallery/${this.item.id}`;
    } else {
      const authorId = this.item.author ? this.item.author.id : null;
      return `/profile/${authorId}/gallery/${this.item.id}`;
    }
  }

  getItemLinkUrl(): string {
    if (!this.item) {
      return '';
    }

    return '/' + Helper.concatUrls(this.helper.environment.startPage, this.getNavigateUrl());
  }

  /**
   * Копирование ссылки на альбом в буфер обмена
   *
   * @memberof AlbumMenuComponent
   */
  copyToClipboard() {
    this.galleryService.copyToClipboard(this.itemLinkHref.nativeElement.href, 'альбом');
  }

  /**
   * Действие при загрузке файлов
   *
   * @param {*} fileInput
   * @memberof AlbumMenuComponent
   */
  onUpload(fileInput) {
    const files = fileInput.target.files;
    this.filesUploading.emit(files);
  }

  show(e) {
    if (this.config && this.config.allowDelete) {
      this.trigger.openMenu();
      e.preventDefault();
      e.stopImmediatePropagation();
      e.cancelBubble = true;
      return false;
    }
  }
}

export interface IAlbumMenuConfig {
  allowAdd?: boolean;
  allowEdit?: boolean;
  allowDelete?: boolean;
  allowVoting?: boolean;
}
