<div class="pull-right btn btn-dots tooltip" [ngClass]="{'btn-dots-white': whiteDots}" mat-button [matMenuTriggerFor]="menu"></div>
<mat-menu #menu="matMenu">
  <div class="icon-menu">
    <a mat-menu-item href="javascript:;" class="icon-menu__item" (click)="editClick()" *ngIf="allowEdit">
      <span class="icon-menu__icon">
        <img src="{{assetsPrefix}}/assets/images/icons/menu/edit.svg" alt="" class="icon-menu__img">
      </span>
      <span>Редактировать</span>
    </a>
    <a mat-menu-item href="javascript:;" class="icon-menu__item" (click)="shareClick()" *ngIf="allowShare">
      <span class="icon-menu__icon">
        <img src="{{assetsPrefix}}/assets/images/icons/menu/repost.svg" alt="" class="icon-menu__img">
      </span>
      <span>Поделиться</span>
    </a>
    <a mat-menu-item href="javascript:;" class="icon-menu__item" (click)="showRemoveConfirmation()" *ngIf="allowDelete">
      <span class="icon-menu__icon">
        <img src="{{assetsPrefix}}/assets/images/icons/menu/remove.svg" alt="" class="icon-menu__img">
      </span>
      <span>Удалить</span>
    </a>
    <a mat-menu-item href="javascript:;" class="icon-menu__item" (click)="cancelPinClick()" *ngIf="allowEdit && post?.event?.pin">
      <span class="icon-menu__icon">
        <img src="{{assetsPrefix}}/assets/images/icons/menu/pin.svg" alt="" class="icon-menu__img">
      </span>
      <span>Отменить закрепление</span>
    </a>
    <a mat-menu-item href="javascript:;" class="icon-menu__item" (click)="copyToClipboard()" *ngIf="post && post.event">
      <span class="icon-menu__icon">
        <img src="{{assetsPrefix}}/assets/images/icons/menu/link.svg" alt="" class="icon-menu__img">
      </span>
      <span>Копировать ссылку</span>
    </a>
  </div>
</mat-menu>

<a #postLinkHref [href]="postLink" style="display:none"></a>
