<div class="cos__body _paddings" *ngIf="idea && loaded">
  <div class="cos__actions pull-right">
    <app-idea-menu [item]="idea" [config]="getIdeaConfig(idea)" (deleted)="onDeleted($event)"></app-idea-menu>
  </div>
  <div class="news-title _bright">{{idea.title}}</div>
  <span class="header-text _low _secondary _inline">
    <span class="header-text__text">Создана {{idea.created | dateSpLike }} <span
        *ngIf="isIdeaAdmin(idea)">,&nbsp;</span>
    </span>
  </span>
  <!-- _pending, _ok, _rejected -->
  <div class="dropdown _inline" *ngIf="isIdeaAdmin(idea)">
    <div mat-button [matMenuTriggerFor]="statusMenu">{{selectedStatus?.title}}</div>
    <mat-menu #statusMenu="matMenu">
      <div class="icon-menu" #menuContainer>
        <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" (click)="setStatus(status)"
          *ngFor="let status of statuses">
          <span>{{status?.title}}</span>
        </a>
      </div>
    </mat-menu>
  </div>
  <span class="text-secondary" *ngIf="statusDateEnd">до {{idea.statusDateEnd | date:'dd.MM.yyyy'}}</span>
  <button type="button" class="btn _abstract" *ngIf="isIdeaAdmin(idea)" (click)="showStatusHistory()"
    matTooltip="Информация об изменении статусов" matTooltipPosition="above"><span
      class="icon _i-bright"></span></button>
  <div class="news-section _lower">
    <div class="news-announce">Автор идеи</div>
    <div class="news-body">
      <div class="co _short">
        <div class="co__avatar">
          <app-user-avatar [user]="idea.author" [linkClass]="'avatar _80'" [imageClass]="'avatar__image'">
          </app-user-avatar>
        </div>
        <div class="co__info">
          <a href="#" class="link co__name" routerLink="/profile/{{idea.author?.id}}"
            (click)="usersService.navigateProfile(idea.author?.id)"
            [appUserTooltip]="idea.author?.id">{{idea.author?.fullName}}</a>
          <div class="co__occ" *ngIf="idea.author?.jobTitle">{{idea.author?.jobTitle}}</div>
          <div class="co__occ" *ngIf="idea.author?.department">{{idea.author?.department}}</div>
        </div>
      </div>
    </div>
  </div>
  <!-- Idea description -->
  <div class="news-section">
    <div class="news-announce">Описание идеи</div>
    <div class="news-body">{{idea.description}}</div>
  </div>
  <!-- Attachments list-->
  <div class="news-section">
    <app-attachments-list [attachments]="attachments" [elementType]="elementType"></app-attachments-list>
  </div>
  <!-- Likes and comments counters -->
  <div class="post__actions">
    <app-like [count]="idea.likesCount" [canLike]="idea.canLike" [elementId]="idea.id" [elementType]="elementType">
    </app-like>
    <button class="btn btn-post _comment" [ngClass]="{'transparent-text': idea.commentsCount === 0}" type="button">{{idea.commentsCount}}</button>
    <a href="javascript:;" class="link" (click)="reply()">Ответить</a>
  </div>
  <!-- Comments block -->
  <app-comments-list *ngIf="commentsLoaded" #commentsList [count]="idea.commentsCount" [comments]="comments"
    [elementId]="idea.id" [elementType]="elementType" (commentReply)="commentNew.activate($event)"
    (commentAdded)="onCommentAdded($event)" (commentDeleted)="onCommentDeleted($event)" [highlightText]="highlightText">
  </app-comments-list>
  <!-- New Comment block -->
  <app-comment-new *ngIf="commentsLoaded" #commentNew [elementId]="idea.id" [elementType]="elementType"
    (commentAdded)="onNewCommentAdded($event)"></app-comment-new>
</div>
