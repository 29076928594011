import {Component, OnInit, OnDestroy} from '@angular/core';
import {GalleryViewComponent} from '@app/gallery/components/forms/gallery-view/gallery-view.component';
import {Router, ActivatedRoute} from '@angular/router';
import {UsersService} from '@app/profile/services/users.service';
import {GroupsService} from '@app/groups/services/groups.service';
import {GroupInfo} from '@app/groups/model/group-info.model';
import {HelperService} from '@app/core/services/helper.service';
import {take, takeUntil} from 'rxjs/operators';
import {GroupType} from '@app/groups/model/group-type.model';
import { User } from '@app/profile/model/user.model';

/**
 * Альбом в группе
 *
 * @export
 * @class GroupGalleryViewComponent
 * @extends {GalleryViewComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-group-gallery-view',
  templateUrl: './group-gallery-view.component.html',
  styleUrls: ['./group-gallery-view.component.scss'],
})
export class GroupGalleryViewComponent extends GalleryViewComponent implements OnInit {
  /**
   * Текущая группа
   *
   * @type {GroupInfo}
   * @memberof GroupGalleryViewComponent
   */
  group: GroupInfo;
  currentUser: User;

  constructor(
    public groupsService: GroupsService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected usersService: UsersService,
    public helper: HelperService,
  ) {
    super(router, route, usersService, helper);
  }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
        this.group = group;

        if (group) {
          // show "+" when has rights
          this.showAddButton = this.groupsService.isParticipant(this.group, currentUser);

          const link = `/group/${group.id}/gallery`;

          this.tabs = {
            title: 'Галерея',
            routerLink: link,
            hideCounters: true,
            items: [
              {
                id: 1,
                title: 'Альбомы группы',
                fragment: '',
                items: [],
                offset: 0,
                itemsCount: 0,
                method: this.showTab.bind(this),
                default: true,
                emptyText: 'В группе нет альбомов',
              },
            ],
          };

          this.newLink = link + '/new';

          this.route.fragment.pipe(takeUntil(this.destroyed$)).subscribe(val => {
            this.mobHeight = window.innerHeight;
            this.setNewLimit();

            // get hash
            if (val != null) {
              const keys = val.split('&');
              const hash = {};
              keys.forEach(key => {
                // tslint:disable-next-line:no-shadowed-variable
                const val = key.split('=');
                hash[val[0]] = val[1];
              });

              this.currentTab = null;

              this.tabs.items.forEach(tab => {
                if (tab.fragment && keys.find(k => k === tab.fragment)) {
                  this.currentTab = tab;
                }
              });

              if (!this.currentTab) {
                this.currentTab = this.tabs.items[0];
              }
            } else {
              this.currentTab = this.tabs.items[0];
            }

            this.currentTab.method(this.currentTab, this);
          });
        }
      });
    });
  }

  isVisibleMainBlock(): boolean {
    return this.groupsService.isAllowToViewGroupContent(this.group, this.currentUser);
  }

  isVisibleLists(): boolean {
    return (
      this.loaded &&
      this.currentTab &&
      !this.currentTab.loading &&
      !!this.currentTab.items &&
      !!this.currentTab.items.length
    );
  }
}
