import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { HelperService } from '@app/core/services/helper.service';
import { User } from '@app/profile/model/user.model';
import { UsersService } from '@app/profile/services/users.service';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import { ScrollableListComponent } from '@app/shared/components/scrollable-list/scrollable-list.component';
import { AlertsService } from '@app/shared/services/alerts.service';
import { SettingsService } from '@app/shared/services/settings.service';
import { IUserEmailSent, IUserRelation } from '@app/user-relations/model/user-relation';
import { UserRelationsApiService } from '@app/user-relations/services/user-relations-api.service';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-user-relations',
  templateUrl: './user-relations.component.html',
  styleUrls: ['./user-relations.component.scss']
})
export class UserRelationsComponent extends ScrollableListComponent implements OnInit {

  currentUser: User;
  isCurrentUserHasRelation: boolean = null;
  userRelationsFinished: boolean = null;

  userRelationsPairs: IUserRelation[] = [];
  userRelationSingle: IUserRelation;

  userEmailSent: IUserEmailSent[] = [];
  userEmailSentFull: boolean;

  loaded = false;
  usersNotificated = false;
  isUserCheckedAgreement = true;

  protected limit = 50;
  protected allLoaded = false;

  private clearDialog: MatDialogRef<ModalConfirmationComponent>;
  private startDialog: MatDialogRef<ModalConfirmationComponent>;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    public usersService: UsersService,
    public userRelationsService: UserRelationsApiService,
    protected alertsService: AlertsService,
    public settingsService: SettingsService,
    private dialog: MatDialog,
    public helper: HelperService
  ) { super(helper); }

  ngOnInit() {
    this.usersService.currentUser.subscribe(user => {
      this.currentUser = user;

      if (this.currentUser) {
        this.settingsService.getValue('userRelationsFinished')
          .pipe(takeUntil(this.destroyed$))
          .subscribe(res => {

            // проверяем закончена ли регистрация
            this.userRelationsFinished = !!(res && res === 'True');

            // проверяем есть ли связи между пользователями
            this.userRelationsService.isUserHasRelation()
              .pipe(takeUntil(this.destroyed$))
              .subscribe(isCurrentUserHasRelation => {
                this.isCurrentUserHasRelation = isCurrentUserHasRelation;
              });

            // если пользователь администратор, то получаем связи
            if (this.currentUser.isAdmin) {
              this.getUserRelations();
            }
          });
      } else {
        this.router.navigate(['403'], { skipLocationChange: true });
      }
    });
  }

  isShowNotFinished() {
    return this.currentUser != null && !this.userRelationsFinished && this.userRelationsFinished != null;
  }

  isShowFinished() {
    return this.currentUser != null && this.userRelationsFinished && this.userRelationsFinished != null;
  }

  isUserRegistered() {
    return this.currentUser != null && this.isCurrentUserHasRelation && this.isCurrentUserHasRelation != null;
  }

  participate() {
    this.userRelationsService.isUserHasRelation()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(isCurrentUserHasRelation => {
        this.isCurrentUserHasRelation = isCurrentUserHasRelation;
        if (this.isCurrentUserHasRelation) {
          this.alertsService.riseSuccess('Вы успешно зарегистрировались');
        } else {
          this.userRelationsService.addUserRelation()
            .pipe(takeUntil(this.destroyed$))
            .subscribe(res => {
              if (res) {
                this.isCurrentUserHasRelation = true;
                this.alertsService.riseSuccess('Вы успешно зарегистрировались');
              } else {
                this.alertsService.riseError('Произошла ошибка при регистрации');
              }
            });
        }
      });
  }

  onRemoveAll() {
    this.userRelationsService.removeUserRelation()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        if (res) {
          this.alertsService.riseSuccess('Польователь успешно исключен из тайного санты');
        } else {
          this.alertsService.riseError('Произошла ошибка во время удаления');
        }
      });
    this.userRelationsService.removePairs()
      .subscribe(res => {
        if (res) {
          this.alertsService.riseSuccess('Пары успешно расформированы');
          this.userRelationSingle = null;
          this.userRelationsPairs = [];
          this.userRelationsFinished = false;
          this.isCurrentUserHasRelation = false;
        } else {
          this.alertsService.riseError('Произошла ошибка во время удаления пар');
        }
      });
  }

  onStart() {
    this.userRelationsService.startUserRelations()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        if (res) {
          this.alertsService.riseSuccess('Пары успешно сформированы');
          this.userRelationsFinished = true;
          this.offset = 0;
          this.userRelationSingle = null;
          this.userRelationsPairs = [];
          this.getUserRelations();
        } else {
          this.alertsService.riseError('Произошла ошибка во время формировки пар');
        }
      });
  }

  start() {
    this.startDialog = this.dialog.open(ModalConfirmationComponent, {
      data: {
        text: `Вы действительно хотите сформировать пары?`,
        okText: 'Да',
        onOk: () => { this.onStart(); }
      }
    });
  }

  removeAll() {
    this.clearDialog = this.dialog.open(ModalConfirmationComponent, {
      data: {
        text: `Вы действительно хотите повторить Санту?`,
        okText: 'Да',
        onOk: () => { this.onRemoveAll(); }
      }
    });
  }

  sendNotifications() {
    this.clearDialog = this.dialog.open(ModalConfirmationComponent, {
      data: {
        text: `Вы действительно хотите отправить уведомления на email?`,
        additionalText: 'Уведомления будут отправлены повторно, если вы их отправляли ранее.',
        okText: 'Да',
        onOk: () => { this.onSendNotifications(); }
      }
    });
  }

  onSendNotifications() {
    this.alertsService.riseSuccess('Пользователи уведомляются... Не закрывайте страницу');
    this.notificateUsers();
  }

  private operateUserRelations(userRelations: IUserRelation[]) {
    if (userRelations) {
      userRelations.forEach(item => {
        if (this.userRelationsFinished === true && (item.user2 == null || !item.user2.id)) {
          this.userRelationSingle = item;
        } else if (!this.userRelationsPairs.find(userRelation => userRelation.id === item.id)) {
          this.userRelationsPairs.push(item);
        }
      });

      if (this.userRelationsPairs.length) {
        this.offset = this.userRelationsPairs.length;
      }

      if (userRelations.length < this.limit) {
        this.allLoaded = true;
      }

    } else {
      this.allLoaded = true;
    }
  }

  private notificateUsers() {
    this.usersNotificated = null;
    this.userEmailSent = [];
    this.userRelationsService.notificateUsers()
      .pipe(finalize(() => this.usersNotificated = true))
      .subscribe(res => {
        if (res) {
          this.userEmailSent = res;
          if (res.length) {
            if (res.length === res.filter(s => s.sent).length) {
              this.alertsService.riseSuccess('Все пользователи уведомлены');
              this.userEmailSentFull = true;
            } else {
              this.alertsService.riseError('Уведомлены не все пользователи');
              this.userEmailSentFull = false;
            }
          } else {
            this.alertsService.riseError('Уведомлены не все пользователи');
            this.userEmailSentFull = false;
          }
        } else {
          this.alertsService.riseError('Произошла ошибка при уведомлении пользователей');
        }
      });
  }

  protected onScroll() {
    const number = window.innerHeight + window.pageYOffset + 20;
    if (this.userRelationsPairs && number > this.maxItemHeight * this.userRelationsPairs.length) {
      if (!this.loading) {
        if (!this.allLoaded) {
          this.getUserRelations();
        }
      }
    }
  }

  private getUserRelations() {
    this.loaded = false;
    this.userRelationsService.getUserRelations(this.isUnique(), this.offset, this.limit)
      .pipe(finalize(() => this.loaded = true), takeUntil(this.destroyed$))
      .subscribe(userRelations => {
        this.operateUserRelations(userRelations);
      });
  }

  private isUnique(): boolean {
    return this.userRelationsFinished === true;
  }

  unSuccessfullySent(): IUserEmailSent[] {
    if (!this.userEmailSent) {
      return [];
    }
    return this.userEmailSent.filter(s => !s.sent);
  }

  unSuccessfullySentUsers(): string {
    if (!this.userEmailSent) {
      return '';
    }
    let result = '';
    this.unSuccessfullySent().forEach(user => {
      if (result) {
        result += ',<br/>';
      }
      result += `${user.name} (${user.email ? user.email : 'нет email'})`;
    });
    return result;
  }
}
