import { SearchResultItem } from '@app/search/model/search-result-item.model';

/**
 * Represents the search result
 */
export class SearchResult {
    /**
     * Contains search results.
     */
    items: SearchResultItem[];
    /**
     * Represents the number of matches found.
     */
    total: number;
}
