import { Component, OnInit, HostListener, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UsersService } from '@app/profile/services/users.service';
import { User, IUserProfileProperty, Privacy, UserType } from '@app/profile/model/user.model';
import { SubscribeService } from '@app/subscribe/services/subscribe.service';
import { BaseResizableComponent } from '@app/core/components/base-resizable.component';
import { GroupsService } from '@app/groups/services/groups.service';
import { GroupInfo } from '@app/groups/model/group-info.model';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AlertsService } from '@app/shared/services/alerts.service';
import { takeUntil, take, finalize } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { SkypeService } from '@app/skype/services/skype.service';
import { Helper } from '@app/core/helpers/helper';
import { GlobalValidator } from '@app/shared/global-validator';
import { UserAvatarViewComponent } from '@app/shared/components/user-avatar-view/user-avatar-view.component';
import { SettingsService } from '@app/shared/services/settings.service';
import { GamificationApiService } from '@app/gamification/services/gamification-api.service';

/**
 * Карточка пользователя
 *
 * @export
 * @class UserCardComponent
 * @extends {BaseResizableComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
    selector: 'app-user-card',
    templateUrl: 'user-card.component.html'
})

export class UserCardComponent extends BaseResizableComponent implements OnInit, OnDestroy {

    /**
     * Пользователь карточки
     *
     * @type {User}
     * @memberof UserCardComponent
     */
    user: User;
    currentUser: User;

    showPicture = true;
    showBack = false;
    allowEdit = false;
    isCurrentUser = false;
    gamificationEnabled = false;
    gamificationCount = null;

    changingPicture = false;

    backUrl: string;
    isSubscribed: boolean = undefined;

    countersLoaded = false;
    showCounters: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    colleaguesCount: number;
    lastColleague: User;

    groupsCount: number;
    lastGroup: GroupInfo;
    groupRequestsCount: number;

    birthdayProperty: IUserProfileProperty;
    cellPhoneProperty: IUserProfileProperty;
    russianPatronymicProperty: IUserProfileProperty;

    fbLinkProperty: IUserProfileProperty;
    vkLinkProperty: IUserProfileProperty;
    instagramLinkProperty: IUserProfileProperty;
    odnoklassnikiLinkProperty: IUserProfileProperty;

    fbLink: string;
    vkLink: string;
    instagramLink: string;
    odnoklassnikiLink: string;

    isShowSocial: boolean;
    isShowEditSocial: boolean;

    propertiesLoaded: boolean;

    Privacy = Privacy;
    UserType = UserType;

    isCurrentOrAdmin: boolean;

    private updatingProperty: boolean;

    constructor(
        private usersService: UsersService,
        private groupsService: GroupsService,
        private subscribeService: SubscribeService,
        private settingsService: SettingsService,
        private gamificationService: GamificationApiService,
        private alertsService: AlertsService,
        public dialog: MatDialog,
        protected cdr: ChangeDetectorRef,
        public skypeService: SkypeService,
        public helper: HelperService) { super(helper); }

    ngOnInit(): void {
        this.clearProperties();

        this.usersService.user.pipe(takeUntil(this.destroyed$)).subscribe(user => {
            this.clearProperties();
            this.user = user;
            this.isShowSocial = false;
            this.clearProperties();
            this.getUserCard();
        });

        this.usersService.userBirthdayProperty$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(res => {
                this.birthdayProperty = res;
            });

        this.usersService.cellPhoneProperty$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(res => {
                this.cellPhoneProperty = res;
            });

        this.usersService.russianPatronymicName$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(res => {
                this.russianPatronymicProperty = res;
            });

        this.usersService.fbLinkProperty$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(res => {
                this.fbLinkProperty = res;
                this.fbLink = res && res.value ? res.value : '';
                this.checkIsShowSocial(this.fbLink);
            });

        this.usersService.vkLinkProperty$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(res => {
                this.vkLinkProperty = res;
                this.vkLink = res && res.value ? res.value : '';
                this.checkIsShowSocial(this.vkLink);
            });

        this.usersService.instagramLinkProperty$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(res => {
                this.instagramLinkProperty = res;
                this.instagramLink = res && res.value ? res.value : '';
                this.checkIsShowSocial(this.instagramLink);
            });

        this.usersService.odnoklassnikiLinkProperty$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(res => {
                this.odnoklassnikiLinkProperty = res;
                this.odnoklassnikiLink = res && res.value ? res.value : '';
                this.checkIsShowSocial(this.odnoklassnikiLink);
            });

        this.usersService.userPropertiesLoaded$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(res => {
                this.propertiesLoaded = true;
            });

        this.usersService.anyPropertyChanged$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(res => {
                // gamification
                this.getGamifications();
            });
    }

    private checkIsShowSocial(value: string) {
        if (value) {
            this.isShowSocial = true;
        }
    }

    hideEditSocialClick() {
        this.isShowEditSocial = false;
        if (this.fbLink || this.vkLink || this.instagramLink || this.odnoklassnikiLink) {
            this.isShowSocial = true;
        } else {
            this.isShowSocial = false;
        }
    }

    private getUserCard(): void {
        this.loaded = false;
        this.usersService.currentUser.subscribe(currentUser => {
            this.currentUser = currentUser;
            // check user
            if (this.user != null) {
                this.showPicture = true;
                this.allowEdit = this.isCurrentUser = this.user.id === currentUser.id;
                this.isCurrentOrAdmin = this.allowEdit || currentUser.isAdmin;
                this.loaded = true;
                if (!this.allowEdit) {
                    this.subscribeService.isSubscribedOn(currentUser.id, this.user.id)
                        .pipe(take(1), takeUntil(this.destroyed$)).subscribe(res => {
                            this.isSubscribed = res;
                        });
                }
                this.skypeService.subscribeToUser(this.user);

                // get user counters
                this.getCounters();

                // gamification
                this.getGamifications();
            } else {
                this.loaded = false;
            }
        });
    }

    private getGamifications(): void {
        this.settingsService.getValue('Включить геймификацию')
            .pipe(takeUntil(this.destroyed$))
            .subscribe(res => {
                if (res === 'True') {
                    this.gamificationService.getCountForUser(this.user.id)
                        .pipe(takeUntil(this.destroyed$))
                        .subscribe(gamificationCount => {
                            this.gamificationCount = gamificationCount;
                            this.gamificationEnabled = true;
                            this.cdr.detectChanges();
                        });
                }
            });
    }

    imageChangeEvent(fileInput) {
        const files = fileInput.target.files;
        if (files && files[0]) {
            if (this.user && this.currentUser) {
                this.changingPicture = true;

                const formData: FormData = new FormData();
                formData.append('file', files[0], files[0].name);

                this.usersService.uploadProfilePhoto(formData, this.user.accountName).pipe(take(1)).subscribe(res => {
                    if (res.url) {
                        this.showPicture = true;
                        this.usersService.userPhotoChanged$.next({ userId: this.user.id, url: res.url });
                        this.alertsService.riseSuccess(`Аватар был изменен успешно`);
                        // gamification
                        this.getGamifications();
                    } else if (res.error) {
                        console.log(res.error);
                        this.showPicture = false;
                        this.alertsService.riseError(`Произошла ошибка при смене аватара`);
                    }
                    fileInput.target.value = '';
                    this.changingPicture = false;
                }, err => {
                    console.log(err);
                    this.showPicture = false;
                    fileInput.target.value = '';
                    this.alertsService.riseError(`Произошла ошибка при смене аватара`);
                    this.changingPicture = false;
                });
            } else {
                this.alertsService.riseError(`Произошла ошибка при смене аватара`);
            }
        }
    }

    subscribe() {
        if (this.isSubscribed) {
            this.subscribeService.unsubscribe(this.user.id)
                .pipe(take(1))
                .subscribe(res => {
                    this.isSubscribed = false;
                    console.log('successfully updated subscription');
                }, err => {
                    this.error = err;
                });
        } else {
            this.subscribeService.subscribe(this.user.id)
                .pipe(take(1))
                .subscribe(res => {
                    this.isSubscribed = true;
                    console.log('successfully updated subscription');
                }, err => {
                    this.error = err;
                });
        }
    }

    getSip(): string {
        return this.user && this.user.email ? `sip:${this.user.email}` : '';
    }

    /**
     * Удалить фото профиля
     *
     * @memberof UserCardComponent
     */
    removePhoto() {
        this.usersService.removeProfilePhoto().pipe(take(1)).subscribe(res => {
            if (res.error) {
                console.log(res.error);
                this.showPicture = true;
                this.alertsService.riseError(`Произошла ошибка при удалении аватара`);
            } else {
                this.user.pictureUrl = '';
                this.usersService.userPhotoDeleted$.next({ userId: this.user.id, url: res.url });
                this.alertsService.riseSuccess(`Аватар был успешно удалён`);
                this.showPicture = false;
            }
        }, err => {
            console.log(err);
            this.showPicture = true;
            this.alertsService.riseError(`Произошла ошибка при удалении аватара`);
        });
    }


    private getColleaguesCount() {
        if (this.user != null) {
            this.subscribeService.getAllOnUser(this.user.id, 0, 1).pipe(take(1)).subscribe(res => {
                if (res) {
                    // all colleagues count (minus one)
                    this.colleaguesCount = res.count > 1 ? res.count - 1 : 0;
                    // last colleagur picture
                    if (res.items && res.items.length) {
                        this.lastColleague = res.items[0].user;
                    }
                }
            });
        }
    }

    private getGroupsCount() {
        if (this.user != null) {
            this.groupsService.getUserGroups(this.user.id, 0, 1).pipe(take(1)).subscribe(res => {
                // all groups count (minus one)
                this.groupsCount = res.count > 1 ? res.count - 1 : 0;
                // last colleagur picture
                if (res.groups && res.groups.length) {
                    this.lastGroup = res.groups[0];
                }
            });
        }
    }

    private getCounters() {
        if (this.isNotDesktop()) {
            // show counters
            this.showCounters.next(this.loaded && true);
            if (!this.countersLoaded) {
                this.countersLoaded = true;
                // request counters from api
                this.getColleaguesCount();
                this.getGroupsCount();
            }
        } else {
            // hide counters
            this.showCounters.next(false);
        }
    }

    onImgError(e) {
        this.showPicture = false;
    }

    isShowUserBirthday(): boolean {
        return this.user && this.currentUser && this.user.birthday && this.birthdayProperty
            && (this.user.id === this.currentUser.id
                || this.birthdayProperty.privacy === Privacy.Public && !!this.birthdayProperty.value);
    }

    isShowUserMobile(): boolean {
        return this.user && this.currentUser && (!!this.cellPhoneProperty || !!this.user.mobilePhone);
    }

    isAllowEditMobile(): boolean {
        return this.user && this.currentUser && this.cellPhoneProperty
            && this.cellPhoneProperty.isEditable
            && this.user.id === this.currentUser.id;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.onBaseResize();
        this.getCounters();
    }

    isOnline(user: User) {
        return Helper.isOnline(user);
    }

    isBusy(user: User) {
        return Helper.isBusy(user);
    }

    isAway(user: User) {
        return Helper.isAway(user);
    }

    /**
     * Отслеживать нажатие 'enter'
     *
     * @param {*} e
     * @memberof UserCardComponent
     */
    onKey(e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            e.target.blur();
        }
    }

    /**
     * Сохранение свойства при потере фокуса
     *
     * @param {*} e
     * @memberof UserCardComponent
     */
    onBlur(e) {
        this.usersService.currentUser.subscribe(currentUser => {
            console.log('on blur for user: ' + this.user.accountName + ' and prop ' + e.target.name + ' with value: ' + e.target.value);
            if (this.user.id === currentUser.id) {
                let update = false;
                switch (e.target.name) {
                    case 'WorkEmail':
                        // tslint:disable-next-line:triple-equals
                        if (this.user.email != e.target.value) {
                            if (!GlobalValidator.mailIsValid(e.target.value)) {
                                console.log('email is invalid ' + e.target.value);
                                this.alertsService.riseError(`Ошибка сохранения email адреса. Введён некорректный email.`);
                                update = false;
                                e.target.value = this.user.email;
                            } else {
                                update = true;
                            }
                        }
                        break;
                    case 'CellPhone':
                        // tslint:disable-next-line:triple-equals
                        if (this.user.mobilePhone != e.target.value) {
                            if (!GlobalValidator.phoneIsValid(e.target.value)) {
                                console.log('phone is invalid ' + e.target.value);
                                this.alertsService.riseError(`Ошибка сохранения номера мобильного телефона. Введён некорректный номер.`);
                                update = false;
                                e.target.value = this.user.mobilePhone;
                            } else {
                                update = true;
                            }
                        }
                        break;
                    case 'FB':
                        update = update
                            || this.isNeedToUpdateProperty(e.target.value,
                                this.fbLinkProperty ? this.fbLinkProperty.value : '');
                        break;
                    case 'VK':
                        update = update
                            || this.isNeedToUpdateProperty(e.target.value,
                                this.vkLinkProperty ? this.vkLinkProperty.value : '');
                        break;
                    case 'Instagram':
                        update = update
                            || this.isNeedToUpdateProperty(e.target.value,
                                this.instagramLinkProperty ? this.instagramLinkProperty.value : '');
                        break;
                    case 'OK':
                        update = update
                            || this.isNeedToUpdateProperty(e.target.value,
                                this.odnoklassnikiLinkProperty ? this.odnoklassnikiLinkProperty.value : '');
                        break;
                }
                if (update) {
                    if (!this.updatingProperty) {
                        this.updatingProperty = true;
                        this.usersService.updateUserProperty(e.target.name, e.target.value ? e.target.value : '', this.user.id)
                            .pipe(
                                finalize(() => {
                                    this.updatingProperty = false;
                                }),
                                takeUntil(this.destroyed$))
                            .subscribe(res => {
                                if (res) {
                                    switch (e.target.name) {
                                        case 'WorkEmail':
                                            this.user.email = e.target.value;
                                            break;
                                        case 'CellPhone':
                                            this.user.mobilePhone = e.target.value;
                                            break;
                                    }

                                    if (this.user.id === currentUser.id) {
                                        this.usersService.currentUser.next(this.user);
                                    }
                                    this.updatingProperty = false;

                                    switch (e.target.name) {
                                        case 'FB':
                                            this.fbLink = e.target.value ? e.target.value : '';
                                            this.fbLinkProperty.value = this.fbLink;
                                            break;
                                        case 'VK':
                                            this.vkLink = e.target.value ? e.target.value : '';
                                            if (this.vkLinkProperty) {
                                                this.vkLinkProperty.value = this.vkLink;
                                            }
                                            break;
                                        case 'Instagram':
                                            this.instagramLink = e.target.value ? e.target.value : '';
                                            this.instagramLinkProperty.value = this.instagramLink;
                                            break;
                                        case 'OK':
                                            this.odnoklassnikiLink = e.target.value ? e.target.value : '';
                                            if (this.odnoklassnikiLink) {
                                                this.odnoklassnikiLinkProperty.value = this.odnoklassnikiLink;
                                            }
                                            break;
                                    }
                                    this.alertsService.riseSuccess(`Свойство профиля успешно изменено`);
                                    // gamification
                                    this.getGamifications();
                                } else {
                                    this.alertsService.riseError(`Произошла ошибка при обновлении свойства профиля`);
                                }
                            }, err => {
                                console.log('error on update user property: ' + err);
                                this.updatingProperty = false;
                                this.alertsService.riseError(`Произошла ошибка при обновлении свойства профиля`);
                            });
                    }
                }
            } else {
                console.log('u can\'t change property of another user');
            }
        });
    }

    private isNeedToUpdateProperty(value, oldValue): boolean {
        if (value !== oldValue) {
            if (!GlobalValidator.urlIsValid(value)) {
                this.alertsService.riseError(`Значение не является валидным.`);
                value = oldValue;
                return false;
            } else {
                return true;
            }
        }
        return false;
    }

    private clearProperties() {
        this.birthdayProperty = null;
        this.cellPhoneProperty = null;
        this.russianPatronymicProperty = null;
        this.fbLinkProperty = null;
        this.vkLinkProperty = null;
        this.instagramLinkProperty = null;
        this.odnoklassnikiLinkProperty = null;

        this.usersService.userBirthdayProperty$.next(null);
        this.usersService.cellPhoneProperty$.next(null);
        this.usersService.fbLinkProperty$.next(null);
        this.usersService.vkLinkProperty$.next(null);
        this.usersService.instagramLinkProperty$.next(null);
        this.usersService.odnoklassnikiLinkProperty$.next(null);
    }

    changeUserAvatar(e: Event, uploadPhoto) {
        e.stopImmediatePropagation();
        uploadPhoto.click();
    }

    /**
     * Открыть всплывашку медиафайла
     */
    openUserAvatar(e: Event) {
        e.stopImmediatePropagation();

        if (this.user && this.user.pictureUrl) {
            this.dialog.open(UserAvatarViewComponent, {
                minWidth: window.innerWidth - 100,
                data: {
                    url: this.user.pictureUrl,
                    fullName: this.user.fullName
                }
            });
        }
    }
}
