<!-- TODO: вынести кнопку закрытия на уровень попапа -->
<div class="card hidden-desktop hidden-tablet _close-button">
  <button mat-button mat-dialog-close type="button" class="btn icon _close-blue"></button>
</div>
<div class="gallery-view">
  <div class="gallery-view__image">
    <ngx-slick-carousel class="carousel" style="width: 100%; max-width: 100vw;" #slickModal="slick-carousel" [config]="slideConfig"
      (afterChange)="onAfterChange($event)">
      <div ngxSlickItem *ngFor="let fileItem of files" class="slide">
        <div class="carousel__slide">
          <!-- show image -->
          <ng-container *ngIf="fileItem && filesService.isImage(fileItem)">
            <img [src]="filesService.webApiGetFileUrl(fileItem, null)" width="100%" class="gallery-view__img"
              alt="fileItem.name">
          </ng-container>
          <!-- show video -->
          <ng-container *ngIf="fileItem && filesService.isVideo(fileItem)">
            <app-video-player
              [options]="{
                autoplay: false,
                controls: true,
                sources: [{ src: filesService.webApiGetFileUrl(fileItem, null),
                  type: 'video/mp4'
                }]
              }"
              [current]="file === fileItem"
            ></app-video-player>
          </ng-container>
        </div>
      </div>
    </ngx-slick-carousel>

    <div class="gallery-view__top">
      <div class="gallery-view__title">{{getFileTitle(file)}}</div>
    </div>
  </div>
  <div class="gallery-view__comments">
    <div class="gallery-view__main">
      <div class="card">
        <button mat-button mat-dialog-close type="button" class="btn icon _close-blue pull-right hidden-phone"></button>
        <div class="post">
          <div class="post__author">
            <div class="link-profile" *ngIf="file?.fileUser">
              <app-user-avatar [user]="file?.fileUser" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'">
              </app-user-avatar>
              <span class="link-profile__text">
                <a routerLink="/profile/{{file?.fileUser?.id}}" class="link"
                  (click)="usersService.navigateProfile(file.fileUser?.id)"
                  [appUserTooltip]="file.fileUser?.id">{{file.fileUser?.fullName}}</a>
              </span>
              <span class="link-profile__subtext">{{file.modified | dateSpLike:true }}</span>
            </div>
          </div>
          <!-- <div class="post__text">{{file.description}}</div> -->
        </div>
      </div>
    </div>
  </div>
</div>