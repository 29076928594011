import { Component, OnInit, OnDestroy } from '@angular/core';
import { User } from '@app/profile/model/user.model';
import { UsersService } from '@app/profile/services/users.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { GroupInfo } from '@app/groups/model/group-info.model';
import { GroupsService } from '@app/groups/services/groups.service';
import { GroupRequestState } from '@app/groups/model/group-request-state.model';
import { Tab } from '@app/shared/components/tabs/tab.model';
import { GroupViewModel } from '@app/groups/model/group-view-model.model';
import { TabbedListComponent } from '@app/shared/components/tabs/tabbed-list.component';
import { GroupType } from '@app/groups/model/group-type.model';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { GroupMenuType } from '../group/group-menu/group-menu.component';
import { HelperService } from '@app/core/services/helper.service';
import { takeUntil, take } from 'rxjs/operators';

@Component({
    selector: 'app-groups-list',
    templateUrl: 'groups-list.component.html'
})

export class GroupsListComponent extends TabbedListComponent<GroupInfo> implements OnInit, OnDestroy {
    user: User;
    currentUser: User;
    showPicture = true;

    isCurrentUser: boolean;
    showAddButton: boolean;

    businessGroups: GroupInfo[];
    otherGroups: GroupInfo[];

    protected groupRequestState = GroupRequestState;
    protected groupType = GroupType;
    protected menuType = GroupMenuType;

    // overrided values
    protected limit = 30;
    protected maxItemHeight = 110;

    constructor(protected router: Router,
        protected route: ActivatedRoute,
        private usersService: UsersService,
        private groupsService: GroupsService,
        private sanitizer: DomSanitizer,
        public helper: HelperService) {
        super(router, route, helper);
    }

    ngOnInit() {
        this.usersService.currentUser.subscribe(currentUser => {
            // with current user
            this.usersService.user.pipe(takeUntil(this.destroyed$)).subscribe(user => {
                // set user
                this.user = user;
                // check user
                if (user) {
                    this.isCurrentUser = this.user.id === currentUser.id;
                    // init tabs
                    this.tabs = {
                        title: 'Группы',
                        routerLink: `/profile/${user.id}/groups`,
                        items: [
                            {
                                id: 1,
                                title: this.user.id !== currentUser.id ? 'Группы коллеги' : 'Мои группы',
                                fragment: '',
                                items: [],
                                offset: 0,
                                itemsCount: 0,
                                method: this.getUserGroups.bind(this),
                                default: true
                            },
                            {
                                id: 2,
                                title: 'Все группы',
                                fragment: 'all',
                                items: [],
                                offset: 0,
                                itemsCount: 0,
                                method: this.getGroups.bind(this)
                            }
                        ]
                    };

                    this.showAddButton = this.isCurrentUser && this.usersService.isAnyAdmin(currentUser);

                    this.route.fragment.pipe(takeUntil(this.destroyed$)).subscribe(val => {
                        // get hash
                        if (val != null) {
                            const keys = val.split('&');
                            const hash = {};
                            keys.forEach(key => {
                                // tslint:disable-next-line:no-shadowed-variable
                                const val = key.split('=');
                                hash[val[0]] = val[1];
                            });

                            this.currentTab = null;

                            this.tabs.items.forEach(tab => {
                                if (tab.fragment && keys.find(k => k === tab.fragment)) {
                                    this.currentTab = tab;
                                    this.setGroupsForView();
                                }
                            });

                            if (!this.currentTab) {
                                this.currentTab = this.tabs.items[0];
                                this.setGroupsForView();
                            }
                        } else {
                            this.currentTab = this.tabs.items[0];
                            this.setGroupsForView();
                        }
                    });

                    this.tabs.items.forEach(tab => {
                        tab.method(tab);
                    });
                }
            });
        });
    }

    private setGroupsForView() {
        if (this.currentTab) {
            this.businessGroups = this.currentTab.items.filter(g => {
                return g.type === GroupType.Business;
            });

            this.otherGroups = this.currentTab.items.filter(g => {
                return g.type !== GroupType.Business;
            });
        }
    }

    operateGroups(method: Observable<GroupViewModel>, tab: Tab<GroupInfo>) {
        tab.loading = true;

        if (this.user) {
            return method.pipe(take(1), takeUntil(this.destroyed$)).subscribe(data => {
                if (!tab.items || tab.offset === 0) {
                    tab.items = [];
                }

                if (data) {
                    data.groups.forEach(group => {
                        tab.items.push(group);
                    });
                    tab.offset = tab.items.length;
                    tab.itemsCount = data.count;
                }

                if (tab === this.currentTab) {
                    this.setGroupsForView();
                }

                this.loaded = tab.loaded = true;
                tab.loading = false;
            }, error => {
                this.businessGroups = this.otherGroups = [];
                this.loaded = tab.loaded = true;
                tab.loading = false;
            });
        } else {
            this.businessGroups = this.otherGroups = [];
            this.currentTab.items = [];
            this.currentTab.itemsCount = 0;
            this.currentTab.loaded = true;
        }
    }

    /**
     * Получение групп пользователя
     *
     * @param {Tab<GroupInfo>} tab
     * @memberof GroupsListComponent
     */
    getGroups(tab: Tab<GroupInfo>) {
        this.operateGroups(this.groupsService.getGroups(tab.offset, this.limit), tab);
    }

    /**
     * Получение всех групп
     *
     * @param {Tab<GroupInfo>} tab
     * @memberof GroupsListComponent
     */
    getUserGroups(tab: Tab<GroupInfo>) {
        this.operateGroups(this.groupsService.getUserGroups(this.user.id, tab.offset, this.limit), tab);
    }

    onJoin(e) {
        this.reloadGroups();
    }

    onLeave(e) {
        this.reloadGroups();
    }

    onUnsubscribe(e) {
        this.reloadGroups();
    }

    onSubscribe(e) {
        this.reloadGroups();
    }

    onRemove(e) {
        this.reloadGroups();
    }

    /**
     * Перезагрузить список групп
     *
     * @private
     * @memberof GroupsListComponent
     */
    private reloadGroups() {
        this.tabs.items.forEach(tab => {
            tab.offset = 0;
            tab.method(tab);
        });
    }

    /**
     * Получение картинки группы
     *
     * @param {GroupInfo} group группа
     * @returns {SafeStyle} стиль для background
     * @memberof GroupsListComponent
     */
    getPictureUrl(group: GroupInfo): SafeStyle {
        if (group && group.pictureUrl) {
            const style = `background-image: url('${this.groupsService.getGroupSmallPicture(group)}')`;
            return this.sanitizer.bypassSecurityTrustStyle(style);
        }
        return '';
    }
}
