import { Component } from '@angular/core';
import { WidgetBaseComponent } from '@app/shared/components/widget-base.component';
import { User } from '@app/profile/model/user.model';
import { UsersService } from '@app/profile/services/users.service';
import { HelperService } from '@app/core/services/helper.service';

@Component({
  selector: 'app-files-widget-small.rich-tabs__item._files-new',
  templateUrl: './files-widget-small.component.html',
  styleUrls: ['./files-widget-small.component.scss']
})
export class FilesWidgetSmallComponent extends WidgetBaseComponent {

  user: User;

  constructor(
    private usersService: UsersService,
    public helper: HelperService) {
    super(helper);
    this.usersService.currentUser.subscribe(currentUser => {
      this.user = currentUser;
    });
  }
}
