import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { takeUntil, switchMap, take, finalize } from 'rxjs/operators';
import { IVacancy, VacancyStatus, INewVacancyResponse } from '@app/vacancy/model/vacancy';
import { VacancyService } from '@app/vacancy/services/vacancy.service';
import { HelperService } from '@app/core/services/helper.service';
import { AlertsService } from '@app/shared/services/alerts.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { BaseComponent } from '@app/core/components/base.component';
import { AttachmentViewModel } from '@app/attachments/file-attachments/model/attachment-view-model.model';
import { AttachmentsListComponent } from '@app/attachments/file-attachments/components/attachments-list/attachments-list.component';
import { ElementType } from '@app/core/model/element-type.enum';
import { IDatafileModel } from '@app/files/model/data-file-model';

/**
 * Форма отображения вакансии
 *
 * @export
 * @class VacancyViewComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-vacancy-view',
  templateUrl: './vacancy-view.component.html',
  styleUrls: ['./vacancy-view.component.scss']
})
export class VacancyViewComponent extends BaseComponent implements OnInit, OnDestroy {

  elementType = ElementType;

  vacancy: IVacancy;
  mentions: any[] = [];
  attachments: AttachmentViewModel[] = [];
  responseAttachments: AttachmentViewModel[] = [];

  vacancyResponse: INewVacancyResponse = {
    vacancyId: null,
    description: '',
    docs: []
  };

  creatingResponse: boolean;

  sendingResponse = false;

  @ViewChild('responseAttachmentsList') private responseAttachmentsList: AttachmentsListComponent;

  constructor(
    protected vacancyService: VacancyService,
    protected breadcrumbsService: BreadcrumbsService,
    private alertsService: AlertsService,
    private router: Router,
    private route: ActivatedRoute,
    public helper: HelperService
  ) { super(helper); }

  ngOnInit() {

    this.route.params
      .pipe(takeUntil(this.destroyed$), switchMap((params: Params) => {
        this.vacancy = null;
        const vacancyId = +params['vacancyId'];
        return this.vacancyService.getById(vacancyId);
      }))
      .subscribe(vacancy => {
        this.vacancy = vacancy;

        if (this.vacancy) {

          if (this.vacancy.status === VacancyStatus.draft) {
            this.router.navigate(['edit'], { relativeTo: this.route });
          } else {
            // отрисовываем маршрут
            const vacancyRoute = `/group/${this.vacancy.ownerId}/vacancy`;

            this.breadcrumbsService.breadcrumbs.next([
              { title: 'Вакансии', routerUrl: vacancyRoute },
              { title: 'Вакансия', routerUrl: vacancyRoute + `/${this.vacancy.id}` }
            ]);

            // отображаем вложения
            if (this.vacancy.docs) {
              this.attachments = this.vacancy.docs.map(s => {
                return { attachment: s, owner: null };
              });
            }

            this.vacancyResponse.vacancyId = this.vacancy.id;
          }
        } else {
          this.router.navigate(['/404'], { skipLocationChange: true });
        }

        this.error = '';
        this.loaded = true;
      }, error => {
        this.error = error;
        this.loaded = true;
      });
  }

  onKey(e) {
    // TODO: проверка
  }

  /**
   * Является ли форма валидной
   *
   * @returns {boolean}
   * @memberof VacancyViewComponent
   */
  isValid(): boolean {
    return this.vacancyResponse.description && this.vacancy && this.vacancy.id ? true : false;
  }

  /**
   * Очистить отправку отклика
   *
   * @memberof VacancyViewComponent
   */
  cancel() {
    this.vacancyResponse.description = '';
    this.creatingResponse = false;
  }

  responseClick(): void {
    this.creatingResponse = true;
  }

  showResponseClick(): boolean {
    return !this.creatingResponse
      && this.vacancy
      && this.vacancy.status === VacancyStatus.published
      && !this.vacancy.isResponded;
  }

  isShowResponses() {
    return this.vacancy && this.vacancy.userAdmin;
  }

  /**
   * Отправить отклик
   *
   * @memberof VacancyViewComponent
   */
  submit() {
    if (this.isValid()) {
      this.sendingResponse = true;

      this.checkAttachments();

      this.vacancyService.response(this.vacancyResponse)
        .pipe(take(1), finalize(() => this.sendingResponse = false), takeUntil(this.destroyed$))
        .subscribe(res => {
          this.cancel();
          this.vacancyService.newResponse.next(res);
          if (res) {
            this.vacancy.isResponded = true;
            this.alertsService.riseSuccess('Ваш отклик был отправлен');
          } else {
            this.alertsService.riseError('Произошла ошибка при отправке отклика');
          }
        }, () => {
          this.alertsService.riseError('Произошла ошибка при отправке отклика');
        });
    }
  }

  getVacancyConfig(vacancy) {
    return {
      allowArchive: vacancy.status === VacancyStatus.published,
      allowEdit: true,
      allowDelete: true
    };
  }

  onDeleted(event) {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  showCategoryLink(): boolean {
    return this.vacancy && this.vacancy.category && this.vacancy.category.id > 0;
  }

  showGroupLink(): boolean {
    return true;
  }

  /**
   * Действие при удалении вложения
   *
   * @param {AttachmentViewModel} attachment
   * @memberof VacancyNewComponent
   */
  onRemoveAttachment(attachment: AttachmentViewModel) {
    this.responseAttachments = this.responseAttachments.filter(att =>
      attachment.attachment.guid && att.attachment.guid !== attachment.attachment.guid
      || !attachment.attachment.guid && att.attachment.title !== attachment.attachment.title);
  }

  /**
   * Действие при загрузке вложения
   *
   * @param IDatafileModel[] event
   * @memberof VacancyNewComponent
   */
  onFilesUploaded(event: IDatafileModel[]) {
    // add uploaded attachments
    if (event) {
      event.forEach(file => {
        this.vacancyResponse.docs.push({
          title: file.name,
          modified: file.created,
          ownerID: file.authorId,
          url: `/api/documentFile/${file.guid}`,
          guid: file.guid
        });
      });
    }
    this.sendingResponse = false;
  }

  /**
   * Действие при зарузке аттачмента к Отклику
   *
   * @param {*} e
   * @memberof VacancyViewComponent
   */
  onUploadFile(e) {
    // добавляем аттач в список
    this.responseAttachmentsList.onUploadFile(e);
    // блокируем кнопку отправки
    this.sendingResponse = true;
    // физически загружаем файлы
    this.responseAttachmentsList.uploadAttachedFiles(ElementType.VacancyResponse);
  }

  protected checkAttachments(): void {
    if (this.responseAttachments) {
      this.responseAttachments.forEach(att => {
        if (!this.vacancyResponse.docs.find(s => s.guid === att.attachment.guid || s.title === att.attachment.title)) {
          this.vacancyResponse.docs.push({
            title: att.attachment.title,
            modified: att.attachment.modified,
            ownerID: this.vacancy.ownerId,
            url: att.attachment.url,
            guid: att.attachment.guid
          });
        }
      });
    }
  }
}
