import {switchMap, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Helper} from '../helpers/helper';
import {HelperService} from './helper.service';

@Injectable()
export abstract class BaseApiService {
  protected abstract serviceUrl: string;

  constructor(protected http: HttpClient, public helper: HelperService) {}

  protected post<T>(
    operationName: string,
    data: Object = {},
    headers: HttpHeaders = null,
    responseType: string = 'json',
  ): Observable<T> {
    let url = Helper.concatUrls(this.serviceUrl, operationName);
    if (operationName && (operationName.startsWith('http://') || operationName.startsWith('https://'))) {
      url = operationName;
    }
    let options = {};
    const optionHeaders = this.buildHeaders(headers);
    if (responseType !== 'json') {
      switch (responseType) {
        case 'text':
          options = {headers: optionHeaders, responseType: 'text' /*, withCredentials: true*/};
          break;
        case 'blob':
          options = {headers: optionHeaders, responseType: 'blob', withCredentials: true};
          break;
        default:
          options = {headers: optionHeaders /*, withCredentials: true*/};
          break;
      }
      // return <Observable<T>>this.http.post(url, data, options).map(callbackExtractData);
    } else {
      options = {headers: optionHeaders /*, withCredentials: true*/};
    }
    return <Observable<T>>this.http.post(url, data, options);
  }

  protected delete<T>(operationName: string, params: HttpParams = null, headers: HttpHeaders = null): Observable<T> {
    let url = Helper.concatUrls(this.serviceUrl, operationName);
    if (operationName && (operationName.startsWith('http://') || operationName.startsWith('https://'))) {
      url = operationName;
    }
    return <Observable<T>>(
      this.http.delete(url, {headers: this.buildHeaders(headers), params: params /*, withCredentials: true*/})
    );
  }

  protected patch<T>(operationName: string, data: any, headers: HttpHeaders = null): Observable<T> {
    let url = Helper.concatUrls(this.serviceUrl, operationName);
    if (operationName && (operationName.startsWith('http://') || operationName.startsWith('https://'))) {
      url = operationName;
    }
    return <Observable<T>>this.http.patch(url, data, {headers: this.buildHeaders(headers) /*, withCredentials: true*/});
  }

  protected put<T>(operationName: string, data: Object): Observable<T> {
    return <Observable<T>>this.http.put(`${this.serviceUrl}/${operationName}`, data, {headers: this.buildHeaders()});
  }

  protected get<T>(
    operationNameWithParams: string,
    params: HttpParams = null,
    headers: HttpHeaders = null,
    responseType = 'json',
    noServiceUrl = false,
  ): Observable<T> {
    let url = noServiceUrl ? operationNameWithParams : Helper.concatUrls(this.serviceUrl, operationNameWithParams);

    if (!headers) {
      headers = new HttpHeaders();
    }

    headers = headers
      .append('Cache-Control', 'no-cache')
      .append('Pragma', 'no-cache')
      .append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');

    let options = {};

    const optionHeaders = this.buildHeaders(headers);
    if (responseType !== 'json') {
      switch (responseType) {
        case 'text':
          options = {headers: optionHeaders, responseType: 'text', withCredentials: true, params: params};
          break;
        case 'blob':
          options = {headers: optionHeaders, responseType: 'blob', withCredentials: true, params: params};
          break;
        default:
          options = {headers: optionHeaders, withCredentials: true, params: params};
          break;
      }
    } else {
      options = {headers: optionHeaders, withCredentials: true, params: params};
    }

    if (
      operationNameWithParams &&
      (operationNameWithParams.startsWith('http://') || operationNameWithParams.startsWith('https://'))
    ) {
      url = operationNameWithParams;
    }
    return <any>this.http.get(url, options);
  }

  public getWebServerUrl(url: string, headers: HttpHeaders): Observable<any> {
    return of('');
  }

  private buildHeaders(h: HttpHeaders = null): HttpHeaders {
    // HttpHeaders - immutable поэтому .set идёт сразу при объявлении
    let headers = new HttpHeaders();

    if (h) {
      headers = h;
    }

    // if (this.helper.environment.siteUrl) {
    //     headers = headers.append('Authorization'
    //         , `Basic ${btoa(this.helper.environment.username + ':' + this.helper.environment.password)}`);
    // }
    return headers;
  }
}
