<div class="cos__body _paddings">
  <div class="new-post">
    <div class="input-main new-post__title">
      <textarea rows="1" placeholder="Введите тему*" [(ngModel)]="idea.title" class="input-main__field"
        maxlength="255" (focus)="idea.titleFocused=true" (blur)="idea.titleFocused=false"
        (keyup)="isAlreadyExists=false;"></textarea>
      <div class="form__note" *ngIf="idea.titleFocused">{{idea.title?.length || 0}} из 255</div>
    </div>
    <div class="error-message" *ngIf="isAlreadyExists">Идея с таким названием уже существует</div>
    <div class="input-main new-post__about">
      <textarea autosize rows="3" placeholder="Опишите вашу идею*" [(ngModel)]="idea.description"
        class="input-main__field"></textarea>
    </div>
    <div class="news-section">
      <app-attachments-list #attachmentsList [attachments]="attachments" [isEdit]="true"
        (onRemove)="onRemoveAttachment($event)" (onFilesUploaded)="onFilesUploaded($event)" [ownerId]="idea.ownerId" [elementType]="elementType">
      </app-attachments-list>
    </div>
    <div class="new-post__actions" [ngClass]="{'loading _blue': sending}">
      <div class="new-post__actions-extra" *ngIf="idea.ownerId">
        <app-attachments-menu title="Добавить вложение" (choose)="attachmentsList.onChooseFile($event)"
          (upload)="onUploadFile($event)" [multiple]="false"></app-attachments-menu>
      </div>
      <button type="button" class="btn btn-simple" (click)="cancel()">Отмена</button>
      <button type="button" class="btn btn-primary" (click)="submit()"
        [attr.disabled]="isValid()?null:''">Сохранить</button>
    </div>
  </div>
</div>
