<div class="card _thanks _profile-thanks _preloader _row" *ngIf="!loaded">
  <div class="card__title">
    <span class="preloader _white">
      <span class="preloader__line"></span>
    </span>
  </div>
  <div class="card__content">
    <span class="preloader _white _avatar" *ngFor="let r of [0,1,2]">
      <span class="preloader__side"></span>
    </span>
  </div>
</div>

<div class="card _thanks _profile-thanks" *ngIf="loaded" [ngClass]="{'_no-data': !items?.length}">
  <div class="card__title">
    <span class="header-text">
      <a class="header-text__text" routerLink="/profile/{{user?.id}}/thanks">Спасибо</a>&nbsp;
      <span class="header-text__count">{{itemsCount}}</span>
      <button *ngIf="isShowThankButton()" type="button" class="btn btn-plus pull-right" (click)="thank()">+</button>
    </span>
  </div>
  <div class="card__content">
    <div class="card__cos-oneline" *ngIf="loaded && items?.length">
      <div class="co _white-text" *ngFor="let item of items">
        <app-user-avatar [user]="item.user" [linkClass]="'avatar _80'" [imageClass]="'avatar__image'">
        </app-user-avatar>
      </div>
    </div>
    <button *ngIf="loaded && !items?.length" type="button" class="btn btn-white btn-stroke" (click)="thank()">
      Сказать спасибо
    </button>
  </div>
</div>
