import { GroupInfo } from '@app/groups/model/group-info.model';
import { User } from '@app/profile/model/user.model';
import { Attachment } from '@app/attachments/file-attachments/model/attachment.model';

export interface IVacancyCategory {
    id?: number;
    title?: string;
    order?: number;
    // ui
    focused?: boolean;
}

export interface IVacancyCategoryChanges {
    added?: IVacancyCategory[];
    updated?: IVacancyCategory[];
    deleted?: number[];
}

export interface IVacancyStatus {
    id: number;
    title: string;
}

export interface IVacancyResponse {
    id: number;
    created: Date;
    modified: Date;
    status: ResponseStatus;
    statusDate: Date;
    description: string;
    author: User;
    vacancyTitle: string;
    vacancyId: number;
    vacancyOwnerId: number;
    userAdmin?: number;
    docs?: Attachment[];
}

export interface IVacancy {
    id: number;
    title: string;
    description: string;
    location: string;
    status: VacancyStatus;
    group: GroupInfo;
    authorId?: number;
    ownerId?: number;
    created?: Date;
    modified?: Date;
    publishDate?: Date;
    category?: IVacancyCategory;
    userAdmin?: number;
    responsesCount?: number;
    isResponded?: boolean;
    docs?: Attachment[];
    headHunterId?: number;
}

export enum VacancyStatus {
    published = 1,
    draft = 2,
    archive = 3
}

export enum ResponseStatus {
    new = 1,
    inProcess = 2,
    prospective = 3,
    declined = 4
}

export interface INewVacancy {
    title: string;
    description: string;
    location: string;
    status: VacancyStatus;
    categoryId: number;
    ownerId?: number;
    docs?: Attachment[];
}

export interface IUpdateVacancy extends INewVacancy {
    id: number;
}

export interface INewVacancyResponse {
    vacancyId: number;
    description: string;
    docs?: Attachment[];
}

export interface IHhVacancyResponse {
    items: IHhVacancy[];
    found: number;
    page: number;
    pages: number;
    per_page: number;
}

export interface IHhVacancy {
    id: number;
    name: string;
    description: string;
    location: string;
    specializations: ISpecialization[];
    area: IHhVacancyArea;
    alternate_url: string;

    // ui
    checked: boolean;
}

export interface ISpecialization {
    id: number;
    name: string;
}

export interface IHhVacancyArea {
    id: number;
    name: string;
}

export interface ISyncVacanciesModel {
    ownerId: number;
    hhIds: number[];
}
