import Konva from 'konva';

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { environment } from '@env/environment';
import { GalleryService } from '@app/gallery/services/gallery.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { AlertsService } from '@app/shared/services/alerts.service';
import { SettingsService } from '@app/shared/services/settings.service';
import { Router } from '@angular/router';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { IMediaFile } from '@app/gallery/model/media-file';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { DomSanitizer } from '@angular/platform-browser';

const STEPS = [
  {
    caption: 'Загрузите фото вашего малыша',
    tip: 'Лучше всего использовать горизонтальную фотографию (пропорции сторон 16:9) с лицом ребенка по центру фотографии',
    croppable: true,
    canUpload: true,
    showNext: true,
  },
  {
    caption: 'Выберите элементы фрутомоськи',
    tip: 'Мордочку и зверушку можно масштабировать, перемещать и вращать',
    editable: true,
    showNext: true,
    showBack: true,
  },
  {
    caption: 'Готово! Загрузите фотографию в альбом конкурса.',
    tip: '',
    completed: true,
    showBack: true,
  },
];

const FACES = [
  'icon-moska-myshka.png',
  'icon-moska-ovechka.png',
  'icon-moska-egik.png',
  'icon-moska-koshka.png',
  'icon-moska-giraf.png',
  'icon-moska-sobachka.png',
  'icon-moska-mishka.png',
].map(fileName => `${environment.assetsPrefix}/assets/images/fruitfaces/faces/${fileName}`);

const ANIMALS = [
  'icon-zver-1.svg',
  'icon-zver-2.svg',
  'icon-zver-4.svg',
  'icon-zver-5.svg',
  'icon-zver-6.svg',
  'icon-zver-7.svg',
  'icon-zver-8.svg',
  'icon-zver-9.svg',
  'icon-zver-10.svg',
  'icon-zver-11.svg',
  'icon-zver-12.svg',
  'icon-zver-13.svg',
  'icon-zver-14.svg',
  'icon-zver-15.svg',
  'icon-zver-16.svg',
  'icon-zver-17.svg',
  'icon-zver-18.svg',
  'icon-zver-19.svg',
  'icon-zver-20.svg',
  'icon-zver-21.svg',
  'icon-zver-22.svg',
  'icon-zver-23.svg',
  'icon-zver-24.svg',
  'icon-zver-25.svg',
  'icon-zver-26.svg',
  'icon-zver-27.svg',
  'icon-zver-28.svg',
  'icon-zver-29.svg',
  'icon-zver-30.svg',
  'icon-zver-31.svg',
  'icon-zver-32.svg',
  'icon-zver-33.svg',
  'icon-zver-34.svg',
].map(fileName => `${environment.assetsPrefix}/assets/images/fruitfaces/animals/${fileName}`);

const BUTTONS = {
  rotater: {
    path: '<svg width="18" height="18" xmlns="http://www.w3.org/2000/svg"><path d="M16.83 10.764c.945-4.866-2.131-9.616-6.857-10.59A8.535 8.535 0 0 0 3.58 1.4L2.57.662c-.558-.407-1.069-.091-1.142.704l-.422 4.662c-.073.796.44 1.172 1.146.838l4.134-1.954c.706-.334.89-.887.411-1.236l-.866-.632a6.108 6.108 0 0 1 3.658-.386c3.396.7 5.606 4.113 4.927 7.61-.68 3.496-3.995 5.772-7.391 5.072a6.204 6.204 0 0 1-2.885-1.456 1.206 1.206 0 0 0-1.736.124 1.292 1.292 0 0 0 .121 1.788 8.641 8.641 0 0 0 4.017 2.028c4.726.974 9.34-2.193 10.286-7.06z" fill="#4A9E89"/></svg>',
    selector: '.rotater',
    fileUrl: `${environment.assetsPrefix}/assets/images/fruitfaces/icon-rotate.svg`,
  },
  bottom_right: {
    path: '<svg width="18" height="18" xmlns="http://www.w3.org/2000/svg"><path d="M8.972 8.173-4.39-3.324.915-.07a.662.662 0 1 0-.106-1.32l-2.654.207a.662.662 0 0 0-.606.714v.011l.002.013c0 .01.003.033.01.06l.52 2.6c.072.356.424.59.78.518a.663.663 0 0 0 .518-.773l-.18-.901 4.39 3.324 1.057.796 4.39 3.324-.914.07a.662.662 0 1 0 .106 1.32l2.653-.207a.662.662 0 0 0 .607-.714l-.001-.011-.001-.014a.355.355 0 0 0-.01-.06l-.52-2.6a.665.665 0 0 0-.78-.517.664.664 0 0 0-.518.772l.18.906-4.392-3.325-1.056-.8z" fill="#4A9E89" fill-rule="nonzero" stroke="#4A9E89" stroke-width=".545"/></svg>',
    selector: '.bottom-right',
    fileUrl: `${environment.assetsPrefix}/assets/images/fruitfaces/icon-size.svg`,
  },
};

@Component({
  selector: 'app-fruitfaces',
  templateUrl: './fruitfaces.component.html',
  styleUrls: ['./fruitfaces.component.scss'],
})
export class FruitfacesComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('fruitFacesUpload', { static: true }) fruitFacesUpload: ElementRef;
  @ViewChild('slickModalFaces') slickModalFaces: SlickCarouselComponent;
  @ViewChild('slickModalAnimals') slickModalAnimals: SlickCarouselComponent;

  hasImage = false;
  currentStepIndex = 0;
  currentStep = STEPS[this.currentStepIndex];
  layer: Konva.Layer;
  masksLayer: Konva.Layer;
  stage: Konva.Stage;
  tr: Konva.Transformer;
  fitStageIntoParentContainer: () => void;

  currentFaceUrl = FACES[0];
  currentAnimalUrl = ANIMALS[0];

  currentFaceImage: HTMLImageElement;
  currentAnimalImage: HTMLImageElement;

  faces = FACES;
  animals = ANIMALS;

  saving = false;
  redirect = false;

  showFacesOverlay = false;
  showAnimalsOverlay = false;

  mediaFileItem: IMediaFile;
  croppedImage: any = '';
  errorMessage: string;
  imageIsLoaded: boolean;
  imageChanged: boolean;
  tempUrl: any;
  croppedUrl: any;
  imageURL: string;

  private fruitFacesAlbumId: string;
  private fruitFacesGroupId: string;

  constructor(
    private readonly sanitizer: DomSanitizer,
    private galleryService: GalleryService,
    private settingsService: SettingsService,
    private alertService: AlertsService,
    private router: Router,
    public helper: HelperService) {
    super(helper);
  }

  ngOnInit(): void {
    const sceneWidth = 480;
    const sceneHeight = 270;

    this.stage = new Konva.Stage({
      container: 'fruitfacesContainer',
      width: 480,
      height: 270,
    });

    this.layer = new Konva.Layer({
      listening: false,
    });
    this.stage.add(this.layer);

    this.fitStageIntoParentContainer = fitStageIntoParentContainer.bind(null, this.stage, sceneWidth, sceneHeight);
    window.addEventListener('resize', this.fitStageIntoParentContainer);

    // https://konvajs.org/docs/select_and_transform/Basic_demo.html
    this.stage.on('click tap', (e) => {
      if (e.target === this.stage || e.target.hasName('mainImage')) {
        this.tr.nodes([]);
        return;
      }

      const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
      const isSelected = this.tr.nodes().indexOf(e.target) >= 0;

      if (!metaPressed && !isSelected) {
        this.tr.nodes([e.target]);
      } else if (metaPressed && isSelected) {
        const nodes = this.tr.nodes().slice();
        nodes.splice(nodes.indexOf(e.target), 1);
        this.tr.nodes(nodes);
      } else if (metaPressed && !isSelected) {
        const nodes = this.tr.nodes().concat([e.target]);
        this.tr.nodes(nodes);
      }
    });
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.fitStageIntoParentContainer);

    this.stage.destroy();

    super.ngOnDestroy();
  }

  public nextStep() {
    this.currentStepIndex++;
    this.currentStep = STEPS[this.currentStepIndex];

    if (!this.masksLayer) {
      this.setAnimalsAndFaces();
    } else {
      this.masksLayer.show();
    }

    if (this.currentStep.completed) {
      this.tr.nodes([]);
      this.masksLayer?.setAttr('listening', false);
    }
  }

  public prevStep() {
    this.redirect = false;
    this.currentStepIndex--;
    this.currentStep = STEPS[this.currentStepIndex];

    if (this.currentStep.canUpload) {
      this.masksLayer?.hide();
    } else {
      this.masksLayer?.setAttr('listening', true);
    }
  }

  public handleMasksSet(event, maskType: 'face' | 'animal') {
    const { currentSlide } = event.slick;

    this.setMask(currentSlide, maskType);
  }

  public handleMaskClick(index: number, maskType: 'face' | 'animal') {
    this.setMask(index, maskType);

    if (maskType === 'face') {
      this.slickModalFaces.slickGoTo(index);
    } else if (maskType === 'animal') {
      this.slickModalAnimals.slickGoTo(index);
    }

    this.showFacesOverlay = false;
    this.showAnimalsOverlay = false;
  }

  public setMask(index: number, maskType: 'face' | 'animal') {
    switch (maskType) {
      case 'face':
        this.currentFaceUrl = FACES[index];
        this.currentFaceImage.src = this.currentFaceUrl;

        break;
      case 'animal':
        this.currentAnimalUrl = ANIMALS[index];
        this.currentAnimalImage.src = this.currentAnimalUrl;

        break;
      default:
        break;
    }

    this.masksLayer.draw();
  }

  public setAnimalsAndFaces() {
    this.masksLayer?.destroy();

    this.masksLayer = new Konva.Layer();
    this.stage.add(this.masksLayer);

    this.masksLayer.on('mouseenter', () => {
      this.stage.container().style.cursor = 'move';
      this.masksLayer.draw();
    });

    this.masksLayer.on('mouseleave', () => {
      this.stage.container().style.cursor = 'default';
    });

    [{
      url: this.currentFaceUrl,
      name: 'face',
      width: 140,
      height: 102,
      x: this.stage.width() / 2 - 140 / 2,
      y: this.stage.height() / 2 - 102 / 2,
      imageKey: 'currentFaceImage',
    }, {
      url: this.currentAnimalUrl,
      name: 'animal',
      width: 166,
      height: 166,
      x: 0,
      y: this.stage.height() - 166,
      imageKey: 'currentAnimalImage',
    }].forEach(({ url, name, width, height, x, y, imageKey }) => {
      const imageElement = new Image();
      imageElement.src = url;

      const image = new Konva.Image({
        x, y, width, height, draggable: true, name,
        image: imageElement,
      });

      this.masksLayer.add(image);

      this[imageKey] = imageElement;
    });

    this.tr = new Konva.Transformer({
      nodes: [],
      keepRatio: false,
      borderStroke: '#fff',
      borderStrokeWidth: 2,
      anchorCornerRadius: 50,
      anchorSize: 28,
      anchorStroke: '#4a9e89',
      anchorFill: '#fff',
      enabledAnchors: ['bottom-right'],
      rotateAnchorOffset: 0,
    });

    this.tr.update = () => {
      Konva.Transformer.prototype.update.call(this.tr);

      // tslint:disable-next-line:forin
      for (const button in BUTTONS) {
        const selector = button.replace('_', '-');
        const shape = this.tr.findOne(`.${selector}`);
        const icon = this.tr.findOne(`.${selector}-icon`);

        icon.position(shape.position());
        icon.x(icon.x() - 9); icon.y(icon.y() - 9);

        this.layer.batchDraw();
      }
    };

    // tslint:disable-next-line:forin
    for (const button in BUTTONS) {
      const shape = this.tr.findOne(BUTTONS[button].selector);
      const selector = button.replace('_', '-');

      const imageObj = new Image();
      imageObj.onload = () => {
        const image = new Konva.Image({
          x: shape.x() - 9,
          y: shape.y() - 9,
          image: imageObj,
          width: 18,
          height: 18,
          name: selector + '-icon',
          listening: false,
        });

        image.position(shape.position());

        this.tr.add(image);
      };
      imageObj.src = BUTTONS[button].fileUrl;
    }

    this.masksLayer.add(this.tr);
  }

  public onSetMainPicture($event) {
    const URL = window.webkitURL || window.URL;
    this.imageURL = URL.createObjectURL($event.target.files[0]);

    this.hasImage = true;
  }

  setKonvaImage(url) {
    const { layer, stage } = this;

    const oldImage = layer.findOne('.mainImage');
    oldImage?.remove();

    console.log(stage, stage.scaleX(), stage.scaleY());

    Konva.Image.fromURL(
        url,
        (newImage) => {
          newImage.setAttrs({
            width: stage.width() / stage.scaleX(),
            height: stage.height() / stage.scaleY(),
            x: 0,
            y: 0,
            name: 'mainImage',
          });

          layer.add(newImage);

          applyCrop(layer, newImage, 'center-middle');
        }
    );
  }

  /**
   * Меняет миниатюры фотографии и проверяет их ширину
   *
   * @memberof PhotoEditorComponent
   */
  imageCropped(event: ImageCroppedEvent) {
    if (this.imageIsLoaded) {
      const width = event.width - 1;

      if (width < 200 && width > 0) {
        this.errorMessage = 'Выбранная область слишком маленькая';
        this.imageChanged = false;
      } else {
        this.errorMessage = undefined;
        this.imageChanged = true;
        this.croppedUrl = event.base64;
      }
    }

    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.base64);

    this.setKonvaImage(event.base64);
  }

  imageLoaded() {
    this.imageIsLoaded = true;
  }

  public save() {
    this.saving = true;
    const dataURL = this.stage.toDataURL({ pixelRatio: 2, mimeType: 'image/jpeg' });

    const blob = dataURItoBlob(dataURL);
    const formData = new FormData();
    const file = new File([blob], 'moska.jpg', { type: 'image/jpeg' });
    formData.append('file', file);

    this.settingsService.getValues(['fruitFacesAlbumId', 'fruitFacesGroupId'])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(val => {
        if (val) {

          this.fruitFacesAlbumId = val.find(s => s.key === 'fruitFacesAlbumId')?.value;
          this.fruitFacesGroupId = val.find(s => s.key === 'fruitFacesGroupId')?.value;

          if (!this.fruitFacesAlbumId || !this.fruitFacesGroupId) {
            this.alertService.riseError('Не настроен альбом для фрутомосек');
            this.saving = false;
            return;
          }

          this.galleryService.uploadMediaFile(+this.fruitFacesAlbumId, formData)
            .pipe(
              finalize(() => { this.saving = false; }),
              takeUntil(this.destroyed$))
            .subscribe(res => {
              if (res) {
                this.alertService.riseSuccess('Фрутомоська была успешно загружена');
                this.redirect = true;
              } else {
                this.alertService.riseError('Произошла ошибка при загрузке фрутомоськи');
              }
            }, error => {
              this.alertService.riseError('Произошла ошибка при загрузке фрутомоськи');
            }, () => {
              this.saving = false;
            });
        } else {
          this.saving = false;
          this.alertService.riseError('Не настроен альбом для фрутомосек');
        }
      });

    // downloadURI(dataURL, 'moska.png');
  }

  goToAlbum() {
    this.router.navigate(['group', this.fruitFacesGroupId, 'gallery', this.fruitFacesAlbumId]);
  }
}

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = unescape(dataURI.split(',')[1]);
  }

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

function fitStageIntoParentContainer(stage, sceneWidth, sceneHeight) {
  const container = document.querySelector('#fruitfacesContainerParent');
  const computedStyle = getComputedStyle(container);
  const paddings = parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);

  const containerWidth = container.clientWidth - paddings;

  const scale = containerWidth / sceneWidth;

  stage.width(sceneWidth * scale);
  stage.height(sceneHeight * scale);
  stage.scale({
    x: scale,
    y: scale,
  });
}

function getCrop(image, size, clipPosition = 'center-middle') {
  const width = size.width;
  const height = size.height;
  const aspectRatio = width / height;

  let newWidth;
  let newHeight;

  const imageRatio = image.width / image.height;

  if (aspectRatio >= imageRatio) {
    newWidth = image.width;
    newHeight = image.width / aspectRatio;
  } else {
    newWidth = image.height * aspectRatio;
    newHeight = image.height;
  }

  let x = 0;
  let y = 0;
  if (clipPosition === 'left-top') {
    x = 0;
    y = 0;
  } else if (clipPosition === 'left-middle') {
    x = 0;
    y = (image.height - newHeight) / 2;
  } else if (clipPosition === 'left-bottom') {
    x = 0;
    y = image.height - newHeight;
  } else if (clipPosition === 'center-top') {
    x = (image.width - newWidth) / 2;
    y = 0;
  } else if (clipPosition === 'center-middle') {
    x = (image.width - newWidth) / 2;
    y = (image.height - newHeight) / 2;
  } else if (clipPosition === 'center-bottom') {
    x = (image.width - newWidth) / 2;
    y = image.height - newHeight;
  } else if (clipPosition === 'right-top') {
    x = image.width - newWidth;
    y = 0;
  } else if (clipPosition === 'right-middle') {
    x = image.width - newWidth;
    y = (image.height - newHeight) / 2;
  } else if (clipPosition === 'right-bottom') {
    x = image.width - newWidth;
    y = image.height - newHeight;
  } else if (clipPosition === 'scale') {
    x = 0;
    y = 0;
    newWidth = width;
    newHeight = height;
  } else {
    console.error(
      new Error('Unknown clip position property - ' + clipPosition)
    );
  }

  return {
    cropX: x,
    cropY: y,
    cropWidth: newWidth,
    cropHeight: newHeight,
  };
}

function applyCrop(layer, img, pos) {
  img.setAttr('lastCropUsed', pos);
  const crop = getCrop(
    img.image(),
    { width: img.width(), height: img.height() },
    pos
  );
  img.setAttrs(crop);
}

function downloadURI(uri, name) {
  const link = document.createElement('a');
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
