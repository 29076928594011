<div class="form cos__body">
  <div class="news-title _bright">Настройки категорий</div>
  <div cdkDropList (cdkDropListDropped)="drop($event)">
    <div class="form__line draggable-line _nowrap" cdkDrag *ngFor="let category of categories; let i = index">
      <div class="draggable-line__content">
        <button type="button" class="btn btn-drag draggable-line__icon"></button>
        <div class="input-main form__about">
          <input placeholder="Название категории {{i + 1}}" [(ngModel)]="category.title" type="text" maxlength="255"
            class="input-main__field" (focus)="onFocus($event, category)" (blur)="onBlur($event, category)" (keyup)="onBlur($event, category)" />
          <div class="form__note" *ngIf="category.focused">{{category.title?.length || 0}} из 255</div>
        </div>
        <button type="button" class="btn btn-remove" (click)="onRemove(category)"></button>
      </div>
    </div>
  </div>
  <div class="form__line">
    <button type="button" class="btn btn-add" (click)="addCategory()">Добавить категорию</button>
  </div>
  <div class="form__line">
    <label>
      <span>ID компании на hh.ru для интеграции: </span>
      <input placeholder="Введите id компании на hh.ru"
        [(ngModel)]="hhCompanyId"
        type="number"
        maxlength="20"
        class="main-input _inline _hh"
      />
    </label>
  </div>
  <div class="form__actions">
    <button type="button" class="btn btn-simple" [attr.disabled]="hasAnyChanges()?null:''"
      (click)="onCancel()">Отмена</button>
    <button type="button" class="btn btn-primary" [attr.disabled]="hasAnyChanges()?null:''"
      (click)="submit()">Сохранить</button>
  </div>
</div>
