import { Component, OnDestroy, Input } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { NewThankComponent } from '../new-thank/new-thank.component';
import { User } from '@app/profile/model/user.model';
import { UsersService } from '@app/profile/services/users.service';
import { ThanksService } from '@app/thanks/services/thanks.service';
import { AlertsService } from '@app/shared/services/alerts.service';

@Component({
  selector: 'app-add-thank-button',
  templateUrl: './add-thank-button.component.html',
  styleUrls: ['./add-thank-button.component.scss']
})
export class AddThankButtonComponent extends BaseComponent implements OnDestroy {

  @Input() user: User;

  protected newThankDialog: MatDialogRef<NewThankComponent>;

  constructor(
    public dialog: MatDialog,
    public usersService: UsersService,
    protected thanksService: ThanksService,
    protected alertsService: AlertsService,
    public helper: HelperService) {
    super(helper);
  }

  thank() {
    this.newThankDialog = this.dialog.open(NewThankComponent, {
      data: {
        usersToAdd: [this.user],
        onOk: () => {
          this.newThankDialog.close();
        }
      }
    });
  }
}
