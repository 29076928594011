<div class="btn tooltip" [ngClass]="{'btn-dots':isMobileView(), 'btn-plus':!isMobileView()}" *ngIf="showAddButton" mat-button [matMenuTriggerFor]="menu">+</div>
<mat-menu #menu="matMenu">
    <div class="icon-menu">
        <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" (click)="createSurvey()">
            <span class="icon-menu__icon">
                <img src="{{assetsPrefix}}/assets/images/icons/menu/poll.svg" alt="" class="icon-menu__img">
            </span>
            <span>Опрос</span>
        </a>
        <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" (click)="createTest()">
            <span class="icon-menu__icon">
                <img src="{{assetsPrefix}}/assets/images/icons/menu/quiz.svg" alt="" class="icon-menu__img">
            </span>
            <span>Тест</span>
        </a>
    </div>
</mat-menu>