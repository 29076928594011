<div class="cos__body _paddings" *ngIf="loaded && response">
  <div class="cos__actions pull-right" *ngIf="response.userAdmin">
    <app-response-menu [responseId]="response.id" [config]="responseConfig" (statusChanged)="onStatusChanged($event)">
    </app-response-menu>
  </div>
  <div class="news-title _bright">Отклик на вакансию "{{response.vacancyTitle}}"</div>
  <span class="header-text _low _secondary">
    <span class="header-text__text">Отклик от {{response.created | date:'dd.MM.yyyy'}}</span>
  </span>,&nbsp;<a href="javascript:;" class="link {{vacancyService.getResponseStatusCss(response)}}">{{vacancyService.getResponseStatusText(response)}}</a>
  <div class="news-section">
    <div class="news-announce">Кандидат</div>
    <div class="news-body">
      <div class="co">
        <div class="co__avatar">
          <app-user-avatar [user]="response.author" [linkClass]="'avatar _80'" [imageClass]="'avatar__image'">
          </app-user-avatar>
        </div>
        <div class="co__info">
          <a href="#" class="link co__name" routerLink="/profile/{{response.author?.id}}"
            (click)="usersService.navigateProfile(response.author?.id)"
            [appUserTooltip]="response.author?.id">{{response.author?.fullName}}</a>
          <div class="co__occ" *ngIf="response.author?.jobTitle">{{response.author?.jobTitle}}</div>
          <div class="co__occ" *ngIf="response.author?.department">{{response.author?.department}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="news-section">
    <div class="news-announce">Сопроводительное письмо</div>
    <div class="news-body">
      <app-message [text]="response.description"></app-message>
    </div>
  </div>
  <div class="news-section">
    <app-attachments-list [attachments]="attachments" [elementType]="elementType.VacancyResponse"></app-attachments-list>
  </div>
  <div class="news-section" *ngIf="authorManager">
    <div class="news-announce">Текущий руководитель</div>
    <div class="news-body">
      <div class="co">
        <div class="co__avatar">
          <app-user-avatar [user]="authorManager" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'">
          </app-user-avatar>
        </div>
        <div class="co__info">
          <a href="#" class="link co__name" routerLink="/profile/{{authorManager?.id}}"
            (click)="usersService.navigateProfile(authorManager?.id)"
            [appUserTooltip]="authorManager?.id">{{authorManager?.fullName}}</a>
          <div class="co__occ" *ngIf="authorManager?.jobTitle">{{authorManager?.jobTitle}}</div>
        </div>
      </div>
    </div>
  </div>
</div>