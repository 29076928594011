
import { takeUntil, take } from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FilesService } from '@app/files/services/files.service';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { FilesTreeComponent } from '@app/files/components/files-tree/files-tree.component';
import { Helper } from '@app/core/helpers/helper';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { FileShareComponent } from '@app/files/components/file-share/file-share.component';
import { FilesRenameComponent } from '@app/files/components/files-rename/files-rename.component';
import { FilesListItem, PermMask } from '@app/files/model/files-list-item.model';
import { AlertsService } from '@app/shared/services/alerts.service';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import { FileHistoryComponent } from '@app/files/components/file-history/file-history.component';
import { SearchResultItem } from '@app/search/model/search-result-item.model';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { UsersService } from '@app/profile/services/users.service';

export interface IFileMenuConfig {
    allowShare?: boolean;
    allowRename?: boolean;
    allowMove?: boolean;
    allowDelete?: boolean;
}

/**
 * Компонент меню файла
 *
 * @export
 * @class FileMenuComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
    selector: 'app-file-menu',
    templateUrl: 'file-menu.component.html'
})
export class FileMenuComponent extends BaseComponent implements OnInit, OnDestroy {
    @Input() item: FilesListItem;
    @Input() isCurrentUser: boolean;
    // list guid
    @Input() listId: string;
    @Input() portalUrl: string;

    @Input() itemLink: any;

    @Output() onDeleted: EventEmitter<any> = new EventEmitter();
    @Output() onMoved: EventEmitter<any> = new EventEmitter();
    @Output() onCopied: EventEmitter<any> = new EventEmitter();
    @Output() onRenamed: EventEmitter<any> = new EventEmitter();

    @Input() config: IFileMenuConfig = {};

    @Input() webUrl: Subject<string> = new BehaviorSubject<string>(null);
    @Input() folderUrl: Subject<string> = new BehaviorSubject<string>(null);
    @Input() rootFolderUrl: Subject<string> = new BehaviorSubject<string>(null);

    @ViewChild(MatMenuTrigger, { static: true }) trigger: MatMenuTrigger;
    @ViewChild('menuContainer', { static: true }) menu: ElementRef;

    @Input() selectedItems: FilesListItem[];

    deletingDialog: MatDialogRef<ModalConfirmationComponent>;
    filesTreeDialog: MatDialogRef<FilesTreeComponent>;
    historyDialog: MatDialogRef<FileHistoryComponent>;

    private url: string;

    constructor(
        protected usersService: UsersService,
        protected filesService: FilesService,
        protected dialog: MatDialog,
        protected alertsService: AlertsService,
        public helper: HelperService) { super(helper); }

    ngOnInit() {
        this.item.link = this.itemLink;
        if (!this.selectedItems || !this.selectedItems.length) {
            this.selectedItems = [this.item];
        }

        if (this.webUrl) {
            this.webUrl.subscribe(webUrl => {
                this.url = webUrl;
            });
        }
    }

    // немного костыльный код открытия файлов
    // todo: проверить в MAC-OS
    openInClientApp(item: any) {
        if (this.webUrl) {
            this.webUrl.subscribe(resUrl => {
                const url = Helper.concatUrls(this.portalUrl, item.url);
                this.openInClientAppByAbsUrl(item, url);
            });
        } else {
            this.openInClientAppByAbsUrl(item, item.url);
        }
    }

    /**
     * Скачать файл
     *
     * @memberof FileMenuComponent
     */
    getItem() {
        if (this.webUrl) {
            this.webUrl
                .pipe(takeUntil(this.destroyed$))
                .subscribe(resUrl => {
                    const itemUrl = this.item.url;

                    if (itemUrl && itemUrl.startsWith('http')) {
                        this.getItemByAbsUrl(itemUrl, this.item.name);
                    } else {

                        let url =  Helper.concatUrls(
                            Helper.concatUrls(resUrl, '/_layouts/15/download.aspx?SourceUrl='),
                            itemUrl
                        );

                        if (url.startsWith('/')) {
                            url = Helper.concatUrls((<any>window).signalRServer, url);
                        }

                        this.getItemByAbsUrl(url, this.item.name, false);
                    }
                });
        } else if (this.item instanceof SearchResultItem) {
            // this for search
            const elem = (<SearchResultItem>this.item);
            this.getItemByAbsUrl(elem.url, elem.fileName);
        }
    }

    isShowShare() {
        return this.isShowEdit() && this.config.allowShare;
    }

    isShowDelete() {
        return this.isShowEdit() && this.config.allowDelete;
    }

    isShowMove() {
        return this.isShowEdit() && this.config.allowMove;
    }

    isShowRename() {
        return this.isShowEdit() && this.config.allowRename;
    }

    isShowHistory() {
        return this.isShowEdit() && this.config.allowDelete && !this.filesService.isFolder(this.item);
    }

    // TODO: https://stackoverflow.com/questions/49129470/confusing-permmask-values-in-sharepoint-online
    isShowEdit() {
        return this.config && this.item
            && (this.item.permMask === PermMask.AddListItems
                || this.item.permMask === PermMask.ApproveItems
                || this.item.permMask === PermMask.DeleteListItems
                || this.item.permMask === PermMask.EditListItems
                || this.item.permMask === PermMask.ManageLists
                || this.item.permMask === PermMask.FullMask
                || this.item.permMask === PermMask.Edit
                || this.item.permMask === PermMask.Design
                || this.item.permMask === PermMask.Input);
    }

    private getItemByAbsUrl(url: string, fileName: string, targetBlank: boolean = true) {
        const a = document.createElement('a');
        a.href = url; // xhr.response is a blob
        a.download = fileName; // Set the file name.
        a.style.display = 'none';
        if (targetBlank) {
            a.target = '_blank';
        }
        document.body.appendChild(a);
        a.click();
    }

    private multipleAllFinished(item, e: EventEmitter<any>, success: boolean) {
        item.finished = true;
        item.success = success;
        if (!this.selectedItems.filter(f => !(<any>f).finished).length) {
            if (e != null) {
                e.emit(this.selectedItems.filter(i => (<any>i).success));
            }
        }
    }

    // deleting items
    private deleteItem() {
        this.webUrl
            .pipe(take(1), takeUntil(this.destroyed$))
            .subscribe(resUrl => {
                this.selectedItems.forEach(item => {
                    console.log(`deleting, name: '${resUrl}', item.id: ${item.id}`);
                    this.filesService.webApiDeleteItem(resUrl, item).subscribe(res => {
                        console.log('deleted');
                        this.multipleAllFinished(item, this.onDeleted, true);
                        this.deletingDialog.close();
                        this.alertsService.riseSuccess(!this.filesService.isFolder(item) ?
                            `Файл ${item.name} был успешно удалён`
                            : `Папка ${item.name} была успешно удалёна`);
                    }, error => {
                        this.multipleAllFinished(item, this.onDeleted, false);
                        const errorText = `Произошла ошибка при удалении файла${Helper.getErrorMsg(error)}`;
                        console.log(`${errorText}: '${error}'`);
                        if (this.selectedItems.length > 1) {
                            this.alertsService.riseError(errorText);
                        } else {
                            this.deletingDialog.componentInstance.data.error = errorText;
                        }
                    });
                });
            });
    }

    private moveItem(result: any) {
        this.webUrl
            .pipe(take(1), takeUntil(this.destroyed$))
            .subscribe(resUrl => {
                this.selectedItems.forEach(item => {
                    const newUrl = Helper.concatUrls(result.folderUrl, item.name);
                    let observable: Observable<any>;
                    let text = '';
                    if (!this.filesService.isFolder(item) && !this.filesService.isOneNote(item)) {
                        text = 'Файл был перемещён';
                        observable = this.filesService.webApiMoveFile(resUrl,
                            Helper.concatUrls(result.portalUrl, item.url),
                            Helper.concatUrls(result.portalUrl, newUrl));
                    } else {
                        text = this.filesService.isOneNote(item)
                            ? 'Файл был перемещён'
                            : 'Папка была перемещёна';
                        observable = this.filesService.webApiMoveFolder(resUrl,
                            Helper.concatUrls(result.portalUrl, item.url),
                            Helper.concatUrls(result.portalUrl, newUrl));
                    }

                    observable.pipe(take(1)).subscribe(moved => {
                        this.alertsService.riseSuccess(text);
                        this.filesTreeDialog.close();
                        this.multipleAllFinished(item, this.onMoved, true);
                    }, error => {
                        this.multipleAllFinished(item, this.onMoved, false);
                        const errorText = `Произошла ошибка при перемещении${Helper.getErrorMsg(error)}`;
                        this.filesTreeDialog.componentInstance.disabled = false;
                        console.log(errorText, error);
                        if (this.selectedItems.length > 1) {
                            this.alertsService.riseError(errorText);
                        } else {
                            this.filesTreeDialog.componentInstance.data.error = errorText;
                        }
                    });
                });
            });
    }

    private copyItem(result: any) {
        this.webUrl.subscribe(resUrl => {
            this.selectedItems.forEach(item => {
                const newUrl = Helper.concatUrls(result.folderUrl, item.name);
                let observable: Observable<any>;
                let text = '';
                if (!this.filesService.isFolder(item) && !this.filesService.isOneNote(item)) {
                    text = 'Файл был скопирован';
                    observable = this.filesService.webApiCopyFile(resUrl,
                        Helper.concatUrls(result.portalUrl, item.url),
                        Helper.concatUrls(result.portalUrl, newUrl));
                } else {
                    text = this.filesService.isOneNote(item)
                        ? 'Файл был скопирован'
                        : 'Папка была скопирована';
                    observable = this.filesService.webApiCopyFolder(resUrl,
                        Helper.concatUrls(result.portalUrl, item.url),
                        Helper.concatUrls(result.portalUrl, newUrl));
                }

                observable.pipe(take(1)).subscribe(moved => {
                    this.alertsService.riseSuccess(text);
                    this.filesTreeDialog.close();

                    if (this.onCopied) {
                        this.onCopied.emit(this.item);
                    }
                }, error => {
                    const errorText = `Произошла ошибка при копировании${Helper.getErrorMsg(error)}`;
                    console.log(errorText, error);
                    this.filesTreeDialog.componentInstance.disabled = false;
                    if (this.selectedItems.length > 1) {
                        this.alertsService.riseError(errorText);
                    } else {
                        this.filesTreeDialog.componentInstance.data.error = errorText;
                    }
                });
            });
        });
    }

    openMoveItem() {
        this.openTreeItem(this.moveItem.bind(this), 'Переместить сюда', 'Выберите папку для перемещения', true);
    }

    openCopyItem() {
        this.openTreeItem(this.copyItem.bind(this), 'Копировать сюда', 'Выберите папку для копирования', true);
    }

    private openTreeItem(func: Function, okText: string, title: string, excludeCurrentFolder: boolean) {
        this.filesTreeDialog = this.dialog.open(FilesTreeComponent, {
            data: {
                item: this.item,
                skipCurrent: true,
                foldersOnly: true,
                webUrl: this.webUrl,
                folderUrl: this.folderUrl,
                rootFolderUrl: this.rootFolderUrl,
                onOk: func,
                okText: okText,
                headerText: title,
                excludeCurrentFolder: excludeCurrentFolder
            }
        });
    }

    // deleting items
    showDeleteItem() {
        let text;
        if (this.selectedItems && this.selectedItems.length > 1) {
            text = 'элементы?';
        } else {
            text = this.filesService.isFolder(this.item) ? 'папку?' : 'файл?';
        }
        this.deletingDialog = this.dialog.open(ModalConfirmationComponent, {
            data: {
                text: `Вы действительно хотите удалить ${text}`,
                onOk: this.deleteItem.bind(this),
                doNotHideOnOk: true
            }
        });
    }

    // history items
    showHistoryItem() {
        this.historyDialog = this.dialog.open(FileHistoryComponent, {
            data: {
                item: this.item,
                webUrl: this.webUrl
            }
        });
    }

    // sharing items
    openShareItem() {
        this.dialog.open(FileShareComponent, {
            data: {
                url: this.webUrl,
                placeholder: 'Введите имя или почту, чтобы поделиться',
                items: [this.item],
                listId: this.listId
            }
        });
    }

    // renaming items
    showRenameItem() {
        this.dialog.open(FilesRenameComponent, {
            data: {
                webUrl: this.webUrl,
                listUrl: this.rootFolderUrl,
                item: this.item,
                onRenamed: this.onRenamed
            }
        });
    }

    /**
     * Копировать ссылку на элемент
     *
     * @memberof FileMenuComponent
     */
    copyToClipboard() {
        const item = (<any>this.item);
        const elem = <any>document.getElementById(this.itemLink);

        if (item.isFolder) {
            if (item.isPersonal) {
                if (item.editorLogin) {
                    this.usersService.getExistingUserByLogin(item.editorLogin)
                        .pipe(takeUntil(this.destroyed$))
                        .subscribe(user => {
                            if (user) {
                                const folderPath = item.url.substring(item.url.indexOf('/personal/'));
                                elem.href = `${this.helper.environment.startPage}/profile/${user.id}/files#files&folder=${folderPath}`;
                            }
                        });
                }
            } else if (item.isGroup) {
                const index = item.url.indexOf('/groups/group');
                const folderPath = item.url.substring(index);

                let numberString = item.url.substring(index + 13);
                numberString = numberString.substring(0, numberString.indexOf('/'));

                elem.href = `${this.helper.environment.startPage}/group/${numberString}/files#files&folder=${folderPath}`;
            } else {
                elem.href = item.url;
            }
        }
        this.filesService.copyToClipboard(elem.href);
    }

    show(event) {
        this.trigger.openMenu();
        event.preventDefault();
        event.stopImmediatePropagation();
        event.cancleBubble = true;
        return false;
    }

    getOfficeOpeningName(item: any) {
        return this.filesService.getOfficeOpeningName(item);
    }

    openInClientAppByAbsUrl(item: FilesListItem, url: string) {
        return this.filesService.openInClientAppByAbsUrl(item, url);
    }
}
