import {Component, OnInit, OnDestroy} from '@angular/core';
import {SurveysService} from '@app/surveys/services/surveys.service';
import {ISurvey} from '@app/surveys/model/survey';
import {Observable} from 'rxjs';
import {BreadcrumbsService} from '@app/shared/services/breadcrumbs.service';
import {HelperService} from '@app/core/services/helper.service';
import {takeUntil, take} from 'rxjs/operators';
import {TabbedListComponent} from '@app/shared/components/tabs/tabbed-list.component';
import {Router, ActivatedRoute} from '@angular/router';
import {Tab} from '@app/shared/components/tabs/tab.model';

/**
 * Компонент списка опросов
 *
 * @export
 * @class SurveyListComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-survey-list',
  template: '',
})
export class SurveyListComponent
  extends TabbedListComponent<ISurvey>
  implements OnInit, OnDestroy
{
  showAddButton: boolean;
  filtersVisible: boolean;

  protected maxItemHeight = 84;

  constructor(
    protected surveysService: SurveysService,
    protected breadcrumbsService: BreadcrumbsService,
    protected router: Router,
    protected route: ActivatedRoute,
    public helper: HelperService,
  ) {
    super(router, route, helper);
  }

  ngOnInit() {
    // find what has is now and route navigate with hash
    this.route.fragment.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      // get hash
      if (val != null) {
        const keys = val.split('&');
        const hash = {};
        keys.forEach(key => {
          // tslint:disable-next-line:no-shadowed-variable
          const val = key.split('=');
          hash[val[0]] = val[1];
        });

        // очищаем текущий табик
        this.currentTab = null;

        this.tabs.items.forEach(tab => {
          const fragment = keys.find(k => k === tab.fragment);

          if (tab.fragment && fragment) {
            const currentTabId = this.currentTab ? this.currentTab.id : null;
            this.currentTab = tab;
            if (currentTabId !== tab.id) {
              this.cleanTab(this.currentTab);
            }
          }
        });

        if (!this.currentTab) {
          this.currentTab = this.tabs.items.find(tab => !tab.fragment || tab.default);
        }
      } else {
        this.currentTab = this.tabs.items.find(tab => !tab.fragment || tab.default);
      }

      // получить данные по текущему табику
      this.cleanTab(this.currentTab);
      this.currentTab.method(this.currentTab, this);
    });
  }

  isShowMenu(): boolean {
    return false;
  }

  isShowFilters(): boolean {
    return false;
  }

  showFilters() {}

  protected operateSurveys(tab: Tab<ISurvey>, method: Observable<ISurvey[]>) {
    tab.loading = true;
    method.pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        if (res && res.length) {
          res.forEach(item => {
            if (!tab.items.find(vacancy => vacancy.id === item.id)) {
              tab.items.push(item);
            }
          });
          tab.offset = tab.items.length;
        } else {
          tab.allLoaded = true;
        }
        this.loaded = tab.loaded = true;
        tab.loading = false;
      },
      error => {
        this.loaded = tab.loaded = true;
        tab.loading = false;
      },
    );
  }

  protected onScroll() {
    const number = window.innerHeight + window.pageYOffset + 20;
    if (
      this.currentTab &&
      this.currentTab.items &&
      number > this.maxItemHeight * this.currentTab.items.length
    ) {
      if (!this.currentTab.loading && !this.currentTab.allLoaded) {
        this.currentTab.method(this.currentTab, this);
      }
    }
  }
}
