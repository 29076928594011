import { Injectable } from '@angular/core';
import { User } from '@app/profile/model/user.model';
import { BehaviorSubject } from 'rxjs';
import { ITag } from '../model/tag';

@Injectable({
  providedIn: 'root'
})
export class EditorService {

  public bodyEditorChanged: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor() { }

  createMentionNode(user: User) {
    const container = document.createElement('span');
    container.setAttribute('contentEditable', 'false');
    container.setAttribute('style', 'display:inline');
    container.setAttribute('userid', user.id.toString());
    container.className = 'mention';
    const tag = document.createElement('a');
    tag.setAttribute('target', '_blank');
    tag.setAttribute('userid', user.id.toString());
    tag.setAttribute('href', `/default.aspx/profile/${user.id}`);
    tag.innerText = user.fullName;
    container.appendChild(tag);
    return container;
  }

  createTagNode(tag: ITag) {
    const container = document.createElement('span');
    container.setAttribute('contentEditable', 'false');
    container.setAttribute('style', 'display:inline');
    container.setAttribute('tagid', tag.name);
    container.className = 'tag';
    const htmlTag = document.createElement('a');
    htmlTag.setAttribute('target', '_blank');
    htmlTag.setAttribute('tagid', tag.name);
    htmlTag.setAttribute('href', `/default.aspx/search#feed&k=#${tag.name}`);
    htmlTag.innerText = '#' + tag.name;
    container.appendChild(htmlTag);
    return container;
  }

  /**
   * Преобразовать текст для тэгов
   *
   * @private
   * @param {string} html Исходная html-разметка
   * @param {string} result Результат
   */
  getTags(html: string, result: string, currentUser: User): string {
    // check tags
    if (!result) {
      result = '';
    }

    const patt = /\B#([a-zA-Z\u0400-\u04ff_0-9][a-zA-Z\d\u0400-\u04ff_0-9]*)/gi;
    const tags = html.match(patt);
    if (tags && tags.length > 0) {
      let match;
      const indexes = [];
      // save start & end indexes for all matches
      while (match = patt.exec(html)) {
        indexes.push({ start: match.index, end: patt.lastIndex });
      }

      // replace matches
      for (let i = 0; i < indexes.length; i++) {
        const start = indexes[i].start;
        const end = indexes[i].end;
        let text = '';
        if (i === 0) {
          text = html.substr(0, start);
        } else {
          const lastIndex = indexes[i - 1].end;
          text = html.substr(lastIndex, start - lastIndex);
        }

        result += text;

        // сохраняем в переменную текст, опознанный как тег
        const tag = html.substr(start, end - start);
        // текст до #
        const textBefore = start < 7 ? '' : html.substr(start - 7, 7);

        // если тег был найден - нужно преобразовать его в ссылку
        if (tag && tag.length > 1
          && textBefore.indexOf('/search') === -1
          && textBefore.indexOf('feed&k=') === -1) {
          const link = this.createTagNode({ name: tag.slice(1) }).outerHTML;

          // append space char
          if (result.length > 0 && result[result.length - 1] !== '') {
            result += ' ';
          }
          result += link;
        } else {
          result += tag;
        }
      }

      // append end of text
      if (indexes.length > 0 && indexes[indexes.length - 1].end < html.length) {
        result += html.substr(indexes[indexes.length - 1].end);
      }

    } else {
      result += html;
    }

    return result;
  }
}
