<div class="card _vacation _preloader" *ngIf="!loaded">
  <div class="card__title">
    <span class="preloader _white">
      <span class="preloader__line"></span>
    </span>
  </div>
  <div class="card__content">
    <span class="preloader _white _column" *ngFor="let r of [0,1,2]">
      <span class="preloader__line"></span>
    </span>
  </div>
</div>

<div class="card _vacation" *ngIf="loaded" [ngClass]="{'_no-data': !vacations?.length}">
  <div class="card__title">
<!--    <a *ngIf="isCurrent" [attr.href]="link" target="_blank" title="Заявление на отпуск" class="btn btn-download pull-right">+</a>-->
    <div class="header-text">
      <span class="header-text__text">Отпуск</span>&nbsp;
      <span class="header-text__count" *ngIf="isCurrent && this.vacationDaysSign">{{this.vacationDaysSign}} осталось</span>
    </div>
  </div>
  <div class="card__content">
    <div class="vacation-wrap" *ngIf="vacations?.length">
      <div class="vacation">
        <div class="vacation__item" *ngFor="let vacation of vacations">
          {{vacation.start}} – {{vacation.end}}
        </div>
      </div>
    </div>
    <span *ngIf="!vacations?.length">
      Пока нет сведений об отпуске
    </span>
  </div>
</div>
