export class FeedEvent {
    public id: number;
    public ownerType: number;
    public ownerId: number;
    public authorId: number;
    public created: Date;
    public body: string;
    public title?: string;
    public announce?: string;
    public eventType: number;
    public data: any;
    public isSystem: boolean;
    public disableComment?: boolean;
    public disableLike?: boolean;
    public pin?: boolean;
    public pinDateEnd?: Date;
    public isDraft?: boolean;
    public repostId?: number;
    public showOnMainPage?: boolean;
}

export interface INewFeedEventBody {
    text?: string;
    disableComment?: boolean;
    disableLike?: boolean;
    title?: string;
    announce?: string;
    pinEvent?: boolean;
    pinDateEnd?: Date;
    isNews?: boolean;
    isNewEmployee?: boolean;
    isDraft?: boolean;
    publishDate?: Date;
    repostId?: number;
    showOnMainPage?: boolean;
}

export enum EventType {
    // События привязанные к пользователю
    UserNewPost = 1, // Пользователь создал новый пост
    UserNewSubscription = 2, // Пользователь подписался на другого пользователя // TODO: временно
    UserPhotoUpload = 3, // Пользователь загрузил фото
    UserDocUpload = 4, // Пользователья загрузил документ
    UserProfileEdit = 6, // Пользователь отредактировал свой профиль
    // UserCommentedPost = 7, // Пользователь прокомментировал пост
    // UserCommentedComment = 8, // Пользователь прокомментировал коммент
    // UserLikedPost = 9, // Пользователь лайкнул пост
    // UserLikedComment = 10, // Пользователь лайкнул пост
    // UserNewMention = 11, // Пользователь упомянул другого пользователя в посте
    UserNewsPost = 12, // Пользователь сменил свою фотку
    UserAvatarChanged = 13, // Пользователь сменил свою фотку
    UserWasMentioned = 14, // Пользователь был упомянут
    UserAnniversary = 15, // У пользователя юбилей

    Repost = 50, // Ре пост

    // События привязанные к группе
    GroupNewUser = 100, // Пользователь был добавлен в группу
    GroupNewPost = 101, // Новый пост в группе
    GroupPhotoUpload = 102, // Фото было загружено в группу
    GroupDocUpload = 103, // Документ был загружен в группу
    GroupNameChanged = 105, // Название группы было изменено
    GroupDescChanged = 106, // Описание группы было изменено
    GroupAvatarChanged = 107, // Аватар был изменён
    GroupTypeChanged = 108, // Тип группы был изменён
    GroupUserRemoved = 109, // Пользователь был удалён из группы
    GroupUserJoin = 110, // Пользователь вступил в группу
    GroupUserLeave = 111, // Пользователь вышел из группы
    UserNewGroup = 112, // Пользователь создал новую группу
    GroupNewsPost = 113, // Пользователь создал новый новостной пост

    // бизнес-группы
    BusinessGroupNewPost = 150, // новый пост в бизнес-группе
    BusinessGroupNewsPost = 151, // новый новостной пост в бизнес-группе

    BusinessGroupNewEmployeePost = 160, // новый сотрудник (новость в бизнес-группе)

    // События системы
    SystemUserProfileEdit = 200 // Система изменила профиль пользователя
}
