import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { IAward } from '@app/awards/model/awards';
import { AwardsService } from '@app/awards/services/awards.service';
import { UsersService } from '@app/profile/services/users.service';
import { takeUntil, finalize } from 'rxjs/operators';
import { User } from '@app/profile/model/user.model';

/**
 * Награды пользователя
 *
 * @export
 * @class UserAwardsComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-user-awards',
  templateUrl: './user-awards.component.html',
  styleUrls: ['./user-awards.component.scss']
})
export class UserAwardsComponent extends BaseComponent implements OnInit, OnDestroy {

  /**
   * Массив наград пользователя
   *
   * @type {IAward[]}
   * @memberof UserAwardsComponent
   */
  awards: IAward[];

  awardsCount: number;

  /**
   * Текущий пользователь администратор наград?
   *
   * @type {boolean}
   * @memberof UserAwardsComponent
   */
  isAdmin: boolean;

  constructor(
    public usersService: UsersService,
    public awardsService: AwardsService,
    public helper: HelperService
  ) { super(helper); }

  ngOnInit() {
    this.usersService.user.pipe(takeUntil(this.destroyed$)).subscribe(user => {
      if (user) {
        this.getAwards(user);
      }
    });

    this.awardsService.isAdmin().pipe(takeUntil(this.destroyed$)).subscribe(isAdmin => {
      this.isAdmin = isAdmin;
    });
  }

  protected getAwards(user: User) {
    this.awardsService.getUserAwards(user.id)
      .pipe(takeUntil(this.destroyed$), finalize(() => this.loaded = true))
      .subscribe(awards => {
        if (awards) {
          this.awardsCount = awards.length;
          // отображаются только первые 3, начиная с последних добавленных
          this.awards = awards
            .sort((a, b) => b.id - a.id)
            .slice(0, 3);
        }
      });
  }

  showAwards(): boolean {
    return this.loaded;
  }
}
