<div class="cos">
  <div class="cos__header">
    <app-tabs [tabs]="tabs" [hideCounters]="true"></app-tabs>
  </div>
  <!--Разметка пользователей-->
  <div class="cos__body" *ngIf="isVisible('colleagues')">
    <app-user-simple-card [link]="link" *ngFor="let link of currentTab?.items"></app-user-simple-card>
  </div>
  <!--Разметка пользователей END-->
  <!--Разметка файлов-->
  <div class="cos__body" *ngIf="isVisible('files')">
    <app-files-search-list [items]="currentTab.items" [allowSort]="true" [sort]="sort" [sortBy]="sortBy"
      [sortTitle]="sortTitle" [sortAsc]="sortAsc" [sortField]="sortField" (sortItems)="sortSearchTab($event)" [highlightText]="highlightText">
    </app-files-search-list>
  </div>
  <!--  Разметка файлов END -->
  <!--Разметка групп-->
  <div class="cos__body" *ngIf="isVisible('groups')">
    <!-- класс '_inactive' вешать при отписке и снимать при подписке обратно-->
    <!-- класс '_red' вешать при текущем запросе на вступление-->
    <div class="co" *ngFor="let group of currentTab?.items" (mouseover)="groupMenu.checkVisibility()">
      <div class="co__avatar">
        <span class="avatar _80" [attr.title]="group.title" [attr.data-initials]="groupsService.getInitials(group)"
          routerLink="/group/{{group.id}}">
          <img class="avatar__image" *ngIf="group.pictureUrl" [attr.src]="group.pictureUrl"
            (error)="group.pictureUrl=null">
        </span>
      </div>
      <div class="co__info">
        <a class="link co__name" routerLink="/group/{{group.id}}" [innerHTML]="group.title | highlight: text"></a>
        <div class="co__occ">
          <span>{{groupsService.getGroupTypeNameByEnum(group.type)}} группа</span>,
          <span>{{group.usersCount}}&nbsp;</span>
          <span>{{groupsService.getUsersCountText(group)}}</span>
        </div>
        <div class="co__occ">{{groupsService.getUserRoleByEnum(group)}}</div>
      </div>
      <button type="button" class="btn tooltip">
        <app-group-menu #groupMenu [user]="user" [group]="group" [type]="1" [hideDelete]="true"></app-group-menu>
      </button>
    </div>
  </div>
  <!--Разметка групп END -->
  <!--Разметка ленты-->
  <div class="cos__body" *ngIf="isVisible('feed')">
    <ng-container *ngFor="let post of currentTab.items">
      <app-post [post]="post" [feedType]="2" [highlightText]="highlightText" [showBody]="false"></app-post>
    </ng-container>
  </div>
  <!--Разметка ленты END -->
  <!--Разметка галереи-->
  <div class="cos__body" *ngIf="isVisible('gallery')">
    <app-search-album-results [albums]="currentTab?.data?.albums" [albumsLoaded]="currentTab?.data?.albumsLoaded"
      (showMoreEvent)="onAlbumsShowMore($event)" (hideMoreEvent)="onAlbumsHideMore($event)"></app-search-album-results>
    <app-search-media-result [title]="'Найденные медиафайлы'" [mediaFiles]="currentTab?.data?.mediaFiles">
    </app-search-media-result>
  </div>
  <!--Разметка галереи END -->
  <!-- Пустая выдача -->
  <div class="cos__body" *ngIf="showEmptyBlock()">
    <div class="group-empty">
      <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" class="group-empty__img">
      <div class="group-empty__text">Нет результатов.
        <br> Попробуйте уточнить запрос.</div>
    </div>
  </div>
  <!-- пустая выдача END -->
  <!-- Меньше 2 символов -->
  <div class="cos__body" *ngIf="currentTab && currentTab.loaded && (!text || text?.length<2)">
    <div class="group-empty">
      <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" class="group-empty__img">
      <div class="group-empty__text">
        Необходимо ввести не менее двух символов.
      </div>
    </div>
  </div>
  <!-- Меньше 2 символов END -->
  <!-- body preloader -->
  <div class="cos__body" *ngIf="currentTab?.id==2 && !currentTab?.loaded">
    <span class="preloader _square _co" *ngFor="let r of [0,1,2,3,4,5]">
      <span class="preloader__side"></span>
      <span class="preloader__line"></span>
    </span>
  </div>
  <!-- header preloader -->
  <app-tabbed-list-preloader *ngIf="currentTab?.id!=2 && !currentTab?.loaded"></app-tabbed-list-preloader>
  <!--  header preloader END -->
</div>

<!--Разметка разметка контентных страниц -->
<ng-container *ngIf="isVisible('pages')">
  <app-search-other-results [pages]="currentTab.items" [highlightText]="text"></app-search-other-results>
</ng-container>
<!--Разметка разметка контентных страниц END -->
