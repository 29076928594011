import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FilesService } from '@app/files/services/files.service';
import { FilesListItem } from '@app/files/model/files-list-item.model';
import { BaseComponent } from '@app/core/components/base.component';
import { UsersService } from '@app/profile/services/users.service';
import { take } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { IFileMediaViewData } from '@app/files/model/file-media-view-data';

/**
 * Копонент просморта фото/видео в списке файлов
 *
 * @export
 * @class FileMediaViewComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-file-media-view',
  templateUrl: './file-media-view.component.html',
  styleUrls: ['./file-media-view.component.scss'],
  providers: [UsersService]
})
export class FileMediaViewComponent extends BaseComponent {

  /**
   * Текущий файл
   *
   * @type {FilesListItem}
   * @memberof FileMediaViewComponent
   */
  file: FilesListItem;
  files: FilesListItem[];

  /**
     * Конфигурация слайдера-карусели
     *
     * @memberof MediaFileViewComponent
     */
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0
  };

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: IFileMediaViewData,
    public filesService: FilesService,
    public usersService: UsersService,
    public helper: HelperService) {

    super(helper);

    // Если массив медиа-файлов пустой (т.е. в блоке есть только 1 файл),
    // то вносим его в массив.
    if (!data.items) {
      if (data.item) {
        data.items = [data.item];
      }
    }

    // указываем текущий медиа-файл и массив медиа-файлов
    if (data.items) {
      this.files = data.items;
      if (data.items && data.items.length && data.item) {
        if (data.items[0].id !== data.item.id) {
          this.file = data.items.find(s => s.id === data.item.id);
          this.slideConfig.initialSlide = this.files.indexOf(this.file);
        } else {
          this.file = data.item;
        }
      }
    }

    if (this.file && !this.file.fileUser) {
      this.file.fileUser = this.getFileEditor(this.file);
    }
  }

  /**
   * Получить заголовок файла. Если заголовка нет, то возвращается название файла
   *
   * @param {FilesListItem} file
   * @returns
   * @memberof MediaFileViewComponent
   */
  getFileTitle(file: FilesListItem) {
    if (file) {
      if (file.title) {
        return file.title;
      } else {
        return file.name;
      }
    }
    return '';
  }

  /**
   * После изменения текущего элемента слайда
   *
   * @param {*} e
   * @memberof MediaFileViewComponent
   */
  onAfterChange(e: any): void {
    const currentSlide: number = e.currentSlide;
    if (this.files
      && this.files.length
      && this.files.length >= currentSlide) {
      // append current mediafile
      this.file = this.files[currentSlide];
      // get file editor
      this.file.fileUser = this.getFileEditor(this.file);
    } else {
      console.error(`problems with current file`);
      this.file = null;
    }
  }

  /**
   * Получение автора (редактора) файла
   *
   * @param {FilesListItem} file
   * @returns
   * @memberof FileMediaViewComponent
   */
  getFileEditor(file: FilesListItem) {
    if (!file.fileUser
      && file.editor
      && file.editor.length) {
      const id = +file.editor[0].id;

      // для получения url сайта
      let webUrl = '';
      let index = file.url.search(/\/Shared Documents/i);
      if (index === -1) {
        index = file.url.search(/\/Documents/i);
      }
      if (index !== -1) {
        webUrl = file.url.substr(0, index);
      }

      this.usersService.getExistingUserBySharePoint(webUrl, id)
        .pipe(take(1))
        .subscribe(res => {
          if (res) {
            this.file.fileUser = res;
          } else {
            this.file.fileUser = null;
          }
        });
    }
    return file.fileUser || null;
  }
}
