export enum ShareType {
    Read = 1073741826,
    Write = 1073741827
}

export class ListItemShareBody {
    // absolute url
    url: string;
    peoplePickerInput: string;
    roleValue: string;
    sendEmail: boolean;
    emailBody: string;
    includeAnonymousLinkInEmail: boolean;
    propagateAcl: boolean;
}

export class PeoplePickerInput {
    // domain\\user
    Key: string;
    EntityType = 'User';
    ProviderDisplayName = 'Active Directory';
    ProviderName = 'AD';
    IsResolved = true;
    EntityData: EntityData;
}

export class EntityData {
    Email: string;
    PrincipalType = 'User';
}
