<ng-container *ngIf="user && user?.absenceType && user?.absenceDateStart">
  <div class="absence" [ngClass]="usersService.getAbsenceCss(user)" *ngIf="!isLightMode">
    <span>{{usersService.getAbsenceText(user)}}</span>
  </div>
  <div class="absence _light"
    *ngIf="isLightMode"
    [ngClass]="usersService.getAbsenceCss(user)"
    [matTooltip]="usersService.getAbsenceText(user)"
  >
    <span>{{usersService.getAbsenceText(user)}}</span>
  </div>
</ng-container>