import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges, NgZone, ChangeDetectorRef } from '@angular/core';
import { MediaListComponent } from '@app/gallery/components/lists/media-list/media-list.component';
import { UsersService } from '@app/profile/services/users.service';
import { GalleryService } from '@app/gallery/services/gallery.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { AlertsService } from '@app/shared/services/alerts.service';
import { GroupsService } from '@app/groups/services/groups.service';
import { takeUntil, take, finalize } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { SignalRService } from '@app/signalr/signalR.service';
import { IAlbum } from '@app/gallery/model/album-model';
import { IMediaFile } from '@app/gallery/model/media-file';
import { Tab, Tabs } from '@app/shared/components/tabs/tab.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from '@app/core/helpers/helper';
import { IMediaFileMenuConfig } from '@app/gallery/model/media-file-menu-config';
import { ProfileType } from '@app/core/model/profile-type.enum';
import { MediaFileUploadV2Service } from '@app/shared/services/media-file-upload-v2.service';

/**
 * Список медиа-файлов группы
 *
 * @export
 * @class GroupMediaListComponent
 * @extends {MediaListComponent}
 * @implements {OnInit}
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-group-media-list',
  templateUrl: './group-media-list.component.html',
  styleUrls: ['./group-media-list.component.scss'],
})
export class GroupMediaListComponent extends MediaListComponent implements OnInit, OnChanges, OnDestroy {
  album: IAlbum;
  tabs: Tabs<IMediaFile>;
  currentTab: Tab<IMediaFile>;
  votingStartDate: Date;
  votingEndDate: Date;
  votesCount: number;

  constructor(
    public groupsService: GroupsService,
    public usersService: UsersService,
    public galleryService: GalleryService,
    public sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public alertsService: AlertsService,
    public signalRService: SignalRService,
    public mediaFileUploadService: MediaFileUploadV2Service,
    public ngZone: NgZone,
    public route: ActivatedRoute,
    public router: Router,
    public cdr: ChangeDetectorRef,
    public helper: HelperService,
  ) {
    super(
      usersService,
      groupsService,
      galleryService,
      sanitizer,
      dialog,
      alertsService,
      signalRService,
      mediaFileUploadService,
      ngZone,
      route,
      cdr,
      helper,
    );
  }

  ngOnInit() {

    // обработка удаления альбома
    this.galleryService.albumDeleted.pipe(takeUntil(this.destroyed$)).subscribe(id => {
      if (id && this.mediaFiles && this.currentTab && this.currentTab.items) {
        this.mediaFiles = this.mediaFiles.filter(s => s.album && s.album.id !== id);
        this.currentTab.items = this.currentTab.items.filter(s => s.album && s.album.id !== id);
      }
    });

    // обработка удаления медиа-файла
    this.galleryService.mediaFileDeleted.pipe(takeUntil(this.destroyed$)).subscribe(id => {
      if (id && this.mediaFiles && this.currentTab && this.currentTab.items) {
        this.mediaFiles = this.mediaFiles.filter(s => s.id !== id);
        this.currentTab.items = this.currentTab.items.filter(s => s.id !== id);
        // меняем счётчик
        if (this.mediaFilesCount > 0) {
          this.mediaFilesCount--;
        }
      }
    });

    // обработка перемещения медиа-файла
    this.galleryService.mediaFileMoved$.pipe(takeUntil(this.destroyed$)).subscribe(model => {
      if (this.mediaFiles && model && this.currentTab && this.currentTab.items) {
        this.mediaFiles = this.mediaFiles.filter(s => s.id !== model.id);
        this.currentTab.items = this.currentTab.items.filter(s => s.id !== model.id);
        // меняем счётчик
        if (this.mediaFilesCount > 0) {
          this.mediaFilesCount--;
        }
      }
    });

    this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
      if (group) {
        if (this.all) {
          // если находимся на главной странице галереи
          this.getProfileMediaFiles(group.id);
          // проставляем текущего owner для загрузки при scroll-событии
          this.currentOwner = { owner: group, ownerType: ProfileType.Group };
        } else {
          // если находимся внутри альбома
          this.galleryService.currentAlbum.pipe(takeUntil(this.destroyed$)).subscribe(album => {
            this.album = album;

            if (album) {
              this.albumId = this.album.id;

              this.signalRService.galleryHub.onAlbumVoting.pipe(takeUntil(this.destroyed$)).subscribe(res => {
                this.ngZone.run(() => {
                  if (!res || !res.albumId || res.albumId !== album.id) {
                    return;
                  }

                  if (!res.enable || res.dateEnd < new Date()) {
                    this.addResultsTab();
                  } else {
                    this.removeResultsTab();
                  }
                });
              });

              this.tabs = {
                title: 'Галерея',
                routerLink: `/group/${group.id}/gallery/${album.id}`,
                hideCounters: false,
                noBreadcrumbs: true,
                items: [
                  {
                    id: 1,
                    title: 'Медиафайлы',
                    name: 'media',
                    fragment: '',
                    items: [],
                    offset: 0,
                    itemsCount: 0,
                    method: this.showMediaFiles.bind(this),
                    default: true,
                    emptyText: 'Нет медиа-файлов',
                  },
                ],
              };

              if (this.galleryService.isVoteFinished(this.album)) {
                this.addResultsTab();
              }

              this.route.fragment.pipe(takeUntil(this.destroyed$)).subscribe(val => {
                // get hash
                if (val != null) {
                  const keys = val.split('&');
                  const hash = {};
                  keys.forEach(key => {
                    // tslint:disable-next-line:no-shadowed-variable
                    const val = key.split('=');
                    hash[val[0]] = val[1];
                  });

                  this.currentTab = null;

                  this.tabs.items.forEach(tab => {
                    if (tab.fragment && keys.find(k => k === tab.fragment)) {
                      this.currentTab = tab;
                    }
                  });

                  if (!this.currentTab) {
                    this.currentTab = this.tabs.items[0];
                  }
                } else {
                  this.currentTab = this.tabs.items[0];
                }

                this.offset = 0;
                this.currentTab.offset = 0;
                this.limit = this.currentTab.name === 'results' ? 20 : 10;
                this.mediaFilesAllLoaded = false;
                this.currentTab.method(this.currentTab);
              });
            }
          });
        }
      }
    });
  }

  private addResultsTab() {
    if (this.tabs && this.tabs.items && !this.tabs.items.find(s => s.id === 2)) {
      this.tabs.items.push({
        id: 2,
        title: 'Результаты',
        name: 'results',
        fragment: 'results',
        items: [],
        offset: 0,
        itemsCount: 0,
        method: this.showVoteResults.bind(this),
        default: false,
        emptyText: 'Нет результатов',
        hideCounter: true,
      });
    }
  }

  private removeResultsTab() {
    if (this.tabs && this.tabs.items && this.tabs.items.find(s => s.id === 2)) {
      this.tabs.items = this.tabs.items.slice(0, 1);
      this.router.navigate([this.tabs.routerLink]);
    }
  }

  showMediaFiles(tab: Tab<IMediaFile>) {
    tab.loading = true;
    this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
      if (group) {
        if (this.groupsService.isParticipant(group, this.currentUser)) {
          this.allowEdit = true;
        }

        this.isAdmin = this.groupsService.isAdmin(group, this.currentUser);

        // количество медиа-файлов альбома
        this.galleryService
          .getAlbumMediaFilesCount(this.albumId)
          .pipe(take(1), takeUntil(this.destroyed$))
          .subscribe(res => {
            this.mediaFilesCount = res;
            tab.itemsCount = res;
          });

        // медиа-файлы профиля
        this.galleryService
          .getMediaFiles(this.albumId, tab.offset, this.limit)
          .pipe(
            finalize(() => {
              tab.loaded = true;
              tab.loading = false;
              this.loaded = true;
            }),
            takeUntil(this.destroyed$),
          )
          .subscribe(
            mediaFiles => {
              if (!tab.items) {
                tab.items = [];
              }
              if (mediaFiles && mediaFiles.length) {
                mediaFiles.forEach(mediaFile => {
                  if (!tab.items.find(s => s.id === mediaFile.id)) {
                    tab.items.push(mediaFile);
                  }
                });
                tab.offset = tab.items.length;
                this.mediaFilesAllLoaded = false;
              } else {
                this.mediaFilesAllLoaded = true;
              }
              this.mediaFiles = tab.items;
            },
            error => {
              this.error = error;
            },
          );
      }
    });
  }

  showVoteResults(tab: Tab<IMediaFile>) {
    tab.loading = true;
    this.galleryService
      .getAlbumVoteResults(this.albumId, tab.offset, this.limit)
      .pipe(
        finalize(() => {
          tab.loaded = true;
          tab.loading = false;
          this.loaded = true;
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe(res => {
        if (res) {
          if (!tab.items) {
            tab.items = [];
          }
          if (res.mediaFiles && res.mediaFiles.length) {
            res.mediaFiles.forEach(mediaFile => {
              if (!tab.items.find(s => s.id === mediaFile.id)) {
                tab.items.push(mediaFile);
              }
            });
            tab.offset = tab.items.length;
            this.mediaFilesAllLoaded = false;
          } else {
            this.mediaFilesAllLoaded = true;
          }
          this.mediaFiles = tab.items;
          this.votingStartDate = res.votingStartDate;
          this.votingEndDate = res.votingEndDate;
          this.votesCount = res.votesCount;
        }
      });
  }

  getVoteText() {
    if (this.votingStartDate && this.votingEndDate) {
      return (
        Helper.getNoun(this.votesCount, 'Проголосовал', 'Проголосовало', 'Проголосовало') +
        ' ' +
        this.votesCount +
        ' ' +
        Helper.getNoun(this.votesCount, 'человек', 'человека', 'человек')
      );
    }
  }

  load() { }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  getItems(isAll: boolean) {
    if (isAll) {
      return this.mediaFiles;
    } else {
      return this.currentTab.items;
    }
  }

  getItemsLoaded(isAll: boolean): boolean {
    if (isAll) {
      return this.loaded;
    } else {
      return this.currentTab.loaded;
    }
  }

  getMenuConfig(mediaFile: IMediaFile): IMediaFileMenuConfig {
    const group = this.groupsService.currentGroup.getValue();
    if (group) {
      if (this.isAdmin || (mediaFile.author && this.currentUser && mediaFile.author.id === this.currentUser.id)) {
        return this.allowAllConfig;
      }
    }
    return null;
  }

  /**
   * Разрешить drop для элемента(-ов)
   *
   * @param {*} e
   * @memberof MediaListComponent
   */
  allowDrop(e: any) {
    const group = this.groupsService.currentGroup.getValue();
    if (group && this.groupsService.isAdmin(group, this.currentUser) && this.albumId) {
      e.preventDefault();
    }
  }

  protected onScroll() {
    // console.log('onScroll');
    const number = window.innerHeight + window.pageYOffset + 20;
    if (this.mediaFiles && number > this.maxItemHeight * this.mediaFiles.length) {
      // console.log('prepare for upload');
      if (!this.mediaFilesLoading) {
        // console.log('check for upload');
        if (!this.mediaFilesAllLoaded) {
          // console.log('uploading');
          if (this.all) {
            if (this.currentOwner && this.currentOwner.owner && this.currentOwner.owner.id) {
              this.getProfileMediaFiles(this.currentOwner.owner.id);
            } else {
              console.log('current owner is not initialized');
            }
          } else {
            this.currentTab.method(this.currentTab);
            // this.getAlbumMediaFilesNoCount(this.albumId, this.offset, this.limit);
          }
        }
      }
    }
  }
}
