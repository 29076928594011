import { Attachment } from '@app/attachments/file-attachments/model/attachment.model';
import { User } from '@app/profile/model/user.model';

export enum IdeasListType {
    AllOrMy = 1,
    NewOrOther = 2
}

export interface IIdea {
    id: number;
    title: string;
    description: string;
    authorId?: number;
    author: User;
    ownerId?: number;
    created?: Date;
    modified?: Date;
    published?: boolean;
    docs?: Attachment[];
    status: IIdeaStatus;
    commentsCount: number;
    likesCount: number;
    canLike: boolean;
}

export interface INewIdea {
    title: string;
    description: string;
    ownerId?: number;
    docs?: Attachment[];
    // ui
    titleFocused?: boolean;
}

export interface IUpdateIdea extends INewIdea {
    id: number;
}

export interface IIdeaStatus extends INewIdeaStatus {
    id: number;
    order?: number;
    deleted?: Date;
}

export interface IIdeaStatusUpdate {
    ideaId: number;
    statusId: number;
    comment?: string;
    dateEnd?: Date;
}

export interface INewIdeaStatus {
    title: string;
    commentRequired?: boolean;
    dateEndRequired?: boolean;
}

export interface IUpdateIdeaStatus extends IIdeaStatus {
    id: number;
    // ui
    focused?: boolean;
    order?: number;
}

export interface IIdeaStatusChanges {
    added: INewIdeaStatus[];
    updated: IUpdateIdeaStatus[];
    hidden: number[];
    visible: number[];
    deleted: number[];
}

export interface IIdeaStatusChangesModel {
    id: number;
    statusId: number;
    created: Date;
    author: User;
    changes: IStatusChange[];
    ownerId: number;
}

export interface IStatusChange {
    id: number;
    statusId: number;
    title: number;
    dateEnd: number;
    dateChange: number;
    comment: number;
    author: User;
}
