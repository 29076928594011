<div class="card _colleagues" *ngIf="userLinks && loaded">
  <div class="card__title">
    <a *ngIf="showAddButton" class="btn btn-plus pull-right" (click)="showAddUser()">+</a>
    <div class="header-text">
      <a class="header-text__text" [routerLink]="link">Коллеги</a>&nbsp;
      <span class="header-text__count">{{userLinksCount | async}}</span>
    </div>
  </div>
  <div class="card__content">
    <div class="nowrap">
      <app-user-avatar [user]="link?.user" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'" *ngFor="let link of userLinks | async"></app-user-avatar>
    </div>
  </div>
</div>
<div class="card _colleagues" *ngIf="!loaded">
  <div class="card__title">
    <span class="preloader _no-margin">
      <span class="preloader__line"></span>
    </span>
  </div>
  <div class="card__content">
    <span class="preloader _avatar" *ngFor="let r of [0,1,2,3,4]">
      <span class="preloader__side"></span>
    </span>
  </div>
</div>
