import { Component, OnInit, OnDestroy } from '@angular/core';
import { HelperService } from '@app/core/services/helper.service';
import { Tabs, Tab } from '@app/shared/components/tabs/tab.model';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil, take, finalize } from 'rxjs/operators';
import { ICalendarEvent, CalendarViewType, CalendarFilterType, ICalendarDate } from '@app/calendar/model/calendar';
import { UsersService } from '@app/profile/services/users.service';
import { User } from '@app/profile/model/user.model';
import { CalendarBaseComponent } from '../calendar-base/calendar-base.component';
import { CalendarService } from '@app/calendar/services/calendar.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent extends CalendarBaseComponent implements OnInit, OnDestroy {

  currentUser: User;
  foundDepartments: string[];
  filterDepartmentVisible = false;
  filterDepartmentError: string;

  CalendarFilterType = CalendarFilterType;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    public usersService: UsersService,
    public calendarService: CalendarService,
    public helper: HelperService
  ) { super(router, route, usersService, calendarService, helper); }

  ngOnInit() {

    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;
      if (this.currentUser) {
        this.filterDepartment = this.currentUser.department
          ? `${this.currentUser.department}`
          : '';
      }
    });

    this.tabs = {
      title: 'Календарь',
      routerLink: `/calendar`,
      hideCounters: true,
      items: [
        {
          id: 1,
          title: 'Месяц',
          name: 'monthView',
          fragment: 'monthView',
          items: [],
          offset: 0,
          itemsCount: 0,
          method: this.showMonthView.bind(this),
          default: true
        },
        {
          id: 2,
          title: 'День',
          name: 'dayView',
          fragment: 'dayView',
          items: [],
          offset: 0,
          itemsCount: 0,
          method: this.showDayView.bind(this),
          emptyText: ''
        }
      ]
    };

    this.route.fragment.pipe(takeUntil(this.destroyed$)).subscribe(val => {

      // get hash
      if (val != null) {
        const keys = val.split('&');
        const hash = {};

        let isDayView = false;
        const today = new Date();

        keys.forEach(key => {
          // tslint:disable-next-line:no-shadowed-variable
          const val = key.split('=');
          hash[val[0]] = val[1];

          if (val[0] === 'dayView') {
            isDayView = true;
          }
        });

        const selectedDate: ICalendarDate = {
          year: today.getFullYear(),
          month: today.getMonth(),
          day: today.getDate()
        };

        let invalidDate = false;

        if (hash['year']) {
          const year = +hash['year'];
          if (!year) {
            invalidDate = true;
          }
          selectedDate.year = year;
          // console.log(`year=`, hash['year']);
        } else {
          invalidDate = true;
        }

        if (hash['month']) {
          // console.log(`month=`, hash['month']);
          const month = +hash['month'];
          if (!month || month < 1 || month < 12) {
            invalidDate = true;
          }
          selectedDate.month = month - 1;
        } else {
          invalidDate = true;
        }

        if (hash['day']) {
          // console.log(`day=`, hash['day']);
          const day = +hash['day'];
          const maxDaysInMonth = super.daysInMonth(selectedDate.month, selectedDate.year);
          if (!day || day < 1 || day > maxDaysInMonth) {
            invalidDate = true;
          }
          selectedDate.day = day;
        } else {
          invalidDate = true;
          selectedDate.day = 1;
        }

        // получаем фильтр из хэш
        if (hash['filter']) {
          const filter = hash['filter'];
          const filterValue = CalendarFilterType[filter];
          if (filterValue !== undefined) {
            this.filterType = <any>filterValue;
          } else {
            this.filterType = CalendarFilterType.colleagues;
            // invalidDate = true;
          }
        } else {
          invalidDate = true;
          // this.filterType = CalendarFilterType.colleagues;
        }

        if (invalidDate) {
          // перенаправление на текущий день
          // this.navigateCalendarToDate(
          //   today.getFullYear(),
          //   today.getMonth() + 1,
          //   today.getDate(),
          //   CalendarFilterType.colleagues);
          // return;
        }

        this.selectedDate = selectedDate;

        this.currentTab = null;

        this.tabs.items.forEach(tab => {
          if (tab.fragment && keys.find(k => k === tab.fragment)) {
            this.currentTab = tab;
          }
        });

        if (!this.currentTab) {
          this.currentTab = this.tabs.items[0];
        }
      } else {
        this.currentTab = this.tabs.items[0];
      }

      // получить данные
      this.currentTab.method(this.currentTab, this);
    });
  }

  isTabActive(section: string): boolean {
    return location.href.indexOf(section) !== -1;
  }

  showMonthView(tab: Tab<ICalendarEvent>) {
    this.viewType = CalendarViewType.month;
    // получаем данные для календаря
    super.onInit(this.selectedDate);
  }

  showDayView(tab: Tab<ICalendarEvent>) {
    this.viewType = CalendarViewType.day;
    super.onInit(this.selectedDate);
  }

  onInit(selectedDate: ICalendarDate) {
    // super.onInit(selectedDate);
    // this.GetBirthdays();
  }

  onPrev(selectedDate: ICalendarDate) {
    this.selectedDate = selectedDate;
    this.navigateCalendar();
  }

  onNext(selectedDate: ICalendarDate) {
    this.selectedDate = selectedDate;
    this.navigateCalendar();
  }

  filterChanged(calendarFilterType: CalendarFilterType) {
    // сохранить значение
    this.filterType = calendarFilterType;
    this.navigateCalendar();
  }

  onSearchDepartment(e: KeyboardEvent) {
    // ESC
    if (e.keyCode === 27) {
      this.filterDepartment = '';
      e.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();
      this.clearDepartmentsFilter();
      this.navigateCalendar();
      return false;
    }

    if (this.filterDepartment && this.filterDepartment.length > 1) {
      // получение списка департаментов
      this.usersService.getDepartments(this.filterDepartment, 0, 10)
        .pipe(take(1), finalize(() => this.filterDepartmentVisible = true), takeUntil(this.destroyed$))
        .subscribe(res => {
          this.foundDepartments = res ? res.slice(0, 10) : [];
          this.filterDepartmentError = this.foundDepartments.length ? '' : 'Департаменты по заданному фильтру не найдены';
        }, error => {
          this.filterDepartmentError = 'Произошла ошибка при поиске департаментов';
        });
    } else {
      this.filterDepartmentVisible = true;
      this.filterDepartmentError = 'Введите хотя бы 2 символа';
    }
  }

  clearDepartmentsFilter() {
    this.foundDepartments = [];
    this.filterDepartmentError = '';
    this.filterDepartmentVisible = false;
  }

  getDayFragment(year: number, month: number, day: number) {
    return `dayView&year=${year}`
      + `&month=${month}`
      + `&day=${day}`
      + `&filter=${CalendarFilterType[CalendarFilterType.all]}`;
  }

  selectDepartment(e, department: string) {
    this.filterDepartment = department;
    this.foundDepartments = [];
    this.filterChanged(CalendarFilterType.departments);
  }

  private navigateCalendarToDate(year: number, month: number, day: number, filter: CalendarFilterType) {
    let fragment =
      this.currentTab.name
      + `&year=${year}`
      + `&month=${month}`
      + `&day=${day}`
      + `&filter=${CalendarFilterType[filter]}`;

    if (this.filterDepartment) {
      fragment += `&department=${encodeURIComponent(this.filterDepartment)}`;
    }

    this.router.navigate(['calendar'], { fragment: fragment });
  }

  private navigateCalendar() {
    this.navigateCalendarToDate(
      this.selectedDate.year,
      this.selectedDate.month + 1,
      this.selectedDate.day ? this.selectedDate.day : 1,
      this.filterType);
  }

  congratulate(user: User) {
    this.usersService.navigateProfile(user.id, null, 'congratulate', true);
  }

  showCongratulate(e: Event, user: User) {
    if (user) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      user.congratulate = !user.congratulate;
    }
  }
}
