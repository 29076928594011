import { Component, OnInit, OnDestroy } from '@angular/core';
import { TabbedListComponent } from '@app/shared/components/tabs/tabbed-list.component';
import { UserLink } from '@app/subscribe/model/user-link';
import { User } from '@app/profile/model/user.model';
import { Tab } from '@app/shared/components/tabs/tab.model';
import { takeUntil, finalize } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService } from '@app/profile/services/users.service';
import { HelperService } from '@app/core/services/helper.service';

@Component({
  selector: 'app-user-subordinates-list',
  templateUrl: './user-subordinates-list.component.html',
  styleUrls: ['./user-subordinates-list.component.scss']
})
export class UserSubordinatesListComponent extends TabbedListComponent<UserLink> implements OnInit, OnDestroy {
  user: User;
  currentUser: User;
  showPicture = true;

  isCurrent = false;

  limit = 10;

  protected maxItemHeight = 110;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    public usersService: UsersService,
    public helper: HelperService) { super(router, route, helper); }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.usersService.user.pipe(takeUntil(this.destroyed$)).subscribe(user => {
        // set user
        this.user = user;
        // check user
        if (user) {

          this.currentUser = currentUser;
          this.isCurrent = this.user.id === currentUser.id;

          this.tabs = {
            title: 'Подчинённые',
            routerLink: `/profile/${user.id}/subordinates`,
            items: [
              {
                id: 1,
                title: 'Подчинённые',
                fragment: '',
                name: 'subordinates',
                items: [],
                offset: 0,
                itemsCount: 0,
                method: this.getSubordinates.bind(this),
                default: true,
                emptyText: 'У сотрудника нет подчинённых'
              }
            ]
          };
          this.route.fragment.pipe(takeUntil(this.destroyed$)).subscribe(val => {

            this.mobHeight = window.innerHeight;
            this.setNewLimit();

            // get hash
            if (val != null) {
              const keys = val.split('&');
              const hash = {};
              keys.forEach(key => {
                // tslint:disable-next-line:no-shadowed-variable
                const val = key.split('=');
                hash[val[0]] = val[1];
              });

              this.currentTab = null;

              this.tabs.items.forEach(tab => {
                if (tab.fragment && keys.find(k => k === tab.fragment)) {
                  this.currentTab = tab;
                }
              });

              if (!this.currentTab) {
                this.currentTab = this.tabs.items[0];
              }
            } else {
              this.currentTab = this.tabs.items[0];
            }

            // получить данные по всем табикам
            this.tabs.items.forEach(tab => {
              if (!tab.loaded) {
                tab.method(tab, this);
              } else if (this.currentTab.id === tab.id) {
                tab.offset = 0;
                tab.method(tab, this);
              }
            });
          });
        }
      });
    });
  }

  getSubordinates(tab: Tab<UserLink>) {
    if (this.user) {
      tab.loading = true;
      this.usersService.getSubordinates(this.user.accountName, this.offset, this.limit).pipe(
        finalize(() => {
          this.loaded = tab.loaded = true;
          tab.loading = false;
        }),
        takeUntil(this.destroyed$)).subscribe(res => {
          if (!tab.items || tab.offset === 0) {
            tab.items = [];
          }

          if (res) {
            res.items.forEach(link => {
              if (!tab.items.filter(g => g.user.id === link.user.id).length) {
                tab.items.push(link);
              }
            });
            tab.itemsCount = res.count;
            tab.offset = tab.items.length;
          }
        });
    } else {
      tab.items = [];
      tab.itemsCount = 0;
    }
  }

  /**
   * Отображать кнопки подписки пользователя
   *
   * @param {UserLink} link
   * @returns
   * @memberof ColleaguesListComponent
   */
  showSubscribeButtons(link: UserLink) {
    return this.currentUser && link && link.user && this.currentUser.id !== link.user.id;
  }
}
