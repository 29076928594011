<div class="pull-right btn btn-dots tooltip" mat-button [matMenuTriggerFor]="menu" *ngIf="isAnyItemInMenu()"></div>
<mat-menu #menu="matMenu">
  <div class="icon-menu" #menuContainer>
    <ng-container *ngIf="selectedItems?.length>1; then multipleItemsMenu; else singleItemMenu"></ng-container>
    <ng-template #singleItemMenu>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="isShowEditButton()"
        (click)="editClick()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/edit.svg" alt="" class="icon-menu__img">
        </span>
        <span>Редактировать</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item"
        *ngIf="config?.allowPublish && !item.published" (click)="publishClick()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/check.svg" alt="" class="icon-menu__img">
        </span>
        <span>Опубликовать</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item"
        *ngIf="config?.allowPublish && item.published" (click)="unPublishClick()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/cancel.svg" alt="" class="icon-menu__img">
        </span>
        <span>Отменить публикацию</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="config?.allowDelete"
        (click)="deleteClick()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/remove.svg" alt="" class="icon-menu__img">
        </span>
        <span>Удалить</span>
      </a>
    </ng-template>
    <ng-template #multipleItemsMenu>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="config?.allowDelete"
        (click)="deleteClick()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/remove.svg" alt="" class="icon-menu__img">
        </span>
        <span>Удалить</span>
      </a>
    </ng-template>
  </div>
</mat-menu>
