/**
 * Represent a search result item  webUrl: any;

 */
export class SearchResultItem {
    title: string;
    fileName: string;
    isFolder: boolean;
    editor: string;
    editorLogin: string;
    modified: Date;
    created: Date;
    childCount: number;
    highlightedSummary: string;
    serverRedirectedUrl: string;
    url: string;
    uniqueId: string;
    webUrl: string;
    size: number;
    isPersonal: boolean;
    isGroup: boolean;
}
