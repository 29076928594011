<div class="card _awards _preloader _row" *ngIf="!loaded">
  <div class="card__title">
    <span class="preloader _white">
      <span class="preloader__line"></span>
    </span>
  </div>
  <div class="card__content">
    <span class="preloader _white _avatar" *ngFor="let r of [0,1,2]">
      <span class="preloader__side"></span>
    </span>
  </div>
</div>

<div class="card _awards" *ngIf="loaded" [ngClass]="{'_no-data': !awards?.length}">
  <div class="card__title">
    <span class="header-text">
      <a class="header-text__text" routerLink="awards">Награды</a>&nbsp;
      <span class="header-text__count">{{awardsCount}}</span>
    </span>
  </div>
  <div class="awards" *ngIf="awards?.length">
    <div class="awards__item hint" *ngFor="let award of awards">
      <img class="awards__image" [attr.src]="awardsService.getIconUrl(award?.iconUrl)">
      <span class="hint__body">
        <span class="hint__title">{{award.title}}</span>
        <span class="hint__text">{{award.period}}</span>
      </span>
    </div>
  </div>
  <app-awards-menu *ngIf="isAdmin && awards?.length"></app-awards-menu>
  <div class="awards-new card__content" *ngIf="isAdmin && !awards?.length">
    <a class="link _white btn btn-white btn-stroke" [routerLink]="'awards/new'">
      Добавить награду
    </a>
  </div>
  <div class="awards-new card__content" *ngIf="!isAdmin && !awards?.length">
    Пока нет наград
  </div>
</div>
