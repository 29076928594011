import {Component, OnInit, OnDestroy} from '@angular/core';
import {GroupInfo} from '@app/groups/model/group-info.model';
import {BaseComponent} from '@app/core/components/base.component';
import {GroupsService} from '@app/groups/services/groups.service';
import {Router} from '@angular/router';
import {HelperService} from '@app/core/services/helper.service';
import {takeUntil, take} from 'rxjs/operators';
import {IPage} from '@app/pages/model/page';
import {PagesService} from '@app/pages/services/pages.service';
import {UsersService} from '@app/profile/services/users.service';

/**
 * Виджет страниц
 *
 * @export
 * @class PagesWidgetComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-pages-widget',
  templateUrl: './pages-widget.component.html',
  styleUrls: ['./pages-widget.component.scss'],
})
export class PagesWidgetComponent extends BaseComponent implements OnInit, OnDestroy {
  /**
   * Группа, в которой находится виджет
   *
   * @type {GroupInfo}
   * @memberof PagesWidgetComponent
   */
  group: GroupInfo;

  /**
   * Массив вакансий
   *
   * @type {IPage[]}
   * @memberof PagesWidgetComponent
   */
  pages: IPage[];

  /**
   * Ссылка перехода в раздел страниц
   *
   * @memberof PagesWidgetComponent
   */
  link = '/pages';

  /**
   * Отображать виджет (только для пользователей у которых есть права)
   *
   * @type {boolean}
   * @memberof PagesWidgetComponent
   */
  showWidget: boolean;

  /**
   * Отображать ссылку создания новой страницы
   *
   * @type {boolean}
   * @memberof SurveyWidgetComponent
   */
  showAddButton: boolean;

  /**
   * Ссылка создания новой страницы
   *
   * @type {string}
   * @memberof SurveyWidgetComponent
   */
  addButtonLink: string;

  constructor(
    protected usersService: UsersService,
    protected groupsService: GroupsService,
    protected pagesService: PagesService,
    protected router: Router,
    public helper: HelperService,
  ) {
    super(helper);
  }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(
        group => {
          if (group) {
            this.group = group;

            this.link = `/group/${this.group.id}/pages`;

            this.showWidget = this.groupsService.canReadGroup(this.group, currentUser);

            if (this.showWidget) {
              this.showAddButton = this.groupsService.isAdmin(this.group, currentUser);
              this.addButtonLink = this.link + `/new`;
            }

            this.getItems(true);

            this.pagesService.pageInWidgetUpdated$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
              this.getItems(true);
            });
          } else {
            this.group = null;
          }
          this.loaded = true;
        },
        error => {
          this.loaded = true;
        },
      );
    });
  }

  getItems(force: boolean) {
    if (!this.loaded || force) {
      this.pagesService
        .getProfilePages(this.group.id)
        .pipe(take(1))
        .subscribe(
          res => {
            this.pages = res && res.length ? res : null;
            this.loaded = true;
          },
          error => {
            this.loaded = true;
          },
        );
    }
  }

  navigate() {
    this.router.navigate([this.link]);
  }

  onDeleted(id: number) {
    this.pages = this.pages.filter(s => s.id !== id);
  }
}
