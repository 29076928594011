import { Component, OnInit, Input, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FilesService } from '@app/files/services/files.service';
import { Subject } from 'rxjs';
import { BaseComponent } from '@app/core/components/base.component';
import { AlertsService } from '@app/shared/services/alerts.service';
import { FilesListItemType } from '@app/files/model/files-list-item.model';
import { takeUntil, take } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { Helper } from '@app/core/helpers/helper';

@Component({
  selector: 'app-files-rename',
  templateUrl: './files-rename.component.html'
})
export class FilesRenameComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() newName: string;
  @ViewChild('itemName', { static: true }) itemNameElem: any;

  constructor(
    private alertService: AlertsService,
    public dialogRef: MatDialogRef<FilesRenameComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public filesService: FilesService,
    public alertsService: AlertsService,
    public helper: HelperService) { super(helper); }

  ngOnInit() {
    if (this.data.item.type === FilesListItemType.Folder) {
      this.newName = this.data.item.name;
    } else {
      this.newName = this.filesService.getFileNameWithoutExtension(this.data.item.name);
    }
  }

  rename() {
    this.data.webUrl.pipe(take(1), takeUntil(this.destroyed$)).subscribe(resUrl => {
      this.data.listUrl.pipe(take(1), takeUntil(this.destroyed$)).subscribe(listUrl => {
        console.log(`renaming, name: '${resUrl}', listUrl: '${listUrl}', item.id: ${this.data.item.id}`);
        this.loading = true;
        this.filesService.webApiUpdateFolder(resUrl, listUrl, this.data.item, this.newName)
          .pipe(take(1), takeUntil(this.destroyed$)).subscribe(res => {
            console.log('renamed');
            this.loading = false;
            if (this.data.onRenamed) {
              const url: string = this.data.item.url;
              this.data.item.url = url.substring(0, url.lastIndexOf('/') + 1) + this.newName;
              if (this.data.item.type === FilesListItemType.Folder) {
                this.data.item.name = this.newName;
              } else {
                this.data.item.name = this.newName + this.filesService.getFileExtension(this.data.item);
                this.data.item.url += this.data.item.ext;
              }
              this.data.onRenamed.emit(this.data.item);
            }
            this.dialogRef.close();
            this.alertsService.riseSuccess(this.data.item.type === 0
              ? 'Файл был переименован'
              : 'Папка была переименована');
          }, error => {
            this.loading = false;
            this.error = `Возникла ошибка при переименовании${Helper.getErrorMsg(error)}`;
            this.alertService.riseError(this.error);
          });
      });
    });
  }
}
