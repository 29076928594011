<div class="card _media-edit">
  <span class="header-text _modal">
    <button type="button" mat-button mat-dialog-close class="btn btn-close"></button>
    <span class="header-text__text">
      Редактирование {{getMediaFileTypeText(data?.item)}}
    </span>
  </span>
  <ng-container *ngIf="data?.item?.type===mediaFileType.video">
    <div class="video-preview">
      <img [src]="videoPreviewUrl" width="100%" class="video-preview__img" alt="data?.item?.title">
      <div class="video-preview__edit">
        <div class="video-preview__input-wrap" (click)="mediafileUpload.click()">
          <img src="{{assetsPrefix}}/assets/images/icons/edit-photo.svg" alt="">Заменить
        </div>
        <input type="file" #mediafileUpload accept=".jpeg,.jpg,.png" (change)="onUpload($event)" style="display:none" />
      </div>
    </div>
  </ng-container>
  <div class="input-main _big">
    <input type="text"
      placeholder="Введите название"
      [(ngModel)]="newName"
      class="input-main__field"
      (keyup)="validate()"
      maxlength="255">
  </div>
  <div class="subinput">{{getMediaFileLengthText(newName, 255)}}</div>
  <div class="input-main _big">
    <textarea
      autosize
      rows="1"
      placeholder="Введите описание"
      [(ngModel)]="newDescription"
      class="input-main__field"
      (keyup)="validate()"
      maxlength="3000">
    </textarea>
  </div>
  <div class="subinput">{{getMediaFileLengthText(newDescription, 3000)}}</div>
  <div class="error-message" *ngIf="error">{{error}}</div>
</div>
<div class="confirm _right _modal">
  <div class="confirm__actions" mat-dialog-actions [ngClass]="{'loading _blue':loading}">
    <button type="button" mat-button mat-dialog-close class="btn btn-white">Отмена</button>
    <button type="button" mat-button class="btn btn-primary" (click)="save()" [attr.disabled]="isValid ? null :'' ">Сохранить</button>
  </div>
</div>
