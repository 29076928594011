import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { User } from '@app/profile/model/user.model';
import { UsersService } from '@app/profile/services/users.service';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { HelperService } from '@app/core/services/helper.service';
import { takeUntil, take, shareReplay, finalize } from 'rxjs/operators';
import { AwardsService } from '@app/awards/services/awards.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertsService } from '@app/shared/services/alerts.service';
import { INewAwardModel, IAwardIcon } from '@app/awards/model/awards';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { FilesService } from '@app/files/services/files.service';

/**
 * Компонент создания новой награды
 *
 * @export
 * @class NewAwardComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-new-award',
  templateUrl: './new-award.component.html',
  styleUrls: ['./new-award.component.scss']
})
export class NewAwardComponent extends BaseComponent implements OnInit {

  /**
   * Пользователь, для которого добавляется награда
   *
   * @type {User}
   * @memberof NewAwardComponent
   */
  user: User;

  /**
   * Новая награда
   *
   * @type {INewAwardModel}
   * @memberof NewAwardComponent
   */
  award: INewAwardModel = {
    title: '',
    userId: 0,
    iconId: 0,
    period: ''
  };

  /**
   * Существующие на данный момент иконки
   *
   * @type {IAwardIcon[]}
   * @memberof NewAwardComponent
   */
  icons: IAwardIcon[];

  iconUploading: boolean;
  sending: boolean;

  buttonSaveText = 'Добавить';

  private iconsToUpload: any[];

  constructor(
    protected filesService: FilesService,
    protected usersService: UsersService,
    protected awardsService: AwardsService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected breadcrumbsService: BreadcrumbsService,
    protected alertsService: AlertsService,
    protected dialog: MatDialog,
    public helper: HelperService) { super(helper); }

  ngOnInit() {
    // получение всех иконок
    this.getIcons();

    // получение пользователя профиля и добавление хлебных крошек
    this.usersService.user.pipe(takeUntil(this.destroyed$)).subscribe(user => {
      if (user) {
        this.user = user;

        this.breadcrumbsService.breadcrumbs.next([
          { title: 'Награды', routerUrl: `/profile/${this.user.id}/awards` },
          { title: 'Новая награда', routerUrl: `/profile/${this.user.id}/awards/new` },
        ]);

        this.award.userId = user.id;

        this.loaded = true;

      } else {
        this.user = null;
      }
    });
  }

  /**
   * Отправка формы
   *
   * @memberof NewAwardComponent
   */
  submit() {
    if (this.isValid()) {
      this.sending = true;
      this.awardsService.create(this.award).pipe(take(1), finalize(() => this.sending = false)).subscribe(res => {
        if (res) {
          this.alertsService.riseSuccess('Награда успешно добавлена.');
          this.router.navigate(['..'], { relativeTo: this.route });
        } else {
          this.alertsService.riseError('Произошла ошибка при отправке формы. Обратитесь к администратору.');
        }
      }, error => {
        this.alertsService.riseError('Произошла ошибка при отправке формы. Обратитесь к администратору.');
        console.log(error);
      });
    } else {
      this.alertsService.riseError('Произошла ошибка при отправке формы. Не заполнены обязательные поля');
    }
  }

  fileChangeEvent(fileInput) {
    const files = fileInput.target.files;
    this.operateUploadingFiles(files);
  }

  /**
   * Выбрать иконку награды
   *
   * @param {IAwardIcon} icon
   * @memberof NewAwardComponent
   */
  choseIcon(icon: IAwardIcon) {
    this.award.iconId = icon.id;
  }

  /**
   * Удаление иконки награды
   *
   * @param {IAwardIcon} icon Иконка, которую необходимо удалить
   * @memberof NewAwardComponent
   */
  removeIcon(icon: IAwardIcon) {
    this.filesService.webApiDeleteItem((<any>window).signalRServer, <any>icon)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        this.icons = this.icons.filter(s => s.url !== icon.url);
        this.alertsService.riseSuccess('Иконка награды была успешно удалена.');
      }, error => {
        this.alertsService.riseError('Произошла ошибка при удалении иконки.');
      });
  }

  /**
   * Переопределяем отмену, т.к. редактирование на 1 уровень дальше
   *
   * @memberof EditAwardComponent
   */
  cancel() {
    if (this.award
      && (this.award.title
        || this.award.period
        || this.award.iconId)
    ) {
      this.dialog.open(ModalConfirmationComponent, {
        data: {
          text: `Вы действительно хотите отменить добавление?`,
          onOk: this.goBack.bind(this),
          okText: 'Да'
        }
      });
    } else {
      this.goBack();
    }
  }

  /**
   * Отмена создания награды
   *
   * @memberof NewAwardComponent
   */
  private goBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  /**
   * Получение всех иконок
   *
   * @memberof NewAwardComponent
   */
  getIcons() {
    this.awardsService.getAwardIcons().pipe(take(1), shareReplay(1, 10000)).subscribe(icons => {
      this.icons = icons;
    });
  }

  /**
   * Форма валидна для отправки?
   *
   * @returns {boolean}
   * @memberof FeedbackComponent
   */
  isValid(): boolean {
    return this.award
      && this.award.userId
      && this.award.title
      && this.award.period
      && this.award.iconId ? true : false;
  }

  /**
   * Добавление новой иконки
   *
   * @memberof NewAwardComponent
   */
  private operateUploadingFiles(files) {
    if (files && files.length) {
      this.iconsToUpload = [];
      for (let i = 0; i < files.length; i++) {
        this.iconsToUpload.push(files[i]);
      }
    }

    if (this.iconsToUpload && this.iconsToUpload.length) {
      // upload file
      this.iconUploading = true;
      this.iconsToUpload.forEach(file => {
        this.uploadFile(file);
      });
    }
  }

  private uploadFile(file: any) {
    file.uploading = true;
    const formData: FormData = new FormData();
    formData.append('file', file);
    this.awardsService.uploadIcon(formData).subscribe(res => {
      if (res) {
        console.log('icon uploading success');
        // алёрт об успешно добавленном файле
        this.alertsService.riseSuccess(`Иконка '${file.name}' была успешно добавлена`);
        if (this.icons && !this.icons.find(s => s.url === res.url)) {
          this.icons.push(res);
        }
      }
      this.iconUploadingFinished(file);
    }, error => {
      console.log(error);
      // алёрт об ошибке добавления файла
      this.alertsService.riseError(`Ошибка во время добавления иконки '${file.name}'.`);
      this.iconUploadingFinished(file);
    });
  }

  private iconUploadingFinished(file: any) {
    file.uploading = false;
    if (!this.iconsToUpload.filter(f => f.uploading === true).length) {
      this.iconUploading = false;
    }
  }
}
