import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BaseComponent } from '@app/core/components/base.component';
import { Helper } from '@app/core/helpers/helper';
import { HelperService } from '@app/core/services/helper.service';
import { GamificationApiService } from '@app/gamification/services/gamification-api.service';
import { IGift } from '@app/gifts/model/gift';
import { GiftService } from '@app/gifts/services/gift.service';
import { User } from '@app/profile/model/user.model';
import { UsersService } from '@app/profile/services/users.service';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import { AlertsService } from '@app/shared/services/alerts.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-gift-tiles-view',
  templateUrl: './gift-tiles-view.component.html',
  styleUrls: ['./gift-tiles-view.component.scss']
})
export class GiftTilesViewComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input() gifts: IGift[];

  gamificationCountForCurrentUser: number = null;
  currentUser: User;

  constructor(
    public giftService: GiftService,
    public usersService: UsersService,
    public gamificationService: GamificationApiService,
    protected alertsService: AlertsService,
    private dialog: MatDialog,
    public helper: HelperService
  ) { super(helper); }

  ngOnInit(): void {
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;
      this.getCountForUser();
    });
  }

  orderGift(item: IGift) {
    const valueText = Helper.getNoun(item.value, 'балл', 'балла', 'баллов');
    this.dialog.open(ModalConfirmationComponent, {
      data: {
        text: `Вы уверены, что хотите получить этот подарок? С вашего счета будет списано ${item.value} ${valueText}?`,
        okText: 'Да',
        onOk: () => {
          this.giftService.orderGift(item.id)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(res => {
              if (res) {
                this.alertsService.riseSuccess('Подарок был успешно заказан.');
                this.getCountForUser();
              } else {
                this.alertsService.riseError('Произошла ошибка при заказе подарка. Обратитесь к администратору.');
              }
            });
        }
      }
    });
  }

  trackByFunc(index, item) {
    return item.id;
  }

  private getCountForUser() {
    if (this.currentUser) {
      this.gamificationService.getCountForUser(this.currentUser.id)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(res => {
          this.gamificationCountForCurrentUser = res;
        });
    }
  }
}
