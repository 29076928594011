<div class="onboarding-wrapper" #wrapper *ngIf="slides">
    <div class="onboarding">
        <div class="onboarding__controls">
            <button type="button" class="btn onboarding__next" (click)="next()"></button>
            <button type="button" class="btn onboarding__prev" (click)="prev()"></button>

            <div class="onboarding__dots">
                <button
                    type="button"
                    class="btn onboarding__dot"
                    *ngFor="let slide of slides; let i=index;"
                    [ngClass]="{'_active': slide.active}"
                    (click)="set(i)"
                ></button>
            </div>

            <button type="button" class="btn btn-stroke onboarding__skip" (click)="skip()">Пропустить демонстрацию</button>
        </div>

        <div class="onboarding__slide" *ngFor="let slide of slides; let i=index;" [ngClass]="{'_active': slide.active}">
            <img
                class="onboarding__back1200"
                src="{{assetsPrefix}}/assets/images/onboarding/{{page}}/bg_{{slide.bg}}_1200.jpg" alt="">
            <img
                class="onboarding__back1600"
                src="{{assetsPrefix}}/assets/images/onboarding/{{page}}/bg_{{slide.bg}}_1600.jpg" alt="">

            <img
                [style.top]="slide.top1200 + 'px'"
                class="onboarding__slide1200"
                src="{{assetsPrefix}}/assets/images/onboarding/{{page}}/slide_{{slide.slide}}_1200.svg" alt="">
            <img
                [style.top]="slide.top1600 + 'px'"
                class="onboarding__slide1600"
                src="{{assetsPrefix}}/assets/images/onboarding/{{page}}/slide_{{slide.slide}}_1600.svg" alt="">
        </div>
    </div>
</div>

