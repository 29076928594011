<div class="card">
  <span class="header-text _modal">
    <span class="header-text__text">{{headerText}}</span>
  </span>
  <div class="tabs" *ngIf="groupsService.selectedGroup | async">
    <a class="tabs__item" [ngClass]="{'_active':widgetMode==1}" href="javascript:;" (click)="myFiles()">
      <span class="header-text _low">
        <span class="header-text__text">Мои файлы</span>
      </span>
    </a>
    <a class="tabs__item" *ngIf="!data.noGroupFiles" [ngClass]="{'_active':widgetMode==2}" href="javascript:;"
      (click)="groupFiles()">
      <span class="header-text _low">
        <span class="header-text__text">Файлы группы</span>
      </span>
    </a>
  </div>
  <div class="files-search">
    <span class="icon _search"></span>
    <input class="main-input _files-search" type="text" [(ngModel)]="text" (ngModelChange)="text = $event"
      (keyup)="onSearch($event)" placeholder="введите название файла" />
  </div>
  <app-files-breadcrumbs (onNavigate)="navigateBreadcrumb($event)" [folderUrl]="folder" [rootFolderUrl]="rootFolderUrl">
  </app-files-breadcrumbs>
</div>
<div class="files-list">
  <div class="list-table">
    <table class="list-table__inner" *ngIf="loaded && items && items.length">
      <tbody>
        <tr *ngFor="let item of items" [ngClass]="{'_selected':item.checked | async}">
          <td>
            <label class="checkbox _hover" *ngIf="!filesService.isFolder(item)">
              <input type="checkbox" class="checkbox__input" [checked]="item.checked | async"
                (change)="selectItem($event, item)">
              <span class="checkbox__icon"></span>
            </label>
          </td>
          <td (click)="selectItem($event, item)">
            <!--Если папка-->
            <a href="javascript:" *ngIf="filesService.isFolder(item)" (click)="navigate(item)"
              class="link-file _folder-num">
              <span class="link-file__text">{{item.name}}</span>
              <span class="link-file__subtext">{{item.count}}</span>
            </a>
            <!--Если файл-->
            <span #fileLink *ngIf="!filesService.isFolder(item)" [ngClass]="filesService.getFileIcon(item)"
              class="link-file">
              <a class="link-file__text" target="_blank"
                [attr.href]="filesService.webApiGetFileUrl(item, item.uniqueId)"
                [ngStyle]="{'display':'inline'}">{{item.name}}</a>
              <span class="link-file__subtext">
                <ng-container *ngIf="item.size">{{filesService.getSize(item)}} КБ</ng-container>
                <span class="icon _shared" *ngIf="item?.shared?.length"></span>
              </span>
            </span>
          </td>
          <td>{{item.modified | dateSpLike }}</td>
          <td>{{item.editor && item.editor.length ? item.editor[0].title : ''}}</td>
        </tr>
      </tbody>
    </table>
    <div class="list-table__empty" *ngIf="loaded && (!items || !items.length)">
      Нет файлов
    </div>
    <div *ngIf="allowCreateFolder">
      <div class="new-file">
        <input placeholder="Новая папка" class="new-file__name" [(ngModel)]="newFolderName"
          (keyup)="onNewFolderKey($event)">
        <button type="button" class="btn btn-simple icon _close new-file__clear" *ngIf="newFolderName"
          (click)="newFolderName=''"></button>
        <button type="button" class="btn btn-check new-file__add" *ngIf="newFolderName"
          [attr.disabled]="newFolderName?null:''" (click)="createFolder()"></button>
      </div>
    </div>
    <div *ngIf="!loaded">
      <div class="loading _blue _standalone"></div>
    </div>
  </div>
</div>
<div class="confirm _right">
  <div class="confirm__actions" mat-dialog-actions>
    <button type="button" mat-button mat-dialog-close class="btn btn-secondary" (click)="cancel()">Отмена</button>
    <button type="button" mat-button class="btn btn-primary" (click)="ok()">{{okText}}</button>
  </div>
</div>