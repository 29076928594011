<ng-container *ngIf="!hideMenu">
  <div class="btn btn-dots tooltip" mat-button [matMenuTriggerFor]="menu" (onMenuOpen)="onMenuOpen($event)"></div>
  <mat-menu #menu="matMenu">
    <div class="icon-menu">
      <ng-container *ngFor="let m of menuItems">
        <ng-container *ngIf="m.click; then clickAction; else routerAction;"></ng-container>
        <ng-template #clickAction>
          <a mat-menu-item [attr.href]="m.href" class="icon-menu__item" *ngIf="m.showCondition" (click)="m.click()">
            <ng-container *ngTemplateOutlet="menuItemBody"></ng-container>
          </a>
        </ng-template>
        <ng-template #routerAction>
          <a mat-menu-item [attr.href]="m.href" class="icon-menu__item" *ngIf="m.showCondition" [routerLink]="m.routerLink">
            <ng-container *ngTemplateOutlet="menuItemBody"></ng-container>
          </a>
        </ng-template>
        <ng-template #menuItemBody>
          <span class="icon-menu__icon">
            <img src="{{assetsPrefix}}/assets/images/icons/menu/{{m.icon}}" class="icon-menu__img">
          </span>
          <span>{{m.title}}</span>
        </ng-template>
      </ng-container>
    </div>
  </mat-menu>
</ng-container>