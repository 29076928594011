<div class="cos__add">
  <!-- класс _active для приподнимания над всей страницей. Ставить по фокусу в инпут-->
  <!-- в конец body вставить пустой <div class="modal" />-->
  <div class="cos-add">
    <div class="cos-add__tags">
      <div class="tag" *ngFor="let u of usersToAdd">
        <button type="button" class="btn btn-secondary btn-icon tag__button" (click)="removeUser(u)">
            <span class="tag__name">{{u.fullName}}</span>
            <img src="{{assetsPrefix}}/assets/images/icons/menu/remove.svg" />
          </button>
      </div>
    </div>
    <div class="input-main _full">
      <input #inputUser placeholder="{{placeholder}}" class="input-main__field" (keyup)="onSearchKey($event)" (keydown)="onSearchKey($event)"
             [ngModel]="searchText" (ngModelChange)="searchText = $event">
    </div>
    <div class="error-message" *ngIf="data.error">{{data.error}}</div>
    <div class="cos-add__controls" mat-dialog-actions>
      <button type="button" mat-button mat-dialog-close class="btn btn-simple" (click)="cancel()">Отмена</button>
      <button type="button" mat-button class="btn btn-primary" (click)="addUsers()" [ngClass]="{_inactive:!isValid}">Добавить</button>
    </div>
    <div class="cos-add__suggest">
      <div class="co _one-line" *ngFor="let u of usersFound" (click)="addUser(u)">
        <div class="co__avatar">
          <app-user-avatar [user]="u" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'" [noRedirect]="true"></app-user-avatar>
        </div>
        <div class="co__info">
          <a href="javascript:void(0)" class="link co__name">{{u.fullName}}</a>
          <span class="co__occ">{{u.jobTitle}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
