<div class="header" [ngClass]="{'_search-opened':searchService.searchOpened | async}">
    <div class="container _full-page _moved">
        <div class="pull-left">
            <div class="hidden-desktop">
                <app-menu></app-menu>
            </div>
            <a routerLink="/" class="logo icon hidden-desktop-1200" (click)="usersService.navigateRoot()">
                <img src="{{assetsPrefix}}/assets/images/logo-big.svg?rev=6" />
            </a>
            <app-menu-pages-list *ngIf="megaMenu!=null" [megaMenu]="megaMenu" [showMenu]="true" [orientation]="MenuOrientation.horizontal"></app-menu-pages-list>
        </div>
        <div class="pull-right">
            <app-search-box class="header__icon _search"></app-search-box>
            <app-notifications></app-notifications>
            <div class="avatar _header tooltip _bottom hidden-desktop-1200" *ngIf="user">
                <div class="tooltip__body">
                    <div class="icon-menu">
                        <app-left-menu-items [cssClass]="'icon-menu__item'"></app-left-menu-items>
                    </div>
                </div>
                <app-user-avatar [user]="user" [linkClass]="'avatar _30'" [imageClass]="'avatar__image'" [noTooltip]="true"></app-user-avatar>
            </div>
        </div>
    </div>
</div>
<div class="container _full-page _moved _subheader">
    <app-mission></app-mission>
</div>
