<div class="container _moved _full-page">
  <div class="santa">
    <div *ngIf="isShowNotFinished()">
      <div *ngIf="isUserRegistered()">
        <h1 class="santa__title">ПОЗДРАВЛЯЕМ! <br> ВЫ СТАЛИ САНТОЙ!</h1><img src="{{assetsPrefix}}/assets/images/santa/santa-congrad-desktop.svg" class="santa__picture">
        <p class="santa__desc">До 14 декабря вам на корпоративную электронную почту поступит сообщение с именем коллеги, для которого вы станете Тайным Сантой, а также инструкция с дальнейшими действиями.</p>
      </div>
      <div *ngIf="!isUserRegistered()">
        <h1 class="santa__title">ТАЙНЫЙ САНТА</h1>
        <p class="santa__desc">Хотите сделать приятный сюрприз коллеге и получить неожиданный подарок к Новому году? Участвуйте в акции "Тайный Санта"!</p>
        <h2 class="santa__subtitle">Три простых шага<br/> стать Сантой</h2>
        <ol class="santa__list">
          <li>Ознакомьтесь с условиями участия<br><a target="_blank" href="/groups/group2660/Shared Documents/Условия_Тайный санта.docx?d=wdb2ebbb3984b46dd9c29d1ed47642494"> Как стать Тайным Сантой?</a></li>
          <li>Нажмите кнопку «Участвую»</li>
          <li>Ожидайте секретное письмо</li>
        </ol>
        <div class="santa__submit">
          <button type="button" class="btn btn-primary" *ngIf="!userRelationsFinished" (click)="participate()"
            matTooltip="Участвовать в Тайном санте" matTooltipPosition="above" [disabled]="!isUserCheckedAgreement">УЧАСТВУЮ</button>
          <label class="checkbox">
            <input type="checkbox" [checked]="isUserCheckedAgreement" (change)="isUserCheckedAgreement = !isUserCheckedAgreement" name="santa-agreement" class="checkbox__input"><span class="checkbox__icon"></span><span class="checkbox__text">Нажимая кнопку, вы соглашаетесь со всеми условиями участия</span>
          </label>
        </div>
      </div>

      <div class="santa__pairs" *ngIf="currentUser?.isAdmin">
        <h2 class="santa__subtitle" *ngIf="userRelationsPairs?.length">Все участники</h2>
        <app-user-simple-card [user]="userRelation.user1" *ngFor="let userRelation of userRelationsPairs">
        </app-user-simple-card>
      </div>
      <div class="santa__submit">
        <button type="button" class="btn btn-simple" *ngIf="currentUser?.isAdmin" (click)="start()"
          matTooltip="Создать пары" matTooltipPosition="above">Создать пары</button>
      </div>
    </div>

    <div *ngIf="isShowFinished()">
      <h1 class="santa__title">ПОДАЧА ЗАЯВОК<br/>ЗАВЕРШЕНА</h1>
      <div *ngIf="currentUser?.isAdmin">
        <div class="santa__pairs" *ngIf="userRelationSingle">
          <h2 class="santa__subtitle">Сотрудник без пары</h2>
          <app-user-simple-card [user]="userRelationSingle.user1"></app-user-simple-card>
        </div>
        <div class="santa__pairs" *ngIf="userRelationsPairs?.length">
          <h2 class="santa__subtitle">Сформированные пары</h2>
          <div class="santa__pair" *ngFor="let userRelation of userRelationsPairs">
            <app-user-simple-card [user]="userRelation.user1"></app-user-simple-card>
            <app-user-simple-card [user]="userRelation.user2"></app-user-simple-card>
          </div>
        </div>

        <div class="santa__submit">
          <button type="button" class="btn btn-simple" *ngIf="currentUser?.isAdmin" (click)="sendNotifications()"
          matTooltip="Отправить уведомления" matTooltipPosition="above">Отправить уведомления</button>
          <br>
          <button type="button" class="btn btn-simple" (click)="removeAll()" matTooltip="Повторить Санту"
            matTooltipPosition="above">Повторить Санту (сбросить все)</button>
          <div class="text-secondary">Список пар будет сброшен</div>
<!--          <button type="button" class="btn btn-simple" (click)="removeAll()" matTooltip="Удалить всё" matTooltipPosition="above">Удалить всё</button>-->
        </div>
        <div *ngIf="userRelationsFinished && loaded && usersNotificated===null">
          Пользователи уведомляются
        </div>
        <div *ngIf="userRelationsFinished && loaded && usersNotificated" style="margin-top: 1rem;">
          <div *ngIf="userEmailSentFull">
            email письма пользователям отправлены
          </div>
          <div *ngIf="userEmailSentFull===false">
            email письма пользователям отправлены не всем. Не были отправлены: {{(unSuccessfullySent()).length}} шт. пользователям:
            <div [innerHTML]="unSuccessfullySentUsers()"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- list preloader -->
    <app-tabbed-list-preloader *ngIf="currentUser?.isAdmin && !loaded"></app-tabbed-list-preloader>
    <!--  list preloader END -->
  </div>

<!--
    <div class="santa">
      <h1 class="santa__title">ТАЙНЫЙ САНТА</h1>
      <p class="santa__desc">Хотите сделать приятный сюрприз коллеге и получить неожиданный подарок к Новому году? Участвуйте в акции "Тайный Санта"!</p>
      <h2 class="santa__subtitle">Три простых шага<br/> стать Сантой</h2>
      <ol class="santa__list">
        <li>Ознакомьтесь с условиями участия<br><a href="#"> Как стать Тайным Сантой?</a></li>
        <li>Нажмите кнопку «Участвую»</li>
        <li>Ожидайте секретное письмо</li>
      </ol>
      <div class="santa__submit">
        <button type="button" class="btn btn-primary">УЧАСТВУЮ</button>
        <label class="checkbox">
          <input type="checkbox" name="search-dir" class="checkbox__input"><span class="checkbox__icon"></span><span class="checkbox__text">Нажимая кнопку, вы соглашаетесь со всеми условиями участия</span>
        </label>
        <button type="button" class="btn btn-simple">Создать пары</button>
      </div>
    </div>
    <div class="santa">
      <h1 class="santa__title">ПОЗДРАВЛЯЕМ! <br> ВЫ СТАЛИ САНТОЙ!</h1><img src="/images/santa/santa-congrad-desktop.svg" class="santa__picture">
      <p class="santa__desc">До 14 декабря вам на корпоративную электронную почту поступит сообщение с именем коллеги, для которого вы станете Тайным Сантой, а также инструкция с дальнейшими действиями.</p>
      <div class="santa__submit">
        <button type="button" class="btn btn-simple">Создать пары</button>
      </div>
    </div>
    <div class="santa">
      <h1 class="santa__title">ПОДАЧА ЗАЯВОК <br> ЗАВЕРШЕНА</h1>
      <p class="santa__desc">Спасибо всем, кто принял участие!</p>
      <div class="santa__pairs">
        <h2 class="santa__subtitle">Сотрудник без пары</h2>
        <div class="co">
        </div>
      </div>
      <div class="santa__pairs">
        <h2 class="santa__subtitle">Сформированные пары</h2>
        <div class="santa__pair">
          <div class="co">
          </div>
          <div class="co">
          </div>
        </div>
        <div class="santa__pair">
          <div class="co">
          </div>
          <div class="co">
          </div>
        </div>
      </div>
      <div class="santa__submit">
        <button type="button" class="btn btn-simple">Повторить Санту</button>
        <div class="text-secondary">Список пар будет сброшен</div>
      </div>
    </div>
  </div>
-->