import {Component, OnDestroy, Input} from '@angular/core';
import {GroupsService} from '@app/groups/services/groups.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {GroupInfo} from '@app/groups/model/group-info.model';
import {BaseComponent} from '@app/core/components/base.component';
import {HelperService} from '@app/core/services/helper.service';
import {GroupForbiddenType} from '@app/groups/model/group-forbidden-type';
import {GroupType} from '@app/groups/model/group-type.model';
import {UsersService} from '@app/profile/services/users.service';
import {User} from '@app/profile/model/user.model';

/**
 * Компонент с текстом "Чтобы увидеть контент группы, вам необходимо
 * вступить в нее или подписаться"
 *
 * @export
 * @class GroupForbiddenComponent
 */
@Component({
  selector: 'app-group-forbidden',
  templateUrl: './group-forbidden.component.html',
  styleUrls: ['./group-forbidden.component.scss'],
})
export class GroupForbiddenComponent extends BaseComponent implements OnDestroy {
  /**
   * Тип отрисовки компонента (отличается вёрсткой)
   *
   * @type {GroupForbiddenType}
   * @memberof GroupForbiddenComponent
   */
  @Input() type: GroupForbiddenType;
  @Input() loaded: boolean;

  GroupForbiddenType = GroupForbiddenType;

  /**
   * Группа
   *
   * @type {GroupInfo}
   * @memberof GroupForbiddenComponent
   */
  group: GroupInfo;
  currentUser: User;

  constructor(private usersService: UsersService, private groupsService: GroupsService, public helper: HelperService) {
    super(helper);
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;
    });
    this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
      this.group = group;
    });
  }

  isVisibleForbidden() {
    return this.group && !this.groupsService.isAllowToViewGroupContent(this.group, this.currentUser);
  }
}
