import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData, CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppComponent } from '@app/app.component';
import { AppRoutingModule } from '@app/app.routing';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import { MatDialogModule, MatDialog } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import localeRU from '@angular/common/locales/ru';
import { SharedModule } from '@app/shared/shared.module';
import { SignalRModule } from '@app/signalr/signalR.module';
import { ProfileModule } from '@app/profile/profile.module';
import { SubscribeModule } from '@app/subscribe/subscribe.module';
import { GroupsModule } from '@app/groups/groups.module';
import { FilesModule } from '@app/files/files.module';
import { FeedModule } from '@app/feed/feed.module';
import { GalleryModule } from '@app/gallery/gallery.module';
import { SearchModule } from '@app/search/search.module';
import { FruitfacesModule } from '@app/fruitfaces/fruitfaces.module';
import { NotificationsModule } from './notifications/notifications.module';
import { EnvironmentSettings } from './core/model/environment-settings';
import { environment } from '@env/environment';
import { ENV_SETTINGS } from './core/services/helper.service';
import { FeedbackModule } from './feedback/feedback.module';
import { AdminModule } from './admin/admin.module';
import { MainModule } from './main/main.module';
import { HeaderModule } from './header/header.module';
import { EmployeesModule } from './employees/employees.module';
import { ThanksModule } from './thanks/thanks.module';
import {
    MomentDateAdapter,
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MatMomentDateModule
} from '@angular/material-moment-adapter';
import { IntryAuthModule } from './auth/auth.module';
import { IntryAuthInterceptor } from './auth/shared/interceptors/intry-auth.interceptor';
import { GetCurrentUserResolver } from './groups/resolvers/current-user-resolver';
import { IntryMissingIdTokenDuringRefreshInterceptor } from './auth/shared/interceptors/intry-missing-id-token.interceptor';

registerLocaleData(localeRU);

export const INTRY_FORMATS: MatDateFormats = {
    parse: {
        dateInput: 'DD.MM.YYYY',
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatMenuModule,
        MatDialogModule,
        MatMomentDateModule,
        SharedModule,
        SignalRModule,
        HeaderModule,
        MainModule,
        ProfileModule,
        SubscribeModule,
        GroupsModule,
        FilesModule,
        MatProgressSpinnerModule,
        FeedModule,
        GalleryModule,
        SearchModule,
        NotificationsModule,
        FeedbackModule,
        AdminModule,
        EmployeesModule,
        ThanksModule,
        IntryAuthModule.forRoot(environment.auth),
        FruitfacesModule,
        AppRoutingModule
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        MatDialog,
        // GetCurrentUserResolver,
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
        { provide: MAT_DATE_FORMATS, useValue: INTRY_FORMATS },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: LOCALE_ID, useValue: 'ru' },
        {
            provide: ENV_SETTINGS,
            useFactory: () => {
                const environmenetSettings: EnvironmentSettings = {
                    production: environment.production,
                    siteUrl: environment.siteUrl,
                    username: environment.username,
                    password: environment.password,
                    assetsPrefix: environment.assetsPrefix,
                    startPage: environment.startPage
                };
                return environmenetSettings;
            },
            multi: false
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: IntryAuthInterceptor,
            multi: true
        },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: IntryMissingIdTokenDuringRefreshInterceptor,
        //     multi: true
        // },
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule { }
