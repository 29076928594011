import { Helper } from '@app/core/helpers/helper';
import { BehaviorSubject } from 'rxjs';
import { RowUser, FSObjType } from '@app/files/model/list-data';
import { FileVersion } from '@app/files/model/file-version.model';

export enum FilesListItemType {
    File = 0,
    Folder = 1
}

export class FilesListItem {
    id: string;
    name: string;
    title: string;
    url: string;
    type: FilesListItemType;
    count: number;
    created: Date;
    modified: Date;
    editor: Array<RowUser>;
    size: string;
    shared: Array<RowUser>;
    uniqueId: string;
    version: string;
    versions: FileVersion[];
    originalPath: string;
    itemType: string;
    // UI
    checked: BehaviorSubject<boolean>;
    link: string;
    isImage: boolean;
    isVideo: boolean;
    fileUser: any;
    permMask: string;

    constructor() {
        this.editor = [];
        this.shared = [];
    }

    public static fromSpFile(file: IRootOdataResponseModel): FilesListItem {

        const item = new FilesListItem();

        item.name = file.title ? file.title : file.name;
        item.title = file.title;
        item.url = file.serverRelativeUrl;
        item.id = file.id;
        // tslint:disable-next-line:triple-equals
        item.type = file.fSObjType == FSObjType.File ? FilesListItemType.File : FilesListItemType.Folder;
        item.size = file.length;
        item.modified = file.timeLastModified;
        item.uniqueId = file.uniqueId;
        item.version = file.uIVersionLabel;
        item.checked = new BehaviorSubject<boolean>(false);
        // if (file.modifiedBy) {
        //     item.editor = [];
        //     item.editor.push({
        //         id: file.modifiedBy.Id,
        //         email: file.modifiedBy.Email,
        //         title: file.modifiedBy.Title,
        //         value: file.modifiedBy.LoginName
        //     });
        // }
        // if (file.versions) {
        //     item.versions = file.versions;
        // }

        // if (file.sharedWithUsers) {
        //     item.shared = file.sharedWithUsers;
        // }
        return item;
    }
}

export class FilesListItemShared extends FilesListItem {
    listId: string;
    constructor() {
        super();
    }
}

export class IntryFilesList {
    listId: string;
    rootFolder: string;
    items: Array<FilesListItem>;
    listUrlDir: string;
    prevHref: string;
    nextHref: string;
    lastRow: number;
    firstRow: number;
    totalItems: number;
    portalUrl: string;
    folderPermissions: string;
    newWOPIDocumentEnabled: boolean;

    constructor() {
        this.items = [];
    }
}

export class FilesList {
    listId: string;
    rootFolder: string;
    items: Array<FilesListItem>;
    listUrlDir: string;
    prevHref: string;
    nextHref: string;
    lastRow: number;
    firstRow: number;
    totalItems: number;
    portalUrl: string;
    folderPermissions: string;
    newWOPIDocumentEnabled: boolean;

    constructor() {
        this.items = [];
    }
}

export class FolderItem {
    Exists: boolean;
    IsWOPIEnabled: boolean;
    ItemCount: number;
    Name: string;
    ProgID: any;
    ServerRelativeUrl: string;
    TimeCreated: Date;
    TimeLastModified: Date;
    UniqueId: string;
}

export class Breadcrumb {
    public name: string;
    public url: string;
}

export enum PermMask {
    EmptyMask = '0x0000000000000000',
    ViewListItems = '0x0000000000000001',
    AddListItems = '0x0000000000000002',
    EditListItems = '0x0000000000000004',
    DeleteListItems = '0x0000000000000008',
    ApproveItems = '0x0000000000000010',
    ManageLists = '0x0000000000000800',
    FullMask = '0x7fffffffffffffff',
    Edit = '0x1b03c431aef',
    Input = '0x1b03c4312ef',
    Design = '0x1b03c5f1bff',
    OnlyView = '0xb008431041'
}

export enum DocumentTemplateType {
    Word = 1,
    Excel = 2,
    PowerPoint = 3,
    OneNote = 4
}

export interface IRootOdataResponseModel {
    odataError: string;
    odataMetadata: string;
    odataType: string;
    odataId: string;
    odataEditLink: string;
    checkInComment: string;
    checkOutType: number;
    contentTag: string;
    customizedPageStatus: number;
    eTag: string;
    exists: boolean;
    irmEnabled: boolean;
    length: string;
    level: number;
    linkingUrl: string;
    majorVersion: number;
    minorVersion: number;
    fSObjType: number;
    name: string;
    serverRelativeUrl: string;
    timeCreated: Date;
    timeLastModified: Date;
    title: string;
    uIVersion: number;
    uIVersionLabel: string;
    uniqueId: string;
    value: string;
    id: string;
}
