export interface EditorOptions {
  htmlAllowedTags: string[];
  htmlRemoveTags: string[];
  htmlDoNotWrapTags: string[];
  events?: any;
  creditLabel?: boolean;
  key: string;
  language: string;
  placeholderText?: string;
  paragraphFormat?: {
    N: string,
    H1: string,
    H2: string
  };
  pluginsEnabled: string[];
  imageInsertButtons: string[];
  imageUploadRemoteUrls: boolean;
  imageUpload?: boolean;
  tableEditButtons?: string[];
  tableStyles?: any | null;
  toolbarButtons: string[];
  listAdvancedTypes: boolean;
  fontFamily: any;
  videoUploadParam?: string;
  videoUploadURL?: string;
  videoUploadMethod?: string | null;
  videoUploadParams?: any;
  videoMaxSize?: number;
  videoAllowedTypes?: string[];
  requestHeaders?: any;
  fileUploadParam?: string;
  fileUploadURL?: string;
  fileUploadParams?: any;
  fileUploadMethod?: string | null;
  fileMaxSize?: number;
  fileAllowedTypes?: string[];
  imageUploadParam?: string;
  imageUploadURL?: string;
  imageUploadMethod?: string | null;
  imageUploadParams?: any;
  imageMaxSize?: number;
  imageAllowedTypes?: string[];
  toolbarInline?: boolean;
  toolbarSticky?: boolean;
  toolbarStickyOffset?: number;
  initOnClick?: boolean;
  attribution?: boolean;
  charCounterCount?: boolean;
  height?: number;
}

export const FroalaEditorOptions: EditorOptions = {
  htmlAllowedTags: [
    'a', 'abbr', 'address', 'area', 'article', 'aside', 'audio', 'b', 'base', 'bdi', 'bdo', 'blockquote',
    'br', 'button', 'canvas', 'caption', 'cite', 'code', 'col', 'colgroup', 'datalist', 'dd', 'del', 'details',
    'dfn', 'dialog', 'div', 'dl', 'dt', 'em', 'embed', 'fieldset', 'figcaption', 'figure', 'footer', 'form',
    'h1', 'h2', 'h3', 'header', 'hgroup', 'hr', 'i', 'img', 'ins', 'kbd', 'keygen', 'label', 'legend', 'li',
    'link', 'main', 'map', 'mark', 'menu', 'menuitem', 'meter', 'nav', 'object', 'ol', 'optgroup', 'option',
    'output', 'p', 'param', 'pre', 'progress', 'queue', 'rp', 'rt', 'ruby', 's', 'samp', 'select', 'small',
    'source', 'span', 'strike', 'strong', 'sub', 'summary', 'sup', 'table', 'tbody', 'td', 'tfoot', 'th',
    'thead', 'time', 'title', 'tr', 'track', 'u', 'ul', 'var', 'video', 'wbr', 'iframe', 'script', 'style'],
  htmlDoNotWrapTags: ['h1', 'script', 'style', 'img'],
  htmlRemoveTags: [],
  language: 'ru',
  creditLabel: false,
  key: 'eHE5C-11E3B2D2F1C6D5A3td1CGHNOa1TNSPH1e1J1VLPUUCVd1FC-22C4A3C3D2D4G2I2C3B3C1==',
  paragraphFormat: {
    N: 'Обычный',
    H1: 'Заголовок 1',
    H2: 'Заголовок 2'
  },
  pluginsEnabled: [
    'align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly',
    'emoticons', 'entities', 'file', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageManager',
    'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quote', 'save',
    'table', 'url', 'video', 'wordPaste'
  ],
  imageInsertButtons: ['imageUpload', 'imageByURL'],
  imageUploadRemoteUrls: false,
  tableEditButtons: [
    'tableHeader', 'tableRemove', '|', 'tableRows', 'tableColumns',
    '-', 'tableCells', 'tableCellBackground', 'tableCellVerticalAlign', 'tableCellHorizontalAlign'
  ],
  tableStyles: {
    'wiki-table--borders': 'С границами',
    'wiki-table--no-borders': 'Без границ'
  },
  toolbarButtons: [
    'bold', 'italic', 'underline', '|',
    'paragraphFormat', '|',
    'align', 'formatOL', 'formatUL', '|',
    'insertImage', 'insertLink', 'insertVideo', 'insertTable', '|',
    'undo', 'redo', 'clearFormatting', '|',
    'html', 'fullscreen'
  ],
  fontFamily: {
    'ProbaPro, sans-serif': 'ProbaPro'
  },
  listAdvancedTypes: false, // маркированный и числовой список без выпадашек
  videoUploadParam: 'file',
  videoUploadMethod: 'PUT',
  videoMaxSize: 500 * 1024 * 1024,
  videoAllowedTypes: ['webm', 'mp4', 'ogg'],
  fileUploadParam: 'file',
  fileUploadMethod: 'PUT',
  fileMaxSize: 500 * 1024 * 1024,
  fileAllowedTypes: ['*'],
  imageUploadParam: 'file',
  imageUploadMethod: 'PUT',
  imageMaxSize: 500 * 1024 * 1024,
  imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif', 'webp'],
  toolbarInline: false,
  toolbarSticky: true,
  toolbarStickyOffset: 50,
  initOnClick: false,
  attribution: false,
  charCounterCount: false
};
