import {Component, Input, Output, EventEmitter, ViewChild, OnDestroy} from '@angular/core';
import {GroupInfo} from '@app/groups/model/group-info.model';
import {User} from '@app/profile/model/user.model';
import {Router} from '@angular/router';
import {UsersService} from '@app/profile/services/users.service';
import {BaseComponent} from '@app/core/components/base.component';
import {GroupsService} from '@app/groups/services/groups.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatMenuTrigger} from '@angular/material/menu';
import {ModalConfirmationComponent} from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import {ModalResultComponent} from '@app/shared/components/modals/modal-result/modal-result.component';
import {MenuItem} from '@app/shared/menu-item';
import {AlertsService} from '@app/shared/services/alerts.service';
import {Tab} from '@app/shared/components/tabs/tab.model';
import {GroupType} from '@app/groups/model/group-type.model';
import {IGroupUser} from '@app/groups/model/group-request.model';
import {GroupRequestState} from '@app/groups/model/group-request-state.model';
import {setTimeout} from 'core-js/library/web/timers';
import {GroupUserRole} from '@app/groups/model/group-user-role.model';
import {HelperService} from '@app/core/services/helper.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {GroupUsersFragment} from '../group-users/group-users-list.component';
import {GroupUserStateChangedType} from '@app/groups/model/group-user-state-changed';
import {UserLink} from '@app/subscribe/model/user-link';
import {SubscribeService} from '@app/subscribe/services/subscribe.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

/**
 * Меню элемента группы
 *
 * @export
 * @class GroupMenuComponent
 * @extends {BaseComponent}
 */
@Component({
  selector: 'app-group-menu',
  templateUrl: 'group-menu.component.html',
})
export class GroupMenuComponent extends BaseComponent implements OnDestroy {
  @Output() onJoin: EventEmitter<any> = new EventEmitter();
  @Output() onLeave: EventEmitter<any> = new EventEmitter();
  @Output() onRemoveModerator: EventEmitter<any> = new EventEmitter();
  @Output() onUnsubscribe: EventEmitter<any> = new EventEmitter();
  @Output() onSubscribe: EventEmitter<any> = new EventEmitter();
  @Output() onRemove: EventEmitter<any> = new EventEmitter();

  @Input() group: GroupInfo;
  @Input() user: User;
  @Input() groupUser: IGroupUser;
  @Input() userLink: UserLink;
  @Input() type: GroupMenuType;
  @Input() currentTab: Tab<User>;

  @Input() hideJoin: boolean;
  @Input() hideSubscribe: boolean;
  @Input() hideDelete: boolean;

  deleting: boolean;
  deleted: boolean;

  @Input() hideMenu = true;

  menuType = GroupMenuType;

  deletingDialog: MatDialogRef<ModalConfirmationComponent>;

  menuItems: MenuItem[];
  currentUser: User;

  @ViewChild(MatMenuTrigger) menu: MatMenuTrigger;

  constructor(
    private subscribeService: SubscribeService,
    private router: Router,
    private usersService: UsersService,
    private groupsService: GroupsService,
    private alertsService: AlertsService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    public helper: HelperService,
  ) {
    super(helper);
  }

  /**
   * При открытии меню
   */
  onMenuOpen(e) {
    this.initItems();
  }

  initItems() {
    const href = this.sanitizer.bypassSecurityTrustUrl('javascript:;');

    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;
      if (!this.menuItems || !this.menuItems.length) {
        // add items in menu
        this.menuItems = [
          {
            href,
            title: 'Настройка',
            icon: 'settings.svg',
            click: this.redirectToSettings.bind(this),
            showCondition:
              this.user.id === currentUser.id &&
              this.groupsService.isAdmin(this.group, currentUser) &&
              !this.currentTab,
          },
          {
            href,
            title: 'Выйти',
            icon: 'exit.svg',
            click: this.leaveSelf.bind(this),
            showCondition: this.user.id === currentUser.id && this.isMemberOrAdmin(),
          },
          {
            href,
            title: 'Удалить из группы',
            icon: 'exit.svg',
            click: this.leaveUser.bind(this),
            showCondition:
              this.user.id !== currentUser.id &&
              (this.isAdminOnMembersTab() || this.isAdminOnAdminsTab() || currentUser.isAdmin) &&
              this.currentTab &&
              (this.currentTab.id === 1 || this.currentTab.id === 3),
          },
          {
            href,
            title: 'Вступить',
            icon: 'exit.svg',
            click: this.join.bind(this),
            showCondition:
              ((!this.groupsService.isMember(this.group) && !this.groupsService.isAdmin(this.group, currentUser)) ||
                this.groupsService.isDeclined(this.group, this.group.memberState)) &&
              !this.groupsService.isRequested(this.group, this.group.memberState) &&
              this.group.type !== GroupType.Business &&
              !this.hideJoin,
          },
          {
            href,
            title: 'Отменить запрос на вступление',
            icon: 'exit.svg',
            click: this.leaveSelf.bind(this),
            showCondition:
              (this.groupsService.isRequested(this.group, this.group.memberState) ||
                (this.groupUser &&
                  this.groupUser.role === GroupUserRole.Member &&
                  this.groupUser.state === GroupRequestState.Active)) &&
              this.user.id === currentUser.id &&
              this.group.type !== GroupType.Business,
          },
          {
            href,
            title: 'Отписаться',
            icon: 'rss.svg',
            click: this.unsubscribeSelf.bind(this),
            showCondition:
              this.groupsService.isSubscriber(this.group) &&
              this.user.id === currentUser.id &&
              this.group.type !== GroupType.Business,
          },
          {
            href,
            title: 'Удалить из подписчиков',
            icon: 'remove.svg',
            click: this.unsubscribeUser.bind(this),
            showCondition:
              (this.groupsService.isAdmin(this.group, currentUser) || currentUser.isAdmin) &&
              this.user.id !== currentUser.id &&
              this.currentTab &&
              this.currentTab.id === 2 &&
              this.group.type !== GroupType.Business,
          },
          {
            href,
            title: 'Подписаться',
            icon: 'rss.svg',
            click: this.subscribe.bind(this),
            showCondition:
              (!this.groupsService.isSubscriber(this.group) ||
                this.groupsService.isDeclined(this.group, this.group.subscriberState)) &&
              !this.groupsService.isRequested(this.group, this.group.subscriberState) &&
              this.user.id === currentUser.id &&
              this.group.type !== GroupType.Business &&
              !this.hideSubscribe,
          },
          {
            href,
            title: 'Отменить запрос на подписку',
            icon: 'rss.svg',
            click: this.unsubscribeSelf.bind(this),
            showCondition:
              (this.groupsService.isRequested(this.group, this.group.subscriberState) ||
                (this.groupUser &&
                  this.groupUser.role === GroupUserRole.Subscriber &&
                  this.groupUser.state === GroupRequestState.Active)) &&
              this.user.id === currentUser.id &&
              this.group.type !== GroupType.Business,
          },
          {
            href,
            title: 'Удалить',
            icon: 'remove.svg',
            click: this.removeGroup.bind(this),
            showCondition:
              (this.groupsService.isAdmin(this.group, currentUser) || currentUser.isAdmin) &&
              this.type === this.menuType.Group &&
              !this.hideDelete,
          },
        ];

        if (
          this.groupUser &&
          this.groupUser.state === GroupRequestState.Active &&
          this.currentTab &&
          this.currentTab.id === 4 &&
          this.group.type === GroupType.Closed &&
          this.groupsService.isAdmin(this.group, currentUser)
        ) {
          this.menuItems.push({
            href,
            title: 'В участники',
            icon: 'exit.svg',
            click: this.approveJoin.bind(this),
            showCondition: this.groupUser.role === GroupUserRole.Member || currentUser.isAdmin,
          });
          this.menuItems.push({
            href,
            title: 'В подписчики',
            icon: 'exit.svg',
            click: this.approveSubscribe.bind(this),
            showCondition: this.groupUser.role === GroupUserRole.Subscriber || currentUser.isAdmin,
          });
          this.menuItems.push({
            href,
            title: 'Удалить запрос в подписчики',
            icon: 'exit.svg',
            click: this.declineSubscribe.bind(this),
            showCondition: this.groupUser.role === GroupUserRole.Subscriber || currentUser.isAdmin,
          });
          this.menuItems.push({
            href,
            title: 'Удалить запрос в участники',
            icon: 'exit.svg',
            click: this.declineJoin.bind(this),
            showCondition: this.groupUser.role === GroupUserRole.Member || currentUser.isAdmin,
          });
        }

        // подписка на пользователя из меню действия над пользователем группы
        if (this.userLink && this.user.id !== currentUser.id) {
          this.addSubscribeToUserMenuItem(href);
          this.addUnSubscribeFromUserMenuItem(href);
          // отслеживаем изменение состояния подписки
          this.subscribeService.subscribedOnUser$.pipe(takeUntil(this.destroyed$)).subscribe(userId => {
            if (userId && this.userLink.user.id === userId) {
              this.menuItems = this.menuItems.filter(s => s.group !== 'subscribeToUser');
              this.addUnSubscribeFromUserMenuItem(href);
            }
          });
          this.subscribeService.unsubscribedFromUser$.pipe(takeUntil(this.destroyed$)).subscribe(userId => {
            if (userId && this.userLink.user.id === userId) {
              this.menuItems = this.menuItems.filter(s => s.group !== 'subscribeToUser');
              this.addSubscribeToUserMenuItem(href);
            }
          });
        }

        this.hideMenu = !this.menuItems || !this.menuItems.length || !this.menuItems.find(s => s.showCondition);
      }
    });
  }

  private addSubscribeToUserMenuItem(href: SafeUrl) {
    this.menuItems.push({
      href,
      title: 'Подписаться на пользователя',
      group: 'subscribeToUser',
      icon: 'rss.svg',
      click: this.subscribeService.changeSubscribe.bind(this.subscribeService, this.userLink),
      showCondition: !this.subscribeService.isSubscribed(this.userLink),
    });
  }

  private addUnSubscribeFromUserMenuItem(href: SafeUrl) {
    this.menuItems.push({
      href,
      title: 'Отписаться от пользователя',
      group: 'subscribeToUser',
      icon: 'rss.svg',
      click: this.subscribeService.changeSubscribe.bind(this.subscribeService, this.userLink),
      showCondition: this.subscribeService.isSubscribed(this.userLink),
    });
  }

  checkVisibility() {
    const that = this;
    setTimeout(() => {
      that.initItems();
    }, 100);
  }

  private isMemberOnMembersTab() {
    return (
      this.groupsService.isMember(this.group) && this.currentTab && this.currentTab.name === GroupUsersFragment.Members
    );
  }

  private isAdminOnAdminsTab() {
    return (
      this.groupsService.isAdmin(this.group, this.currentUser) &&
      this.currentTab &&
      this.currentTab.name === GroupUsersFragment.Admins &&
      this.currentTab.itemsCount > 1
    );
  }

  private isAdminOnMembersTab() {
    return (
      this.groupsService.isAdmin(this.group, this.currentUser) && this.currentTab && this.currentTab.name === GroupUsersFragment.Members
    );
  }

  private isMemberOrAdmin() {
    return (
      this.groupsService.isMember(this.group) ||
      (this.groupsService.isAdmin(this.group, this.currentUser) &&
        ((this.currentTab && this.currentTab.name === GroupUsersFragment.Admins && this.currentTab.itemsCount > 1) ||
          !this.currentTab))
    );
  }

  approveSubscribe() {
    this.approve(GroupUserRole.Subscriber);
  }

  approveJoin() {
    this.approve(GroupUserRole.Member);
  }

  declineSubscribe() {
    this.decline(GroupUserRole.Subscriber);
  }

  declineJoin() {
    this.decline(GroupUserRole.Member);
  }

  private approve(role: GroupUserRole) {
    this.groupsService.approveRequest(this.groupUser.groupID, this.groupUser.userID, role).subscribe(
      res => {
        if (res) {
          this.updateCurrentUserCounters();
          this.onJoin.emit({res: this.groupUser.userID, success: res, role: role});
        } else {
          this.alertsService.riseError('Произошла ошибка при попытке утвердить запрос');
        }
      },
      error => {
        this.alertsService.riseError('Произошла ошибка при попытке утвердить запрос');
        this.onJoin.error('error on joining');
      },
    );
  }

  private decline(role: GroupUserRole) {
    this.groupsService.declineRequest(this.groupUser.groupID, this.groupUser.userID, role).subscribe(
      res => {
        if (res) {
          this.updateCurrentUserCounters();
          this.onLeave.emit({res: this.groupUser.userID, success: res, role: role});
        } else {
          this.alertsService.riseError('Произошла ошибка при попытке отклонить запрос');
        }
      },
      error => {
        this.alertsService.riseError('Произошла ошибка при попытке отклонить запрос');
        this.onLeave.error('error on joining');
      },
    );
  }

  join() {
    this.groupsService.join(this.group.id).subscribe(
      res => {
        if (res) {
          this.updateCurrentUserCounters();
          this.onJoin.emit({res: this.group.id, success: res});
          this.groupsService.userMemberStateChanged$.next({
            userId: this.user.id,
            groupId: this.group.id,
            state: GroupUserStateChangedType.joined,
          });
        } else {
          this.alertsService.riseError('Произошла ошибка при попытке вступить в группу');
        }
      },
      error => {
        this.alertsService.riseError('Произошла ошибка при попытке вступить в группу');
        this.onJoin.error('error on joining');
      },
    );
  }

  private redirectToSettings() {
    this.router.navigate(['group', this.group.id, 'settings']);
  }

  private leaveSelf() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.leave(currentUser.id, true);
    });
  }

  private leaveUser() {
    this.leave(this.user.id);
  }

  leave(userId: number, update: boolean = false) {
    this.groupsService.leave(this.group.id, userId).subscribe(
      res => {
        if (res) {
          this.updateCurrentUserCounters();
          this.onLeave.emit({res: userId, success: res});
          if (update) {
            this.group.isMember = false;
            this.groupsService.currentGroup.next(this.group);
            this.groupsService.userMemberStateChanged$.next({
              userId: this.user.id,
              groupId: this.group.id,
              state: GroupUserStateChangedType.leaved,
            });
          }
        } else {
          this.alertsService.riseError('Произошла ошибка при попытке выйти из группы');
        }
      },
      error => {
        this.alertsService.riseError('Произошла ошибка при попытке выйти из группы');
        this.onLeave.error('error on leaving');
      },
    );
  }

  removeModerator() {
    this.groupsService.removeModerator(this.group.id, this.user.id).subscribe(
      res => {
        if (res) {
          this.updateCurrentUserCounters();
          this.onRemoveModerator.emit({res: this.user.id, succes: res});
        } else {
          this.alertsService.riseError('Произошла ошибка при попытке удалить администратора из группы');
        }
      },
      error => {
        this.alertsService.riseError('Произошла ошибка при попытке удалить администратора из группы');
        this.onLeave.error('error on leaving');
      },
    );
  }

  public subscribe() {
    this.groupsService.subscribe(this.group.id).subscribe(
      res => {
        if (res) {
          this.updateCurrentUserCounters();
          this.onSubscribe.emit({res: this.user.id, succes: res});
          this.group.isSubscriber = true;
          this.groupsService.currentGroup.next(this.group);
          this.groupsService.userMemberStateChanged$.next({
            userId: this.user.id,
            groupId: this.group.id,
            state: GroupUserStateChangedType.joined,
          });
        } else {
          this.alertsService.riseError('Произошла ошибка при попытке подписаться');
        }
      },
      error => {
        this.alertsService.riseError('Произошла ошибка при попытке подписаться');
        this.onSubscribe.error('error on unsubscribe');
      },
    );
  }

  private unsubscribeSelf() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.unsubscribe(currentUser.id, true);
    });
  }

  private unsubscribeUser() {
    this.unsubscribe(this.user.id);
  }

  unsubscribe(userId: number, update: boolean = false) {
    this.groupsService.unsubscribe(this.group.id, userId).subscribe(
      res => {
        if (res) {
          this.updateCurrentUserCounters();
          this.onUnsubscribe.emit({res: userId, succes: res});
          if (update) {
            this.group.isSubscriber = false;
            this.groupsService.currentGroup.next(this.group);
            this.groupsService.userSubscriberStateChanged$.next({
              userId: this.user.id,
              groupId: this.group.id,
              state: GroupUserStateChangedType.leaved,
            });
          }
        } else {
          this.alertsService.riseError('Произошла ошибка при попытке отписаться');
        }
      },
      error => {
        this.alertsService.riseError('Произошла ошибка при попытке отписаться');
        this.onUnsubscribe.error('error on unsubscribe');
      },
    );
  }

  remove() {
    this.groupsService.deleteGroup(this.group.id).subscribe(
      res => {
        if (res) {
          this.groupsService.groupDeleted$.next(this.group.id);
          this.deletingDialog.close();
          this.alertsService.riseSuccess('Группа удалена');
          const that = this;
          setTimeout(() => {
            that.usersService.currentUser.subscribe(currentUser => {
              that.router.navigate(['/profile/' + currentUser.id + '/groups']);
            });
          }, 1000);
        } else {
          this.deletingDialog.close();
          this.alertsService.riseError('Произошла ошибка во время удаления группы');
        }
        if (this.onRemove) {
          this.onRemove.emit();
        }
      },
      error => {
        this.error = error;
      },
    );
  }

  public clear() {
    this.menuItems = [];
  }

  private removeGroup() {
    this.deletingDialog = this.dialog.open(ModalConfirmationComponent, {
      data: {
        text: 'Вы действительно хотите удалить группу?',
        onOk: this.remove.bind(this),
        source: this,
        doNotHideOnOk: true,
      },
    });
  }

  private updateCurrentUserCounters() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.groupsService.getUserGroupsCount(currentUser.id).subscribe(count => {
        this.groupsService.currentUserGroupsCount.next(count);
      });
    });
  }

  private onRemovedGroupCallback(text: string): MatDialogRef<ModalResultComponent> {
    return this.dialog.open(ModalResultComponent, {
      data: {
        text: text,
      },
    });
  }
}

export enum GroupMenuType {
  User,
  Group,
}
