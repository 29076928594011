import {Component, Input, OnInit, ChangeDetectorRef} from '@angular/core';
import {PostComponent} from '@app/feed/components/post/post.component';
import {UsersService} from '@app/profile/services/users.service';
import {GroupsService} from '@app/groups/services/groups.service';
import {DomSanitizer} from '@angular/platform-browser';
import {SubscribeService} from '@app/subscribe/services/subscribe.service';
import {FeedService} from '@app/feed/services/feed.service';
import {FeedEventViewModel} from '@app/feed/model/feed-event-view-model.model';
import {GroupInfo} from '@app/groups/model/group-info.model';
import {EventType} from '@app/feed/model/feed-event.model';
import {HelperService} from '@app/core/services/helper.service';
import {AlertsService} from '@app/shared/services/alerts.service';
import {takeUntil} from 'rxjs/operators';
import {GroupType} from '@app/groups/model/group-type.model';
import {ActivatedRoute, Router} from '@angular/router';
import {SettingsService} from '@app/shared/services/settings.service';
import {BreadcrumbsService} from '@app/shared/services/breadcrumbs.service';

/**
 * Публикация в группе. Расширяет публикацию пользователя.
 *
 * @export
 * @class GroupPostComponent
 * @extends {PostComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-group-post',
  templateUrl: './group-post.component.html',
})
export class GroupPostComponent extends PostComponent implements OnInit {
  /**
   * Отображать весь текст поста сразу
   *
   * @type {boolean}
   * @memberof GroupPostComponent
   */
  @Input() fullText: boolean;

  /**
   * Находимся на форме отображения отдельного поста
   *
   * @type {boolean}
   * @memberof GroupPostViewComponent
   */
  @Input() isPostView: boolean;

  /**
   * Текущая группа
   *
   * @type {GroupInfo}
   * @memberof GroupPostComponent
   */
  group: GroupInfo;

  isBusinessGroup: boolean = null;

  constructor(
    protected usersService: UsersService,
    protected groupsService: GroupsService,
    protected sanitizer: DomSanitizer,
    protected subscribeService: SubscribeService,
    protected feedService: FeedService,
    protected alertsService: AlertsService,
    protected breadcrumbsService: BreadcrumbsService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected cdr: ChangeDetectorRef,
    protected settingsService: SettingsService,
    public helper: HelperService,
  ) {
    super(
      usersService,
      groupsService,
      sanitizer,
      subscribeService,
      feedService,
      alertsService,
      breadcrumbsService,
      router,
      route,
      cdr,
      settingsService,
      helper,
    );
  }

  ngOnInit() {
    this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
      this.group = group;
      if (group && group.isCanView) {
        this.isBusinessGroup = this.group.type === GroupType.Business;
        super.ngOnInit();
        this.breadcrumbsService.breadcrumbs.next([
          {title: 'Публикация', routerUrl: `/group/${this.group.id}/post/${this.post.event.id}`},
        ]);
      }
    });

    this.showBody = this.fullText;
  }

  /**
   * Может ли пользователь редактировать пост
   *
   * @param {FeedEventViewModel} post
   * @returns
   * @memberof GroupPostComponent
   */
  canEdit(post: FeedEventViewModel) {
    return post.author.id === this.currentUser.id || this.groupsService.isAdmin(this.group, this.currentUser);
  }

  /**
   * Может ли пользователь удалить пост
   *
   * @param {FeedEventViewModel} post
   * @returns
   * @memberof GroupPostComponent
   */
  canDelete(post: FeedEventViewModel) {
    return (
      post.author.id === this.currentUser.id ||
      this.groupsService.isAdmin(this.group, this.currentUser) ||
      (this.currentUser && this.currentUser.isAdmin)
    );
  }

  /**
   * Является ли пост системным?
   *
   * @returns boolean, true - если пост системный
   * @memberof GroupPostComponent
   */
  isSystemPost() {
    if (this.post) {
      if (this.post.event.isSystem !== undefined) {
        return this.post.event.isSystem;
      } else {
        this.post.event.isSystem =
          this.post.event.eventType === EventType.GroupUserJoin ||
          this.post.event.eventType === EventType.GroupNewUser ||
          this.post.event.eventType === EventType.GroupUserRemoved ||
          this.post.event.eventType === EventType.GroupNameChanged ||
          this.post.event.eventType === EventType.GroupTypeChanged ||
          this.post.event.eventType === EventType.GroupUserLeave ||
          this.post.event.eventType === EventType.UserNewGroup;
      }
      return this.post.event.isSystem;
    }
    return false;
  }

  checkPostMode() {
    this.showScope = false;
    this.allowNews = this.group && this.group.isAdmin;
  }

  onShare() {
    this.router.navigate(
      [
        'group',
        this.group.id,
        'post',
        this.post.event.repostId ? this.post.event.repostId : this.post.event.id,
        'share',
      ],
      {fragment: `from=${this.router.url}`},
    );
  }
}
