<div class="cos__header" *ngIf="loaded">
  <div class="cos__actions pull-right">
    <button type="button" title="Фильтры" class="btn btn-filter" [ngClass]="{'_active': filtersVisible}"
      *ngIf="isShowFilters()" (click)="showFilters()"></button>
    <button type="button" title="Выгрузить вакансии с hh" class="btn btn-hh" *ngIf="isShowNavigateToHhIntegration()" (click)="navigateToHhIntegration()"></button>
    <a *ngIf="showAddButton" class="btn btn-plus pull-right" routerLink="/group/{{group?.id}}/vacancy/new">+</a>
  </div>
  <app-tabs [tabs]="tabs" [hideCounters]="true"></app-tabs>
  <app-vacancy-filters [visible]="filtersVisible" [ngClass]="{'_active': filtersVisible}"
    [selectedCategory]="currentCategory" [selectedStatus]="currentStatus" (categoryChanged)="onCategoryChanged($event)"
    (statusChanged)="onStatusChanged($event)"></app-vacancy-filters>
</div>

<app-vacancy-list-view *ngIf="currentTab?.loaded && currentTab?.items?.length" [vacancies]="currentTab?.items"
  [showMenu]="showAddButton" [hideGroupsTitle]="true"></app-vacancy-list-view>

<div class="cos__body" *ngIf="currentTab?.loaded && !currentTab?.items?.length">
  <!-- Empty list -->
  <div class="group-empty">
    <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" style="width:auto" class="group-empty__img">
    <div class="group-empty__text">{{currentTab.emptyText}}</div>
  </div>
  <!-- Empty list END-->
</div>
