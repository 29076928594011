import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IAnniversary } from '@app/anniversary/model/anniversary';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { EventType } from '@app/feed/model/feed-event.model';
import { FeedService } from '@app/feed/services/feed.service';
import { User } from '@app/profile/model/user.model';
import { UsersService } from '@app/profile/services/users.service';
import { AlertsService } from '@app/shared/services/alerts.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-anniversary-widget',
  templateUrl: './anniversary-widget.component.html',
  styleUrls: ['./anniversary-widget.component.scss']
})
export class AnniversaryWidgetComponent extends BaseComponent implements OnInit {

  anniversaries: IAnniversary[] = [];
  currentUser: User;

  constructor(
    public usersService: UsersService,
    protected feedService: FeedService,
    protected alertsService: AlertsService,
    protected router: Router,
    public helper: HelperService) { super(helper); }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;

      this.feedService.getEventsByType(EventType.UserAnniversary, 0, 100)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        if (res) {
          res.forEach(post => {
            if (post.event != null && this.anniversaries.find(s => s.id === post.event.id) == null) {
              const anniversary: IAnniversary = {
                id: post.event.id,
                anniversary: post.event.data.anniversary,
                user: post.owner,
                postId: post.event.id,
                profileId: post.owner.id
              };
              this.anniversaries.push(anniversary);
            }
          });
          this.anniversaries = this.anniversaries.sort((a, b) => b.id - a.id);
          this.loaded = true;
        }
      });
    });
  }

  congratulate(anniversary: IAnniversary) {
    this.router.navigate(['profile', anniversary.profileId, 'post', anniversary.postId]);
  }
}
