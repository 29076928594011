import { OnInit, OnDestroy, Output, Input, EventEmitter, ViewChildren, AfterViewInit, Directive } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { User } from '@app/profile/model/user.model';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '@app/profile/services/users.service';
import { HelperService } from '@app/core/services/helper.service';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export class AddUsersBaseComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren('inputUser') inputUser;

  public usersToAdd: User[] = new Array<User>();
  usersFound: User[];

  isValid = false;

  usersLoaded = false;
  usersError: string;

  timeoutId: any;
  searchText: string;

  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  @Output() onOk: EventEmitter<User[]> = new EventEmitter();
  @Input() public placeholder: string;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public usersService: UsersService,
    public helper: HelperService
  ) { super(helper); }

  ngOnInit() { }

  ngAfterViewInit() {
    if (this.inputUser && this.inputUser.first) {
      this.inputUser.first.nativeElement.focus();
    }
  }

  onSearchKey(e) {
    clearTimeout(this.timeoutId);

    if (e.keyCode === 27) {
      // esq
      this.searchText = '';
      return false;
    }

    if (this.searchText && this.searchText.length > 1) {
      const that = this;
      this.timeoutId = setTimeout(function () {
        that.usersLoaded = false;
        that.usersService.searchUsers(encodeURIComponent(that.searchText.trim()), 0, 0, 20).subscribe(res => {
          if (res) {
            that.usersFound = res.items.map(u => u.user);
          }

          if (that.usersFound.length === 0) {
            that.error = 'Совпадения не найдены';
          } else {
            that.error = null;
          }

          that.usersLoaded = true;
        }, error => {
          console.log(error);
          that.error = 'Произошла ошибка при поиске данных';
          that.usersLoaded = true;
        });
      }, 500);
    } else {
      this.usersLoaded = true;
      this.usersFound = [];
    }

    if (e.keyCode === 13) {
      return false;
    }
  }

  removeUser(user: User) {
    this.usersToAdd = this.usersToAdd.filter(u => {
      return u.id !== user.id;
    });
    this.isFormValid();
  }

  addUser(user: User) {
    if (!this.usersToAdd.find(u => u.id === user.id)) {
      this.usersToAdd.push(user);
      this.usersFound = [];
      this.searchText = '';
    } else {
      this.removeUser(user);
    }
    this.isFormValid();
  }

  cancel() {
    this.usersFound = this.usersToAdd = [];
    this.searchText = '';
    this.onCancel.emit();
  }

  addUsers() {
    if (this.onOk instanceof EventEmitter) {
      this.onOk.emit(this.usersToAdd);
    } else {
      (<any>this).onOk(this.usersToAdd);
    }
  }

  isFormValid(): void {
    this.isValid = !!(this.usersToAdd && this.usersToAdd.length);
  }
}
