import { User } from '@app/profile/model/user.model';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * Helper
 */
export class Helper {
  public static FILE_UPLOAD_ERROR =
    `Проверьте, что название файла не содержит недопустимых символов # % * : < > ? / |` +
    `,а также то, что url папки, в которую загружается файл, не превышает длину в 256 символов`;

  public static colors: string[] = [
    '#5a7fc6',
    '#1c438e',
    '#0067b5',
    '#193261',
    '#3e5685',
  ];

  public static routes: string[] = [];

  public static getColorRandomly() {
    let rand = Math.random() * Helper.colors.length;
    rand = Math.floor(rand);
    return Helper.colors[rand];
  }

  public static getColor(value: string) {
    const code = this.hashCode(value);
    const char = +code.charAt(code.length - 1);
    if (char >= 0 && char <= 3) {
      return Helper.colors[0];
    } else if (char > 3 && char <= 6) {
      return Helper.colors[1];
    } else {
      return Helper.colors[2];
    }
  }

  public static getColorById(id: number) {
    const char = +id.toString().split('').pop();
    switch (char) {
      case 1:
      case 5:
        return Helper.colors[0];
      case 2:
      case 6:
        return Helper.colors[1];
      case 3:
      case 7:
        return Helper.colors[2];
      case 4:
      case 8:
        return Helper.colors[3];
      case 9:
      case 0:
        return Helper.colors[4];
    }
  }

  public static hashCode(str, max = 2) {
    let hash = 0;
    const lenght = str.length > max ? max : str.length;
    for (let i = 0; i < lenght; i++) {
      hash += str.charCodeAt(i);
    }
    return hash.toString();
  }

  public static addRoute(url: string) {
    if (
      !Helper.routes.length ||
      Helper.routes[Helper.routes.length - 1] !== url
    ) {
      Helper.routes.push(url);
    }
  }
  public static addUniqueRoute(url: string) {
    if (!Helper.routes.length || !Helper.routes.find((s) => s === url)) {
      Helper.routes.push(url);
    }
  }

  public static getNoun(number, one, two, five): string {
    number = Math.abs(number);
    number %= 100;
    if (number >= 5 && number <= 20) {
      return five;
    }
    number %= 10;
    if (number === 1) {
      return one;
    }
    if (number >= 2 && number <= 4) {
      return two;
    }
    return five;
  }

  public static concatUrls(
    first: string,
    second: string,
    check: boolean = true
  ) {
    if (!first) {
      return second;
    }
    if (!second) {
      return first;
    }

    if (check) {
      if (second.startsWith('http://') || second.startsWith('https://')) {
        return second;
      }
    }

    if (
      (first.endsWith('/') && !second.startsWith('/')) ||
      (!first.endsWith('/') && second.startsWith('/'))
    ) {
      return first + second;
    } else if (first.endsWith('/') && second.startsWith('/')) {
      second = second.substring(1);
      return first + second;
    } else {
      return `${first}/${second}`;
    }
  }

  public static getLocaleByLcid(lcid: number) {
    switch (lcid) {
      case 1033:
        return 'en';
      default:
        return 'ru';
    }
  }

  public static asciiEncode(value: string) {
    let result = '';
    for (let i = 0; i < value.length; i++) {
      if (value[i].match(/^[a-z0-9]+$/i)) {
        result += value[i];
      } else {
        result += '_' + value.charCodeAt(i);
      }
    }
    return result;
  }

  public static checkDate(date: string): Date {
    const dateArray = date.split('.');
    let year: string = null;
    let time: string = null;
    let day: string = null;
    let month: string = null;
    if (dateArray.length > 1) {
      day = dateArray[0];
      month = dateArray[1];
      const yearAndTime = dateArray[2].split(' ');
      if (yearAndTime.length > 0) {
        year = yearAndTime[0];
        time = yearAndTime[1];
        if (!time.startsWith('T')) {
          time = 'T' + time;
        }
        const timeOnly = time.substr(1);
        const timeArray = timeOnly.split(':');
        if (timeArray[0].length === 1) {
          timeArray[0] = '0' + timeArray[0];
        }
        time = `T${timeArray[0]}:${timeArray[1]}`;
      }
      const d = `${year}-${month}-${day}${time}`;
      return new Date(d);
    } else {
      return new Date(date);
    }
  }

  public static guidToN(guid: string) {
    return guid.toLowerCase().replace(new RegExp('-|{|}', 'g'), '');
  }

  public static guidToLower(guid: string) {
    return guid.toLowerCase().replace(new RegExp('{|}', 'g'), '');
  }

  public static guid(): string {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return (
      s4() +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      s4() +
      s4()
    );
  }

  public static copyToClipboard(text: string): boolean {
    try {
      const textArea = document.createElement('textarea');

      //
      // *** This styling is an extra step which is likely not required. ***
      //
      // Why is it here? To ensure:
      // 1. the element is able to have focus and selection.
      // 2. if element was to flash render it has minimal visual impact.
      // 3. less flakyness with selection and copying which **might** occur if
      //    the textarea element is not visible.
      //
      // The likelihood is the element won't even render, not even a flash,
      // so some of these are just precautions. However in IE the element
      // is visible whilst the popup box asking the user for permission for
      // the web page to copy to the clipboard.
      //

      // Place in top-left corner of screen regardless of scroll position.
      textArea.style.position = 'absolute';
      textArea.style.top = '-100px';
      textArea.style.left = '-100px';

      // Ensure it has a small width and height. Setting to 1px / 1em
      // doesn't work as this gives a negative w/h on some browsers.
      textArea.style.width = '2em';
      textArea.style.height = '2em';

      // We don't need padding, reducing the size if it does flash render.
      textArea.style.padding = '0';

      // Clean up any borders.
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';

      // Avoid flash of white box if rendered for any reason.
      textArea.style.background = 'transparent';

      textArea.value = decodeURIComponent(text);

      document.body.appendChild(textArea);

      textArea.select();

      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      console.log(`Copying text command was ${msg} ${textArea.value}`);
      return successful;
    } catch (err) {
      console.log('error on copy to clipboard' + err);
      return false;
    }
  }

  public static getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }

  public static addOrRemoveClassToBody(add: boolean) {
    if (!document.body.classList.contains('locked') || !add) {
      if (add) {
        document.body.classList.add('locked');
      } else {
        document.body.classList.remove('locked');
      }
    }
  }

  public static addOrRemoveScrollLockongOnBody(add: boolean) {
    if (!document.body.classList.contains('locked-scroll') || !add) {
      if (add) {
        document.body.classList.add('locked-scroll');
      } else {
        document.body.classList.remove('locked-scroll');
      }
    }
  }

  public static addDays(days: number, date: Date = new Date()) {
    date.setDate(date.getDate() + days);
    return date;
  }

  public static getSkypeStatusCss(user: User) {
    if (this.isStatus(user, 'Online')) {
      return '_online';
    }
    if (this.isBusy(user)) {
      return '_away';
    }
    if (this.isOffline(user)) {
      return '_offline';
    }
  }

  public static isOnline(user: User) {
    return this.isStatus(user, 'Online');
  }

  public static isOffline(user: User) {
    return this.isStatus(user, 'Offline');
  }

  public static isBusy(user: User) {
    return this.isStatus(user, 'Busy');
  }

  public static isAway(user: User) {
    return this.isStatus(user, 'Away');
  }

  public static isStatus(user: User, status: string): boolean {
    if (user && user.skypeStatus) {
      return user.skypeStatus.getValue() === status;
    }
    return false;
  }

  /**
   * Копирование объекта
   *
   * @static
   * @param {*} object
   * @returns {*}
   * @memberof Helper
   */
  public static clone(object: any): any {
    const cloneObj = {};
    for (const attribute in object) {
      if (typeof object[attribute] === 'object') {
        cloneObj[attribute] = this.clone(object[attribute]);
      } else {
        cloneObj[attribute] = object[attribute];
      }
    }
    return cloneObj;
  }

  public static getPicture(pictureUrl: string): string {
    let url = '';
    if (pictureUrl) {
      const index = pictureUrl.indexOf('?');
      url = pictureUrl;
      if (index !== -1) {
        url = decodeURIComponent(url.substr(0, index)) + url.substr(index);
      } else {
        url = decodeURIComponent(url);
      }
    }
    return url;
  }

  public static getErrorMsg(error): string {
    let msg = '';

    if (!error) {
      return msg;
    }

    let err: any = error.error;

    if (error instanceof HttpErrorResponse) {
      if (error.error) {
        try {
          if (typeof error.error === 'string') {
            err = JSON.parse(error.error);
          } else {
            err = error.error;
          }
        } catch (e) {
          console.log(e);
        }
      }
    }

    if (err && err['odata.error']) {
      if (
        err['odata.error'].innererror &&
        err['odata.error'].innererror.message
      ) {
        msg = `: ${err['odata.error'].innererror.message}`;
      } else if (
        err['odata.error'].message &&
        err['odata.error'].message.value
      ) {
        msg = `: ${err['odata.error'].message.value}`;
      }
    }
    return msg;
  }

  public static checkUserDate(): boolean {
    const isActive = (<any>window).Intry.isActive;

    if (isActive != null && isActive !== undefined) {
      return isActive;
    }

    return true;
  }

  public static dataURItoBlob(dataURI): Blob {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  public static isYesNo(input: string): boolean {
    if (!input) {
      return false;
    }
    if (input.toLowerCase() === 'да' || input.toLowerCase() === 'yes' || input === '1' || input === 'true') {
      return true;
    }
  }
}
