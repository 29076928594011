import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AttachmentsMenuComponent } from '@app/attachments/file-attachments/components/attachments-menu/attachments-menu.component';
import { AttachmentsListComponent } from '@app/attachments/file-attachments/components/attachments-list/attachments-list.component';
import { AttachmentMenuComponent } from '@app/attachments/file-attachments/components/attachment-menu/attachment-menu.component';
import { AttachmentsTreeComponent } from '@app/attachments/file-attachments/components/attachments-tree/attachments-tree.component';
import { FilesModule } from '@app/files/files.module';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    SharedModule,
    FilesModule,
    MatMenuModule
  ],
  declarations: [
    AttachmentsMenuComponent,
    AttachmentsListComponent,
    AttachmentMenuComponent,
    AttachmentsTreeComponent
  ],
  exports: [
    AttachmentsMenuComponent,
    AttachmentsListComponent,
    AttachmentMenuComponent,
    AttachmentsTreeComponent
  ],

})
export class AttachmentsModule { }
