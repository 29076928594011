import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy, NgZone, HostListener } from '@angular/core';
import { NotificationsService } from '@app/notifications/services/notifications.service';
import { PushNotificationsService } from '@app/notifications/services/push-notifications.service';
import { SignalRService } from '@app/signalr/signalR.service';
import { UsersService } from '@app/profile/services/users.service';
import { GroupsService } from '@app/groups/services/groups.service';
import { Helper } from '@app/core/helpers/helper';
import { BaseResizableComponent } from '@app/core/components/base-resizable.component';
import {
  NotificationDataWithId,
  IExternalNotificationData,
  IPostNotificationData,
  IPostCommentNotificationData,
  IGroupPostNotificationData,
  IGroupPostCommentNotificationData,
  VacancyResponseNotificationData,
  NotificationDataWithGroup,
  INotificationCategory,
  INotificationData
} from '@app/notifications/models/notification.interface';
import { NotificationType } from '@app/notifications/models/notification-type.enum';
import { IntryNotification } from '@app/notifications/models/notification.interface';
import { PushNotification } from '@app/notifications/models/push-notification.interface';
import { HelperService } from '@app/core/services/helper.service';
import { User } from '@app/profile/model/user.model';

/**
 * Компонент уведомлений в шапке
 */
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html'
})
export class NotificationsComponent extends BaseResizableComponent implements OnInit, OnDestroy {

  categories: INotificationCategory[] = [];

  currentUser: User;

  notificationType = NotificationType;
  visible = false;
  notifications: IntryNotification[];
  count: number;
  pushNotificationOnclickSubscription: any;

  windowFocused = true;

  isLast: boolean;
  timeout: any;

  // TODO: переделать на динамический подсчёт элементов в зависимости от высоты экрана
  private pageSize = 20;

  constructor(
    private notificationsService: NotificationsService,
    private usersService: UsersService,
    private groupsService: GroupsService,
    private pushNotificationsService: PushNotificationsService,
    protected signalRService: SignalRService,
    private zone: NgZone,
    private router: Router,
    public helper: HelperService) {
    super(helper);
    this.pushNotificationOnclickSubscription = this.pushNotificationsService.onclick
      .subscribe(o => this.pushNotificationOnClickCallback(o));
  }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;
      const that = this;

      this.notificationsService.getNotificationCategories().subscribe(res => {
        if (res) {
          this.categories = res;
          this.notificationsService.getNotifications(res.filter(s => s.selected).map(s => s.id), 0, this.pageSize)
            .subscribe(list => {
              this.notifications = list.notifications;
              this.count = list.count;
              this.loading = false;
            });
        } else {
          this.categories = [];
        }
      });

      document.addEventListener('visibilitychange', function () {
        that.windowFocused = !document.hidden;
      });

      this.signalRService.mainHub.onNotifyUsers
        .subscribe((notification: IntryNotification) => {
          this.onNotifyUsers(notification);
        });

      this.loading = true;
    });
  }

  private onNotifyUsers(notification: IntryNotification) {
    this.zone.run(() => {
      this.count++;
      this.notifications.unshift(notification);
      this.showPushNotification(notification);
    });
  }

  ngOnDestroy() {
    this.pushNotificationOnclickSubscription.unsubscribe();
    super.ngOnDestroy();
  }

  toggle(event) {
    this.cancelEvent(event);
    this.visible = !this.visible;
    Helper.addOrRemoveClassToBody(this.visible);
  }

  filterClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  clickOutside() {
    this.visible = false;
    Helper.addOrRemoveClassToBody(this.visible);
  }

  /**
   * Sets notification as viewed if it loaded.
   * @param notification The notification to update.
   */
  setViewed(notification: IntryNotification) {
    // we should work only with loaded notifications
    notification = this.notifications.find(o => o.id === notification.id);

    if (!notification || notification.viewed) {
      return;
    }

    this.notificationsService.setViewed(notification.id)
      .subscribe(res => {
        notification.viewed = new Date();
        this.count--;
      });
  }

  /**
   * Sets all user notification as viewed.
   */
  setAllViewed() {
    this.notificationsService.setAllViewed()
      .subscribe(res => {
        this.notifications.forEach(notification => {
          notification.viewed = new Date();
        });
        this.count = 0;
      });
  }

    /**
   * Sets all user notification as viewed.
   */
  hasNoViewed() {
      return (this.notifications || []).some(notification => (notification && !notification.viewed));
  }

  /**
   * Handling notification when a user click on the container.
   * @param notification The notification associated with container.
   * @param event The user input event.
   */
  handle(notification: IntryNotification, event: any) {
    this.cancelEvent(event);
    this.setViewed(notification);
    this.navigate(notification);
    Helper.addOrRemoveClassToBody(this.visible);
  }

  /**
   * Handling notification when a user click on the initiator container.
   * @param notification The notification associated with container.
   * @param event The user input event.
   */
  handleInitiator(notification: IntryNotification, event: any) {
    this.cancelEvent(event);
    this.setViewed(notification);
    const user = this.usersService.user.getValue();
    if (user && user.id !== notification.initiator.id) {
      this.usersService.user.next(null);
    }

    this.router.navigate(['/profile/' + notification.initiator.id]);
  }

  /**
   * By notification type user will be navigated to appropriate page.
   * @param notification The notification for decide which page should be opened.
   */
  navigate(notification: IntryNotification) {
    this.visible = false;
    switch (notification.type) {
      case NotificationType.SharedFile:
      case NotificationType.SharedGroupFile:
        const fdata = <IExternalNotificationData>(notification.data);
        window.open(fdata.url, '_blank');
        break;
      case NotificationType.AddedToGroup:
      case NotificationType.RemovedFromGroup:
      case NotificationType.GroupJoinApproved:
      case NotificationType.GroupJoinRejected:
      case NotificationType.GroupSubscriptionApproved:
      case NotificationType.GroupSubscriptionRejected:
        const adata = <NotificationDataWithId>(notification.data);
        this.checkGroup(adata.id);
        this.router.navigate([`group/${adata.id}`]);
        break;
      case NotificationType.Subsribed:
        const sdata = <NotificationDataWithId>(notification.data);
        this.checkUser(sdata.id);
        this.router.navigate([`profile/${sdata.id}`]);
        break;
      case NotificationType.GroupJoinRequest:
      case NotificationType.GroupSubscriptionRequest:
        const gsdata = <NotificationDataWithId>(notification.data);
        this.checkGroup(gsdata.id);
        this.router.navigate([`group/${gsdata.id}/users`], { fragment: 'requests' });
        break;
      case NotificationType.PostMention:
      case NotificationType.PostLike:
      case NotificationType.PostResponse:
      case NotificationType.PostResponseForMentioned:
        const pmdata = <IPostNotificationData>(notification.data);
        this.checkUser(pmdata.feedId);
        this.router.navigate([`profile/${pmdata.feedId}/post/${pmdata.postId}`]);
        break;
      case NotificationType.PostCommentLike:
      case NotificationType.PostCommentMention:
      case NotificationType.PostCommentResponse:
      case NotificationType.PostCommentResponseForMentioned:
        const pcmdata = <IPostCommentNotificationData>(notification.data);
        this.checkUser(pcmdata.feedId);
        this.router.navigate([`profile/${pcmdata.feedId}/post/${pcmdata.postId}`], { fragment: `comment=${pcmdata.commentId}` });
        break;
      case NotificationType.GroupPostMention:
      case NotificationType.GroupPostLike:
      case NotificationType.GroupPostResponse:
      case NotificationType.GroupPostResponseForMentioned:
        const gpmdata = <IGroupPostNotificationData>(notification.data);
        this.checkGroup(gpmdata.groupId);
        this.router.navigate([`group/${gpmdata.groupId}/post/${gpmdata.postId}`]);
        break;
      case NotificationType.GroupCommentMention:
      case NotificationType.GroupCommentLike:
      case NotificationType.GroupCommentResponse:
      case NotificationType.GroupCommentResponseForMentioned:
        const gcmdata = <IGroupPostCommentNotificationData>(notification.data);
        this.checkGroup(gcmdata.groupId);
        this.router.navigate([`group/${gcmdata.groupId}/post/${gcmdata.postId}`], { fragment: `comment=${gcmdata.commentId}` });
        break;
      case NotificationType.NewVacancyResponse:
        const gvdata = <VacancyResponseNotificationData>(notification.data);
        this.checkGroup(gvdata.groupId);
        this.router.navigate([`group/${gvdata.groupId}/vacancy/${gvdata.vacancyId}/response/${gvdata.responseId}`]);
        break;
      case NotificationType.NewIdea:
        const gideadata = <NotificationDataWithGroup>(notification.data);
        this.checkGroup(gideadata.groupId);
        this.router.navigate([`group/${gideadata.groupId}/ideas/${gideadata.id}`]);
        break;
      case NotificationType.ThanksAdded:
        const thankData = <NotificationDataWithId>(notification.data);
        this.checkUser(notification.initiator.id);
        this.router.navigate([`profile/${this.currentUser.id}/thanks/${thankData.id}`]);
        break;
      case NotificationType.BirthdayToday:
        this.checkUser(notification.initiator.id);
        this.router.navigate([`profile/${notification.initiator.id}`]);
        break;
      case NotificationType.TakeSurvey:
        const takeSurveyData = <NotificationDataWithGroup>(notification.data);
        this.checkGroup(takeSurveyData.groupId);
        this.router.navigate([`group/${takeSurveyData.groupId}/surveys/${takeSurveyData.id}`]);
        break;
      case NotificationType.SurveyExpired:
        const expiredSurveyData = <NotificationDataWithGroup>(notification.data);
        this.checkGroup(expiredSurveyData.groupId);
        this.router.navigate([`group/${expiredSurveyData.groupId}/surveys/${expiredSurveyData.id}`]);
        break;
      case NotificationType.AwardAdded:
        this.checkUser(notification.initiator.id);
        this.router.navigate([`profile/${this.currentUser.id}/awards`]);
        break;
    }
  }

  private checkUser(userId: any) {
    const user = this.usersService.user.getValue();
    if (user != null && user.id !== +userId) {
      this.usersService.user.next(null);
    }
  }

  private checkGroup(groupId: any) {
    const group = this.groupsService.currentGroup.getValue();
    if (group != null && group.id !== +groupId) {
      this.usersService.user.next(null);
    }
  }

  /**
   * Callback for scroll event.
   */
  scrollCallback() {
    if (this.loading || this.isLast) {
      return;
    }

    this.loading = true;

    this.getNotifications(false);
  }

  categoryClick(event: Event, category: INotificationCategory) {
    this.loading = true;
    event.preventDefault();
    event.stopPropagation();
    category.selected = !category.selected;
    this.getNotifications(true);
  }

  /**
   * Получение уведомлений
   *
   * @param {boolean} force true если нужно очистить список от старых элементов
   * @memberof NotificationsComponent
   */
  getNotifications(force: boolean) {
    const offset: number = force ? 0 : this.notifications.length;
    this.notificationsService.getNotifications(
      this.categories.filter(s => s.selected).map(s => s.id),
      offset,
      this.pageSize)
      .subscribe(list => {

        if (!list.notifications || !list.notifications.length) {
          this.isLast = true;
          if (force) {
            this.notifications = list.notifications;
          }
        } else if (force) {
          this.notifications = list.notifications;
        } else {
          list.notifications.forEach(notification => {
            this.notifications.push(notification);
          });
        }

        // set loaded
        this.loading = false;
      });
  }

  /**
   * Creates a push notification by the specified notification.
   * @param notification The notification to show using push API.
   */
  showPushNotification(notification: IntryNotification) {
    // skip notification if window focused
    if (this.windowFocused) {
      return;
    }

    // todo: use right image, it should be png 192x192 or larger
    const options: PushNotification = {
      icon: notification.initiator.pictureUrl,
      data: notification
    };

    switch (notification.type) {
      case NotificationType.Subsribed:
        options.body = 'Подписался на вас';
        break;
      case NotificationType.SharedFile:
      case NotificationType.SharedGroupFile:
        options.body = 'Доступ к файлу ' + notification.data.title;
        break;
      case NotificationType.AddedToGroup:
        options.body = 'Вы добавлены в группу ' + notification.data.title;
        break;
      case NotificationType.GroupJoinApproved:
        options.body = 'Одобрен запрос на вступление в группу ' + notification.data.title;
        break;
      case NotificationType.GroupJoinRejected:
        options.body = 'Отклонён запрос на вступление в группу ' + notification.data.title;
        break;
      case NotificationType.GroupSubscriptionApproved:
        options.body = 'Одобрен запрос на подписание в группу ' + notification.data.title;
        break;
      case NotificationType.GroupSubscriptionRejected:
        options.body = 'Отклонён запрос на подписание в группу ' + notification.data.title;
        break;
      case NotificationType.GroupJoinRequest:
        options.body = 'Добавлен запрос на вступление в группу ' + notification.data.title;
        break;
      case NotificationType.GroupSubscriptionRequest:
        options.body = 'Добавлен запрос на подписание в группу ' + notification.data.title;
        break;
      case NotificationType.PostMention:
        options.body = 'Вас упомянули в посте ';
        break;
      case NotificationType.PostCommentMention:
        options.body = 'Вас упомянули в комментарии ';
        break;
      case NotificationType.GroupPostMention:
        options.body = 'Вас упомянули в посте ';
        break;
      case NotificationType.GroupCommentMention:
        options.body = 'Вас упомянули в комментарии ';
        break;
      case NotificationType.PostLike:
        options.body = 'Ваш пост лайкнули ';
        break;
      case NotificationType.PostCommentLike:
        options.body = 'Ваш коммент лайкнули ';
        break;
      case NotificationType.GroupPostLike:
        options.body = 'Ваш пост лайкнули ';
        break;
      case NotificationType.GroupCommentLike:
        options.body = 'Ваш коммент лайкнули ';
        break;
      case NotificationType.GroupPostResponse:
      case NotificationType.PostResponse:
        options.body = 'На Ваш пост ответили ';
        break;
      case NotificationType.GroupCommentResponse:
      case NotificationType.PostCommentResponse:
        options.body = 'На Ваш коммент ответили ';
        break;
      case NotificationType.GroupPostResponseForMentioned:
      case NotificationType.PostResponseForMentioned:
        options.body = 'На пост ответили ';
        break;
      case NotificationType.GroupCommentResponseForMentioned:
      case NotificationType.PostCommentResponseForMentioned:
        options.body = 'На коммент ответили ';
        break;
      case NotificationType.RemovedFromGroup:
        options.body = 'Вы исключены из группы ' + notification.data.title;
        break;
      case NotificationType.NewVacancyResponse:
        options.body = 'Новый отклик на вакансию ' + notification.data.title;
        break;
      case NotificationType.NewIdea:
        options.body = 'Новая идея ' + notification.data.title;
        break;
      case NotificationType.ThanksAdded:
        options.body = 'Новое Спасибо ';
        break;
      case NotificationType.BirthdayToday:
        options.body = 'День рождения коллеги ';
        break;
      case NotificationType.TakeSurvey:
        options.body = 'Прохождение опроса пользователем ';
        break;
      case NotificationType.SurveyExpired:
        options.body = 'Истечение срока прохождения опроса ';
        break;
      case NotificationType.AwardAdded:
        options.body = 'Новая награда ';
        break;
      // todo: add new notification types here
    }

    this.pushNotificationsService.show(notification.initiator.fullName, options);
  }

  /**
   * Called when user click on the browser push notification.
   * @param notification The notification associated with push notification.
   */
  pushNotificationOnClickCallback(notification: IntryNotification) {
    // need to go to target window even if browser minimized
    window.focus();
    // navigate to target
    this.zone.run(() => {
      this.setViewed(notification);
      this.navigate(notification);
    });
  }

  // todo: move to shared utility
  cancelEvent(event) {
    if (event) {
      if (event.stopPropagation) {
        event.stopPropagation();
      }
      if (event.preventDefault) {
        event.preventDefault();
      }
      event.returnValue = false;
      event.cancelBubble = true;
    }
  }

  getExternalNotificationData(notification: IntryNotification): IExternalNotificationData {
    return notification ? (notification.data as IExternalNotificationData) : null;
  }

  getNotificationDataWithId(notification: IntryNotification): NotificationDataWithId {
    return notification ? (notification.data as NotificationDataWithId) : null;
  }

  getPostCommentNotificationData(notification: IntryNotification): IPostCommentNotificationData {
    return notification ? (notification.data as IPostCommentNotificationData) : null;
  }

  getGroupPostCommentNotificationData(notification: IntryNotification): IGroupPostCommentNotificationData {
    return notification ? (notification.data as IGroupPostCommentNotificationData) : null;
  }

  getVacancyResponseNotificationData(notification: IntryNotification): VacancyResponseNotificationData {
    return notification ? (notification.data as VacancyResponseNotificationData) : null;
  }

  @HostListener('window:focus')
  onWindowFocus() {
    this.windowFocused = true;
  }

  @HostListener('window:scroll', [])
  onScroll() {
    if (this.visible && super.isNotDesktop()) {
      const that = this;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        that.scrollCallback();
      }, 100);
    }
  }
}
