import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { HelperService } from '@app/core/services/helper.service';
import { Observable } from 'rxjs';
import { IBannerSlideType, INewBannerSlideTypeModel, IUpdateBannerSlideTypeModel } from '../model/banner';
import { IBannerFile } from '../model/banner-file';

@Injectable({
  providedIn: 'root'
})
export class BannerSlideTypeApiService extends BaseService {
  protected serviceUrl = `${this.helper.environment.siteUrl}/api/bannerSlideType`;

  constructor(
    protected http: HttpClient,
    public helper: HelperService) { super(http, helper); }

  getAll(): Observable<IBannerSlideType[]> {
    return super.get('');
  }

  getIcons(): Observable<IBannerFile[]> {
    return super.get('icons');
  }

  getById(id: number): Observable<IBannerSlideType> {
    return super.get(`${id}`);
  }

  add(data: INewBannerSlideTypeModel): Observable<IBannerSlideType> {
    return super.post('', { data });
  }

  update(data: IUpdateBannerSlideTypeModel): Observable<IBannerSlideType> {
    return super.put(`${data.id}`, { data });
  }

  remove(id: number): Observable<boolean> {
    return super.delete(`${id}`);
  }

  upload(formData: FormData): Observable<IBannerFile> {
    return super.post(`upload`, formData);
  }
}
