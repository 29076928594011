<div class="cos__header">
  <div class="cos__actions pull-right" *ngIf="isShowMenu()">
    <button type="button" title="Фильтры" class="btn btn-filter" *ngIf="isShowFilters()"
      (click)="showFilters()"></button>
      <app-surveys-menu [showAddButton]="showAddButton"></app-surveys-menu>
  </div>
  <app-tabs [tabs]="tabs" [hideCounters]="true"></app-tabs>
</div>

<app-survey-list-view *ngIf="currentTab?.loaded && currentTab?.items?.length" [surveys]="currentTab?.items"
  [showCounters]="false"></app-survey-list-view>

<div class="cos__body" *ngIf="currentTab?.loaded && !currentTab?.items?.length">
  <!-- Empty list -->
  <div class="group-empty">
    <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" style="width:auto" class="group-empty__img">
    <div class="group-empty__text">{{currentTab.emptyText}}</div>
  </div>
  <!-- Empty list END-->
</div>
