import { Component, OnInit, Input } from '@angular/core';
import { SurveysService } from '@app/surveys/services/surveys.service';
import { ISurvey } from '@app/surveys/model/survey';
import { GroupsService } from '@app/groups/services/groups.service';
import { Helper } from '@app/core/helpers/helper';

/**
 * Компонент списка опросов
 *
 * @export
 * @class SurveyListComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-survey-list-view',
  templateUrl: './survey-list-view.component.html'
})
export class SurveyListViewComponent {

  @Input() surveys: ISurvey[];
  @Input() showCounters: boolean;

  constructor(
    public surveysService: SurveysService,
    public groupsService: GroupsService
  ) { }

  getText(survey: ISurvey) {
    let result = '';

    if (this.showCounters) {
      result = `${survey.answersCount ? survey.answersCount : 0} `;
      result += Helper.getNoun(survey.answersCount, 'ответил', 'ответили', 'ответили');
      result += this.getStatusText(survey);
    } else {
      result += this.getCurrentUserStatusText(survey);
    }

    return result;
  }

  trackBySurvey(index, item) {
    return index; // or item.id
  }

  onDeleted(id: number) {
    this.surveys = this.surveys.filter(s => s.id !== id);
  }

  private getStatusText(survey: ISurvey) {
    if (survey.published) {
      if (survey.complete) {
        return `, ответили`;
      } else if (new Date(survey.dateEnd) > new Date()) {
        return `, активен`;
      } else {
        return `, завершен`;
      }
    } else {
      return `, черновик`;
    }
  }

  private getCurrentUserStatusText(survey: ISurvey) {
    if (survey.complete) {
      return `вы ответили`;
    } else {
      return `вы еще не ответили`;
    }
  }
}
