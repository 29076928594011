import { map, takeUntil, take } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, HostListener, ViewChild, Renderer2 } from '@angular/core';
import { User } from '@app/profile/model/user.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { GroupInfo } from '@app/groups/model/group-info.model';
import { UsersService } from '@app/profile/services/users.service';
import { FilesService } from '@app/files/services/files.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Tab } from '@app/shared/components/tabs/tab.model';
import { FilesList, FilesListItem, FilesListItemType, Breadcrumb, PermMask, IntryFilesList } from '@app/files/model/files-list-item.model';
import { TabbedListComponent } from '@app/shared/components/tabs/tabbed-list.component';
import { IFileMenuConfig, FileMenuComponent } from '@app/files/components/file-menu/file-menu.component';
import { FileShareComponent } from '@app/files/components/file-share/file-share.component';
import { FilesMenuComponent } from '@app/files/components/files-menu/files-menu.component';
import { HelperService } from '@app/core/services/helper.service';
import { Helper } from '@app/core/helpers/helper';
import { FilesDialogService } from '@app/files/services/files-dialog.service';

/**
 * Базовый компонент отображения списка файлов
 */
@Component({
    selector: 'app-files-list',
    template: ''
})

export class FilesListComponent extends TabbedListComponent<FilesListItem> implements OnInit, OnDestroy {
    currentUser: User;
    user: User;
    group: GroupInfo;

    // folderId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    // ownerId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    // folderUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    // listUrl: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    // rootFolderUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    // rootFolderId: BehaviorSubject<number> = new BehaviorSubject<number>(null);

    listAbsoluteUrl: Subject<string> = new BehaviorSubject('');

    // list guid
    listId: string;
    portalUrl: string;

    filestListType: number;

    syncUrl: string;

    fragment: string = null;

    showFiles = true;

    // sorting
    sort: boolean;
    sortBy: string;
    showSortTooltip: boolean;
    sortTitle = 'По дате изменения';
    sortAsc = 'Asc'; // Asc | Desc
    sortField = ''; // Modified, Filename, Editor
    nextHref = '';

    showAddButton: boolean;
    showShareFiles: boolean;

    itemsChecked: boolean;
    skipItemsCheck: boolean;

    currentFolderId: number;
    // currentFolderUrl: string;

    parentFolderName: string;
    parentFolderUrl: string;

    protected limit = 30;
    protected maxItemHeight = 50;

    config: IFileMenuConfig = {};

    folderPermissions: string;

    // selecting
    selecting: boolean;
    selectingStart: number;
    moveY: number;
    up: boolean;

    // children
    @ViewChild(FilesMenuComponent) private topFilesMenu: FilesMenuComponent;

    constructor(
        protected usersService: UsersService,
        public filesService: FilesService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected filesDialog: FilesDialogService,
        protected renderer: Renderer2,
        public helper: HelperService
    ) {
        super(router, route, helper);
        this.filesService.filesUploaded$.pipe(takeUntil(this.destroyed$))
            .subscribe(res => {
                this.reloadCurrentTab();
            });
    }

    /**
     * Данные для вкладки "Файлы"
     */
    getFiles(tab: Tab<FilesListItem>) {
        this.error = '';

        const ownerId = this.filesService.ownerId$.getValue();
        const folderId = this.filesService.folderId$.getValue();

        if (ownerId) {
            const folder = folderId ? folderId : null;
            if (this.currentFolderId !== folder) {
                tab.items = [];
                tab.loaded = false;
                tab.offset = 0;
                this.nextHref = null;
            }
            this.currentFolderId = folder;
            tab.loading = true;
            if (!this.sortField) {
                this.sortField = 'FileName';
            }

            this.filesService.getListData(ownerId, folderId).subscribe(filesList => {
                this.operateApiResultV2(filesList, tab);
                this.calculateShowAddButton();
                this.error = '';
                tab.loading = false;
            }, error => {
                this.error = error;
                this.loaded = tab.loaded = true;
                tab.loading = false;

                if (error.status === 404) {
                    console.log(`Папка не существует`);
                }
            });

            // this.filesService.getRenderListDataAsStream(
            //     Helper.concatUrls(this.helper.getSiteHostUrl(), webUrl),
            //     listUrl,
            //     folder,
            //     this.sortField,
            //     this.sortAsc,
            //     tab.offset,
            //     this.limit,
            //     null,
            //     false,
            //     this.nextHref)
            //     .subscribe(filesList => {
            //         this.listId = filesList.listId;
            //         this.portalUrl = filesList.portalUrl;
            //         this.listAbsoluteUrl.next(Helper.concatUrls(this.portalUrl, listUrl));
            //         tab.data = filesList;
            //         tab.offset = filesList.lastRow;
            //         this.nextHref = filesList.nextHref;
            //         this.folderPermissions = filesList.folderPermissions;
            //         this.filesService.newWOPIDocumentEnabled = filesList.newWOPIDocumentEnabled;
            //         if (tab.data.listUrlDir) {
            //             this.rootFolderUrl.next(tab.data.listUrlDir);
            //             console.log('listUrl=' + tab.data.listUrlDir);
            //         }
            //         this.operateWebApiResult(filesList, tab);

            //         // ссылка для синхронизации определяется очень костыльно
            //         // лучше не трогать
            //         this.syncUrl = this.filesService.getSyncUrl(
            //             Helper.concatUrls(this.portalUrl, webUrl),
            //             filesList.listId,
            //             filesList.rootFolder,
            //             folder ? 'OPENFOLDER' : 'OPENLIST');

            //         // проверить отображение кнопки '+'
            //         // так как переходим по папкам это актуально (для корневой папки прав может не быть, а глубже - да)
            //         this.calculateShowAddButton();

            //         this.error = '';
            //         tab.loading = false;
            //     }, error => {
            //         this.error = error;
            //         this.loaded = tab.loaded = true;
            //         tab.loading = false;

            //         if (error.status === 404) {
            //             console.log(`Папка не существует`);
            //         }
            //     });
        } else if (ownerId === null) {
            this.error = 'Список не существует';
            this.loaded = tab.loaded = true;
        }
    }

    operateApiResultV2(filesList: IntryFilesList, tab: Tab<FilesListItem>) {
        if (filesList) {
            if (filesList.items && filesList.items.length) {
                filesList.items.forEach(rowItem => {
                    const item = tab.items.find(i => i.uniqueId === rowItem.uniqueId);
                    if (!item) {
                        rowItem.checked.next(false);
                        tab.items.push(rowItem);
                        rowItem.checked.subscribe(res => {
                            if (res !== null && !this.skipItemsCheck) {
                                this.setAllChecked();
                            }
                        });
                    }
                });

                if (filesList.firstRow + this.limit <= filesList.lastRow + 1) {
                    tab.itemsCount = filesList.lastRow + 1;
                } else {
                    tab.itemsCount = filesList.lastRow;
                }
            }
        }
        tab.loaded = true;
        this.loaded = true;
    }

    operateWebApiResult(filesList: FilesList, tab: Tab<FilesListItem>) {
        if (filesList) {
            if (filesList.items && filesList.items.length) {
                filesList.items.forEach(rowItem => {
                    const item = tab.items.find(i => i.uniqueId === rowItem.uniqueId);
                    if (!item) {
                        rowItem.checked.next(false);
                        tab.items.push(rowItem);
                        rowItem.checked.subscribe(res => {
                            if (res !== null && !this.skipItemsCheck) {
                                this.setAllChecked();
                            }
                        });
                    }
                });

                if (filesList.firstRow + this.limit <= filesList.lastRow + 1) {
                    tab.itemsCount = filesList.lastRow + 1;
                } else {
                    tab.itemsCount = filesList.lastRow;
                }
            }
        }
        tab.loaded = true;
        this.loaded = true;
    }

    sortColumn(sortBy: string, sort: boolean) {
        this.sort = sort;
        this.sortBy = sortBy;
        this.showSortTooltip = false;
        this.sortItems(this);
    }

    sortItems(obj: FilesListComponent) {
        if (obj.sortBy) {
            this.sortAsc = this.sort ? 'Asc' : 'Desc';
            switch (obj.sortBy) {
                case 'modified':
                    if (this.currentTab.name === 'shared' || this.currentTab.name === 'files'
                        && (!this.showAddButton || this.filestListType === 2)) {
                        this.sortField = 'Modified';
                        this.sortCurrentTab();
                    } else if (this.currentTab.name === 'recent') {
                        this.sortField = 'LastModifiedTime';
                        this.sortCurrentTab();
                    } else {
                        obj.sortByModified(obj.sort);
                    }
                    this.sortTitle = 'По дате изменения';
                    break;
                case 'user':
                    if (this.currentTab.name === 'shared' || this.currentTab.name === 'files'
                        && (!this.showAddButton || this.filestListType === 2)) {
                        this.sortField = 'Editor';
                        this.sortCurrentTab();
                    } else if (this.currentTab.name === 'recent') {
                        this.sortField = 'ModifiedBy';
                        this.sortCurrentTab();
                    } else {
                        obj.sortByUser(obj.sort);
                    }
                    this.sortTitle = 'По автору изменения';
                    break;
                default:
                    if (this.currentTab.name === 'recent' || this.currentTab.name === 'files'
                        && (!this.showAddButton || this.filestListType === 2)) {
                        this.sortField = 'Filename';
                        this.sortCurrentTab();
                    } else if (this.currentTab.name === 'shared') {
                        this.sortField = 'Filename';
                        this.sortCurrentTab();
                    } else {
                        obj.sortByName(obj.sort);
                    }
                    this.sortTitle = 'По названию';
                    break;
            }
        }
    }

    private sortCurrentTab() {
        this.currentTab.loaded = false;
        this.currentTab.items = [];
        this.currentTab.itemsCount = 0;
        this.currentTab.offset = 0;
        this.nextHref = null;
        this.currentTab.method(this.currentTab, this);
    }

    sortByUser(sort: boolean) {
        this.sort = sort;
        this.currentTab.items.sort((a, b) => {
            if (a.editor[0].title < b.editor[0].title) {
                return sort ? -1 : 1;
            }
            if (a.editor[0].title > b.editor[0].title) {
                return sort ? 1 : -1;
            }
            return 0;
        });
    }

    sortByModified(sort: boolean) {
        this.sort = sort;
        this.currentTab.items.sort((a, b) => {
            if (a.modified < b.modified) {
                return sort ? -1 : 1;
            }
            if (a.modified > b.modified) {
                return sort ? 1 : -1;
            }
            return 0;
        });
    }

    sortByName(sort: boolean) {
        this.sort = sort;
        this.currentTab.items.sort((a, b) => {
            if (a.name < b.name) {
                return sort ? -1 : 1;
            }
            if (a.name > b.name) {
                return sort ? 1 : -1;
            }
            return 0;
        });
    }

    toggleSortTooltip() {
        this.showSortTooltip = !this.showSortTooltip;
    }

    ngOnInit() {
        this.usersService.currentUser.subscribe(currentUser => {
            this.currentUser = currentUser;
        });

        // find what has is now and route navigate with hash
        this.route.fragment
            .pipe(takeUntil(this.destroyed$))
            .subscribe(val => {
                // get hash
                if (val != null) {
                    const keys = val.split('&');
                    const hash = {};
                    keys.forEach(key => {
                        // tslint:disable-next-line:no-shadowed-variable
                        const val = key.split('=');
                        hash[val[0]] = val[1];
                    });

                    if (hash['folder']) {
                        this.filesService.folderId$.next(hash['folder']);
                    } else {
                        this.filesService.folderId$.next(null);
                    }

                    if (hash['sort']) {
                        this.sort = hash['sort'] === 'asc';
                    } else {
                        this.sort = null;
                    }

                    this.sort = hash['sortBy'] ? hash['sortBy'] : null;

                    // очищаем текущий табик
                    this.currentTab = null;

                    this.tabs.items.forEach(tab => {
                        const fragment = keys.find(k => k === tab.fragment);

                        if (tab.fragment && fragment) {
                            const currentTabId = this.currentTab ? this.currentTab.id : null;
                            this.currentTab = tab;
                            if (currentTabId !== tab.id) {
                                this.cleanTab(this.currentTab);
                            }
                        }
                    });

                    if (!this.currentTab) {
                        this.currentTab = this.tabs.items.find(tab => !tab.fragment || tab.default);
                    }
                } else {
                    this.currentTab = this.tabs.items.find(tab => !tab.fragment || tab.default);
                    this.filesService.folderId$.next(null);
                }

                // получить данные по текущему табику
                this.cleanTab(this.currentTab);
                // сбросить сортировку на сортировку по умолчанию
                this.setSort(this.currentTab);
                this.currentTab.method(this.currentTab, this);

                if (this.filestListType === 1 && this.currentTab.id !== 2) {
                    this.config.allowDelete = this.config.allowMove = this.config.allowRename = this.config.allowShare = false;
                } else {
                    this.config.allowDelete = this.config.allowMove = this.config.allowRename = this.config.allowShare = true;
                }
            });
    }

    /**
     * Сбросить данные табика
     */
    cleanTab(tab: Tab<FilesListItem>) {
        this.folderPermissions = null;
        tab.items = [];
        tab.offset = 0;
        tab.loaded = false;
        this.setSort(tab);
        this.nextHref = null;
    }

    protected setSort(tab: Tab<FilesListItem>) {
        if (tab.name === 'files') {
            this.sortField = 'Filename';
            this.sortAsc = 'Asc';
        } else {
            this.sortField = 'LastModifiedTime';
            this.sortAsc = 'Desc';
        }
    }

    onCreated(event: any) {
        this.filesService.filesUploaded$.next();
    }

    onFileAdded(event: any) {
        // show placeholder
        console.log('file added');
    }

    onFilesAdded(event: any) {
        console.log('files added');
        this.filesService.filesUploaded$.next();
    }

    private reloadCurrentTab() {
        const tab = this.currentTab;
        this.cleanTab(tab);
        tab.method(tab, this);
    }

    onDeleted(items: FilesListItem[]) {
        this.currentTab.items = this.currentTab.items.filter(item => {
            return !items.find(d => d.uniqueId === item.uniqueId);
        });
    }

    onMoved(items: FilesListItem[]) {
        this.currentTab.items = this.currentTab.items.filter(item => {
            return !items.find(d => d.uniqueId === item.uniqueId);
        });
        this.reloadCurrentTab();
    }

    onCopied(items: FilesListItem[]) {
        this.reloadCurrentTab();
    }

    onRenamed(renamedItem: FilesListItem) {
        const foundItem = this.currentTab.items.find(item => item.id === renamedItem.id);
        foundItem.name = renamedItem.name;
        foundItem.url = renamedItem.url;
    }

    /**
     * Действие при выборе всех отмеченными
     */
    onAllChecked(event) {
        this.itemsChecked = event.target.checked;
        this.skipItemsCheck = true;
        this.currentTab.items.forEach(item => {
            item.checked.next(event.target.checked);
        });
        this.skipItemsCheck = false;
        this.setAllChecked();
    }

    /**
     * Отметить все выбранными
     */
    setAllChecked() {
        this.itemsChecked = this.selectedItems().length === this.currentTab.items.length;
    }

    /**
     * Синхронизировать папку
     */
    syncFolder() {
        console.log(this.syncUrl);
        window.location.href = this.syncUrl;
    }

    /**
     * Есть ли хоть один прочитанный?
     */
    isAnyChecked(): boolean {
        return this.currentTab.items && this.currentTab.items.filter(item => item.checked.getValue()).length !== 0;
    }

    /**
     * Получить все выбранные элементы
     */
    selectedItems(): FilesListItem[] {
        if (this.currentTab.items) {
            return this.currentTab.items.filter(item => item.checked.getValue());
        } else {
            return [];
        }
    }

    /**
     * Отобразить контекстное меню
     */
    showContextMenu(event, item: FilesListItem, itemMenu: FileMenuComponent) {
        event.preventDefault();
        event.stopImmediatePropagation();
        if (item.checked.getValue() && this.selectedItems().length > 1) {
            if (this.showAddButton && this.currentTab.name === 'files') {
                itemMenu.selectedItems = this.selectedItems();
                itemMenu.show(event);
            }
            // show complex context menu
        } else {
            // show simple context menu
            this.unselectAll();
            item.checked.next(true);
            itemMenu.show(event);
        }
    }

    /**
     * Выбрать элемент
     */
    selectItem(event, item: FilesListItem) {
        item.checked.next(item.checked.getValue());
        event.preventDefault();
        event.stopImmediatePropagation();
    }

    clickItem(event, item: FilesListItem) {
        event.preventDefault();
        event.stopImmediatePropagation();
    }

    /**
     * Отменить выбор всех элементов
     */
    unselectAll() {
        this.currentTab.items.forEach(item => item.checked.next(false));
    }

    /**
     * Перейти по хлебным крошкам
     */
    navigateBreadcrumb(b: Breadcrumb) {
        this.router.navigate([this.tabs.routerLink], { fragment: `${this.currentTab.fragment}&folder=${b.url}` });
    }

    itemMouseDown(event, item: FilesListItem) {
        if (event.target.className === 'checkbox__icon') {
            item.checked.next(!item.checked.getValue());
            event.preventDefault();
            event.stopImmediatePropagation();
            return;
        }
        if (event.which === 1 && this.showAddButton) {
            this.selecting = true;
            if (!event.ctrlKey) {
                this.unselectAll();
                item.checked.next(true);
            } else {
                item.checked.next(!item.checked.getValue());
            }

            this.up = null;
            event.preventDefault();
            event.stopImmediatePropagation();
            this.moveY = event.clientY;
            this.selectingStart = event.clientY;
        } else {
            this.selecting = false;
        }
    }

    itemMouseUp(event, item: FilesListItem, itemMenu: FileMenuComponent) {
        if (event.which === 1) {
            this.selecting = false;
        }
    }

    itemMouseOver(event, item: FilesListItem) {
        if (this.selecting) {
            item.checked.next(true);

            if (this.up === undefined || this.up === null) {
                if (event.clientY < this.moveY) {
                    // go up
                    this.up = true;
                } else {
                    // go down
                    this.up = false;
                }
            } else {
                if (this.up === true && this.selectingStart < event.clientY) {
                    this.up = false;
                } else if (this.up === false && this.selectingStart > event.clientY) {
                    this.up = true;
                }
            }

            this.moveY = event.clientY;
        }
    }

    itemMouseOut(event, item: FilesListItem) {
        if (this.selecting) {
            if (event.clientY < this.moveY) {
                if (this.up === false) {
                    item.checked.next(false);
                }
            } else {
                if (this.up === true) {
                    item.checked.next(false);
                }
            }
        }
    }

    openShareItem() {
        const selectedItems = this.selectedItems();
        if (selectedItems && selectedItems.length) {
            this.openSharingDialog(selectedItems);
        } else {
            if (this.currentFolderId) {
                // get current
                // this.shareFolder(this.currentFolderUrl);
            } else {
                // get root
                // this.rootFolderId.pipe(take(1)).subscribe(rootFolder => {
                //     // this.shareFolder(rootFolder);
                // }, error => {
                //     this.error = error;
                //     console.log(error);
                // });
            }
        }
    }

    private openSharingDialog(items: FilesListItem[]) {
        // this.filesDialog.dialog.open(FileShareComponent, {
        //     data: {
        //         url: this.webUrl,
        //         placeholder: 'Введите имя или почту, чтобы поделиться',
        //         items: items,
        //         listId: this.listId
        //     }
        // });
    }

    private shareFolder(folderUrl: string) {
        // this.webUrl.pipe(take(1)).subscribe(url => {
        //     this.filesService.webApiGetFolder(url, folderUrl).pipe(take(1), map(res => {
        //         const item = new FilesListItem();
        //         item.name = res.Name;
        //         item.uniqueId = res.UniqueId;
        //         item.url = res.ServerRelativeUrl;
        //         item.created = res.TimeCreated;
        //         item.modified = res.TimeLastModified;
        //         item.type = FilesListItemType.Folder;
        //         return item;
        //     })).subscribe(currentFolder => {
        //         if (currentFolder) {
        //             this.openSharingDialog([currentFolder]);
        //         } else {
        //             console.log(`can't get folder ${folderUrl}`);
        //         }
        //     });
        // });
    }

    isEditor() {
        return this.folderPermissions === PermMask.AddListItems
            || this.folderPermissions === PermMask.ApproveItems
            || this.folderPermissions === PermMask.DeleteListItems
            || this.folderPermissions === PermMask.EditListItems
            || this.folderPermissions === PermMask.ManageLists
            || this.folderPermissions === PermMask.FullMask
            || this.folderPermissions === PermMask.Edit
            || this.folderPermissions === PermMask.Input
            || this.folderPermissions === PermMask.Design;
    }

    allowSort() {
        return this.currentTab.id !== 3;
    }

    /**
     * Отображать меню?
     */
    isShowMenu() {
        return this.currentTab && this.currentTab.name === 'files' && this.showAddButton;
    }

    isShowEmpty(): boolean {
        return !this.error && this.showFiles && this.loaded && this.currentTab && this.currentTab.loaded
            && (!this.currentTab.items || !this.currentTab.items.length);
    }

    isShowItems(): boolean {
        return !this.error && this.showFiles && this.loaded && this.currentTab && this.currentTab.loaded
            && this.currentTab.items && !!this.currentTab.items.length;
    }

    isShowNotFound(): boolean {
        return this.showFiles && this.loaded && this.error && (<any>this.error).status === 404;
    }

    isShowError(): boolean {
        return this.showFiles && this.loaded && this.error && (<any>this.error).status !== 404;
    }

    @HostListener('window:dragover', ['$event'])
    onDragOver(e) {
        if (e.dataTransfer && e.dataTransfer.files) {
            this.renderer.addClass(document.body, 'file-dragging');
            e.preventDefault();
        }
    }

    @HostListener('window:dragend', ['$event'])
    onDragEnd(e) {
        if (e.dataTransfer && e.dataTransfer.files) {
            e.preventDefault();
        }
    }

    @HostListener('window:dragenter', ['$event'])
    onDragEnter(e) {
        if (e.dataTransfer && e.dataTransfer.files) {
            e.preventDefault();
        }
    }

    @HostListener('window:dragleave', ['$event'])
    onDragLeave(e) {
        this.renderer.removeClass(document.body, 'file-dragging');
        e.preventDefault();
    }

    @HostListener('window:drop', ['$event'])
    onDrop(e) {
        if (this.topFilesMenu && e.dataTransfer && e.dataTransfer.files) {
            console.log('drop');
            e.preventDefault();
            e.stopPropagation();
            const files = e.dataTransfer.files;
            if (files) {
                this.topFilesMenu.dropFiles(files);
            }
        }
    }

    private toggleChecked(item: FilesListItem) {
        const currentValue = item.checked.getValue();
        if (currentValue === null || currentValue === undefined) {
            item.checked.next(true);
        } else {
            item.checked.next(!currentValue);
        }
    }

    openFile(e: Event, item: FilesListItem): boolean {
        return this.filesDialog.openFile(e, item, this.currentTab.items);
    }

    protected calculateShowAddButton() {
        console.log(`Not implemented`);
    }
}
