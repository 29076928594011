import { Component, OnInit } from '@angular/core';
import { PostViewComponent } from '@app/feed/components/post-view/post-view.component';
import { GroupsService } from '@app/groups/services/groups.service';
import { FeedService } from '@app/feed/services/feed.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '@app/profile/services/users.service';
import { HelperService } from '@app/core/services/helper.service';
import { takeUntil } from 'rxjs/operators';

/**
 * Отображение поста в группе
 *
 * @export
 * @class GroupPostViewComponent
 * @extends {PostViewComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-group-post-view',
  templateUrl: './group-post-view.component.html'
})
export class GroupPostViewComponent extends PostViewComponent implements OnInit {

  constructor(
    protected usersService: UsersService,
    protected groupsService: GroupsService,
    protected feedService: FeedService,
    protected route: ActivatedRoute,
    protected router: Router,
    public helper: HelperService
  ) { super(usersService, feedService, route, router, helper); }

  ngOnInit() {

    this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
      this.group = group;
      if (this.group && this.group.isCanView) {
        super.ngOnInit();
      }
    });
  }
}
