import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from '@app/shared/components/404/page-not-found.component';
import { ForbiddenComponent } from './shared/components/403/forbidden.component';
import { InternalServerErrorComponent } from './shared/components/503/internal-server-error.component';

const routes: Routes = [
  { path: '403', component: ForbiddenComponent },
  { path: '503', component: InternalServerErrorComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
