import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '@app/profile/services/users.service';
import { FeedService } from '@app/feed/services/feed.service';
import { SubscribeService } from '@app/subscribe/services/subscribe.service';
import { FeedBaseComponent } from '@app/feed/components/feed-base/feed-base.component';
import { LikesService } from '@app/likes/services/likes.service';
import { SignalRService } from '@app/signalr/signalR.service';
import { HelperService } from '@app/core/services/helper.service';
import { FeedType } from '@app/feed/model/feed-type.model';
import { PostProfileSelectorType } from '@app/feed/model/post-profile-selector-type';

/**
 * Лента пользователя в профиле по ссылке profile/{id}/feed
 */
@Component({
  selector: 'app-user-feed',
  templateUrl: './user-feed.component.html'
})
export class UserFeedComponent extends FeedBaseComponent implements OnInit, OnDestroy {

  selectorType: PostProfileSelectorType = PostProfileSelectorType.exceptBusiness;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected usersService: UsersService,
    protected subscribeService: SubscribeService,
    protected feedService: FeedService,
    protected likesService: LikesService,
    protected signalRService: SignalRService,
    protected ngZone: NgZone,
    public helper: HelperService) {
    super(router, route, usersService, subscribeService, feedService, likesService, ngZone, helper);
  }

  ngOnInit() {
    super.onBaseResize();
    this.getFeed();
    this.onFeedUpdate();
  }

  protected getFeedInternal() {
    if (this.currentUser) {
      this.user = this.currentUser;
      // получение постов
      this.operateFeedResult(this.feedService.getUserFeed(this.user.id, this.offset, this.limit));
    }
  }

  protected onFeedUpdate() {
    this.signalRService.feedHub.onNewUserPost.subscribe(res => {
      this.onFeedNewPost(res);
    });
    this.signalRService.feedHub.onPostEdited.subscribe(res => {
      this.onFeedPostEdited(res);
    });
    this.signalRService.feedHub.onPostRemoved.subscribe(res => {
      this.onFeedPostRemoved(Number(res));
    });
  }

  /**
   * Обработка результата списка постов с бэка
   */
  // protected operateFeedResult(method: Observable<FeedEventViewModel[]>) {
  //   this.loading = true;
  //   this.lastOffset = this.offset;
  //   method
  //     .pipe(finalize(() => this.loading = false), takeUntil(this.destroyed$))
  //     .subscribe(resFeed => {

  //       if (resFeed && resFeed.length) {
  //         for (let index = 0; index < resFeed.length; index++) {
  //           if (index % 2 === 0) {
  //             this.postsRight.push(resFeed[index]);
  //           } else {
  //             this.postsLeft.push(resFeed[index]);
  //           }
  //         }
  //       }

  //       // resFeed.forEach(item => {
  //       //   if (!this.posts.find(p => p.event.id === item.event.id)) {
  //       //     this.posts.push(item);
  //       //   }
  //       // });
  //       this.offset = this.posts.length;
  //       this.loaded = true;
  //     }, error => {
  //       this.loaded = false;
  //     });
  // }

  getFeedType(): FeedType {
    return FeedType.UserFullFeed;
  }
}
