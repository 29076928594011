<div class="cos">
  <div class="user-header" *ngIf="loaded && user">
    <a href="#" class="link-profile" routerLink="/profile/{{user?.id}}"
      (click)="usersService.navigateProfile(user?.id)">
      <app-user-avatar [user]="user" [linkClass]="'avatar _30'" [imageClass]="'avatar__image'" [asSpan]="true">
      </app-user-avatar>
      <span class="link-profile__text" [appUserTooltip]="user?.id">{{user?.fullName}}</span>
    </a>
    <app-breadcrumbs></app-breadcrumbs>
  </div>
  <div class="cos__header" *ngIf="loaded">
    <app-tabs [tabs]="tabs"></app-tabs>
    <a *ngIf="isCurrent" class="btn btn-plus pull-right" (click)="showAddUser()">+</a>
  </div>
  <!-- header preloader -->
  <app-tab-preloader *ngIf="!loaded"></app-tab-preloader>
  <!--  header preloader END -->
  <div class="cos__body" *ngIf="loaded && currentTab && currentTab.loaded">
    <app-user-simple-card [link]="link" *ngFor="let link of currentTab?.items"></app-user-simple-card>
    <div class="co" *ngIf="currentTab?.loading">
      <mat-spinner [diameter]="56"></mat-spinner>
    </div>
  </div>
  <!-- Empty tab -->
  <div class="card"
    *ngIf="!currentTab?.loading && loaded && currentTab?.loaded && (!currentTab?.items || !currentTab?.items.length)">
    <div class="group-empty">
      <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" class="group-empty__img">
      <div class="group-empty__text">{{currentTab?.emptyText}}</div>
    </div>
  </div>
  <!-- Empty tab END-->
  <!-- header preloader -->
  <app-tabbed-list-preloader *ngIf="!currentTab?.loaded"></app-tabbed-list-preloader>
  <!--  header preloader END -->
</div>