import {
  Component,
  OnInit,
  Input,
  ViewChild,
  forwardRef,
  OnDestroy,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AttachmentViewModel } from '@app/attachments/file-attachments/model/attachment-view-model.model';
import { FeedEventViewModel, FeedEventGroupInfo } from '@app/feed/model/feed-event-view-model.model';
import { FeedService } from '@app/feed/services/feed.service';
import { User } from '@app/profile/model/user.model';
import { UsersService } from '@app/profile/services/users.service';
import { ElementType } from '@app/core/model/element-type.enum';
import { AttachmentsListComponent } from '@app/attachments/file-attachments/components/attachments-list/attachments-list.component';
import { EventType, INewFeedEventBody } from '@app/feed/model/feed-event.model';
import { GroupsService } from '@app/groups/services/groups.service';
import { GroupType } from '@app/groups/model/group-type.model';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { ProfileType } from '@app/core/model/profile-type.enum';
import { FeedType } from '@app/feed/model/feed-type.model';
import { SubscribeService } from '@app/subscribe/services/subscribe.service';
import { UserLink } from '@app/subscribe/model/user-link';
import { CommentsListComponent } from '@app/comments/components/comments-list/comments-list.component';
import { BaseComponent } from '@app/core/components/base.component';
import { Helper } from '@app/core/helpers/helper';
import { environment } from '@env/environment';
import { CommentViewModel } from '@app/comments/model/comment-view-model.model';
import { IMediaFile } from '@app/gallery/model/media-file';
// tslint:disable-next-line:max-line-length
import { MediaAttachmentsListComponent } from '@app/attachments/media-attachments/components/media-attachments-list/media-attachments-list.component';
import { MediaAttachmentViewModel } from '@app/attachments/media-attachments/model/media-attachment-view-model';
import { take, takeUntil, finalize } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { CommentNewComponent } from '@app/comments/components/comment-new/comment-new.component';
import { PostMode } from '../post-new/post-new.component';
import { IUserGroupCounters, GroupInfo } from '@app/groups/model/group-info.model';
import { AlertsService } from '@app/shared/services/alerts.service';
import { IIntryProfile } from '@app/shared/model/intry-profile';
import { GroupRequestState } from '@app/groups/model/group-request-state.model';

import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { IDatafileModel } from '@app/files/model/data-file-model';
import { SettingsService } from '@app/shared/services/settings.service';
import { ITag, TagType } from '@app/shared/model/tag';
import { AddTagsComponent, AddTagsComponentMode } from '@app/shared/components/add-tags/add-tags.component';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';

/**
 * Компонент отображения поста
 */
@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() post: FeedEventViewModel;
  @Input() allowEdit: boolean;
  @Input() allowDelete: boolean;
  @Input() allowShare: boolean;
  @Input() readOnly: boolean;
  @Input() feedType: FeedType;
  @Input() highlightText: string;
  @Input() user: User;
  @Input() showBody: boolean;
  @Input() startEdit: boolean;

  @Output() remove: EventEmitter<any> = new EventEmitter();

  @ViewChild(forwardRef(() => AttachmentsListComponent))
  protected attachmentsList: AttachmentsListComponent;
  @ViewChild(forwardRef(() => MediaAttachmentsListComponent))
  protected mediaAttachmentsList: MediaAttachmentsListComponent;
  @ViewChild(forwardRef(() => CommentsListComponent))
  protected commentsList: CommentsListComponent;
  @ViewChild(forwardRef(() => CommentNewComponent))
  protected commentNew: CommentNewComponent;
  @ViewChild(forwardRef(() => AddTagsComponent), { static: false })
  protected tagsList: AddTagsComponent;

  currentUser: User;
  isEdit = false;
  attachments: AttachmentViewModel[] = [];
  mediaAttachments: MediaAttachmentViewModel[] = [];
  posting = false;
  uploadFiles = false;
  elementType = ElementType.Event;
  allowNews: boolean;
  showScope: boolean;
  isNewEmployee: boolean;
  showOnMainPage = false;

  targetProfile: IIntryProfile;
  profileType = ProfileType;

  // временные значения
  text: string;
  title: string;
  announce: string;
  disableComment: boolean;
  disableLike: boolean;
  pin: boolean;
  pinDateEnd: Date;

  ownerType: ProfileType;
  ownerId: number;

  mentions: User[] = [];
  mentionsEdit: User[] = [];

  eventType = EventType;

  userGroupCounters: IUserGroupCounters;
  postMode: PostMode = PostMode.text;
  postModes = PostMode;

  // is post from other user feed
  isExternalPost: boolean;

  // additional post data
  showSubscribe: boolean;
  showJoin: boolean;
  groupTypeName: string;
  groupBackground: SafeStyle;
  groupId: number;

  // avatar changed event
  avatarImage: string;

  // profile edit event
  propertyNameText: string;
  propertyDisplayNameText: string;
  propertyNewNameText: string;
  propertyValue: string;

  // user added to group
  groupUsersAddedOrRemoved: any[] = [];
  groupUsersAddedOrRemovedText: string;
  groupUsersAddedOrRemovedSuffix: string;

  // group changed
  groupAvatarImage: string;
  businessGroupAvatarImage: string;

  // user subscribed to
  subscriptionUser: UserLink;
  isSubscribed: boolean = undefined;

  isDraft = false;

  maxDate = new Date();
  publishDate: moment.Moment;

  tags: ITag[] = [];
  TagType = TagType;
  AddTagsComponentMode = AddTagsComponentMode;

  protected orgGroupId: number;

  constructor(
    protected usersService: UsersService,
    protected groupsService: GroupsService,
    protected sanitizer: DomSanitizer,
    protected subscribeService: SubscribeService,
    protected feedService: FeedService,
    protected alertsService: AlertsService,
    protected breadcrumbsService: BreadcrumbsService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected cdr: ChangeDetectorRef,
    protected settingsService: SettingsService,
    public helper: HelperService,
  ) {
    super(helper);
  }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;

      if (!this.post) {
        // there is no post
        return;
      }

      this.ownerId = this.post.event.ownerId;
      this.ownerType = this.post.event.ownerType;

      this.breadcrumbsService.breadcrumbs.next([
        { title: 'Публикация', routerUrl: `/profile/${this.ownerId}/post/${this.post.event.id}` },
      ]);

      this.cdr.detectChanges();

      this.mentions = (this.post.mentions || []).map(item => item.user);

      if (this.readOnly) {
        this.allowEdit = this.allowDelete = this.allowShare = false;
      } else {
        this.allowEdit = this.canEdit(this.post);
        this.allowDelete = this.canDelete(this.post);
        this.allowShare = this.canShare(this.post);
      }

      // for group post
      if (this.post.group) {
        this.groupTypeName = this.getGroupType(this.post.group.type);
        this.groupBackground = this.getPictureUrl(this.post.group);
        this.groupId = this.post.event.ownerId;
      }

      switch (this.post.event.eventType) {
        case EventType.GroupUserJoin:
        case EventType.UserNewGroup:
        case EventType.BusinessGroupNewPost:
        case EventType.BusinessGroupNewsPost:
        case EventType.GroupNewPost:
        case EventType.GroupNewsPost:
        case EventType.BusinessGroupNewEmployeePost:
        case EventType.Repost:
          if (this.feedType !== FeedType.GroupFeed) {
            this.getPostGroup();
          }
          if (this.post.group) {
            this.groupAvatarImage = this.post.group.pictureUrl
              ? Helper.concatUrls((<any>window).signalRServer, this.post.group.pictureUrl)
              : null;
            this.businessGroupAvatarImage = this.groupsService.getGroupSmallPicture(this.post.group);
          }
          break;
        case EventType.UserAvatarChanged:
          if (this.post.attachments && this.post.attachments.length) {
            const avatar = this.post.attachments[0].attachment;
            if (avatar && avatar.url) {
              const prefixUrl =
                avatar.url.indexOf('/api/documentFile') !== -1
                  ? this.helper.getSiteUrl()
                  : this.helper.getSiteHostUrl();
              this.avatarImage = Helper.concatUrls(prefixUrl, avatar.url);
            } else {
              // todo: if no attachment
            }
          }
          if (this.post.group) {
            if (this.post.group.pictureUrl) {
              const prefixUrl =
                this.post.group.pictureUrl.indexOf('/api/documentFile') !== -1
                  ? this.helper.getSiteUrl()
                  : this.helper.getSiteHostUrl();

              this.groupAvatarImage = Helper.concatUrls(prefixUrl, this.post.group.pictureUrl);
            } else {
              this.groupAvatarImage = null;
            }

            this.businessGroupAvatarImage = this.groupsService.getGroupSmallPicture(this.post.group);
          }
          break;
        case EventType.UserProfileEdit:
          try {
            const data = this.post.event.data;
            switch (data.name) {
              case 'CellPhone':
                this.propertyNameText = 'мобильный телефон';
                this.propertyNewNameText = 'Новый номер';
                this.propertyValue = data.value;
                break;
              default:
                this.propertyNameText = 'свойство профиля';
                this.propertyNewNameText = 'Новое значение';
                this.propertyValue = data.value;
                break;
            }

            if (data.displayName) {
              this.propertyDisplayNameText = data.displayName;
            } else {
              this.propertyDisplayNameText = data.name;
            }
          } catch (e) {
            console.log(e);
          }
          break;
        case EventType.GroupNewUser:
        case EventType.GroupUserRemoved:
          try {
            const data = this.post.event.data;
            if (data) {
              this.groupUsersAddedOrRemoved = [];
              if (data.userId && data.userName) {
                this.groupUsersAddedOrRemoved.push({
                  fullName: data.userName,
                  id: data.userId,
                });
              }
              if (data.users) {
                data.users.forEach(user => {
                  this.groupUsersAddedOrRemoved.push({
                    fullName: user.userName,
                    id: user.userId,
                  });
                });
              }

              if (this.post.event.eventType === EventType.GroupNewUser) {
                // added
                this.groupUsersAddedOrRemovedText = 'добавил(-а) в группу';
              } else {
                // removed
                this.groupUsersAddedOrRemovedText = 'удалил(-а) из группы';
              }

              if (this.groupUsersAddedOrRemoved.length) {
                if (this.groupUsersAddedOrRemoved.length > 1) {
                  this.groupUsersAddedOrRemovedSuffix = ' пользователей';
                } else {
                  this.groupUsersAddedOrRemovedSuffix = ' пользователя';
                }
              }
            }
          } catch (e) {
            console.log(e);
          }
          break;
        case EventType.GroupAvatarChanged:
          if (this.post.attachments && this.post.attachments.length) {
            const avatar = this.post.attachments[0].attachment;
            if (avatar && avatar.url) {
              this.groupAvatarImage = avatar.url;
            } else {
              // todo: if no attachment
            }
          }
          break;
        case EventType.GroupNameChanged:
        case EventType.GroupTypeChanged:
          try {
            const data = this.post.event.data;
            switch (data.name) {
              case 'groupName':
                this.propertyNameText = 'название группы';
                this.propertyNewNameText = 'Новое название';
                this.propertyValue = data.value;
                break;
              case 'groupType':
                this.propertyNameText = 'тип группы';
                this.propertyNewNameText = 'Новый тип';
                this.propertyValue = this.getGroupType(+data.value);
                break;
            }
          } catch (e) {
            console.log(e);
          }
          break;
        default:
          // set other user post flag
          if (this.feedType === FeedType.UserFullFeed) {
            this.isExternalPost = this.post.author.id !== this.post.event.ownerId;
          }
          break;
      }

      if (this.feedType === FeedType.UserFullFeed) {
        this.isExternalPost = this.post.author.id !== this.post.event.ownerId;
      }

      if (this.startEdit) {
        this.onEdit();
      }

      this.cdr.detectChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['post']) {
      this.cdr.detectChanges();
    }
  }

  /**
   * Событие при редактировании поста
   */
  onEdit() {
    // проверить текущий режим поста
    if (
      this.post.event.eventType === EventType.BusinessGroupNewsPost ||
      this.post.event.eventType === EventType.GroupNewsPost ||
      this.post.event.eventType === EventType.BusinessGroupNewEmployeePost
    ) {
      this.postMode = PostMode.news;

      this.feedService.getPost(this.post.event.id, false).subscribe(post => {
        this.post.event = post.event;

        this.title = post.event.title;
        this.announce = post.event.announce;

        this.showOnMainPage = post.event.showOnMainPage;
        this.isDraft = post.event.isDraft;
        this.publishDate = moment(post.event.created);
        this.onEditPost();
      });

      this.isNewEmployee = this.post.event.eventType === EventType.BusinessGroupNewEmployeePost;
    } else {
      this.postMode = PostMode.text;
      this.onEditPost();
    }
  }

  private onEditPost() {
    if (this.userGroupCounters) {
      this.checkPostMode();
    } else {
      this.groupsService
        .getUserGroupCounters(this.currentUser.id)
        .pipe(take(1))
        .subscribe(res => {
          this.userGroupCounters = res;
          this.checkPostMode();
        });
    }

    // временное значение тела поста
    this.text = this.post.event.body;
    this.disableComment = this.post.event.disableComment;
    this.disableLike = this.post.event.disableLike;
    this.pin = this.post.event.pin;
    this.pinDateEnd = this.post.event.pinDateEnd;
    // вложения
    this.attachments = [];
    this.post.attachments.forEach(o => this.attachments.push(o));
    // медиа вложения
    this.mediaAttachments = [];
    this.post.mediaAttachments.forEach(o => this.mediaAttachments.push(o));
    // упомнинания
    this.mentionsEdit = [];
    if (this.mentions) {
      this.mentions.forEach(o => this.mentionsEdit.push(o));
    }
    // тэги
    this.tags = [];
    this.tagsList?.tags.forEach(o => this.tags.push(o));
    this.isEdit = true;
    this.cdr.detectChanges();
  }

  public onShare() {
    this.router.navigate(
      [
        'profile',
        this.currentUser.id,
        'post',
        this.post.event.repostId ? this.post.event.repostId : this.post.event.id,
        'share',
      ],
      { fragment: `from=${this.router.url}` },
    );
  }

  /**
   * Событие при удалении поста
   */
  onRemove() {
    if (!this.posting) {
      this.feedService.removePost(this.post.event.id).subscribe(
        () => {
          this.remove.emit(this.post);
        },
        () => { },
      );
    }
  }

  /**
   * Событие при отмене закрепления todo: переделать на отдельный метод
   */
  onCancelPin() {
    if (!this.posting) {
      // временное значение тела поста
      this.text = this.post.event.body;
      this.disableComment = this.post.event.disableComment;
      this.disableLike = this.post.event.disableLike;

      if (
        this.post.event.eventType === EventType.BusinessGroupNewsPost ||
        this.post.event.eventType === EventType.GroupNewsPost
      ) {
        this.title = this.post.event.title;
        this.announce = this.post.event.announce;
        this.postMode = PostMode.news;
      } else {
        this.postMode = PostMode.text;
      }

      this.pin = false;
      this.pinDateEnd = null;
      // вложения
      this.attachments = [];
      this.post.attachments.forEach(o => this.attachments.push(o));
      // медиа вложения
      this.mediaAttachments = [];
      this.post.mediaAttachments.forEach(o => this.mediaAttachments.push(o));
      // упомнинания
      this.mentionsEdit = [];
      if (this.mentions) {
        this.mentions.forEach(o => this.mentionsEdit.push(o));
      }
      // тэги
      this.tags = [];
      this.tagsList.tags.forEach(o => this.tags.push(o));
      this.submit();
    }
  }

  isValid() {
    return (
      ((this.postMode === PostMode.news && this.text && this.title) || this.postMode === PostMode.text) &&
      !this.mediaAttachmentsList.filesUploading
    );
  }

  isBusinessNews(): boolean {
    return (this.postMode === PostMode.news
      && this.post && (!this.post.group || this.post.group.type === GroupType.Business));
  }

  /**
   * Отмена редактирования поста
   */
  cancel() {
    this.isEdit = false;
    this.clear();
  }

  /**
   * Ответить на пост
   */
  reply() {
    let author = null;
    if (this.post && (!this.post.group || this.post.group.type !== GroupType.Business)) {
      author = this.post.author;
    }
    this.commentNew.activateNoParent(author);
  }

  saveAsDraft() {
    this.isDraft = true;
    this.submit();
  }

  publish() {
    this.isDraft = false;
    this.submit();
  }

  /**
   * Отправка отредактированного поста
   */
  submit() {
    // тело поста
    this.post.event.body = this.text;
    // вложения поста
    this.post.attachments = [];
    this.attachments.forEach(o => this.post.attachments.push(o));

    // медиа вложения поста
    this.post.mediaAttachments = [];
    this.mediaAttachments.forEach(o => this.post.mediaAttachments.push(o));

    // упоминания поста
    this.mentions = [];
    this.mentionsEdit.forEach(o => this.mentions.push(o));

    // тэги поста
    this.tags = [];
    if (this.tagsList?.tags) {
      this.tagsList.tags.forEach(o => this.tags.push(o));
    }

    if (
      ((this.text || this.post.event.repostId) && !this.posting) ||
      (this.attachments && this.attachments.length) ||
      (this.attachmentsList &&
        this.attachmentsList.attachmentsToUpload &&
        this.attachmentsList.attachmentsToUpload.length) ||
      (this.mediaAttachments && this.mediaAttachments.length) ||
      (this.mediaAttachmentsList &&
        this.mediaAttachmentsList.attachmentsToUpload &&
        this.mediaAttachmentsList.attachmentsToUpload.length)
    ) {
      this.posting = true;

      if (
        this.attachmentsList &&
        this.attachmentsList.attachmentsToUpload &&
        this.attachmentsList.attachmentsToUpload.length
      ) {
        this.uploadFiles = true;
        // upload attached files at first
        this.attachmentsList.uploadAttachedFiles(ElementType.Event);
      }

      if (!this.uploadFiles) {
        // send to server
        this.send();
      }
    } else {
      // todo: error empty text
    }
  }

  private send() {
    // attachments
    const attachmentsIds = this.attachments ? this.attachments.map(att => att.attachment.guid) : null;
    // media attachments
    const mediaAttachmentsIds = this.mediaAttachments ? this.mediaAttachments.map(att => att.mediaAttachment.id) : null;
    // mentions
    const mentionsIds = this.mentions ? this.mentions.map(item => item.id) : null;
    // tags
    const tagsIds = this.tags ? this.tags.map(item => item.id) : null;

    const body: INewFeedEventBody = {
      text: this.text,
      disableComment: this.disableComment,
      disableLike: this.disableLike,
    };

    // для новостей дополнительные поля
    if (this.postMode === PostMode.news) {
      const now = new Date();
      body.title = this.title;
      body.announce = this.announce;
      body.isNews = true;
      body.isNewEmployee = this.isNewEmployee;
      body.isDraft = this.isDraft;
      body.showOnMainPage = this.showOnMainPage;
      // TODO: поправить это
      if (!this.publishDate) {
        body.publishDate = this.post.event.created;
      } else {
        try {
          this.publishDate = this.publishDate.set('hour', now.getHours()).set('minute', now.getMinutes());
        } catch (e) { }
        body.publishDate = this.publishDate.toDate();
      }
    }

    // закрепление только для поста в группе
    if (this.ownerType === ProfileType.Group) {
      body.pinEvent = this.pin;
      body.pinDateEnd = this.pinDateEnd;
    } else {
      body.pinEvent = false;
      body.pinDateEnd = null;
    }

    // отправляем данные на сервер, устанавливаем значение поста, если всё оке
    this.feedService
      .editPost(this.post.event.id, body, mediaAttachmentsIds, attachmentsIds, mentionsIds, tagsIds)
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => {
          this.posting = false;
          this.isEdit = false;
          this.clear();
          this.cdr.detectChanges();
        }),
      )
      .subscribe(
        post => {
          this.post = post;
          this.alertsService.riseSuccess('Публикация изменена.');
        },
        () => {
          this.alertsService.riseError('Произошла ошибка при изменении публикации. Попробуйте позже.');
        },
      );
  }

  pinText() {
    if (
      this.targetProfile &&
      this.targetProfile.profile &&
      (<GroupInfo>this.targetProfile.profile).type === GroupType.Business
    ) {
      return 'Закрепить в ленте группы и на главной';
    } else {
      return 'Закрепить в ленте группы';
    }
  }

  /**
   * При выборе профиля из списка сохраняем его значение
   *
   * @param {IIntryProfile} profile
   */
  onProfileSelected(profile: IIntryProfile) {
    this.targetProfile = profile;
  }

  /**
   * Изменить режим создания поста
   *
   * @param {PostMode} mode
   */
  changePostMode(mode: PostMode): void {
    this.postMode = mode;
    this.cdr.detectChanges();
  }

  onRemoveAttachment(attachment: AttachmentViewModel) {
    this.attachments = this.attachments.filter(
      att =>
        (attachment.attachment.guid && att.attachment.guid !== attachment.attachment.guid) ||
        (!attachment.attachment.guid && att.attachment.title !== attachment.attachment.title),
    );
  }

  onRemoveMediaAttachment(attachment: MediaAttachmentViewModel) {
    this.mediaAttachments = this.mediaAttachments.filter(
      att =>
        (attachment.mediaAttachment.guid && att.mediaAttachment.guid !== attachment.mediaAttachment.guid) ||
        (!attachment.mediaAttachment.guid && att.mediaAttachment.title !== attachment.mediaAttachment.title),
    );
  }

  onFilesUploaded(event: IDatafileModel[]) {
    // add uploaded attachments
    this.attachments = this.attachments.filter(att => att.attachment.guid);
    if (event) {
      event.forEach(file => {
        this.attachments.push({
          attachment: {
            title: file.name,
            modified: file.created,
            ownerID: file.authorId,
            url: `/api/documentFile/${file.guid}`,
            guid: file.guid,
          },
          owner: null, // file.author
        });
      });
    }
    this.uploadFiles = false;

    // send to server
    if (!this.uploadFiles) {
      this.send();
    }
  }

  onMediaFilesUploaded(event: IMediaFile[]) {
    // add uploaded attachments
    this.mediaAttachments = this.mediaAttachments.filter(att => att.mediaAttachment.id);
    if (event) {
      event.forEach(mediaFile => {
        this.mediaAttachments.push({
          mediaAttachment: {
            id: mediaFile.id,
            title: mediaFile.name,
            url: mediaFile.url,
            previewUrl: mediaFile.previewUrl,
            guid: mediaFile.guid,
            type: mediaFile.type,
          },
        });
      });
    }
    this.cdr.detectChanges();
  }

  /**
   * Событие при добавлении нового комментария
   *
   * @param CommentViewModel comment
   */
  onNewCommentAdded(comment: CommentViewModel) {
    this.commentsList.onCommentAdded(comment);
  }

  /**
   * Комментарий был добавлен. Обновление счётчиков.
   *
   * @param number count
   */
  onCommentAdded(count: number) {
    this.post.commentsCount = count;
  }

  /**
   * Комментарий был удалён. Обновление счётчиков.
   *
   * @param number count
   */
  onCommentDeleted(count: number) {
    this.post.commentsCount = count;
  }

  /**
   * Перейти в выбранную группу
   *
   * @param * e
   * @param * id
   */
  navigateGroup(e, id) {
    e.preventDefault();
    this.groupsService.navigateGroup(id);
  }

  getPostPictureUrl(): SafeStyle {
    if (this.groupAvatarImage) {
      const prefixUrl =
        this.groupAvatarImage.indexOf('/api/documentFile') !== -1
          ? this.helper.getSiteUrl()
          : this.helper.getSiteHostUrl();

      const style = `background-image: url(${Helper.concatUrls(prefixUrl, Helper.getPicture(this.groupAvatarImage))})`;
      return this.sanitizer.bypassSecurityTrustStyle(style);
    }
    return '';
  }

  /**
   * Обработка изменения значений в поле Название
   *
   * @protected
   * @param * e
   */
  onKey(e) {
    // this.isValid = e.target.value && /\S/.test(e.target.value) ? true : false;
  }

  /**
   * Проверить режим создания нового поста.
   * 1. Новости могут создавать только пользователи, которые являются админами в каких либо группах.
   * 2. Блок выбора профиля (свой или какую-либо группу), в который добавить пост отображается только если
   * пользователь админ или участник какой-либо группы.
   *
   */
  protected checkPostMode() {
    if (this.userGroupCounters) {
      if (this.userGroupCounters.asAdministrator) {
        this.showScope = this.allowNews = this.user && this.user.id === this.currentUser.id;
      }
      if (this.userGroupCounters.asMember) {
        this.showScope = this.user && this.user.id === this.currentUser.id;
      }
    }
    this.cdr.detectChanges();
    return;
  }

  isShowNewEmployeeCheckbox(): boolean {
    return (
      this.postMode === PostMode.news &&
      this.post &&
      !!this.post.group &&
      this.post.group.title === this.groupsService.groupOrgNews$.getValue()
    );
  }

  private clear() {
    this.text = '';
    this.announce = '';
    this.title = '';
    this.attachments = [];
    this.mediaAttachments = [];
  }

  private getPostGroup() {
    if (
      this.post.event.eventType === this.eventType.GroupUserJoin ||
      this.post.event.eventType === this.eventType.UserNewGroup ||
      this.post.event.eventType === this.eventType.BusinessGroupNewPost ||
      this.post.event.eventType === this.eventType.BusinessGroupNewsPost ||
      this.post.event.eventType === this.eventType.GroupNewsPost ||
      this.post.event.eventType === this.eventType.GroupNewPost
    ) {
      if (this.post.group) {
        this.showSubscribe = this.showSubscribeButton();
        this.showJoin = this.showJoinButton();
      }
    }
  }

  // временно
  private getGroupType(groupType: number) {
    switch (groupType) {
      case 0:
        return 'Открытая группа';
      case 1:
        return 'Закрытая группа';
      case 2:
        return 'Бизнес-группа';
      case 3:
        return 'Приватная группа';
    }
  }

  private showJoinButton() {
    return this.post.group.memberState !== 2 && this.post.group.type !== GroupType.Business;
  }

  private showSubscribeButton() {
    return this.post.group.subscriberState !== 2 && this.post.group.type !== GroupType.Business;
  }

  private getPictureUrl(group: FeedEventGroupInfo): SafeStyle {
    if (group && group.pictureUrl) {
      const style = `background-image: url('${this.groupsService.getGroupSmallPicture(group)}')`;
      return this.sanitizer.bypassSecurityTrustStyle(style);
    }
    return '';
  }

  private groupChangedEvent() {
    return EventType.GroupNameChanged || EventType.GroupTypeChanged;
  }

  /**
   * Подписаться или отписаться от пользователя
   *
   * @param {number} userId
   */
  private subscribe(userId: number) {
    if (userId) {
      if (this.isSubscribed) {
        this.subscribeService
          .unsubscribe(userId)
          .pipe(take(1))
          .subscribe(
            res => {
              this.isSubscribed = false;
              console.log('successfully updated subscription');
            },
            err => {
              console.log(`error on unsubscribe. e='${err}'`);
            },
          );
      } else {
        this.subscribeService
          .subscribe(userId)
          .pipe(take(1))
          .subscribe(
            res => {
              this.isSubscribed = true;
              console.log('successfully updated subscription');
            },
            err => {
              console.log(`error on subscribe. e='${err}'`);
            },
          );
      }
    } else {
      console.log(`error on subscribe. userId is null='${userId}'`);
    }
  }

  isBusinessGroupPost() {
    return (
      this.post &&
      this.post.event &&
      (this.post.event.eventType === EventType.BusinessGroupNewPost ||
        this.post.event.eventType === EventType.BusinessGroupNewsPost)
    );
  }

  /**
   * Отображать блок действий с постом
   */
  isShowActions() {
    return (
      this.post &&
      this.post.event &&
      (!this.post.event.disableComment || !this.post.event.disableLike) &&
      !this.readOnly
    );
  }

  canEdit(post: FeedEventViewModel) {
    switch (this.feedType) {
      case FeedType.GroupFeed:
        return (
          post.author.id === this.currentUser.id || (post.group && post.group.adminState === GroupRequestState.Approved)
        );
      case FeedType.UserFullFeed:
        return post.author.id === this.currentUser.id;
      case FeedType.ProfileFeed:
        return post.author.id === this.currentUser.id;
      default:
        return post.author.id === this.currentUser.id;
    }
  }

  canDelete(post: FeedEventViewModel) {
    if (this.currentUser && this.currentUser.isAdmin) {
      return true;
    }

    switch (this.feedType) {
      case FeedType.GroupFeed:
      case FeedType.UserFullFeed:
        if (post.event.ownerType === ProfileType.Group) {
          return (
            post.author.id === this.currentUser.id ||
            (post.group && post.group.adminState === GroupRequestState.Approved)
          );
        }
        return post.author.id === this.currentUser.id;
      case FeedType.ProfileFeed:
        return (
          (this.currentUser.id === post.event.ownerId && post.event.ownerType === ProfileType.User) ||
          post.author.id === this.currentUser.id
        );
      default:
        return post.author.id === this.currentUser.id;
    }
  }

  canShare(post: FeedEventViewModel) {
    if (
      post.event.ownerType === ProfileType.User ||
      (post.event.ownerType === ProfileType.Group &&
        post.group &&
        (post.group.type === GroupType.Open || post.group.type === GroupType.Business))
    ) {
      return true;
    }
    return false;
  }

  onChanged(html: string): void {
    this.text = html;
  }
}
