<ng-container *ngIf="isVisible()">
  <div class="btn btn-dots tooltip" [ngClass]="{'btn-dots-white': whiteDots}" mat-button [matMenuTriggerFor]="menu"></div>
  <mat-menu #menu="matMenu">
    <div class="icon-menu" #menuContainer>
      <a mat-menu-item [routerLink]="getEditLink()" class="icon-menu__item" *ngIf="config?.allowEdit">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/edit.svg" alt="" class="icon-menu__img">
        </span>
        <span>Редактировать</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="config?.allowArchive"
        (click)="archiveClick()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/archive.svg" alt="" class="icon-menu__img">
        </span>
        <span>Архивировать</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="config?.allowDelete"
        (click)="deleteClick()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/remove.svg" alt="" class="icon-menu__img">
        </span>
        <span>Удалить</span>
      </a>
    </div>
  </mat-menu>
</ng-container>
