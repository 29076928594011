<ng-container *ngIf="config?.allowChangeStatus">
  <div class="btn btn-dots tooltip" mat-button [matMenuTriggerFor]="menu"></div>
  <mat-menu #menu="matMenu">
    <div class="icon-menu" #menuContainer>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="config?.allowChangeStatus" (click)="inProcessClick()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/eye-progress.svg" alt="" class="icon-menu__img">
        </span>
        <span>Взять в работу</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="config?.allowChangeStatus" (click)="prospectiveClick()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/edit.svg" alt="" class="icon-menu__img">
        </span>
        <span>Перспективный</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="config?.allowChangeStatus" (click)="declineClick()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/remove.svg" alt="" class="icon-menu__img">
        </span>
        <span>Отклонить</span>
      </a>
    </div>
  </mat-menu>
</ng-container>