import { Component, OnInit, OnDestroy, ChangeDetectorRef, HostListener } from '@angular/core';
import {
  ISurvey,
  SurveyQuestionType,
  SurveyQuestionOptionType,
  ITakeSurvey,
  ITakeSurveyQuestion,
  ISurveyQuestion,
  ISurveyQuestionOption,
  ISurveyQuestionRangeOptionValue,
  SurveyType
} from '@app/surveys/model/survey';
import { BaseComponent } from '@app/core/components/base.component';
import { SurveysService } from '@app/surveys/services/surveys.service';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { HelperService } from '@app/core/services/helper.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { takeUntil, switchMap, take } from 'rxjs/operators';
import { AlertsService } from '@app/shared/services/alerts.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';

/**
 * Компонент просмотра опроса
 *
 * @export
 * @class SurveyViewComponent
 * @extends BaseComponent
 * @implements OnInit
 */
@Component({
  selector: 'app-survey-view',
  templateUrl: './survey-view.component.html',
  styleUrls: ['./survey-view.component.scss']
})
export class SurveyViewComponent extends BaseComponent implements OnInit, OnDestroy {

  /**
   * Опрос
   */
  survey: ISurvey;

  fakeTest: string[];

  sending: boolean;

  SurveyQuestionType = SurveyQuestionType;
  SurveyQuestionOptionType = SurveyQuestionOptionType;

  testResultCount = 0;

  private requiredQuestions: ISurveyQuestion[];

  private timeInterval: any;

  constructor(
    protected surveysService: SurveysService,
    protected breadcrumbsService: BreadcrumbsService,
    public helper: HelperService,
    private alertsService: AlertsService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute) {
    super(helper);
  }

  showContinueConfirmation(savedSurvey: ISurvey) {
    this.dialog.open(ModalConfirmationComponent, {
      data: {
        text: 'Вы хотите продолжить заполнение?',
        okText: 'Да',
        onOk: () => {
          this.survey = savedSurvey;
          this.operateContinueSurvey();
          this.cdr.detectChanges();
        },
        onCancel: () => {
          this.clearStorage();
          this.operateContinueSurvey();
          this.cdr.detectChanges();
        }
      }
    });
  }

  ngOnInit() {
    this.route.params
      .pipe(takeUntil(this.destroyed$), switchMap((params: Params) => {
        this.survey = null;
        const surveyId = +params['surveyId'];
        return this.surveysService.getById(surveyId);
      }))
      .subscribe(survey => {
        this.survey = survey;

        if (this.survey) {
          if (!this.survey.published) {
            this.router.navigate(['edit'], { relativeTo: this.route });
          } else {
            const surveysRoute = `/group/${this.survey.ownerId}/surveys`;
            this.breadcrumbsService.breadcrumbs.next([
              { title: 'Опросы', routerUrl: surveysRoute },
              { title: (this.survey.type === SurveyType.test ? 'Тест' : 'Опрос'), routerUrl: surveysRoute + '/' + this.survey.id }
            ]);

            if (this.survey.complete) {
              this.clearStorage();
              this.operateContinueSurvey();
            } else {
              const prevData = this.getStorage();

              if (prevData) {
                this.showContinueConfirmation(prevData);
              } else {
                this.operateContinueSurvey();
              }
            }
          }
        } else {
          // TODO:404
        }

        const that = this;

        this.timeInterval = setInterval(() => {
          that.updateStorage();
        }, 10000);

        this.error = '';
        this.loaded = true;
      }, error => {
        this.error = error;
        this.loaded = true;
      });
  }

  private operateContinueSurvey() {
    this.requiredQuestions = this.survey.questions.filter(s => s.required);
    this.survey.questions.forEach(question => {
      if (question.questions) {
        const requiredQuestions = question.questions.filter(s => s.required);
        requiredQuestions.forEach(requiredQuestion => {
          this.requiredQuestions.push(requiredQuestion);
        });
      }
    });
    
    this.operateCompletedTestResult();
  }

  private operateCompletedTestResult() {
    // для завершённого теста вычисляем процент завершения
    if (this.isTestCompleted()) {
      let questionsCount = 0;
      let correctQuestionsCount = 0;

      this.survey.questions.forEach(question => {
        if (!question.isGroup && question.type === SurveyQuestionType.single
          || question.type === SurveyQuestionType.multiple) {
          questionsCount++;

          if (question.options && question.type === SurveyQuestionType.multiple) {
            if (question.options.filter(s => s.isCorrect).every(s => s.isAnswered)
              && question.options.filter(s => !s.isCorrect).every(s => !s.isAnswered)) {
              correctQuestionsCount++;
            }
          } else if (question.options && question.options.find(s => s.isCorrect && s.isAnswered)) {
            correctQuestionsCount++;
          }
        }

        if (question.questions) {
          question.questions.forEach(childQuestion => {
            if (question.isGroup && childQuestion.type === SurveyQuestionType.single
              || childQuestion.type === SurveyQuestionType.multiple) {
              questionsCount++;

              if (childQuestion.options && childQuestion.type === SurveyQuestionType.multiple) {
                if (childQuestion.options.filter(s => s.isCorrect).every(s => s.isAnswered)
                  && question.options.filter(s => !s.isCorrect).every(s => !s.isAnswered)) {
                  correctQuestionsCount++;
                }
              } else if (childQuestion.options && childQuestion.options.find(s => s.isCorrect && s.isAnswered)) {
                correctQuestionsCount++;
              }
            }
          });
        }
      });

      this.testResultCount = Math.round(correctQuestionsCount / questionsCount * 100);
    }
  }

  /**
   * Отправка формы
   */
  submit() {
    if (!this.sending) {
      this.sending = true;

      const questions = this.flattenSurveyQuestions(this.survey.questions);

      const takeSurvey: ITakeSurvey = {
        id: this.survey.id,
        questions: this.operateTakeSurvey(questions)
      };

      this.surveysService.take(takeSurvey)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(res => {
          this.clearStorage();
          this.alertsService.riseSuccess(`Опрос "${this.survey.title}" был успешно пройден`);
          if (this.survey.type === SurveyType.test) {
            const currentUrl = this.router.url;
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate([currentUrl]);
            });
          } else {
            this.survey.complete = true;
            this.sending = false;

            if (res && res.fakeTest) {
              this.fakeTest = res.fakeTest;

              this.fakeTest[2] = this.fakeTest[2].replace('.png', '_2x.png');
            }
          }
        }, error => {
          this.alertsService.riseError(`Произошла ошибка при прохождении опроса '${this.survey.title}'`);
          this.sending = false;
        });
    }
  }

  flattenSurveyQuestions(questions: ISurveyQuestion[]): ISurveyQuestion[] {
    const result: ISurveyQuestion[] = [];
    questions.forEach(question => {
      if (question.questions) {
        question.questions.forEach(childQuestion => {
          result.push(childQuestion);
        });
      }
      if (!question.isGroup) {
        result.push(question);
      }
    });
    return result;
  }

  operateTakeSurvey(questions: ISurveyQuestion[]): ITakeSurveyQuestion[] {
    return questions.filter(s => !s.isGroup).map(item => {

      const question: ITakeSurveyQuestion = {
        id: item.id,
        options: null
      };

      switch (item.type) {
        case SurveyQuestionType.single:
        case SurveyQuestionType.multiple:
        case SurveyQuestionType.range:
          question.options = item.options.filter(s => s.selected).map(optionItem => {
            const value = optionItem.value != null && optionItem.value !== undefined ? optionItem.value : null;
            if (optionItem.type === SurveyQuestionOptionType.other && !value) {
              this.alertsService.riseError(`Ошибка прохождения опроса, поле 'Другое' не содержит ответа`);
              return;
            }
            return {
              id: optionItem.id,
              value: value
            };
          });
          break;
        case SurveyQuestionType.text:
          const selected = item.options[0];
          if (selected) {
            question.options = [{ id: selected.id, value: selected.value ? selected.value : null }];
          }
          break;
      }

      return question;
    });
  }

  checkboxSelected(option: ISurveyQuestionOption) {
    option.selected = !option.selected;
    if (!option.selected && option.type === SurveyQuestionOptionType.other) {
      option.value = null;
    }
  }

  radioSelected(question: ISurveyQuestion, option: ISurveyQuestionOption) {
    question.options.filter(o => o.id !== option.id).forEach(s => {
      s.selected = false;
      if (s.type === SurveyQuestionOptionType.other) {
        s.value = null;
      }
    });
    option.selected = true;
  }

  checkboxOtherFocused(option: ISurveyQuestionOption) {
    option.selected = true;
  }

  radioOtherFocused(option: ISurveyQuestionOption) {
    option.selected = true;
  }

  getQuestionRange(meta: ISurveyQuestionRangeOptionValue) {
    if (meta) {
      if (meta.max > meta.min) {
        const items = [];
        for (let i = meta.min; i <= meta.max; i++) {
          items.push(i);
        }
        return items;
      } else {
        return [];
      }
    }
  }

  questionRangeRadioSelected(question: ISurveyQuestion, option: any) {
    question.options.forEach(s => {
      s.value = option;
      s.selected = true;
    });
  }

  isValid() {
    const surveyCheck = this.survey
      && !this.survey.complete
      && this.survey.questions;

    if (!surveyCheck) {
      return false;
    }

    if (this.requiredQuestions && this.requiredQuestions.length) {
      let requiredCheck = true;
      this.requiredQuestions.forEach(question => {
        // обязательный вопрос должен быть выбран
        // или заполнен (если подразумевает текстовый ответ)
        if (question.type !== SurveyQuestionType.text && !question.options.find(s => s.selected)
          || question.type === SurveyQuestionType.text && !question.options.find(s => !!s.value)) {
          requiredCheck = false;
        }
      });
      return requiredCheck;
    }
    return true;
  }

  onDeleted(id: number) {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  /**
   * Разрешено пройти опрос?
   */
  allowTakeSurvey(): boolean {
    return this.survey && !this.survey.complete && this.isSurveyActive();
  }

  /**
   * Опрос ещё не завершён?
   */
  isSurveyActive() {
    return this.survey && new Date(this.survey.dateEnd) > new Date();
  }

  showSurveyCompleted() {
    return this.isSurveyActive() && this.isSurveyCompleted() && this.isNotTest() && !this.fakeTest;
  }

  showFakeSurveyCompleted() {
    return this.isSurveyActive() && this.isSurveyCompleted() && this.isNotTest() && this.fakeTest;
  }

  isSurveyCompleted() {
    return this.survey && this.survey.complete;
  }

  isNotTest() {
    return this.survey && this.survey.type !== SurveyType.test;
  }

  isTestCompleted() {
    return this.survey && this.survey.complete && this.survey.type === SurveyType.test;
  }

  private updateStorage() {
    localStorage.setItem('survey' + this.survey.id, JSON.stringify(this.survey));
  }

  private clearStorage() {
    localStorage.removeItem('survey' + this.survey.id);
  }

  private getStorage() {
    let result: ISurvey = null;
    try {
      result = JSON.parse(localStorage.getItem('survey' + this.survey.id));
    } catch (e) {
      console.error(e);
    }
    return result;
  }

  // @HostListener('window:beforeunload', ['$event'])
  // beforeunloadHandler(event) {
  //   return false;
  // }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
    }
  }
}
