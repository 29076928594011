import { Component, OnInit, OnDestroy } from '@angular/core';
import { User } from '@app/profile/model/user.model';
import { FilesService } from '@app/files/services/files.service';
import { UsersService } from '@app/profile/services/users.service';
import { Router } from '@angular/router';
import { FilesListItemType } from '@app/files/model/files-list-item.model';
import { FilesWidgetComponent } from '@app/files/components/files-widget/files-widget.component';
import { takeUntil, take } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { FilesDialogService } from '@app/files/services/files-dialog.service';

/**
 * Виджет Файлов пользователя
 *
 * @export
 * @class UserFilesWidgetComponent
 * @extends {FilesWidgetComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
    selector: 'app-user-files-widget',
    templateUrl: 'files-widget.component.html'
})

export class UserFilesWidgetComponent extends FilesWidgetComponent implements OnInit, OnDestroy {
    protected user: User;
    private userId: number;

    showWidget = true;

    constructor(
        private usersService: UsersService,
        public filesService: FilesService,
        private router: Router,
        protected filesDialog: FilesDialogService,
        public helper: HelperService
    ) { super(filesService, filesDialog, helper); }

    ngOnInit() {
        this.usersService.user.pipe(takeUntil(this.destroyed$)).subscribe(user => {
            this.user = user;
            this.getUserFiles();
        });
    }

    navigate(force: boolean = false) {
        if (force) {
            if (this.fragment) {
                this.fragment += `&d=${new Date().getTime()}`;
            }
            this.router.navigate([this.link], { fragment: `d=${new Date().getTime()}` });
        } else {
            this.router.navigate([this.link], { fragment: this.fragment });
        }
    }

    getItems(force: boolean) {
        if (!this.loaded && this.user) {
            // with current user
            this.usersService.currentUser.subscribe(currentUser => {
                // get web url
                // const webUrl = this.webUrl.getValue();
                this.loaded = false;
                this.loading = true;

                if (this.user.id === currentUser.id) {
                    this.fragment = 'recent';
                }

                const ownerId = this.user.id;

                if (ownerId && (this.user.id !== ownerId || force)) {
                    this.items = [];
                    this.showAddButton = this.user.id === currentUser.id;

                    if (this.showAddButton) {
                        this.filesService.getRecentListData(ownerId, 0, 4, true).pipe(take(1)).subscribe(res => {
                            if (res) {
                                this.items = res.items;
                            }
                            this.loaded = true;
                            this.loading = false;
                        }, error => {
                            this.items = [];
                            this.error = error;
                            this.loaded = true;
                            this.loading = false;
                        });
                    } else {
                        this.filesService.getSharedWithPersonV2(ownerId, 0, 30, true)
                            .pipe(take(1)).subscribe(filesList => {
                                this.items = [];
                                if (filesList) {
                                    if (filesList.items && filesList.items.length) {
                                        filesList.items.forEach(rowItem => {
                                            if (this.items.length < 4 && rowItem.type === FilesListItemType.File) {
                                                this.items.push(rowItem);
                                            }
                                        });
                                    }
                                }

                                this.loaded = true;
                                this.loading = false;
                            }, error => {
                                this.error = error;
                                this.loaded = true;
                                this.loading = false;
                            });
                    }
                } else {
                    this.loaded = true;
                }
            });
        }
    }

    onFilesAdded(folder: string) {
        this.usersService.currentUser.subscribe(currentUser => {
            this.router.navigate([`profile/${currentUser.id}/files`], { fragment: `folder=${folder}&d=${new Date().getMilliseconds()}` });
        });
    }

    getUserFiles(): void {
        this.loaded = false;
        if (this.user) {
            // нужно для того, чтобы правильно и вовремя отображался прелоадер
            if (this.userId !== this.user.id) {
                this.loaded = false;
            }
            this.userId = this.user.id;
            this.link = `/profile/${this.userId}/files`;
            this.getItems(true);
        }
    }
}
