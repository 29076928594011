import { Directive, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective {
  @Output() public clickOutside = new EventEmitter();

  constructor(private _elementRef: ElementRef) { }

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!this._elementRef.nativeElement.classList.contains('_active')
      && !this._elementRef.nativeElement.classList.contains('__active')
      && !this._elementRef.nativeElement.classList.contains('_visible')) {
      return;
    }

    if (!targetElement) {
      return;
    }
    // console.log('clicked'); //todo: check everywhere
    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.clickOutside.emit(event);
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      if (this.clickOutside) {
        this.clickOutside.emit(event);
      }
    }
  }
}
