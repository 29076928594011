import { Component, OnInit } from '@angular/core';
import { VacancyNewComponent } from '../vacancy-new/vacancy-new.component';
import { VacancyService } from '@app/vacancy/services/vacancy.service';
import { AlertsService } from '@app/shared/services/alerts.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { MatDialog } from '@angular/material/dialog';
import { HelperService } from '@app/core/services/helper.service';
import { Subject } from 'rxjs';
import { takeUntil, switchMap, take } from 'rxjs/operators';
import { VacancyStatus, IUpdateVacancy, IVacancy } from '@app/vacancy/model/vacancy';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import { UsersService } from '@app/profile/services/users.service';

/**
 * Страница редактирования вакансии
 *
 * @export
 * @class VacancyEditComponent
 * @extends {VacancyNewComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-vacancy-edit',
  templateUrl: './vacancy-edit.component.html',
  styleUrls: ['./vacancy-edit.component.scss']
})
export class VacancyEditComponent extends VacancyNewComponent implements OnInit {

  /**
   * Вакансия
   *
   * @type {IUpdateVacancy}
   * @memberof VacancyEditComponent
   */
  vacancy: IUpdateVacancy = {
    id: null,
    title: '',
    description: '',
    location: '',
    ownerId: null,
    categoryId: null,
    status: null,
    docs: []
  };

  /**
   * Оригинальная вакансия, не изменяется при редактировании формы
   *
   * @type {IVacancy}
   * @memberof VacancyEditComponent
   */
  originVacancy: IVacancy;

  /**
   * Категория, не изменяется при редактировании формы
   *
   * @type {IVacancy}
   * @memberof VacancyEditComponent
   */
  originCategory: IVacancy;

  constructor(
    public vacancyService: VacancyService,
    public usersService: UsersService,
    protected alertsService: AlertsService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected breadcrumbsService: BreadcrumbsService,
    protected dialog: MatDialog,
    public helper: HelperService
  ) {
    super(usersService, vacancyService, alertsService, route, router, breadcrumbsService, dialog, helper);
  }

  ngOnInit() {

    // получаем категории вакансий
    this.vacancyService.getVacancyCategories(0, 20)
      .pipe(take(1), takeUntil(this.destroyed$))
      .subscribe(res => {
        this.categories = res ? res : [];
      });

    // отслеживаем изменения маршрута для получения элемента вакансии
    this.route.params
      .pipe(takeUntil(this.destroyed$), switchMap((params: Params) => {
        this.vacancy = {
          id: null,
          title: '',
          description: '',
          location: '',
          ownerId: null,
          categoryId: null,
          status: null,
          docs: []
        };
        const vacancyId = +params['vacancyId'];
        return this.vacancyService.getById(vacancyId);
      }))
      .subscribe(vacancy => {

        if (!vacancy) {
          this.router.navigate(['/404'], { skipLocationChange: true });
        }

        this.originVacancy = vacancy;

        this.vacancy = {
          id: vacancy.id,
          title: vacancy.title,
          location: vacancy.location,
          description: vacancy.description,
          categoryId: vacancy.category ? vacancy.category.id : null,
          ownerId: vacancy.ownerId,
          status: vacancy.status,
          docs: vacancy.docs ? vacancy.docs : []
        };

        // обновляем хлебные крошки
        const vacancyRoute = `/group/${this.vacancy.ownerId}/vacancy`;

        this.breadcrumbsService.breadcrumbs.next([
          { title: 'Вакансии', routerUrl: vacancyRoute },
          { title: 'Вакансия', routerUrl: vacancyRoute + `/${this.vacancy.id}` }
        ]);

        // отображаем аттачи
        if (this.vacancy.docs) {
          this.attachments = this.vacancy.docs.map(s => {
            return { attachment: s, owner: null };
          });
        }

        this.category = vacancy.category;

        this.error = '';
        this.loaded = true;
      }, error => {
        this.error = error;
        this.loaded = true;
      });
  }

  /**
   * Отмена редактирования, уходим с формы
   *
   * @memberof VacancyEditComponent
   */
  cancel() {
    if (this.isChanged()) {
      this.dialog.open(ModalConfirmationComponent, {
        data: {
          text: `Вы действительно хотите уйти с формы?`,
          onOk: () => { this.router.navigate([`../..`], { relativeTo: this.route }); }
        }
      });
    } else {
      this.router.navigate([`../..`], { relativeTo: this.route });
    }
  }

  protected send() {
    this.vacancy.categoryId = this.category ? this.category.id : null;
    this.sending = true;

    this.checkAttachments();

    this.vacancyService.update(this.vacancy.id, this.vacancy).pipe(take(1)).subscribe(res => {
      if (res) {
        this.sending = false;
        if (this.vacancy.status === VacancyStatus.published) {
          this.alertsService.riseSuccess(`Вакансия '${this.vacancy.title}' была успешно создана`);
          this.router.navigate([`..`], { relativeTo: this.route });
        } else {
          this.alertsService.riseSuccess(`Вакансия '${this.vacancy.title}' была успешно сохранена как черновик`);
        }
      } else {
        this.alertsService.riseError(`Произошла ошибка при создании вакансии '${this.vacancy.title}'`);
      }
    }, error => {
      this.sending = false;
      console.log(error);
      this.alertsService.riseError(`Произошла ошибка при создании вакансии '${this.vacancy.title}'`);
    });
  }

  private isChanged() {
    return this.vacancy && this.originVacancy && this.category && this.originCategory
      && (this.vacancy.title !== this.originVacancy.title
        || this.vacancy.description !== this.originVacancy.description
        || this.category.id !== this.originCategory.id);
  }
}
