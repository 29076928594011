
import { filter, takeUntil } from 'rxjs/operators';
import { Component, AfterViewInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { UsersService } from '@app/profile/services/users.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { setTimeout } from 'core-js/library/web/timers';
import { SearchService } from '@app/search/services/search.service';
import { GroupsService } from '@app/groups/services/groups.service';
import { BaseResizableComponent } from '@app/core/components/base-resizable.component';
import { HelperService } from '@app/core/services/helper.service';

/**
 * Компонент строки поиска
 *
 * @export
 * @class SearchBoxComponent
 * @extends {BaseResizableComponent}
 * @implements {AfterViewInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html'
})
export class SearchBoxComponent extends BaseResizableComponent implements AfterViewInit, OnDestroy {
  query = '';
  searchTab: string;
  isActive: boolean;
  isOverlayActive: boolean;
  showGoBack: boolean;

  selectorVisible: boolean;
  selectorActive: boolean;
  selector: string;

  @ViewChild('clearButton') private clearButton: ElementRef;
  @ViewChild('searchText', { static: true }) private searchText: ElementRef;

  constructor(
    private searchService: SearchService,
    private groupsService: GroupsService,
    private usersService: UsersService,
    private router: Router,
    private route: ActivatedRoute,
    public helper: HelperService
  ) { super(helper); }

  ngAfterViewInit(): void {
    // check search tapped
    this.router.events.pipe(filter(e => e instanceof NavigationEnd))
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res: any) => {
        this.isActive = this.showGoBack = res.url.indexOf('/search') !== -1;
        this.searchService.searchOpened.next(this.isActive);

        const url = this.router.url;
        if (url.match(/group\/\d+/gi)) {
          this.selector = 'group';
          this.selectorVisible = true;
        } else {
          this.selector = 'all';
          this.selectorVisible = false;
        }
      });

    this.route.fragment
      .pipe(takeUntil(this.destroyed$))
      .subscribe(val => {
        // get hash
        if (val != null) {
          const keys = val.split('&');
          const hash = {};
          keys.forEach(key => {
            // tslint:disable-next-line:no-shadowed-variable
            const val = key.split('=');
            hash[val[0]] = val[1];
            if (val[0] === 'k') {
              this.query = decodeURIComponent(val[1]);
            }
            if (val[0] === 'colleagues' || val[0] === 'files' || val[0] === 'groups') {
              this.searchTab = val[0];
            }
          });
          if (this.query) {
            this.isActive = true;
          }
        } else {
          this.searchTab = this.query = '';
        }
      });
  }

  /**
   * Событие при вводе любого символа в блок поиска
   *
   * @param {any} e
   * @returns
   * @memberof SearchBoxComponent
   */
  onSearchKey(e) {

    // ESC
    if (e.keyCode === 27) {
      this.query = '';
      this.loaded = false;
      return false;
    }

    // ENTER
    if (e.keyCode === 13) {
      this.search(e);
    }
  }

  private search(e) {
    if (!this.query) {
      this.isOverlayActive = true;
      return false;
    } else {
      this.isOverlayActive = false;
      this.usersService.currentUser.subscribe(currentUser => {
        let fragment = '';
        const url = this.router.url;
        let navigateUrl = '';

        // tslint:disable-next-line:triple-equals
        if (this.selector == 'group') {
          const group = this.groupsService.currentGroup.getValue();
          navigateUrl = `group/${group.id}/search`;

          if (url) {
            if (url.match(/group\/\d+/gi)) {

              if (url.match(/group\/\d+\/files/gi)
                || url.match(/group\/\d+\/search#files/gi)) {
                fragment = 'files';
              } else if (url.match(/group\/\d+\/users/gi)
                || url.match(/group\/\d+\/search#users/gi)) {
                fragment = 'users';
              } else if (url.match(/group\/\d+\/gallery/gi)
                || url.match(/group\/\d+\/search#gallery/gi)) {
                fragment = 'gallery';
              } else if (url.match(/group\/\d+\/pages/gi)
                || url.match(/group\/\d+\/search#pages/gi)) {
                fragment = 'pages';
              } else {
                fragment = 'feed';
              }
            }
          }
        } else {
          // default navigate url to user search
          navigateUrl = `/profile/${currentUser.id}/search`;

          if (url) {
            if (url.match(/profile\/\d+\/files/gi)) {
              fragment = 'files';
            } else if (url.match(/profile\/\d+\/groups/gi)) {
              fragment = 'groups';
            }
          }
        }

        // append search key
        fragment += `&k=${this.query}`;

        if (this.searchText) {
          this.searchText.nativeElement.blur();
          this.isOverlayActive = false;
        }

        // navigate to
        this.router.navigate([navigateUrl], { fragment: fragment });
      });
      if (e) {
        e.preventDefault();
      }
      return false;
    }
  }

  /**
   * Событие при потере фокуса блока поиска
   *
   * @param {any} e событие
   * @memberof SearchBoxComponent
   */
  onBlur(e) {
    if (this.clearButton && e.relatedTarget !== this.clearButton.nativeElement) {
      this.isOverlayActive = false;
      this.checkOverlay();
    }
  }

  /**
   * Событие при фокусе блока поиска
   *
   * @param {any} e событие
   * @memberof SearchBoxComponent
   */
  onFocus(e) {
    this.isOverlayActive = true;
  }

  /**
   * Переход "Назад" в поиске
   *
   * @param {any} e событие
   * @memberof SearchBoxComponent
   */
  goBack(e) {
    this.isActive = false;
    this.searchService.searchOpened.next(this.isActive);
  }

  /**
   * Отображать оверлей?
   *
   * @returns
   * @memberof SearchBoxComponent
   */
  showOverlay() {
    return this.isOverlayActive && this.isActive;
  }

  /**
   * Действие при клике на оверлей. Скрыть оверлей.
   *
   * @param {*} e
   * @memberof SearchBoxComponent
   */
  overlayClick(e) {
    e.preventDefault();
    this.isOverlayActive = false;
    this.checkOverlay();
  }

  toggle(e) {
    this.isActive = !this.isActive;

    this.searchService.searchOpened.next(this.isActive);

    if (this.searchText && this.isActive) {
      const that = this;
      setTimeout(() => {
        that.searchText.nativeElement.focus();
      }, 50);
    }
  }

  /**
   * Очистить текст запроса
   *
   * @param {*} e
   * @memberof SearchBoxComponent
   */
  clear(e) {
    e.preventDefault();
    this.query = '';
    this.searchText.nativeElement.focus();
  }

  /**
   * Выбрать скоуп поиска
   *
   * @param {string} val
   * @memberof SearchBoxComponent
   */
  selectChoice(val: string) {
    this.selector = val;
    this.search(null);
  }

  /**
   * Отображать кнопку очистки текста запроса
   *
   * @returns
   * @memberof SearchBoxComponent
   */
  showClearButton() {
    return this.query && (this.isDesktop() || !this.isDesktop() && this.isActive);
  }

  /**
   * Отображать всплывашку выбора поиска по группе или по сайту
   *
   * @param {*} e
   * @memberof SearchBoxComponent
   */
  showSelector(e) {
    this.selectorActive = !this.selectorActive;
    e.preventDefault();
    e.stopPropagation();
  }

  private checkOverlay() {
    if (!this.showGoBack && !this.query) {
      this.searchService.searchOpened.next(false);
      this.isActive = false;
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.isOverlayActive = false;
  }

  /**
   * Отписываемся от всех подписок
   */
  ngOnDestroy(): void {
    this.searchService.searchOpened.next(false);
    this.selectorVisible = false;
    super.ngOnDestroy();
  }
}
