import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { UsersService } from '@app/profile/services/users.service';
import { User } from '@app/profile/model/user.model';
import { HelperService } from '@app/core/services/helper.service';
import { Helper } from '@app/core/helpers/helper';
import { FilesService } from '@app/files/services/files.service';

interface IMenuItem {
  title: string;
  url: string;
  fragment?: string;
  icon?: string;
}

@Component({
  selector: 'app-left-menu-items',
  templateUrl: './left-menu-items.component.html',
  styleUrls: ['./left-menu-items.component.scss']
})
export class LeftMenuItemsComponent extends BaseComponent implements OnInit {

  @Input() cssClass: string;
  @Output() navigated: EventEmitter<void> = new EventEmitter();
  currentUser: User;

  links: IMenuItem[] = [];

  constructor(
    private usersService: UsersService,
    private filesService: FilesService,
    public helper: HelperService) {
    super(helper);
  }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;
      if (this.currentUser) {
        this.addLinks('Коллеги', 'colleagues', `/profile/${currentUser.id}/colleagues`);
        this.addLinks('Группы', 'groups', `/profile/${currentUser.id}/groups`);
        this.filesService.showUserFiles$.subscribe(res => {
          if (res) {
            this.links.splice(2, 0, {
              title: 'Файлы',
              icon: '/assets/images/icons/menu/main/files.svg',
              url: `/profile/${currentUser.id}/files`
            });
          }
        });
        this.addLinks('Галерея', 'gallery', `/profile/${currentUser.id}/gallery`);
        this.addLinks('Опросы', 'polls', `/profile/${currentUser.id}/surveys`);
        this.addLinks('Вакансии', 'vacancies', `/profile/${currentUser.id}/vacancy`);
        this.addLinks('Календарь', 'calendar', `/calendar`, 'monthView');
      }
    });
  }

  private addLinks(title: string, icon: string, url: string, fragment: string = null): void {
    if (!this.links.find(s => s.title === title)) {
      this.links.push({
        title: title,
        icon: `/assets/images/icons/menu/main/${icon}.svg`,
        url: url,
        fragment: fragment ? fragment : null
      });
    }
  }

  navigate() {
    this.navigated.emit();
    Helper.addOrRemoveClassToBody(false);
  }
}
