<div class="post-profile dropdown _full-width" *ngIf="postProfiles?.length">
  <div *ngIf="selectedProfile" (click)="chooseProfile()">
    <ng-container *ngTemplateOutlet="profileItemView; context: {$implicit: selectedProfile}"></ng-container>
  </div>
  <div class="post-profile__overlay" *ngIf="show" (click)="show=false"></div>
  <div class="post-profile__wrapper" [ngClass]="{'_active': show}" appInfiniteScroll scrollPercent="70"
    immediateCallback="true" (scrollCallback)="scrollCallback()">
    <!-- appClickOutside (clickOutside)="clickOutside()" -->
    <div *ngFor="let p of postProfiles; trackBy:trackBy" (click)="onProfileSelected(p)">
      <ng-container *ngTemplateOutlet="profileItemView; context: {$implicit: p}"></ng-container>
    </div>
  </div>
</div>

<ng-template #profileItemView let-p>
  <div class="post-profile__item" *ngIf="p.profile && p.profileType==profileType.User">
    <app-user-avatar [user]="p.profile" [linkClass]="'avatar _30'" [imageClass]="'avatar__image'" [asSpan]="true"></app-user-avatar>
    <span class="post-profile__body"> В моей ленте</span>
  </div>
  <div class="post-profile__item" *ngIf="p.profile && p.profileType==profileType.Group">
    <app-user-avatar [avatarImage]="groupsService.getGroupSmallPicture(p.profile)" [linkClass]="'avatar _30'"
      [imageClass]="'avatar__image'" [name]="p.profile.title" [asSpan]="true"></app-user-avatar>
    <div class="post-profile__body">
      <span class="post-profile__text">{{p.profile.title}}</span>
      <span class="post-profile__subtext">{{groupsService.getGroupTypeNameByEnum(p.profile.type)}} группа</span>
    </div>
  </div>
  <div class="post-profile__item" *ngIf="!p.profile">Ошибка отображения объекта</div>
</ng-template>
