import {
  Component,
  OnInit,
  OnChanges,
  Input,
  ViewChild,
  forwardRef,
  SimpleChanges,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import {User} from '@app/profile/model/user.model';
import {UsersService} from '@app/profile/services/users.service';
import {GroupInfo, IUserGroupCounters} from '@app/groups/model/group-info.model';
import {FeedService} from '@app/feed/services/feed.service';
import {ElementType} from '@app/core/model/element-type.enum';
import {FeedEventViewModel} from '@app/feed/model/feed-event-view-model.model';
import {FroalaEditorComponent} from '@app/shared/components/froala-editor/froala-editor.component';
import {AttachmentsListComponent} from '@app/attachments/file-attachments/components/attachments-list/attachments-list.component';
// tslint:disable-next-line:max-line-length
import {MediaAttachmentsListComponent} from '@app/attachments/media-attachments/components/media-attachments-list/media-attachments-list.component';
import {AttachmentViewModel} from '@app/attachments/file-attachments/model/attachment-view-model.model';
import {MediaAttachmentViewModel} from '@app/attachments/media-attachments/model/media-attachment-view-model';
import {take} from 'rxjs/operators';
import {INewFeedEventBody} from '@app/feed/model/feed-event.model';
import {GroupsService} from '@app/groups/services/groups.service';
import {IMediaFile} from '@app/gallery/model/media-file';
import {ProfileType} from '@app/core/model/profile-type.enum';
import {GroupType} from '@app/groups/model/group-type.model';
import {IIntryProfile} from '@app/shared/model/intry-profile';
import {AlertsService} from '@app/shared/services/alerts.service';
import {PostProfileSelectorType} from '@app/feed/model/post-profile-selector-type';

import * as moment from 'moment';
import {BaseComponent} from '@app/core/components/base.component';
import {HelperService} from '@app/core/services/helper.service';
import {IDatafileModel} from '@app/files/model/data-file-model';
import {ITag, TagType} from '@app/shared/model/tag';
import {AddTagsComponent, AddTagsComponentMode} from '@app/shared/components/add-tags/add-tags.component';

export enum PostMode {
  text = 1,
  news = 2,
}

/**
 * Компонент создания нового поста
 *
 * @export
 * @class PostNewComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-post-new',
  templateUrl: './post-new.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PostNewComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() user: User;
  @Input() group: GroupInfo;
  @Input() placeholder: string;
  @Input() profileType: ProfileType;
  @Input() selectorType: PostProfileSelectorType;

  @ViewChild(forwardRef(() => AttachmentsListComponent))
  private attachmentsList: AttachmentsListComponent;

  @ViewChild(forwardRef(() => MediaAttachmentsListComponent))
  protected mediaAttachmentsList: MediaAttachmentsListComponent;

  @ViewChild(FroalaEditorComponent, {static: true}) private editor: FroalaEditorComponent;

  @ViewChild(forwardRef(() => AddTagsComponent))
  private tagsList: AddTagsComponent;

  @Output() onAdd: EventEmitter<FeedEventViewModel> = new EventEmitter<FeedEventViewModel>();

  currentUser: User;
  attachments: AttachmentViewModel[] = [];
  mediaAttachments: MediaAttachmentViewModel[] = [];
  fullView: boolean;
  text: string;
  title: string = null;
  announce: string = null;
  posting = false;
  uploadFiles = false;

  ownerType: number;
  ownerId: number;

  targetProfile: IIntryProfile;
  profilesType = ProfileType;

  mentions: User[] = [];
  disableComment: boolean;
  disableLike: boolean;
  pin: boolean;
  pinDateEnd: Date;
  isNewEmployee: boolean;
  showOnMainPage = false;

  userGroupCounters: IUserGroupCounters;
  postMode: PostMode = PostMode.text;
  postModes = PostMode;

  TagType = TagType;
  tags: ITag[] = [];

  /**
   * Отображать блок выбора профиля для публикации
   */
  showScope: boolean;
  allowNews: boolean;

  isDraft = false;
  repostId = null;

  maxDate = new Date();
  publishDate: moment.Moment;

  PostProfileSelectorType = PostProfileSelectorType;
  AddTagsComponentMode = AddTagsComponentMode;

  elementType = ElementType.Event;

  constructor(
    protected usersService: UsersService,
    protected groupsService: GroupsService,
    protected feedService: FeedService,
    protected alertsService: AlertsService,
    protected cdr: ChangeDetectorRef,
    public helper: HelperService,
  ) {
    super(helper);
  }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;
      this.cdr.detectChanges();
    });
    this.publishDate = moment(new Date());
  }

  focus() {
    this.editor.focus();
    this.cdr.detectChanges();
  }

  /**
   * Раскрыть полную форму редактора
   *
   * @memberof PostNewComponent
   */
  expand() {
    switch (this.profileType) {
      case ProfileType.User:
        this.allowNews = false;
        if (this.userGroupCounters) {
          this.checkPostMode();
        } else {
          this.groupsService
            .getUserGroupCounters(this.currentUser.id)
            .pipe(take(1))
            .subscribe(res => {
              this.userGroupCounters = res;
              this.checkPostMode();
            });
        }
        break;
      case ProfileType.Group:
        if (!this.repostId) {
          if (this.group && this.group.isAdmin) {
            this.allowNews = true;
            this.showScope = false;
          }
        } else {
          this.allowNews = false;
        }
        this.fullView = true;
        // this.placeholder = this.placeholderText();
        break;
    }

    if (this.repostId) {
      this.showScope = true;
    }

    this.cdr.detectChanges();
  }

  /**
   * Плейсхолдер для текста поста.
   * Отличается при открытии и переходе в расширенный режим.
   *
   * @returns
   * @memberof PostNewComponent
   */
  placeholderText() {
    if (this.fullView) {
      if (this.postMode === PostMode.news) {
        return 'Текст новости*';
      } else {
        if (this.repostId) {
          return 'Ваш комментарий к публикации';
        } else {
          return this.group
            ? 'Что нового в группе?'
            : this.selectorType === PostProfileSelectorType.onlyBusiness
            ? 'Добавить публикацию'
            : 'Что у вас нового?';
        }
      }
    } else {
      return this.placeholder ? this.placeholder : '';
    }
  }

  pinText() {
    if (
      this.targetProfile &&
      this.targetProfile.profile &&
      (<GroupInfo>this.targetProfile.profile).type === GroupType.Business
    ) {
      return 'Закрепить в ленте группы и на главной';
    } else {
      return 'Закрепить в ленте группы';
    }
  }

  /**
   * Проверить режим создания нового поста.
   * 1. Новости могут создавать только пользователи, которые являются админами в каких либо группах.
   * 2. Блок выбора профиля (свой или какую-либо группу), в который добавить пост отображается только если
   * пользователь админ или участник какой-либо группы.
   *
   * @private
   * @returns
   * @memberof PostNewComponent
   */
  private checkPostMode() {
    if (this.userGroupCounters) {
      if (this.userGroupCounters.asAdministrator) {
        this.showScope = this.user.id === this.currentUser.id || this.repostId;
        this.allowNews = this.profileType === ProfileType.Group && !this.repostId;
      }
      if (this.userGroupCounters.asMember) {
        this.showScope = this.user.id === this.currentUser.id || this.repostId;
      }
      this.fullView = true;
    }
    // this.placeholder = this.placeholderText();
    return;
  }

  /**
   * Изменить режим создания поста
   *
   * @param {PostMode} mode новый режим
   * @memberof PostNewComponent
   */
  changePostMode(mode: PostMode): void {
    this.postMode = mode;
    this.checkPostMode();
    this.placeholder = this.placeholderText();
    this.cdr.detectChanges();
  }

  collapse() {
    if (!this.text && this.attachments.length === 0) {
      this.fullView = false;
    }
    // this.placeholder = this.placeholderText();
  }

  isShowPinCheckbox(): boolean {
    return this.isTargetProfileGroup();
  }

  isShowNewEmployeeCheckbox(): boolean {
    return (
      this.postMode === PostMode.news &&
      ((this.selectorType === PostProfileSelectorType.onlyBusinessWithNewEmployee && this.isTargetProfileGroup()) ||
        (this.group && this.group.title === this.groupsService.groupOrgNews$.getValue()))
    );
  }

  isBusinessNews(): boolean {
    return (
      this.postMode === PostMode.news &&
      (this.selectorType === PostProfileSelectorType.onlyBusinessWithNewEmployee ||
        (this.group && this.group.type === GroupType.Business))
    );
  }

  private isTargetProfileGroup() {
    return (
      (this.targetProfile && this.targetProfile.profileType === this.profilesType.Group) ||
      this.ownerType === this.profilesType.Group
    );
  }

  isValid() {
    return (
      (!this.mediaAttachmentsList?.filesUploading &&
        this.postMode === PostMode.news &&
        this.text &&
        this.title &&
        this.publishDate) ||
      this.postMode === PostMode.text
    );
  }

  cancel() {
    this.clear();
  }

  saveAsDraft() {
    this.isDraft = true;
    this.submit();
  }

  submit() {
    // console.log(this);

    if (
      ((this.text || this.repostId) && !this.posting) ||
      (this.attachments && this.attachments.length) ||
      (this.attachmentsList &&
        this.attachmentsList.attachmentsToUpload &&
        this.attachmentsList.attachmentsToUpload.length) ||
      (this.mediaAttachments && this.mediaAttachments.length) ||
      (this.mediaAttachmentsList &&
        this.mediaAttachmentsList.attachmentsToUpload &&
        this.mediaAttachmentsList.attachmentsToUpload.length)
    ) {
      this.posting = true;

      if (
        this.attachmentsList &&
        this.attachmentsList.attachmentsToUpload &&
        this.attachmentsList.attachmentsToUpload.length
      ) {
        this.uploadFiles = true;
        // upload attached files at first
        this.attachmentsList.uploadAttachedFiles(ElementType.Event);
      }

      if (this.tagsList && this.tagsList.tags && this.tagsList.tags.length) {
        this.tags = this.tagsList.tags;
      }

      if (!this.uploadFiles) {
        // send to server
        this.send();
      }
    } else {
      // todo: error empty text
      console.log(`error empty text`);
    }
  }

  /**
   * Отправка данных на сервер
   *
   * @private
   * @memberof PostNewComponent
   */
  private send() {
    // attachments
    const attachmentsIds = this.attachments ? this.attachments.map(att => att.attachment.guid) : null;
    // media attachments
    const mediaAttachmentsIds = this.mediaAttachments ? this.mediaAttachments.map(att => att.mediaAttachment.id) : null;
    // mentions
    const mentionsIds = this.mentions ? this.mentions.map(item => item.id) : null;
    // tags
    const tagsIds = this.tags ? this.tags.map(item => item.id) : null;

    const body: INewFeedEventBody = {
      text: this.text,
      disableComment: this.disableComment,
      disableLike: this.disableLike,
      pinEvent: false,
      pinDateEnd: null,
      isNewEmployee: this.isNewEmployee,
      repostId: this.repostId,
    };

    if (this.postMode === PostMode.news) {
      const now = new Date();
      body.title = this.title;
      body.announce = this.announce;
      body.isNews = true;
      body.isDraft = this.isDraft;
      body.showOnMainPage = this.showOnMainPage;
      // TODO: поправить это
      if (!this.publishDate) {
        body.publishDate = now;
      } else {
        try {
          this.publishDate = this.publishDate.set('hour', now.getHours()).set('minute', now.getMinutes());
        } catch (e) {}
        body.publishDate = this.publishDate.toDate();
      }
    }

    // determine promise & send
    let profileId: number;

    if (this.targetProfile && this.targetProfile.profile) {
      // если был выбран профиль из списка
      profileId = this.targetProfile.profile.id;
      // если группа, то соъраняем выбор закрепления
      if (this.targetProfile.profileType === ProfileType.Group) {
        body.pinEvent = this.pin;
        body.pinDateEnd = this.pinDateEnd;
      }
    } else {
      switch (this.profileType) {
        case ProfileType.User:
          profileId = this.user.id;
          break;
        case ProfileType.Group:
          profileId = this.group.id;
          // если группа, то соъраняем выбор закрепления
          body.pinEvent = this.pin;
          body.pinDateEnd = this.pinDateEnd;
          break;
      }
    }

    // operate result
    this.feedService
      .createPost(body, profileId, mediaAttachmentsIds, attachmentsIds, mentionsIds, tagsIds)
      .pipe(take(1))
      .subscribe(
        res => {
          this.clear();
          if (this.onAdd) {
            this.onAdd.emit(res);
          }
          this.posting = false;
          this.alertsService.riseSuccess('Публикация создана.');
        },
        error => {
          this.posting = false;
          this.alertsService.riseError('Произошла ошибка при создании публикации. Попробуйте позже.');
        },
      );
  }

  onRemoveAttachment(attachment: AttachmentViewModel) {
    this.attachments = this.attachments.filter(
      att =>
        (attachment.attachment.guid && att.attachment.guid !== attachment.attachment.guid) ||
        (!attachment.attachment.guid && att.attachment.title !== attachment.attachment.title),
    );
  }

  onRemoveMediaAttachment(attachment: MediaAttachmentViewModel) {
    this.mediaAttachments = this.mediaAttachments.filter(
      att =>
        (attachment.mediaAttachment.guid && att.mediaAttachment.guid !== attachment.mediaAttachment.guid) ||
        (!attachment.mediaAttachment.guid && att.mediaAttachment.title !== attachment.mediaAttachment.title),
    );
  }

  onFilesUploaded(event: IDatafileModel[]) {
    // add uploaded attachments
    this.attachments = this.attachments.filter(att => att.attachment.guid);
    if (event) {
      event.forEach(file => {
        this.attachments.push({
          attachment: {
            title: file.name,
            modified: file.created,
            ownerID: file.authorId,
            url: `/api/documentFile/${file.guid}`,
            guid: file.guid,
          },
          owner: null, // file.author
        });
      });
    }

    this.uploadFiles = false;

    // send to server
    if (!this.uploadFiles) {
      this.send();
    }
  }

  onMediaFilesUploaded(event: IMediaFile[]) {
    // add uploaded attachments
    this.mediaAttachments = this.mediaAttachments.filter(att => att.mediaAttachment.id);
    if (event) {
      event.forEach(mediaFile => {
        this.mediaAttachments.push({
          mediaAttachment: {
            id: mediaFile.id,
            title: mediaFile.name,
            url: mediaFile.url,
            previewUrl: mediaFile.previewUrl,
            guid: mediaFile.guid,
            type: mediaFile.type,
          },
        });
      });
    }
    this.cdr.detectChanges();
  }

  /**
   * При выборе профиля из списка сохраняем его значение
   *
   * @param {IIntryProfile} profile
   * @memberof PostNewComponent
   */
  onProfileSelected(profile: IIntryProfile) {
    if (
      (this.targetProfile &&
        this.targetProfile.profile &&
        profile &&
        profile.profile &&
        this.targetProfile.profile.id !== profile.profile.id) ||
      !this.targetProfile
    ) {
      this.clearAttachments();
    }
    this.targetProfile = profile;
    this.profileType = this.targetProfile.profileType;

    if (this.repostId) {
      this.ownerId = profile.profile.id;
      if (this.profileType === ProfileType.User) {
        this.ownerType = 0;
      } else {
        this.ownerType = 1;
      }
    }

    // новости могут создавать только админы в группах
    this.allowNews = this.isTargetProfileGroup() && profile.profile.isAdmin && !this.repostId;

    // если создавать новости нельзя то текущий тип принудительно меняем на текстовый
    if (!this.allowNews) {
      this.changePostMode(PostMode.text);
    }
    this.cdr.detectChanges();
  }

  /**
   * Обработка изменения значений в поле Название
   *
   * @protected
   * @param {*} e
   * @memberof AlbumNewComponent
   */
  onKey(e) {
    // this.isValid = e.target.value && /\S/.test(e.target.value) ? true : false;
  }

  /**
   * Очистить блок создания поста
   *
   * @private
   * @memberof PostNewComponent
   */
  private clear(): void {
    this.text = '';
    this.title = '';
    this.announce = '';
    this.editor.clear();
    this.mentions = [];
    this.tags = [];
    this.clearAttachments();
    this.postMode = PostMode.text;
    this.fullView = false;
    this.pin = false;
    this.disableComment = false;
    this.disableLike = false;
    this.targetProfile = null;
    this.title = null;
    this.announce = null;
    this.pinDateEnd = null;
    this.placeholder = this.group ? 'Что нового в группе?' : 'Что у вас нового?';
  }

  private clearAttachments() {
    this.attachments = [];
    this.mediaAttachments = [];
    if (this.attachmentsList) {
      this.attachmentsList.attachments = [];
      this.attachmentsList.attachmentsToUpload = [];
    }
    if (this.mediaAttachmentsList) {
      this.mediaAttachmentsList.mediaAttachments = [];
      this.mediaAttachmentsList.attachmentsToUpload = [];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['user']) {
      if (this.user && !this.group) {
        this.ownerId = this.user.id;
        this.ownerType = 0;
      }
    }
    if (changes['group']) {
      if (this.group && !this.user) {
        this.ownerId = this.group.id;
        this.ownerType = 1;
      }
    }
  }
}
