<div class="cos">
  <app-group-small-header [group]="group"></app-group-small-header>

  <div class="form cos__body" *ngIf="loaded">
    <div class="form__section">
      <div class="form__line">
        <p>Выберите виджеты для отображения в группе:</p>
      </div>
      <div class="form__line">
        <label class="checkbox _one-third" *ngFor="let m of modules">
          <input type="checkbox" [(checked)]="m.checked" (change)="m.checked=!m.checked" name="search-dir"
            class="checkbox__input">
          <span class="checkbox__icon"></span>
          <span class="checkbox__text">{{m.name}}</span>
        </label>
      </div>
    </div>
    <ng-container *ngIf="group && group.type===GroupType.Business && subscribeConfigLoaded">
      <div class="form__section">
        <div class="form__line">
          <p>Настройка подписчиков:</p>
        </div>
        <div class="form__line">
          <label class="checkbox _one-third">
            <input type="radio" name="subscribersSettings" value="GroupUserLinkObjectType.allUsers"
              class="checkbox__input" (change)="subscribeTypeSelected=GroupUserLinkObjectType.allUsers"
              [checked]="subscribeTypeSelected===GroupUserLinkObjectType.allUsers">
            <span class="checkbox__icon"></span>
            <span class="checkbox__text">Все пользователи</span>
          </label>
          <label class="checkbox _one-third">
            <input type="radio" name="subscribersSettings" value="GroupUserLinkObjectType.domainGroup"
              class="checkbox__input" (change)="subscribeTypeSelected=GroupUserLinkObjectType.domainGroup"
              [checked]="subscribeTypeSelected===GroupUserLinkObjectType.domainGroup">
            <span class="checkbox__icon"></span>
            <span class="checkbox__text">Доменная группа</span>
          </label>
          <label class="checkbox _one-third">
            <input type="radio" name="subscribersSettings" value="GroupUserLinkObjectType.distributionGroup"
              class="checkbox__input" (change)="subscribeTypeSelected=GroupUserLinkObjectType.distributionGroup"
              [checked]="subscribeTypeSelected===GroupUserLinkObjectType.distributionGroup">
            <span class="checkbox__icon"></span>
            <span class="checkbox__text">Группа рассылки</span>
          </label>
        </div>
        <div class="form__line" *ngIf="isSubscriberSettingsAreaVisible()">
          <ng-container *ngIf="subscribeTypeSelected===GroupUserLinkObjectType.domainGroup">
            <input type="text" placeholder="Название доменной группы*" [(ngModel)]="domainGroupName"
              class="input-main__field" maxlength="255"
              [attr.disabled]="subscribeTypeSelected===GroupUserLinkObjectType.domainGroup?null:''">
            <div class="form__note">Можно добавить только одну группу</div>
          </ng-container>
          <ng-container *ngIf="subscribeTypeSelected===GroupUserLinkObjectType.distributionGroup">
            <input type="text" placeholder="Название группы рассылки*" [(ngModel)]="distributionGroupName"
              class="input-main__field" maxlength="255"
              [attr.disabled]="subscribeTypeSelected===GroupUserLinkObjectType.distributionGroup?null:''">
            <div class="form__note">Можно добавить только одну группу</div>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <div class="form__actions" [ngClass]="{'loading _blue': sending}">
      <button type="button" class="btn btn-simple" [routerLink]="['..']">Отмена</button>
      <button type="button" disabled="" class="btn btn-primary" [attr.disabled]="isValid()?null:''"
        (click)="submit()">Сохранить</button>
    </div>
  </div>
</div>
