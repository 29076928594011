import { Component, OnInit, OnDestroy } from '@angular/core';
import { TabbedListComponent } from '@app/shared/components/tabs/tabbed-list.component';
import { Subject } from 'rxjs';
import { BannersService } from '@app/banners/services/banners.service';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HelperService } from '@app/core/services/helper.service';
import { UsersService } from '@app/profile/services/users.service';
import { takeUntil, take, finalize } from 'rxjs/operators';
import { IBannerSlideType } from '@app/banners/model/banner';

/**
 * Список типов слайдов
 *
 * @export
 * @class BannerTypesListComponent
 * @extends {TabbedListComponent<IBannerType>}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-banner-types-list',
  templateUrl: './banner-types-list.component.html',
  styleUrls: ['./banner-types-list.component.scss']
})
export class BannerTypesListComponent extends TabbedListComponent<IBannerSlideType> implements OnInit, OnDestroy {

  isAdmin: boolean;

  constructor(
    public usersService: UsersService,
    public bannersService: BannersService,
    protected breadcrumbsService: BreadcrumbsService,
    protected router: Router,
    protected route: ActivatedRoute,
    public helper: HelperService
  ) { super(router, route, helper); }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      if (currentUser) {
        this.isAdmin = currentUser.isAdmin;

        this.tabs = {
          title: 'Типы слайдов',
          routerLink: `/admin/banner-types`,
          items: [
            {
              id: 1,
              title: 'Типы слайдов',
              fragment: '',
              items: [],
              offset: 0,
              itemsCount: 0,
              method: () => { },
              default: true,
              emptyText: 'Список типов слайдов пуст'
            }
          ]
        };

        this.currentTab = this.tabs.items[0];

        this.getBannerTypes();
      }
    });

    this.breadcrumbsService.breadcrumbs.next([
      { title: 'Настройка баннера', routerUrl: `/admin/banner-settings` },
      { title: 'Типы слайдов баннера', routerUrl: `/admin/banner-types` }
    ]);
  }

  navigate(id: number) {
    this.router.navigate([id], { relativeTo: this.route });
  }

  private getBannerTypes() {
    this.bannersService.bannerSlideTypeApiService.getAll().pipe(
      take(1),
      finalize(() => { this.loaded = true; this.currentTab.loaded = true; }),
      takeUntil(this.destroyed$))
      .subscribe(bannerTypes => {
        this.currentTab.items = bannerTypes.sort((a, b) => b.id - a.id);
        this.currentTab.itemsCount = bannerTypes.length;
      });
  }

  onDeleted(id: number) {
    this.currentTab.items = this.currentTab.items.filter(s => s.id !== id);
  }

  trackByFunc(index, item) {
    return item.id;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }
}
