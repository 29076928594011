<div class="post__action tooltip" mat-button [matMenuTriggerFor]="menu">
  <span class="btn btn-picture"></span>
</div>
<mat-menu #menu="matMenu">
  <div class="icon-menu">
    <a mat-menu-item href="javascript:;" class="icon-menu__item" (click)="showGalleryTree(galleryTreeMode.myGallery)">
      <span class="icon-menu__icon">
        <img src="{{assetsPrefix}}/assets/images/icons/menu/choose-file.svg" alt="" class="icon-menu__img">
      </span>
      <span>Выбрать из моей галереи</span>
    </a>
    <a mat-menu-item href="javascript:;" class="icon-menu__item" *ngIf="showGroupMenu() | async" (click)="showGalleryTree(galleryTreeMode.groupGallery)">
      <span class="icon-menu__icon">
        <img src="{{assetsPrefix}}/assets/images/icons/menu/choose-group-file.svg" alt="" class="icon-menu__img">
      </span>
      <span>Выбрать из галереи группы</span>
    </a>
    <a mat-menu-item href="javascript:;" class="icon-menu__item" (click)="mediaUpload.click()">
      <span class="icon-menu__icon">
        <img src="{{assetsPrefix}}/assets/images/icons/menu/upload.svg" alt="" class="icon-menu__img">
      </span>
      <span>Загрузить с компьютера</span>
    </a>
  </div>
</mat-menu>

<input type="file" #mediaUpload (change)="onUpload($event)" multiple style="display: none" accept=".jpeg,.jpg,.png,.bmp,.gif,.mp4,.avi,.wmv,.webm,.ogg" />
