import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { IGamificationType, IGamificationTypeChanges } from '@app/gamification/model/gamification-type';
import { GamificationApiService } from '@app/gamification/services/gamification-api.service';
import { GamificationTypeService } from '@app/gamification/services/gamification-type.service';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import { AlertsService } from '@app/shared/services/alerts.service';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-gamification-type-list',
  templateUrl: './gamification-type-list.component.html',
  styleUrls: ['./gamification-type-list.component.scss']
})
export class GamificationTypeListComponent extends BaseComponent implements OnInit, OnDestroy {

  gamificationTypes: IGamificationType[] = [];
  originalGamificationTypes: IGamificationType[] = [];

  changes: IGamificationTypeChanges = {
    hidden: [],
    visible: [],
    values: [],
  };

  constructor(
    public gamificationService: GamificationApiService,
    public gamificationTypeService: GamificationTypeService,
    private breadcrumbsService: BreadcrumbsService,
    private alertsService: AlertsService,
    private dialog: MatDialog,
    public helper: HelperService,
  ) { super(helper); }

  ngOnInit() {
    this.breadcrumbsService.breadcrumbs.next([
      { title: 'Геймификация', routerUrl: `/admin/gamification-types` }
    ]);

    this.getItems();
  }

  getToggleText(item: IGamificationType) {
    if (item.deleted) {
      return 'Включить тип геймификации';
    } else {
      return 'Отключить тип геймификации';
    }
  }

  isHidden(item: IGamificationType) {
    return item.deleted ? true : false;
  }

  onBlur(e: any, item: IGamificationType) {
    const existing = this.changes.values.find(s => s.id === item.id);
    if (existing) {
      existing.value = item.value;
    } else {
      this.changes.values.push({ id: item.id, value: item.value > 0 ? item.value : 0 });
    }
  }

  toggle(e: any, item: IGamificationType) {
    if (item.deleted) {
      // отмечаем, что нужно отобразить
      this.changes.visible.push(item.id);
      // снимаем дату удаления
      item.deleted = null;
      // убираем из статусов на скрытие, отправляемых на сервер
      this.changes.hidden = this.changes.hidden.filter(s => s !== item.id);
    } else {
      // отмечаем, что нужно скрыть
      this.changes.hidden.push(item.id);
      // проставляем дату удаления
      item.deleted = new Date();
      // убираем из статусов на отображение, отправляемых на сервер
      this.changes.visible = this.changes.visible.filter(s => s !== item.id);
    }
  }

  cancel() {
    this.gamificationTypes = JSON.parse(JSON.stringify(this.originalGamificationTypes));
    this.clearChanges();
  }

  submit() {
    if (this.hasAnyChanges()) {
      // Проверяем подтверждение
      this.dialog.open(ModalConfirmationComponent, {
        data: {
          text: `Вы действительно хотите сохранить изменения?`,
          okText: 'Да',
          onOk: () => {
            this.gamificationTypeService.editGamificationTypes(this.changes)
              .pipe(takeUntil(this.destroyed$))
              .subscribe(res => {
                if (res) {
                  this.alertsService.riseSuccess(`Список изменен`);
                  this.clearChanges();
                  this.getItems();
                } else {
                  this.alertsService.riseError(`Не удалось сохранить список`);
                }
              }, error => {
                this.alertsService.riseError(`Не удалось сохранить список`);
                console.log(error);
              });
          }
        }
      });
    }
  }

  private hasAnyChanges(): boolean {
    return this.changes
      && (this.changes.hidden && !!this.changes.hidden.length
        || this.changes.visible && !!this.changes.visible.length
        || !!this.changes.values.length);
  }

  isValid() {
    if (!this.changes) {
      return false;
    }
    const isValid = this.hasAnyChanges();
    return isValid;
  }

  drop(event: CdkDragDrop<string[]>) {
    console.log(`drop`);
    moveItemInArray(this.gamificationTypes, event.previousIndex, event.currentIndex);
    this.gamificationTypeService.changeGamificationTypeOrder({
      id: this.gamificationTypes[event.currentIndex].id,
      orders: this.gamificationTypes.map(s => {
        return { id: s.id, order: this.gamificationTypes.indexOf(s) };
      })
    })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        if (res) {
          this.alertsService.riseSuccess(`Успешно внесены изменения`);
        } else {
          this.alertsService.riseError(`Произошла ошибка при внесении изменений`);
        }
      }, error => {
        this.alertsService.riseError(`Произошла ошибка при внесении изменений`);
      });
  }

  private clearChanges() {
    this.changes.hidden = [];
    this.changes.visible = [];
  }

  private getItems() {
    this.gamificationTypeService.getGamificationTypes(true)
      .pipe(finalize(() => this.loaded = true), takeUntil(this.destroyed$))
      .subscribe(res => {
        this.gamificationTypes = res ? res : [];
        this.originalGamificationTypes = JSON.parse(JSON.stringify(this.gamificationTypes));
      });
  }
}
