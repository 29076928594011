import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewEmployeesWidgetComponent } from './components/new-employees-widget/new-employees-widget.component';
import { NewEmployeesListComponent } from './components/new-employees-list/new-employees-list.component';
import { SharedModule } from '@app/shared/shared.module';
import { FeedModule } from '@app/feed/feed.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule,
    FeedModule
  ],
  declarations: [
    NewEmployeesWidgetComponent,
    NewEmployeesListComponent
  ],
  exports: [
    NewEmployeesWidgetComponent,
    NewEmployeesListComponent
  ],
})
export class EmployeesModule { }
