<div class="cos__header">
  <div class="cos__actions pull-right" *ngIf="isShowMenu()">
    <button type="button" title="Фильтры" class="btn btn-filter" *ngIf="isShowFilters()" (click)="showFilters()"></button>
    <a *ngIf="showAddButton" class="btn btn-plus pull-right" routerLink="/profile/{{user?.id}}/vacancy/new">+</a>
  </div>
  <app-tabs [tabs]="tabs" [hideCounters]="true"></app-tabs>
  <app-vacancy-filters [visible]="filtersVisible" (categoryChanged)="onCategoryChanged($event)" (statusChanged)="onStatusChanged($event)"></app-vacancy-filters>
</div>

<app-vacancy-list-view *ngIf="currentTab?.loaded && currentTab?.items?.length" [vacancies]="currentTab?.items" [showMenu]="currentTab?.name==='my'"></app-vacancy-list-view>

<div class="cos__body" *ngIf="currentTab?.loaded && !currentTab?.items?.length">
  <!-- Empty list -->
  <div class="group-empty">
    <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" style="width:auto" class="group-empty__img">
    <div class="group-empty__text">{{currentTab.emptyText}}</div>
  </div>
  <!-- Empty list END-->
</div>
