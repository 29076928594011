import {Component, OnInit, Input, ChangeDetectorRef} from '@angular/core';
import {GalleryWidgetComponent} from '@app/gallery/components/gallery-widget/gallery-widget.component';
import {GroupInfo} from '@app/groups/model/group-info.model';
import {UsersService} from '@app/profile/services/users.service';
import {GalleryService} from '@app/gallery/services/gallery.service';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {GroupsService} from '@app/groups/services/groups.service';
import {HelperService} from '@app/core/services/helper.service';
import {takeUntil} from 'rxjs/operators';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-group-gallery-widget',
  templateUrl: './group-gallery-widget.component.html',
  styleUrls: ['./group-gallery-widget.component.scss'],
})
export class GroupGalleryWidgetComponent extends GalleryWidgetComponent implements OnInit {
  /**
   * Текущая группа
   *
   * @type {GroupInfo}
   * @memberof GroupGalleryWidgetComponent
   */
  @Input() group: GroupInfo;

  constructor(
    public dialog: MatDialog,
    protected usersService: UsersService,
    protected sanitizer: DomSanitizer,
    public galleryService: GalleryService,
    protected groupsService: GroupsService,
    protected router: Router,
    protected cdr: ChangeDetectorRef,
    public helper: HelperService,
  ) {
    super(dialog, usersService, sanitizer, galleryService, router, cdr, helper);
  }

  protected loadMediaFiles() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
        this.group = group;

        if (group) {
          this.link = `/group/${this.group.id}/gallery`;

          // show "+" when has rights
          this.showAddButton = this.groupsService.isParticipant(this.group, currentUser);
          this.isAdmin = this.groupsService.isAdmin(this.group, currentUser);
          const showWidget = this.groupsService.canReadGroup(this.group, currentUser);

          this.showWidget.next(showWidget);

          if (showWidget) {
            // в чужом профиле пользователь видит в виджете последние загруженные файле данного коллеги
            this.operateMediaFiles(this.galleryService.getProfileMediaFiles(group.id, this.offset, this.limit));
          } else {
            this.loaded = true;
          }
        }
      });
    });
  }

  /**
   * Добавлять класс для основного блока
   *
   * @returns {boolean}
   * @memberof GroupGalleryWidgetComponent
   */
  isShowLoaded(): boolean {
    return this.showWidget.getValue() && this.loaded && !this.isShowEmpty();
  }
}
