<div class="cos__body _paddings" *ngIf="loaded && vacancy">
  <div class="cos__actions pull-right" *ngIf="vacancy.userAdmin">
    <app-vacancy-menu [item]="vacancy" [config]="getVacancyConfig(vacancy)" [vacancyLocation]="1" (deleted)="onDeleted($event)"></app-vacancy-menu>
  </div>
  <div class="news-title _bright">{{vacancy?.title}}</div>
  <div class="text-secondary" *ngIf="vacancy?.location">{{vacancy?.location}}</div>
  <div class="tags">
    <a *ngIf="showGroupLink()" routerLink="/group/{{vacancy?.group?.id}}" class="link">{{vacancy?.group?.title}}</a>
    <a *ngIf="showCategoryLink()" routerLink="/group/{{vacancy?.group?.id}}/vacancy" fragment='category={{vacancy?.category?.id}}'
      class="link">{{vacancy?.category?.title}}</a>
  </div>
  <div class="text-secondary" *ngIf="vacancy?.userAdmin">
    <span>{{vacancyService.getStatusText(vacancy)}}</span>,&nbsp;
    <span>{{vacancyService.getResponsesCountText(vacancy)}}</span>
  </div>
  <div class="news-section">
    <app-message [text]="vacancy?.description" [mentions]="mentions" [showBody]="true"></app-message>
  </div>
  <div class="news-section" *ngIf="attachments?.length">
    <app-attachments-list [attachments]="attachments" [elementType]="elementType.Vacancy"></app-attachments-list>
  </div>
  <div class="news-section" *ngIf="showResponseClick()">
    <div class="new-post">
      <div class="new-post__actions">
        <button type="button" class="btn btn-primary" (click)="responseClick()">Откликнуться</button>
      </div>
    </div>
  </div>
  <div class="news-section" *ngIf="creatingResponse">
    <div class="news-announce">Ваш отклик</div>
    <div class="new-post">
      <div class="input-main new-post__about">
        <textarea autosize rows="1" placeholder="Опишите ваш опыт, образование и почему вам интересна эта вакансия*"
          [(ngModel)]="vacancyResponse.description" class="input-main__field" (keyup)="onKey($event)">
        </textarea>
      </div>
      <app-attachments-list #responseAttachmentsList [attachments]="responseAttachments" [isEdit]="true" (onRemove)="onRemoveAttachment($event)"
        (onFilesUploaded)="onFilesUploaded($event)" [ownerId]="vacancy.ownerId" [elementType]="elementType.VacancyResponse"></app-attachments-list>
      <div class="new-post__actions">
        <div class="new-post__actions-extra" *ngIf="vacancy.ownerId">
          <app-attachments-menu title="Добавить вложение" (choose)="responseAttachmentsList.onChooseFile($event)"
            (upload)="onUploadFile($event)" [multiple]="false" [noGroupFiles]="true"></app-attachments-menu>
        </div>
        <button type="button" class="btn btn-simple" (click)="cancel()">Отмена</button>
        <button type="button" class="btn btn-primary" (click)="submit()"
          [attr.disabled]="(!sendingResponse && vacancyResponse.description) ? null : ''"
        >Отправить</button>
      </div>
    </div>
  </div>
  <div class="news-section" *ngIf="vacancy?.isResponded">
    Спасибо. Вы уже откликнулись на вакансию.
  </div>
</div>
<app-response-list [vacancy]="vacancy" *ngIf="isShowResponses()"></app-response-list>
