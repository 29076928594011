import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseResizableComponent } from '@app/core/components/base-resizable.component';
import { HelperService } from '@app/core/services/helper.service';

@Component({
  selector: 'app-surveys-menu',
  templateUrl: './surveys-menu.component.html'
})
export class SurveysMenuComponent extends BaseResizableComponent {
  @Input() showAddButton: boolean;
  @Input() fromWidget: boolean;

  private link = 'new';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public helper: HelperService) {
    super(helper);
  }

  createSurvey() {
    this.router.navigate([this.getLink()], { relativeTo: this.route });
  }

  createTest() {
    this.router.navigate([this.getLink()], { relativeTo: this.route, fragment: 'test' });
  }

  private getLink() {
    return this.fromWidget ? 'surveys/' + this.link : this.link;
  }
}
