import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { HelperService } from '@app/core/services/helper.service';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { IBanner, IUpdateBannerSlideTypeModel, INewBannerSlideTypeModel, IBannerSlideType } from '../model/banner';
import { IBannerFile } from '../model/banner-file';

@Injectable({
  providedIn: 'root'
})
export class BannersApiService extends BaseService {
  protected serviceUrl = `${this.helper.environment.siteUrl}/api/banner`;

  constructor(
    protected http: HttpClient,
    public helper: HelperService) { super(http, helper); }

  getMainBanners(): Observable<IBanner> {
    return super.get('main');
  }

  getBanner(): Observable<IBanner> {
    return super.get('');
  }

  saveBanner(data: IBanner): Observable<IBanner> {
    return super.put('', { data });
  }

  uploadBannerSlideImage(formData: FormData): Observable<IBannerFile> {
    return super.post(`${(<any>window).signalRServer}/_layouts/15/Intry/handlers/BannerSlideImageUploadHandler.ashx`, formData);
  }

  /**
   * Является ли пользователь администратором
   *
   * @returns {Observable<boolean>}
   * @memberof BannersApiService
   */
  isAdmin(): Observable<boolean> {
    return super.get('isAdmin');
  }

  getMission(): Observable<string> {
    return super.get('mission');
  }
}
