import { Router } from '@angular/router';
import { IntryAuthService } from '../services/intry-auth.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export abstract class IntryGuard {

  protected constructor(protected auth: IntryAuthService, protected router: Router) {
  }

  protected forbidden(): void {
    this.router
      .navigate(
        [this.auth.configuration.forbiddenRoute],
        {
          skipLocationChange: true,
          queryParams: {
            from_url: encodeURIComponent(location.href)
          }
        }
      )
      .then(() => {
        console.log('[IntryGuard] Forbidden');
      });
  }

  protected check(checker: (isAuthorized: boolean) => boolean): Observable<boolean> {
    return this.auth.isAuthenticated$.pipe(
      map(checker)
    );
  }

}
