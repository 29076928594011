import { Component, Input, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BaseComponent } from '@app/core/components/base.component';
import { User } from '@app/profile/model/user.model';
import { HelperService } from '@app/core/services/helper.service';
import { UsersService } from '@app/profile/services/users.service';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
  animations: [
    trigger('visibilityChanged', [
      state('true', style({ opacity: 1, display: 'inherit' })),
      state('false', style({ opacity: 0, display: 'none' })),
      transition('* => *', animate('.5s'))
    ])
  ]
})
export class ContainerComponent extends BaseComponent {
  @Input() goTopVisible = false;

  user: User;

  constructor(
    private usersService: UsersService,
    public helper: HelperService) {
    super(helper);
    this.usersService.currentUser.subscribe(currentUser => {
      this.user = currentUser;
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.goTopVisible = window.pageYOffset > window.innerHeight / 2;
  }

  scrollTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}
