import { Injectable, EventEmitter } from '@angular/core';
import { GroupUserRole } from '@app/groups/model/group-user-role.model';
import { GroupInfo } from '@app/groups/model/group-info.model';
import { HubConnection } from '@microsoft/signalr';

@Injectable()
export class GroupHub {
    public onAddUsers: EventEmitter<any> = new EventEmitter<any>();
    public onAddUsersForCurrent: EventEmitter<boolean> = new EventEmitter<boolean>();
    public onRemoveUsersForCurrent: EventEmitter<boolean> = new EventEmitter<boolean>();

    public onLeaveForCurrent: EventEmitter<IGroupUserResult> = new EventEmitter<IGroupUserResult>();
    public onSubscribeForCurrent: EventEmitter<IGroupUserResult> = new EventEmitter<IGroupUserResult>();
    public onJoinForCurrent: EventEmitter<IGroupUserResult> = new EventEmitter<IGroupUserResult>();
    public onUnsubscribeForCurrent: EventEmitter<IGroupUserResult> = new EventEmitter<IGroupUserResult>();

    public onApproveRequestForCurrent: EventEmitter<IGroupUserRoleResult> = new EventEmitter<IGroupUserRoleResult>();
    public onDeclineRequestForCurrent: EventEmitter<IGroupUserRoleResult> = new EventEmitter<IGroupUserRoleResult>();

    public onGroupSiteCreated: EventEmitter<IGroupIdResult> = new EventEmitter<IGroupIdResult>();

    // register all client functions
    registerOnServerEvents(hubConnection: HubConnection): void {
        // add users to group
        hubConnection.on('onAddUsers', (data: any) => {
            this.onAddUsers.emit(data);
        });
        hubConnection.on('onAddUsersForCurrent', (data: any) => {
            this.onAddUsersForCurrent.emit(data);
        });
        hubConnection.on('onRemoveUsersForCurrent', (data: any) => {
            this.onRemoveUsersForCurrent.emit(data);
        });

        // subscription
        hubConnection.on('onLeaveForCurrent', (data: any) => {
            this.onLeaveForCurrent.emit(data);
        });
        hubConnection.on('onSubscribeForCurrent', (data: IGroupUserResult) => {
            this.onSubscribeForCurrent.emit(data);
        });
        hubConnection.on('onJoinForCurrent', (data: IGroupUserResult) => {
            this.onJoinForCurrent.emit(data);
        });
        hubConnection.on('onUnsubscribeForCurrent', (data: any) => {
            this.onUnsubscribeForCurrent.emit(data);
        });

        // request
        hubConnection.on('onApproveRequestForCurrent', (data: IGroupUserRoleResult) => {
            this.onApproveRequestForCurrent.emit(data);
        });
        hubConnection.on('onDeclineRequestForCurrent', (data: IGroupUserRoleResult) => {
            this.onDeclineRequestForCurrent.emit(data);
        });

        hubConnection.on('onGroupSiteCreated', (data: IGroupIdResult) => {
            this.onGroupSiteCreated.emit(data);
        });
    }
}

export interface IGroupResult {
    group: GroupInfo;
    result: boolean;
}

export interface IGroupUserResult extends IGroupResult {
    userId: number;
}

export interface IGroupIdResult {
    groupId: number;
    result: boolean;
}

export interface IGroupIdUserResult extends IGroupIdResult {
    userId: number;
}

export interface IGroupUserRoleResult extends IGroupUserResult {
    role: GroupUserRole;
}

export interface IGroupIdUserRoleResult extends IGroupIdUserResult {
    role: GroupUserRole;
}
