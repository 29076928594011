export enum FSObjType {
    File = 0,
    Folder = 1
}

export class RowUser {
    id?: string;
    value?: string;
    title?: string;
    email?: string;
    sip?: string;
    picture?: string;
}

export class RowItem {
    ID: string;
    PermMask: any;
    FSObjType: FSObjType;
    HTML_x0020_File_x0020_Type: string;
    UniqueId: string;
    ProgId: string;
    // <name>.<ext>,
    FileLeafRef: string;
    'Created_x0020_Date.ifnew': string;
    // relative path
    FileRef: string;
    File_x0020_Type: string;
    'File_x0020_Type.mapapp': string;
    'serverurl.progid': string;
    ServerRedirectedEmbedUrl: string;
    ContentTypeId: string;
    CheckoutUser: any;
    CheckedOutUserId: any;
    IsCheckedoutToLocal: any;
    Modified: Date;
    'Modified.FriendlyDisplay': string;
    Editor: Array<RowUser>;
    File_x0020_Size: string;
    PrincipalCount: string;
    SharedWithUsers: Array<RowUser>;
    ItemChildCount: number;
    FolderChildCount: number;
    LinkFilename: string;
}

export class ListData {
    Row: Array<RowItem>;
    FirstRow: number;
    FolderPermissions: any;
    LastRow: number;
    //  ?RootFolder=%2Fpersonal%2Fdev%5Fadministrator%2FDocuments%2F%D0%B0%D0%B0%D0%B0&SortField=LinkFilename&SortDir=Asc&,
    FilterLink: string;
    SortField: string;
    SortDir: string;
    ForceNoHierarchy: string;
    HierarchyHasIndention: string;
    FolderId: string;
}

export class SPField {
    Name: any;
    FieldType: any;
    RealFieldName: any;
    DisplayName: string;
    ID: string;
    ReadOnly: boolean;
    role: any;
    ariaLabel: any;
    Type: any;
    AllowGridEditing: boolean;
}

export class ListSchema {
    Field: Array<SPField>;
    LCID: number;
    Userid: number;
    View: string;
    // tslint:disable-next-line:max-line-length
    // RootFolderParam: View={00000000-0000-0000-0000-000000000000}&RootFolder=%2Fpersonal%2Fdev%5Fadministrator%2FDocuments%2F%D0%B0%D0%B0%D0%B0&,
    RootFolderParam: string;
    FieldSortParam: string;
    // http://portal/personal/dev_administrator,
    HttpVDir: string;
    UIVersion: number;
    // текст по умолчанию при пустом представлении
    NoListItem: string;
    // текст по умолчанию добавления нового элемента
    NoListItemHowTo: string;
    DefaultItemOpen: number;
    ForceCheckout: number;
    Direction: string;
    TabularView: number;
    EffectivePresenceEnabled: number;
    // ссылка на старницу профиля пользователя
    UserDispUrl: string;
    SelectedID: number;
    ListRight_AddListItems: number;
    FolderRight_AddListItems: number;
    InplaceSearchEnabled: number;
    // при отображении папки приходит RootFolder=%2Fpersonal%2...
    ViewSelector_ViewParameters: string;
}

export class BasePermissions {
    ManageLists: boolean;
    ManagePersonalViews: boolean;
    OpenItems: boolean;
}

export class FolderData {
    wpq: string;
    Templates: any;
    ListData: ListData;
    ListSchema: ListSchema;
    BaseViewID: string;
    ListTemplateType: string;
    listBaseType: number;
    noGroupCollapse: boolean;
    InlineEdit: boolean;
    NavigateForFormsPages: boolean;
    BasePermissions: BasePermissions;
    CurrentUserIsSiteAdmin: boolean;
    IsAppWeb: boolean;
    AllowGridMode: boolean;
    inGridMode: boolean;
    listTemplate: string;
    // guid
    listName: string;
    // folder relative path
    rootFolder: string;
    view: string;
    viewTitle: string;
    // relative path
    listUrlDir: string;
    HttpPath: string;
    HttpRoot: string;
    NoScriptEnabled: boolean;
    imagesPath: string;
    PortalUrl: boolean;
    SendToLocationName: string;
    SendToLocationUrl: string;
    RecycleBinEnabled: number;
    OfficialFileName: string;
    OfficialFileNames: string;
    WriteSecurity: string;
    SiteTitle: string;
    ListTitle: string;
    // absolute
    displayFormUrl: string;
    newFormUrl: string;
    editFormUrl: string;
    ctxId: number;
    isXslView: boolean;
    IsClientRendering: boolean;
    CurrentUserId: number;
    isModerated: boolean;
    isForceCheckout: boolean;
    EnableMinorVersions: boolean;
    verEnabled: boolean;
    recursiveView: boolean;
    WorkflowsAssociated: boolean;
    ContentTypesEnabled: boolean;
    DocumentLibraryCalloutOfficeWebAppPreviewersDisabled: boolean;
    RegionalSettingsTimeZoneBias: any;
    NewWOPIDocumentEnabled: boolean;
    AllowCreateFolder: boolean;
    CanShareLinkForNewDocument: boolean;
    SiteTemplateId: number;
    ExcludeFromOfflineClient: boolean;
}
