<div class="cos__body _paddings">
  <div class="new-post">
    <div class="input-main new-post__title">
      <textarea rows="1" placeholder="Введите название страницы*" [(ngModel)]="page.title" class="input-main__field"
        maxlength="255"></textarea>
    </div>
    <div class="new-post__page">
      <app-froala-editor
        class="post__editor _full"
        placeholder="Добавьте содержание страницы*"
        [showToolbar]="true"
        [(editorModel)]="page.body"
        [(text)]="page.body"
        [(mentions)]="mentions"
      ></app-froala-editor>
    </div>
    <app-add-tags placeholder="Теги" [tagType]="TagType.page" [mode]="AddTagsComponentMode.edit"></app-add-tags>
    <div class="new-post__actions _flex-phone">
      <button type="button" class="btn btn-simple" (click)="cancel()">Отмена</button>
      <button type="button" class="btn btn-white" (click)="draft()" [attr.disabled]="isValid()?null:''">Сохранить как
        черновик</button>
      <button type="button" class="btn btn-primary" (click)="submit()"
        [attr.disabled]="isValid()?null:''">Опубликовать</button>
    </div>
  </div>
</div>
