<div class="btn btn-dots-white tooltip" mat-button [matMenuTriggerFor]="menu"></div>
<mat-menu #menu="matMenu">
  <div class="icon-menu" #menuContainer>
    <a mat-menu-item [routerLink]="['awards']" class="icon-menu__item">
      <span class="icon-menu__icon">
        <img src="{{assetsPrefix}}/assets/images/icons/menu/edit.svg" alt="" class="icon-menu__img">
      </span>
      <span>Редактировать список</span>
    </a>
    <a mat-menu-item [routerLink]="['awards/new']" class="icon-menu__item">
      <span class="icon-menu__icon">
        <img src="{{assetsPrefix}}/assets/images/icons/menu/copy.svg" alt="" class="icon-menu__img">
      </span>
      <span>Добавить награду</span>
    </a>
  </div>
</mat-menu>
