import {Component, OnInit, ViewChild, OnDestroy, forwardRef} from '@angular/core';
import {AttachmentViewModel} from '@app/attachments/file-attachments/model/attachment-view-model.model';
import {takeUntil, switchMap, take} from 'rxjs/operators';
import {IdeasService} from '@app/ideas/services/ideas.service';
import {BaseComponent} from '@app/core/components/base.component';
import {BreadcrumbsService} from '@app/shared/services/breadcrumbs.service';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {HelperService} from '@app/core/services/helper.service';
import {IIdea, IIdeaStatus} from '@app/ideas/model/idea';
import {ElementType} from '@app/core/model/element-type.enum';
import {CommentViewModel} from '@app/comments/model/comment-view-model.model';
import {CommentsListComponent} from '@app/comments/components/comments-list/comments-list.component';
import {CommentsService} from '@app/comments/services/comments.service';
import {CommentNewComponent} from '@app/comments/components/comment-new/comment-new.component';
import {IIdeaMenuConfig} from '../../idea-menu/idea-menu.component';
import {GroupsService} from '@app/groups/services/groups.service';
import {UsersService} from '@app/profile/services/users.service';
import {GroupInfo} from '@app/groups/model/group-info.model';
import {User} from '@app/profile/model/user.model';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {IdeaStatusModalComponent} from '../../dialogs/idea-status-modal/idea-status-modal.component';
import {IdeaStatusHistoryModalComponent} from '../../dialogs/idea-status-history-modal/idea-status-history-modal.component';

/**
 * Страница просмотра идеи
 *
 * @export
 * @class IdeaViewComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-idea-view',
  templateUrl: './idea-view.component.html',
  styleUrls: ['./idea-view.component.scss'],
})
export class IdeaViewComponent extends BaseComponent implements OnInit, OnDestroy {
  idea: IIdea;
  selectedStatus: IIdeaStatus;
  statuses: IIdeaStatus[] = [];

  attachments: AttachmentViewModel[];
  elementType = ElementType.Idea;

  comments: CommentViewModel[] = [];
  commentsLoaded: boolean;

  @ViewChild(forwardRef(() => CommentsListComponent)) protected commentsList: CommentsListComponent;
  @ViewChild(forwardRef(() => CommentNewComponent)) protected commentNew: CommentNewComponent;

  private group: GroupInfo;
  private currentUser: User;
  private menuItemConfig: IIdeaMenuConfig = {
    allowDelete: true,
    allowEdit: true,
    allowPublish: true,
  };

  private statusChangeDialog: MatDialogRef<IdeaStatusModalComponent>;

  constructor(
    protected ideasService: IdeasService,
    protected groupsService: GroupsService,
    protected usersService: UsersService,
    protected breadcrumbsService: BreadcrumbsService,
    protected commentsService: CommentsService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public helper: HelperService,
  ) {
    super(helper);
  }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;

      this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
        this.group = group;
        if (group) {
          // получаем все статусы (только если админ группы)
          if (this.groupsService.isAdmin(this.group, currentUser)) {
            this.ideasService
              .getIdeaStatuses()
              .pipe(take(1), takeUntil(this.destroyed$))
              .subscribe(res => {
                // заполняем массив статусов
                this.statuses = res ? res : [];
              });
          }
        }
      });
    });

    this.route.params
      .pipe(
        takeUntil(this.destroyed$),
        switchMap((params: Params) => {
          this.idea = null;
          const ideaId = +params['ideaId'];
          return this.ideasService.getById(ideaId);
        }),
      )
      .subscribe(
        idea => {
          this.idea = idea;

          if (this.idea) {
            this.selectedStatus = this.idea.status;

            // получаем комментарии
            this.commentsService
              .getComments(this.idea.id, this.elementType, 0, 10)
              .pipe(take(1), takeUntil(this.destroyed$))
              .subscribe(res => {
                if (!this.comments) {
                  this.comments = [];
                }

                if (res && res.comments) {
                  res.comments.forEach(comment => {
                    if (!this.comments.find(s => s.comment.id === comment.comment.id)) {
                      this.comments.unshift(comment);
                    }
                  });
                }

                this.commentsLoaded = true;
              });

            // рисуем маршрут
            const ideaRoute = `/group/${this.idea.ownerId}/ideas`;

            this.breadcrumbsService.breadcrumbs.next([
              {title: 'Идеи', routerUrl: ideaRoute},
              {title: 'Идея', routerUrl: ideaRoute + `/${this.idea.id}`},
            ]);

            // отображаем вложения
            if (this.idea.docs) {
              this.attachments = this.idea.docs.map(s => {
                return {attachment: s, owner: null};
              });
            }
          } else {
            // переход на 404 страницу
            this.router.navigate(['/404'], {skipLocationChange: true});
          }

          this.error = '';
          this.loaded = true;
        },
        error => {
          this.error = error;
          this.loaded = true;
        },
      );
  }

  /**
   * Событие при добавлении нового комментария
   *
   * @param {CommentViewModel} comment
   * @memberof PostComponent
   */
  onNewCommentAdded(comment: CommentViewModel) {
    this.commentsList.onCommentAdded(comment);
  }

  /**
   * Комментарий был добавлен. Обновление счётчиков.
   *
   * @param {number} count
   * @memberof PostComponent
   */
  onCommentAdded(count: number) {
    this.idea.commentsCount = count;
  }

  /**
   * Комментарий был удалён. Обновление счётчиков.
   *
   * @param {number} count
   * @memberof PostComponent
   */
  onCommentDeleted(count: number) {
    this.idea.commentsCount = count;
  }

  /**
   * Ответить на идею
   *
   * @memberof PostComponent
   */
  reply() {
    this.commentNew.activateNoParent(this.idea.author);
  }

  onDeleted() {
    this.router.navigate(['..'], {relativeTo: this.route});
  }

  getIdeaConfig(idea: IIdea): IIdeaMenuConfig {
    return this.ideasService.getIdeaConfig(
      idea,
      this.groupsService.isAdmin(this.group, this.currentUser),
      this.currentUser,
    );
  }

  isIdeaAdmin(idea: IIdea) {
    return this.groupsService.isAdmin(this.group, this.currentUser);
  }

  setStatus(status: IIdeaStatus) {
    this.statusChangeDialog = this.dialog.open(IdeaStatusModalComponent, {
      data: {
        ideaId: this.idea.id,
        status: status,
        onOk: (res: boolean) => {
          if (res) {
            this.selectedStatus = this.idea.status = status;
            // отправляем событие о изменении в сервис
            this.ideasService.ideaChanged$.next();
          }
          this.statusChangeDialog.close();
        },
      },
    });
  }

  showStatusHistory() {
    this.dialog.open(IdeaStatusHistoryModalComponent, {
      minWidth: '50vw',
      maxWidth: '90vw',
      minHeight: '50vh',
      maxHeight: '90vh',
      data: {
        ideaId: this.idea.id,
      },
    });
  }
}
