import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HelperService } from '@app/core/services/helper.service';
import { IUpdateGiftModel } from '@app/gifts/model/gift';
import { GiftService } from '@app/gifts/services/gift.service';
import { AlertsService } from '@app/shared/services/alerts.service';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { takeUntil, switchMap, finalize } from 'rxjs/operators';
import { GiftNewComponent } from '../gift-new/gift-new.component';

@Component({
  selector: 'app-gift-edit',
  templateUrl: '../gift-new/gift-new.component.html'
})
export class GiftEditComponent extends GiftNewComponent implements OnInit {

  gift: IUpdateGiftModel;

  sending: boolean;

  constructor(
    public giftService: GiftService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected dialog: MatDialog,
    protected breadcrumbsService: BreadcrumbsService,
    protected alertsService: AlertsService,
    public helper: HelperService
  ) { super(giftService, router, route, dialog, breadcrumbsService, alertsService, helper); }

  ngOnInit(): void {
    this.route.params
      .pipe(
        takeUntil(this.destroyed$),
        switchMap((params: Params) => {
          this.gift = null;
          const giftId = +params['giftId'];
          return this.giftService.getById(giftId);
        }))
      .subscribe(gift => {
        this.gift = gift;

        if (this.gift) {
          this.breadcrumbsService.breadcrumbs.next([
            { title: 'Магазин подарков', routerUrl: this.pageRoute },
            { title: gift.title, routerUrl: this.pageRoute + `/${this.gift.id}` }
          ]);
        } else {
          this.router.navigate(['/404'], { skipLocationChange: true });
        }

        this.loaded = true;
        this.error = '';
      }, error => {
        this.loaded = true;
        this.error = error;
      });
  }

  submit() {
    if (this.isValid()) {
      this.sending = true;
      this.giftService.update(this.gift)
        .pipe(finalize(() => this.sending = false), takeUntil(this.destroyed$))
        .subscribe(res => {
          if (res) {
            this.alertsService.riseSuccess('Подарок был успешно сохранён.');
            this.router.navigate(['../..'], { relativeTo: this.route, fragment: 'admin' });
          } else {
            this.alertsService.riseError('Произошла ошибка при отправке формы. Обратитесь к администратору.');
          }
        }, error => {
          this.alertsService.riseError('Произошла ошибка при отправке формы. Обратитесь к администратору.');
          console.log(error);
        });
    } else {
      this.alertsService.riseError('Произошла ошибка при отправке формы. Не заполнены обязательные поля');
    }
  }
}
