<div class="cos__body">
  <div class="survey" *ngIf="banner">
    <div class="survey__section _banner" *ngFor="let item of banner.slides; let i=index">
      <div class="section-move">
        <div class="section-move__number">{{item.order}}.</div>
        <div class="section-move__buttons">
          <button type="button" class="btn btn-content-up" *ngIf="i!==0" (click)="moveUp(i)"></button>
          <button type="button" class="btn btn-content-down" *ngIf="i!==banner.slides.length-1"
            (click)="moveDown(i)"></button>
          <button type="button" class="btn btn-content-can-view"
            [ngClass]="{'btn-content-can-view': item.show, 'btn-content-eye-hide': !item.show}"
            (click)="item.show=!item.show"></button>
          <button type="button" class="btn btn-remove" (click)="remove(i)"></button>
        </div>
      </div>
      <div [ngClass]="{'_shadow': item.imageUrl}" [attr.style]="bannersService.getPictureUrl(item)" class="banner-item _image _settings">
        <a [attr.href]="item.externalUrl" class="banner-item__link link _white">
          <div class="banner-item__title">{{item.title}}</div>
          <div class="banner-item__desc">{{item.description}}</div>
        </a>
        <div class="banner-item__controls">
          <div class="profile-photo _group">
            <img src="{{assetsPrefix}}/assets/images/icons/photo.svg" class="profile-photo__img">
            <span class="profile-photo__text">{{item.imageUrl ? 'Заменить фото':'Добавить фото'}}</span>
            <div class="profile-photo__edit" (click)="groupImage.click()">
              <input #groupImage style="display: none" type="file" class="profile-photo__file" accept="image/*"
                (change)="imageChangeEvent($event, item)">
            </div>
          </div>
          <div class="profile-photo _group" *ngIf="item.imageUrl" (click)="removeImage(item)">
            <img src="{{assetsPrefix}}/assets/images/icons/photo_remove.svg" class="profile-photo__img">
            <span class="profile-photo__text">Удалить фото</span>
          </div>
        </div>
      </div>
      <div class="form__line">
        <div class="form__note">Минимальный размер для фото: ширина - 2284 пикс, высота - 672 пикс.</div>
      </div>
      <div class="form__line">
        <div class="survey__title input-main">
          <textarea autosize [(ngModel)]="item.title" maxlength="255" placeholder="Название слайда" rows="1"
            class="input-main__field"></textarea>
        </div>
      </div>
      <div class="form__line">
        <div class="survey__description input-main">
          <textarea autosize [(ngModel)]="item.description" maxlength="255" placeholder="Анонс слайда" rows="1"
            class="input-main__field"></textarea>
        </div>
      </div>
      <div class="form__line">
        <div class="dropdown _default" [ngClass]="{'_placeholder':!item.sliderType}">
          <div mat-button [matMenuTriggerFor]="menu">
            {{item.sliderType?.title ? item.sliderType?.title : 'Выберите тип слайда*'}}
          </div>
          <mat-menu #menu="matMenu">
            <div class="icon-menu" #menuContainer>
              <a mat-menu-item href="javascript:;" class="icon-menu__item"
                (click)="setBannerSliderType(item, bannerSlideType)" *ngFor="let bannerSlideType of bannerSlideTypes">
                <span>{{bannerSlideType.title}}</span>
              </a>
            </div>
          </mat-menu>
        </div>
      </div>
      <div class="form__line">
        <div class="input-main form__about _full-width">
          <textarea autosize [(ngModel)]="item.externalUrl" placeholder="Ссылка на страницу в формате url" rows="1"
            class="input-main__field"></textarea>
        </div>
      </div>
      <div class="form__line">
        <label class="checkbox">
          <input type="checkbox" name="" class="checkbox__input" [(ngModel)]="item.showInNewWindow">
          <span class="checkbox__icon"></span>
          <span class="checkbox__text">Открывать ссылку в новом окне</span>
        </label>
      </div>
      <div class="post__calendar">Отображать до
        <input placeholder="дд.мм.гггг" matInput [matDatepicker]="dp" [(ngModel)]="item.showUntil">
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp></mat-datepicker>
      </div>
      <div class="form__line"></div>
    </div>
    <div class="survey__section">
      <div class="form__line"><span>Автопрокрутка через</span>
        <input class="main-input _inline" type="tel" [(ngModel)]="banner.autoSlide"/><span>сек</span>
      </div>
      <div class="form__line">
        <div type="button" class="btn btn-add" (click)="add()">Добавить слайд</div>
      </div>
      <div class="form__line">
        <a type="button" class="link _progress" [routerLink]="['/admin/banner-types']">Настроить
          типы слайдов</a>
      </div>
      <div class="form__actions" [ngClass]="{'loading _blue': sending}">
        <button type="button" class="btn btn-simple" (click)="cancel()">Отмена</button>
        <button type="button" class="btn btn-primary" (click)="submit()"
          [attr.disabled]="isValid()?null:''">Сохранить</button>
      </div>
    </div>
  </div>
</div>
