import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import { ISetting, ISettingsChanges } from '@app/shared/model/setting';
import { AlertsService } from '@app/shared/services/alerts.service';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { SettingsService } from '@app/shared/services/settings.service';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent extends BaseComponent implements OnInit {

  settings: ISetting[] = [];
  originalSettings: ISetting[] = [];

  changes: ISettingsChanges = {
    added: [],
    deleted: [],
    updated: []
  };

  constructor(
    private settingsService: SettingsService,
    private breadcrumbsService: BreadcrumbsService,
    private alertsService: AlertsService,
    protected dialog: MatDialog,
    public helper: HelperService
  ) { super(helper); }

  ngOnInit() {
    this.breadcrumbsService.breadcrumbs.next([
      { title: 'Настройки', routerUrl: `/admin/settings` }
    ]);

    this.settingsService.getAll()
      .pipe(finalize(() => this.loaded = true), takeUntil(this.destroyed$))
      .subscribe(res => {
        this.settings = res ? res : [];
        this.originalSettings = JSON.parse(JSON.stringify(this.settings));
      });
  }

  submit() {
    if (this.hasAnyChanges()) {
      this.settingsService.editSettings(this.changes)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(res => {
          if (res) {
            this.alertsService.riseSuccess(`Список изменен`);
            this.clearChanges();
          } else {
            this.alertsService.riseError(`Не удалось сохранить список`);
          }
        }, error => {
          this.alertsService.riseError(`Не удалось сохранить список`);
          console.log(error);
        });
    }
  }

  hasAnyChanges() {
    return this.changes
      && (this.changes.added && this.changes.added.length
        || this.changes.updated && this.changes.updated.length
        || this.changes.deleted && this.changes.deleted.length);
  }

  private clearChanges() {
    this.changes.added = [];
    this.changes.updated = [];
    this.changes.deleted = [];
  }

  onRemove(setting: ISetting) {
    this.dialog.open(ModalConfirmationComponent, {
      data: {
        text: `Вы действительно хотите удалить элемент из списка?`,
        okText: 'Да',
        onOk: () => { this.removeSetting(setting); }
      }
    });
  }

  removeSetting(setting: ISetting) {
    if (!this.changes.deleted.find(s => s === setting.id)) {
      this.changes.deleted.push(setting.id);
    }
    this.settings = this.settings.filter(s => s.id !== setting.id && s.key !== setting.key);
    console.log(this.changes);
  }

  addSetting() {
    const index = this.settings.length
      ? this.settings.length + 1
      : 1;
    this.settings.push({ id: 0, key: '' });
  }

  onCancel() {
    this.dialog.open(ModalConfirmationComponent, {
      data: {
        text: `Вы действительно отменить изменения?`,
        okText: 'Да',
        onOk: () => { this.cancel(); }
      }
    });
  }

  cancel() {
    this.settings = JSON.parse(JSON.stringify(this.originalSettings));
    this.clearChanges();
  }

  onFocus(e: Event, setting: ISetting) {
    setting.keyFocused = true;
  }

  onBlur(e: any, setting: ISetting) {
    setting.keyFocused = false;
    setting.valueFocused = false;
    if (setting.id) {
      const originalCategory = this.originalSettings.find(s => s.id === setting.id);
      if (originalCategory.key !== setting.key
        || originalCategory.value !== setting.value) {
        const updated = this.changes.updated.find(s => s.id === setting.id);
        if (!updated) {
          this.changes.updated.push(setting);
        } else {
          updated.key = setting.key;
          updated.value = setting.value;
        }
      }
    } else {
      const added = this.changes.added.find(s => s.key === setting.key);
      if (added) {
        added.key = setting.key;
        added.value = setting.value;
      } else {
        this.changes.added.push(setting);
      }
    }
  }
}
