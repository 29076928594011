<ng-template #pageLink let-page>
  <a class="link" *ngIf="page.isLink" [attr.href]="pagesService.getPageUrl(page)"
    [attr.target]="page.inNewTab ? '_blank': '_self'">{{page.title}}</a>
  <a class="link" *ngIf="!page.isLink" [routerLink]="pagesService.getPageUrl(page)"
    [attr.target]="page.inNewTab ? '_blank': '_self'"
    (click)="pagesService.navigatePage($event, page)">{{page.title}}</a>
</ng-template>

<ng-template #pageTreeNodeContent let-d>
  <div class="draggable-line__content">
    <!-- btn-drag -->
    <button *ngIf="isAllowDrag()" type="button" class="btn btn-drag draggable-line__icon"></button>
    <button *ngIf="!isAllowDrag()" type="button" class="btn btn-drag draggable-line__icon" style="visibility: hidden"></button>
    <!-- btn-drag END -->
    <div class="level _{{d.level}}" [ngClass]="{'_inactive': !d.page.visible}">
      <ng-container *ngTemplateOutlet="pageLink; context: {$implicit: d.page}"></ng-container>
    </div>
    <div class="draggable-line__actions" *ngIf="config?.allowEdit || isShowMenu(d.page)">
      <button type="button" class="btn btn-content-draft _visible" *ngIf="!d.page.published"
        matTooltip="Черновик"></button>
      <button type="button" class="btn btn-content-can-view" *ngIf="d.page.visible"
        matTooltip="Просмотр элемента разрешен. Кликните, чтобы запретить" (click)="toggleVisible(d.page)"></button>
      <button type="button" class="btn btn-content-eye-hide _visible" *ngIf="!d.page.visible"
        matTooltip="Элемент скрыт с Портала. Кликните, чтобы разрешить просмотр"
        (click)="toggleVisible(d.page)"></button>
      <button type="button" class="btn" *ngIf="enableShowInWidget"
        [ngClass]="{'btn-bookmark-filled _visible': d.page.showInWidget, 'btn-bookmark': !d.page.showInWidget}"
        (click)="toggleShowInWidget(d.page)" [matTooltip]="getShowInWidgetTooltip(d.page)"></button>
      <!-- на десктопе два меню, на таче одно-->
      <div class="hidden-desktop hidden-tablet">
        <app-page-menu *ngIf="isShowMenu(d.page)" [item]="d.page" [config]="config" [showExternalLinks]="config?.allowEdit && d.level <= 5"
          (added)="onAdded($event)" (deleted)="onDeleted($event)"></app-page-menu>
      </div>
      <div class="hidden-phone">
        <app-pages-menu  *ngIf="d.level <= 5" [showAddButton]="config?.allowEdit" [parentId]="d.page.id" [groupId]="d.page.ownerId"
          (added)="onAdded($event)"></app-pages-menu>
        <app-page-menu *ngIf="isShowMenu(d.page)" [item]="d.page" [config]="config" (deleted)="onDeleted($event)"></app-page-menu>
      </div>
    </div>
  </div>
</ng-template>

<!-- дерево с драг/дропом -->
<tree-root #tree [state]="state" [options]="options" [focused]="true" [nodes]="pagesTree"
  (moveNode)="onMoveNode($event)">
  <ng-template #treeNodeWrapperTemplate let-node let-index="index">
    <div class="node-wrapper" [style.padding-left]="node.getNodePadding()">
      <tree-node-expander [node]="node"></tree-node-expander>
      <div class="node-content-wrapper form__line draggable-line" [class.node-content-wrapper-active]="node.isActive"
        [class.node-content-wrapper-focused]="node.isFocused" (click)="node.mouseAction('click', $event)"
        (dblclick)="node.mouseAction('dblClick', $event)" (contextmenu)="node.mouseAction('contextMenu', $event)"
        (treeDrop)="node.onDrop($event)" [treeAllowDrop]="node.allowDrop" [treeDrag]="node"
        [treeDragEnabled]="node.allowDrag()">
        <ng-container
          *ngTemplateOutlet="pageTreeNodeContent; context: {$implicit: {page:node.data, level:node.level?node.level+1:0}}">
        </ng-container>
      </div>
    </div>
  </ng-template>
</tree-root>
