<div class="container _moved _full-page">
  <div class="cos">
    <div class="user-header">
      <a href="javascript:;" class="link-profile hint">
        <span title="" class="avatar _30">
          <span class="avatar__image-wrap">
            <img src="{{assetsPrefix}}/assets/images/icons/calendar-white.svg" alt="" class="avatar__image">
          </span>
        </span>
        <span class="link-profile__text">Календарь</span>
      </a>
    </div>
    <div class="cos__header">
      <div class="cos__actions pull-right">
        <button type="button" class="btn btn-filter" [ngClass]="{'_active': filtersVisible}"
          (click)="filtersVisible=!filtersVisible"></button>
      </div>
      <app-calendar-tabs [selectedDate]="selectedDate" [viewType]="viewType" [tabs]="tabs" (prev)="onPrev($event)"
        (next)="onNext($event)" (init)="onInit($event)">
      </app-calendar-tabs>
      <div class="cos__filters" *ngIf="filtersVisible">
        <div class="calendar-filter">
          <div class="calendar-filter__title hidden-desktop"><span class="header-text _low _secondary"><span
                class="header-text__text">Фильтр по дням рождения:</span>&nbsp;<span
                class="header-text__count"></span></span>
          </div>
          <div class="calendar-filter__values">
            <label class="checkbox">
              <input type="radio" name="birthday-filter" class="checkbox__input" value="CalendarFilterType.colleagues"
                (change)="filterChanged(CalendarFilterType.colleagues)"
                [checked]="filterType===CalendarFilterType.colleagues">
              <span class="checkbox__icon"></span>
              <span class="checkbox__text">
                <span class='link'>Мои коллеги</span>
              </span>
            </label>
            <label class="checkbox">
              <input type="radio" name="birthday-filter" class="checkbox__input" value="CalendarFilterType.all"
                (change)="filterChanged(CalendarFilterType.all)" [checked]="filterType===CalendarFilterType.all">
              <span class="checkbox__icon"></span>
              <span class="checkbox__text">
                <span class='link'>Все</span>
              </span>
            </label>
            <label class="checkbox">
              <input type="radio" name="birthday-filter" class="checkbox__input" value="CalendarFilterType.departments"
                (change)="filterChanged(CalendarFilterType.departments)"
                [checked]="filterType===CalendarFilterType.departments">
              <span class="checkbox__icon"></span>
              <span class="checkbox__text">
                <span class='link'>Из подразделения</span>
              </span>
            </label>
            <div class="common-search">
              <span class="icon _search-grey"></span>
              <input [(ngModel)]="filterDepartment" (keyup)="onSearchDepartment($event)" type="text"
                placeholder="Введите название подразделения" class="main-input"
                (click)="filterChanged(CalendarFilterType.departments)">
              <div class="cos-add__suggest" [ngClass]="{'_visible': filterDepartmentVisible}" appClickOutside
                (clickOutside)="clearDepartmentsFilter()">
                <div *ngIf="filterDepartmentError">{{filterDepartmentError}}</div>
                <div class="co _one-line" *ngFor="let dep of foundDepartments" (click)="selectDepartment($event,dep)">
                  <div class="co__info">
                    <div class="link co__name">{{dep}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="currentTab">
      <ng-container *ngIf="currentTab.name==='monthView'">
        <div class="calendar">
          <div *ngFor="let dayOfWeek of CalendarHelper.daysOfWeek" [attr.data-text]="dayOfWeek"
            class="calendar__cell _header"></div>

          <div *ngFor="let calendarDay of calendarDays" class="calendar__cell _inactive" [ngClass]="{'_inactive': !isSelectedMonth(calendarDay),
            '_current': isCurrentDay(calendarDay),
            '_holiday': isHoliday(calendarDay)}">

            <a routerLink="/calendar" class="calendar__link"
              [fragment]="getDayFragment(calendarDay.year, calendarDay.month + 1, calendarDay.day)">{{calendarDay.day}}</a>

            <ng-container *ngIf="calendarDay.birthdays?.length && calendarDay.birthdays?.length > 6">
              <a routerLink="/calendar"
                [fragment]="getDayFragment(calendarDay.year, calendarDay.month + 1, calendarDay.day)"
                (mouseover)="calendarDay.mouseOver=true" [attr.data-count]="calendarDay.birthdays?.length"
                class="calendar__birthdays hint"></a>
              <div (mouseleave)="calendarDay.mouseOver=false" class="cos-add__suggest _calendar-users"
                *ngIf="calendarDay.mouseOver">
                <div class="co _one-line" *ngFor="let u of calendarDay.birthdays.slice(0, 6)" (click)="congratulate(u)">
                  <div class="co__avatar">
                    <app-user-avatar [user]="u" [linkClass]="'avatar _30'" [imageClass]="'avatar__image'"
                      [noRedirect]="true" [asSpan]="true"></app-user-avatar>
                  </div>
                  <div class="co__info">
                    <div class="link co__name">{{u.fullName}}</div>
                  </div>
                </div>
                <div class="text-secondary" *ngIf="calendarDay.birthdays.length > 6">
                  Eщё {{calendarDay.birthdays.length - 6}}
                </div>
                <div class="mouseover-help"></div>
              </div>
            </ng-container>

            <ng-container *ngIf="calendarDay.birthdays?.length && calendarDay.birthdays?.length < 7">
              <div [attr.data-count]="calendarDay.birthdays?.length" class="calendar__birthdays">
                <ng-container *ngFor="let user of calendarDay.birthdays">
                  <app-user-avatar [user]="user" [linkClass]="'avatar _30'" (click)="showCongratulate($event, user)"
                    [imageClass]="'avatar__image'" [asSpan]="true" [noRedirect]="true"></app-user-avatar>
                  <div class="cos-add__suggest _calendar-users _calendar-users-simple"
                    *ngIf="user && user.congratulate && currentUser?.id!==user?.id" appClickOutside
                    (clickOutside)="user.congratulate=false">
                    <div class="co _one-line">
                      <div class="co__info">
                        <button class="btn btn-primary" type="button" (click)="congratulate(user)">Поздравить</button>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="currentTab.name==='dayView'">
        <ng-container *ngIf="currentTab.loaded && currentTab.items?.length">
          <div class="cos__body _paddings">
            <span class="header-text _bigger _low _secondary">
              <span class="header-text__text">День рождения празднуют:</span>
            </span>
          </div>
          <div *ngFor="let user of currentTab?.items" class="co">
            <div class="co__avatar">
              <app-user-avatar [user]="user" [linkClass]="'avatar _80'" [imageClass]="'avatar__image'">
              </app-user-avatar>
            </div>
            <div class="co__info">
              <a href="#" class="link co__name" routerLink="/profile/{{user?.id}}"
                (click)="usersService.navigateProfile(user?.id)" [appUserTooltip]="user?.id"
                [innerHTML]="user?.fullName | highlight:text:true"></a>
              <div class="co__occ" *ngIf="user?.jobTitle">{{user?.jobTitle }}</div>
              <a class="co__occ" routerLink="/profile/{{currentUser?.id}}/search"
                fragment="k={{link?.user?.department}}" *ngIf="link?.user?.department">{{user?.department}}</a>
              <div class="co__phones" *ngIf="user?.mobilePhone || user?.workPhone">
                <a href="tel:{{user?.mobilePhone}}" *ngIf="user?.mobilePhone"
                  class="link co__phone">{{user?.mobilePhone}}</a>
                <a href="tel:{{user?.workPhone}}" *ngIf="user?.workPhone" class="link co__phone">{{user?.workPhone}}</a>
              </div>
            </div>
            <div class="co__action" *ngIf="currentUser?.id!==user?.id">
              <button class="btn btn-primary" type="button" (click)="congratulate(user)">Поздравить</button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="currentTab.loaded && !currentTab.items?.length">
          <div class="no-data"><img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" class="no-data__img">
            <div class="no-data__text">На выбранную дату нет именинников
            </div>
          </div>
        </ng-container>
      </ng-container>
      <div class="co" *ngIf="currentTab?.loading">
        <mat-spinner [diameter]="56"></mat-spinner>
      </div>
    </ng-container>
  </div>
</div>
