import { Component } from '@angular/core';
import { GroupInfo } from '@app/groups/model/group-info.model';
import { GroupFormType } from '@app/groups/model/group-form-type.model';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';

@Component({
    selector: 'app-group-new',
    templateUrl: 'group-new.component.html'
})

export class GroupNewComponent extends BaseComponent {
    group: GroupInfo;
    public groupFormType = GroupFormType;

    constructor(public helper: HelperService) { super(helper); }
}
