import { Component, OnDestroy } from '@angular/core';
import { ISurvey } from '@app/surveys/model/survey';
import { HelperService } from '@app/core/services/helper.service';
import { BaseComponent } from '@app/core/components/base.component';
import { Router } from '@angular/router';

/**
 * Компонент виджета опросов
 *
 * @export
 * @class SurveyWidgetComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-survey-widget',
  template: ''
})
export class SurveyWidgetComponent extends BaseComponent implements OnDestroy {

  /**
   * Массив опросов
   *
   * @type {ISurvey[]}
   * @memberof GroupSurveysWidgetComponent
   */
  surveys: ISurvey[];

  /**
   * Ссылка перехода в раздел опросов
   *
   * @memberof GroupSurveysWidgetComponent
   */
  link = '/surveys';

  /**
   * Отображать виджет (только для пользователей у которых есть права)
   *
   * @type {boolean}
   * @memberof GroupSurveysWidgetComponent
   */
  showWidget: boolean;

  /**
   * Отображать ссылку создания нового опроса
   *
   * @type {boolean}
   * @memberof SurveyWidgetComponent
   */
  showAddButton: boolean;

  /**
   * Ссылка создания нового опроса
   *
   * @type {string}
   * @memberof SurveyWidgetComponent
   */
  addButtonLink: string;

  constructor(
    protected router: Router,
    public helper: HelperService
  ) { super(helper); }

  navigate() {
    this.router.navigate([this.link]);
  }

  onDeleted(id: number) {
    this.surveys = this.surveys.filter(s => s.id !== id);
  }

  /**
   * Отображать заглушку?
   *
   * @returns {boolean}
   * @memberof GroupGalleryWidgetComponent
   */
  isShowEmpty(): boolean {
    return this.showWidget && this.loaded && (!this.surveys || !this.surveys.length);
  }
}
