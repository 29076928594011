<ng-container [ngSwitch]="type" *ngIf="isVisibleForbidden()">
  <ng-container *ngSwitchCase="GroupForbiddenType.cos">
    <div class="cos">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ng-container>
</ng-container>

<ng-template #content>
  <div class="group-empty">
    <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" class="group-empty__img">
    <div class="group-empty__text">Чтобы увидеть контент группы, вам необходимо
      <br /> вступить в нее или подписаться</div>
  </div>
</ng-template>
