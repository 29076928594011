import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GiftListComponent } from './components/gift-list/gift-list.component';
import { GiftNewComponent } from './components/forms/gift-new/gift-new.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { CoreModule } from '@app/core/core.module';
import { GiftApiService } from './services/gift-api.service';
import { GiftService } from './services/gift.service';
import { GiftListViewComponent } from './components/gift-list-view/gift-list-view.component';
import { GiftViewComponent } from './components/forms/gift-view/gift-view.component';
import { GiftEditComponent } from './components/forms/gift-edit/gift-edit.component';
import { GiftTilesViewComponent } from './components/gift-tiles-view/gift-tiles-view.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CoreModule,
    SharedModule,
    RouterModule,
  ],
  declarations: [
    GiftListComponent,
    GiftNewComponent,
    GiftListViewComponent,
    GiftViewComponent,
    GiftEditComponent,
    GiftTilesViewComponent,
  ],
  exports: [
    GiftListComponent,
    GiftNewComponent,
    GiftViewComponent,
  ],
  providers: [
    GiftApiService,
    GiftService,
  ]
})
export class GiftsModule { }
