<div class="container _full-page _moved _with-sidebar">

  <div class="sidebar">
    <div class="card">
      <div class="card__title">
        <div class="header-text _inactive"><span class="header-text__text">Участники</span></div>
      </div>
      <div class="card__content"></div>
    </div>
    <div class="card">
      <div class="card__title">
        <div class="header-text _inactive"><span class="header-text__text">Файлы</span></div>
      </div>
      <div class="card__content"></div>
    </div>
    <div class="card">
      <div class="card__title">
        <div class="header-text _inactive"><span class="header-text__text">Галерея</span></div>
      </div>
      <div class="card__content"></div>
    </div>
    <div class="card">
      <div class="card__title">
        <div class="header-text _inactive"><span class="header-text__text">Опросы</span></div>
      </div>
      <div class="card__content"></div>
    </div>
  </div>
  <div class="content">
    <app-group-card [formType]="groupFormType.new"></app-group-card>
    <div class="card">
      <div class="group-empty"><img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" class="group-empty__img">
        <div class="group-empty__text">После создания группы вы<br> сможете добавить коллег, файлы и публикации</div>
      </div>
    </div>
  </div>
</div>
