import { IntryAuthConfig } from '@app/auth/shared/models/intry-auth.config';
import { LogLevel } from 'angular-auth-oidc-client';

export const environment = {
  production: false,
  siteUrl: (<any>window)?.env?.api || 'https://api.intry.net',
  siteHost: (<any>window)?.env?.api || 'https://api.intry.net',
  username: null,
  password: null,
  assetsPrefix: '',
  startPage: '',
  auth: {
    clientId: 'intry_spa',
    scope: 'openid profile email offline_access intry_api',
    startCheckSession: false,
    allowExternal: false,
    showHeaderForExternal: false,
    logLevel: LogLevel.Warn,
    authority: (<any>window)?.env?.authority || 'https://auth.intry.net/realms/intry'
  } as IntryAuthConfig
};
