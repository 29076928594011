import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { FeedService } from '@app/feed/services/feed.service';
import { HelperService } from '@app/core/services/helper.service';
import { UsersService } from '@app/profile/services/users.service';
import { takeUntil, finalize } from 'rxjs/operators';
import { FeedEventViewModel } from '@app/feed/model/feed-event-view-model.model';
import { Helper } from '@app/core/helpers/helper';
import { GroupsService } from '@app/groups/services/groups.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { MediaFileType } from '@app/gallery/model/media-file';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { User } from '@app/profile/model/user.model';
import { AlertsService } from '@app/shared/services/alerts.service';
import { ElementType } from '@app/core/model/element-type.enum';

/**
 * Виджет бизнес-новостей на главной
 *
 * @export
 * @class FeedWidgetComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-feed-widget',
  templateUrl: './feed-widget.component.html',
  styleUrls: ['./feed-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedWidgetComponent
  extends BaseComponent
  implements OnInit, OnDestroy {
  bigPosts: Array<FeedEventViewModel> = [];
  posts: Array<FeedEventViewModel> = [];
  simplePosts: Array<FeedEventViewModel> = [];
  currentUser: User;

  ElementType = ElementType;

  constructor(
    protected router: Router,
    protected usersService: UsersService,
    protected groupsService: GroupsService,
    protected feedService: FeedService,
    protected sanitizer: DomSanitizer,
    protected alertsService: AlertsService,
    protected cdr: ChangeDetectorRef,
    public helper: HelperService
  ) {
    super(helper);
  }

  ngOnInit() {
    this.getBusinessPosts();
    this.usersService.currentUser.subscribe((currentUser) => {
      this.currentUser = currentUser;
    });
  }

  getAvatarImage(post: FeedEventViewModel) {
    return post.group.pictureUrl
      ? Helper.concatUrls((<any>window).signalRServer, post.group.pictureUrl)
      : null;
  }

  /**
   * Перейти в выбранную группу
   *
   * @param {*} e
   * @param {*} id
   */
  navigateGroup(e, id) {
    e.preventDefault();
    this.groupsService.navigateGroup(id);
  }

  /**
   * Отображать блок действий с постом
   */
  isShowActions(post: FeedEventViewModel) {
    return (
      post &&
      post.event &&
      (!post.event.disableComment || !post.event.disableLike)
    );
  }

  getBackgroundImage(post: FeedEventViewModel): SafeStyle {
    if (post) {
      if (
        !post.preview &&
        post.mediaAttachments &&
        post.mediaAttachments.length
      ) {
        const media = post.mediaAttachments[0].mediaAttachment;
        if (media.type === MediaFileType.image) {
          if (media.preview) {
            post.preview = media.preview;
          } else {
            post.preview = media.url;
          }
        } else if (media.type === MediaFileType.video) {
          post.preview = media.preview;
        }
      }
      if (post.preview) {
        let url = post.preview;
        if (
          environment.siteUrl &&
          !post.preview.startsWith('http://') &&
          !post.preview.startsWith('https://')
        ) {
          url = Helper.concatUrls(environment.siteUrl, url);
        }
        return this.sanitizer.bypassSecurityTrustStyle(
          `background-image: url('${url}')`
        );
      }
    }
    return '';
  }

  navigateToEdit(post: FeedEventViewModel) {
    this.router.navigate(
      [`/group/${post.event.ownerId}/post/${post.event.id}`],
      { fragment: 'edit' }
    );
  }

  allowEdit(post: FeedEventViewModel) {
    return post && post.group.adminState === 2;
  }

  allowDelete(post: FeedEventViewModel) {
    return (
      (post && post.group.adminState === 2) ||
      (this.currentUser && this.currentUser.isAdmin)
    );
  }

  cancelPin(post: FeedEventViewModel) {
    this.feedService
      .pin(post.event.id, false)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        if (res) {
          this.alertsService.riseSuccess('Пост был успешно изменён');
          this.reload();
        } else {
          this.alertsService.riseError('Произошла ошибка при изменении поста');
        }
      });
  }

  reload() {
    this.getBusinessPosts();
  }

  onRemove(post: FeedEventViewModel) {
    this.feedService.removePost(post.event.id).subscribe(() => {
      this.reload();
    });
  }

  private getBusinessPosts() {
    this.feedService
      .getBusinessNews()
      .pipe(
        finalize(() => {
          this.loaded = true;
          this.cdr.detectChanges();
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe((res) => {
        if (res) {
          // запиненные новости поднимаем наверх в массиве, чтобы отображать их в первой строке
          const sortedPostsByPin = res.sort((a, b) =>
            a.event.pin === b.event.pin ? 0 : a.event.pin ? -1 : 1
          );

          this.bigPosts = sortedPostsByPin.slice(0, 2);
          this.posts = sortedPostsByPin.slice(2, 4);
          this.simplePosts = sortedPostsByPin.slice(4, 6);
        } else {
          this.posts = this.bigPosts = this.simplePosts = [];
        }
        this.cdr.detectChanges();
      });
  }
}
