import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { UsersService } from '@app/profile/services/users.service';
import { User } from '@app/profile/model/user.model';
import { Tab } from '@app/shared/components/tabs/tab.model';
import { forkJoin, Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { GroupsService } from '@app/groups/services/groups.service';
import { UserLink } from '@app/subscribe/model/user-link';
import { SubscribeService } from '@app/subscribe/services/subscribe.service';
import { SearchService } from '@app/search/services/search.service';
import { TabbedListComponent } from '@app/shared/components/tabs/tabbed-list.component';
import { FeedService } from '@app/feed/services/feed.service';
import { FeedType } from '@app/feed/model/feed-type.model';
import { takeUntil, take, map, finalize } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { GalleryService } from '@app/gallery/services/gallery.service';
import { GallerySearchResult } from '@app/search/model/gallery-search-result.model';
import { IAlbum } from '@app/gallery/model/album-model';
import { IMediaFile } from '@app/gallery/model/media-file';
import { FeedEventViewModel } from '@app/feed/model/feed-event-view-model.model';
import { PagesService } from '@app/pages/services/pages.service';
import { SearchResultItem } from '@app/search/model/search-result-item.model';
import { FilesService } from '@app/files/services/files.service';

/**
 * Компонент результатов поиска
 *
 * @export
 * @class SearchResultsComponent
 * @extends {TabbedListComponent<any>}
 * @implements {AfterViewInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
})
export class SearchResultsComponent extends TabbedListComponent<any> implements AfterViewInit, OnDestroy {
  pageSize: 30;
  checkedText: string;
  user: User;
  // результаты поиска в текущем представлении
  searchItems: any;
  // search text
  text = '';
  lastText = '';
  highlightText = '';
  limit = 20;
  albumsLimit = 4;
  mediaFilesLimit = 10;

  // sorting
  sort: boolean;
  sortBy: string;
  sortTitle = 'По дате изменения';
  sortAsc = 'asc';
  sortField = 'Filename';

  enableSorting = false;

  constructor(
    protected usersService: UsersService,
    protected groupsService: GroupsService,
    protected subscribeService: SubscribeService,
    protected feedService: FeedService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected searchService: SearchService,
    protected galleryService: GalleryService,
    protected pagesService: PagesService,
    protected filesService: FilesService,
    public helper: HelperService,
  ) {
    super(router, route, helper);
  }

  ngAfterViewInit(): void {
    this.usersService.currentUser.subscribe(currentUser => {
      // set current
      this.user = currentUser;
      // init tabs
      this.tabs = {
        title: 'Поиск',
        routerLink: `/profile/${this.user.id}/search`,
        items: [
          {
            id: 1,
            title: 'Коллеги',
            fragment: 'colleagues',
            name: 'colleagues',
            items: [],
            offset: 0,
            itemsCount: 0,
            method: this.searchUsers.bind(this),
            default: true,
          },
          {
            id: 3,
            title: 'Группы',
            fragment: 'groups',
            name: 'groups',
            items: [],
            offset: 0,
            itemsCount: 0,
            method: this.searchGroups.bind(this),
          },
          {
            id: 4,
            title: 'Лента',
            fragment: 'feed',
            name: 'feed',
            items: [],
            offset: 0,
            itemsCount: 0,
            method: this.searchFeed.bind(this),
          },
          {
            id: 5,
            title: 'Галерея',
            fragment: 'gallery',
            name: 'gallery',
            items: [],
            offset: 0,
            itemsCount: 0,
            method: this.searchGallery.bind(this),
          },
          {
            id: 6,
            title: 'Страницы',
            fragment: 'pages',
            name: 'pages',
            items: [],
            offset: 0,
            itemsCount: 0,
            method: this.searchPage.bind(this),
          },
        ],
      };

      if (this.filesService.showUserFiles$.getValue()) {
        if (!this.tabs.items.find(s => s.name === 'files')) {
          this.tabs.items.splice(2, 0, {
            id: 2,
            title: 'Файлы',
            fragment: 'files',
            name: 'files',
            items: [],
            offset: 0,
            itemsCount: 0,
            method: this.searchFiles.bind(this),
            maxItemHeight: 50,
            limit: 30,
          });
        }
      }

      this.route.fragment.pipe(takeUntil(this.destroyed$)).subscribe(val => {
        // get hash
        this.lastText = this.text;

        if (val != null) {
          const keys = val.split('&');
          const hash = {};
          keys.forEach(key => {
            // tslint:disable-next-line:no-shadowed-variable
            const val = key.split('=');
            hash[val[0]] = val[1];
            if (val[0] === 'k') {
              this.text = decodeURIComponent(val[1]);
            }
          });

          this.tabs.items.forEach(tab => {
            if (tab.fragment && keys.find(k => k === tab.fragment)) {
              this.currentTab = tab;
            }
          });

          if (!this.currentTab) {
            this.currentTab = this.tabs.items[0];
          }
        } else {
          this.currentTab = this.tabs.items[0];
        }

        this.tabs.items.forEach(tab => {
          tab.additionalFragment = `&k=${this.text}`;
        });

        if (this.text) {
          if (this.currentTab && this.currentTab.id === 6 && !this.text.startsWith('#')) {
            this.highlightText = '#' + this.text;
          } else {
            this.highlightText = this.text;
          }

          if (this.text.length > 1) {
            // получить данные по всем табикам
            this.currentTab.items = [];
            this.currentTab.loaded = false;
            this.currentTab.loading = true;
            this.currentTab.method(this.currentTab, this);
          } else {
            this.currentTab.loaded = true;
            this.currentTab.loading = false;
            this.currentTab.items = [];
          }
        } else {
          this.currentTab.items = [];
        }
      });
    });
  }

  /**
   * Поиск по пользователям
   *
   * @param {Tab<any>} tab
   * @memberof SearchResultsComponent
   */
  searchUsers(tab: Tab<any>) {
    this.usersService
      .searchUsers(this.text, 0, tab.items.length, this.limit)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        res => {
          if (!tab.items) {
            tab.items = [];
          }

          if (res) {
            res.items.forEach(link => {
              if (!tab.items.filter(g => g.user.id === link.user.id).length) {
                tab.items.push(link);
              }
            });
          } else {
            tab.allLoaded = true;
          }

          tab.loaded = true;
          tab.loading = false;
        },
        error => {
          tab.loaded = true;
          tab.loading = false;
        },
      );
  }

  /**
   * Поиск по группам
   *
   * @param {Tab<any>} tab
   * @memberof SearchResultsComponent
   */
  searchGroups(tab: Tab<any>) {
    this.groupsService
      .searchGroups(this.text, tab.items.length, this.limit)
      .pipe(take(1), takeUntil(this.destroyed$))
      .subscribe(
        res => {
          if (!tab.items) {
            tab.items = [];
          }

          if (res) {
            res.groups.forEach(group => {
              if (!tab.items.filter(g => g.id === group.id).length) {
                tab.items.push(group);
              }
            });
            if (!res.count) {
              tab.items = [];
            }
            tab.itemsCount = res.count;
          } else {
            tab.itemsCount = 0;
            tab.items = [];
          }

          tab.loaded = true;
          tab.loading = false;
        },
        error => {
          tab.loaded = true;
          tab.loading = false;
        },
      );
  }

  /**
   * Поиск по ленте
   *
   * @param {Tab<any>} tab
   * @memberof SearchResultsComponent
   */
  searchFeed(tab: Tab<any>) {
    tab.loading = true;

    let promise: Observable<FeedEventViewModel[]> = null;

    if (this.text.startsWith('#')) {
      promise = this.feedService.searchTags(
        this.text,
        this.user.id,
        FeedType.UserFeedSearchTags,
        tab.items.length,
        this.limit,
      );
    } else {
      promise = this.feedService.search(
        this.text,
        this.user.id,
        FeedType.UserFeedSearchTags,
        tab.items.length,
        this.limit,
      );
    }

    promise
      .pipe(
        finalize(() => {
          tab.loaded = true;
          tab.loading = false;
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe(res => {
        this.operateFeedResult(tab, res);
      });
  }

  /**
   * Поиск по файлам
   *
   * @param {Tab<any>} tab
   * @memberof SearchResultsComponent
   */
  searchFiles(tab: Tab<SearchResultItem>) {
    tab.loading = true;
    this.searchService
      .search(this.text, tab.offset, tab.limit, this.sortAsc, this.sortField, null, this.enableSorting)
      .pipe(
        finalize(() => {
          tab.loaded = true;
          tab.loading = false;
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe(result => {
        if (!tab.items) {
          tab.items = [];
        }

        if (result && result.items) {
          result.items.forEach(item => {
            if (!tab.items.find(s => s.uniqueId === item.uniqueId)) {
              tab.items.push(item);
            }
          });

          tab.offset = tab.items.length;
          tab.itemsCount = tab.items.length + tab.limit;
        } else if (this.offset === 0) {
          tab.itemsCount = 0;
          tab.items = [];
        } else {
          tab.itemsCount = tab.items.length;
        }
      });
  }

  /**
   * Поиск по галереи
   *
   * @param {Tab<any>} tab
   * @memberof SearchResultsComponent
   */
  searchGallery(tab: Tab<any>) {
    tab.data = new GallerySearchResult();

    const getAlbums = this.galleryService
      .searchAlbums(
        this.text,
        null,
        tab.data && tab.data.albums && tab.data.albums.length ? tab.data.albums.length : 0,
        this.albumsLimit,
      )
      .pipe(take(1), takeUntil(this.destroyed$));

    const getMediafiles = this.galleryService
      .searchMediaFiles(
        this.text,
        0,
        tab.data && tab.data.mediaFiles && tab.data.mediaFiles.length ? tab.data.mediaFiles.length : 0,
        this.mediaFilesLimit,
      )
      .pipe(take(1), takeUntil(this.destroyed$));

    forkJoin(getAlbums, getMediafiles)
      .pipe(
        map(res => {
          return { albums: res[0], mediaFiles: res[1] };
        }),
      )
      .subscribe(
        res => {
          this.operateAlbumsResult(tab, res.albums);
          this.operateMediaFilesResult(tab, res.mediaFiles);

          tab.loaded = true;
          tab.loading = false;
        },
        error => {
          tab.loaded = true;
          tab.loading = false;
        },
      );
  }

  searchPage(tab: Tab<any>) {
    this.pagesService
      .search(this.text, 0, tab.offset, 100)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        res => {
          if (res) {
            res.forEach(page => {
              if (!tab.items.filter(g => g.id === page.id).length) {
                tab.items.push(page);
              }
            });
          }

          tab.itemsCount = tab.items.length + 100;
          tab.loaded = true;
          tab.loading = false;
        },
        error => {
          tab.loaded = true;
          tab.loading = false;
        },
      );
  }

  /**
   * Выполняем полисковый запрос с изменением offset при
   * действии при нажатии "показать ещё" в списке найденных альбомов
   *
   * @param IAlbum[] e текущий массив альбомов
   * @memberof SearchResultsComponent
   */
  onAlbumsShowMore(e: IAlbum[]) {
    this.galleryService
      .searchAlbums(
        this.text,
        null,
        this.currentTab.data && this.currentTab.data.albums && this.currentTab.data.albums.length
          ? this.currentTab.data.albums.length
          : 0,
        this.albumsLimit,
      )
      .pipe(take(1), takeUntil(this.destroyed$))
      .subscribe(
        res => {
          this.operateAlbumsResult(this.currentTab, res);
        },
        error => {
          this.loaded = true;
          this.loading = false;
        },
      );
  }

  /**
   * Скрываем все найденные альбомы кроме 4 первых
   *
   * @param IAlbum[] e текущий массив альбомов
   * @memberof SearchResultsComponent
   */
  onAlbumsHideMore(e: IAlbum[]) {
    this.currentTab.data.albums = e;
    this.currentTab.data.albumsLoaded = false;
    this.offset = this.currentTab.data.albumsCount = e ? e.length : 0;
  }

  /**
   * Сортировать вкладку поиска
   *
   * @param {*} e
   * @memberof SearchResultsComponent
   */
  sortSearchTab(e) {
    this.sort = e.sort;
    this.sortAsc = e.sortAsc;
    this.sortBy = e.sortBy;
    this.sortField = e.sortField;
    this.sortTitle = e.sortTitle;

    this.enableSorting = true;

    this.currentTab.loaded = false;
    this.currentTab.items = [];
    this.currentTab.itemsCount = 0;
    this.currentTab.offset = 0;
    this.currentTab.method(this.currentTab, this);
  }

  /**
   * Подписаться на пользователя
   *
   * @param {UserLink} link
   * @memberof SearchResultsComponent
   */
  subscribe(link: UserLink) {
    if (this.subscribeService.isSubscribed(link)) {
      this.subscribeService
        .unsubscribe(link.user.id)
        .pipe(take(1))
        .subscribe(
          res => {
            link.canSubscribe = false;
            link.unsubscribed = true;
            console.log('successfully updated subscription');
          },
          error => {
            this.error = error;
          },
        );
    } else {
      this.subscribeService
        .subscribe(link.user.id)
        .pipe(take(1))
        .subscribe(
          res => {
            link.canSubscribe = true;
            link.unsubscribed = false;
            console.log('successfully updated subscription');
          },
          error => {
            this.error = error;
          },
        );
    }
  }

  /**
   * Отобразить блок "пустой" выдачи
   *
   * @returns
   * @memberof SearchResultsComponent
   */
  showEmptyBlock() {
    return (
      this.currentTab &&
      this.currentTab.loaded &&
      ((this.currentTab.name !== 'gallery' && (!this.currentTab.items || !this.currentTab.items.length)) ||
        (this.currentTab.name === 'gallery' &&
          (!this.currentTab.data || !this.currentTab.data.albums || !this.currentTab.data.albums.length))) &&
      this.text &&
      this.text.length > 1
    );
  }

  protected onScroll() {
    const number = window.innerHeight + window.pageYOffset + 20;

    if (!this.currentTab) {
      return;
    }

    if (this.currentTab.id === 5) {
      if (
        this.currentTab.data &&
        this.currentTab.data.mediaFiles &&
        number > this.maxItemHeight * this.currentTab.data.mediaFiles.length
      ) {
        //  console.log('prepare for upload');
        if (!this.currentTab.data.mediaFilesLoading) {
          //  console.log('check for upload');
          if (!this.currentTab.data.mediaFilesLoaded) {
            //  console.log('uploading');
            this.currentTab.data.mediaFilesLoading = true;
            this.galleryService
              .searchMediaFiles(
                this.text,
                0,
                this.currentTab.data && this.currentTab.data.mediaFiles && this.currentTab.data.mediaFiles.length
                  ? this.currentTab.data.mediaFiles.length
                  : 0,
                this.mediaFilesLimit,
              )
              .pipe(take(1), takeUntil(this.destroyed$))
              .subscribe(
                res => {
                  this.operateMediaFilesResult(this.currentTab, res);
                },
                error => {
                  this.loaded = true;
                  this.loading = false;
                },
              );
          }
        }
      }
    } else {
      if (
        this.currentTab &&
        this.currentTab.items &&
        number > this.getMaxItemHeight(this.currentTab) * this.currentTab.items.length
      ) {
        //  console.log('prepare for upload');
        if (!this.currentTab.loading) {
          //  console.log('check for upload');
          if (this.currentTab.itemsCount > this.currentTab.items.length) {
            //  console.log('uploading');
            this.currentTab.method(this.currentTab, this);
          }
        }
      }
    }
  }

  private getMaxItemHeight(tab: Tab<any>): number {
    return tab && tab.maxItemHeight ? tab.maxItemHeight : 100;
  }

  protected operateFeedResult(tab: Tab<any>, posts: FeedEventViewModel[]) {
    if (!tab.items) {
      tab.items = [];
    }

    if (posts) {
      posts.forEach(post => {
        if (!tab.items.filter(g => g.event.id === post.event.id).length) {
          tab.items.push(post);
        }
      });

      tab.itemsCount = tab.items.length + this.limit;
    } else if (this.offset === 0) {
      tab.itemsCount = 0;
      tab.items = [];
    } else {
      tab.itemsCount = tab.items.length;
    }

    this.offset = tab.items.length;
  }

  protected operateAlbumsResult(tab: Tab<any>, albums: IAlbum[]) {
    if (!tab.data) {
      tab.data = new GallerySearchResult();
    }

    if (albums && albums.length) {
      albums.forEach(album => {
        if (!tab.data.albums.filter(g => g.id === album.id).length) {
          tab.data.albums.push(album);
        }
      });

      tab.data.albumsLoaded = albums.length < this.albumsLimit;
    } else {
      tab.data.albumsCount = tab.data.albums.length;
      tab.data.albumsLoaded = true;
    }
  }

  protected operateMediaFilesResult(tab: Tab<any>, mediaFiles: IMediaFile[]) {
    if (!tab.data) {
      tab.data = new GallerySearchResult();
    }

    if (mediaFiles && mediaFiles.length) {
      mediaFiles.forEach(mediaFile => {
        if (!tab.data.mediaFiles.filter(g => g.id === mediaFile.id).length) {
          tab.data.mediaFiles.push(mediaFile);
        }
      });

      tab.data.mediaFilesLoaded = mediaFiles.length < this.mediaFilesLimit;
    } else {
      tab.data.mediaFilesCount = tab.data.mediaFiles.length;
      tab.data.mediaFilesLoaded = true;
    }
    tab.data.mediaFilesLoading = false;
  }

  isVisible(tabName: string): boolean {
    return (
      this.currentTab &&
      this.currentTab.name === tabName &&
      this.currentTab.loaded &&
      ((this.currentTab.data && this.currentTab.data.albums && this.currentTab.data.albums.length) ||
        (this.currentTab.items && this.currentTab.items.length)) &&
      this.text &&
      this.text.length > 1
    );
  }
}
