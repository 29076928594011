import { Injectable, EventEmitter } from '@angular/core';
import { FeedEventViewModel } from '@app/feed/model/feed-event-view-model.model';
import { HubConnection } from '@microsoft/signalr';

@Injectable()
export class FeedHub {
    public onNewUserPost: EventEmitter<FeedEventViewModel> = new EventEmitter<FeedEventViewModel>();
    public onNewGroupPost: EventEmitter<FeedEventViewModel> = new EventEmitter<FeedEventViewModel>();
    public onPostEdited: EventEmitter<FeedEventViewModel> = new EventEmitter<FeedEventViewModel>();
    public onPostRemoved: EventEmitter<number> = new EventEmitter<number>();

    // register all client functions
    registerOnServerEvents(hubConnection: HubConnection): void {
        // feed
        hubConnection.on('onNewUserPost', (data: FeedEventViewModel) => {
            this.onNewUserPost.emit(data);
        });
        hubConnection.on('onNewGroupPost', (data: FeedEventViewModel) => {
            this.onNewGroupPost.emit(data);
        });
        hubConnection.on('onPostEdited', (data: FeedEventViewModel) => {
            this.onPostEdited.emit(data);
        });
        hubConnection.on('onPostRemoved', (data: number) => {
            this.onPostRemoved.emit(data);
        });
    }
}
