import { Injectable, Inject, InjectionToken } from '@angular/core';
import { EnvironmentSettings } from '../model/environment-settings';
import { BehaviorSubject, Subject } from 'rxjs';

export const ENV_SETTINGS = new InjectionToken<EnvironmentSettings>(null);

@Injectable()
export class HelperService {

    hideMenu$: Subject<void> = new Subject();
    errorText$: BehaviorSubject<void> = new BehaviorSubject(null);

    constructor(@Inject(ENV_SETTINGS) public environment: EnvironmentSettings) {

    }

    getSiteUrl(): string {
        return this.environment.siteUrl;
    }

    getSiteHostUrl(): string {
        return (<any>window).Intry.siteHost;
    }
}
