import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IIntryProfile } from '@app/shared/model/intry-profile';
import { ProfileType } from '@app/core/model/profile-type.enum';
import { GroupsService } from '@app/groups/services/groups.service';
import { UsersService } from '@app/profile/services/users.service';
import { GroupInfo } from '@app/groups/model/group-info.model';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-profile-selector',
  templateUrl: './profile-selector.component.html',
  styleUrls: ['./profile-selector.component.scss']
})
export class ProfileSelectorComponent implements OnChanges {

  @Input() selectedProfileId: number;
  @Input() required: boolean;
  @Input() placeholder: string;
  @Output() profileSelected: EventEmitter<IIntryProfile> = new EventEmitter();

  profiles: IIntryProfile[] = [];
  profileType = ProfileType;

  loaded: boolean;
  fullLoaded: boolean;

  show: boolean;
  selectedProfile: IIntryProfile;

  private limit = 20;
  private offset = 0;

  constructor(
    protected groupsService: GroupsService,
    public usersService: UsersService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedProfileId']) {
      if (this.selectedProfileId) {
        if (!this.loaded) {
          this.getUserGroups();
        }
      }
    }
  }

  trackBy(index: number, item: IIntryProfile) {
    return item != null ? item.profile.id : index;
  }

  onProfileSelected(profile: IIntryProfile) {
    this.selectedProfile = profile;
    if (this.selectedProfile.profileType === ProfileType.Group) {
      this.groupsService.selectedGroup.next(this.selectedProfile.profile as GroupInfo);
    } else {
      this.groupsService.selectedGroup.next(null);
    }
    this.profileSelected.emit(this.selectedProfile);
    this.show = false;
  }

  chooseProfile() {
    this.show = !this.show;
    if (!this.loaded) {
      this.getUserGroups();
    }
  }

  scrollCallback() {
    this.getUserGroups();
  }

  /**
   * Получение групп пользователя
   *
   * @private
   * @memberof PostProfileSelectorComponent
   */
  private getUserGroups(): void {
    if (this.fullLoaded) {
      return;
    }
    this.usersService.currentUser.subscribe(currentUser => {
      this.groupsService.getUserGroupsCanCreateSurveys(currentUser.id, this.offset, this.limit)
        .pipe(take(1))
        .subscribe(res => {
          if (res) {
            res.groups.forEach(group => {
              if (!this.profiles.find(p => p.profileType === ProfileType.Group && p.profile && p.profile.id === group.id)) {
                const profile = { profileType: ProfileType.Group, profile: group };
                this.profiles.push(profile);
                if (this.selectedProfileId && this.selectedProfileId === group.id) {
                  this.selectedProfile = profile;
                  this.profileSelected.emit(this.selectedProfile);
                }
              }
            });
            // сдвигаем выборку
            this.offset = this.offset + res.groups.length;
          } else {
            this.fullLoaded = true;
          }

          this.loaded = true;
        }, error => {
          this.loaded = true;
          console.log(`error=${error}`);
        });
    });
  }
}
