import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Inject,
  ViewChild,
  forwardRef,
  ChangeDetectorRef,
  NgZone,
} from '@angular/core';
import {BaseComponent} from '@app/core/components/base.component';
import {IMediaFile, MediaFileType} from '@app/gallery/model/media-file';
import {Subject} from 'rxjs';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ProfileType} from '@app/core/model/profile-type.enum';
import {GalleryService} from '@app/gallery/services/gallery.service';
import {AlertsService} from '@app/shared/services/alerts.service';
import {AlertType} from '@app/shared/components/alerts/alert';
import {UsersService} from '@app/profile/services/users.service';
import {ElementType} from '@app/core/model/element-type.enum';
import {CommentsListComponent} from '@app/comments/components/comments-list/comments-list.component';
import {CommentsService} from '@app/comments/services/comments.service';
import {CommentViewModel} from '@app/comments/model/comment-view-model.model';
import {finalize, takeUntil} from 'rxjs/operators';
import {HelperService} from '@app/core/services/helper.service';
import {CommentNewComponent} from '@app/comments/components/comment-new/comment-new.component';
import {User} from '@app/profile/model/user.model';

/**
 * Всплывающее окно медиа-файла
 *
 * @export
 * @class MediaFileViewComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-media-file-view',
  templateUrl: './media-file-view.component.html',
  styleUrls: ['./media-file-view.component.scss'],
})
export class MediaFileViewComponent extends BaseComponent implements OnInit, OnDestroy {
  /**
   * Текущий медиа-файл
   *
   * @type {IMediaFile}
   * @memberof MediaFileViewComponent
   */
  mediaFile: IMediaFile;

  /**
   * Массив всех медиа-файлов для слайдера
   *
   * @type {IMediaFile[]}
   * @memberof MediaFileViewComponent
   */
  mediaFiles: IMediaFile[];
  filesCountInBlock: number;
  filesCountInBlockLoaded: boolean;
  mediaFileType = MediaFileType;
  elementType = ElementType.Media;

  /**
   * Список комментариев медиа-файла
   *
   * @type {CommentViewModel[]}
   * @memberof MediaFileViewComponent
   */
  comments: CommentViewModel[];

  /**
   * Конфигурация слайдера-карусели
   *
   * @memberof MediaFileViewComponent
   */
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    initialSlide: 0,
  };

  /**
   * Список комментариев
   *
   * @protected
   * @type {CommentsListComponent}
   * @memberof MediaFileViewComponent
   */
  @ViewChild(forwardRef(() => CommentsListComponent)) protected commentsList: CommentsListComponent;

  /**
   * Новый комментарий
   *
   * @protected
   * @type {CommentNewComponent}
   * @memberof MediaFileViewComponent
   */
  @ViewChild(forwardRef(() => CommentNewComponent)) protected commentNew: CommentNewComponent;

  constructor(
    public dialogRef: MatDialogRef<MediaFileViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected alertsService: AlertsService,
    public usersService: UsersService,
    private commentsService: CommentsService,
    public galleryService: GalleryService,
    public cdr: ChangeDetectorRef,
    public helper: HelperService,
  ) {
    super(helper);

    // Если массив медиа-файлов пустой (т.е. в блоке есть только 1 файл),
    // то вносим его в массив.
    if (!data.items || !data.items.length) {
      if (data.item) {
        data.items = [data.item];
      }
    }

    // указываем текущий медиа-файл и массив медиа-файлов
    if (data.items) {
      this.mediaFiles = data.items;
      if (data.items && data.items.length && data.item) {
        if (data.items[0].id !== data.item.id) {
          this.mediaFile = data.items.find(s => s.id === data.item.id);
          this.slideConfig.initialSlide = this.mediaFiles.indexOf(this.mediaFile);
        } else {
          this.mediaFile = data.item;
        }
      }
    }

    if (data.filesCountInBlock) {
      this.filesCountInBlock = data.filesCountInBlock;
    }
  }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.comments = [];
      this.getComments(this.mediaFile);

      // если пользователь нажал на "Ответить", то открываем всплывашку и делаем фокус на новый коммент
      if (this.data.reply) {
        this.commentNew.activateNoParent(currentUser);
      }

      // автор может удалять комментарии фотографии
      if (!this.data.isAdmin) {
        this.data.isAdmin = this.data.item && this.data.item.author && this.data.item.author.id === currentUser.id;
      }

      if (this.data.filesCountInBlock > 4) {
        this.getLastMediaFilesBlock(currentUser);
      }
    });
  }

  getLastMediaFilesBlock(currentUser: User) {
    if (!this.filesCountInBlockLoaded) {
      this.galleryService
        .getLastMediaFilesBlock(currentUser.id, this.mediaFile.ownerId, this.mediaFile.created, this.mediaFiles.length, 10)
        .subscribe(mediaFiles => {
          if (mediaFiles && mediaFiles.length) {
            mediaFiles.forEach(mediaFile => {
              if (!this.mediaFiles.find(s => s.id === mediaFile.id)) {
                this.mediaFiles.push(mediaFile);
              }
            });
          } else {
            this.filesCountInBlockLoaded = true;
          }
        });
    }
  }

  /**
   * Получить заголовок медиа-файла. Если заголовка нет, то возвращается название медиа-файла
   *
   * @param {IMediaFile} mediaFile
   * @returns
   * @memberof MediaFileViewComponent
   */
  getMediaFileTitle(mediaFile: IMediaFile) {
    if (mediaFile && mediaFile.title) {
      return mediaFile.title;
    }
    return '';
  }

  /**
   * Получить ссылку на альбом медиа-файла
   *
   * @param {IMediaFile} mediaFile
   * @memberof MediaFileViewComponent
   */
  getAlbumLink(mediaFile: IMediaFile) {
    if (mediaFile && mediaFile.album) {
      if (mediaFile.albumLink === undefined) {
        if (mediaFile.ownerType === ProfileType.User) {
          mediaFile.albumLink = `/profile`;
        } else {
          mediaFile.albumLink = `/group`;
        }
        mediaFile.albumLink += `/${mediaFile.ownerId}/gallery/${mediaFile.album.id}`;
        return mediaFile.albumLink;
      } else {
        return mediaFile.albumLink;
      }
    }
    return '';
  }

  /**
   * Переход на страницу альбома
   *
   * @param {IMediaFile} mediaFile
   * @memberof MediaFileViewComponent
   */
  navigateAlbum(mediaFile: IMediaFile) {
    if (mediaFile && mediaFile.album && mediaFile.album.id && mediaFile.ownerId) {
      this.dialogRef.close();
      this.galleryService.navigateAlbum(mediaFile.album.id, mediaFile.ownerId, mediaFile.ownerType);
    } else {
      this.alertsService.alert.next({
        text: 'Невозможно перейти по ссылке на альбом. Обратитесь к администратору.',
        type: AlertType.Error,
        delayHide: 5000,
      });
    }
  }

  /**
   * Получить название альбома, в котором находится медиа-файл
   *
   * @param {IMediaFile} mediaFile
   * @returns
   * @memberof MediaFileViewComponent
   */
  getAlbumTitle(mediaFile: IMediaFile) {
    if (mediaFile && mediaFile.album && mediaFile.album.title) {
      return mediaFile.album.title;
    } else {
      return '';
    }
  }

  /**
   * Получить список комментариев медиа-файла
   *
   * @param {number} id ИД медиа-файла
   * @memberof MediaFileViewComponent
   */
  getComments(mediaFile: IMediaFile): void {
    if (this.mediaFile) {
      this.commentsService
        .getComments(mediaFile.id, ElementType.Media, 0, 2)
        .pipe(finalize(() => this.cdr.detectChanges()), takeUntil(this.destroyed$))
        .subscribe(
          res => {
            if (res && res.comments) {
              if (!this.comments) {
                this.comments = [];
              }

              res.comments.forEach(comment => {
                if (!this.comments.find(s => s.comment.id === comment.comment.id)) {
                  this.comments.unshift(comment);
                }
              });
            }
          },
          error => {
            console.error(`error on getting comments for mediafiles`);
          },
        );
    } else {
      console.error(`there is no mediafile for comments`);
    }
  }

  /**
   * После изменения текущего элемента слайда
   *
   * @param {*} e
   * @memberof MediaFileViewComponent
   */
  onAfterChange(e: any): void {
    const currentSlide: number = e.currentSlide;

    // загружаем дополнительные слайды
    if (
      this.filesCountInBlock > 20 &&
      this.mediaFiles.length < this.filesCountInBlock &&
      currentSlide > this.mediaFiles.length - 2
    ) {
      this.usersService.currentUser.subscribe(currentUser => {
        this.getLastMediaFilesBlock(currentUser);
      });
    }

    if (this.mediaFiles && this.mediaFiles.length && this.mediaFiles.length >= currentSlide) {
      // append current media file
      this.mediaFile = this.mediaFiles[currentSlide];
      // get comments
      this.comments = [];
      this.getComments(this.mediaFile);
    } else {
      console.error(`problems with current media file`);
      this.mediaFile = null;
      this.comments = null;
    }
  }

  /**
   * Событие при добавлении нового комментария
   *
   * @param {CommentViewModel} comment
   * @memberof PostComponent
   */
  onNewCommentAdded(comment: any) {
    this.commentsList.onCommentAdded(comment);
  }

  /**
   * Комментарий был добавлен. Обновление счётчиков.
   *
   * @param {number} count
   * @memberof PostComponent
   */
  onCommentAdded(count: number) {
    this.mediaFile.commentsCount = count;
  }

  /**
   * Комментарий был удалён. Обновление счётчиков.
   *
   * @param {number} count
   * @memberof PostComponent
   */
  onCommentDeleted(count: number) {
    this.mediaFile.commentsCount = count;
  }

  ngOnDestroy(): void {
    // чтобы не перезагружать заново
    if (this.mediaFiles) {
      this.data.items = this.mediaFiles;
    }
    super.ngOnDestroy();
  }
}
