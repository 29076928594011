import { Component } from '@angular/core';
import { GalleryComponent } from '@app/gallery/components/gallery-main/gallery.component';

@Component({
  selector: 'app-group-gallery-main',
  templateUrl: './group-gallery-main.component.html',
  styleUrls: ['./group-gallery-main.component.scss']
})
export class GroupGalleryMainComponent extends GalleryComponent {

  constructor() { super(); }

}
