import { Injectable } from '@angular/core';
import { SurveysApiService } from './surveys-api.service';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '@app/core/services/helper.service';
import { INewSurvey, ISurvey } from '../model/survey';
import { environment } from '@env/environment';
import { ISurveyMenuConfig } from '../components/survey-menu/survey-menu.component';
import { BehaviorSubject, Subject } from 'rxjs';

/**
 * Сервис опросов
 *
 * @export
 * @class SurveysService
 * @extends {SurveysApiService}
 */
@Injectable({
  providedIn: 'root'
})
export class SurveysService extends SurveysApiService {

  surveyAdded$: Subject<void> = new Subject();
  surveyChanged$: Subject<ISurvey> = new Subject();
  surveyDeleted$: Subject<number> = new Subject();

  copySurvey$: BehaviorSubject<ISurvey> = new BehaviorSubject(null);

  private defaultFullMenu: ISurveyMenuConfig = {
    allowEdit: true,
    allowDelete: true,
    allowResults: true
  };

  constructor(
    protected http: HttpClient,
    public helper: HelperService) { super(http, helper); }

  getStatusImageUrl(survey: ISurvey): string {
    if (survey) {
      if (survey.published) {
        if (survey.complete) {
          return environment.assetsPrefix + '/assets/images/polls/poll-done.svg';
        } else if (new Date(survey.dateEnd) > new Date()) {
          return environment.assetsPrefix + '/assets/images/polls/poll.svg';
        } else {
          return environment.assetsPrefix + '/assets/images/polls/poll-completed.svg';
        }
      } else {
        return environment.assetsPrefix + '/assets/images/polls/poll-draft.svg';
      }
    }
    return '';
  }

  getMenuConfig(survey: ISurvey) {
    if (survey.userAdmin) {
      return this.defaultFullMenu;
    }
  }
}
