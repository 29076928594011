export const SLIDES = {
    index: [
        {
            active: true,
            bg: 0,
            slide: 0,
        },
        {
            active: false,
            bg: 1,
            slide: 1,
        },
        {
            active: false,
            bg: 2,
            slide: 2,
        },
        {
            active: false,
            bg: 2,
            slide: 3,
            top1200: 362,
            top1600: 340,
        },
        {
            active: false,
            bg: 2,
            slide: 4,
            top1200: 613,
            top1600: 589,
        },
        {
            active: false,
            bg: 2,
            slide: 5,
            top1200: 923,
            top1600: 860,
        },

    ],
    profile: [
        {
            active: true,
            bg: 0,
            slide: 0,
        },
        {
            active: false,
            bg: 0,
            slide: 1,
            top1200: 50,
            top1600: 62,
        },
    ],
    group: [
        {
            active: true,
            bg: 0,
            slide: 0,
        },
        {
            active: false,
            bg: 0,
            slide: 1,
        },
        {
            active: false,
            bg: 0,
            slide: 2,
        },
    ],
};
