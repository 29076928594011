import { Directive, ElementRef, Input, HostListener } from '@angular/core';
import { UsersService } from '@app/profile/services/users.service';
import { TooltipHelper } from '@app/core/helpers/tooltip.helper';
import { take, shareReplay } from 'rxjs/operators';

@Directive({
  selector: '[appUserTooltip]'
})
export class UserTooltipDirective {

  @Input('appUserTooltip') userId: number;
  @Input() noTooltip: boolean;

  private timerId: any;

  constructor(
    private element: ElementRef,
    private usersService: UsersService,
  ) { }


  @HostListener('mouseover', ['$event']) onMouseOver(e: MouseEvent) {
    if (!this.noTooltip) {
      const that = this;
      this.timerId = setTimeout(() => {
        if (that.userId) {
          const user = that.usersService.users.find(s => s.id === that.userId);
          if (!user) {
            // получить данные по пользователю
            that.usersService.getUser(that.userId).pipe(take(1), shareReplay(1))
              .subscribe(res => {
                if (res) {
                  if (!that.usersService.users.find(s => s.id === that.userId)) {
                    that.usersService.users.push(res);
                  }
                  TooltipHelper.showUserTooltip(e, res);
                }
              });
          } else {
            TooltipHelper.showUserTooltip(e, user);
          }
        }
      }, 100);
    }
  }

  @HostListener('mouseout', ['$event']) onMouseOut(e: MouseEvent) {
    clearTimeout(this.timerId);
    this.timerId = null;
    TooltipHelper.hideUserTooltip(e);
  }

}
