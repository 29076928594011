import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  AfterViewChecked,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import {User} from '@app/profile/model/user.model';
import {UsersService} from '@app/profile/services/users.service';
import {HighlightPipe} from '@app/shared/pipes/highlight.pipe';
import {TooltipHelper} from '@app/core/helpers/tooltip.helper';
import {Router} from '@angular/router';
import {environment} from '@env/environment';
import {Helper} from '@app/core/helpers/helper';
import {BaseComponent} from '@app/core/components/base.component';
import {HelperService} from '@app/core/services/helper.service';

/**
 * Компонент отображения текста wysiwyg редактора
 *
 * @export
 * @class MessageComponent
 * @implements {AfterViewInit} добавляем глобальный метод
 * @implements {OnChanges} отслеживаем изменения для упоминаний
 */
@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
})
export class MessageComponent extends BaseComponent implements AfterViewInit, AfterViewChecked, OnChanges {
  /**
   * Сообщение слишком длинное и нужно показать кнопку "Читать дальше"
   *
   * @type {boolean}
   * @memberof MessageComponent
   */
  showButton: boolean;
  heightIsChecked: boolean;

  @Input() text: string;
  @Input() mentions: User[] = [];
  @Input() highlightText: string;
  @Input() showBody: boolean;
  @Input() mentionToCard: boolean;

  @ViewChild('editor', {static: true}) editor: ElementRef;

  html = '';

  editorElement: any;

  user: User;

  constructor(
    private usersService: UsersService,
    private highlightPipe: HighlightPipe,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public helper: HelperService,
  ) {
    super(helper);
  }

  /**
   * В window добавлен метод navigateProfile для перехода по ссылке
   *
   * @memberof MessageComponent
   */
  ngAfterViewInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      const win = <any>window;
      const that = this;

      // глобальный метод для перехода на чужой профиль
      if (!win.navigateProfile) {
        win.navigateProfile = function (e, userId) {
          e.preventDefault();
          that.usersService.navigateProfile(userId);
        };
      }

      if (!win.navigateSearchTag) {
        win.navigateSearchTag = function (e, tag) {
          e.preventDefault();
          that.router.navigate([`profile`, currentUser.id, 'search'], {fragment: `feed&k=${tag}`});
        };
      }

      // todo: сделать красивее
      if (!win.Intry.showTooltip) {
        win.Intry.showTooltip = function (e, userId) {
          e.preventDefault();
          if (that.usersService.users) {
            const user = that.usersService.users.find(s => s.id === userId);
            TooltipHelper.showUserTooltip(e, user);
          }
        };
      }

      if (!win.Intry.hideTooltip) {
        win.Intry.hideTooltip = function (e) {
          e.preventDefault();
          TooltipHelper.hideUserTooltip(e);
        };
      }

      // console.log(this.text, this.html);
      this.html = this.getHtml(currentUser);
      // console.log(this.text, this.html);
    });

    this.editorElement = this.editor.nativeElement;
    this.cdr.detectChanges();
  }

  ngAfterViewChecked() {
    if (this.showBody) {
      return;
    }

    if (this.heightIsChecked) {
      return;
    }

    if (this.editorElement.querySelectorAll('iframe').length) {
      return;
    }

    const editorHeight = Math.max(this.editorElement.scrollHeight, this.editorElement.offsetHeight);
    const MAX_EDITOR_HEIGHT = 162;

    if (editorHeight > MAX_EDITOR_HEIGHT) {
      this.showButton = true;

      this.heightIsChecked = true;
    }
    this.cdr.detectChanges();
  }

  showFullText() {
    this.showButton = false;
  }

  /**
   * Отслеживаем Input параметры и обновляем html если необходимо
   *
   * @param {SimpleChanges} changes
   * @memberof MessageComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['mentions']) {
      if (this.mentions && this.mentions.length) {
        this.usersService.currentUser.subscribe(currentUser => {
          this.html = this.getHtml(currentUser);
        });
        this.cdr.detectChanges();
      }
    }
  }

  /**
   * Получение html-разметки и добавление её в контейнер
   *
   * @private
   * @memberof MessageComponent
   */
  private getHtml(currentUser: User) {
    // this.directiveRef.clear();
    let result = '';

    // operate mentions
    if (this.text && this.text !== '' && this.text !== 'undefined') {
      let html = this.text.slice();
      html = html
        .replace(/&nbsp;/g, ' ')
        .replace(/\r\n/gi, '<br/>')
        .replace(/{portalUrl}/gi, this.helper.getSiteHostUrl());

      // for search results
      if (this.highlightText) {
        html = this.highlightPipe.transform(html, this.highlightText);
      }

      // check mentions
      result = this.getMentions(html, result);

      return result;
    }
  }

  /**
   * Преобразовать отображение для упоминаний
   *
   * @private
   * @param {string} html Исходная html-разметка
   * @param {string} result Результат
   * @returns {string}
   * @memberof MessageComponent
   */
  private getMentions(html: string, result: string): string {
    // check mentions
    const patt = /(@+id([\d]+))|(<a (.*)href="\/profile\/\d+".*<\/a>)/g;
    const mentions = html.match(patt);

    if (mentions && mentions.length > 0) {
      let match;
      const indexes = [];
      // save start & end indexes for all matches
      while ((match = patt.exec(html))) {
        indexes.push({start: match.index, end: patt.lastIndex});
      }

      // replace matches
      for (let i = 0; i < indexes.length; i++) {
        const start = indexes[i].start;
        const end = indexes[i].end;
        let text = '';
        if (i === 0) {
          text = html.substr(0, start);
        } else {
          const lastIndex = indexes[i - 1].end;
          text = html.substr(lastIndex, start - lastIndex);
        }

        result += text;

        const id = html.substr(start, end - start);
        const userId = (id.match(/@+id(\d+)/i) || [])[1] || (id.match(/profile\/(\d+)/) || [])[1];

        // append mention
        if (!this.mentions) {
          continue;
        }

        const user = this.mentions.find(item => item.id === Number(userId));
        if (user) {
          // пока что костыль

          if (!this.usersService.users.find(s => s.id === user.id)) {
            this.usersService.users.push(user);
          }

          let mentionHtml = '';

          // преобразовывать упоминание в объект
          if (this.mentionToCard) {
            mentionHtml = `
            <div class="co">
              <div class="co__avatar">
                  <a title="" class="avatar _80" data-initials="${this.usersService.getInitials(user)}"
                     href="${environment.startPage}/profile/${user.id}" onclick="navigateProfile(event,${user.id})">
                    <span class="avatar__status" class="avatar__status ${Helper.getSkypeStatusCss(user)}"></span>
                    <span class="avatar__image-wrap" style="background-image: url('${this.usersService.getPictureUrl(
                      user,
                    )})'"></span>
                  </a>
              </div>
              <div class="co__info">
                  <a href="javascript:;" class="link co__name" href="${environment.startPage}/profile/${user.id}"
                    onclick="navigateProfile(event,${user.id})" onmouseover="window.Intry.showTooltip(event,${user.id})"
                      onmouseout="window.Intry.hideTooltip(event)">${user.fullName}</a>`;

            if (user.jobTitle) {
              mentionHtml += `<div class="co__occ">${user.jobTitle}</div>`;
            }

            if (user.department) {
              mentionHtml += `<a class="co__occ"
              href="${environment.startPage}/profile/${user.id}/search#k=${user.department}">${user.department}</a>`;
            }

            if (user.mobilePhone || user.workPhone) {
              mentionHtml += `<div class="co__phones">`;

              if (user.mobilePhone) {
                mentionHtml += `<a href="tel:${user.mobilePhone}" class="link co__phone">${user.mobilePhone}</a>`;
              }

              if (user.workPhone) {
                mentionHtml += `<a href="tel:${user.workPhone}" class="link co__phone">${user.workPhone}</a>`;
              }

              mentionHtml += `</div>`;
            }
            mentionHtml += `</div>
            </div>`;
          } else {
            mentionHtml = `<a userid="${userId}" href="${environment.startPage}/profile/${user.id}"
            onclick="navigateProfile(event,${user.id})"
            onmouseover="window.Intry.showTooltip(event,${user.id})"
            onmouseout="window.Intry.hideTooltip(event)">${user.fullName}</a>`;
          }

          // append space char
          if (result.length > 0 && result[result.length - 1] !== '') {
            result += ' ';
          }
          result += mentionHtml;
        }
      }

      // append end of text
      if (indexes.length > 0 && indexes[indexes.length - 1].end < html.length) {
        result += html.substr(indexes[indexes.length - 1].end);
      }
    } else {
      result += html;
    }

    return result;
  }
}
