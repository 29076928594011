<div #mediaList class="grid">
  <div class="grid__header">
    <span class="header-text _low">
      <span class="header-text__text">{{title}}</span>&nbsp;
      <span class="header-text__count">{{mediaFilesCount}}</span>
    </span>
  </div>
  <div class="grid__content">
    <div class="grid__item" *ngFor="let m of mediaFiles; let odd = odd;">
      <!-- _checked если галочка-->
      <div [style.background-image]="galleryService.getMediaFilePreview(m)" class="gallery-preview _file"
        [ngClass]="{ '_checked' : m.selected }" (click)="openMediaFile($event,m)">
<!--        <video *ngIf="m.type===mediaFileType.video"-->
<!--               class="gallery-preview__video"-->
<!--               width="640" height="480" preload="metadata">-->
<!--          <source [src]="galleryService.getMediaFileUrl(m)" type="video/mp4">-->
<!--        </video>-->
        <div class="gallery-preview__wrap">
          <label class="checkbox" (click)="mediaFileChecked($event,m)">
            <input type="checkbox" class="checkbox__input" (change)="mediaFileCheckChanged($event,m)">
            <span class="checkbox__icon"></span>
          </label>
          <div class="gallery-preview__menu" (click)="stopPropagation($event)">
            <app-media-item-menu [item]="m" [config]="config"></app-media-item-menu>
          </div>
          <div class="gallery-preview__controls">
            <div class="gallery-preview__title" *ngIf="m.type!==mediaFileType.video">{{m.title}}</div>
            <div class="gallery-preview__actions">
              <button type="button" class="btn btn-social _like-white">{{m.likesCount}}</button>
              <button type="button" class="btn btn-social _comment-white" [ngClass]="{'transparent-text': m.commentsCount === 0}">{{m.commentsCount}}</button>
              <a href="javascript:;" class="link" (click)="replyMediaFile($event,m)">Ответить</a>
            </div>
          </div>
          <div class="gallery-preview__play" *ngIf="m.type===mediaFileType.video">
            <!--Длительность видео вставить здесь-->
            <!--<span>14:15</span>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
