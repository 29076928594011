import { StorageInterface } from './storage.interface';

export abstract class WebStorageService implements StorageInterface {

  protected constructor() {
    // todo: Нужно сделать fallback в куки
    this.isStorageIsAvailable();
  }

  public read<T>(key: string, defaultValue: T | null = null): T | null {
    if (!this.isStorageIsAvailable()) {
      return defaultValue;
    }

    const nativeStorage = this.getNativeStorage();
    const json = nativeStorage.getItem(key);

    if (json === 'undefined' || json === 'null') {
      return defaultValue;
    }

    const data = JSON.parse(json);
    if (!data) {
      return defaultValue;
    }

    return data as T;
  }

  public write(key: string, value: any): void {
    if (!this.isStorageIsAvailable()) {
      return;
    }

    this.getNativeStorage().setItem(key, JSON.stringify(value));
  }

  public remove(key: string): void {
    if (!this.isStorageIsAvailable()) {
      return;
    }

    this.getNativeStorage().removeItem(key);
  }

  public clear(): void {
    if (!this.isStorageIsAvailable()) {
      return;
    }

    this.getNativeStorage().clear();
  }

  private isStorageIsAvailable(): boolean {
    const nativeStorage = this.getNativeStorage();
    try {
      nativeStorage.setItem('WebStorageService', 'WebStorageService');
      nativeStorage.removeItem('WebStorageService');
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  protected abstract getNativeStorage(): Storage;

}
