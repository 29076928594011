import { Injectable } from '@angular/core';
import { UsersService } from '@app/profile/services/users.service';
import { AlertType } from '@app/shared/components/alerts/alert';
import { HttpClient } from '@angular/common/http';
import { AlertsService } from '@app/shared/services/alerts.service';
import { RowItem, FSObjType } from '@app/files/model/list-data';
import { FilesApiService } from '@app/files/services/files.api.service';
import { Breadcrumb, FilesListItem, FilesListItemType } from '@app/files/model/files-list-item.model';
import { Helper } from '@app/core/helpers/helper';
import { environment } from '@env/environment';
import { HelperService } from '@app/core/services/helper.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { FileUploadService } from '@app/shared/services/file-upload.service';

/**
 * Сервис работы с файлами. Является расширением API-сервиса работы с файлами
 *
 * @export
 * @class FilesService
 * @extends {FilesApiService}
 */
@Injectable()
export class FilesService extends FilesApiService {

    /**
     * Файлы были загружены в профиль
     *
     * @type {Subject<void>}
     * @memberof FilesService
     */
    filesUploaded$: Subject<void> = new Subject();

    folderId$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    ownerId$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    showUserFiles$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    // currentGroupId$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

    constructor(
        protected http: HttpClient,
        protected usersService: UsersService,
        protected alertsService: AlertsService,
        protected fileUploadService: FileUploadService,
        public helper: HelperService) {
        super(http, usersService, alertsService, fileUploadService, helper);
    }

    getFileUrlV2(item: FilesListItem) {
        return Helper.concatUrls(this.helper.getSiteHostUrl(), `/api/documentFile/${item.uniqueId}`);
    }

    /**
     * Получение ссылки на файл
     *
     * @param {*} item элемент для которого необходимо получить ссылку
     * @param {string} guid Guid "постоянной" ссылки на файл
     * @returns string
     * @memberof FilesService
     */
    webApiGetFileUrl(item: any, guid: string) {
        if (!item || item.toUpload) {
            return '';
        }
        const url = this.getFileUrl(item);
        if (this.webApiFileIsOffice(item) && guid) {
            return `${url}?d=w${Helper.guidToN(guid)}`;
        }
        return url;
    }

    isWebApiFolder(item: RowItem): boolean {
        return item.FSObjType === FSObjType.Folder;
    }

    webApiGetSize(item: RowItem): number {
        return Math.floor((+item.File_x0020_Size) / 1024);
    }

    getBreadcrumbs(url: string, rootFolderUrl: string): Array<Breadcrumb> {
        const breadcrumbs = new Array<Breadcrumb>();
        let breadcrumb = this.getParentFolderStrings(url, rootFolderUrl, true);
        do {
            if (breadcrumb) {
                breadcrumbs.unshift(breadcrumb);
                breadcrumb = this.getParentFolderStrings(breadcrumb.url, rootFolderUrl);
            }
        } while (breadcrumb);

        return breadcrumbs;
    }

    getParentFolderStrings(url: string, rootFolderUrl: string, withCurrent: boolean = false): Breadcrumb {
        let breadcrumb = null;
        if (url) {
            // name
            url = this.clearFolderUrl(url);
            let name = url;
            if (!withCurrent) {
                name = name.substr(0, name.lastIndexOf('/'));
            }
            name = name.substr(name.lastIndexOf('/') + 1);

            // url
            let folderUrl = url;
            if (!withCurrent) {
                folderUrl = folderUrl.substr(0, folderUrl.lastIndexOf('/'));
            }

            if (rootFolderUrl.toLowerCase() !== folderUrl.toLowerCase()) {
                breadcrumb = new Breadcrumb();
                breadcrumb.name = name;
                breadcrumb.url = folderUrl;
            } else {
                breadcrumb = new Breadcrumb();
                breadcrumb.name = 'Файлы';
                breadcrumb.url = '';
            }
        }
        return breadcrumb;
    }

    private clearFolderUrl(url: any) {
        let result = decodeURIComponent(url);
        do {
            if (result[result.length] === '/') {
                result = result.substr(0, result.length - 1);
            }
        } while (url[url.length] === '/');
        return result;
    }

    // helper methods

    // подсчитать количество файлов рекурсивно
    countItemsRecursive(parent: any): number {
        let count = parent.ItemCount;
        if (parent.Folders) {
            parent.Folders.forEach(folder => {
                count += this.countItemsRecursive(folder);
            });
        }
        return count;
    }

    getFileUrl(item): string {
        let url = '';
        if (item && item.remoteItem && item.remoteItem.webUrl) {
            url = (<string>item.remoteItem.webUrl);
        } else if (item.url) {
            url = (<string>item.url);
        } else if (item && item.webUrl) {
            url = (<string>item.webUrl);
        } else if (item.LinkFilename) {
            url = (<string>item.LinkFilename);
        }

        if (this.helper.getSiteHostUrl()
            && !url.startsWith('http://')
            && !url.startsWith('https://')) {
            url = Helper.concatUrls(this.helper.getSiteHostUrl(), url);
        }

        return url;
    }

    /**
     * Получение расширения файла
     * @param {string} item
     */
    getFileExtension(item): string {
        if (item.ext !== undefined && item.ext !== null) {
            return item.ext.toLowerCase();
        }

        const url = item.name; // this.getFileUrl(item);
        item.ext = url ? url.substr(url.lastIndexOf('.')) : '';
        return item.ext.toLowerCase();
    }

    // получить расширение файла
    getFileNameWithoutExtension(name): string {
        if (!name) {
            return '';
        }
        const index = name.lastIndexOf('.');
        return index ? name.substr(0, index) : name;
    }

    webApiFileIsOffice(item: any): boolean {
        if (item.isOffice !== undefined
            && item.isOffice !== null) {
            return item.isOffice;
        }
        // console.log('webApiFileIsOffice');
        if (item.itemType === 'OneNote.Notebook') {
            item.isOffice = true;
            return item.isOffice;
        }
        const ext = this.getFileExtension(item);
        switch (ext) {
            case '.csv':
            case '.ods':
            case '.xls':
            case '.xlsb':
            case '.xlsm':
            case '.xlsx':
            case '.one':
            case '.onetoc2':
            case '.onepkg':
            case '.odp':
            case '.pot':
            case '.potm':
            case '.potx':
            case '.pps':
            case '.ppsm':
            case '.ppsx':
            case '.ppt':
            case '.pptm':
            case '.pptx':
            case '.doc':
            case '.docm':
            case '.docx':
            case '.dot':
            case '.dotm':
            case '.dotx':
            case '.odt':
            case '.rtf':
            case '.pdf':
                item.isOffice = true;
                break;
            default:
                item.isOffice = false;
                break;
        }
        return item.isOffice;
    }

    // получить иконку файла
    getFileIcon(item): string {
        if (!item) {
            return '';
        }

        if (item.fileIcon !== undefined
            && item.fileIcon !== null) {
            return item.fileIcon;
        }

        if (item.itemType === 'OneNote.Notebook') {
            item.fileIcon = '_onenote';
            return item.fileIcon;
        }
        const ext = this.getFileExtension(item);
        switch (ext) {
            case '.csv':
            case '.ods':
            case '.xls':
            case '.xlsb':
            case '.xlsm':
            case '.xlsx':
                item.fileIcon = '_xls';
                break;
            case '.odp':
            case '.pot':
            case '.potm':
            case '.potx':
            case '.pps':
            case '.ppsm':
            case '.ppsx':
            case '.ppt':
            case '.pptm':
            case '.pptx':
                item.fileIcon = '_ppt';
                break;
            case '.one':
            case '.onetoc2':
            case '.onepkg':
                item.fileIcon = '_onenote';
                break;
            case '.doc':
            case '.docm':
            case '.docx':
            case '.dot':
            case '.dotm':
            case '.dotx':
            case '.odt':
            case '.rtf':
                item.fileIcon = '_doc';
                break;
            case '.pdf':
                item.fileIcon = '_pdf';
                break;
            case '.zip':
            case '.rar':
            case '.7z':
                item.fileIcon = '_zip';
                break;
            case '.jpg':
            case '.jpeg':
            case '.bmp':
            case '.png':
            case '.svg':
            case '.tiff':
            case '.gif':
                item.fileIcon = '_img';
                break;
            case '.txt':
                item.fileIcon = '_txt';
                break;
            default:
                item.fileIcon = '';
                break;
        }
        return item.fileIcon;
    }

    // получить ссылку на иконку файла
    getOfficeFileIcon(item): string {
        if (!item) {
            return '';
        }

        if (item.officeFileIcon !== undefined
            && item.officeFileIcon !== null) {
            return item.officeFileIcon;
        }

        const icon = this.getFileIcon(item);

        let img: string;

        switch (icon) {
            case '_xls':
                img = 'xls';
                break;
            case '_ppt':
                img = 'ppt';
                break;
            case '_onenote':
                img = 'onenote';
                break;
            case '_doc':
                img = 'doc';
                break;
            default:
                item.officeFileIcon = '';
                break;
        }

        if (img) {
            item.officeFileIcon = `${environment.assetsPrefix}/assets/images/icons/files/${img}.svg`;
        }

        return item.officeFileIcon;
    }

    getSize(item: any): number {
        const size = item.size ? item.size : item.Size;
        return size ? Math.floor((+size) / 1024) : 0;
    }

    odGetSize(driveItem): number {
        return Math.floor(driveItem.size / 1024);
    }

    odIsFolder(driveItem): boolean {
        return driveItem.folder;
    }

    isFolder(item: FilesListItem) {
        return item.type === FilesListItemType.Folder && item.itemType !== 'OneNote.Notebook' ? true : false;
    }

    isOneNote(item: FilesListItem) {
        return item.itemType === 'OneNote.Notebook' ? true : false;
    }

    isPdf(item: any) {
        return this.getFileIcon(item) === '_pdf';
    }

    /**
     * Является ли файл - картинкой
     *
     * @param {FilesListItem} item Файл, который необходимо проверить
     * @returns
     * @memberof FilesService
     */
    isImage(item: FilesListItem) {
        if (!item) {
            return false;
        }
        if (item.isImage === null || item.isImage === undefined) {
            const ext = this.getFileExtension(item);
            const regExp = new RegExp(/.jpg|.jpeg|.bmp|.png|.tiff|.gif|.svg/gi);
            item.isImage = ext && regExp.test(ext);
        }
        return item.isImage;
    }

    /**
     * Является ли файл - видеофайлом
     *
     * @param {FilesListItem} item Файл, который необходимо проверить
     * @memberof FilesService
     */
    isVideo(item: FilesListItem) {
        if (!item) {
            return false;
        }
        if (item.isVideo === null || item.isVideo === undefined) {
            const ext = this.getFileExtension(item);
            const regExp = new RegExp(/.mp4|.webm|.ogv|.avi/gi);
            item.isVideo = ext && regExp.test(ext);
        }
        return item.isVideo;
    }

    getSyncUrl(url: string, listId: string, type: string, method: string) {
        // tslint:disable-next-line:max-line-length
        return `grvopen://${Helper.asciiEncode(url)}/_aahl${Helper.asciiEncode(listId.replace('{', '').replace('}', ''))}_aahn/101${type}?${method}`;
    }

    copyToClipboard(url) {
        if (Helper.copyToClipboard(url)) {
            this.alertsService.alert.next({
                text: 'Ссылка на файл была успешно скопирована в буфер обмена',
                type: AlertType.Success
            });
        } else {
            this.alertsService.alert.next({
                text: 'Ссылка на файл не была скопирована в буфер обмена',
                type: AlertType.Error
            });
        }
    }

    getAllDocumentsExtArray(): Array<string> {
        return [
            'csv',
            'ods',
            'xls',
            'xlsb',
            'xlsm',
            'xlsx',
            'one',
            'onetoc2',
            'onepkg',
            'odp',
            'pot',
            'potm',
            'potx',
            'pps',
            'ppsm',
            'ppsx',
            'ppt',
            'pptm',
            'pptx',
            'doc',
            'docm',
            'docx',
            'dot',
            'dotm',
            'dotx',
            'odt',
            'rtf',
            'pdf',
            'txt'
        ];
    }

    getAllSearchExtArray(): Array<string> {
        const res = [
            'jpg',
            'jpeg',
            'png',
            'zip',
            'rar',
            'svg'
        ];
        res.concat(this.getAllDocumentsExtArray());
        return res;
    }

    openInClientAppByAbsUrl(item: any, url: string) {
        const index = url.lastIndexOf('?d=w');
        if (index !== -1) {
            url = url.substr(0, index);
        }
        let openUrl = null;
        const icon = this.getFileIcon(item);
        switch (icon) {
            case '_xls':
                openUrl = `ms-excel:ofv|u|${url}`;
                break;
            case '_ppt':
                openUrl = `ms-powerpoint:ofv|u|${url}`;
                break;
            case '_onenote':
                openUrl = `onenote:${url}`;
                break;
            case '_doc':
                openUrl = `ms-word:ofv|u|${url}`;
                break;
        }
        if (openUrl) {
            window.location.href = openUrl;
        } else {
            console.log(`can't open file ${url}`);
        }
    }

    getOfficeOpeningName(item: any) {
        if (!item) {
            return '';
        }

        if (item.offceName !== undefined
            && item.offceName !== null) {
            return item.offceName;
        }
        // console.log('getOfficeOpeningName');
        const icon = this.getFileIcon(item);
        switch (icon) {
            case '_pdf':
                item.offceName = 'PDF';
                break;
            case '_doc':
                item.offceName = 'Word';
                break;
            case '_xls':
                item.offceName = 'Excel';
                break;
            case '_ppt':
                item.offceName = 'PowerPoint';
                break;
            case '_onenote':
                item.offceName = 'OneNote';
                break;
            default:
                item.offceName = '';
                break;
        }
        return item.offceName;
    }
}
