<div class="grid _transparent">
  <div class="grid__content">
    <div class="grid__item" style="width:100%" *ngFor="let page of pages">
      <div class="card" style="margin-bottom:0">
        <div class="post">
          <div class="post__author" *ngIf="page.group">
            <div class="link-profile">
              <app-user-avatar [avatarImage]="getGroupAvatar(page)" [linkClass]="'avatar _40'"
                [imageClass]="'avatar__image'" routerLink="/group/{{page.group?.id}}" [name]="page.group?.title">
              </app-user-avatar>
              <span class="link-profile__text">
                <a routerLink="/group/{{page.group?.id}}" class="link"
                  (click)="groupsService.navigateGroup(page.group?.id)">{{page.group?.title}}</a>
              </span>
            </div>
          </div>
          <div class="post__text ng-star-inserted">
            <div class="news-title">
              <ng-container *ngTemplateOutlet="pageLink; context: {$implicit: page}"></ng-container>
            </div>
          </div>
          <div class="post__text _full" *ngIf="page.body" [innerHTML]="page.body | highlight: highlightText"></div>
          <div>
            <ng-container *ngTemplateOutlet="pageLinkRedirect; context: {$implicit: page}"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #pageLink let-page>
  <a class="link" *ngIf="page.isLink" [attr.href]="pagesService.getPageSearchUrl(page)"
    [attr.target]="page.inNewTab ? '_blank': '_self'" [innerHTML]="page.title | highlight: highlightText"></a>
  <a class="link" *ngIf="!page.isLink" [routerLink]="pagesService.getPageSearchUrl(page)"
    [attr.target]="page.inNewTab ? '_blank': '_self'" (click)="pagesService.navigatePage($event, page)"
    [innerHTML]="page.title | highlight: highlightText"></a>
</ng-template>

<ng-template #pageLinkRedirect let-page>
  <a class="post__read-more" *ngIf="page.isLink" [attr.href]="pagesService.getPageSearchUrl(page)"
    [attr.target]="page.inNewTab ? '_blank': '_self'">Перейти на страницу</a>
  <a class="post__read-more" *ngIf="!page.isLink" [routerLink]="pagesService.getPageSearchUrl(page)"
    [attr.target]="page.inNewTab ? '_blank': '_self'" (click)="pagesService.navigatePage($event, page)">Перейти на
    страницу</a>
</ng-template>