import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  NgZone,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GalleryService, GalleryOwner } from '@app/gallery/services/gallery.service';
import { IMediaFile, MediaFileType, UploadingFile } from '@app/gallery/model/media-file';
import { MatDialog } from '@angular/material/dialog';
import { MediaFileViewComponent } from '@app/gallery/components/forms/media-file-view/media-file-view.component';
import { UsersService } from '@app/profile/services/users.service';
import { AlertsService } from '@app/shared/services/alerts.service';
import { IMediaFileMenuConfig } from '@app/gallery/model/media-file-menu-config';
import { takeUntil, take, finalize } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { SignalRService } from '@app/signalr/signalR.service';
import { ProfileType } from '@app/core/model/profile-type.enum';
import { GroupsService } from '@app/groups/services/groups.service';
import { ActivatedRoute } from '@angular/router';
import { ScrollableListComponent } from '@app/shared/components/scrollable-list/scrollable-list.component';
import { MediaItemMenuComponent } from '../../menu/media-item-menu/media-item-menu.component';
import { Helper } from '@app/core/helpers/helper';
import { User } from '@app/profile/model/user.model';
import { MediaFileUploadV2Service } from '@app/shared/services/media-file-upload-v2.service';

export interface IMediaFileSelect {
  selected: boolean;
  mediaFile: IMediaFile;
}

/**
 * Медиафайлы альбома или профиля
 *
 * @export
 * @class MediaListComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-media-list',
  templateUrl: './media-list.component.html',
  styleUrls: ['./media-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaListComponent extends ScrollableListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() albumId: number;
  @Input() all: boolean;
  @Input() limit: number;
  @Input() title: string;

  @Input() mediaFiles: IMediaFile[] = [];

  @Output() fileAdded: EventEmitter<any> = new EventEmitter();
  @Output() filesAdded: EventEmitter<any> = new EventEmitter();
  @Output() fileAddedError: EventEmitter<any> = new EventEmitter();

  /**
   * Включить обработичик выбора медиа-файла
   *
   * @type {boolean}
   * @memberof MediaListComponent
   */
  @Input() selectMediaFile: boolean;

  /**
   * Действие при выборе медиафайла
   *
   * @type {EventEmitter<IMediaFileSelect>}
   * @memberof MediaListComponent
   */
  @Output() select: EventEmitter<IMediaFileSelect> = new EventEmitter();

  @ViewChild('mediaList', { static: true }) mediaList: ElementRef;

  offset = 0;
  maxItemHeight = 110;

  mediaFilesCount: number;
  mediaFilesLoading: boolean;
  mediaFilesAllLoaded: boolean;

  mediaFileType = MediaFileType;

  filesToUpload = [];
  fileUploading: boolean;

  allowEdit: boolean;
  isAdmin: boolean;

  allowEditConfig: IMediaFileMenuConfig = {
    allowEdit: true,
    allowMove: true
  };

  allowAllConfig: IMediaFileMenuConfig = {
    allowDelete: true,
    allowEdit: true,
    allowMove: true
  };

  currentOwner: GalleryOwner;
  currentUser: User;

  selectedItems: IMediaFile[] = [];

  protected mediaFileToOpenId: number;

  constructor(
    public usersService: UsersService,
    public groupsService: GroupsService,
    public galleryService: GalleryService,
    public sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public alertsService: AlertsService,
    public signalRService: SignalRService,
    public mediaFileUploadService: MediaFileUploadV2Service,
    public ngZone: NgZone,
    public route: ActivatedRoute,
    public cdr: ChangeDetectorRef,
    public helper: HelperService
  ) {
    super(helper);

    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;
    });

    // find what has is now and route navigate with hash
    this.route.fragment
      .pipe(takeUntil(this.destroyed$))
      .subscribe(val => {
        // get hash
        if (val != null) {
          const keys = val.split('&');
          const hash = {};
          keys.forEach(key => {
            // tslint:disable-next-line:no-shadowed-variable
            const val = key.split('=');
            hash[val[0]] = val[1];
          });

          this.mediaFileToOpenId = hash['m'];

          if (this.mediaFileToOpenId) {
            this.galleryService.getMediaFile(this.mediaFileToOpenId).pipe(takeUntil(this.destroyed$))
              .subscribe(res => {
                if (res) {
                  this.openMediaFileDialog(res, false);
                } else {
                  this.alertsService.riseError('Произошла ошибка при открытии медиа-файла');
                }
              }, error => {
                this.alertsService.riseError('Произошла ошибка при открытии медиа-файла');
              });
          }
        }
      });

    this.signalRService.galleryHub.onSetMediaFileAsCover.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.ngZone.run(() => {

        if (!res || !res.albumId || !res.mediaFileId || !res.url) {
          return;
        }

        const mediaFile = this.mediaFiles.find(m => m.id === res.mediaFileId);
        if (mediaFile) {
          mediaFile.isAlbumPreview = true;
        }

        const otherMediaFiles = this.mediaFiles.filter(m => m.id !== res.mediaFileId);
        otherMediaFiles.forEach(m => {
          m.isAlbumPreview = false;
        });
      });
    });
  }

  ngOnInit() {

    this.galleryService.albumDeleted.pipe(takeUntil(this.destroyed$)).subscribe(id => {
      if (id && this.mediaFiles) {
        this.mediaFiles = this.mediaFiles.filter(s => s.album && s.album.id !== id);
        this.cdr.detectChanges();
      }
    });

    this.galleryService.mediaFileDeleted.pipe(takeUntil(this.destroyed$)).subscribe(id => {
      if (id && this.mediaFiles) {
        this.mediaFiles = this.mediaFiles.filter(s => s.id !== id);
        // меняем счётчик
        if (this.mediaFilesCount > 0) {
          this.mediaFilesCount--;
        }
        this.cdr.detectChanges();
      }
    });

    // обработка перемещения медиа-файла
    this.galleryService.mediaFileMoved$.pipe(takeUntil(this.destroyed$)).subscribe(model => {
      if (this.mediaFiles && model) {
        this.mediaFiles = this.mediaFiles.filter(s => s.id !== model.id);
        this.cdr.detectChanges();
      }
    });

    if (this.all) {
      this.usersService.user
        .pipe(takeUntil(this.destroyed$))
        .subscribe(user => {
          if (user) {
            // проставляем текущего owner для загрузки при scroll-событии
            this.currentOwner = { owner: user, ownerType: ProfileType.User };
            this.usersService.currentUser.subscribe(currentUser => {
              this.currentUser = currentUser;
              if (currentUser.id === user.id) {
                this.allowEdit = true;
              }
              this.getProfileMediaFiles(user.id);
            });
          }
        });
    } else {
      this.signalRService.galleryHub.onMediaFileMoved.pipe(takeUntil(this.destroyed$)).subscribe(res => {
        this.ngZone.run(() => {
          // only if mediaFiles list has this mediaFile
          if (this.mediaFiles.find(m => m.id === res.id)) {
            this.mediaFiles = this.mediaFiles.filter(o => o.id !== res.id);
            // update counters
            if (this.mediaFilesCount > 0) {
              this.mediaFilesCount--;
            }
          } else if (this.albumId === res.album.id) {
            this.mediaFiles.push(res);
          }
          this.cdr.detectChanges();
        });
      });
    }
  }

  /**
   * Получение медиа-файлов профиля
   *
   * @protected
   * @param {number} profileId
   * @memberof MediaListComponent
   */
  protected getProfileMediaFiles(profileId: number, noCount: boolean = false) {

    if (!noCount) {
      // количество медиа-файлов профиля
      this.galleryService.getProfileMediaFilesCount(profileId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(res => {
          this.mediaFilesCount = res;
        });
    }

    // медиа-файлы профиля
    this.mediaFilesLoading = true;
    this.galleryService.getProfileMediaFiles(profileId, this.offset, this.limit)
      .pipe(finalize(() => {
        this.loaded = true;
        this.mediaFilesLoading = false;
        this.cdr.detectChanges();
      }), takeUntil(this.destroyed$))
      .subscribe(res => {
        this.operateMediaFiles(res);
      }, error => {
        this.error = error;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['albumId']) {
      this.load();
    }
  }

  public load() {
    if (this.albumId) {
      this.usersService.currentUser.subscribe(currentUser => {
        this.currentUser = currentUser;
        this.galleryService.currentOwner.pipe(takeUntil(this.destroyed$))
          .subscribe(galleryOwner => {
            this.currentOwner = galleryOwner;
            if (galleryOwner && galleryOwner.owner) {
              if (galleryOwner.ownerType === ProfileType.User) {
                this.usersService.user
                  .pipe(takeUntil(this.destroyed$))
                  .subscribe(user => {
                    if (user) {
                      this.allowEdit = user && user.id === currentUser.id;
                    }
                    this.cdr.detectChanges();
                  });
              } else if (galleryOwner.ownerType === ProfileType.Group) {
                this.groupsService.currentGroup
                  .pipe(takeUntil(this.destroyed$))
                  .subscribe(group => {
                    if (group) {
                      this.isAdmin = group.isAdmin;
                      this.allowEdit = group.isAdmin || group.isMember;
                    }
                    this.cdr.detectChanges();
                  });
              } else {
                return;
              }
              this.getAlbumMediaFiles();
            }
          });
      });
    }
  }

  protected getAlbumMediaFiles() {
    // количество медиа-файлов альбома
    this.galleryService.getAlbumMediaFilesCount(this.albumId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        this.mediaFilesCount = res;
        this.cdr.detectChanges();
      });

    // медиа-файлы профиля
    this.getAlbumMediaFilesNoCount(this.albumId, this.offset, this.limit);
  }

  private getAlbumMediaFilesNoCount(albumId: number, offset: number, limit: number) {
    this.mediaFilesLoading = true;
    this.galleryService.getMediaFiles(albumId, offset, limit)
      .pipe(finalize(() => {
        this.loaded = true;
        this.mediaFilesLoading = false;
        this.cdr.detectChanges();
      }), takeUntil(this.destroyed$))
      .subscribe(res => {
        this.operateMediaFiles(res);
      }, error => {
        this.error = error;
      });
  }

  /**
   * Обработка результата загрузки медиа-файлов
   */
  protected operateMediaFiles(mediaFiles: IMediaFile[]) {
    if (!this.mediaFiles) {
      this.mediaFiles = [];
    }

    if (mediaFiles && mediaFiles.length) {
      mediaFiles.forEach(mediaFile => {
        if (!this.mediaFiles.find(s => s.id === mediaFile.id)) {
          this.mediaFiles.push(mediaFile);
        }
      });
      this.offset = this.mediaFiles.length;
      this.mediaFilesAllLoaded = false;
    } else {
      // все медиа-файлы загружены
      this.mediaFilesAllLoaded = true;
    }
    this.cdr.detectChanges();
  }

  /**
   * Открыть всплывашку медиа-файла
   */
  openMediaFile(e: Event, mediaFile: IMediaFile) {
    this.openPopup(e, mediaFile, false);
  }

  /**
   * Открыть всплывашку и поставить фокус на "ответить"
   *
   * @param {Event} e
   * @param {IMediaFile} mediaFile
   * @param {IMediaFile[]} mediaFiles
   */
  replyMediaFile(e: Event, mediaFile: IMediaFile) {
    this.openPopup(e, mediaFile, true);
  }

  private openPopup(e: Event, mediaFile: IMediaFile, reply: boolean): void {
    e.stopImmediatePropagation();
    this.openMediaFileDialog(mediaFile, reply);
  }

  private openMediaFileDialog(mediaFile: IMediaFile, reply: boolean) {
    this.dialog.open(MediaFileViewComponent, {
      minWidth: window.innerWidth - 100,
      data: {
        item: mediaFile,
        items: this.mediaFiles,
        reply: reply,
        isAdmin: this.isAdmin
      }
    });
  }

  mediaFileChecked(e: Event, mediaFile: IMediaFile) {
    e.stopImmediatePropagation();
  }

  mediaFileCheckChanged(e: any, mediaFile: IMediaFile) {
    e.stopImmediatePropagation();
    mediaFile.selected = e.target.checked;
    if (this.selectMediaFile) {
      this.select.emit({ selected: mediaFile.selected, mediaFile });
    }
    this.selectedItems = this.getSelectedItems(mediaFile);
  }

  getSelectedItems(mediaFile: IMediaFile): IMediaFile[] {
    if (!mediaFile) {
      return [];
    }

    if (this.mediaFiles) {
      const selected = this.mediaFiles.filter(s => s.selected);
      if (selected.find(s => s.id === mediaFile.id)) {
        return selected;
      }
    }
    return [mediaFile];
  }

  /**
   * Админ группы или автор медиа-файла может его удалять и редактировать
   *
   * @param {IMediaFile} mediaFile
   */
  getMenuConfig(mediaFile: IMediaFile): IMediaFileMenuConfig {
    if (this.currentOwner && this.currentOwner.ownerType === ProfileType.Group) {
      const group = this.groupsService.currentGroup.getValue();
      if (group) {
        if (this.isAdmin
          || mediaFile.author && this.currentUser && mediaFile.author.id === this.currentUser.id) {
          return this.allowAllConfig;
        }
      }
    } else if (mediaFile.author && this.currentUser && mediaFile.author.id === this.currentUser.id) {
      return this.allowAllConfig;
    }
    return null;
  }

  /**
   * Разрешить drop для элемента(-ов)
   *
   * @param {*} e
   */
  allowDrop(e: any) {
    this.usersService.currentUser.subscribe(currentUser => {
      const user = this.usersService.user.getValue();
      if (user && user.id === currentUser.id && this.albumId) {
        e.preventDefault();
      }
    });
  }

  /**
   * Действие при загрузке файлов
   *
   * @param {*} fileInput
   */
  onUpload(fileInput) {
    const files = fileInput.target.files;
    this.dropFiles(files);
  }

  /**
   * При дропе медиа-файла
   *
   * @param {*} e
   */
  drop(e: any) {
    if (e.dataTransfer && e.dataTransfer.files) {
      console.log('drop');
      e.preventDefault();
      e.stopPropagation();
      const files = e.dataTransfer.files;
      this.dropFiles(files);
    }
  }

  dropFiles(files) {
    this.galleryService.mediaFilesUploading.next(true);
    this.operateUploadingFiles(files);
  }

  private operateUploadingFiles(files) {
    if (files && files.length) {
      this.filesToUpload = [];
      for (let i = 0; i < files.length; i++) {
        this.filesToUpload.push(files[i]);
      }
    }

    if (this.filesToUpload && this.filesToUpload.length) {
      // upload file
      this.fileUploading = true;
      this.filesToUpload.forEach(file => {
        this.uploadFile(this.albumId, file);
      });
    }
  }

  private uploadFile(albumId: number, file: UploadingFile) {
    file.uploading = true;
    const formData: FormData = new FormData();
    formData.append('file', file);

    this.mediaFileUploadService.uploadMediaFile(albumId, file.name, file, true, null, null).then(res => {
      if (res) {
        console.log('file uploading success');

        // сообщение об успешно добавленном файле
        this.alertsService.riseSuccess(`Медиа-файл '${file.name}' был успешно добавлен`);

        if (this.fileAdded) {
          this.fileAdded.emit();
        }
        this.operateMediaFiles((<any>res).comments);
      } else {
        this.alertsService.riseError(`Произошла ошибка во время добавления медиа-файла '${file.name}'. ` + Helper.FILE_UPLOAD_ERROR);
      }
      this.fileUploadingFinished(file);
    }, error => {
      console.log(error);

      // сообщение об ошибке добавления файла
      this.alertsService.riseError(`Произошла ошибка во время добавления медиа-файла '${file.name}'. ` + Helper.FILE_UPLOAD_ERROR);

      if (this.fileAddedError) {
        this.fileAddedError.emit(error);
      }
      this.fileUploadingFinished(file);
    });

    // this.galleryService.uploadMediaFile(albumId, formData).subscribe(res => {
    //   if (res) {
    //     console.log('file uploading success');

    //     // сообщение об успешно добавленном файле
    //     this.alertsService.riseSuccess(`Медиа-файл '${file.name}' был успешно добавлен`);

    //     if (this.fileAdded) {
    //       this.fileAdded.emit();
    //     }
    //     this.operateMediaFiles(res);
    //   } else {
    //     this.alertsService.riseError(`Произошла ошибка во время добавления медиа-файла '${file.name}'. ` + Helper.FILE_UPLOAD_ERROR);
    //   }
    //   this.fileUploadingFinished(file);
    // }, error => {
    //   console.log(error);

    //   // сообщение об ошибке добавления файла
    //   this.alertsService.riseError(`Произошла ошибка во время добавления медиа-файла '${file.name}'. ` + Helper.FILE_UPLOAD_ERROR);

    //   if (this.fileAddedError) {
    //     this.fileAddedError.emit(error);
    //   }
    //   this.fileUploadingFinished(file);
    // });
  }

  private fileUploadingFinished(file: any) {
    file.uploading = false;
    if (!this.filesToUpload.filter(f => f.uploading === true).length) {
      this.fileUploading = false;
      if (this.filesAdded) {
        this.filesAdded.emit();
      }
      this.galleryService.mediaFileAdded.next();
      this.galleryService.mediaFilesUploading.next(false);
      // обновить счётчик
      if (!this.mediaFilesCount) {
        this.mediaFilesCount = this.mediaFiles.length;
      } else {
        this.mediaFilesCount += this.filesToUpload.length;
      }
    }
    this.cdr.detectChanges();
  }

  protected onScroll() {
    const number = window.innerHeight + window.pageYOffset + 20;
    if (this.mediaFiles && number > this.maxItemHeight * this.mediaFiles.length) {
      if (!this.mediaFilesLoading) {
        if (!this.mediaFilesAllLoaded) {
          if (this.all) {
            if (this.currentOwner && this.currentOwner.owner && this.currentOwner.owner.id) {
              this.getProfileMediaFiles(this.currentOwner.owner.id, true);
            } else {
              console.log('current owner is not initialized');
            }
          } else {
            this.getAlbumMediaFilesNoCount(this.albumId, this.offset, this.limit);
          }
        }
      }
    }
  }

  showContextMenu(e: any, m: IMediaFile, itemMenu: MediaItemMenuComponent) {
    e.preventDefault();
    e.stopImmediatePropagation();
    itemMenu.show(event);
  }
}
