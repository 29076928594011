import {Component, OnInit, OnDestroy, NgZone, ChangeDetectorRef} from '@angular/core';
import {BaseComponent} from '@app/core/components/base.component';
import {GalleryService} from '@app/gallery/services/gallery.service';
import {UsersService} from '@app/profile/services/users.service';
import {IMediaFile, MediaFileType, IMediaFileGrouped} from '@app/gallery/model/media-file';
import {MatDialog} from '@angular/material/dialog';
import {MediaFileViewComponent} from '@app/gallery/components/forms/media-file-view/media-file-view.component';
import {ProfileType} from '@app/core/model/profile-type.enum';
import {IMediaFileMenuConfig} from '@app/gallery/model/media-file-menu-config';
import {GroupsService} from '@app/groups/services/groups.service';
import {takeUntil, take, finalize} from 'rxjs/operators';
import {HelperService} from '@app/core/services/helper.service';
import * as moment from 'moment';
import {ScrollableListComponent} from '@app/shared/components/scrollable-list/scrollable-list.component';

/**
 * Последние медиафайлы.
 * Если пользователь текущий, то отображаются группированные по ownerId медиафайлы пользователей и групп,
 * на которых он подписан.
 * Если пользователь не текущий, то все последние файлы, которые данный пользователь добавил.
 *
 * @export
 * @class MediaLastListComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-media-last-list',
  templateUrl: './media-last-list.component.html',
  styleUrls: ['./media-last-list.component.scss'],
  providers: [GroupsService],
})
export class MediaLastListComponent extends ScrollableListComponent implements OnInit, OnDestroy {
  offset = 0;
  limit = 20;
  allLoaded = false;

  protected maxItemHeight = 250;

  mediaFileGroups: IMediaFileGrouped[] = [];
  mediaFiles: IMediaFile[] = [];
  mediaFileType = MediaFileType;
  profileType = ProfileType;

  config: IMediaFileMenuConfig = {
    allowMove: true,
    allowEdit: true,
    allowDelete: true,
  };

  constructor(
    private usersService: UsersService,
    public galleryService: GalleryService,
    public groupsService: GroupsService,
    public dialog: MatDialog,
    protected ngZone: NgZone,
    protected cdr: ChangeDetectorRef,
    public helper: HelperService,
  ) {
    super(helper);
  }

  ngOnInit() {
    this.allLoaded = false;
    this.offset = 0;
    this.loadItems();
  }

  /**
   * Получение ссылки на галерею владельца
   *
   * @param {IMediaFileGrouped} mediaFileGroup
   * @memberof MediaLastListComponent
   */
  getOwnerLink(mediaFileGroup: IMediaFileGrouped) {
    return mediaFileGroup.ownerType === ProfileType.User
      ? `/profile/${mediaFileGroup.ownerId}/gallery`
      : `/group/${mediaFileGroup.ownerId}/gallery`;
  }

  /**
   * Получение текста ссылки на галерею владельца
   *
   * @param {IMediaFileGrouped} mediaFileGroup
   * @returns
   * @memberof MediaLastListComponent
   */
  getOwnerTypeText(mediaFileGroup: IMediaFileGrouped) {
    return mediaFileGroup.ownerType === ProfileType.User ? 'Галерея коллеги' : 'Галерея группы';
  }

  /**
   * Открыть всплывашку медиафайла
   *
   * @param {IMediaFile} mediaFile
   * @memberof MediaListComponent
   */
  openMediaFile(e: Event, mediaFile: IMediaFile, mediaFileGroup: IMediaFileGrouped) {
    this.openPopup(e, mediaFile, mediaFileGroup, false);
  }

  /**
   * Открыть всплывашку и поставить фокус на "ответить"
   *
   * @param {Event} e
   * @param {IMediaFile} mediaFile
   * @param {IMediaFile[]} mediaFiles
   * @memberof MediaLastListComponent
   */
  replyMediaFile(e: Event, mediaFile: IMediaFile, mediaFileGroup: IMediaFileGrouped) {
    this.openPopup(e, mediaFile, mediaFileGroup, true);
  }

  private openPopup(
    e: Event,
    mediaFile: IMediaFile,
    mediaFileGroup: IMediaFileGrouped,
    reply: boolean,
  ): void {
    e.stopImmediatePropagation();
    this.dialog
      .open(MediaFileViewComponent, {
        minWidth: window.innerWidth - 100,
        data: {
          item: mediaFile,
          items: mediaFileGroup.mediaFiles,
          reply: reply,
          filesCountInBlock: mediaFile.filesCountInBlock,
        },
      })
      .beforeClosed()
      .subscribe(res => {
        if (mediaFileGroup.mediaFiles && mediaFileGroup.mediaFiles.length && mediaFileGroup.mediaFiles.length > 4) {
          mediaFileGroup.mediaFiles = mediaFileGroup.mediaFiles.slice(0, 4);
        }
      });
  }

  private loadItems(): void {
    this.loading = true;
    this.usersService.currentUser.subscribe(currentUser => {
      this.usersService.user.pipe(takeUntil(this.destroyed$)).subscribe(user => {
        if (user) {
          this.galleryService
            .getLastMediaFiles(user.id, this.offset, this.limit)
            .pipe(
              finalize(() => {
                this.loaded = true;
                this.loading = false;
              }),
              takeUntil(this.destroyed$),
            )
            .subscribe(
              mediaFiles => {
                if ((currentUser && currentUser.id === user.id) || currentUser.isAdmin) {
                  this.config = {
                    allowMove: true,
                    allowDelete: true,
                    allowEdit: true,
                  };
                }

                if (mediaFiles && mediaFiles.length) {
                  for (let index = 0; index < mediaFiles.length; index++) {
                    const mediaFile = mediaFiles[index];
                    const date = moment(mediaFile.created).format('DD.MM.YY');

                    if (
                      this.mediaFileGroups.length === 0 ||
                      this.mediaFileGroups[this.mediaFileGroups.length - 1].ownerId !== mediaFile.owner.id ||
                      this.mediaFileGroups[this.mediaFileGroups.length - 1].date !== date
                    ) {
                      // создаём новую группу
                      const grouped: IMediaFileGrouped = {
                        group: mediaFile.group,
                        ownerType: mediaFile.ownerType,
                        ownerId: mediaFile.owner.id,
                        user: mediaFile.owner,
                        date: date,
                        mediaFiles: [mediaFile],
                        filesCountInBlock: mediaFile.filesCountInBlock,
                      };

                      this.mediaFileGroups.push(grouped);
                    } else {
                      // добавляем элемент в уже существующую
                      this.mediaFileGroups[this.mediaFileGroups.length - 1].mediaFiles.push(mediaFile);
                    }
                  }
                } else {
                  this.allLoaded = true;
                }

                this.offset += this.limit;
              },
              error => {
                this.error = error;
              },
            );
        }
      });
    });
  }

  /**
   * Настройки меню для медиа-файла
   *
   * @param {IMediaFile} mediaFile медиа-файл
   * @returns {IMediaFileMenuConfig}
   * @memberof MediaLastListComponent
   */
  getMenuConfig(mediaFile: IMediaFile): IMediaFileMenuConfig {
    return null;
  }

  protected onScroll() {
    if (this.mediaFiles) {
      const number = window.innerHeight + window.pageYOffset + 100;
      let itemsLength = 0;
      if (document.scrollingElement) {
        itemsLength = document.scrollingElement.scrollHeight - 1000;
      } else {
        itemsLength = this.maxItemHeight * this.mediaFiles.length;
      }

      if (number > itemsLength) {
        if (!this.loading && !this.allLoaded) {
          this.loadItems();
        }
      }
    }
  }
}
