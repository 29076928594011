import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { environment } from '@env/environment';
import { HelperService } from '@app/core/services/helper.service';
import { Observable } from 'rxjs';
import { FeedEventViewModel } from '../model/feed-event-view-model.model';
import { EventType, INewFeedEventBody } from '../model/feed-event.model';
import { FeedType } from '../model/feed-type.model';
import { HttpClient } from '@angular/common/http';

/**
 * API - сервис ленты
 *
 * @export
 * @class FeedApiService
 * @extends {BaseService}
 */
@Injectable({
  providedIn: 'root'
})
export class FeedApiService extends BaseService {

  protected serviceUrl = `${environment.siteUrl}/api/feed`;
  constructor(
    protected http: HttpClient,
    public helper: HelperService) { super(http, helper); }

  /**
   * Получить ленту профиля
   *
   * @param number userId ИД пользователя
   * @param number offset сдвиг
   * @param number limit лимит
   * @returns Observable<FeedEventViewModel[]>
   * @memberof FeedService
   */
  getProfileFeed(userId: number, offset: number, limit: number): Observable<FeedEventViewModel[]> {
    return super.get(`user/${userId}/feed?offset=${offset}&limit=${limit}`);
  }

  /**
   * Получить ленту пользователя
   *
   * @param {number} userId ИД пользователя
   * @param {number} offset сдвиг
   * @param {number} limit лимит
   * @returns {Observable<FeedEventViewModel[]>}
   * @memberof FeedService
   */
  getUserFeed(userId: number, offset: number, limit: number): Observable<FeedEventViewModel[]> {
    return super.get(`user/${userId}/fullFeed?offset=${offset}&limit=${limit}`);
  }

  /**
   * Получить ленту группы
   *
   * @param {number} groupId ИД группы
   * @param {number} offset сдвиг
   * @param {number} limit лимит
   * @returns {Observable<FeedEventViewModel[]>}
   * @memberof FeedService
   */
  getGroupFeed(groupId: number, offset: number, limit: number): Observable<FeedEventViewModel[]> {
    return super.get(`group/${groupId}/feed?offset=${offset}&limit=${limit}`);
  }

  /**
   * Получить ленту бизнес-новостей
   *
   * @param {number} offset сдвиг
   * @param {number} limit лимит
   * @returns {Observable<FeedEventViewModel[]>}
   * @memberof FeedApiService
   */
  getBusinessFeed(offset: number, limit: number): Observable<FeedEventViewModel[]> {
    return super.get(`businessFeed?offset=${offset}&limit=${limit}`);
  }

  /**
   * Получить бизнес-новости для главной
   *
   * @returns {Observable<FeedEventViewModel[]>}
   * @memberof FeedApiService
   */
  getBusinessNews(): Observable<FeedEventViewModel[]> {
    return super.get(`businessNews`);
  }

  /**
   * Получить ленту новых сотрудников
   *
   * @param {number} offset сдвиг
   * @param {number} limit лимит
   * @returns {Observable<FeedEventViewModel[]>}
   * @memberof FeedApiService
   */
  getNewEmployeeFeed(offset: number, limit: number): Observable<FeedEventViewModel[]> {
    return super.get(`newEmployeeFeed?offset=${offset}&limit=${limit}`);
  }

  /**
   * Получить события определённого типа
   *
   * @param {EventType} eventType
   * @param {number} offset
   * @param {number} limit
   * @returns {Observable<FeedEventViewModel[]>}
   * @memberof FeedApiService
   */
  getEventsByType(eventType: EventType, offset: number, limit: number): Observable<FeedEventViewModel[]> {
    return super.get(`postsByType?eventType=${eventType}&offset=${offset}&limit=${limit}`);
  }

  /**
   * Получить новых сотрудников для виджета
   *
   * @param {number} offset сдвиг
   * @param {number} limit лимит
   * @returns {Observable<FeedEventViewModel[]>}
   * @memberof FeedApiService
   */
  getNewEmployees(offset: number, limit: number): Observable<FeedEventViewModel[]> {
    return super.get(`newEmployees?offset=${offset}&limit=${limit}`);
  }

  /**
   * Создать пост
   *
   * @param {INewFeedEventBody} body тело поста
   * @param {number} profileId ИД профиля в котором создать пост (пользователь или группа)
   * @param {number[]} media массив медиафайлов
   * @param {string[]} docs массив документов
   * @param {number[]} mentions массив упоминаний
   * @returns {Observable<FeedEventViewModel>}
   * @memberof FeedService
   */
  createPost(
    body: INewFeedEventBody,
    profileId: number,
    media: number[],
    docs: string[],
    mentions: number[],
    tags: number[]): Observable<FeedEventViewModel> {
    return super.post(`post`, { body, profileId, media, docs, mentions, tags });
  }

  /**
   * Закрепить или открепить пост
   * @param postId ИД поста
   * @param enable true, если закрепить
   * @returns булево успешности операции
   */
  pin(postId: number, enable: boolean): Observable<boolean> {
    return super.put(`pin`, { postId, enable });
  }

  /**
   * Удалить пост
   *
   * @param {number} postId ИД поста
   * @returns {Observable<FeedEventViewModel>}
   * @memberof FeedService
   */
  removePost(postId: number): Observable<FeedEventViewModel> {
    return super.delete(`post/${postId}`);
  }

  /**
   * Редактировать пост
   *
   * @param {number} postId ИД поста
   * @param {INewFeedEventBody} body тело поста
   * @param {number[]} media массив медиафайлов
   * @param {string[]} docs массив документов
   * @param {number[]} mentions массив упоминаний
   * @returns {Observable<FeedEventViewModel>}
   * @memberof FeedService
   */
  editPost(
    postId: number,
    body: INewFeedEventBody,
    media: number[],
    docs: string[],
    mentions: number[],
    tags: number[]): Observable<FeedEventViewModel> {
    return super.put(`post`, { postId, body, media, docs, mentions, tags });
  }

  /**
   * Получить пост по идентификатору
   *
   * @param {number} postId ИД поста
   * @param {boolean} allComments Все комменты сразу?
   * @returns {Observable<FeedEventViewModel>}
   * @memberof FeedService
   */
  getPost(postId: number, allComments: boolean): Observable<FeedEventViewModel> {
    const commentsLimit = allComments ? 250 : 2;
    return super.get(`post/${postId}?commentsLimit=${commentsLimit}`);
  }

  /**
   * Поиск по ленте
   *
   * @param {string} text строка поиска
   * @param {number} ownerId ИД владельца
   * @param {FeedType} feedType тип ленты (1 - профиль, 2 - лента, 3 - группа)
   * @param {number} offset сдвиг
   * @param {number} limit лимит
   * @returns {*}
   * @memberof FeedService
   */
  search(text: string, ownerId: number, feedType: FeedType, offset: number, limit: number): Observable<FeedEventViewModel[]> {
    return super.get(`searchFeed?text=${encodeURIComponent(text)}&ownerId=${ownerId}&feedType=${feedType}&offset=${offset}&limit=${limit}`);
  }

  /**
   * Поиск по тегам
   *
   * @param {string} text строка поиска
   * @param {number} ownerId ИД владельца
   * @param {FeedType} feedType тип ленты (1 - профиль, 2 - лента, 3 - группа)
   * @param {number} offset сдвиг
   * @param {number} limit лимит
   * @returns {*}
   * @memberof FeedService
   */
  searchTags(text: string, ownerId: number, feedType: FeedType, offset: number, limit: number): Observable<FeedEventViewModel[]> {
    // tslint:disable-next-line:max-line-length
    return super.get(`searchFeedTags?text=${encodeURIComponent(text)}&ownerId=${ownerId}&feedType=${feedType}&offset=${offset}&limit=${limit}`);
  }
}
