<div class="cos__header-light" *ngIf="loaded && responses?.length">
  <div class="news-title _bright">Отклики</div>
</div>
<div class="cos__body _last" *ngIf="loaded && responses?.length">
  <div class="co" *ngFor="let response of responses; trackBy:response?.id">
    <app-user-avatar [user]="response.author" [linkClass]="'avatar _80'" [imageClass]="'avatar__image'">
    </app-user-avatar>
    <a class="co__info" routerLink="/group/{{vacancy?.ownerId}}/vacancy/{{vacancy?.id}}/response/{{response?.id}}">
      <a href="#" class="link co__name" routerLink="/profile/{{response.author?.id}}"
        (click)="usersService.navigateProfile(response.author?.id)"
        [appUserTooltip]="response.author?.id">{{response.author?.fullName}}
      </a>
      <div class="co__occ" *ngIf="response.author?.jobTitle">{{response.author?.jobTitle}}</div>
      <div class="co__phones" *ngIf="response.author?.mobilePhone || response.author?.workPhone">
        <a href="tel:{{response.author?.mobilePhone}}" class="link co__phone"
          *ngIf="response.author?.mobilePhone">{{response.author?.mobilePhone}}</a>
        <a href="tel:{{response.author?.workPhone}}" class="link co__phone"
          *ngIf="response.author?.workPhone">{{response.author?.workPhone}}</a>
      </div>
      <div class="co__phones">Отклик от {{response.created | date:'dd.MM.yyyy'}},
        <span [attr.class]="vacancyService.getResponseStatusCss(response)">{{vacancyService.getResponseStatusText(response)}}</span>
      </div>
    </a>
    <div class="co__action" *ngIf="vacancy?.userAdmin">
      <app-response-menu [responseId]="response.id" [config]="responseConfig" (statusChanged)="onStatusChanged($event)">
      </app-response-menu>
    </div>
  </div>
</div>