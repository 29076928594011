import {Injectable} from '@angular/core';
import {BaseService} from '@app/core/services/base.service';
import {ElementType} from '@app/core/model/element-type.enum';
import {Like} from '../model/like.model';
import {LikeViewModel} from '@app/likes/model/like-view-model.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@env/environment';
import {HelperService} from '@app/core/services/helper.service';
import {User} from '@app/profile/model/user.model';
import {UserLink} from '@app/subscribe/model/user-link';

@Injectable()
export class LikesService extends BaseService {
  protected serviceUrl = `${environment.siteUrl}/api/like`;
  constructor(protected http: HttpClient, public helper: HelperService) {
    super(http, helper);
  }

  // getUserLikes(userId: number): Observable<Like[]> {
  //   return this.get<Like[]>(`likes?userId=${userId}`);
  // }

  getLikes(elementId: string, elementType: ElementType): Observable<Like[]> {
    return this.get<Like[]>(`${elementId}?elementType=${elementType}`);
  }

  getLikesSimple(elementId: string, elementType: ElementType, offset: number, limit: number): Observable<User[]> {
    return this.get<User[]>(
      `${elementId}/simple?elementType=${elementType}&elementType=${elementType}&offset=${offset}&limit=${limit}`,
    );
  }

  getLikesFull(elementId: string, elementType: ElementType, offset: number, limit: number): Observable<UserLink[]> {
    return this.get<UserLink[]>(
      `${elementId}/full?elementType=${elementType}&elementType=${elementType}&offset=${offset}&limit=${limit}`,
    );
  }

  getLikesCount(elementId: string, elementType: ElementType): Observable<LikeViewModel> {
    return this.get<LikeViewModel>(`${elementId}/count?elementType=${elementType}`);
  }

  canLike(elementId: string, elementType: ElementType): Observable<boolean> {
    return this.get<boolean>(`${elementId}/canLike?elementType=${elementType}`);
  }

  like(elementId: string, elementType: ElementType): Observable<LikeViewModel> {
    return this.post<LikeViewModel>(`${elementId}/like?elementType=${elementType}`, {});
  }

  unlike(elementId: string, elementType: ElementType): Observable<LikeViewModel> {
    return this.post<LikeViewModel>(`${elementId}/unlike?elementType=${elementType}`, {});
  }
}
