import { Component, OnChanges, SimpleChanges, Input, OnDestroy } from '@angular/core';
import { IVacancyResponse, ResponseStatus, IVacancy } from '@app/vacancy/model/vacancy';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { VacancyService } from '@app/vacancy/services/vacancy.service';
import { take, takeUntil, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IVacancyResponseMenuResult, IVacancyResponseMenuConfig } from '../menu/response-menu/response-menu.component';

/**
 * Список откликов вакансии
 *
 * @export
 * @class ResponseListComponent
 * @extends {BaseComponent}
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-response-list',
  templateUrl: './response-list.component.html',
  styleUrls: ['./response-list.component.scss']
})
export class ResponseListComponent extends BaseComponent implements OnChanges, OnDestroy {

  @Input() vacancy: IVacancy;

  responses: IVacancyResponse[] = [];

  responseConfig: IVacancyResponseMenuConfig;

  offset = 0;
  limit = 20;

  constructor(
    public vacancyService: VacancyService,
    public helper: HelperService
  ) {
    super(helper);
    this.vacancyService.newResponse.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      if (res
        && this.vacancy
        && this.vacancy.id === res.vacancyId
        && !this.responses.find(s => s.id === res.id)) {

        this.responses.push(res);
        this.vacancyService.newResponse.next(null);
      }
    });

    // TODO: check group rights
    this.responseConfig = {
      allowChangeStatus: true
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['vacancy']) {
      if (this.vacancy) {
        this.vacancyService.getResponses(this.vacancy.id, this.offset, this.limit)
          .pipe(take(1), finalize(() => this.loaded = true), takeUntil(this.destroyed$))
          .subscribe(res => {
            if (res) {
              res.forEach(item => {
                if (!this.responses.find(s => s.id === item.id)) {
                  this.responses.push(item);
                }
              });
              this.offset = this.responses.length;
            }
          });
      }
    }
  }

  onStatusChanged(result: IVacancyResponseMenuResult) {
    const response = this.responses.find(s => s.id === result.responseId);
    if (response) {
      response.status = result.status;
    }
  }
}
