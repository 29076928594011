import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { GroupsService } from '@app/groups/services/groups.service';
import { takeUntil, groupBy, switchMap } from 'rxjs/operators';
import { GroupInfo } from '@app/groups/model/group-info.model';
import { Subject } from 'rxjs';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { IModule, IModuleInfo } from '@app/groups/model/module';
import { AlertsService } from '@app/shared/services/alerts.service';
import { GroupType } from '@app/groups/model/group-type.model';
import { IGroupUsersConfig } from '@app/groups/model/group-users-config';
import { GroupUserRole } from '@app/groups/model/group-user-role.model';
import { FilesService } from '@app/files/services/files.service';

export enum GroupUserLinkObjectType {
  allUsers = 1,
  domainGroup = 2,
  distributionGroup = 3
}

/**
 * Страница настроек группы
 *
 * @export
 * @class GroupSettingsComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-group-settings',
  templateUrl: './group-settings.component.html',
  styleUrls: ['./group-settings.component.scss']
})
export class GroupSettingsComponent extends BaseComponent implements OnInit, OnDestroy {
  group: GroupInfo;

  modules: IModule[] = [];
  groupModules: IModuleInfo[] = [];

  GroupType = GroupType;

  GroupUserLinkObjectType = GroupUserLinkObjectType;
  subscribeTypeSelected: GroupUserLinkObjectType;
  domainGroupName: string;
  distributionGroupName: string;
  subscribeConfigLoaded: boolean;

  sending: boolean;

  constructor(
    public groupsService: GroupsService,
    private alertsService: AlertsService,
    private breadcrumbsService: BreadcrumbsService,
    private filesService: FilesService,
    public helper: HelperService
  ) { super(helper); }

  ngOnInit() {
    this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$))
      .subscribe(group => {
        if (group) {
          this.group = group;
          this.breadcrumbsService.set('Настройки', null, `/group/${this.group.id}/settings`, null);

          if (group) {
            this.groupsService.getGroupUsersSettings(this.group.id).pipe(takeUntil(this.destroyed$)).subscribe(res => {
              if (res) {
                this.subscribeTypeSelected = res.linkObjectType;
                if (res.linkObjectType === GroupUserLinkObjectType.domainGroup) {
                  this.domainGroupName = res.linkObjectValue;
                } else if (res.linkObjectType === GroupUserLinkObjectType.distributionGroup) {
                  this.distributionGroupName = res.linkObjectValue;
                }
              } else {
                this.subscribeTypeSelected = GroupUserLinkObjectType.allUsers;
              }
              this.subscribeConfigLoaded = true;
            }, error => {
              this.subscribeConfigLoaded = true;
            });

            this.groupsService.getModules().pipe(takeUntil(this.destroyed$), switchMap((modules: IModule[]) => {
              if (modules) {
                modules.forEach(module => {
                  if (!this.modules.find(s => s.id === module.id)) {
                    if (module.name === 'Файлы' && this.filesService.showUserFiles$.getValue() || module.name !== 'Файлы') {
                      this.modules.push(module);
                    }
                  }
                });
              }
              return this.groupsService.getGroupModules(this.group.id).pipe(takeUntil(this.destroyed$));
            })).subscribe(groupModules => {
              this.groupModules = groupModules;
              if (this.groupModules) {
                this.modules.forEach(m => {
                  m.checked = !!this.groupModules.find(s => s.id === m.id);
                });
              }
              this.loaded = true;
            }, error => {
              this.loaded = true;
            });
          }
        }
      });
  }

  submit() {
    const groupModules = this.modules.filter(s => s.checked).map(gm => {
      const moduleInfo: IModuleInfo = { id: gm.id, title: gm.name };
      return moduleInfo;
    });

    const groupUserConfig: IGroupUsersConfig = {
      groupId: this.group.id,
      role: GroupUserRole.Subscriber,
      linkObjectType: this.subscribeTypeSelected
    };

    if (this.subscribeTypeSelected === GroupUserLinkObjectType.domainGroup) {
      groupUserConfig.linkObjectValue = this.domainGroupName;
    } else if (this.subscribeTypeSelected === GroupUserLinkObjectType.distributionGroup) {
      groupUserConfig.linkObjectValue = this.distributionGroupName;
    } else {
      groupUserConfig.linkObjectValue = null;
    }

    this.groupsService.changeGroupSettings(this.group.id, groupModules, groupUserConfig).subscribe(res => {
      this.alertsService.riseSuccess('Настройки группы были применены успешно');
      this.groupsService.settingsChanged$.next();
    }, err => {
      this.alertsService.riseError('Произошла ошибка при изменении настроек группы');
    });
  }

  isSubscriberSettingsAreaVisible() {
    return this.subscribeTypeSelected !== GroupUserLinkObjectType.allUsers;
  }

  subscriberSettingsPlaceholder() {
    if (this.subscribeTypeSelected === GroupUserLinkObjectType.domainGroup) {
      return 'Название доменной группы';
    } else {
      return 'Название группы рассылки';
    }
  }

  isValid() {
    return !this.sending
      && (this.subscribeTypeSelected === GroupUserLinkObjectType.allUsers
        || this.subscribeTypeSelected === GroupUserLinkObjectType.domainGroup && this.domainGroupName
        || this.subscribeTypeSelected === GroupUserLinkObjectType.distributionGroup && this.distributionGroupName);
  }
}
