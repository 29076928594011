import { Component, Inject } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { IMediaFile, MediaFileType } from '@app/gallery/model/media-file';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertsService } from '@app/shared/services/alerts.service';
import { UsersService } from '@app/profile/services/users.service';
import { GalleryService } from '@app/gallery/services/gallery.service';
import { ProfileType } from '@app/core/model/profile-type.enum';
import { AlertType } from '@app/shared/components/alerts/alert';
import { HelperService } from '@app/core/services/helper.service';

/**
 * Всплывающее окно отображения медиа вложения
 *
 * @export
 * @class MediaAttachmentViewComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-media-attachment-view',
  templateUrl: './media-attachment-view.component.html',
  styleUrls: ['./media-attachment-view.component.scss']
})
export class MediaAttachmentViewComponent extends BaseComponent {

  /**
   * Текущий медиа-файл
   *
   * @type {IMediaFile}
   * @memberof MediaFileViewComponent
   */
  mediaFile: IMediaFile;

  /**
   * Массив всех медиа-файлов для слайдера
   *
   * @type {IMediaFile[]}
   * @memberof MediaFileViewComponent
   */
  mediaFiles: IMediaFile[];

  mediaFileType = MediaFileType;

  /**
   * Конфигурация слайдера-карусели
   *
   * @memberof MediaFileViewComponent
   */
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    initialSlide: 0
  };

  constructor(
    public dialogRef: MatDialogRef<MediaAttachmentViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected alertsService: AlertsService,
    public usersService: UsersService,
    public galleryService: GalleryService,
    public helper: HelperService) {

    super(helper);

    // Если массив медиа-файлов пустой (т.е. в блоке есть только 1 файл),
    // то вносим его в массив.
    if (!data.items) {
      if (data.item) {
        data.items = [data.item];
      }
    }

    // указываем текущий медиа-файл и массив медиа-файлов
    if (data.items) {
      this.mediaFiles = data.items;
      if (data.items && data.items.length && data.item) {
        if (data.items[0].id !== data.item.id) {
          this.mediaFile = data.items.find(s => s.id === data.item.id);
          this.slideConfig.initialSlide = this.mediaFiles.indexOf(this.mediaFile);
        } else {
          this.mediaFile = data.item;
        }
      }
    }
  }

  /**
  * Получить заголовок медиа-файла. Если заголовка нет, то возвращается название медиа-файла
  *
  * @param {IMediaFile} mediaFile
  * @returns
  * @memberof MediaFileViewComponent
  */
  getMediaFileTitle(mediaFile: IMediaFile) {
    if (mediaFile && mediaFile.title) {
      return mediaFile.title;
    }
    return '';
  }

  /**
   * Получить ссылку на альбом медиа-файла
   *
   * @param {IMediaFile} mediaFile
   * @memberof MediaFileViewComponent
   */
  getAlbumLink(mediaFile: IMediaFile) {
    if (mediaFile && mediaFile.album) {
      if (mediaFile.albumLink === undefined) {
        if (mediaFile.ownerType === ProfileType.User) {
          mediaFile.albumLink = `/profile`;
        } else {
          mediaFile.albumLink = `/groups`;
        }
        mediaFile.albumLink += `/${mediaFile.ownerId}/gallery/${mediaFile.album.id}`;
        return mediaFile.albumLink;
      } else {
        return mediaFile.albumLink;
      }
    }
    return '';
  }

  /**
   * Переход на страницу альбома
   *
   * @param {IMediaFile} mediaFile
   * @memberof MediaFileViewComponent
   */
  navigateAlbum(mediaFile: IMediaFile) {
    if (mediaFile && mediaFile.album && mediaFile.album.id && mediaFile.ownerId) {
      this.dialogRef.close();
      this.galleryService.navigateAlbum(mediaFile.album.id, mediaFile.ownerId, mediaFile.ownerType);
    } else {
      this.alertsService.alert.next({
        text: 'Невозможно перейти по ссылке на альбом. Обратитесь к администратору.',
        type: AlertType.Error,
        delayHide: 5000
      });
    }
  }

  /**
   * Получить название альбома, в котором находится медиа-файл
   *
   * @param {IMediaFile} mediaFile
   * @returns
   * @memberof MediaFileViewComponent
   */
  getAlbumTitle(mediaFile: IMediaFile) {
    if (mediaFile && mediaFile.album && mediaFile.album.title) {
      return mediaFile.album.title;
    } else {
      return '';
    }
  }

  /**
   * После изменения текущего элемента слайда
   *
   * @param {*} e
   * @memberof MediaFileViewComponent
   */
  onAfterChange(e: any): void {
    const currentSlide: number = e.currentSlide;
    if (this.mediaFiles
      && this.mediaFiles.length
      && this.mediaFiles.length >= currentSlide) {
      // append current mediafile
      this.mediaFile = this.mediaFiles[currentSlide];
    } else {
      console.error(`problems with current mediafile`);
      this.mediaFile = null;
    }
  }
}
