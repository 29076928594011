import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from '@app/profile/services/users.service';
import { VacationsService } from '@app/vacations/services/vacations.service';
import { takeUntil, take, finalize } from 'rxjs/operators';
import { IVacationDay } from '@app/vacations/model/vacation';
import { AlertsService } from '@app/shared/services/alerts.service';
import { Helper } from '@app/core/helpers/helper';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';

/**
 * Оставшиеся дни отпуска
 *
 * @export
 * @class VacationDaysComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-vacation-days',
  templateUrl: './vacation-days.component.html',
  styleUrls: ['./vacation-days.component.scss']
})
export class VacationDaysComponent extends BaseComponent implements OnInit, OnDestroy {

  vacationDays: number;
  vacationDaysSign: string;
  vacations: IVacationDay[];
  loaded: boolean;
  link = `${(<any>window).signalRServer}/_layouts/15/Intry/handlers/VacationApplicationDownload.ashx`;
  // linkBlob: any;

  /**
   * Текст ошибки при попытке получить оставшиеся дни отпуска
   *
   * @type {string}
   * @memberof VacationDaysComponent
   */
  vacationDaysError: string;

  /**
   * Текст ошибки при попытке получить отпуска
   *
   * @type {string}
   * @memberof VacationDaysComponent
   */
  vacationsError: string;

  isCurrent: boolean;

  constructor(
    private usersService: UsersService,
    private vacationsService: VacationsService,
    private alertsService: AlertsService,
    public helper: HelperService,
  ) { super(helper); }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.usersService.user.pipe(takeUntil(this.destroyed$)).subscribe(user => {
        if (user) {
          this.isCurrent = currentUser.id === user.id;
          this.vacationsService.getVacations((<any>window).signalRServer, user.accountName, this.isCurrent)
            .pipe(finalize(() => { this.loaded = true; }), takeUntil(this.destroyed$)).subscribe(vacations => {
              this.vacations = vacations;
              if (this.vacations && this.vacations.length) {
                this.vacationDays = Number(this.vacations[0].vacationDays);
                this.vacationDaysSign = `${this.vacationDays} ${Helper.getNoun(this.vacationDays, 'день', 'дня', 'дней')}`;
              }
            }, error => {
              this.vacationsError = error;
            });
        }
      });
    });
  }

  /**
   * Сформировать заявку на отпуск
   *
   * @memberof VacationDaysComponent
   */
  createVacationRequest(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.vacationsService.createVacationRequest().pipe(take(1)).subscribe((blobData: any) => {
      const blob = new Blob([blobData], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.display = 'none';
      a.href = url;
      a.target = '_blank';
      const d = new Date();
      a.download = `Заявление на отпуск ${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} ${d.getHours()}:${d.getMinutes()}.docx`;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
      this.alertsService.riseSuccess(`Заявление на отпуск сформировано успешно`);
    }, error => {
      this.alertsService.riseError(`Произошла ошибка при формировании заявления на отпуск`);
    });
  }
}
