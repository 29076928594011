import { Component, OnInit, OnDestroy } from '@angular/core';
import { User } from '@app/profile/model/user.model';
import { GroupInfo } from '@app/groups/model/group-info.model';
import { UsersService } from '@app/profile/services/users.service';
import { GroupsService } from '@app/groups/services/groups.service';
import { WidgetBaseComponent } from '@app/shared/components/widget-base.component';
import { HelperService } from '@app/core/services/helper.service';
import { takeUntil, take } from 'rxjs/operators';

@Component({
    selector: 'app-groups-widget',
    templateUrl: 'groups-widget.component.html'
})

export class GroupsWidgetComponent extends WidgetBaseComponent implements OnInit, OnDestroy {
    protected user: User;
    groups: GroupInfo[] = new Array<GroupInfo>();
    groupsCount: number;
    showAddButton = false;

    constructor(
        private usersService: UsersService,
        private groupsService: GroupsService,
        public helper: HelperService
    ) { super(helper); }

    ngOnInit() {
        super.ngOnInit();

        this.usersService.user.pipe(takeUntil(this.destroyed$)).subscribe(user => {
            this.user = user;
            this.getUserGroups();
        });

        this.groupsService.groupDeleted$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(res => {
                this.getUserGroups();
            });
    }

    private getUserGroups(): void {
        this.loaded = false;
        // with current
        this.usersService.currentUser.subscribe(currentUser => {
            // check user
            if (this.user) {
                // simple link for current user
                this.link = `/profile/${this.user.id}/groups`;
                // show "+" when is current user
                this.showAddButton = this.user.id === currentUser.id && this.usersService.isAnyAdmin(currentUser);

                this.showWidget.pipe(takeUntil(this.destroyed$)).subscribe(res => {
                    if (res && (!this.loaded || res !== this.showWidgetOld && this.loaded) && !this.loading) {
                        this.showWidgetOld = res;
                        this.loaded = false;
                        this.loading = true;

                        this.groupsService.getUserGroups(this.user.id, 0, 4).pipe(take(1), takeUntil(this.destroyed$))
                            .subscribe(groupsRes => {
                                this.groups = groupsRes.groups;
                                this.groupsCount = groupsRes.count;
                                // указываем новый набор значений коллег текущего пользователя
                                if (this.user.id === currentUser.id) {
                                    // todo: после правок Андрея внести изменения сюда
                                    this.groupsService.currentUserGroupsCount.next(groupsRes.count);
                                }
                                this.loaded = true;
                                this.loading = false;
                            }, error => {
                                this.error = error;
                                this.loaded = true;
                                this.loading = false;
                            });

                    } else if (res === false) {
                        this.loaded = true;
                    }
                });
            }
        });
    }
}

