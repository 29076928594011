import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { GalleryService } from '@app/gallery/services/gallery.service';
import { Router } from '@angular/router';
import { AlertsService } from '@app/shared/services/alerts.service';
import { UsersService } from '@app/profile/services/users.service';
import { take, finalize } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { Helper } from '@app/core/helpers/helper';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';

/**
 * Создание нового альбома
 *
 * @export
 * @class AlbumNewComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-album-new',
  templateUrl: './album-new.component.html',
  styleUrls: ['./album-new.component.scss']
})
export class AlbumNewComponent extends BaseComponent implements OnInit {
  albumName = '';
  albumNameFocused: boolean;
  albumDescription: string;
  creating: boolean;

  isValid = false;
  isAlreadyExists: boolean;

  cancelLink = 'gallery';

  constructor(
    protected galleryService: GalleryService,
    protected userService: UsersService,
    protected router: Router,
    protected alertsService: AlertsService,
    protected breadcrumbsService: BreadcrumbsService,
    public helper: HelperService
  ) { super(helper); }

  ngOnInit(): void {
    this.userService.currentUser.subscribe(currentUser => {
      const galleryRoute = `/profile/${currentUser.id}/gallery`;
      this.breadcrumbsService.breadcrumbs.next([
        { title: 'Галерея', routerUrl: galleryRoute },
        { title: 'Новый альбом', routerUrl: galleryRoute + '/new' }
      ]);
    });
  }

  /**
   * Создание альбома
   *
   * @protected
   * @memberof AlbumNewComponent
   */
  create() {
    if (this.isValid) {
      // set creating flag
      this.creating = true;
      this.isAlreadyExists = false;

      this.userService.currentUser.subscribe(currentUser => {
        const data = {
          title: this.albumName,
          description: this.albumDescription,
          ownerId: currentUser.id
        };

        // send post request
        this.galleryService.addAlbum(data).pipe(take(1), finalize(() => this.creating = false))
          .subscribe(newAlbumRes => {
            if (newAlbumRes) {
              this.alertsService.riseSuccess(`Альбом с названием '${this.albumName}' успешно создан.`);
              this.router.navigate(['profile', currentUser.id, 'gallery', newAlbumRes.id]);
            } else {
              this.alertsService.riseError(`Произошла ошибка при создании альбома.`);
            }
          }, error => {
            this.alertsService.riseError(`Произошла ошибка при создании альбома.`);
            if (error && error.error && error.error.ErrorCode === 14000) {
              this.isAlreadyExists = true;
            }
          });
      });
    } else {
      console.error(`show not valid error`);
    }
  }

  /**
   * Отмена и возвращение в свою галерею
   *
   * @protected
   * @memberof AlbumNewComponent
   */
  cancel() {
    if (!Helper.routes || !Helper.routes.length) {
      this.router.navigate([this.cancelLink]);
    } else {
      let redirectUrl: string = this.cancelLink;
      let redirectFragment = '';
      for (let index = Helper.routes.length - 1; index < Helper.routes.length; index--) {
        const route = Helper.routes[index];
        if (route !== this.router.url) {
          redirectUrl = route;
          if (redirectUrl.indexOf('#') !== -1) {
            const split = redirectUrl.split('#');
            if (split && split.length) {
              redirectUrl = split[0];
              redirectFragment = split[1];
            }
          }
          break;
        }
      }
      if (redirectUrl) {
        if (redirectFragment) {
          this.router.navigate([redirectUrl], { fragment: redirectFragment });
        } else {
          this.router.navigate([redirectUrl]);
        }
      }
    }
  }

  /**
   * Обработка изменения значений в поле Название
   *
   * @protected
   * @param {*} e
   * @memberof AlbumNewComponent
   */
  onKey(e) {
    this.isAlreadyExists = false;
    this.isValid = e.target.value && /\S/.test(e.target.value) ? true : false;
  }
}
