import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';
import { Subject } from 'rxjs';
import { BannersService } from '@app/banners/services/banners.service';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { HelperService } from '@app/core/services/helper.service';
import { IBanner, IBannerSlideType, IBannerSlide } from '@app/banners/model/banner';
import { take, takeUntil, finalize } from 'rxjs/operators';
import { AlertsService } from '@app/shared/services/alerts.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-banner-settings',
  templateUrl: './banner-settings.component.html',
  styleUrls: ['./banner-settings.component.scss']
})
export class BannerSettingsComponent extends BaseComponent implements OnInit, OnDestroy {

  banner: IBanner;
  currentBanner: IBanner;

  bannerSlideTypes: IBannerSlideType[];
  bannerSlideTypesLoaded: boolean;

  sending: boolean;

  constructor(
    public bannersService: BannersService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private breadcrumbsService: BreadcrumbsService,
    private alertsService: AlertsService,
    public helper: HelperService
  ) { super(helper); }

  ngOnInit() {
    // типы слайдов баннера
    this.bannersService.bannerSlideTypeApiService.getAll().pipe(
      take(1),
      finalize(() => { this.bannerSlideTypesLoaded = true; }),
      takeUntil(this.destroyed$))
      .subscribe(bannerTypes => {
        // заполнение типов слайдов
        this.bannerSlideTypes = bannerTypes.sort((a, b) => b.id - a.id);

        // настройки баннера со слайдами
        this.bannersService.getBanner()
          .pipe(
            take(1),
            finalize(() => this.loaded = true),
            takeUntil(this.destroyed$))
          .subscribe(res => {
            this.banner = res;
            if (this.banner && this.banner.slides && !this.banner.slides.length) {
              this.add();
              this.currentBanner = JSON.parse(JSON.stringify(this.banner));
            } else {
              this.banner.slides.forEach(slide => {
                slide.sliderType = this.bannerSlideTypes.find(s => s.id === slide.type);
              });
            }
          });
      });

    // хлебные крошки
    this.breadcrumbsService.breadcrumbs.next([
      { title: 'Настройка баннера', routerUrl: `/admin/banner-settings` }
    ]);
  }

  moveUp(index: number) {
    this.moveItem(index, index - 1);
    this.checkSlidesOrder();
  }

  moveDown(index: number) {
    this.moveItem(index, index + 1);
    this.checkSlidesOrder();
  }

  private checkSlidesOrder() {
    this.banner.slides.forEach(slide => {
      slide.order = this.banner.slides.indexOf(slide) + 1;
    });
  }

  private moveItem(from, to) {
    // remove `from` item and store it
    const f = this.banner.slides.splice(from, 1)[0];
    // insert stored item into position `to`
    this.banner.slides.splice(to, 0, f);
  }

  remove(index: number) {
    this.banner.slides.splice(index, 1);
    this.checkSlidesOrder();
  }

  add() {
    let max = 1;
    if (this.banner.slides.length) {
      max = Math.max.apply(null, this.banner.slides.map(s => s.order)) + 1;
    }

    this.banner.slides.push({
      id: 0,
      title: '',
      description: '',
      order: max,
      show: true
    });
  }

  setBannerSliderType(bannerSlide: IBannerSlide, sliderType: IBannerSlideType) {
    bannerSlide.sliderType = sliderType;
    bannerSlide.type = sliderType.id;
    bannerSlide.typeColor = sliderType.color;
    bannerSlide.typeIconUrl = sliderType.iconUrl;
  }

  imageChangeEvent(fileInput, item: IBannerSlide) {
    const files = fileInput.target.files;
    if (files && files[0]) {
      const formData: FormData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i], files[i].name);
      }
      this.bannersService.uploadBannerSlideImage(formData).subscribe(res => {
        if (res) {
          if (res.url) {
            // new picture
            item.imageUrl = res.url;
          } else {
            item.imageUrl = '';
          }
        }
      }, error => {
        console.log(error);
      });
    }
  }

  removeImage(item: IBannerSlide) {
    item.imageUrl = '';
  }

  submit() {
    if (this.isValid()) {
      this.sending = true;
      this.bannersService.saveBanner(this.banner)
        .pipe(take(1), finalize(() => this.sending = false))
        .subscribe(res => {
          if (res) {
            this.alertsService.riseSuccess('Баннер был успешно сохранён.');
            this.router.navigate(['..'], { relativeTo: this.route });
          } else {
            this.alertsService.riseError('Произошла ошибка при отправке формы. Обратитесь к администратору.');
          }
        }, error => {
          this.alertsService.riseError('Произошла ошибка при отправке формы. Обратитесь к администратору.');
          console.log(error);
        });
    } else {
      this.alertsService.riseError('Произошла ошибка при отправке формы. Не заполнены обязательные поля');
    }
  }

  /**
   * Отмена сохранения баннера
   */
  cancel() {
    if (this.currentBanner && this.banner
      && (this.currentBanner.autoSlide !== this.banner.autoSlide
        || this.currentBanner.slides.length !== this.banner.slides.length)
    ) {
      this.dialog.open(ModalConfirmationComponent, {
        data: {
          text: `Вы действительно хотите отменить сохранение?`,
          onOk: this.goBackToProfile.bind(this),
          okText: 'Да'
        }
      });
    } else {
      this.goBackToProfile();
    }
  }

  private goBackToProfile() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  isValid(): boolean {
    return this.banner
      && (!this.banner.slides
        || (this.banner.slides && !this.banner.slides.find(s => !s.order || !s.type)));
  }
}
