import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { IGift } from '@app/gifts/model/gift';
import { GiftService } from '@app/gifts/services/gift.service';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-gift-view',
  templateUrl: './gift-view.component.html',
  styleUrls: ['./gift-view.component.scss']
})
export class GiftViewComponent extends BaseComponent implements OnInit {

  gift: IGift;

  sending: boolean;

  constructor(
    public giftService: GiftService,
    protected router: Router,
    protected route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService,
    public helper: HelperService
  ) { super(helper); }

  ngOnInit(): void {
    this.route.params
      .pipe(
        takeUntil(this.destroyed$),
        switchMap((params: Params) => {
          this.gift = null;
          const giftId = +params['giftId'];
          return this.giftService.getById(giftId);
        }))
      .subscribe(gift => {
        this.gift = gift;

        if (this.gift) {
          const pageRoute = `/profile/gifts`;
          this.breadcrumbsService.breadcrumbs.next([
            { title: 'Магазин подарков', routerUrl: pageRoute },
            { title: gift.title, routerUrl: pageRoute + `/${this.gift.id}` }
          ]);
        } else {
          this.router.navigate(['/404'], { skipLocationChange: true });
        }

        this.loaded = true;
        this.error = '';
      }, error => {
        this.loaded = true;
        this.error = error;
      });
  }
}
