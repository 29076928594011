<div class="header__icon" [ngClass]="{_active:visible}" >
<div class="btn notification icon _header-notification" (click)="toggle($event)">
  <span class="badge" *ngIf="count > 0">{{count}}</span>
  <div class="notification__body" [ngClass]="{_visible:visible}" *ngIf="!loading && visible && !notifications?.length"
    appClickOutside (clickOutside)="clickOutside()">
    <div class="no-data"><img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" class="no-data__img">
      <div class="no-data__text"> Здесь вы узнаете обо всех событиях,<br>требующих вашего внимания.
      </div>
    </div>
  </div>
  <div class="notification__body" [ngClass]="{_visible:visible}" *ngIf="visible" appClickOutside
    (clickOutside)="clickOutside()">
    <div class="notification__header">
      <button type="button" class="btn icon _close-black notification__close"></button><span
        class="header-text _low notification__title"><span class="header-text__text">Уведомления</span>&nbsp;
        <span class="header-text__count" *ngIf="count">{{count}}</span>
      </span>
      <a href="#" class="notification__clear link _progress" (click)="setAllViewed()"
        *ngIf="hasNoViewed()">Отметить все как
        прочитанные</a>
      <button mat-button [matMenuTriggerFor]="menu" type="button" title="Фильтры" class="btn btn-filter"
        (click)="filterClick($event)"></button>
      <mat-menu #menu="matMenu">
        <div class="icon-menu" #menuContainer>
          <a mat-menu-item href="javascript:;" class="icon-menu__item" (click)="categoryClick($event, category)"
            *ngFor="let category of categories">
            <span class="icon-menu__icon">
              <img *ngIf="category.selected" src="{{assetsPrefix}}/assets/images/icons/menu/check.svg"
                class="icon-menu__img">
            </span>
            <span>{{category.name}}</span>
          </a>
        </div>
      </mat-menu>
    </div>
    <div class="notification__content" appInfiniteScroll scrollPercent=70 immediateCallback="true"
      (scrollCallback)="scrollCallback()">
      <div class="notification-item" *ngFor="let notification of notifications"
        [ngClass]="{_new: !notification.viewed}">
        <div class="notification-item__avatar">
          <app-user-avatar [user]="notification.initiator" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'">
          </app-user-avatar>
        </div>
        <div class="notification-item__content" (click)="handle(notification, $event)">
          <div class="notification-item__date pull-right hidden-phone">{{notification.created | dateSpLike}}</div>
          <a routerLink="/profile/{{notification.initiator?.id}}" class="notification-item__name"
            (click)="handleInitiator(notification, $event)">{{notification.initiator.fullName}}</a>
          <div class="notification-item__event" [ngSwitch]="notification.type">
            <ng-container *ngSwitchCase="notificationType.Subsribed">
              Подписался на вас
            </ng-container>
            <ng-container *ngSwitchCase="notificationType.SharedFile">
              Поделился(-лась) с Вами файлом <a [attr.href]="getExternalNotificationData(notification)?.url">{{getExternalNotificationData(notification)?.title}}</a>
            </ng-container>
            <ng-container *ngSwitchCase="notificationType.SharedGroupFile">
              Поделился(-лась) с Вами файлом <a [attr.href]="getExternalNotificationData(notification)?.url">{{getExternalNotificationData(notification)?.title}}</a>
            </ng-container>
            <ng-container *ngSwitchCase="notificationType.AddedToGroup">
              Вы добавлены в группу <a routerLink="/group/{{getNotificationDataWithId(notification)?.id}}">{{getNotificationDataWithId(notification)?.title}}</a>
            </ng-container>
            <ng-container *ngSwitchCase="notificationType.RemovedFromGroup">
              Вы исключены из группы <a routerLink="/group/{{getNotificationDataWithId(notification)?.id}}">{{getNotificationDataWithId(notification)?.title}}</a>
            </ng-container>
            <ng-container *ngSwitchCase="notificationType.GroupJoinApproved">
              Одобрил(-а) Ваш запрос на вступление в группу <a
                routerLink="/group/{{getNotificationDataWithId(notification)?.id}}">{{getNotificationDataWithId(notification)?.title}}</a>
            </ng-container>
            <ng-container *ngSwitchCase="notificationType.GroupJoinRejected">
              Отклонил(-а) Ваш запрос на вступление в группу <a
                routerLink="/group/{{getNotificationDataWithId(notification)?.id}}">{{getNotificationDataWithId(notification)?.title}}</a>
            </ng-container>
            <ng-container *ngSwitchCase="notificationType.GroupSubscriptionApproved">
              Одобрил(-а) Ваш запрос на подписание в группу <a
                routerLink="/group/{{getNotificationDataWithId(notification)?.id}}">{{getNotificationDataWithId(notification)?.title}}</a>
            </ng-container>
            <ng-container *ngSwitchCase="notificationType.GroupSubscriptionRejected">
              Отклонил(-а) Ваш запрос на подписание в группу <a
                routerLink="/group/{{getNotificationDataWithId(notification)?.id}}">{{getNotificationDataWithId(notification)?.title}}</a>
            </ng-container>

            <!-- group join/subscribe request -->
            <ng-container *ngSwitchCase="notificationType.GroupJoinRequest">
              Отправил(-а) запрос на вступление в группу <a
                routerLink="/group/{{getNotificationDataWithId(notification)?.id}}">{{getNotificationDataWithId(notification)?.title}}</a>
            </ng-container>
            <ng-container *ngSwitchCase="notificationType.GroupSubscriptionRequest">
              Отправил(-а) запрос на подписание в группу <a
                routerLink="/group/{{getNotificationDataWithId(notification)?.id}}">{{getNotificationDataWithId(notification)?.title}}</a>
            </ng-container>

            <!-- mentions -->
            <ng-container *ngSwitchCase="notificationType.PostMention">
              Упомянул(-а) Вас в <a
                routerLink="/profile/{{getPostCommentNotificationData(notification)?.feedId}}/post/{{getPostCommentNotificationData(notification)?.postId}}">публикации</a>
            </ng-container>
            <ng-container *ngSwitchCase="notificationType.PostCommentMention">
              Упомянул(-а) Вас в <a
                routerLink="/profile/{{getPostCommentNotificationData(notification)?.feedId}}/post/{{getPostCommentNotificationData(notification)?.postId}}"
                fragment="comment={{getPostCommentNotificationData(notification)?.commentId}}">комментарии</a>
            </ng-container>

            <!-- group mentions -->
            <ng-container *ngSwitchCase="notificationType.GroupPostMention">
              Упомянул(-а) Вас в <a
                routerLink="/group/{{getGroupPostCommentNotificationData(notification)?.groupId}}/post/{{getGroupPostCommentNotificationData(notification)?.postId}}">публикации</a>
            </ng-container>
            <ng-container *ngSwitchCase="notificationType.GroupCommentMention">
              Упомянул(-а) Вас в <a
                routerLink="/group/{{getGroupPostCommentNotificationData(notification)?.groupId}}/post/{{getGroupPostCommentNotificationData(notification)?.postId}}"
                fragment="comment={{getGroupPostCommentNotificationData(notification)?.commentId}}">комментарии</a>
            </ng-container>

            <!-- likes -->
            <ng-container *ngSwitchCase="notificationType.PostLike">
              Лайкнул(-а) Вашу <a
                routerLink="/profile/{{getPostCommentNotificationData(notification)?.feedId}}/post/{{getPostCommentNotificationData(notification)?.postId}}">публикацию</a>
            </ng-container>
            <ng-container *ngSwitchCase="notificationType.PostCommentLike">
              Лайкнул(-а) Ваш <a routerLink="/profile/{{getPostCommentNotificationData(notification)?.feedId}}/post/{{getPostCommentNotificationData(notification)?.postId}}"
                fragment="comment={{getPostCommentNotificationData(notification)?.commentId}}">комментарий</a>
            </ng-container>

            <!-- group likes -->
            <ng-container *ngSwitchCase="notificationType.GroupPostLike">
              Лайкнул(-а) Вашу <a
                routerLink="/group/{{getGroupPostCommentNotificationData(notification)?.groupId}}/post/{{getGroupPostCommentNotificationData(notification)?.postId}}">публикацию</a>
            </ng-container>
            <ng-container *ngSwitchCase="notificationType.GroupCommentLike">
              Лайкнул(-а) Ваш <a routerLink="/group/{{getGroupPostCommentNotificationData(notification)?.groupId}}/post/{{getGroupPostCommentNotificationData(notification)?.postId}}"
                fragment="comment={{getGroupPostCommentNotificationData(notification)?.commentId}}">комментарий</a>
            </ng-container>

            <!-- responses on posts/comments -->
            <ng-container *ngSwitchCase="notificationType.PostResponse">
              Ответил(-а) на Вашу <a
                routerLink="/profile/{{getPostCommentNotificationData(notification)?.feedId}}/post/{{getPostCommentNotificationData(notification)?.postId}}">публикацию</a>
            </ng-container>
            <ng-container *ngSwitchCase="notificationType.PostCommentResponse">
              Ответил(-а) на Ваш <a
                routerLink="/profile/{{getPostCommentNotificationData(notification)?.feedId}}/post/{{getPostCommentNotificationData(notification)?.postId}}"
                fragment="comment={{getPostCommentNotificationData(notification)?.commentId}}">комментарий</a>
            </ng-container>

            <!-- responses on group posts/comments -->
            <ng-container *ngSwitchCase="notificationType.GroupPostResponse">
              Ответил(-а) на Вашу <a
                routerLink="/group/{{getGroupPostCommentNotificationData(notification)?.groupId}}/post/{{getGroupPostCommentNotificationData(notification)?.postId}}">публикацию</a>
            </ng-container>
            <ng-container *ngSwitchCase="notificationType.GroupCommentResponse">
              Ответил(-а) на Ваш <a
                routerLink="/group/{{getGroupPostCommentNotificationData(notification)?.groupId}}/post/{{getGroupPostCommentNotificationData(notification)?.postId}}"
                fragment="comment={{getGroupPostCommentNotificationData(notification)?.commentId}}">комментарий</a>
            </ng-container>

            <!-- responses on posts/comments for mentioned -->
            <ng-container *ngSwitchCase="notificationType.PostResponseForMentioned">
              Ответил(-а) на <a
                routerLink="/profile/{{getPostCommentNotificationData(notification)?.feedId}}/post/{{getPostCommentNotificationData(notification)?.postId}}">публикацию</a>
            </ng-container>
            <ng-container *ngSwitchCase="notificationType.PostCommentResponseForMentioned">
              Ответил(-а) на <a routerLink="/profile/{{getPostCommentNotificationData(notification)?.feedId}}/post/{{getPostCommentNotificationData(notification)?.postId}}"
                fragment="comment={{getPostCommentNotificationData(notification)?.commentId}}">комментарий</a>
            </ng-container>

            <!-- responses on group posts/comments for mentioned -->
            <ng-container *ngSwitchCase="notificationType.GroupPostResponseForMentioned">
              Ответил(-а) на <a
                routerLink="/group/{{getGroupPostCommentNotificationData(notification)?.groupId}}/post/{{getGroupPostCommentNotificationData(notification)?.postId}}">публикацию</a>
            </ng-container>
            <ng-container *ngSwitchCase="notificationType.GroupCommentResponseForMentioned">
              Ответил(-а) на <a routerLink="/group/{{getGroupPostCommentNotificationData(notification)?.groupId}}/post/{{getGroupPostCommentNotificationData(notification)?.postId}}"
                fragment="comment={{getGroupPostCommentNotificationData(notification)?.commentId}}">комментарий</a>
            </ng-container>

            <!-- новый отклик на вакансию -->
            <ng-container *ngSwitchCase="notificationType.NewVacancyResponse">
              Откликнулся(-лась) на вакансию <a
                routerLink="/group/{{getVacancyResponseNotificationData(notification)?.groupId}}/vacancy/{{getVacancyResponseNotificationData(notification)?.vacancyId}}/response/{{getVacancyResponseNotificationData(notification)?.responseId}}">{{getVacancyResponseNotificationData(notification)?.title}}</a>
            </ng-container>

            <!-- новая идея -->
            <ng-container *ngSwitchCase="notificationType.NewIdea">
              Добавил(-а) идею <a
                routerLink="/group/{{getVacancyResponseNotificationData(notification)?.groupId}}/ideas/{{getVacancyResponseNotificationData(notification)?.id}}">{{getVacancyResponseNotificationData(notification)?.title}}</a>
            </ng-container>

            <!-- новое Спасибо -->
            <ng-container *ngSwitchCase="notificationType.ThanksAdded">
              отправил(а) Вам <a routerLink="/profile/{{currentUser?.id}}/thanks/{{getNotificationDataWithId(notification)?.id}}">Спасибо</a>
            </ng-container>

            <!-- День рождения коллеги -->
            <ng-container *ngSwitchCase="notificationType.BirthdayToday">
              празднует сегодня свой день рождения
            </ng-container>

            <!-- Завершение опроса пользователем -->
            <ng-container *ngSwitchCase="notificationType.TakeSurvey">
              завершил(-а) опрос
              <a routerLink="/group/{{getVacancyResponseNotificationData(notification)?.groupId}}/surveys/{{getVacancyResponseNotificationData(notification)?.id}}">{{getVacancyResponseNotificationData(notification)?.title}}</a>
            </ng-container>

            <!-- Истечение срока опроса -->
            <ng-container *ngSwitchCase="notificationType.SurveyExpired">
              Завтра завершается срок прохождения опроса
              <a routerLink="/group/{{getVacancyResponseNotificationData(notification)?.groupId}}/surveys/{{getVacancyResponseNotificationData(notification)?.id}}">{{getVacancyResponseNotificationData(notification)?.title}}</a>
            </ng-container>

            <!-- Уведомление о награда -->
            <ng-container *ngSwitchCase="notificationType.AwardAdded">
              добавил(а) Вам награду <a routerLink="/profile/{{currentUser?.id}}/awards">{{getExternalNotificationData(notification)?.title}}</a>
            </ng-container>

          </div>
          <div class="notification-item__date hidden-desktop hidden-tablet">{{notification.created | dateSpLike}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
