<!-- List of posts -->
<ng-container *ngFor="let post of posts; trackBy:trackBy">
  <app-post [post]="post" [user]="user" [feedType]="getFeedType()" (remove)="onRemove($event)"></app-post>
</ng-container>
<!-- List of Posts END -->
<div class="feed-spinner" *ngIf="loaded && loading">
  <mat-spinner [diameter]="56"></mat-spinner>
</div>
<!-- preloaders -->
<app-feed-preloader [loaded]="loaded" [loading]="loading"></app-feed-preloader>
<!-- preloaders END -->