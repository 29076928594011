import { Directive, ElementRef, Input, Output, HostListener, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appInfiniteScroll]'
})

export class InfiniteScrollDirective {

  private position = 0;
  private scrollHeight = 0;

  @Output()
  public scrollCallback = new EventEmitter();

  @Input()
  public scrollPercent = 70;

  constructor(private element: ElementRef) { }

  @HostListener('scroll', ['$event'])
  private onScroll(event: Event): void {
    const element = (event.target as Element);
    const position: number = element.scrollTop;

    if (this.scrollCallback &&
      this.position < position &&
      ((position + element.clientHeight) / element.scrollHeight) > (this.scrollPercent / 100) &&
      this.scrollHeight < element.scrollHeight) {
      this.scrollCallback.emit(event);
      this.scrollHeight = element.scrollHeight;
    }

    this.position = position;
  }

}
