import { Component, OnInit, OnDestroy } from '@angular/core';
import { PostViewComponent } from '@app/feed/components/post-view/post-view.component';
import { UsersService } from '@app/profile/services/users.service';
import { FeedService } from '@app/feed/services/feed.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from '@app/core/services/helper.service';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-profile-post-view',
  templateUrl: './profile-post-view.component.html',
  styleUrls: ['./profile-post-view.component.css']
})
export class ProfilePostViewComponent extends PostViewComponent implements OnInit, OnDestroy {

  constructor(
    protected usersService: UsersService,
    protected feedService: FeedService,
    protected route: ActivatedRoute,
    protected router: Router,
    public helper: HelperService
  ) { super(usersService, feedService, route, router, helper); }

  ngOnInit() {

    this.usersService.user.pipe(takeUntil(this.destroyed$)).subscribe(user => {
      this.user = user;
    });

    super.ngOnInit();
  }
}
