import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Helper } from '@app/core/helpers/helper';
import { HelperService } from '@app/core/services/helper.service';
import { UsersService } from '@app/profile/services/users.service';
import { environment } from '@env/environment';
import { FileUploadV2Service, IFileUpload } from './file-upload-v2.service';

export interface IMediaFileUpload extends IFileUpload {
  albumId: number;
}

@Injectable({
  providedIn: 'root'
})
export class MediaFileUploadV2Service extends FileUploadV2Service {

  protected serviceUrl = `${environment.siteUrl}/api/documentFile`;

  protected uploadedFiles: Array<IMediaFileUpload> = [];

  private albumId: number;

  constructor(
    protected http: HttpClient,
    protected usersService: UsersService,
    public helper: HelperService) {
    super(http, usersService, helper);
  }

  uploadMediaFile(
    albumId: number,
    fileName: string,
    file: any,
    overwrite: boolean,
    authorId: number,
    ownerId: number,
  ) {

    const newUpload: IMediaFileUpload = {
      guid: Helper.guid(),
      albumId,
      ownerId,
      fileName,
      startTime: new Date(),
      digestTime: new Date(),
      endTime: null,
      authorId,
      overwrite,
    };

    this.albumId = albumId;

    return this.uploadFileInternal(newUpload, fileName, file);
  }

  protected getUploadMethod(guid: string, chunkIndex: number, chunkCount: number) {
    if (chunkIndex === chunkCount - 1) {
      return `${environment.siteUrl}/api/gallery/album/${this.albumId}/uploadMediaFile/${guid}/finish`;
    } else if (chunkIndex === 0) {
      return `upload/${guid}/start`;
    } else if (chunkIndex < chunkCount) {
      return `upload/${guid}/continue`;
    }
    return null;
  }
}
