import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { GroupCardComponent } from '@app/groups/components/group/group-card/group-card.component';
import { GroupNewComponent } from '@app/groups/components/group/group-form/group-new.component';
import { GroupViewComponent } from '@app/groups/components/group/group-form/group-view.component';
import { GroupMenuComponent } from '@app/groups/components/group/group-menu/group-menu.component';
import { GroupSmallHeaderComponent } from '@app/groups/components/group/group-small-header/group-small-header.component';
import { GroupUsersComponent } from '@app/groups/components/group/group-users/group-users.component';
import { GroupsListComponent } from '@app/groups/components/groups-list/groups-list.component';
import { GroupsWidgetComponent } from '@app/groups/components/groups-widget/groups-widget.component';
import { SharedModule } from '@app/shared/shared.module';
import { GroupUsersListComponent } from '@app/groups/components/group/group-users/group-users-list.component';
import { FilesModule } from '@app/files/files.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FeedModule } from '@app/feed/feed.module';
import { GroupFeedComponent } from '@app/groups/components/feed/group-feed/group-feed.component';
import { AttachmentsModule } from '@app/attachments/file-attachments/attachments.module';
import { CommentsModule } from '@app/comments/comments.module';
import { LikesModule } from '@app/likes/likes.module';
import { GroupPostViewComponent } from '@app/groups/components/feed/group-post-view/group-post-view.component';
import { GroupFilesListComponent } from '@app/groups/components/group/group-files-list/group-files-list.component';
import { GroupSearchResultsComponent } from '@app/groups/components/group/group-search-results/group-search-results.component';
import { GroupPostComponent } from '@app/groups/components/feed/group-post/group-post.component';
import { GroupGalleryWidgetComponent } from '@app/groups/components/gallery/group-gallery-widget/group-gallery-widget.component';
import { GroupGalleryMainComponent } from '@app/groups/components/gallery/group-gallery-main/group-gallery-main.component';
import { GroupGalleryViewComponent } from '@app/groups/components/gallery/group-gallery-view/group-gallery-view.component';
import { GroupAlbumListComponent } from '@app/groups/components/gallery/group-album-list/group-album-list.component';
import { GroupMediaListComponent } from '@app/groups/components/gallery/group-media-list/group-media-list.component';
import { GalleryModule } from '@app/gallery/gallery.module';
import { GroupAlbumNewComponent } from '@app/groups/components/gallery/group-album-new/group-album-new.component';
import { GroupsService } from '@app/groups/services/groups.service';
import { GroupAlbumViewComponent } from '@app/groups/components/gallery/group-album-view/group-album-view.component';
import { MediaAttachmentsModule } from '@app/attachments/media-attachments/media-attachments.module';
import { GroupSettingsComponent } from '@app/groups/components/group/group-settings/group-settings.component';
import { GroupSurveyListComponent } from '@app/groups/components/surveys/group-survey-list/group-survey-list.component';
import { SurveysModule } from '@app/surveys/surveys.module';
import { GroupSurveysWidgetComponent } from '@app/groups/components/surveys/group-surveys-widget/group-surveys-widget.component';
import { GroupVacancyListComponent } from '@app/groups/components/vacancy/group-vacancy-list/group-vacancy-list.component';
import { GroupVacancyWidgetComponent } from '@app/groups/components/vacancy/group-vacancy-widget/group-vacancy-widget.component';
import { GroupServiceComponent } from '@app/groups/components/group/group-service/group-service.component';
import { VacancyModule } from '@app/vacancy/vacancy.module';
import { PagesModule } from '@app/pages/pages.module';
import { IdeasModule } from '@app/ideas/ideas.module';
import { GroupIdeaListComponent } from '@app/groups/components/ideas/group-idea-list/group-idea-list.component';
import { GroupIdeasWidgetComponent } from '@app/groups/components/ideas/group-ideas-widget/group-ideas-widget.component';
import { GroupPageListComponent } from '@app/groups/components/pages/group-page-list/group-page-list.component';
import { GroupForbiddenComponent } from '@app/groups/components/group/group-forbidden/group-forbidden.component';
import { GroupAddUsersComponent } from '@app/groups/components/group/group-add-users/group-add-users.component';
import { GroupsApiService } from '@app/groups/services/groups-api.service';
import { SearchSharedModule } from '@app/search/shared/search-shared.module';
import { GroupsWidgetSmallComponent } from '@app/groups/components/groups-widget-small/groups-widget-small.component';
import { OnboardingModule } from '@app/onboarding/onboarding.module';
import { GroupVacancyHhListComponent } from '@app/groups/components/vacancy/group-vacancy-hh-list/group-vacancy-hh-list.component';
import { GroupPostShareComponent } from '@app/groups/components/feed/group-post-share/group-post-share.component';
import { GroupsRoutingModule } from '@app/groups/groups.routing';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        BrowserModule,
        HttpClientModule,
        GroupsRoutingModule,
        SharedModule,
        FilesModule,
        MatMenuModule,
        MatDialogModule,
        FeedModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        CommentsModule,
        AttachmentsModule,
        MediaAttachmentsModule,
        LikesModule,
        GalleryModule,
        SurveysModule,
        VacancyModule,
        PagesModule,
        SearchSharedModule,
        OnboardingModule,
        IdeasModule
    ],
    declarations: [
        GroupCardComponent,
        GroupNewComponent,
        GroupViewComponent,
        GroupMenuComponent,
        GroupSmallHeaderComponent,
        GroupUsersComponent,
        GroupUsersListComponent,
        GroupsListComponent,
        GroupsWidgetComponent,
        GroupFilesListComponent,
        GroupPostViewComponent,
        GroupPostComponent,
        GroupFeedComponent,
        GroupSearchResultsComponent,
        GroupGalleryWidgetComponent,
        GroupGalleryMainComponent,
        GroupGalleryViewComponent,
        GroupAlbumListComponent,
        GroupMediaListComponent,
        GroupAlbumNewComponent,
        GroupAlbumViewComponent,
        GroupSettingsComponent,
        GroupSurveyListComponent,
        GroupSurveysWidgetComponent,
        GroupVacancyListComponent,
        GroupVacancyWidgetComponent,
        GroupServiceComponent,
        GroupIdeaListComponent,
        GroupIdeasWidgetComponent,
        GroupPageListComponent,
        GroupForbiddenComponent,
        GroupAddUsersComponent,
        GroupsWidgetSmallComponent,
        GroupVacancyHhListComponent,
        GroupPostShareComponent,
    ],
    exports: [
        GroupCardComponent,
        GroupNewComponent,
        GroupViewComponent,
        GroupMenuComponent,
        GroupSmallHeaderComponent,
        GroupUsersComponent,
        GroupUsersListComponent,
        GroupsListComponent,
        GroupsWidgetComponent,
        GroupFeedComponent,
        GroupsWidgetSmallComponent,
        PagesModule,
        RouterModule
    ],
    providers: [
        GroupsService,
        GroupsApiService
    ],
})
export class GroupsModule { }
