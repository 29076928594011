<div class="card _pages" *ngIf="loaded && showWidget">
  <div class="card__title">
    <app-pages-menu [showAddButton]="showAddButton" [groupId]="group?.id" [bigPlus]="true"></app-pages-menu>
    <span class="header-text">
      <a class="header-text__text" (click)="navigate()" [routerLink]="link">Страницы</a>
    </span>
  </div>
  <div class="card__content">
    <ng-container *ngFor="let page of pages; trackBy:page?.id">
      <a class="link _block" *ngIf="page.isLink" [attr.href]="pagesService.getPageUrl(page)" [attr.target]="page.inNewTab ? '_blank': '_self'">{{page.title}}</a>
      <a class="link _block" *ngIf="!page.isLink" [routerLink]="pagesService.getPageUrl(page)" [attr.target]="page.inNewTab ? '_blank': '_self'" (click)="pagesService.navigatePage($event, page)" >{{page.title}}</a>
    </ng-container>
  </div>
</div>