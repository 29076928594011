import { Injectable } from '@angular/core';
import { WebStorageService } from './web-storage.service';

@Injectable()
export class LocalStorageService extends WebStorageService {

  public constructor() {
    super();
  }

  protected  getNativeStorage(): Storage {
    return window.localStorage;
  }

}
