import { Component, OnInit, Input, OnDestroy, NgZone } from '@angular/core';
import { GroupInfo } from '@app/groups/model/group-info.model';
import { FilesService } from '@app/files/services/files.service';
import { GroupsService } from '@app/groups/services/groups.service';
import { Router } from '@angular/router';
import { Helper } from '@app/core/helpers/helper';
import { IGroupUserResult } from '@app/signalr/group.hub';
import { SignalRService } from '@app/signalr/signalR.service';
import { FilesWidgetComponent } from '@app/files/components/files-widget/files-widget.component';
import { take, takeUntil } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { FilesDialogService } from '@app/files/services/files-dialog.service';
import { UsersService } from '@app/profile/services/users.service';
import { User } from '@app/profile/model/user.model';

/**
 * Виджет файлов группы
 *
 * @export
 * @class GroupFilesWidgetComponent
 * @extends {FilesWidgetComponent}
 */
@Component({
  selector: 'app-group-files-widget',
  templateUrl: 'files-widget.component.html',
})
export class GroupFilesWidgetComponent extends FilesWidgetComponent implements OnInit, OnDestroy {
  /**
   * Группа для которой отображается виджет
   *
   * @type {GroupInfo}
   * @memberof GroupFilesWidgetComponent
   */
  @Input() group: GroupInfo;
  currentUser: User;

  constructor(
    private usersService: UsersService,
    private groupsService: GroupsService,
    public filesService: FilesService,
    private signalRService: SignalRService,
    private ngZone: NgZone,
    private router: Router,
    protected filesDialog: FilesDialogService,
    public helper: HelperService,
  ) {
    super(filesService, filesDialog, helper);
  }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;
      this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
        if (group) {
          this.group = group;
          this.load();
        }
      });

      this.signalRService.groupHub.onSubscribeForCurrent.subscribe((res: IGroupUserResult) => {
        this.operateJoinSubscribe(res);
      });

      this.signalRService.groupHub.onJoinForCurrent.subscribe((res: IGroupUserResult) => {
        this.operateJoinSubscribe(res);
      });

      this.signalRService.groupHub.onUnsubscribeForCurrent.subscribe((res: IGroupUserResult) => {
        this.operateJoinSubscribe(res);
      });

      this.signalRService.groupHub.onLeaveForCurrent.subscribe((res: IGroupUserResult) => {
        this.operateJoinSubscribe(res);
      });
    });
  }

  navigate(force: boolean = false) {
    if (force) {
      this.router.navigate([this.link], { fragment: `d=${new Date().getTime()}` });
    } else {
      this.router.navigate([this.link]);
    }
  }

  filter(res: string) {
    this.items = this.items.filter(item => item.url.indexOf(res) !== -1);
  }

  getItems(force: boolean) {
    this.loading = true;
    this.loaded = false;
    const ownerId = this.groupsService.currentGroup.getValue()?.id;
    if (ownerId) {
      this.filesService
        .getLastListData(ownerId, 0, 4, true)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          filesList => {
            this.filesService.newWOPIDocumentEnabled = filesList.newWOPIDocumentEnabled;
            this.loaded = true;
            this.loading = false;
            if (filesList) {
              this.items = filesList.items;
            }
          },
          error => {
            this.error = error;
            this.loaded = true;
            this.loading = false;
          },
        );
    } else {
      // no ownerId
      this.items = [];
    }
  }

  onFilesAdded(folder: string) {
    this.router.navigate([`group/${this.group.id}/files`], {
      fragment: `folder=${folder}&d=${new Date().getMilliseconds()}`,
    });
  }

  private load() {
    this.showWidget = this.groupsService.canReadGroup(this.group, this.currentUser);
    this.link = `/group/${this.group.id}/files`;

    if (this.showWidget) {
      this.getItems(false);
    } else {
      this.loaded = true;
    }

    if (this.groupsService.isParticipant(this.group, this.currentUser)) {
      this.showAddButton = true;
      this.config.allowShare = this.config.allowDelete = this.config.allowMove = this.config.allowRename = true;
    }
  }

  private operateJoinSubscribe(res: IGroupUserResult) {
    this.ngZone.run(() => {
      if (res && res.result && this.group.id === res.group.id) {
        this.group = res.group;
        this.showWidget = this.groupsService.canReadGroup(res.group, this.currentUser);
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
