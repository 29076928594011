import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainViewComponent } from './components/main-view/main-view.component';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '@app/shared/shared.module';
import { ProfileModule } from '@app/profile/profile.module';
import { BannersModule } from '@app/banners/banners.module';
import { FeedModule } from '@app/feed/feed.module';
import { HeaderModule } from '@app/header/header.module';
import { EmployeesModule } from '@app/employees/employees.module';
import { ThanksModule } from '@app/thanks/thanks.module';
import { BirthdaysModule } from '@app/birthdays/birthdays.module';
import { CalendarModule } from '@app/calendar/calendar.module';
import { OnboardingModule } from '@app/onboarding/onboarding.module';
import { AnniversaryModule } from '@app/anniversary/anniversary.module';
import { IntryAutoLoginGuard } from '@app/auth/shared/guards/intry-auto-login.guard';
import { GetCurrentUserResolver } from '@app/groups/resolvers/current-user-resolver';

const routes: Routes = [
  {
    path: '', component: MainViewComponent,
    canActivate: [IntryAutoLoginGuard],
    // resolve: { currentUser: GetCurrentUserResolver }
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forChild(routes),
    SharedModule,
    ProfileModule,
    BannersModule,
    HeaderModule,
    FeedModule,
    EmployeesModule,
    ThanksModule,
    BirthdaysModule,
    OnboardingModule,
    CalendarModule,
    AnniversaryModule,
  ],
  declarations: [
    MainViewComponent
  ],
  providers: [
    // GetCurrentUserResolver,
  ],
})
export class MainModule { }
