<div class="menu-list" [ngClass]="{'_horizontal': orientation === MenuOrientation.horizontal, '_mega': megaMenu}">
  <ng-container *ngFor="let page of pagesTree; let i=index">
    <ng-container *ngTemplateOutlet="pageTreeNode; context: {$implicit: {page:page, level:1}}"></ng-container>
  </ng-container>
</div>

<ng-template #pageTreeNode let-data>
  <!-- ссылка для мобилки для открытия меню-->
  <a class="menu-list__item _anchor" *ngIf="data.page?.children?.length && orientation !== MenuOrientation.horizontal"
    href="#menu{{data.page.id}}" (click)="onAnchorClick($event, data.page.id)">{{data.page.title}}</a>

  <div *ngIf="orientation !== MenuOrientation.horizontal">
    <a class="menu-list__item" *ngIf="data.page.isLink" [attr.href]="pagesService.getPageUrl(data.page)"
      [attr.target]="data.page.inNewTab ? '_blank': '_self'"
      [ngClass]="{'_complex': data.page?.children?.length}">{{data.page.title}}</a>

    <a class="menu-list__item" *ngIf="!data.page.isLink" [routerLink]="pagesService.getPageUrl(data.page)"
      [attr.target]="data.page.inNewTab ? '_blank': '_self'" (click)="pagesService.navigatePage($event, data.page)"
      [ngClass]="{'_complex': data.page?.children?.length}">{{data.page.title}}</a>

    <div id="menu{{data.page.id}}" class="menu-list" *ngIf="data.page?.children?.length">
      <div class="menu-list__back">
        <a href="#" class="back _white _simple" (click)="onBackClick($event)">
          <span class="header-text _light">
            <span class="header-text__text">Назад</span>
          </span>
        </a>
      </div>

      <a class="menu-list__item _top" *ngIf="!data.page.isLink" [routerLink]="pagesService.getPageUrl(data.page)"
        [attr.target]="data.page.inNewTab ? '_blank': '_self'"
        (click)="pagesService.navigatePage($event, data.page)">{{data.page.title}}</a>

      <ng-container *ngFor="let child of data.page.children">
        <ng-container *ngTemplateOutlet="pageTreeNode; context: {$implicit: {page:child, level:data.level+1}}">
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="menu-list__wrap"
    *ngIf="orientation === MenuOrientation.horizontal"
    (mouseleave)="onMouseLeave($event, data.level)"
    (mouseenter)="onMouseOver($event, data.level)"
  >
    <a class="menu-list__item _leaf" *ngIf="data.page.isLink && data.page.url" [attr.href]="pagesService.getPageUrl(data.page)"
      [attr.target]="data.page.inNewTab ? '_blank': '_self'" (click)="helper.hideMenu$.next()"
      [ngClass]="{'_complex': data.page?.children?.length, '_hidden':!opened}">{{data.page.title}}</a>

    <span class="menu-list__item" *ngIf="data.page.isLink && !data.page.url" [ngClass]="{'_complex': data.page?.children?.length, '_hidden':!opened}">{{data.page.title}}</span>

    <a class="menu-list__item _leaf" *ngIf="!data.page.isLink" [routerLink]="pagesService.getPageUrl(data.page)"
      [attr.target]="data.page.inNewTab ? '_blank': '_self'" (click)="pagesService.navigatePage($event, data.page)"
      [ngClass]="{'_complex': data.page?.children?.length, '_hidden':!opened}">{{data.page.title}}</a>

    <div class="menu-list _dropdown" *ngIf="data.page?.children?.length">
      <div class="container _full-page" *ngIf="megaMenu && data.level === 1">
        <ng-container *ngFor="let child of data.page.children">
          <ng-container *ngTemplateOutlet="pageTreeNode; context: {$implicit: {page:child, level:data.level+1}}">
          </ng-container>
        </ng-container>
      </div>

      <ng-container *ngIf="!megaMenu || data.level !== 1">
        <ng-container *ngFor="let child of data.page.children">
          <ng-container *ngTemplateOutlet="pageTreeNode; context: {$implicit: {page:child, level:data.level+1}}">
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>

</ng-template>
