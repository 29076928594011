import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from '@app/core/components/base.component';

@Component({
  selector: 'app-feed-preloader',
  templateUrl: './feed-preloader.component.html',
  styleUrls: ['./feed-preloader.component.css']
})
export class FeedPreloaderComponent {
  @Input() loaded: boolean;
  @Input() loading: boolean;
}
