<div *ngIf="loaded" class="horizontal-bar _main">
  <div *ngFor="let post of bigPosts.slice(0,1)" class="card _full-content _post-business">
    <div class="card__content">
      <div class="post _business">
        <div class="post__author-link">
          <a routerLink="/group/{{post.event.ownerId}}"
             (click)="navigateGroup($event,post.event.ownerId)">{{post.group?.title}}</a>
        </div>
        <div class="post__author" [attr.style]="getBackgroundImage(post)">
        </div>
        <div class="post__date">{{post.event?.created | date:'dd.MM.yy HH:mm'}}</div>
        <div class="post__text">
          <a routerLink="/group/{{post.event.ownerId}}/post/{{post.event.id}}">
            <div class="news-title" *ngIf="post.event?.title">{{post.event?.title}}</div>
            <app-message *ngIf="!post.event?.title" [showBody]="false" [text]="post.event?.body"
                         [mentions]="mentions">
            </app-message>
          </a>
        </div>
        <div class="post__actions" *ngIf="isShowActions(post)">
          <div class="post__menu pull-right">
            <app-feed-event-menu [whiteDots]="true" [post]="post" (remove)="onRemove(post)" (edit)="navigateToEdit(post)"
                                 (cancelPin)="cancelPin(post)" [allowEdit]="allowEdit(post)" [allowDelete]="allowDelete(post)">
            </app-feed-event-menu>
          </div>
          <div class="post__pin pull-right" title="Закреплённый пост" *ngIf="post?.event?.pin"></div>
          <app-like *ngIf="!post.event.disableLike" [count]="post.likes.count" [canLike]="post.likes.canLike"
          [elementId]="post.event.id" [elementType]="ElementType.Event" [white]="true"></app-like>
          <!-- <a routerLink="/group/{{post.event.ownerId}}/post/{{post.event.id}}" *ngIf="!post.event.disableLike"
             class="btn btn-social _like-white" [ngClass]="{'_dislike': !post.likes.canLike}">{{post.likes.count}}</a> -->
          <a routerLink="/group/{{post.event.ownerId}}/post/{{post.event.id}}" *ngIf="!post.event.disableComment"
             class="btn btn-social _comment-white" [ngClass]="{'transparent-text': post.commentsCount === 0}">{{post.commentsCount}}</a>
        </div>
      </div>
    </div>
  </div>

  <div class="card _post _widget" *ngFor="let post of bigPosts.slice(1,2)">
    <ng-container *ngTemplateOutlet="postOrSimplePost; context: {$implicit: {post: post}}"></ng-container>
  </div>
</div>

<div *ngIf="loaded" class="horizontal-bar">
  <div class="card _post _widget" *ngFor="let post of posts">
    <ng-container *ngTemplateOutlet="postOrSimplePost; context: {$implicit: {post: post}}"></ng-container>
  </div>

  <div class="horizontal-bar__side">
    <div class="card _post _widget" *ngFor="let post of simplePosts">
      <ng-container *ngTemplateOutlet="postOrSimplePost; context: {$implicit: {post: post, simple: true}}"></ng-container>
    </div>
  </div>
</div>

<ng-template #postOrSimplePost let-params>
  <a class="post-link" *ngIf="!params.simple" routerLink="/group/{{params.post.event.ownerId}}/post/{{params.post.event.id}}">
    <app-media-attachments-list [mediaAttachments]="params.post.mediaAttachments" [isEdit]="false">
    </app-media-attachments-list>
  </a>
  <div class="card__content">
    <div>
      <a routerLink="/group/{{params.post.event.ownerId}}" class="link co__name _progress"
         (click)="navigateGroup($event,params.post.event.ownerId)">{{params.post.group?.title}}</a>
      <div class="co__occ">{{params.post.event?.created | date:'dd.MM.yy HH:mm'}}</div>
    </div>
    <div class="post">
      <div class="post__author">
        <div class="post__menu pull-right">
          <app-feed-event-menu [post]="params.post" (remove)="onRemove(params.post)" (edit)="navigateToEdit(params.post)"
                               (cancelPin)="cancelPin(params.post)" [allowEdit]="allowEdit(params.post)" [allowDelete]="allowDelete(params.post)">
          </app-feed-event-menu>
        </div>
      </div>
      <div class="post__text">
        <a routerLink="/group/{{params.post.event.ownerId}}/post/{{params.post.event.id}}">
          <div class="news-title" *ngIf="params.post.event?.title">{{params.post.event?.title}}</div>
          <app-message *ngIf="!params.post.event?.title" [showBody]="false" [text]="params.post.event?.body" [mentions]="mentions">
          </app-message>
        </a>
      </div>
      <div class="post__actions" *ngIf="isShowActions(params.post)">
        <div class="post__pin pull-right" title="Закреплённый пост" *ngIf="params.post?.event?.pin"></div>
        <app-like *ngIf="!params.post.event.disableLike" [count]="params.post.likes.count" [canLike]="params.post.likes.canLike"
          [elementId]="params.post.event.id" [elementType]="ElementType.Event"></app-like>
        <a routerLink="/group/{{params.post.event.ownerId}}/post/{{params.post.event.id}}" *ngIf="!params.post.event.disableComment"
           class="btn btn-post _comment" [ngClass]="{'transparent-text': params.post.commentsCount === 0}">{{params.post.commentsCount}}</a>
      </div>
    </div>
  </div>
</ng-template>

<div *ngIf="!loaded" class="horizontal-bar _main">
  <div class="card _full-content _post-business _preloader">
    <div class="card__content">
      <div class="post _business">
        <div class="post__author-link">
          <span class="preloader">
            <span class="preloader__line"></span>
          </span>
        </div>
        <div class="post__author"></div>
        <div class="post__date">
          <span class="preloader">
            <span class="preloader__line"></span>
          </span>
        </div>
        <div class="post__text">
          <span class="preloader _cascade"><span class="preloader__line"></span></span>
        </div>
        <div class="post__actions">
          <div class="post__menu pull-right">
            <div class="pull-right btn btn-dots-white tooltip"></div>
          </div>
          <button class="btn btn-post _like _like-white" type="button">&nbsp;</button>
        </div>
      </div>
    </div>
  </div>

  <div class="card _post _widget _preloader">
   <span class="post-link">
      <div class="grid _post">
         <div class="grid__content">
            <div class="grid__item">
               <div class="gallery-preview _file _loading"></div>
            </div>
         </div>
      </div>
   </span>

    <div class="card__content">
      <div>
        <span class="preloader"><span class="preloader__line"></span></span>
      </div>
      <div class="post">
        <div class="post__text">
          <div class="post__text">
            <span class="preloader _cascade"><span class="preloader__line"></span></span>
            <span class="preloader _cascade"><span class="preloader__line"></span></span>
          </div>
        </div>
        <div class="post__actions">
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!loaded" class="horizontal-bar">
  <div class="card _post _widget _preloader" *ngFor="let r of [0,1]">
   <span class="post-link">
      <div class="grid _post">
         <div class="grid__content">
            <div class="grid__item">
               <div class="gallery-preview _file _loading"></div>
            </div>
         </div>
      </div>
   </span>

    <div class="card__content">
      <div>
        <span class="preloader"><span class="preloader__line"></span></span>
      </div>
      <div class="post">
        <div class="post__text">
          <div class="post__text">
            <span class="preloader _cascade"><span class="preloader__line"></span></span>
            <span class="preloader _cascade"><span class="preloader__line"></span></span>
          </div>
        </div>
        <div class="post__actions">
        </div>
      </div>
    </div>
  </div>

  <div class="horizontal-bar__side">
    <div class="card _post _widget _preloader" *ngFor="let r of [0,1]">
      <div class="card__content">
        <div>
          <span class="preloader"><span class="preloader__line"></span></span>
        </div>
        <div class="post">
          <div class="post__text">
            <div class="post__text">
              <span class="preloader _cascade"><span class="preloader__line"></span></span>
              <span class="preloader _cascade"><span class="preloader__line"></span></span>
            </div>
          </div>
          <div class="post__actions">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="false" class="horizontal-bar">
  <div class="card _post _preloader">
    <div class="card__content">
      <div class="co _new">
        <span class="preloader _co _post-business">
          <span class="preloader__side"></span>
          <span class="preloader__lines">
            <span class="preloader__line"></span>
            <span class="preloader__line"></span>
          </span>
        </span>
      </div>
      <div class="post">
        <div class="post__text">
          <span class="preloader _cascade"><span class="preloader__line"></span></span>
          <span class="preloader _cascade"><span class="preloader__line"></span></span>
          <span class="preloader _cascade"><span class="preloader__line"></span></span>
          <span class="preloader _cascade"><span class="preloader__line"></span></span>
        </div>
      </div>
    </div>
  </div>
  <div class="card _post _preloader">
    <div class="card__content">
      <div class="co _new">
        <span class="preloader _co _post-business">
          <span class="preloader__side"></span>
          <span class="preloader__lines">
            <span class="preloader__line"></span>
            <span class="preloader__line"></span>
          </span>
        </span>
      </div>
      <div class="post">
        <div class="post__text">
          <span class="preloader _cascade"><span class="preloader__line"></span></span>
          <span class="preloader _cascade"><span class="preloader__line"></span></span>
          <span class="preloader _cascade"><span class="preloader__line"></span></span>
          <span class="preloader _cascade"><span class="preloader__line"></span></span>
        </div>
      </div>
    </div>
  </div>
  <div class="card _post _preloader">
    <div class="card__content">
      <div class="co _new">
        <span class="preloader _co _post-business">
          <span class="preloader__side"></span>
          <span class="preloader__lines">
            <span class="preloader__line"></span>
            <span class="preloader__line"></span>
          </span>
        </span>
      </div>
      <div class="post">
        <div class="post__text">
          <span class="preloader _cascade"><span class="preloader__line"></span></span>
          <span class="preloader _cascade"><span class="preloader__line"></span></span>
          <span class="preloader _cascade"><span class="preloader__line"></span></span>
          <span class="preloader _cascade"><span class="preloader__line"></span></span>
        </div>
      </div>
    </div>
  </div>
</div>
