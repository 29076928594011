<div class="cos__body" *ngIf="loaded">
  <div class="survey">
    <div class="survey__section _first">
      <div class="survey__title input-main">
        <input [(ngModel)]="bannerSlideType.title" maxlength="255" placeholder="Введите название типа*" type="text"
          class="input-main__field">
      </div>
      <div class="survey__description input-main">
        <textarea autosize [(ngModel)]="bannerSlideType.color" maxlength="255"
          placeholder="Введите цвет фона по умолчанию*" rows="1" class="input-main__field"></textarea>
      </div>
      <div class="survey__note">Цвет фона в формате HEX (#000000).<br>Рекомендуемые цвета:<span class="color-preview">
          <span style="background-color: #5299CA" class="color-preview__dot"></span>
          <span class="color-preview__text">#5299CA</span></span><span class="color-preview"><span
            style="background-color: #2177BE" class="color-preview__dot"></span><span
            class="color-preview__text">#2177BE</span></span><span class="color-preview"><span
            style="background-color: #FEB34B" class="color-preview__dot"></span><span
            class="color-preview__text">#FEB34B</span></span><span class="color-preview"><span
            style="background-color: #75BFAD" class="color-preview__dot"></span><span
            class="color-preview__text">#75BFAD</span></span><span class="color-preview"><span
            style="background-color: #F4AFAE" class="color-preview__dot"></span><span
            class="color-preview__text">#F4AFAE</span></span><span class="color-preview"><span
            style="background-color: #D492C2" class="color-preview__dot"></span><span
            class="color-preview__text">#D492C2</span></span>
      </div>
      <div>
        <div>Выберите иконку для слайда:</div>
        <span *ngFor="let icon of icons">
          <span class="awards-image-wrap _banner" [ngClass]="{'_active': icon?.url === bannerSlideType.iconUrl}"
            (click)="choseIcon(icon)">
            <img class="awards-image" [attr.src]="bannersService.getIconUrlServerRelative(icon?.url)">
            <button class="btn btn-remove" type="button" (click)="removeIcon(icon)"></button>
          </span>
        </span>
        <div>
          <a href="javascript:;" class="btn btn-add" (click)="iconUpload.click()">Загрузить новую иконку</a>
          <input type="file" (change)="fileChangeEvent($event)" #iconUpload multiple accept=".svg"
            style="display: none" />
        </div>
        <div class="survey__note">
          Требования к иконке:
          Формат svg, используются только контуры без заливки, цвет контуров – #FFFFFF (opacity (normal) 25%), иконка
          должна размещаться внутри контейнера (маски) размером 206*163 пикс, размер самой иконки рекомендуется не
          превышать 125*125 пикс. Внутри контейнера иконку следует выровнять по центру со всех сторон или по центру и
          нижнему краю.
        </div>
      </div>
      <div class="post__actions" [ngClass]="{'loading _blue': sending}">
        <div class="post__submit">
          <button type="button" class="btn btn-secondary" (click)="cancel()">Отмена</button>
          <button type="button" class="btn btn-primary" (click)="submit()"
            [attr.disabled]="isValid()?null:''">{{buttonSaveText}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
