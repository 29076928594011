import {Injectable} from '@angular/core';
import {Subject, BehaviorSubject} from 'rxjs';
import {GroupType} from '@app/groups/model/group-type.model';
import {GroupUserRole} from '@app/groups/model/group-user-role.model';
import {GroupInfo} from '@app/groups/model/group-info.model';
import {Helper} from '@app/core/helpers/helper';
import {HttpClient} from '@angular/common/http';
import {GroupRequestState} from '@app/groups/model/group-request-state.model';
import {Router} from '@angular/router';
import {environment} from '@env/environment';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {HelperService} from '@app/core/services/helper.service';
import {GroupForbiddenType} from '../model/group-forbidden-type';
import {GroupsApiService} from './groups-api.service';
import {IGroupUserStateChanged} from '../model/group-user-state-changed';
import {User} from '@app/profile/model/user.model';

@Injectable()
export class GroupsService extends GroupsApiService {
  GroupForbiddenType = GroupForbiddenType;

  currentUserGroupsCount: Subject<number> = new BehaviorSubject<number>(0);
  currentGroup: BehaviorSubject<GroupInfo> = new BehaviorSubject<GroupInfo>(null);
  currentGroupShowCounters$: Subject<boolean> = new Subject();

  settingsChanged$: Subject<void> = new Subject();

  userSubscriberStateChanged$: Subject<IGroupUserStateChanged> = new Subject();
  userMemberStateChanged$: Subject<IGroupUserStateChanged> = new Subject();
  userAdminStateChanged$: Subject<IGroupUserStateChanged> = new Subject();

  groupDeleted$: Subject<number> = new Subject();

  groupOrgNews$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  /**
   * "Выбранная группа" - группа, которая была выбрана в различных всплывашках
   *
   * @type {BehaviorSubject<GroupInfo>}
   * @memberof GroupsService
   */
  selectedGroup: BehaviorSubject<GroupInfo> = new BehaviorSubject<GroupInfo>(null);

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected sanitizer: DomSanitizer,
    public helper: HelperService,
  ) {
    super(http, helper);

    // автоматически выбираем текущую группу выбранной
    this.currentGroup.subscribe(res => {
      this.selectedGroup.next(res);
    });
  }

  // helpers methods
  getGroupTypeNameByEnum(groupType: GroupType): string {
    switch (groupType) {
      case GroupType.Business:
        return 'Бизнес';
      case GroupType.Closed:
        return 'Закрытая';
      case GroupType.Private:
        return 'Приватная';
      default:
        return 'Открытая';
    }
  }

  getUserRoleByEnum(group: GroupInfo, currentUser: User): string {
    if (this.isRequested(group, group.memberState)) {
      return 'Отправлен запрос на вступление';
    }

    if (this.isRequested(group, group.subscriberState)) {
      return 'Отправлен запрос на подписание';
    }

    if (this.isAdmin(group, currentUser)) {
      return 'Администратор';
    }

    if (this.isMember(group)) {
      return 'Участник';
    }

    if (this.isMember(group)) {
      return 'Подписчик';
    }

    return '';
  }

  getUsersCountText(group: GroupInfo): string {
    return Helper.getNoun(group.usersCount, 'человек', 'человека', 'человек');
  }

  isSubscriber(group: GroupInfo): boolean {
    return group && group.isSubscriber && this.isApproved(group, group.subscriberState);
  }

  isMember(group: GroupInfo): boolean {
    return group && group.isMember && this.isApproved(group, group.memberState);
  }

  isAdmin(group: GroupInfo, currentUser: User): boolean {
    return currentUser?.isAdmin || (group && group.isAdmin && this.isApproved(group, group.adminState));
  }

  isParticipant(group: GroupInfo, currentUser: User): boolean {
    return this.isMember(group) || this.isAdmin(group, currentUser);
  }

  isAnyRole(group: GroupInfo, currentUser: User): boolean {
    return this.isSubscriber(group) || this.isMember(group) || this.isAdmin(group, currentUser);
  }

  isRequested(group: GroupInfo, state: GroupRequestState): boolean {
    return state === GroupRequestState.Active;
  }

  isDeclined(group: GroupInfo, state: GroupRequestState): boolean {
    return state === GroupRequestState.Declined;
  }

  isApproved(group: GroupInfo, state: GroupRequestState): boolean {
    return state === GroupRequestState.Approved;
  }

  canRequest(group: GroupInfo, role: GroupUserRole): boolean {
    switch (role) {
      case GroupUserRole.Subscriber:
        return group.subscriberState !== GroupRequestState.Active;
      case GroupUserRole.Member:
        return group.memberState !== GroupRequestState.Active;
      case GroupUserRole.Administrator:
        return group.adminState !== GroupRequestState.Active;
    }
    return false;
  }

  getGroupPicture(group: GroupInfo): string {
    return group ? Helper.getPicture(group.pictureUrl) : '';
  }

  getGroupSmallPicture(group: any): string {
    if (group && group.smallPicture !== undefined) {
      return group.smallPicture;
    }

    let url = decodeURIComponent(this.getGroupPicture(group));
    if (url) {
      if (environment.siteUrl && !url.startsWith('http://') && !url.startsWith('https://')) {
        url = Helper.concatUrls(environment.siteUrl, url);
      }
      const index = url.lastIndexOf('/');
      if (index !== -1) {
        // ensure params
        const paramsIndex = url.indexOf('?');
        let params = '';
        if (paramsIndex !== -1) {
          params = url.substr(paramsIndex);
          url = url.substr(0, paramsIndex);
        }

        let name = url.substr(index + 1);
        const extIndex = name.lastIndexOf('.');
        if (extIndex !== -1) {
          // replace '.' to '_'
          name = name.substr(0, extIndex) + '_' + name.substr(extIndex + 1) + '.jpg';
          url = Helper.concatUrls(Helper.concatUrls(url.substr(0, index), '_w'), name);
        }

        // encode or it will not work on background
        // url = encodeURIComponent(url);

        // append params
        if (params) {
          url = url + params;
        }

        // set picture url for no overhead
        group.smallPicture = url;
      }
    }
    return url;
  }

  getSmallPictureUrl(group: GroupInfo): string {
    if (group) {
      return this.getGroupSmallPicture(group);
    }

    return '';
  }

  getPictureUrlStyle(group: GroupInfo): SafeStyle {
    if (group) {
      const style = `background-image: url('${this.getGroupSmallPicture(group)}')`;
      return this.sanitizer.bypassSecurityTrustStyle(style);
    }
    return '';
  }

  canReadGroup(group: GroupInfo, currentUser: User) {
    if (group.type === GroupType.Closed || group.type === GroupType.Private) {
      return this.isAnyRole(group, currentUser);
    } else if (group.type === GroupType.Open || group.type === GroupType.Business) {
      return true;
    }
  }

  getInitials(group: any, isDesktop: boolean): string {
    if (!group) {
      return '';
    }

    if ((<any>group).initials) {
      return (<any>group).initials;
    }

    if (group && group.title) {
      const max = 3;
      if (group.title.length <= max) {
        (<any>group).initials = group.title;
      } else {
        (<any>group).initials = group.title
          .split(' ')
          .map(function (s) {
            return s.charAt(0);
          })
          .join('')
          .substr(0, 2);
        if ((<any>group).initials.length < 3) {
          (<any>group).initials = group.title.substr(0, 3);
        }
      }
    } else {
      (<any>group).initials = '';
    }
    return (<any>group).initials;
  }

  /**
   * Получение вычисленного цвета заглушки
   *
   * @param {*} group группа
   * @returns строку в формате #3434
   * @memberof GroupsListComponent
   */
  getColor(group: any) {
    if (group) {
      if (!group.color) {
        group.color = Helper.getColorById(group.id);
      }
      return group.color;
    }
    return '';
  }

  navigateGroup(id: number, fragment: string = null) {
    // при переходе на профиль юзера лучше обнулять текущего
    const group = this.currentGroup.getValue();
    if (group != null && id !== group.id) {
      this.currentGroup.next(null);
    }
    if (fragment) {
      this.router.navigate([`/group/${id}`], {fragment: fragment});
    } else {
      this.router.navigate([`/group/${id}`]);
    }
  }

  isAllowToViewGroupContent(group, currentUser: User): boolean {
    return group && (group.type === GroupType.Business || group.type === GroupType.Open || this.isAnyRole(group, currentUser));
  }
}
