import {Component, OnInit, Input, OnDestroy, NgZone} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersService} from '@app/profile/services/users.service';
import {FeedService} from '@app/feed/services/feed.service';
import {SubscribeService} from '@app/subscribe/services/subscribe.service';
import {FeedBaseComponent} from '@app/feed/components/feed-base/feed-base.component';
import {GroupInfo} from '@app/groups/model/group-info.model';
import {LikesService} from '@app/likes/services/likes.service';
import {SignalRService} from '@app/signalr/signalR.service';
import {GroupsService} from '@app/groups/services/groups.service';
import {ProfileType} from '@app/core/model/profile-type.enum';
import {GroupType} from '@app/groups/model/group-type.model';
import {HelperService} from '@app/core/services/helper.service';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-group-feed',
  templateUrl: './group-feed.component.html',
})
/*
  Лента группы по ссылке group/{id}/feed
*/
export class GroupFeedComponent extends FeedBaseComponent implements OnInit, OnDestroy {
  @Input() group: GroupInfo;
  canRead: boolean;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected usersService: UsersService,
    protected subscribeService: SubscribeService,
    protected feedService: FeedService,
    protected signalRService: SignalRService,
    protected groupsService: GroupsService,
    protected likesService: LikesService,
    protected ngZone: NgZone,
    public helper: HelperService,
  ) {
    super(router, route, usersService, subscribeService, feedService, likesService, ngZone, helper);
  }

  ngOnInit() {
    this.groupsService.currentGroup.pipe(takeUntil(this.destroyed$)).subscribe(group => {
      this.group = group;
      if (this.group) {
        this.group.showCard = true;
        this.offset = this.lastOffset = 0;
        this.posts = [];
        this.getFeed();

        if (this.group.type === GroupType.Closed || this.group.type === GroupType.Private) {
          this.canRead = this.groupsService.isAnyRole(this.group, this.currentUser);
        } else if (this.group.type === GroupType.Open || this.group.type === GroupType.Business) {
          this.canRead = true;
        }
      }
    });

    this.signalRService.feedHub.onNewGroupPost.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      const group = this.groupsService.currentGroup.getValue();
      if (group && group.id === res.event.ownerId && res.event.ownerType === ProfileType.Group) {
        this.onFeedNewPost(res);
      }
    });
    // spread info about post removed
    this.signalRService.feedHub.onPostRemoved.subscribe(res => {
      this.onFeedPostRemoved(Number(res));
    });
    // spread info about post edited
    this.signalRService.feedHub.onPostEdited.subscribe(res => {
      this.onFeedPostEdited(res);
    });
  }

  protected getFeedInternal() {
    if (this.group) {
      // получение постов
      this.operateFeedResult(this.feedService.getGroupFeed(this.group.id, this.offset, this.limit));
    }
  }

  canAdd() {
    return this.groupsService.isMember(this.group) || this.groupsService.isAdmin(this.group, this.currentUser);
  }
}
