import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedService } from '@app/feed/services/feed.service';
import { FeedBaseComponent } from '@app/feed/components/feed-base/feed-base.component';
import { FeedEventMenuComponent } from '@app/feed/components/feed-event-menu/feed-event-menu.component';
import { PostComponent } from '@app/feed/components/post/post.component';
import { PostNewComponent } from '@app/feed/components/post-new/post-new.component';
import { PostViewComponent } from '@app/feed/components/post-view/post-view.component';
import { ProfileFeedComponent } from '@app/feed/components/profile-feed/profile-feed.component';
import { UserFeedComponent } from '@app/feed/components/user-feed/user-feed.component';
import { CommentsModule } from '@app/comments/comments.module';
import { AttachmentsModule } from '@app/attachments/file-attachments/attachments.module';
import { SharedModule } from '@app/shared/shared.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { LikesModule } from '@app/likes/likes.module';
import { FeedPreloaderComponent } from '@app/feed/components/feed-preloader/feed-preloader.component';
import { PostPreloaderComponent } from '@app/feed/components/post-preloader/post-preloader.component';
import { MediaAttachmentsModule } from '@app/attachments/media-attachments/media-attachments.module';
import { PostProfileSelectorComponent } from './components/post-profile-selector/post-profile-selector.component';
import { FeedApiService } from './services/feed-api.service';
import { FeedWidgetComponent } from './components/feed-widget/feed-widget.component';
import { BusinessFeedComponent } from './components/business-feed/business-feed.component';
import { PostShareComponent } from './components/post-share/post-share.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule,
    CommentsModule,
    AttachmentsModule,
    MediaAttachmentsModule,
    LikesModule,
    MatMenuModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    FeedBaseComponent,
    FeedEventMenuComponent,
    PostComponent,
    PostNewComponent,
    PostViewComponent,
    ProfileFeedComponent,
    UserFeedComponent,
    FeedPreloaderComponent,
    PostPreloaderComponent,
    PostProfileSelectorComponent,
    FeedWidgetComponent,
    BusinessFeedComponent,
    PostShareComponent,
  ],
  exports: [
    FeedBaseComponent,
    FeedEventMenuComponent,
    PostComponent,
    PostNewComponent,
    PostViewComponent,
    ProfileFeedComponent,
    UserFeedComponent,
    FeedPreloaderComponent,
    PostPreloaderComponent,
    FeedWidgetComponent,
    BusinessFeedComponent,
    PostShareComponent,
  ],
  providers: [
    FeedService,
    FeedApiService
  ]
})
export class FeedModule {
  static forRoot(): ModuleWithProviders<FeedModule> {
    return {
      ngModule: FeedModule,
      providers: [FeedService],
    };
  }
}
