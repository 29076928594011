import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild, forwardRef, NgZone } from '@angular/core';
import { CommentViewModel } from '@app/comments/model/comment-view-model.model';
import { CommentsService } from '@app/comments/services/comments.service';
import { ElementType } from '@app/core/model/element-type.enum';
import { BaseComponent } from '@app/core/components/base.component';
import { UsersService } from '@app/profile/services/users.service';
import { User } from '@app/profile/model/user.model';
import { LikesService } from '@app/likes/services/likes.service';
import { SignalRService } from '@app/signalr/signalR.service';
import { take } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper.service';
import { ICommentMenu } from '@app/comments/model/comment.model';

export interface ICommentReply {
  user?: User;
  parentId?: string;
}

/**
 * Компонент списка комментариев
 *
 * @export
 * @class CommentsListComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-comments-list',
  templateUrl: './comments-list.component.html'
})
export class CommentsListComponent extends BaseComponent implements OnInit, OnChanges {

  /**
   * Список комментариев
   *
   * @type {CommentViewModel[]}
   * @memberof CommentsListComponent
   */
  @Input() comments: CommentViewModel[];
  @Input() count: number;
  @Input() elementId: number;
  @Input() elementType: ElementType;
  @Input() elementAuthorId: number;
  @Input() isAdmin: boolean;
  @Input() highlightText: string;
  @Input() config: ICommentMenu = {};
  @Input() showBody: boolean;

  @Output() commentReply: EventEmitter<ICommentReply> = new EventEmitter();
  @Output() commentAdded: EventEmitter<number> = new EventEmitter();
  @Output() commentDeleted: EventEmitter<number> = new EventEmitter();

  currentUser: User;
  previousCommentsCount: number;
  offset = 0;
  limit = 20;

  constructor(
    private commentsService: CommentsService,
    private usersService: UsersService,
    private likesService: LikesService,
    private signalRService: SignalRService,
    protected ngZone: NgZone,
    public helper: HelperService) { super(helper); }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;
    });

    this.signalRService.commentHub.onNew.subscribe(res => {
      this.onCommentAdded(res);
    });
    this.signalRService.commentHub.onUpdated.subscribe(res => {
      this.onCommentUpdated(res);
    });
    this.signalRService.commentHub.onDeleted.subscribe(res => {
      this.onCommentDeleted(res);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // отображение текста подзагрузки комментов
    if (changes['comments']) {
      this.calculatePreviousCommentsCount();
      this.config.allowDelete = this.isAdmin;
    }

    if (changes['isAdmin']) {
      this.config.allowDelete = this.isAdmin;
    }
  }

  private calculatePreviousCommentsCount() {
    if (this.comments) {
      if (this.count - this.comments.length >= 0) {
        this.previousCommentsCount = this.count - this.comments.length;
      } else {
        this.previousCommentsCount = 0;
      }
    } else {
      this.previousCommentsCount = this.count;
    }
  }

  /**
   * Действие при ответе на комментарий
   *
   * @param {ICommentReply} reply
   * @memberof CommentsListComponent
   */
  onCommentReply(reply: ICommentReply) {
    this.commentReply.emit(reply);
  }

  /**
   * Действие при добавлении комментария. Добавление нового комментария в список.
   *
   * @param {CommentViewModel} comment
   * @memberof CommentsListComponent
   */
  onCommentAdded(comment: CommentViewModel) {
    // add into comments array for destination post
    if (this.elementId === comment.comment.elementID && this.elementType === comment.comment.elementType) {
      // no duplicates check
      if (!this.comments.filter(o => o.comment.id === comment.comment.id).length) {
        // need zone cause updates not in the flow
        this.ngZone.run(() => {
          this.comments.push(comment);
          // update counters
          this.count++;
          // bubble
          if (this.commentAdded) {
            this.commentAdded.emit(this.count);
          }
        });
      }
    }
  }

  /**
   * Действие при обновлении комментария. Обновление списка комментариев.
   *
   * @param {CommentViewModel} comment
   * @memberof CommentsListComponent
   */
  onCommentUpdated(comment: CommentViewModel) {
    this.ngZone.run(() => {
      const c = this.comments.filter(o => o.comment.id === comment.comment.id)[0];
      if (c) {
        const i = this.comments.indexOf(c);
        if (i >= 0) {
          this.comments[i] = comment;
        }
      }
    });
  }

  /**
   * Действие при удалении комментария. Удаление комментария из списка.
   *
   * @param {string} commentId
   * @memberof CommentsListComponent
   */
  onCommentDeleted(commentId: string) {
    this.ngZone.run(() => {
      // only if comments list has this comment
      if (this.comments.find(o => o.comment.id === commentId)) {
        this.comments = this.comments.filter(o => o.comment.id !== commentId);
        // update counters
        if (this.count > 0) {
          this.count--;
        }
        // bubble
        if (this.commentDeleted) {
          this.commentDeleted.emit(this.count);
        }
      }
    });
  }

  /**
   * Показать предыдущие комментарии
   *
   * @memberof CommentsListComponent
   */
  showPrevious() {
    this.commentsService.getComments(this.elementId, this.elementType, this.comments.length, 200)
      .pipe(take(1))
      .subscribe(res => {
        this.ngZone.run(() => {
          if (res && res.comments) {
            res.comments.forEach(comment => {
              if (!this.comments.find(o => o.comment.id === comment.comment.id)) {
                this.comments.unshift(comment);
              }
            });
            // update counters
            this.count = res.count;
            // previous comments
            this.calculatePreviousCommentsCount();
            // bubble
            if (this.commentAdded) {
              this.commentAdded.emit(this.count);
            }
            if (this.comments) {
              this.comments.sort((a, b) => {
                if (a.comment.created < b.comment.created) {
                  return -1;
                }
                if (a.comment.created > b.comment.created) {
                  return 1;
                }
                return 0;
              });
            }

          } else {
            console.log('ошибка во время получения комментариев');
          }
        });
      });
  }

  hideComments() {
    this.comments.splice(0, this.comments.length - 2);
    this.calculatePreviousCommentsCount();
  }

  removeComment(comment: CommentViewModel) {
    if (comment && comment.comment) {
      this.onCommentDeleted(comment.comment.id);
    }
  }
}
