import {
  Component,
  OnInit,
  Input,
  NgZone,
  OnDestroy,
  Output,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@app/core/components/base.component';
import { Helper } from '@app/core/helpers/helper';
import { ElementType } from '@app/core/model/element-type.enum';
import { HelperService } from '@app/core/services/helper.service';
import { LikeViewModel } from '@app/likes/model/like-view-model.model';
import { LikesService } from '@app/likes/services/likes.service';
import { User } from '@app/profile/model/user.model';
import { SignalRService } from '@app/signalr/signalR.service';
import { finalize, shareReplay, takeUntil } from 'rxjs/operators';
import { LikesListComponent } from '../likes-list/likes-list.component';
import {UsersService} from '@app/profile/services/users.service';

/**
 * Компонент лайка
 *
 * @export
 * @class LikeComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-like',
  templateUrl: './like.component.html'
})
export class LikeComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input() white: boolean;
  @Input() canLike: boolean;
  @Input() count: number;
  @Input() elementId: string;
  @Input() elementType: ElementType;

  /**
   * Событие при изменении canLike. Необходим для two-way binding
   *
   * @type {EventEmitter<boolean>}
   * @memberof LikeComponent
   */
  @Output() canLikeChange: EventEmitter<boolean> = new EventEmitter();

  /**
   * Событие при изменении count. Необходим для two-way binding
   *
   * @type {EventEmitter<number>}
   * @memberof LikeComponent
   */
  @Output() countChange: EventEmitter<number> = new EventEmitter();

  mouseOver: boolean;
  mouseLeave: boolean;

  loading: boolean;

  users: User[] = [];
  oldUsers: User[] = [];

  timeout: any;

  likesListDialog: MatDialogRef<LikesListComponent>;

  constructor(
    private likesService: LikesService,
    private signalRService: SignalRService,
    public helper: HelperService,
    protected dialog: MatDialog,
    protected cdr: ChangeDetectorRef,
    private usersService: UsersService,
    protected ngZone: NgZone) {
    super(helper);
  }

  ngOnInit() {
    this.signalRService.likeHub.liked.subscribe(likes => {
      this.onLiked(likes);
    });
  }

  /**
   * Изменение состояния лайка
   *
   * @memberof LikeComponent
   */
  toggle() {
    if (this.canLike) {
      this.likesService.like(this.elementId, this.elementType)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(model => {
          this.count = model.count;
          this.canLike = false;
          this.canLikeChange.emit(this.canLike);
          this.countChange.emit(this.count);
          this.cdr.detectChanges();
        });
    } else {
      this.likesService.unlike(this.elementId, this.elementType)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(model => {
          this.count = model.count;
          this.canLike = true;
          this.canLikeChange.emit(this.canLike);
          this.countChange.emit(this.count);
          this.cdr.detectChanges();
        });
    }
  }

  /**
   * При лайке элемента
   *
   * @param {LikeViewModel} model
   * @memberof LikeComponent
   */
  onLiked(model: LikeViewModel) {
    if (model.elementId === this.elementId) {
      this.ngZone.run(() => {
        this.count = model.count;
        this.likesService.canLike(this.elementId, this.elementType)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(result => {
            this.canLike = result;
            this.cdr.detectChanges();
          });
      });
    }
  }

  showTooltip() {
    if (!this.count) {
      return;
    }

    const that = this;

    this.mouseOver = true;

    this.timeout = setTimeout(() => {
      that.loading = true;

      that.likesService.getLikesSimple(that.elementId, that.elementType, 0, 6)
        .pipe(
          shareReplay(3, 10000),
          finalize(() => {
            that.loading = false;
          }))
        .subscribe(res => {
          that.users = res ? res : [];
          that.cdr.detectChanges();
        });
    }, 500);
  }

  hideTooltip() {
    if (this.mouseOver && this.mouseLeave) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.mouseOver = false;
      this.mouseLeave = false;

      this.oldUsers = this.users;

      this.users = [];

      this.cdr.detectChanges();
    }
  }

  showPopup() {
    this.likesListDialog = this.dialog.open(LikesListComponent, {
      minWidth: '0',
      data: {
        cancelText: 'Отмена',
        okText: 'Добавить',
        headerText: `Понравилось ${this.count} ${Helper.getNoun(this.count, 'коллеге', 'коллегам', 'коллегам')}`,
        elementId: this.elementId,
        elementType: this.elementType
      }
    });
  }
}
