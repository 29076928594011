import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IVacancy, VacancyStatus } from '@app/vacancy/model/vacancy';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { VacancyService } from '@app/vacancy/services/vacancy.service';
import { take } from 'rxjs/operators';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import { AlertsService } from '@app/shared/services/alerts.service';

/**
 * Конфигурация меню (скрытие/отображение определённых элементов)
 *
 * @export
 * @interface IVacancyMenuConfig
 */
export interface IVacancyMenuConfig {
  allowArchive?: boolean;
  allowEdit?: boolean;
  allowDelete?: boolean;
}

/**
 * Расположение вакансии
 *
 * @export
 * @enum {number}
 */
export enum VacancyMenuLocation {
  view = 1,
  vacancyList = 2,
  userVacancyWidget = 3,
  groupVacancyWidget = 4,
}

/**
 * Меню вакансии
 *
 * @export
 * @class VacancyMenuComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-vacancy-menu',
  templateUrl: './vacancy-menu.component.html',
  styleUrls: ['./vacancy-menu.component.scss']
})
export class VacancyMenuComponent extends BaseComponent {

  @Input() config: IVacancyMenuConfig;

  @Input() selectedItems: IVacancy[];
  @Input() item: IVacancy;
  @Input() vacancyLocation: VacancyMenuLocation;
  @Input() whiteDots: boolean;

  @Output() deleted: EventEmitter<number> = new EventEmitter();
  @Output() archived: EventEmitter<any> = new EventEmitter();

  deletingDialog: MatDialogRef<ModalConfirmationComponent>;

  constructor(
    private vacancyService: VacancyService,
    private dialog: MatDialog,
    private alertsService: AlertsService,
    public helper: HelperService,
  ) { super(helper); }

  /**
   * Отображается ли меню
   *
   * @returns
   * @memberof VacancyMenuComponent
   */
  isVisible(): boolean {
    return this.config
      && (this.config.allowArchive || this.config.allowEdit || this.config.allowDelete);
  }

  /**
   * Ссылка редактирования вакансии
   *
   * @returns {string}
   * @memberof VacancyMenuComponent
   */
  getEditLink(): string {
    return this.item && this.item.ownerId ? `/group/${this.item.ownerId}/vacancy/${this.item.id}/edit` : '';
  }

  /**
   * Нажатие кнопки "Архивировать вакансию"
   *
   * @memberof VacancyMenuComponent
   */
  archiveClick(): void {
    this.vacancyService.updateVacancyStatus(this.item.id, VacancyStatus.archive)
      .pipe(take(1))
      .subscribe(res => {
        if (res) {
          this.item.status = VacancyStatus.archive;
          this.archived.emit(this.item.id);
          this.alertsService.riseSuccess('Вакансия успешно архивирована');
        } else {
          this.alertsService.riseError('Произошла ошибка при архивировании вакансии');
        }
      }, error => {
        console.log(error);
        this.alertsService.riseError('Произошла ошибка при архивировании вакансии');
      });
  }

  /**
   * Нажатие кнопки "Удалить вакансию"
   *
   * @memberof VacancyMenuComponent
   */
  deleteClick(): void {
    this.deletingDialog = this.dialog.open(ModalConfirmationComponent, {
      data: {
        text: `Вы действительно хотите удалить вакансию?`,
        onOk: this.deleteItem.bind(this),
        doNotHideOnOk: true
      }
    });
  }

  /**
   * Удаление элемента
   *
   * @memberof VacancyMenuComponent
   */
  private deleteItem(): void {
    this.vacancyService.remove(this.item.id).pipe(take(1)).subscribe(res => {
      if (res) {
        this.deletingDialog.close();
        this.deleted.emit(this.item.id);
        this.alertsService.riseSuccess('Вакансия успешно удалена');
      } else {
        this.alertsService.riseError('Произошла ошибка при удалении вакансии');
      }
    }, error => {
      console.log(error);
      this.alertsService.riseError('Произошла ошибка при удалении вакансии');
    });
  }
}
