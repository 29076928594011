import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ElementType } from '@app/core/model/element-type.enum';
import { HelperService } from '@app/core/services/helper.service';
import { LikesService } from '@app/likes/services/likes.service';
import { ScrollableListComponent } from '@app/shared/components/scrollable-list/scrollable-list.component';
import { UserLink } from '@app/subscribe/model/user-link';
import { finalize } from 'rxjs/operators';

export interface ILikesListData {
  limit: number;
  elementId: string;
  elementType: ElementType;
  headerText: string;
  error: string;
}

@Component({
  selector: 'app-likes-list',
  templateUrl: './likes-list.component.html',
  styleUrls: ['./likes-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LikesListComponent extends ScrollableListComponent implements OnInit, OnDestroy {

  users: UserLink[] = [];
  limit: 10;
  maxItemHeight: 100;
  loadedFull: boolean;

  constructor(
    public likesService: LikesService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: ILikesListData,
    public cdr: ChangeDetectorRef,
    public helper: HelperService,
  ) {
    super(helper);
  }

  ngOnInit(): void {
    this.getLikes();
  }

  onScrollLikes() {
    if (!this.loading && !this.loadedFull) {
      this.getLikes();
    }
  }

  private getLikes() {
    this.loading = true;
    this.likesService.getLikesFull(this.data.elementId, this.data.elementType, this.offset, this.limit)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.loaded = true;
        }))
      .subscribe(res => {
        if (res && res.length) {
          res.forEach(link => {
            if (!this.users.find(p => p.user.id === link.user.id)) {
              this.users.push(link);
            }
          });
        } else {
          this.loadedFull = true;
        }

        this.loading = false;
        this.loaded = true;

        this.cdr.detectChanges();
        this.offset += this.users.length;
      });
  }
}
