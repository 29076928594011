<div class="btn btn-dots tooltip" [ngClass]="{'btn-dots-white': whiteDots}" mat-button [matMenuTriggerFor]="menu"></div>
<mat-menu #menu="matMenu">
  <div class="icon-menu" #menuContainer>
    <ng-container *ngIf="selectedItems?.length>1; then multipleItemsMenu; else singleItemMenu"></ng-container>
    <ng-template #singleItemMenu>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="config?.allowVoting"
        (click)="showVotingPopup()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/check.svg" alt="" class="icon-menu__img">
        </span>
        <span>{{getVotingtext()}}</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="config?.allowAdd"
        (click)="fileUpload.click()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/upload.svg" alt="" class="icon-menu__img">
        </span>
        <span>Загрузить медиафайл</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" (click)="copyToClipboard()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/link.svg" alt="" class="icon-menu__img">
        </span>
        <span>Копировать ссылку</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="config?.allowEdit" (click)="editItem()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/edit.svg" alt="" class="icon-menu__img">
        </span>
        <span>Редактировать</span>
      </a>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="config?.allowDelete"
        (click)="showDeletePopup()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/remove.svg" alt="" class="icon-menu__img">
        </span>
        <span>Удалить</span>
      </a>
    </ng-template>
    <ng-template #multipleItemsMenu>
      <a mat-menu-item href="javascript:void(0)" class="icon-menu__item" *ngIf="config?.allowDelete"
        (click)="showDeletePopup()">
        <span class="icon-menu__icon">
          <img src="{{assetsPrefix}}/assets/images/icons/menu/remove.svg" alt="" class="icon-menu__img">
        </span>
        <span>Удалить</span>
      </a>
    </ng-template>
  </div>
</mat-menu>

<input type="file" (change)="onUpload($event)" accept=".jpeg,.jpg,.png,.bmp,.gif,.mp4,.avi,.wmv,.webm,.ogg" multiple
  #fileUpload style="display: none" />

<div class="modal _loading" *ngIf="galleryService.mediaFilesUploading | async" style="z-index: 20001">
  <div class="modal__container">
    <mat-spinner [diameter]="80"></mat-spinner>
  </div>
</div>

<a #itemLinkHref [href]="getItemLinkUrl()" style="display:none"></a>