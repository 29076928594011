import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from '@app/profile/services/users.service';
import { User } from '@app/profile/model/user.model';
import { BaseComponent } from '@app/core/components/base.component';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { HelperService } from '@app/core/services/helper.service';
import { takeUntil } from 'rxjs/operators';

/**
 * Корневой компонент для списка наград пользователя и формы создания новой награды
 *
 * @export
 * @class UserAwardsMainComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-user-awards-main',
  templateUrl: './user-awards-main.component.html',
  styleUrls: ['./user-awards-main.component.scss']
})
export class UserAwardsMainComponent extends BaseComponent implements OnInit, OnDestroy {

  user: User;

  constructor(
    protected usersService: UsersService,
    protected breadcrumbsService: BreadcrumbsService,
    public helper: HelperService) { super(helper); }

  ngOnInit() {

    this.usersService.user.pipe(takeUntil(this.destroyed$)).subscribe(user => {
      if (user) {
        this.user = user;

        this.breadcrumbsService.breadcrumbs.next([
          { title: 'Награды', routerUrl: `/profile/${this.user.id}/awards` }
        ]);

      } else {
        this.user = null;
      }
    });
  }
}
