<div class="cos__body">
    <div class="survey" *ngIf="gift && loaded">
      <div class="survey__section _banner">

        <div [attr.style]="giftService.getPictureUrl(gift)" class="banner-item _image _settings _gift">
          <div class="banner-item__controls">
            <div class="profile-photo__edit" (click)="groupImage.click()">
              <input #groupImage style="display: none" type="file" class="profile-photo__file" accept="image/*"
                     (change)="imageChangeEvent($event, gift)">
              <div class="profile-photo__input-wrap">
                <img src="{{assetsPrefix}}/assets/images/icons/edit-photo-pencil.svg"
                     alt="{{gift.pictureUrl ? 'Заменить': 'Добавить'}}">
              </div>
            </div>
          </div>
        </div>

        <div class="form__line">
            <div class="form__note">Минимальный размер для фото: ширина - 420 пикс, высота - 420 пикс.</div>
        </div>

        <div class="form__line">
          <div class="survey__title input-main">
            <textarea autosize [(ngModel)]="gift.title" maxlength="255" placeholder="Название" rows="1"
              class="input-main__field"></textarea>
          </div>
        </div>

        <div class="form__line">
          <div class="survey__description input-main">
            <textarea autosize [(ngModel)]="gift.description" maxlength="512" placeholder="Описание" rows="1"
              class="input-main__field"></textarea>
          </div>
        </div>

        <div class="form__line">
          <label class="form__line-label">Стоимость в баллах</label>
          <div class="input-main _inline _smaller">
            <input placeholder="от 1 балла" [(ngModel)]="gift.value" type="number" min="1" class="input-main__field" />
          </div>
        </div>

        <div class="form__line"></div>
      </div>
      <div class="survey__section">
        <div class="form__actions" [ngClass]="{'loading _blue': sending}">
          <button type="button" class="btn btn-simple" (click)="cancel()">Отмена</button>
          <button type="button" class="btn btn-primary" (click)="submit()"
            [attr.disabled]="isValid()?null:''">Сохранить</button>
        </div>
      </div>
    </div>
  </div>
