import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@app/core/core.module';
import { SharedModule } from '@app/shared/shared.module';
import { VacationsService } from './services/vacations.service';
import { VacationDaysComponent } from './components/vacation-days/vacation-days.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    SharedModule
  ],
  declarations: [
    VacationDaysComponent
  ],
  exports: [
    VacationDaysComponent
  ],
  providers: [
    VacationsService
  ]
})
export class VacationsModule { }
