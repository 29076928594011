import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '@app/core/services/helper.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Helper } from '@app/core/helpers/helper';
import { IVacationDay } from '../model/vacation';
import { environment } from '@env/environment';

/**
 * Сервис отпусков
 *
 * @export
 * @class VacationsService
 * @extends {BaseService}
 */
@Injectable({
  providedIn: 'root'
})
export class VacationsService extends BaseService {

  protected serviceUrl = `${environment.siteUrl}/api/vacation`;

  constructor(
    protected http: HttpClient,
    public helper: HelperService) { super(http, helper); }

  getVacations(
    webUrl: string,
    login: string,
    isCurrent: boolean
  ): Observable<IVacationDay[]> {
    const body = {
      'parameters':
      {
        'RenderOptions': 23,
        'ViewXml': `<View>
                <Query>
                  <Where>
                    <Eq>
                      <FieldRef Name='Login' />
                      <Value Type='Text'>${login}</Value>
                    </Eq>
                  </Where>
                  <OrderBy>
                    <FieldRef Name=\"VacationStart\" Ascending=\"true\"></FieldRef>
                  </OrderBy>
                </Query>
                <ViewFields>
                  <FieldRef Name=\"Login\"/>
                  <FieldRef Name=\"VacationStart\"/>
                  <FieldRef Name=\"VacationEnd\"/>
                  ${isCurrent ? '<FieldRef Name=\"VacationDays\"/>' : ''}
                </ViewFields>
                <RowLimit Paged=\"TRUE\">100</RowLimit></View>`
      }
    };

    const listUrl = Helper.concatUrls(webUrl, 'Lists/1C_Employees');

    const operationUrl = `/_api/web/GetList(@listUrl)/RenderListDataAsStream?@listUrl='${decodeURIComponent(listUrl)}'`;

    return this.post<any>(`renderListDataAsStream`, { url: Helper.concatUrls(webUrl, operationUrl), body: JSON.stringify(body) })
      .pipe(map(response => {
        if (!response) {
          return null;
        }

        const data = JSON.parse(response);
        const result = new Array<IVacationDay>();
        if (data && data.ListData && data.ListData.Row) {
          data.ListData.Row.forEach(row => {
            const start: string = row.VacationStart;
            const end: string = row.VacationEnd;
            const startString = start.indexOf(' ') !== -1 ? start.substr(0, start.indexOf(' ')) : start;
            const endString = end.indexOf(' ') !== -1 ? end.substr(0, end.indexOf(' ')) : end;
            if (!result.find(s => s.start === startString && s.end === endString)) {
              result.push({ start: startString, end: endString, vacationDays: (isCurrent ? row.VacationDays : null) });
            }
          });
        }
        return result;
      }));
  }

  createVacationRequest(): any {
    return super.get(`${(<any>window).signalRServer}/_layouts/15/Intry/handlers/VacationApplicationDownload.ashx`, null, null, 'blob');
  }
}
