import { Component, Input, OnChanges, SimpleChanges, OnDestroy, Output, EventEmitter } from '@angular/core';
import { IVacancyCategory, IVacancyStatus, VacancyStatus } from '@app/vacancy/model/vacancy';
import { VacancyService } from '@app/vacancy/services/vacancy.service';
import { takeUntil, take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { HelperService } from '@app/core/services/helper.service';
import { BaseComponent } from '@app/core/components/base.component';

/**
 * Фильтры списка вакансий
 *
 * @export
 * @class VacancyFiltersComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-vacancy-filters',
  templateUrl: './vacancy-filters.component.html',
  styleUrls: ['./vacancy-filters.component.scss']
})
export class VacancyFiltersComponent extends BaseComponent implements OnChanges, OnDestroy {

  @Input() visible: boolean;

  @Output() categoryChanged: EventEmitter<IVacancyCategory> = new EventEmitter();
  @Output() statusChanged: EventEmitter<IVacancyStatus> = new EventEmitter();

  @Input() selectedCategory: IVacancyCategory;
  @Input() selectedStatus: IVacancyStatus;

  private selectedCategoryId: number;

  statuses: IVacancyStatus[] =
    [
      { id: 0, title: 'Все статусы' },
      { id: VacancyStatus.published, title: 'Опубликована' },
      { id: VacancyStatus.draft, title: 'Черновик' },
      { id: VacancyStatus.archive, title: 'Архивная' }
    ];
  categories: IVacancyCategory[];

  constructor(
    private vacancyService: VacancyService,
    private route: ActivatedRoute,
    public helper: HelperService,
  ) {
    super(helper);
    this.selectedStatus = this.statuses[0];

    // find what has is now and route navigate with hash
    this.route.fragment
      .pipe(takeUntil(this.destroyed$))
      .subscribe(val => {
        // get hash
        if (val != null) {
          const keys = val.split('&');
          const hash = {};
          keys.forEach(key => {
            // tslint:disable-next-line:no-shadowed-variable
            const val = key.split('=');
            hash[val[0]] = val[1];
          });

          this.selectedCategoryId = +hash['category'];
          const statusId = +hash['status'];
        } else {

        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['visible']) {
      if (this.visible) {
        this.getVacancyCategories();

        if (!this.selectedStatus) {
          this.selectedStatus = this.statuses[0];
        }
      }
    }
  }

  /**
   * Получение категорий вакансий
   *
   * @memberof VacancyFiltersComponent
   */
  getVacancyCategories() {
    this.vacancyService.getVacancyCategories(0, 20)
      .pipe(take(1), takeUntil(this.destroyed$))
      .subscribe(res => {
        // заполняем массив категорий
        this.categories = res ? res : [];
        // вставляем категорию по умолчанию
        if (!this.categories.find(s => s.id === 0)) {
          this.categories.unshift({ id: 0, title: 'Все категории', order: 0 });
        }
        if (this.selectedCategoryId) {
          this.selectedCategory = this.categories.find(s => s.id === this.selectedCategoryId);
        } else {
          this.selectedCategory = this.categories[0];
        }
      });
  }

  /**
   * Установить статус
   *
   * @param {IVacancyStatus} status
   * @memberof VacancyFiltersComponent
   */
  setStatus(status: IVacancyStatus) {
    this.selectedStatus = status;
    this.statusChanged.emit(status);
  }

  /**
   * Установить категорию
   *
   * @param {IVacancyCategory} category
   * @memberof VacancyFiltersComponent
   */
  setCategory(category: IVacancyCategory) {
    this.selectedCategory = category;
    this.categoryChanged.emit(category);
  }
}
