<div class="cos">
  <div class="cos__body" *ngIf="albums?.length && loaded">
    <div class="grid">
      <div class="grid__content">
        <div class="grid__item" *ngFor="let album of albums" (contextmenu)="showContextMenu($event, m, albumMenu)">
          <!-- ; trackBy:album?.id -->
          <div [style.background-image]="getPreviewUrl(album)" class="gallery-preview"
            [ngClass]="{ '_checked' : album.selected }">
            <div class="gallery-preview__wrap">
              <label class="checkbox" (click)="stopPropagation($event)" *ngIf="!hideAlbumSelector">
                <input type="checkbox" class="checkbox__input" (change)="albumCheckChanged($event,album)">
                <span class="checkbox__icon"></span>
              </label>
              <div class="gallery-preview__menu" (click)="stopPropagation($event)"> <!-- *ngIf="!hideAlbumMenu" -->
                <app-album-menu #albumMenu [selectedItems]="selectedItems" [item]="album" [config]="getMenuConfig(album)" [whiteDots]="true"
                  (vote)="onVote($event, album)"></app-album-menu>
              </div>

              <ng-container *ngIf="select">
                <a class="gallery-preview__link" href="javascript:;" (click)="navigate(album)"></a>
              </ng-container>
              <ng-container *ngIf="!select">
                <a class="gallery-preview__link" [routerLink]="getRouterLink(album.id)"
                  (click)="navigate(album.id)"></a>
              </ng-container>

              <div class="gallery-preview__controls" (click)="stopPropagation($event)">
                <ng-container *ngIf="select">
                  <a class="gallery-preview__title" href="javascript:;" (click)="navigate(album)">{{album.title}}</a>
                </ng-container>
                <ng-container *ngIf="!select">
                  <a class="gallery-preview__title" [routerLink]="getRouterLink(album.id)"
                    (click)="navigate(album.id)">{{album.title}}</a>
                </ng-container>
                <div class="gallery-preview__actions">
                  <div *ngIf="galleryService.isVoteEnabled(album)" class="gallery-preview__vote">
                    <span class="icon _like-white"></span>
                    Голосование
                  </div>
                  <button type="button" class="btn btn-social _pic">{{album.filesCount}}</button>
                  <button type="button" class="btn btn-social _eye">{{album.viewCount}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid__footer align-center" *ngIf="showMoreBlock() || showHideMore()">
        <button type="button" class="btn btn-full">
          <span class="link _progress" (click)="showMore()" *ngIf="showMoreBlock()">Показать ещё</span>
          <span class="link _progress" (click)="hideMore()" *ngIf="showHideMore()">Свернуть список</span>
        </button>
      </div>
    </div>
  </div>
  <div class="cos__body" *ngIf="!albums?.length && loaded">
    <div class="no-data">
      <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" class="no-data__img">
      <div class="no-data__text">В галерее нет альбомов</div>
    </div>
  </div>
</div>
