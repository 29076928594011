import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HelperService } from '@app/core/services/helper.service';
import { PagesService } from '@app/pages/services/pages.service';
import { IPage } from '@app/pages/model/page';
import { take } from 'rxjs/operators';
import { BaseComponent } from '@app/core/components/base.component';

export enum MenuOrientation {
  horizontal = 1,
  vertical = 2
}

/**
 * Страницы в меню
 */
@Component({
  selector: 'app-menu-pages-list',
  templateUrl: './menu-pages-list.component.html',
  styleUrls: ['./menu-pages-list.component.scss']
})
export class MenuPagesListComponent extends BaseComponent implements OnChanges {

  @Input() megaMenu: boolean;
  @Input() showMenu: boolean;
  @Input() orientation: MenuOrientation = MenuOrientation.vertical;

  MenuOrientation = MenuOrientation;

  pages: IPage[];
  pagesTree: IPage[];

  opened: boolean;

  constructor(
    public pagesService: PagesService,
    public helper: HelperService
  ) {
    super(helper);

    // скрывать меню при переходе
    this.helper.hideMenu$.subscribe(() => {
      this.opened = false;
    });

    this.pagesService.pageInMenuUpdated$.subscribe(() => {
      this.getMenu();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showMenu']) {
      this.getMenu();
    }
  }

  private getMenu(): void {
    if (this.showMenu) {
      this.pagesService.getMenuPages()
        .pipe(take(1))
        .subscribe(res => {
          if (res) {
            this.pages = res;
            this.pagesTree = this.pagesService.plainToTree(this.pages, []);
          } else {
            this.pages = [];
            this.pagesTree = [];
          }
        });
    }
  }

  onAnchorClick(event: Event, pageId: number): void {
    event.preventDefault();

    window.location.hash = `menu${pageId}`;
  }

  onBackClick(event: Event): void {
    event.preventDefault();

    window.location.hash = '';
  }

  onMouseOver(event: Event, level: number) {
    if (level === 1) {
      this.opened = true;
    }
  }

  onMouseLeave(event: Event, level: number) {
    if (level === 1) {
      this.opened = false;
    }
  }
}
