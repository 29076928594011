<div class="cos">
  <app-group-small-header [group]="group"></app-group-small-header>
  <div class="cos__header" *ngIf="loaded">
    <a *ngIf="showAddButton" class="btn btn-plus pull-right" (click)="showAddUser()">+</a>
    <app-tabs [tabs]="tabs"></app-tabs>
  </div>
  <!-- header preloader -->
  <app-tab-preloader *ngIf="!loaded"></app-tab-preloader>
  <!--  header preloader END -->

  <div class="cos__body" *ngIf="!showUsers && loaded">
    <app-group-forbidden></app-group-forbidden>
  </div>

  <div class="cos__body" *ngIf="showUsers && loaded && currentTab?.loaded && currentTab?.items?.length">
    <div class="co" *ngFor="let userLink of currentTab?.items" (mouseover)="groupMenu.checkVisibility()">
      <div class="co__avatar">
        <app-user-avatar [user]="userLink?.user" [linkClass]="'avatar _80'" [imageClass]="'avatar__image'">
        </app-user-avatar>
      </div>
      <div class="co__info">
        <a href="#" class="link co__name" routerLink="/profile/{{userLink?.user?.id}}"
          (click)="usersService.navigateProfile(userLink?.user?.id)">
          <span [appUserTooltip]="userLink.user?.id">{{userLink?.user?.fullName}}</span>
          <app-user-absence [user]="userLink.user" [isLightMode]="true"></app-user-absence>
        </a>
        <div class="co__occ" *ngIf="userLink?.user?.jobTitle">{{userLink?.user?.jobTitle}}</div>
        <a class="co__occ" routerLink="/profile/{{currentUser?.id}}/search" fragment="k={{userLink?.user?.department}}"
          *ngIf="userLink?.user?.department">{{userLink?.user?.department}}</a>
        <div class="co__phones" *ngIf="userLink?.user?.mobilePhone || userLink?.user?.workPhone">
          <a href="tel:{{userLink?.user?.mobilePhone}}" class="link co__phone"
            *ngIf="userLink?.user?.mobilePhone">{{userLink?.user?.mobilePhone}}</a>
          <a href="tel:{{userLink?.user?.workPhone}}" class="link co__phone"
            *ngIf="userLink?.user?.workPhone">{{userLink?.user?.workPhone}}</a>
        </div>
      </div>
      <div class="co__action">
        <app-group-menu #groupMenu [user]="userLink?.user" [group]="group" [currentTab]="currentTab"
          [groupUser]="userLink?.groupUser" [userLink]="userLink" (onLeave)="leave($event)" (onJoin)="join($event)"
          (onSubscribe)="join($event)" (onUnsubscribe)="leave($event)" (onRemoveModerator)="leave($event)">
        </app-group-menu>
      </div>
    </div>
  </div>
  <!-- empty users block-->
  <div class="cos__body" *ngIf="showUsers && loaded && currentTab?.loaded && !currentTab?.items?.length">
    <div class="group-empty">
      <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" class="group-empty__img">
      <div class="group-empty__text">{{currentTab.emptyText}}</div>
    </div>
  </div>
  <!-- empty users block END -->
  <!-- header preloader -->
  <app-tabbed-list-preloader *ngIf="!currentTab?.loaded"></app-tabbed-list-preloader>
  <!--  header preloader END -->
</div>