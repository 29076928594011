import { Component, Input } from '@angular/core';
import { UserLink } from '@app/subscribe/model/user-link';
import { SubscribeService } from '@app/subscribe/services/subscribe.service';
import { UsersService } from '@app/profile/services/users.service';
import { User } from '@app/profile/model/user.model';
import { AlertsService } from '@app/shared/services/alerts.service';

/**
 * Простая карточка пользователя
 *
 * @export
 * @class UserSimpleCardComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-user-simple-card',
  templateUrl: './user-simple-card.component.html',
  styleUrls: ['./user-simple-card.component.scss']
})
export class UserSimpleCardComponent {

  @Input() link: UserLink;
  @Input() user: User;

  currentUser: User;

  constructor(
    public alertsService: AlertsService,
    public subscribeService: SubscribeService,
    public usersService: UsersService
  ) {
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;
    });
  }

  /**
   * Отображать блок подписки
   *
   * @param {UserLink} link
   * @returns
   * @memberof SearchResultsComponent
   */
  showSubscribe(link: UserLink) {
    return link
      && link.user
      && this.currentUser
      && link.user.id !== this.currentUser.id;
  }
}
