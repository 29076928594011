<div class="cos__header">
  <div class="cos__actions pull-right" *ngIf="showAddButton">
    <label class="switch">
      <input type="checkbox" [(checked)]="showHidden" (change)="toggleHidden($event)"><span></span><i>Скрытые</i>
    </label>
    <app-pages-menu [groupId]="group?.id" [showAddButton]="showAddButton" [bigPlus]="true" (added)="pagesList.onAdded($event)"></app-pages-menu>
  </div>
  <div class="cos__tabs">
    <div class="tabs _inline">
      <a [routerLink]="getLink()" routerLinkActive="router-link-active" class="tabs__item _active">
        <span class="header-text _low">
          <span class="header-text__text">Страницы</span>
        </span>
      </a>
    </div>
  </div>
</div>
<app-pages-list #pagesList [pages]="pages" [config]="config" [enableShowInWidget]="true"></app-pages-list>