<div class="cos__body">
    <div class="survey" *ngIf="gift">
      <div class="survey__section _banner">
        <div [ngClass]="{'_shadow': gift.pictureUrl}" [attr.style]="giftService.getPictureUrl(gift)" class="banner-item _image _settings">
          <div class="banner-item__controls">
            <div class="profile-photo _group">
              <img src="{{assetsPrefix}}/assets/images/icons/photo.svg" class="profile-photo__img">
            </div>
          </div>
        </div>
        <div class="form__line">
            <div class="survey__title">{{gift.title}}</div>
        </div>
        <div class="form__line">
          <div class="survey__description">{{gift.description}}</div>
        </div>
        <div class="form__line">
          <div class="input-main form__about _full-width">{{gift.value}} {{giftService.getValueText(gift)}}</div>
        </div>
        <div class="form__line"></div>
      </div>
    </div>
  </div>
