import { Component, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { KeyCodes } from '@app/shared/key-codes';
import { FroalaEditorComponent } from '../froala-editor.component';
import { FroalaEditorOptions } from '@app/shared/components/froala-editor/froala-editor-options';

/**
 * Компонент инлайн-wysiwyg редактора Froala
 *
 * 1. https://www.froala.com/wysiwyg-editor/
 * 2. https://github.com/froala/angular-froala-wysiwyg
 */
@Component({
  selector: 'app-froala-inline-editor',
  templateUrl: './inline.component.html',
  styleUrls: ['../froala-editor.component.scss']
})
export class FroalaInlineEditorComponent extends FroalaEditorComponent implements OnInit, OnDestroy, OnChanges {
  /**
  * Добавляет обязательные опции
  * 1. Поднятие события при изменении контента в редакторе
  */
  public addMandatoryOptions(): void {
    this.editorOptions = { ...FroalaEditorOptions };

    const that = this;
    this.editorOptions.placeholderText = this.placeholder;
    this.editorOptions.toolbarInline = true;
    this.editorOptions.toolbarButtons = ['bold', 'italic', 'insertLink'];

    this.editorOptions.events = {
      'initialized': function () {
        const editor = this;

        that.tribute.attach(editor.el);

        editor.events.on('keydown', function (e) {
          if (e.which === KeyCodes.ENTER && that.tribute.isActive) {
            return false;
          }
        }, true);

        if (that.focusOnInit) {
          editor.events.focus();
        } else {
          editor.toolbar.hide();
        }
      },
      'focus': function (e) {
        that.focused.emit();
      },
      'contentChanged': function () {
        const html = this.html.get();

        that.text = html;

        that.changed.emit(html);
      },
      'image.error': this.onError.bind(this),
      'video.error': this.onError.bind(this),
      'file.error': this.onError.bind(this),
    };
  }
}
