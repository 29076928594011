<div class="profile__contacts">
  <div class="profile__contacts-row _center" *ngIf="manager">
    <label class="profile__label">Руководитель</label>
    <div class="profile__info">
      <ng-container *ngTemplateOutlet="userTemplate; context: {$implicit: manager}"></ng-container>
    </div>
  </div>
  <div class="profile__contacts-row _center" *ngIf="subordinates?.length">
    <label class="profile__label _top">Подчинённые</label>
    <div class="profile__subordinates" [ngClass]="{'_show-more':showMoreSubordinates}">
      <ng-container *ngFor="let link of subordinates; let last = last">
        <ng-container *ngTemplateOutlet="subordinateTemplate; context: {$implicit: link}"></ng-container>
      </ng-container>
      <button type="button" class="btn" *ngIf="subordinates?.length > 2"
        [ngClass]="{'btn-content-up':showMoreSubordinates, 'btn-content-down': !showMoreSubordinates}"
        (click)="toggleSubordinatesShow()">
      </button>
    </div>
  </div>
  <div class="profile__contacts-row _center" *ngIf="hireDate">
    <label class="profile__label">Стаж</label>
    <div class="profile__info">
      <ng-container *ngTemplateOutlet="htmlTemplate; context: {$implicit: hireDate}"></ng-container>
    </div>
  </div>
  <div class="profile__contacts-row _center" *ngFor="let property of profileProperties">
    <label class="profile__label">{{getPropertyName(property)}}</label>
    <div class="profile__info">
      <ng-container [ngSwitch]="property.type">
        <!-- если это пользователь, то нужно получить его из нашей базы по логину -->
        <ng-container *ngSwitchCase="'Person'">
          <ng-container *ngTemplateOutlet="userTemplate; context: {$implicit: property}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'string (Single Value)'">
          <!-- текст бывает разный... -->
          <ng-container [ngSwitch]="property.name">
            <ng-container *ngSwitchCase="'HomePhone'">
              <ng-container *ngTemplateOutlet="phoneTemplate; context: {$implicit: property}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'CellPhone'">
              <ng-container *ngTemplateOutlet="phoneTemplate; context: {$implicit: property}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'Fax'">
              <ng-container *ngTemplateOutlet="phoneTemplate; context: {$implicit: property}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <ng-container *ngTemplateOutlet="textTemplate; context: {$implicit: property}"></ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'string (Multi Value)'">
          <ng-container *ngTemplateOutlet="multiTextTemplate; context: {$implicit: property}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'URL'">
          <ng-container *ngTemplateOutlet="urlTemplate; context: {$implicit: property}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <ng-container *ngTemplateOutlet="textTemplate; context: {$implicit: property}"></ng-container>
        </ng-container>
      </ng-container>
    </div>
    <button type="button" class="btn" *ngIf="usersService.isCanEditPrivacy(property, user, currentUser)"
      [matTooltip]="usersService.getPropertyVisiblityText(property)" (click)="usersService.togglePrivacy(property)"
      [ngClass]="{'btn-content-can-view _visible': property.privacy==Privacy.Public, 'btn-content-eye-hide _visible': property.privacy==Privacy.Private}"></button>
  </div>
</div>

<ng-template #subordinateTemplate let-link>
  <div class="co _subordinate">
    <div class="co__avatar">
      <app-user-avatar [user]="link.user" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'">
      </app-user-avatar>
    </div>
    <div class="co__info">
      <a class="link co__name" routerLink="/profile/{{link.user?.id}}"
        (click)="usersService.navigateProfile(link.user?.id)"
        [appUserTooltip]="link.user?.id">{{link.user?.fullName}}</a>
      <div class="co__occ" *ngIf="link.user?.jobTitle">{{link.user?.jobTitle}}</div>
    </div>
  </div>
</ng-template>

<ng-template #userTemplate let-property>
  <div class="co _profile">
    <div class="co__avatar">
      <app-user-avatar [user]="property.data" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'">
      </app-user-avatar>
    </div>
    <!-- редактирование значения пользователя -->
    <div class="co__info" *ngIf="isCurrent && property.isEditable" (click)="changeUser($event,property)">
      <a class="link co__name" routerLink="/profile/{{property.data?.id}}"
        (click)="usersService.navigateProfile(property.data?.id)"
        [appUserTooltip]="property.data?.id">{{property.data?.fullName}}</a>
      <div class="co__occ" *ngIf="property.data?.jobTitle">{{property.data?.jobTitle}}</div>
    </div>
    <!-- всплывашка выбора пользователя -->
    <div class="new-comment__action tooltip" [ngClass]="{'__active': property.showPopup}" appClickOutside
      (clickOutside)="clearChangeUser($event,property)">
      <div class="tooltip__body" style="right:50%; top:0px; visibility: hidden;"
        [ngStyle]="{'visibility':property.showPopup?'visible':'hidden'}">
        <input #inputUser placeholder="Выберите сотрудника" class="main-input cos-add__input _profile"
          (keyup)="onSearchKey($event, property)" (keydown)="onSearchKey($event, property)"
          [ngModel]="property.searchText" (ngModelChange)="property.searchText = $event">
        <div class="error-message" *ngIf="property.error">{{property.error}}</div>
        <a class="link _progress" (click)="selectUser($event,null,property)">Нет заместителя</a>
        <div class="cos-add__suggest">
          <div class="co _one-line" *ngFor="let u of property.dataFound" (click)="selectUser($event,u, property)">
            <div class="co__avatar">
              <app-user-avatar [user]="u" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'" [noRedirect]="true">
              </app-user-avatar>
            </div>
            <div class="co__info">
              <a href="javascript:;" class="link co__name">{{u.fullName}}</a>
              <span class="co__occ">{{u.jobTitle}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- всплывашка выбора пользователя END -->
    <!-- редактирование значения пользователя END -->
    <!-- просмотр значения пользователя -->
    <div class="co__info" *ngIf="!isCurrent || !property.isEditable">
      <a class="link co__name" routerLink="/profile/{{property.data?.id}}"
        (click)="usersService.navigateProfile(property.data?.id)"
        [appUserTooltip]="property.data?.id">{{property.data?.fullName}}</a>
      <div class="co__occ" *ngIf="property.data?.jobTitle">{{property.data?.jobTitle}}</div>
    </div>
    <!-- просмотр значения пользователя END -->
  </div>
</ng-template>

<ng-template #textTemplate let-property>
  <input *ngIf="isCurrent && property.isEditable" type="text" value="{{property.value}}" class="profile__info"
    (keydown)="onKey($event)" (keyup)="onKey($event)" (blur)="onBlur($event, property.name)"
    placeholder="Заполните данные">
  <div class="profile__info" *ngIf="!isCurrent || !property.isEditable">{{property.value}}</div>
</ng-template>

<ng-template #urlTemplate let-property>
  <input *ngIf="isCurrent && property.isEditable" type="text" value="{{property.value}}" class="profile__info"
    (keydown)="onKey($event)" (keyup)="onKey($event)" (blur)="onBlur($event, property.name)"
    placeholder="Заполните данные">
  <a [attr.href]="property.value" target="_blank" class="profile__info"
    *ngIf="!isCurrent || !property.isEditable">{{property.value}}</a>
</ng-template>

<ng-template #multiTextTemplate let-property>
  <textarea *ngIf="isCurrent && property.isEditable" autosize rows="1" placeholder="Заполните данные"
    [(ngModel)]="property.value" class="input-main__field" (keydown)="onKey($event)" (keyup)="onKey($event)"
    (blur)="onBlur($event, property.name)"></textarea>
  <div class="profile__info" *ngIf="!isCurrent || !property.isEditable">{{property.value}}</div>
</ng-template>

<ng-template #htmlTemplate let-property>
  <div class="profile__info">{{property.value}}</div>
</ng-template>

<ng-template #phoneTemplate let-property>
  <input *ngIf="isCurrent && property.isEditable" type="tel" value="{{property.value}}" class="profile__info"
    (keydown)="onKey($event)" (keyup)="onKey($event)" (blur)="onBlur($event, property.name)"
    placeholder="Заполните данные" pattern="^\+|[0-9]{11}">
  <a class="profile__info" *ngIf="!isCurrent || !property.isEditable"
    href="tel:{{property.value}}">{{property.value}}</a>
</ng-template>