<!-- Header -->
<div class="user-header" *ngIf="loaded && currentUser">
    <span class="user-header__back">
      <a routerLink="/profile/{{currentUser?.id}}" class="back"
        (click)="usersService.navigateProfile(currentUser?.id)"></a>
    </span>
    <a routerLink="/profile/{{currentUser?.id}}" class="link-profile"
      (click)="usersService.navigateProfile(currentUser?.id)">
      <app-user-avatar [user]="currentUser" [linkClass]="'avatar _40'" [imageClass]="'avatar__image'" [asSpan]="true">
      </app-user-avatar>
      <span class="link-profile__text" [appUserTooltip]="currentUser?.id">{{currentUser?.fullName}}</span>
    </a>
  </div>
  <!-- Header END -->
<!-- Empty post -->
<div class="card" *ngIf="loaded && (!post || notFound)">
    <div class="group-empty">
      <img src="{{assetsPrefix}}/assets/images/icons/no_data.svg" alt="" class="group-empty__img">
      <div class="group-empty__text">Пост не найден</div>
    </div>
  </div>
  <!-- Empty post END-->
  <!-- post -->
  <app-post-share [profileType]="0" [user]="user" *ngIf="loaded" [placeholder]="'Ваш комментарий к публикации'"
  (onAdd)="redirectToTarget($event)" [selectorType]="selectorType" [post]="post"></app-post-share>
  <!-- post END -->
  <!-- preloaders -->
  <app-post-preloader [loaded]="loaded" [loading]="loading"></app-post-preloader>
  <!-- preloaders END -->
  