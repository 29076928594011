import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommentViewModel } from '@app/comments/model/comment-view-model.model';
import { User } from '@app/profile/model/user.model';
import { UsersService } from '@app/profile/services/users.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmationComponent } from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import { BaseComponent } from '@app/core/components/base.component';
import { HelperService } from '@app/core/services/helper.service';
import { ICommentMenu } from '@app/comments/model/comment.model';

/**
 * Меню комментария
 *
 * @export
 * @class CommentMenuComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-comment-menu',
  templateUrl: './comment-menu.component.html'
})
export class CommentMenuComponent extends BaseComponent implements OnInit {
  @Input() currentUser: User;
  @Input() comment: CommentViewModel;
  @Input() elementAuthorId: number;
  @Input() config: ICommentMenu = {};

  @Output() onEdit: EventEmitter<CommentViewModel> = new EventEmitter();
  @Output() onRemove: EventEmitter<CommentViewModel> = new EventEmitter();

  constructor(
    private usersService: UsersService,
    private dialog: MatDialog,
    public helper: HelperService) { super(helper); }

  ngOnInit() {
    this.usersService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;
    });
  }

  removeComment() {
    this.dialog.open(ModalConfirmationComponent, {
      data: {
        text: 'Вы действительно хотите удалить комментарий?',
        onOk: this.onRemove
      }
    });
  }

  edit() {
    this.onEdit.emit();
  }

  isAllowEdit(): boolean {
    return this.config.allowEdit
      || (this.comment && this.currentUser
        && this.comment.author
        && this.currentUser.id === this.comment.author.id);
  }

  isVisible(): boolean {
    return (this.config.allowEdit || this.config.allowDelete)
      || (this.comment && this.currentUser
        && this.comment.author
        && (this.currentUser.id === this.comment.author.id
          || this.currentUser.id === this.elementAuthorId));
  }
}
