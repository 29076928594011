<div class="card _anniversary" *ngIf="loaded && anniversaries?.length">
  <div class="card__content">
    <div>
      <ngx-slick-carousel class="carousel" [config]="{}">
        <div ngxSlickItem *ngFor="let anniversary of anniversaries">
          <div class="co _anniversary">
            <div class="co__avatar">
              <app-user-avatar [user]="anniversary.user" [linkClass]="'avatar _80'" [imageClass]="'avatar__image'">
              </app-user-avatar>
            </div>
            <ng-container
              *ngTemplateOutlet="anniversaryBadge; context: {$implicit: {anniversary: anniversary.anniversary, platform: 'desktop'}}"
            ></ng-container>
            <div class="co__info">
              <a href="javascript:;" class="link co__name" routerLink="/profile/{{anniversary.user?.id}}"
                (click)="usersService.navigateProfile(anniversary.user?.id)">{{anniversary.user?.fullName}}</a>
            </div>
            <ng-container
                *ngTemplateOutlet="anniversaryBadge; context: {$implicit: {anniversary: anniversary.anniversary, platform: 'phone'}}"
            ></ng-container>
          </div>
          <div class="align-center hidden-phone" *ngIf="currentUser?.id!==anniversary.user?.id">
            <button
              type="button"
              class="btn btn-white btn-stroke"
              (click)="congratulate(anniversary)"
            >Присоединиться к поздравлению</button>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</div>

<ng-template #anniversaryBadge let-params>
  <span
    [ngClass]="{
      'hidden-desktop hidden-tablet': params.platform === 'phone',
      'hidden-phone': params.platform === 'desktop'
    }"
    class="anniversary co__anniversary"
  >
    <span class="anniversary__number">
      {{params.anniversary}}
    </span>
    лет с нами
  </span>
</ng-template>