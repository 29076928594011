import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { ElementType } from '@app/core/model/element-type.enum';
import { CommentViewModel } from '@app/comments/model/comment-view-model.model';
import { CommentsViewModel } from '@app/comments/model/comments-view-model.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HelperService } from '@app/core/services/helper.service';

/**
 * Сервис комментариев
 *
 * @export
 * @class CommentsService
 * @extends {BaseService}
 */
@Injectable()
export class CommentsService extends BaseService {

  protected serviceUrl = `${environment.siteUrl}/api/comment`;
  constructor(
    protected http: HttpClient,
    public helper: HelperService) { super(http, helper); }

  /**
   * Получить комментарий по идентификатору
   *
   * @param {string} id
   * @returns {Observable<CommentViewModel>}
   * @memberof CommentsService
   */
  getCommentById(id: string): Observable<CommentViewModel> {
    return this.get<CommentViewModel>(`${id}`);
  }

  /**
   * Получить все комментарии по элементу
   *
   * @param {number} elementId
   * @param {ElementType} elementType
   * @param {number} offset
   * @param {number} count
   * @returns {Observable<CommentsViewModel>}
   * @memberof CommentsService
   */
  getComments(elementId: number, elementType: ElementType, offset: number, count: number): Observable<CommentsViewModel> {
    return this.get<CommentsViewModel>(`all/${elementId}?elementType=${elementType}&offset=${offset}&count=${count}`);
  }

  /**
   * Получить количество комментариев элемента
   *
   * @param {number} elementId
   * @param {ElementType} elementType
   * @returns {Observable<number>}
   * @memberof CommentsService
   */
  getCommentsCount(elementId: number, elementType: ElementType): Observable<number> {
    return this.get<number>(`count/${elementId}?elementType=${elementType}`);
  }

  /**
   * Добавить комментарий к элементу
   *
   * @param {number} elementId
   * @param {ElementType} elementType
   * @param {string} text
   * @param {number[]} mentions
   * @param {string[]} docs
   * @param {string} [parentId=null]
   * @returns {Observable<CommentViewModel>}
   * @memberof CommentsService
   */
  addComment(
    elementId: number,
    elementType: ElementType,
    text: string,
    parentId: string = null,
    mentions: number[] = null,
    docs: string[] = null,
    media: number[] = null): Observable<CommentViewModel> {
    return this.post<CommentViewModel>(``,
      {
        elementId: elementId,
        elementType: elementType,
        text: text,
        parentId: parentId,
        mentions: mentions,
        docs: docs,
        media: media
      });
  }

  /**
   * Редактировать комментарий по идентификатору
   *
   * @param {string} id
   * @param {string} text
   * @param {number[]} mentions
   * @param {string[]} docs
   * @param {number[]} media
   * @returns {Observable<boolean>}
   * @memberof CommentsService
   */
  editComment(id: string, text: string, mentions: number[], docs: string[], media: number[]): Observable<boolean> {
    return this.put<boolean>(`${id}`,
      {
        text: text,
        mentions: mentions,
        docs: docs,
        media: media
      });
  }

  /**
   * Удалить комментарий по идентификатору
   *
   * @param {string} id ИД комментария
   * @param {ElementType} elementType тип элемента
   * @returns {Observable<boolean>}
   * @memberof CommentsService
   */
  deleteComment(id: string): Observable<boolean> {
    return this.delete<boolean>(`${id}`);
  }
}
