<!-- TODO: вынести кнопку закрытия на уровень попапа -->
<div class="card _close-button">
  <button mat-button mat-dialog-close type="button" class="btn icon _close-blue"></button>
</div>
<div class="gallery-view">
  <div class="gallery-view__image">
    <ng-container *ngIf="originPictureUrl">
      <img [src]="originPictureUrl" width="100%" class="gallery-view__img" [alt]="data.fullName" (error)="originPictureUrl=pictureUrl">
    </ng-container>
  </div>
</div>