import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {IUpdateSurvey, ISurveyQuestion, SurveyType} from '@app/surveys/model/survey';
import {SurveyNewComponent} from '../survey-new/survey-new.component';
import {SurveysService} from '@app/surveys/services/surveys.service';
import {AlertsService} from '@app/shared/services/alerts.service';
import {ActivatedRoute, Router, Params} from '@angular/router';
import {take, takeUntil, switchMap} from 'rxjs/operators';
import {HelperService} from '@app/core/services/helper.service';
import {BreadcrumbsService} from '@app/shared/services/breadcrumbs.service';
import {MatDialog} from '@angular/material/dialog';
import {ModalConfirmationComponent} from '@app/shared/components/modals/modal-confirmation/modal-confirmation.component';
import {UsersService} from '@app/profile/services/users.service';

/**
 * Редактирование опроса
 *
 * @export
 * @class SurveyEditComponent
 * @extends {SurveyNewComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-survey-edit',
  templateUrl: '../survey-new/survey-new.component.html',
})
export class SurveyEditComponent extends SurveyNewComponent implements OnInit, OnDestroy {
  @Input() survey: IUpdateSurvey;
  showScope = false;
  draftButtonText = 'Сохранить';
  loaded = false;

  constructor(
    public usersService: UsersService,
    public surveysService: SurveysService,
    protected alertsService: AlertsService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected breadcrumbsService: BreadcrumbsService,
    protected dialog: MatDialog,
    public helper: HelperService,
  ) {
    super(
      usersService,
      surveysService,
      alertsService,
      route,
      router,
      breadcrumbsService,
      dialog,
      helper,
    );
  }

  ngOnInit() {
    this.route.params
      .pipe(
        takeUntil(this.destroyed$),
        switchMap((params: Params) => {
          this.survey = null;
          const surveyId = +params['surveyId'];
          return this.surveysService.getById(surveyId, true);
        }),
      )
      .subscribe(
        survey => {
          if (survey) {
            this.survey = {
              id: survey.id,
              ownerId: survey.ownerId,
              title: survey.title,
              description: survey.description,
              dateEnd: survey.dateEnd,
              published: survey.published,
              questions: survey.questions,
              type: survey.type,
            };

            this.isTest = this.survey.type === SurveyType.test;

            this.breadcrumbsService.breadcrumbs
              .pipe(takeUntil(this.destroyed$))
              .subscribe(breadcrumbs => {
                if (breadcrumbs) {
                  this.breadcrumbsService.remove('Новый тест');
                  this.breadcrumbsService.remove('Новый опрос');
                  if (this.isTest) {
                    this.breadcrumbsService.add('Тест', 'javascript:;');
                  } else {
                    this.breadcrumbsService.add('Опрос', 'javascript:;');
                  }
                }
              });
          } else {
            this.router.navigate(['404'], {skipLocationChange: true});
          }

          this.loaded = true;
        },
        error => {
          this.error = error;

          // TODO: #1818 поправить лучше (возвращать нормальный statusCode=403)
          if (
            error &&
            error.error &&
            error.error.Message &&
            error.error.Message.indexOf('не может отредактировать') !== -1
          ) {
            this.router.navigate(['/403'], {skipLocationChange: true});
          }

          this.loaded = true;
        },
      );
  }

  /**
   * Разрешено редактировать опрос?
   *
   * @returns
   * @memberof SurveyEditComponent
   */
  allowEditSurvey(): boolean {
    return this.survey && !this.survey.published;
  }

  /**
   * Разрешено добавлять вопросы?
   *
   * @param {ISurveyQuestion} question
   * @returns
   * @memberof SurveyEditComponent
   */
  allowEditQuestion(question: ISurveyQuestion) {
    return this.survey && (!this.survey.published || (question && !question.id));
  }

  /**
   * Отправка формы
   *
   * @protected
   * @memberof SurveyEditComponent
   */
  protected send() {
    this.sending = true;
    this.isAlreadyExists = false;

    this.survey.questions.forEach(question => {
      question.order = this.survey.questions.indexOf(question) + 1;
      if (question.questions) {
        question.questions.forEach(childQuestion => {
          childQuestion.order = question.questions.indexOf(childQuestion) + 1;
        });
      }
    });

    this.surveysService
      .update(this.survey.id, this.survey)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        () => {
          this.sending = false;
          this.alertsService.riseSuccess(
            `Опрос "${this.survey.title}" был успешно изменён`,
          );
          if (this.survey.published) {
            this.router.navigate([`..`], {relativeTo: this.route});
          }
          // отправляем изменение в сервис
          this.surveysService.surveyChanged$.next();
        },
        error => {
          this.sending = false;
          this.alertsService.riseError(
            `Произошла ошибка при изменении опроса '${this.survey.title}'`,
          );
          if (error && error.error && error.error.ErrorCode === 13000) {
            this.isAlreadyExists = true;
          }
        },
      );
  }

  /**
   * При нажатии кнопки "Отмена" - возвращаемся в список опросов
   *
   * @memberof SurveyEditComponent
   */
  cancel() {
    this.dialog.open(ModalConfirmationComponent, {
      data: {
        text: `Вы действительно хотите уйти с формы?`,
        onOk: () => {
          this.router.navigate([`../..`], {relativeTo: this.route});
        },
      },
    });
  }
}
