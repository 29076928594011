<ng-container *ngIf="isEdit; then edit; else display"></ng-container>
<ng-template #edit>
  <div class="grid _attachments" *ngIf="mediaAttachments?.length">
    <div class="grid__content">
      <div class="grid__item" *ngFor="let m of mediaAttachments;let i=index;">
        <div [style.background-image]="galleryService.getMediaFilePreview(m.mediaAttachment)"
          class="gallery-preview _file" (click)="openMediaFile($event,m.mediaAttachment)">
          <div class="gallery-preview__wrap">
            <div class="gallery-preview__menu _vertical" (click)="stopPropagation($event)">
              <a href="javascript:;" (click)="remove(m, i)"><span class="icon _remove"></span></a>
              <a href="javascript:;" *ngIf="m.mediaAttachment.type===mediaFileType.image" (click)="crop($event,m, i)"><span class="icon _edit"></span></a>
            </div>
            <div class="gallery-preview__play" *ngIf="isVideo(m)">
              <!--Длительность видео вставить здесь-->
              <!--<span>14:15</span>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="filesUploading">
    <mat-spinner [diameter]="32"></mat-spinner>
  </div>
</ng-template>
<ng-template #display>
  <div class="grid _post" *ngIf="mediaAttachments?.length">
    <div class="grid__content">
      <div class="grid__item" *ngFor="let m of mediaAttachments?.slice(0, 6); let i = index"
        [attr.data-rest]="i === 5 && mediaAttachments.length - 6 || null">
        <div [style.background-image]="galleryService.getMediaFilePreview(m.mediaAttachment)"
          class="gallery-preview _file" (click)="openMediaFile($event,m.mediaAttachment)">
          <div class="gallery-preview__wrap">
            <div class="gallery-preview__menu" (click)="stopPropagation($event)">
              <!-- <app-media-attachment-menu [attachment]="m" (deleted)="onMediaFileDeleted($event)"></app-media-attachment-menu> -->
            </div>
            <div class="gallery-preview__play" *ngIf="m.mediaAttachment.type===mediaFileType.video">
              <!--Длительность видео вставить здесь-->
              <!--<span>14:15</span>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
