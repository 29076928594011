import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { HelperService } from '@app/core/services/helper.service';
import { IChangeOrders } from '@app/shared/model/item-order';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IGamificationType, IGamificationTypeChanges } from '../model/gamification-type';

@Injectable({
  providedIn: 'root'
})
export class GamificationTypeService extends BaseService {

  protected serviceUrl = `${this.helper.environment.siteUrl}/api/gamificationType`;

  constructor(
    protected http: HttpClient,
    public helper: HelperService) { super(http, helper); }

  getGamificationTypes(inactive: boolean = true): Observable<IGamificationType[]> {
    return super.get(`?inactive=${inactive}`)
      .pipe(map((data: IGamificationType[]) => {
        data.forEach(d => {
          if (d.value <= 0) {
            d.value = null;
          }
        });
        return data;
      }));
  }

  editGamificationTypes(changes: IGamificationTypeChanges): Observable<boolean> {
    return super.put(`update`, changes );
  }

  hideGamificationType(id: number): Observable<boolean> {
    return super.put(`hide/${id}`, {});
  }

  changeGamificationTypeOrder(settings: IChangeOrders): Observable<boolean> {
    return super.put(`order`, settings);
  }
}
