<div class="card">
  <span class="header-text _modal">
    <span class="header-text__text">Журнал версий</span>&nbsp;<span class="header-text__count"></span>
  </span>
  <a href="javascript:;" class="link-file" [ngClass]="filesService.getFileIcon(item)">
    <span class="link-file__text">{{item?.name}}</span>
    <span class="link-file__subtext">{{filesService.getSize(item)}} КБ, версия {{item.version}}</span>
  </a>
  <div class="list-table _journal">
    <div class="list-table__mobile-header">По дате изменения</div>
    <table class="list-table__inner">
      <thead>
        <tr>
          <th>
            <span class="list-table__heading" [ngClass]="{'_up': (sortBy=='version' && !sortAsc), '_down': (sortBy=='version' && sortAsc) }"
              (click)="sortColumn('version', !sortAsc)"><span>Версия</span></span>
          </th>
          <th>
            <span class="list-table__heading" [ngClass]="{'_up': (sortBy=='created' && !sortAsc), '_down': (sortBy=='created' && sortAsc)}"
              (click)="sortColumn('created', !sortAsc)"><span>Изменено</span></span>
          </th>
          <th>

          </th>
          <th>
            <span class="list-table__heading" [ngClass]="{'_up': (sortBy=='createdBy' && !sortAsc), '_down': (sortBy=='createdBy' && sortAsc)}"
              (click)="sortColumn('createdBy', !sortAsc)"><span>Кем изменено</span></span>
          </th>
          <th>
            <span class="list-table__heading" [ngClass]="{'_up': (sortBy=='size' && !sortAsc), '_down': (sortBy=='size' && sortAsc)}"
              (click)="sortColumn('size', !sortAsc)"><span>Размер</span></span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let file of items">
          <td> <span>{{file.VersionLabel}}</span></td>
          <td><a [href]="file.AbsoluteUrl" target="_blank">{{file.Created | dateSpLike}}</a></td>
          <td class="list-table__actions">
            <ng-container *ngIf="!file.IsCurrentVersion">
              <div class="btn btn-dots tooltip" mat-button [matMenuTriggerFor]="menu"></div>
              <mat-menu #menu="matMenu">
                <div class="icon-menu">
                  <a mat-menu-item href="javascript:;" class="icon-menu__item" (click)="restore(file)">
                    <span class="icon-menu__icon">
                      <img src="{{assetsPrefix}}/assets/images/icons/sync.svg" alt="" class="icon-menu__img" />
                    </span>
                    <span>Восстановить</span>
                  </a>
                  <a mat-menu-item href="javascript:;" class="icon-menu__item" *ngIf="!file.IsCurrentVersion" (click)="delete(file)">
                    <span class="icon-menu__icon">
                      <img src="{{assetsPrefix}}/assets/images/icons/menu/remove.svg" alt="" class="icon-menu__img" />
                    </span>
                    <span>Удалить</span>
                  </a>
                </div>
              </mat-menu>
            </ng-container>
          </td>
          <td>{{file.CreatedBy?.Title}}</td>
          <td>{{filesService.getSize(file)}} кб</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>